import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import i18n from '../../../../i18n/i18n'
import isEmpty from 'is-empty'

import { omitByEmpty, popLastSections } from '../../../../helpers/dataHelper'
import {
  dateFormatFromTo,
  countOfDatesBetween,
} from '../../../../helpers/dateHelper'

import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'

import InnerCallsSummaryTable from './InnerCallsSummaryTable'
import InnerCallsChart from './InnerCallsChart'
import InnerCallsFilters from './InnerCallsFilters'

const styles = theme => ({
  title: {
    fontSize: 26,
    color: theme.palette.apply.main,
  },
  expansionTitle: {
    fontSize: 15,
    color: theme.palette.apply.main,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  textAlignRight: {
    textAlign: 'right',
  },
})

class InnerCallsAnalytics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateFrom: '',
      dateTo: '',
      limit: 30,
      limitEngineer: 30,
      pageCreated: 0,
      pageAverage: 0,
      pageCount: 0,
      isExpandedCreatedOpen: false,
      isExpandedAverageOpen: false,
      isExpandedCountOpen: false,
    }
  }

  componentDidMount() {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        fetchOrganization,
      },
    } = this

    fetchOrganization(organizationId)
    this.changeFilters()
  }

  componentWillUnmount() {
    const {
      props: { clearFilterDrawer },
    } = this
    clearFilterDrawer()
  }

  changeState = (field, value) => {
    this.setState({ [field]: value })
  }

  handleChangePage = (event, page, field) => {
    this.setState({ [field]: page }, () => {
      const {
        state: {
          pageCreated,
          pageAverage,
          pageCount,
          limitEngineer,
          limit,
          dateFrom,
          dateTo,
          radioValue,
          engineerId,
        },
        props: {
          fetchInnerCallAnalyticsChartCreatedRequest,
          fetchInnerCallAnalyticsChartAverageRequest,
          fetchInnerCallAnalyticsChartClosedByEngineerRequest,
          match: {
            params: { organizationId },
          },
        },
      } = this

      const paramsDefault = omitByEmpty({
        organization_id: organizationId,
        date_from: dateFrom && new Date(dateFrom).toISOString(),
        date_to: dateTo && new Date(dateTo).toISOString(),
        interval: radioValue,
        engineer_id: engineerId,
        timezoneoffset: new Date().getTimezoneOffset() / 60,
      })

      let offset = 0
      if (field === 'pageCreated') {
        offset = pageCreated * limit
        fetchInnerCallAnalyticsChartCreatedRequest(
          Object.assign({}, paramsDefault, { offset, limit }),
        )
      }

      if (field === 'pageAverage') {
        offset = pageAverage * limitEngineer
        fetchInnerCallAnalyticsChartAverageRequest(
          Object.assign({}, paramsDefault, { offset, limit: limitEngineer }),
        )
      }

      if (field === 'pageCount') {
        offset = pageCount * limitEngineer
        fetchInnerCallAnalyticsChartClosedByEngineerRequest(
          Object.assign({}, paramsDefault, { offset, limit: limitEngineer }),
        )
      }
    })
  }

  changeFilters = () => {
    const {
      state: { isExpandedAverageOpen, isExpandedCountOpen, limitEngineer },
      props: {
        setFilterDrawer,
        fetchInnerCallAnalyticsChartCreatedRequest,
        fetchInnerCallAnalyticsChartAverageRequest,
        fetchInnerCallAnalyticsChartClosedByEngineerRequest,
        match: {
          params: { organizationId },
        },
      },
      changeState,
    } = this

    setFilterDrawer([
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="left"
      >
        <Grid item>
          <InnerCallsFilters
            limitEngineer={limitEngineer}
            organizationId={organizationId}
            changeState={changeState}
            isEngineerDisable={isExpandedAverageOpen || isExpandedCountOpen}
            fetchInnerCallAnalyticsChartCreatedRequest={
              fetchInnerCallAnalyticsChartCreatedRequest
            }
            fetchInnerCallAnalyticsChartAverageRequest={
              fetchInnerCallAnalyticsChartAverageRequest
            }
            fetchInnerCallAnalyticsChartClosedByEngineerRequest={
              fetchInnerCallAnalyticsChartClosedByEngineerRequest
            }
          />
        </Grid>
      </Grid>,
    ])
  }

  render() {
    const {
      props: {
        classes,
        summary,
        summaryAverage,
        chartCreated,
        chartAverage,
        chartClosedByEngineer,
        engineerCount,
        match: { path },
        organization,
      },
      state: {
        dateFrom,
        dateTo,
        isExpandedCreatedOpen,
        isExpandedAverageOpen,
        isExpandedCountOpen,
        limit,
        limitEngineer,
        pageCreated,
        pageAverage,
        pageCount,
        radioValue,
      },
      handleChangePage,
      changeFilters,
    } = this

    const countOfDatesBetweenTwoDates = countOfDatesBetween(
      dateFrom,
      dateTo,
      radioValue,
    )
    const countOfEngineer = parseInt(engineerCount, 10)
    return (
      <Fragment>
        {organization && (
          <BreadCrumb to={popLastSections(path)} notClickable>
            {organization.full_name}
          </BreadCrumb>
        )}
        <Typography variant="h4" gutterBottom>
          {i18n.t('inner_calls_web_atoir:innerCallAnalytics')}
        </Typography>
        <ExpansionPanel
          onChange={() => {
            this.setState(
              {
                isExpandedCreatedOpen: !isExpandedCreatedOpen,
                isExpandedAverageOpen: false,
                isExpandedCountOpen: false,
              },
              () => changeFilters(),
            )
          }}
          expanded={isExpandedCreatedOpen}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.expansionTitle}>
              {i18n.t('inner_calls_web_atoir:createdDynamic')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid
                item
                xs={12}
                className={classes.marginBottom}
                justify="flex-end"
              >
                <div className={classes.textAlignRight}>
                  {dateFormatFromTo(dateFrom, dateTo)}
                </div>
                <InnerCallsChart
                  chartData={chartCreated}
                  chartType="line"
                  interval={radioValue}
                />
              </Grid>
              <Grid item xs={6} className={classes.marginBottom}>
                <InnerCallsSummaryTable
                  summary={
                    !isEmpty(summary.chart_info) ? summary.chart_info[0] : null
                  }
                />
              </Grid>
              {countOfDatesBetweenTwoDates > limit && (
                <Grid item xs={6}>
                  <TablePagination
                    component="nav"
                    page={pageCreated}
                    rowsPerPage={limit}
                    count={countOfDatesBetweenTwoDates}
                    labelDisplayedRows={({
                      from: fromPage,
                      to: toPage,
                      count: countPage,
                    }) =>
                      i18n.t('shared_web_atoir:labelDisplayedRows', {
                        fromPage,
                        toPage,
                        countPage,
                      })
                    }
                    rowsPerPageOptions={[]}
                    onChangePage={(event, page) =>
                      handleChangePage(event, page, 'pageCreated')
                    }
                  />
                </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          onChange={() => {
            this.setState(
              {
                isExpandedCreatedOpen: false,
                isExpandedAverageOpen: !isExpandedAverageOpen,
                isExpandedCountOpen: false,
              },
              () => changeFilters(),
            )
          }}
          expanded={isExpandedAverageOpen}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.expansionTitle}>
              {i18n.t('inner_calls_web_atoir:averageTime')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid
                item
                xs={12}
                className={classes.marginBottom}
                justify="flex-end"
              >
                <div className={classes.textAlignRight}>
                  {dateFormatFromTo(dateFrom, dateTo)}
                </div>
                <InnerCallsChart
                  chartData={chartAverage}
                  chartType="bar"
                  options={{
                    tooltips: {
                      callbacks: {
                        label(tooltipItem, data) {
                          const label =
                            data.datasets[tooltipItem.datasetIndex].label || ''
                          const hours = Math.floor(+tooltipItem.value)
                          const minutes = Math.floor(
                            (+tooltipItem.value * 60) % 60,
                          )
                          return `${label} ${i18n.t(
                            'shared_web_atoir:hoursInWork',
                            { hours },
                          )} ${i18n.t('shared_web_atoir:minutesInWork', {
                            minutes,
                          })}`
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <InnerCallsSummaryTable
                  summary={
                    !isEmpty(summaryAverage.chart_info)
                      ? summaryAverage.chart_info[0]
                      : null
                  }
                />
              </Grid>
              {countOfEngineer > limitEngineer && (
                <Grid item xs={6}>
                  <TablePagination
                    component="nav"
                    page={pageAverage}
                    rowsPerPage={limitEngineer}
                    count={countOfEngineer}
                    labelDisplayedRows={({
                      from: fromPage,
                      to: toPage,
                      count: countPage,
                    }) =>
                      i18n.t('shared_web_atoir:labelDisplayedRows', {
                        fromPage,
                        toPage,
                        countPage,
                      })
                    }
                    rowsPerPageOptions={[]}
                    onChangePage={(event, page) =>
                      handleChangePage(event, page, 'pageAverage')
                    }
                  />
                </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          onChange={() => {
            this.setState(
              {
                isExpandedCreatedOpen: false,
                isExpandedAverageOpen: false,
                isExpandedCountOpen: !isExpandedCountOpen,
              },
              () => changeFilters(),
            )
          }}
          expanded={isExpandedCountOpen}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.expansionTitle}>
              {i18n.t('inner_calls_web_atoir:countByEngineer')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid
                item
                xs={12}
                className={classes.marginBottom}
                justify="flex-end"
              >
                <div className={classes.textAlignRight}>
                  {dateFormatFromTo(dateFrom, dateTo)}
                </div>
                <InnerCallsChart
                  chartData={chartClosedByEngineer}
                  chartType="bar"
                />
              </Grid>
              <Grid item xs={6}>
                <InnerCallsSummaryTable
                  summary={
                    !isEmpty(summaryAverage.chart_info)
                      ? summaryAverage.chart_info[0]
                      : null
                  }
                />
              </Grid>
              {countOfEngineer > limitEngineer && (
                <Grid item xs={6}>
                  <TablePagination
                    component="nav"
                    page={pageCount}
                    rowsPerPage={limitEngineer}
                    count={countOfEngineer}
                    labelDisplayedRows={({
                      from: fromPage,
                      to: toPage,
                      count: countPage,
                    }) =>
                      i18n.t('shared_web_atoir:labelDisplayedRows', {
                        fromPage,
                        toPage,
                        countPage,
                      })
                    }
                    rowsPerPageOptions={[]}
                    onChangePage={(event, page) =>
                      handleChangePage(event, page, 'pageCount')
                    }
                  />
                </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
}

InnerCallsAnalytics.propTypes = {
  organization: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  summaryAverage: PropTypes.object.isRequired,
  chartCreated: PropTypes.object.isRequired,
  chartAverage: PropTypes.object.isRequired,
  chartClosedByEngineer: PropTypes.object.isRequired,
  engineerCount: PropTypes.string.isRequired,
  fetchInnerCallAnalyticsChartCreatedRequest: PropTypes.func.isRequired,
  fetchInnerCallAnalyticsChartAverageRequest: PropTypes.func.isRequired,
  fetchInnerCallAnalyticsChartClosedByEngineerRequest:
    PropTypes.func.isRequired,
}

export default withStyles(styles)(InnerCallsAnalytics)
