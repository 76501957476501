import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AppDialog from './AppDialog'
import { handleCloseSimpleDialog } from '../../actions/appActions'

const mapStateToProps = state => ({
  simpleDialogOpen: state.app.simpleDialogOpen,
  simpleDialogText: state.app.simpleDialogText,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleCloseSimpleDialog,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppDialog),
)
