import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchUserEquipment,
  fetchUserEquipmentVendors,
  fetchUserEquipmentDepartments,
  fetchOrganizationEquipment,
  fetchUserEquipmentCount,
  transferEquipment,
  fetchTransferringEquipmentReceiver,
} from '../../actions/equipmentActions'
import {
  fetchUserOrganizations,
  fetchUserOrganizationsCanAddEquipment,
} from '../../actions/organizationsActions'
import {
  fetchUserEquipmentLocations,
  fetchLocations,
} from '../../actions/locationsActions'
import { showDialogLoader, hideDialogLoader } from '../../actions/utilsActions'
import EquipmentComponent from '../../components/Equipment/EquipmentComponent'
import { fetchDepartments } from '../../actions/departmentsActions'

const mapStateToProps = state => ({
  isTransferringEquipment: state.equipment.isTransferringEquipment,
  organizationLocations: state.locations.locations,
  organizationDepartments: state.departments.departments,
  isFetchingOrganizationLocations: state.locations.isFetchingLocations,
  isFetchingOrganizationDepartments: state.departments.isFetchingDepartments,
  error: state.equipment.error,
  rowsPerPage: state.equipment.list.rowsPerPage,
  canAddEquipmentOrganizations:
    state.organizations.canAddEquipmentOrganizations.organizations,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEquipment: fetchUserEquipment,
      fetchVendors: fetchUserEquipmentVendors,
      fetchDepartments: fetchUserEquipmentDepartments,
      fetchOrganizations: fetchUserOrganizations,
      fetchLocations: fetchUserEquipmentLocations,
      fetchOrganizationEquipment,
      transferEquipment,
      showDialogLoader,
      hideDialogLoader,
      fetchOrganizationLocations: fetchLocations,
      fetchOrganizationDepartments: fetchDepartments,
      fetchUserEquipmentCount,
      fetchTransferringEquipmentReceiver,
      fetchUserOrganizationsCanAddEquipment,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentComponent),
)
