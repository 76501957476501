import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditCreateDepartmentComponent from './EditCreateDepartmentComponent'
import { fetchOrganizationStaff } from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  organizationUsers: state.staff.organizationStaff,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationStaff,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditCreateDepartmentComponent),
)
