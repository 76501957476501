import React, { Component } from 'react'
import isEmpty from 'is-empty'
import _ from 'lodash'
import PropTypes from 'prop-types'

// ---material--
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles/index'

// ---components---
import ApplyButton from '../../../../../../widgets/Buttons/ApplyButton'
import DropDownList from '../../../../../../widgets/DropDownList'

// ---helprs---
import { organizationLocationStringify } from '../../../../../../helpers/parserHelper'

import i18next from '../../../../../../i18n/i18n'
import { getUserFullName } from '../../../../../../helpers/userHelper'

const styles = () => ({
  button: {
    width: '100%',
  },
})

class FiltersListOrders extends Component {
  state = {
    openDropDown: null,
    organizationLocationId: '',
    organizationDepartmentId: '',
    floor: '',
    office: '',
    status: '',
    performerUserId: '',
  }

  handleOpenDropDown = name => () => {
    this.setState({
      openDropDown: name,
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const {
      state: {
        organizationLocationId,
        organizationDepartmentId,
        floor,
        office,
        status,
        performerUserId,
      },
      props: { handleFilters },
    } = this

    let strParams = ''
    if (!isEmpty(organizationDepartmentId)) {
      strParams = isEmpty(strParams)
        ? `organization_department_id=${organizationDepartmentId}`
        : `${strParams}&organization_department_id=${organizationDepartmentId}`
    }
    if (!isEmpty(organizationLocationId)) {
      strParams = isEmpty(strParams)
        ? `organization_location_id=${organizationLocationId}`
        : `${strParams}&organization_location_id=${organizationLocationId}`
    }
    if (!isEmpty(floor)) {
      strParams = isEmpty(strParams)
        ? `floor=${floor}`
        : `${strParams}&floor=${floor}`
    }
    if (!isEmpty(performerUserId)) {
      strParams = isEmpty(strParams)
        ? `performer_user_id=${performerUserId}`
        : `${strParams}&performer_user_id=${performerUserId}`
    }
    if (!isEmpty(office)) {
      strParams = isEmpty(strParams)
        ? `office=${office}`
        : `${strParams}&office=${office}`
    }
    if (!isEmpty(status)) {
      strParams = isEmpty(strParams)
        ? `filters=[status=${status}]`
        : `${strParams}&filters=[status=${status}]`
    }
    handleFilters(strParams)
  }

  compareNumeric = (a, b) => {
    if (+a.title > +b.title) return 1
    return -1
  }

  render() {
    const {
      handleOpenDropDown,
      handleChange,
      handleSubmit,
      props: { classes, filters, isFetchFilters },
      state: {
        openDropDown,
        floor,
        office,
        status,
        organizationLocationId,
        organizationDepartmentId,
        performerUserId,
      },
    } = this
    let addressesElements = []
    let departmentsElements = []
    let performersElements = []
    let floorsElements = []
    let officesElements = []
    const statusElements = [
      {
        id: 'created',
        title: `${i18next.t('inventory_web_atoir:created')}`,
      },
      {
        id: 'done',
        title: `${i18next.t('inventory_web_atoir:done')}`,
      },
      {
        id: 'closed',
        title: `${i18next.t('inventory_web_atoir:closed')}`,
      },
    ]

    if (!isEmpty(filters)) {
      addressesElements = isEmpty(filters.addresses)
        ? []
        : filters.addresses.map(_address => ({
            id: _address.id,
            title: organizationLocationStringify(_address),
          }))
      departmentsElements = isEmpty(filters.departments)
        ? []
        : filters.departments.map(_department => ({
            id: _department.id,
            title: _department.name,
          }))
      floorsElements = isEmpty(filters.floors)
        ? []
        : filters.floors.map(_floor => ({
            id: `${_floor}`,
            title: `${_floor}`,
          }))
      officesElements = isEmpty(filters.offices)
        ? []
        : filters.offices.map(_office => ({ id: _office, title: _office }))
      performersElements = isEmpty(filters.performers)
        ? []
        : filters.performers.map(_performer => ({
            id: _performer.id,
            title: getUserFullName(_performer),
          }))
    }

    return (
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        className={classes.section}
      >
        <Grid container spacing={2} item xs={10}>
          <Grid item xs={7}>
            <DropDownList
              value={organizationLocationId}
              name="organizationLocationId"
              open={openDropDown === 'organizationLocationId'}
              handleOpen={handleOpenDropDown('organizationLocationId')}
              handleClose={handleOpenDropDown(null)}
              handleChange={handleChange('organizationLocationId')}
              showLabel
              label={i18next.t('inventory_web_atoir:Address')}
              elements={_.sortBy(addressesElements, 'title')}
              isFetching={isFetchFilters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={5}>
            <DropDownList
              value={organizationDepartmentId}
              name="organizationDepartmentId"
              open={openDropDown === 'organizationDepartmentId'}
              handleOpen={handleOpenDropDown('organizationDepartmentId')}
              handleClose={handleOpenDropDown(null)}
              handleChange={handleChange('organizationDepartmentId')}
              showLabel
              label={i18next.t('inventory_web_atoir:Department')}
              elements={_.sortBy(departmentsElements, 'title')}
              isFetching={isFetchFilters}
              classes={classes}
            />
          </Grid>

          <Grid item xs={2}>
            <DropDownList
              value={floor}
              name="floor"
              open={openDropDown === 'floor'}
              handleOpen={handleOpenDropDown('floor')}
              handleClose={handleOpenDropDown(null)}
              handleChange={handleChange('floor')}
              showLabel
              label={i18next.t('inventory_web_atoir:Floor')}
              elements={floorsElements.sort(this.compareNumeric)}
              isFetching={isFetchFilters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={2}>
            <DropDownList
              value={office}
              name="office"
              open={openDropDown === 'office'}
              handleOpen={handleOpenDropDown('office')}
              handleClose={handleOpenDropDown(null)}
              handleChange={handleChange('office')}
              showLabel
              label={i18next.t('inventory_web_atoir:Cabinet')}
              elements={_.sortBy(officesElements, 'title')}
              isFetching={isFetchFilters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={3}>
            <DropDownList
              value={status}
              name="status"
              open={openDropDown === 'status'}
              handleOpen={handleOpenDropDown('status')}
              handleClose={handleOpenDropDown(null)}
              handleChange={handleChange('status')}
              showLabel
              label={i18next.t('inventory_web_atoir:statusOrder')}
              elements={statusElements}
              isFetching={isFetchFilters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={5}>
            <DropDownList
              value={performerUserId}
              name="performerUserId"
              open={openDropDown === 'performerUserId'}
              handleOpen={handleOpenDropDown('performerUserId')}
              handleClose={handleOpenDropDown(null)}
              handleChange={handleChange('performerUserId')}
              showLabel
              label={i18next.t('inventory_web_atoir:Specialist')}
              elements={_.sortBy(performersElements, 'title')}
              isFetching={isFetchFilters}
              classes={classes}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <ApplyButton onClick={handleSubmit}>
            {i18next.t('shared_web_atoir:buttonShow')}
          </ApplyButton>
        </Grid>
      </Grid>
    )
  }
}

FiltersListOrders.defaultProps = {
  isFetchFilters: false,
}

FiltersListOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  isFetchFilters: PropTypes.bool,
}

export default withStyles(styles)(FiltersListOrders)
