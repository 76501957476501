import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ContractEquipmentTable from './ContractEquipmentTable'
import {
  clearRequestDataOnCreation,
  fetchContractRequest,
} from '../../../../actions/serviceDesk'
import {
  fetchEquipmentFilterLocationRequest,
  fetchEquipmentFilterDepartmentRequest,
} from '../../../../actions/equipmentActions'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../../actions/utilsActions'
import {
  deleteContractEquipmentsRequest,
  fetchContractEquipmentsCountRequest,
  fetchContractEquipmentsRequest,
  setContractsEquipmentRowsPerPage,
} from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isCreatingRequest: state.serviceDesk.createRequest.isFetching,
  creatingRequestError: state.serviceDesk.createRequest.error,
  callId: state.serviceDesk.createRequest.id,
  isDeletingEquipment: state.serviceDeskContracts.delete.isFetching,
  deleteError: state.serviceDeskContracts.delete.error,
  availableEquipment: state.serviceDeskContracts.equipments.equipments,
  availableEquipmentIsFetching:
    state.serviceDeskContracts.equipments.isFetching,
  availableEquipmentFetchingError: state.serviceDeskContracts.equipments.error,
  contract: state.serviceDesk.contract.contract,
  contractIsFetching: state.serviceDesk.contract.isFetching,
  contractFetchingError: state.serviceDesk.contract.error,
  equipmentCount: state.serviceDeskContracts.equipmentCount.count,
  equipmentCountIsFetching:
    state.serviceDeskContracts.equipmentCount.isFetching,
  equipmentCountFetchingError: state.serviceDeskContracts.equipmentCount.error,
  locationFilterData: state.equipment.equipmentFilterLocation.addresses,
  locationFilterDataIsFetching:
    state.equipment.equipmentFilterLocation.isFetching,
  locationFilterDataError: state.equipment.equipmentFilterLocation.error,
  departmentFilterData: state.equipment.equipmentFilterDepartment.departments,
  departmentFilterDataIsFetching:
    state.equipment.equipmentFilterDepartment.isFetching,
  departmentFilterDataError: state.equipment.equipmentFilterDepartment.error,
  equipmentAddIsFetching: state.serviceDesk.addEquipmentToContracts.isFetching,
  equipmentAddError: state.serviceDesk.addEquipmentToContracts.error,
  rowsPerPage: state.serviceDeskContracts.equipments.rowsPerPage,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showDialogLoader,
      hideDialogLoader,
      clearRequestDataOnCreation,
      fetchContractRequest,
      deleteContractEquipmentsRequest,
      fetchCount: fetchContractEquipmentsCountRequest,
      fetchFilterLocation: fetchEquipmentFilterLocationRequest,
      fetchFilterDepartment: fetchEquipmentFilterDepartmentRequest,
      fetchContractEquipmentsRequest,
      setContractsEquipmentRowsPerPage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractEquipmentTable),
)
