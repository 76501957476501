import React from 'react'

import FormDialog from '../../../../widgets/FormDialog'
import ContragentSelector from './ContragentSelector'

import i18next from '../../../../i18n/i18n'

const CreationDialog = ({
  open,
  onExit,
  onSubmit,
  organizationId,
  isCreating,
}) => {
  const [contragentId, setContragentId] = React.useState(null)
  function handleSubmit() {
    onSubmit(contragentId)
    setContragentId(null)
  }
  return (
    <FormDialog
      isLoading={isCreating}
      positiveDisabled={!contragentId}
      open={open}
      title={i18next.t('organizations_web_atoir:contragentAdding')}
      onSubmit={handleSubmit}
      onClose={onExit}
      onExit={onExit}
      submitButtonTitle={i18next.t('shared_web_atoir:add')}
      closeIconHidden
      secondaryButtonsArray={[
        {
          secondaryText: i18next.t('shared_web_atoir:cancel'),
          handleSecondaryAction: () => {
            onExit()
          },
        },
      ]}
      DialogContentStyle={{ minWidth: 370 }}
    >
      <ContragentSelector
        onChange={setContragentId}
        contragentId={contragentId}
        organizationId={organizationId}
      />
    </FormDialog>
  )
}

export default CreationDialog
