import { takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/tagsActions'
import * as constants from '../constants/actionTypes/tags'
import * as api from './api'
import { createDefaultSaga } from '../helpers/api'

export function* watchCreateTagSaga() {
  yield takeLatest(
    constants.CREATE_TAG_REQUEST,
    createDefaultSaga(
      payload => api.createTagRequestApi(payload),
      result => actions.createTagSuccess(result),
      code => actions.createTagFailure(code),
      true,
    ),
  )
}

export function* watchEditTagSaga() {
  yield takeLatest(
    constants.EDIT_TAG_REQUEST,
    createDefaultSaga(
      payload => api.editTagRequestApi(payload),
      result => actions.editTagSuccess(result),
      code => actions.editTagFailure(code),
      true,
    ),
  )
}

export function* watchGetTagsSaga() {
  yield takeLatest(
    constants.GET_TAGS_REQUEST,
    createDefaultSaga(
      payload => api.getTagsRequestApi(payload),
      result =>
        actions.getTagsSuccess(
          result.tags.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      code => actions.getTagsFailure(code),
      false,
    ),
  )
}

export function* watchDeleteTagSaga() {
  yield takeLatest(
    constants.DELETE_TAG_REQUEST,
    createDefaultSaga(
      payload => api.deleteTagRequestApi(payload),
      result => actions.deleteTagSuccess(result),
      code => actions.deleteTagFailure(code),
      false,
    ),
  )
}

export function* watchGetTagSaga() {
  yield takeLatest(
    constants.GET_TAG_REQUEST,
    createDefaultSaga(
      payload => api.getTagRequestApi(payload),
      result => actions.getTagSuccess(result.tag),
      code => actions.getTagFailure(code),
      true,
    ),
  )
}
