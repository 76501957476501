import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'
import i18next from '../../../../../i18n/i18n'
import isEmpty from 'is-empty'
import Grid from '@material-ui/core/Grid'

export default function({
  industry,
  classes,
  downloadMedCertificate,
  equipment,
}) {
  return (
    <Fragment>
      <Typography className={classes.sectionName}>
        {i18next.t('equipment_web_atoir:industry')} -{' '}
        {i18next.t(`equipment_web_atoir:${industry.EquipmentIndustry.name}`)}
      </Typography>
      {[
        {
          title: `${i18next.t('equipment_web_atoir:medCertNumber')}`,
          content: isEmpty(industry.med_registration_certificate_number)
            ? ''
            : industry.med_registration_certificate_number,
        },
        {
          title: `${i18next.t('equipment_web_atoir:riskCategory')}`,
          content: isEmpty(industry.med_level_risk)
            ? ''
            : industry.med_level_risk,
        },
        {
          title: `${i18next.t('equipment_web_atoir:medKODMO')}`,
          content: isEmpty(industry.med_kodmo) ? '' : industry.med_kodmo,
        },
        {
          title: `${i18next.t('equipment_web_atoir:medCert')}`,
          content: isEmpty(industry.med_registration_certificate_file) ? (
            ''
          ) : (
            <Typography
              className={classes.downloadText}
              onClick={() =>
                downloadMedCertificate({
                  equipmentId: equipment.id,
                  organizationId: equipment.organization_id,
                  id: industry.id,
                  name: `cert-${i18next.t(
                    `equipment_web_atoir:${industry.EquipmentIndustry.name}`,
                  )}.docx`,
                })
              }
            >
              {i18next.t('shared_web_atoir:download')}
            </Typography>
          ),
        },
      ]
        .filter(i => i.content)
        .map(i => (
          <Grid container className={classes.infoRoot}>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.title}>
                {i.title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" className="wordWrapBreakAll">
                {i.content}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Fragment>
  )
}
