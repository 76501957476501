import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AsPerformer from './AsPerformer'
import {
  fetchInventoryOffersPerformer,
  cancelOfferInventoryProject,
} from '../../../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  infoProject: state.inventory.project,
  isFetchOffers: state.inventory.fetchInventoryOffersPerformer.isFetchOffers,
  offers: state.inventory.fetchInventoryOffersPerformer.offers,
  isOffer: state.inventory.offerInventoryProject.isOffer,
  isOfferSuccess: state.inventory.offerInventoryProject.offerSuccessfully,
  isCancelOffer: state.inventory.cancelOfferInventoryProject.isCancel,
  isCancelOfferSuccess:
    state.inventory.cancelOfferInventoryProject.isCancelSuccessfully,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOffer: fetchInventoryOffersPerformer,
      cancelOffer: cancelOfferInventoryProject,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsPerformer),
)
