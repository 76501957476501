import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Downshift from 'downshift'

import InputAdornment from '@material-ui/core/InputAdornment'

import { getLocation, getSuggestions } from '../../helpers/locationHelper'
import { receivedErrorMessage } from '../../actions/appActions'
import Loader from '../../components/Loader'

// internationalization
import i18next from '../../i18n/i18n'

// material-ui
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles/index'

import { homeConcatHelper } from '../../helpers/parserHelper'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  dropDownForm: {
    marginTop: theme.spacing * 10,
  },
  error: {
    color: theme.palette.error.main,
  },
})

const addressCheckMissed = data => {
  let missed
  if (data.country)
    missed = i18next.t('organizations_web_atoir:enterCityStreetHouse')
  if (data.city || data.settlement)
    missed = i18next.t('organizations_web_atoir:enterStreetHouse')
  if (data.street) missed = i18next.t('organizations_web_atoir:enterHouse')
  if (!data.street && data.house)
    missed = i18next.t('validation_web_atoir:adressTypeDontSupportForNow')
  if (data.street && data.house) missed = undefined
  return missed
}

function renderInput(inputProps) {
  const { InputProps, classes, ref, isFetching, ...other } = inputProps
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: <EndAdornmentLoader show={isFetching} />,
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.value}
    </MenuItem>
  )
}

const EndAdornmentLoader = ({ show }) => (
  <InputAdornment position="end">{show && <Loader size={20} />}</InputAdornment>
)

renderSuggestion.propTypes = {
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

class AddressPicker extends React.Component {
  constructor(props) {
    super(props)
    let { locationData } = props
    const { defaultLocation } = props
    locationData = locationData || {}
    this.state = {
      menuIsOpen: false,
      isChanged: false,
      isFetching: false,
      selectedItem: defaultLocation
        ? {
            value: `${defaultLocation.Street.name_full} ${defaultLocation.home}`,
          }
        : '',
      suggestions: [],
      isFullAddress: undefined,
      locationType: props.phisycal
        ? 0
        : props.legal
        ? 1
        : locationData.location_type !== undefined
        ? locationData.location_type
        : 2,
      updateSuggestionsAllowed: true,
    }
  }

  componentDidMount = () => {
    console.log(this.state.isFetching)
    if (this.props.defaultLocation) {
      this.setState({ updateSuggestionsAllowed: false }, () => {
        this.fetchLocation()
      })
    }
  }

  updateSuggestions(text) {
    const { updateSuggestionsAllowed } = this.state
    if (updateSuggestionsAllowed) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.setState(
          {
            isFetching: true,
          },
          () => {
            getSuggestions(text).then(suggestions => {
              if (suggestions) {
                this.setState({
                  suggestions,
                  isFetching: false,
                })
              } else {
                this.props.receivedErrorMessage({
                  app: [i18next.t('error_web_atoir:canNotCallKALDRCheck')],
                })
                this.setState({
                  isFetching: false,
                })
              }
            })
          },
        )
      }, 500)
    }
  }

  fetchLocation = () => {
    const location = this.props.defaultLocation
    if (location && location.Street) {
      this.setState(
        {
          isFetching: true,
        },
        () => {
          getLocation(location.Street.street_fias_id).then(
            suggestionsStreet => {
              if (suggestionsStreet) {
                const street = suggestionsStreet[0]
                if (street) {
                  getSuggestions(`${street.value} ${location.home}`).then(
                    suggestionsFullAddress => {
                      if (suggestionsFullAddress) {
                        const addressFull = suggestionsFullAddress.find(
                          item =>
                            item.data.street_fias_id ===
                              location.Street.street_fias_id &&
                            homeConcatHelper(item.data).toLowerCase() ===
                              location.home.toLowerCase(),
                        )
                        if (addressFull) {
                          this.setState(
                            {
                              isFullAddress: true,
                              isFetching: false,
                              updateSuggestionsAllowed: true,
                            },
                            () => {
                              this.handleChange(addressFull)
                            },
                          )
                        } else {
                          this.setState({
                            selectedItem: '',
                            isFetching: false,
                            updateSuggestionsAllowed: true,
                          })
                          this.props.receivedErrorMessage({
                            app: [
                              i18next.t(
                                'error_web_atoir:dadataEquipmentHomeMissing',
                              ),
                            ],
                          })
                        }
                      } else {
                        this.setState({
                          isFetching: false,
                          updateSuggestionsAllowed: true,
                        })
                        this.props.receivedErrorMessage({
                          app: [
                            i18next.t('error_web_atoir:canNotCallKALDRCheck'),
                          ],
                        })
                      }
                    },
                  )
                } else {
                  this.setState({
                    selectedItem: '',
                    isFetching: false,
                    updateSuggestionsAllowed: true,
                  })
                  this.props.receivedErrorMessage({
                    app: [
                      i18next.t('error_web_atoir:dadataEquipmentStreetMissing'),
                    ],
                  })
                }
              } else {
                this.setState({
                  isFetching: false,
                  updateSuggestionsAllowed: true,
                })
                this.props.receivedErrorMessage({
                  app: [i18next.t('error_web_atoir:canNotCallKALDRCheck')],
                })
              }
            },
          )
        },
      )
    }
  }

  handleInputChange = text => {
    this.setState({
      isChanged: true,
      menuIsOpen: true,
    })
    this.updateSuggestions(text)
  }

  handleChange = selectedItem => {
    const isFullAddress =
      selectedItem && selectedItem.data
        ? Boolean(selectedItem.data.house && selectedItem.data.street_fias_id)
        : undefined
    const { isFetching } = this.state
    this.setState(
      {
        isChanged: !isFetching,
        isFetching: false,
        selectedItem,
        isFullAddress,
      },
      () => {
        this.updateParentData({
          location: selectedItem,
          isFullAddress,
          isChanged: this.state.isChanged,
        })
      },
    )
  }

  handleToggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  updateParentData(field) {
    const { selectedItem, isChanged } = this.state
    const isValid = this.validate(field)
    const location = selectedItem
    if (location.data.house) {
      location.home = homeConcatHelper(location.data)
      location.id = location.data.fias_id || location.data.street_fias_id
    }
    this.props.onChange({
      location,
      isValid,
      ...field,
      isChanged,
    })
  }

  validate = field => {
    const { isFullAddress } = { ...this.state, ...field }

    if (!isFullAddress) {
      return false
    }
    return true
  }

  overrideItemToString = item => (item ? item.value : '')

  render() {
    const { classes, label, ...otherProps } = this.props
    const { isFullAddress, isFetching } = this.state
    const locationErrorText = !isFullAddress
      ? this.state.selectedItem && this.state.selectedItem.data
        ? addressCheckMissed(this.state.selectedItem.data)
        : undefined
      : undefined
    return (
      <FormControl {...otherProps}>
        <Downshift
          onChange={this.handleChange}
          selectedItem={this.state.selectedItem}
          itemToString={this.overrideItemToString}
          onInputValueChange={this.handleInputChange}
          onOuterClick={() => {
            this.setState({ menuIsOpen: false })
          }}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            selectedItem,
            highlightedIndex,
          }) => (
            <div className={classes.container}>
              {renderInput({
                required: true,
                fullWidth: true,
                classes,
                label,
                error: this.state.isFullAddress === false,
                InputProps: getInputProps({
                  placeholder: i18next.t(
                    'organizations_web_atoir:enterAddressInOneLine',
                  ),
                  id: 'downshift-AddressPicker',
                  endAdornment: <EndAdornmentLoader show={isFetching} />,
                }),
              })}
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {this.state.suggestions.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
        <Typography variant="body1" className={classes.error}>
          {isFullAddress === false
            ? locationErrorText ||
              i18next.t('validation_web_atoir:incompleteAddress')
            : ''}
        </Typography>
      </FormControl>
    )
  }
}

AddressPicker.defaultProps = {
  defaultLocation: null,
  fullWidth: true,
}

AddressPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultLocation: PropTypes.object,
  fullWidth: PropTypes.bool,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      receivedErrorMessage,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AddressPicker))
