import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import { dateFormat } from '../../../helpers/dateHelper'
import { organizationLocationStringifyFull } from '../../../helpers/parserHelper'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import Loader from '../../Loader'
import SectionProfileList from '../../../widgets/SectionProfileList'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { addMaskCharacters } from '../../../helpers/phoneStringHelper'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  table: {
    minWidth: 700,
  },
  cellName: {
    textAlign: 'right',
  },
  cellValue: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'left',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  dividerMargin: {
    margin: theme.spacing(1),
  },
})

class EquipmentInformationComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isFetchingEquipmentDetails: this.props.isFetchingEquipmentDetails,
      equipment: this.props.equipment,
    }
  }

  render() {
    const {
      state: { isFetchingEquipmentDetails, equipment },
    } = this

    return isFetchingEquipmentDetails || isEmpty(equipment) ? (
      <Loader />
    ) : (
      <Fragment>
        <SectionProfileList
          list={[
            {
              title: `${i18next.t('equipment_web_atoir:vendor')}`,
              content: isEmpty(equipment.vendor) ? '' : equipment.vendor,
            },
            {
              title: `${i18next.t('equipment_web_atoir:country')}`,
              content: isEmpty(equipment.Country)
                ? ''
                : equipment.Country.full_name,
            },
            {
              title: `${i18next.t('equipment_web_atoir:yearIssue')}`,
              content: isEmpty(equipment.year_issue)
                ? ''
                : new Date(equipment.year_issue).getFullYear(),
            },
            {
              title: `${i18next.t('equipment_web_atoir:dateInputWork')}`,
              content: isEmpty(equipment.date_input_work)
                ? ''
                : dateFormat(equipment.date_input_work),
            },
            {
              title: `${i18next.t('equipment_web_atoir:dateExpiredGuarantee')}`,
              content: isEmpty(equipment.date_expired_guarantee)
                ? ''
                : dateFormat(equipment.date_expired_guarantee),
            },
            {
              title: `${i18next.t('equipment_web_atoir:portableEquipment')}`,
              content: equipment.portable
                ? i18next.t('shared_web_atoir:yes')
                : i18next.t('shared_web_atoir:no'),
            },
          ]}
        />
        <SectionProfileList
          list={[
            {
              title: `${i18next.t('equipment_web_atoir:serialNumber')}`,
              content: isEmpty(equipment.serial_number)
                ? ''
                : equipment.serial_number,
              ellipsis: true,
            },
            {
              title: `${i18next.t('equipment_web_atoir:articleNumber')}`,
              content: isEmpty(equipment.article_number)
                ? ''
                : equipment.article_number,
              ellipsis: true,
            },
            {
              title: `${i18next.t('equipment_web_atoir:externalId')}`,
              content: isEmpty(equipment.external_id)
                ? ''
                : equipment.external_id,
              ellipsis: true,
            },
            {
              title: `${i18next.t('organizations_web_atoir:address')}`,
              content: isEmpty(equipment.OrganizationLocation)
                ? ''
                : organizationLocationStringifyFull(
                    equipment.OrganizationLocation,
                  ),
            },
            {
              title: `${i18next.t('organizations_web_atoir:department')}`,
              content: isEmpty(equipment.OrganizationDepartment)
                ? ''
                : equipment.OrganizationDepartment.name,
            },
            {
              title: `${i18next.t('organizations_web_atoir:mol')}`,
              content: isEmpty(equipment.OrganizationUser)
                ? ''
                : `${equipment.OrganizationUser.last_name} ${
                    equipment.OrganizationUser.first_name
                  } ${equipment.OrganizationUser.middle_name || ''} ${
                    equipment.OrganizationUser.phone
                      ? addMaskCharacters(equipment.OrganizationUser.phone)
                      : ''
                  }`,
            },
          ]}
          notDivider
        />
      </Fragment>
    )
  }
}

EquipmentInformationComponent.propTypes = {
  isFetchingEquipmentDetails: PropTypes.bool.isRequired,
  equipment: PropTypes.object.isRequired,
}

export default withStyles(styles)(EquipmentInformationComponent)
