import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreateEditEmailTemplate from './CreateEditEmailTemplate'
import {
  setNavigationDrawer,
  clearNavigationDrawer,
} from '../../../../../actions/appActions'
import { fetchOrganization } from '../../../../../actions/organizationsActions'
import {
  getEmailTemplateRequest,
  createEmailTemplateRequest,
  editEmailTemplateRequest,
} from '../../../../../actions/emailTemplatesActions'

const mapStateToProps = state => ({
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
  isFetchingTemplate: state.emailTemplates.getTemplate.isFetching,
  template: state.emailTemplates.getTemplate.template,
  isCreatingTemplate: state.emailTemplates.createTemplate.isFetching,
  createTemplateError: state.emailTemplates.createTemplate.error,
  isEditingTemplate: state.emailTemplates.editTemplate.isFetching,
  editTemplateError: state.emailTemplates.editTemplate.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNavigationDrawer,
      clearNavigationDrawer,
      fetchOrganization,
      getEmailTemplateRequest,
      createEmailTemplateRequest,
      editEmailTemplateRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateEditEmailTemplate),
)
