import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function withContinuousLoad(
  WrappedComponent,
  mapStateToProps,
  mapDispatchToProps,
  propNameData = 'data',
  propNameLoad = 'getData',
  startOffset = 0,
  chunkSize = 10,
  maxFirstDataCount,
) {
  const WithContinuousLoad = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class WithContinuousLoadHOCClass extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          offset: startOffset,
          limit: chunkSize,
          dataEnd: false,
          dataAccumulated: [],
        }
      }

      // поддержка изменяемго размера чанка загружаемых данных для самой первой загрузки
      getCurrLimit = () => {
        const {
          state: { dataAccumulated },
        } = this
        let limit = 0
        if (dataAccumulated.length > 0) {
          limit = chunkSize
        } else {
          limit = this.props.maxFirstDataCount || maxFirstDataCount
        }
        console.log('limit', limit, dataAccumulated)
        return limit
      }

      loadData = payload => {
        const {
          props: { fetchAction },
          state: { offset },
        } = this
        fetchAction({ ...payload, offset, limit: this.getCurrLimit() + 1 })
      }

      componentDidUpdate = prevProps => {
        const {
          props: { fetching, data, error },
          state: { dataAccumulated, offset },
          getCurrLimit,
        } = this

        if (fetching !== prevProps.fetching && !fetching) {
          const newState = {}
          if (error || data.length <= getCurrLimit()) {
            newState.dataEnd = true
          }
          if (data.length > 0) {
            const newData =
              data.length > getCurrLimit() ? data.slice(0, -1) : data
            newState.dataAccumulated = dataAccumulated.concat(newData)
            newState.offset = offset + getCurrLimit()
          }
          this.setState(newState)
        }
      }

      render() {
        const {
          state: { dataAccumulated, dataEnd },
        } = this
        return (
          <WrappedComponent
            {...this.props}
            {...{
              [propNameLoad]: this.loadData,
              [propNameData]: dataAccumulated,
              [`${propNameData}End`]: dataEnd,
            }}
          />
        )
      }
    },
  )

  WithContinuousLoad.displayName = `WithContinuousLoad(${getDisplayName(
    WrappedComponent,
  )})`

  WithContinuousLoad.propTypes = {
    data: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
    fetchAction: PropTypes.func.isRequired,
  }

  return WithContinuousLoad
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default function wrapper(
  mapStateToProps,
  mapDispatchToProps,
  propNameData,
  propNameLoad,
  startOffset,
  chunkSize,
  maxFirstDataCount,
) {
  return component =>
    withContinuousLoad(
      component,
      mapStateToProps,
      mapDispatchToProps,
      propNameData,
      propNameLoad,
      startOffset,
      chunkSize,
      maxFirstDataCount,
    )
}
