import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InventoryListEngineersInWork from './InventoryListEngineersInWork'
import { fetchBoundOrganizationUsers } from '../../../../../actions/staffActions'
import {
  fetchEngineersInventory,
  removeEngineerInventory,
} from '../../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  engineers: state.inventory.engineers.engineers,
  isFetchEngineers: state.inventory.engineers.isFetch,
  inventoryProject: state.inventory.project,
  isRemoveEngineer: state.inventory.engineersRemove.isRemove,
  isRemoveEngineerSuccess: state.inventory.engineersRemove.isRemoveSuccess,
  isEditEngineers: state.inventory.engineersEdit.isEdit,
  isEditEngineersSuccess: state.inventory.engineersEdit.isEditSuccess,
  inventoryProjectPermissions:
    state.inventory.fetchInventoryProjectRights.inventoryProjectPermissions,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBoundOrganizationUsers,
      fetchEngineersInventory,
      removeEngineerInventory,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryListEngineersInWork),
)
