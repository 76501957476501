import {
  REPAIR_PASSWORD_REQUEST,
  REPAIR_PASSWORD_SUCCESS,
  REPAIR_PASSWORD_FAILURE,
  CONFIRM_REPAIR_PASSWORD_REQUEST,
  CONFIRM_REPAIR_PASSWORD_SUCCESS,
  CONFIRM_REPAIR_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  REPAIR_PASSWORD_CANCEL,
  CLEAR_REPAIR_PASSWORD_ERRORS,
} from '../constants/actionTypes'

export const repairPassword = email => ({
  type: REPAIR_PASSWORD_REQUEST,
  email,
})

export const repairPasswordSuccess = () => ({
  type: REPAIR_PASSWORD_SUCCESS,
})
export const repairPasswordCancel = () => ({
  type: REPAIR_PASSWORD_CANCEL,
})

export const repairPasswordFailure = (errors, shouldResendLink) => ({
  type: REPAIR_PASSWORD_FAILURE,
  errors,
  shouldResendLink,
})

export const clearRepairPasswordErrors = () => ({
  type: CLEAR_REPAIR_PASSWORD_ERRORS,
})

export const confirmRepairPassword = () => ({
  type: CONFIRM_REPAIR_PASSWORD_REQUEST,
})

export const confirmRepairPasswordSuccess = () => ({
  type: CONFIRM_REPAIR_PASSWORD_SUCCESS,
})

export const confirmRepairPasswordFailure = (errors, code) => ({
  type: CONFIRM_REPAIR_PASSWORD_FAILURE,
  errors,
  code,
})

export const setNewPassword = data => ({
  type: SET_NEW_PASSWORD_REQUEST,
  data,
})

export const setNewPasswordSuccess = () => ({
  type: SET_NEW_PASSWORD_SUCCESS,
})

export const setNewPasswordFailure = errors => ({
  type: SET_NEW_PASSWORD_FAILURE,
  errors,
})
