import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { organizationLocationStringify } from '../../helpers/parserHelper'
import i18next from '../../i18n/i18n'
import DropDownList from '../DropDownList'

const style = {
  formControl: {
    marginBottom: 0,
  },
}

class LocationPickerComponent extends Component {
  state = {
    isLocationsOpen: false,
  }

  componentDidMount = () => {
    this.props.fetchLocations(this.props.organizationId)
  }

  componentDidUpdate = prevProps => {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.props.fetchLocations(this.props.organizationId)
    }
  }

  handleToggle = (field, state) => () => {
    this.setState({ [field]: state })
  }

  handleChange = field => event => {
    this.setState({ [field]: event.target.value })
  }

  render() {
    const {
      state: { isLocationsOpen },
      props: {
        onChange,
        classes,
        locations,
        organizationLocation: location,
        isFetchingLocations,
        allOptionDisable,
      },
    } = this
    const elements = (allOptionDisable
      ? []
      : [
          {
            id: 'allAddr',
            title: i18next.t(
              'service_desk_web_atoir:requestCreationAllAddresses',
            ),
          },
        ]
    ).concat(
      locations
        ? locations.map(l => ({
            id: l.id,
            title: organizationLocationStringify(l),
          }))
        : [],
    )

    return (
      <DropDownList
        showLabel
        label={i18next.t('equipment_web_atoir:placement')}
        classes={classes}
        open={isLocationsOpen}
        noEmpty
        value={location}
        elements={elements}
        isFetching={isFetchingLocations}
        handleOpen={this.handleToggle('isLocationsOpen', true)}
        handleClose={this.handleToggle('isLocationsOpen', false)}
        handleChange={onChange}
      />
    )
  }
}

LocationPickerComponent.defaultProps = {
  organizationLocation: '',
  allOptionDisable: false,
}

LocationPickerComponent.propTypes = {
  organizationLocation: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  allOptionDisable: PropTypes.bool,
}

export default withStyles(style)(LocationPickerComponent)
