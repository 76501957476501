const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'

export function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

// app
export const ERROR_MESSAGE_RECEIVED = 'ERROR_MESSAGE_RECEIVED'
export const INFO_MESSAGE_RECEIVED = 'INFO_MESSAGE_RECEIVED'
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'
export const CATCH_ERROR = 'CATCH_ERROR'
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE'
export const REDIRECT_USER_TO_HOME_PAGE = 'REDIRECT_USER_TO_HOME_PAGE'
export const REDIRECTED_TO_HOME_PAGE = 'REDIRECTED_TO_HOME_PAGE'

export const SHOW_DIALOG_WITH_OK = 'SHOW_DIALOG_WITH_OK'
export const CLOSE_DIALOG_WITH_OK = 'CLOSE_DIALOG_WITH_OK'

// account registration
export const REGISTER_EMAIL_REQUEST = 'REGISTER_EMAIL_REQUEST'
export const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS'
export const REGISTER_EMAIL_FAILURE = 'REGISTER_EMAIL_FAILURE'

export const CLEAR_REGISTER_VALIDATION_ERRORS =
  'CLEAR_REGISTER_VALIDATION_ERRORS'

export const GET_CONFIRM_LINK_AGAIN_REQUEST = 'GET_CONFIRM_LINK_AGAIN_REQUEST'
export const GET_CONFIRM_LINK_AGAIN_SUCCESS = 'GET_CONFIRM_LINK_AGAIN_SUCCESS'
export const GET_CONFIRM_LINK_AGAIN_FAILURE = 'GET_CONFIRM_LINK_AGAIN_FAILURE'

export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE'
export const CONFIRM_EMAIL_FAILURE_CODES = 'CONFIRM_EMAIL_FAILURE_CODES'

export const REGISTER_ACCOUNT_REQUEST = 'REGISTER_ACCOUNT_REQUEST'
export const REGISTER_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS'
export const REGISTER_ACCOUNT_FAILURE = 'REGISTER_ACCOUNT_FAILURE'

// login
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const REFRESH_TOKENS_REQUEST = 'REFRESH_TOKENS_REQUEST'
export const REFRESH_TOKENS_SUCCESS = 'REFRESH_TOKENS_SUCCESS'
export const REFRESH_TOKENS_FAILURE = 'REFRESH_TOKENS_FAILURE'

export const REPAIR_PASSWORD_REQUEST = 'REPAIR_PASSWORD_REQUEST'
export const REPAIR_PASSWORD_SUCCESS = 'REPAIR_PASSWORD_SUCCESS'
export const REPAIR_PASSWORD_FAILURE = 'REPAIR_PASSWORD_FAILURE'
export const REPAIR_PASSWORD_CANCEL = 'REPAIR_PASSWORD_CANCEL'

export const CLEAR_REPAIR_PASSWORD_ERRORS = 'CLEAR_REPAIR_PASSWORD_ERRORS'

export const CONFIRM_REPAIR_PASSWORD_REQUEST = 'CONFIRM_REPAIR_PASSWORD_REQUEST'
export const CONFIRM_REPAIR_PASSWORD_SUCCESS = 'CONFIRM_REPAIR_PASSWORD_SUCCESS'
export const CONFIRM_REPAIR_PASSWORD_FAILURE = 'CONFIRM_REPAIR_PASSWORD_FAILURE'

export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST'
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS'
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

// user
export const FETCH_USER_REQUEST = 'USER_INFO_REQUEST'
export const FETCH_USER_SUCCESS = 'USER_INFO_SUCCESS'
export const FETCH_USER_FAILURE = 'USER_INFO_FAILURE'

export const FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST'

export const UPLOAD_USER_IMAGE_REQUEST = 'UPLOAD_USER_IMAGE_REQUEST'
export const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS'
export const UPLOAD_USER_IMAGE_FAILURE = 'UPLOAD_USER_IMAGE_FAILURE'

export const UPDATE_USER_AVATAR_REQUEST = 'UPDATE_USER_AVATAR_REQUEST'
export const UPDATE_USER_AVATAR_SUCCESS = 'UPDATE_USER_AVATAR_SUCCESS'
export const UPDATE_USER_AVATAR_FAILURE = 'UPDATE_USER_AVATAR_FAILURE'

export const REMOVE_USER_AVATAR_REQUEST = 'REMOVE_USER_AVATAR_REQUEST'
export const REMOVE_USER_AVATAR_SUCCESS = 'REMOVE_USER_AVATAR_SUCCESS'
export const REMOVE_USER_AVATAR_FAILURE = 'REMOVE_USER_AVATAR_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const CLEAR_VALIDATION_ERRORS = 'CLEAR_VALIDATION_ERRORS'

export const FETCH_USER_NOTIFICATION_SETTINGS =
  'user/FETCH_USER_NOTIFICATION_SETTINGS'
export const FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS =
  'user/FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS'
export const FETCH_USER_NOTIFICATION_SETTINGS_FAILURE =
  'user/FETCH_USER_NOTIFICATION_SETTINGS_FAILURE'

export const SET_USER_NOTIFICATION_SETTING =
  'user/SET_USER_NOTIFICATION_SETTING'
export const SET_USER_NOTIFICATION_SETTING_SUCCESS =
  'user/SET_USER_NOTIFICATION_SETTING_SUCCESS'
export const SET_USER_NOTIFICATION_SETTING_FAILURE =
  'user/SET_USER_NOTIFICATION_SETTING_FAILURE'

// organizations
export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE'

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE'

export const FETCH_USER_ORGANIZATIONS_REQUEST =
  'FETCH_USER_ORGANIZATIONS_REQUEST'
export const FETCH_USER_ORGANIZATIONS_SUCCESS =
  'FETCH_USER_ORGANIZATIONS_SUCCESS'
export const FETCH_USER_ORGANIZATIONS_FAILURE =
  'FETCH_USER_ORGANIZATIONS_FAILURE'

export const FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST =
  'FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST'
export const FETCH_USER_ORGANIZATIONS_CAN_ADD_SUCCESS =
  'FETCH_USER_ORGANIZATIONS_CAN_ADD_SUCCESS'
export const FETCH_USER_ORGANIZATIONS_CAN_ADD_FAILURE =
  'FETCH_USER_ORGANIZATIONS_CAN_ADD_FAILURE'

export const FETCH_USER_ORGANIZATIONS_COUNT_REQUEST =
  'FETCH_USER_ORGANIZATIONS_COUNT_REQUEST'
export const FETCH_USER_ORGANIZATIONS_COUNT_SUCCESS =
  'FETCH_USER_ORGANIZATIONS_COUNT_SUCCESS'
export const FETCH_USER_ORGANIZATIONS_COUNT_FAILURE =
  'FETCH_USER_ORGANIZATIONS_COUNT_FAILURE'

export const FETCH_NOT_USER_ORGANIZATIONS_REQUEST =
  'FETCH_NOT_USER_ORGANIZATIONS_REQUEST'
export const FETCH_NOT_USER_ORGANIZATIONS_SUCCESS =
  'FETCH_NOT_USER_ORGANIZATIONS_SUCCESS'
export const FETCH_NOT_USER_ORGANIZATIONS_FAILURE =
  'FETCH_NOT_USER_ORGANIZATIONS_FAILURE'

export const FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST =
  'FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST'
export const FETCH_ORGANIZATIONS_MEMBER_OF_SUCCESS =
  'FETCH_ORGANIZATIONS_MEMBER_OF_SUCCESS'
export const FETCH_ORGANIZATIONS_MEMBER_OF_FAILURE =
  'FETCH_ORGANIZATIONS_MEMBER_OF_FAILURE'

export const FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST'
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS'
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE'

export const FETCH_ORGANIZATION_WHO_AM_I_REQUEST =
  'FETCH_ORGANIZATION_WHO_AM_I_REQUEST'
export const FETCH_ORGANIZATION_WHO_AM_I_SUCCESS =
  'FETCH_ORGANIZATION_WHO_AM_I_SUCCESS'
export const FETCH_ORGANIZATION_WHO_AM_I_FAILURE =
  'FETCH_ORGANIZATION_WHO_AM_I_FAILURE'

export const FETCH_ORGANIZATION_USER_ME_REQUEST =
  'FETCH_ORGANIZATION_USER_ME_REQUEST'
export const FETCH_ORGANIZATION_USER_ME_SUCCESS =
  'FETCH_ORGANIZATION_USER_ME_SUCCESS'
export const FETCH_ORGANIZATION_USER_ME_FAILURE =
  'FETCH_ORGANIZATION_USER_ME_FAILURE'

export const FETCH_ADMIN_ORGANIZATIONS_REQUEST =
  'FETCH_ADMIN_ORGANIZATIONS_REQUEST'
export const FETCH_ADMIN_ORGANIZATIONS_SUCCESS =
  'FETCH_ADMIN_ORGANIZATIONS_SUCCESS'
export const FETCH_ADMIN_ORGANIZATIONS_FAILURE =
  'FETCH_ADMIN_ORGANIZATIONS_FAILURE'

export const FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST =
  'FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST'
export const FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS =
  'FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS'
export const FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE =
  'FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE'

export const FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST =
  'FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST'
export const FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS =
  'FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS'
export const FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE =
  'FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE'

export const FETCH_ORGANIZATION_PERMISSIONS_REQUEST =
  'FETCH_ORGANIZATION_PERMISSIONS_REQUEST'
export const FETCH_ORGANIZATION_PERMISSIONS_SUCCESS =
  'FETCH_ORGANIZATION_PERMISSIONS_SUCCESS'
export const FETCH_ORGANIZATION_PERMISSIONS_FAILURE =
  'FETCH_ORGANIZATION_PERMISSIONS_FAILURE'

export const FETCH_EMPLOYERS_REQUEST = 'FETCH_EMPLOYERS_REQUEST'
export const FETCH_EMPLOYERS_SUCCESS = 'FETCH_EMPLOYERS_SUCCESS'
export const FETCH_EMPLOYERS_FAILURE = 'FETCH_EMPLOYERS_FAILURE'

export const FETCH_EMPLOYERS_COUNT_REQUEST = 'FETCH_EMPLOYERS_COUNT_REQUEST'
export const FETCH_EMPLOYERS_COUNT_SUCCESS = 'FETCH_EMPLOYERS_COUNT_SUCCESS'
export const FETCH_EMPLOYERS_COUNT_FAILURE = 'FETCH_EMPLOYERS_COUNT_FAILURE'

export const FETCH_ALL_ORGANIZATIONS_REQUEST = 'FETCH_ALL_ORGANIZATIONS_REQUEST'
export const FETCH_ALL_ORGANIZATIONS_SUCCESS = 'FETCH_ALL_ORGANIZATIONS_SUCCESS'
export const FETCH_ALL_ORGANIZATIONS_FAILURE = 'FETCH_ALL_ORGANIZATIONS_FAILURE'

export const FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST =
  'FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST'
export const FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_SUCCESS =
  'FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_SUCCESS'
export const FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_FAILURE =
  'FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_FAILURE'

export const FETCH_SERVICE_ORGANIZATIONS_REQUEST =
  'FETCH_SERVICE_ORGANIZATIONS_REQUEST'
export const FETCH_SERVICE_ORGANIZATIONS_SUCCESS =
  'FETCH_SERVICE_ORGANIZATIONS_SUCCESS'
export const FETCH_SERVICE_ORGANIZATIONS_FAILURE =
  'FETCH_SERVICE_ORGANIZATIONS_FAILURE'

export const FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST =
  'FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST'
export const FETCH_SERVICE_ORGANIZATIONS_COUNT_SUCCESS =
  'FETCH_SERVICE_ORGANIZATIONS_COUNT_SUCCESS'
export const FETCH_SERVICE_ORGANIZATIONS_COUNT_FAILURE =
  'FETCH_SERVICE_ORGANIZATIONS_COUNT_FAILURE'

export const SET_ORGANIZATIONS_LIST_ROWS_PER_PAGE =
  'SET_ORGANIZATIONS_LIST_ROWS_PER_PAGE'
export const SET_ORGANIZATIONS_EMPLOYERS_LIST_ROWS_PER_PAGE =
  'SET_ORGANIZATIONS_EMPLOYERS_LIST_ROWS_PER_PAGE'

// locations
export const FETCH_PHYSICAL_LOCATION_REQUEST = 'FETCH_PHYSICAL_LOCATION_REQUEST'
export const FETCH_PHYSICAL_LOCATION_SUCCESS = 'FETCH_PHYSICAL_LOCATION_SUCCESS'
export const FETCH_PHYSICAL_LOCATION_FAILURE = 'FETCH_PHYSICAL_LOCATION_FAILURE'

export const FETCH_LEGAL_LOCATION_REQUEST = 'FETCH_LEGAL_LOCATION_REQUEST'
export const FETCH_LEGAL_LOCATION_SUCCESS = 'FETCH_LEGAL_LOCATION_SUCCESS'
export const FETCH_LEGAL_LOCATION_FAILURE = 'FETCH_LEGAL_LOCATION_FAILURE'

export const FETCH_LOCATION_REQUEST = 'FETCH_LOCATION_REQUEST'
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS'
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATION_FAILURE'

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE'

export const FETCH_LOCATIONS_COUNT_REQUEST = 'FETCH_LOCATIONS_COUNT_REQUEST'
export const FETCH_LOCATIONS_COUNT_SUCCESS = 'FETCH_LOCATIONS_COUNT_SUCCESS'
export const FETCH_LOCATIONS_COUNT_FAILURE = 'FETCH_LOCATIONS_COUNT_FAILURE'

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE'

export const CREATE_LOCATION_REQUEST = 'CREATE_LOCATION_REQUEST'
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS'
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE'

export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE'

export const UPDATE_LOCATION_IN_LOCATIONS = 'UPDATE_LOCATION_IN_LOCATIONS'

// departments
export const FETCH_DEPARTMENT_REQUEST = 'FETCH_DEPARTMENT_REQUEST'
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS'
export const FETCH_DEPARTMENT_FAILURE = 'FETCH_DEPARTMENT_FAILURE'

export const FETCH_DEPARTMENTS_REQUEST = 'FETCH_DEPARTMENTS_REQUEST'
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS'
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE'

export const FETCH_DEPARTMENTS_COUNT_REQUEST = 'FETCH_DEPARTMENTS_COUNT_REQUEST'
export const FETCH_DEPARTMENTS_COUNT_SUCCESS = 'FETCH_DEPARTMENTS_COUNT_SUCCESS'
export const FETCH_DEPARTMENTS_COUNT_FAILURE = 'FETCH_DEPARTMENTS_COUNT_FAILURE'

export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST'
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS'
export const UPDATE_DEPARTMENT_FAILURE = 'UPDATE_DEPARTMENT_FAILURE'

export const CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST'
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS'
export const CREATE_DEPARTMENT_FAILURE = 'CREATE_DEPARTMENT_FAILURE'

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST'
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS'
export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE'

export const LEAVE_ORGANIZATION_REQUEST = 'LEAVE_ORGANIZATION_REQUEST'
export const LEAVE_ORGANIZATION_SUCCESS = 'LEAVE_ORGANIZATION_SUCCESS'
export const LEAVE_ORGANIZATION_FAILURE = 'LEAVE_ORGANIZATION_FAILURE'

export const CONFIRM_JOIN_ORGANIZATION_REQUEST =
  'CONFIRM_JOIN_ORGANIZATION_REQUEST'
export const CONFIRM_JOIN_ORGANIZATION_SUCCESS =
  'CONFIRM_JOIN_ORGANIZATION_SUCCESS'
export const CONFIRM_JOIN_ORGANIZATION_FAILURE =
  'CONFIRM_JOIN_ORGANIZATION_FAILURE'

// staff
export const FETCH_STAFF_REQUEST = 'FETCH_STAFF_REQUEST'
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS'
export const FETCH_STAFF_FAILURE = 'FETCH_STAFF_FAILURE'

export const FETCH_USER_CONTACTS_REQUEST = 'FETCH_USER_CONTACTS_REQUEST'
export const FETCH_USER_CONTACTS_SUCCESS = 'FETCH_USER_CONTACTS_SUCCESS'
export const FETCH_USER_CONTACTS_FAILURE = 'FETCH_USER_CONTACTS_FAILURE'

export const FETCH_USER_CONTACTS_COUNT_REQUEST =
  'FETCH_USER_CONTACTS_COUNT_REQUEST'
export const FETCH_USER_CONTACTS_COUNT_SUCCESS =
  'FETCH_USER_CONTACTS_COUNT_SUCCESS'
export const FETCH_USER_CONTACTS_COUNT_FAILURE =
  'FETCH_USER_CONTACTS_COUNT_FAILURE'

export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST'
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS'
export const DELETE_STAFF_FAILURE = 'DELETE_STAFF_FAILURE'

export const FETCH_ORGANIZATION_STAFF_REQUEST =
  'FETCH_ORGANIZATION_STAFF_REQUEST'
export const FETCH_ORGANIZATION_STAFF_SUCCESS =
  'FETCH_ORGANIZATION_STAFF_SUCCESS'
export const FETCH_ORGANIZATION_STAFF_FAILURE =
  'FETCH_ORGANIZATION_STAFF_FAILURE'

export const FETCH_ORGANIZATION_STAFF_COUNT_REQUEST =
  'FETCH_ORGANIZATION_STAFF_COUNT_REQUEST'
export const FETCH_ORGANIZATION_STAFF_COUNT_SUCCESS =
  'FETCH_ORGANIZATION_STAFF_COUNT_SUCCESS'
export const FETCH_ORGANIZATION_STAFF_COUNT_FAILURE =
  'FETCH_ORGANIZATION_STAFF_COUNT_FAILURE'

export const FETCH_ORGANIZATION_LOCATIONS_REQUEST =
  'FETCH_ORGANIZATION_LOCATIONS_REQUEST'
export const FETCH_ORGANIZATION_LOCATIONS_SUCCESS =
  'FETCH_ORGANIZATION_LOCATIONS_SUCCESS'
export const FETCH_ORGANIZATION_LOCATIONS_FAILURE =
  'FETCH_ORGANIZATION_LOCATIONS_FAILURE'

export const ADD_ORGANIZATION_USER_REQUEST = 'ADD_ORGANIZATION_USER_REQUEST'
export const ADD_ORGANIZATION_USER_SUCCESS = 'ADD_ORGANIZATION_USER_SUCCESS'
export const ADD_ORGANIZATION_USER_FAILURE = 'ADD_ORGANIZATION_USER_FAILURE'

export const BIND_ORGANIZATION_USER_ACCOUNT_REQUEST =
  'BIND_ORGANIZATION_USER_ACCOUNT_REQUEST'
export const BIND_ORGANIZATION_USER_ACCOUNT_SUCCESS =
  'BIND_ORGANIZATION_USER_ACCOUNT_SUCCESS'
export const BIND_ORGANIZATION_USER_ACCOUNT_FAILURE =
  'BIND_ORGANIZATION_USER_ACCOUNT_FAILURE'

export const UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST =
  'UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST'
export const UNBIND_ORGANIZATION_USER_ACCOUNT_SUCCESS =
  'UNBIND_ORGANIZATION_USER_ACCOUNT_SUCCESS'
export const UNBIND_ORGANIZATION_USER_ACCOUNT_FAILURE =
  'UNBIND_ORGANIZATION_USER_ACCOUNT_FAILURE'

export const START_CREATE_UPDATE_ORGANIZATION_USER =
  'START_CREATE_UPDATE_ORGANIZATION_USER'
export const COMPLETE_CREATE_UPDATE_ORGANIZATION_USER =
  'COMPLETE_CREATE_UPDATE_ORGANIZATION_USER'

export const UPDATE_ORGANIZATION_USER_REQUEST =
  'UPDATE_ORGANIZATION_USER_REQUEST'
export const UPDATE_ORGANIZATION_USER_SUCCESS =
  'UPDATE_ORGANIZATION_USER_SUCCESS'
export const UPDATE_ORGANIZATION_USER_FAILURE =
  'UPDATE_ORGANIZATION_USER_FAILURE'

export const DELETE_ORGANIZATION_USER_REQUEST =
  'DELETE_ORGANIZATION_USER_REQUEST'
export const DELETE_ORGANIZATION_USER_SUCCESS =
  'DELETE_ORGANIZATION_USER_SUCCESS'
export const DELETE_ORGANIZATION_USER_FAILURE =
  'DELETE_ORGANIZATION_USER_FAILURE'

export const FETCH_BOUND_ORGANIZATION_USERS_REQUEST =
  'FETCH_BOUND_ORGANIZATION_USERS_REQUEST'
export const FETCH_BOUND_ORGANIZATION_USERS_SUCCESS =
  'FETCH_BOUND_ORGANIZATION_USERS_SUCCESS'
export const FETCH_BOUND_ORGANIZATION_USERS_FAILURE =
  'FETCH_BOUND_ORGANIZATION_USERS_FAILURE'

export const SET_STAFF_LIST_ROWS_PER_PAGE = 'SET_STAFF_LIST_ROWS_PER_PAGE'

// organization contacts
export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST'
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE'

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE'

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE'

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE'

// organization equipment
export const DOWNLOAD_MED_CERT = 'DOWNLOAD_MED_CERT'

export const FETCH_ORGANIZATION_EQUIPMENT_REQUEST =
  'FETCH_ORGANIZATION_EQUIPMENT_REQUEST'
export const FETCH_ORGANIZATION_EQUIPMENT_SUCCESS =
  'FETCH_ORGANIZATION_EQUIPMENT_SUCCESS'
export const FETCH_ORGANIZATION_EQUIPMENT_FAILURE =
  'FETCH_ORGANIZATION_EQUIPMENT_FAILURE'

export const CLEAR_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST =
  'CLEAR_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST'
export const FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST =
  'FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST'
export const FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_SUCCESS =
  'FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_SUCCESS'
export const FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_FAILURE =
  'FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_FAILURE'

// organization equipment binded
export const FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST =
  'FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST'
export const FETCH_ORGANIZATION_BINDED_EQUIPMENT_SUCCESS =
  'FETCH_ORGANIZATION_BINDED_EQUIPMENT_SUCCESS'
export const FETCH_ORGANIZATION_BINDED_EQUIPMENT_FAILURE =
  'FETCH_ORGANIZATION_BINDED_EQUIPMENT_FAILURE'

// organization vendors
export const FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST =
  'FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST'
export const FETCH_ORGANIZATION_EQUIPMENT_COUNT_SUCCESS =
  'FETCH_ORGANIZATION_EQUIPMENT_COUNT_SUCCESS'
export const FETCH_ORGANIZATION_EQUIPMENT_COUNT_FAILURE =
  'FETCH_ORGANIZATION_EQUIPMENT_COUNT_FAILURE'

export const FETCH_USER_EQUIPMENT_COUNT_REQUEST =
  'FETCH_USER_EQUIPMENT_COUNT_REQUEST'
export const FETCH_USER_EQUIPMENT_COUNT_SUCCESS =
  'FETCH_USER_EQUIPMENT_COUNT_SUCCESS'
export const FETCH_USER_EQUIPMENT_COUNT_FAILURE =
  'FETCH_USER_EQUIPMENT_COUNT_FAILURE'

// user equipment
export const FETCH_USER_EQUIPMENTS_REQUEST = 'FETCH_USER_EQUIPMENTS_REQUEST'
export const FETCH_USER_EQUIPMENTS_SUCCESS = 'FETCH_USER_EQUIPMENTS_SUCCESS'
export const FETCH_USER_EQUIPMENTS_FAILURE = 'FETCH_USER_EQUIPMENTS_FAILURE'

export const FETCH_USER_EQUIPMENTS_VENDORS_REQUEST =
  'FETCH_USER_EQUIPMENTS_VENDORS_REQUEST'
export const FETCH_USER_EQUIPMENTS_VENDORS_SUCCESS =
  'FETCH_USER_EQUIPMENTS_VENDORS_SUCCESS'
export const FETCH_USER_EQUIPMENTS_VENDORS_FAILURE =
  'FETCH_USER_EQUIPMENTS_VENDORS_FAILURE'

export const FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST =
  'FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST'
export const FETCH_USER_EQUIPMENTS_DEPARTMENTS_SUCCESS =
  'FETCH_USER_EQUIPMENTS_DEPARTMENTS_SUCCESS'
export const FETCH_USER_EQUIPMENTS_DEPARTMENTS_FAILURE =
  'FETCH_USER_EQUIPMENTS_DEPARTMENTS_FAILURE'

export const FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST =
  'FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST'
export const FETCH_USER_EQUIPMENTS_LOCATIONS_SUCCESS =
  'FETCH_USER_EQUIPMENTS_LOCATIONS_SUCCESS'
export const FETCH_USER_EQUIPMENTS_LOCATIONS_FAILURE =
  'FETCH_USER_EQUIPMENTS_LOCATIONS_FAILURE'

export const FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST =
  'FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST'
export const FETCH_TRANSFERRING_EQUIPMENTS_SENDER_SUCCESS =
  'FETCH_TRANSFERRING_EQUIPMENTS_SENDER_SUCCESS'
export const FETCH_TRANSFERRING_EQUIPMENTS_SENDER_FAILURE =
  'FETCH_TRANSFERRING_EQUIPMENTS_SENDER_FAILURE'

export const FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST =
  'FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST'
export const FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_SUCCESS =
  'FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_SUCCESS'
export const FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_FAILURE =
  'FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_FAILURE'

export const FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST =
  'FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST'
export const FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_SUCCESS =
  'FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_SUCCESS'
export const FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_FAILURE =
  'FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_FAILURE'

export const FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST =
  'FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST'
export const FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_SUCCESS =
  'FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_SUCCESS'
export const FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_FAILURE =
  'FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_FAILURE'

// organization vendors
export const FETCH_ORGANIZATION_VENDORS_REQUEST =
  'FETCH_ORGANIZATION_VENDORS_REQUEST'
export const FETCH_ORGANIZATION_VENDORS_SUCCESS =
  'FETCH_ORGANIZATION_VENDORS_SUCCESS'
export const FETCH_ORGANIZATION_VENDORS_FAILURE =
  'FETCH_ORGANIZATION_VENDORS_FAILURE'

// equipment
export const FETCH_COUNTRY_NAMES_REQUEST = 'FETCH_COUNTRY_NAMES_REQUEST'
export const FETCH_COUNTRY_NAMES_SUCCESS = 'FETCH_COUNTRY_NAMES_SUCCESS'
export const FETCH_COUNTRY_NAMES_FAILURE = 'FETCH_COUNTRY_NAMES_FAILURE'

export const ADD_EQUIPMENT_UNIT_FIRST_STEP = 'ADD_EQUIPMENT_UNIT_FIRST_STEP'
export const CLEAR_EQUIPMENT_UNIT_CREATION_DATA =
  'CLEAR_EQUIPMENT_UNIT_CREATION_DATA'

export const ADD_EQUIPMENT_UNIT_REQUEST = 'ADD_EQUIPMENT_UNIT_REQUEST'
export const ADD_EQUIPMENT_UNIT_SUCCESS = 'ADD_EQUIPMENT_UNIT_SUCCESS'
export const ADD_EQUIPMENT_UNIT_FAILURE = 'ADD_EQUIPMENT_UNIT_FAILURE'

export const FETCH_EQUIPMENT_PERMISSIONS_REQUEST =
  'FETCH_EQUIPMENT_PERMISSIONS_REQUEST'
export const FETCH_EQUIPMENT_PERMISSIONS_SUCCESS =
  'FETCH_EQUIPMENT_PERMISSIONS_SUCCESS'
export const FETCH_EQUIPMENT_PERMISSIONS_FAILURE =
  'FETCH_EQUIPMENT_PERMISSIONS_FAILURE'

export const FETCH_EQUIPMENT_DETAILS_REQUEST = 'FETCH_EQUIPMENT_DETAILS_REQUEST'
export const FETCH_EQUIPMENT_DETAILS_SUCCESS = 'FETCH_EQUIPMENT_DETAILS_SUCCESS'
export const FETCH_EQUIPMENT_DETAILS_FAILURE = 'FETCH_EQUIPMENT_DETAILS_FAILURE'

export const UPDATE_EQUIPMENT_DETAILS_REQUEST =
  'UPDATE_EQUIPMENT_DETAILS_REQUEST'
export const UPDATE_EQUIPMENT_DETAILS_SUCCESS =
  'UPDATE_EQUIPMENT_DETAILS_SUCCESS'
export const UPDATE_EQUIPMENT_DETAILS_FAILURE =
  'UPDATE_EQUIPMENT_DETAILS_FAILURE'

export const TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST =
  'TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST'
export const TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_SUCCESS =
  'TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_SUCCESS'
export const TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_FAILURE =
  'TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_FAILURE'

export const TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST =
  'TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST'
export const TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_SUCCESS =
  'TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_SUCCESS'
export const TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_FAILURE =
  'TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_FAILURE'

export const TRANSFER_EQUIPMENTS_REQUEST = 'TRANSFER_EQUIPMENTS_REQUEST'
export const TRANSFER_EQUIPMENTS_SUCCESS = 'TRANSFER_EQUIPMENTS_SUCCESS'
export const TRANSFER_EQUIPMENTS_FAILURE = 'TRANSFER_EQUIPMENTS_FAILURE'

export const FETCH_EQUIPMENT_LOGS_REQUEST = 'FETCH_EQUIPMENT_LOGS_REQUEST'
export const FETCH_EQUIPMENT_LOGS_SUCCESS = 'FETCH_EQUIPMENT_LOGS_SUCCESS'
export const FETCH_EQUIPMENT_LOGS_FAILURE = 'FETCH_EQUIPMENT_LOGS_FAILURE'

export const EQUIPMENT_LOGS_COUNT_REQUEST = 'EQUIPMENT_LOGS_COUNT_REQUEST'
export const EQUIPMENT_LOGS_COUNT_SUCCESS = 'EQUIPMENT_LOGS_COUNT_SUCCESS'
export const EQUIPMENT_LOGS_COUNT_FAILURE = 'EQUIPMENT_LOGS_COUNT_FAILURE'

export const EQUIPMENT_LIST_ROWS_PER_PAGE_CHANGE =
  'EQUIPMENT_LIST_ROWS_PER_PAGE_CHANGE'

// inventory
export const CREATE_INVENTORY_PROJECT_REQUEST =
  'CREATE_INVENTORY_PROJECT_REQUEST'
export const CREATE_INVENTORY_PROJECT_SUCCESS =
  'CREATE_INVENTORY_PROJECT_SUCCESS'
export const CREATE_INVENTORY_PROJECT_FAILURE =
  'CREATE_INVENTORY_PROJECT_FAILURE'

export const EDIT_INVENTORY_PROJECT_REQUEST = 'EDIT_INVENTORY_PROJECT_REQUEST'
export const EDIT_INVENTORY_PROJECT_SUCCESS = 'EDIT_INVENTORY_PROJECT_SUCCESS'
export const EDIT_INVENTORY_PROJECT_FAILURE = 'EDIT_INVENTORY_PROJECT_FAILURE'

export const FETCH_INVENTORY_PROJECT_PROFILE_REQUEST =
  'FETCH_INVENTORY_PROJECT_PROFILE_REQUEST'
export const FETCH_INVENTORY_PROJECT_PROFILE_SUCCESS =
  'FETCH_INVENTORY_PROJECT_PROFILE_SUCCESS'
export const FETCH_INVENTORY_PROJECT_PROFILE_FAILURE =
  'FETCH_INVENTORY_PROJECT_PROFILE_FAILURE'
export const FETCH_INVENTORY_PROJECT_PROFILE_DEFAULT =
  'FETCH_INVENTORY_PROJECT_PROFILE_DEFAULT'

export const FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST =
  'FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST'
export const FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_SUCCESS =
  'FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_SUCCESS'
export const FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_FAILURE =
  'FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_FAILURE'
export const FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_DEFAULT =
  'FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_DEFAULT'

export const FETCH_INVENTORY_PROJECTS_REQUEST =
  'FETCH_INVENTORY_PROJECTS_REQUEST'
export const FETCH_INVENTORY_PROJECTS_SUCCESS =
  'FETCH_INVENTORY_PROJECTS_SUCCESS'
export const FETCH_INVENTORY_PROJECTS_FAILURE =
  'FETCH_INVENTORY_PROJECTS_FAILURE'

export const PUT_UP_ON_AUCTION_REQUEST = 'PUT_UP_ON_AUCTION_REQUEST'
export const PUT_UP_ON_AUCTION_SUCCESS = 'PUT_UP_ON_AUCTION_SUCCESS'
export const PUT_UP_ON_AUCTION_FAILURE = 'PUT_UP_ON_AUCTION_FAILURE'

export const INVENTORY_PROJECTS_COUNT_REQUEST =
  'INVENTORY_PROJECTS_COUNT_REQUEST'
export const INVENTORY_PROJECTS_COUNT_SUCCESS =
  'INVENTORY_PROJECTS_COUNT_SUCCESS'
export const INVENTORY_PROJECTS_COUNT_FAILURE =
  'INVENTORY_PROJECTS_COUNT_FAILURE'

export const INVENTORY_PROJECTS_CITIES_REQUEST =
  'INVENTORY_PROJECTS_CITIES_REQUEST'
export const INVENTORY_PROJECTS_CITIES_SUCCESS =
  'INVENTORY_PROJECTS_CITIES_SUCCESS'
export const INVENTORY_PROJECTS_CITIES_FAILURE =
  'INVENTORY_PROJECTS_CITIES_FAILURE'

export const GET_INVENTORY_CLIENTS_REQUEST = 'GET_INVENTORY_CLIENTS_REQUEST'
export const GET_INVENTORY_CLIENTS_SUCCESS = 'GET_INVENTORY_CLIENTS_SUCCESS'
export const GET_INVENTORY_CLIENTS_FAILURE = 'GET_INVENTORY_CLIENTS_FAILURE'

export const GET_INVENTORY_PERFORMERS_REQUEST =
  'GET_INVENTORY_PERFORMERS_REQUEST'
export const GET_INVENTORY_PERFORMERS_SUCCESS =
  'GET_INVENTORY_PERFORMERS_SUCCESS'
export const GET_INVENTORY_PERFORMERS_FAILURE =
  'GET_INVENTORY_PERFORMERS_FAILURE'

export const INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST =
  'INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST'
export const INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_SUCCESS =
  'INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_SUCCESS'
export const INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_FAILURE =
  'INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_FAILURE'

export const INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST =
  'INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST'
export const INVENTORY_PROJECT_REJECT_AS_PERFORMER_SUCCESS =
  'INVENTORY_PROJECT_REJECT_AS_PERFORMER_SUCCESS'
export const INVENTORY_PROJECT_REJECT_AS_PERFORMER_FAILURE =
  'INVENTORY_PROJECT_REJECT_AS_PERFORMER_FAILURE'

export const SET_OWN_PERFORMER_REQUEST = 'SET_OWN_PERFORMER_REQUEST'
export const SET_OWN_PERFORMER_SUCCESS = 'SET_OWN_PERFORMER_SUCCESS'
export const SET_OWN_PERFORMER_FAILURE = 'SET_OWN_PERFORMER_FAILURE'

export const SET_FOREIGN_PERFORMER_REQUEST = 'SET_FOREIGN_PERFORMER_REQUEST'
export const SET_FOREIGN_PERFORMER_SUCCESS = 'SET_FOREIGN_PERFORMER_SUCCESS'
export const SET_FOREIGN_PERFORMER_FAILURE = 'SET_FOREIGN_PERFORMER_FAILURE'

export const OFFER_INVENTORY_PROJECT_REQUEST = 'OFFER_INVENTORY_PROJECT_REQUEST'
export const OFFER_INVENTORY_PROJECT_SUCCESS = 'OFFER_INVENTORY_PROJECT_SUCCESS'
export const OFFER_INVENTORY_PROJECT_FAILURE = 'OFFER_INVENTORY_PROJECT_FAILURE'

export const EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST =
  'EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST'
export const EDIT_INVENTORY_PROJECT_RESPONSIBLE_SUCCESS =
  'EDIT_INVENTORY_PROJECT_RESPONSIBLE_SUCCESS'
export const EDIT_INVENTORY_PROJECT_RESPONSIBLE_FAILURE =
  'EDIT_INVENTORY_PROJECT_RESPONSIBLE_FAILURE'

export const FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST =
  'FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST'
export const FETCH_INVENTORY_OFFERS_PERFORMER_SUCCESS =
  'FETCH_INVENTORY_OFFERS_PERFORMER_SUCCESS'
export const FETCH_INVENTORY_OFFERS_PERFORMER_FAILURE =
  'FETCH_INVENTORY_OFFERS_PERFORMER_FAILURE'

export const FETCH_INVENTORY_OFFERS_CLIENT_REQUEST =
  'FETCH_INVENTORY_OFFERS_CLIENT_REQUEST'
export const FETCH_INVENTORY_OFFERS_CLIENT_SUCCESS =
  'FETCH_INVENTORY_OFFERS_CLIENT_SUCCESS'
export const FETCH_INVENTORY_OFFERS_CLIENT_FAILURE =
  'FETCH_INVENTORY_OFFERS_CLIENT_FAILURE'

export const STOP_INVENTORY_PROJECT_AUCTION_REQUEST =
  'STOP_INVENTORY_PROJECT_AUCTION_REQUEST'
export const STOP_INVENTORY_PROJECT_AUCTION_SUCCESS =
  'STOP_INVENTORY_PROJECT_AUCTION_SUCCESS'
export const STOP_INVENTORY_PROJECT_AUCTION_FAILURE =
  'STOP_INVENTORY_PROJECT_AUCTION_FAILURE'

export const CANCEL_INVENTORY_PROJECT_REQUEST =
  'CANCEL_INVENTORY_PROJECT_REQUEST'
export const CANCEL_INVENTORY_PROJECT_SUCCESS =
  'CANCEL_INVENTORY_PROJECT_SUCCESS'
export const CANCEL_INVENTORY_PROJECT_FAILURE =
  'CANCEL_INVENTORY_PROJECT_FAILURE'

export const SET_INVENTORY_PROJECT_DONE_REQUEST =
  'SET_INVENTORY_PROJECT_DONE_REQUEST'
export const SET_INVENTORY_PROJECT_DONE_SUCCESS =
  'SET_INVENTORY_PROJECT_DONE_SUCCESS'
export const SET_INVENTORY_PROJECT_DONE_FAILURE =
  'SET_INVENTORY_PROJECT_DONE_FAILURE'

export const SET_INVENTORY_PROJECT_CLOSED_REQUEST =
  'SET_INVENTORY_PROJECT_CLOSED_REQUEST'
export const SET_INVENTORY_PROJECT_CLOSED_SUCCESS =
  'SET_INVENTORY_PROJECT_CLOSED_SUCCESS'
export const SET_INVENTORY_PROJECT_CLOSED_FAILURE =
  'SET_INVENTORY_PROJECT_CLOSED_FAILURE'

export const SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST =
  'SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST'
export const SET_INVENTORY_PROJECT_BACK_IN_WORK_SUCCESS =
  'SET_INVENTORY_PROJECT_BACK_IN_WORK_SUCCESS'
export const SET_INVENTORY_PROJECT_BACK_IN_WORK_FAILURE =
  'SET_INVENTORY_PROJECT_BACK_IN_WORK_FAILURE'

export const CHANGE_PERFORMER_RATING_REQUEST = 'CHANGE_PERFORMER_RATING_REQUEST'
export const CHANGE_PERFORMER_RATING_SUCCESS = 'CHANGE_PERFORMER_RATING_SUCCESS'
export const CHANGE_PERFORMER_RATING_FAILURE = 'CHANGE_PERFORMER_RATING_FAILURE'

export const CHANGE_CLIENT_RATING_REQUEST = 'CHANGE_CLIENT_RATING_REQUEST'
export const CHANGE_CLIENT_RATING_SUCCESS = 'CHANGE_CLIENT_RATING_SUCCESS'
export const CHANGE_CLIENT_RATING_FAILURE = 'CHANGE_CLIENT_RATING_FAILURE'

export const CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST =
  'CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST'
export const CONFIRM_OFFER_INVENTORY_PROJECT_SUCCESS =
  'CONFIRM_OFFER_INVENTORY_PROJECT_SUCCESS'
export const CONFIRM_OFFER_INVENTORY_PROJECT_FAILURE =
  'CONFIRM_OFFER_INVENTORY_PROJECT_FAILURE'

export const CANCEL_OFFER_INVENTORY_PROJECT_REQUEST =
  'CANCEL_OFFER_INVENTORY_PROJECT_REQUEST'
export const CANCEL_OFFER_INVENTORY_PROJECT_SUCCESS =
  'CANCEL_OFFER_INVENTORY_PROJECT_SUCCESS'
export const CANCEL_OFFER_INVENTORY_PROJECT_FAILURE =
  'CANCEL_OFFER_INVENTORY_PROJECT_FAILURE'

export const FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST =
  'FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST'
export const FETCH_ENGINEERS_INVENTORY_PROJECT_SUCCESS =
  'FETCH_ENGINEERS_INVENTORY_PROJECT_SUCCESS'
export const FETCH_ENGINEERS_INVENTORY_PROJECT_FAILURE =
  'FETCH_ENGINEERS_INVENTORY_PROJECT_FAILURE'

export const EDIT_ENGINEERS_INVENTORY_PROJECT_SUCCESS =
  'EDIT_ENGINEERS_INVENTORY_PROJECT_SUCCESS'
export const EDIT_ENGINEERS_INVENTORY_PROJECT_FAILURE =
  'EDIT_ENGINEERS_INVENTORY_PROJECT_FAILURE'
export const EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST =
  'EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST'

export const REMOVE_ENGINEERS_INVENTORY_PROJECT_SUCCESS =
  'REMOVE_ENGINEERS_INVENTORY_PROJECT_SUCCESS'
export const REMOVE_ENGINEERS_INVENTORY_PROJECT_FAILURE =
  'REMOVE_ENGINEERS_INVENTORY_PROJECT_FAILURE'
export const REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST =
  'REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST'

export const FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST =
  'FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST'
export const FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_SUCCESS =
  'FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_SUCCESS'
export const FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_FAILURE =
  'FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_FAILURE'

export const FETCH_ORDERS_INVENTORY_PROJECT_REQUEST =
  'FETCH_ORDERS_INVENTORY_PROJECT_REQUEST'
export const FETCH_ORDERS_INVENTORY_PROJECT_SUCCESS =
  'FETCH_ORDERS_INVENTORY_PROJECT_SUCCESS'
export const FETCH_ORDERS_INVENTORY_PROJECT_FAILURE =
  'FETCH_ORDERS_INVENTORY_PROJECT_FAILURE'

export const REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST =
  'REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST'
export const REMOVE_ORDERS_INVENTORY_PROJECT_SUCCESS =
  'REMOVE_ORDERS_INVENTORY_PROJECT_SUCCESS'
export const REMOVE_ORDERS_INVENTORY_PROJECT_FAILURE =
  'REMOVE_ORDERS_INVENTORY_PROJECT_FAILURE'

export const FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST =
  'FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST'
export const FETCH_ORDERS_COUNT_INVENTORY_PROJECT_SUCCESS =
  'FETCH_ORDERS_COUNT_INVENTORY_PROJECT_SUCCESS'
export const FETCH_ORDERS_COUNT_INVENTORY_PROJECT_FAILURE =
  'FETCH_ORDERS_COUNT_INVENTORY_PROJECT_FAILURE'

export const EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST =
  'EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST'
export const EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_SUCCESS =
  'EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_SUCCESS'
export const EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_FAILURE =
  'EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_FAILURE'

export const FETCH_HISTORY_INVENTORY_PROJECT_REQUEST =
  'FETCH_HISTORY_INVENTORY_PROJECT_REQUEST'
export const FETCH_HISTORY_INVENTORY_PROJECT_SUCCESS =
  'FETCH_HISTORY_INVENTORY_PROJECT_SUCCESS'
export const FETCH_HISTORY_INVENTORY_PROJECT_FAILURE =
  'FETCH_HISTORY_INVENTORY_PROJECT_FAILURE'

// utils
export const SHOW_DIALOG_LOADER = 'SHOW_DIALOG_LOADER'
export const HIDE_DIALOG_LOADER = 'HIDE_DIALOG_LOADER'

// inventory equipment
export const GET_INVENTORY_EQUIPMENT_REQUEST = 'GET_INVENTORY_EQUIPMENT_REQUEST'
export const GET_INVENTORY_EQUIPMENT_SUCCESS = 'GET_INVENTORY_EQUIPMENT_SUCCESS'
export const GET_INVENTORY_EQUIPMENT_FAILURE = 'GET_INVENTORY_EQUIPMENT_FAILURE'

export const GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST =
  'GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST'
export const GET_INVENTORY_EQUIPMENT_LOCATIONS_SUCCESS =
  'GET_INVENTORY_EQUIPMENT_LOCATIONS_SUCCESS'
export const GET_INVENTORY_EQUIPMENT_LOCATIONS_FAILURE =
  'GET_INVENTORY_EQUIPMENT_LOCATIONS_FAILURE'

export const GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST =
  'GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST'
export const GET_INVENTORY_EQUIPMENT_FILTERS_SUCCESS =
  'GET_INVENTORY_EQUIPMENT_FILTERS_SUCCESS'
export const GET_INVENTORY_EQUIPMENT_FILTERS_FAILURE =
  'GET_INVENTORY_EQUIPMENT_FILTERS_FAILURE'

export const GET_INVENTORY_EQUIPMENT_COUNT_REQUEST =
  'GET_INVENTORY_EQUIPMENT_COUNT_REQUEST'
export const GET_INVENTORY_EQUIPMENT_COUNT_SUCCESS =
  'GET_INVENTORY_EQUIPMENT_COUNT_SUCCESS'
export const GET_INVENTORY_EQUIPMENT_COUNT_FAILURE =
  'GET_INVENTORY_EQUIPMENT_COUNT_FAILURE'

export const FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST =
  'FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST'
export const FETCH_HISTORY_COUNT_INVENTORY_PROJECT_SUCCESS =
  'FETCH_HISTORY_COUNT_INVENTORY_PROJECT_SUCCESS'
export const FETCH_HISTORY_COUNT_INVENTORY_PROJECT_FAILURE =
  'FETCH_HISTORY_COUNT_INVENTORY_PROJECT_FAILURE'

export const DOWNLOAD_FILE_REQUEST = 'serviceDesk/DOWNLOAD_FILE_REQUEST'
export const DOWNLOAD_FILE_SUCCESS = 'serviceDesk/DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILURE = 'serviceDesk/DOWNLOAD_FILE_FAILURE'

export const FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST =
  'FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST '
export const FETCH_EQUIPMENT_FILTER_LOCATION_SUCCESS =
  'FETCH_EQUIPMENT_FILTER_LOCATION_SUCCESS'
export const FETCH_EQUIPMENT_FILTER_LOCATION_FAILURE =
  'FETCH_EQUIPMENT_FILTER_LOCATION_FAILURE'

export const FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST =
  'FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST '
export const FETCH_EQUIPMENT_FILTER_DEPARTMENT_SUCCESS =
  'FETCH_EQUIPMENT_FILTER_DEPARTMENT_SUCCESS'
export const FETCH_EQUIPMENT_FILTER_DEPARTMENT_FAILURE =
  'FETCH_EQUIPMENT_FILTER_DEPARTMENT_FAILURE'

export const SET_FILTER_DRAWER = 'serviceDesk/SET_FILTER_DRAWER'
export const CLEAR_FILTER_DRAWER = 'serviceDesk/CLEAR_FILTER_DRAWER'

export const SET_NAVIGATION_DRAWER = 'serviceDesk/SET_NAVIGATION_DRAWER'
export const CLEAR_NAVIGATION_DRAWER = 'serviceDesk/CLEAR_NAVIGATION_DRAWER'

export const CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST =
  'CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST'
export const CHECK_EQUIPMENTS_IMPORT_FILE_SUCCESS =
  'CHECK_EQUIPMENTS_IMPORT_FILE_SUCCESS'
export const CHECK_EQUIPMENTS_IMPORT_FILE_FAILURE =
  'CHECK_EQUIPMENTS_IMPORT_FILE_FAILURE'

export const PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST =
  'PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST'
export const PROCESS_EQUIPMENTS_IMPORT_FILE_SUCCESS =
  'PROCESS_EQUIPMENTS_IMPORT_FILE_SUCCESS'
export const PROCESS_EQUIPMENTS_IMPORT_FILE_FAILURE =
  'PROCESS_EQUIPMENTS_IMPORT_FILE_FAILURE'
export const PROCESS_EQUIPMENTS_IMPORT_FILE_CLEAR =
  'PROCESS_EQUIPMENTS_IMPORT_FILE_CLEAR'

export const FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST =
  'FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST'
export const FETCH_USER_ORGANIZATION_FUNCTIONALS_SUCCESS =
  'FETCH_USER_ORGANIZATION_FUNCTIONALS_SUCCESS'
export const FETCH_USER_ORGANIZATION_FUNCTIONALS_FAILURE =
  'FETCH_USER_ORGANIZATION_FUNCTIONALS_FAILURE'

export const CREATE_SD_INVOICE_FOR_ORGANIZATION = createRequestTypes(
  'Organizations/CREATE_SD_INVOICE_FOR_ORGANIZATION',
)

export const GET_BA_VARIANTS = createRequestTypes('EQUIPMENTS/GET_BA_VARIANTS')
export const CLEAR_BA_VARIANTS = 'EQUIPMENTS/CLEAR_BA_VARIANTS'

export const GET_BA_SUGGEST = createRequestTypes('EQUIPMENTS/GET_BA_SUGGEST')
export const CLEAR_BA_SUGGEST = 'EQUIPMENTS/CLEAR_BA_SUGGEST'

export const REJECT_BA_SUGGESTIONS = createRequestTypes(
  'EQUIPMENTS/REJECT_BA_SUGGESTIONS',
)

export const EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS = createRequestTypes(
  'Organizations/EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS',
)

export const GET_ORGANIZATION_SETTINGS = createRequestTypes(
  'Organizations/GET_ORGANIZATION_SETTINGS',
)

export const GET_ORGANIZATION_CONTRAGENTS = createRequestTypes(
  'Organizations/GET_ORGANIZATION_CONTRAGENTS',
)

export const CREATE_ORGANIZATION_CONTRAGENT = createRequestTypes(
  'Organizations/CREATE_ORGANIZATION_CONTRAGENT',
)

export const DELETE_ORGANIZATION_CONTRAGENT = createRequestTypes(
  'Organizations/DELETE_ORGANIZATION_CONTRAGENT',
)

export const EDIT_ORGANIZATION_CONTRAGENT = createRequestTypes(
  'Organizations/EDIT_ORGANIZATION_CONTRAGENT',
)

export const GET_ALL_USER_SETTINGS = createRequestTypes(
  'innerCalls/GET_ALL_USER_SETTINGS',
)

export const SAVE_USER_SETTINGS = createRequestTypes(
  'innerCalls/SAVE_USER_SETTINGS',
)
