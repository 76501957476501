import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

// We can inject some CSS into the DOM.
const styles = theme => ({
  button: {
    background: theme.palette.second.main,
    borderRadius: 3,
    margin: '0 4px 0 4px',
    border: 0,
    color: theme.palette.second.contrastText,
    padding: '12px 30px',
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      backgroundColor: theme.palette.main.main,
      color: theme.palette.main.contrastText,
    },
  },
})

function SecondaryButton(props) {
  return (
    <Button
      color="second"
      variant="contained"
      {...props}
      className={props.classes.button}
    >
      {props.children}
    </Button>
  )
}

SecondaryButton.defaultProps = {
  children: '',
}

SecondaryButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SecondaryButton)
