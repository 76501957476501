import React from 'react'
import PropTypes from 'prop-types'

import FormDialog from '../../widgets/FormDialog'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import i18next from '../../i18n/i18n'

import SelectLimitedAuto from '../../widgets/SelectLimitedAuto'
import { sortStringify } from '../../helpers/queryHelper'
import { URL } from '../../middlewares/api'

const ImportFromListDialog = ({ open, closeDialog, submit }) => {
  const [organizationId, setOrganizationId] = React.useState(null)
  function orgChanged(value) {
    setOrganizationId(value)
  }
  function onSubmitHandler() {
    submit(organizationId)
  }
  return (
    <FormDialog
      positiveDisabled={!organizationId}
      open={open}
      title={i18next.t('equipment_web_atoir:selectOrgForImport')}
      onClose={closeDialog}
      onExit={closeDialog}
      onSubmit={onSubmitHandler}
      submitButtonTitle={i18next.t('equipment_web_atoir:startImport')}
    >
      <FormControl fullWidth required>
        <InputLabel htmlFor="age-simple">
          {i18next.t('equipment_web_atoir:selectOrganizationName')}
        </InputLabel>
        <SelectLimitedAuto
          query={sortStringify({ short_name: 'ASC' })}
          value={organizationId}
          sourceUrl={`${URL}/organizations/canAdd`}
          onChange={orgChanged}
          requestResultTransformerToArray={r => r.organizations}
          itemTransformer={l => ({ id: l.id, title: l.short_name })}
        />
      </FormControl>
    </FormDialog>
  )
}

ImportFromListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
}

export default ImportFromListDialog
