import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreateEditScripts from './CreateEditScripts'
import {
  clearNavigationDrawer,
  setNavigationDrawer,
} from '../../../../../actions/appActions'
import { fetchOrganization } from '../../../../../actions/organizationsActions'
import {
  createScriptRequest,
  getScriptRequest,
  editScriptRequest,
} from '../../../../../actions/scriptActions'

const mapStateToProps = state => ({
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
  createScriptError: state.scripts.createScript.error,
  isCreatingScript: state.scripts.createScript.isFetching,
  isFetching: state.scripts.getScript.isFetching,
  isEditing: state.scripts.editScript.isEditing,
  editScriptError: state.scripts.editScript.error,
  script: state.scripts.getScript.script,
  getScriptError: state.scripts.getScript.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNavigationDrawer,
      clearNavigationDrawer,
      fetchOrganization,
      createScriptRequest,
      getScriptRequest,
      editScriptRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateEditScripts),
)
