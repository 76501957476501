import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../../i18n/i18n'
import { URL } from '../../../../../middlewares/api'
import { omitByEmpty } from '../../../../../helpers/dataHelper'
import isEmpty from 'is-empty'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Divider from '@material-ui/core/Divider'

// components
import DateInput from '../../../../../widgets/DateInput'
import ApplyButton from '../../../../../widgets/Buttons/ApplyButton'
import AutocompleteLimitedAuto from '../../../../../widgets/AutocompleteLimitedAuto'

const styles = theme => ({
  filterWrapper: {
    padding: 20,
  },
  dividerMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
})

class InnerCallsFilters extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radioValue: 'day',
      engineer: '',
      engineerId: '',
      dateFrom: '',
      dateTo: '',
    }
  }

  componentDidMount() {
    const {
      props: { organizationId },
      handleFiltersApply,
    } = this

    handleFiltersApply(organizationId)
  }

  handleRadioChange = event => {
    this.setState({
      radioValue: event.target.value,
    })
  }

  getEngineerQuery = organizationId => {
    const {
      state: { engineer },
    } = this

    const params = omitByEmpty({
      query: engineer,
      organization_id: organizationId,
    })

    return params
  }

  handleFiltersApply = organizationId => {
    const {
      state: { radioValue, dateFrom, dateTo, engineerId },
      props: {
        fetchInnerCallAnalyticsChartCreatedRequest,
        fetchInnerCallAnalyticsChartAverageRequest,
        fetchInnerCallAnalyticsChartClosedByEngineerRequest,
        fetchInnerCallsEngineersCountRequest,
        fetchInnerCallAnalyticsSummaryRequest,
        fetchInnerCallAnalyticsSummaryAverageRequest,
        isEngineerDisable,
        changeState,
        limitEngineer,
      },
    } = this

    const defaultLimit = limitEngineer
    let limit = defaultLimit
    if (radioValue === 'month') {
      limit = 12
    }
    if (radioValue === 'year') {
      limit = 20
    }

    const engId = isEngineerDisable ? '' : engineerId
    const params = omitByEmpty({
      organization_id: organizationId,
      date_from: dateFrom && new Date(dateFrom).toISOString(),
      date_to: dateTo && new Date(dateTo).toISOString(),
      limit: defaultLimit,
      engineer_id: engId,
      interval: radioValue,
      timezoneoffset: new Date().getTimezoneOffset() / 60,
    })

    const paramsCreatedChart = Object.assign({}, params, {
      limit,
      interval: radioValue,
    })

    fetchInnerCallAnalyticsSummaryRequest(params)
    fetchInnerCallAnalyticsSummaryAverageRequest(params)
    fetchInnerCallAnalyticsChartCreatedRequest(paramsCreatedChart)
    fetchInnerCallAnalyticsChartClosedByEngineerRequest(params)
    fetchInnerCallAnalyticsChartAverageRequest(params)
    fetchInnerCallsEngineersCountRequest(params)

    // change parrent state
    changeState('dateFrom', dateFrom)
    changeState('dateTo', dateTo)
    changeState('limit', limit)
    changeState('radioValue', radioValue)
    changeState('engineerId', engId)
  }

  onChangeFilter = (value, field) => {
    if (field === 'engineer') {
      this.setState({
        engineerId: !isEmpty(value) ? value.id : '',
        engineer: !isEmpty(value) ? value.full_name : '',
      })
    }

    this.setState({ [field]: value })
  }

  render() {
    const {
      state: { radioValue, dateFrom, dateTo },
      props: { classes, organizationId, isEngineerDisable },
      handleRadioChange,
      handleFiltersApply,
      onChangeFilter,
      getEngineerQuery,
    } = this

    return (
      <Fragment>
        <List
          component="nav"
          subheader={
            <ListSubheader disableGutters component="div">
              {i18n.t('inner_calls_web_atoir:division')}
            </ListSubheader>
          }
          className={classes.filterWrapper}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="division"
              name="division"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="day"
                control={<Radio color="primary" />}
                label={i18n.t('inner_calls_web_atoir:day')}
                disabled={isEngineerDisable}
              />
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label={i18n.t('inner_calls_web_atoir:month')}
                disabled={isEngineerDisable}
              />
              <FormControlLabel
                value="year"
                control={<Radio color="primary" />}
                label={i18n.t('inner_calls_web_atoir:year')}
                disabled={isEngineerDisable}
              />
            </RadioGroup>
          </FormControl>
          <Divider className={classes.dividerMargin} />
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            justify="space-between"
          >
            <Grid item xs={12} className={classes.marginBottom}>
              <AutocompleteLimitedAuto
                InputProps={{ disabled: isEngineerDisable }}
                placeholder={i18n.t('inner_calls_web_atoir:engineer')}
                sourceUrl={`${URL}/inner_calls/statistics/engineers`}
                query={() => getEngineerQuery(organizationId)}
                getItemDisplayName={i => i.full_name}
                getItemValue={i => i.id}
                onChange={value => onChangeFilter(value, 'engineer')}
                requestResultTransformerToArray={res => res.engineers}
                transformInputToQuery={query => ({ query })}
              />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <DateInput
                value={dateFrom}
                name="dateFrom"
                label={i18n.t('inner_calls_web_atoir:dateFrom')}
                handleChange={value => onChangeFilter(value, 'dateFrom')}
              />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <DateInput
                value={dateTo}
                name="dateTo"
                label={i18n.t('inner_calls_web_atoir:dateTo')}
                handleChange={value => onChangeFilter(value, 'dateTo')}
                disabledDays={{ before: dateFrom }}
              />
            </Grid>
            <Grid item xs={12} container justify="flex-end">
              <ApplyButton onClick={() => handleFiltersApply(organizationId)}>
                {i18n.t('service_desk_web_atoir:applyAction')}
              </ApplyButton>
            </Grid>
          </Grid>
        </List>
      </Fragment>
    )
  }
}

InnerCallsFilters.propTypes = {
  fetchInnerCallAnalyticsSummaryRequest: PropTypes.func.isRequired,
  fetchInnerCallAnalyticsSummaryAverageRequest: PropTypes.func.isRequired,
  fetchInnerCallAnalyticsChartCreatedRequest: PropTypes.func.isRequired,
  fetchInnerCallsEngineersCountRequest: PropTypes.func.isRequired,
  isEngineerDisable: PropTypes.bool.isRequired,
  changeState: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  limitEngineer: PropTypes.number.isRequired,
}

export default withStyles(styles)(InnerCallsFilters)
