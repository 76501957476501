/* eslint-disable no-unused-vars */
import {
  unsubscribeFromAllNotifications,
  subscribeToAllNotifications,
  pushNotificationsKey,
} from '../middlewares/api'
import config from '../config'

const pushSubscriptionCreatedKey = 'pushSubscriptionCreated'
const pushSubscriptionCreatedForKey = 'pushSubscriptionCreatedForKey'

function urlBase64ToUint8Array(base64String) {
  // eslint-disable-next-line no-mixed-operators
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
export const subscribeOnNotifications = () => {
  navigator.serviceWorker.ready.then(registration => {
    registration.pushManager.getSubscription().then(subscription => {
      if (subscription) {
        unsubscribeFromNotifications().then(subscribeOnNotifications)
      } else {
        navigator.serviceWorker.ready.then(async reg => {
          const response = await pushNotificationsKey()
          const vapidPublicKey = await response.text()
          reg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
            })
            .then(subs => {
              if (subs) {
                subscribeToAllNotifications(subs)
              }
            })
        })
      }
    })
  })
}

export const subscribeOnNotificationsCheck = async email => {
  const registration = await navigator.serviceWorker.ready
  const subscription = await registration.pushManager.getSubscription()
  const pushSubscriptionCreated = localStorage.getItem(
    pushSubscriptionCreatedKey,
  )
  const pushSubscriptionCreatedFor = localStorage.getItem(
    pushSubscriptionCreatedForKey,
  )
  if (subscription) {
    if (
      !pushSubscriptionCreated ||
      !pushSubscriptionCreatedFor ||
      email !== pushSubscriptionCreatedFor ||
      Date.now() - new Date(pushSubscriptionCreated).getTime() >
        config.subscriptionLifetime
    ) {
      await unsubscribeFromNotifications()
    } else {
      return
    }
  }
  const response = await pushNotificationsKey()
  const vapidPublicKey = await response.text()
  const sub = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
  })
  await subscribeToAllNotifications(sub)
  localStorage.setItem(pushSubscriptionCreatedKey, new Date().toString())
  localStorage.setItem(pushSubscriptionCreatedForKey, email)
}

export const unsubscribeFromNotifications = async () => {
  localStorage.removeItem(pushSubscriptionCreatedKey)
  localStorage.removeItem(pushSubscriptionCreatedForKey)
  await navigator.serviceWorker.ready
    .then(registration => registration.pushManager.getSubscription())
    .then(async subscription => {
      if (subscription) {
        await subscription.unsubscribe()
        await unsubscribeFromAllNotifications(subscription)
      }
    })
}
