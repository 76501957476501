import TextField from '@material-ui/core/TextField'
import React from 'react'
import shallowEqual from 'shallowequal'

import CheckBoxSelector from '../../../widgets/CheckBoxSelector'
import DatePickField from './DatePickField'
import i18next from '../../../i18n/i18n'
import StatusSelector from './SelectFields/StatusSelector'

function AdditionalInfo({
  setFieldValueHandler,
  handleBlur,
  values,
  errors,
  touched,
}) {
  return (
    <React.Fragment>
      <TextField
        fullWidth
        value={values.serial_number || ''}
        id={fields.serial_number}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:serialNumber')}
      />
      <TextField
        fullWidth
        value={values.article_number || ''}
        id={fields.article_number}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:articleNumber')}
      />
      <TextField
        fullWidth
        value={values.external_id || ''}
        id={fields.external_id}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:externalId')}
      />
      <TextField
        fullWidth
        value={values.year_issue || ''}
        id={fields.year_issue}
        onChange={setFieldValueHandler}
        onBlur={handleBlur}
        label={i18next.t('equipment_web_atoir:yearIssue')}
        error={errors.year_issue && touched.year_issue}
      />
      <DatePickField
        label={i18next.t('equipment_web_atoir:dateInputWork')}
        idValue={fields.date_input_work}
        value={values.date_input_work}
        required
        error={errors.date_input_work && touched.date_input_work}
        onBlur={handleBlur}
        fullWidth
        onChange={setFieldValueHandler}
      />
      <DatePickField
        label={i18next.t('equipment_web_atoir:dateExpiredGuarantee')}
        idValue={fields.date_expired_guarantee}
        value={values.date_expired_guarantee}
        required
        error={errors.date_expired_guarantee && touched.date_expired_guarantee}
        onBlur={handleBlur}
        fullWidth
        onChange={setFieldValueHandler}
      />
      <StatusSelector
        idValue={fields.status}
        value={values.status}
        onChange={setFieldValueHandler}
      />
      <div>
        <div>
          <CheckBoxSelector
            onChange={setFieldValueHandler}
            idValue={fields.portable}
            currentValue={values.portable || false}
            label={i18next.t('equipment_web_atoir:portableEquipment')}
          />
        </div>
        <div>
          <CheckBoxSelector
            onChange={setFieldValueHandler}
            idValue={fields.not_working}
            currentValue={values.not_working || false}
            label={i18next.t('equipment_web_atoir:notOperation')}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const fields = {
  serial_number: 'serial_number',
  article_number: 'article_number',
  external_id: 'external_id',
  year_issue: 'year_issue',
  date_input_work: 'date_input_work',
  date_expired_guarantee: 'date_expired_guarantee',
  status: 'status',
  portable: 'portable',
  not_working: 'not_working',
}

const fieldsList = _.keys(fields)

function isAllFieldsEqual(_old, _new) {
  return shallowEqual(_.pick(_old, fieldsList), _.pick(_new, fieldsList))
}

function arePropsEqual(prevProps, nextProps) {
  return (
    isAllFieldsEqual(prevProps.values, nextProps.values) &&
    isAllFieldsEqual(prevProps.errors, nextProps.errors) &&
    isAllFieldsEqual(prevProps.touched, nextProps.touched) &&
    prevProps.setFieldValueHandler === nextProps.setFieldValueHandler
  )
}

export default React.memo(AdditionalInfo, arePropsEqual)
