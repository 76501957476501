import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../i18n/i18n'
import isEmpty from 'is-empty'

// material-ui
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'

// components
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
// eslint-disable-next-line max-len
import InformationFrom from '../../../CreateRequest/ServiceDeskRequestDetails/widgets/InformationForPerformer/InformationForm'
import Loader from '../../../../Loader'
import Typography from '@material-ui/core/Typography'
import Heading from '../../../../../widgets/Lettering/Heading'

const styles = () => ({
  paper: {
    minWidth: 844,
  },
  buttonRight: {
    marginRight: 20,
  },
})

class EditPerformerResponsibleDialog extends Component {
  constructor(props) {
    super(props)
    const {
      comment,
      floor,
      office,
      dateFrom,
      responsible,
      dateTo,
      defaultLocation,
    } = props
    this.state = {
      data: {
        comment,
        floor,
        cabinet: office,
        dateFrom,
        responsible,
        dateTo,
        defaultLocation,
      },
      errors: {},
    }
  }

  componentDidMount = () => {
    this.props.fetchOrganizationWhoAmI(this.props.organizationId)
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.isFetchingUserStatus !== this.props.isFetchingUserStatus &&
      nextProps.isFetchingUserStatus === false
    ) {
      if (nextProps.userStatus.is_manager_or_higher) {
        this.props.fetchOrganizationStaff(this.props.organizationId)
      } else {
        this.props.fetchOrganizationUserMe(this.props.organizationId)
      }
    }
    if (
      nextProps.isFetchingMe !== this.props.isFetchingMe &&
      nextProps.isFetchingMe === false
    ) {
      this.setState(state => ({
        data: {
          ...state.data,
          responsible: nextProps.me.id,
        },
      }))
    }
  }

  onChange = field => event => {
    const {
      target: { value },
    } = event
    this.setState(state => ({
      data: {
        ...state.data,
        [field]: value,
      },
    }))
  }

  onSubmit = () => {
    const {
      state: {
        data: { responsible },
      },
    } = this
    const errors = {}
    if (!responsible || responsible === '') {
      errors.responsibleError = i18next.t(
        'service_desk_web_atoir:requestCreationNoResponsibleValidationError',
      )
    }
    if (isEmpty(errors)) {
      this.props.onSubmit(this.state.data)
    } else {
      this.setState({ errors })
    }
  }

  render() {
    const {
      state: { data, errors },
      props: {
        classes,
        open,
        onClose,
        userStatus,
        isFetchingUserStatus,
        isFetchingMe,
        isFetchingOrganizationStaff,
        me,
        organizationStaff,
        organizationId,
      },
      onSubmit,
      onChange,
    } = this
    const employees = userStatus
      ? userStatus.is_manager_or_higher
        ? _.compact(organizationStaff)
        : [me]
      : []
    return isFetchingUserStatus ||
      isFetchingOrganizationStaff ||
      isFetchingMe ? (
      <Loader />
    ) : (
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Heading>
          <Typography variant="h6" style={{ padding: 16 }}>
            {i18next.t('service_desk_web_atoir:chooseResponsible')}
          </Typography>
        </Heading>
        <Divider />
        <DialogContent>
          <InformationFrom
            {...{ ...data, employees }}
            disablePast={false}
            responsibleOnly
            organizationId={organizationId}
            errors={errors}
            canChangeResponsible={userStatus.is_manager_or_higher}
            onChangeDateTo={onChange('dateTo')}
            onChangeFloor={onChange('floor')}
            onChangeLocation={onChange('location')}
            onChangeComment={onChange('comment')}
            onChangePhone={onChange('phone')}
            onChangePosition={onChange('position')}
            onChangeResponsible={onChange('responsible')}
            hideComment
          />
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={onClose}>
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton
            onClick={onSubmit}
            classes={{ root: classes.buttonRight }}
          >
            {i18next.t('shared_web_atoir:save')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

EditPerformerResponsibleDialog.defaultProps = {
  floor: '',
  office: '',
  dateFrom: new Date(),
  dateTo: new Date(new Date().setMonth(new Date().getMonth() + 1)),
  responsible: null,
  defaultLocation: null,
}

EditPerformerResponsibleDialog.defaultProps = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  comment: PropTypes.string,
  responsible: PropTypes.string,
  floor: PropTypes.string,
  office: PropTypes.string,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  defaultLocation: PropTypes.object,
}

export default withStyles(styles)(EditPerformerResponsibleDialog)
