import React from 'react'
import ChooseThirdPartyOrganization from '../../ChooseThirdPartyOrganization/InnerCallConnectedTrusted'
import i18next from '../../../../i18n/i18n'
import Box from '@material-ui/core/Box'
import FormDialog from '../../../../widgets/FormDialog'

const TrustedOrgSelectDialog = ({
  orgIdFor,
  open,
  handleClose,
  side,
  onChange,
  selected,
  title,
}) => {
  React.useEffect(() => {
    setValue({ id: selected })
  }, [open])

  function onChangeHandler(org) {
    setValue(org)
  }

  const [value, setValue] = React.useState({ id: null })

  function onSubmit() {
    if (value.id !== selected) {
      onChange(value)
    }
    handleClose()
  }

  return (
    <FormDialog
      positiveDisabled={!value.id}
      closeIconHidden
      open={open}
      title={title}
      onClose={handleClose}
      onExit={handleClose}
      onSubmit={onSubmit}
      submitButtonTitle={i18next.t('inner_calls_web_atoir:doChose')}
      fullWidth
      dialogProps={{
        maxWidth: 'xl',
      }}
      secondaryButtonsArray={[
        {
          secondaryText: i18next.t('shared_web_atoir:cancel'),
          handleSecondaryAction: handleClose,
        },
      ]}
    >
      {open && (
        <Box b={2} t={2} height="calc(100vh - 280px)">
          <ChooseThirdPartyOrganization
            side={side}
            repairMode
            clientOrganizationId={orgIdFor}
            selected={value.id}
            onOrganizationChanged={onChangeHandler}
          />
        </Box>
      )}
    </FormDialog>
  )
}

export default TrustedOrgSelectDialog
