import React from 'react'
import { useDropzone } from 'react-dropzone'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import i18next from '../../../../i18n/i18n'
import config from '../../../../config'

import ExcelIcon from './ExcelIcon'
import FileRequirementInfo from './FileRequirementInfo'

const style = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed #999',
    borderRadius: '6px',
    height: '400px',
    '&:hover': {
      border: '2px dashed #1976D2',
    },
  },
  input: {
    display: 'none',
  },
  inputLabel: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
}

function OpenFileComponent({ classes, showDialogWithOk, onChange, file }) {
  const onDrop = React.useCallback(acceptedFiles => {
    const fileSelected = acceptedFiles[0]
    if (!fileSelected) {
      showDialogWithOk(i18next.t('equipment_web_atoir:ensureForExcelFile'))
      return
    }
    if (fileSelected && fileSelected.size > config.max_call_file_size) {
      showDialogWithOk(i18next.t('shared_web_atoir:fileToBig'))
      return
    }
    onChange(fileSelected)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: '.xls,.xlsx',
  })

  return (
    <React.Fragment>
      <FileRequirementInfo />
      <div {...getRootProps()} className={classes.container}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>
            <Typography>
              {i18next.t('equipment_web_atoir:moveFileThere')}
            </Typography>
          </p>
        ) : (
          <div className={classes.inputLabel}>
            {file ? (
              <React.Fragment>
                <ExcelIcon />
                <Typography>{file.name}</Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <AttachFileIcon />
                <Typography>
                  {i18next.t('equipment_web_atoir:addFileInExcelFormat')}
                </Typography>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withStyles(style)(OpenFileComponent)
