import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import _ from 'lodash'

import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'

import i18next from '../../../i18n/i18n'
import Loader from '../../Loader'
import EditEquipmentInfoDialog from './widgets/EditEquipmentInfoDialog'
import { serviceDeskRequestType } from '../../../constants/constants'
import EditPerformerResponsibleDialog from './widgets/EditPerformerResponsibleDialog'
import EditPerformerInfoDialog from './widgets/EditPerformerInfoDialog'
import AssignToThirdPartyOrganization from '../AssignToThirdPartyOrgnization'
import SnackbarElContent from '../../../widgets/SnackbarElContent'
import OrdersList from './widgets/OrdersList'
import OrdersListRenderer from './widgets/OrdersList/OrdersListRenderer'
import {
  canAssignToOwnSpecialist,
  canAssignToThirdPartyOrganization,
  isOnClientCallSide,
  canMakeRecurring,
  canSendToContract,
  canEditAttachments,
  canEditResponsible,
  canEditWhatsWrong,
  canCancelCall,
  canCancelCallFromNeedrepair,
  canSendToRepairFromNeedrepair,
  canViewRepairOfferDocs,
  canEditRepairOfferDocs,
  canViewCallRepairPerfomanceInfo,
  canEditInformationForPerformer,
  canPrintBlank,
  canOperateButton,
} from '../../../helpers/callPermissionsResolver'
import AssignToOwnSpecialist from '../AssignToOwnSpecialist'
import FormDialog from '../../../widgets/FormDialog'
import SetRatingDialog from '../../Inventory/InventoryProject/InventoryProjectProfile/ProjectRating/SetRatingDialog'
import BreadCrumb from '../../App/routing/BreadCrumb'
import CallMainInfo from './widgets/CallMainInfo'
import CallAttachments from './widgets/CallAttachments'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import TextButton from '../../../widgets/Buttons/TextButton'
import Title from './widgets/Title'
import RequestDocs from './widgets/RequestDocs'
import { popLastSections } from '../../../helpers/dataHelper'
import classNames from 'classnames'
import { URL } from '../../../middlewares/api'
import RepairDateReminderContext from './RepairDateReminder/RepairDateReminderContext'
import UnpaidTooltip from './UnpaidTooltip'

const styles = theme => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  rootWithPadding: {
    padding: 12,
  },
  dividerWithMargin: {
    marginTop: 8,
    marginBottom: 8,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  editEquipmentDialogRoot: {
    width: '70%',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 8px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  textFieldMulty: {
    width: '500px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  grayText: {
    color: theme.palette.grey[500],
  },
})

class CallProfile extends Component {
  constructor(props) {
    super(props)

    this.setDontShowForReminder = id => {
      const repairDateReminderContext = {
        ...this.state.repairDateReminderContext,
      }
      if (!repairDateReminderContext.dontShowState[id]) {
        repairDateReminderContext.dontShowState[id] = true
      }
      this.setState(() => ({ repairDateReminderContext }))
    }

    this.state = {
      editEquipmentDialogOpen: false,
      editEditResponsibleDialogOpen: false,
      editInformationForPerformerDialogOpen: false,
      assignToOwnSpecialistDialogOpen: false,
      assignToThirdPartyDialogOpen: false,
      anchorElAssign: null,
      openCancelCallDialog: false,
      openCloseCallFromNeedRepair: false,
      callCancelFromNeedrepairReason: '',
      callCancelFromNeedrepairReasonValid: undefined,
      openSendCallToRepairDiagnosticians: false,
      callCancelReason: '',
      callCancelReasonValid: undefined,
      openSnackbar: false,
      snackbarVariant: 'default',
      snackbarMessage: '',
      repairDateReminderContext: {
        dontShowState: {},
        setDontShow: this.setDontShowForReminder,
      },
    }
  }

  updateData = () => {
    const {
      props: {
        fetchCallPermissions,
        match: {
          params: { id },
        },
      },
    } = this
    fetchCallPermissions(id)
  }

  componentDidMount = () => {
    const {
      props: { setNavigationDrawer },
    } = this
    this.updateData()
    setNavigationDrawer([
      {
        component: 'nestedList',
        listTitle: i18next.t('service_desk_web_atoir:mainInfoTitle'),
        listTitleAnchor: '#call-main-info',
        listItems: [
          {
            title: i18next.t('service_desk_web_atoir:equipment'),
            anchor: '#call-equipment-info',
          },
          {
            title: i18next.t(
              'service_desk_web_atoir:requestCreationClientOrganization',
            ),
            anchor: '#call-equipment-owner',
          },
          {
            title: i18next.t('service_desk_web_atoir:repairInformation'),
            anchor: '#call-repair-info',
          },
        ],
      },
      {
        title: i18next.t('service_desk_web_atoir:detailedInfo'),
        anchor: '#call-detailed-info',
      },
      {
        title: i18next.t('service_desk_web_atoir:callOrdersListTitle'),
        anchor: '#call-orders-list',
      },
    ])
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        permissionsCommon,
        match: {
          params: { id },
          url,
        },
        history,
        fetchCallPermissions,
        isFetchingPermissions,
        callStatus,
        permissions,
        isFetchingCall,
        call,
        isFetchingCancelRequest,
        cancelRequestSuccess,
        isRejectingCertificateOfCompletion,
        rejectedCertificateOfCompletionSuccess,
        isAcceptingCertificateOfCompletion,
        acceptedCerificateOfCompletionSuccess,
        isClosingCallFromNeedrepair,
        isSendingCallToRepairDiagnosticians,
        activeOrders,
        closedOrders,
        canceledOrders,
        isAssigningOrderToOwnSpecialist,
        isSendingOrderToSuborder,
        isRejectingOrder,
        rejectOrderSuccess,
        isConfirmingCertificateOfCompletion,
        isSendingCallUsingContract,
        sendCallUsingContractSuccess,
        sendCallUsingContractError,
        isCancellingOrder,
        isFetchingCallPossibleReports,
        callPossibleReportsError,
        isSettingOrderResultType,
        orderResultReportingType,
        shouldSetOrderResultReportAction,
      },
      showCancelCallDialog,
    } = this

    if (
      prevProps.isFetchingCallPossibleReports !==
        isFetchingCallPossibleReports &&
      !isFetchingCallPossibleReports &&
      isEmpty(callPossibleReportsError)
    ) {
      this.downloadBlank()
    }

    if (
      prevProps.isFetchingPermissions !== isFetchingPermissions &&
      !isFetchingPermissions
    ) {
      if (this.props.permissionsError || !this.props.permissions.ANY) {
        history.push('/404')
        return
      }
      this.props.fetchServiceDeskCall(id)
      this.props.fetchServiceDeskCallDocs(id)
      this.props.fetchServiceDeskCallOfferDocs(id)
      this.props.fetchServiceDeskCallPhotos(id)
      this.props.getSDCallButtonsRequest({ serviceCallId: id })
      this.props.getSDCallAvailableScriptsRequest({ serviceCallId: id })
      if (orderResultReportingType) {
        shouldSetOrderResultReportAction(true)
      }
    }

    if (prevProps.isFetchingCall !== isFetchingCall && !isFetchingCall) {
      this.props.getTitleInfo({
        title: i18next.t('service_desk_web_atoir:callExternalNumberDateTitle', {
          externalNumber: ` № ${call.internal_num}`,
          date: new Date(Date.parse(call.createdAt)).toLocaleDateString('ru'),
        }),
        historyChanges: [
          {
            title: call.status_enhanced
              ? i18next.t(
                  `service_desk_web_atoir:calls_${call.status_enhanced}_status`,
                )
              : 'временная надпись т.к не приходит status_enhanced',
          },
          {
            link: `${url}/history`,
            title: i18next.t('shared_web_atoir:changeLog'),
          },
        ],
        OuterButton: canCancelCall(callStatus, permissions) && (
          <UnpaidTooltip
            disabled={canOperateButton(permissionsCommon, 'calls', 'client')}
          >
            <TextButton
              disabled={!canOperateButton(permissionsCommon, 'calls', 'client')}
              onClick={showCancelCallDialog}
            >
              {i18next.t('service_desk_web_atoir:cancelCall')}
            </TextButton>
          </UnpaidTooltip>
        ),
      })
    }

    if (
      prevProps.isFetchingCancelRequest !== isFetchingCancelRequest &&
      !isFetchingCancelRequest &&
      cancelRequestSuccess
    ) {
      if (
        call.status === 'created' &&
        !activeOrders.length &&
        !closedOrders.length &&
        !canceledOrders.length
      ) {
        history.replace(popLastSections(url, 1))
      } else {
        fetchCallPermissions(id)
      }
    }

    if (
      (prevProps.isConfirmingCertificateOfCompletion !==
        isConfirmingCertificateOfCompletion &&
        !isConfirmingCertificateOfCompletion) ||
      (prevProps.isSendingOrderToSuborder !== isSendingOrderToSuborder &&
        !isSendingOrderToSuborder) ||
      (prevProps.isAssigningOrderToOwnSpecialist !==
        isAssigningOrderToOwnSpecialist &&
        !isAssigningOrderToOwnSpecialist) ||
      (prevProps.isCancellingOrder !== isCancellingOrder &&
        !isCancellingOrder) ||
      (prevProps.isRejectingCertificateOfCompletion !==
        isRejectingCertificateOfCompletion &&
        !isRejectingCertificateOfCompletion &&
        rejectedCertificateOfCompletionSuccess) ||
      (prevProps.isAcceptingCertificateOfCompletion !==
        isAcceptingCertificateOfCompletion &&
        !isAcceptingCertificateOfCompletion &&
        acceptedCerificateOfCompletionSuccess) ||
      (prevProps.isSettingOrderResultType !== isSettingOrderResultType &&
        !isSettingOrderResultType)
    ) {
      fetchCallPermissions(id)
    }

    if (
      prevProps.isRejectingOrder !== isRejectingOrder &&
      !isRejectingOrder &&
      rejectOrderSuccess
    ) {
      history.replace(popLastSections(url, 1))
    }

    if (
      prevProps.isSendingCallUsingContract !== isSendingCallUsingContract &&
      !isSendingCallUsingContract
    ) {
      if (sendCallUsingContractSuccess) {
        this.openSnackbar(
          'success',
          i18next.t('service_desk_web_atoir:sendCallUsingContractSuccessfully'),
        )
        fetchCallPermissions(id)
      } else if (sendCallUsingContractError === 285) {
        this.openSnackbar(
          'error',
          i18next.t(
            'service_desk_web_atoir:equipmentIsNotAssignedToAnyContract',
          ),
        )
      }
    }

    if (
      this.props.isUpdatingRequest !== prevProps.isUpdatingRequest &&
      this.props.isUpdatingRequest === false
    ) {
      this.onToggle('editEditResponsibleDialogOpen', false)()
      this.onToggle('editInformationForPerformerDialogOpen', false)()
      this.onToggle('editEquipmentDialogOpen', false)()
      this.props.hideDialogLoader()
      this.props.fetchServiceDeskCall(this.props.match.params.id)
    }

    if (
      this.props.isUploadingDoc !== prevProps.isUploadingDoc &&
      this.props.isUploadingDoc === false
    ) {
      this.props.hideDialogLoader()
      this.props.fetchServiceDeskCallDocs(this.props.match.params.id)
      this.props.fetchServiceDeskCallOfferDocs(this.props.match.params.id)
    }
    if (
      this.props.isDeletingFile !== prevProps.isDeletingFile &&
      this.props.isDeletingFile === false
    ) {
      this.props.hideDialogLoader()
      this.props.fetchServiceDeskCallDocs(this.props.match.params.id)
      this.props.fetchServiceDeskCallOfferDocs(this.props.match.params.id)
    }
    if (
      this.props.isDeletingPhoto !== prevProps.isDeletingPhoto &&
      this.props.isDeletingPhoto === false
    ) {
      this.props.hideDialogLoader()
      this.props.fetchServiceDeskCallPhotos(this.props.match.params.id)
    }
    if (
      (this.props.isDeletingFile !== prevProps.isDeletingFile &&
        this.props.isDeletingFile === true) ||
      (this.props.isDeletingPhoto !== prevProps.isDeletingPhoto &&
        this.props.isDeletingPhoto === true)
    ) {
      this.props.showDialogLoader()
    }
    if (
      this.props.isUploadingPhoto !== prevProps.isUploadingPhoto &&
      this.props.isUploadingPhoto === false
    ) {
      this.props.hideDialogLoader()
      this.props.fetchServiceDeskCallPhotos(this.props.match.params.id)
    }

    if (
      prevProps.isClosingCallFromNeedrepair !== isClosingCallFromNeedrepair &&
      !isClosingCallFromNeedrepair
    ) {
      this.closeCancelCallFromNeedrepairDialog()
      this.props.fetchCallPermissions(this.props.call.id)
    }

    if (
      prevProps.isSendingCallToRepairDiagnosticians !==
        isSendingCallToRepairDiagnosticians &&
      !isSendingCallToRepairDiagnosticians
    ) {
      this.closeSendCallToRepairDiagnosticiansDialog()
      this.props.fetchCallPermissions(this.props.call.id)
    }
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
    this.props.clearOrdersList()
    if (this.props.orderResultReportingType) {
      this.props.setOrderResultReportingType({ type: null, id: null })
    }
  }

  openSnackbar = (variant, message) => {
    this.setState({
      openSnackbar: true,
      snackbarVariant: variant,
      snackbarMessage: message,
    })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      openSnackbar: false,
      snackbarVariant: 'default',
      snackbarMessage: '',
    })
  }

  onToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  onReasonEdit = event => {
    event.preventDefault()
    const {
      target: { value },
    } = event
    if (value.length < 255) {
      this.setState({
        callCancelReason: value,
        callCancelReasonValid: !isEmpty(value),
      })
    }
  }

  showCancelCallDialog = () => {
    this.setState({
      openCancelCallDialog: true,
    })
  }

  closeCancelCallDialog = () => {
    this.setState({
      openCancelCallDialog: false,
      callCancelReason: '',
      callCancelReasonValid: undefined,
    })
  }

  onCancelCall = () => {
    const {
      props: {
        match: {
          params: { id },
        },
      },
      state: { callCancelReason },
      closeCancelCallDialog,
    } = this
    if (!isEmpty(callCancelReason)) {
      const { cancelServiceDeskRequest } = this.props
      const payload = { id, reason: callCancelReason }
      cancelServiceDeskRequest(payload)
      closeCancelCallDialog()
    } else {
      this.setState({
        callCancelReasonValid: false,
      })
    }
  }

  onUpdateEquipmentInfo = data => {
    this.props.showDialogLoader()
    this.props.updateServiceDeskCall({
      requestId: this.props.call.id,
      data: {
        organization_equipment_id: data.equipment || null,
        whats_broken: data.comment || null,
      },
    })
  }

  onUpdatePerformerInfo = data => {
    this.props.showDialogLoader()
    this.props.updateServiceDeskCall({
      requestId: this.props.call.id,
      data: { ...data },
    })
  }

  onUpdatePerformerResponsible = data => {
    this.props.showDialogLoader()
    this.props.updateServiceDeskCall({
      requestId: this.props.call.id,
      data: { client_responsible_id: data.responsible },
    })
  }

  showAssignMenu = event => {
    this.setState({ anchorElAssign: event.currentTarget })
  }

  uploadDoc = file => {
    const {
      props: {
        call: { id },
        showDialogLoader,
        uploadDocServiceDeskCall,
      },
    } = this
    showDialogLoader()
    uploadDocServiceDeskCall({ file, id })
  }

  uploadPhoto = file => {
    const {
      props: {
        call: { id },
        showDialogLoader,
        uploadPhotoServiceDeskCall,
      },
    } = this
    showDialogLoader()
    uploadPhotoServiceDeskCall({ file, id })
  }

  onCancelCallFromNeedrepairReasonEdit = event => {
    event.preventDefault()
    const {
      target: { value },
    } = event
    if (value.length < 255) {
      this.setState({
        callCancelFromNeedrepairReason: value,
        callCancelFromNeedrepairReasonValid: !isEmpty(value),
      })
    }
  }

  closeCancelCallFromNeedrepairDialog = () => {
    this.setState({
      openCloseCallFromNeedRepair: false,
      callCancelFromNeedrepairReason: null,
      callCancelFromNeedrepairReasonValid: undefined,
    })
  }

  onCancelCallFromNeedrepairAction = () => {
    const {
      props: { call, closeServiceCallFromNeedrepairRequest },
      state: { callCancelFromNeedrepairReason },
    } = this
    if (!isEmpty(callCancelFromNeedrepairReason)) {
      const payload = {
        callId: call.id,
        data: {
          reject_comment: callCancelFromNeedrepairReason,
        },
      }
      closeServiceCallFromNeedrepairRequest(payload)
    } else {
      this.setState({ callCancelFromNeedrepairReasonValid: false })
    }
  }

  onSendCallToRepairDiagnosticians = () => {
    const {
      props: { call, sendServiceCallToRepairDiagnosticiansRequest },
    } = this
    sendServiceCallToRepairDiagnosticiansRequest({ callId: call.id })
  }

  closeSendCallToRepairDiagnosticiansDialog = () => {
    this.setState({
      openSendCallToRepairDiagnosticians: false,
    })
  }

  previewBlank = () => {
    const {
      props: {
        getServiceCallBlankDocumentLinkRequest,
        match: {
          params: { id: callId },
        },
      },
    } = this
    getServiceCallBlankDocumentLinkRequest({ callId })
  }

  downloadBlank = () => {
    const {
      props: {
        downloadFileRequest,
        match: {
          params: { id: callId },
        },
        callPossibleReportsCount,
        callPossibleReportsNames,
      },
    } = this
    if (callPossibleReportsCount > 1 && callPossibleReportsNames.length > 1) {
      downloadFileRequest({
        url: `${URL}/service_calls/${callId}/blanks`,
        name: 'blanks.zip',
      })
    } else if (
      callPossibleReportsCount === 1 &&
      callPossibleReportsNames.length === 1
    ) {
      downloadFileRequest({
        url: `${URL}/service_calls/${callId}/blanks`,
        name: callPossibleReportsNames[0],
      })
    }
  }

  executeButtonAction = button => {
    const {
      props: {
        match: {
          params: { id: callId },
        },
        executeSDCallButtonRequest,
      },
    } = this
    const payload = {
      organizationId: button.organization_id,
      buttonId: button.id,
      data: { callId },
    }
    executeSDCallButtonRequest(payload)
  }

  onDisableActionsClick = orderId => ({ target: { checked } }) => {
    const { toggleDisableScripts } = this.props
    toggleDisableScripts({
      value: checked,
      orderId,
    })
  }

  downloadPhoto = photo => {
    this.props.downloadServiceDeskCallPhotos(photo)
  }

  deletePhoto = params => {
    this.props.deletePhotoServiceDeskCall(params)
  }

  render() {
    const {
      state: {
        editEquipmentDialogOpen,
        editEditResponsibleDialogOpen,
        editInformationForPerformerDialogOpen,
        assignToThirdPartyDialogOpen,
        anchorElAssign,
        assignToOwnSpecialistDialogOpen,
        openCancelCallDialog,
        callCancelReason,
        callCancelReasonValid,
        openCloseCallFromNeedRepair,
        callCancelFromNeedrepairReason,
        callCancelFromNeedrepairReasonValid,
        openSendCallToRepairDiagnosticians,
        openSnackbar,
        snackbarVariant,
        snackbarMessage,
      },
      props: {
        match: {
          params: { id: callId },
        },
        call,
        classes,
        isFetchingCall,
        isFetchingDocs,
        docs,
        isFetchingOfferDocs,
        offerDocs,
        isFetchingPhotos,
        isFetchingPermissions,
        photos,
        match,
        permissions,
        permissionsCommon,
        ordersPermissions,
        callStatus,
        fetchCallPermissions,
        isFetchingCancelRequest,
        activeOrders,
        closedOrders,
        canceledOrders,
        isClosingCallFromNeedrepair,
        isSendingCallToRepairDiagnosticians,
        sendCallUsingContract,
        callButtons,
        orderButtons,
        isExecutingButtonAction,
        disableScriptsForIds,
        callAvailableScripts,
      },
      onUpdateEquipmentInfo,
      onUpdatePerformerInfo,
      onUpdatePerformerResponsible,
      onToggle,
      showAssignMenu,
      uploadDoc,
      uploadPhoto,
      closeCancelCallDialog,
      onCancelCall,
      onReasonEdit,
      onCancelCallFromNeedrepairAction,
      onCancelCallFromNeedrepairReasonEdit,
      closeCancelCallFromNeedrepairDialog,
      onSendCallToRepairDiagnosticians,
      closeSendCallToRepairDiagnosticiansDialog,
      previewBlank,
      onDisableActionsClick,
      downloadPhoto,
      deletePhoto,
    } = this
    const defaultLocation = {
      Street: call.Street,
      home: call.location_repair_home,
      building_id: call.location_repair_building_id,
    }
    let isAnyActionAvailableToCallClient = false
    if (!isEmpty(callStatus) && !isEmpty(permissions)) {
      isAnyActionAvailableToCallClient =
        canAssignToOwnSpecialist(callStatus, permissions) ||
        canAssignToThirdPartyOrganization(callStatus, permissions) ||
        canSendToContract(callStatus, permissions)
      // || canEditAttachments(callStatus, permissions)
      // || canCancelCallFromNeedrepair(callStatus, permissions)
      // || canSendToRepairFromNeedrepair(callStatus, permissions)
      // || canEditRepairOfferDocs(callStatus, permissions)
      // || canCancelCall(callStatus, permissions)
    }
    let isAnyScriptAvailableToCallClient = false
    if (!isEmpty(callAvailableScripts) && !isEmpty(call)) {
      const callOrgId = call.client_organization_id
      isAnyScriptAvailableToCallClient = Boolean(
        _.filter(
          callAvailableScripts,
          script => script.organization_id === callOrgId,
        ).length,
      )
    }
    return isFetchingCall || isFetchingPermissions ? (
      <Loader />
    ) : (
      !isEmpty(call) && !isEmpty(permissions) && call.id === callId && (
        <Fragment>
          <BreadCrumb length={29} to={match.url}>
            {i18next.t('service_desk_web_atoir:titleCallCard', {
              date: new Date(
                Date.parse(this.props.call.createdAt),
              ).toLocaleDateString('ru'),
              internalNum: this.props.call.internal_num,
            })}
          </BreadCrumb>
          <CallMainInfo
            updateData={this.updateData}
            call={call}
            permissions={permissions}
            permissionsCommon={permissionsCommon}
            tags={call.tags}
            disableScriptsForIds={disableScriptsForIds}
            onDisableActionsClick={onDisableActionsClick}
            showDisableActionCheckbox={
              isOnClientCallSide(callStatus, permissions) &&
              isAnyActionAvailableToCallClient &&
              isAnyScriptAvailableToCallClient
            }
            showMakeRecurringButton={canMakeRecurring(callStatus, permissions)}
            assignButtonContent={
              canAssignToThirdPartyOrganization(callStatus, permissions) ||
              canAssignToOwnSpecialist(callStatus, permissions) ? (
                <div className={classes.actionBlock}>
                  <UnpaidTooltip
                    disabled={canOperateButton(
                      permissionsCommon,
                      'calls',
                      'client',
                    )}
                  >
                    <ContainedButton
                      onClick={showAssignMenu}
                      disabled={
                        !canOperateButton(permissionsCommon, 'calls', 'client')
                      }
                    >
                      {i18next.t('service_desk_web_atoir:assignInWork')}
                    </ContainedButton>
                  </UnpaidTooltip>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorElAssign}
                    open={Boolean(anchorElAssign)}
                    onClose={onToggle('anchorElAssign', null)}
                  >
                    {canAssignToThirdPartyOrganization(
                      callStatus,
                      permissions,
                    ) && (
                      <MenuItem
                        onClick={() => {
                          this.setState({
                            assignToThirdPartyDialogOpen: true,
                            anchorElAssign: null,
                          })
                        }}
                      >
                        {i18next.t(
                          'service_desk_web_atoir:fromOrganizationsList',
                        )}
                      </MenuItem>
                    )}
                    {canAssignToOwnSpecialist(callStatus, permissions) && (
                      <MenuItem
                        onClick={() => {
                          this.setState({
                            assignToOwnSpecialistDialogOpen: true,
                            anchorElAssign: null,
                          })
                        }}
                      >
                        {i18next.t('service_desk_web_atoir:fromOwnSpecialist')}
                      </MenuItem>
                    )}
                    {canSendToContract(callStatus, permissions) && (
                      <MenuItem
                        onClick={() =>
                          this.setState({ anchorElAssign: null }, () =>
                            sendCallUsingContract({ callId: call.id }),
                          )
                        }
                      >
                        {i18next.t(
                          'service_desk_web_atoir:sendCallUsingContract',
                        )}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              ) : null
            }
            onEditEquipment={
              canEditWhatsWrong(callStatus, permissions) &&
              onToggle('editEquipmentDialogOpen', true)
            }
            onEditRepairInfo={
              canEditInformationForPerformer(callStatus, permissions)
                ? onToggle('editInformationForPerformerDialogOpen', true)
                : null
            }
            onEditRepairPerformerResponsible={
              canEditResponsible(callStatus, permissions) &&
              onToggle('editEditResponsibleDialogOpen', true)
            }
            onCloseCallFromNeedrepair={
              canCancelCallFromNeedrepair(callStatus, permissions) &&
              onToggle('openCloseCallFromNeedRepair', true)
            }
            onSendToRepairFromNeedrepair={
              canSendToRepairFromNeedrepair(callStatus, permissions) &&
              onToggle('openSendCallToRepairDiagnosticians', true)
            }
            repairPerfomanceInfoVisible={
              !!canViewCallRepairPerfomanceInfo(callStatus, permissions)
            }
            previewBlank={
              canPrintBlank(callStatus, permissions) && previewBlank
            }
            callButtons={callButtons}
            callButtonExecute={this.executeButtonAction}
            callButtonExecuting={isExecutingButtonAction}
          />
          {!!permissions &&
          canViewRepairOfferDocs(
            callStatus,
            permissions,
            !isEmpty(offerDocs),
          ) ? (
            <div className={classes.marginBottom}>
              <RequestDocs
                canEdit={canEditRepairOfferDocs(callStatus, permissions)}
                callId={call.id}
                isFetchingDocs={isFetchingOfferDocs}
                docs={offerDocs}
                upload={uploadDoc}
                fileTypeOffer="offer"
              />
            </div>
          ) : null}
          <CallAttachments
            callId={call.id}
            callCreationDate={call.createdAt}
            canEdit={canEditAttachments(callStatus, permissions)}
            isFetchingDocs={isFetchingDocs}
            docs={docs}
            uploadDoc={uploadDoc}
            isFetchingPhotos={isFetchingPhotos}
            photos={photos}
            uploadPhoto={uploadPhoto}
            downloadPhoto={downloadPhoto}
            deletePhoto={deletePhoto}
          />
          <div id="call-orders-list">
            <RepairDateReminderContext.Provider
              value={this.state.repairDateReminderContext}
            >
              {(activeOrders.length > 0 ||
                closedOrders.length > 0 ||
                canceledOrders.length > 0) && (
                <Title
                  text={i18next.t('service_desk_web_atoir:callOrdersListTitle')}
                />
              )}
              {!isEmpty(ordersPermissions) &&
                _.map(['active', 'closed', 'canceled'], type => (
                  <OrdersList
                    key={type}
                    type={type}
                    render={orders => (
                      <OrdersListRenderer
                        call={call}
                        type={type}
                        isFetching={!isEmpty(orders) && orders.isFetching}
                        orders={!isEmpty(orders) && orders.orders}
                        permissions={ordersPermissions}
                        orderButtons={orderButtons}
                        orderButtonExecute={this.executeButtonAction}
                        orderButtonExecuting={isExecutingButtonAction}
                        callScripts={callAvailableScripts}
                      />
                    )}
                    service_call_id={callId}
                  />
                ))}
            </RepairDateReminderContext.Provider>
          </div>
          {editEquipmentDialogOpen && (
            <EditEquipmentInfoDialog
              comment={call.whats_broken}
              open={editEquipmentDialogOpen}
              onSubmit={onUpdateEquipmentInfo}
              onClose={onToggle('editEquipmentDialogOpen', false)}
              organization={call.client_organization}
              equipment={call.OrganizationEquipment}
              reason={serviceDeskRequestType.DIAGNOSTIC_AND_REPAIR}
            />
          )}
          {editInformationForPerformerDialogOpen && (
            <EditPerformerInfoDialog
              organizationId={call.client_organization_id}
              comment={call.client_comment}
              floor={
                call.location_repair_floor
                  ? `${call.location_repair_floor}`
                  : ''
              }
              responsible={call.client_responsible_id}
              cabinet={call.location_repair_office}
              dateFrom={new Date(Date.parse(call.reaction_date_begin))}
              dateTo={new Date(Date.parse(call.reaction_date_end))}
              defaultLocation={{
                home: call.location_repair_home,
                Street: call.Street,
              }}
              onClose={onToggle('editInformationForPerformerDialogOpen', false)}
              open={editInformationForPerformerDialogOpen}
              onSubmit={onUpdatePerformerInfo}
            />
          )}
          {editEditResponsibleDialogOpen && (
            <EditPerformerResponsibleDialog
              organizationId={call.client_organization_id}
              comment={call.client_comment}
              floor={`${call.location_repair_floor}`}
              responsible={call.client_responsible_id}
              office={call.location_repair_office}
              dateFrom={new Date(Date.parse(call.reaction_date_begin))}
              dateTo={new Date(Date.parse(call.reaction_date_end))}
              location={defaultLocation}
              onClose={onToggle('editEditResponsibleDialogOpen', false)}
              open={editEditResponsibleDialogOpen}
              onSubmit={onUpdatePerformerResponsible}
            />
          )}
          {assignToThirdPartyDialogOpen && (
            <AssignToThirdPartyOrganization
              city={call.Street.city_id}
              callId={call.id}
              onFinish={() => fetchCallPermissions(call.id)}
              callClientOrganization={call.client_organization_id}
              open={assignToThirdPartyDialogOpen}
              onClose={onToggle('assignToThirdPartyDialogOpen', false)}
            />
          )}
          {assignToOwnSpecialistDialogOpen && (
            <AssignToOwnSpecialist
              organizationId={call.client_organization_id}
              onFinish={() => fetchCallPermissions(call.id)}
              callId={call.id}
              open={assignToOwnSpecialistDialogOpen}
              onClose={onToggle('assignToOwnSpecialistDialogOpen', false)}
            />
          )}
          <FormDialog
            open={openCancelCallDialog}
            isLoading={isFetchingCancelRequest}
            title={i18next.t('service_desk_web_atoir:cancelCallCaption')}
            onClose={closeCancelCallDialog}
            onExit={closeCancelCallDialog}
            onSubmit={onCancelCall}
            submitButtonTitle={i18next.t('service_desk_web_atoir:confirm')}
          >
            <TextField
              required
              label={i18next.t('service_desk_web_atoir:cancelCallNote')}
              onChange={onReasonEdit}
              value={callCancelReason}
              variant="outlined"
              multiline
              rows="4"
              className={classes.textFieldMulty}
              margin="normal"
              error={callCancelReasonValid === false}
            />
          </FormDialog>
          <SetRatingDialog
            classes={classes}
            isOpen={false}
            isLoading={false}
            handleClose={() => {}}
            positiveAction={() => {}}
            negativeAction={() => {}}
            rating={{}}
            comment={{}}
          />
          <FormDialog
            open={openCloseCallFromNeedRepair}
            isLoading={isClosingCallFromNeedrepair}
            title={i18next.t(
              'service_desk_web_atoir:cancelCallFromNeedrepairCaption',
            )}
            onClose={closeCancelCallFromNeedrepairDialog}
            onExit={closeCancelCallFromNeedrepairDialog}
            onSubmit={onCancelCallFromNeedrepairAction}
            submitButtonTitle={i18next.t('service_desk_web_atoir:confirm')}
          >
            <TextField
              required
              label={i18next.t(
                'service_desk_web_atoir:cancelCallFromNeedrepairNote',
              )}
              onChange={onCancelCallFromNeedrepairReasonEdit}
              value={callCancelFromNeedrepairReason}
              variant="outlined"
              multiline
              rows="4"
              className={classes.textFieldMulty}
              margin="normal"
              error={callCancelFromNeedrepairReasonValid === false}
            />
          </FormDialog>
          <FormDialog
            open={openSendCallToRepairDiagnosticians}
            isLoading={isSendingCallToRepairDiagnosticians}
            title={i18next.t('service_desk_web_atoir:repairOfferDocsFirstStep')}
            onClose={closeSendCallToRepairDiagnosticiansDialog}
            onExit={closeSendCallToRepairDiagnosticiansDialog}
            onSubmit={onSendCallToRepairDiagnosticians}
            submitButtonTitle={i18next.t('service_desk_web_atoir:confirm')}
            positiveDisabled={!offerDocs.length}
          >
            <Grid container spacing={2}>
              <Grid item>
                <RequestDocs
                  canEdit={canEditRepairOfferDocs(callStatus, permissions)}
                  callId={call.id}
                  isFetchingDocs={isFetchingOfferDocs}
                  docs={offerDocs}
                  upload={uploadDoc}
                  fileTypeOffer="offer"
                  hideTitle
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  className={classNames({
                    [classes.grayText]: !offerDocs.length,
                  })}
                >
                  {i18next.t(
                    'service_desk_web_atoir:repairOfferDocsSecondStep',
                  )}
                </Typography>
              </Grid>
            </Grid>
          </FormDialog>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={this.closeSnackbar}
          >
            <SnackbarElContent
              onClose={this.closeSnackbar}
              variant={snackbarVariant}
              message={snackbarMessage}
            />
          </Snackbar>
        </Fragment>
      )
    )
  }
}

export default withStyles(styles)(CallProfile)
