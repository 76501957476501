import React, { Fragment } from 'react'
import { Chart, Line, Bar } from 'react-chartjs-2'
import isEmpty from 'is-empty'
import moment from 'moment'

const formatLabelByInterval = (data, interval) => {
  let newLables = []
  if (interval === 'year') {
    data.labels.forEach(label => {
      let date = new Date(moment(label, 'DD.MM.YYYY')).getFullYear()
      if (isNaN(date)) date = ''
      newLables.push(date)
    })
  }

  if (interval === 'month') {
    data.labels.forEach(label => {
      if (!label.length) {
        newLables.push(label)
        return
      }

      let date = new Date(moment(label, 'DD.MM.YYYY')).toLocaleString('ru', {
        month: 'long',
      })
      if (typeof date !== 'string' && isNaN(date)) date = ''
      newLables.push(date)
    })
  }

  if (interval === 'day') {
    newLables = data.labels
  }

  return newLables
}

/* eslint-disable */
Chart.pluginService.register({
  beforeInit: chart => {
    chart.legend.afterFit = () => {
      if (chart.legend.margins) {
        chart.legend.options.labels.padding = 30
      }
    }
  },
})
/* eslint-enable */

const InnerCallsChart = props => {
  const { chartData, chartType, options, interval } = props

  if (isEmpty(chartData)) {
    return null
  }

  const chartDataNew = Object.assign({}, chartData)

  if (interval) {
    // Format chart date(label)
    chartDataNew.labels = formatLabelByInterval(chartDataNew, interval)
  }

  const defaultOptions = {
    legend: {
      display: true,
      position: 'bottom',
    },
  }

  return (
    <Fragment>
      {chartType === 'line' && (
        <Line
          data={chartDataNew}
          options={Object.assign({}, defaultOptions, options)}
          width="630"
          height="300"
        />
      )}
      {chartType === 'bar' && (
        <Bar
          data={chartDataNew}
          options={Object.assign(
            {
              scales: {
                yAxes: [
                  {
                    display: true,
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            },
            defaultOptions,
            options,
          )}
          width="630"
          height="300"
        />
      )}
    </Fragment>
  )
}

export default InnerCallsChart
