import InputLabel from '@material-ui/core/InputLabel'
import i18next from '../../../../i18n/i18n'
import Select from '@material-ui/core/Select'
import { statuses } from '../../../../constants/constants'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import React from 'react'

function StatusSelector({ idValue, onChange, value }) {
  const onChangeHandler = ({
    target: {
      value: { id },
    },
  }) => {
    onChange(idValue, id)
  }
  const [_value, setValue] = React.useState(null)
  React.useEffect(() => {
    setValue(statuses.find(status => status.id === value))
  }, [value])
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={idValue}>
        {i18next.t('equipment_web_atoir:equipmentStatus')}
      </InputLabel>
      <Select
        value={_value || ''}
        onChange={onChangeHandler}
        inputProps={{
          id: idValue,
        }}
      >
        {statuses.map(status => (
          <MenuItem key={status.id} value={status}>
            {status.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StatusSelector
