import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import Loader from '../../../Loader'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { validateEmail } from '../../../../helpers/inputDataValidators'
import { organizationLocationStringify } from '../../../../helpers/parserHelper'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  marginSides: {
    marginLeft: 0,
    marginRight: 0,
  },
  marginLeftZero: {
    marginLeft: 0,
  },
  marginRightZero: {
    marginRight: 0,
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

class LinkUserAccountComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      email: '',
      emailShow: true,
      location: null,
      isAdmin: false,
      isManager: false,
      isPlainUser: false,
      errors: {},
    }
  }

  componentDidMount() {
    const { organization, fetchOrganizationLocations } = this.props
    fetchOrganizationLocations(organization.id)
  }

  componentDidUpdate(prevProps) {
    const { isFetchingLocations, locations, edit, user } = this.props
    if (
      prevProps.isFetchingLocations !== isFetchingLocations &&
      !isFetchingLocations
    ) {
      this.setState({
        location: locations[0],
      })
    }
    if (prevProps.edit !== edit && edit) {
      if (!isEmpty(user.user_id)) {
        const location = _.filter(locations, l => l.id === user.location_id)
        this.setState({
          email: user.User.email,
          emailShow: isEmpty(user.User.email),
          isAdmin: user.is_admin,
          isManager: user.is_manager,
          isPlainUser: user.is_plain_user,
          location: location[0],
        })
      } else {
        this.setState({
          email: '',
          emailShow: true,
          location: locations ? locations[0] : null,
          isAdmin: false,
          isManager: false,
          isPlainUser: false,
          errors: {},
        })
      }
    }
    if (prevProps.edit !== edit && !edit) {
      this.setState({
        email: '',
        emailShow: true,
        location: locations ? locations[0] : null,
        isAdmin: false,
        isManager: false,
        isPlainUser: false,
        errors: {},
      })
    }
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleCheckboxChange = name => event => {
    this.setState({
      [name]: event.target.checked,
    })
  }

  handleLocationChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleClose = () => {
    if (this.props.isCreatingUpdatingOrganizationUser) {
      this.props.completeCreateUpdateOrganizationUser()
    }
    this.setState(
      {
        email: '',
        emailShow: true,
        isAdmin: false,
        isManager: false,
        isPlainUser: false,
        location: null,
        errors: {},
      },
      () => this.props.closeDialog,
    )
  }

  handleSkip = () => {
    const { completeCreateUpdateOrganizationUser, closeDialog } = this.props
    completeCreateUpdateOrganizationUser()
    closeDialog()
  }

  handleSubmitForm = event => {
    event.preventDefault()
    const { bindUserAccount } = this
    bindUserAccount()
  }

  bindUserAccount = () => {
    const {
      organization,
      organizationUserId,
      updateOrganizationUser,
    } = this.props
    const { isAdmin, isManager, isPlainUser, location } = this.state
    const email = this.state.email.trim().toLowerCase()
    const emptyErrors = this.validateEmptyFields(
      email,
      isAdmin,
      isManager,
      isPlainUser,
      location,
    )
    if (!isEmpty(emptyErrors)) {
      this.setState({
        errors: emptyErrors,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const { edit, user, bindOrganizationUserAccount } = this.props
    const userId = edit ? user.id : organizationUserId
    const data = {
      is_admin: isAdmin,
      is_manager: isManager,
      is_plain_user: isPlainUser,
      location_id: location ? location.id : undefined,
    }

    if (this.state.emailShow) {
      data.organization_user_id = userId
      data.email = email
      bindOrganizationUserAccount(organization.id, data)
    } else {
      updateOrganizationUser(organization.id, userId, data)
    }
  }

  unbindUserAccount = () => {
    const { organization, user, unbindOrganizationUserAccount } = this.props
    const data = { organization_user_id: user.id }
    unbindOrganizationUserAccount(organization.id, data)
  }

  validateEmptyFields = (email, isAdmin, isManager, isPlainUser, location) => {
    const errors = {}
    if (isEmpty(_.trim(email))) {
      errors.email = i18next.t('validation_web_atoir:fieldShouldBeFilled')
    } else if (!validateEmail(_.trim(email))) {
      errors.email = i18next.t('validation_web_atoir:incorrectEmailFormat')
    }
    if (!isAdmin && !isManager && !isPlainUser) {
      errors.roles = i18next.t(
        'validation_web_atoir:atLeastOneRoleShouldBeSelected',
      )
    }
    if (isEmpty(location)) {
      errors.location = i18next.t(
        'validation_web_atoir:shouldSelectSelectAddress',
      )
    }
    return errors
  }

  render() {
    const { errors, location } = this.state
    const {
      classes,
      isFetchingLocations,
      locations,
      bindUserAccountError,
      edit,
      user,
    } = this.props

    const emailHelperText = !isEmpty(errors.email)
      ? errors.email
      : !isEmpty(bindUserAccountError.email)
      ? bindUserAccountError.email[0]
      : ''

    return (
      <div>
        <Dialog
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          onExiting={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
        >
          <form onSubmit={this.handleSubmitForm} noValidate>
            <DialogTitle id="form-dialog-title">
              {i18next.t(
                'organizations_web_atoir:linkOrganizationUserAccountDialogTitle',
              )}
            </DialogTitle>
            <DialogContent>
              <FormGroup>
                {this.state.emailShow && (
                  <TextField
                    error={
                      !isEmpty(errors.email) ||
                      !isEmpty(bindUserAccountError.email)
                    }
                    required
                    autoFocus
                    id="email"
                    type="email"
                    label={i18next.t('login_web_atoir:email')}
                    placeholder={i18next.t(
                      'validation_web_atoir:enterOrganizationUserEmail',
                    )}
                    className={classes.textField}
                    helperText={emailHelperText}
                    margin="normal"
                    onChange={this.handleTextChange('email')}
                    value={this.state.email}
                  />
                )}
                <br />
                <FormLabel required>
                  {i18next.t('employees_web_atoir:userOrganizationRole')}
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isPlainUser}
                        onChange={this.handleCheckboxChange('isPlainUser')}
                        value="isPlainUser"
                        color="primary"
                      />
                    }
                    label={i18next.t('employees_web_atoir:plainUserRole')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isManager}
                        onChange={this.handleCheckboxChange('isManager')}
                        value="isManager"
                        color="primary"
                      />
                    }
                    label={i18next.t('employees_web_atoir:managerRole')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isAdmin}
                        onChange={this.handleCheckboxChange('isAdmin')}
                        value="isAdmin"
                        color="primary"
                      />
                    }
                    label={i18next.t('employees_web_atoir:administratorRole')}
                  />
                </FormGroup>
                <FormHelperText error={!isEmpty(errors.roles)}>
                  {errors.roles}
                </FormHelperText>
                {isFetchingLocations ? (
                  <Loader />
                ) : (
                  <FormGroup>
                    <FormControl className={classes.formControl} required>
                      <InputLabel htmlFor="select-location">
                        {i18next.t('equipment_web_atoir:selectAddress')}
                      </InputLabel>
                      <Select
                        value={location}
                        onChange={this.handleLocationChange}
                        name="location"
                        input={<Input name="location" id="select-location" />}
                        MenuProps={MenuProps}
                      >
                        {locations.map(loc => (
                          <MenuItem key={loc.id} value={loc}>
                            {organizationLocationStringify(loc)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText error={!isEmpty(errors.location)}>
                      {errors.location}
                    </FormHelperText>
                  </FormGroup>
                )}
              </FormGroup>
            </DialogContent>
            <DialogActions className={classes.marginSides}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 24,
                  paddingRight: 24,
                  margin: 0,
                }}
              >
                <OutlinedButton
                  classes={{ root: classes.marginLeftZero }}
                  onClick={this.handleSkip}
                  size="medium"
                >
                  {i18next.t('shared_web_atoir:cancel')}
                </OutlinedButton>
                {edit && !isEmpty(user.user_id) && !user.is_owner ? (
                  <ContainedButton
                    onClick={this.unbindUserAccount}
                    size="medium"
                  >
                    {i18next.t('organizations_web_atoir:unbindAccount')}
                  </ContainedButton>
                ) : null}
                <ContainedButton
                  classes={{ root: classes.marginRightZero }}
                  size="medium"
                  type="submit"
                >
                  {this.state.emailShow
                    ? i18next.t('organizations_web_atoir:bindAccount')
                    : i18next.t('shared_web_atoir:saveChanges')}
                </ContainedButton>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

LinkUserAccountComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  organizationUserId: PropTypes.string.isRequired,
  fetchOrganizationLocations: PropTypes.func.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  bindOrganizationUserAccount: PropTypes.func.isRequired,
  bindUserAccountError: PropTypes.object.isRequired,
  completeCreateUpdateOrganizationUser: PropTypes.func.isRequired,
  unbindOrganizationUserAccount: PropTypes.func.isRequired,
  isCreatingUpdatingOrganizationUser: PropTypes.bool.isRequired,
}

export default withStyles(styles)(LinkUserAccountComponent)
