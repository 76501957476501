import _ from 'lodash'
import contracts from '../constants/contracts'

const canViewPermissions = [
  'MANAGER_OF_CLIENT_OR_HIGHER',
  'MANAGER_OF_CLIENT',
  'MANAGER_OF_PERFORMER_OR_HIGHER',
  'MANAGER_OF_PERFORMER',
  'RESPONSIBLE_OF_CLIENT',
  'RESPONSIBLE_OF_PERFORMER',
]

const canEditBaseInfoPermissions = [
  'MANAGER_OF_CLIENT_OR_HIGHER',
  'RESPONSIBLE_OF_CLIENT',
]

const canEditResponsibleClientPermissions = ['ADMIN_OF_CLIENT_OR_HIGHER']

const canCancelContractPermissions = {
  created: ['MANAGER_OF_CLIENT_OR_HIGHER', 'RESPONSIBLE_OF_CLIENT'],
  rest: ['ADMIN_OF_CLIENT_OR_HIGHER'],
}

const canEditResponsiblePerformerPermissions = ['ADMIN_OF_PERFORMER_OR_HIGHER']

const canDistribute = [
  'MANAGER_OF_PERFORMER_OR_HIGHER',
  'RESPONSIBLE_OF_PERFORMER',
]

const canAssignContractPermissions = [
  'MANAGER_OF_CLIENT_OR_HIGHER',
  'RESPONSIBLE_OF_CLIENT',
]

const canSendAgreementPermissions = [
  'MANAGER_OF_CLIENT_OR_HIGHER',
  'RESPONSIBLE_OF_CLIENT',
]

const canAcceptRejectAgreementPermissions = [
  'ADMIN_OF_PERFORMER_OR_HIGHER',
  'RESPONSIBLE_OF_PERFORMER',
]

const canEditAgreementPermissions = [
  'MANAGER_OF_CLIENT_OR_HIGHER',
  'RESPONSIBLE_OF_CLIENT',
]

const canCancelAgreementPermissions = {
  created: ['MANAGER_OF_CLIENT_OR_HIGHER', 'RESPONSIBLE_OF_CLIENT'],
  inperformer: ['ADMIN_OF_CLIENT_OR_HIGHER'],
}

const canCreateAgreementPermissions = [
  'MANAGER_OF_CLIENT_OR_HIGHER',
  'RESPONSIBLE_OF_CLIENT',
]

const doIntersectPermissions = (permissions, neededPermissions) =>
  _.intersection(Object.keys(permissions), neededPermissions).some(
    permission => permissions[permission],
  )

export const canViewContractInfo = permissions =>
  doIntersectPermissions(permissions, canViewPermissions)

export const canViewAgreementInfo = permissions =>
  doIntersectPermissions(permissions, canViewPermissions)

export const canSendAgreement = (permissions, agreementStatus) =>
  doIntersectPermissions(permissions, canSendAgreementPermissions) &&
  agreementStatus === contracts.AGREEMENT_STATUS_CREATED

export const canCreateAgreement = (permissions, contractStatus) =>
  doIntersectPermissions(permissions, canCreateAgreementPermissions) &&
  contractStatus === contracts.AGREEMENT_STATUS_INWORK

export const canEditAgreement = (permissions, agreementStatus) =>
  doIntersectPermissions(permissions, canEditAgreementPermissions) &&
  agreementStatus === contracts.AGREEMENT_STATUS_CREATED

export const canCancelAgreement = (permissions, agreementStatus) =>
  (agreementStatus === contracts.AGREEMENT_STATUS_CREATED &&
    doIntersectPermissions(
      permissions,
      canCancelAgreementPermissions.created,
    )) ||
  (agreementStatus === contracts.AGREEMENT_STATUS_INPERFORMER &&
    doIntersectPermissions(
      permissions,
      canCancelAgreementPermissions.inperformer,
    ))

export const canAcceptAgreement = (permissions, agreementStatus) =>
  doIntersectPermissions(permissions, canAcceptRejectAgreementPermissions) &&
  agreementStatus === contracts.AGREEMENT_STATUS_INPERFORMER

export const canRejectAgreement = (permissions, agreementStatus) =>
  doIntersectPermissions(permissions, canAcceptRejectAgreementPermissions) &&
  agreementStatus === contracts.AGREEMENT_STATUS_INPERFORMER

export const canCancelContract = (permission, contractStatus) =>
  (contractStatus === contracts.STATUS_CREATED &&
    doIntersectPermissions(permission, canCancelContractPermissions.created)) ||
  (![contracts.STATUS_CLOSED, contracts.STATUS_CANCELED].includes(
    contractStatus,
  ) &&
    doIntersectPermissions(permission, canCancelContractPermissions.rest))

export const canEditContract = (permission, contractStatus) =>
  contractStatus === contracts.STATUS_CREATED &&
  doIntersectPermissions(permission, canEditBaseInfoPermissions)

export const canDistributeContract = (permission, contractStatus) =>
  contractStatus === contracts.STATUS_INWORK &&
  doIntersectPermissions(permission, canDistribute)

export const canEditClientResponsible = (permission, contractStatus) =>
  [
    contracts.STATUS_CREATED,
    contracts.STATUS_INPERFORMER,
    contracts.STATUS_INWORK,
  ].includes(contractStatus) &&
  doIntersectPermissions(permission, canEditResponsibleClientPermissions)

export const canEditPerformerResponsible = (permission, contractStatus) =>
  contractStatus === contracts.STATUS_INWORK &&
  doIntersectPermissions(permission, canEditResponsiblePerformerPermissions)

export const canAssignContract = (permission, contractStatus) =>
  contractStatus === contracts.STATUS_CREATED &&
  doIntersectPermissions(permission, canAssignContractPermissions)

export const canAcceptContract = (permission, contractStatus) =>
  contractStatus === contracts.STATUS_INPERFORMER &&
  permission.MANAGER_OF_PERFORMER_OR_HIGHER

export const canRejectContract = (permission, contractStatus) =>
  contractStatus === contracts.STATUS_INPERFORMER &&
  permission.MANAGER_OF_PERFORMER_OR_HIGHER
