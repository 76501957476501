import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from '../../../../../../../i18n/i18n'
import { URL } from '../../../../../../../middlewares/api'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom'

// modules
import OrderItemsForm from '../../../../../WorkCompletionReport/OrderItemsForm'
import CommonAddition from '../../../../../WorkCompletionReport/CommonAddition'
import Dialog from '../../../../../../Other/SimpleDialog'

// button
import TextButton from '../../../../../../../widgets/Buttons/TextButton'
import OutlinedButton from '../../../../../../../widgets/Buttons/OutlinedButton'
import Loader from '../../../../../../Loader'
import * as permissionResolvers from '../../../../../../../helpers/callPermissionsResolver'
import UnpaidTooltip from '../../../../UnpaidTooltip'

const styles = theme => ({
  margin: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
})

class OrderCertificate extends Component {
  constructor(props) {
    super(props)
    this.refCertificate = React.createRef()
    this.state = {
      commonAddition: 0,
      openConfirmCommonAdditionDialog: false,
      anchorElFormDocumentsMenu: null,
    }
  }

  handleTextChange = name => event => {
    const {
      target: { value },
    } = event
    const regExp = /^\d+,?\d{0,2}?$/
    if (value !== '' && regExp.test(value)) {
      this.setState({ [name]: value })
    } else if (value === '') {
      this.setState({ [name]: value })
    }
  }

  handleDialogOpen = fieldName => {
    this.setState({ [fieldName]: true })
  }

  handleDialogClose = fieldName => {
    this.setState({
      [fieldName]: false,
    })
  }

  handleCommonAdditionApply = () => {
    const {
      state: { commonAddition },
    } = this
    const updatedItems = this.refCertificate.current.setCommonPercent(
      commonAddition,
    )
    this.saveOrderItemsAppliedAddition(updatedItems)
    this.setState({ openConfirmCommonAdditionDialog: false })
  }

  saveOrderItemsAppliedAddition = items => {
    const itemsForSending = _.omitBy(
      items.map(itm => {
        if (itm.update) {
          return itm
        }
        return null
      }),
      el => el === null,
    )

    const {
      props: { orderId, saveServiceCallOrderItemsListRequest },
    } = this
    const updatedItems = _.map(itemsForSending, updItm =>
      _.omitBy(
        {
          name: updItm.name,
          item_type: updItm.item_type,
          item_category: updItm.item_category,
          oem: updItm.oem,
          units: updItm.units,
          count: updItm.count === null ? null : Number(updItm.count),
          delta: updItm.delta,
          price_purchase:
            updItm.price_purchase === null || updItm.price_purchase === ''
              ? null
              : Number(updItm.price_purchase),
          result_type: updItm.result_type,
          ba_equipment_part_id: updItm.ba_equipment_part_id,
          ba_equipment_part_variant_id: updItm.ba_equipment_part_variant_id,
          percent: updItm.percent === null ? 0 : Number(updItm.percent),
          id: updItm.id,
        },
        e => e === null || e === '',
      ),
    )
    saveServiceCallOrderItemsListRequest({
      order_id: orderId,
      new_items: [],
      updated_items: updatedItems,
      deleted_items: [],
    })
  }

  showFormDocumentsMenu = event => {
    const {
      props: { getOrderPossibleReports, orderId },
    } = this
    getOrderPossibleReports({ orderId })
    this.setState({ anchorElFormDocumentsMenu: event.currentTarget })
  }

  previewReport = item => {
    const {
      props: { getServiceCallOrderCertificateDocumentLinkRequest, orderId },
    } = this
    getServiceCallOrderCertificateDocumentLinkRequest({
      orderId,
      documentId: item.id,
    })
  }

  downloadReport = item => {
    const {
      props: { downloadFileRequest, orderId },
    } = this
    downloadFileRequest({
      url: `${URL}/service_call_orders/${orderId}/reports/${item.id}`,
      name: item.name,
    })
    this.onToggle('anchorElPrintedFormMenu', null)()
  }

  onToggle = (field, value) => () => {
    this.setState({ [field]: value })
  }

  render() {
    const {
      props: {
        classes,
        orderType,
        orderResultType,
        canUpdate,
        commonOrderPermissions,
        orderItems,
        toWorkCompletionForm,
        nds,
        overhead,
        inputNDS,
        isFetchingPossibleReports,
        possibleReports,
        showVersionForClient,
      },
      state: {
        commonAddition,
        openConfirmCommonAdditionDialog,
        anchorElFormDocumentsMenu,
      },
      handleTextChange,
      handleDialogOpen,
      handleDialogClose,
      handleCommonAdditionApply,
    } = this
    return (
      <Fragment>
        {orderItems.orderItemsDiagnostic.length === 0 &&
        orderItems.orderItemsNeedRepair.length === 0 ? (
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.margin}
          >
            <Grid item>
              <Typography variant="h6">
                {`${i18next.t(
                  'service_desk_web_atoir:listOfWorksAndConsumables',
                )}`}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {`${i18next.t('service_desk_web_atoir:isAbsent')}`}
              </Typography>
            </Grid>
            <Grid item>
              {canUpdate && (
                <UnpaidTooltip
                  disabled={permissionResolvers.canOperateButton(
                    commonOrderPermissions,
                    'orders',
                    'performer',
                  )}
                >
                  <TextButton
                    disabled={
                      !permissionResolvers.canOperateButton(
                        commonOrderPermissions,
                        'orders',
                        'performer',
                      )
                    }
                    onClick={toWorkCompletionForm}
                  >
                    {`${i18next.t('shared_web_atoir:create')} >>`}
                  </TextButton>
                </UnpaidTooltip>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.margin}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction="row">
                <Grid item>
                  {canUpdate && (
                    <TextButton onClick={toWorkCompletionForm}>
                      {`${i18next.t('service_desk_web_atoir:moveToEditMode')}`}
                    </TextButton>
                  )}
                </Grid>
                <Grid>
                  <OutlinedButton onClick={this.showFormDocumentsMenu}>
                    {`${i18next.t('service_desk_web_atoir:formDocumentFile')}`}
                  </OutlinedButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                direction="row"
                container
                alignItems="flex-start"
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h6">
                    {`${i18next.t(
                      'service_desk_web_atoir:listOfWorksAndConsumables',
                    )}`}
                  </Typography>
                </Grid>
                <Grid item>
                  {canUpdate && (
                    <CommonAddition
                      classes={classes}
                      commonAddition={commonAddition}
                      onChange={handleTextChange('commonAddition')}
                      onApply={() =>
                        handleDialogOpen('openConfirmCommonAdditionDialog')
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <OrderItemsForm
                orderType={orderType}
                orderResultType={orderResultType}
                itemsDiagnostic={orderItems.orderItemsDiagnostic}
                itemsNeedRepair={orderItems.orderItemsNeedRepair}
                percentSetMixed={value => {
                  if (typeof value === 'boolean') {
                    this.setState({
                      commonAddition: i18next.t('service_desk_web_atoir:mixed'),
                    })
                  } else if (typeof value === 'string') {
                    this.setState({ commonAddition: Number(value) })
                  }
                }}
                percent={commonAddition}
                nds={nds}
                inputNDS={inputNDS}
                overhead={overhead}
                ref={this.refCertificate}
                itemsEditable={false}
                showClientVersion={showVersionForClient}
              />
            </Grid>
          </Grid>
        )}
        <Menu
          id="formDocumentsMenu"
          anchorEl={anchorElFormDocumentsMenu}
          open={Boolean(anchorElFormDocumentsMenu)}
          onClose={this.onToggle('anchorElFormDocumentsMenu', null)}
        >
          {isFetchingPossibleReports ? (
            <Fragment>
              <Loader />
            </Fragment>
          ) : (
            <Fragment>
              {possibleReports.map(r => (
                <MenuItem onClick={() => this.previewReport(r)}>
                  <Fragment>
                    <DownloadIcon className={classes.downloadIcon} />
                    {`${i18next.t(
                      `service_desk_web_atoir:${r.report_type}`,
                    )} (${r.Organization.short_name})`}
                  </Fragment>
                </MenuItem>
              ))}
            </Fragment>
          )}
        </Menu>
        <Dialog
          isOpen={openConfirmCommonAdditionDialog}
          content={i18next.t(
            'service_desk_web_atoir:areYouSureToApplyCommonAddition',
          )}
          handleClose={() =>
            handleDialogClose('openConfirmCommonAdditionDialog')
          }
          handleNegative={() =>
            handleDialogClose('openConfirmCommonAdditionDialog')
          }
          handlePositive={() => handleCommonAdditionApply()}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          positiveText={i18next.t('service_desk_web_atoir:applyAction')}
          positiveButtonVariant="positive"
          closeIconHidded
        />
      </Fragment>
    )
  }
}

OrderCertificate.defaultProps = {
  canUpdate: false,
  nds: 0,
  inputNDS: 0,
  overhead: 0,
  isFetchingPossibleReports: false,
  possibleReports: [],
}

OrderCertificate.propTypes = {
  classes: PropTypes.object.isRequired,
  canUpdate: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  orderResultType: PropTypes.string.isRequired,
  nds: PropTypes.number,
  inputNDS: PropTypes.number,
  overhead: PropTypes.number,
  orderItems: PropTypes.object.isRequired,
  toWorkCompletionForm: PropTypes.func.isRequired,
  getOrderPossibleReports: PropTypes.func.isRequired,
  isFetchingPossibleReports: PropTypes.bool,
  possibleReports: PropTypes.array,
  downloadFileRequest: PropTypes.func.isRequired,
  showVersionForClient: PropTypes.bool.isRequired,
}

export default withStyles(styles)(OrderCertificate)
