import React, { useMemo } from 'react'
import i18next from '../../../../i18n/i18n'
import FormDialog from '../../../../widgets/FormDialog'
import Typography from '@material-ui/core/Typography'

const TrustAcceptOrDenyDialog = ({
  params,
  open,
  acceptTrust,
  rejectTrust,
  closeAcceptOrDenyDialog,
}) => {
  return useMemo(
    () => (
      <FormDialog
        positiveButtonVariant="contained"
        secondaryButtonVariant="applyButton"
        closeIconHidden
        open={open}
        dialogProps={{
          maxWidth: 'sm',
        }}
        isLoading={false}
        title={i18next.t(
          `trusted_organizations_web_atoir:${params.action}_trust_dialog_title`,
        )}
        onClose={closeAcceptOrDenyDialog}
        onExit={closeAcceptOrDenyDialog}
        onSubmit={() =>
          params.action === 'accept'
            ? acceptTrust({ id: params.id })
            : rejectTrust({ id: params.id })
        }
        submitButtonTitle={
          params.action === 'accept'
            ? i18next.t('shared_web_atoir:acceptAction')
            : i18next.t('shared_web_atoir:denyAction')
        }
        secondaryButtonsArray={[
          {
            secondaryText: i18next.t('shared_web_atoir:close'),
            handleSecondaryAction: closeAcceptOrDenyDialog,
          },
          ...(params.action === 'deny'
            ? [
                {
                  secondaryText: i18next.t(
                    'trusted_organizations_web_atoir:rejectTrust',
                  ),
                  handleSecondaryAction: () =>
                    rejectTrust({ id: params.id, hard: true }),
                  variant: 'secondary',
                },
              ]
            : []),
        ]}
      >
        <Typography color="textSecondary" variant="subtitle1">
          {i18next.t(
            `trusted_organizations_web_atoir:${params.sender}_sender_${params.action}_dialog_body`,
            {
              number: params.number,
            },
          )}
        </Typography>
      </FormDialog>
    ),
    [open, params.action, params.sender, params.id],
  )
}

export default TrustAcceptOrDenyDialog
