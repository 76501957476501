import React from 'react'

import i18n from '../../../i18n/i18n'
import FormDialog from '../../../widgets/FormDialog'
import AddTrustedOrganizationsDialogForm from './AddTrustedOrganizationsDialogForm'
import { bindActionCreators } from 'redux'
import {
  createTrust,
  fetchTrustsCountRequest,
  fetchTrustsRequest,
} from '../../../actions/trustedOrganizationActions'
import { connect } from 'react-redux'

const initialData = { side: 'performer' }

function AddTrustedOrganization({
  open,
  doClose,
  callCreateTrust,
  isCreating,
  callFetchTrustsRequest,
  callFetchTrustsCountRequest,
}) {
  const [data, setData] = React.useState(initialData)

  React.useEffect(() => {
    if (open) {
      setData(initialData)
    }
  }, [open])

  const create = () => {
    callCreateTrust(data)
  }

  const [creationStarted, setCreationStarted] = React.useState(false)
  React.useEffect(() => {
    if (isCreating) {
      setCreationStarted(true)
    }
    if (!isCreating && creationStarted) {
      setCreationStarted(false)
      doClose()
      callFetchTrustsRequest()
      callFetchTrustsCountRequest()
    }
  }, [isCreating])

  return (
    <FormDialog
      open={open}
      closeIconHidden
      positiveDisabled={!(data.organizationId && data.contragent_id)}
      submitButtonTitle={i18n.t('shared_web_atoir:add')}
      secondaryButtonsArray={[
        {
          secondaryText: i18n.t('shared_web_atoir:cancel'),
          handleSecondaryAction: doClose,
        },
      ]}
      title={i18n.t(
        'trusted_organizations_web_atoir:trustedOrganizationAdding',
      )}
      onClose={doClose}
      onSubmit={create}
    >
      <AddTrustedOrganizationsDialogForm data={data} setData={setData} />
    </FormDialog>
  )
}

const mapStateToProps = state => ({
  isCreating: state.trustedOrganizations.createTrust.isFetching,
  errorCreating: state.trustedOrganizations.createTrust.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callCreateTrust: createTrust.REQUEST,
      callFetchTrustsRequest: fetchTrustsRequest,
      callFetchTrustsCountRequest: fetchTrustsCountRequest,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTrustedOrganization)
