import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField/TextField'
import FormControlSelectWithSearch from '../../../../../../../widgets/Controlls/FormControlSelectWithSearch'
import i18next from '../../../../../../../i18n/i18n'
import FormControlText from '../../../../../../../widgets/Controlls/FormControlText'
import AddressPicker from '../../../../../../../widgets/AddressPicker'
import DateInput from '../../../../../../../widgets/DateInput'
import FormControlTextPhone from '../../../../../../../widgets/Controlls/FormControlTextPhone'
import { getUserFullName } from '../../../../../../../helpers/userHelper'
import { URL } from '../../../../../../../middlewares/api'
import FormControl from '@material-ui/core/FormControl'
import SelectLimitedAuto from '../../../../../../../widgets/SelectLimitedAuto'
import InputLabel from '@material-ui/core/InputLabel'

class InformationFrom extends Component {
  constructor(props) {
    super(props)
    this.floors = []
    for (let i = -11; i < 101; i++) {
      if (i <= -2) {
        const ind = i + 1
        this.floors.push(`${ind}`)
      } else if (i === -1) {
        this.floors.push('')
      } else {
        this.floors.push(`${i}`)
      }
    }
    this.floors = this.floors.reverse()
  }

  onChangeLocation = location => {
    this.props.onChangeLocation({ target: { value: location } })
  }

  render() {
    const {
      props: {
        onChangeFloor,
        onChangeCabinet,
        onChangeDateFrom,
        onChangeDateTo,
        onChangePhone,
        onChangePosition,
        onChangeResponsible,
        onChangeComment,
        errors,
        defaultLocation,
        cabinet,
        dateFrom,
        dateTo,
        floor,
        location,
        comment,
        canChangeResponsible,
        responsible,
        employees,
        disablePast,
        responsibleOnly,
        organizationId,
        hideComment,
      },
      onChangeLocation,
    } = this
    const chosenResponsible = employees.filter(e => e.id === responsible)[0]
    const { phone, position } = chosenResponsible || {}
    return (
      <Fragment>
        {!responsibleOnly && (
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} xs={6}>
              <DateInput
                requiredValue
                disablePast={disablePast}
                value={dateFrom}
                label={i18next.t('service_desk_web_atoir:requestResponseTime')}
                InputProps={{
                  startAdornment: (
                    <Typography variant="caption" style={{ marginRight: 4 }}>
                      {i18next.t('shared_web_atoir:unitFrom')}
                    </Typography>
                  ),
                }}
                handleChange={date => {
                  onChangeDateFrom({ target: { value: date } })
                }}
              />
              <Typography color="error" align="left">
                {errors.dateFromError}
              </Typography>
            </Grid>
            <Grid item lg={2} md={3} xs={6}>
              <DateInput
                disablePast={disablePast}
                value={dateTo}
                InputProps={{
                  startAdornment: (
                    <Typography variant="caption" style={{ marginRight: 4 }}>
                      {i18next.t('shared_web_atoir:unitTo')}
                    </Typography>
                  ),
                }}
                handleChange={date => {
                  onChangeDateTo({ target: { value: date } })
                }}
              />
              <Typography color="error" align="left">
                {errors.dateToError}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="error" align="left">
                {errors.dateToDateFromError}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          {!responsibleOnly && (
            <Grid item xs={12}>
              <AddressPicker
                required
                defaultLocation={defaultLocation}
                label={`${i18next.t('equipment_web_atoir:placement')}*`}
                fullWidth
                fetchLocation
                value={location}
                onChange={onChangeLocation}
                classes={{}}
                style={{ textAlign: 'left' }}
              />
              <Typography color="error" align="left">
                {errors.locationError}
              </Typography>
            </Grid>
          )}
          {!responsibleOnly && (
            <Grid item xs={6}>
              <FormControlSelectWithSearch
                label={i18next.t(
                  'service_desk_web_atoir:performerInfoFieldFloor',
                )}
                showLabel
                values={this.floors.map(f => ({ id: f, title: f }))}
                onChange={onChangeFloor}
                value={floor}
              />
              <Typography color="error" align="left">
                {errors.floorError}
              </Typography>
            </Grid>
          )}
          {!responsibleOnly && (
            <Grid item xs={6}>
              <FormControlText
                label={i18next.t(
                  'service_desk_web_atoir:performerInfoFieldCabinet',
                )}
                onChange={onChangeCabinet}
                value={cabinet}
              />
              <Typography color="error" align="left">
                {errors.cabinetError}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <FormControl required fullWidth disabled={!canChangeResponsible}>
              <InputLabel htmlFor="age-simple" shrink={!!responsible}>
                {i18next.t('inventory_web_atoir:responsibleForEquipment')}
              </InputLabel>
              <SelectLimitedAuto
                value={responsible}
                sourceUrl={`${URL}/organizations/${organizationId}/users`}
                query={{
                  sort: '[last_name=ASC,first_name=ASC,middle_name=ASC]',
                }}
                onChange={i => onChangeResponsible({ target: { value: i } })}
                requestResultTransformerToArray={r => r.users}
                itemTransformer={l => ({ id: l.id, title: getUserFullName(l) })}
              />
            </FormControl>
            <Typography color="error" align="left">
              {errors.responsibleError}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControlTextPhone
              disabled
              label={i18next.t(
                'service_desk_web_atoir:performerInfoFiledPhone',
              )}
              onChange={onChangePhone}
              value={phone ? phone.substring(1, 11) : ''}
            />
            <Typography color="error" align="left">
              {errors.phoneError}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControlText
              disabled
              label={i18next.t(
                'service_desk_web_atoir:performerInfoFiledPosition',
              )}
              onChange={onChangePosition}
              value={
                responsible
                  ? position === 'owner'
                    ? i18next.t('employees_web_atoir:ownerRole')
                    : position
                  : ''
              }
            />
            <Typography color="error" align="left">
              {errors.positionError}
            </Typography>
          </Grid>
          {!hideComment && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={i18next.t(
                  'inventory_web_atoir:inventoryCommentForPerformer',
                )}
                onChange={onChangeComment}
                value={comment}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    )
  }
}

InformationFrom.defaultProps = {
  disablePast: true,
  cabinet: null,
  dateFrom: new Date(),
  dateTo: new Date(new Date().setMonth(new Date().getMonth() + 1)),
  employees: [],
  floor: '',
  location: null,
  defaultLocation: null,
  comment: null,
  responsibleOnly: false,
  canChangeResponsible: false,
  responsible: null,
  errors: {},
  hideComment: false,
}

InformationFrom.propTypes = {
  onChangeCabinet: PropTypes.func.isRequired,
  onChangeDateFrom: PropTypes.func.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
  onChangeFloor: PropTypes.func.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  onChangeComment: PropTypes.func.isRequired,
  onChangeResponsible: PropTypes.func.isRequired,
  canChangeResponsible: PropTypes.bool,
  defaultLocation: PropTypes.object,
  disablePast: PropTypes.bool,
  responsibleOnly: PropTypes.bool,
  employees: PropTypes.array,
  cabinet: PropTypes.string,
  comment: PropTypes.string,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  errors: PropTypes.object,
  floor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  location: PropTypes.object,
  responsible: PropTypes.string,
  hideComment: PropTypes.bool,
}

export default InformationFrom
