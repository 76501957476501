import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'

// constants
import tagScope from '../../../../../../constants/tagScope'

const styles = () => ({
  list: {
    width: 270,
    padding: 0,
  },
})

function TagScope(props) {
  return (
    <GridList cellHeight={36} cols={3} className={props.classes.list}>
      {props.scope.map(role => (
        <GridListTile
          key={role}
          cols={_.find(tagScope, el => role === el.value).cols || 2}
        >
          <Chip
            label={i18n.t(`organizations_web_atoir:${role}TagScopeValue`)}
          />
        </GridListTile>
      ))}
    </GridList>
  )
}

TagScope.propTypes = {
  scope: PropTypes.array.isRequired,
}

export default withStyles(styles)(TagScope)
