import React, { Component, Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import _ from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import isEmpty from 'is-empty'
import { Redirect } from 'react-router-dom'

// components
import InventoryProjectProfileInfo from './InventoryProjectProfileInfo'
import InventoryAbility from './InventoryProjectAbility/InventoryAbility'
import Loader from '../../../Loader'
import InventoryProjectListOffersClient from './InventoryProjectListOffers/AsClient'
import InventoryProjectListOffersPerformer from './InventoryProjectListOffers/AsPerformer'
import InventoryPerformerInfo from './InventoryPerformerInfo'
import i18next from '../../../../i18n/i18n'

// helpers
import { dateFormat } from '../../../../helpers/dateHelper'
import InventoryListEngineersInWork from './InventoryListEngineersInWork'
import InventoryListOrders from './InventoryListOrders'

class InventoryProjectProfileComponent extends Component {
  componentDidMount() {
    const {
      fetchInventoryProjectProfileRights,
      match,
      fetchInventoryProjectProfile,
    } = this.props
    fetchInventoryProjectProfileRights(match.params.projectId)
    fetchInventoryProjectProfile(match.params.projectId)
  }

  componentDidUpdate = prevProps => {
    const {
      match,
      hideDialogLoader,
      isFetchingInventoryProjectRights,
      inventoryProjectPermissions,
      isPuttingOnAuctionProject,
      puttingOnAuctionProjectError,
      fetchInventoryProjectProfileRights,
      fetchInventoryProjectProfile,
      isStopAuctionFetching,
      isStopAuctionSuccess,
      isConfirmOffer,
      isConfirmOfferSuccess,
      isSettingOwnPerformer,
      setOwnSuccessfully,
      isConfirmingAsPerformer,
      confirmingErrors,
      isSettingForeignPerformer,
      setForeignSuccessfully,
      isFetchingProject,
      projectInfo,
    } = this.props

    if (
      isFetchingInventoryProjectRights !==
        prevProps.isFetchingInventoryProjectRights &&
      isFetchingInventoryProjectRights === false
    ) {
      hideDialogLoader()
      InventoryAbility.update(
        _.toPairs(_.pickBy(inventoryProjectPermissions, p => p)).map(p => ({
          subject: 'Inventory',
          action: p[0],
        })),
      )
    }
    if (
      isPuttingOnAuctionProject !== prevProps.isPuttingOnAuctionProject &&
      !isPuttingOnAuctionProject &&
      isEmpty(puttingOnAuctionProjectError)
    ) {
      fetchInventoryProjectProfileRights(match.params.projectId)
      fetchInventoryProjectProfile(match.params.projectId)
    }
    if (
      prevProps.isStopAuctionSuccess !== isStopAuctionSuccess &&
      !isStopAuctionFetching &&
      isStopAuctionSuccess
    ) {
      fetchInventoryProjectProfile(match.params.projectId)
      fetchInventoryProjectProfileRights(match.params.projectId)
    }
    if (
      prevProps.isConfirmOfferSuccess !== isConfirmOfferSuccess &&
      !isConfirmOffer &&
      isConfirmOfferSuccess
    ) {
      fetchInventoryProjectProfile(match.params.projectId)
      fetchInventoryProjectProfileRights(match.params.projectId)
    }
    if (
      prevProps.isSettingOwnPerformer !== isSettingOwnPerformer &&
      !isSettingOwnPerformer &&
      setOwnSuccessfully
    ) {
      fetchInventoryProjectProfile(match.params.projectId)
      fetchInventoryProjectProfileRights(match.params.projectId)
    }
    if (
      prevProps.isSettingForeignPerformer !== isSettingForeignPerformer &&
      !isSettingForeignPerformer &&
      setForeignSuccessfully
    ) {
      fetchInventoryProjectProfile(match.params.projectId)
      fetchInventoryProjectProfileRights(match.params.projectId)
    }
    if (
      prevProps.isConfirmingAsPerformer !== isConfirmingAsPerformer &&
      !isConfirmingAsPerformer &&
      isEmpty(confirmingErrors)
    ) {
      fetchInventoryProjectProfile(match.params.projectId)
      fetchInventoryProjectProfileRights(match.params.projectId)
    }
    if (
      !isFetchingInventoryProjectRights &&
      !isFetchingProject &&
      inventoryProjectPermissions &&
      !isEmpty(inventoryProjectPermissions) &&
      inventoryProjectPermissions.view &&
      !isEmpty(projectInfo)
    ) {
      const kitDescription = projectInfo.inventory_kit
        ? projectInfo.inventory_type === 'qr'
          ? `${i18next.t('inventory_web_atoir:havePrintingDevice')}`
          : `${i18next.t('inventory_web_atoir:haveReadingDevice')}`
        : projectInfo.inventory_type === 'qr'
        ? `${i18next.t('inventory_web_atoir:noPrintingDevice')}`
        : `${i18next.t('inventory_web_atoir:noReadingDevice')}`

      this.props.getTitleInfo({
        title: i18next.t('inventory_web_atoir:projectInventory', {
          type: projectInfo.inventory_type.toUpperCase(),
          date: dateFormat(projectInfo.date_created),
        }),
        status: i18next.t(`inventory_web_atoir:${projectInfo.status}`),
        substringLeft: {
          title: kitDescription,
          doneIcon: projectInfo.inventory_kit,
        },
        historyChanges: {
          title: i18next.t('shared_web_atoir:changeLog'),
          link: `/inventory_projects/${this.props.match.params.projectId}/change_log`,
        },
      })
    }
  }

  componentWillUnmount() {
    const {
      props: {
        fetchInventoryProjectProfileRightsDefault,
        fetchInventoryProjectProfileDefault,
      },
    } = this
    fetchInventoryProjectProfileRightsDefault()
    fetchInventoryProjectProfileDefault()
  }

  render = () => {
    const {
      classes,
      location,
      isFetchingInventoryProjectRights,
      isFetchingProject,
      inventoryProjectPermissions,
      projectInfo,
    } = this.props

    return isFetchingInventoryProjectRights || isFetchingProject ? (
      <Loader />
    ) : isEmpty(inventoryProjectPermissions) ? (
      <div />
    ) : inventoryProjectPermissions.view ? (
      <Fragment>
        <Paper className={classes.root}>
          <InventoryProjectProfileInfo
            location={location}
            permissions={inventoryProjectPermissions}
          />
          {!isEmpty(projectInfo) &&
          !isEmpty(projectInfo.status) &&
          projectInfo.status === 'auction' ? (
            <Fragment>
              {!isEmpty(inventoryProjectPermissions.accept_offer) &&
              inventoryProjectPermissions.accept_offer ? (
                <InventoryProjectListOffersClient />
              ) : (
                <div />
              )}
              {!isEmpty(inventoryProjectPermissions.cancel_offer) &&
              inventoryProjectPermissions.cancel_offer ? (
                <InventoryProjectListOffersPerformer />
              ) : (
                <div />
              )}
            </Fragment>
          ) : (
            <div />
          )}
          {!isEmpty(projectInfo) &&
            !isEmpty(projectInfo.status) &&
            (projectInfo.status === 'inperformer' ||
              projectInfo.status === 'inwork' ||
              projectInfo.status === 'done' ||
              projectInfo.status === 'close') && <InventoryPerformerInfo />}
        </Paper>
        <br />
        <Paper elevation={2}>
          <InventoryListEngineersInWork />
        </Paper>
        {projectInfo && projectInfo.status === 'inwork' && (
          <Fragment>
            <Paper elevation={2}>
              <InventoryListOrders />
            </Paper>
          </Fragment>
        )}
      </Fragment>
    ) : (
      <Redirect to="/404" />
    )
  }
}

const styles = () => ({
  root: {},
})

InventoryProjectProfileComponent.defaultProps = {
  projectInfo: null,
}

InventoryProjectProfileComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  projectInfo: PropTypes.object,
  fetchInventoryProjectProfileRights: PropTypes.func.isRequired,
  isFetchingInventoryProjectRights: PropTypes.bool.isRequired,
  inventoryProjectPermissions: PropTypes.object.isRequired,
}

export default withStyles(styles)(InventoryProjectProfileComponent)
