import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'

// components
import ColorButton from './ColorButton'
import colors from '../../constants/tagColors'

const styles = theme => ({
  container: {
    marginLeft: theme.spacing(2),
  },
  gridList: {
    width: 180,
    height: 130,
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})

class ColorPalette extends Component {
  onSelect = color => {
    const {
      props: { onColorSelect },
    } = this
    onColorSelect(color)
  }

  render() {
    const {
      props: { classes, selectedColor },
      onSelect,
    } = this
    return (
      <Fragment>
        <div className={classes.container}>
          <GridList cellHeight={28} className={classes.gridList} cols={5}>
            {colors.map(color => (
              <GridListTile key={color} cols={1}>
                <ColorButton
                  color={color}
                  selected={selectedColor === color}
                  notSelected={
                    !isEmpty(selectedColor) && selectedColor !== color
                  }
                  onSelect={onSelect}
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      </Fragment>
    )
  }
}

ColorPalette.defaultProps = {
  selectedColor: '',
}

ColorPalette.propTypes = {
  selectedColor: PropTypes.string,
  onColorSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(ColorPalette)
