import { takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/scriptActions'
import * as constants from '../constants/actionTypes/scripts'
import * as api from './api'
import { createDefaultSaga } from '../helpers/api'

export function* watchCreateScriptSaga() {
  yield takeLatest(
    constants.CREATE_SCRIPT_REQUEST,
    createDefaultSaga(
      payload => api.createScriptRequestApi(payload),
      result => actions.createScriptSuccess(result),
      code => actions.createScriptFailure(code),
      true,
    ),
  )
}
export function* watchEditScriptSaga() {
  yield takeLatest(
    constants.EDIT_SCRIPT_REQUEST,
    createDefaultSaga(
      payload => api.editScriptRequestApi(payload),
      result => actions.editScriptSuccess(result),
      code => actions.editScriptFailure(code),
      true,
    ),
  )
}

export function* watchGetScriptsSaga() {
  yield takeLatest(
    constants.GET_SCRIPTS_REQUEST,
    createDefaultSaga(
      payload => api.getScriptsRequestApi(payload),
      result =>
        actions.getScriptsSuccess(
          result.triggers.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      code => actions.getScriptsFailure(code),
      false,
    ),
  )
}

export function* watchDeleteScriptSaga() {
  yield takeLatest(
    constants.DELETE_SCRIPT_REQUEST,
    createDefaultSaga(
      payload => api.deleteScriptRequestApi(payload),
      result => actions.deleteScriptSuccess(result),
      code => actions.deleteScriptFailure(code),
      false,
    ),
  )
}

export function* watchGetScriptSaga() {
  yield takeLatest(
    constants.GET_SCRIPT_REQUEST,
    createDefaultSaga(
      payload => api.getScriptRequestApi(payload),
      result => actions.getScriptSuccess(result.trigger),
      code => actions.getScriptFailure(code),
      true,
    ),
  )
}

export function* watchGetScriptsHistorySaga() {
  yield takeLatest(
    constants.GET_SCRIPTS_HISTORY_REQUEST,
    createDefaultSaga(
      payload => api.getScriptsHistoryRequestApi(payload),
      (result, payload) =>
        actions.getScriptsHistorySuccess({
          history: result.customActions,
          end:
            payload.query.limit &&
            result.customActions.length < payload.query.limit,
        }),
      code => actions.getScriptsHistoryFailure(code),
      false,
    ),
  )
}

export function* watchGetSDCallAvailableScriptsSaga() {
  yield takeLatest(
    constants.GET_SD_CALL_AVAILABLE_SCRIPTS_REQUEST,
    createDefaultSaga(
      payload => api.getSDCallAvailableScriptsRequestApi(payload),
      result => actions.getSDCallAvailableScriptsSuccess(result.scripts),
      code => actions.getSDCallAvailableScriptsFailure(code),
      true,
    ),
  )
}
