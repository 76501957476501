import { createRequestTypes } from '../actionTypes'

export const FETCH_INNER_CALLS_REQUEST = 'innerCalls/FETCH_INNER_CALLS_REQUEST'
export const FETCH_INNER_CALLS_SUCCESS = 'innerCalls/FETCH_INNER_CALLS_SUCCESS'
export const FETCH_INNER_CALLS_FAILURE = 'innerCalls/FETCH_INNER_CALLS_FAILURE'

export const FETCH_INNER_CALLS_COUNT_ALL_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_COUNT_ALL_REQUEST'
export const FETCH_INNER_CALLS_COUNT_ALL_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_COUNT_ALL_SUCCESS'
export const FETCH_INNER_CALLS_COUNT_ALL_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_COUNT_ALL_FAILURE'

export const FETCH_INNER_CALLS_COUNT_CREATED_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_COUNT_CREATED_REQUEST'
export const FETCH_INNER_CALLS_COUNT_CREATED_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_COUNT_CREATED_SUCCESS'
export const FETCH_INNER_CALLS_COUNT_CREATED_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_COUNT_CREATED_FAILURE'

export const FETCH_INNER_CALLS_COUNT_CLOSED_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_COUNT_CLOSED_REQUEST'
export const FETCH_INNER_CALLS_COUNT_CLOSED_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_COUNT_CLOSED_SUCCESS'
export const FETCH_INNER_CALLS_COUNT_CLOSED_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_COUNT_CLOSED_FAILURE'

export const FETCH_INNER_CALLS_NUMBERS_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_NUMBERS_REQUEST'
export const FETCH_INNER_CALLS_NUMBERS_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_NUMBERS_SUCCESS'
export const FETCH_INNER_CALLS_NUMBERS_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_NUMBERS_FAILURE'

export const FETCH_INNER_CALLS_ENGINEERS_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_ENGINEERS_REQUEST'
export const FETCH_INNER_CALLS_ENGINEERS_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_ENGINEERS_SUCCESS'
export const FETCH_INNER_CALLS_ENGINEERS_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_ENGINEERS_FAILURE'

export const FETCH_INNER_CALLS_EQUIPMENTS_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_EQUIPMENTS_REQUEST'
export const FETCH_INNER_CALLS_EQUIPMENTS_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_EQUIPMENTS_SUCCESS'
export const FETCH_INNER_CALLS_EQUIPMENTS_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_EQUIPMENTS_FAILURE'

export const SET_PAGINATION = 'innerCalls/SET_PAGINATION'
export const CLEAR_PAGINATION = 'innerCalls/CLEAR_PAGINATION'

export const FETCH_INNER_CALL_REQUEST = 'innerCalls/FETCH_INNER_CALL_REQUEST'
export const FETCH_INNER_CALL_SUCCESS = 'innerCalls/FETCH_INNER_CALL_SUCCESS'
export const FETCH_INNER_CALL_FAILURE = 'innerCalls/FETCH_INNER_CALL_FAILURE'

export const FETCH_INNER_CALL_HISTORY_REQUEST =
  'innerCalls/FETCH_INNER_CALL_HISTORY_REQUEST'
export const FETCH_INNER_CALL_HISTORY_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_HISTORY_SUCCESS'
export const FETCH_INNER_CALL_HISTORY_FAILURE =
  'innerCalls/FETCH_INNER_CALL_HISTORY_FAILURE'
export const CLEAR_INNER_CALL_HISTORY = 'innerCalls/CLEAR_INNER_CALL_HISTORY'

export const FETCH_INNER_CALL_PHOTOS_REQUEST =
  'innerCalls/FETCH_INNER_CALL_PHOTOS_REQUEST'
export const FETCH_INNER_CALL_PHOTOS_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_PHOTOS_SUCCESS'
export const FETCH_INNER_CALL_PHOTOS_FAILURE =
  'innerCalls/FETCH_INNER_CALL_PHOTOS_FAILURE'

export const FETCH_INNER_CALL_PHOTOS_COUNT_REQUEST =
  'innerCalls/FETCH_INNER_CALL_PHOTOS_COUNT_REQUEST'
export const FETCH_INNER_CALL_PHOTOS_COUNT_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_PHOTOS_COUNT_SUCCESS'
export const FETCH_INNER_CALL_PHOTOS_COUNT_FAILURE =
  'innerCalls/FETCH_INNER_CALL_PHOTOS_COUNT_FAILURE'

export const UPLOAD_INNER_CALL_PHOTO_REQUEST =
  'innerCalls/UPLOAD_INNER_CALL_PHOTO_REQUEST'
export const UPLOAD_INNER_CALL_PHOTO_SUCCESS =
  'innerCalls/UPLOAD_INNER_CALL_PHOTO_SUCCESS'
export const UPLOAD_INNER_CALL_PHOTO_FAILURE =
  'innerCalls/UPLOAD_INNER_CALL_PHOTO_FAILURE'

export const DELETE_INNER_CALL_PHOTO_REQUEST =
  'innerCalls/DELETE_INNER_CALL_PHOTO_REQUEST'
export const DELETE_INNER_CALL_PHOTO_SUCCESS =
  'innerCalls/DELETE_INNER_CALL_PHOTO_SUCCESS'
export const DELETE_INNER_CALL_PHOTO_FAILURE =
  'innerCalls/DELETE_INNER_CALL_PHOTO_FAILURE'

export const FETCH_INNER_CALL_ANALYTICS_SUMMARY_REQUEST =
  'innerCalls/FETCH_INNER_CALL_SUMMARY_REQUEST'
export const FETCH_INNER_CALL_ANALYTICS_SUMMARY_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_SUMMARY_SUCCESS'
export const FETCH_INNER_CALL_ANALYTICS_SUMMARY_FAILURE =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_SUMMARY_FAILURE'

export const FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_REQUEST =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_REQUEST'
export const FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_SUCCESS'
export const FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_FAILURE =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_FAILURE'

export const FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_REQUEST =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_REQUEST'
export const FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_SUCCESS'
export const FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_FAILURE =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_FAILURE'

export const FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_REQUEST =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_REQUEST'
export const FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_SUCCESS'
export const FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_FAILURE =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_FAILURE'

export const FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_REQUEST =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_REQUEST'
export const FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_SUCCESS =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_SUCCESS'
export const FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_FAILURE =
  'innerCalls/FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_FAILURE'

export const FETCH_INNER_CALLS_ENGINEERS_COUNT_REQUEST =
  'innerCalls/FETCH_INNER_CALLS_ENGINEERS_COUNT_REQUEST'
export const FETCH_INNER_CALLS_ENGINEERS_COUNT_SUCCESS =
  'innerCalls/FETCH_INNER_CALLS_ENGINEERS_COUNT_SUCCESS'
export const FETCH_INNER_CALLS_ENGINEERS_COUNT_FAILURE =
  'innerCalls/FETCH_INNER_CALLS_ENGINEERS_COUNT_FAILURE'

export const GET_ORGANIZATIONS_TRUSTED = createRequestTypes(
  'innerCalls/GET_ORGANIZATIONS_TRUSTED',
)

export const GET_INNER_CALLS_PERFORMER_EQUIPMENTS = createRequestTypes(
  'innerCalls/GET_INNER_CALLS_PERFORMER_EQUIPMENTS',
)
export const CREATE_INNER_CALL = createRequestTypes(
  'innerCalls/CREATE_INNER_CALL',
)
export const EDIT_INNER_CALL = createRequestTypes('innerCalls/EDIT_INNER_CALL')
export const CLOSE_INNER_CALL = createRequestTypes(
  'innerCalls/CLOSE_INNER_CALL',
)
export const ASSIGNEE_INNER_CALL = createRequestTypes(
  'innerCalls/ASSIGNEE_INNER_CALL',
)

export const SET_COMMON_INNER_CALLS_LIST_STATE =
  'innerCalls/SET_COMMON_INNER_CALLS_LIST_STATE'
