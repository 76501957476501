import TextField from '@material-ui/core/TextField'
import React from 'react'
import i18next from '../../../i18n/i18n'

function ModelInput({
  value,
  onChange,
  idValue,
  disabled,
  modelTouched,
  onFocusModel,
}) {
  const onChangeHandler = React.useCallback(
    function({ target: { value: _value } }) {
      onChange(idValue, _value)
    },
    [onChange],
  )
  return (
    <TextField
      error={modelTouched && value.length < 3}
      disabled={disabled}
      fullWidth
      id={idValue}
      value={value}
      onChange={onChangeHandler}
      label={i18next.t('equipment_web_atoir:model')}
      onFocus={onFocusModel}
    />
  )
}

export default ModelInput
