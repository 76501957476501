import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// material-ui
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

// components
import ActionViewer from './ActionViewer'
import DelayedLoader from '../../../../../widgets/DelayedLoader'

class HistoryViewer extends PureComponent {
  state = {}

  render() {
    const {
      props: { classes, history },
    } = this
    return (
      <Table>
        <DelayedLoader delay={300} show={!history} />
        <TableBody>
          {history &&
            history.map(action => (
              <ActionViewer key={action.id} classes={classes} action={action} />
            ))}
        </TableBody>
      </Table>
    )
  }
}

HistoryViewer.propTypes = {
  history: PropTypes.array.isRequired,
}

export default HistoryViewer
