import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// import Downshift from 'downshift'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { receivedErrorMessage } from '../../../actions/appActions'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { validateAlphabetAndNumbers } from '../../../helpers/inputDataValidators'
import { withStyles } from '@material-ui/core/styles/index'
import DropDownList from '../../../widgets/DropDownList/DropDownList'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import Typography from '@material-ui/core/Typography/Typography'
import AddressPicker from '../../../widgets/AddressPicker'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    minWidth: 100,
  },
  dropDownForm: {
    marginTop: theme.spacing * 10,
  },
  selectFloorSelect: {},
  textField: {
    width: 250,
  },
})

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.value}
    </MenuItem>
  )
}

renderSuggestion.propTypes = {
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

const LocationTitle = withStyles({
  divMargin: {
    marginBottom: -30,
  },
})(({ name, classes }) => (
  <Fragment>
    <div className={classes.divMargin}>
      <Typography variant="subtitle1">{name}</Typography>
    </div>
  </Fragment>
))

class EditLocationComponent extends React.Component {
  constructor(props) {
    super(props)
    let { locationData } = props
    locationData = locationData || {}
    this.state = {
      menuIsOpen: false,
      inputValue: '',
      isChanged: false,
      floorSelectOpen: false,
      selectedItem: '',
      name: props.legal
        ? i18next.t('organizations_web_atoir:legalAddress')
        : props.phisycal
        ? i18next.t('organizations_web_atoir:physicalAddress')
        : locationData.name,
      floor: locationData.floor ? `${locationData.floor}` : '',
      office: locationData.office || '',
      suggestions: [],
      isFullAddress: undefined,
      locationType: props.phisycal
        ? 0
        : props.legal
        ? 1
        : locationData.location_type !== undefined
        ? locationData.location_type
        : 2,
    }

    for (let i = -11; i < 101; i++) {
      if (i <= -2) {
        const ind = i + 1
        this.floors.push(`${ind}`)
      } else if (i === -1) {
        this.floors.push('')
      } else {
        this.floors.push(`${i}`)
      }
    }
    this.floors = this.floors.reverse()
    this.floorInput = React.createRef()
  }

  floors = []

  handleChange = selectedItem => {
    const isFullAddress =
      selectedItem && selectedItem.location && selectedItem.location.data
        ? Boolean(selectedItem.location.home)
        : undefined
    this.setState(
      {
        inputValue: '',
        selectedItem,
        isFullAddress,
      },
      () => {
        this.updateParentData({
          location: selectedItem,
          isFullAddress,
          isChanged: this.state.isChanged,
        })
      },
    )
  }

  onNameChange = event => {
    this.setState({
      name: event.currentTarget.value,
      isChanged: true,
      isNameValid: event.currentTarget.value.length > 0,
    })
    this.updateParentData({ name: event.currentTarget.value })
  }

  onOfficeChange = event => {
    if (event.currentTarget.value.length >= 100) {
      return
    }
    const withNoNumbers = event.currentTarget.value.replace(/[0-9]/gi, '')
    const updateField = () => {
      this.setState({
        office:
          event.currentTarget.value === '' ? null : event.currentTarget.value,
        isChanged: true,
      })
      this.updateParentData({ office: event.currentTarget.value })
    }
    if (withNoNumbers.length === 0) {
      updateField()
    } else if (validateAlphabetAndNumbers(withNoNumbers)) {
      updateField()
    }
  }

  handleToggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  handleChangeFloor = event => {
    this.setState({ floor: event.target.value, isChanged: true })
    this.updateParentData({ floor: event.target.value })
  }

  updateParentData(field) {
    const {
      selectedItem,
      name,
      office,
      floor,
      locationType,
      isChanged,
    } = this.state
    const isValid = this.validate(field)
    this.props.onChange({
      location: selectedItem,
      name,
      floor,
      office,
      isValid,
      locationType,
      ...field,
      isChanged,
    })
  }

  validate = field => {
    const { name, isFullAddress, office, floor } = { ...this.state, ...field }

    if ((Boolean(office) && !floor) || (Boolean(floor) && !office)) {
      return false
    }

    if (!(name && name !== '' && isFullAddress)) {
      return false
    }
    return true
  }

  render() {
    const { classes, denyNameEditing, locationData, hideLabel } = this.props
    const {
      name,
      office,
      floor,
      isNameValid,
      locationType,
      floorSelectOpen,
    } = this.state

    return (
      <div>
        <Grid container spacing={4} classes="center">
          <Grid item xs={12}>
            {denyNameEditing && name ? (
              <LocationTitle name={name} />
            ) : (
              <TextField
                label={i18next.t(
                  'organizations_web_atoir:locationNameInputLabel',
                )}
                id="location-name"
                required
                disabled={+locationType !== 2}
                value={name}
                error={isNameValid === false}
                className={classNames(classes.margin, classes.textField)}
                onChange={this.onNameChange}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <AddressPicker
              required
              defaultLocation={locationData}
              label={
                hideLabel
                  ? undefined
                  : i18next.t('equipment_web_atoir:placement')
              }
              fullWidth
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <TextField
                label={i18next.t('shared_web_atoir:office')}
                id="office-name"
                value={office}
                className={classNames(classes.margin, classes.textField)}
                onChange={this.onOfficeChange}
              />
              {Boolean(floor) && !office ? (
                <FormHelperText error>
                  {i18next.t('organizations_web_atoir:missedOffice')}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.dropDownForm}>
              <DropDownList
                classes={classes}
                value={floor.toString()}
                showLabel
                title={i18next.t('equipment_web_atoir:floor')}
                open={floorSelectOpen}
                handleOpen={this.handleToggle('floorSelectOpen', true)}
                handleChange={this.handleChangeFloor}
                handleClose={this.handleToggle('floorSelectOpen', false)}
                label={i18next.t('equipment_web_atoir:floor')}
                elements={this.floors.map(f => ({
                  id: f,
                  title: f.toString(),
                }))}
                isFetching={false}
              />
              {Boolean(office) && !floor ? (
                <FormHelperText error>
                  {i18next.t('organizations_web_atoir:missedFloor')}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }
}

EditLocationComponent.defaultProps = {
  denyNameEditing: false,
  locationData: null,
  hideLabel: false,
}

EditLocationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  denyNameEditing: PropTypes.bool,
  hideLabel: PropTypes.bool,
  locationData: PropTypes.object,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      receivedErrorMessage,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(EditLocationComponent))
