import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showDialogWithOk } from '../../../../actions/appActions'
import OpenFileComponent from './OpenFileComponent'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showDialogWithOk,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(OpenFileComponent)
