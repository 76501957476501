import * as XLSX from 'xlsx'

function grabHeaders(file) {
  return new Promise((res, rej) => {
    const reader = new FileReader()
    reader.onload = evt => {
      const bstr = evt.target.result
      const headersList = []
      try {
        const wb = XLSX.read(bstr, { type: 'binary', sheetRows: 1 })
        _.mapValues(wb.Sheets[wb.SheetNames[0]], (cell, cellName) => {
          if (cellName[0] !== '!') {
            headersList.push({
              value: cell.v,
              collName: cellName.slice(0, -1),
              collIndex: XLSX.utils.decode_col(cellName.slice(0, -1)),
            })
          }
        })
      } catch (e) {
        rej(e)
      }
      res(_.sortBy(headersList, ['value']))
    }
    reader.readAsBinaryString(file)
  })
}

export default grabHeaders
