import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'

const style = () => ({
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    borderBottom: '1px dotted black',
  },
  tooltipText: {
    visibility: 'hidden',
    backgroundColor: '#DEDEDE',
    color: ' #000',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '5px 5px',
    position: 'absolute',
    zIndex: '1',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%)',

    opacity: 0,
    transition: 'opacity 1s',
  },
  '@global': {
    '.tooltip-ToolTipBox:hover .tooltip-tooltipText': {
      visibility: 'visible',
      opacity: 1,
    },
  },
})

function ToolTipBox({ classes, children, text }) {
  return (
    <div className={classNames([classes.tooltip, 'tooltip-ToolTipBox'])}>
      <React.Fragment>
        {children}
        <span
          className={classNames([classes.tooltipText, 'tooltip-tooltipText'])}
        >
          <Typography variant="caption" display="block" color="inherit">
            {text}
          </Typography>
        </span>
      </React.Fragment>
    </div>
  )
}

ToolTipBox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
}

export default withStyles(style)(ToolTipBox)
