import React from 'react'

const DialogManager = ({ onSubmit, children, dialog }) => {
  const [open, setOpen] = React.useState(false)
  function openDialog() {
    setOpen(true)
  }
  function onSubmitHandler(payload) {
    setOpen(false)
    onSubmit(payload)
  }
  function onCloseHandler() {
    setOpen(false)
  }
  return (
    <>
      {dialog({ open, onSubmit: onSubmitHandler, onClose: onCloseHandler })}
      {children({ openDialog })}
    </>
  )
}

export default DialogManager
