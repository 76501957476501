import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import BreadCrumb from '../../App/routing/BreadCrumb'
// eslint-disable-next-line no-unused-vars
import { compactObject, popLastSections } from '../../../helpers/dataHelper'
import i18next from '../../../i18n/i18n'
import {
  fetchEquipmentDetails,
  updateEquipmentDetails,
} from '../../../actions/equipmentActions'
import { fetchOrganization } from '../../../actions/organizationsActions'
import { connect } from 'react-redux'
import EquipmentEditor from '../EquipmentEditor'
import Loader from '../../Loader'

function EditEquipmentUnit({
  organization,
  equipment,
  callUpdateEquipmentDetails,
  match,
  callFetchEquipmentDetails,
  callFetchOrganization,
  isUpdatingEquipment,
  updatingEquipmentError,
  isFetchingEquipment,
  match: {
    params: { organizationId, equipmentId },
  },
  history,
}) {
  // load needed init data
  React.useEffect(() => {
    callFetchOrganization(organizationId)
    callFetchEquipmentDetails(organizationId, equipmentId)
  }, [])

  React.useEffect(() => {}, [equipment])

  // goto equipment profile on success
  const [prevIsUpdatingEquipment, setPrevIsUpdatingEquipment] = React.useState()

  React.useEffect(() => {
    if (
      prevIsUpdatingEquipment &&
      !isUpdatingEquipment &&
      !updatingEquipmentError
    ) {
      history.push(
        `/organizations/${organizationId}/equipment/equipments/${equipment.id}/information`,
      )
    }
    setPrevIsUpdatingEquipment(isUpdatingEquipment)
  }, [isUpdatingEquipment])

  function submit(payload) {
    callUpdateEquipmentDetails(organizationId, equipment.id, payload)
  }

  return (
    <Fragment>
      <BreadCrumb to={popLastSections(match.url, 4)}>
        {organization.short_name}
      </BreadCrumb>
      <BreadCrumb to={match.url}>
        {i18next.t('equipment_web_atoir:editEquipmentUnit')}
      </BreadCrumb>
      {isFetchingEquipment ? (
        <Loader />
      ) : (
        <EquipmentEditor
          equipment={equipment}
          organizationId={organizationId}
          onSubmit={submit}
        />
      )}
    </Fragment>
  )
}

EditEquipmentUnit.propTypes = {
  organization: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  callFetchOrganization: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  isFetchingEquipment:
    state.equipment.equipmentDetails.isFetchingEquipmentDetails,
  equipmentError: state.equipment.equipmentDetails.error,
  equipment: state.equipment.equipmentDetails.equipment,
  isUpdatingEquipment:
    state.equipment.updateEquipmentDetails.isUpdatingEquipmentDetails,
  updatingEquipmentError: state.equipment.updateEquipmentDetails.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callFetchOrganization: fetchOrganization,
      callFetchEquipmentDetails: fetchEquipmentDetails,
      callUpdateEquipmentDetails: updateEquipmentDetails,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditEquipmentUnit),
)
