import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import RLink from 'react-router-dom/Link'
import { statuses } from '../../../constants/constants'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import DropDownList from '../../../widgets/DropDownList'

// TPL
import Loader from '../../Loader'
import DateInput from '../../../widgets/DateInput'
import SelectAuto from '../../../widgets/SelectAuto'
import { URL } from '../../../middlewares/api'

// helper
import { organizationLocationStringify } from '../../../helpers/parserHelper'
import EquipmentSuggestionsIndicator from './EquipmentSuggestionsIndicator'
import InputLabel from '@material-ui/core/InputLabel'

class EnhancedTableHead extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  handleChange = field => event => {
    const { updateFilters } = this.props
    updateFilters(field, event && event.target ? event.target.value : event)
  }

  handleChangeData = field => date => {
    const { updateFilters } = this.props
    updateFilters(field, date)
  }

  handleOpen = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleClose = field => () => {
    this.setState({
      [field]: false,
    })
  }

  handleRequestSort = property => () => {
    let orderBy = property
    const { updateSorting } = this.props

    let order = 'DESC'
    if (this.state.orderBy === property) {
      if (this.state.order === 'DESC') {
        order = 'ASC'
      } else if (this.state.order === 'ASC') {
        order = ''
        orderBy = ''
      } else {
        order = 'DESC'
      }
    }
    this.setState({ order, orderBy })
    updateSorting(orderBy, order)
  }

  render() {
    const {
      classes,
      noOrganization,
      organization,
      filters: {
        nameModel,
        vendor,
        location,
        articleNumber,
        externalId,
        guarantee,
      },
    } = this.props
    const { order, orderBy } = this.state
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="dense">
            <TableSortLabel
              active={orderBy === 'article_number'}
              direction={order ? order.toLowerCase() : order}
              onClick={this.handleRequestSort('article_number')}
            >
              {i18next.t('equipment_web_atoir:articleNumber')}
            </TableSortLabel>
            <TextField
              id="articleNumber"
              className={classes.textField}
              value={articleNumber}
              onChange={this.handleChange('articleNumber')}
              margin="none"
            />
          </TableCell>
          <TableCell padding="dense">
            <TableSortLabel
              active={orderBy === 'external_id'}
              direction={order ? order.toLowerCase() : order}
              onClick={this.handleRequestSort('external_id')}
            >
              {i18next.t('equipment_web_atoir:externalId')}
            </TableSortLabel>
            <TextField
              id="externalId"
              className={classes.textField}
              value={externalId}
              onChange={this.handleChange('externalId')}
              margin="none"
            />
          </TableCell>
          <TableCell padding="none">
            <TableSortLabel
              active={orderBy === 'name'}
              direction={order ? order.toLowerCase() : order}
              onClick={this.handleRequestSort('name')}
            >
              {i18next.t('equipment_web_atoir:nameModel')}
            </TableSortLabel>
            <TextField
              id="name/model"
              className={classes.textField}
              value={nameModel}
              onChange={this.handleChange('nameModel')}
              margin="none"
            />
          </TableCell>
          <TableCell padding="dense">
            <TableSortLabel
              active={orderBy === 'vendor'}
              direction={order ? order.toLowerCase() : order}
              onClick={this.handleRequestSort('vendor')}
            >
              {i18next.t('equipment_web_atoir:vendor')}
            </TableSortLabel>
            <SelectAuto
              id="vendor-select"
              fullWidth
              firstElement={{ id: '', title: 'нет' }}
              value={vendor}
              sourceUrl={`${URL}/${
                organization
                  ? `organizations/${organization.id}/equipments`
                  : 'users/equipment'
              }/vendors`}
              onChange={this.handleChange('vendor')}
              query={{ sort: '[vendor=ASC]' }}
              requestResultTransformerToArray={r =>
                r.vendors.filter(i => !!i).map(i => ({ id: i, vendor: i }))
              }
              itemTransformer={i => ({ id: i.id, title: i.vendor })}
            />
          </TableCell>
          <TableCell padding="none">
            <TableSortLabel
              active={orderBy === 'date_expired_guarantee'}
              direction={order ? order.toLowerCase() : order}
              onClick={this.handleRequestSort('date_expired_guarantee')}
            >
              {i18next.t('equipment_web_atoir:guaranteeTill')}
            </TableSortLabel>
            <DateInput
              // date={currentDate}
              classes={classes.textField}
              name="guaranty"
              label={i18next.t('inventory_web_atoir:inventoryDesiredPeriodEnd')}
              handleChange={this.handleChangeData('guarantee')}
              value={guarantee}
            />
          </TableCell>
          <TableCell padding="dense">
            <Typography>
              {noOrganization
                ? i18next.t('equipment_web_atoir:organizationAddressDepartment')
                : i18next.t('organizations_web_atoir:addressDepartment')}
            </Typography>
            <SelectAuto
              id="organization-address-department"
              fullWidth
              firstElement={{ id: '', title: 'нет' }}
              value={location}
              sourceUrl={`${URL}/${
                organization
                  ? `organizations/${organization.id}`
                  : 'users/equipment'
              }/locations`}
              onChange={this.handleChange('location')}
              requestResultTransformerToArray={r => r.locations}
              itemTransformer={i => ({
                id: i.id,
                title: i ? organizationLocationStringify(i) : '',
              })}
            />
          </TableCell>
          <TableCell padding="none">
            {i18next.t('equipment_web_atoir:routineChart')}
          </TableCell>
          <TableCell padding="dense">
            {i18next.t('equipment_web_atoir:nearestMaintenanceDate')}
          </TableCell>
          <TableCell padding="none">
            <TableSortLabel
              active={orderBy === 'created_at'}
              direction={order ? order.toLowerCase() : order}
              onClick={this.handleRequestSort('created_at')}
            >
              {i18next.t('equipment_web_atoir:createdAt')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
}

const propTypes = {
  classes: PropTypes.object.isRequired,
  equipment: PropTypes.array.isRequired,
}

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  }),
  table: {},
  noEquipText: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '24px',
  },
})

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  selectMaxWidth: {
    maxWidth: 320,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  notFirstAction: {
    // marginLeft: theme.spacing(3)
  },
  title: {
    flex: '0 0 auto',
  },
  titleLeft: {
    textAlign: 'left',
  },
  maxWidthDiv: {
    maxWidth: 380,
  },
})

class EnhancedTableToolbar extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isStatusOpen: false,
    }
  }

  handleChange = field => event => {
    const { updateFilters } = this.props
    updateFilters(field, event.target ? event.target.value : event)
  }

  handleOpen = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleClose = field => () => {
    this.setState({
      [field]: false,
    })
  }

  handleChangeData = field => date => {
    const { updateFilters } = this.props
    updateFilters(field, date)
  }

  render() {
    const {
      classes,
      filters: {
        status,
        department,
        organization,
        createdDateFrom,
        createdDateTo,
      },
      org,
      noOrganization,
    } = this.props
    const { isStatusOpen } = this.state
    return (
      <Toolbar className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div className={classes.actions}>
              <DropDownList
                shrink
                showLabel
                label={i18next.t('equipment_web_atoir:equipmentStatus')}
                classes={classes}
                open={isStatusOpen}
                value={status || ''}
                handleToogle={this.handleOpen('isStatusOpen')}
                elements={statuses}
                isFetching={false}
                handleOpen={this.handleOpen('isStatusOpen')}
                handleClose={this.handleClose('isStatusOpen')}
                handleChange={this.handleChange('status')}
              />
            </div>
          </Grid>
          {noOrganization ? (
            <Grid item xs={2}>
              <div
                className={classNames(classes.actions, classes.notFirstAction)}
              >
                <SelectAuto
                  label={i18next.t('organizations_web_atoir:organization')}
                  id="organization-select"
                  fullWidth
                  shrink
                  firstElement={{ id: '', title: 'нет' }}
                  value={organization}
                  query={{ sort: '[short_name=ASC]' }}
                  sourceUrl={`${URL}/organizations/member`}
                  onChange={this.handleChange('organization')}
                  requestResultTransformerToArray={r => r.member}
                  itemTransformer={i => ({
                    id: _.get(i, 'Organization.id') || '',
                    title: _.get(i, 'Organization.short_name') || '',
                  })}
                />
              </div>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={2}>
            <div
              className={classNames(classes.actions, classes.notFirstAction)}
            >
              <SelectAuto
                label={i18next.t('organizations_web_atoir:department')}
                fullWidth
                shrink
                firstElement={{ id: '', title: 'нет' }}
                id="department-select"
                value={department}
                query={{ sort: '[name=ASC]' }}
                sourceUrl={`${URL}/${
                  org ? `organizations/${org.id}` : 'users/equipment'
                }/departments`}
                onChange={this.handleChange('department')}
                requestResultTransformerToArray={r => r.departments}
                itemTransformer={i => ({ id: i.id, title: i.name })}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.actions}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ height: 0 }}>
                    <InputLabel shrink>
                      {i18next.t('inventory_web_atoir:creationDatePeriod')}
                    </InputLabel>
                  </div>
                  <DateInput
                    startAdornment={i18next.t(
                      'inventory_web_atoir:inventoryDesiredPeriodBegin',
                    )}
                    value={createdDateFrom}
                    name="createdDateFrom"
                    handleChange={this.handleChangeData('createdDateFrom')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    startAdornment={i18next.t(
                      'inventory_web_atoir:inventoryDesiredPeriodEnd',
                    )}
                    value={createdDateTo}
                    name="createdDateTo"
                    handleChange={this.handleChangeData('createdDateTo')}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <div className={classes.spacer} />
        </Grid>
      </Toolbar>
    )
  }
}

EnhancedTableToolbar.propTypes = {
  updateFilters: PropTypes.func.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

class EquipmentTable extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      filters: {},
    }
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  fetchEquipment = (filters = {}) => {
    const { page } = this.state
    const { rowsPerPage } = this.props

    const {
      organization,
      noOrganization,
      fetchCount,
      fetchOrganizationCount,
    } = this.props
    this.hasBeenFetched = true
    if (noOrganization) {
      this.props.fetchEquipment({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...filters,
      })
      fetchCount({
        ...filters,
      })
    } else {
      this.props.fetchEquipment(organization.id, {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...filters,
      })
      fetchOrganizationCount(organization.id, {
        ...filters,
      })
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      if (this.props.fetchEquipment) {
        this.fetchEquipment(this.state.filters)
      }
    })
  }

  updateFilters = (field, value) => {
    const f = { ...this.state.filters, [field]: value }
    this.setState(state => ({
      filters: { ...state.filters, [field]: value },
      page: 0,
    }))
    clearTimeout(this.nameModelTimer)
    if (field === 'nameModel') {
      this.nameModelTimer = setTimeout(() => this.fetchEquipment(f), 400)
    } else {
      this.fetchEquipment(f)
    }
  }

  updateSorting = (field, order) => {
    const f = {
      ...this.state.filters,
      orderField: field,
      orderDirection: order,
    }
    this.setState(state => ({
      filters: { ...state.filters, orderField: field, orderDirection: order },
    }))
    clearTimeout(this.nameModelTimer)
    if (field === 'nameModel') {
      this.nameModelTimer = setTimeout(() => this.fetchEquipment(f), 400)
    } else {
      this.fetchEquipment(f)
    }
  }

  componentDidMount() {
    const {
      props: { loadOnMount },
    } = this
    if (loadOnMount) {
      this.fetchEquipment()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rowsPerPage !== this.props.rowsPerPage) {
      if (this.props.fetchEquipment) {
        this.fetchEquipment(this.state.filters)
      }
    }
  }

  handleChangeRowsPerPage = event => {
    this.props.setEquipmentRowsPerPage(event.target.value)
  }

  render = () => {
    const {
      classes,
      equipment,
      count,
      isFetchingEquipment,
      isFetchingVendors,
      isFetchingDepartments,
      isFetchingOrganizations,
      isFetchingLocations,
      vendors,
      departments,
      locations,
      organizations,
      noOrganization,
      organization,
      match,
      rowsPerPage,
      hideTopFilter,
    } = this.props
    const { page, filters } = this.state
    const baseUrl = match.url.split('/')
    baseUrl.pop()
    return (
      <Fragment>
        {equipment && equipment.length === 0 && !this.hasBeenFetched ? (
          <Typography align="center">
            {i18next.t('organizations_web_atoir:noOrganizationEquipment')}
          </Typography>
        ) : (
          <Fragment>
            {!hideTopFilter && (
              <EnhancedTableToolbar
                hideTopFilter={hideTopFilter}
                updateFilters={this.updateFilters}
                filters={filters}
                org={organization}
                departments={departments}
                organizations={organizations}
                noOrganization={noOrganization}
                isFetchingDepartments={isFetchingDepartments}
                isFetchingOrganizations={isFetchingOrganizations}
              />
            )}

            <div className="table-wrapper">
              <Table className={classes.table}>
                <EnhancedTableHead
                  classes={classes}
                  noOrganization={noOrganization}
                  isFetchingVendors={isFetchingVendors}
                  isFetchingLocations={isFetchingLocations}
                  filters={filters}
                  updateFilters={this.updateFilters}
                  updateSorting={this.updateSorting}
                  locations={locations}
                  vendors={vendors}
                  organization={organization}
                />
                {isFetchingEquipment ? (
                  <TableBody>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Loader />
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableBody>
                ) : equipment && equipment.length > 0 ? (
                  <TableBody>
                    {equipment.map(equip => (
                      <TableRow key={equip.id}>
                        <TableCell>
                          <div
                            className={classNames([
                              classes.maxWidthDiv,
                              'preBreak',
                            ])}
                          >
                            {equip.article_number}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className={classNames([
                              classes.maxWidthDiv,
                              'preBreak',
                            ])}
                          >
                            {equip.external_id || ''}
                          </div>
                        </TableCell>
                        <TableCell padding="none">
                          <RLink
                            to={`${match.url}/${
                              !match.params.organizationId &&
                              equip &&
                              equip.organization_id
                                ? `organization/${equip.organization_id}/`
                                : 'equipments/'
                            }${equip.id}/information`}
                          >
                            <div
                              className={classNames([
                                classes.maxWidthDiv,
                                'preBreak',
                              ])}
                            >
                              <span className="staff-name-underlined">
                                {equip.name}
                              </span>
                              <EquipmentSuggestionsIndicator
                                show={equip.suggestionsCount > 0}
                              />
                            </div>
                          </RLink>
                          <Typography>{equip.model}</Typography>
                        </TableCell>
                        <TableCell padding="dense">
                          {equip.vendor || '-'}
                        </TableCell>
                        <TableCell padding="none">
                          {equip.date_expired_guarantee
                            ? new Date(
                                equip.date_expired_guarantee,
                              ).toLocaleDateString()
                            : '-'}
                        </TableCell>
                        <TableCell padding="dense">
                          <span>
                            {equip.Organization
                              ? `${equip.Organization.short_name} / `
                              : ''}
                            {equip.OrganizationLocation
                              ? organizationLocationStringify(
                                  equip.OrganizationLocation,
                                )
                              : '-'}
                          </span>
                          /
                          <span>
                            {equip.OrganizationDepartment
                              ? equip.OrganizationDepartment.name
                              : '-'}
                          </span>
                        </TableCell>
                        <TableCell padding="none">-</TableCell>
                        <TableCell padding="dense">-</TableCell>
                        <TableCell padding="none">
                          {new Date(equip.created_at).toLocaleDateString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  ''
                )}
              </Table>
            </div>
          </Fragment>
        )}

        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
          labelDisplayedRows={({
            from: fromPage,
            to: toPage,
            count: countPage,
          }) =>
            i18next.t('shared_web_atoir:labelDisplayedRows', {
              fromPage,
              toPage,
              countPage,
            })
          }
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Fragment>
    )
  }
}

EquipmentTable.propTypes = propTypes
export default withStyles(styles)(EquipmentTable)
