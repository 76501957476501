import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import {
  fetchOrganizationEquipment,
  fetchOrganizationEquipmentCount,
} from '../../../../../../actions/equipmentActions'
import EquipmentChoosingList from './EquipmentChoosingList'

const mapStateToProps = state => ({
  equipment: state.equipment.equipment,
  equipmentCount: state.equipment.count,
  isFetchingEquipment: state.equipment.isFetchingEquipment,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationEquipment,
      fetchOrganizationEquipmentCount,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentChoosingList),
)
