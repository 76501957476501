import React from 'react'
import PropTypes from 'prop-types'
import RLink from 'react-router-dom/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  link: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.contrastText,
  },
  titleLink: {
    margin: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
})

function TitleLink(props) {
  return (
    <RLink to={props.link} className={props.classes.link}>
      <Typography
        className={props.classes.titleLink}
        variant="subheading"
        align="left"
      >
        {props.children}
      </Typography>
    </RLink>
  )
}

TitleLink.defaultProps = {
  children: '',
  link: '404',
}

TitleLink.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  link: PropTypes.string,
}

export default withStyles(styles)(TitleLink)
