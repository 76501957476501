import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField/TextField'
import FormControlSelectWithSearch from '../../../../../widgets/Controlls/FormControlSelectWithSearch'
import i18next from '../../../../../i18n/i18n'
import FormControlText from '../../../../../widgets/Controlls/FormControlText'
import AddressPicker from '../../../../../widgets/AddressPicker'
import DateInput from '../../../../../widgets/DateInput'

class InformationForm extends Component {
  constructor(props) {
    super(props)
    this.floors = []
    for (let i = -11; i < 101; i++) {
      if (i <= -2) {
        const ind = i + 1
        this.floors.push(`${ind}`)
      } else if (i === -1) {
        this.floors.push('')
      } else {
        this.floors.push(`${i}`)
      }
    }
    this.floors = this.floors.reverse()
  }

  onChangeLocation = location => {
    this.props.onChangeLocation({ target: { value: location } })
  }

  render() {
    const {
      props: {
        onChangeFloor,
        onChangeCabinet,
        onChangeDateFrom,
        onChangeDateTo,
        onChangeComment,
        errors,
        defaultLocation,
        disablePast,
        cabinet,
        dateFrom,
        dateTo,
        floor,
        location,
        comment,
      },
      onChangeLocation,
    } = this
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput
              requiredValue
              disablePast={disablePast}
              value={dateFrom}
              label={i18next.t('service_desk_web_atoir:requestResponseTime')}
              InputProps={{
                startAdornment: (
                  <Typography variant="caption" style={{ marginRight: 4 }}>
                    {i18next.t('shared_web_atoir:unitFrom')}
                  </Typography>
                ),
              }}
              handleChange={date => {
                onChangeDateFrom({ target: { value: date } })
              }}
            />
            <Typography color="error" align="left">
              {errors.dateFromError}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DateInput
              disablePast={disablePast}
              value={dateTo}
              InputProps={{
                startAdornment: (
                  <Typography variant="caption" style={{ marginRight: 4 }}>
                    {i18next.t('shared_web_atoir:unitTo')}
                  </Typography>
                ),
              }}
              handleChange={date => {
                onChangeDateTo({ target: { value: date } })
              }}
            />
            <Typography color="error" align="left">
              {errors.dateToError}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="error" align="left">
              {errors.dateToDateFromError}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AddressPicker
              required
              defaultLocation={defaultLocation}
              label={`${i18next.t('equipment_web_atoir:placement')}*`}
              fullWidth
              fetchLocation
              value={location}
              onChange={onChangeLocation}
              classes={{}}
              style={{ textAlign: 'left' }}
            />
            <Typography color="error" align="left">
              {errors.locationError}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControlSelectWithSearch
              label={i18next.t(
                'service_desk_web_atoir:performerInfoFieldFloor',
              )}
              showLabel
              values={this.floors.map(f => ({ id: f, title: f }))}
              onChange={onChangeFloor}
              value={floor}
              error={errors.floorError}
            />
            <Typography color="error" align="left">
              {errors.floorError}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControlText
              label={i18next.t(
                'service_desk_web_atoir:performerInfoFieldCabinet',
              )}
              onChange={onChangeCabinet}
              value={cabinet}
              error={errors.cabinetError}
            />
            <Typography color="error" align="left">
              {errors.cabinetError}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label={i18next.t(
                'inventory_web_atoir:inventoryCommentForPerformer',
              )}
              onChange={onChangeComment}
              value={comment}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

InformationForm.defaultProps = {
  disablePast: true,
  cabinet: null,
  dateFrom: new Date(),
  dateTo: new Date(new Date().setMonth(new Date().getMonth() + 1)),
  floor: '',
  location: null,
  defaultLocation: null,
  comment: null,
  errors: {},
}

InformationForm.propTypes = {
  onChangeCabinet: PropTypes.func.isRequired,
  onChangeDateFrom: PropTypes.func.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
  onChangeFloor: PropTypes.func.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  onChangeComment: PropTypes.func.isRequired,
  defaultLocation: PropTypes.object,
  disablePast: PropTypes.bool,
  cabinet: PropTypes.string,
  comment: PropTypes.string,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  errors: PropTypes.object,
  floor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  location: PropTypes.object,
}

export default InformationForm
