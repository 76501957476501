import React from 'react'
import ImportEquipments from './ImportEquipments'
import BreadCrumb from '../../App/routing/BreadCrumb'
import { popLastSections } from '../../../helpers/dataHelper'
import i18next from '../../../i18n/i18n'

function ImportEquipmentsWrapper({
  match,
  fetchOrganization,
  organization,
  ...otherProps
}) {
  const {
    params: { backTo, organizationId },
  } = match
  React.useEffect(() => {
    fetchOrganization(organizationId)
  }, [])
  return (
    <React.Fragment>
      {backTo === 'from-equipment' ? (
        <React.Fragment>
          <BreadCrumb to="/equipment">
            {i18next.t('equipment_web_atoir:equipList')}
          </BreadCrumb>
          <BreadCrumb length={65} to={match.url}>
            {organization
              ? i18next.t('equipment_web_atoir:loadByEquipListFor', {
                  orgName: organization.short_name,
                })
              : ''}
          </BreadCrumb>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <BreadCrumb to={popLastSections(match.url, 1)}>
            {organization ? organization.short_name : ''}
          </BreadCrumb>
          <BreadCrumb length={45} to={match.url}>
            {i18next.t('equipment_web_atoir:loadByEquipList')}
          </BreadCrumb>
        </React.Fragment>
      )}
      <ImportEquipments
        organizationId={organizationId}
        match={match}
        {...otherProps}
      />
    </React.Fragment>
  )
}

export default ImportEquipmentsWrapper
