import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import i18next from '../../../../i18n/i18n'
import config from '../../../../config'
import { popLastSections } from '../../../../helpers/dataHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// components
import Loader from '../../../Loader'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import HistoryViewer from './widgets/HistoryViewer'
import TextButton from '../../../../widgets/Buttons/TextButton'
import Title from '../../CallProfile/widgets/Title'

const styles = theme => ({
  rootWithPadding: {
    padding: 12,
  },
  dividerWithMargin: {
    marginTop: 8,
    marginBottom: 8,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  editEquipmentDialogRoot: {
    width: '70%',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 8px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  textFieldMulty: {
    width: '500px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  grayText: {
    color: theme.palette.grey[500],
  },
})

class InnerCallHistory extends Component {
  state = {
    offset: 0,
    limit: config.default_inner_call_history_limit,
  }

  componentDidMount = () => this.fetchHistory()

  componentWillUnmount = () => this.props.clearInnerCallHistory()

  fetchHistory = () => {
    const {
      state: { offset, limit },
      props: {
        fetchInnerCallHistoryRequest,
        match: {
          params: { innerCallId },
        },
      },
    } = this
    fetchInnerCallHistoryRequest({
      innerCallId,
      query: {
        offset,
        limit,
        sort: '[createdAt=DESC]',
      },
    })
  }

  loadMore = () => {
    this.setState(
      state => ({
        offset: state.offset + state.limit,
      }),
      this.fetchHistory,
    )
  }

  render() {
    const {
      props: {
        match: {
          params: { innerCallId },
          url,
        },
        isFetching,
        history,
        end,
      },
      loadMore,
    } = this
    return isFetching ? (
      <Loader />
    ) : (
      !isEmpty(history) && (
        <Fragment>
          <BreadCrumb length={29} to={popLastSections(url)}>
            {`№ ${innerCallId}`}
          </BreadCrumb>
          <BreadCrumb length={29} to={url}>
            {i18next.t('inner_calls_web_atoir:innerCallHistoryChangelog')}
          </BreadCrumb>
          <Title
            text={i18next.t('inner_calls_web_atoir:innerCallIdHistory', {
              innerCallId,
            })}
          />
          <HistoryViewer history={history} />
          {!end && (
            <TextButton onClick={loadMore}>
              {i18next.t('service_desk_web_atoir:show_more')}
            </TextButton>
          )}
        </Fragment>
      )
    )
  }
}

InnerCallHistory.propTypes = {
  fetchInnerCallHistoryRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  history: PropTypes.array.isRequired,
  end: PropTypes.bool.isRequired,
  clearInnerCallHistory: PropTypes.func.isRequired,
}

export default withStyles(styles)(InnerCallHistory)
