import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import { getInnerCallsPerformerEquipments } from '../../../../../../actions/innerCallsActions'
import EquipmentChoosingList from './EquipmentChoosingList'

const mapStateToProps = state => ({
  equipment: state.innerCalls.performerEquipments.value,
  equipmentCount: state.innerCalls.performerEquipments.count,
  isFetchingEquipment: state.innerCalls.performerEquipments.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationEquipment: getInnerCallsPerformerEquipments.REQUEST,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentChoosingList),
)
