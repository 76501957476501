import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../../../../i18n/i18n'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// ---material--
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import Grid from '@material-ui/core/Grid'

// ---components---
import TableComponent from '../TableComponent/index'
import TableHeadComponent from '../TableHeadComponent/index'
import Loader from '../../../../../Loader'
import SimpleDialog from '../../../../../Other/SimpleDialog'
import DetailsOffer from '../DetailsOffer'
import CanInventory from '../../InventoryProjectAbility/CanInventory'
import PrimaryButton from '../../../../../../widgets/Buttons/PrimaryButton'

class AsClient extends Component {
  state = {
    order: 'ASC',
    orderBy: null,
    shortName: null,
    offerId: null,
    dialogOpen: false,
  }

  componentDidMount() {
    const { updateSorting } = this
    updateSorting()
  }

  componentDidUpdate(prevProps) {
    const {
      updateSorting,
      props: { isOfferSuccess, isOffer, isCancelOffer, isCancelOfferSuccess },
    } = this
    if (
      prevProps.isOfferSuccess !== isOfferSuccess &&
      !isOffer &&
      isOfferSuccess
    ) {
      updateSorting()
    }
    if (
      prevProps.isCancelOfferSuccess !== isCancelOfferSuccess &&
      !isCancelOffer &&
      isCancelOfferSuccess
    ) {
      updateSorting()
    }
  }

  handleRequestSort = property => () => {
    let orderBy = property

    let order = 'DESC'
    if (this.state.orderBy === property) {
      if (this.state.order === 'DESC') {
        order = 'ASC'
      } else if (this.state.order === 'ASC') {
        order = ''
        orderBy = ''
      } else {
        order = 'DESC'
      }
    }
    this.setState({ order, orderBy }, () => {
      this.updateSorting()
    })
  }

  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.updateSorting(300)
      },
    )
  }

  updateSorting = (actionDelay = 0) => {
    const {
      state: { order, orderBy, shortName },
      props: { infoProject, match, fetchOffer },
    } = this
    const sort = orderBy === null ? null : `[${orderBy}=${order}]`
    const filters =
      shortName === null ? null : JSON.stringify({ short_name: shortName })
    const params =
      sort && filters
        ? `?sort=${sort}&filters=${filters}`
        : sort && !filters
        ? `?sort=${sort}`
        : !sort && filters
        ? `?filters=${filters}`
        : ''
    fetchOffer(
      infoProject.client_organization_id,
      match.params.projectId,
      params,
      actionDelay,
    )
  }

  handleDialogShow = () => {
    this.setState({
      dialogOpen: true,
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    })
  }

  handleDialogPositive = () => {
    const {
      handleDialogClose,
      props: { match, infoProject, stopAuction },
    } = this
    handleDialogClose()
    stopAuction(infoProject.client_organization_id, match.params.projectId)
  }

  handleChangeOffer = id => {
    this.setState({
      offerId: id,
    })
  }

  handleConfirmOffer = offerId => {
    const {
      props: { match, confirmOffer, infoProject },
    } = this

    confirmOffer(infoProject.Client.id, match.params.projectId, offerId)
    this.setState({
      offerId: null,
    })
  }

  render() {
    const {
      handleRequestSort,
      handleChange,
      handleChangeOffer,
      handleConfirmOffer,
      props: { isFetchOffers, classes, offers },
      state: { order, orderBy, shortName, dialogOpen, offerId },
    } = this

    const offer =
      offerId !== null ? offers.find(_offer => _offer.id === offerId) : {}
    return (
      <div className={classes.rootDiv}>
        <section className={classes.container}>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography
                variant="h6"
                className={classes.title}
                gutterBottom
              >
                {i18next.t('inventory_web_atoir:offersPerformers')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <CanInventory I="stop_auction" a="Inventory">
                <PrimaryButton
                  color="secondary"
                  onClick={this.handleDialogShow}
                >
                  {`${i18next.t('inventory_web_atoir:cancelAuction')}`}
                </PrimaryButton>
              </CanInventory>
            </Grid>
          </Grid>
          <Table className={classes.table}>
            <TableHeadComponent
              handleRequestSort={handleRequestSort}
              handleChange={handleChange}
              order={order}
              orderBy={orderBy}
              shortName={shortName}
            />
            {isFetchOffers ? (
              <Loader />
            ) : (
              <TableComponent
                offers={offers}
                handleChangeOffer={handleChangeOffer}
                selectedOffer={offerId}
              />
            )}
          </Table>
          <SimpleDialog
            isOpen={dialogOpen}
            handleClose={this.handleDialogClose}
            handlePositive={this.handleDialogPositive}
            handleNegative={this.handleDialogClose}
            positiveText={i18next.t(
              'inventory_web_atoir:responseCancelAuction',
            )}
            negativeText={i18next.t('shared_web_atoir:cancel')}
            title=""
            content={i18next.t('inventory_web_atoir:sureCancelAuction')}
          />
          {offerId !== null && !isEmpty(offers) && (
            <DetailsOffer
              textAction={i18next.t(
                'inventory_web_atoir:designateProjectPerformer',
              )}
              offer={offer}
              handleClose={() => {
                handleChangeOffer(null)
              }}
              handleAction={handleConfirmOffer}
            />
          )}
        </section>
      </div>
    )
  }
}
const styles = theme => ({
  container: {
    marginTop: '40px',
  },
  title: {
    textAlign: 'left',
  },
  rootDiv: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
})

AsClient.defaultProps = {
  infoProject: {},
}

AsClient.propTypes = {
  offers: PropTypes.array.isRequired,
  infoProject: PropTypes.object,
  isFetchOffers: PropTypes.bool.isRequired,
  fetchOffer: PropTypes.func.isRequired,
  stopAuction: PropTypes.func.isRequired,
}

export default withStyles(styles)(AsClient)
