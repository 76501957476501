import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  CREATE_LOCATION_REQUEST,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATIONS_REQUEST,
  UPDATE_LOCATION_REQUEST,
  FETCH_PHYSICAL_LOCATION_REQUEST,
  FETCH_LEGAL_LOCATION_REQUEST,
  FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_COUNT_REQUEST,
  DELETE_LOCATION_REQUEST,
} from '../constants/actionTypes'
import {
  fetchLocationsRequest,
  fetchLocationRequest,
  createLocationRequest,
  updateLocationRequest,
  fetchPhysicalLocationRequest,
  fetchLegalLocationRequest,
  fetchUserEquipmentLocationsRequest,
  fetchLocationsCountRequest,
  deleteLocationRequest,
} from './api'
import {
  fetchLocationFailure,
  fetchLocationsFailure,
  fetchLocationsSuccess,
  fetchLocationSuccess,
  createLocationFailure,
  createLocationSuccess,
  updateLocationFailure,
  updateLocationSuccess,
  fetchPhysicalLocationSuccess,
  fetchLegalLocationSuccess,
  fetchLegalLocationFailure,
  fetchPhysicalLocationFailure,
  updateLocationInLocations,
  fetchUserEquipmentLocationsFailure,
  fetchUserEquipmentLocationsSuccess,
  fetchLocationsCountSuccess,
  fetchLocationsCountFailure,
  deleteLocationSuccess,
  deleteLocationFailure,
} from '../actions/locationsActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'

export function* watchFetchLocationsSaga() {
  yield takeLatest(FETCH_LOCATIONS_REQUEST, fetchLocations)
}

export function* watchFetchLocationsCountSaga() {
  yield takeLatest(FETCH_LOCATIONS_COUNT_REQUEST, fetchLocationsCount)
}

export function* watchFetchLocationSaga() {
  yield takeLatest(FETCH_LOCATION_REQUEST, fetchLocation)
}

export function* watchFetchLegalLocationSaga() {
  yield takeLatest(FETCH_LEGAL_LOCATION_REQUEST, fetchLegalLocation)
}

export function* watchFetchPhysicalLocationSaga() {
  yield takeLatest(FETCH_PHYSICAL_LOCATION_REQUEST, fetchPhysicalLocation)
}

export function* watchCreateLocationSaga() {
  yield takeLatest(CREATE_LOCATION_REQUEST, createLocation)
}

export function* watchUpdateLocationSaga() {
  yield takeLatest(UPDATE_LOCATION_REQUEST, updateLocation)
}

export function* watchDeleteLocationSaga() {
  yield takeLatest(DELETE_LOCATION_REQUEST, deleteLocation)
}

export function* watchFetchUserEquipmentLocationsSaga() {
  yield takeLatest(
    FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST,
    fetchUserEquipmentLocations,
  )
}

function* fetchLocations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchLocationsRequest(accessToken, action.organizationId, action.query),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchLocationsSuccess(result.locations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchLocationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchLocationsCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchLocationsCountRequest(accessToken, action.organizationId),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchLocationsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchLocationsCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserEquipmentLocations() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchUserEquipmentLocationsRequest(accessToken))
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserEquipmentLocationsSuccess(result.locations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserEquipmentLocationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchLocation(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchLocationRequest(accessToken, action.organizationId, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchLocationSuccess(result.locations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchLocationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchPhysicalLocation(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchPhysicalLocationRequest(accessToken, action.organizationId),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchPhysicalLocationSuccess(result.locations[0]))
      } else {
        const errors = handleErrors(result)
        yield put(fetchPhysicalLocationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchLegalLocation(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchLegalLocationRequest(accessToken, action.organizationId),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchLegalLocationSuccess(result.locations[0]))
      } else {
        const errors = handleErrors(result)
        yield put(fetchLegalLocationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createLocation(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      createLocationRequest(accessToken, action.organizationId, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(createLocationSuccess(result.id))
      } else {
        const errors = handleErrors(result)
        yield put(createLocationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateLocation(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, locationId, data } = action
    const result = yield call(
      updateLocationRequest(accessToken, organizationId, locationId, data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        if (action.updateLocations) {
          const locationRequest = yield call(
            fetchLocationRequest(accessToken, organizationId, locationId),
          )
          if (locationRequest.success && locationRequest.locations[0]) {
            yield put(updateLocationInLocations(locationRequest.locations[0]))
          }
        }
        yield put(updateLocationSuccess(result.id))
      } else {
        const errors = handleErrors(result)
        yield put(updateLocationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteLocation(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, locationId } = action
    const result = yield call(
      deleteLocationRequest(accessToken, organizationId, locationId),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(deleteLocationSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(deleteLocationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(deleteLocationFailure(0))
  }
}
