import React, { Fragment } from 'react'
import i18next from '../../../../i18n/i18n'
import isEmpty from 'is-empty'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

export default function({ vendor, classes }) {
  return (
    <Fragment>
      <Typography className={classes.sectionName}>
        {i18next.t('equipment_web_atoir:vendor')}
      </Typography>
      {[
        {
          title: `${i18next.t('equipment_web_atoir:vendorShortName')}`,
          content: isEmpty(vendor.name) ? '' : vendor.name,
        },
        {
          title: `${i18next.t('equipment_web_atoir:vendorFullName')}`,
          content: isEmpty(vendor.full_name) ? '' : vendor.full_name,
        },
        {
          title: `${i18next.t('equipment_web_atoir:vendorNameInEnglish')}`,
          content: isEmpty(vendor.name_english) ? '' : vendor.name_english,
        },
      ]
        .filter(i => i.content)
        .map(i => (
          <Grid container className={classes.infoRoot}>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.title}>
                {i.title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" className="wordWrapBreakAll">
                {i.content}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Fragment>
  )
}
