import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import FormHelperText from '@material-ui/core/FormHelperText'
import Loader from '../../components/Loader'

const styles = {
  menuItemFirstElement: {
    minHeight: 36,
  },
}

class DropDownList extends React.Component {
  constructor(props) {
    super(props)
    this.menuRef = React.createRef()
    this.pointRef = React.createRef()
    this.state = {}
  }

  componentDidUpdate = prevProps => {
    if (prevProps.elements !== this.props.elements) {
      this.pointRef = React.createRef()
      this.forceUpdate()
    }
  }

  componentDidMount() {
    document.addEventListener('keypress', this.keyFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.keyFunction, false)
  }

  keyFunction = event => {
    if (!this.props.open) {
      return
    }
    const { handleChange, elements, handleClose, handleOpen } = this.props
    const char = String.fromCharCode(event.keyCode)
    if (!this.search) {
      this.search = { value: '' }
    }
    this.search.value += char
    const value = elements
      .map(element => ({
        index: element.title
          .toLowerCase()
          .indexOf(this.search.value.toLowerCase()),
        element,
      }))
      .filter(element => element.index !== -1)
      .sort((a, b) =>
        a.index - b.index === 0
          ? a.element.title.length - b.element.title.length
          : a.index - b.index,
      )[0]
    if (value && this.search.id !== value.element.id) {
      this.search.id = value.element.id
      handleClose()
      handleChange({ target: { value: value.element.id } })
      handleOpen()
    }
    clearTimeout(this.clearSearchTimer)
    this.clearSearchTimer = setTimeout(() => {
      this.search = undefined
    }, 500)
  }

  render = () => {
    const {
      classes,
      value,
      open,
      handleOpen,
      handleChange,
      handleClose,
      label,
      elements,
      isFetching,
      error,
      disabled,
      showLabel,
      ref,
      required,
      noEmpty,
      fullWidth,
      helperText,
      pointerValue,
      shrink,
    } = this.props
    const content = elements
      ? elements.map(e => (
          <MenuItem
            value={e.id}
            key={e.id}
            ref={pointRef => {
              if (pointerValue && e.id === pointerValue)
                this.pointRef = pointRef
            }}
            style={
              pointerValue && e.id === pointerValue
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {e.title}
          </MenuItem>
        ))
      : ''
    return (
      <FormControl
        className={classes.formControl}
        error={error}
        required={required}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        {showLabel && (
          <InputLabel
            shrink={shrink || Boolean(value && value !== '')}
            htmlFor={`open-select-${label}`}
          >
            {label}
          </InputLabel>
        )}
        <Select
          id={`open-select-${label}`}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          inputRef={ref}
          onChange={handleChange}
          inputProps={{
            name: label,
            id: `open-select-${label}`,
          }}
          MenuProps={{
            onEntering: () => {
              if (!value && pointerValue && !(this.pointRef.current === null)) {
                // eslint-disable-next-line
                ReactDOM.findDOMNode(this.pointRef).focus()
              }
            },
            ref: menuRef => {
              this.menuRef = menuRef
            },
            MenuListProps: {},
          }}
        >
          {!noEmpty && (
            <MenuItem
              value=""
              className={classes.menuItemFirstElement}
              key="menu-item-first-element"
            >
              <em />
            </MenuItem>
          )}

          {isFetching ? (
            <MenuItem>
              <Loader />
            </MenuItem>
          ) : (
            content
          )}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

DropDownList.defaultProps = {
  disabled: false,
  required: false,
  error: false,
  label: '',
  showLabel: false,
  noEmpty: false,
  fullWidth: true,
  ref: () => {},
  helperText: null,
  pointerValue: null,
}

DropDownList.propTypes = {
  classes: PropTypes.object.isRequired,
  pointerValue: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  value: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  label: PropTypes.string,
  elements: PropTypes.array.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  ref: PropTypes.func,
  showLabel: PropTypes.bool,
  noEmpty: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
}

export default withStyles(styles)(DropDownList)
