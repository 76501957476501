import React from 'react'
import PropTypes from 'prop-types'
import ReactResizeDetector from 'react-resize-detector'
import CircularProgress from '@material-ui/core/CircularProgress'

// default params
const inDelayDefault = 500
const outDelayDefault = 600

function Fader({ children, show }) {
  return (
    <div style={{ position: 'relative' }}>
      <ReactResizeDetector handleWidth handleHeight>
        {({ width, height }) => (
          <>
            {show && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  backgroundColor: 'rgba(220, 220, 220, 0.5)',
                  width,
                  height,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}
            {children}
          </>
        )}
      </ReactResizeDetector>
    </div>
  )
}

function LoadingWrapper({
  children,
  loading,
  onClick,
  inDelay,
  outDelay,
  data,
  ...other
}) {
  const [dataFreezed, setDataFreezed] = React.useState([])
  const [loadingDelayed, setLoadingDelayed] = React.useState(false)
  const [animationFreezed, setAnimationFreezed] = React.useState(false)
  const [timerAnimation, setTimerAnimation] = React.useState(null)
  const [timerFreezed, setTimerFreezed] = React.useState(null)

  React.useEffect(() => {
    setDataFreezed(data)
  }, [])

  React.useEffect(() => {
    setDataFreezed(data)
  }, [loadingDelayed])

  const loadingRef = React.useRef(loading)
  loadingRef.current = loading

  React.useEffect(() => {
    if (!loading) {
      if (!animationFreezed) {
        setLoadingDelayed(false)
      }
    } else {
      setTimerAnimation(
        setTimeout(() => {
          if (loadingRef.current) {
            setLoadingDelayed(true)
            setAnimationFreezed(true)
            setTimerFreezed(
              setTimeout(() => {
                setAnimationFreezed(false)
                if (!loadingRef.current) setLoadingDelayed(false)
              }, outDelay),
            )
          }
        }, inDelay),
      )
    }

    return () => {
      clearTimeout(timerAnimation)
      clearTimeout(timerFreezed)
    }
  }, [loading])

  function onClickInterlayer(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (!loading && !loadingDelayed) onClick()
  }
  return (
    <React.Fragment>
      {children({
        ...other,
        onClick: onClickInterlayer,
        loading: loadingDelayed,
        data: dataFreezed,
      })}
    </React.Fragment>
  )
}

LoadingWrapper.Fader = Fader

LoadingWrapper.defaultProps = {
  inDelay: inDelayDefault,
  outDelay: outDelayDefault,
  onClick: () => {},
  data: [],
}

LoadingWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  inDelay: PropTypes.number,
  outDelay: PropTypes.number,
  data: PropTypes.array,
}

export default LoadingWrapper
