import React, { Fragment, memo } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import scriptSchema from './script.schema'
import config from '../../../../../config'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Grid } from '@material-ui/core'

// components
import i18next from '../../../../../i18n/i18n'
import Selector from '../../Components/Selector'
import ConditionsConstructorWrapper from '../../Components/ConditionsConstructorWrapper'
import TimingConstructor from './TimingConstructor'
import StuffSelector from './StuffSelector'
import ActionCreator from '../../Components/ActionCreator'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import CheckBoxSelector from '../../../../../widgets/CheckBoxSelector'
import DescriptionField from '../../Components/DescriptionField'

const triggerActions = () => [
  {
    id: 'sc_created',
    description: i18next.t('organizations_web_atoir:callCtreated'),
  },
  {
    id: 'sc_assigned',
    description: i18next.t('organizations_web_atoir:callAsignedToOrg'),
  },
  {
    id: 'sc_in_needrepair',
    description: i18next.t('organizations_web_atoir:callToInNeedrepairMoved'),
  },
  {
    id: 'order_file_attached',
    description: i18next.t('organizations_web_atoir:fileAttachedToOrder'),
  },
]

const workerTimeSensitiveList = () => [
  {
    id: 'withoutWorkerTime',
    description: i18next.t('organizations_web_atoir:withWorkingTime'),
  },
  {
    id: 'withWorkerTime',
    description: i18next.t('organizations_web_atoir:withoutOtherConditions'),
  },
]

const strategyAfterTimeoutList = () => [
  {
    id: 'no_checks',
    description: i18next.t('organizations_web_atoir:withoutOtherConditions'),
  },
  {
    id: 'before_conditions_check',
    description: i18next.t('organizations_web_atoir:inCaseConditionStillMet'),
  },
  {
    id: 'after_conditions_check',
    description: i18next.t('organizations_web_atoir:icCaseOtherConditionMet'),
  },
]

const actorList = () => [
  {
    id: 'custom',
    description: i18next.t('organizations_web_atoir:selectedOrgUser'),
  },
  {
    id: 'action_maker',
    description: i18next.t('organizations_web_atoir:autorOfChange'),
  },
]

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  helperTextRoot: {
    textAlign: 'right',
  },
})

const formikEnhancer = withFormik({
  validationSchema: scriptSchema,
  mapPropsToValues: ({ script }) => ({
    ...script,
  }),
  handleSubmit: (payload, { props, setSubmitting }) => {
    setSubmitting(false)
    props.onSubmit(payload)
  },
  displayName: 'CreateEditScriptFormEnhanced',
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
})

function arePropsEqualSectionProxy(prevProps, nextProps) {
  return (
    prevProps.sectionData === nextProps.sectionData &&
    prevProps.error === nextProps.error
  )
}

const SectionProxy = memo(function ClonedElement({
  children,
  onChange,
  sectionData,
  sectionId,
  ...props
}) {
  const onChangeHandler = (event, possibleValue) => {
    let id = event
    let value = possibleValue
    if (event.target) {
      ;({ id, value } = event.target)
    }
    onChange(sectionId, {
      ...sectionData,
      [id]: value,
    })
  }
  return (
    <div>
      {React.cloneElement(children, { ...props, onChange: onChangeHandler })}
    </div>
  )
},
arePropsEqualSectionProxy)

SectionProxy.propTypes = {
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  sectionData: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
}

const CreateEditScriptFormTemplate = withStyles(styles)(props => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
    classes,
    organizationId,
    history,
    submitCount,
  } = props

  const cancelHandler = () => {
    history.goBack()
  }

  const setFieldValueHandler = (event, possibleValue) => {
    let id = event
    let value = possibleValue
    if (event.target) {
      ;({ id, value } = event.target)
    }
    setFieldValue(id, value, submitCount !== 0)
  }
  return (
    <form onSubmit={handleSubmit}>
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              error={errors.name}
              onBlur={handleBlur}
              fullWidth
              id="name"
              onChange={setFieldValueHandler}
              value={values.name}
              label={i18next.t('organizations_web_atoir:scriptName')}
              inputProps={{ maxLength: 255 }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperTextRoot,
                },
              }}
              helperText={`${values.name.length}/${config.orgSettingNameLength}`}
            />
          </Grid>
          <Grid item xs={12}>
            <DescriptionField
              onBlur={handleBlur}
              fullWidth
              idValue="description"
              onChange={setFieldValueHandler}
              currentValue={values.description || ''}
              label={i18next.t('organizations_web_atoir:scripDescription')}
            />
            <br />
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`1. ${i18next.t('organizations_web_atoir:startEvent')}`}
            </Typography>
            <Selector
              fullWidth
              idValue="trigger_action"
              valueList={triggerActions()}
              currentValue={values.trigger_action}
              onChange={setFieldValueHandler}
              error={errors.trigger_action && touched.trigger_action}
            />
            <br />
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`2. ${i18next.t('organizations_web_atoir:scripCondition')}`}
            </Typography>
            {Boolean(values.trigger_action) && (
              <ConditionsConstructorWrapper
                idValueConditions="conditions"
                idValueLogic="logic"
                conditions={values.conditions}
                logic={values.logic}
                onChange={setFieldValueHandler}
                errors={errors.conditions || {}}
                touched={touched.conditions}
              />
            )}
            <br />
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`3. ${i18next.t('organizations_web_atoir:queueScript')}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TimingConstructor
              idValue="timing"
              currentValue={values.timing}
              onChange={setFieldValueHandler}
            />
          </Grid>

          {values.timing.in > 0 && (
            <Fragment>
              <Grid item xs={12}>
                <Selector
                  fullWidth
                  idValue="whenSelector"
                  valueList={workerTimeSensitiveList()}
                  currentValue="withoutWorkerTime"
                  onChange={() => {}}
                />
              </Grid>
              <Grid item xs={12}>
                <Selector
                  fullWidth
                  idValue="trigger_strategy_after_timeout"
                  valueList={strategyAfterTimeoutList()}
                  currentValue={values.trigger_strategy_after_timeout}
                  onChange={setFieldValueHandler}
                />
              </Grid>
            </Fragment>
          )}
          {values.timing.in > 0 &&
            values.trigger_strategy_after_timeout ===
              'after_conditions_check' && (
              <ConditionsConstructorWrapper
                idValueConditions="conditions_after_timeout"
                idValueLogic="logic_after_timeout"
                conditions={values.conditions_after_timeout}
                logic={values.logic_after_timeout}
                onChange={setFieldValueHandler}
                errors={errors.conditions_after_timeout || {}}
                touched={touched.conditions_after_timeout}
              />
            )}
          {values.timing.in > 0 && (
            <Grid item xs={12}>
              <SectionProxy
                onChange={setFieldValueHandler}
                sectionId="timing"
                sectionData={values.timing}
              >
                <CheckBoxSelector
                  idValue="update_if_triggered_on_pending"
                  currentValue={
                    (values.timing || {}).update_if_triggered_on_pending
                  }
                  label={i18next.t(
                    'organizations_web_atoir:updateIfTriggeredOnPending',
                  )}
                />
              </SectionProxy>
            </Grid>
          )}
          <Grid item xs={12}>
            <SectionProxy
              label={i18next.t('organizations_web_atoir:runScriptAs')}
              fullWidth
              idValue="type"
              valueList={actorList()}
              currentValue={values.actor.type}
              onChange={setFieldValueHandler}
              sectionId="actor"
              sectionData={values.actor}
            >
              <Selector />
            </SectionProxy>
          </Grid>
          <Grid item xs={12}>
            <SectionProxy
              idValue="organization_user_id"
              currentValue={values.actor.organization_user_id}
              onChange={setFieldValueHandler}
              organizationId={organizationId}
              sectionId="actor"
              sectionData={values.actor}
              error={
                (errors.actor || {}).organization_user_id &&
                (touched.actor || {}).organization_user_id
              }
            >
              <StuffSelector />
            </SectionProxy>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`4. ${i18next.t('organizations_web_atoir:actThatStuff')}`}
            </Typography>
            <ActionCreator
              organizationId={organizationId}
              actions={values.actions}
              onChange={setFieldValueHandler}
              errors={(errors.actions || [])[0] || {}}
              touched={(touched.actions || [])[0] || {}}
            />
          </Grid>
          <Grid xs={6} container justify="flex-start" alignItems="center">
            <OutlinedButton size="medium" onClick={cancelHandler}>
              {i18next.t('shared_web_atoir:cancel')}
            </OutlinedButton>
          </Grid>
          <Grid xs={6} container justify="flex-end" alignItems="center">
            <ContainedButton type="submit" size="medium">
              {i18next.t('shared_web_atoir:save')}
            </ContainedButton>
          </Grid>
        </Grid>
      </Fragment>
    </form>
  )
})

const CreateEditScriptForm = formikEnhancer(CreateEditScriptFormTemplate)

CreateEditScriptForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  script: PropTypes.object.isRequired,
}

export default withStyles(styles)(CreateEditScriptForm)
