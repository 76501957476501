import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchOrganization } from '../../../../actions/organizationsActions'
import {
  fetchInnerCallAnalyticsChartCreatedRequest,
  fetchInnerCallAnalyticsChartAverageRequest,
  fetchInnerCallAnalyticsChartClosedByEngineerRequest,
} from '../../../../actions/innerCallsActions'
import {
  setFilterDrawer,
  clearFilterDrawer,
} from '../../../../actions/appActions'
import InnerCallsAnalytics from './InnerCallsAnalytics'

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  summary: state.innerCalls.innerCallAnalyticSummary.summary,
  summaryAverage:
    state.innerCalls.innerCallAnalyticSummaryAverage.summaryAverage,
  chartCreated: state.innerCalls.innerCallsChartCreated.chartCreated,
  chartAverage: state.innerCalls.innerCallsChartAverage.chartAverage,
  chartClosedByEngineer:
    state.innerCalls.innerCallsChartClosedByEngineer.chartClosedByEngineer,
  engineerCount: state.innerCalls.innerCallsEngineerCount.count,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganization,
      setFilterDrawer,
      clearFilterDrawer,
      fetchInnerCallAnalyticsChartCreatedRequest,
      fetchInnerCallAnalyticsChartAverageRequest,
      fetchInnerCallAnalyticsChartClosedByEngineerRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerCallsAnalytics),
)
