import * as constants from '../constants/actionTypes/trustedOrganizations'
import {
  actionCreator as externalActionCreator,
  actionCreatorTyped,
} from './actionCreator'

export const fetchTrustsRequest = externalActionCreator(
  constants.FETCH_TRUSTS_REQUEST,
)
export const fetchTrustsSuccess = externalActionCreator(
  constants.FETCH_TRUSTS_SUCCESS,
)
export const fetchTrustsFailure = externalActionCreator(
  constants.FETCH_TRUSTS_FAILURE,
)

export const fetchTrustsCountRequest = externalActionCreator(
  constants.FETCH_TRUSTS_COUNT_REQUEST,
)
export const fetchTrustsCountSuccess = externalActionCreator(
  constants.FETCH_TRUSTS_COUNT_SUCCESS,
)
export const fetchTrustsCountFailure = externalActionCreator(
  constants.FETCH_TRUSTS_COUNT_FAILURE,
)

export const changePage = externalActionCreator(constants.CHANGE_PAGE)

export const changeRowsPerPage = externalActionCreator(
  constants.CHANGE_ROWS_PER_PAGE,
)

export const changeStatus = externalActionCreator(constants.CHANGE_STATUS)

export const changeSide = externalActionCreator(constants.CHANGE_SIDE)

export const changeOrganization = externalActionCreator(
  constants.CHANGE_ORGANIZATION,
)

export const applyFilters = externalActionCreator(constants.APPLY_FILTERS)

export const showAcceptOrDenyDialog = externalActionCreator(
  constants.SHOW_ACCEPT_OR_DENY_DIALOG,
)

export const closeAcceptOrDenyDialog = externalActionCreator(
  constants.CLOSE_ACCEPT_OR_DENY_DIALOG,
)

export const acceptTrustRequest = externalActionCreator(
  constants.ACCEPT_TRUST_REQUEST,
)
export const acceptTrustSuccess = externalActionCreator(
  constants.ACCEPT_TRUST_SUCCESS,
)
export const acceptTrustFailure = externalActionCreator(
  constants.ACCEPT_TRUST_FAILURE,
)

export const rejectTrustRequest = externalActionCreator(
  constants.REJECT_TRUST_REQUEST,
)
export const rejectTrustSuccess = externalActionCreator(
  constants.REJECT_TRUST_SUCCESS,
)
export const rejectTrustFailure = externalActionCreator(
  constants.REJECT_TRUST_FAILURE,
)

export const createTrust = actionCreatorTyped(constants.CREATE_TRUST)
