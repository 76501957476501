import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'

import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles/index'
import Typography from '@material-ui/core/Typography/index'
import TextField from '@material-ui/core/TextField/index'
import { Grid } from '@material-ui/core'
import Selector from './Selector'
import IconButton from '@material-ui/core/IconButton/index'
import SimpleMenu from './SimpleMenu'
import FormControl from '@material-ui/core/FormControl/index'
import FormHelperText from '@material-ui/core/FormHelperText/index'

const operators = [
  { id: 'equal', description: '=' },
  { id: 'notEqual', description: '\u2260' },
]

const parameters = () => [
  {
    id: 'call_status',
    description: i18next.t('organizations_web_atoir:callStatus'),
  },
  {
    id: 'order_status',
    description: i18next.t('organizations_web_atoir:orderStatus'),
  },
  {
    id: 'order_type',
    description: i18next.t('organizations_web_atoir:orderType'),
  },
  {
    id: 'order_result',
    description: i18next.t('organizations_web_atoir:enginerWorkResut'),
  },
]

const callStatuses = () => [
  {
    id: 'created',
    description: i18next.t('organizations_web_atoir:callStatusNameCreated'),
  },
  {
    id: 'inwork',
    description: i18next.t('organizations_web_atoir:callStatusNameInwork'),
  },
  {
    id: 'needrepair',
    description: i18next.t('organizations_web_atoir:callStatusNameNeedrepair'),
  },
  {
    id: 'qa',
    description: i18next.t('organizations_web_atoir:callStatusNameQA'),
  },
  {
    id: 'close',
    description: i18next.t('organizations_web_atoir:callStatusNameClose'),
  }, // Принят
  {
    id: 'cancel',
    description: i18next.t('organizations_web_atoir:callStatusNameCancel'),
  }, // Отменен
]

const orderStatuses = () => [
  {
    id: 'created',
    description: i18next.t('organizations_web_atoir:orderStatusNameCreated'),
  },
  {
    id: 'inwork',
    description: i18next.t('organizations_web_atoir:orderStatusNameInwork'),
  },
  {
    id: 'control',
    description: i18next.t('organizations_web_atoir:orderStatusNameControl'),
  }, // Формирование цен
  {
    id: 'qa',
    description: i18next.t('organizations_web_atoir:orderStatusNameQA'),
  }, // Контроль кочаства
  {
    id: 'done',
    description: i18next.t('organizations_web_atoir:orderStatusNameDone'),
  }, // Отправлен заказчику
  {
    id: 'close',
    description: i18next.t('organizations_web_atoir:orderStatusNameClose'),
  }, // Принят
  {
    id: 'cancel',
    description: i18next.t('organizations_web_atoir:orderStatusNameCancel'),
  }, // Отменен
]

const orderResults = () => [
  {
    id: 'needrepair',
    description: i18next.t('organizations_web_atoir:orderResultNameNeedrepair'),
  },
  {
    id: 'complete',
    description: i18next.t('organizations_web_atoir:orderResultNameComplete'),
  },
  {
    id: 'writeoff',
    description: i18next.t('organizations_web_atoir:orderResultNameWriteoff'),
  },
]

const orderTypes = () => [
  {
    id: 'diagnostic',
    description: i18next.t('organizations_web_atoir:orderTypeNameDiagnostic'),
  },
  {
    id: 'repair',
    description: i18next.t('organizations_web_atoir:orderTypeNameRepair'),
  },
]

const valueList = () => ({
  call_status: callStatuses(),
  order_status: orderStatuses(),
  order_type: orderTypes(),
  order_result: orderResults(),
})

const maxRulesCount = 10

const styles = theme => ({
  divBox: {
    display: 'flex',
  },
  boxSpaceBetween: {
    justifyContent: 'space-between',
  },
  divItemFlex: {
    flexGrow: 1,
  },
  divItemFix: {
    flexGrow: 0,
  },
  paddingLeftClass: {
    paddingLeft: theme.typography.pxToRem(16),
  },
  inputCenter: {
    textAlign: 'right',
  },
  operatorSelectWidth: {
    width: theme.typography.pxToRem(60),
  },
  rightToolboxWidth: {
    width: theme.typography.pxToRem(140),
  },
  spanOrderNumber: {
    flexGrow: 1,
    color: 'green',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  menuBtn: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(-8),
  },
})

export const conditionsToArray = (conditions, errors) => {
  const arr = []
  _.keys(conditions).forEach(triggerParameterName => {
    const items = [].concat(conditions[triggerParameterName])
    items.forEach((item, index) => {
      arr.push({
        triggerParameter: triggerParameterName,
        ...item,
        indexForError: index,
        errors: (errors || {})[triggerParameterName],
      })
    })
  })
  return _.sortBy(arr, 'number')
}

const newRowNumber = conditions => {
  const res = _.last(conditionsToArray(conditions))
  return res ? res.number + 1 : 1
}

const rowToConditionObject = (row, conditionsSimple) => {
  const out = conditionsSimple || {}
  // 1 удалить свойство по номеру
  // если row.number = null, то строка создается копирование, поэтому переход на второй шаг
  if (row.number) {
    _.keys(out).forEach(triggerParameterName => {
      if (Array.isArray(out[triggerParameterName])) {
        out[triggerParameterName] = out[triggerParameterName].filter(
          item => item.number !== row.number,
        )
        if (out[triggerParameterName].length === 0)
          delete out[triggerParameterName]
      } else if (out[triggerParameterName] === row.number)
        delete out[triggerParameterName]
    })
  }
  // 2 вставить новое
  // если row.operator = null, значит поле помечено для удаления
  if (row.operator) {
    if (out[row.triggerParameter]) {
      // от апи может прийти обьект и массив, приводим к массиву
      out[row.triggerParameter] = [].concat(out[row.triggerParameter], {
        number: row.number || newRowNumber(conditionsSimple),
        operator: row.operator,
        value: row.value,
      })
    } else {
      out[row.triggerParameter] = [
        {
          number: row.number || newRowNumber(conditionsSimple),
          operator: row.operator,
          value: row.value,
        },
      ]
    }
  }

  return out
}

const ConditionRow = withStyles(styles)(
  ({ classes, item, onChange, countTotal }) => {
    const onChangeHandler = (id, value) => {
      onChange({
        ...item,
        value:
          id === 'operator' ||
          (id === 'triggerParameter' && item.triggerParameter === value)
            ? item.value
            : null,
        [id]: value,
      })
    }

    const copyRow = () => {
      onChange({
        ...item,
        number: null,
      })
    }

    const deleteRow = () => {
      onChange({
        ...item,
        operator: null,
      })
    }

    const menuItems = [
      {
        id: 'copy',
        title: i18next.t('organizations_web_atoir:doCopy'),
        onClick: copyRow,
        enable: true,
      },
      {
        id: 'delete',
        title: i18next.t('organizations_web_atoir:doDelete'),
        onClick: deleteRow,
        enable: true,
      },
    ]
    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <div className={classes.divBox}>
            <div className={classes.divItemFlex}>
              <Selector
                fullWidth
                idValue="triggerParameter"
                valueList={parameters()}
                currentValue={item.triggerParameter}
                onChange={onChangeHandler}
              />
            </div>
            <div className={classes.divItemFix}>
              <Selector
                className={classNames(
                  classes.paddingLeftClass,
                  classes.operatorSelectWidth,
                )}
                fullWidth
                idValue="operator"
                valueList={operators}
                currentValue={item.operator}
                onChange={onChangeHandler}
                selectClasses={classes.inputCenter}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.divBox}>
            <div className={classes.divItemFlex}>
              <Selector
                fullWidth
                idValue="value"
                valueList={valueList()[item.triggerParameter]}
                currentValue={item.value}
                onChange={onChangeHandler}
                error={Boolean((item.errors || [])[item.indexForError])}
              />
            </div>
            <div
              className={classNames(
                classes.divItemFix,
                classes.rightToolboxWidth,
                classes.divBox,
                classes.boxSpaceBetween,
              )}
            >
              <span className={classes.spanOrderNumber}>
                <Typography variant="h6" style={{ color: 'inherit' }}>
                  {item.number}
                </Typography>
              </span>
              <SimpleMenu
                classes={{ menuBtn: classes.menuBtn }}
                disabledItems={{ copy: countTotal >= maxRulesCount }}
                menuItems={menuItems}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    )
  },
)

const ConditionsConstructor = ({
  onChange,
  conditions,
  idValueConditions,
  idValueLogic,
  logic,
  errors,
  touched,
}) => {
  const [conditionsArray, setConditionsArray] = useState(
    conditionsToArray(conditions.simple, errors.simple),
  )

  useEffect(() => {
    setConditionsArray(conditionsToArray(conditions.simple, errors.simple))
  }, [conditions, errors.simple])

  const changeRowHandler = item => {
    onChange(idValueConditions, {
      ...conditions,
      simple: rowToConditionObject(item, conditions.simple),
    })
  }

  const addNewRow = () => {
    changeRowHandler({
      triggerParameter: 'call_status',
      operator: 'equal',
      value: null,
    })
  }

  const changeTextHandler = ({ target: { value } }) => {
    onChange(idValueLogic, value)
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            {i18next.t('organizations_web_atoir:selectParameters')}
          </Typography>
        </Grid>
        {(conditionsArray || []).length > 0 && (
          <Grid item xs={12}>
            {conditionsArray.map(item => (
              <ConditionRow
                key={item.number}
                item={item}
                onChange={changeRowHandler}
                countTotal={conditionsArray.length}
              />
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl error={errors === 'object empty'} fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <IconButton
                  disabled={conditionsArray.length >= maxRulesCount}
                  aria-haspopup="true"
                  onClick={addNewRow}
                >
                  <AddCircleOutline
                    color={
                      errors === 'object empty' && Boolean(touched)
                        ? 'error'
                        : ''
                    }
                  />
                </IconButton>
              </Grid>
              <Grid container xs={11} alignItems="center">
                {errors === 'object empty' && Boolean(touched) && (
                  <FormHelperText>
                    {i18next.t(
                      'organizations_web_atoir:needAtLeastOneCondition',
                    )}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        {(conditionsArray || []).length > 1 && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              id={idValueLogic}
              onChange={changeTextHandler}
              value={logic || ''}
              label={i18next.t('organizations_web_atoir:filterLogic')}
              helperText={i18next.t(
                'organizations_web_atoir:filterFullExample',
              )}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

ConditionsConstructor.defaultProps = {
  idValueLogic: 'logic',
  conditions: {},
  onChange: () => {},
  logic: '',
}

ConditionsConstructor.propTypes = {
  idValueConditions: PropTypes.string.isRequired,
  idValueLogic: PropTypes.string,
  conditions: PropTypes.string,
  onChange: PropTypes.func,
  logic: PropTypes.string,
  touched: PropTypes.object.isRequired,
}

export default withStyles(styles)(ConditionsConstructor)
