import React, { Fragment, useState } from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import i18next from '../../../../../../i18n/i18n'
import TextButton from '../../../../../../widgets/Buttons/TextButton'
import TOWorks from '../TOWorks'

function WorksDialog({ onClose, name, id, organizationId, equipmentId }) {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {i18next.t('equipment_web_atoir:works')} | {name}
      </DialogTitle>
      <DialogContent>
        <TOWorks
          id={id}
          organizationId={organizationId}
          equipmentId={equipmentId}
        />
      </DialogContent>
      <DialogActions>
        <TextButton onClick={onClose}>
          {i18next.t('shared_web_atoir:close')}
        </TextButton>
      </DialogActions>
    </Dialog>
  )
}

export default function({
  classes,
  to,
  to: {
    name,
    regularity_value: regularityValue,
    regularity_indicator: regularityIndicator,
  },
  equipment,
}) {
  const [previewId, setPreview] = useState(null)

  return (
    <Fragment>
      <Card className={classes.toCardRoot}>
        <CardContent>
          <Typography className={classes.toName} variant="body1" gutterBottom>
            {name}
          </Typography>
          <Typography variant="caption">
            {i18next.t('equipment_web_atoir:regularyityIndicator', {
              regularity_indicator: i18next.t(
                `equipment_web_atoir:${regularityIndicator}RegularyityIndicator`,
              ),
              regularity_value: regularityValue,
            })}
          </Typography>
        </CardContent>
        <CardActions>
          {+to.worksCount > 0 ? (
            <TextButton onClick={() => setPreview(to)}>
              {i18next.t('shared_web_atoir:details')}
            </TextButton>
          ) : null}
        </CardActions>
      </Card>
      {previewId ? (
        <WorksDialog
          id={to.id}
          organizationId={equipment.organization_id}
          equipmentId={equipment.id}
          name={name}
          onClose={() => setPreview(null)}
        />
      ) : null}
    </Fragment>
  )
}
