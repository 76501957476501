import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import withStyles from '@material-ui/core/styles/withStyles'
import SimpleDialogLoader from '../../../../widgets/SimpleDialogLoader'
import config from '../../../../config'
import i18next from '../../../../i18n/i18n'
import { formatSizeUnits } from '../../../../helpers/formatSizeUnits'

const styles = () => ({
  inputFiles: {
    display: 'none',
  },
})

class FileAddComponent extends React.Component {
  constructor(props) {
    super(props)

    this.inputFileRef = React.createRef()
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching &&
      !this.props.error
    ) {
      this.props.uploadDoneFunc()
    }
  }

  fileUpload = files => {
    const { upload, idOrder, showDialogWithOk } = this.props
    const selectedFile = files[0]
    if (!selectedFile) {
      return
    }
    if (selectedFile.size > config.max_logo_photo_size) {
      showDialogWithOk(`${i18next.t('shared_web_atoir:fileToBig')} \
      ${formatSizeUnits(config.max_logo_photo_size)}`)
      return
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    upload({
      orderId: idOrder,
      formData,
    })
  }

  openFileDialog = () => {
    this.inputFileRef.current.click()
  }

  render() {
    const {
      props: { classes, isFetching },
      fileUpload,
    } = this

    return (
      <Fragment>
        <InputBase
          type="file"
          inputRef={this.inputFileRef}
          className={classes.inputFiles}
          onChange={event => {
            fileUpload(event.target.files)
          }}
        />
        <SimpleDialogLoader open={isFetching} />
      </Fragment>
    )
  }
}

FileAddComponent.propTypes = {
  uploadDoneFunc: PropTypes.func.isRequired,
  idOrder: PropTypes.number.isRequired,
}

export default withStyles(styles)(FileAddComponent)
