import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEmpty from 'is-empty'
import i18next from '../../../i18n/i18n'
import { getUserFullName } from '../../../helpers/userHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'

// components
import Heading from '../../../widgets/Lettering/Heading'
import DateInput from '../../../widgets/DateInput'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'

const styles = theme => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    width: '100%',
  },
  engineerTextField: {
    width: '100%',
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  containedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.dark,
      color: 'white',
    },
  },
  outlinedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: theme.palette.blueSecondary.main,
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
    '&:disabled': {
      borderColor: theme.palette.apply.extraLight,
      color: theme.palette.apply.extraLight,
    },
  },
})

class AssignToMeAsEngineer extends Component {
  state = {
    employee: null,
    date: new Date(),
    comment: null,
    errors: {},
  }

  componentDidMount() {
    const {
      props: { fetchOrganizationUserMe, organizationId },
    } = this
    fetchOrganizationUserMe(organizationId)
  }

  componentDidUpdate = prevProps => {
    const {
      props: { isFetchingMeInOrganization, meInOrganization },
    } = this
    if (
      prevProps.isFetchingMeInOrganization !== isFetchingMeInOrganization &&
      !isFetchingMeInOrganization &&
      !!meInOrganization
    ) {
      this.setState({ employee: meInOrganization })
    }
  }

  onChangeDate = date => {
    this.setState({ date })
  }

  onChangeComment = event => {
    this.setState({ comment: event.target.value })
  }

  onAssign = () => {
    const {
      state: { employee, date, comment },
      props: { onSubmit },
      onCloseDialog,
    } = this
    const errors = {}
    if (!date) {
      errors.date = i18next.t('shared_web_atoir:incorrectDateFormat')
    }
    this.setState({ errors })
    if (isEmpty(errors) && !isEmpty(onSubmit)) {
      onSubmit({
        engineer_user_id: employee.id,
        visit_date: date,
        visit_comment: comment,
      })
    }
    onCloseDialog()
  }

  onCloseDialog = () => {
    const {
      props: { onClose },
    } = this
    this.setState(
      {
        date: new Date(),
        comment: null,
        errors: {},
      },
      () => onClose(),
    )
  }

  render() {
    const {
      state: { employee, date, comment, errors },
      props: { classes, open },
      onChangeDate,
      onChangeComment,
      onAssign,
      onCloseDialog,
    } = this
    return (
      <Dialog
        classes={{ paper: classes.dialogRoot }}
        open={open}
        onClose={onCloseDialog}
      >
        <Heading
          className={classnames(classes.subheading, classes.defaultPadding)}
        >
          {i18next.t('service_desk_web_atoir:assingOrderToMeAsEngineer')}
        </Heading>
        <Divider />
        <DialogContent style={{ padding: 24, paddingTop: 16 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row" alignItems="flex-end">
                <Grid item md={6} sm={12}>
                  <TextField
                    fullWidth
                    disabled
                    label={i18next.t('service_desk_web_atoir:engineer')}
                    value={!!employee && getUserFullName(employee)}
                    className={classes.engineerTextField}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <DateInput
                    dateValid={errors.date === undefined}
                    disablePast
                    value={date}
                    handleChange={onChangeDate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={i18next.t('service_desk_web_atoir:comment')}
                variant="outlined"
                value={comment}
                onChange={onChangeComment}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <OutlinedButton
            onClick={onCloseDialog}
            className={classes.outlinedButton}
          >
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton
            onClick={onAssign}
            className={classes.containedButton}
            autoFocus
          >
            {i18next.t('service_desk_web_atoir:assignToOwnSpecialist')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

AssignToMeAsEngineer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default withStyles(styles)(AssignToMeAsEngineer)
