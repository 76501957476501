import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Sticky from 'react-stickynode'

// material UI
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles/index'

import config from '../../../../config'

const drawerWidth = config.right_drawer_width

const styles = theme => ({
  drawerPaper: {
    position: 'absolute',
    zIndex: 1000,
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerList: {
    paddingTop: theme.spacing(1),
  },
  drawerItem: {},
  ListItemChild: {
    padding: '0px',
    marginRight: '4px',
  },
  nested1: {
    paddingLeft: theme.spacing(4),
  },
  nested2: {
    paddingLeft: theme.spacing(6),
  },
  '@media (min-width: 1918px)': {
    drawerPaper: {
      width: drawerWidth,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    marginBottom: theme.spacing(2),
  },
})

class DrawerFiltersComponent extends Component {
  state = {}

  onToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  render = () => {
    const {
      classes,
      open,
      handleDrawerClose,
      handleDrawerOpen,
      component,
      footer,
    } = this.props
    return (
      component && (
        <nav>
          <Hidden lgUp implementation="js">
            <SwipeableDrawer
              variant="temporary"
              anchor="right"
              open={open}
              onClose={handleDrawerClose}
              onOpen={handleDrawerOpen}
              classes={{
                paper: classNames(classes.drawerPaper, 'algolia-drawer'),
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div className={classes.drawerHeader} />
              <Sticky>{component}</Sticky>
            </SwipeableDrawer>
          </Hidden>
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              anchor="right"
              open
            >
              <div className={classes.drawerHeader} />
              <Sticky>{component}</Sticky>
              <div className={classes.footer}>{footer}</div>
            </Drawer>
          </Hidden>
        </nav>
      )
    )
  }
}

DrawerFiltersComponent.defaultProps = {
  open: false,
  footer: null,
}

DrawerFiltersComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  footer: PropTypes.object,
  open: PropTypes.bool,
  handleDrawerClose: PropTypes.func.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
}

export default withStyles(styles)(DrawerFiltersComponent)
