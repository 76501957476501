const defaultUserSettings = {
  sd: {
    calls_list: {
      address: { city: true },
      equipment: {
        city: true,
        name: true,
        model: true,
        office: false,
        street: true,
        vendor: true,
        equipment_external_id: false,
        article_number: false,
        serial_number: false,
      },
      client_organization: { full_name: false, short_name: true },
      performer_organization: { full_name: false, short_name: true },
      reaction_date: true,
      visit_date: false,
      engineer_user: false,
      order_result: false,
      ext_num: true,
    },
    orders_list: {
      address: { city: true },
      equipment: {
        city: true,
        name: true,
        model: true,
        office: false,
        street: true,
        vendor: true,
        equipment_external_id: false,
        article_number: false,
        serial_number: false,
      },
      client_organization: { full_name: false, short_name: true },
      performer_organization: { full_name: false, short_name: true },
      reaction_date: true,
      visit_date: false,
      engineer_user: false,
      order_result: false,
      ext_num: true,
    },
  },
}
module.exports = defaultUserSettings
