import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(() => ({
  formControl: {},
}))

const Selector = ({
  currentValue,
  onChange,
  idValue,
  valueList,
  label,
  selectClasses,
  error,
  ...restProps
}) => {
  const classes = useStyles()
  const onChangeHandler = ({ target: { value } }) => {
    onChange(idValue, value)
  }
  return (
    <FormControl className={classes.formControl} error={error} {...restProps}>
      <InputLabel id="demo-simple-select-disabled-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-disabled-label"
        id="demo-simple-select-disabled"
        value={currentValue}
        onChange={onChangeHandler}
        classes={{
          select: selectClasses || '',
        }}
      >
        {valueList.map(action => (
          <MenuItem key={action.id} value={action.id}>
            {action.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

Selector.defaultProps = {
  label: '',
  error: false,
}

Selector.propTypes = {
  classes: PropTypes.object.isRequired,
  valueList: PropTypes.array.isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
}

export default memo(Selector)
