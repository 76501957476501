import { createRequestTypes } from '../actionTypes'

export const SET_DATA = 'serviceDesk/SET_DATA'
export const CLEAR_DATA = 'serviceDesk/CLEAR_DATA'

export const MAKE_RECURRING_FROM_CALL_REQUEST =
  'serviceDesk/MAKE_RECURRING_FROM_CALL_REQUEST'
export const MAKE_RECURRING_FROM_CALL_SUCCESS =
  'serviceDesk/MAKE_RECURRING_FROM_CALL_SUCCESS'
export const MAKE_RECURRING_FROM_CALL_FAILURE =
  'serviceDesk/MAKE_RECURRING_FROM_CALL_FAILURE'

export const EDIT_RECURRING_CALL_REQUEST =
  'serviceDesk/EDIT_RECURRING_CALL_REQUEST'
export const EDIT_RECURRING_CALL_SUCCESS =
  'serviceDesk/EDIT_RECURRING_CALL_SUCCESS'
export const EDIT_RECURRING_CALL_FAILURE =
  'serviceDesk/EDIT_RECURRING_CALL_FAILURE'

export const DELETE_RECURRING_CALL_REQUEST =
  'serviceDesk/DELETE_RECURRING_CALL_REQUEST'
export const DELETE_RECURRING_CALL_SUCCESS =
  'serviceDesk/DELETE_RECURRING_CALL_SUCCESS'
export const DELETE_RECURRING_CALL_FAILURE =
  'serviceDesk/DELETE_RECURRING_CALL_FAILURE'

export const SET_SC_SIDE_NEED_PARTICIPATION_FILTER =
  'serviceDesk/SET_SC_SIDE_NEED_PARTICIPATION_FILTER'
export const SET_SCO_SIDE_TYPE_ORDER = 'serviceDesk/SET_SCO_SIDE_TYPE_ORDER'
export const SET_SCO_SIDE_RESULT_ORDER = 'serviceDesk/SET_SCO_SIDE_RESULT_ORDER'
export const SET_SC_LIST_REQUEST_ROWS_PER_PAGE =
  'serviceDesk/SET_SC_LIST_REQUEST_ROWS_PER_PAGE'
export const SET_INITIAL_LIST_FILTERS_FOR_STATUS =
  'serviceDesk/SET_INITIAL_LIST_FILTERS_FOR_STATUS'

export const CREATE_REQUEST_REQUEST = 'serviceDesk/CREATE_REQUEST_REQUEST'
export const CREATE_REQUEST_SUCCESS = 'serviceDesk/CREATE_REQUEST_SUCCESS'
export const CREATE_REQUEST_FAILURE = 'serviceDesk/CREATE_REQUEST_FAILURE'

export const FETCH_CALL_PERMISSIONS_REQUEST =
  'serviceDesk/FETCH_CALL_PERMISSIONS_REQUEST'
export const FETCH_CALL_PERMISSIONS_SUCCESS =
  'serviceDesk/FETCH_CALL_PERMISSIONS_SUCCESS'
export const FETCH_CALL_PERMISSIONS_FAILURE =
  'serviceDesk/FETCH_CALL_PERMISSIONS_FAILURE'

export const CLEAR_CALL_HISTORY = 'serviceDesk/CLEAR_CALL_HISTORY'
export const FETCH_CALL_HISTORY_REQUEST =
  'serviceDesk/FETCH_CALL_HISTORY_REQUEST'
export const FETCH_CALL_HISTORY_SUCCESS =
  'serviceDesk/FETCH_CALL_HISTORY_SUCCESS'
export const FETCH_CALL_HISTORY_FAILURE =
  'serviceDesk/FETCH_CALL_HISTORY_FAILURE'

export const ASSIGN_TO_THIRD_PARTY_REQUEST_REQUEST =
  'serviceDesk/ASSIGN_TO_THIRD_PARTY_REQUEST_REQUEST'
export const ASSIGN_TO_THIRD_PARTY_REQUEST_SUCCESS =
  'serviceDesk/ASSIGN_TO_THIRD_PARTY_REQUEST_SUCCESS'
export const ASSIGN_TO_THIRD_PARTY_REQUEST_FAILURE =
  'serviceDesk/ASSIGN_TO_THIRD_PARTY_REQUEST_FAILURE'

export const ASSIGN_TO_OWN_SPECIALIST_REQUEST_REQUEST =
  'serviceDesk/ASSIGN_TO_OWN_SPECIALIST_REQUEST_REQUEST'
export const ASSIGN_TO_OWN_SPECIALIST_REQUEST_SUCCESS =
  'serviceDesk/ASSIGN_TO_OWN_SPECIALIST_REQUEST_SUCCESS'
export const ASSIGN_TO_OWN_SPECIALIST_REQUEST_FAILURE =
  'serviceDesk/ASSIGN_TO_OWN_SPECIALIST_REQUEST_FAILURE'

export const FETCH_CALL_REQUEST = 'serviceDesk/FETCH_CALL_REQUEST'
export const FETCH_CALL_SUCCESS = 'serviceDesk/FETCH_CALL_SUCCESS'
export const FETCH_CALL_FAILURE = 'serviceDesk/FETCH_CALL_FAILURE'

export const SENDING_TO_SUB_ORDER_REQUEST =
  'serviceDesk/SENDING_TO_SUB_ORDER_REQUEST'
export const SENDING_TO_SUB_ORDER_SUCCESS =
  'serviceDesk/SENDING_TO_SUB_ORDER_SUCCESS'
export const SENDING_TO_SUB_ORDER_FAILURE =
  'serviceDesk/SENDING_TO_SUB_ORDER_FAILURE'

export const UPDATE_CALL_REQUEST = 'serviceDesk/UPDATE_CALL_REQUEST'
export const UPDATE_CALL_SUCCESS = 'serviceDesk/UPDATE_CALL_SUCCESS'
export const UPDATE_CALL_FAILURE = 'serviceDesk/UPDATE_CALL_FAILURE'

export const UPLOAD_FILE_REQUEST = 'serviceDesk/UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'serviceDesk/UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'serviceDesk/UPLOAD_FILE_FAILURE'

export const DELETE_FILE_REQUEST = 'serviceDesk/DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'serviceDesk/DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'serviceDesk/DELETE_FILE_FAILURE'

export const DELETE_PHOTO_REQUEST = 'serviceDesk/DELETE_PHOTO_REQUEST'
export const DELETE_PHOTO_SUCCESS = 'serviceDesk/DELETE_PHOTO_SUCCESS'
export const DELETE_PHOTO_FAILURE = 'serviceDesk/DELETE_PHOTO_FAILURE'

export const UPLOAD_PHOTO_REQUEST = 'serviceDesk/UPLOAD_PHOTO_REQUEST'
export const UPLOAD_PHOTO_SUCCESS = 'serviceDesk/UPLOAD_PHOTO_SUCCESS'
export const UPLOAD_PHOTO_FAILURE = 'serviceDesk/UPLOAD_PHOTO_FAILURE'

export const FETCH_FILES_REQUEST = 'serviceDesk/FETCH_FILES_REQUEST'
export const FETCH_FILES_SUCCESS = 'serviceDesk/FETCH_FILES_SUCCESS'
export const FETCH_FILES_FAILURE = 'serviceDesk/FETCH_FILES_FAILURE'

export const FETCH_PHOTOS_REQUEST = 'serviceDesk/FETCH_PHOTOS_REQUEST'
export const FETCH_PHOTOS_SUCCESS = 'serviceDesk/FETCH_PHOTOS_SUCCESS'
export const FETCH_PHOTOS_FAILURE = 'serviceDesk/FETCH_PHOTOS_FAILURE'

export const FETCH_ORDER_REQUEST = 'serviceDesk/FETCH_ORDER_REQUEST'
export const FETCH_ORDER_SUCCESS = 'serviceDesk/FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'serviceDesk/FETCH_ORDER_FAILURE'

export const DOWNLOAD_DOCUMENT_REQUEST = 'serviceDesk/DOWNLOAD_DOCUMENT_REQUEST'
export const DOWNLOAD_PHOTO_REQUEST = 'serviceDesk/DOWNLOAD_PHOTO_REQUEST'

export const FETCH_SERVICE_CALLS_LIST_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALLS_LIST_REQUEST'
export const FETCH_SERVICE_CALLS_LIST_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALLS_LIST_SUCCESS'
export const FETCH_SERVICE_CALLS_LIST_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALLS_LIST_FAILURE'

export const FETCH_SERVICE_CALLS_LIST_COUNT_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALLS_LIST_COUNT_REQUEST'
export const FETCH_SERVICE_CALLS_LIST_COUNT_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALLS_LIST_COUNT_SUCCESS'
export const FETCH_SERVICE_CALLS_LIST_COUNT_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALLS_LIST_COUNT_FAILURE'

export const FETCH_SERVICE_CALL_ORDERS_LIST_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALL_ORDERS_LIST_REQUEST'
export const FETCH_SERVICE_CALL_ORDERS_LIST_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALL_ORDERS_LIST_SUCCESS'
export const FETCH_SERVICE_CALL_ORDERS_LIST_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALL_ORDERS_LIST_FAILURE'

export const FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_REQUEST'
export const FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_SUCCESS'
export const FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_FAILURE'

export const FETCH_ORDERS_REQUEST = 'serviceDesk/FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_SUCCESS = 'serviceDesk/FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAILURE = 'serviceDesk/FETCH_ORDERS_FAILURE'

export const ASSIGN_ORDER_TO_OWN_SPECIALIST_REQUEST =
  'serviceDesk/ASSIGN_ORDER_TO_OWN_SPECIALIST_REQUEST'
export const ASSIGN_ORDER_TO_OWN_SPECIALIST_SUCCESS =
  'serviceDesk/ASSIGN_ORDER_TO_OWN_SPECIALIST_SUCCESS'
export const ASSIGN_ORDER_TO_OWN_SPECIALIST_FAILURE =
  'serviceDesk/ASSIGN_ORDER_TO_OWN_SPECIALIST_FAILURE'

export const SEND_ORDER_TO_SUB_ORDER_REQUEST =
  'serviceDesk/SEND_ORDER_TO_SUB_ORDER_REQUEST'
export const SEND_ORDER_TO_SUB_ORDER_SUCCESS =
  'serviceDesk/SEND_ORDER_TO_SUB_ORDER_SUCCESS'
export const SEND_ORDER_TO_SUB_ORDER_FAILURE =
  'serviceDesk/SEND_ORDER_TO_SUB_ORDER_FAILURE'

export const CANCEL_REQUEST_REQUEST = 'serviceDesk/CANCEL_REQUEST_REQUEST'
export const CANCEL_REQUEST_SUCCESS = 'serviceDesk/CANCEL_REQUEST_SUCCESS'
export const CANCEL_REQUEST_FAILURE = 'serviceDesk/CANCEL_REQUEST_FAILURE'

export const REJECT_ORDER_REQUEST = 'serviceDesk/REJECT_ORDER_REQUEST'
export const REJECT_ORDER_SUCCESS = 'serviceDesk/REJECT_ORDER_SUCCESS'
export const REJECT_ORDER_FAILURE = 'serviceDesk/REJECT_ORDER_FAILURE'

export const CHANGE_PERFORMER_RESPONSIBLE_ORDER_REQUEST =
  'serviceDesk/CHANGE_PERFORMER_RESPONSIBLE_ORDER_REQUEST'
export const CHANGE_PERFORMER_RESPONSIBLE_ORDER_SUCCESS =
  'serviceDesk/CHANGE_PERFORMER_RESPONSIBLE_ORDER_SUCCESS'
export const CHANGE_PERFORMER_RESPONSIBLE_ORDER_FAILURE =
  'serviceDesk/CHANGE_PERFORMER_RESPONSIBLE_ORDER_FAILURE'

export const CHANGE_CLIENT_RESPONSIBLE_ORDER_REQUEST =
  'serviceDesk/CHANGE_CLIENT_RESPONSIBLE_ORDER_REQUEST'
export const CHANGE_CLIENT_RESPONSIBLE_ORDER_SUCCESS =
  'serviceDesk/CHANGE_CLIENT_RESPONSIBLE_ORDER_SUCCESS'
export const CHANGE_CLIENT_RESPONSIBLE_ORDER_FAILURE =
  'serviceDesk/CHANGE_CLIENT_RESPONSIBLE_ORDER_FAILURE'

export const CHANGE_ENGINEER_ORDER_REQUEST =
  'serviceDesk/CHANGE_ENGINEER_ORDER_REQUEST'
export const CHANGE_ENGINEER_ORDER_SUCCESS =
  'serviceDesk/CHANGE_ENGINEER_ORDER_SUCCESS'
export const CHANGE_ENGINEER_ORDER_FAILURE =
  'serviceDesk/CHANGE_ENGINEER_ORDER_FAILURE'

export const EDIT_ORDER_INFO_REQUEST = 'serviceDesk/EDIT_ORDER_INFO_REQUEST'
export const EDIT_ORDER_INFO_SUCCESS = 'serviceDesk/EDIT_ORDER_INFO_SUCCESS'
export const EDIT_ORDER_INFO_FAILURE = 'serviceDesk/EDIT_ORDER_INFO_FAILURE'

export const FETCH_ORDERS_LOG_REQUEST = 'serviceDesk/FETCH_ORDERS_LOG_REQUEST'
export const FETCH_ORDERS_LOG_SUCCESS = 'serviceDesk/FETCH_ORDERS_LOG_SUCCESS'
export const FETCH_ORDERS_LOG_FAILURE = 'serviceDesk/FETCH_ORDERS_LOG_FAILURE'

export const FETCH_ORDERS_LOG_COUNT_REQUEST =
  'serviceDesk/FETCH_ORDERS_LOG_COUNT_REQUEST'
export const FETCH_ORDERS_LOG_COUNT_SUCCESS =
  'serviceDesk/FETCH_ORDERS_LOG_COUNT_SUCCESS'
export const FETCH_ORDERS_LOG_COUNT_FAILURE =
  'serviceDesk/FETCH_ORDERS_LOG_COUNT_FAILURE'

export const CANCEL_ORDER_BY_CLIENT_REQUEST =
  'serviceDesk/CANCEL_ORDER_BY_CLIENT_REQUEST'
export const CANCEL_ORDER_BY_CLIENT_SUCCESS =
  'serviceDesk/CANCEL_ORDER_BY_CLIENT_SUCCESS'
export const CANCEL_ORDER_BY_CLIENT_FAILURE =
  'serviceDesk/CANCEL_ORDER_BY_CLIENT_FAILURE'
export const FETCH_ORDER_COMMENTS_REQUEST =
  'serviceDesk/FETCH_ORDER_COMMENTS_REQUEST '
export const FETCH_ORDER_COMMENTS_SUCCESS =
  'serviceDesk/FETCH_ORDER_COMMENTS_SUCCESS'
export const FETCH_ORDER_COMMENTS_FAILURE =
  'serviceDesk/FETCH_ORDER_COMMENTS_FAILURE'

export const UPLOAD_ORDER_COMMENT_REQUEST =
  'serviceDesk/UPLOAD_ORDER_COMMENT_REQUEST'
export const UPLOAD_ORDER_COMMENT_SUCCESS =
  'serviceDesk/UPLOAD_ORDER_COMMENT_SUCCESS'
export const UPLOAD_ORDER_COMMENT_FAILURE =
  'serviceDesk/UPLOAD_ORDER_COMMENT_FAILURE'

export const UPLOAD_ORDER_SUBCOMMENT_REQUEST =
  'serviceDesk/UPLOAD_ORDER_SUBCOMMENT_REQUEST'
export const UPLOAD_ORDER_SUBCOMMENT_SUCCESS =
  'serviceDesk/UPLOAD_ORDER_SUBCOMMENT_SUCCESS'
export const UPLOAD_ORDER_SUBCOMMENT_FAILURE =
  'serviceDesk/UPLOAD_ORDER_SUBCOMMENT_FAILURE'

export const FETCH_ORDER_COMMENTS_COUNT_REQUEST =
  'serviceDesk/FETCH_ORDER_COMMENTS_COUNT_REQUEST '
export const FETCH_ORDER_COMMENTS_COUNT_SUCCESS =
  'serviceDesk/FETCH_ORDER_COMMENTS_COUNT_SUCCESS'
export const FETCH_ORDER_COMMENTS_COUNT_FAILURE =
  'serviceDesk/FETCH_ORDER_COMMENTS_COUNT_FAILURE'

export const CREATE_SERVICE_CONTRACT_REQUEST =
  'serviceDesk/CREATE_SERVICE_CONTRACT_REQUEST '
export const CREATE_SERVICE_CONTRACT_SUCCESS =
  'serviceDesk/CREATE_SERVICE_CONTRACT_SUCCESS'
export const CREATE_SERVICE_CONTRACT_FAILURE =
  'serviceDesk/CREATE_SERVICE_CONTRACT_FAILURE'

export const SET_ORDER_RESULT_TYPE_REQUEST =
  'serviceDesk/SET_ORDER_RESULT_TYPE_REQUEST'
export const SET_ORDER_RESULT_TYPE_SUCCESS =
  'serviceDesk/SET_ORDER_RESULT_TYPE_SUCCESS'
export const SET_ORDER_RESULT_TYPE_FAILURE =
  'serviceDesk/SET_ORDER_RESULT_TYPE_FAILURE'

export const FETCH_SERVICE_CALL_ORDER_ITEMS_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALL_ORDER_ITEMS_REQUEST'
export const FETCH_SERVICE_CALL_ORDER_ITEMS_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALL_ORDER_ITEMS_SUCCESS'
export const FETCH_SERVICE_CALL_ORDER_ITEMS_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALL_ORDER_ITEMS_FAILURE'

export const FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_REQUEST'
export const FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_SUCCESS'
export const FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_FAILURE'

export const FETCH_SERVICE_CALL_SUBORDER_ITEMS_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALL_SUBORDER_ITEMS_REQUEST'
export const FETCH_SERVICE_CALL_SUBORDER_ITEMS_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALL_SUBORDER_ITEMS_SUCCESS'
export const FETCH_SERVICE_CALL_SUBORDER_ITEMS_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALL_SUBORDER_ITEMS_FAILURE'

export const FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_REQUEST =
  'serviceDesk/FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_REQUEST'
export const FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_SUCCESS =
  'serviceDesk/FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_SUCCESS'
export const FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_FAILURE =
  'serviceDesk/FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_FAILURE'

export const SAVE_ORDER_ITEMS_LIST_REQUEST =
  'serviceDesk/SAVE_ORDER_ITEMS_LIST_REQUEST'
export const SAVE_ORDER_ITEMS_LIST_SUCCESS =
  'serviceDesk/SAVE_ORDER_ITEMS_LIST_SUCCESS'
export const SAVE_ORDER_ITEMS_LIST_FAILURE =
  'serviceDesk/SAVE_ORDER_ITEMS_LIST_FAILURE'

export const CLEAR_ORDER_ITEMS_LIST_REQUEST =
  'serviceDesk/CLEAR_ORDER_ITEMS_LIST_REQUEST'
export const CLEAR_ORDER_ITEMS_LIST_SUCCESS =
  'serviceDesk/CLEAR_ORDER_ITEMS_LIST_SUCCESS'
export const CLEAR_ORDER_ITEMS_LIST_FAILURE =
  'serviceDesk/CLEAR_ORDER_ITEMS_LIST_FAILURE'

export const COPY_ORDER_ITEMS_FROM_CHILD_REQUEST =
  'serviceDesk/COPY_ORDER_ITEMS_FROM_CHILD_REQUEST'
export const COPY_ORDER_ITEMS_FROM_CHILD_SUCCESS =
  'serviceDesk/COPY_ORDER_ITEMS_FROM_CHILD_SUCCESS'
export const COPY_ORDER_ITEMS_FROM_CHILD_FAILURE =
  'serviceDesk/COPY_ORDER_ITEMS_FROM_CHILD_FAILURE'

export const FETCH_ORDER_NDS_REQUEST = 'serviceDesk/FETCH_ORDER_NDS_REQUEST'
export const FETCH_ORDER_NDS_SUCCESS = 'serviceDesk/FETCH_ORDER_NDS_SUCCESS'
export const FETCH_ORDER_NDS_FAILURE = 'serviceDesk/FETCH_ORDER_NDS_FAILURE'

export const FETCH_ORDER_OVERHEAD_REQUEST =
  'serviceDesk/FETCH_ORDER_OVERHEAD_REQUEST'
export const FETCH_ORDER_OVERHEAD_SUCCESS =
  'serviceDesk/FETCH_ORDER_OVERHEAD_SUCCESS'
export const FETCH_ORDER_OVERHEAD_FAILURE =
  'serviceDesk/FETCH_ORDER_OVERHEAD_FAILURE'

export const FETCH_ORDER_INCOME_NDS_REQUEST =
  'serviceDesk/FETCH_ORDER_INCOME_NDS_REQUEST'
export const FETCH_ORDER_INCOME_NDS_SUCCESS =
  'serviceDesk/FETCH_ORDER_INCOME_NDS_SUCCESS'
export const FETCH_ORDER_INCOME_NDS_FAILURE =
  'serviceDesk/FETCH_ORDER_INCOME_NDS_FAILURE'

export const UPDATE_ORDER_NDS_REQUEST = 'serviceDesk/UPDATE_ORDER_NDS_REQUEST'
export const UPDATE_ORDER_NDS_SUCCESS = 'serviceDesk/UPDATE_ORDER_NDS_SUCCESS'
export const UPDATE_ORDER_NDS_FAILURE = 'serviceDesk/UPDATE_ORDER_NDS_FAILURE'

export const UPDATE_OVERHEAD_REQUEST = 'serviceDesk/UPDATE_OVERHEAD_REQUEST'
export const UPDATE_OVERHEAD_SUCCESS = 'serviceDesk/UPDATE_OVERHEAD_SUCCESS'
export const UPDATE_OVERHEAD_FAILURE = 'serviceDesk/UPDATE_OVERHEAD_FAILURE'

export const UPDATE_ORDER_INCOME_NDS_REQUEST =
  'serviceDesk/UPDATE_ORDER_INCOME_NDS_REQUEST'
export const UPDATE_ORDER_INCOME_NDS_SUCCESS =
  'serviceDesk/UPDATE_ORDER_INCOME_NDS_SUCCESS'
export const UPDATE_ORDER_INCOME_NDS_FAILURE =
  'serviceDesk/UPDATE_ORDER_INCOME_NDS_FAILURE'

export const UPLOAD_ORDER_WORK_COMPLETION_FILE_REQUEST =
  'serviceDesk/UPLOAD_ORDER_WORK_COMPLETION_FILE_REQUEST '
export const UPLOAD_ORDER_WORK_COMPLETION_FILE_SUCCESS =
  'serviceDesk/UPLOAD_ORDER_WORK_COMPLETION_FILE_SUCCESS'
export const UPLOAD_ORDER_WORK_COMPLETION_FILE_FAILURE =
  'serviceDesk/UPLOAD_ORDER_WORK_COMPLETION_FILE_FAILURE'

export const DELETE_ORDER_WORK_COMPLETION_FILE_REQUEST =
  'serviceDesk/DELETE_ORDER_WORK_COMPLETION_FILE_REQUEST '
export const DELETE_ORDER_WORK_COMPLETION_FILE_SUCCESS =
  'serviceDesk/DELETE_ORDER_WORK_COMPLETION_FILE_SUCCESS'
export const DELETE_ORDER_WORK_COMPLETION_FILE_FAILURE =
  'serviceDesk/DELETE_ORDER_WORK_COMPLETION_FILE_FAILURE'

export const CONFIRM_CERTIFICATE_OF_COMPLETION_REQUEST =
  'serviceDesk/CONFIRM_CERTIFICATE_OF_COMPLETION_REQUEST'
export const CONFIRM_CERTIFICATE_OF_COMPLETION_SUCCESS =
  'serviceDesk/CONFIRM_CERTIFICATE_OF_COMPLETION_SUCCESS'
export const CONFIRM_CERTIFICATE_OF_COMPLETION_FAILURE =
  'serviceDesk/CONFIRM_CERTIFICATE_OF_COMPLETION_FAILURE'

export const FETCH_ACTIVE_SERVICE_CALL_ORDERS_REQUEST =
  'serviceDesk/FETCH_ACTIVE_SERVICE_CALL_ORDERS_REQUEST'
export const FETCH_ACTIVE_SERVICE_CALL_ORDERS_SUCCESS =
  'serviceDesk/FETCH_ACTIVE_SERVICE_CALL_ORDERS_SUCCESS'
export const FETCH_ACTIVE_SERVICE_CALL_ORDERS_FAILURE =
  'serviceDesk/FETCH_ACTIVE_SERVICE_CALL_ORDERS_FAILURE'

export const FETCH_CLOSED_SERVICE_CALL_ORDERS_REQUEST =
  'serviceDesk/FETCH_CLOSED_SERVICE_CALL_ORDERS_REQUEST'
export const FETCH_CLOSED_SERVICE_CALL_ORDERS_SUCCESS =
  'serviceDesk/FETCH_CLOSED_SERVICE_CALL_ORDERS_SUCCESS'
export const FETCH_CLOSED_SERVICE_CALL_ORDERS_FAILURE =
  'serviceDesk/FETCH_CLOSED_SERVICE_CALL_ORDERS_FAILURE'

export const FETCH_CANCELED_SERVICE_CALL_ORDERS_REQUEST =
  'serviceDesk/FETCH_CANCELED_SERVICE_CALL_ORDERS_REQUEST'
export const FETCH_CANCELED_SERVICE_CALL_ORDERS_SUCCESS =
  'serviceDesk/FETCH_CANCELED_SERVICE_CALL_ORDERS_SUCCESS'
export const FETCH_CANCELED_SERVICE_CALL_ORDERS_FAILURE =
  'serviceDesk/FETCH_CANCELED_SERVICE_CALL_ORDERS_FAILURE'

export const ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST =
  'serviceDesk/ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST'
export const ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS =
  'serviceDesk/ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS'
export const ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE =
  'serviceDesk/ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE'

export const REJECT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST =
  'serviceDesk/REJECT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST'
export const REJECT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS =
  'serviceDesk/REJECT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS'
export const REJECT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE =
  'serviceDesk/REJECT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE'

export const ASSIGN_USING_CONTRACT_REQUEST =
  'serviceDesk/ASSIGN_USING_CONTRACT_REQUEST'
export const ASSIGN_USING_CONTRACT_SUCCESS =
  'serviceDesk/ASSIGN_USING_CыONTRACT_SUCCESS'
export const ASSIGN_USING_CONTRACT_FAILURE =
  'serviceDesk/ASSIGN_USING_CONTRACT_FAILURE'

export const FETCH_CONTRACT_AVAILABLE_EQUIPMENT_REQUEST =
  'serviceDesk/FETCH_CONTRACT_AVAILABLE_EQUIPMENT_REQUEST '
export const FETCH_CONTRACT_AVAILABLE_EQUIPMENT_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_AVAILABLE_EQUIPMENT_SUCCESS'
export const FETCH_CONTRACT_AVAILABLE_EQUIPMENT_FAILURE =
  'serviceDesk/FETCH_CONTRACT_AVAILABLE_EQUIPMENT_FAILURE'

export const FETCH_CONTRACT_REQUEST = 'serviceDesk/FETCH_CONTRACT_REQUEST '
export const FETCH_CONTRACT_SUCCESS = 'serviceDesk/FETCH_CONTRACT_SUCCESS'
export const FETCH_CONTRACT_FAILURE = 'serviceDesk/FETCH_CONTRACT_FAILURE'

export const FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_REQUEST =
  'serviceDesk/FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_REQUEST '
export const FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_SUCCESS'
export const FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_FAILURE =
  'serviceDesk/FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_FAILURE'

export const ADD_EQUIPMENT_TO_CONTRACT_REQUEST =
  'serviceDesk/ADD_EQUIPMENT_TO_CONTRACT_REQUEST '
export const ADD_EQUIPMENT_TO_CONTRACT_SUCCESS =
  'serviceDesk/ADD_EQUIPMENT_TO_CONTRACT_SUCCESS'
export const ADD_EQUIPMENT_TO_CONTRACT_FAILURE =
  'serviceDesk/ADD_EQUIPMENT_TO_CONTRACT_FAILURE'

export const CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_REQUEST =
  'serviceDesk/CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_REQUEST'
export const CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_SUCCESS =
  'serviceDesk/CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_SUCCESS'
export const CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_FAILURE =
  'serviceDesk/CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_FAILURE'

export const SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_REQUEST =
  'serviceDesk/SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_REQUEST'
export const SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_SUCCESS =
  'serviceDesk/SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_SUCCESS'
export const SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_FAILURE =
  'serviceDesk/SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_FAILURE'

export const FETCH_OFFER_FILES_REQUEST = 'serviceDesk/FETCH_OFFER_FILES_REQUEST'
export const FETCH_OFFER_FILES_SUCCESS = 'serviceDesk/FETCH_OFFER_FILES_SUCCESS'
export const FETCH_OFFER_FILES_FAILURE = 'serviceDesk/FETCH_OFFER_FILES_FAILURE'

export const SEND_CALL_USING_CONTRACT_REQUEST =
  'serviceDesk/SEND_CALL_USING_CONTRACT_REQUEST'
export const SEND_CALL_USING_CONTRACT_SUCCESS =
  'serviceDesk/SEND_CALL_USING_CONTRACT_SUCCESS'
export const SEND_CALL_USING_CONTRACT_FAILURE =
  'serviceDesk/SEND_CALL_USING_CONTRACT_FAILURE'

export const GET_ORDER_POSSIBLE_REPORTS_REQUEST =
  'serviceDesk/GET_ORDER_POSSIBLE_REPORTS_REQUEST'
export const GET_ORDER_POSSIBLE_REPORTS_SUCCESS =
  'serviceDesk/GET_ORDER_POSSIBLE_REPORTS_SUCCESS'
export const GET_ORDER_POSSIBLE_REPORTS_FAILURE =
  'serviceDesk/GET_ORDER_POSSIBLE_REPORTS_FAILURE'

export const GET_CALL_POSSIBLE_BLANKS_REQUEST =
  'serviceDesk/GET_CALL_POSSIBLE_BLANKS_REQUEST'
export const GET_CALL_POSSIBLE_BLANKS_SUCCESS =
  'serviceDesk/GET_CALL_POSSIBLE_BLANKS_SUCCESS'
export const GET_CALL_POSSIBLE_BLANKS_FAILURE =
  'serviceDesk/GET_CALL_POSSIBLE_BLANKS_FAILURE'

export const GET_CALLS_BY_GENERAL_SEARCH_REQUEST =
  'serviceDesk/GET_CALLS_BY_GENERAL_SEARCH_REQUEST'
export const GET_CALLS_BY_GENERAL_SEARCH_SUCCESS =
  'serviceDesk/GET_CALLS_BY_GENERAL_SEARCH_SUCCESS'
export const GET_CALLS_BY_GENERAL_SEARCH_FAILURE =
  'serviceDesk/GET_CALLS_BY_GENERAL_SEARCH_FAILURE'

export const CLEAR_CALLS_SEARCH_BY_NUMBER =
  'serviceDesk/CLEAR_CALLS_SEARCH_BY_NUMBER'

export const SET_NDS_CHECKBOX_VALUE = 'serviceDesk/SET_NDS_CHECKBOX_VALUE'
export const SET_INPUT_NDS_CHECKBOX_VALUE =
  'serviceDesk/SET_INPUT_NDS_CHECKBOX_VALUE'
export const SET_OVERHEAD_CHECKBOX_VALUE =
  'serviceDesk/SET_OVERHEAD_CHECKBOX_VALUE'

export const FETCH_SD_ACTIONS_HISTORY_REQUEST =
  'serviceDesk/FETCH_SD_ACTIONS_HISTORY_REQUEST'
export const FETCH_SD_ACTIONS_HISTORY_SUCCESS =
  'serviceDesk/FETCH_SD_ACTIONS_HISTORY_SUCCESS'
export const FETCH_SD_ACTIONS_HISTORY_FAILURE =
  'serviceDesk/FETCH_SD_ACTIONS_HISTORY_FAILURE'
export const CLEAR_SD_ACTIONS_HISTORY = 'serviceDesk/CLEAR_SD_ACTIONS_HISTORY'

export const FETCH_SD_ACTIONS_HISTORY_COUNT = createRequestTypes(
  'serviceDesk/FETCH_SD_ACTIONS_HISTORY_COUNT',
)

export const TOGGLE_DISABLE_SCRIPTS = 'serviceDesk/TOGGLE_DISABLE_SCRIPTS'

export const CLEAR_ORDERS_LIST = 'serviceDesk/CLEAR_ORDERS_LIST'

export const SET_ORDER_RESULT_REPORTING_TYPE =
  'serviceDesk/SET_ORDER_RESULT_REPORTING_TYPE'
export const SHOULD_SET_ORDER_RESULT_REPORT =
  'serviceDesk/SHOULD_SET_ORDER_RESULT_REPORT'

export const GET_SC_DOCUMENT_LINK_REQUEST =
  'serviceDesk/GET_SC_DOCUMENT_LINK_REQUEST'
export const GET_SC_DOCUMENT_LINK_SUCCESS =
  'serviceDesk/GET_SC_DOCUMENT_LINK_SUCCESS'
export const GET_SC_DOCUMENT_LINK_FAILURE =
  'serviceDesk/GET_SC_DOCUMENT_LINK_FAILURE'

export const GET_SC_BLANK_DOCUMENT_LINK_REQUEST =
  'serviceDesk/GET_SC_BLANK_DOCUMENT_LINK_REQUEST'
export const GET_SC_BLANK_DOCUMENT_LINK_SUCCESS =
  'serviceDesk/GET_SC_BLANK_DOCUMENT_LINK_SUCCESS'
export const GET_SC_BLANK_DOCUMENT_LINK_FAILURE =
  'serviceDesk/GET_SC_BLANK_DOCUMENT_LINK_FAILURE'

export const GET_SCO_CERTIFICATE_DOCUMENT_LINK_REQUEST =
  'serviceDesk/GET_SCO_CERTIFICATE_DOCUMENT_LINK_REQUEST'
export const GET_SCO_CERTIFICATE_DOCUMENT_LINK_SUCCESS =
  'serviceDesk/GET_SCO_CERTIFICATE_DOCUMENT_LINK_SUCCESS'
export const GET_SCO_CERTIFICATE_DOCUMENT_LINK_FAILURE =
  'serviceDesk/GET_SCO_CERTIFICATE_DOCUMENT_LINK_FAILURE'

export const GET_SCO_DOCUMENT_LINK_REQUEST =
  'serviceDesk/GET_SCO_DOCUMENT_LINK_REQUEST'
export const GET_SCO_DOCUMENT_LINK_SUCCESS =
  'serviceDesk/GET_SCO_DOCUMENT_LINK_SUCCESS'
export const GET_SCO_DOCUMENT_LINK_FAILURE =
  'serviceDesk/GET_SCO_DOCUMENT_LINK_FAILURE'

export const GET_SCO_COMMENT_FILE_LINK_REQUEST =
  'serviceDesk/GET_SCO_COMMENT_FILE_LINK_REQUEST'
export const GET_SCO_COMMENT_FILE_LINK_SUCCESS =
  'serviceDesk/GET_SCO_COMMENT_FILE_LINK_SUCCESS'
export const GET_SCO_COMMENT_FILE_LINK_FAILURE =
  'serviceDesk/GET_SCO_COMMENT_FILE_LINK_FAILURE'

export const ATTACH_SC_TAG_REQUEST = 'serviceDesk/ATTACH_SC_TAG_REQUEST'
export const ATTACH_SC_TAG_SUCCESS = 'serviceDesk/ATTACH_SC_TAG_SUCCESS'
export const ATTACH_SC_TAG_FAILURE = 'serviceDesk/ATTACH_SC_TAG_FAILURE'

export const DETACH_SC_TAG_REQUEST = 'serviceDesk/DETACH_SC_TAG_REQUEST'
export const DETACH_SC_TAG_SUCCESS = 'serviceDesk/DETACH_SC_TAG_SUCCESS'
export const DETACH_SC_TAG_FAILURE = 'serviceDesk/DETACH_SC_TAG_FAILURE'

export const FETCH_CALL_HISTORY_COUNT = createRequestTypes(
  'serviceDesk/FETCH_CALL_HISTORY_COUNT',
)
