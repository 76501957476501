import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TrustRow from './TrustRow'
import { showAcceptOrDenyDialog } from '../../../../actions/trustedOrganizationActions'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showAcceptOrDenyDialog,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrustRow),
)
