import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import NoMatchComponent from '../../NoMatchComponent'
import AgreementCreation from '../../ServiceDesk/Contract/AgreementCreation'
import AgreementProfile from '../../ServiceDesk/Contract/AgreementProfile'
import AgreementAddEquipment from '../../ServiceDesk/Contract/AgreementProfile/AgreementAddEquipment'
import AgreementHistory from '../../ServiceDesk/Contract/AgreementHistory'

const ServiceDeskContractAgreementRouter = ({ match: { path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${path}/`}
        component={({ match: { path: _path } }) => (
          <Fragment>
            <Switch>
              <WrapperRoute
                showHeader
                showTitleOutOfPaper
                path={`${_path}create`}
                component={AgreementCreation}
              />
              <WrapperRoute
                showHeader
                showTitleOutOfPaper
                path={`${_path}:agreementId/add_equipment`}
                component={AgreementAddEquipment}
              />
              <WrapperRoute
                showHeader
                showTitleOutOfPaper
                path={`${_path}:agreementId/history`}
                component={AgreementHistory}
              />
              <WrapperRoute
                showHeader
                showTitleOutOfPaper
                path={`${_path}:agreementId`}
                component={AgreementProfile}
              />
            </Switch>
          </Fragment>
        )}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default ServiceDeskContractAgreementRouter
