import React from 'react'
import Settings from './Settings/index'

const Notifications = () => {
  return (
    <div>
      <Settings />
    </div>
  )
}

export default Notifications
