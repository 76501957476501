import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import OrganizationEquipmentTable from './OrganizationEquipmentTable'

import { setEquipmentRowsPerPage } from '../../../actions/equipmentActions'

const mapStateToProps = state => ({
  equipment: state.equipment.equipment,
  vendors: _.uniq(state.equipment.vendors),
  locations: state.locations.locations,
  organizations: state.organizations.organizations,
  departments: state.departments.departments,
  isFetchingOrganizations: state.organizations.isFetchingOrganizations,
  isFetchingEquipment: state.equipment.isFetchingEquipment,
  isFetchingLocations: state.locations.isFetchingLocations,
  isFetchingVendors: state.equipment.isFetchingVendors,
  isFetchingDepartments: state.departments.isFetchingDepartments,
  count: state.equipment.count,
  rowsPerPage: state.equipment.list.rowsPerPage,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEquipmentRowsPerPage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationEquipmentTable),
)
