export function formatSizeUnits(bytesTo) {
  let bytes = bytesTo
  if (bytes >= 1048576) {
    bytes = `${(bytes / 1048576).toFixed(1)} MB`
  } else if (bytes >= 1024) {
    bytes = `${(bytes / 1024).toFixed(1)} KB`
  } else if (bytes > 1) {
    bytes += ' bytes'
  } else if (bytes === 1) {
    bytes += ' byte'
  } else {
    bytes = '0 bytes'
  }
  return bytes
}
