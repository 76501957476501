import React, { Fragment, memo } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import buttonSchema from './button.schema'
import config from '../../../../../config'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Grid } from '@material-ui/core'
import i18next from '../../../../../i18n/i18n'
import ActionCreator from '../../Components/ActionCreator'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import DescriptionField from '../../Components/DescriptionField'
import SelectorChipsed from './SelectorChipsed'
import ConditionsConstructorWrapper from '../../Components/ConditionsConstructorWrapper'

const showInList = () => [
  { id: 'call', description: i18next.t('organizations_web_atoir:call') },
  { id: 'order', description: i18next.t('organizations_web_atoir:order') },
]

const scopeList = () => [
  {
    id: 'owner',
    description: i18next.t('organizations_web_atoir:ownerTagScopeValue'),
  },
  {
    id: 'admin',
    description: i18next.t('organizations_web_atoir:adminTagScopeValue'),
  },
  {
    id: 'manager',
    description: i18next.t('organizations_web_atoir:managerTagScopeValue'),
  },
  {
    id: 'plain',
    description: i18next.t('organizations_web_atoir:plainTagScopeValue'),
  },
]

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  helperTextRoot: {
    textAlign: 'right',
  },
})

const formikEnhancer = withFormik({
  validationSchema: buttonSchema,
  mapPropsToValues: ({ button }) => ({
    ...button,
  }),
  handleSubmit: (payload, { props, setSubmitting }) => {
    setSubmitting(false)
    props.onSubmit(payload)
  },
  displayName: 'CreateEditButtonFormEnhanced',
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
})

function arePropsEqualSectionProxy(prevProps, nextProps) {
  return (
    prevProps.sectionData === nextProps.sectionData &&
    prevProps.error === nextProps.error
  )
}

const SectionProxy = memo(
  ({ children, onChange, sectionData, sectionId, ...props }) => {
    const onChangeHandler = (event, possibleValue) => {
      let id = event
      let value = possibleValue
      if (event.target) {
        ;({ id, value } = event.target)
      }
      onChange(sectionId, {
        ...sectionData,
        [id]: value,
      })
    }
    return React.cloneElement(
      children,
      {
        ...props,
        onChange: onChangeHandler,
      },
      null,
    )
  },
  arePropsEqualSectionProxy,
)

SectionProxy.propTypes = {
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  sectionData: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
}

const CreateEditButtonFormTemplate = withStyles(styles)(props => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
    classes,
    organizationId,
    history,
    submitCount,
  } = props

  const cancelHandler = () => {
    history.goBack()
  }

  const setFieldValueHandler = (event, possibleValue) => {
    let id = event
    let value = possibleValue
    if (event.target) {
      ;({ id, value } = event.target)
    }
    setFieldValue(id, value, submitCount !== 0)
  }

  const onBlurHandler = id => {
    handleBlur({ target: { id } })
  }
  return (
    <form onSubmit={handleSubmit}>
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              error={errors.name && touched.name}
              onBlur={handleBlur}
              fullWidth
              id="name"
              onChange={setFieldValueHandler}
              value={values.name}
              label={i18next.t('organizations_web_atoir:buttonName')}
              inputProps={{ maxLength: 255 }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperTextRoot,
                },
              }}
              helperText={`${values.name.length}/${config.orgSettingNameLength}`}
            />
          </Grid>
          <Grid item xs={12}>
            <DescriptionField
              onBlur={handleBlur}
              fullWidth
              idValue="description"
              onChange={setFieldValueHandler}
              currentValue={values.description || ''}
              label={i18next.t('organizations_web_atoir:buttonDecsription')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`1. ${i18next.t(
                'organizations_web_atoir:showButtonInNextProfiles',
              )}`}
            </Typography>
            <SelectorChipsed
              fullWidth
              idValue="show_in"
              valueList={showInList()}
              currentValue={values.show_in}
              onChange={setFieldValueHandler}
              error={Boolean(errors.show_in) && touched.show_in}
              errorText={i18next.t(
                'organizations_web_atoir:selectAtLeastOneProfile',
              )}
              onBlur={onBlurHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`2. ${i18next.t(
                'organizations_web_atoir:conditionsToShowButton',
              )}`}
            </Typography>
            <ConditionsConstructorWrapper
              idValueConditions="conditions"
              idValueLogic="logic"
              conditions={values.conditions}
              logic={values.logic}
              onChange={setFieldValueHandler}
              errors={errors.conditions || {}}
              touched={touched.conditions}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`3. ${i18next.t('organizations_web_atoir:whoCanPressButton')}`}
            </Typography>
            <SelectorChipsed
              fullWidth
              idValue="scope"
              valueList={scopeList()}
              currentValue={values.scope}
              onChange={setFieldValueHandler}
              error={errors.scope && touched.scope}
              errorText={i18next.t(
                'organizations_web_atoir:selectAtLeastOneRole',
              )}
              onBlur={onBlurHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.marginBtm}>
              {`4. ${i18next.t('organizations_web_atoir:actThatStuff')}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ActionCreator
              organizationId={organizationId}
              actions={values.actions}
              onChange={setFieldValueHandler}
              errors={(errors.actions || [])[0] || {}}
              touched={(touched.actions || [])[0] || {}}
            />
          </Grid>
          <Grid xs={6} container justify="flex-start" alignItems="center">
            <OutlinedButton size="medium" onClick={cancelHandler}>
              {i18next.t('shared_web_atoir:cancel')}
            </OutlinedButton>
          </Grid>
          <Grid xs={6} container justify="flex-end" alignItems="center">
            <ContainedButton type="submit" size="medium">
              {i18next.t('shared_web_atoir:save')}
            </ContainedButton>
          </Grid>
        </Grid>
      </Fragment>
    </form>
  )
})

const CreateEditButtonForm = formikEnhancer(CreateEditButtonFormTemplate)

CreateEditButtonForm.defaultProps = {}

CreateEditButtonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  script: PropTypes.object.isRequired,
}

export default withStyles(styles)(CreateEditButtonForm)
