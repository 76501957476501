import React from 'react'

import SelectLimitedAuto from '../../../../../widgets/SelectLimitedAuto'
import { URL } from '../../../../../middlewares/api'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ExpandableTextField from '../components/ExpandableTextField'
import MyEquipmentsDialog from './MyEquipmentsDialog'
import TrustedOrgSelectDialog from '../TrustedOrgSelectDialog'

import i18next from '../../../../../i18n/i18n'

const OptionsForClient = ({
  clientOrganizationId,
  performerOrganizationId,
  equipmentId,
  onChange,
  errors,
}) => {
  function setMyOrgHandler(id, value) {
    const {
      Organization: { short_name: shortName },
    } = value
    onChange('client_organization_id', {
      client_organization_id: id,
      text: shortName,
    })
  }
  function setPerformerHandler(value) {
    const { id, short_name: shortName } = value
    onChange('performer_organization_id', {
      performer_organization_id: id,
      text: shortName,
    })
  }

  function checkedEquipmentHandler(value) {
    onChange('equipment_id', {
      equipment_id: value.id,
      text: value.name,
    })
  }

  return (
    <div>
      <FormControl fullWidth required error={errors.client_organization_id}>
        <InputLabel htmlFor="age-simple">
          {i18next.t('inner_calls_web_atoir:clientOrgName')}
        </InputLabel>
        <SelectLimitedAuto
          autoSetOnOneValue
          value={clientOrganizationId.client_organization_id}
          sourceUrl={`${URL}/users/members`}
          query={{
            filters: JSON.stringify({ state: 1 }),
            sort: '[short_organization_name=ASC]',
            block: 'sd_extended',
          }}
          onChange={setMyOrgHandler}
          requestResultTransformerToArray={r => r.members}
          itemTransformer={l => ({
            id: l.Organization.id,
            title: l.Organization.short_name,
          })}
        />
      </FormControl>
      <ExpandableTextField
        error={errors.equipment_id}
        value={equipmentId.text || ''}
        fullWidth
        disabled={!clientOrganizationId.client_organization_id}
        label={i18next.t('inner_calls_web_atoir:equipmentName')}
      >
        {({ open, handleClose }) => (
          <MyEquipmentsDialog
            open={open}
            handleClose={handleClose}
            org={clientOrganizationId}
            checkedEquipment={equipmentId}
            setCheckedEquipment={checkedEquipmentHandler}
          />
        )}
      </ExpandableTextField>
      <ExpandableTextField
        error={errors.performer_organization_id}
        required
        value={performerOrganizationId.text || ''}
        fullWidth
        disabled={!clientOrganizationId.client_organization_id}
        label={i18next.t('inner_calls_web_atoir:performmerOrgName')}
      >
        {({ open, handleClose }) => (
          <TrustedOrgSelectDialog
            title="Выбор организации-исполнителя"
            side="client"
            open={open}
            handleClose={handleClose}
            orgIdFor={clientOrganizationId.client_organization_id}
            selected={performerOrganizationId.performer_organization_id}
            onChange={setPerformerHandler}
          />
        )}
      </ExpandableTextField>
    </div>
  )
}

export default OptionsForClient
