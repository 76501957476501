import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import RLink from 'react-router-dom/Link'
import isEmpty from 'is-empty'
import { addMaskCharacters } from '../../helpers/phoneStringHelper'
import { organizationLocationStringify } from '../../helpers/parserHelper'

// internationalization
import i18next from '../../i18n/i18n'
import _ from 'lodash'

// components
import Loader from '../Loader'

// styles
import '../../styles/components/Staff.css'

// material-ui-icons
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField/TextField'

// modules
import ContainedButton from '../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../widgets/Buttons/OutlinedButton'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import FormControl from '@material-ui/core/FormControl'
import { URL } from '../../middlewares/api'
import SelectLimitedFromControl from '../../widgets/SelectLimitedFormControl'

class EnhancedTableHead extends Component {
  state = {
    filters: {},
  }

  update = filters => {
    const { fetchContacts: update } = this.props
    update(filters)
  }

  handleChange = field => event => {
    if (field === 'phone' && new RegExp(/[^0-9]/).test(event.target.value)) {
      return
    }
    this.setState(
      state => ({
        filters: {
          ...state.filters,
          [field]: event.target ? event.target.value : event,
        },
      }),
      () => {
        if (field === 'organizationId') {
          this.update(this.state.filters)
          return
        }
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(
          () => this.update(_.omitBy(this.state.filters, e => e === '')),
          300,
        )
      },
    )
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  handleToggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  render() {
    const { order, orderBy, classes } = this.props
    const columnData = [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: (
          <FormControl>
            <TextField
              id="name"
              label={i18next.t('user_profile_web_atoir:userFullName')}
              value={this.state.filters.initials}
              onChange={this.handleChange('initials')}
              margin="normal"
            />
          </FormControl>
        ),
      },
      {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: (
          <FormControl>
            <TextField
              id="phone"
              label={i18next.t('login_web_atoir:phone')}
              value={this.state.filters.phone}
              onChange={this.handleChange('phone')}
              margin="normal"
            />
          </FormControl>
        ),
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: (
          <FormControl>
            <TextField
              id="email"
              label={i18next.t('login_web_atoir:email')}
              value={this.state.filters.email}
              onChange={this.handleChange('email')}
              margin="normal"
            />
          </FormControl>
        ),
      },
      {
        id: 'company',
        numeric: false,
        disablePadding: false,
        label: (
          <SelectLimitedFromControl
            FormControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            label={i18next.t('organizations_web_atoir:organization')}
            firstElement={{ id: '', title: '' }}
            value={this.state.filters.organizationId}
            sourceUrl={`${URL}/organizations/canAdd`}
            onChange={this.handleChange('organizationId')}
            requestResultTransformerToArray={r => r.organizations}
            itemTransformer={l => ({ id: l.id, title: l.full_name })}
          />
        ),
      },
    ]
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          {columnData.map(
            column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.label}
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
}

const mapStateToPropsEnhancedTableHead = state => ({
  isFetchingOrganizations: state.organizations.adminOrganizations.isFetching,
  organizations: state.organizations.adminOrganizations.organizations,
})

const mapDispatchToPropsEnhancedTableHead = dispatch =>
  bindActionCreators({}, dispatch)

EnhancedTableHead = withRouter(
  connect(
    mapStateToPropsEnhancedTableHead,
    mapDispatchToPropsEnhancedTableHead,
  )(EnhancedTableHead),
)

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    minHeight: '0',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

let EnhancedTableToolbar = props => {
  const { onDeleteClicked, numSelected, classes } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subheading">
            {numSelected} {i18next.t('employees_web_atoir:staffSelectedAmount')}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title={i18next.t('employees_web_atoir:deleteStaff')}>
            <IconButton aria-label="Delete" onClick={onDeleteClicked}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  onDeleteClicked: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

class StaffComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      limit: 10,
      offset: 0,
      page: 0,
      rowsPerPage: 10,
      openDialog: false,
    }
  }

  componentDidMount() {
    const { fetchContacts, fetchContactsCount } = this
    fetchContacts()
    fetchContactsCount()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDeletingStaff !== this.props.isDeletingStaff &&
      this.props.isDeletingStaff === false
    ) {
      this.setState({
        selected: [],
        openDialog: false,
      })
      const { fetchContacts: update } = this.props
      update()
    }
  }

  fetchContacts = (filters = {}) => {
    const {
      props: { fetchContacts },
      state: { limit, offset },
    } = this
    fetchContacts({
      limit,
      offset,
      ...filters,
    })
  }

  fetchContactsCount = (filters = {}) => {
    const {
      props: { fetchContactsCount },
    } = this
    fetchContactsCount({
      ...filters,
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleCheckboxChange = (event, checked, id) => {
    if (checked) {
      this.setState(state => ({
        selected: [...state.selected, id],
      }))
    } else {
      this.setState(state => ({
        selected: state.selected.filter(selectedId => selectedId !== id),
      }))
    }
  }

  handleChangePage = (event, page) => {
    this.setState(
      state => ({ page, offset: state.limit * page }),
      this.fetchContacts,
    )
  }

  handleChangeRowsPerPage = event => {
    this.setState(
      { rowsPerPage: event.target.value, limit: event.target.value },
      this.fetchContacts,
    )
  }

  handleDeleteClicked = () => {
    this.setState({
      openDialog: true,
    })
  }

  deleteStaff = () => {
    const { deleteStaff } = this.props
    const { selected } = this.state
    deleteStaff(selected)
  }

  displayedRows = (from, to, count) => count

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
    })
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const { classes, contacts, isFetchingContacts, match, count } = this.props
    const { order, orderBy, selected, rowsPerPage, page } = this.state
    const emptyList = (
      <TableRow>
        <TableCell colSpan={6}>
          <Typography align="center">
            {i18next.t('shared_web_atoir:emptyList')}
          </Typography>
        </TableCell>
      </TableRow>
    )

    return (
      <Fragment>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            onDeleteClicked={this.handleDeleteClicked}
          />
          <Fragment>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={contacts.length}
                  classes={classes}
                  fetchContacts={filters => {
                    this.fetchContactsCount(filters)
                    this.fetchContacts(filters)
                  }}
                />

                <TableBody>
                  {isFetchingContacts ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography align="center">
                          <Loader />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : isEmpty(contacts) ? (
                    emptyList
                  ) : (
                    <Fragment>
                      {contacts.map(n => {
                        const isSelected = this.isSelected(n.contactUser.id)
                        return (
                          <TableRow hover key={n.contactUser.id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                disabled={
                                  !isEmpty(n.contactUser.OrganizationUsers)
                                }
                                selected={isSelected}
                                onChange={(event, checked) =>
                                  this.handleCheckboxChange(
                                    event,
                                    checked,
                                    n.contactUser.id,
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {n.contactUser.first_name !== 'none' ? (
                                <RLink to={`${match.url}/${n.contactUser.id}`}>
                                  <span className="staff-name-underlined">
                                    {`${n.contactUser.last_name} `}
                                    {`${n.contactUser.first_name} `}
                                    {`${n.contactUser.middle_name}`}
                                  </span>
                                </RLink>
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableCell className="table-cell-phone">
                              {n.contactUser.phone
                                ? addMaskCharacters(n.contactUser.phone)
                                : '-'}
                            </TableCell>
                            <TableCell>{n.contactUser.email}</TableCell>
                            <TableCell>
                              {!isEmpty(n.contactUser.OrganizationUsers)
                                ? n.contactUser.OrganizationUsers.filter(
                                    e => !e.is_owner,
                                  ).map(employment => (
                                    <ExpansionPanel
                                      key={employment.Organization.id}
                                      elevation={0}
                                      className="staff-expansion-panel"
                                    >
                                      <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        className="staff-expansion-panel-summary"
                                      >
                                        <div className="staff-container-row">
                                          <div className="staff-organization-name-container">
                                            <RLink
                                              to={`/organizations/${employment.Organization.id}/profile`}
                                            >
                                              <span className="staff-organization-name-underlined">
                                                {
                                                  employment.Organization
                                                    .short_name
                                                }
                                              </span>
                                            </RLink>
                                          </div>
                                          <div className="staff-position-container">
                                            {employment.is_admin ? (
                                              <div>
                                                <span className="staff-position-name-secondary">
                                                  {i18next.t(
                                                    'employees_web_atoir:administratorRole',
                                                  )}
                                                </span>
                                                <br />
                                              </div>
                                            ) : null}
                                            {employment.is_manager ? (
                                              <div>
                                                <span className="staff-position-name-secondary">
                                                  {i18next.t(
                                                    'employees_web_atoir:managerRole',
                                                  )}
                                                </span>
                                                <br />
                                              </div>
                                            ) : null}
                                            {employment.is_plain_user ? (
                                              <span className="staff-position-name-secondary">
                                                {i18next.t(
                                                  'employees_web_atoir:plainUserRole',
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      </ExpansionPanelSummary>
                                      <ExpansionPanelDetails className="staff-expansion-panel-details">
                                        <div>
                                          <span>
                                            {i18next.t(
                                              'organizations_web_atoir:organizationFullName',
                                            )}
                                            :{employment.Organization.full_name}
                                          </span>
                                          <br />
                                          <span>
                                            {`${i18next.t(
                                              'employees_web_atoir:hireDate',
                                            )}:
                                            ${
                                              employment.hire_date
                                                ? employment.hire_date
                                                    .split('T')
                                                    .shift()
                                                : '-'
                                            }`}
                                          </span>
                                          <br />
                                          <span>
                                            {`${i18next.t(
                                              'employees_web_atoir:position',
                                            )}:
                                            ${
                                              employment.position
                                                ? employment.position
                                                : '-'
                                            }`}
                                          </span>
                                          <br />
                                          <span>
                                            {`${i18next.t(
                                              'organizations_web_atoir:address',
                                            )}:
                                            ${
                                              employment.OL
                                                ? organizationLocationStringify(
                                                    employment.OL,
                                                  )
                                                : '-'
                                            }`}
                                          </span>
                                        </div>
                                      </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                  ))
                                : '-'}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              count={count || 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
              labelDisplayedRows={({
                from: fromPage,
                to: toPage,
                count: countPage,
              }) =>
                i18next.t('shared_web_atoir:labelDisplayedRows', {
                  fromPage,
                  toPage,
                  countPage,
                })
              }
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Fragment>
        </Paper>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {i18next.t('employees_web_atoir:deleteStaffDialogTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {i18next.t('employees_web_atoir:deleteStaffDialogText')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <OutlinedButton onClick={this.handleDialogClose} size="medium">
              {i18next.t('shared_web_atoir:cancel')}
            </OutlinedButton>
            <ContainedButton onClick={this.deleteStaff} size="medium">
              {i18next.t('shared_web_atoir:delete')}
            </ContainedButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

StaffComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(StaffComponent)
