import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import PropTypes from 'prop-types'

// ---material---
import { withStyles } from '@material-ui/core/styles/index'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// ---material-icons
import MoreVertIcon from '@material-ui/icons/MoreVert'

// ---helpers---
import { inventoryLocationStringify } from '../../../../../../helpers/parserHelper'
import i18next from '../../../../../../i18n/i18n'
import { getUserFullName } from '../../../../../../helpers/userHelper'

// ---components---
import SimpleDialog from '../../../../../Other/SimpleDialog/DialogComponent'

const styles = () => ({})

class TableOrders extends Component {
  state = {
    anchorEl: null,
    selectedOrder: {},
    isDeleteOrderOpen: false,
  }

  handleOrderMenuOpen = (event, order) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedOrder: order,
    })
  }

  handleOrderMenuClose = () => {
    this.setState({
      anchorEl: null,
      selectedOrder: {},
    })
  }

  handleToggle = (field, status) => () => {
    this.setState({
      [field]: status,
      anchorEl: null,
    })
  }

  handleOrderDelete = () => {
    const {
      props: { removeItem },
      state: { selectedOrder },
    } = this
    removeItem(selectedOrder.id)
  }

  handleEditEngineerOrder = () => {
    const {
      props: { editEngineerOrder },
      state: { selectedOrder },
    } = this
    editEngineerOrder(selectedOrder)()
    this.setState({
      anchorEl: null,
      selectedOrder: {},
    })
  }

  render() {
    const {
      props: { location, classes, permissions },
      state: { anchorEl, isDeleteOrderOpen, selectedOrder },
    } = this
    return (
      <section className={classes.section}>
        <Typography variant="subheading" gutterBottom align="left">
          {inventoryLocationStringify(location.OL)}
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                {i18next.t('inventory_web_atoir:dateOfCreation')}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {i18next.t('inventory_web_atoir:datePerformance')}
              </TableCell>
              <TableCell className={classes.tableCellSmall}>
                {i18next.t('inventory_web_atoir:Floor')}
              </TableCell>
              <TableCell className={classes.tableCellBig}>
                {i18next.t('inventory_web_atoir:Cabinet')}
              </TableCell>
              <TableCell className={classes.tableCellBig}>
                {i18next.t('inventory_web_atoir:Department')}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {i18next.t('inventory_web_atoir:Specialist')}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {i18next.t('inventory_web_atoir:statusOrder')}
              </TableCell>
              <TableCell className={classes.tableCellSmall}>
                {i18next.t('inventory_web_atoir:inventoryEquipmentAmount')}
              </TableCell>
              <TableCell className={classes.tableCellSmall} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(location.InventoryProjectJobs) &&
              location.InventoryProjectJobs.map(_order => {
                const department = isEmpty(_order.OrganizationDepartment)
                  ? '-'
                  : _order.OrganizationDepartment.name
                return (
                  <TableRow key={_order.id}>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{_order.floor}</TableCell>
                    <TableCell>{_order.office}</TableCell>
                    <TableCell>{department}</TableCell>
                    <TableCell>
                      {getUserFullName(_order.performer_user)}
                    </TableCell>
                    <TableCell>
                      {i18next.t(`inventory_web_atoir:${_order.status}`)}
                    </TableCell>
                    <TableCell>{_order.equipmentCount}</TableCell>
                    <TableCell>
                      <Fragment>
                        <IconButton
                          className="User-profile-edit-icon"
                          aria-owns={anchorEl ? 'simple-menu' : null}
                          aria-haspopup="true"
                          onClick={event =>
                            this.handleOrderMenuOpen(event, _order)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={
                            Boolean(anchorEl) &&
                            !isEmpty(selectedOrder) &&
                            selectedOrder.id === _order.id
                          }
                          onClose={this.handleOrderMenuClose}
                        >
                          <MenuItem disabled>
                            {i18next.t('shared_web_atoir:details')}
                          </MenuItem>
                          {isEmpty(_order.equipmentCount) &&
                            !isEmpty(permissions) &&
                            permissions.delete_job && (
                              <MenuItem
                                onClick={this.handleToggle(
                                  'isDeleteOrderOpen',
                                  true,
                                )}
                              >
                                {i18next.t('shared_web_atoir:delete')}
                              </MenuItem>
                            )}
                          {!isEmpty(permissions) &&
                            permissions.change_performer_user && (
                              <MenuItem onClick={this.handleEditEngineerOrder}>
                                {i18next.t(
                                  'inventory_web_atoir:changeEngineer',
                                )}
                              </MenuItem>
                            )}
                        </Menu>
                      </Fragment>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <SimpleDialog
          isOpen={isDeleteOrderOpen}
          handleClose={this.handleToggle('isDeleteOrderOpen', false)}
          handlePositive={() => {
            this.handleToggle('isDeleteOrderOpen', false)()
            this.handleOrderDelete()
          }}
          handleNegative={this.handleToggle('isDeleteOrderOpen', false)}
          positiveText={i18next.t('shared_web_atoir:delete')}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          title=""
          content={`${i18next.t('inventory_web_atoir:wantToDeleteOrder')}?`}
        />
      </section>
    )
  }
}

TableOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  removeItem: PropTypes.func.isRequired,
  editEngineerOrder: PropTypes.func.isRequired,
  permissions: PropTypes.func.isRequired,
}

export default withStyles(styles)(TableOrders)
