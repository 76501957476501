import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import FormComponent from '../../../widgets/FormComponent'
import LoginFormWrapper from '../LoginFormWrapper'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'

const propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  formControl: {
    margin: theme.spacing(1),
  },
})

class RepairPasswordComponent extends Component {
  state = {
    password: '',
    passwordConfirm: '',
    errors: {},
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSettingNewPassword !== this.props.isSettingNewPassword &&
      this.props.isSettingNewPassword === false &&
      isEmpty(this.props.repairPasswordErrors)
    ) {
      this.props.history.push('/profile')
    }
  }

  render() {
    const { classes, isSettingNewPassword, repairPasswordErrors } = this.props
    const { errors } = this.state

    const passwordHelperText = !isEmpty(errors.password)
      ? errors.password
      : !isEmpty(repairPasswordErrors.password)
      ? repairPasswordErrors.password[0]
      : ''

    const passwordConfirmHelperText = !isEmpty(errors.passwordConfirm)
      ? errors.passwordConfirm
      : !isEmpty(repairPasswordErrors.passwordConfirm)
      ? repairPasswordErrors.passwordConfirm[0]
      : ''

    return (
      <LoginFormWrapper
        title={i18next.t('login_web_atoir:changePasswordTitle')}
        nav
        text={i18next.t('login_web_atoir:alreadyRegisteredText')}
        link="/"
        linkText={i18next.t('login_web_atoir:enter')}
      >
        <FormComponent
          titleForm={i18next.t('login_web_atoir:enterNewPassword')}
          submitText={i18next.t('login_web_atoir:saveNewPassword')}
          isLoading={isSettingNewPassword}
          handleSubmitAction={this.handleSubmitForm}
        >
          <FormGroup>
            <TextField
              error={
                !isEmpty(errors.password) ||
                !isEmpty(repairPasswordErrors.password)
              }
              required
              id="password"
              type="password"
              label={i18next.t('login_web_atoir:newPassword')}
              placeholder={i18next.t('login_web_atoir:enterNewPassword')}
              className={classes.textField}
              helperText={passwordHelperText}
              margin="normal"
              onChange={this.handleTextChange('password')}
              value={this.state.password}
            />
            <TextField
              error={
                !isEmpty(errors.passwordConfirm) ||
                !isEmpty(repairPasswordErrors.passwordConfirm)
              }
              required
              id="passwordConfirm"
              label={i18next.t('login_web_atoir:newPasswordConfirmation')}
              placeholder={i18next.t(
                'login_web_atoir:enterNewPasswordConfirmation',
              )}
              className={classes.textField}
              helperText={passwordConfirmHelperText}
              margin="normal"
              onChange={this.handleTextChange('passwordConfirm')}
              value={this.state.passwordConfirm}
              type="password"
            />
          </FormGroup>
        </FormComponent>
      </LoginFormWrapper>
    )
  }

  handleSubmitForm = () => {
    const { setNewPassword } = this
    setNewPassword()
  }

  setNewPassword = () => {
    const { password, passwordConfirm } = this.state
    const emptyErrors = this.validateEmptyFields(password, passwordConfirm)
    const passwordErrors = this.validateNewPasswords(password, passwordConfirm)
    if (!isEmpty(emptyErrors)) {
      this.setState({
        errors: emptyErrors,
      })
      return
    }
    if (!isEmpty(passwordErrors)) {
      this.setState({
        errors: passwordErrors,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const { setNewPassword } = this.props
    const data = {
      password,
      password_confirm: passwordConfirm,
    }
    setNewPassword(data)
  }

  validateEmptyFields = (password, passwordConfirm) => {
    const errors = {}
    if (isEmpty(password)) {
      errors.password = i18next.t('validation_web_atoir:fieldShouldBeFilled')
    }
    if (isEmpty(passwordConfirm)) {
      errors.passwordConfirm = i18next.t(
        'validation_web_atoir:fieldShouldBeFilled',
      )
    }
    return errors
  }

  validateNewPasswords = (password, passwordConfirm) => {
    const errors = {}
    if (password !== passwordConfirm) {
      errors.passwordConfirm = i18next.t(
        'validation_web_atoir:passwordConfirmationNotMatch',
      )
    }
    return errors
  }
}

RepairPasswordComponent.propTypes = propTypes

export default withStyles(styles)(RepairPasswordComponent)
