import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import classNames from 'classnames'

import ToolTipBox from './ToolTipBox'

import i18next from '../../../../i18n/i18n'

const style = {
  tableCell: {
    border: 0,
  },
  selectCell: {
    minWidth: 300,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}

const MatchCaseRow = withStyles(style)(
  ({ classes, option, headersList, onChange, value }) => {
    function onChangeHandler(e) {
      const { id } = option
      const {
        target: { value: newValue, name, checked },
      } = e
      if (name === 'MatchCaseRowSelect') {
        onChange(id, { ...value, col: newValue })
      }
      if (name === 'MatchCaseRowCheckBox') {
        onChange(id, { ...value, unique: checked })
      }
    }
    return (
      <TableRow>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {option.title}
        </TableCell>
        <TableCell
          align="right"
          className={classNames(classes.tableCell, classes.selectCell)}
        >
          <Select
            name="MatchCaseRowSelect"
            fullWidth
            value={`${(value || {}).col}` || ''}
            onChange={onChangeHandler}
          >
            <MenuItem value="">
              <em>{i18next.t('equipment_web_atoir:emptyFiled')}</em>
            </MenuItem>
            {headersList &&
              headersList.map(header => (
                <MenuItem
                  disabled={header.disabled}
                  key={header.collIndex}
                  value={`${header.collIndex}`}
                >
                  {header.value}
                </MenuItem>
              ))}
          </Select>
        </TableCell>
        <TableCell padding="checkbox" className={classes.tableCell}>
          {option.uniqueAvailable && (
            <Checkbox
              name="MatchCaseRowCheckBox"
              checked={(value || {}).unique || false}
              onChange={onChangeHandler}
              color="primary"
            />
          )}
        </TableCell>
      </TableRow>
    )
  },
)
MatchCaseRow.displayName = 'MatchCaseRow'

function HeadersMatchingComponent({
  classes,
  headersList,
  optionsList,
  onChange,
  headersMatching,
}) {
  return (
    <React.Fragment>
      <div className={classes.content}>
        <Table
          className={classes.table}
          style={{ width: 'auto', tableLayout: 'auto' }}
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                {i18next.t('equipment_web_atoir:equipmentField')}
              </TableCell>
              <TableCell
                className={classNames(classes.tableCell, classes.selectCell)}
              >
                {i18next.t('equipment_web_atoir:tableField')}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <ToolTipBox
                  text={
                    <div style={{ minWidth: 100 }}>
                      {i18next.t('equipment_web_atoir:uniqField')}
                    </div>
                  }
                >
                  {i18next.t('equipment_web_atoir:uniqCaption')}
                </ToolTipBox>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {optionsList.map(option => (
              <MatchCaseRow
                key={option.id}
                option={option}
                headersList={headersList}
                onChange={onChange}
                value={(headersMatching || [])[option.id]}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  )
}

HeadersMatchingComponent.defaultProps = {}

HeadersMatchingComponent.propTypes = {
  headersMatching: PropTypes.object.isRequired,
  headersList: PropTypes.array.isRequired,
  optionsList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}
HeadersMatchingComponent.displayName = 'HeadersMatchingComponent'

export default withStyles(style)(HeadersMatchingComponent)
