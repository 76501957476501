import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChoosingEquipment from './ChoosingEquipment'

class Wrapper extends Component {
  state = {}

  render() {
    const {
      props: { handleNext, handleBack, setRequestDataOnCreation, creationData },
    } = this
    return (
      <ChoosingEquipment
        data={creationData}
        setRequestData={setRequestDataOnCreation}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    )
  }
}

Wrapper.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
}

export default Wrapper
