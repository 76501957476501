import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import {
  palette,
  spacing,
  typography,
  flexbox,
  display,
  borders,
} from '@material-ui/system'

import WarningIcon from '@material-ui/icons/Warning'

import TextButton from '../../../widgets/Buttons/TextButton'
import PaymentDialog from './PaymentDialog'
import SuccessDialog from './SuccessDialog'
import i18next from '../../../i18n/i18n'
import { Typography } from '@material-ui/core'

const Box = styled.div`${palette}${spacing}${typography}${borders}${flexbox}${display}`
const Section = styled.div`${display}${flexbox}`

function checkPaymentRequestingPermissions(role) {
  return role.is_admin || role.is_owner
}

function BannerWidget({
  id,
  name,
  onHide,
  role,
  onPaimentRequest,
  paymentDialogOpen,
  successDialogOpen,
  setPaymentDialogOpen,
  setSuccessDialogOpen,
}) {
  function onHideHandler() {
    onHide(id)
  }

  function paymentDialogOpenHandler(value = true) {
    setPaymentDialogOpen(value)
  }

  function successDialogOpenHandler(value = true) {
    setSuccessDialogOpen(value)
  }

  function doPaymentRequest() {
    onPaimentRequest({ id })
  }

  return (
    <React.Fragment>
      {id && (
        <Box
          pl={3}
          pt={2}
          pb={0}
          mt="-38px"
          ml="-38px"
          mr="-38px"
          mb={1}
          borderColor="rgba(0, 0, 0, 0.12)"
          bgcolor="#FFFFFF"
        >
          <Section display="flex">
            <Box>
              <WarningIcon fontSize="large" color="error" />
            </Box>
            <Box flex={1} ml={4} mt={-2} display="flex">
              <Box
                mt={2}
                flex={1}
                pb="17px"
                display="flex"
                alignItems="flex-end"
              >
                <Typography component="div">
                  {i18next.t(
                    'service_desk_web_atoir:sdBannerBannerWidgetText',
                    { name },
                  )}
                </Typography>
              </Box>
              <Box ml="90px" display="flex" alignItems="flex-end">
                {checkPaymentRequestingPermissions(role) ? (
                  <TextButton onClick={paymentDialogOpenHandler} size="medium">
                    {i18next.t('service_desk_web_atoir:doPay')}
                  </TextButton>
                ) : (
                  <TextButton onClick={onHideHandler} size="medium">
                    {i18next.t('service_desk_web_atoir:setIGotIt')}
                  </TextButton>
                )}
              </Box>
            </Box>
          </Section>
        </Box>
      )}
      <PaymentDialog
        open={paymentDialogOpen}
        isOpenHandler={paymentDialogOpenHandler}
        doPaymentRequest={doPaymentRequest}
      />
      <SuccessDialog
        open={successDialogOpen}
        isOpenHandler={successDialogOpenHandler}
      />
    </React.Fragment>
  )
}

BannerWidget.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
  onPaimentRequest: PropTypes.func.isRequired,
  paymentDialogOpen: PropTypes.bool.isRequired,
  successDialogOpen: PropTypes.bool.isRequired,
  setPaymentDialogOpen: PropTypes.func.isRequired,
  setSuccessDialogOpen: PropTypes.func.isRequired,
}

export default BannerWidget
