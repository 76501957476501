import { call } from '../middlewares/loginMiddlewares'
import { put } from 'redux-saga/effects'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import isEmpty from 'is-empty'
import { handleErrors } from './errorsHelper'
import { hideDialogLoader, showDialogLoader } from '../actions/utilsActions'

export const downloadFile = (name, blob) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
}

export const createDefaultSaga = (apiCall, onSuccess, onFailure, loader) =>
  function* defaultSaga({ payload }) {
    try {
      if (loader) {
        yield put(showDialogLoader())
      }
      const result = yield call(apiCall(payload))
      if (!isEmpty(result.success)) {
        if (result.success) {
          yield put(onSuccess(result, payload))
          if (loader) {
            yield put(hideDialogLoader())
          }
        } else {
          const errors = handleErrors(result)
          yield put(onFailure(result.code))
          yield put(receivedErrorMessage(errors))
          if (loader) {
            yield put(hideDialogLoader())
          }
        }
      }
    } catch (error) {
      yield put(catchError(error.message))
      if (loader) {
        yield put(hideDialogLoader())
      }
    }
  }
