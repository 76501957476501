import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InnerCallsFilters from './InnerCallsFilters'
import {
  fetchInnerCallsNumbersRequest,
  fetchInnerCallsEngineersRequest,
  fetchInnerCallsEquipmentsRequest,
} from '../../../../actions/innerCallsActions'

const mapStateToProps = state => ({
  isFetchingNumbers: state.innerCalls.innerCallsNumbers.isFetching,
  status: state.innerCalls.innerCallsList.status,
  numbers: state.innerCalls.innerCallsNumbers.numbers,
  isFetchingEngineers: state.innerCalls.innerCallsEngineers.isFetching,
  engineers: state.innerCalls.innerCallsEngineers.engineers,
  isFetchingEquipments: state.innerCalls.innerCallsEquipments.isFetching,
  equipments: state.innerCalls.innerCallsEquipments.equipments,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInnerCallsNumbersRequest,
      fetchInnerCallsEngineersRequest,
      fetchInnerCallsEquipmentsRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerCallsFilters),
)
