import {
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  FETCH_PHYSICAL_LOCATION_REQUEST,
  FETCH_PHYSICAL_LOCATION_FAILURE,
  FETCH_PHYSICAL_LOCATION_SUCCESS,
  FETCH_LEGAL_LOCATION_FAILURE,
  FETCH_LEGAL_LOCATION_REQUEST,
  FETCH_LEGAL_LOCATION_SUCCESS,
  UPDATE_LOCATION_IN_LOCATIONS,
  FETCH_USER_EQUIPMENTS_LOCATIONS_FAILURE,
  FETCH_USER_EQUIPMENTS_LOCATIONS_SUCCESS,
  FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_COUNT_REQUEST,
  FETCH_LOCATIONS_COUNT_SUCCESS,
  FETCH_LOCATIONS_COUNT_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from '../constants/actionTypes'

export const fetchLocation = (organizationId, id) => ({
  type: FETCH_LOCATION_REQUEST,
  organizationId,
  id,
})

export const fetchLocationSuccess = data => ({
  type: FETCH_LOCATION_SUCCESS,
  data,
})

export const fetchLocationFailure = code => ({
  type: FETCH_LOCATION_FAILURE,
  code,
})

export const fetchUserEquipmentLocations = data => ({
  type: FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST,
  data,
})

export const fetchUserEquipmentLocationsSuccess = (data, count) => ({
  type: FETCH_USER_EQUIPMENTS_LOCATIONS_SUCCESS,
  data,
  count,
})

export const fetchUserEquipmentLocationsFailure = code => ({
  type: FETCH_USER_EQUIPMENTS_LOCATIONS_FAILURE,
  code,
})

export const fetchPhysicalLocation = organizationId => ({
  type: FETCH_PHYSICAL_LOCATION_REQUEST,
  organizationId,
})

export const fetchPhysicalLocationSuccess = data => ({
  type: FETCH_PHYSICAL_LOCATION_SUCCESS,
  data,
})

export const fetchPhysicalLocationFailure = code => ({
  type: FETCH_PHYSICAL_LOCATION_FAILURE,
  code,
})

export const fetchLegalLocation = organizationId => ({
  type: FETCH_LEGAL_LOCATION_REQUEST,
  organizationId,
})

export const fetchLegalLocationSuccess = data => ({
  type: FETCH_LEGAL_LOCATION_SUCCESS,
  data,
})

export const fetchLegalLocationFailure = code => ({
  type: FETCH_LEGAL_LOCATION_FAILURE,
  code,
})

export const fetchLocations = (organizationId, query) => ({
  type: FETCH_LOCATIONS_REQUEST,
  organizationId,
  query,
})

export const fetchLocationsSuccess = data => ({
  type: FETCH_LOCATIONS_SUCCESS,
  data,
})

export const fetchLocationsFailure = code => ({
  type: FETCH_LOCATIONS_FAILURE,
  code,
})

export const fetchLocationsCount = organizationId => ({
  type: FETCH_LOCATIONS_COUNT_REQUEST,
  organizationId,
})

export const fetchLocationsCountSuccess = count => ({
  type: FETCH_LOCATIONS_COUNT_SUCCESS,
  count,
})

export const fetchLocationsCountFailure = code => ({
  type: FETCH_LOCATIONS_COUNT_FAILURE,
  code,
})

export const createLocation = (organizationId, data) => ({
  type: CREATE_LOCATION_REQUEST,
  organizationId,
  data,
})

export const createLocationSuccess = data => ({
  type: CREATE_LOCATION_SUCCESS,
  data,
})

export const createLocationFailure = code => ({
  type: CREATE_LOCATION_FAILURE,
  code,
})

export const updateLocation = (
  organizationId,
  locationId,
  data,
  updateLocations,
) => ({
  type: UPDATE_LOCATION_REQUEST,
  organizationId,
  locationId,
  data,
  updateLocations,
})

export const updateLocationSuccess = data => ({
  type: UPDATE_LOCATION_SUCCESS,
  data,
})

export const updateLocationFailure = code => ({
  type: UPDATE_LOCATION_FAILURE,
  code,
})

export const deleteLocation = (organizationId, locationId) => ({
  type: DELETE_LOCATION_REQUEST,
  organizationId,
  locationId,
})

export const deleteLocationSuccess = data => ({
  type: DELETE_LOCATION_SUCCESS,
  data,
})

export const deleteLocationFailure = code => ({
  type: DELETE_LOCATION_FAILURE,
  code,
})

export const updateLocationInLocations = data => ({
  type: UPDATE_LOCATION_IN_LOCATIONS,
  data,
})
