export default {
  api:
    process.env.NODE_ENV === 'production' ? '/api' : 'https://dev.atoir.ru/api',
  baseApiURL:
    process.env.NODE_ENV === 'production' ? '/' : 'https://dev.atoir.ru/api',
  kladr: {
    atoirAPI: '/apikladr',
    publicAPI: 'https://kladr-api.ru/api.php',
  },
}
