import React from 'react'
import PropTypes from 'prop-types'

import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core'

const styles = {
  root: {},
}

function CommentCell({ classes, children }) {
  return (
    <TableCell className={classes.root} colSpan={2}>
      {children}
    </TableCell>
  )
}

CommentCell.propTypes = {
  children: PropTypes.string.isRequired,
}

export default withStyles(styles)(CommentCell)
