import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { addMaskCharacters } from '../../../helpers/phoneStringHelper'
import { organizationAddressStringifyFull } from '../../../helpers/parserHelper'

import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import OrganizationTypeIcon from '../Stateless/OrganizationTypeIcon'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'

const styles = theme => ({
  root: theme.mixins.gutters({
    overflowX: 'scroll',
  }),
  table: {
    minWidth: 700,
  },
})

const displayContact = contact => (
  <Fragment>
    <Typography style={{ fontSize: 13 }}>
      {`${contact.full_name}, ${contact.email || ''}, ${addMaskCharacters(
        contact.phone,
      )}
      ${contact.phone_additional ? `, ${contact.phone_additional}` : ''}`}
    </Typography>
  </Fragment>
)

const OrganizationInformationComponent = props => (
  <Paper className={props.classes.root}>
    <Table className={props.classes.table}>
      <TableBody>
        <TableRow key={props.organization.full_name}>
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:organizationFullName')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.full_name}
          </TableCell>
        </TableRow>
        <TableRow key={props.organization.short_name}>
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:companyShortName')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.short_name}
          </TableCell>
        </TableRow>
        <TableRow key="organization_type">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:organizationActivityType')}
          </TableCell>
          <TableCell>
            <OrganizationTypeIcon
              fluid
              clientOrganization={props.organization.client_organization}
              tradeOrganization={props.organization.trade_organization}
              serviceOrganization={props.organization.service_organization}
              type="m"
            />
            <OrganizationTypeIcon
              fluid
              clientOrganization={props.organization.client_organization}
              tradeOrganization={props.organization.trade_organization}
              serviceOrganization={props.organization.service_organization}
              type="c"
            />
            {/* AT-396 */}
            {/* <OrganizationTypeIcon */}
            {/* fluid */}
            {/* clientOrganization={props.organization.client_organization} */}
            {/* tradeOrganization={props.organization.trade_organization} */}
            {/* serviceOrganization={props.organization.service_organization} */}
            {/* type="t" */}
            {/* /> */}
          </TableCell>
        </TableRow>
        <TableRow key="legal_address">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:legalAddress')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.legal_address &&
            Object.keys(props.organization.legal_address).length > 1
              ? organizationAddressStringifyFull(
                  props.organization.legal_address,
                )
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow key="physical_addres">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:physicalAddress')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.physical_address
              ? organizationAddressStringifyFull(
                  props.organization.physical_address,
                )
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow key="contacts">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:contacts')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {!isEmpty(props.contacts) ? (
              <List>
                {_.sortBy(props.contacts, 'full_name').map(c => (
                  <ListItem>
                    <ListItemText>{displayContact(c)}</ListItemText>
                  </ListItem>
                ))}
              </List>
            ) : (
              '-'
            )}
          </TableCell>
        </TableRow>
        <TableRow
          key={props.organization.INN || props.organization.KPP || 'INN/KPP'}
        >
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:INN')} /
            {i18next.t('organizations_web_atoir:KPP')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.INN || '-'} / {props.organization.KPP || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="OGRS-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:OGRN')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.OGRN || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="OKPO-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:OKPO')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.OKPO || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="bank-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:bank')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.bank || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="r-account-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:checkingAccount')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.r_account || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="k-account-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:correspondentAccount')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.k_account || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="bik-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:BIK')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.BIK || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="director-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:director')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.director || '-'}
          </TableCell>
        </TableRow>
        <TableRow key="establishment-key">
          <TableCell align="right">
            {i18next.t('organizations_web_atoir:establishment')}
          </TableCell>
          <TableCell className="wordWrapBreakAll">
            {props.organization.establishment || '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
)

OrganizationInformationComponent.propTypes = {
  organization: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OrganizationInformationComponent)
