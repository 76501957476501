import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import isEmpty from 'is-empty'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import i18next from '../../../i18n/i18n'
import DictionaryForm from './DictionaryForm'
import {
  getBAVariants,
  clearBAVariants,
} from '../../../actions/equipmentActions'
import SearchResult from '../common/SearchResult'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import TextButton from '../../../widgets/Buttons/TextButton'
import styled from 'styled-components'
import { flexbox, spacing, typography } from '@material-ui/system'
import DelayedLoader from '../../../widgets/DelayedLoader'
import Typography from '@material-ui/core/Typography'
import { compactObject } from '../../../helpers/dataHelper'

const Box = styled.div`${spacing}${flexbox}${typography}`

const style = {
  root: {
    minWidth: 380,
  },
}

function EquipmentDictionary({
  open,
  handleClose,
  callGetBAVariants,
  callClearBAVariants,
  classes,
  baVariants,
  isFetchingBaVariants,
  values,
}) {
  const [param, setParam] = React.useState({})
  const [selectedId, setSelectedId] = React.useState(null)
  const [triedSearching, setTriedSearching] = React.useState(false)

  // initial values
  React.useEffect(() => {
    if (open && isEmpty(param)) {
      const { model, industry_id: industryId, vendor } = values
      setParam(
        _.omitBy(
          { model, industry: industryId, vendorCandidate: vendor },
          _.isUndefined,
        ),
      )
    }
  }, [open])

  // model input highlighting
  const [modelTouched, setModelTouched] = React.useState(false)
  function onFocusModel() {
    setModelTouched(true)
  }

  const setSelectedIdHandler = React.useCallback(
    id => {
      setSelectedId(id)
    },
    [setSelectedId],
  )

  React.useEffect(() => {
    return () => {
      callClearBAVariants()
    }
  }, [])

  const paramRef = React.useRef(param)
  React.useEffect(() => {
    paramRef.current = param
  }, [param])

  const loadVariants = React.useCallback(
    _.debounce(() => {
      setTriedSearching(true)
      const { industry, vendor, model: query } = paramRef.current
      callGetBAVariants(
        compactObject({
          ...(() => (industry ? { industry_id: industry } : {}))(),
          vendor_id: vendor,
          query,
        }),
      )
    }, 300),
    [],
  )

  const onChangeHandler = React.useCallback(
    function(id, value) {
      if (id === 'vendor') {
        setParam({
          industry: param.industry,
          vendor: value,
          // заблокируем очистку модели в случае когда поле vendor инициализируется входящим значением
          ...(() =>
            !param.vendor && param.model ? { model: param.model } : {})(),
        })
        setModelTouched(false)
        callClearBAVariants()
      } else {
        setParam({ ...param, [id]: value })
      }
      if (
        (id === 'model' && value && value.length > 2) ||
        (id === 'industry' && param.model && param.model.length > 2) ||
        (id === 'vendor' &&
          !param.vendor &&
          param.model &&
          param.model.length > 2)
      ) {
        loadVariants()
      }
      if (id === 'model' && value && value.length <= 2) {
        callClearBAVariants()
      }
    },
    [setParam, param],
  )

  const _handleClose = React.useCallback(
    function() {
      callClearBAVariants()
      handleClose()
      // clear form data
      setParam({})
      setModelTouched(false)
      setSelectedId(null)
    },
    [handleClose, callClearBAVariants],
  )

  const onSubmit = React.useCallback(
    function() {
      handleClose(baVariants.find(equipment => equipment.id === selectedId))
    },
    [baVariants, selectedId],
  )

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={_handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">
        {i18next.t('equipment_web_atoir:baEquipmentDictionaryTitle')}
      </DialogTitle>
      <DialogContent>
        <DictionaryForm
          {...param}
          onChange={onChangeHandler}
          {...{ modelTouched, onFocusModel }}
        />
        <Box textAlign="center" marginTop={4} marginBottom={1}>
          <DelayedLoader delay={300} show={isFetchingBaVariants} />
          {!isFetchingBaVariants &&
          triedSearching &&
          baVariants.length === 0 ? (
            <Typography variant="caption">
              {i18next.t('shared_web_atoir:notFound')}
            </Typography>
          ) : null}
        </Box>
        <SearchResult
          result={baVariants}
          {...{ selectedId, setSelectedIdHandler }}
        />
      </DialogContent>
      <DialogActions>
        <Box pr={1}>
          <TextButton onClick={_handleClose} size="medium">
            {i18next.t('shared_web_atoir:cancel')}
          </TextButton>
          <ContainedButton
            size="medium"
            onClick={onSubmit}
            disabled={!selectedId}
          >
            {i18next.t('equipment_web_atoir:doBind')}
          </ContainedButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  baVariants: state.equipment.baVariants.value,
  isFetchingBaVariants: state.equipment.baVariants.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callGetBAVariants: getBAVariants.REQUEST,
      callClearBAVariants: clearBAVariants,
    },
    dispatch,
  )

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentDictionary),
)
