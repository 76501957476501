import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  changeClientRating,
  changePerformerRating,
  fetchInventoryProjectProfileRights,
  fetchInventoryProjectProfile,
} from '../../../../../../actions/inventoryActions'
import ShowRatingDialog from './ShowRatingDialog'

const mapStateToProps = state => ({
  isChangingPerformerRating: state.inventory.changePerformerRating.isChanging,
  changePerformerRatingSuccessfully:
    state.inventory.changePerformerRating.changeSuccessfully,
  changePerformerRatingErrors: state.inventory.changePerformerRating.errors,
  isChangingClientRating: state.inventory.changeClientRating.isChanging,
  changeClientRatingSuccessfully:
    state.inventory.changeClientRating.changeSuccessfully,
  changeClientRatingErrors: state.inventory.changeClientRating.errors,
  isClosedForTwoWeeks: state.inventory.project.is_closed_for_two_weeks,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeClientRating,
      changePerformerRating,
      fetchInventoryProjectProfileRights,
      fetchInventoryProjectProfile,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowRatingDialog),
)
