import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

import { AbilityContextManager, Can } from './Can'

import i18next from '../../../../i18n/i18n'
import { dateFormatShortYear } from '../../../../helpers/dateHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// components
import Loader from '../../../Loader'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import InnerCallMainInfo from './InnerCallMainInfo'
import InnerCallAttachments from './InnerCallAttachments'
import InnerCallsModifier from './InnerCallsModifier'

const styles = theme => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  rootWithPadding: {
    padding: 12,
  },
  dividerWithMargin: {
    marginTop: 8,
    marginBottom: 8,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  editEquipmentDialogRoot: {
    width: '70%',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 8px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  textFieldMulty: {
    width: '500px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  grayText: {
    color: theme.palette.grey[500],
  },
})

class InnerCallProfile extends Component {
  state = {}

  componentDidMount() {
    this.updateDataRequest()
  }

  updateDataRequest = () => {
    const {
      props: {
        match: {
          params: { innerCallId },
        },
        fetchInnerCallRequest,
      },
    } = this
    fetchInnerCallRequest(innerCallId)
  }

  componentDidUpdate(prevProps) {
    const {
      props: { match, isFetchingInnerCall, innerCall },
    } = this
    if (
      prevProps.isFetchingInnerCall !== isFetchingInnerCall &&
      !isFetchingInnerCall &&
      !isEmpty(innerCall)
    ) {
      this.props.getTitleInfo({
        title: i18next.t('inner_calls_web_atoir:innerCallNumberFromDate', {
          innerCallNum: innerCall.id,
          dateFrom: dateFormatShortYear(innerCall.createdAt),
        }),
        historyChanges: [
          {
            link: `${match.url}/history`,
            title: i18next.t('inner_calls_web_atoir:innerCallHistoryChangelog'),
          },
        ],
      })
    }
  }

  render() {
    const {
      props: {
        match: {
          params: { innerCallId },
          url,
        },
        isFetchingInnerCall,
        innerCall,
        permissions,
      },
      updateDataRequest,
    } = this
    return (
      <Fragment>
        <BreadCrumb length={29} to={url}>
          {`№ ${innerCallId}`}
        </BreadCrumb>
        {isFetchingInnerCall ? (
          <Loader />
        ) : (
          !isEmpty(innerCall) && (
            <AbilityContextManager permissions={permissions}>
              <InnerCallsModifier
                callId={innerCall.id}
                update={updateDataRequest}
              >
                {({ closeCall, editCall, assigneeCall }) => (
                  <InnerCallMainInfo
                    innerCall={innerCall}
                    closeCall={closeCall}
                    editCall={editCall}
                    assigneeCall={assigneeCall}
                  />
                )}
              </InnerCallsModifier>
              <Can I="edit" a="CALL_ATTACHMENTS" passThrough>
                {can => (
                  <InnerCallAttachments
                    callId={innerCall.id}
                    innerCall={innerCall}
                    callCreationDate={innerCall.createdAt}
                    canEdit={can}
                  />
                )}
              </Can>
            </AbilityContextManager>
          )
        )}
      </Fragment>
    )
  }
}

InnerCallProfile.propTypes = {
  fetchInnerCallRequest: PropTypes.func.isRequired,
  isFetchingInnerCall: PropTypes.bool.isRequired,
  innerCall: PropTypes.object.isRequired,
}

export default withStyles(styles)(InnerCallProfile)
