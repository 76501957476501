// app main color theme
import { createMuiTheme } from '@material-ui/core/styles'
import cyan from '@material-ui/core/colors/cyan'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[700],
      contrastText: grey[800],
    },
    secondary: {
      main: grey[700],
    },
    loginNav: {
      main: grey[600],
    },
    main: {
      light: cyan[300],
      main: cyan[500],
      dark: cyan[600],
      contrastText: '#fff',
    },
    second: {
      light: 'transparent',
      main: 'transparent',
      dark: 'transparent',
      contrastText: cyan[500],
    },
    error: {
      transparent: '#E5393508',
      lightest: red[50],
      light: red[400],
      main: red[500],
      dark: red[600],
      contrastText: '#fff',
    },
    textSecondary: {
      main: blue[500],
    },
    apply: {
      lightest: grey[300],
      extraLight: grey[500],
      light: grey[600],
      main: grey[700],
      dark: grey[800],
      contrastText: '#fff',
    },
    blueSecondary: {
      transparent: '#1976D205',
      lightest: blue[50],
      main: blue[700],
      dark: blue[900],
    },
    text: {
      light: '#00000054',
      medium: '#00000087',
      main: '#000000',
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        fill: grey[600],
      },
      active: {
        fill: blue[700],
      },
      text: {
        fill: grey[100],
      },
    },
  },
})

export default theme
