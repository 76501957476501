import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Wrapper from './Wrapper'
import { setRequestDataOnCreation } from '../../../../actions/serviceDesk'

const mapStateToProps = state => ({
  creationData: state.serviceDesk.creation.data,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRequestDataOnCreation,
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wrapper))
