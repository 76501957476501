import React from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import Loader from './Loader'

const styles = {
  root: { overflow: 'hidden' },
}

const DialogLoader = ({ showDialogLoader, isLoggingOut, classes }) => (
  <Dialog
    open={isLoggingOut || showDialogLoader}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent className={classes.root}>
      <Loader />
    </DialogContent>
  </Dialog>
)

DialogLoader.propTypes = {
  showDialogLoader: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoggingOut: state.login.isLoggingOut,
  showDialogLoader: state.utils.showDialogLoader,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DialogLoader)
