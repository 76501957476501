import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchOrganizationEquipment,
  fetchOrganizationVendors,
  fetchOrganizationEquipmentCount,
} from '../../actions/equipmentActions'
import { fetchLocations } from '../../actions/locationsActions'
import { fetchDepartments } from '../../actions/departmentsActions'

import OrganizationEquipmentComponent from '../../components/Organizations/Profile/OrganizationEquipmentComponent'

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  rowsPerPage: state.equipment.list.rowsPerPage,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationEquipment,
      fetchOrganizationVendors,
      fetchLocations,
      fetchDepartments,
      fetchOrganizationEquipmentCount,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationEquipmentComponent),
)
