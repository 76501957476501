export function removeMaskCharacters(phoneMask) {
  return phoneMask
    .trim()
    .replace('+7 (', '7')
    .replace(') ', '')
    .replace('-', '')
}

export function addMaskCharacters(p) {
  const phone = p.substring(1, 11)
  const str1 = phone.substring(0, 3)
  const str2 = phone.substring(3, 6)
  const str3 = phone.substring(6, 8)
  const str4 = phone.substr(8, 10)

  return `+7 (${str1}) ${str2}-${str3}-${str4}`
}
