import FormControl from '@material-ui/core/FormControl'
import { URL } from '../../../middlewares/api'
import React from 'react'
import styled from 'styled-components'
import i18next from '../../../i18n/i18n'
import AutocompleteLimitedAuto from '../../../widgets/AutocompleteLimitedAuto'

const ContainerWithTopMargin = styled.div`
  margin-top: 16px;
`

function VendorSelector({ onChange, idValue, value, vendorCandidate }) {
  const onChangeHandler = vendor => {
    const id = _.get(vendor, 'id') || ''
    onChange(idValue, id)
  }
  return (
    <FormControl fullWidth>
      <ContainerWithTopMargin>
        <AutocompleteLimitedAuto
          placeholder={i18next.t('equipment_web_atoir:vendor')}
          sourceUrl={`${URL}/bo/equipments/list/vendors`}
          query={() => ({ vendor: value })}
          vendorCandidate={vendorCandidate}
          getItemDisplayName={i => i.name}
          getItemValue={i => i.id}
          onChange={onChangeHandler}
          limit={50}
          requestResultTransformerToArray={res => res.vendors}
          transformInputToQuery={query => ({ query })}
          menuListProps={{ style: { maxHeight: 360 } }}
        />
      </ContainerWithTopMargin>
    </FormControl>
  )
}

export default VendorSelector
