import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  FETCH_USER_REQUEST,
  FETCH_USER_BY_ID_REQUEST,
  UPLOAD_USER_IMAGE_REQUEST,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  REMOVE_USER_AVATAR_REQUEST,
  FETCH_USER_NOTIFICATION_SETTINGS,
  SET_USER_NOTIFICATION_SETTING,
} from '../constants/actionTypes'
import {
  fetchUserRequest,
  fetchUserByIdRequest,
  updateUserRequest,
  uploadImage,
  changePasswordRequest,
  removeUserAvatarRequest,
  fetchUserNotificationSettingsRequest,
  setUserNotificationSettingsRequest,
} from './api'
import {
  fetchUser as fetchUserAction,
  fetchUserSuccess,
  fetchUserFailure,
  uploadUserImageSuccess,
  uploadUserImageFailure,
  updateUserAvatarSuccess,
  updateUserAvatarFailure,
  updateUserSuccess,
  updateUserFailure,
  changePasswordSuccess,
  changePasswordFailure,
  removeUserAvatarSuccess,
  removeUserAvatarFailure,
  fetchNotificationSettingsFailure,
  fetchNotificationSettingsSuccess,
  setNotificationSettingFailure,
  setNotificationSettingSuccess,
} from '../actions/userActions'
import {
  catchError,
  receivedErrorMessage,
  showDialogWithOk,
} from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
import { subscribeOnNotificationsCheck } from '../pushNotifications'
import i18next from '../i18n/i18n'

export function* watchFetchUserSaga() {
  yield takeLatest(FETCH_USER_REQUEST, fetchUser)
}

export function* watchFetchUserByIdSaga() {
  yield takeLatest(FETCH_USER_BY_ID_REQUEST, fetchUserById)
}

export function* watchUploadUserImageSaga() {
  yield takeLatest(UPLOAD_USER_IMAGE_REQUEST, uploadUserImage)
}

export function* watchUpdateUserAvatarSaga() {
  yield takeLatest(UPDATE_USER_AVATAR_REQUEST, updateUserAvatar)
}

export function* watchUpdateUserSaga() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUserData)
}

export function* watchChangePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword)
}

export function* watchRemoveUserAvatarSaga() {
  yield takeLatest(REMOVE_USER_AVATAR_REQUEST, removeUserAvatar)
}

export function* watchFetchUsesrNotificationSettings() {
  yield takeLatest(
    FETCH_USER_NOTIFICATION_SETTINGS,
    fetchUserNotificationSettings,
  )
}

export function* watchSetUserNotificationSettings() {
  yield takeLatest(SET_USER_NOTIFICATION_SETTING, setUserNotificationSettings)
}

function* fetchUser() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchUserRequest(accessToken))
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserSuccess(result.data))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
    subscribeOnNotificationsCheck(result.data.email)
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserById(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserByIdRequest(accessToken, action.userId, action.organizationId),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserSuccess(result.data))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* uploadImageWrapperSaga(imageData) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(uploadImage(accessToken, imageData))
    if (!isEmpty(result.success)) {
      if (result.success) {
        return { success: true, data: result.data }
      }
      if (result.status === 413) {
        const mess = `${i18next.t('shared_web_atoir:fileToBig')}`
        yield put(showDialogWithOk(mess))
        return {
          success: false,
          error: mess,
        }
      }
      const errors = handleErrors(result)
      return { success: false, error: errors }
    }
    return { success: false, error: error.message }
  } catch (error) {
    return { success: false, error: error.message }
  }
}

function* uploadUserImage(action) {
  try {
    const result = yield call(uploadImageWrapperSaga, action.imageData)
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(uploadUserImageSuccess(result.data.file))
      } else {
        yield put(uploadUserImageFailure(result.error))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateUserAvatar(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(updateUserRequest(accessToken, action.avatarData))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateUserAvatarSuccess(action.avatarData))
      } else {
        const errors = handleErrors(result)
        yield put(updateUserAvatarFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateUserData(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(updateUserRequest(accessToken, action.data))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateUserSuccess())
      } else {
        const errors = handleErrors(result)
        if (
          !isEmpty(errors.lastName) ||
          !isEmpty(errors.firstName) ||
          !isEmpty(errors.phone)
        ) {
          yield put(updateUserFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* removeUserAvatar() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(removeUserAvatarRequest(accessToken))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(removeUserAvatarSuccess())
        yield put(fetchUserAction())
      } else {
        const errors = handleErrors(result)
        yield removeUserAvatarFailure(result.code)
        yield receivedErrorMessage(errors)
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* changePassword(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(changePasswordRequest(accessToken, action.data))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(changePasswordSuccess())
      } else {
        const errors = handleErrors(result)
        if (
          !isEmpty(errors.password) ||
          !isEmpty(errors.newPassword) ||
          !isEmpty(errors.passwordConfirm)
        ) {
          yield put(changePasswordFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserNotificationSettings(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserNotificationSettingsRequest(accessToken, action.data),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchNotificationSettingsSuccess(result.notifications))
      } else {
        const errors = handleErrors(result)
        yield put(fetchNotificationSettingsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setUserNotificationSettings(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      setUserNotificationSettingsRequest(accessToken, action.payload),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(setNotificationSettingSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(setNotificationSettingFailure(action.payload))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
