import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'

function UUIDMask(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      placeholderChar={'\u2000'}
      {...other}
      ref={inputRef}
      mask={[
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        '-',
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        '-',
        /[4]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        '-',
        /[89ab]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        '-',
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
        /[0-9abcdef]/,
      ]}
    />
  )
}

const UUIDInput = props => {
  const { onChange, value, label, error } = props
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor={label}
        shrink={value && value.replace(/[^0-9abcdef]/gi, '').length > 0}
      >
        {label}
      </InputLabel>
      <Input
        id={label}
        value={value}
        onChange={onChange}
        inputComponent={UUIDMask}
      />
      <FormHelperText error={error}>{error}</FormHelperText>
    </FormControl>
  )
}

UUIDInput.defaultProps = {
  error: null,
}

UUIDInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  error: PropTypes.string,
}

export default UUIDInput
