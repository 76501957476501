import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import ServiceDeskOrderChangeLog from '../../ServiceDesk/ServiceDeskOrderChangeLog'
import WorkCompletionReport from '../../ServiceDesk/WorkCompletionReport'
import OrderComments from '../../ServiceDesk/CallProfile/widgets/OrdersList/OrderComments'

const ServiceDeskRouter = ({ match: { path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${path}/:id`}
        title={i18next.t('equipment_web_atoir:equipmentCrumbTitle')}
        component={({ match: { path: _path } }) => (
          <Fragment>
            <Switch>
              <WrapperRoute
                path={`${_path}/work_completion_report`}
                showHeader
                showTitleOutOfPaper
                component={WorkCompletionReport}
                title={i18next.t(
                  'service_desk_web_atoir:workCompletionReportForming',
                )}
              />
              <WrapperRoute
                path={`${_path}/comments`}
                showHeader
                component={OrderComments}
                title={i18next.t('service_desk_web_atoir:comments')}
              />
              <WrapperRoute
                path={`${_path}/history`}
                title={i18next.t(
                  'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepair',
                )}
                component={ServiceDeskOrderChangeLog}
              />
            </Switch>
          </Fragment>
        )}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default ServiceDeskRouter
