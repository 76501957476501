import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchInventoryProjectProfile } from '../../../../../actions/inventoryActions'
import InventoryProjectEquipment from './InventoryProjectEquipment'

const mapStateToProps = state => ({
  isFetchingProject: state.inventory.isFetchingProject,
  inventoryProject: state.inventory.project,
  equipmentCount: state.inventoryEquipment.count,
  locationsCount: state.inventoryEquipment.locationsCount,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryProjectProfile,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryProjectEquipment),
)
