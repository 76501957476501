import {
  GET_INVENTORY_EQUIPMENT_REQUEST,
  GET_INVENTORY_EQUIPMENT_SUCCESS,
  GET_INVENTORY_EQUIPMENT_FAILURE,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_SUCCESS,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_FAILURE,
  GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST,
  GET_INVENTORY_EQUIPMENT_FILTERS_SUCCESS,
  GET_INVENTORY_EQUIPMENT_FILTERS_FAILURE,
  GET_INVENTORY_EQUIPMENT_COUNT_REQUEST,
  GET_INVENTORY_EQUIPMENT_COUNT_SUCCESS,
  GET_INVENTORY_EQUIPMENT_COUNT_FAILURE,
} from '../constants/actionTypes'

export const fetchInventoryEquipment = (projectId, params, filters) => ({
  type: GET_INVENTORY_EQUIPMENT_REQUEST,
  projectId,
  params,
  filters,
})

export const fetchInventoryEquipmentSuccess = (
  projectId,
  filters,
  equipment,
) => ({
  type: GET_INVENTORY_EQUIPMENT_SUCCESS,
  projectId,
  filters,
  equipment,
})

export const fetchInventoryEquipmentFailure = errors => ({
  type: GET_INVENTORY_EQUIPMENT_FAILURE,
  errors,
})

export const fetchInventoryEquipmentLocations = (
  projectId,
  params,
  filters,
) => ({
  type: GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST,
  projectId,
  params,
  filters,
})

export const fetchInventoryEquipmentLocationsSuccess = (locations, count) => ({
  type: GET_INVENTORY_EQUIPMENT_LOCATIONS_SUCCESS,
  locations,
  count,
})

export const fetchInventoryEquipmentLocationsFailure = errors => ({
  type: GET_INVENTORY_EQUIPMENT_LOCATIONS_FAILURE,
  errors,
})

export const fetchInventoryEquipmentFilters = projectId => ({
  type: GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST,
  projectId,
})

export const fetchInventoryEquipmentFiltersSuccess = filters => ({
  type: GET_INVENTORY_EQUIPMENT_FILTERS_SUCCESS,
  filters,
})

export const fetchInventoryEquipmentFiltersFailure = errors => ({
  type: GET_INVENTORY_EQUIPMENT_FILTERS_FAILURE,
  errors,
})

export const fetchInventoryEquipmentCount = (projectId, params, filters) => ({
  type: GET_INVENTORY_EQUIPMENT_COUNT_REQUEST,
  projectId,
  params,
  filters,
})

export const fetchInventoryEquipmentCountSuccess = count => ({
  type: GET_INVENTORY_EQUIPMENT_COUNT_SUCCESS,
  count,
})

export const fetchInventoryEquipmentCountFailure = errors => ({
  type: GET_INVENTORY_EQUIPMENT_COUNT_FAILURE,
  errors,
})
