import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = () => ({
  progress: {},
})

const Loader = ({ size, classes, ...rest }) => (
  <CircularProgress
    className={classes.progress}
    variant="indeterminate"
    size={size}
    {...rest}
  />
)

Loader.defaultProps = {
  size: 40,
}

Loader.propTypes = {
  size: PropTypes.number,
}

export default withStyles(styles)(Loader)
