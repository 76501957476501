import React, { Fragment, useMemo } from 'react'
import _ from 'lodash'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import i18n from '../../../../../i18n/i18n'

const NotificationSetting = props => {
  const { settingKey, settings, onChange, classes } = props

  const checkboxes = useMemo(
    () =>
      ['email', 'browser', 'mobile'].map(type => (
        <Checkbox
          key={`${settingKey}_${type}`}
          checked={
            _.find(settings, s => s.name === `${settingKey}_${type}`).enabled
          }
          onChange={e => onChange(type, e.target.checked)}
          value="checkedA"
          color="primary"
        />
      )),
    [settings],
  )
  return (
    <Fragment>
      <Divider />
      <Grid container className={classes.userNotificationRowContainer}>
        <Grid item sm={8}>
          <Typography
            color="textSecondary"
            className={classes.userNotificationKey}
          >
            {i18n.t(`user_profile_web_atoir:${settingKey}`)}
          </Typography>
        </Grid>
        <Grid item sm={4} className={classes.userNotificationCheckboxes}>
          {checkboxes}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default NotificationSetting
