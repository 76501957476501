export default {
  bau: 'transport',
  bap: 'yjdsqgfhjkm',
  default_inner_call_history_limit: 30,
  default_service_call_history_limit: 30,
  default_organization_settings_history_limit: 30,
  defaultItemsPerPage: 10,
  max_logo_photo_size: 5242880, // 5 mb
  max_call_photo_count: 5, // 5 mb
  max_call_file_count: 10, // 5 mb
  max_call_photo_size: 5242880 * 5, // 5 mb
  max_call_file_size: 5242880 * 5, // 5 mb
  right_drawer_width: 320,
  left_drawer_width: 240,
  subscriptionLifetime: 1000 * 3600 * 24 * 7, // Неделя
  right_navigation_drawer_width: 320,
  dadata_token_developer: 'Token 472066e20a87bdfa3c0de6357bdb1dd4b42a18f4',
  dadata_token_atoir: 'Token 05fff4d30f188912471d58711893c365964da235',
  orgSettingNameLength: 255,
  templateThemeMaxLength: 255,
  templateTextMaxLength: 2000,
  GOOGLE_MAP_API_KEY:
    process.env.NODE_ENV !== 'development'
      ? 'AIzaSyAwEh62F7iYcsofV1M81uZARJ1wt3mh-XQ'
      : 'AIzaSyBMxcx9pFvt3dlO1iE4BIhUMv6TDQ28es4',
}
