import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchInventoryProjectProfile } from '../../../../../actions/inventoryActions'
import {
  fetchBoundOrganizationUsers,
  fetchOrganizationStaff,
} from '../../../../../actions/staffActions'
import InventoryProjectProfileInfoComponent from './InventoryProjectProfileInfoComponent'

const mapStateToProps = state => ({
  project: state.inventory.project,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryProjectProfile,
      fetchBoundOrganizationUsers,
      fetchOrganizationStaff,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InventoryProjectProfileInfoComponent),
)
