import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ChooseThirdPartyOrganization from './ChooseThirdPartyOrganization'
import { getOrganizationsTrusted } from '../../../actions/innerCallsActions'

const mapStateToProps = state => ({
  isFetchingServiceOrganizations:
    state.innerCalls.organizationsTrusted.isFetching,
  serviceOrganizations: state.innerCalls.organizationsTrusted.value,
  isFetchingServiceOrganizationsCount:
    state.innerCalls.organizationsTrusted.isFetching,
  serviceOrganizationsCount: state.innerCalls.organizationsTrusted.count,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceOrganizationsCount: () => {},
      fetchServiceOrganizations: getOrganizationsTrusted.REQUEST,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChooseThirdPartyOrganization),
)
