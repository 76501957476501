import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { editContractRequest } from '../../../../../actions/serviceDeskContractsActions'
import EditResponsible from './EditResponsible'

const mapStateToProps = state => ({
  isFetching: state.serviceDeskContracts.edit.isFetching,
  error: state.serviceDeskContracts.edit.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editContractRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditResponsible),
)
