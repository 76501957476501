import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SettingsList from './SettingsList'
import {
  setNavigationDrawer,
  clearNavigationDrawer,
} from '../../../actions/appActions'
import { fetchOrganization } from '../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNavigationDrawer,
      clearNavigationDrawer,
      fetchOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsList),
)
