import React from 'react'
import PropTypes from 'prop-types'

import BannerWidget from './BannerWidget'

function getHideOption(id) {
  const sdBannersConfig =
    JSON.parse(localStorage.getItem('sdBannersConfig')) || {}
  const res = (sdBannersConfig[id] || {}).hide
  return { value: !!res }
}

function checkSDFunctional(organization) {
  return Boolean(
    organization.OrganizationFunctionalAvailables.find(
      functional => functional.name === 'sd_extended',
    ),
  )
}

function fixHideOption(organizationsFunctionals) {
  const sdBannersConfig = JSON.parse(localStorage.getItem('sdBannersConfig'))
  const sdBannersConfigNew = {}
  _.forIn(sdBannersConfig, (value, key) => {
    const organization = organizationsFunctionals.find(
      organizations => organizations.id === key,
    )
    // убрать hide для тех у кого есть лицензия
    // убрать организации которых больше нет в лицензиях
    if (organization && !checkSDFunctional(organization)) {
      sdBannersConfigNew[key] = { hide: true }
    }
  })
  localStorage.setItem('sdBannersConfig', JSON.stringify(sdBannersConfigNew))
}

function getBannersToShow(organizationsFunctionals) {
  const result = []
  _.each(organizationsFunctionals, organization => {
    const haveFunctional = checkSDFunctional(organization)
    if (!haveFunctional && !getHideOption(organization.id).value) {
      result.push({
        id: organization.id,
        name: organization.short_name,
        role: organization.OrganizationUsers[0],
      })
    }
  })
  return result
}

function ServiceDeskBanner({
  fetchFunctionals,
  organizationsFunctionals,
  createInvoice,
  isFetching,
  isInvoiceCreating,
  invoiceCreatingError,
  invoiceCreatingOrgId,
}) {
  const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false)
  const [invoiceCreatingStarted, setInvoiceCreatingStarted] = React.useState(
    false,
  )
  const [bannersToShow, setBannersToShow] = React.useState([])
  const [loadStarted, setLoadStarted] = React.useState(false)

  React.useEffect(() => {
    fetchFunctionals()
  }, [])

  // invoice creating end catcher
  React.useEffect(() => {
    if (invoiceCreatingStarted && !isInvoiceCreating && !invoiceCreatingError) {
      onHideHandler(invoiceCreatingOrgId)
      setSuccessDialogOpen(true)
    }
    setInvoiceCreatingStarted(isInvoiceCreating)
  }, [isInvoiceCreating])

  // load end catcher
  React.useEffect(() => {
    if (loadStarted && !isFetching) {
      fixHideOption(organizationsFunctionals)
      setBannersToShow(getBannersToShow(organizationsFunctionals))
    }
    setLoadStarted(isFetching)
  }, [isFetching])

  function onHideHandler(id) {
    const sdBannersConfig = JSON.parse(localStorage.getItem('sdBannersConfig'))
    localStorage.setItem(
      'sdBannersConfig',
      JSON.stringify({ ...sdBannersConfig, [id]: { hide: true } }),
    )
    setBannersToShow(getBannersToShow(organizationsFunctionals))
  }

  function onPaymentRequestHandler(id) {
    createInvoice(id)
  }

  return (
    <React.Fragment>
      {(bannersToShow.length > 0 || successDialogOpen) && (
        <BannerWidget
          onPaimentRequest={onPaymentRequestHandler}
          onHide={onHideHandler}
          paymentDialogOpen={paymentDialogOpen}
          setPaymentDialogOpen={setPaymentDialogOpen}
          successDialogOpen={successDialogOpen}
          setSuccessDialogOpen={setSuccessDialogOpen}
          {..._.last(bannersToShow)}
        />
      )}
    </React.Fragment>
  )
}

ServiceDeskBanner.propTypes = {
  organizationsFunctionals: PropTypes.array.isRequired,
  createInvoice: PropTypes.func.isRequired,
  isInvoiceCreating: PropTypes.bool.isRequired,
}

export default ServiceDeskBanner
