import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InventoryProjectChangeLog from './InventoryProjectChangeLog'
import {
  fetchHistoryCountInventory,
  fetchHistoryInventory,
  fetchInventoryProjectProfile,
} from '../../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  isFetchingHistory: state.inventory.history.isFetching,
  isFetchingProject: state.inventory.isFetchingProject,
  history: state.inventory.history.history,
  status: state.inventory.project ? state.inventory.project.status : '',
  date: state.inventory.project ? state.inventory.project.date_created : '',
  isFetchingHistoryCount: state.inventory.historyCount.isFetching,
  historyCount: state.inventory.historyCount.count,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchHistory: fetchHistoryInventory,
      fetchHistoryCount: fetchHistoryCountInventory,
      fetchInventoryProjectProfile,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryProjectChangeLog),
)
