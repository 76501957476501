import React from 'react'

import { Box } from '@material-ui/core'

const ToolsPanel = ({ children }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {children}
    </Box>
  )
}

export default ToolsPanel
