import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreateEditButtons from './CreateEditButtons'
import {
  clearNavigationDrawer,
  setNavigationDrawer,
} from '../../../../../actions/appActions'
import { fetchOrganization } from '../../../../../actions/organizationsActions'
import {
  createButtonRequest,
  getButtonRequest,
  editButtonRequest,
} from '../../../../../actions/buttonsActions'

const mapStateToProps = state => ({
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
  createButtonError: state.buttons.createButton.error,
  isCreatingButton: state.buttons.createButton.isFetching,
  isFetching: state.buttons.getButton.isFetching,
  isEditing: state.buttons.editButton.isFetching,
  editButtonError: state.buttons.editButton.error,
  button: state.buttons.getButton.button,
  getButtonError: state.buttons.getButton.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNavigationDrawer,
      clearNavigationDrawer,
      fetchOrganization,
      createButtonRequest,
      getButtonRequest,
      editButtonRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateEditButtons),
)
