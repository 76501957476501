import React from 'react'

import ExpansionPanelContainer from './ExpansionPanelContainer'
import ContragentsTable from './ContragentsTable'
import ToolsPanel from './ToolsPanel'
import AssigningRestrictionSwitch from './AssigningRestrictionSwitch'
import CreationDialog from './CreationDialog'
import AddContragentButton from './AddContragentButton'
import ContragentToolBox from './ContragentToolBox'

function useDidUpdateEffect(fn, inputs) {
  const didMountRef = React.useRef(false)

  React.useEffect(() => {
    if (didMountRef.current) fn()
    else didMountRef.current = true
  }, inputs)
}

const Contragents = ({
  organizationId,
  callCreateOrganizationContragent,
  callGetOrganizationSettings,
  organizationContragentsUpdate,
  creatingIsFetching,
  organizationContragents,
  setOrganizationContragentRequestPayload,
  callDeleteOrganizationContragent,
  deleteOrganizationContragentIsFetching,
  callEditOrganizationContragent,
  editOrganizationContragentIsFetching,
  organizationSettings,
  callEditRestrictionSdCallsFromNonContragents,
  editRestrictionSdCallsFromNonContragentsIsFetching,
  ...paginationProps
}) => {
  const [addingDialogOpen, setAddingDialogOpen] = React.useState(false)

  React.useEffect(() => {
    callGetOrganizationSettings({ id: organizationId })
  }, [])

  React.useEffect(() => {
    if (organizationId) {
      setOrganizationContragentRequestPayload({ id: organizationId })
    }
  }, [organizationId])

  // update on creation done
  useDidUpdateEffect(() => {
    if (!creatingIsFetching) {
      callGetOrganizationSettings({ id: organizationId })
      organizationContragentsUpdate()
      setAddingDialogOpen(false)
    }
  }, [creatingIsFetching])

  // update on deleting or editing done
  useDidUpdateEffect(() => {
    if (!creatingIsFetching) {
      callGetOrganizationSettings({ id: organizationId })
      organizationContragentsUpdate()
    }
  }, [
    deleteOrganizationContragentIsFetching,
    editOrganizationContragentIsFetching,
  ])
  // update on assign restriction editing done
  useDidUpdateEffect(() => {
    callGetOrganizationSettings({ id: organizationId })
  }, [editRestrictionSdCallsFromNonContragentsIsFetching])

  const addContragentsHandler = contragentId => {
    callCreateOrganizationContragent({
      id: organizationId,
      contragent_id: contragentId,
    })
  }
  return (
    <>
      <CreationDialog
        isCreating={creatingIsFetching}
        open={addingDialogOpen}
        organizationId={organizationId}
        onExit={() => {
          setAddingDialogOpen(false)
        }}
        onSubmit={addContragentsHandler}
      />
      <ExpansionPanelContainer>
        <ToolsPanel>
          <AssigningRestrictionSwitch
            organizationId={organizationId}
            organizationSettings={organizationSettings}
            editRestriction={callEditRestrictionSdCallsFromNonContragents}
          />
          <AddContragentButton
            onClick={() => {
              setAddingDialogOpen(true)
            }}
          />
        </ToolsPanel>
        <ContragentsTable
          contragents={organizationContragents}
          {...paginationProps}
        >
          <ContragentToolBox
            organizationId={organizationId}
            deleteContragent={callDeleteOrganizationContragent}
            editContragent={callEditOrganizationContragent}
          />
        </ContragentsTable>
      </ExpansionPanelContainer>
    </>
  )
}

export default Contragents
