export const actionCreator = type => payload => ({
  type,
  payload,
})

const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'

export const actionCreatorTyped = base =>
  [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = payload => ({
      type: base[type],
      payload,
    })
    return acc
  }, {})
