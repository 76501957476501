import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// actions
import { detachServiceCallTagRequest } from '../../../../../../actions/serviceDesk'
// component
import TagCloseableContainer from './TagCloseableContainer'

const mapStateToProps = state => ({
  detaching: state.serviceDesk.serviceCallTagsDetaching.isFetching,
  error: state.serviceDesk.serviceCallTagsDetaching.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      detachTag: detachServiceCallTagRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TagCloseableContainer),
)
