import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { Box } from '@material-ui/core'

const RadioSwitch = ({ value, values, idValue, onChangeById }) => {
  const buildResultObj = (container, _value) => {
    const result = {}
    _.forOwn(container, (_null, key) => {
      result[key] = key === _value
    })
    return result
  }

  const handleChange = event => {
    onChangeById({
      idValue,
      value: buildResultObj(value, event.target.value),
    })
  }

  const calculateCurrValue = clientOrganizationSettings => {
    let result = 'none'
    _.forOwn(clientOrganizationSettings, (_value, key) => {
      if (_value) {
        result = key
      }
    })
    return result
  }
  return (
    <div>
      <FormControl component="fieldset">
        <Box pl={2} pr={2}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={calculateCurrValue(value)}
            onChange={handleChange}
          >
            <FormControlLabel value="none" control={<Radio />} label="нет" />
            {values.length > 0 &&
              values.map(item => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.title}
                />
              ))}
          </RadioGroup>
        </Box>
      </FormControl>
    </div>
  )
}

export default RadioSwitch
