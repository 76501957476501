import * as constants from '../constants/actionTypes/buttons'
import { actionCreator as externalActionCreator } from './actionCreator'

export const createButtonRequest = externalActionCreator(
  constants.CREATE_BUTTON_REQUEST,
)
export const createButtonSuccess = externalActionCreator(
  constants.CREATE_BUTTON_SUCCESS,
)
export const createButtonFailure = externalActionCreator(
  constants.CREATE_BUTTON_FAILURE,
)

export const editButtonRequest = externalActionCreator(
  constants.EDIT_BUTTON_REQUEST,
)
export const editButtonSuccess = externalActionCreator(
  constants.EDIT_BUTTON_SUCCESS,
)
export const editButtonFailure = externalActionCreator(
  constants.EDIT_BUTTON_FAILURE,
)

export const getButtonsRequest = externalActionCreator(
  constants.GET_BUTTONS_REQUEST,
)
export const getButtonsSuccess = externalActionCreator(
  constants.GET_BUTTONS_SUCCESS,
)
export const getButtonsFailure = externalActionCreator(
  constants.GET_BUTTONS_FAILURE,
)

export const deleteButtonRequest = externalActionCreator(
  constants.DELETE_BUTTON_REQUEST,
)
export const deleteButtonSuccess = externalActionCreator(
  constants.DELETE_BUTTON_SUCCESS,
)
export const deleteButtonFailure = externalActionCreator(
  constants.DELETE_BUTTON_FAILURE,
)

export const getButtonRequest = externalActionCreator(
  constants.GET_BUTTON_REQUEST,
)
export const getButtonSuccess = externalActionCreator(
  constants.GET_BUTTON_SUCCESS,
)
export const getButtonFailure = externalActionCreator(
  constants.GET_BUTTON_FAILURE,
)

export const getButtonsHistoryRequest = externalActionCreator(
  constants.GET_BUTTONS_HISTORY_REQUEST,
)
export const getButtonsHistorySuccess = externalActionCreator(
  constants.GET_BUTTONS_HISTORY_SUCCESS,
)
export const getButtonsHistoryFailure = externalActionCreator(
  constants.GET_BUTTONS_HISTORY_FAILURE,
)
export const clearButtonsHistory = externalActionCreator(
  constants.CLEAR_BUTTONS_HISTORY,
)

export const executeSDCallButtonRequest = externalActionCreator(
  constants.EXECUTE_SD_CALL_BUTTON_REQUEST,
)
export const executeSDCallButtonSuccess = externalActionCreator(
  constants.EXECUTE_SD_CALL_BUTTON_SUCCESS,
)
export const executeSDCallButtonFailure = externalActionCreator(
  constants.EXECUTE_SD_CALL_BUTTON_FAILURE,
)

export const getSDCallButtonsRequest = externalActionCreator(
  constants.GET_SD_CALL_BUTTONS_REQUEST,
)
export const getSDCallButtonsSuccess = externalActionCreator(
  constants.GET_SD_CALL_BUTTONS_SUCCESS,
)
export const getSDCallButtonsFailure = externalActionCreator(
  constants.GET_SD_CALL_BUTTONS_FAILURE,
)
