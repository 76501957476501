/* eslint-disable */
/*
 * Компонент возвращает:
 * data | '' | null
 *                    *null вернется если в поле ввода с клавиатуре введена неправильная дата
 * onChanged будет вызван если:
 * - дата выбрана из выпадающего пикера        >  data
 * - в текстовом поле введена полностью дата   >  data
 * - в текстовом поле весь ввод очищен         >  ''
 * - в текстовом поле потерян фокус            >  data | '' | null
 * */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MaskedInput from 'react-text-mask'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment'
import 'moment/locale/ru'

// ---material---
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles/index'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'

// i18n
import i18next from '../../i18n/i18n'

// icons
import DateRange from '@material-ui/icons/DateRange'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'

// settings for years interval
const currentYear = new Date(1960, 0, 1).getFullYear()
const fromMonth = new Date(currentYear, 0)
const toMonth = new Date(currentYear + 90, 11)

const stylesYearMonthForm = () => ({
  divHead: {
    display: 'flex',
  },
  selMonth: {
    minWidth: '105px',
  },
})

const YearMonthForm = withStyles(stylesYearMonthForm)(
  class extends Component {
    constructor(props) {
      super(props)
      const years = []
      for (
        let i = fromMonth.getFullYear();
        i <= toMonth.getFullYear();
        i += 1
      ) {
        years.push(i)
      }
      const months = props.localeUtils.getMonths('ru')
      this.state = {
        years,
        months,
        openMonth: false,
        openYear: false,
      }
    }

    handleChange = (field, value) => {
      this.setState(
        {
          [field]: value,
        },
        () => {
          const { currSelYear, currSelMonth } = this.state
          this.props.onChange(new Date(currSelYear, currSelMonth))
        },
      )
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.date && prevState.date !== nextProps.date) {
        const currSelMonth = nextProps.date.getMonth()
        const currSelYear = nextProps.date.getFullYear()
        return {
          date: nextProps.date,
          currSelMonth,
          currSelYear,
        }
      }
      return null
    }

    openListHandler = (toOpen, openNow) => {
      this.setState({ [toOpen]: openNow })
    }

    render() {
      const {
        state: {
          openMonth,
          openYear,
          months,
          years,
          currSelMonth,
          currSelYear,
        },
        props: { classes },
        openListHandler,
        handleChange,
      } = this
      return (
        <form className="DayPicker-Caption">
          <FormControl>
            <div className={classes.divHead}>
              <Select
                className={classes.selMonth}
                value={currSelMonth}
                onChange={event => {
                  handleChange('currSelMonth', event.target.value)
                }}
                onClose={() => {
                  openListHandler('openMonth', false)
                }}
                onOpen={() => {
                  openListHandler('openMonth', true)
                }}
                open={openMonth}
              >
                {months.map((month, i) => (
                  <MenuItem key={month} value={i}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={currSelYear}
                onChange={event => {
                  handleChange('currSelYear', event.target.value)
                }}
                onClose={() => {
                  openListHandler('openYear', false)
                }}
                onOpen={() => {
                  openListHandler('openYear', true)
                }}
                open={openYear}
              >
                {years.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormControl>
        </form>
      )
    }
  },
)

function DateMasked(props) {
  const { inputRef, showPlaceholder, ...other } = props

  return (
    <MaskedInput
      {...other}
      keepCharPositions
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      placeholder={
        showPlaceholder
          ? i18next.t('shared_web_atoir:ruDatePlaceholder')
          : undefined
      }
      showMask={false}
    />
  )
}

const styles = () => ({
  divDatePicker: {
    display: 'flex',
    position: 'relative',
  },
  btnDatePicker: {
    marginRight: 0,
  },
  inputDatePicker: {
    minWidth: '104px',
  },
  inputDatePickerError: {
    color: 'red',
  },
})

class DateInput extends Component {
  constructor(props) {
    super(props)
    this.inputFieldRef = React.createRef()
    this.state = {
      valueSting: props.value ? moment(props.value).format('DD.MM.YYYY') : '',
      showPlaceholder: false,
      month: new Date(),
      anchorEl: null,
      value: props.value,
      focusCanCallSubmit: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const toReturnState = {}
    // опция для запрета всех дней до текущей даты
    if (
      nextProps.disablePast &&
      prevState.disablePast !== nextProps.disablePast
    ) {
      toReturnState.disabledDays = {
        before: new Date(),
      }
      toReturnState.disablePast = nextProps.disablePast
    }
    // отмена запрета всех дней до текущей даты
    if (
      prevState.disablePast !== nextProps.disablePast &&
      !nextProps.disablePast
    ) {
      toReturnState.disabledDays = null
      toReturnState.disablePast = nextProps.disablePast
    }
    // опция запрета дней кастомным списком
    if (
      nextProps.disabledDays &&
      prevState.disabledDays !== nextProps.disabledDays
    ) {
      toReturnState.disabledDays = nextProps.disabledDays
    }
    // обновление данных
    if (nextProps.value && prevState.value !== nextProps.value) {
      toReturnState.value = nextProps.value
      toReturnState.month = nextProps.value
      toReturnState.valueSting = moment(nextProps.value).format('DD.MM.YYYY')
    }
    if (nextProps.value === null && prevState.value !== nextProps.value) {
      toReturnState.value = nextProps.value
      toReturnState.valueSting = ''
    }
    // перемотка пикера на нужный месяц (напр при использования запрещеных дней)
    if (
      prevState.startFrom !== nextProps.startFrom &&
      nextProps.startFrom &&
      moment(nextProps.startFrom).isValid()
    ) {
      toReturnState.startFrom = nextProps.startFrom
      toReturnState.month = nextProps.startFrom
    }
    if (!_.isEmpty(toReturnState)) {
      return toReturnState
    }
    return null
  }

  handleYearMonthChange = month => {
    this.setState({ month })
  }

  validateDate = () => {
    const { valueSting, mustSubmit } = this.state
    if (valueSting !== '') {
      this.setState({
        showPlaceholder: false,
        valueNotValid: !moment(valueSting, 'DD.MM.YYYY', true).isValid(),
      })
    }
    this.setState({
      showPlaceholder: false,
    })
    if (moment(valueSting, 'DD.MM.YYYY', true).isValid()) {
      const value = moment(valueSting, 'DD.MM.YYYY').toDate()
      if (mustSubmit) this.props.handleChange(value, valueSting)
      this.setState({
        mustSubmit: false,
        value,
      })
    } else if (valueSting === '') {
      this.props.handleChange('', '')
    } else {
      this.props.handleChange(null, null)
    }
    this.setState({ focusCanCallSubmit: false })
  }

  handleChangeDateInput = day => {
    const {
      props: { handleChangeText },
    } = this
    handleChangeText(day)
    const { valueSting } = this.state
    if (valueSting === '' && valueSting === day) {
      return
    }
    this.setState(
      {
        valueNotValid: false,
        valueSting: day === '  .  .    ' ? '' : day,
        mustSubmit: true,
        focusCanCallSubmit: true,
      },
      () => {
        if (day === '') {
          this.validateDate()
          return
        }
        if (moment(day, 'DD.MM.YYYY', true).isValid()) {
          this.validateDate()
        }
      },
    )
  }

  handleChangeDate = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    this.handlePickerClose()
    const valueSting = moment(day).format('DD.MM.YYYY')
    this.setState({
      valueSting,
      valueNotValid: false,
      value: day,
    })
    this.props.handleChange(day, valueSting)
    this.syntheticOnBlur()
    this.setState({ focusCanCallSubmit: false })
  }

  handlePickerOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handlePickerClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  syntheticOnBlur = () => {
    this.inputFieldRef.current.dispatchEvent(new Event('blur'))
  }

  render() {
    const {
      props: {
        classes,
        requiredValue,
        name,
        dateValid,
        label,
        helperText,
        inputAdornmentPosition,
        disabled,
        error,
        id,
        onBlur,
        fullWidth,
        startAdornment,
      },
      state: {
        valueSting,
        valueNotValid,
        showPlaceholder,
        anchorEl,
        value,
        disabledDays,
        focusCanCallSubmit,
      },
      handleChangeDate,
      handleChangeDateInput,
      validateDate,
      handlePickerOpen,
      handlePickerClose,
    } = this
    const sideInputAdornment = (
      <InputAdornment position={inputAdornmentPosition}>
        <IconButton
          className={classes.btnDatePicker}
          onClick={event => {
            handlePickerOpen(event)
          }}
        >
          <DateRange />
        </IconButton>
      </InputAdornment>
    )
    return (
      <FormControl
        required={requiredValue}
        fullWidth={fullWidth}
        error={dateValid === false || error}
        disabled={disabled}
      >
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Fragment>
          <Input
            inputRef={this.inputFieldRef}
            {...(inputAdornmentPosition === 'start'
              ? { startAdornment: sideInputAdornment }
              : {})}
            {...(startAdornment
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      {startAdornment}
                    </InputAdornment>
                  ),
                }
              : {})}
            endAdornment={
              inputAdornmentPosition === 'end' ? sideInputAdornment : null
            }
            inputComponent={DateMasked}
            inputProps={{
              showPlaceholder,
            }}
            onFocus={() => {
              this.setState({ showPlaceholder: true })
            }}
            onBlur={event => {
              onBlur(event)
              if (focusCanCallSubmit) validateDate()
            }}
            className={classNames(classes.inputDatePicker, {
              [classes.inputDatePickerError]: valueNotValid,
            })}
            name={name}
            id={id}
            onChange={event => {
              handleChangeDateInput(event.target.value)
            }}
            value={valueSting}
          />
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
          <Popover
            id="simple-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePickerClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper elevation={2}>
              <DayPicker
                enableOutsideDaysClick={false}
                disabledDays={disabledDays === null ? undefined : disabledDays}
                selectedDays={value}
                locale="ru"
                localeUtils={MomentLocaleUtils}
                onDayClick={(date, modifiers) => {
                  handleChangeDate(date, modifiers)
                }}
                month={this.state.month}
                fromMonth={fromMonth}
                toMonth={toMonth}
                captionElement={({ date, localeUtils }) => (
                  <YearMonthForm
                    date={date}
                    localeUtils={localeUtils}
                    onChange={this.handleYearMonthChange}
                  />
                )}
              />
            </Paper>
          </Popover>
        </Fragment>
      </FormControl>
    )
  }
}

DateInput.defaultProps = {
  dateValid: true,
  requiredValue: false,
  InputProps: null,
  value: null,
  disablePast: false,
  fullWidth: true,
  dayPickerProps: {},
  startFrom: null,
  disabledDays: null,
  helperText: null,
  inputAdornmentPosition: 'end',
  disabled: false,
  handleChangeText: () => {},
  onBlur: () => {},
  error: false,
  id: 'data',
}

DateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  dateValid: PropTypes.bool,
  requiredValue: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleChangeText: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  helperText: PropTypes.string,
  inputAdornmentPosition: PropTypes.string,
  id: PropTypes.string,
  // disablePast: PropTypes.bool,
  // dayPickerProps: PropTypes.object,
  // startFrom: PropTypes.string,
  // disabledDays: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

export default withStyles(styles)(DateInput)
