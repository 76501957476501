import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import _ from 'lodash'
import i18next from '../../../../i18n/i18n'
import Autocomplete from '../../../../widgets/Autocomplete/Autocomplete'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// helpers
import { URL } from '../../../../middlewares/api'
import { omitByEmpty } from '../../../../helpers/dataHelper'

// components
import SelectLimitedFromControl from '../../../../widgets/SelectLimitedFormControl'
import ApplyButton from '../../../../widgets/Buttons/ApplyButton'

import config from '../../../../config'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const drawerFiltersWidth = config.right_drawer_width

const styles = theme => ({
  divDatePicker: {
    paddingTop: '0px',
  },
  paddingTopGrid: {
    paddingTop: 0,
    padding: '12px',
  },
  checkbox: {
    '&$checked': {
      color: theme.palette.blueSecondary.main,
    },
  },
  checked: {
    color: theme.palette.blueSecondary.main,
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  filtersContainerGrid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    width: drawerFiltersWidth,
  },
  formControlContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  marginBottom: {
    marginBottom: 24,
  },
})

const doesNotMatterItem = () => ({
  id: '',
  full_name: i18next.t('shared_web_atoir:irrelevant'),
})

class InnerCallsFilters extends Component {
  constructor(props) {
    super(props)

    this.selectRefs = {}
    this.state = {
      limit: 20,
      offset: 0,
      organization: null,
      number: '',
      engineer: '',
      equipment: '',
    }
  }

  componentDidMount() {
    if (this.props.createRef) {
      this.props.createRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    console.log('')
    const {
      props: { status },
    } = this
    if (prevProps.status !== status) {
      this.resetFilters()
    }
  }

  resetFilters = () => {
    this.setState(
      {
        organization: null,
        number: '',
        engineer: '',
        equipment: '',
      },
      () => {
        this.innerCallsOrganizations.reset()
        _.compact(Object.values(this.selectRefs)).map(ref => ref.clear())
      },
    )
  }

  onChangeSelect = field => value => {
    const {
      props: {
        onChangeFilter,
        fetchInnerCallsNumbersRequest,
        fetchInnerCallsEngineersRequest,
        fetchInnerCallsEquipmentsRequest,
      },
      state: { limit, offset, number, engineer, equipment },
    } = this
    this.setState({ [field]: value }, () => {
      onChangeFilter(value, field)
      if (!isEmpty(number)) {
        fetchInnerCallsNumbersRequest(
          omitByEmpty({
            limit,
            offset,
            query: number,
            organization_id: value,
          }),
        )
      }
      if (!isEmpty(engineer)) {
        fetchInnerCallsEngineersRequest(
          omitByEmpty({
            limit,
            offset,
            query: engineer,
            organization_id: value,
          }),
        )
      }
      if (!isEmpty(equipment)) {
        fetchInnerCallsEquipmentsRequest(
          omitByEmpty({
            limit,
            offset,
            query: equipment,
            organization_id: value,
          }),
        )
      }
    })
  }

  onReceivedValue = (value, field) => {
    if (this[field] === value) {
      return
    }
    this[field] = value

    const {
      props: {
        fetchInnerCallsNumbersRequest,
        fetchInnerCallsEngineersRequest,
        fetchInnerCallsEquipmentsRequest,
      },
      state: { limit, offset, organization },
    } = this
    const re = /^[0-9\b]+$/
    if (!isEmpty(value)) {
      const params = omitByEmpty({
        limit,
        offset,
        query: value,
        organization_id: organization,
      })
      if (field === 'number' && re.test(value)) {
        this.setState({ [field]: value }, () =>
          fetchInnerCallsNumbersRequest(params),
        )
      }
      if (field === 'engineer') {
        this.setState({ [field]: value }, () =>
          fetchInnerCallsEngineersRequest(params),
        )
      }
      if (field === 'equipment') {
        this.setState({ [field]: value }, () =>
          fetchInnerCallsEquipmentsRequest(params),
        )
      }
    } else {
      this.setState({ [field]: value })
    }
  }

  handleFiltersApply = () => {
    const {
      props: { onApplyFilters },
    } = this
    onApplyFilters()
  }

  render() {
    const {
      state: { organization, number, engineer, equipment },
      props: {
        classes,
        isFetchingNumbers,
        numbers,
        status,
        isFetchingEngineers,
        engineers,
        isFetchingEquipments,
        equipments,
        onChangeFilter,
        which,
      },
      onReceivedValue,
      handleFiltersApply,
    } = this
    const numberItems = (number === ''
      ? [doesNotMatterItem()]
      : numbers || []
    ).map(n => {
      if (typeof n === 'string') {
        return { id: n, title: n }
      }
      return n
    })
    const engineerItems = (engineer === ''
      ? [doesNotMatterItem()]
      : engineers || []
    ).map(eng => ({ id: eng.id, title: eng.full_name }))
    const equipmentItems = (equipment === ''
      ? [doesNotMatterItem()]
      : equipments || []
    ).map(eqp => ({ id: eqp.id, title: eqp.name }))
    const RadioBox = (
      <Box p={1} mt={4}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="whichRadio"
            value={which}
            onChange={({ target: { value: _value } }) => {
              onChangeFilter(_value, 'which')
            }}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="Все заявки"
            />
            <FormControlLabel
              value="my"
              control={<Radio />}
              label="Только мои заявки"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    )
    return (
      <Fragment>
        {RadioBox}
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          justify="space-between"
          className={classes.filtersContainerGrid}
        >
          <Grid item xs={12}>
            <SelectLimitedFromControl
              FormControlProps={{
                fullWidth: true,
              }}
              createRef={ref => {
                this.innerCallsOrganizations = ref
              }}
              label={i18next.t('inner_calls_web_atoir:organization')}
              firstElement={{ id: null, title: '' }}
              value={isEmpty(organization) ? '' : organization}
              query={{ status }}
              sourceUrl={`${URL}/inner_calls/statistics/organizations`}
              onChange={this.onChangeSelect('organization')}
              requestResultTransformerToArray={r => r.organizations}
              itemTransformer={l => ({ id: l.id, title: l.short_name })}
            />
          </Grid>
          <Grid item xs={12} className={classes.marginBottom}>
            <Autocomplete
              classes={classes}
              isFetchingSuggestions={number === '' ? false : isFetchingNumbers}
              overrideItemToString={i => i.title}
              suggestions={numberItems}
              placeholder={i18next.t('inner_calls_web_atoir:innerCallsNumber')}
              searchValue={value => {
                onReceivedValue(value, 'number')
              }}
              onSelect={value => onChangeFilter(value, 'number')}
              refFunc={el => {
                this.selectRefs.innerCallsNumber = el
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.marginBottom}>
            <Autocomplete
              classes={classes}
              isFetchingSuggestions={
                engineer === '' ? false : isFetchingEngineers
              }
              overrideItemToString={i => i.title}
              suggestions={engineerItems}
              placeholder={i18next.t('inner_calls_web_atoir:engineer')}
              searchValue={value => onReceivedValue(value, 'engineer')}
              onSelect={value => onChangeFilter(value, 'engineer')}
              refFunc={el => {
                this.selectRefs.innerCallsEngineer = el
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.marginBottom}>
            <Autocomplete
              classes={classes}
              isFetchingSuggestions={
                equipment === '' ? false : isFetchingEquipments
              }
              overrideItemToString={i => i.title}
              suggestions={equipmentItems}
              placeholder={i18next.t('inner_calls_web_atoir:equipmentName')}
              searchValue={value => onReceivedValue(value, 'equipment')}
              onSelect={value => onChangeFilter(value, 'equipment')}
              refFunc={el => {
                this.selectRefs.innerCallsEquipment = el
              }}
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <ApplyButton onClick={handleFiltersApply}>
              {i18next.t('service_desk_web_atoir:applyAction')}
            </ApplyButton>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

InnerCallsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  fetchInnerCallsNumbersRequest: PropTypes.func.isRequired,
  isFetchingNumbers: PropTypes.bool.isRequired,
  numbers: PropTypes.array.isRequired,
  fetchInnerCallsEngineersRequest: PropTypes.func.isRequired,
  isFetchingEngineers: PropTypes.bool.isRequired,
  engineers: PropTypes.array.isRequired,
  fetchInnerCallsEquipmentsRequest: PropTypes.func.isRequired,
  isFetchingEquipments: PropTypes.bool.isRequired,
  equipments: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
}

export default withStyles(styles)(InnerCallsFilters)
