import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEmpty from 'is-empty'
import i18n from '../../../i18n/i18n'
import { omitByEmpty } from '../../../helpers/dataHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

// components
import InnerCallsTable from './InnerCallsTable'
import InnerCallsFilters from './InnerCallsFilters'
import ApplyButton from '../../../widgets/Buttons/ApplyButton'

const styles = theme => ({
  statusChip: {
    minWidth: 120,
    marginRight: 16,
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: 16,
  },
  activeChip: {},
  arrowIcon: {
    fontSize: 16,
    rotate: 180,
    marginRight: 4,
    verticalAlign: 'text-top',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  arrowIconRotated: {
    transform: 'rotate(180deg)',
  },
  listItem: {
    paddingTop: 6,
    paddingBottom: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  analyticButton: {
    textAlign: 'right',
    marginTop: 43,
    marginRight: 15,
  },
})

const statuses = ['all', 'pending', 'created', 'closed']

class InnerCalls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sortBy: '',
      sortDirection: 'asc',
      organization: null,
      number: null,
      engineer: null,
      equipment: null,
      which: 'all',
    }
  }

  componentDidMount() {
    const {
      props: {
        currentPage,
        currentRowsPerPage,
        rowsPerPage,
        setCommonInnerCallsListState,
      },
      fetchInnerCalls,
      onChangePageHandler,
      onChangeRowsPerPageHandler,
    } = this
    if (currentRowsPerPage > 0 || currentPage > 0) {
      // есть сохраненное состояние пагинации, восстановим его передав хоку пагинации
      if (currentRowsPerPage > 0) {
        onChangeRowsPerPageHandler(null, currentRowsPerPage)
      }
      if (currentPage > 0) {
        onChangePageHandler(null, currentPage)
      }
      fetchInnerCalls()
    } else {
      setCommonInnerCallsListState({ currentRowsPerPage: rowsPerPage })
      fetchInnerCalls()
    }
    this.changeFilters()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      state: { which, organization },
    } = this
    if (
      prevProps.page !== this.props.page ||
      prevProps.rowsPerPage !== this.props.rowsPerPage
    ) {
      this.fetchInnerCalls()
      this.changeFilters()
    }
    if (prevState.organization !== organization || prevState.which !== which) {
      this.changeFilters()
    }
  }

  componentWillUnmount() {
    const {
      props: { clearFilterDrawer },
    } = this
    clearFilterDrawer()
  }

  fetchInnerCalls = () => {
    const {
      state: {
        sortDirection,
        sortBy,
        organization,
        number,
        engineer,
        equipment,
        which,
      },
      props: {
        page,
        rowsPerPage,
        fetchInnerCallsRequest,
        currentStatus: status,
      },
    } = this
    const params = omitByEmpty({
      status: status === 'all' ? null : status,
      which: which === 'all' ? null : which,
      sort_direction: sortDirection,
      sort_by: sortBy,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      organization_id: organization,
      inner_call_number: number ? number.id : null,
      engineer_id: engineer ? engineer.id : null,
      equipment_id: equipment ? equipment.id : null,
    })
    fetchInnerCallsRequest(params)
  }

  onChangeStatus = status => {
    const {
      props: { currentStatus: propsStatus, setCommonInnerCallsListState },
      onChangePageHandler,
    } = this
    if (propsStatus === status) {
      return
    }
    setCommonInnerCallsListState({ currentStatus: status })
    onChangePageHandler(null, 0)
    this.setState(
      {
        organization: null,
        number: null,
        engineer: null,
        equipment: null,
      },
      () => {
        this.changeFilters()
        this.fetchInnerCalls()
      },
    )
  }

  changeFilters = () => {
    const {
      props: { classes, setFilterDrawer, currentStatus: status },
      state: { sortBy, sortDirection, organization, which },
      onSort,
      onChangeFilter,
      onApplyFilters,
      handleRedirectToAnalytics,
    } = this
    const orderKeys = ['createdAt', 'work_time']
    const ordering = (
      <Fragment>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {i18n.t('inner_calls_web_atoir:sortings')}
            </ListSubheader>
          }
        >
          {orderKeys.map(k => (
            <ListItem onClick={() => onSort(k)} className={classes.listItem}>
              <ListItemText>
                <div style={{ display: 'flex' }}>
                  <ArrowUpward
                    className={classnames([
                      classes.arrowIcon,
                      sortBy !== k && classes.hidden,
                      sortDirection === 'desc' && classes.arrowIconRotated,
                    ])}
                  />
                  <span style={sortBy === k ? { fontWeight: 'bold' } : {}}>
                    {i18n.t(`inner_calls_web_atoir:${k}SortBy`)}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Fragment>
    )

    setFilterDrawer([
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="right"
      >
        <Grid item>
          {ordering}
          <Divider />
          <InnerCallsFilters
            key={status}
            status={status}
            which={which}
            onChangeFilter={onChangeFilter}
            onApplyFilters={onApplyFilters}
          />
        </Grid>
        <Grid item className={classes.analyticButton}>
          <ApplyButton
            disabled={isEmpty(organization)}
            onClick={() => {
              handleRedirectToAnalytics(organization)
            }}
          >
            {i18n.t('inner_calls_web_atoir:analytics')}
          </ApplyButton>
        </Grid>
      </Grid>,
    ])
  }

  onSort = key => {
    const {
      state: { sortDirection: sd },
    } = this
    let sortBy = key
    let sortDirection = 'asc'

    if (this.state.sortBy === key && sd === 'asc') {
      sortDirection = 'desc'
    } else if (this.state.sortBy === key && sd === 'desc') {
      sortBy = ''
      sortDirection = sd
    }
    this.setState({ sortDirection, sortBy }, () => {
      this.changeFilters()
      this.fetchInnerCalls()
    })
  }

  onChangeFilter = (value, field) => this.setState({ [field]: value })

  onApplyFilters = () => {
    const { fetchInnerCalls, changeFilters } = this
    fetchInnerCalls()
    changeFilters()
  }

  handleRedirectToAnalytics = organizationId => {
    const {
      props: { history, match },
    } = this
    history.push(`${match.url}/${organizationId}/analitycs`)
  }

  onChangePageHandler = (event, page) => {
    const {
      props: { onChangePage, setCommonInnerCallsListState },
    } = this
    onChangePage(null, page)
    setCommonInnerCallsListState({ currentPage: page })
  }

  onChangeRowsPerPageHandler = (event, RowsPerPage) => {
    const {
      props: { onChangeRowsPerPage, setCommonInnerCallsListState },
    } = this
    const _value = _.get(event, 'target.value') || RowsPerPage
    onChangeRowsPerPage(null, _value)
    setCommonInnerCallsListState({
      currentRowsPerPage: _value,
    })
  }

  render() {
    const {
      props: {
        classes,
        page,
        count,
        rowsPerPage,
        rowsPerPageOptions,
        isFetchingInnerCalls,
        innerCalls,
        allCount,
        createdCount,
        closedCount,
        pendingCount,
        ifOneOrganizationId,
        currentStatus: status,
      },
      onChangeStatus,
      onChangePageHandler,
      onChangeRowsPerPageHandler,
    } = this
    const counts = { allCount, createdCount, closedCount, pendingCount }
    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          {i18n.t('inner_calls_web_atoir:innerCalls')}
        </Typography>
        {statuses.map(s => (
          <Chip
            onClick={() => onChangeStatus(s)}
            color={status === s ? 'primary' : 'default'}
            className={classnames([
              classes.statusChip,
              status === s ? classes.activeChip : null,
            ])}
            label={`${i18n.t(`inner_calls_web_atoir:${s}Status`)} (${
              counts[`${s}Count`]
            })`}
            variant="outlined"
          />
        ))}
        <InnerCallsTable
          isFetching={isFetchingInnerCalls}
          innerCalls={innerCalls}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangePage={onChangePageHandler}
          handleChangeRowsPerPage={onChangeRowsPerPageHandler}
          ifOneOrganizationId={ifOneOrganizationId}
        />
      </Fragment>
    )
  }
}

InnerCalls.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  // setPagination: PropTypes.func.isRequired,
  fetchInnerCallsRequest: PropTypes.func.isRequired,
  isFetchingInnerCalls: PropTypes.bool.isRequired,
  innerCalls: PropTypes.array.isRequired,
  currentStatus: PropTypes.string.isRequired,
}

export default withStyles(styles)(InnerCalls)
