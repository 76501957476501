import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RLink from 'react-router-dom/Link'

const styles = theme => ({
  link: {
    textDecoration: 'underline',
    // textDecorationColor: theme.palette.primary.contrastText,
    color: theme.palette.loginNav.main,
  },
  titleLink: {
    margin: theme.spacing(1),
    color: theme.palette.loginNav.main,
    fontWeight: '100',
  },
})

const LoginNavLink = props => (
  <Typography
    variant="subheading"
    align="right"
    className={props.classes.titleLink}
    {...props}
  >
    {props.text}
    <RLink to={props.link} className={props.classes.link}>
      <span className={props.classes.titleLink}>{props.linkText}</span>
    </RLink>
  </Typography>
)

LoginNavLink.defaultProps = {
  text: '',
  link: '',
  linkText: '',
}

LoginNavLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginNavLink)
