import * as constants from '../constants/actionTypes/tags'

const initialState = {
  createTag: {
    isFetching: false,
    error: null,
  },
  editTag: {
    isFetching: false,
    error: null,
  },
  getTags: {
    isFetching: false,
    tags: [],
    error: null,
  },
  deleteTag: {
    isFetching: false,
    error: null,
  },
  getTag: {
    isFetching: false,
    tag: [],
    error: null,
  },
}

const tags = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_TAG_REQUEST:
      return {
        ...state,
        createTag: {
          ...state.createTag,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_TAG_SUCCESS:
      return {
        ...state,
        createTag: {
          ...state.createTag,
          isFetching: false,
        },
      }

    case constants.CREATE_TAG_FAILURE:
      return {
        ...state,
        createTag: {
          ...state.createTag,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_TAG_REQUEST:
      return {
        ...state,
        editTag: {
          ...state.editTag,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_TAG_SUCCESS:
      return {
        ...state,
        editTag: {
          ...state.editTag,
          isFetching: false,
        },
      }

    case constants.EDIT_TAG_FAILURE:
      return {
        ...state,
        editTag: {
          ...state.editTag,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_TAGS_REQUEST:
      return {
        ...state,
        getTags: {
          ...state.getTags,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_TAGS_SUCCESS:
      return {
        ...state,
        getTags: {
          ...state.getTags,
          isFetching: false,
          tags: action.payload,
        },
      }

    case constants.GET_TAGS_FAILURE:
      return {
        ...state,
        getTags: {
          ...state.getTags,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_TAG_REQUEST:
      return {
        ...state,
        deleteTag: {
          ...state.deleteTag,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_TAG_SUCCESS:
      return {
        ...state,
        deleteTag: {
          ...state.deleteTag,
          isFetching: false,
        },
      }

    case constants.DELETE_TAG_FAILURE:
      return {
        ...state,
        deleteTag: {
          ...state.deleteTag,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_TAG_REQUEST:
      return {
        ...state,
        getTag: {
          ...state.getTag,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_TAG_SUCCESS:
      return {
        ...state,
        getTag: {
          ...state.getTag,
          isFetching: false,
          tag: action.payload,
        },
      }

    case constants.GET_TAG_FAILURE:
      return {
        ...state,
        getTag: {
          ...state.getTag,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default tags
