import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table/Table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

// helpers
import { getUserFullName } from '../../helpers/userHelper'
import { convertTimestamp } from '../../helpers/dateHelper'

// modules
import Loader from '../../components/Loader'

const headerStyles = () => ({
  headerCell: {
    border: 0,
  },
})

let EnhancedTableHead = props => (
  <TableHead>
    <TableRow>
      <TableCell
        numeric={false}
        className={props.classes.headerCell}
        sortDirection={props.orderBy === 'ByDate' ? props.order : false}
      >
        <TableSortLabel
          onClick={() => props.orderBySetter('ByDate')}
          direction={props.order}
          active={props.orderBy === 'ByDate'}
        >
          {i18next.t('inventory_web_atoir:changedWhen')}
        </TableSortLabel>
      </TableCell>
      <TableCell
        numeric={false}
        className={props.classes.headerCell}
        padding="checkbox"
        sortDirection={props.orderBy === 'ByWho' ? props.order : false}
      >
        <TableSortLabel
          onClick={() => props.orderBySetter('ByWho')}
          active={props.orderBy === 'ByWho'}
          direction={props.order}
        >
          {i18next.t('inventory_web_atoir:changedWho')}
        </TableSortLabel>
      </TableCell>
      <TableCell className={props.classes.headerCell} padding="checkbox">
        {i18next.t('inventory_web_atoir:changedWhat')}
      </TableCell>
    </TableRow>
  </TableHead>
)

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  tableCell: {
    border: 0,
  },
  tableRow: {
    height: 40,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.apply.lightest,
    },
  },
  textStyle: {
    color: 'black',
    textStyle: 'underlined',
    margin: theme.spacing(1),
  },
})

const LogTableRender = props => {
  const {
    classes,
    isFetchingHistory,
    isFetchingHistoryCount,
    historyData,
    pagination,
    page,
  } = props
  return (
    <Fragment>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead {...props} />
          {isFetchingHistory || isFetchingHistoryCount ? (
            <Loader />
          ) : (
            <TableBody>
              {historyData &&
                historyData.map(h => (
                  <TableRow key={h.id} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {convertTimestamp(h.date)}
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="checkbox">
                      <Typography variant="body1">
                        {getUserFullName(h.actor_organization_user)}
                      </Typography>
                      <Typography color="default" variant="caption">
                        {h.organization_short_name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="checkbox">
                      <Typography>{h.comment}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </div>
      <TablePagination
        component="div"
        rowsPerPageOptions={pagination}
        count={props.historyDataCount}
        rowsPerPage={props.limit}
        page={page}
        labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
        labelDisplayedRows={({
          from: fromPage,
          to: toPage,
          count: countPage,
        }) =>
          i18next.t('shared_web_atoir:labelDisplayedRows', {
            fromPage,
            toPage,
            countPage,
          })
        }
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={(event, newPage) => props.setOptions('page', newPage)}
        onChangeRowsPerPage={event =>
          props.setOptions('limit', event.target.value)
        }
      />
    </Fragment>
  )
}

LogTableRender.defaultProps = {
  pagination: [25, 50, 100],
  limit: 50,
  historyDataCount: 1,
}

LogTableRender.propTypes = {
  classes: PropTypes.object.isRequired,
  historyData: PropTypes.array.isRequired,
  orderBySetter: PropTypes.func.isRequired,
  historyDataCount: PropTypes.number,
  order: PropTypes.string.isRequired,
  pagination: PropTypes.array,
  limit: PropTypes.number,
  page: PropTypes.number.isRequired,
  setOptions: PropTypes.func.isRequired,
}

export default withStyles(styles)(LogTableRender)
