import {
  SHOW_DIALOG_LOADER,
  HIDE_DIALOG_LOADER,
} from '../constants/actionTypes'

export const showDialogLoader = () => ({
  type: SHOW_DIALOG_LOADER,
})

export const hideDialogLoader = () => ({
  type: HIDE_DIALOG_LOADER,
})
