import * as constants from '../constants/actionTypes/recurringCalls'

const initialState = {
  calls: {
    isFetching: false,
    calls: [],
    error: null,
  },
  callsCount: {
    isFetching: false,
    count: 0,
    error: null,
  },
}

const recurringCalls = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORGANIZATION_RECURRING_CALLS_REQUEST:
      return {
        ...state,
        calls: {
          isFetching: true,
          calls: [],
          error: null,
        },
      }
    case constants.GET_ORGANIZATION_RECURRING_CALLS_SUCCESS:
      return {
        ...state,
        calls: {
          isFetching: false,
          calls: action.payload,
          error: null,
        },
      }
    case constants.GET_ORGANIZATION_RECURRING_CALLS_FAILURE:
      return {
        ...state,
        calls: {
          ...state.calls,
          isFetching: false,
          error: action.payload,
        },
      }
    case constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_REQUEST:
      return {
        ...state,
        callsCount: {
          isFetching: true,
          count: 0,
          error: null,
        },
      }
    case constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_SUCCESS:
      return {
        ...state,
        callsCount: {
          isFetching: false,
          count: action.payload,
          error: null,
        },
      }
    case constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_FAILURE:
      return {
        ...state,
        callsCount: {
          ...state.callsCount,
          isFetching: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default recurringCalls
