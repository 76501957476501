import React, { Component, Fragment } from 'react'
import RLink from 'react-router-dom/Link'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../../../i18n/i18n'

// components
import Loader from '../../../../../Loader'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'

const headStyles = theme => ({
  chip: {
    margin: theme.spacing(1),
  },
  root: {
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
})

let InventoryEquipmentTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell>{i18next.t('inventory_web_atoir:Name')}</TableCell>
      <TableCell>{i18next.t('inventory_web_atoir:Department')}</TableCell>
      <TableCell>{i18next.t('inventory_web_atoir:Status')}</TableCell>
      <TableCell>{i18next.t('inventory_web_atoir:serialNo')}</TableCell>
      <TableCell>{i18next.t('inventory_web_atoir:inventoryNo')}</TableCell>
      <TableCell>{i18next.t('inventory_web_atoir:Marking')}</TableCell>
      <TableCell>
        {i18next.t('inventory_web_atoir:availabilityEquipment')}
      </TableCell>
    </TableRow>
  </TableHead>
)

InventoryEquipmentTableHead = withStyles(headStyles)(
  InventoryEquipmentTableHead,
)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 800,
    padding: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  text: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textStyle: {
    color: 'black',
    textStyle: 'underlined',
    margin: theme.spacing(1),
  },
})

class InventoryEquipmentTable extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      rowsPerPage: 5,
      filters: {},
    }
  }

  handleChangePage = (event, page) => {
    this.props.changePage(page)
    this.setState({ page }, () => {
      this.handleFetchInventory()
    })
  }

  handleChangeRowsPerPage = event => {
    this.props.changeRowsPerPage(event.target.value)
    this.setState({ rowsPerPage: event.target.value }, () =>
      this.handleFetchInventory(),
    )
  }

  handleFetchInventory = () => {
    const {
      props: { handleFetchInventory },
      state: { filters, page, rowsPerPage },
    } = this
    const f = {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      ...filters,
    }
    handleFetchInventory(f)
  }

  updateFilters = (field, value) => {
    this.setState(
      state => ({ filters: { ...state.filters, [field]: value } }),
      () => this.handleFetchInventory(),
    )
  }

  render() {
    const {
      props: { classes, isFetching, equipment },
    } = this
    return (
      <Fragment>
        <Fragment>
          <Table className={classes.table}>
            <Fragment>
              {isFetching ? (
                <Loader />
              ) : equipment && equipment.length !== 0 ? (
                <Fragment>
                  <InventoryEquipmentTableHead />
                  <TableBody>
                    {equipment.map(eq => (
                      <TableRow key={eq.id}>
                        <TableCell>
                          <RLink
                            to={`/equipment_profile/${eq.organization_id}/${eq.id}`}
                          >
                            <span className={classes.textStyle}>{eq.name}</span>
                          </RLink>
                        </TableCell>
                        <TableCell>
                          {!isEmpty(eq.OrganizationDepartment)
                            ? eq.OrganizationDepartment.name
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {`${i18next.t(`equipment_web_atoir:${eq.status}`)}`}
                        </TableCell>
                        <TableCell>
                          {eq.serial_number ? eq.serial_number : '-'}
                        </TableCell>
                        <TableCell>
                          {eq.article_number ? eq.article_number : '-'}
                        </TableCell>
                        <TableCell>
                          {eq.is_marked ? <DoneIcon /> : '-'}
                        </TableCell>
                        <TableCell>
                          {eq.is_found ? <DoneIcon /> : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              ) : (
                <Typography className={classes.text}>
                  {i18next.t('equipment_web_atoir:equipmentListIsEmpty')}
                </Typography>
              )}
            </Fragment>
          </Table>
        </Fragment>
      </Fragment>
    )
  }
}

InventoryEquipmentTable.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  equipment: PropTypes.array.isRequired,
}

export default withStyles(styles)(InventoryEquipmentTable)
