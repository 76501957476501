import React from 'react'
import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../i18n/i18n'
import { previewDocument } from '../../../helpers/previewHelper'

const styles = {
  link: {
    cursor: 'pointer',
    color: 'inherit',
    fontWeight: 600,
  },
  buttonLink: {
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
}

const StartSec = () => (
  <React.Fragment>
    {i18next.t('login_web_atoir:licenseConfirmTextStart')}
  </React.Fragment>
)

const FileLink = withStyles(styles)(({ classes }) => {
  function showPreview() {
    previewDocument('politics/privacy_policy.pdf')
  }
  return (
    <button type="button" onClick={showPreview} className={classes.buttonLink}>
      <Typography className={classes.link}>
        {i18next.t('login_web_atoir:licenseConfirmTextMidle')}
      </Typography>
    </button>
  )
})
const EndSec = () => (
  <React.Fragment>
    {i18next.t('login_web_atoir:licenseConfirmTextEnd')}
  </React.Fragment>
)

function AgreementTextWithFile() {
  return (
    <Typography component="div">
      <StartSec />
      <FileLink />
      <EndSec />
    </Typography>
  )
}

export default withStyles(styles)(AgreementTextWithFile)
