import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RejectInventoryProjectPerformerDialog from './RejectInventoryProjectPerformerDialog'
import { rejectInventoryProjectAsPerformer } from '../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  isRejectingInventoryProjectAsPerformer:
    state.inventory.inventoryProjectRejectAsPerformer
      .isRejectingInventoryProjectAsPerformer,
  rejectingErrors:
    state.inventory.inventoryProjectRejectAsPerformer.rejectingErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      rejectInventoryProjectAsPerformer,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RejectInventoryProjectPerformerDialog),
)
