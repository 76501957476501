import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import SimpleLoader from '../SimpleLoader'

const SimpleDialogLoader = props => (
  <Dialog
    open={props.open}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <SimpleLoader />
    </DialogContent>
  </Dialog>
)

SimpleDialogLoader.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default SimpleDialogLoader
