import React from 'react'
import { Box } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'

const Subtitle = ({ text }) => (
  <Box mt={1} mb={1}>
    <FormLabel>{text}</FormLabel>
  </Box>
)

export default Subtitle
