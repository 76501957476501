import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'

// components
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import Loader from '../../../../Loader'
import Typography from '@material-ui/core/Typography'
import Heading from '../../../../../widgets/Lettering/Heading'
import isEmpty from 'is-empty'
import InformationFrom from './InformationForm'

const styles = () => ({
  paper: {
    minWidth: 844,
  },
  buttonRight: {
    marginRight: 20,
  },
})

class EditPerformerInfoDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cabinet: props.cabinet,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      floor: props.floor,
      location: props.defaultLocation,
      comment: props.comment,
    }
  }

  onChange = field => event => {
    const { value } = event.target
    let { errors } = this.state
    const { floor, cabinet } = this.state

    if (
      field === 'cabinet' &&
      typeof value === 'string' &&
      value.length > 255
    ) {
      return
    }
    if (
      field === 'comment' &&
      typeof value === 'string' &&
      value.length > 1000
    ) {
      return
    }
    if (field === 'dateFrom' || field === 'dateTo') {
      errors = _.omit(errors, ['dateToDateFromError'])
    }
    if (field === 'cabinet' || value === '') {
      errors = _.omit(errors, ['floorError'])
    }
    if (field === 'floor' || value === '') {
      errors = _.omit(errors, ['cabinetError'])
    }
    if (field === 'cabinet' && value && !floor) {
      errors.floorError = i18next.t(
        'service_desk_web_atoir:requestCreationNoFloorValidationError',
      )
    }
    if (field === 'cabinet' && !value && floor) {
      errors.cabinetError = i18next.t(
        'service_desk_web_atoir:requestCreationNoCabinetValidationError',
      )
    }
    if (field === 'floor' && value && !cabinet) {
      errors.cabinetError = i18next.t(
        'service_desk_web_atoir:requestCreationNoCabinetValidationError',
      )
    }
    if (field === 'floor' && !value && cabinet) {
      errors.floorError = i18next.t(
        'service_desk_web_atoir:requestCreationNoFloorValidationError',
      )
    }

    this.setState({
      [field]: value,
      errors: value ? _.omit(errors, [`${field}Error`]) : errors,
    })
  }

  handlePositive = () => {
    const {
      state: { dateFrom, dateTo, location, floor, cabinet, comment },
    } = this
    const errors = {}
    if (!dateFrom) {
      errors.dateFromError = i18next.t(
        'service_desk_web_atoir:requestCreationNoDateValidationError',
      )
    }
    if (!dateTo) {
      errors.dateToError = i18next.t(
        'service_desk_web_atoir:requestCreationNoDateValidationError',
      )
    }
    if (dateTo && dateFrom && dateTo < dateFrom) {
      errors.dateToDateFromError = i18next.t(
        'service_desk_web_atoir:requestCreationDatesInvertedValidationError',
      )
    }
    if (!location || !location.location || !location.location.home) {
      errors.locationError = i18next.t(
        'service_desk_web_atoir:requestCreationNoLocationValidationError',
      )
    }
    if (cabinet && !floor) {
      errors.floorError = i18next.t(
        'service_desk_web_atoir:requestCreationNoFloorValidationError',
      )
    }
    if (floor && !cabinet) {
      errors.cabinetError = i18next.t(
        'service_desk_web_atoir:requestCreationNoCabinetValidationError',
      )
    }

    if (isEmpty(errors)) {
      this.props.onSubmit({
        reaction_date_begin: dateFrom,
        reaction_date_end: dateTo,
        location_repair_home: location.location.home,
        location_repair_street_fias_id: location.location.data.street_fias_id,
        location_repair_building_id: location.location.id,
        location_value: location.location.value,
        location_repair_office: cabinet,
        location_repair_floor: Number(floor),
        client_comment: comment,
      })
    } else {
      this.setState({ errors })
    }
  }

  componentDidMount = () => {
    const {
      props: { organizationId, fetchOrganization, fetchOrganizationWhoAmI },
    } = this
    fetchOrganizationWhoAmI(organizationId)
    fetchOrganization(organizationId)
  }

  render() {
    const {
      state: { cabinet, dateFrom, dateTo, floor, location, comment, errors },
      props: {
        organizationId,
        open,
        onClose,
        classes,
        isFetchingEquipment,
        isFetchingOrganization,
        organization,
        isFetchingUserStatus,
        isFetchingOrganizationStaff,
        userStatus,
        isFetchingMe,
      },
      onChange,
      handlePositive,
    } = this
    return isFetchingUserStatus ||
      isFetchingOrganizationStaff ||
      isFetchingMe ? (
      <Loader />
    ) : (
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Heading>
          <Typography variant="h6" style={{ padding: 16 }}>
            {i18next.t('service_desk_web_atoir:editingPerformerInfoTitle')}
          </Typography>
        </Heading>
        <Divider />
        <DialogContent>
          <Fragment>
            <Fragment>
              {isFetchingEquipment ||
              isFetchingOrganization ||
              isFetchingMe ||
              isEmpty(userStatus) ||
              !organization.physical_address ? (
                <Loader />
              ) : (
                <InformationFrom
                  comment={comment}
                  floor={floor}
                  cabinet={cabinet}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  location={location}
                  defaultLocation={this.props.defaultLocation}
                  organizationId={organizationId}
                  errors={errors}
                  onChangeCabinet={onChange('cabinet')}
                  onChangeDateFrom={onChange('dateFrom')}
                  onChangeDateTo={onChange('dateTo')}
                  onChangeFloor={onChange('floor')}
                  onChangeLocation={onChange('location')}
                  onChangeComment={onChange('comment')}
                />
              )}
            </Fragment>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={onClose}>
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton
            onClick={handlePositive}
            classes={{ root: classes.buttonRight }}
          >
            {i18next.t('shared_web_atoir:save')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

EditPerformerInfoDialog.defaultProps = {
  floor: '',
  cabinet: '',
  dateFrom: new Date(),
  dateTo: new Date(new Date().setMonth(new Date().getMonth() + 1)),
  defaultLocation: null,
}

EditPerformerInfoDialog.defaultProps = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  comment: PropTypes.string,
  floor: PropTypes.string,
  cabinet: PropTypes.string,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  defaultLocation: PropTypes.object,
}

export default withStyles(styles)(EditPerformerInfoDialog)
