import * as Yup from 'yup'

function transform(value, originalValue) {
  if (typeof originalValue === 'string' && `${originalValue}`.trim() === '') {
    return null
  }
  return value
}

const commonField = Yup.object().shape({
  col: Yup.number()
    .min(0)
    .max(11000)
    .nullable()
    .transform(transform),
  unique: Yup.bool(),
})

const commonFieldUniqueLess = Yup.object().shape({
  col: Yup.number()
    .min(0)
    .max(11000)
    .nullable()
    .transform(transform),
})

const commonFieldClearedOnCast = Yup.lazy(value =>
  value && value.col ? commonField : Yup.object().strip(),
)
const commonFieldClearedOnCastUniqueLess = Yup.lazy(value =>
  value && value.col ? commonFieldUniqueLess : Yup.object().strip(),
)

const requiredField = Yup.object()
  .shape({
    col: Yup.number()
      .min(0)
      .max(11000)
      .required(),
    unique: Yup.bool(),
  })
  .required()

const requiredFieldUniqueLess = Yup.object()
  .shape({
    col: Yup.number()
      .min(0)
      .max(11000)
      .required(),
  })
  .required()

export default Yup.object().shape({
  name: requiredField,
  address: requiredFieldUniqueLess,
  model: commonFieldClearedOnCast,
  vendor: commonFieldClearedOnCast,
  serial_number: commonFieldClearedOnCast,
  article_number: commonFieldClearedOnCast,
  external_id: commonFieldClearedOnCast,
  country: commonFieldClearedOnCastUniqueLess,
  year_issue: commonFieldClearedOnCast,
  date_input_work: commonFieldClearedOnCast,
  date_expired_guarantee: commonFieldClearedOnCast,
  status: commonFieldClearedOnCast,
  workable: commonFieldClearedOnCast,
  floor: commonFieldClearedOnCast,
  portable: commonFieldClearedOnCast,
  cabinet: commonFieldClearedOnCast,
})
