import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import { inventoryEquipmentAmount } from '../../../../constants/constants'
import { omitByEmpty } from '../../../../helpers/dataHelper'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import Subheading from '../../../../widgets/Lettering/Subheading'
import InventoryType from './InventoryType'
import InventoryLocationsTable from './InventoryLocationsTable/InventoryLocationsTable'
import Loader from '../../../Loader'
import DateInput from '../../../../widgets/DateInput'
import SimpleDialog from '../../../Other/SimpleDialog/DialogComponent'
import FormComponent from '../../../../widgets/FormComponent/index'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  container: {
    margin: theme.spacing(1),
  },
  divErrorOut: {
    minHeight: theme.spacing(3),
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

class AddInventoryProjectComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      organization: {},
      inventoryType: '',
      locationsChanged: false,
      formChanged: false,
      isConfirmCancelDialogOpen: false,
      qrInventoryKit: false,
      rfidInventoryKit: false,
      locations: [],
      desiredPeriodBegin: '',
      desiredPeriodEnd: '',
      clientResponsible: {},
      clientComment: '',
      errors: {},
    }
  }

  componentDidMount() {
    const { fetchManagerOrHigherOrganizations } = this.props
    fetchManagerOrHigherOrganizations()
  }

  componentDidUpdate(prevState) {
    const {
      isFetchingOrganizations,
      organizations,
      fetchOrganizationStaff,
      isCreatingInventoryProject,
      createProjectErrors,
    } = this.props
    if (
      prevState.isFetchingOrganizations !== isFetchingOrganizations &&
      !isFetchingOrganizations &&
      !isEmpty(organizations)
    ) {
      this.setState({ organization: organizations[0] }, () =>
        fetchOrganizationStaff(organizations[0].id),
      )
    }
    if (
      prevState.isCreatingInventoryProject !== isCreatingInventoryProject &&
      !isCreatingInventoryProject &&
      isEmpty(createProjectErrors)
    ) {
      this.goBack()
    }
  }

  goBack = () => {
    this.props.history.push('/inventory')
  }

  handleInventoryTypeChange = type => {
    this.setState({
      inventoryType: type,
      qrInventoryKit: false,
      rfidInventoryKit: false,
      formChanged: true,
    })
  }

  handleInventoryKitChange = value => {
    const { inventoryType } = this.state
    switch (inventoryType) {
      case 'qr':
        this.setState({ qrInventoryKit: value, formChanged: true })
        break

      case 'rfid':
        this.setState({ rfidInventoryKit: value, formChanged: true })
        break

      default:
        break
    }
  }

  handleOrganizationChange = event => {
    const { fetchOrganizationStaff } = this.props
    this.setState(
      { [event.target.name]: event.target.value, formChanged: true },
      () => fetchOrganizationStaff(event.target.value.id),
    )
  }

  handleLocations = locations => {
    const params = {}
    if (this.state.locationsChanged) {
      params.formChanged = true
    }
    this.setState({ locations, ...params, locationsChanged: true })
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
      formChanged: true,
    })
  }

  handleDateChange = field => date => {
    const {
      state: { desiredPeriodBegin, desiredPeriodEnd },
    } = this

    this.setState(state => ({
      [field]: date,
      formChanged: true,
      errors: {
        ...state.errors,
        desiredPeriod: '',
      },
    }))

    if (isEmpty(desiredPeriodBegin) && isEmpty(desiredPeriodEnd)) {
      return
    }

    const desiredPeriodBeginMS = new Date(desiredPeriodBegin).getTime()
    const desiredPeriodEndMS = new Date(desiredPeriodEnd).getTime()
    const dateMS = new Date(date).getTime()

    if (field === 'desiredPeriodBegin' && desiredPeriodEndMS < dateMS) {
      this.setState(state => ({
        errors: {
          ...state.errors,
          desiredPeriod: i18next.t('validation_web_atoir:desiredPeriodFailure'),
        },
      }))
    }

    if (field === 'desiredPeriodEnd' && desiredPeriodBeginMS > dateMS) {
      this.setState(state => ({
        errors: {
          ...state.errors,
          desiredPeriod: i18next.t('validation_web_atoir:desiredPeriodFailure'),
        },
      }))
    }
  }

  handleClientResponsibleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      formChanged: true,
    })
  }

  handleSubmitForm = () => {
    const { createInventoryProject } = this
    createInventoryProject()
  }

  handleCancel = () => {
    const { formChanged } = this.state
    if (formChanged) {
      this.setState({
        isConfirmCancelDialogOpen: true,
      })
    } else this.goBack()
  }

  createInventoryProject = () => {
    const {
      organization,
      inventoryType,
      qrInventoryKit,
      rfidInventoryKit,
      locations,
      desiredPeriodBegin,
      desiredPeriodEnd,
      clientResponsible,
      clientComment,
    } = this.state

    const addedLocations = _.filter(locations, l => l.added)
    const validateFields = this.validateEmptyFields(
      inventoryType,
      addedLocations,
      clientResponsible,
      desiredPeriodBegin,
      desiredPeriodEnd,
    )
    if (!isEmpty(validateFields)) {
      this.setState({ errors: validateFields })
      return
    }
    this.setState({ errors: {} })

    const inventoryLocations = addedLocations.map(l => {
      const loc = {}
      loc.id = l.location.id
      loc.amt = l.amt
      return loc
    })

    const { createInventoryProject } = this.props
    const data = {
      client_responsible_id: clientResponsible.id,
      inventory_type: inventoryType,
      inventory_kit: qrInventoryKit || rfidInventoryKit,
      desired_period_begin: desiredPeriodBegin,
      desired_period_end: desiredPeriodEnd,
      client_comment: clientComment,
      locations: JSON.stringify(inventoryLocations),
    }
    createInventoryProject(organization.id, omitByEmpty(data))
  }

  validateEmptyFields = (
    inventoryType,
    addedLocations,
    clientResponsible,
    desiredPeriodBegin,
    desiredPeriodEnd,
  ) => {
    const errors = {}
    const locationsWithoutAmount = _.filter(
      addedLocations,
      l => _.indexOf(inventoryEquipmentAmount, l.amt) === -1,
    )

    if (inventoryType === '') {
      errors.inventoryType = i18next.t(
        'validation_web_atoir:inventoryTypeShouldBeSelected',
      )
    }
    if (addedLocations.length === 0) {
      errors.locations = i18next.t(
        'validation_web_atoir:inventoryLocationsCanNotBeEmpty',
      )
    } else if (locationsWithoutAmount.length !== 0) {
      errors.locations = i18next.t(
        'validation_web_atoir:inventoryEquipmentAmountShouldBeSelected',
      )
    }
    if (desiredPeriodBegin === '' && desiredPeriodEnd !== '') {
      errors.desiredPeriod = i18next.t(
        'validation_web_atoir:desiredPeriodSelectBeginDate',
      )
    }
    if (desiredPeriodBegin !== '' && desiredPeriodEnd === '') {
      errors.desiredPeriod = i18next.t(
        'validation_web_atoir:desiredPeriodSelectEndDate',
      )
    }
    if (
      new Date(desiredPeriodBegin).getTime() >
      new Date(desiredPeriodEnd).getTime()
    ) {
      errors.desiredPeriod = i18next.t(
        'validation_web_atoir:desiredPeriodFailure',
      )
    }
    if (isEmpty(clientResponsible)) {
      errors.clientResponsible = i18next.t(
        'inventory_web_atoir:clientResponsibleShouldBeSelected',
      )
    }

    return errors
  }

  toggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  render() {
    const {
      organization,
      inventoryType,
      qrInventoryKit,
      rfidInventoryKit,
      desiredPeriodBegin,
      desiredPeriodEnd,
      clientResponsible,
      clientComment,
      isConfirmCancelDialogOpen,
      errors,
    } = this.state
    const {
      classes,
      isFetchingOrganizations,
      organizations,
      isFetchingOrganizationStaff,
      organizationStaff,
      isCreatingInventoryProject,
    } = this.props

    const inventoryTypeHelperText = !isEmpty(errors.inventoryType)
      ? errors.inventoryType
      : ''

    const locationsHelperText = !isEmpty(errors.locations)
      ? errors.locations
      : ''

    const periodHelperText = !isEmpty(errors.desiredPeriod)
      ? errors.desiredPeriod
      : ''

    const clientResponsibleHelperText = !isEmpty(errors.clientResponsible)
      ? errors.clientResponsible
      : ''
    return (
      <div>
        <FormComponent
          submitText={i18next.t('inventory_web_atoir:createProject')}
          isLoading={isCreatingInventoryProject || isFetchingOrganizations}
          handleSubmitAction={this.handleSubmitForm}
          secondaryButtonsArray={[
            {
              secondaryText: `${i18next.t('shared_web_atoir:cancel')}`,
              handleSecondaryAction: this.handleCancel,
            },
          ]}
        >
          <FormGroup>
            <Grid
              container
              className={classes.container}
              alignItems="flex-start"
              justify="flex-start"
              spacing={2}
            >
              <Grid item xs={4}>
                <Subheading>
                  {i18next.t('inventory_web_atoir:inventoryTypeSubtitle')}
                </Subheading>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid item xs={4}>
                  <InventoryType
                    typeTitle={i18next.t(
                      'inventory_web_atoir:inventoryQrTypeTitle',
                    )}
                    typeValue="qr"
                    checkedType={inventoryType === 'qr'}
                    handleInventoryTypeChange={this.handleInventoryTypeChange}
                    handleInventoryKitChange={this.handleInventoryKitChange}
                    hasOwnTitle={i18next.t(
                      'inventory_web_atoir:inventoryQrHasOwnText',
                    )}
                    inventoryKit={qrInventoryKit}
                    descriptionTitle={i18next.t(
                      'inventory_web_atoir:inventoryQrTypeDialogTitle',
                    )}
                    description={i18next.t(
                      'inventory_web_atoir:inventoryQrTypeDescriptionText',
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InventoryType
                    typeTitle={i18next.t(
                      'inventory_web_atoir:inventoryRfidTypeTitle',
                    )}
                    typeValue="rfid"
                    checkedType={inventoryType === 'rfid'}
                    handleInventoryTypeChange={this.handleInventoryTypeChange}
                    handleInventoryKitChange={this.handleInventoryKitChange}
                    hasOwnTitle={i18next.t(
                      'inventory_web_atoir:inventoryRfidHasOwnText',
                    )}
                    inventoryKit={rfidInventoryKit}
                    descriptionTitle={i18next.t(
                      'inventory_web_atoir:inventoryRfidTypeDialogTitle',
                    )}
                    description={i18next.t(
                      'inventory_web_atoir:inventoryRfidTypeDescriptionText',
                    )}
                  />
                </Grid>
                {!isEmpty(errors.inventoryType) ? (
                  <Grid item xs={12}>
                    <FormHelperText error={!isEmpty(errors.inventoryType)}>
                      {inventoryTypeHelperText}
                    </FormHelperText>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.container}
              alignItems="flex-start"
              justify="flex-start"
              spacing={2}
            >
              <Grid item xs={4}>
                <Subheading>
                  {i18next.t(
                    'inventory_web_atoir:inventoryClientInformationSubtitle',
                  )}
                </Subheading>
              </Grid>
              <Grid item xs={11}>
                <FormControl
                  fullWidth
                  required
                  error={!isEmpty(errors.organization)}
                  className={classes.formControl}
                  area-desxribedby="organization-text"
                >
                  <InputLabel htmlFor="organization">
                    {i18next.t('organizations_web_atoir:clientOrganization')}
                  </InputLabel>
                  <Select
                    value={organization}
                    onChange={this.handleOrganizationChange}
                    name="organization"
                    input={<Input name="organization" id="organization" />}
                    MenuProps={MenuProps}
                  >
                    {organizations.map(org => (
                      <MenuItem key={org.id} value={org}>
                        {org ? org.short_name : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <InventoryLocationsTable
                  organization={organization}
                  selectedLocations={this.handleLocations}
                />
                {!isEmpty(errors.locations) ? (
                  <FormHelperText error={!isEmpty(errors.locations)}>
                    {locationsHelperText}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.container}
              alignItems="flax-start"
              justify="flex-start"
              spacing={2}
            >
              <Grid item xs={4}>
                <Subheading>
                  {i18next.t(
                    'inventory_web_atoir:inventoryWorkPerformerInformationSubtitle',
                  )}
                </Subheading>
              </Grid>
              <Grid item xs={11}>
                <Grid
                  container
                  alignItems="flex-end"
                  justify="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      error={!isEmpty(errors.desiredPeriod)}
                      aria-describedby="name-error-text"
                    >
                      <FormLabel className={classes.formControlLabel}>
                        {i18next.t(
                          'inventory_web_atoir:inventoryDesiredPeriod',
                        )}
                        :
                      </FormLabel>
                      <FormGroup>
                        <Grid container spacing={1}>
                          <Grid item xs={5}>
                            <DateInput
                              value={desiredPeriodBegin}
                              name="desiredPeriodBegin"
                              handleChange={this.handleDateChange(
                                'desiredPeriodBegin',
                              )}
                              requiredValue={false}
                              label={i18next.t(
                                'inventory_web_atoir:inventoryDesiredPeriodBegin',
                              )}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <DateInput
                              value={desiredPeriodEnd}
                              name="desiredPeriodEnd"
                              handleChange={this.handleDateChange(
                                'desiredPeriodEnd',
                              )}
                              requiredValue={false}
                              label={i18next.t(
                                'inventory_web_atoir:inventoryDesiredPeriodEnd',
                              )}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                      <div className={classes.divErrorOut}>
                        <FormHelperText
                          error={!isEmpty(errors.desiredPeriod)}
                          id="name-error-text"
                        >
                          {periodHelperText}
                        </FormHelperText>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid container alignItems="flex-end" md={8} xs={12}>
                    {isFetchingOrganizationStaff ? (
                      <Loader />
                    ) : (
                      <FormControl
                        fullWidth
                        required
                        error={!isEmpty(errors.clientResponsible)}
                        className={classes.formControl}
                        area-desxribedby="client-responsible-text"
                      >
                        <InputLabel htmlFor="client-responsible">
                          {i18next.t(
                            'inventory_web_atoir:inventoryResponsiblePersonFullName',
                          )}
                        </InputLabel>
                        <Select
                          value={clientResponsible}
                          onChange={this.handleClientResponsibleChange}
                          name="clientResponsible"
                          input={
                            <Input
                              name="clientResponsible"
                              id="client-responsible"
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {organizationStaff.map(s => (
                            <MenuItem key={s.id} value={s}>
                              {`${s.last_name} ${s.first_name} ${
                                s.middle_name ? s.middle_name : ''
                              }`}
                            </MenuItem>
                          ))}
                        </Select>
                        {!isEmpty(errors.clientResponsible) ? (
                          <FormHelperText
                            error={!isEmpty(errors.clientResponsible)}
                            id="client-responsible-text"
                          >
                            {clientResponsibleHelperText}
                          </FormHelperText>
                        ) : null}
                        <div className={classes.divErrorOut} />
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  id="clientComment"
                  label={i18next.t('inventory_web_atoir:inventoryComments')}
                  placeholder={i18next.t(
                    'inventory_web_atoir:inventoryCommentsPlaceholder',
                  )}
                  multiline
                  fullWidth
                  margin="normal"
                  onChange={this.handleTextChange('clientComment')}
                  value={clientComment}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormComponent>
        <SimpleDialog
          isOpen={isConfirmCancelDialogOpen}
          handleClose={this.toggle('isConfirmCancelDialogOpen', false)}
          handlePositive={this.goBack}
          handleNegative={this.toggle('isConfirmCancelDialogOpen', false)}
          positiveText={i18next.t('shared_web_atoir:close')}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          title=""
          content={i18next.t(
            'inventory_web_atoir:closeAddProjectFormConfirmationText',
          )}
        />
      </div>
    )
  }
}

AddInventoryProjectComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchManagerOrHigherOrganizations: PropTypes.func.isRequired,
  isFetchingOrganizations: PropTypes.bool.isRequired,
  organizations: PropTypes.array.isRequired,
  fetchOrganizationStaff: PropTypes.func.isRequired,
  isFetchingOrganizationStaff: PropTypes.bool.isRequired,
  organizationStaff: PropTypes.array.isRequired,
  createInventoryProject: PropTypes.func.isRequired,
  isCreatingInventoryProject: PropTypes.bool.isRequired,
  createProjectErrors: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(
  AddInventoryProjectComponent,
)
