import React from 'react'
import { withRouter, useRouteMatch } from 'react-router-dom'

import {
  appActions,
  appOptions,
  useAppDispatch,
  useAppState,
} from '../appContext'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import Input from '@material-ui/core/Input'
import styled from 'styled-components'
import {
  borders,
  display,
  flexbox,
  palette,
  spacing,
  positions,
  sizing,
} from '@material-ui/system'
import i18next from '../../../i18n/i18n'

const searchResultPath = '/search_result'

const Box = styled.div`${palette}${spacing}${positions}${borders}${flexbox}${display}${sizing}`

function GlobalSearchComponent({ history }) {
  const { globalSearchShow, drawerMode } = useAppState()
  const dispatch = useAppDispatch()
  const inputRef = React.useRef()

  const [searchString, setSearchString] = React.useState('')

  React.useEffect(() => {
    if (!globalSearchShow) {
      dispatch(appActions.setGlobalSearchString(null))
      setSearchString('')
    }
    if (globalSearchShow) {
      inputRef.current.focus()
    }
  }, [globalSearchShow])

  const goBackAvailable = useRouteMatch({
    path: searchResultPath,
    strict: true,
    sensitive: true,
  })

  const closeSearch = () => {
    dispatch(appActions.setGlobalSearchShow(false))
  }

  const onSubmit = event => {
    event.preventDefault()
    dispatch(appActions.setGlobalSearchString(searchString))
    if (!goBackAvailable) {
      history.push(searchResultPath)
    }
  }

  const inputHandler = event => {
    setSearchString(event.target.value)
  }

  const goBack = () => {
    dispatch(appActions.setGlobalSearchShow(false))
    history.goBack()
  }

  return (
    <React.Fragment>
      {globalSearchShow && (
        <Box
          bgcolor="#f5f5f5"
          height="100%"
          position="absolute"
          right={0}
          left={0}
          zIndex={9999}
          display="flex"
        >
          <Box
            width="100%"
            flex={1}
            display="flex"
            pl={drawerMode === appOptions.drawerMode.collapsible && 7}
            alignItems="center"
          >
            <Box minWidth={50}>
              {goBackAvailable && (
                <IconButton
                  aria-haspopup="true"
                  data-cy="ExitToAppBtn"
                  onClick={goBack}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            </Box>
            <Box flex={1}>
              <form onSubmit={onSubmit}>
                <Input
                  inputRef={inputRef}
                  fullWidth
                  disableUnderline
                  placeholder={i18next.t('shared_web_atoir:searchPlaceholder')}
                  value={searchString}
                  id="search-string"
                  onChange={inputHandler}
                  margin="normal"
                />
              </form>
            </Box>
            <IconButton
              aria-haspopup="true"
              data-cy="ExitToAppBtn"
              onClick={closeSearch}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

export default withRouter(GlobalSearchComponent)
