import XRegExp from 'xregexp/lib/index'

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /\S@\S/
  return re.test(String(email).toLowerCase())
}

export function validateAlphabetAndNumbers(string) {
  const regex = XRegExp('\\pL+$', 'i')
  return XRegExp.replace(string, regex, '').length === 0
}

export const validateTemplateParamScheme = (incomes, scheme) => {
  if (scheme[incomes[0]] === true) return true
  if (scheme[incomes[0]] === undefined) return false
  return validateTemplateParamScheme(incomes.slice(1), scheme[incomes[0]])
}
