import { takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/recurringCallsActions'
import * as constants from '../constants/actionTypes/recurringCalls'
import * as api from './api'
import { createDefaultSaga } from '../helpers/api'

export function* watchGetRecurringCallsSaga() {
  yield takeLatest(
    constants.GET_ORGANIZATION_RECURRING_CALLS_REQUEST,
    createDefaultSaga(
      payload => api.getRecurringCallsRequestApi(payload),
      result => actions.getOrganizationRecurringCallsSuccess(result.calls),
      code => actions.getOrganizationRecurringCallsFailure(code),
      true,
    ),
  )
}

export function* watchGetRecurringCallsCountSaga() {
  yield takeLatest(
    constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_REQUEST,
    createDefaultSaga(
      payload => api.getRecurringCallsRequestCountApi(payload),
      result => actions.getOrganizationRecurringCallsCountSuccess(result.count),
      code => actions.getOrganizationRecurringCallsCountFailure(code),
      true,
    ),
  )
}
