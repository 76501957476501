import React from 'react'
import { genericHashLink } from 'react-router-hash-link'
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx'
import MuiLink from '@material-ui/core/Link'
import { useLocation } from 'react-router-dom'

const MyHashLink = props => genericHashLink(props, MuiLink)

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
function Link(props) {
  const {
    activeClassName = 'active',
    className: classNameProps,
    href,
    innerRef,
    role: roleProp,
    ...other
  } = props

  const url = useLocation()

  const className = clsx(classNameProps, {
    [activeClassName]: url === href && activeClassName,
  })

  // catch role passed from ButtonBase. This is definitely a link
  const role = roleProp === 'button' ? undefined : roleProp

  return (
    <MyHashLink
      className={className}
      href={href}
      ref={innerRef}
      role={role}
      {...other}
    />
  )
}

export default React.forwardRef((props, ref) => (
  <Link {...props} innerRef={ref} />
))
