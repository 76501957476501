import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core'

const styles = {
  root: {},
  widthRestriction: {
    width: 80,
  },
}

function DateCell({ classes, children }) {
  return (
    <TableCell className={classNames(classes.widthRestriction, classes.root)}>
      {children}
    </TableCell>
  )
}

DateCell.propTypes = {
  children: PropTypes.string.isRequired,
}

export default withStyles(styles)(DateCell)
