import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

function getRandomColor() {
  const letters = '0123456789ABCDEF'.split('')
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.round(Math.random() * 15)]
  }
  return color
}

const styles = {
  randomlyColoredAvatar: {
    color: '#fff',
    backgroundColor: getRandomColor(),
    width: 150,
    height: 150,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
}

const LetterAvatar = props => (
  <div className={props.classes.row}>
    <Avatar className={props.classes.randomlyColoredAvatar}>
      {props.letters}
    </Avatar>
  </div>
)

LetterAvatar.propTypes = {
  letters: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LetterAvatar)
