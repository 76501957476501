import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import _ from 'lodash'
import i18next from '../../../../../../../i18n/i18n'
import { getUserNameWithInitials } from '../../../../../../../helpers/userHelper'

import ContainedButton from '../../../../../../../widgets/Buttons/ContainedButton'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// actions
import {
  deleteOrderWorkCompletionFileRequest,
  getServiceCallOrderDocumentLinkRequest,
} from '../../../../../../../actions/serviceDesk'
import * as permissionResolvers from '../../../../../../../helpers/callPermissionsResolver'
import ClickWrapper from '../ClickWrapper'

const styles = theme => ({
  margin: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

class OrderDocuments extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  previewFile = file => {
    const {
      props: { getPreviewPath, order },
    } = this
    getPreviewPath({ orderId: order.id, documentId: file.id })
  }

  render() {
    const {
      props: {
        classes,
        order,
        canUpdate,
        commonOrderPermissions,
        canView,
        orderFile,
        anchorElAddDocumentMenu,
        handleMenuOpen,
        handleMenuClose,
        handleAttach,
        deleteFile,
      },
    } = this
    const file =
      order.type_order === 'repair'
        ? _.find(orderFile, f => f.file_type === 'act_repair') || null
        : order.order_result === 'writeoff'
        ? _.find(orderFile, f => f.file_type === 'act_repair')
        : order.order_result === 'complete'
        ? _.find(orderFile, f => f.file_type === 'act_repair') || null
        : _.find(orderFile, f => f.file_type === 'act_diagnostic') || null
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.margin}
      >
        <Grid item>
          <Typography variant="h6">
            {isEmpty(file)
              ? `${i18next.t('service_desk_web_atoir:document')} *`
              : file.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {isEmpty(file)
              ? i18next.t('service_desk_web_atoir:fieldMustBeFilled')
              : `${getUserNameWithInitials(file.OrganizationUser)}
            ${file.OrganizationUser.Organization.short_name}`}
          </Typography>
        </Grid>
        <Grid item>
          <ClickWrapper>
            {((canUpdate && !isEmpty(file)) || (canView && !isEmpty(file))) && (
              <IconButton
                aria-label="More"
                aria-owns={
                  !isEmpty(anchorElAddDocumentMenu) ? 'doc-add-menu' : undefined
                }
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              id="doc-add-menu"
              anchorEl={anchorElAddDocumentMenu}
              open={!isEmpty(anchorElAddDocumentMenu)}
              onClose={handleMenuClose}
            >
              <Fragment>
                {canView && (
                  <MenuItem
                    onClick={event => {
                      event.stopPropagation()
                      this.previewFile(file)
                      handleMenuClose()
                    }}
                  >
                    {i18next.t('service_desk_web_atoir:takeALook')}
                  </MenuItem>
                )}
                {canUpdate && (
                  <MenuItem
                    disabled={
                      !permissionResolvers.canOperateButton(
                        commonOrderPermissions,
                        'orders',
                        'performer',
                      )
                    }
                    onClick={event => {
                      event.stopPropagation()
                      deleteFile({
                        fileId: file.id,
                        orderId: order.id,
                      })
                      handleMenuClose()
                    }}
                  >
                    {i18next.t('shared_web_atoir:delete')}
                  </MenuItem>
                )}
              </Fragment>
            </Menu>
            {canUpdate && isEmpty(file) && (
              <ContainedButton onClick={handleAttach}>
                {i18next.t('service_desk_web_atoir:attach')}
              </ContainedButton>
            )}
          </ClickWrapper>
        </Grid>
      </Grid>
    )
  }
}

OrderDocuments.defaultProps = {
  canUpdate: false,
  canView: false,
  anchorElAddDocumentMenu: null,
}

OrderDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  canUpdate: PropTypes.bool,
  canView: PropTypes.bool,
  orderFile: PropTypes.object.isRequired,
  anchorElAddDocumentMenu: PropTypes.object,
  handleMenuOpen: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleAttach: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  call: state.serviceDesk.request.request,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteFile: deleteOrderWorkCompletionFileRequest,
      getPreviewPath: getServiceCallOrderDocumentLinkRequest,
    },
    dispatch,
  )

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDocuments)),
)
