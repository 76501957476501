import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchInventoryEquipmentFilters } from '../../../../../../actions/inventoryEquipmentActions'
import InventoryEquipmentFiltersToolbar from './InventoryEquipmentFiltersToolbar'

const mapStateToProps = state => ({
  isFetchingFilters: state.inventoryEquipment.isFetchingFilters,
  filters: state.inventoryEquipment.filters,
  fetchFiltersErrors: state.inventoryEquipment.fetchFiltersErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryEquipmentFilters,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InventoryEquipmentFiltersToolbar),
)
