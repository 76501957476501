import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import IconButton from '@material-ui/core/IconButton'
import QuestionMarkIcon from '@material-ui/icons/Info'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

// internationalization
import i18next from '../../../../i18n/i18n'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  card: {
    display: 'flex',
    backgroundColor: 'transparent',
    border: '1.0px solid rgb(66, 155, 213)',
    boxShadow: 'none',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  cover: {
    width: 151,
    height: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
})

class InventoryType extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      open: false,
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const {
      classes,
      checkedType,
      handleInventoryTypeChange,
      handleInventoryKitChange,
      typeTitle,
      typeValue,
      hasOwnTitle,
      inventoryKit,
      descriptionTitle,
      description,
    } = this.props

    return (
      <div>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div className={classes.details}>
              <FormControlLabel
                value={typeValue}
                control={
                  <Radio
                    checked={checkedType}
                    onChange={() => handleInventoryTypeChange(typeValue)}
                    color="primary"
                  />
                }
                label={typeTitle}
              />
              <div>
                <FormControlLabel
                  disabled={!checkedType}
                  control={
                    <Checkbox
                      checked={inventoryKit}
                      onChange={(event, checked) =>
                        handleInventoryKitChange(checked)
                      }
                      color="primary"
                    />
                  }
                  label={hasOwnTitle}
                />
                <IconButton
                  onClick={this.handleClickOpen}
                  color="primary"
                  className={classes.button}
                  aria-label="Add to shopping cart"
                >
                  <QuestionMarkIcon />
                </IconButton>
              </div>
            </div>
          </CardContent>
        </Card>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18next.t(descriptionTitle)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18next.t(description)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>
              {i18next.t('shared_web_atoir:close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

InventoryType.propTypes = {
  classes: PropTypes.object.isRequired,
  checkedType: PropTypes.bool.isRequired,
  handleInventoryTypeChange: PropTypes.func.isRequired,
  handleInventoryKitChange: PropTypes.func.isRequired,
  typeTitle: PropTypes.string.isRequired,
  typeValue: PropTypes.string.isRequired,
  hasOwnTitle: PropTypes.string.isRequired,
  inventoryKit: PropTypes.bool.isRequired,
  descriptionTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default withStyles(styles, { withTheme: true })(InventoryType)
