import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ContractProfile from './ContractProfile'
import {
  fetchContractRequest,
  editContractRequest,
  editContractResponsibleRequest,
  fetchContractPermissionsRequest,
} from '../../../../actions/serviceDeskContractsActions'
import {
  clearNavigationDrawer,
  setNavigationDrawer,
  showDialogWithOk,
} from '../../../../actions/appActions'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../../actions/utilsActions'

const mapStateToProps = state => ({
  contract: state.serviceDeskContracts.contract.contract,
  isFetching: state.serviceDeskContracts.contract.isFetching,
  error: state.serviceDeskContracts.contract.error,
  permissions: state.serviceDeskContracts.permissions.permissions,
  isFetchingPermissions: state.serviceDeskContracts.permissions.isFetching,
  permissionsError: state.serviceDeskContracts.permissions.error,
  isEditingContract: state.serviceDeskContracts.edit.isFetching,
  editContractError: state.serviceDeskContracts.edit.error,
  isFetchingEditResponsible:
    state.serviceDeskContracts.editResponsible.isFetching,
  editResponsibleError: state.serviceDeskContracts.editResponsible.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractPermissionsRequest,
      fetchContract: fetchContractRequest,
      editContract: editContractRequest,
      editContractResponsible: editContractResponsibleRequest,
      showDialogLoader,
      hideDialogLoader,
      setNavigationDrawer,
      clearNavigationDrawer,
      showDialogWithOk,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractProfile),
)
