import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import MailOutline from '@material-ui/icons/MailOutline'
import DesktopWindowsOutlined from '@material-ui/icons/DesktopWindowsOutlined'
import PhonelinkRingOutlined from '@material-ui/icons/PhonelinkRingOutlined'
import Grid from '@material-ui/core/Grid'
import NotificationSetting from './NotificationSetting'
import i18n from '../../../../../i18n/i18n'

const styles = {
  userNotificationCheckboxes: {
    textAlign: 'right',
  },
  userNotificationKey: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  userNotificationRowContainer: {
    padding: '0 24px',
  },
  userSetingsContainer: {
    width: '100%',
  },
  paddingLeftZero: {
    paddingLeft: 0,
  },
}

class Settings extends Component {
  componentDidMount() {
    const { fetchNotificationSettings } = this.props
    fetchNotificationSettings()
  }

  onChange = key => (type, value) => {
    const { setSetting } = this.props
    setSetting([{ key, type, value }])
  }

  onChangeAll = type => value => {
    const { setSetting, settings } = this.props
    const enabled =
      value !== true && value !== false
        ? !Object.keys(settings).every(
            k => _.find(settings[k], s => s.name === `${k}_${type}`).enabled,
          )
        : value
    setSetting(
      Object.keys(settings).map(key => ({ key, type, value: enabled })),
    )
  }

  render() {
    const { settings, classes } = this.props
    return (
      <div className={classes.userSetingsContainer}>
        <Grid container className={classes.userNotificationRowContainer}>
          <Grid item sm={8}>
            <Typography
              color="textSecondary"
              className={classes.userNotificationKey}
              variant="body1"
            >
              <Checkbox
                className={classes.paddingLeftZero}
                checked={_.flatten(Object.values(settings)).every(
                  s => s.enabled,
                )}
                onChange={e => {
                  this.onChangeAll('email')(e.target.checked)
                  this.onChangeAll('browser')(e.target.checked)
                  this.onChangeAll('mobile')(e.target.checked)
                }}
                value="checkedA"
                color="primary"
              />
              {i18n.t('user_profile_web_atoir:checkAllNotifications')}
            </Typography>
          </Grid>
          <Grid item sm={4} className={classes.userNotificationCheckboxes}>
            {['email', 'browser', 'mobile'].map(type => (
              <Tooltip
                title={i18n.t(
                  `user_profile_web_atoir:${type}_notification_tooltip`,
                )}
                placement="bottom"
              >
                <IconButton
                  color={
                    Object.keys(settings).every(
                      k =>
                        _.find(settings[k], s => s.name === `${k}_${type}`)
                          .enabled,
                    )
                      ? 'primary'
                      : 'default'
                  }
                  className={classes.button}
                  onClick={this.onChangeAll(type)}
                  aria-label="add an alarm"
                >
                  {type === 'email' ? <MailOutline /> : null}
                  {type === 'browser' ? <DesktopWindowsOutlined /> : null}
                  {type === 'mobile' ? <PhonelinkRingOutlined /> : null}
                </IconButton>
              </Tooltip>
            ))}
          </Grid>
        </Grid>
        {Object.keys(settings).map(settingKey => (
          <NotificationSetting
            classes={classes}
            key={settingKey}
            settingKey={settingKey}
            onChange={this.onChange(settingKey)}
            settings={settings[settingKey]}
          />
        ))}
      </div>
    )
  }
}

Settings.propTypes = {
  fetchNotificationSettings: PropTypes.func.isRequired,
}

export default withStyles(styles)(Settings)
