import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'

import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import OrganizationProfileContainer from '../../../containers/Organizations/OrganizationProfileContainer'
import EquipmentRouter from './EquipmentRouter'
import UserProfileContainer from '../../../containers/User/UserProfileContainer'
import ImportEquipments from '../../Equipment/ImportEquipments'
import NoMatchComponent from '../../NoMatchComponent'

const OrganizationRouterTabbed = ({ match: { path: __path } }) => (
  <Switch>
    <WrapperRoute
      showHeader
      showTitleOutOfPaper
      title={i18next.t('equipment_web_atoir:loadByEquipList')}
      path={`${__path}/import_equipments/:backTo*`}
      component={ImportEquipments}
    />
    <WrapperRoute
      path={`${__path}/equipments`}
      component={EquipmentRouter}
      crumbOff
    />
    <WrapperRoute
      title={i18next.t('user_profile_web_atoir:userProfileCrumbTitle')}
      path={`${__path}/staff_worker_profile/:id`}
      component={() => <UserProfileContainer wentFromOrganization />}
    />
    <WrapperRoute
      path={`${__path}`}
      showTitleOutOfPaper
      showHeader
      component={OrganizationProfileContainer}
    />
  </Switch>
)

const OrganizationRouterTabbedWrapper = ({ match }) => {
  const { path } = match
  return (
    <Fragment>
      <Switch>
        <WrapperRoute
          path={`${path}/:tab`}
          title="Организация"
          component={OrganizationRouterTabbed}
        />
        <Route component={NoMatchComponent} />
      </Switch>
    </Fragment>
  )
}

export default OrganizationRouterTabbedWrapper
