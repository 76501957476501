import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'
import namespaces from './namespaces'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'ru',
    whitelist: ['ru'],
    load: 'languageOnly',
    backend: {
      crossDomain: true,
      loadPath:
        process.env.NODE_ENV !== 'development'
          ? '/locales/{{ns}}/{{lng}}'
          : 'http://localhost:3001/locales/{{ns}}/{{lng}}',
      referenceLng: 'ru',
    },
    // have a common namespace used around the full app
    ns: namespaces,
    defaultNS: 'frontend',

    debug: false,

    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      wait: true,
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
