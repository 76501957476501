import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TrustedOrganizationsFilters from './TrustedOrganizationsFilters'
import {
  applyFilters,
  changeOrganization,
  changeSide,
} from '../../../actions/trustedOrganizationActions'

const mapStateToProps = state => ({
  side: state.trustedOrganizations.side,
  status: state.trustedOrganizations.status,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeSide,
      applyFilters,
      changeOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrustedOrganizationsFilters),
)
