import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles/index'

import { inventoryLocationStringify } from '../../../../../../helpers/parserHelper'
import i18next from '../../../../../../i18n/i18n'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  section: {
    marginBottom: '40px',
  },
  linkText: {
    color: theme.palette.textSecondary.main,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
  sectionContainer: {
    marginBottom: '20px',
  },
})

class ConfirmInfo extends Component {
  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  render() {
    const {
      props: { order, orders, project },
    } = this

    if (isEmpty(orders) || isEmpty(order) || isEmpty(project)) {
      return false
    }

    const locationId = order.project_location_id
    const location = orders.find(_order => locationId === _order.id)
    const orderLocation =
      `${i18next.t('inventory_web_atoir:floorFilter')}: ${order.floor} ` +
      `${i18next.t('inventory_web_atoir:cabinetFilter')}: ${order.office}`

    return (
      <Fragment>
        <Typography variant="subheading" gutterBottom align="left">
          {inventoryLocationStringify(location.OL)}
        </Typography>
        <Typography align="left" variant="body2">
          {orderLocation.toUpperCase()}
        </Typography>
        {this.getDividerGrid()}
        <Typography variant="subheading" align="left">
          {isEmpty(project.Performer) ? '' : project.Performer.full_name}
        </Typography>
        <Typography align="left">
          {!isEmpty(project.Performer.OrganizationLocations) &&
            inventoryLocationStringify(
              project.Performer.OrganizationLocations[0],
            )}
        </Typography>
        {this.getDividerGrid()}
      </Fragment>
    )
  }
}

export default withStyles(styles)(ConfirmInfo)
