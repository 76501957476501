import isEmpty from 'is-empty'
import i18n from '../i18n/i18n'
import { dateFormat } from './dateHelper'

export const omitByEmpty = d =>
  _.omitBy(d, el => el === undefined || el === '' || el === null)

export const popLastSections = (a, n = 1) =>
  a
    .split('/')
    .slice(0, a.split('/').length - n)
    .join('/')

export const data = d => d

const transformCamelToSnake = text =>
  text.replace(/([A-Z])/g, '_$1').toLowerCase()

export const transformCamelCaseObjectCaseToSnake = obj =>
  _.mapKeys(obj, (v, k) => transformCamelToSnake(k))

function msToTimeNumber(duration) {
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
  return hours + minutes / 60
}

const defaultDatasetOptions = {
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
}

export const convertDataToCreatedChartJsData = obj => {
  if (isEmpty(obj.payload.chart_info)) {
    return obj
  }

  const copyObj = Object.assign({}, obj)
  const chartInfo = copyObj.payload.chart_info

  const colorCreatedChart = 'rgba(25,118,210,1)'
  const colorCreatedChartBg = 'rgba(25,118,210,0.4)'
  const colorClosedChart = 'rgba(255,92,0,1)'
  const colorClosedChartBg = 'rgba(255,92,0,0.4)'

  const chartOptions = {
    datasets: [
      Object.assign({}, defaultDatasetOptions, {
        label: i18n.t('inner_calls_web_atoir:created'),
        backgroundColor: colorCreatedChartBg,
        borderColor: colorCreatedChart,
        pointHoverBackgroundCollimitEngineeror: colorCreatedChart,
        pointHoverBorderColor: colorCreatedChart,
        pointBorderColor: colorCreatedChart,
      }),
      Object.assign({}, defaultDatasetOptions, {
        label: i18n.t('inner_calls_web_atoir:ready'),
        backgroundColor: colorClosedChartBg,
        borderColor: colorClosedChart,
        pointHoverBackgroundColor: colorClosedChart,
        pointHoverBorderColor: colorClosedChart,
        pointBorderColor: colorClosedChart,
      }),
    ],
  }
  const labels = []
  const dataCreated = []
  const dataClosed = []

  chartInfo.forEach(item => {
    labels.push(dateFormat(new Date(item.start).getTime()))
    dataCreated.push(item.created_sum)
    dataClosed.push(item.closed_sum)
  })

  chartOptions.labels = labels
  chartOptions.datasets[0].data = dataCreated
  chartOptions.datasets[1].data = dataClosed

  // if one point centers it
  if (chartInfo.length === 1) {
    chartOptions.datasets[0].pointRadius = 6
    chartOptions.datasets[0].data.push(null)
    chartOptions.datasets[0].data.unshift(null)

    chartOptions.datasets[1].pointRadius = 6
    chartOptions.datasets[1].data.push(null)
    chartOptions.datasets[1].data.unshift(null)

    chartOptions.labels.push('')
    chartOptions.labels.unshift('')
  }

  copyObj.payload.chart_info = chartOptions

  return copyObj
}

export const convertDataToOptionChartJsData = (obj, option = null) => {
  if (isEmpty(obj.payload.chart_info) && option) {
    return obj
  }

  const copyObj = Object.assign({}, obj)
  const chartInfo = copyObj.payload.chart_info

  const colorChart = 'rgba(25,118,210,1)'
  const colorChartBg = 'rgba(25,118,210,1)'

  const chartOptions = {
    datasets: [
      Object.assign({}, defaultDatasetOptions, {
        backgroundColor: colorChartBg,
        borderColor: colorChart,
        pointHoverBackgroundColor: colorChart,
        pointHoverBorderColor: colorChart,
        pointBorderColor: colorChart,
      }),
    ],
  }

  if (option === 'average') {
    chartOptions.datasets[0].label = i18n.t(
      'inner_calls_web_atoir:work_timeSortBy',
    )
  }

  if (option === 'closedByEngineer') {
    chartOptions.datasets[0].label = i18n.t('inner_calls_web_atoir:ready')
  }

  const labels = []
  const dataset = []

  chartInfo.forEach(item => {
    labels.push(`${item.creator_last_name} ${item.creator_first_name[0]}.`)
    if (option === 'average') {
      dataset.push(msToTimeNumber(item.average_work_time))
    }
    if (option === 'closedByEngineer') {
      dataset.push(item.closed_sum)
    }
  })

  chartOptions.labels = labels
  chartOptions.datasets[0].data = dataset

  copyObj.payload.chart_info = chartOptions

  return copyObj
}

export const pipe = (...functions) => value => {
  return functions.reduce((currentValue, currentFunction) => {
    return currentFunction(currentValue)
  }, value)
}

export function autoCompleteDataConverterPiped(idValue, path) {
  return function(_data) {
    const out = { ..._data }
    if (_data && idValue) {
      out[idValue] =
        _.get(_data, path ? `${idValue}.${path}` : `${idValue}.${idValue}`) ||
        null
    }
    return out
  }
}

function removeEmptyObjects(obj) {
  if (_.isEmpty(obj)) {
    return {}
  }
  return _(obj)
    .pickBy(d => d && Object.getPrototypeOf(d) === Object.prototype)
    .mapValues(removeEmptyObjects)
    .omitBy(_.isEmpty)
    .assign(
      _.omitBy(obj, d => d && Object.getPrototypeOf(d) === Object.prototype),
    )
    .value()
}

const removeObjectsWithNull = obj =>
  _(obj)
    .pickBy(d => d && Object.getPrototypeOf(d) === Object.prototype)
    .mapValues(removeObjectsWithNull)
    .assign(
      _.omitBy(obj, d => d && Object.getPrototypeOf(d) === Object.prototype),
    )
    .omitBy(_.isNil)
    .value()

const compactObjectsStringOptions = obj =>
  _(obj)
    .pickBy(d => d && Object.getPrototypeOf(d) === Object.prototype)
    .mapValues(compactObjectsStringOptions)
    .assign(
      _.omitBy(obj, d => d && Object.getPrototypeOf(d) === Object.prototype),
    )
    .mapValues(option =>
      typeof option === 'string'
        ? pipe(
            str => str.trim(),
            str => (str.length > 0 ? str : null),
          )(option)
        : option,
    )
    .value()

export const compactObject = value =>
  pipe(
    compactObjectsStringOptions,
    removeObjectsWithNull,
    removeEmptyObjects,
  )(value)

export const compactObjectOnEdit = value =>
  pipe(compactObjectsStringOptions, removeEmptyObjects)(value)
