import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../i18n/i18n'
import { URL } from '../../../../../middlewares/api'

// material ui
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

// components
import RequestDocs from '../RequestDocs'
import RequestPhotos from '../RequestPhotos'

const styles = theme => ({
  informationRoot: {
    textAlign: 'left',
    padding: 12,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  editIconRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blockTitle: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 'normal',
  },
  blockSubtitle: {
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 'normal',
  },
  expansionPanel: {
    '&::before': {
      height: '0px',
      backgroundColor: 'transparent',
    },
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
    marginBottom: theme.spacing(2),
  },
  expansionPanelSummary: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  expansionPanelDetails: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
})

class CallAttachments extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  downloadPhotoUrl = photo => {
    const {
      props: { callCreationDate },
    } = this
    const year = new Date(callCreationDate).getFullYear()
    return `${URL}/downloads/service_desc/${year}/${photo.service_call_id}/images/${photo.id}.${photo.extension}`
  }

  render() {
    const {
      props: {
        classes,
        callId,
        canEdit,
        isFetchingDocs,
        docs,
        uploadDoc,
        isFetchingPhotos,
        photos,
        uploadPhoto,
        downloadPhoto,
        deletePhoto,
      },
      downloadPhotoUrl,
    } = this
    return (
      <Fragment>
        <ExpansionPanel
          id="call-detailed-info"
          elevation={0}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expansionPanelSummary}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.blockTitle}>
                  {i18next.t('service_desk_web_atoir:detailedInfo')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.blockSubtitle}>
                  {`${i18next.t('service_desk_web_atoir:documents')} (${
                    docs.length
                  })
              ${i18next.t('service_desk_web_atoir:photos')} (${photos.length})`}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <RequestDocs
                  canEdit={canEdit}
                  callId={callId}
                  isFetchingDocs={isFetchingDocs}
                  docs={docs}
                  upload={uploadDoc}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <RequestPhotos
                  canEdit={canEdit}
                  callId={callId}
                  isFetchingPhotos={isFetchingPhotos}
                  photos={photos}
                  formDataName="image"
                  upload={uploadPhoto}
                  download={downloadPhoto}
                  delete={deletePhoto}
                  downloadUrl={downloadPhotoUrl}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
}

CallAttachments.propTypes = {
  callId: PropTypes.string.isRequired,
  callCreationDate: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isFetchingDocs: PropTypes.bool.isRequired,
  docs: PropTypes.array.isRequired,
  uploadDoc: PropTypes.func.isRequired,
  isFetchingPhotos: PropTypes.bool.isRequired,
  photos: PropTypes.array.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
  downloadPhoto: PropTypes.func.isRequired,
  deletePhoto: PropTypes.func.isRequired,
}

export default withStyles(styles)(CallAttachments)
