import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input/Input'
import MaskedInput from 'react-text-mask'

function PhoneMasked(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      keepCharPositions
      ref={inputRef}
      mask={[
        '+',
        '7',
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  )
}

const FormControlTextPhone = props => {
  const { onChange, value, label, ...others } = props
  return (
    <FormControl fullWidth {...others}>
      <InputLabel shrink={value && value !== ''} htmlFor={label}>
        {label}
      </InputLabel>
      <Input
        id={label}
        value={value}
        onChange={onChange}
        inputComponent={PhoneMasked}
      />
    </FormControl>
  )
}

FormControlTextPhone.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
}

export default FormControlTextPhone
