import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import isEmpty from 'is-empty'

import { removeMaskCharacters } from '../../helpers/phoneStringHelper'

// internationalization
import i18next from '../../i18n/i18n'

// styles
import '../../styles/components/User.css'

// components
import LetterAvatar from './LetterAvatarComponent'
import UserAvatar from './UserAvatarComponent'
import Loader from '../Loader'
import FormComponent from '../../widgets/FormComponent/index'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import BreadCrumb from '../App/routing/BreadCrumb'

import { popLastSections } from '../../helpers/dataHelper'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  formRoot: {
    paddingRight: 0,
    padding: 12,
  },
  actionsRoot: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  profileLogoBtn: {
    textAlign: 'center',
  },
  avatarBtnArea: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(10),
  },
})

const phoneMask = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

class EditUserProfileComponent extends Component {
  state = {
    user: {},
    errors: {},
  }

  componentDidMount() {
    const { fetchUser } = this.props
    fetchUser()
  }

  componentWillUnmount() {
    this.props.clearValidationErrors()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetchingUser !== this.props.isFetchingUser &&
      this.props.isFetchingUser === false &&
      !isEmpty(this.props.user)
    ) {
      const { user } = this.props
      if (!user.phone) {
        user.phone = ''
      } else {
        user.phone = `+${user.phone}`
      }
      this.setState({
        user,
      })
    }

    if (
      prevProps.isUpdatingUser !== this.props.isUpdatingUser &&
      this.props.isUpdatingUser === false &&
      isEmpty(this.props.validationErrors)
    ) {
      this.props.history.goBack()
    }

    if (
      prevProps.newAvatar !== this.props.newAvatar &&
      !isEmpty(this.props.newAvatar)
    ) {
      const { updateUserAvatar } = this.props
      const data = {
        avatar: this.props.newAvatar,
      }
      updateUserAvatar(data)
    }

    if (
      prevProps.isUpdatingUserAvatar !== this.props.isUpdatingUserAvatar &&
      this.props.isUpdatingUserAvatar === false
    ) {
      this.setState(state => ({
        user: {
          ...state.user,
          avatar: this.props.newAvatar,
        },
      }))
    }
  }

  handleTextChange = field => event => {
    const { value } = event.target
    this.setState(state => ({
      user: {
        ...state.user,
        [field]: value,
      },
    }))
  }

  handleSubmitForm = () => {
    const { updateUser } = this
    updateUser()
  }

  updateUser = () => {
    const { firstName, lastName, middleName, timezone, phone } = this.state.user
    let phoneStr = ''
    if (!isEmpty(phone)) {
      phoneStr = removeMaskCharacters(phone)
    }
    const emptyErrors = this.validateEmptyFields(
      firstName.trim(),
      lastName.trim(),
      phoneStr.replace(/\s/g, '').length,
    )

    if (!isEmpty(emptyErrors)) {
      this.setState({
        errors: emptyErrors,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const { updateUser } = this.props
    const data = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      middle_name: middleName.trim(),
      timezone,
      phone: phoneStr.trim() && phoneStr !== '' ? phoneStr.trim() : null,
    }

    updateUser(data)
  }

  validateEmptyFields = (firstName, lastName, phoneLength) => {
    const errors = {}
    if (isEmpty(firstName)) {
      errors.firstName = i18next.t(
        'validation_web_atoir:firstNameShouldBeFilled',
      )
    }
    if (isEmpty(lastName)) {
      errors.lastName = i18next.t('validation_web_atoir:lastNameShouldBeFilled')
    }
    if (phoneLength >= 1 && phoneLength < 10) {
      errors.phone = i18next.t('validation_web_atoir:incompletePhoneNumber')
    }
    return errors
  }

  render() {
    const {
      classes,
      isFetchingUser,
      isUpdatingUser,
      validationErrors,
      match: { url },
    } = this.props
    const { errors, user } = this.state

    const lastNameHelperText = !isEmpty(errors.lastName)
      ? errors.lastName
      : !isEmpty(validationErrors.lastName)
      ? validationErrors.lastName[0]
      : ''

    const firstNameHelperText = !isEmpty(errors.firstName)
      ? errors.firstName
      : !isEmpty(validationErrors.firstName)
      ? validationErrors.firstName[0]
      : ''

    const phoneHelperText = !isEmpty(errors.phone)
      ? errors.phone
      : !isEmpty(validationErrors.phone)
      ? validationErrors.phone[0]
      : ''

    return (
      <Fragment>
        {isFetchingUser || isEmpty(user) ? (
          <Loader />
        ) : (
          <div>
            <BreadCrumb to={popLastSections(url, 1)}>
              {i18next.t('user_profile_web_atoir:userProfileCrumbTitle')}
            </BreadCrumb>
            <BreadCrumb to={url}>
              {i18next.t('user_profile_web_atoir:editProfileCrumbTitle')}
            </BreadCrumb>
            <FormComponent
              titleForm={i18next.t(
                'user_profile_web_atoir:editUserProfileTitle',
              )}
              divider
              submitText={i18next.t('shared_web_atoir:saveChanges')}
              isLoading={isUpdatingUser}
              handleSubmitAction={this.handleSubmitForm}
              secondaryButtonsArray={[
                {
                  secondaryText: `${i18next.t('shared_web_atoir:cancel')}`,
                  handleSecondaryAction: this.props.history.goBack,
                },
              ]}
            >
              <Grid container spacing={2}>
                <Grid item md={4} sm={12}>
                  <div>
                    {user.avatar ? (
                      <UserAvatar
                        imageUrl={`/user/avatars/${user.avatar}`}
                        closeHandler={this.deleteUserAvatar}
                        canClose
                      />
                    ) : (
                      <LetterAvatar
                        letters={
                          this.props.user.firstName.charAt(0).toUpperCase() +
                          this.props.user.lastName.charAt(0).toUpperCase()
                        }
                      />
                    )}
                  </div>
                </Grid>
                <Grid item md={8} sm={12}>
                  <FormGroup className={classes.formRoot}>
                    <TextField
                      error={
                        !isEmpty(errors.lastName) ||
                        !isEmpty(validationErrors.lastName)
                      }
                      required
                      id="lastName"
                      label={i18next.t('login_web_atoir:lastName')}
                      placeholder={i18next.t('login_web_atoir:enterLastName')}
                      className={classes.textField}
                      helperText={lastNameHelperText}
                      margin="normal"
                      onChange={this.handleTextChange('lastName')}
                      value={this.state.user.lastName}
                    />
                    <TextField
                      error={
                        !isEmpty(errors.firstName) ||
                        !isEmpty(validationErrors.firstName)
                      }
                      required
                      id="firstName"
                      label={i18next.t('login_web_atoir:firstName')}
                      placeholder={i18next.t('login_web_atoir:enterFirstName')}
                      className={classes.textField}
                      helperText={firstNameHelperText}
                      margin="normal"
                      onChange={this.handleTextChange('firstName')}
                      value={this.state.user.firstName}
                    />
                    <TextField
                      id="middleName"
                      label={i18next.t('login_web_atoir:middleName')}
                      placeholder={i18next.t('login_web_atoir:enterMiddleName')}
                      className={classes.textField}
                      margin="normal"
                      onChange={this.handleTextChange('middleName')}
                      value={this.state.user.middleName}
                    />
                    <MaskedInput
                      value={this.state.user.phone || ''}
                      onChange={this.handleTextChange('phone')}
                      mask={phoneMask}
                      placeholder={i18next.t('login_web_atoir:enterPhone')}
                      placeholderChar={'\u2000'}
                      render={(ref, props) => {
                        return (
                          <FormControl
                            error={
                              !isEmpty(errors.phone) ||
                              !isEmpty(validationErrors.phone)
                            }
                          >
                            <InputLabel htmlFor="personal-phone-number">
                              {i18next.t('login_web_atoir:phone')}
                            </InputLabel>
                            <Input
                              id="personal-phone-number"
                              inputRef={ref}
                              {...props}
                            />
                            <FormHelperText>{phoneHelperText}</FormHelperText>
                          </FormControl>
                        )
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </FormComponent>
          </div>
        )}
      </Fragment>
    )
  }
}

EditUserProfileComponent.propTypes = {
  isFetchingUser: PropTypes.bool.isRequired,
  newAvatar: PropTypes.string.isRequired,
  isUpdatingUserAvatar: PropTypes.bool.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired,
  updateUserAvatar: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditUserProfileComponent)
