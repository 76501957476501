import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import i18n from '../../i18n/i18n'
import Chip from '@material-ui/core/Chip'
import classnames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import TrustedOrganizationsTable from './TrustedOrganizationsTable'
import TrustedOrganizationsFilters from './TrustedOrganizationsFilters'
import TrustAcceptOrDenyDialog from './TrustedOrganizationsTable/TrustAcceptOrDenyDialog'

const styles = theme => ({
  statusChip: {
    minWidth: 120,
    marginRight: 16,
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: 16,
  },
  activeChip: {},
  arrowIcon: {
    fontSize: 16,
    rotate: 180,
    marginRight: 4,
    verticalAlign: 'text-top',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  arrowIconRotated: {
    transform: 'rotate(180deg)',
  },
  listItem: {
    paddingTop: 6,
    paddingBottom: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  analyticButton: {
    textAlign: 'right',
    marginTop: 43,
    marginRight: 15,
  },
})

const statuses = ['all', 'pending', 'accepted', 'rejected']

class TrustedOrganizations extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchTrustsRequest()
    this.props.fetchTrustsCountRequest()
    this.props.setFilterDrawer([<TrustedOrganizationsFilters />])
  }

  componentWillUnmount() {
    this.props.clearFilterDrawer()
  }

  onChangeStatus = status => {
    this.props.changeStatus(status)
  }

  render() {
    const {
      props: { classes, status },
      onChangeStatus,
    } = this
    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          {i18n.t('shared_web_atoir:trustedOrganizations')}
        </Typography>
        {statuses.map(s => (
          <Chip
            onClick={() => onChangeStatus(s)}
            color={status === s ? 'primary' : 'default'}
            className={classnames([
              classes.statusChip,
              status === s ? classes.activeChip : null,
            ])}
            label={`${i18n.t(`trusted_organizations_web_atoir:${s}Status`)}`}
            variant="outlined"
          />
        ))}
        <TrustedOrganizationsTable />
        <TrustAcceptOrDenyDialog />
      </Fragment>
    )
  }
}

TrustedOrganizations.propTypes = {
  fetchTrustsRequest: PropTypes.func.isRequired,
}

export default withStyles(styles)(TrustedOrganizations)
