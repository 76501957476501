/* eslint-disable */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Typography from '@material-ui/core/Typography'
import ChevronRight from '@material-ui/icons/ChevronRight'
import {withStyles} from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase'


const styles = {
  chevron: {
    transition: 'all .3s',
    transform: 'rotate(0.25turn)'
  },
  inverted: {
    transform: 'rotate(-0.25turn)'
  },
}

class LongTextExpander extends Component {
  state = {
    expanded: false,
  }

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }
  render() {
    const {
      state: {
        expanded,
      },
      props: {
        Wrapper,
        WrapperProps,
        children,
        limit,
        classes,
      },
      toggle,
    } = this
    const long = children && children.length > limit
    return (
      <Wrapper {...WrapperProps}>
          {long && !expanded ? children.slice(0, limit) : children}
          {long &&
          <ButtonBase disableFocusRipple disableRipple disableTouchRipple onClick={toggle}>
            <ChevronRight color={'secondary'} className={classnames(expanded ? [classes.inverted, classes.chevron] :
            [classes.chevron])} />
          </ButtonBase>}
      </Wrapper>
    )
  }
}

LongTextExpander.defaultProps = {
  Wrapper: props => <Typography component="pre" className="preBreak">{props.children}</Typography>,
  WrapperProps: {},
  limit: 150,
}

LongTextExpander.propTypes = {
  Wrapper: PropTypes.object,
  limit: PropTypes.number,
  WrapperProps: PropTypes.object,
  children: PropTypes.string.isRequired,
}

export default withStyles(styles)(LongTextExpander)
