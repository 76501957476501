import React, { Component, Fragment } from 'react'
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// constants
import faqConstants from '../../../constants/faqQuestions'
import HighlightedTemplate from '../../../widgets/HighlightedTemplate'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgba(0,0,0,0.54)',
    marginBottom: theme.spacing(2),
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 220,
  },
  input: {
    alignSelf: 'center',
    margin: 0,
    padding: 0,
    fontSize: 13,
    width: 'auto',
  },
  icon: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    color: 'rgba(0,0,0,0.54)',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  inline: {
    display: 'inline',
    wordBreak: 'break-all',
  },
  link: {
    color: theme.palette.primary.main,
  },
})

class FAQ extends Component {
  state = {
    searchValue: '',
    allSelected: false,
    commonSelected: false,
    sdSelected: false,
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleToggle = name => {
    this.setState(state => ({
      [name]: !state[name],
    }))
  }

  render() {
    const {
      props: { classes },
      state: { searchValue, allSelected, commonSelected, sdSelected },
      handleChange,
      handleToggle,
    } = this
    return (
      <Fragment>
        <Typography variant="h4" gutterBottom className={classes.marginBtm}>
          {i18next.t('system_menu_web_atoir:faqReferenceMenuItem')}
        </Typography>
        <div className={classes.searchInput}>
          <SearchIcon className={classes.icon} />
          <InputBase
            id="faq-search-input"
            placeholder={i18next.t('shared_web_atoir:searchPlaceholder')}
            value={searchValue}
            onChange={handleChange('searchValue')}
            className={classes.input}
          />
        </div>
        <div>
          <Chip
            label={i18next.t('faq_web_atoir:faqAllFilter')}
            onClick={() => handleToggle('allSelected')}
            className={classes.chip}
            color={allSelected ? 'primary' : 'default'}
            variant="outlined"
          />
          <Chip
            label={i18next.t('faq_web_atoir:faqCommonFilter')}
            onClick={() => handleToggle('commonSelected')}
            className={classes.chip}
            color={commonSelected ? 'primary' : 'default'}
            variant="outlined"
          />
          <Chip
            label={i18next.t('faq_web_atoir:faqSectionSD')}
            onClick={() => handleToggle('sdSelected')}
            className={classes.chip}
            color={sdSelected ? 'primary' : 'default'}
            variant="outlined"
          />
        </div>
        <div className={classes.root}>
          {faqConstants.map(faq => {
            const includesSearchValue =
              searchValue === ''
                ? true
                : i18next
                    .t(faq.section)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  i18next
                    .t(faq.question)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  i18next
                    .t(faq.answer)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            if (includesSearchValue) {
              return (
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      <HighlightedTemplate searchTemplate={searchValue}>
                        {i18next.t(faq.section)}
                      </HighlightedTemplate>
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      <HighlightedTemplate searchTemplate={searchValue}>
                        {i18next.t(faq.question)}
                      </HighlightedTemplate>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <HighlightedTemplate searchTemplate={searchValue}>
                        {i18next.t(faq.answer)}
                      </HighlightedTemplate>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            }
            return null
          })}
        </div>
      </Fragment>
    )
  }
}

FAQ.propTypes = {}

export default withStyles(styles)(FAQ)
