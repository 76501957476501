import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

// internationalization
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import DropDownList from '../../../../widgets/DropDownList/index'

function PhoneMasked(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      keepCharPositions={false}
      ref={inputRef}
      mask={[
        '+',
        /\d/,
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  )
}

PhoneMasked.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

class EditCreateDepartmentComponent extends React.Component {
  constructor(props) {
    super(props)
    const { name, phone, molUserId, personFunction, phoneAdditional } = props
    this.state = {
      isOrganizationUsersOpen: false,
      name,
      phone: !phone.match(/[+()]/g) ? this.getFormattedPhone(phone) : phone,
      molUserId,
      personFunction,
      phoneAdditional,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { forceValidation } = nextProps
    const {
      isNameValid,
      isPhoneValid,
      isPersonFunctionValid,
      isMolUserIdValid,
    } = prevState

    if (forceValidation) {
      return {
        // replacing undefined to false
        isNameValid: !!isNameValid,
        isPhoneValid: !!isPhoneValid,
        isPersonFunctionValid: !!isPersonFunctionValid,
        isMolUserIdValid: !!isMolUserIdValid,
      }
    }

    return null
  }

  componentDidMount = () => {
    const { fetchOrganizationStaff, molUserId } = this.props
    fetchOrganizationStaff(this.props.match.params.organizationId)
    if (molUserId) {
      this.props.onChangeFields(this.isValid(), {
        phone: this.state.phone,
        phoneAdditional: this.state.phoneAdditional,
        name: this.state.name,
        molUserId: this.state.molUserId,
        personFunction: this.state.personFunction,
      })
    }
  }

  getFormattedPhone = phone => {
    console.log(
      'PHONE',
      phone,
      phone.length === 11 ? `+${phone[0]}` : '+7',
      phone.length,
    )
    if (phone.length === 0) {
      return '+'
    }
    let result = phone.length === 11 ? `+${phone[0]}` : '+7'
    if (phone.length > 1) {
      result += `(${phone.slice(1, 4)}`
      if (phone.length > 4) {
        result += `) ${phone.slice(4, 7)}`
        if (phone.length > 4) {
          result += ` ${phone.slice(7, 9)}`
        }
        if (phone.length > 4) {
          result += `${phone.slice(9, 11)}`
        }
      }
    }
    return result[result.length - 1] === ' '
      ? result.slice(0, result.length - 1)
      : result
  }

  handleChange = field => event => {
    const { value } = event.target
    switch (field) {
      case 'phone':
        this.setState({
          isPhoneValid: value.replace(/[^0-9]/g, '').length === 11,
        })
        break
      case 'molUserId':
        this.setState({
          isMolUserIdValid: value !== '',
        })
        break
      case 'phoneAdditional':
        if (value.match(/[^0-9]/i)) {
          return
        }
        this.setState({
          isPhoneAdditionalValid: value.length < 10,
        })
        break
      case 'person':
        if (value.match(/[`\\^%/~*&()=+_\-<>@£#$!?|\][.,£#¢∞§¶•ªº0-9]/g)) {
          this.setState(state => ({
            [field]: state[field],
            isPersonValid: value.length > 0,
          }))
          return
        }
        this.setState({
          isPersonValid: value.length > 0,
        })

        break
      case 'personFunction':
        if (value.match(/[`\\^%/~*&()=+_\-<>@£#$!?|\][.,£#¢∞§¶•ªº]/g)) {
          this.setState(state => ({
            [field]: state[field],
            isPersonFunctionValid: value.length > 0,
          }))
          return
        }
        this.setState({
          isPersonFunctionValid: value.length > 0,
        })

        break
      case 'name':
        if (value.match(/[`\\^%/~*&()=+_\-<>@£#$!?|\][.,£#¢∞§¶•ªº]/g)) {
          this.setState(state => ({
            [field]: state[field],
            isNameValid: value.length > 0,
          }))
          return
        }
        this.setState({
          isNameValid: value.length > 0,
        })

        break

      default:
        break
    }
    this.setState({ [field]: value }, () => {
      this.props.onChangeFields(this.isValid(), {
        phone: this.state.phone,
        phoneAdditional: this.state.phoneAdditional,
        name: this.state.name,
        molUserId: this.state.molUserId,
        personFunction: this.state.personFunction,
        [field]: value,
      })
    })
  }

  handleChangeMol = event => {
    const {
      props: { organizationUsers: users },
    } = this
    const user = users[_.findIndex(users, { id: event.target.value })]
    if (!user) {
      return
    }
    const params = {
      molUserId: event.target.value,
    }
    if (user.position) {
      params.personFunction =
        user.position === 'owner'
          ? i18next.t('organizations_web_atoir:ownerRole')
          : user.position
    }
    params.isPersonFunctionValid = true
    params.isMolUserIdValid = true
    params.phone = user.phone
      ? user.phone.length === 11
        ? user.phone
        : `+7${user.phone}`
      : null
    this.setState(params, () => {
      this.props.onChangeFields(this.isValid(), {
        phone: this.state.phone,
        phoneAdditional: this.state.phoneAdditional,
        name: this.state.name,
        molUserId: this.state.molUserId,
        personFunction: this.state.personFunction,
      })
    })
  }

  handleToggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  isValid = (data = this) => {
    const { name, phone, phoneAdditional, personFunction } = data
    const { molUserId } = this.state
    console.log(name && name.value && name.value.length > 0)
    console.log(molUserId && molUserId.length > 0)
    console.log(
      phone &&
        phone.inputElement.value &&
        phone.inputElement.value.replace(/[^0-9]/g, '').length === 11,
    )
    return (
      name &&
      name.value &&
      name.value.length > 0 &&
      molUserId &&
      molUserId.length > 0 &&
      phone &&
      phone.inputElement.value &&
      phone.inputElement.value.replace(/[^0-9]/g, '').length === 11 &&
      (!phoneAdditional ||
        (phoneAdditional && phoneAdditional.value.length < 10)) &&
      personFunction &&
      personFunction.value &&
      personFunction.value.length > 0
    )
  }

  render() {
    const {
      classes,
      organizationUsers: users,
      isFetchingOrganizationStaff: isFetchingUsers,
    } = this.props
    const {
      isPhoneValid,
      isPhoneAdditionalValid,
      isNameValid,
      isMolUserIdValid,
      isPersonFunctionValid,
      molUserId,
      isOrganizationUsersOpen,
    } = this.state

    return (
      <Grid container spacing={2} className={classes.container}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth error={isNameValid === false} required>
            <InputLabel htmlFor="name-simple">
              {i18next.t('organizations_web_atoir:departmentName')}
            </InputLabel>
            <Input
              inputRef={el => {
                this.name = el
              }}
              id="name-simple"
              value={this.state.name}
              onChange={this.handleChange('name', this)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <DropDownList
            fullWidth
            noEmpty
            classes={classes}
            required
            showLabel
            error={isMolUserIdValid === false}
            value={molUserId}
            ref={el => {
              this.molUserId = el
            }}
            open={isOrganizationUsersOpen}
            handleOpen={this.handleToggle('isOrganizationUsersOpen', true)}
            handleChange={this.handleChangeMol}
            handleClose={this.handleToggle('isOrganizationUsersOpen', false)}
            label={i18next.t(
              'organizations_web_atoir:financiallyResponsiblePersonFullName',
            )}
            elements={users.map(u => ({
              id: u.id,
              title: `${u.last_name} ${u.first_name}`.trim(),
            }))}
            isFetching={isFetchingUsers}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item sm={6} xs={12}>
              <FormControl
                fullWidth
                error={isPersonFunctionValid === false}
                required
              >
                <InputLabel
                  shrink={
                    this.state.personFunction &&
                    this.state.personFunction.length !== 0
                  }
                  htmlFor="person-function-simple"
                >
                  {i18next.t('employees_web_atoir:position')}
                </InputLabel>
                <Input
                  inputRef={el => {
                    this.personFunction = el
                  }}
                  id="person-function-simple"
                  value={this.state.personFunction}
                  onChange={this.handleChange('personFunction', this)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth error={isPhoneValid === false} required>
                <InputLabel
                  shrink={this.state.phone && this.state.phone.length !== 0}
                  htmlFor="phone-function-simple"
                >
                  {i18next.t('organizations_web_atoir:contactPhone')}
                </InputLabel>
                <Input
                  value={this.state.phone}
                  inputRef={el => {
                    this.phone = el
                  }}
                  onChange={this.handleChange('phone', this)}
                  id="phone-function-simple"
                  inputComponent={PhoneMasked}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={isPhoneAdditionalValid === false}>
            <InputLabel htmlFor="phone-additional-function-simple">
              {i18next.t('organizations_web_atoir:additionalDepartmentPhone')}
            </InputLabel>
            <Input
              value={this.state.phoneAdditional}
              inputRef={el => {
                this.phoneAdditional = el
              }}
              onChange={this.handleChange('phoneAdditional', this)}
              id="phone-additional-function-simple"
            />
          </FormControl>
        </Grid>
      </Grid>
    )
  }
}

EditCreateDepartmentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditCreateDepartmentComponent)
