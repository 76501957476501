import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CallHistory from './CallHistory'
import {
  clearCallHistory,
  fetchCallHistory,
  fetchCallPermissions,
  fetchServiceDeskCall,
} from '../../../actions/serviceDesk'

const mapStateToProps = state => ({
  call: state.serviceDesk.request.request,
  history: state.serviceDesk.history.history,
  end: state.serviceDesk.history.end,
  isFetchingHistory: state.serviceDesk.history.isFetching,
  historyError: state.serviceDesk.history.error,
  isFetchingCall: state.serviceDesk.request.isFetching,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  permissions: state.serviceDesk.permissions.permissions,
  ordersPermissions: state.serviceDesk.permissions.orders,
  callStatus: state.serviceDesk.permissions.status,
  permissionsError: state.serviceDesk.permissions.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCallPermissions,
      fetchServiceDeskCall,
      fetchCallHistory,
      clearCallHistory,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CallHistory),
)
