import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../../i18n/i18n'
import Grid from '@material-ui/core/Grid'
import Edit from '@material-ui/icons/Edit'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton/IconButton'
import RLink from 'react-router-dom/Link'
import { withRouter } from 'react-router-dom'

const styles = {
  informationRoot: {
    textAlign: 'left',
  },
  editIconRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const formEquipmentFullName = e =>
  _.compact([e.name, e.vendor, e.model]).join(', ')

class EquipmentProfile extends Component {
  state = {}

  render() {
    const {
      props: {
        classes,
        equipment,
        cellNameWrapper,
        cellValueWrapper,
        onEdit,
        match,
      },
    } = this
    return (
      <div className={classes.informationRoot}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            {cellNameWrapper(i18next.t('equipment_web_atoir:equipmentName'))}
          </Grid>
          <Grid item xs={9} className={classes.editIconRoot}>
            {match.url === '/service_desk/created/create' ? (
              <RLink
                target="_blank"
                to={`/equipment/organization/${equipment.organization_id}/${equipment.id}/information`}
              >
                <span className="staff-name-underlined">
                  {cellValueWrapper(formEquipmentFullName(equipment))}
                </span>
              </RLink>
            ) : (
              cellValueWrapper(formEquipmentFullName(equipment))
            )}
            {onEdit ? (
              <IconButton onClick={onEdit}>
                <Edit />
              </IconButton>
            ) : (
              ''
            )}
            {onEdit ? (
              <IconButton onClick={onEdit}>
                <Edit />
              </IconButton>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={3}>
            {cellNameWrapper(i18next.t('equipment_web_atoir:country'))}
          </Grid>
          <Grid item xs={9}>
            {cellValueWrapper(
              equipment.Country ? equipment.Country.full_name : '-',
            )}
          </Grid>
          <Grid item xs={3}>
            {cellNameWrapper(
              i18next.t('equipment_web_atoir:serialNumberShort'),
            )}
          </Grid>
          <Grid item xs={9}>
            {cellValueWrapper(
              equipment.serial_number ? equipment.serial_number : '-',
            )}
          </Grid>
          <Grid item xs={3}>
            {cellNameWrapper(
              i18next.t('equipment_web_atoir:articleNumberShort'),
            )}
          </Grid>
          <Grid item xs={9}>
            {cellValueWrapper(
              equipment.article_number ? equipment.article_number : '-',
            )}
          </Grid>
          <Grid item xs={3}>
            {cellNameWrapper(i18next.t('equipment_web_atoir:inventoryCode'))}
          </Grid>
          <Grid item xs={9}>
            {cellValueWrapper(
              equipment.inventory_code ? equipment.inventory_code : '-',
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

EquipmentProfile.defaultProps = {
  onEdit: null,
}

EquipmentProfile.propTypes = {
  equipment: PropTypes.object.isRequired,
  cellNameWrapper: PropTypes.func.isRequired,
  cellValueWrapper: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
}

export default withRouter(withStyles(styles)(EquipmentProfile))
