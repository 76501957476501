import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import i18next from '../../../../../../i18n/i18n'
import PropTypes from 'prop-types'

// ---material-ui---
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PrimaryButton from '../../../../../../widgets/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../../widgets/Buttons/SecondaryButton'
import SimpleDialog from '../../../../../Other/SimpleDialog/DialogComponent'

class DetailsOffer extends Component {
  state = {
    isCancelOfferConfirmationDialogOpen: false,
  }

  getCustomDivider = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  toggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  render() {
    const {
      props: { textAction, handleClose, offer, handleAction },
      state: { isCancelOfferConfirmationDialogOpen },
    } = this
    const smallItem = 5
    const constBigItem = 7
    const personPerformer = `${offer.responsible_user.last_name} 
    ${offer.responsible_user.first_name} ${offer.responsible_user.middle_name}`

    return (
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {i18next.t('inventory_web_atoir:commercialOffer')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid xs={smallItem}>
              <Typography variant="body1" align="left">
                {i18next.t('inventory_web_atoir:totalPrice')}
              </Typography>
            </Grid>
            <Grid xs={constBigItem}>
              <Typography align="left" variant="body2">
                {offer.amount}
              </Typography>
            </Grid>
            {this.getCustomDivider()}
            <Grid xs={smallItem}>
              <Typography variant="body1" align="left">
                {i18next.t('inventory_web_atoir:performerOrganization')}
              </Typography>
            </Grid>
            <Grid xs={constBigItem}>
              <Typography align="left" variant="body2">
                {offer.Organization.short_name}
              </Typography>
            </Grid>
            {this.getCustomDivider()}
            <Grid xs={smallItem}>
              <Typography variant="body1" align="left">
                {i18next.t('inventory_web_atoir:contactPersonPerformer')}
              </Typography>
            </Grid>
            <Grid xs={constBigItem}>
              <Typography align="left" variant="body2">
                <div>{personPerformer}</div>
                <div>
                  {offer.responsible_user.position === 'owner'
                    ? i18next.t('organizations_web_atoir:ownerRole')
                    : offer.responsible_user.position}
                </div>
                <div>{offer.responsible_user.phone}</div>
              </Typography>
            </Grid>
            {this.getCustomDivider()}
            <Grid xs={smallItem}>
              <Typography variant="body1" align="left">
                {i18next.t('inventory_web_atoir:rating')}
              </Typography>
            </Grid>
            <Grid xs={constBigItem}>
              <Typography align="left" variant="body2">
                {offer.Organization.service_rating}
              </Typography>
            </Grid>
            {this.getCustomDivider()}
            <Grid xs={smallItem}>
              <Typography variant="body1" align="left">
                {i18next.t('inventory_web_atoir:actualTo')}
              </Typography>
            </Grid>
            <Grid xs={constBigItem}>
              <Typography align="left" variant="body2">
                {new Date(offer.date_actual_to).toLocaleDateString()}
              </Typography>
            </Grid>
            {this.getCustomDivider()}
            <Grid xs={smallItem}>
              <Typography variant="body1" align="left">
                {i18next.t('inventory_web_atoir:comment')}
              </Typography>
            </Grid>
            <Grid xs={constBigItem}>
              <Typography align="left" variant="body2">
                {offer.performer_comment}
              </Typography>
            </Grid>
            {this.getCustomDivider()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>
            {i18next.t('shared_web_atoir:close')}
          </SecondaryButton>
          {offer.status !== 'cancel' && (
            <PrimaryButton
              onClick={this.toggle('isCancelOfferConfirmationDialogOpen', true)}
              autoFocus
            >
              {textAction}
            </PrimaryButton>
          )}
        </DialogActions>
        <SimpleDialog
          isOpen={isCancelOfferConfirmationDialogOpen}
          handleClose={this.toggle(
            'isCancelOfferConfirmationDialogOpen',
            false,
          )}
          handlePositive={() => {
            handleAction(offer.id, offer.Organization.id)
          }}
          handleNegative={this.toggle(
            'isCancelOfferConfirmationDialogOpen',
            false,
          )}
          positiveText={i18next.t('shared_web_atoir:yes').toLowerCase()}
          negativeText={i18next.t('shared_web_atoir:no').toLowerCase()}
          title=""
          content={i18next.t('inventory_web_atoir:sureToCloseOffer')}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
})

DetailsOffer.propTypes = {
  textAction: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
}

export default withStyles(styles)(DetailsOffer)
