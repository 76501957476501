import React from 'react'
import PropTypes from 'prop-types'

import shortId from 'shortid'

import withStyles from '@material-ui/core/styles/withStyles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'

import i18next from '../../../../i18n/i18n'

import FormDialog from '../../../../widgets/FormDialog'
import DateInput from '../../../../widgets/DateInput'
import RepairDateReminderContext from './RepairDateReminderContext'

import { getUserFullName } from '../../../../helpers/userHelper'

import withSaveVisitDateHOC from './SaveVisitDateHOC'

const unixStartTime = '1970-01-01T00:00:00.000Z'

const styles = {
  checkboxPlacement: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  checkboxLabel: {
    marginRight: 0,
  },
  formControl: {},
}

const RepairDateReminder = withStyles(styles)(
  withSaveVisitDateHOC(
    ({
      classes,
      onSave,
      orderId,
      order,
      dontShowState,
      setDontShow,
      engineer,
      canEdit,
    }) => {
      const [dialogOpen, setDialogOpen] = React.useState(false)
      const [visitDate, setVisitDate] = React.useState(null)
      // its just for clear DateInput on dontRemain will be checked
      const [
        visitDateMountInstance,
        setVisitDateMountInstance,
      ] = React.useState('firsInstance')
      const [dontRemain, setDontRemain] = React.useState(false)

      React.useEffect(() => {
        if (
          order.type_order === 'repair' &&
          order.status === 'inwork' &&
          !dontShowState[orderId] &&
          order.isPerformer &&
          !order.hasChild &&
          !order.visit_date &&
          canEdit
        ) {
          setDialogOpen(true)
        }
      }, [])

      function clearDateInput() {
        setVisitDateMountInstance(shortId.generate())
      }
      function closeDialog() {
        setDontShow(orderId)
        setDialogOpen(false)
      }
      function checkboxHandler(event) {
        if (event.target.checked) {
          setVisitDate(null)
          clearDateInput()
        }
        setDontRemain(event.target.checked)
      }
      function dateInputHandler(value) {
        setVisitDate(value)
        if (value) {
          setDontRemain(false)
        }
      }
      function saveChoice() {
        if (dontRemain) {
          onSave(orderId, unixStartTime)
        } else {
          onSave(orderId, visitDate)
        }
      }
      return (
        <React.Fragment>
          <FormDialog
            positiveButtonVariant="applyButton"
            secondaryButtonVariant="applyButton"
            closeIconHidden
            positiveDisabled={!(visitDate || dontRemain)}
            open={dialogOpen}
            isLoading={false}
            title={i18next.t('service_desk_web_atoir:setDateOfVisitCaption')}
            onClose={closeDialog}
            onExit={closeDialog}
            onSubmit={saveChoice}
            submitButtonTitle={i18next.t('shared_web_atoir:save')}
            secondaryButtonsArray={[
              {
                secondaryText: i18next.t('shared_web_atoir:cancel'),
                handleSecondaryAction: closeDialog,
              },
            ]}
          >
            <FormControl component="fieldset">
              <FormControl className={classes.formControl}>
                <Grid container spacing={2}>
                  <Grid item container xs={6} alignContent="flex-end">
                    <InputLabel htmlFor="age-simple">
                      {i18next.t('service_desk_web_atoir:engineerFullName')}
                    </InputLabel>
                    <Select fullWidth disabled value={1} onChange={() => {}}>
                      <MenuItem value={1}>
                        {engineer
                          ? getUserFullName(engineer)
                          : i18next.t(
                              'service_desk_web_atoir:engineerIsNotAssignedYet',
                            )}
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item container xs={6} alignContent="flex-end">
                    <DateInput
                      disabled={dontRemain}
                      key={visitDateMountInstance}
                      label={i18next.t('service_desk_web_atoir:dateVisit')}
                      name="visitDate"
                      value={visitDate}
                      disablePast
                      handleChange={dateInputHandler}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <div className={classes.checkboxPlacement}>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      checked={dontRemain}
                      onChange={checkboxHandler}
                      value="gilad"
                    />
                  }
                  label={i18next.t(
                    'service_desk_web_atoir:hideDateOfVisitDialog',
                  )}
                />
              </div>
            </FormControl>
          </FormDialog>
        </React.Fragment>
      )
    },
  ),
)

RepairDateReminder.defaultProps = {
  engineer: null,
}

RepairDateReminder.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  dontShowState: PropTypes.func.isRequired,
  setDontShow: PropTypes.func.isRequired,
  engineer: PropTypes.object,
}

const RepairDateReminderWrapped = props => (
  <RepairDateReminderContext.Consumer>
    {({ dontShowState, setDontShow }) => (
      <RepairDateReminder
        {...props}
        dontShowState={dontShowState}
        setDontShow={setDontShow}
      />
    )}
  </RepairDateReminderContext.Consumer>
)

export default RepairDateReminderWrapped
