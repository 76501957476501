import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_BY_ID_REQUEST,
  UPLOAD_USER_IMAGE_REQUEST,
  UPLOAD_USER_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAILURE,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_AVATAR_SUCCESS,
  UPDATE_USER_AVATAR_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CLEAR_VALIDATION_ERRORS,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REMOVE_USER_AVATAR_REQUEST,
  REMOVE_USER_AVATAR_SUCCESS,
  REMOVE_USER_AVATAR_FAILURE,
  FETCH_USER_NOTIFICATION_SETTINGS,
  FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_USER_NOTIFICATION_SETTINGS_FAILURE,
  SET_USER_NOTIFICATION_SETTING_FAILURE,
  SET_USER_NOTIFICATION_SETTING,
  SET_USER_NOTIFICATION_SETTING_SUCCESS,
} from '../constants/actionTypes'

const initialState = {
  isFetchingUser: false,
  isUploadingUserImage: false,
  newAvatar: '',
  isUpdatingUserAvatar: false,
  isUpdatingUser: false,
  isChangingPassword: false,
  user: {},
  validationErrors: {},
  passwordChangeErrors: {},
  isRemovingUserAvatar: true,
  avatarError: null,
  notificationSettings: {
    isFetching: true,
    settings: [],
  },
}

const userReducer = (state = initialState, action) => {
  let settingsToChange
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
      }

    case FETCH_USER_BY_ID_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
      }

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        ownUser: action.data.isOneself
          ? {
              id: action.data.id,
              firstName: action.data.first_name,
              lastName: action.data.last_name,
              middleName: action.data.middle_name,
              email: action.data.email,
              education: action.data.education,
              experience: action.data.experience,
              passwordChanged: action.data.password_changed,
              timezone: action.data.timezone,
              phone: action.data.phone,
              avatar: action.data.avatar,
            }
          : state.ownUser,
        user: {
          id: action.data.id,
          firstName: action.data.first_name,
          lastName: action.data.last_name,
          middleName: action.data.middle_name,
          email: action.data.email,
          education: action.data.education,
          experience: action.data.experience,
          passwordChanged: action.data.password_changed,
          timezone: action.data.timezone,
          phone: action.data.phone,
          avatar: action.data.avatar,
        },
      }

    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetchingUser: false,
      }

    case CLEAR_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: {},
      }

    case UPLOAD_USER_IMAGE_REQUEST:
      return {
        ...state,
        isUploadingUserImage: true,
      }

    case UPLOAD_USER_IMAGE_SUCCESS:
      return {
        ...state,
        isUploadingUserImage: false,
        newAvatar: action.avatarName,
        validationErrors: {},
      }

    case UPLOAD_USER_IMAGE_FAILURE:
      return {
        ...state,
        isUploadingUserImage: false,
        validationErrors: {
          avatar: action.errors.avatar,
        },
      }

    case UPDATE_USER_AVATAR_REQUEST:
      return {
        ...state,
        isUpdatingUserAvatar: true,
      }

    case UPDATE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        isUpdatingUserAvatar: false,
        newAvatar: action.avatarData.avatar,
        validationErrors: {},
      }

    case UPDATE_USER_AVATAR_FAILURE:
      return {
        ...state,
        isUpdatingUserAvatar: false,
        validationErrors: {
          avatar: action.errors.avatar,
        },
      }

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        newAvatar: '',
        validationErrors: {},
      }

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdatingUser: false,
        newAvatar: '',
        validationErrors: action.errors,
      }

    case REMOVE_USER_AVATAR_REQUEST:
      return {
        ...state,
        isRemovingUserAvatar: true,
        avatarError: null,
      }

    case REMOVE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        isRemovingUserAvatar: false,
      }

    case REMOVE_USER_AVATAR_FAILURE:
      return {
        ...state,
        isRemovingUserAvatar: false,
        avatarError: action.code,
      }

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangingPassword: true,
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false,
        passwordChangeErrors: {},
      }

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false,
        passwordChangeErrors: action.errors,
      }
    case FETCH_USER_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: {
          isFetching: true,
          settings: [],
        },
      }

    case FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS:
      const settings = _.groupBy(action.settings, setting =>
        setting.name.replace(/(_browser|_mobile|_email)$/, ''),
      )
      return {
        ...state,
        notificationSettings: {
          isFetching: false,
          settings,
        },
      }

    case FETCH_USER_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          isFetching: false,
        },
      }
    case SET_USER_NOTIFICATION_SETTING:
      settingsToChange = { ...state.notificationSettings.settings }
      action.payload.forEach(change => {
        settingsToChange[change.key] = state.notificationSettings.settings[
          change.key
        ].map(s => {
          if (s.name !== `${change.key}_${change.type}`) {
            return s
          }
          return { ...s, enabled: change.value }
        })
      })
      return {
        ...state,
        notificationSettings: {
          isFetching: true,
          settings: settingsToChange,
        },
      }

    case SET_USER_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          isFetching: false,
        },
      }

    case SET_USER_NOTIFICATION_SETTING_FAILURE:
      settingsToChange = { ...state.notificationSettings.settings }
      action.payload.forEach(change => {
        settingsToChange[change.key] = state.notificationSettings.settings[
          change.key
        ].map(s => {
          if (s.name !== `${change.key}_${change.type}`) {
            return s
          }
          return { ...s, enabled: !change.value }
        })
      })
      return {
        ...state,
        notificationSettings: {
          isFetching: false,
          settings: settingsToChange,
        },
      }

    default:
      return state
  }
}

export default userReducer
