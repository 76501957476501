import React, { Component } from 'react'
import PropTypes from 'prop-types'

// material UI
import { withStyles } from '@material-ui/core/styles'

// components
import CollapseButton from '../../../../widgets/Buttons/CollapseButton'
import Typography from '@material-ui/core/Typography/Typography'

const styles = theme => ({
  divMain: {
    zIndex: 1,
  },
  EnhancedCell: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 1,
    paddingBottom: 1,
    border: 0,
  },
  divItemRow: {
    display: 'flex',
    // border: 2,
    // borderStyle: 'solid',
    width: '100%',
  },
  divDetailInfo: {
    width: '100%',
    textAlign: 'left',
  },
  buttonStyle: {
    '&:hover': {
      backgroundColor: theme.palette.main.light,
    },
  },
})

const doCompactName = (name, desiredLength) => {
  let nameOut = name
  if (name.length > desiredLength) {
    nameOut = `${name.slice(0, desiredLength - 1)}...`
  }
  return nameOut
}

class EquipmentName extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCollapsed: true,
      maxLength: 150,
      showValue: '',
    }
  }

  componentDidMount = () => {
    const {
      state: { maxLength },
      props: { data },
    } = this
    this.setState({ showValue: doCompactName(data, maxLength) })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  onClickHandler = e => {
    e.stopPropagation()
    const { isCollapsed } = this.state
    this.setState({ isCollapsed: !isCollapsed }, () => {
      this.compactName()
    })
  }

  compactName = () => {
    const { data } = this.props
    const { maxLength, isCollapsed } = this.state
    if (isCollapsed && data.length > maxLength) {
      this.setState({ showValue: doCompactName(data, maxLength) })
    } else if (!isCollapsed && data.length > maxLength) {
      this.setState({ showValue: data })
    }
  }

  render() {
    const {
      props: { data, classes },
      state: { isCollapsed, maxLength, showValue },
      onClickHandler,
    } = this
    return (
      <div className={classes.divMain}>
        <Typography variant="body2">
          {showValue}
          {data.length > maxLength && (
            <CollapseButton
              isCollapsed={isCollapsed}
              onClick={onClickHandler}
            />
          )}
        </Typography>
      </div>
    )
  }
}

EquipmentName.propTypes = {
  data: PropTypes.string.isRequired,
}
export default withStyles(styles)(EquipmentName)
