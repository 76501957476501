import React, { Component, Fragment } from 'react'
import shortId from 'shortid'
import _ from 'lodash'
import { round10 } from 'round10'

// i18n
import i18next from '../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import PropTypes from 'prop-types'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Typography from '@material-ui/core/Typography/Typography'

// modules
import CompletionCertificateTable from './Widgets/CompletionCertificateTable'

const initItem = {
  id: 0,
  item_type: null,
  typeList: [],
  units: null,
  unitsList: [],
  name: null,
  oem: null,
  count: null,
  percent: null,
  price_purchase: null,
  update: 'new',
  result_type: 'diagnostic',
}

const itemsTypes = () => {
  const types = [
    {
      type: 'work',
      value: i18next.t('service_desk_web_atoir:workItemType'),
      listEnum: [0],
      defEnum: 0,
    },
    {
      type: 'part',
      value: i18next.t('service_desk_web_atoir:partItemType'),
      listEnum: [1, 2, 3, 4, 5],
      defEnum: 1,
    },
    {
      type: 'material',
      value: i18next.t('service_desk_web_atoir:materialItemType'),
      listEnum: [1, 2, 3, 4, 5],
      defEnum: 1,
    },
    {
      type: 'program',
      value: i18next.t('service_desk_web_atoir:programItemType'),
      listEnum: [1],
      defEnum: 1,
    },
    {
      type: 'other',
      value: i18next.t('service_desk_web_atoir:otherItemType'),
      listEnum: [0, 1, 2, 3, 4, 5],
      defEnum: 1,
    },
  ]
  return types
}

const countEnumTypes = () => {
  const types = [
    {
      type: 'work_hour',
      value: i18next.t('service_desk_web_atoir:work_hourUnitsType'),
    },
    { type: 'one', value: i18next.t('service_desk_web_atoir:oneUnitsType') },
    {
      type: 'liters',
      value: i18next.t('service_desk_web_atoir:litersUnitsType'),
    },
    { type: 'sq', value: i18next.t('service_desk_web_atoir:sqUnitsType') },
    { type: 'm', value: i18next.t('service_desk_web_atoir:mUnitsType') },
    { type: 'kg', value: i18next.t('service_desk_web_atoir:kgUnitsType') },
  ]
  return types
}

const itemTypeObjByType = item => {
  const types = itemsTypes()
  return _.find(types, { type: item })
}

const unitListByItem = itemTypeObj =>
  itemTypeObj.listEnum.map(enumTypeId => {
    const types = countEnumTypes()
    return {
      type: types[enumTypeId].type,
      name: types[enumTypeId].value,
    }
  })

const itemsTypesList = () => {
  const types = itemsTypes()
  return types.map(itemType => ({ type: itemType.type, name: itemType.value }))
}

const isNumber = field => Number.isFinite(Number(field))

const headerStyles = theme => ({
  headerCell: {
    color: theme.palette.apply.extraLight,
    fontSize: '12px',
  },
})

let EnhancedTableHead = props => (
  <TableHead>
    <TableRow>
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:type')}${
          props.markRequired ? '**' : ''
        }`}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:tableCellName')}${
          props.markRequired ? '**' : ''
        }, ${i18next.t('service_desk_web_atoir:catalogueNumber')}`}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:itemCategory')}`}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:unitsOfMeasurement')}${
          props.markRequired ? '**' : ''
        }`}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:amount')}${
          props.markRequired ? '**' : ''
        }`}
      </TableCell>
      {!props.showClientVersion && (
        <TableCell className={props.classes.headerCell}>
          {`${i18next.t('service_desk_web_atoir:costPrice')}${
            props.markRequired ? '**' : ''
          }
          ${i18next.t('service_desk_web_atoir:roubles')}`}
        </TableCell>
      )}
      {!props.showClientVersion && (
        <TableCell className={props.classes.headerCell}>
          {`${i18next.t('service_desk_web_atoir:costPriceNDS')}
          ${i18next.t('service_desk_web_atoir:roubles')}`}
        </TableCell>
      )}
      {!props.showClientVersion && (
        <TableCell className={props.classes.headerCell}>
          {`${i18next.t('service_desk_web_atoir:extraCharge')}${
            props.markRequired ? '**' : ''
          }
          ${i18next.t('service_desk_web_atoir:percentage')}`}
        </TableCell>
      )}
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:price')} ${i18next.t(
          'service_desk_web_atoir:roubles',
        )}`}
      </TableCell>
      {!props.showClientVersion && (
        <TableCell className={props.classes.headerCell}>
          {`${i18next.t('service_desk_web_atoir:shoppingSum')} ${i18next.t(
            'service_desk_web_atoir:roubles',
          )}`}
        </TableCell>
      )}
      <TableCell className={props.classes.headerCell}>
        {`${i18next.t('service_desk_web_atoir:tableOrderAmount')}`}
      </TableCell>
      {props.markRequired && <TableCell className={props.classes.headerCell} />}
    </TableRow>
  </TableHead>
)

EnhancedTableHead.defaultProps = {
  markRequired: true,
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  markRequired: PropTypes.bool,
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead)

const styles = theme => ({
  root: {
    width: 1400,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1020,
    fontSize: '12px',
  },
  tableEditDisabled: {
    overflowX: 'auto',
    width: '100%',
    fontSize: '12px',
  },
  tableCell: {
    border: 0,
    padding: 0,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  divActions: {
    display: 'flex',
    minHeight: '66px',
    alignItems: 'center',
    backgroundColor: lighten(theme.palette.secondary.light, 0.95),
  },
  divActionsRight: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  button: {
    color: theme.palette.second.contrastText,
    background: theme.palette.second.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      backgroundColor: theme.palette.main.main,
      color: theme.palette.main.contrastText,
    },
  },
  customBtnBlue: {
    padding: '8px 8px',
    color: theme.palette.second.contrastText,
    background: theme.palette.second.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      backgroundColor: theme.palette.main.main,
      color: theme.palette.main.contrastText,
    },
  },
  headerCell: {
    color: theme.palette.apply.extraLight,
    fontWeight: 'bold',
  },
})

const stringToNullObj = item => {
  const coolItem = item
  _.forIn(coolItem, (value, key) => {
    if (coolItem[key] === '') coolItem[key] = null
  })
  return coolItem
}

const stringToNull = orderItems => orderItems.map(item => stringToNullObj(item))

const nullToStringObj = item => {
  const coolItem = item
  _.forIn(coolItem, (value, key) => {
    if (coolItem[key] === null) coolItem[key] = ''
  })
  return coolItem
}

const nullToString = orderItems => orderItems.map(item => nullToStringObj(item))

const addTypeList = orderItems =>
  orderItems.map(item => ({ ...item, typeList: itemsTypesList() }))

const addUnitsList = orderItems =>
  orderItems.map(item => {
    if (Boolean(item.item_type) && item.item_type !== '') {
      const unitsList = unitListByItem(itemTypeObjByType(item.item_type))
      return { ...item, unitsList }
    }
    return { ...item, unitsList: [] }
  })

class OrderItemsForm extends Component {
  state = {}

  handleChange = (id, change, value) => {
    const { percentSetMixed, inputNDS } = this.props
    let percentNeedProcessing = false

    if (change === 'name') {
      if (value.length > 128) {
        return
      }
    }

    if (change === 'item_category' && value.length > 8) {
      return
    }

    const { data } = this.state
    const indexItem = _.findIndex(data, { id })
    const enumTypes = countEnumTypes()
    if (indexItem >= 0) {
      const row = data[indexItem]
      switch (change) {
        case 'item_type': {
          const itemTypeObj = itemTypeObjByType(value)
          if (row.item_type !== value) {
            row.item_type = value
            row.units = enumTypes[itemTypeObj.defEnum].type
            row.unitsList = unitListByItem(itemTypeObj)
            row.invalidItemType = false
            row.invalidUnits = false
          }
          break
        }
        case 'item_category': {
          row.item_category = value
          break
        }
        case 'units': {
          row.units = value
          row.invalidUnits = false
          break
        }
        case 'oem': {
          row.oem = value
          break
        }
        case 'name': {
          row.name = value
          row.invalidName = false
          break
        }
        case 'count': {
          row.count = value
          if (Number(value) > 99999999) row.count = 99999999
          row.invalidCount = false
          break
        }
        case 'price_purchase': {
          row.price_purchase = +value
          if (Number(value) >= 99999999) row.price_purchase = 99999999
          if (
            document.activeElement &&
            document.activeElement.attributes.inputname &&
            document.activeElement.attributes.inputname.value ===
              'price_purchase'
          ) {
            row.price_purchase_with_nds =
              +row.price_purchase +
              round10((row.price_purchase * inputNDS) / 100, -2)
            row.delta = row.price_purchase_with_nds - row.price_purchase
          }
          row.invalidPricePurchase = false
          break
        }
        case 'price_purchase_with_nds': {
          row.price_purchase_with_nds = +value
          if (Number(value) >= 99999999) row.price_purchase_with_nds = 99999999
          if (
            document.activeElement &&
            document.activeElement.attributes.inputname &&
            document.activeElement.attributes.inputname.value ===
              'price_purchase_with_nds'
          ) {
            row.price_purchase = round10(
              +row.price_purchase_with_nds -
                round10(
                  (+row.price_purchase_with_nds / (100 + +inputNDS)) *
                    +inputNDS,
                  -2,
                ),
              -2,
            )
            row.delta = row.price_purchase_with_nds - row.price_purchase
          }
          row.invalidPricePurchaseNDS = false
          break
        }

        case 'percent': {
          row.percent = parseFloat(value) > 999.99 ? row.percent : value
          row.invalidPercent = false
          percentNeedProcessing = true
          break
        }
        default: {
          row[change] = value
          data[indexItem] = row
        }
      }
      if (row.update !== 'new' && row.update !== 'delete') row.update = 'update'
      this.setState(
        {
          data,
        },
        () => {
          if (percentNeedProcessing) {
            if (this.checkPercentMix(value)) {
              percentSetMixed(true)
            } else {
              percentSetMixed(value)
            }
          }
        },
      )
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateDiff = {}
    let dataNeedUpdate = false
    if (prevState.itemsDiagnostic !== nextProps.itemsDiagnostic) {
      stateDiff.itemsDiagnostic = nextProps.itemsDiagnostic
      dataNeedUpdate = true
    }
    if (prevState.itemsNeedRepair !== nextProps.itemsNeedRepair) {
      stateDiff.itemsNeedRepair = nextProps.itemsNeedRepair
      dataNeedUpdate = true
    }
    if (dataNeedUpdate) {
      stateDiff.data = addUnitsList(
        addTypeList(
          nullToString(
            nextProps.itemsNeedRepair
              ? _.concat(nextProps.itemsNeedRepair, nextProps.itemsDiagnostic)
              : nextProps.itemsDiagnostic,
          ),
        ),
      ).map(row => ({
        ...row,
        price_purchase_with_nds:
          +row.delta !== 0 || !nextProps.inputNDS
            ? +row.price_purchase + +row.delta
            : +row.price_purchase +
              round10((row.price_purchase * nextProps.inputNDS) / 100, -2),
      }))
    }

    if (!_.isEmpty(stateDiff)) {
      return stateDiff
    }
    return null
  }

  checkPercentMix = percentValue => {
    const isMixed = _.find(
      this.state.data,
      row => `${row.percent}` !== percentValue,
    )
    return Boolean(isMixed)
  }

  addRow = resultType => {
    const { data } = this.state
    const { percent } = this.props
    const row = nullToStringObj({ ...initItem })
    row.id = shortId.generate()
    row.result_type = resultType
    row.typeList = itemsTypesList()
    if (percent >= 0) row.percent = percent
    data.push(row)
    this.setState({ data })
  }

  delRow = id => {
    const { data } = this.state
    const rowIndex = _.findIndex(data, row => row.id === id)
    if (rowIndex >= 0) {
      if (data[rowIndex].update !== 'new') {
        data[rowIndex].update = 'delete'
      } else {
        data.splice(rowIndex, 1)
      }
    }
    this.setState({ data })
  }

  dataToSend = () => {
    const { data } = this.state
    const coolData = stringToNull(data)
    const deletedItems = _.filter(coolData, row => row.update === 'delete')
    const updatedItems = _.filter(coolData, row => row.update === 'update')
    const newItems = _.filter(coolData, row => row.update === 'new')
    return { deletedItems, updatedItems, newItems }
  }

  // out API

  setCommonPercent = percent => {
    const { data } = this.state
    const { orderType, orderResultType } = this.props
    const updatedData = data.map(row => {
      const outRow = row
      if (
        row.percent !== percent &&
        row.update !== 'delete' &&
        !(
          orderResultType === 'complete' &&
          orderType === 'repair' &&
          row.result_type === 'longtermrepair'
        )
      ) {
        outRow.percent = percent
        if (row.update !== 'new') outRow.update = 'update'
      }
      return outRow
    })
    this.setState({ data: updatedData })
    return updatedData
  }

  incomeNdsChanged = (oldNds, newNds) =>
    this.state.data.map(row => {
      const price = round10(row.price_purchase * (1 + newNds / 100), -2)
      return {
        ...row,
        price_purchase_with_nds: price,
      }
      // if (type === 'checkbox') {
      //   console.log('BEFORE', row, newNds)
      //   const price = round10(+row.price_purchase * (1 + newNds / 100), -2)
      //   console.log('AFTER', {
      //     ...row,
      //     price_purchase_with_nds: price,
      //   })
      //   return {
      //     ...row,
      //     price_purchase_with_nds: price,
      //   }
      // } if (type === 'number') {
      //   console.log('BEFORE', row)
      //   const price = round10(row.price_purchase_with_nds -
      //     row.price_purchase_with_nds / (100 + newNds) * newNds, -2)
      //   console.log('AFTER', row)
      //   return {
      //     ...row,
      //     price_purchase: price,
      //   }
      // }
      // return {
      //   ...row,
      // }
    })

  validateForSave = () => {
    let isValid = true
    const { data } = this.state

    const validatedData = data.map(row => {
      const res = { ...row }
      if (row.name.length < 1 && row.update !== 'delete') {
        res.invalidName = true
        isValid = false
      }
      return res
    })
    if (!isValid) {
      this.setState({ data: validatedData })
      return { success: false }
    }

    return {
      payload: this.dataToSend(),
      success: true,
    }
  }

  validateForUnsavedData = () => {
    const { data } = this.state
    let isValid = true
    data.forEach(row => {
      if (
        row.update === 'update' ||
        row.update === 'new' ||
        row.update === 'delete'
      ) {
        isValid = false
      }
    })
    return { success: isValid }
  }

  validateForSendToClient = () => {
    const { data } = this.state
    const types = itemsTypes()
    const enumTypes = countEnumTypes()

    // проверим все ли поля заполненны
    let isAllFilled = true
    const validatedData = data.map(row => {
      const res = { ...row }
      if (row.name.length < 1 && row.update !== 'delete') {
        res.invalidName = true
        isAllFilled = false
      }
      if (
        !types.some(item => item.type === row.item_type) &&
        row.update !== 'delete'
      ) {
        res.invalidItemType = true
        isAllFilled = false
      }
      if (
        !enumTypes.some(item => item.type === row.units) &&
        row.update !== 'delete'
      ) {
        res.invalidUnits = true
        isAllFilled = false
      }
      if (
        (row.count === '' || !isNumber(row.count)) &&
        row.update !== 'delete'
      ) {
        res.invalidCount = true
        isAllFilled = false
      }
      if (
        (row.price_purchase === '' || !isNumber(row.price_purchase)) &&
        row.update !== 'delete'
      ) {
        res.invalidPricePurchase = true
        isAllFilled = false
      }
      if (
        (row.percent === '' || !isNumber(row.percent)) &&
        row.update !== 'delete'
      ) {
        res.invalidPercent = true
        isAllFilled = false
      }
      return res
    })
    if (!isAllFilled) {
      this.setState({ data: validatedData })
      return { success: false }
    }

    // проверим нет ли несохраненых значений
    const formSaved = this.validateForUnsavedData()
    if (!formSaved.success) return { success: false }

    return { success: true }
  }

  render() {
    const {
      props: {
        classes,
        orderType,
        orderResultType,
        orderPerformerOrganization,
        itemsNeedRepair,
        itemsDiagnostic,
        nds,
        overhead,
        inputNDS,
        itemsEditable,
        showClientVersion,
      },
      state: { data },
      addRow,
      delRow,
      handleChange,
    } = this
    const diagnosticTable = (
      <Fragment>
        <TableRow>
          <TableCell colSpan={10} className={classes.tableCell} align="center">
            <Typography variant="caption" className={classes.headerCell}>
              {`${i18next.t('service_desk_web_atoir:workPerformed')}`}
            </Typography>
          </TableCell>
        </TableRow>
        <CompletionCertificateTable
          orderPerformerOrganization={orderPerformerOrganization}
          addRow={(...args) => {
            addRow('diagnostic', ...args)
          }}
          delRow={delRow}
          inputNDS={inputNDS}
          overhead={overhead}
          handleChange={handleChange}
          data={_.filter(
            data,
            row => row.update !== 'delete' && row.result_type === 'diagnostic',
          )}
          ndsValue={nds}
          itemsEditable={itemsEditable}
          showClientVersion={showClientVersion}
        />
      </Fragment>
    )

    const needRepairTable = (
      <Fragment>
        <TableRow>
          <TableCell colSpan={10} className={classes.tableCell} align="center">
            <Typography variant="caption" className={classes.headerCell}>
              {`${i18next.t('service_desk_web_atoir:workRequired')}`}
            </Typography>
          </TableCell>
        </TableRow>
        <CompletionCertificateTable
          orderPerformerOrganization={orderPerformerOrganization}
          addRow={(...args) => {
            addRow('longtermrepair', ...args)
          }}
          delRow={delRow}
          overhead={overhead}
          handleChange={handleChange}
          data={_.filter(
            data,
            row =>
              row.update !== 'delete' && row.result_type === 'longtermrepair',
          )}
          ndsValue={nds}
          inputNDS={inputNDS}
          itemsEditable={
            orderResultType === 'complete' && orderType === 'repair'
              ? false
              : itemsEditable
          }
          showClientVersion={showClientVersion}
        />
      </Fragment>
    )

    return (
      <Fragment>
        <div className={classes.tableWrapper}>
          <Table
            className={
              itemsEditable ? classes.table : classes.tableEditDisabled
            }
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              classes={classes}
              markRequired={itemsEditable}
              showClientVersion={showClientVersion}
            />
            <TableBody>
              {((orderType === 'repair' ||
                (orderType === 'diagnostic' &&
                  orderResultType === 'complete')) &&
                diagnosticTable) ||
                (!!itemsDiagnostic &&
                  (itemsEditable
                    ? diagnosticTable
                    : itemsDiagnostic.length > 0
                    ? diagnosticTable
                    : null))}
              {(((orderResultType === 'needrepair' &&
                orderType === 'diagnostic') ||
                (orderResultType === 'complete' && orderType === 'repair')) &&
                needRepairTable) ||
                (!!itemsNeedRepair &&
                  orderResultType !== 'complete' &&
                  (itemsEditable
                    ? needRepairTable
                    : itemsNeedRepair.length > 0
                    ? needRepairTable
                    : null))}
            </TableBody>
          </Table>
        </div>
      </Fragment>
    )
  }
}

OrderItemsForm.defaultProps = {
  itemsNeedRepair: null,
  itemsEditable: true,
  showClientVersion: false,
}

OrderItemsForm.propTypes = {
  orderType: PropTypes.string.isRequired,
  orderPerformerOrganization: PropTypes.string.isRequired,
  orderResultType: PropTypes.string.isRequired,
  itemsDiagnostic: PropTypes.array.isRequired,
  itemsNeedRepair: PropTypes.array,
  percentSetMixed: PropTypes.func.isRequired,
  percent: PropTypes.number.isRequired,
  nds: PropTypes.number.isRequired,
  inputNDS: PropTypes.number.isRequired,
  itemsEditable: PropTypes.bool,
  showClientVersion: PropTypes.bool,
}

export default withStyles(styles)(OrderItemsForm)
