import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import SearchResult from './SearchResult'
import DialogActions from '@material-ui/core/DialogActions'
import TextButton from '../../../widgets/Buttons/TextButton'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { flexbox, spacing } from '@material-ui/system'
import { Typography } from '@material-ui/core'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'

const Box = styled.div`${spacing}${flexbox}`

const style = {
  root: {
    minWidth: 380,
  },
}

function EquipmentSuggestionsDialog({
  open,
  onSubmit,
  handleClose,
  baVariants,
  selectedId,
  setSelectedIdHandler,
  classes,
  title,
  message,
  submitText,
  cancelText,
  noSameEquipmentText = 'setNoSameEquipmentText',
  setNoSameEquipment,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle align="center" id="form-dialog-title">
        {title}
      </DialogTitle>
      <Typography align="center" color="textSecondary">
        {message}
      </Typography>
      <DialogContent>
        <SearchResult
          result={baVariants}
          {...{ selectedId, setSelectedIdHandler }}
        />
      </DialogContent>
      <DialogActions>
        <Box pr={1}>
          <TextButton onClick={handleClose} size="medium">
            {cancelText}
          </TextButton>
          {setNoSameEquipment && (
            <OutlinedButton onClick={setNoSameEquipment} size="medium">
              {noSameEquipmentText}
            </OutlinedButton>
          )}
          <ContainedButton
            size="medium"
            onClick={onSubmit}
            disabled={!selectedId}
          >
            {submitText}
          </ContainedButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(style)(EquipmentSuggestionsDialog)
