import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

export default withStyles({
  root: {
    display: 'inline-block%',
  },
})(({ children, classes }) => {
  function onClickHandler(event) {
    if (event.stopPropagation) {
      event.stopPropagation()
    }
  }
  return (
    <div role="presentation" className={classes.root} onClick={onClickHandler}>
      {children}
    </div>
  )
})
