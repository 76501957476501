import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import OutlinedButton from '../../../../../../../widgets/Buttons/OutlinedButton'
import i18next from '../../../../../../../i18n/i18n'
import ContainedButton from '../../../../../../../widgets/Buttons/ContainedButton'
import {
  deleteRecurringCallRequest,
  editRecurringCallRequest,
} from '../../../../../../../actions/serviceDesk'
import { useCloseAfterSuccess, useDisabled, useRecurringForm } from '../hooks'
import Form from '../form'
import TextButton from '../../../../../../../widgets/Buttons/TextButton'

const parseDateShort = string =>
  string.split(' ').map(d => ({
    value: (d.toLowerCase().match(/(\d+)[dymq]/) || [undefined, '1'])[1],
    unit: (d.toLowerCase().match(/\d+([dymq])/) || [undefined, 'd'])[1],
  }))

const EditRecurringDialog = ({
  open,
  handleClose,
  callId,
  recurringCall: {
    id,
    interval,
    start_date: callStartDate,
    end_date: callEndDate,
  },
  afterCloseFunction,
}) => {
  const intervalParsed = parseDateShort(interval)[0]
  const {
    intervalUnit,
    intervalValue,
    startDate,
    endDate,
    onChangeIntervalValue,
    onChangeIntervalUnit,
    onChangeStartDate,
    onChangeEndDate,
  } = useRecurringForm({
    unit: intervalParsed.unit,
    value: intervalParsed.value,
    date: new Date(callStartDate),
    dateEnd: callEndDate && new Date(callEndDate),
  })
  const dispatch = useDispatch()
  const { isFetching: isMaking, error } = useSelector(
    state => state.serviceDesk.editRecurringFromCall,
  )
  const { isFetching: isDeleting, error: deleteError } = useSelector(
    state => state.serviceDesk.deleteRecurringFromCall,
  )
  useCloseAfterSuccess(isMaking, error, callId, handleClose, afterCloseFunction)
  useCloseAfterSuccess(
    isDeleting,
    deleteError,
    callId,
    handleClose,
    afterCloseFunction,
  )
  const editRecurring = useCallback(
    () =>
      dispatch(
        editRecurringCallRequest({
          id,
          intervalUnit,
          intervalValue,
          startDate,
          endDate: endDate === '' ? null : endDate,
        }),
      ),
    [dispatch, intervalUnit, intervalValue, startDate, endDate],
  )
  const deleteRecurring = useCallback(
    () =>
      dispatch(
        deleteRecurringCallRequest({
          id,
        }),
      ),
    [dispatch],
  )
  const disabled = useDisabled(intervalValue, startDate, endDate)
  return (
    <Dialog open={!isMaking && open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        {i18next.t('service_desk_web_atoir:edit_recurring_call')}
      </DialogTitle>
      <DialogContent dividers>
        <Form
          intervalUnit={intervalUnit}
          intervalValue={intervalValue}
          startDate={startDate}
          endDate={endDate}
          onChangeIntervalValue={onChangeIntervalValue}
          onChangeIntervalUnit={onChangeIntervalUnit}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
        />
      </DialogContent>
      <DialogActions>
        <TextButton onClick={deleteRecurring} color="primary">
          {i18next.t('shared_web_atoir:delete')}
        </TextButton>
        <OutlinedButton onClick={handleClose} color="primary">
          {i18next.t('shared_web_atoir:cancel')}
        </OutlinedButton>
        <ContainedButton
          color="primary"
          onClick={editRecurring}
          disabled={disabled}
        >
          {i18next.t('shared_web_atoir:save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditRecurringDialog
