import React from 'react'
import styled, { css } from 'styled-components'

const Holder = styled.div`
  border-radius: 16px;
  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  ${({ current }) =>
    current &&
    css`
      background-color: rgba(0, 0, 0, 0.12);
    `}
`

function IconHolder({ children, current = false }) {
  return <Holder current={current}>{children}</Holder>
}

export default IconHolder
