import React from 'react'
import classNames from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { typography } from '@material-ui/system'
import Divider from '@material-ui/core/Divider'

const Inline = styled.span`
  ${typography}
`
const Row = withStyles({
  root: {
    boxShadow: 'none',
    marginTop: 4,
    marginBottom: 4,
    marginLeft: -8,
    marginRight: -8,
    border: '2px solid transparent',
  },
  selected: {
    border: '2px solid #1976d2',
  },
  cardContent: {
    padding: 8,
  },
})(function RowMUI({ classes, equipment, selectedId, setSelectedIdHandler }) {
  const onClickHandler = React.useCallback(() => {
    setSelectedIdHandler(equipment.id)
  }, [equipment, setSelectedIdHandler])
  return (
    <Card
      className={classNames(classes.root, {
        [classes.selected]: selectedId === equipment.id,
      })}
    >
      <CardActionArea onClick={onClickHandler}>
        <CardContent className={classes.cardContent}>
          <Typography color="textSecondary" variant="body2" component="div">
            {equipment.vendor || equipment.BAVendor.name}
          </Typography>
          <Typography variant="subtitle1" component="div">
            <Inline fontWeight={500}>{equipment.name}</Inline>
          </Typography>
          <Typography color="textSecondary" variant="body2" component="div">
            {equipment.model}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
})

const style = {
  divider: {
    marginLeft: -8,
    marginRight: -8,
  },
}

function SearchResult({
  result = [],
  selectedId,
  setSelectedIdHandler,
  classes,
}) {
  return (
    <React.Fragment>
      {result.map((equipment, index) => (
        <React.Fragment key={equipment.id}>
          <Row {...{ equipment, selectedId, setSelectedIdHandler }} />
          {index < result.length - 1 && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}
export default withStyles(style)(SearchResult)
