import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Typography from '@material-ui/core/Typography/Typography'

// modules
import FormDialog from '../../../../../widgets/FormDialog'

// i18
import i18next from '../../../../../i18n/i18n'

const ConfirmExitDialog = ({
  open,
  onLeave,
  onStay,
  leaveText,
  stayText,
  onClose,
}) => (
  <FormDialog
    closeIconHidden
    open={open}
    isLoading={false}
    title={i18next.t('shared_web_atoir:confirmFollow')}
    onClose={onClose || onStay}
    onSubmit={onStay}
    submitButtonTitle={stayText || i18next.t('shared_web_atoir:stayOnPage')}
    leftSecondaryButtonsArray={[
      {
        secondaryText: leaveText || i18next.t('shared_web_atoir:leavePage'),
        handleSecondaryAction: onLeave,
      },
    ]}
  >
    <div
      style={{
        minWidth: 500,
        minHeight: 80,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle">
        {i18next.t('shared_web_atoir:reqConfirmFollowWithDataLost')}
      </Typography>
    </div>
  </FormDialog>
)

ConfirmExitDialog.defaultProps = {
  leaveText: null,
  stayText: null,
  onClose: null,
}

ConfirmExitDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onLeave: PropTypes.func.isRequired,
  leaveText: PropTypes.string,
  stayText: PropTypes.string,
  onStay: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

export default ConfirmExitDialog
