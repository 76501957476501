import React, { useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import AuthImageLoader from '../AuthImageLoader'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  imageRoot: {
    objectFit: 'cover',
    width: '164px',
    height: '164px',
  },
  preview: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  root: {
    width: '164px',
    height: '164px',
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
  },
  closeIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
})

const DialogPaper = styled.div`
  height: 100%;
  width: 100%;
`

const PhotoCell = props => {
  const { url, classes, onDelete } = props
  const [preview, setPreview] = useState(null)

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth={false}
        open={preview}
        fullScreen
        keepMounted
        onClose={() => setPreview(false)}
      >
        <DialogPaper>
          <IconButton
            onClick={() => setPreview(false)}
            disableRipple
            className={classes.closeIcon}
          >
            <CloseIcon fontSize="small" color="secondary" />
          </IconButton>
          <AuthImageLoader className={classes.preview} url={url} />
        </DialogPaper>
      </Dialog>
      <AuthImageLoader
        className={classes.imageRoot}
        url={url}
        onClick={() => setPreview(url)}
      />
      {!!onDelete && (
        <IconButton
          onClick={onDelete}
          disableRipple
          className={classes.closeIcon}
        >
          <CloseIcon fontSize="small" color="secondary" />
        </IconButton>
      )}
    </div>
  )
}

const addStyles = theme => ({
  root: {
    width: '164px',
    height: '164px',
    position: 'relative',
    textAlign: 'center',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    marginTop: 8,
    marginLeft: 8,
  },
  iconRoot: {
    color: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.main,
      color: '#ffffff',
    },
  },
})

const AddPhoto = props => {
  const { classes, onClick } = props
  return (
    <div className={classes.root}>
      <IconButton
        id="addPhotoComponentAddButton"
        classes={{ root: classes.iconRoot }}
        onClick={onClick}
        disableRipple
      >
        <AddIcon />
      </IconButton>
    </div>
  )
}

export const AddPhotoComponent = withStyles(addStyles)(AddPhoto)

export default withStyles(styles)(PhotoCell)
