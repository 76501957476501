import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const star = '★'
class Rating extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      rating: Math.floor(props.rating),
      tempRating: null,
    }
  }

  rate = rating => {
    const {
      props: { getRating },
    } = this
    this.setState({
      rating,
      tempRating: rating,
    })
    getRating(rating)
  }

  starOver = newRating => {
    const {
      state: { rating },
    } = this

    this.setState({
      rating: newRating,
      tempRating: rating,
    })
  }

  starOut = () => {
    const {
      state: { tempRating },
    } = this
    this.setState({ rating: tempRating })
  }

  render = () => {
    const {
      props: { classes },
      state: { rating },
      rate,
      starOver,
      starOut,
    } = this

    return (
      <Fragment>
        {new Array(5).fill(0).map((value, index) => {
          const mark = index + 1
          return (
            // eslint-disable-next-line
              <span
              className={classNames(
                { [classes.redStar]: mark <= rating },
                { [classes.greyStar]: mark > rating },
                { [classes.bigStar]: this.props.bigStarts },
              )}
              onClick={() => rate(mark)}
              onMouseOver={() => starOver(mark)}
              onMouseOut={starOut}
            >
              {star}
            </span>
          )
        })}
      </Fragment>
    )
  }
}

const styles = () => ({
  bigStar: {
    fontSize: '42px',
  },
  redStar: {
    color: 'rgb(255,100,61)',
  },
  greyStar: {
    color: 'rgb(102,102,102)',
  },
})

Rating.defaultProps = {
  rating: 0,
}

Rating.propTypes = {
  rating: PropTypes.number,
  getRating: PropTypes.func.isRequired,
}

export default withStyles(styles)(Rating)
