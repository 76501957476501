import React from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import Loader from '../../../Loader'
import EditLcocationComponent from '../EditLocationComponent'

// material-ui
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import SnackBar from '@material-ui/core/Snackbar'

import { homeConcatHelper } from '../../../../helpers/parserHelper'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class UpdateLocationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDataValid: false,
      data: {},
      isUpdatingLocation: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
    }
  }

  componentWillReceiveProps(props) {
    if (this.state.isUpdatingLocation !== props.isUpdatingLocation) {
      if (props.error !== undefined && props.error !== null) {
        switch (+props.error) {
          case 55:
            this.showSnackBar(
              i18next.t('error_web_atoir:invalidLocationSpecified'),
            )
            break
          case 60:
            this.showSnackBar(i18next.t('error_web_atoir:dataValidationError'))
            break
          case 61:
            this.showSnackBar(
              i18next.t(
                'error_web_atoir:locationAlreadyExistsAndIsUniqueForOrganization',
              ),
            )
            break
          default:
            break
        }
      } else {
        props.onClose(
          i18next.t('organizations_web_atoir:locationUpdatedMessage'),
        )
        // props.fetchLocations()
      }
    }
    this.setState({
      ...props,
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  handleUpdate = () => {
    const { data } = this.state
    if (this.state.isDataValid) {
      this.props.updateLocation(
        this.props.organization.id,
        this.props.locationData.id,
        {
          name: data.name,
          home: homeConcatHelper(data.location.location.data),
          floor: data.floor,
          office: data.office,
          street_fias_id: data.location.location.data.street_fias_id,
          buildingId: data.location.location.id,
          location_type: this.props.locationData.location_type,
          location_value: data.location.location.value,
        },
        true,
      )
    }
  }

  onDataChanged = data => {
    this.setState({
      data,
      isDataValid: data.isValid,
    })
  }

  getSnackBar = () => (
    <SnackBar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  renderAddLocationDialog = () => {
    const { classes, open, locationData } = this.props
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullScreen
        aria-labelledby="responsive-dialog-title"
        onClose={this.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {i18next.t('organizations_web_atoir:addressEditingTitle')}
        </DialogTitle>
        <DialogContent>
          <EditLcocationComponent
            fetchLocation
            locationData={locationData}
            onChange={this.onDataChanged}
            classes={classes}
          />
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={this.handleClose} size="medium">
            {i18next.t('shared_web_atoir:close')}
          </OutlinedButton>
          <ContainedButton
            id="updateLocationComponentSubmitBtn"
            onClick={this.handleUpdate}
            disabled={!this.state.isDataValid}
            size="medium"
          >
            {i18next.t('shared_web_atoir:save')}
          </ContainedButton>
        </DialogActions>
        <Dialog
          open={this.props.isUpdatingLocation}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Loader />
          </DialogContent>
        </Dialog>
        {this.getSnackBar()}
      </Dialog>
    )
  }

  render() {
    return <div>{this.renderAddLocationDialog()}</div>
  }
}

UpdateLocationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default UpdateLocationComponent
