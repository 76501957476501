import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import _ from 'lodash'
import i18next from '../../../../../../i18n/i18n'

// helpers
import * as permissionResolvers from '../../../../../../helpers/callPermissionsResolver'
import {
  visitDateParserOrderSD,
  dateFormat,
} from '../../../../../../helpers/dateHelper'
import { inventoryLocationStringifyDefault } from '../../../../../../helpers/parserHelper'
import { getUserNameWithInitials } from '../../../../../../helpers/userHelper'
import { checkCondition } from '../../../../../../helpers/sdHepers'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/es/TextField'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// modules
import ContainedButton from '../../../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../../../widgets/Buttons/OutlinedButton'
import TextButton from '../../../../../../widgets/Buttons/TextButton'
import CancelOrderDialog from '../CancelOrderDialog'
import FormDialog from '../../../../../../widgets/FormDialog'
import AssignToOwnSpecialist from '../../../../AssignToOwnSpecialist'
import AssignToMeAsEngineer from '../../../../AssignToMeAsEngineer'
import SendToSubOrder from '../../../../SendToSubOrder'
import Dialog from '../../../../../Other/SimpleDialog'
import AddCompletionFile from '../../../../WorkCompletionReport/AddCompletionFile'
import ResponsibleInfo from '../../ResponsibleInfo'
import EngineerInfo from '../../EngineerInfo'
import VisitInfo from '../../VisitInfo'
import FormEditResponsible from '../FormEditResponsible'
import FormEditOrderInfo from '../FormEditOrderInfo'
import OrderDocuments from './OrderDocuments'
import OrderCertificate from './OrderCertificate'
import LongTextExpander from '../../../../../../widgets/LongTextExpander'
import SnackbarElContent from '../../../../../../widgets/SnackbarElContent'
import UnpaidTooltip from '../../../UnpaidTooltip'
import ClickWrapper from './ClickWrapper'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  tableCell: {
    border: 0,
  },
  tableRow: {
    height: 40,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.apply.lightest,
    },
  },
  textStyle: {
    color: 'black',
    textStyle: 'underlined',
    margin: theme.spacing(1),
  },
  organizationText: {
    color: theme.palette.text.main,
    fontSize: 18,
    fontWeight: 'normal',
  },
  locationText: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 'normal',
  },
  disableSDCallOrOrderActionsLabel: {
    fontSize: 14,
  },
  boldText: {
    color: theme.palette.text.main,
    fontSize: 12,
    fontWeight: 'bold',
  },
  expansionPanel: {
    overflowX: 'auto',
    width: '100%',
    alignContent: 'flex-start',
  },
  expansionPanelSummary: {
    justifyContent: 'flex-start',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
  },
  sectionAction: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
  textFieldMulty: {
    width: '500px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  icon: {
    color: theme.palette.text.main,
  },
  bottomMargin: {
    bottomMargin: theme.spacing(2),
  },
  expandIcon: {
    top: '0%',
    alignSelf: 'flex-start',
  },
  menuIcon: {
    color: theme.palette.text.medium,
  },
})

const orderButtonsPrepare = (orderButtons = [], payload, permissions) =>
  _.compact(
    orderButtons.map(button =>
      permissionResolvers.canViewSDOrderButton(permissions, button.scope) &&
      checkCondition(
        { simple: button.simple_conditions, logic: button.logic },
        payload,
      )
        ? button
        : null,
    ),
  )

class OrderRow extends Component {
  constructor(props) {
    super(props)
    this.refOrderItemsForm = React.createRef()
    this.inputFileRef = React.createRef()
    this.state = {
      expanded: false,
      cancelOrderDialogOpen: false,
      cancelReason: '',
      cancelReasonValid: true,
      rejectOrderDialogOpen: false,
      rejectOrderReason: '',
      rejectOrderReasonValid: null,
      anchorElAssign: null,
      openDialogOwnSpecialist: false,
      openDialogSendSubOrder: false,
      openDialogAssignToMe: false,
      anchorElSetOrderDoneRepairResult: null,
      // addFile: false,
      openDialogSetOrderResultDoneRepair: false,
      anchorElSetOrderNeedRepairResult: null,
      openDialogSetOrderResultNeedRepair: false,
      openDialogSetOrderResultWriteoff: false,
      expandMoreNote: false,
      openDialogEdit: null,
      anchorElAddDocumentMenu: null,
      openRejectCertificateOfCompletionDialog: false,
      rejectCertificateOfCompletionComment: '',
      rejectCertificateOfCompletionCommentValid: null,
      openAcceptCertificateOfCompletionDialog: false,
      openEditOrderVisitInfoDialog: false,
      openSnackbar: false,
      snackbarVariant: 'default',
      snackbarMessage: '',
      nds: 0,
      overhead: 0,
      inputWithNDS: 0,
      anchorElButtons: null,
    }
  }

  componentDidMount() {
    const {
      props: {
        order,
        orderPermissions,
        getEngineerSettingsRequest,
        getCommonOrganizationSettingsRequest,
        fetchOrderNdsRequest,
        fetchOrderOverheadRequest,
        fetchOrderIncomeNdsRequest,
        getOrderPossibleReports,
        type,
        fetchOrder,
      },
    } = this
    if (
      order.wip &&
      permissionResolvers.canGetNds(order.status, orderPermissions)
    ) {
      fetchOrderNdsRequest({ orderId: order.id })
      fetchOrderOverheadRequest({ orderId: order.id })
      fetchOrderIncomeNdsRequest({ orderId: order.id })
    }
    if (
      permissionResolvers.canGetEngineerSettings(order.status, orderPermissions)
    ) {
      getEngineerSettingsRequest({
        organizationId: order.performer_organization.id,
      })
      getCommonOrganizationSettingsRequest({
        organizationId: order.performer_organization.id,
      })
    }
    if (order.wip) {
      getOrderPossibleReports({ orderId: order.id })
    }

    fetchOrder({ id: order.id, params: '', type })
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        isFetchingRejectOrder,
        fetchRejectOrderSuccess,
        fetchingRejectOrderError,
        isFetchingNds,
        isFetchingOverhead,
        isFetchingIncomeNds,
        inputWithNDS,
        overhead,
        nds,
        orderButtonExecuting,
      },
    } = this

    if (prevProps.isFetchingNds !== isFetchingNds && !isFetchingNds) {
      this.setState({ nds })
    }
    if (
      prevProps.isFetchingIncomeNds !== isFetchingIncomeNds &&
      !isFetchingIncomeNds
    ) {
      this.setState({ inputWithNDS })
    }
    if (
      prevProps.isFetchingOverhead !== isFetchingOverhead &&
      !isFetchingOverhead
    ) {
      this.setState({ overhead })
    }

    if (
      prevProps.isFetchingRejectOrder !== isFetchingRejectOrder &&
      !isFetchingRejectOrder
    ) {
      if (!fetchRejectOrderSuccess && fetchingRejectOrderError === 286) {
        this.openSnackbar(
          'error',
          i18next.t('service_desk_web_atoir:cantRejectOrderFromContract'),
        )
      }
    }

    if (
      prevProps.orderButtonExecuting !== orderButtonExecuting &&
      !orderButtonExecuting
    ) {
      this.handleButtonsMenuClose()
    }
  }

  openSnackbar = (variant, message) => {
    this.setState({
      openSnackbar: true,
      snackbarVariant: variant,
      snackbarMessage: message,
    })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      openSnackbar: false,
      snackbarVariant: 'default',
      snackbarMessage: '',
    })
  }

  onExpansionChange = () => {
    const {
      props: { type, order, fetchOrder },
      state: { expanded },
    } = this
    this.setState({ expanded: !expanded }, () => {
      if (this.state.expanded) {
        fetchOrder({ id: order.id, params: '', type })
      }
    })
  }

  onToggle = (field, value) => event => {
    if (field === 'openEditOrderVisitInfoDialog') {
      if (!isEmpty(event) && !isEmpty(event.stopPropagation)) {
        event.stopPropagation()
      }
    } else if (event) {
      event.stopPropagation()
    }
    this.setState({ [field]: value })
  }

  cancelOrderDialogClose = () => {
    this.setState({
      cancelReason: '',
      cancelReasonValid: true,
      cancelOrderDialogOpen: false,
    })
  }

  cancelReasonEdit = event => {
    this.setState({
      cancelReason: event.target.value,
      cancelReasonValid: event.target.value.length > 0,
    })
  }

  cancelOrder = () => {
    const {
      props: { order, cancelOrderByClientRequest },
      state: { cancelReason },
      cancelOrderDialogClose,
    } = this
    if (this.state.cancelReason.length > 0) {
      cancelOrderDialogClose()
      cancelOrderByClientRequest({
        orderId: order.id,
        cancelComment: cancelReason,
      })
    } else {
      this.setState({ cancelReasonValid: false })
      this.setState({ cancelReasonValid: false })
    }
  }

  rejectOrderDialogClose = () => {
    this.setState({
      rejectOrderDialogOpen: false,
      rejectOrderReason: '',
      rejectOrderReasonValid: undefined,
    })
  }

  rejectOrderReasonChange = event => {
    event.preventDefault()
    this.setState({
      rejectOrderReason: event.target.value,
      rejectOrderReasonValid: !isEmpty(event.target.value),
    })
  }

  rejectOrder = () => {
    const {
      state: { rejectOrderReason, rejectOrderReasonValid },
      props: { order, rejectOrderRequest },
    } = this
    if (rejectOrderReasonValid) {
      this.setState({
        rejectOrderDialogOpen: false,
        rejectOrderReason: '',
        rejectOrderReasonValid: undefined,
      })
      rejectOrderRequest({
        id: order.id,
        reject_comment: rejectOrderReason,
      })
    } else {
      this.setState({
        rejectOrderReasonValid: false,
      })
    }
  }

  showAssignMenu = event => {
    if (event) {
      event.stopPropagation()
    }
    this.setState({ anchorElAssign: event.currentTarget })
  }

  assignToOwnSpecialist = flag => event => {
    if (event) {
      event.stopPropagation()
    }
    this.setState({ openDialogOwnSpecialist: flag })
    this.onToggle('anchorElAssign', null)()
  }

  closeAssignToOwnSpecialist = () => {
    this.setState({ openDialogOwnSpecialist: false })
    this.onToggle('anchorElAssign', null)()
  }

  submitAssignToOwnSpecialist = data => {
    const {
      props: { order, assignOwnSpecialist },
      assignToOwnSpecialist,
    } = this
    assignOwnSpecialist({
      id: order.id,
      data,
    })
    assignToOwnSpecialist(false)
  }

  checkDialogSendSubOrder = flag => event => {
    if (event) {
      event.stopPropagation()
    }
    this.setState({ openDialogSendSubOrder: flag })
    this.onToggle('anchorElAssign', null)()
  }

  closeDialogSendSubOrder = () => {
    this.setState({ openDialogSendSubOrder: false })
    this.onToggle('anchorElAssign', null)()
  }

  submitSendSubOrder = data => {
    const {
      props: { order, sentOrderToSubOrder },
      checkDialogSendSubOrder,
    } = this
    checkDialogSendSubOrder(false)()
    sentOrderToSubOrder({
      id: order.id,
      data: {
        performer_organization_id: data.organization,
        visit_comment: data.comment,
      },
    })
  }

  closeAssignToMe = () => {
    this.setState({ openDialogAssignToMe: false })
  }

  submitAssignToMe = data => {
    const {
      props: { order, assignOwnSpecialist },
      assignToOwnSpecialist,
    } = this
    assignOwnSpecialist({
      id: order.id,
      data,
    })
    assignToOwnSpecialist(false)
  }

  showSetOrderDoneRepairResultMenu = event => {
    if (event) {
      event.stopPropagation()
    }
    this.setState({ anchorElSetOrderDoneRepairResult: event.currentTarget })
  }

  openSetOrderDoneRepairDialog = type => {
    const {
      props: {
        setOrderResultReportingType,
        order: { id },
      },
    } = this
    setOrderResultReportingType({ type, id })
    if (type === 'file') {
      this.setState({ openDialogSetOrderResultDoneRepair: true })
    }
    if (type === 'report') {
      this.setState({ openDialogSetOrderResultDoneRepair: true })
    }
  }

  closeSetOrderDoneRepairDialog = () => {
    this.setState({ openDialogSetOrderResultDoneRepair: false })
    if (!this.props.shouldSetOrderResultReport) {
      this.props.setOrderResultReportingType({ type: null, id: null })
    }
  }

  showSetOrderNeedRepairResultMenu = event => {
    if (event) {
      event.stopPropagation()
    }
    this.setState({ anchorElSetOrderNeedRepairResult: event.currentTarget })
  }

  openSetOrderNeedRepairDialog = type => {
    const {
      props: {
        setOrderResultReportingType,
        order: { id },
      },
    } = this
    setOrderResultReportingType({ type, id })
    if (type === 'file') {
      this.setState({ openDialogSetOrderResultNeedRepair: true })
    }
    if (type === 'report') {
      this.setState({ openDialogSetOrderResultNeedRepair: true })
    }
  }

  closeSetOrderNeedRepairDialog = () => {
    this.setState({ openDialogSetOrderResultNeedRepair: false })
    if (!this.props.shouldSetOrderResultReport) {
      this.props.setOrderResultReportingType({ type: null, id: null })
    }
  }

  openSetOrderWriteoffDialog = event => {
    if (!isEmpty(event) && !isEmpty(event.stopPropagation)) {
      event.stopPropagation()
    }
    const {
      props: {
        setOrderResultReportingType,
        order: { id },
      },
    } = this
    setOrderResultReportingType({ type: 'file', id })
    this.setState({ openDialogSetOrderResultWriteoff: true })
  }

  closeSetOrderWriteoffDialog = event => {
    if (!isEmpty(event) && !isEmpty(event.stopPropagation)) {
      event.stopPropagation()
    }
    this.setState({ openDialogSetOrderResultWriteoff: false })
    if (!this.props.shouldSetOrderResultReport) {
      this.props.setOrderResultReportingType({ type: null, id: null })
    }
  }

  setOrderRepairResult = type => {
    const {
      props: { order, setOrderResultTypeRequest },
    } = this
    setOrderResultTypeRequest({ id: order.id, body: { type } })
  }

  renderNote = note => (
    <Fragment>
      <Typography variant="caption" color="textSecondary">
        {`${i18next.t('service_desk_web_atoir:note')}: ${note}`}
      </Typography>
    </Fragment>
  )

  renderComment = (comment, type) => (
    <LongTextExpander
      Wrapper={Typography}
      WrapperProps={{ variant: 'subtitle1' }}
    >
      {`${i18next.t(`service_desk_web_atoir:${type}OrderComment`)}: ${comment}`}
    </LongTextExpander>
  )

  handleMenuOpen = event => {
    if (event) {
      event.stopPropagation()
    }
    this.setState({ anchorElAddDocumentMenu: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorElAddDocumentMenu: null })
  }

  handleAttach = () => {
    this.handleMenuClose()
    this.inputFileRef.current.openFileDialog()
  }

  handleRedirectToWorkCompletionReport = () => {
    const {
      props: { history, match, order },
    } = this
    history.push(`${match.url}/orders/${order.id}/work_completion_report`)
  }

  handleRedirectToComments = () => {
    const {
      props: { history, match, order },
    } = this
    history.push(`${match.url}/orders/${order.id}/comments`)
  }

  onRejectCertificateOfCompletionReasonEdit = event => {
    event.preventDefault()
    this.setState({
      rejectCertificateOfCompletionComment: event.target.value,
      rejectCertificateOfCompletionCommentValid: !isEmpty(event.target.value),
    })
  }

  showRejectCertificateOfCompletionDialog = () => {
    this.setState({
      openRejectCertificateOfCompletionDialog: true,
    })
  }

  closeRejectCertificateOfCompletionDialog = () => {
    this.setState({
      openRejectCertificateOfCompletionDialog: false,
      rejectCertificateOfCompletionCommentValid: null,
    })
  }

  onRejectCertificateOfCompletionInDone = () => {
    const {
      props: { order, rejectOrderCertificateOfCompletionRequest },
      state: { rejectCertificateOfCompletionComment },
      closeRejectCertificateOfCompletionDialog,
    } = this
    if (!isEmpty(rejectCertificateOfCompletionComment)) {
      const payload = {
        orderId: order.id,
        rejectСomment: rejectCertificateOfCompletionComment,
      }
      rejectOrderCertificateOfCompletionRequest(payload)
      closeRejectCertificateOfCompletionDialog()
    } else {
      this.setState({ rejectCertificateOfCompletionCommentValid: false })
    }
  }

  showAcceptCertificateOfCompletionDialog = () => {
    this.setState({ openAcceptCertificateOfCompletionDialog: true })
  }

  closeAcceptCertificateOfCompletionDialog = () => {
    this.setState({ openAcceptCertificateOfCompletionDialog: false })
  }

  onAcceptCertificateOfCompletionInDone = () => {
    const {
      props: { order, acceptOrderCertificateOfCompletionRequest },
    } = this
    acceptOrderCertificateOfCompletionRequest({ orderId: order.id })
  }

  clickSummary = event => {
    event.stopPropagation()
  }

  handleButtonsMenuOpen = event => {
    event.stopPropagation()
    this.setState({ anchorElButtons: event.currentTarget })
  }

  handleButtonsMenuClose = event => {
    this.setState({ anchorElButtons: null })
    if (!isEmpty(event)) {
      event.stopPropagation()
    }
  }

  onDisableActionsClick = orderId => ({ target: { checked } }) => {
    const { toggleDisableScripts } = this.props
    toggleDisableScripts({
      value: checked,
      orderId,
    })
  }

  render() {
    const {
      props: {
        classes,
        type,
        order,
        orderPermissions,
        commonOrderPermissions,
        isFetchingCancelOrder,
        isFetchingRejectOrder,
        isSettingOrderResultType,
        commonOrganizationSettings,
        confirmCertificateOfCompletionRequest,
        isRejectingCertificateOfCompletion,
        orderButtons,
        orderButtonExecute,
        disableScriptsForIds,
        callScripts,
        call,
        shouldSetOrderResultReport,
        orderResultReportingType,
        setOrderResultReportingType,
        resultReportingTypeOrderId,
        shouldSetOrderResultReportAction,
      },
      state: {
        cancelOrderDialogOpen,
        cancelReasonValid,
        rejectOrderDialogOpen,
        rejectOrderReasonValid,
        anchorElAssign,
        openDialogOwnSpecialist,
        openDialogSendSubOrder,
        openDialogAssignToMe,
        anchorElSetOrderDoneRepairResult,
        openDialogSetOrderResultDoneRepair,
        anchorElSetOrderNeedRepairResult,
        openDialogSetOrderResultNeedRepair,
        openDialogSetOrderResultWriteoff,
        expandMoreNote,
        openDialogEdit,
        anchorElAddDocumentMenu,
        openRejectCertificateOfCompletionDialog,
        rejectCertificateOfCompletionCommentValid,
        openAcceptCertificateOfCompletionDialog,
        openEditOrderVisitInfoDialog,
        openSnackbar,
        snackbarVariant,
        snackbarMessage,
        nds,
        inputWithNDS,
        overhead,
        anchorElButtons,
      },
      onExpansionChange,
      onToggle,
      cancelOrderDialogClose,
      cancelReasonEdit,
      cancelOrder,
      rejectOrderDialogClose,
      rejectOrderReasonChange,
      rejectOrder,
      showAssignMenu,
      assignToOwnSpecialist,
      submitAssignToOwnSpecialist,
      checkDialogSendSubOrder,
      submitSendSubOrder,
      submitAssignToMe,
      showSetOrderDoneRepairResultMenu,
      openSetOrderDoneRepairDialog,
      closeSetOrderDoneRepairDialog,
      showSetOrderNeedRepairResultMenu,
      openSetOrderNeedRepairDialog,
      closeSetOrderNeedRepairDialog,
      openSetOrderWriteoffDialog,
      closeSetOrderWriteoffDialog,
      setOrderRepairResult,
      renderNote,
      handleMenuOpen,
      handleMenuClose,
      handleAttach,
      handleRedirectToWorkCompletionReport,
      handleRedirectToComments,
      onRejectCertificateOfCompletionReasonEdit,
      showRejectCertificateOfCompletionDialog,
      closeRejectCertificateOfCompletionDialog,
      onRejectCertificateOfCompletionInDone,
      showAcceptCertificateOfCompletionDialog,
      closeAcceptCertificateOfCompletionDialog,
      onAcceptCertificateOfCompletionInDone,
      clickSummary,
      closeDialogSendSubOrder,
      closeAssignToOwnSpecialist,
      closeAssignToMe,
      renderComment,
      onDisableActionsClick,
    } = this
    if (shouldSetOrderResultReport) {
      if (
        orderResultReportingType === 'file' &&
        !isEmpty(this.inputFileRef.current) &&
        order.id === resultReportingTypeOrderId
      ) {
        this.inputFileRef.current.openFileDialog()
        setOrderResultReportingType({ type: null, id: null })
        shouldSetOrderResultReportAction(false)
      }
      if (
        orderResultReportingType === 'report' &&
        order.id === resultReportingTypeOrderId
      ) {
        handleRedirectToWorkCompletionReport()
        setOrderResultReportingType({ type: null, id: null })
        shouldSetOrderResultReportAction(false)
      }
    }

    const orderItems = order.ServiceCallOrderItems
    const orderItemsDiagnostic = !isEmpty(orderItems)
      ? _.filter(orderItems, item => item.result_type === 'diagnostic')
      : []
    const orderItemsNeedRepair = !isEmpty(orderItems)
      ? _.filter(orderItems, item => item.result_type === 'longtermrepair')
      : []
    const isAnyActionAvailableToOrderPerformer = permissionResolvers.canOrderEditReportingInfo(
      order.status,
      orderPermissions,
    )
    let isAnyScriptAvailableToOrderPerformer = false
    if (!isEmpty(callScripts)) {
      const orderPerfOrgId = order.performer_organization.id
      isAnyScriptAvailableToOrderPerformer = Boolean(
        _.filter(
          callScripts,
          script => script.organization_id === orderPerfOrgId,
        ).length,
      )
    }
    const isAnyActionAvailableToOrderClient = permissionResolvers.canAcceptOrRejectCertificateInDone(
      order.status,
      orderPermissions,
    )
    let isAnyScriptAvailableToOrderClient = false
    if (!isEmpty(callScripts)) {
      const orderClientOrgId = order.client_organization.id
      isAnyScriptAvailableToOrderClient = Boolean(
        _.filter(
          callScripts,
          script => script.organization_id === orderClientOrgId,
        ).length,
      )
    }

    return (
      <Fragment>
        <ExpansionPanel
          expanded={this.state.expanded}
          onClick={clickSummary}
          onChange={onExpansionChange}
          elevation={0}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={() => {
                  this.setState(state => ({ expanded: !state.expanded }))
                }}
              />
            }
            className={classes.expansionPanelSummary}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  {order.status_enhanced
                    ? i18next.t(
                        `service_desk_web_atoir:orders_${order.status_enhanced}_status`,
                      )
                    : order.status_enhanced && order.status === 'control'
                    ? i18next.t(
                        `service_desk_web_atoir:${order.status}${order.type_order}Status`,
                      )
                    : i18next.t(`service_desk_web_atoir:${order.status}Status`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {order.type_order
                    ? order.type_order === 'diagnostic'
                      ? order.order_result === 'needrepair'
                        ? i18next.t(
                            'service_desk_web_atoir:orderTypeDateTitle',
                            {
                              typeOrder: i18next.t(
                                'service_desk_web_atoir:diagnostic',
                              ),
                              date: dateFormat(order.createdAt),
                            },
                          )
                        : order.order_result === 'writeoff'
                        ? i18next.t(
                            'service_desk_web_atoir:orderTypeDateTitle',
                            {
                              typeOrder: i18next.t(
                                'service_desk_web_atoir:writeoff',
                              ),
                              date: dateFormat(order.createdAt),
                            },
                          )
                        : i18next.t(
                            'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepairAtDate',
                            { date: dateFormat(order.createdAt) },
                          )
                      : i18next.t('service_desk_web_atoir:orderTypeDateTitle', {
                          typeOrder: i18next.t('service_desk_web_atoir:repair'),
                          date: dateFormat(order.createdAt),
                        })
                    : i18next.t(
                        'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepairAtDate',
                        {
                          date: dateFormat(order.createdAt),
                        },
                      )}
                </Typography>
              </Grid>
              {type !== 'canceled' ||
              (type === 'canceled' && this.state.expanded) ? (
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {`${i18next.t('service_desk_web_atoir:client')}:
                        ${
                          !isEmpty(order.client_organization)
                            ? order.client_organization.short_name
                            : ''
                        }`}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {`${i18next.t('service_desk_web_atoir:performer')}:
                        ${
                          !isEmpty(order.performer_organization)
                            ? order.performer_organization.short_name
                            : ''
                        }`}
                      </Typography>
                      {permissionResolvers.canViewEngineer(orderPermissions) &&
                        !isEmpty(order.engineer_user) && (
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                          >
                            {`${i18next.t(
                              'service_desk_web_atoir:engineer',
                            )}: ${getUserNameWithInitials(
                              order.engineer_user,
                            )}`}
                          </Typography>
                        )}
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {`${i18next.t('service_desk_web_atoir:dateVisit')}:
                              ${visitDateParserOrderSD(order, call)}`}
                      </Typography>
                      {!isEmpty(order.visit_comment) &&
                        renderNote(
                          order.visit_comment,
                          expandMoreNote,
                          classes,
                        )}
                      {!isEmpty(order.reject_comment) &&
                        renderComment(order.reject_comment, 'reject')}
                      {!isEmpty(order.revision_comment) &&
                        renderComment(order.revision_comment, 'revision')}
                      {!isEmpty(order.cancel_comment) &&
                        renderComment(order.cancel_comment, 'cancel')}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {order.status !== 'cancel' ||
              (order.status === 'cancel' && this.state.expanded) ? (
                <Grid item xs={12}>
                  <TextButton onClick={handleRedirectToComments}>
                    {`${i18next.t('service_desk_web_atoir:comments')} (${
                      !isEmpty(order.comment_count) ? order.comment_count : '0'
                    }) >>`}
                  </TextButton>
                </Grid>
              ) : null}
              {!!order && order.status !== 'cancel' ? (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    direction="row"
                  >
                    <Grid item>
                      {!!orderPermissions &&
                      ((permissionResolvers.isOnPerformerSide(
                        order.status,
                        orderPermissions,
                      ) &&
                        isAnyActionAvailableToOrderPerformer &&
                        isAnyScriptAvailableToOrderPerformer) ||
                        (permissionResolvers.isOnClientCallSide(
                          order.status,
                          orderPermissions,
                        ) &&
                          isAnyActionAvailableToOrderClient &&
                          isAnyScriptAvailableToOrderClient)) ? (
                        <FormControlLabel
                          classes={{
                            label: classes.disableSDCallOrOrderActionsLabel,
                          }}
                          value="start"
                          control={
                            <Checkbox
                              checked={disableScriptsForIds[order.id]}
                              onClick={onDisableActionsClick(order.id)}
                            />
                          }
                          label={i18next.t(
                            'service_desk_web_atoir:disableSDCallOrOrderActions',
                          )}
                          labelPlacement="end"
                        />
                      ) : null}
                      {!!orderPermissions &&
                      permissionResolvers.canCancelOrder(
                        order.status,
                        orderPermissions,
                      ) ? (
                        <TextButton
                          onClick={onToggle('cancelOrderDialogOpen', true)}
                        >
                          {i18next.t('service_desk_web_atoir:cancelOrder')}
                        </TextButton>
                      ) : null}
                      {!!orderPermissions &&
                      permissionResolvers.canRejectOrder(
                        order.status,
                        orderPermissions,
                        order.type_order,
                      ) ? (
                        <OutlinedButton
                          onClick={onToggle('rejectOrderDialogOpen', true)}
                        >
                          {i18next.t('service_desk_web_atoir:reject')}
                        </OutlinedButton>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2}>
                        {!!orderPermissions &&
                        permissionResolvers.canOrderAssign(
                          order.status,
                          orderPermissions,
                          order.type_order,
                        ) ? (
                          <Grid item>
                            <Fragment>
                              {permissionResolvers.canOrderAssignToMe(
                                order.status,
                                orderPermissions,
                              ) ? (
                                <UnpaidTooltip
                                  disabled={permissionResolvers.canOperateButton(
                                    commonOrderPermissions,
                                    'orders',
                                    'performer',
                                  )}
                                >
                                  <ContainedButton
                                    disabled={
                                      !permissionResolvers.canOperateButton(
                                        commonOrderPermissions,
                                        'orders',
                                        'performer',
                                      )
                                    }
                                    onClick={onToggle(
                                      'openDialogAssignToMe',
                                      true,
                                    )}
                                  >
                                    {i18next.t(
                                      'service_desk_web_atoir:takePlaceToMe',
                                    )}
                                  </ContainedButton>
                                </UnpaidTooltip>
                              ) : null}
                              {permissionResolvers.canOrderAssignAsManagerOrHigher(
                                order.status,
                                orderPermissions,
                              ) ? (
                                <UnpaidTooltip
                                  disabled={permissionResolvers.canOperateButton(
                                    commonOrderPermissions,
                                    'orders',
                                    'performer',
                                  )}
                                >
                                  <ContainedButton
                                    disabled={
                                      !permissionResolvers.canOperateButton(
                                        commonOrderPermissions,
                                        'orders',
                                        'performer',
                                      )
                                    }
                                    onClick={showAssignMenu}
                                  >
                                    {i18next.t(
                                      'service_desk_web_atoir:acceptAndSetEngineer',
                                    )}
                                  </ContainedButton>
                                </UnpaidTooltip>
                              ) : null}
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorElAssign}
                                open={Boolean(anchorElAssign)}
                                onClose={onToggle('anchorElAssign', null)}
                              >
                                {permissionResolvers.canOrderAssignToOwnSpecialist(
                                  order.status,
                                  orderPermissions,
                                ) ? (
                                  <MenuItem
                                    onClick={assignToOwnSpecialist(true)}
                                  >
                                    {i18next.t(
                                      'service_desk_web_atoir:fromOwnSpecialist',
                                    )}
                                  </MenuItem>
                                ) : null}
                                {permissionResolvers.canOrderAssignToSubOrder(
                                  order.status,
                                  orderPermissions,
                                ) ? (
                                  <MenuItem
                                    onClick={checkDialogSendSubOrder(true)}
                                  >
                                    {i18next.t(
                                      'service_desk_web_atoir:sendOrderToSubOrder',
                                    )}
                                  </MenuItem>
                                ) : null}
                              </Menu>
                            </Fragment>
                          </Grid>
                        ) : null}
                        {order.wip &&
                        !!orderPermissions &&
                        order.type_order === 'diagnostic' &&
                        !order.order_result &&
                        permissionResolvers.canOrderSetResult(
                          order.status,
                          orderPermissions,
                        ) ? (
                          <Grid item>
                            <ClickWrapper>
                              <Grid container spacing={1} direction="row">
                                <Grid item>
                                  <UnpaidTooltip
                                    disabled={permissionResolvers.canOperateButton(
                                      commonOrderPermissions,
                                      'orders',
                                      'performer',
                                    )}
                                  >
                                    <OutlinedButton
                                      disabled={
                                        !permissionResolvers.canOperateButton(
                                          commonOrderPermissions,
                                          'orders',
                                          'performer',
                                        )
                                      }
                                      onClick={showSetOrderNeedRepairResultMenu}
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:doneDiagnosticAction',
                                      )}
                                    </OutlinedButton>
                                  </UnpaidTooltip>
                                  <Menu
                                    id="setNeedRepairMenu"
                                    anchorEl={anchorElSetOrderNeedRepairResult}
                                    open={Boolean(
                                      anchorElSetOrderNeedRepairResult,
                                    )}
                                    onClose={onToggle(
                                      'anchorElSetOrderNeedRepairResult',
                                      null,
                                    )}
                                  >
                                    <MenuItem
                                      onClick={event => {
                                        onToggle(
                                          'anchorElSetOrderNeedRepairResult',
                                          null,
                                        )(event)
                                        openSetOrderNeedRepairDialog('report')
                                      }}
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:fillFormMenuAction',
                                      )}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={event => {
                                        onToggle(
                                          'anchorElSetOrderNeedRepairResult',
                                          null,
                                        )(event)
                                        openSetOrderNeedRepairDialog('file')
                                      }}
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:addActMenuAction',
                                      )}
                                    </MenuItem>
                                  </Menu>
                                  <Dialog
                                    isOpen={openDialogSetOrderResultNeedRepair}
                                    content={i18next.t(
                                      'service_desk_web_atoir:needRepairDialogDescription',
                                    )}
                                    handleClose={closeSetOrderNeedRepairDialog}
                                    handleNegative={
                                      closeSetOrderNeedRepairDialog
                                    }
                                    handlePositive={() =>
                                      setOrderRepairResult('needrepair')
                                    }
                                    negativeText={i18next.t(
                                      'shared_web_atoir:cancel',
                                    )}
                                    positiveText={i18next.t(
                                      'service_desk_web_atoir:needRepairAction',
                                    )}
                                    closeIconHidded
                                    isLoading={isSettingOrderResultType}
                                  />
                                </Grid>
                                <Grid item>
                                  <UnpaidTooltip
                                    disabled={permissionResolvers.canOperateButton(
                                      commonOrderPermissions,
                                      'orders',
                                      'performer',
                                    )}
                                  >
                                    <OutlinedButton
                                      disabled={
                                        !permissionResolvers.canOperateButton(
                                          commonOrderPermissions,
                                          'orders',
                                          'performer',
                                        )
                                      }
                                      onClick={event =>
                                        openSetOrderWriteoffDialog(event)
                                      }
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:writeoffDiagnosticAction',
                                      )}
                                    </OutlinedButton>
                                  </UnpaidTooltip>
                                  <Dialog
                                    isOpen={openDialogSetOrderResultWriteoff}
                                    content={i18next.t(
                                      'service_desk_web_atoir:writeoffDialogDescription',
                                    )}
                                    handleClose={closeSetOrderWriteoffDialog}
                                    handleNegative={closeSetOrderWriteoffDialog}
                                    handlePositive={() =>
                                      setOrderRepairResult('writeoff')
                                    }
                                    negativeText={i18next.t(
                                      'shared_web_atoir:cancel',
                                    )}
                                    positiveText={i18next.t(
                                      'service_desk_web_atoir:writeoffAction',
                                    )}
                                    closeIconHidded
                                    isLoading={isSettingOrderResultType}
                                  />
                                </Grid>
                                <Grid item>
                                  <UnpaidTooltip
                                    disabled={permissionResolvers.canOperateButton(
                                      commonOrderPermissions,
                                      'orders',
                                      'performer',
                                    )}
                                  >
                                    <OutlinedButton
                                      disabled={
                                        !permissionResolvers.canOperateButton(
                                          commonOrderPermissions,
                                          'orders',
                                          'performer',
                                        )
                                      }
                                      onClick={showSetOrderDoneRepairResultMenu}
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:doneRepairAction',
                                      )}
                                    </OutlinedButton>
                                  </UnpaidTooltip>
                                  <Menu
                                    id="setDoneRepairMenu"
                                    anchorEl={anchorElSetOrderDoneRepairResult}
                                    open={Boolean(
                                      anchorElSetOrderDoneRepairResult,
                                    )}
                                    onClose={onToggle(
                                      'anchorElSetOrderDoneRepairResult',
                                      null,
                                    )}
                                  >
                                    <MenuItem
                                      onClick={event => {
                                        onToggle(
                                          'anchorElSetOrderDoneRepairResult',
                                          null,
                                        )(event)
                                        openSetOrderDoneRepairDialog('report')
                                      }}
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:fillFormMenuAction',
                                      )}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={event => {
                                        onToggle(
                                          'anchorElSetOrderDoneRepairResult',
                                          null,
                                        )(event)
                                        openSetOrderDoneRepairDialog('file')
                                      }}
                                    >
                                      {i18next.t(
                                        'service_desk_web_atoir:addActMenuAction',
                                      )}
                                    </MenuItem>
                                  </Menu>
                                  <Dialog
                                    isOpen={openDialogSetOrderResultDoneRepair}
                                    content={i18next.t(
                                      'service_desk_web_atoir:doneRepairDialogDescription',
                                    )}
                                    handleClose={closeSetOrderDoneRepairDialog}
                                    handleNegative={
                                      closeSetOrderDoneRepairDialog
                                    }
                                    handlePositive={() =>
                                      setOrderRepairResult('complete')
                                    }
                                    negativeText={i18next.t(
                                      'shared_web_atoir:cancel',
                                    )}
                                    positiveText={i18next.t(
                                      'service_desk_web_atoir:readyForWork',
                                    )}
                                    closeIconHidded
                                    isLoading={isSettingOrderResultType}
                                  />
                                </Grid>
                              </Grid>
                            </ClickWrapper>
                          </Grid>
                        ) : null}
                        {order.wip &&
                        !!orderPermissions &&
                        !!order.order_result &&
                        ((order.type_order === 'repair' &&
                          _.find(
                            order.ServiceCallOrderFile,
                            f => f.file_type === 'act_repair',
                          )) ||
                          (order.type_order === 'diagnostic' &&
                            order.order_result === 'complete' &&
                            _.find(
                              order.ServiceCallOrderFile,
                              f => f.file_type === 'act_repair',
                            )) ||
                          (order.type_order === 'diagnostic' &&
                            order.order_result === 'writeoff' &&
                            _.find(
                              order.ServiceCallOrderFile,
                              f => f.file_type === 'act_repair',
                            )) ||
                          (order.type_order === 'diagnostic' &&
                            order.order_result === 'needrepair' &&
                            _.find(
                              order.ServiceCallOrderFile,
                              f => f.file_type === 'act_diagnostic',
                            ))) ? (
                          <Grid item>
                            <ClickWrapper>
                              <Grid container spacing={1} direction="row">
                                <Grid item>
                                  {permissionResolvers.canOrderSendToControl(
                                    order.status,
                                    orderPermissions,
                                  ) && (
                                    <UnpaidTooltip
                                      disabled={permissionResolvers.canOperateButton(
                                        commonOrderPermissions,
                                        'orders',
                                        'performer',
                                      )}
                                    >
                                      <ContainedButton
                                        disabled={
                                          !permissionResolvers.canOperateButton(
                                            commonOrderPermissions,
                                            'orders',
                                            'performer',
                                          )
                                        }
                                        onClick={() =>
                                          confirmCertificateOfCompletionRequest(
                                            { orderId: order.id },
                                          )
                                        }
                                      >
                                        {i18next.t(
                                          'service_desk_web_atoir:sendToControl',
                                        )}
                                      </ContainedButton>
                                    </UnpaidTooltip>
                                  )}
                                </Grid>
                                <Grid item>
                                  {permissionResolvers.canOrderSendToDone(
                                    order.status,
                                    orderPermissions,
                                  ) && (
                                    <UnpaidTooltip
                                      disabled={permissionResolvers.canOperateButton(
                                        commonOrderPermissions,
                                        'orders',
                                        'performer',
                                      )}
                                    >
                                      <ContainedButton
                                        disabled={
                                          !permissionResolvers.canOperateButton(
                                            commonOrderPermissions,
                                            'orders',
                                            'performer',
                                          )
                                        }
                                        onClick={() =>
                                          confirmCertificateOfCompletionRequest(
                                            { orderId: order.id },
                                          )
                                        }
                                      >
                                        {i18next.t(
                                          'service_desk_web_atoir:sendToClient',
                                        )}
                                      </ContainedButton>
                                    </UnpaidTooltip>
                                  )}
                                </Grid>
                              </Grid>
                            </ClickWrapper>
                          </Grid>
                        ) : null}
                        {order.wip &&
                        !!orderPermissions &&
                        permissionResolvers.canAcceptOrRejectCertificateInDone(
                          order.status,
                          orderPermissions,
                        ) ? (
                          <Grid item>
                            <Grid container spacing={1} direction="row">
                              <Grid item>
                                <UnpaidTooltip
                                  disabled={permissionResolvers.canOperateButton(
                                    commonOrderPermissions,
                                    'orders',
                                    'client',
                                  )}
                                >
                                  <OutlinedButton
                                    disabled={
                                      !permissionResolvers.canOperateButton(
                                        commonOrderPermissions,
                                        'orders',
                                        'client',
                                      )
                                    }
                                    onClick={
                                      showRejectCertificateOfCompletionDialog
                                    }
                                  >
                                    {i18next.t(
                                      'service_desk_web_atoir:sendCertificateOfCompletionToRework',
                                    )}
                                  </OutlinedButton>
                                </UnpaidTooltip>
                              </Grid>
                              <Grid item>
                                <UnpaidTooltip
                                  disabled={permissionResolvers.canOperateButton(
                                    commonOrderPermissions,
                                    'orders',
                                    'client',
                                  )}
                                >
                                  <ContainedButton
                                    disabled={
                                      !permissionResolvers.canOperateButton(
                                        commonOrderPermissions,
                                        'orders',
                                        'client',
                                      )
                                    }
                                    onClick={
                                      showAcceptCertificateOfCompletionDialog
                                    }
                                  >
                                    {i18next.t(
                                      'service_desk_web_atoir:acceptCertificateOfCompletion',
                                    )}
                                  </ContainedButton>
                                </UnpaidTooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                        {orderButtons.length ? (
                          <Grid item>
                            {orderButtonsPrepare(
                              orderButtons,
                              { order, call },
                              orderPermissions,
                            ).length > 0 && (
                              <Fragment>
                                <IconButton
                                  id="service-desk-order-buttons-menu"
                                  aria-owns={
                                    anchorElButtons
                                      ? 'order-buttons-menu'
                                      : null
                                  }
                                  aria-haspopup="true"
                                  onClick={this.handleButtonsMenuOpen}
                                >
                                  <MoreVertIcon className={classes.menuIcon} />
                                </IconButton>
                                <Menu
                                  id="order-buttons-menu"
                                  anchorEl={anchorElButtons}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  open={Boolean(anchorElButtons)}
                                  onClose={this.handleButtonsMenuClose}
                                >
                                  {orderButtonsPrepare(
                                    orderButtons,
                                    { order, call },
                                    orderPermissions,
                                  ).length > 0 ? (
                                    orderButtonsPrepare(
                                      orderButtons,
                                      { order, call },
                                      orderPermissions,
                                    ).map(button => (
                                      <MenuItem
                                        onClick={() =>
                                          orderButtonExecute(button)
                                        }
                                      >
                                        {button.name}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem disabled>
                                      {i18next.t(
                                        'service_desk_web_atoir:noSuitableButtons',
                                      )}
                                    </MenuItem>
                                  )}
                                </Menu>
                              </Fragment>
                            )}
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : !!order &&
                order.status === 'cancel' &&
                orderButtons.length ? (
                <Grid item xs={12}>
                  <Grid container direction="row" justify="flex-end">
                    {orderButtonsPrepare(
                      orderButtons,
                      { order, call },
                      orderPermissions,
                    ).length > 0 && (
                      <Fragment>
                        <IconButton
                          id="service-desk-order-buttons-menu"
                          aria-owns={
                            anchorElButtons ? 'order-buttons-menu' : null
                          }
                          aria-haspopup="true"
                          onClick={this.handleButtonsMenuOpen}
                        >
                          <MoreVertIcon className={classes.menuIcon} />
                        </IconButton>
                        <Menu
                          id="order-buttons-menu"
                          anchorEl={anchorElButtons}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElButtons)}
                          onClose={this.handleButtonsMenuClose}
                        >
                          {orderButtonsPrepare(
                            orderButtons,
                            { order, call },
                            orderPermissions,
                          ).length > 0 ? (
                            orderButtonsPrepare(
                              orderButtons,
                              { order, call },
                              orderPermissions,
                            ).map(button => (
                              <MenuItem
                                onClick={() => orderButtonExecute(button)}
                              >
                                {button.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>
                              {i18next.t(
                                'service_desk_web_atoir:noSuitableButtons',
                              )}
                            </MenuItem>
                          )}
                        </Menu>
                      </Fragment>
                    )}
                  </Grid>
                </Grid>
              ) : null}
              {type === 'active' && order.wip && order.order_result !== null ? (
                <Grid item xs={12}>
                  <Fragment>
                    <Typography variant="h6">
                      {i18next.t('service_desk_web_atoir:reportingInformation')}
                    </Typography>
                    {
                      <OrderDocuments
                        classes={classes}
                        order={order}
                        canUpdate={permissionResolvers.canOrderEditReportingInfo(
                          order.status,
                          orderPermissions,
                        )}
                        commonOrderPermissions={commonOrderPermissions}
                        canView={permissionResolvers.canOrderViewReportingInfo(
                          order.status,
                          orderPermissions,
                        )}
                        orderFile={order.ServiceCallOrderFile}
                        anchorElAddDocumentMenu={anchorElAddDocumentMenu}
                        handleMenuOpen={handleMenuOpen}
                        handleMenuClose={handleMenuClose}
                        handleAttach={handleAttach}
                      />
                    }
                    {order.order_result !== 'writeoff' && (
                      <OrderCertificate
                        classes={classes}
                        canUpdate={permissionResolvers.canOrderEditReportingInfo(
                          order.status,
                          orderPermissions,
                        )}
                        commonOrderPermissions={commonOrderPermissions}
                        orderId={order.id}
                        orderType={order.type_order}
                        orderResultType={order.order_result}
                        nds={!order.wip ? order.nds_performer : nds}
                        overhead={
                          !order.wip ? order.overhead_performer : overhead
                        }
                        inputNDS={
                          !order.wip ? order.income_nds_performer : inputWithNDS
                        }
                        orderItems={{
                          orderItemsDiagnostic,
                          orderItemsNeedRepair,
                        }}
                        commonOrganizationSettings={commonOrganizationSettings}
                        toWorkCompletionForm={
                          handleRedirectToWorkCompletionReport
                        }
                        showVersionForClient={permissionResolvers.showCertificateVersionForClient(
                          orderPermissions,
                        )}
                      />
                    )}
                  </Fragment>
                </Grid>
              ) : null}
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {!isEmpty(order) && (
              <Fragment>
                <Grid container spacing={2}>
                  {order.client_organization ? (
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                      <Typography variant="caption" color="textSecondary">
                        {i18next.t('service_desk_web_atoir:clientOrganization')}
                      </Typography>
                      <Typography className={classes.organizationText}>
                        {order.client_organization.full_name}
                      </Typography>
                      {order.client_organization.OrganizationLocations ? (
                        <Typography className={classes.locationText}>
                          {inventoryLocationStringifyDefault(
                            order.client_organization.OrganizationLocations[0],
                          )}
                        </Typography>
                      ) : null}
                      {order.client_responsible ? (
                        <ResponsibleInfo
                          responsible={order.client_responsible}
                          onEdit={
                            permissionResolvers.canOrderChangeClientResponsible(
                              order.status,
                              orderPermissions,
                            ) && onToggle('openDialogEdit', 'client')
                          }
                          classes={classes}
                        />
                      ) : null}
                    </Grid>
                  ) : null}
                  {order.performer_organization ? (
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                      <Typography variant="caption" color="textSecondary">
                        {i18next.t(
                          'service_desk_web_atoir:performerOrganization',
                        )}
                      </Typography>
                      <Typography className={classes.organizationText}>
                        {order.performer_organization.full_name}
                      </Typography>
                      {order.performer_organization.OrganizationLocations ? (
                        <Typography className={classes.locationText}>
                          {inventoryLocationStringifyDefault(
                            order.performer_organization
                              .OrganizationLocations[0],
                          )}
                        </Typography>
                      ) : null}
                      {order.performer_responsible ? (
                        <ResponsibleInfo
                          responsible={order.performer_responsible}
                          onEdit={
                            permissionResolvers.canOrderChangePerformerResponsible(
                              order.status,
                              orderPermissions,
                            ) && onToggle('openDialogEdit', 'performer')
                          }
                          classes={classes}
                        />
                      ) : null}
                      {permissionResolvers.canViewEngineer(orderPermissions) ? (
                        <EngineerInfo
                          engineer={order.engineer_user}
                          onEdit={
                            permissionResolvers.canOrderChangeEngineer(
                              order.status,
                              orderPermissions,
                            ) && onToggle('openDialogEdit', 'engineer')
                          }
                        />
                      ) : null}
                      {order.type_order === 'repair' &&
                        order.status_enhanced === 'inworkRepair' &&
                        orderPermissions && (
                          <VisitInfo
                            engineer={order.engineer_user}
                            order={order}
                            orderId={order.id}
                            dateVisit={
                              order.visit_date ||
                              (call.performer_info || {}).visit_date
                            }
                            orderType={order.type_order}
                            commentVisit={order.visit_comment}
                            onEdit={
                              permissionResolvers.canOrderChangeVisitDetails(
                                order.status,
                                orderPermissions,
                              ) &&
                              permissionResolvers.canOperateButton(
                                commonOrderPermissions,
                                'orders',
                                'performer',
                              ) &&
                              onToggle('openEditOrderVisitInfoDialog', true)
                            }
                          />
                        )}
                    </Grid>
                  ) : null}
                  {type === 'closed' || type === 'canceled' ? (
                    <Grid item xs={12}>
                      {!isEmpty(order.ServiceCallOrderFile) ||
                      !isEmpty(order.ServiceCallOrderItems) ? (
                        <Typography variant="h6">
                          {i18next.t(
                            'service_desk_web_atoir:reportingInformation',
                          )}
                        </Typography>
                      ) : null}
                      {!isEmpty(order.ServiceCallOrderFile) ? (
                        <OrderDocuments
                          classes={classes}
                          order={order}
                          canUpdate={false}
                          canView={permissionResolvers.canOrderViewReportingInfoNotActive(
                            orderPermissions,
                          )}
                          orderFile={order.ServiceCallOrderFile}
                          anchorElAddDocumentMenu={anchorElAddDocumentMenu}
                          handleMenuOpen={handleMenuOpen}
                          handleMenuClose={handleMenuClose}
                          handleAttach={handleAttach}
                        />
                      ) : null}
                      {!isEmpty(order.ServiceCallOrderItems) ? (
                        <OrderCertificate
                          classes={classes}
                          canUpdate={false}
                          orderId={order.id}
                          orderType={order.type_order}
                          orderResultType={order.order_result}
                          nds={!order.wip ? order.nds_performer : nds}
                          overhead={
                            !order.wip ? order.overhead_performer : overhead
                          }
                          inputNDS={
                            !order.wip
                              ? order.income_nds_performer
                              : inputWithNDS
                          }
                          orderItems={{
                            orderItemsDiagnostic,
                            orderItemsNeedRepair,
                          }}
                          commonOrganizationSettings={
                            commonOrganizationSettings
                          }
                          toWorkCompletionForm={
                            handleRedirectToWorkCompletionReport
                          }
                          showVersionForClient={permissionResolvers.showCertificateVersionForClient(
                            orderPermissions,
                          )}
                        />
                      ) : null}
                    </Grid>
                  ) : null}
                </Grid>
              </Fragment>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <AddCompletionFile
          ref={this.inputFileRef}
          uploadDoneFunc={() => {}}
          idOrder={order.id}
        />
        <CancelOrderDialog
          open={cancelOrderDialogOpen}
          onClose={cancelOrderDialogClose}
          onCancelOrder={cancelOrder}
          onCancelReasonEdit={cancelReasonEdit}
          reasonValid={cancelReasonValid}
          isLoading={isFetchingCancelOrder}
        />
        <FormDialog
          open={rejectOrderDialogOpen}
          devider
          isLoading={isFetchingRejectOrder}
          title={i18next.t('service_desk_web_atoir:rejectOrderCaption')}
          onClose={rejectOrderDialogClose}
          onExit={rejectOrderDialogClose}
          onSubmit={rejectOrder}
          submitButtonTitle={i18next.t('service_desk_web_atoir:confirm')}
        >
          <TextField
            required
            label={i18next.t('service_desk_web_atoir:note')}
            onChange={rejectOrderReasonChange}
            variant="outlined"
            multiline
            rows="4"
            className={classes.textFieldMulty}
            margin="normal"
            error={rejectOrderReasonValid === false}
          />
        </FormDialog>
        <AssignToOwnSpecialist
          open={openDialogOwnSpecialist}
          onClose={closeAssignToOwnSpecialist}
          onFinish={() => {}}
          callId={order.service_call_id}
          organizationId={order.performer_organization.id}
          onSubmit={submitAssignToOwnSpecialist}
        />
        <SendToSubOrder
          open={openDialogSendSubOrder}
          performerId={order.performer_organization.id}
          callId={order.service_call_id}
          onClose={closeDialogSendSubOrder}
          onSubmit={submitSendSubOrder}
        />
        {permissionResolvers.canOrderAssignToMe(
          order.status,
          orderPermissions,
        ) && (
          <AssignToMeAsEngineer
            open={openDialogAssignToMe}
            onClose={closeAssignToMe}
            onFinish={() => {}}
            callId={order.service_call_id}
            organizationId={order.performer_organization.id}
            onSubmit={submitAssignToMe}
          />
        )}
        {!!orderPermissions &&
          permissionResolvers.canOrderChangeClientResponsible(
            order.status,
            orderPermissions,
          ) &&
          openDialogEdit === 'client' && (
            <FormEditResponsible
              open={openDialogEdit === 'client'}
              idOrder={order.id}
              idOrganization={order.client_organization.id}
              responsible={order.client_responsible}
              client
              onClose={onToggle('openDialogEdit', null)}
            />
          )}
        {!!orderPermissions &&
          permissionResolvers.canOrderChangePerformerResponsible(
            order.status,
            orderPermissions,
          ) &&
          openDialogEdit === 'performer' && (
            <FormEditResponsible
              open={openDialogEdit === 'performer'}
              idOrder={order.id}
              idOrganization={order.performer_organization.id}
              responsible={order.performer_responsible}
              performer
              onClose={onToggle('openDialogEdit', null)}
            />
          )}
        {!!orderPermissions &&
          permissionResolvers.canOrderChangeEngineer(
            order.status,
            orderPermissions,
          ) &&
          openDialogEdit === 'engineer' && (
            <FormEditResponsible
              open={openDialogEdit === 'engineer'}
              idOrder={order.id}
              idOrganization={order.performer_organization.id}
              responsible={order.engineer_user}
              engineer
              onClose={onToggle('openDialogEdit', null)}
            />
          )}
        {!!orderPermissions &&
          permissionResolvers.canOrderChangeVisitDetails(
            order.status,
            orderPermissions,
          ) && (
            <FormEditOrderInfo
              open={openEditOrderVisitInfoDialog}
              idOrder={order.id}
              dateVisit={order.visit_date}
              commentVisit={order.visit_comment}
              onClose={onToggle('openEditOrderVisitInfoDialog', false)}
            />
          )}
        <FormDialog
          open={openRejectCertificateOfCompletionDialog}
          isLoading={isRejectingCertificateOfCompletion}
          title={i18next.t(
            'service_desk_web_atoir:sendCertificateOfCompletionToReworkCaption',
          )}
          onClose={closeRejectCertificateOfCompletionDialog}
          onExit={closeRejectCertificateOfCompletionDialog}
          onSubmit={onRejectCertificateOfCompletionInDone}
          submitButtonTitle={i18next.t('service_desk_web_atoir:send')}
        >
          <TextField
            required
            label={i18next.t('service_desk_web_atoir:note')}
            onChange={onRejectCertificateOfCompletionReasonEdit}
            variant="outlined"
            multiline
            rows="4"
            className={classes.textFieldMulty}
            margin="normal"
            error={rejectCertificateOfCompletionCommentValid === false}
          />
        </FormDialog>
        <Dialog
          isOpen={openAcceptCertificateOfCompletionDialog}
          content={i18next.t(
            'service_desk_web_atoir:acceptCertificateOfCompletionCaption',
          )}
          handleClose={closeAcceptCertificateOfCompletionDialog}
          handleNegative={closeAcceptCertificateOfCompletionDialog}
          handlePositive={onAcceptCertificateOfCompletionInDone}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          positiveText={i18next.t('service_desk_web_atoir:takePlace')}
          positiveButtonVariant="contained"
          closeIconHidded
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={this.closeSnackbar}
        >
          <SnackbarElContent
            onClose={this.closeSnackbar}
            variant={snackbarVariant}
            message={snackbarMessage}
          />
        </Snackbar>
      </Fragment>
    )
  }
}

OrderRow.defaultProps = {
  orderButtons: [],
  orderButtonExecute: () => {},
  orderButtonExecuting: false,
}

OrderRow.propTypes = {
  call: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  orderPermissions: PropTypes.object.isRequired,
  orderButtons: PropTypes.array,
  orderButtonExecute: PropTypes.func,
  orderButtonExecuting: PropTypes.bool,
  disableScriptsForIds: PropTypes.object.isRequired,
  callScripts: PropTypes.array.isRequired,
}

export default withStyles(styles)(OrderRow)
