import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import isEmpty from 'is-empty'

import { Breadcrumbs } from '@atoir/react-breadcrumbs-dynamic'

import styled from 'styled-components'

// material UI
import { spacing, flexbox } from '@material-ui/system'
import { withStyles } from '@material-ui/core/styles/index'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

// icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import config from '../../../config'

// components
import DrawerComponent from './DrawerComponent'
import DrawerFiltersComponent from './DrawerFiltersComponent'
import DrawerNavigationComponent from './DrawerNavigationComponent'
import FormDialog from '../../../widgets/FormDialog'
import BigBreadcrumbs from './BigBreadcrumbs'
import SmallBreadcrumbs from './SmallBreadcrumbs'

import i18next from '../../../i18n/i18n'
import CollapsibleDrawerTurnOn from './CollapsibleDrawerTurnOn'
import UserMenu from './UserMenu'
import GlobalSearchWidget from '../GlobalSearchComponent/GlobalSearchWidget'
import GlobalSearchComponent from '../GlobalSearchComponent'
import { appOptions } from '../appContext'

const drawerWidth = config.left_drawer_width
const drawerFiltersWidth = config.right_drawer_width

const styles = theme => ({
  flex: {
    flex: 1,
  },
  transition: {
    transition: 'margin-left 0.3s ease-in-out',
  },
  appBar: {
    color: theme.palette.blueSecondary.main,
    zIndex: theme.zIndex.drawer + 1,
  },

  root: {
    marginLeft: 5,
    position: 'relative',
  },
  '@media (max-width: 1917px)': {
    root: {
      width: `calc(100% - ${drawerFiltersWidth + 90}px)`,
    },
  },
  '@media (max-width: 1250px)': {
    root: {
      width: '90vw',
    },
  },
  '@media (min-width: 1918px)': {
    root: {
      width: `calc(100% - ${drawerFiltersWidth + (drawerWidth - 150)}px)`,
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

const Box = styled.div`${spacing}${flexbox}`

class AppBarComponent extends Component {
  state = {
    drawerOpen: false,
    filteringDrawerOpen: false,
    dialogOpen: false,
    isPersistDrawerExpended: true,
  }

  componentDidMount() {
    const {
      props: { user, isLoggedIn, fetchUser },
    } = this

    if (isEmpty(user) && isLoggedIn) {
      fetchUser()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isLoggedIn !== this.props.isLoggedIn &&
      this.props.isLoggedIn === false &&
      isEmpty(this.props.errorMessage) &&
      isEmpty(this.props.error)
    ) {
      this.setState({
        filteringDrawerOpen: false,
        drawerOpen: false,
      })
    }
  }

  handleDrawerOpen = () => {
    this.setState({
      drawerOpen: true,
    })
  }

  handleFilteringDrawerOpen = () => {
    this.setState({
      filteringDrawerOpen: true,
    })
  }

  handleDrawerClose = () => {
    this.setState({
      drawerOpen: false,
    })
  }

  handleFilteringDrawerClose = () => {
    this.setState({
      filteringDrawerOpen: false,
    })
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    })
  }

  handleLogout = () => {
    this.setState({
      dialogOpen: false,
    })
    const { closeErrorMessage, logout } = this.props
    closeErrorMessage()
    logout()
  }

  render() {
    const {
      state: {
        drawerOpen,
        dialogOpen,
        filteringDrawerOpen,
        isPersistDrawerExpended,
      },
      props: {
        drawerMode,
        isLoggedIn,
        classes,
        filtersComponent,
        filtersFooter,
        navigationComponent,
      },
    } = this

    return (
      <Fragment>
        <AppBar
          color="default"
          className={isLoggedIn && classes.appBar}
          style={{ position: 'relative' }}
        >
          <GlobalSearchComponent />
          <div>
            <Toolbar className={classes.root} disableGutters>
              {isLoggedIn && (
                <React.Fragment>
                  <CollapsibleDrawerTurnOn />
                </React.Fragment>
              )}

              <Box
                className={classes.transition}
                flex={1}
                drawerMode={drawerMode}
                ml={drawerMode === appOptions.drawerMode.collapsible ? 6 : 29}
              >
                <Breadcrumbs
                  separator={
                    <ChevronRightIcon
                      style={{ verticalAlign: 'middle' }}
                      fontSize="small"
                    />
                  }
                  item={NavLink}
                  finalItem="b"
                  finalProps={{
                    style: { color: 'inherit' },
                  }}
                  render={({ count, ...props }) =>
                    count > 3 ? (
                      <BigBreadcrumbs count={count} {...props} />
                    ) : (
                      <SmallBreadcrumbs count={count} {...props} />
                    )
                  }
                />
              </Box>
              {isLoggedIn && <GlobalSearchWidget />}
              {isLoggedIn && (
                <div className={classes.menuItem}>
                  <UserMenu onLogout={this.handleDialogOpen} />
                </div>
              )}
            </Toolbar>
          </div>
        </AppBar>
        {isLoggedIn && (
          <DrawerComponent
            open={drawerOpen}
            handleDrawerOpen={this.handleDrawerOpen}
            handleDrawerClose={this.handleDrawerClose}
            mode={isPersistDrawerExpended}
          />
        )}
        {isLoggedIn && (
          <DrawerFiltersComponent
            open={filteringDrawerOpen}
            component={filtersComponent}
            footer={filtersFooter}
            handleDrawerOpen={this.handleFilteringDrawerOpen}
            handleDrawerClose={this.handleFilteringDrawerClose}
          />
        )}
        {isLoggedIn && (
          <DrawerNavigationComponent component={navigationComponent} />
        )}
        <FormDialog
          open={dialogOpen}
          title={i18next.t('login_web_atoir:exitSystemDialogTitle')}
          contentText={i18next.t('system_menu_web_atoir:exitSystemDialogText')}
          onSubmit={this.handleLogout}
          onClose={this.handleDialogClose}
          onExit={this.handleDialogClose}
          submitButtonTitle={i18next.t('system_menu_web_atoir:exit')}
        />
      </Fragment>
    )
  }
}

AppBarComponent.propTypes = {}

export default withStyles(styles)(AppBarComponent)
