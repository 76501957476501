import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import TablePagination from '@material-ui/core/TablePagination'
import withStyles from '@material-ui/core/styles/withStyles'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import i18next from '../../../../i18n/i18n'
import { inventoryLocationStringifyDefault } from '../../../../helpers/parserHelper'
import Radio from '@material-ui/core/Radio'
import isEmpty from 'is-empty'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import ReactResizeDetector from 'react-resize-detector'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoadingWrapper from '../../../../widgets/LoadingWrapper'

const OrgMarker = styled.span`
  line-height: inherit;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #1976d2;
  color: #ffffff;
  font-size: 10px;
`

const headRows = () => [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: i18next.t('service_desk_web_atoir:organizationID'),
  },
  {
    id: 'short_name',
    numeric: false,
    disablePadding: false,
    label: i18next.t('shared_web_atoir:organizationName'),
  },
  {
    id: 'address',
    numeric: false,
    disableSort: true,
    disablePadding: false,
    label: i18next.t('organizations_web_atoir:address'),
  },
]

const style = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCellNoBorder: {
    border: 0,
  },
  text: {
    paddingLeft: theme.spacing(3),
  },
  flexDiv: {
    display: 'flex',
  },
  maxWidthDiv: {
    maxWidth: 380,
    minWidth: 150,
  },
})

class OrganizationsTable extends PureComponent {
  createSortHandler = field => event => {
    this.props.onRequestSort(event, field)
  }

  render() {
    const {
      props: {
        classes,
        selected,
        order,
        orderBy,
        organizations,
        rowsPerPage,
        page,
        isFetching,
        serviceOrganizationsCount,
        onRowsPerPageChanged,
        onPageChanged,
        onSelectedChanged,
      },
    } = this
    return (
      <LoadingWrapper
        loading={isFetching}
        inDelay={0}
        outDelay={300}
        data={organizations}
      >
        {({ loading, data }) => (
          <div className={classes.root}>
            <div className={classes.tableWrapper}>
              {data.length === 0 && !loading ? (
                <div className={classes.text}>
                  <Typography variant="body1">
                    {i18next.t(
                      'service_desk_web_atoir:noResultForOrgSearching',
                    )}
                  </Typography>
                </div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) => (
                      <>
                        {loading && data.length > 0 && (
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 100,
                              backgroundColor: 'rgba(220, 220, 220, 0.5)',
                              width,
                              height,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <CircularProgress />
                          </div>
                        )}
                        {loading && data.length === 0 && <CircularProgress />}
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                padding="checkbox"
                                className={classes.tableCellNoBorder}
                              />
                              {headRows().map(cell => (
                                <TableCell
                                  className={classes.tableCellNoBorder}
                                  key={cell.id}
                                  align={cell.numeric ? 'right' : 'left'}
                                  padding={
                                    cell.disablePadding ? 'none' : 'default'
                                  }
                                  sortDirection={
                                    orderBy === cell.id ? order : false
                                  }
                                >
                                  {!cell.disableSort && (
                                    <Tooltip
                                      title="Sort"
                                      placement="bottom-left"
                                      enterDelay={300}
                                    >
                                      <TableSortLabel
                                        active={orderBy === cell.id && order}
                                        direction={
                                          order ? order.toLowerCase() : ''
                                        }
                                        onClick={this.createSortHandler(
                                          cell.id,
                                        )}
                                      >
                                        {cell.label}
                                      </TableSortLabel>
                                    </Tooltip>
                                  )}
                                  {cell.disableSort && cell.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(data || []).map((organization, index) => (
                              <TableRow
                                key={organization.id}
                                selected={!(index % 2)}
                                onClick={() => onSelectedChanged(organization)}
                              >
                                <TableCell
                                  padding="checkbox"
                                  className={classes.tableCellNoBorder}
                                >
                                  <Radio
                                    checked={selected === organization.id}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellNoBorder}
                                >
                                  {organization.id}
                                </TableCell>
                                <TableCell
                                  className={classnames([
                                    classes.tableCellNoBorder,
                                  ])}
                                >
                                  <div
                                    className={classnames([
                                      classes.maxWidthDiv,
                                      'preBreak',
                                    ])}
                                  >
                                    {Boolean(organization.ca_index) && (
                                      <OrgMarker>KA</OrgMarker>
                                    )}
                                    {organization.trusted && (
                                      <OrgMarker>Дов</OrgMarker>
                                    )}
                                    {organization.short_name}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellNoBorder}
                                >
                                  <div className={classnames(['preBreak'])}>
                                    {isEmpty(organization.OrganizationLocations)
                                      ? '-'
                                      : inventoryLocationStringifyDefault(
                                          organization.OrganizationLocations.filter(
                                            ol => ol.location_type === 0,
                                          )[0],
                                        )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </ReactResizeDetector>
                </div>
              )}
            </div>
            <TablePagination
              colSpan={6}
              component="div"
              count={serviceOrganizationsCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
              labelDisplayedRows={({
                from: fromPage,
                to: toPage,
                count: countPage,
              }) =>
                i18next.t('shared_web_atoir:labelDisplayedRows', {
                  fromPage,
                  toPage,
                  countPage,
                })
              }
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={onPageChanged}
              onChangeRowsPerPage={onRowsPerPageChanged}
            />
          </div>
        )}
      </LoadingWrapper>
    )
  }
}

OrganizationsTable.defaultProps = {
  order: 'ASC',
  orderBy: null,
  selected: null,
  page: 0,
  rowsPerPage: 5,
}

OrganizationsTable.propTypes = {
  organizations: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onRowsPerPageChanged: PropTypes.func.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  order: PropTypes.string,
  selected: PropTypes.string,
  orderBy: PropTypes.string,
}

export default withStyles(style)(OrganizationsTable)
