/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from './Link'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    top: 24,
    // Fix IE 11 position sticky issue.
    marginTop: 24,
    // width: 175,
    flexShrink: 0,
    position: 'sticky',
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    padding: theme.spacing(2, 2, 2, 2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  contents: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  item: {
    // fontSize: '.8125rem',
    padding: theme.spacing(
      0.5,
      0,
      0.5,
      `${Math.max(0, theme.spacing(1) - 3)}px`,
    ),
    borderLeft: `3px solid transparent`,
    boxSizing: 'border-box',
    '&:hover': {
      borderLeftColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[900],
    },
    '&$active,&:active': {
      borderLeftColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[800],
    },
  },
  secondaryItem: {
    paddingLeft: theme.spacing(2.5),
  },
  active: {},
}))

// TODO: these nodes are mutable sources. Use createMutableSource once it's stable
function getItemsClient(headings) {
  const itemsWithNode = []

  headings.forEach(item => {
    itemsWithNode.push({
      ...item,
      node: document.getElementById(item.hash),
    })

    if (item.children.length > 0) {
      item.children.forEach(subitem => {
        itemsWithNode.push({
          ...subitem,
          node: document.getElementById(subitem.hash),
        })
      })
    }
  })
  return itemsWithNode
}

const noop = () => {}

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = React.useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay],
  )

  React.useEffect(() => {
    if (throttledCallback === noop) {
      return undefined
    }

    window.addEventListener('scroll', throttledCallback)
    return () => {
      window.removeEventListener('scroll', throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

function SettingsTableOfContents(props) {
  const { items } = props
  const classes = useStyles()

  const itemsWithNodeRef = React.useRef([])
  React.useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items)
  }, [items])

  const [activeState, setActiveState] = React.useState(null)
  const clickedRef = React.useRef(false)
  const unsetClickedRef = React.useRef(null)
  const findActiveIndex = React.useCallback(() => {
    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) {
      return
    }

    let active
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 200) {
        active = { hash: null }
        break
      }

      const item = itemsWithNodeRef.current[i]

      if (process.env.NODE_ENV !== 'production') {
        if (!item.node) {
          console.error(
            `Missing node on the item ${JSON.stringify(item, null, 2)}`,
          )
        }
      }

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
    }
  }, [activeState])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166)

  const handleClick = hash => event => {
    // Ignore click for new tab/new window behavior
    if (
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return
    }

    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)

    if (activeState !== hash) {
      setActiveState(hash)
    }
  }

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    [],
  )

  const itemLink = (item, secondary) => (
    <Link
      display="block"
      color={activeState === item.hash ? 'textPrimary' : 'textSecondary'}
      href={`${window.location.href.split('#')[0]}#${item.hash}`}
      underline="none"
      onClick={handleClick(item.hash)}
      className={classNames(
        classes.item,
        { [classes.secondaryItem]: secondary },
        activeState === item.hash ? classes.active : undefined,
      )}
    >
      <span dangerouslySetInnerHTML={{ __html: item.text }} />
    </Link>
  )

  return (
    <nav className={classes.root}>
      {items.length > 0 ? (
        <React.Fragment>
          <Typography component="ul" className={classes.ul} variant="h6">
            {items.map(item => (
              <li key={item.hash}>
                {itemLink(item)}
                {item.children.length > 0 ? (
                  <ul className={classes.ul}>
                    {item.children.map(subitem => (
                      <Typography component="li" key={subitem.hash}>
                        {itemLink(subitem, true)}
                      </Typography>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </Typography>
        </React.Fragment>
      ) : null}
    </nav>
  )
}

SettingsTableOfContents.propTypes = {
  items: PropTypes.array.isRequired,
}

export default withRouter(SettingsTableOfContents)
