import React from 'react'
import PropTypes from 'prop-types'

import AttachTagDialog from './AttachTagDialog'

class AttachTagDialogContainer extends React.Component {
  componentDidUpdate(prevProps) {
    const { attaching, error } = this.props
    if (attaching !== prevProps.attaching && !attaching && !error) {
      const { attachDone } = this.props
      attachDone()
    }
  }

  attachTag = id => {
    const { attachTag, callId } = this.props
    attachTag({
      callId,
      tagId: id,
    })
  }

  render() {
    const {
      props: { cancel, open, callsTagsAvl, callId },
      attachTag,
    } = this
    return (
      <AttachTagDialog
        tags={callsTagsAvl}
        open={open}
        attachSave={attachTag}
        cancel={cancel}
        callId={callId}
      />
    )
  }
}

AttachTagDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  attachDone: PropTypes.func.isRequired,
  attachTag: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  callId: PropTypes.number.isRequired,
  callsTagsAvl: PropTypes.array.isRequired,
}

export default AttachTagDialogContainer
