import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import * as actions from '../actions/statisticsActions'
import { handleErrors } from '../helpers/errorsHelper'
import * as types from '../constants/actionTypes/statistics'
import * as api from './apis/statistics'
import { downloadFileCommonRequestApi, URL } from './api'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { downloadFile, createDefaultSaga } from '../helpers/api'

export function* watchFetchGenerateSDWorkingReportRequestSaga() {
  yield takeLatest(
    types.GENERATE_SD_WORKING_REPORT_REQUEST,
    generateSDWorkingReportRequest,
  )
}
const generateSDWorkingReportRequest = createDefaultSaga(
  payload => api.generateSDWorkingReport(payload),
  result => actions.generateSDWorkingReportSuccess(result.report),
  code => actions.generateSDWorkingReportFailure(code),
  false,
)

export function* watchDownloadSDWorkingReportRequestSaga() {
  yield takeLatest(
    types.DOWNLOAD_SD_WORKING_REPORT_REQUEST,
    downloadSDWorkingReportRequest,
  )
}

function* downloadSDWorkingReportRequest({ payload }) {
  try {
    const url = `${URL}/organizations/${payload.report.organization_id}/working_reports/${payload.report.id}/download`
    const result = yield call(downloadFileCommonRequestApi({ ...payload, url }))

    if (!isEmpty(result.success)) {
      if (result.success) {
        downloadFile(payload.name, result.blob)
      } else {
        const errors = handleErrors(result)
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
