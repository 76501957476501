import React from 'react'

function SmallBreadcrumbs({
  containerProps,
  prepareItemProps,
  itemsValue,
  separator,
  count,
  finalItem: FinalItem,
  finalProps,
  item: Item = ({ children }) => <div>{children}</div>,
}) {
  return (
    <div {...containerProps}>
      {itemsValue.map((itemValue, i) =>
        i + 1 < count ? (
          separator ? (
            <span key={itemValue.to}>
              <Item {...prepareItemProps(itemValue)} />
              {separator}
            </span>
          ) : (
            <Item key={itemValue.to} {...prepareItemProps(itemValue)} />
          )
        ) : (
          <FinalItem
            key={itemValue.to}
            {...prepareItemProps(itemValue)}
            {...finalProps}
          />
        ),
      )}
    </div>
  )
}

export default SmallBreadcrumbs
