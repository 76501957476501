import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UpdateLocationComponent from './UpdateLocationComponent'
import { updateLocation } from '../../../../actions/locationsActions'

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  isUpdatingLocation: state.locations.isUpdatingLocation,
  error: state.locations.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateLocation,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateLocationComponent),
)
