import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import i18next from '../../../../../i18n/i18n'
import TOItem from './TOItem'

const ListContainer = styled.div`
  max-height: 384px;
  overflow-y: auto;
  padding: 0 4px;
`

export default function({ classes, binded, equipment }) {
  return (
    <Fragment>
      <Typography className={classes.sectionName}>
        {i18next.t('equipment_web_atoir:toList')}
      </Typography>
      <ListContainer>
        {binded.BAEquipmentTOs.map(to => (
          <TOItem classes={classes} key={to.id} to={to} equipment={equipment} />
        ))}
      </ListContainer>
    </Fragment>
  )
}
