import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import isEmpty from 'is-empty'

// i18n
import i18next from '../../../../../i18n/i18n'

// components
import NumberFormatCustom from './NumberFormatCustom'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import EditIcon from '@material-ui/icons/Edit'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import classNames from 'classnames'
import { URL } from '../../../../../middlewares/api'
import AutocompleteLimitedAuto from '../../../../../widgets/AutocompleteLimitedAuto'
import { round10 } from 'round10'

const styles = theme => ({
  headerCell: {
    border: 0,
  },
  divAction: {
    display: 'flex',
    flexDirection: 'column',
  },
  fontSizeClass: {
    fontSize: '12px',
  },
  autoInput: {
    fontSize: '12px',
    lineHeight: '12px',
  },
  autoContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  tableCell: {
    border: 0,
    padding: 0,
    paddingBottom: '16px',
  },
  itemSelect: {
    minWidth: '100px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  countSelect: {
    minWidth: '70px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  nameText: {
    minWidth: '200px',
  },
  divName: {
    position: 'relative',
  },
  divOem: {
    position: 'absolute',
    bottom: '-15px',
  },
  divPopover: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  textPopover: {
    minWidth: '245px',
  },
  editIcon: {
    fontSize: 12,
  },
  tFieldCount: {
    maxWidth: '95px',
  },
  tFieldPricePercent: {
    maxWidth: '60px',
  },
  tFieldPricePurchase: {
    maxWidth: '80px',
  },
  divResult: {
    paddingLeft: '5px',
    paddingRight: '5px',
    maxWidth: '100px',
    wordBreak: 'break-all',
  },
  customBtnGrey: {
    color: lighten(theme.palette.secondary.light, 0.1),
    padding: 0,
    marginLeft: theme.spacing(1),
    marginBottom: -theme.spacing(1),
    margin: 0,
    textTransform: 'none',
    minHeight: 0,
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const isNumber = field => Number.isFinite(Number(field))

const curFormat = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const percentToMulti = percent => 1 + percent / 100

const summCalc = (percent, count, pricePurchase) => {
  if (isNumber(percent) && isNumber(count) && isNumber(pricePurchase)) {
    return curFormat.format(
      round10(percentToMulti(percent) * pricePurchase, -2) * count,
    )
  }

  return 0
}

const summPurchaseCalc = (count, pricePurchase) => {
  if (isNumber(count) && isNumber(pricePurchase)) {
    return curFormat.format(count * pricePurchase)
  }

  return 0
}

const priceCalc = (percent, pricePurchase) => {
  if (isNumber(percent) && isNumber(pricePurchase)) {
    const multiplier = percentToMulti(percent)
    return curFormat.format(multiplier * pricePurchase)
  }
  return 0
}

class OrderItemEditor extends Component {
  state = {
    anchorEl: null,
    open: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateDiff = {}
    const { percent, count, price_purchase: pricePurchase } = nextProps.data

    if (prevState.oemInProps !== nextProps.data.oem) {
      stateDiff.oemInProps = nextProps.data.oem
      stateDiff.oem = nextProps.data.oem
    }
    if (prevState.percent !== nextProps.data.percent) {
      stateDiff.percent = nextProps.data.percent
      stateDiff.summPurchase = summPurchaseCalc(count, pricePurchase)
      stateDiff.summ = summCalc(percent, count, pricePurchase)
      stateDiff.price = priceCalc(percent, pricePurchase)
    }
    if (prevState.count !== nextProps.data.count) {
      stateDiff.count = nextProps.data.count
      stateDiff.summPurchase = summPurchaseCalc(count, pricePurchase)
      stateDiff.summ = summCalc(percent, count, pricePurchase)
    }
    if (prevState.price_purchase !== nextProps.data.price_purchase) {
      stateDiff.price_purchase = nextProps.data.price_purchase
      stateDiff.summPurchase = summPurchaseCalc(count, pricePurchase)
      stateDiff.summ = summCalc(percent, count, pricePurchase)
      stateDiff.price = priceCalc(percent, pricePurchase)
    }

    if (!_.isEmpty(stateDiff)) {
      return stateDiff
    }
    return null
  }

  openOem = event => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    })
  }

  closeOem = () => {
    const { data } = this.props
    this.setState({
      anchorEl: null,
      oem: data.oem,
      open: false,
    })
  }

  saveOem = oem => {
    const {
      props: { data, handleChange },
      closeOem,
    } = this
    handleChange(data.id, 'oem', oem)
    closeOem()
  }

  changeOem = event => {
    event.preventDefault()
    this.setState({ oem: event.target.value })
  }

  render() {
    const {
      props: {
        classes,
        key,
        data,
        handleChange,
        delRow,
        editable,
        inputNDS,
        orderPerformerOrganization,
        clientVersion,
      },
      state: { anchorEl, oem, open, summ, summPurchase, price },
      closeOem,
    } = this
    return (
      <TableRow key={key} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          {editable ? (
            <Select
              error={data.invalidItemType}
              className={classes.itemSelect}
              value={data.item_type}
              onChange={event => {
                handleChange(data.id, 'item_type', event.target.value)
              }}
              MenuProps={classes.MenuProps}
            >
              {data.typeList.map(itemsType => (
                <MenuItem key={itemsType.type} value={itemsType.type}>
                  {itemsType.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Typography className={classes.fontSizeClass}>
              <div className={classes.divResult}>
                {!isEmpty(data.item_type)
                  ? i18next.t(
                      `service_desk_web_atoir:${data.item_type}ItemType`,
                    )
                  : '-'}
              </div>
            </Typography>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {editable ? (
            <div className={classes.divName}>
              <AutocompleteLimitedAuto
                InputProps={{
                  classes: {
                    input: classes.autoInput,
                  },
                }}
                theme={{
                  container: classes.autoContainer,
                }}
                value={data.name}
                onChange={(item, method) => {
                  if (item) {
                    let newValue = item.name
                    if (method === 'type') {
                      newValue = item
                    }
                    handleChange(data.id, 'name', newValue)
                  }
                }}
                requestResultTransformerToArray={s => s.items}
                suggestionMode
                transformInputToQuery={name => {
                  if (name === undefined) {
                    return {
                      filters: JSON.stringify(
                        _.omitBy(
                          _.pick(data, ['oem', 'item_type', 'name']),
                          i => !i,
                        ),
                      ),
                    }
                  }
                  return {
                    filters: JSON.stringify(
                      _.omitBy(
                        _.pick({ ...data, name }, ['name', 'oem', 'item_type']),
                        i => !i,
                      ),
                    ),
                  }
                }}
                getItemDisplayName={i => i.name}
                sourceUrl={`${URL}/service_call_order_items/suggestions`}
                query={{
                  organization_id: orderPerformerOrganization,
                }}
                error={data.invalidName}
                getItemValue={i => i.name}
                placeholder={i18next.t(
                  'service_desk_web_atoir:enterNamePlaceholder',
                )}
              />
              <div className={classes.divOem}>
                <Button
                  disableRipple
                  onClick={this.openOem}
                  className={classes.customBtnGrey}
                >
                  {data.oem ? (
                    <Fragment>
                      <Typography
                        variant="caption"
                        className={classes.fontSizeClass}
                      >
                        {data.oem}
                      </Typography>
                      <EditIcon className={classes.editIcon} />
                    </Fragment>
                  ) : (
                    <Typography
                      variant="caption"
                      className={classes.fontSizeClass}
                    >
                      {i18next.t('service_desk_web_atoir:addCatalogueNumber')}
                    </Typography>
                  )}
                </Button>
                <Popover
                  id="simple-popper"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => {
                    closeOem()
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <form
                    onSubmit={event => {
                      event.preventDefault()
                      this.saveOem(this.state.oem)
                    }}
                    noValidate
                  >
                    <div className={classes.divPopover}>
                      <FormControl>
                        <TextField
                          className={classNames(
                            classes.textField,
                            classes.textPopover,
                          )}
                          onChange={this.changeOem}
                          InputProps={{
                            className: classes.fontSizeClass,
                          }}
                          value={oem}
                        />
                      </FormControl>
                      <IconButton className={classes.iconButton} type="submit">
                        <DoneIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </form>
                </Popover>
              </div>
            </div>
          ) : (
            <Typography className={classes.fontSizeClass}>
              <div className={classes.divResult}>
                {!isEmpty(data.name) ? data.name : '-'}
              </div>
            </Typography>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {editable ? (
            <div className={classes.divName}>
              <TextField
                className={classNames(classes.textField)}
                InputProps={{
                  className: classes.fontSizeClass,
                }}
                onChange={event => {
                  handleChange(data.id, 'item_category', event.target.value)
                }}
                value={data.item_category}
              />
            </div>
          ) : (
            <Typography className={classes.fontSizeClass}>
              <div className={classes.divResult}>
                {!isEmpty(data.item_category) ? data.item_category : '-'}
              </div>
            </Typography>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {editable ? (
            <Select
              error={data.invalidUnits}
              className={classes.countSelect}
              value={data.units}
              onChange={event => {
                handleChange(data.id, 'units', event.target.value)
              }}
              MenuProps={classes.MenuProps}
              disabled={data.unitsList && !(data.unitsList.length > 1)}
            >
              {data.unitsList.map(enumType => (
                <MenuItem key={enumType.type} value={enumType.type}>
                  {enumType.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Typography className={classes.fontSizeClass}>
              <div className={classes.divResult}>
                {!isEmpty(data.units)
                  ? i18next.t(`service_desk_web_atoir:${data.units}UnitsType`)
                  : '-'}
              </div>
            </Typography>
          )}
        </TableCell>
        <TableCell
          numeric={clientVersion ? false : !editable}
          className={classNames(classes.tableCell, classes.tFieldCount)}
        >
          {editable ? (
            <TextField
              error={data.invalidCount}
              className={classNames(classes.textField)}
              onChange={event => {
                handleChange(data.id, 'count', event.target.value)
              }}
              value={String(data.count).replace('.', ',')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                className: classes.fontSizeClass,
              }}
            />
          ) : (
            <Typography className={classes.fontSizeClass}>
              <div className={classes.divResult}>
                {!isEmpty(data.count)
                  ? String(data.count).replace('.', ',')
                  : '-'}
              </div>
            </Typography>
          )}
        </TableCell>
        {!clientVersion && (
          <TableCell
            align={!editable ? 'right' : 'left'}
            className={classNames(
              classes.tFieldPricePurchase,
              classes.tableCell,
            )}
          >
            {editable ? (
              <TextField
                error={data.invalidPricePurchase}
                className={classes.textField}
                onChange={event => {
                  handleChange(data.id, 'price_purchase', event.target.value)
                }}
                value={String(data.price_purchase).replace('.', ',')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: { inputname: 'price_purchase' },
                  className: classes.fontSizeClass,
                }}
              />
            ) : (
              <Typography className={classes.fontSizeClass}>
                <div className={classes.divResult}>
                  {!isEmpty(data.price_purchase)
                    ? String(
                        String(data.price_purchase)
                          ? round10(Number(data.price_purchase), -2)
                          : data.price_purchase,
                      ).replace('.', ',')
                    : '-'}
                </div>
              </Typography>
            )}
          </TableCell>
        )}
        {!clientVersion && (
          <TableCell
            align={!editable ? 'right' : 'left'}
            className={classNames(
              classes.tFieldPricePurchase,
              classes.tableCell,
            )}
          >
            {editable && inputNDS ? (
              <TextField
                error={data.invalidPricePurchaseNDS}
                className={classes.textField}
                onChange={event => {
                  handleChange(
                    data.id,
                    'price_purchase_with_nds',
                    event.target.value,
                  )
                }}
                value={String(data.price_purchase_with_nds || '').replace(
                  '.',
                  ',',
                )}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: { inputname: 'price_purchase_with_nds' },
                  className: classes.fontSizeClass,
                }}
              />
            ) : (
              <Typography className={classes.fontSizeClass}>
                {String(data.price_purchase_with_nds || '').replace('.', ',')}
              </Typography>
            )}
          </TableCell>
        )}
        {!clientVersion && (
          <TableCell
            align={!editable ? 'right' : 'left'}
            className={classNames(
              classes.tFieldPricePercent,
              classes.tableCell,
            )}
          >
            {editable ? (
              <TextField
                error={data.invalidPercent}
                className={classes.textField}
                onChange={event => {
                  handleChange(data.id, 'percent', event.target.value)
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  className: classes.fontSizeClass,
                }}
                value={String(data.percent).replace('.', ',')}
              />
            ) : (
              <Typography className={classes.fontSizeClass}>
                <div className={classes.divResult}>
                  {!isEmpty(data.percent)
                    ? String(data.percent).replace('.', ',')
                    : '-'}
                </div>
              </Typography>
            )}
          </TableCell>
        )}
        <TableCell
          align={!clientVersion ? 'right' : 'left'}
          className={classes.tableCell}
        >
          <div className={classes.divResult}>
            <Typography className={classes.fontSizeClass}>{price}</Typography>
          </div>
        </TableCell>
        {!clientVersion && (
          <TableCell align="right" className={classes.tableCell}>
            <Typography className={classes.fontSizeClass}>
              <div className={classes.divResult}>{summPurchase}</div>
            </Typography>
          </TableCell>
        )}
        <TableCell
          align={!clientVersion ? 'right' : 'left'}
          className={classNames(classes.tableCell)}
        >
          <Typography className={classNames(classes.fontSizeClass)}>
            <div className={classes.divResult}>{summ}</div>
          </Typography>
        </TableCell>
        {editable && (
          <TableCell className={classes.tableCell}>
            <IconButton
              onClick={() => {
                delRow(data.id)
              }}
            >
              <CloseIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    )
  }
}

OrderItemEditor.defaultProps = {
  editable: true,
  inputNDS: 0,
  clientVersion: false,
}

OrderItemEditor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  orderPerformerOrganization: PropTypes.string.isRequired,
  inputNDS: PropTypes.number,
  data: PropTypes.object.isRequired,
  delRow: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  clientVersion: PropTypes.bool,
}

export default withStyles(styles)(OrderItemEditor)
