import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKENS_REQUEST,
  REFRESH_TOKENS_SUCCESS,
  REFRESH_TOKENS_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  isRequestingAccessData: false,
  isRefreshingAccessTokens: false,
  isLoggedIn: !!localStorage.getItem('loggedIn'),
  isLoggingOut: false,
  validationErrors: {},
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isRequestingAccessData: true,
        isLoggedIn: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isRequestingAccessData: false,
        isLoggedIn: true,
        validationErrors: {},
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        isRequestingAccessData: false,
        isLoggedIn: false,
        validationErrors: {
          email: action.errors.email,
          password: action.errors.password,
        },
      }

    case REFRESH_TOKENS_REQUEST:
      return {
        ...state,
        isRefreshingAccessTokens: true,
      }

    case REFRESH_TOKENS_SUCCESS:
      return {
        ...state,
        isRefreshingAccessTokens: false,
        validationErrors: {},
      }

    case REFRESH_TOKENS_FAILURE:
      return {
        ...state,
        isRefreshingAccessTokens: false,
        validationErrors: action.errors,
      }

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingOut: true,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isLoggedIn: false,
      }

    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        isLoggedIn: false,
      }

    default:
      return state
  }
}

export default loginReducer
