import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { omitByEmpty } from '../../../helpers/dataHelper'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import ContactsForm from '../ContactsForm'
import EditLocationComponent from '../Locations/EditLocationComponent'
import TextButton from '../../../widgets/Buttons/TextButton'
import FormDialog from '../../../widgets/FormDialog'
import Subheading from '../../../widgets/Lettering/Subheading'

// material-ui
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

class AddOrganizationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.contactsFormRef = React.createRef()
    this.state = {
      isDataValid: false,
      isFullNameValid: undefined,
      isShortNameValid: undefined,
      data: {},
      dataLegal: {},
      isCreatingOrganization: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      clientOrganization: false,
      tradeOrganization: false,
      serviceOrganization: false,
      isAddLegalAddressOpen: false,
      contacts: [],
      isContactsValid: false,
      contactsFormHelperText: false,
      INN: '',
      OGRN: '',
      BIK: '',
      KPP: '',
      OKPO: '',
    }
  }

  componentWillReceiveProps(props) {
    if (
      this.state.isCreatingOrganization !== props.isCreatingOrganization &&
      props.isCreatingOrganization === false
    ) {
      if (props.error !== undefined) {
        switch (+props.error) {
          case 55:
            this.showSnackBar(
              i18next.t('error_web_atoir:invalidLocationSpecified'),
            )
            break
          case 60:
            this.showSnackBar(i18next.t('error_web_atoir:dataValidationError'))
            break
          default:
            this.showSnackBar(
              i18next.t('error_web_atoir:couldNotCreateLocation'),
            )
            break
        }
      } else {
        props.fetchUserOrganizations()
        props.onClose(
          i18next.t('organizations_web_atoir:organizationAddedMessage'),
        )
      }
    }
    this.setState({
      ...props,
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  handleCreate = () => {
    const {
      data,
      dataLegal,
      fullName,
      shortName,
      clientOrganization,
      tradeOrganization,
      serviceOrganization,
      INN,
      KPP,
      OGRN,
      OKPO,
      bank,
      rAccount,
      kAccount,
      BIK,
      director,
      establishment,
      isINNValid,
      isOGRNValid,
      isOKPOValid,
      isBIKValid,
      isAddLegalAddressOpen,
      isContactsValid,
      contacts,
      isFullNameValid,
      isShortNameValid,
      isLocationDataValid,
      isOrganizationTypeValid,
      isLocationLegalDataValid,
    } = this.state
    this.contactsFormRef.current.validateAllContacts()
    if (
      this.state.isDataValid &&
      isINNValid !== false &&
      isOGRNValid !== false &&
      isOKPOValid !== false &&
      isBIKValid !== false
    ) {
      const streetId = data.location.location.data.street_fias_id
      let legalData = {}
      if (isAddLegalAddressOpen) {
        const streetId2 = dataLegal.location.location.data.street_fias_id
        legalData = {
          name_2: dataLegal.name,
          home_2: dataLegal.location.location.home,
          floor_2: dataLegal.floor,
          office_2: dataLegal.office,
          location_type_2: dataLegal.locationType,
          streetId_2: streetId2,
          buildingId_2: dataLegal.location.location.id,
          location_value_2: dataLegal.location.location.location.value,
        }
      }
      let contact = {}
      if (isContactsValid) {
        contact = {
          contact_full_name: contacts[0].fullName,
          contact_position: contacts[0].position,
          contact_email: contacts[0].email,
          contact_phone: contacts[0].phone.replace(/[^0-9]/g, ''),
          contact_phone_additional: contacts[0].phoneAdditional
            ? contacts[0].phoneAdditional.replace(/[^0-9]/g, '')
            : undefined,
        }
      }
      this.props.createOrganization(
        omitByEmpty({
          name_1: data.name,
          home_1: data.location.location.home,
          floor_1: data.floor,
          office_1: data.office,
          location_type_1: data.locationType,
          streetId_1: streetId,
          buildingId_1: data.location.location.id,
          location_value_1: data.location.location.value,
          ...legalData,
          ...contact,
          full_name: fullName,
          short_name: shortName,
          client_organization: clientOrganization,
          trade_organization: tradeOrganization,
          service_organization: serviceOrganization,
          INN: INN ? (INN.length > 0 ? INN : undefined) : undefined,
          KPP,
          OGRN: OGRN ? (OGRN.length > 0 ? OGRN : undefined) : undefined,
          OKPO: OKPO ? (OKPO.length > 0 ? OKPO : undefined) : undefined,
          bank,
          r_account: rAccount,
          k_account: kAccount,
          BIK: BIK ? (BIK.length > 0 ? BIK : undefined) : undefined,
          director,
          establishment,
        }),
        contacts.slice(1, contacts.length).map(c => ({
          full_name: c.fullName,
          phone: c.phone.replace(/[^0-9]/g, ''),
          email: c.email,
          position: c.position,
          phone_additional: c.phoneAdditional
            ? c.phoneAdditional.replace(/[^0-9]/g, '')
            : undefined,
        })),
      )
    } else {
      if (typeof isFullNameValid === 'undefined')
        this.setState({ isFullNameValid: false })
      if (typeof isShortNameValid === 'undefined')
        this.setState({ isShortNameValid: false })
      if (typeof isContactsValid === 'undefined')
        this.setState({ isContactsValid: false })
      if (typeof isLocationDataValid === 'undefined')
        this.setState({ isLocationDataValid: false })
      if (typeof isOrganizationTypeValid === 'undefined')
        this.setState({ isOrganizationTypeValid: false })
      if (
        isAddLegalAddressOpen &&
        typeof isLocationLegalDataValid === 'undefined'
      ) {
        this.setState({ isLocationLegalDataValid: false })
      }

      this.setState({
        contactsFormHelperText: true,
      })
    }
  }

  onDataChanged = data => {
    this.setState({
      data,
      isLocationDataValid: data.isValid,
      isDataValid: this.validate(data.isValid),
    })
  }

  onDataChangedLegal = dataLegal => {
    this.setState({
      dataLegal,
      isLocationLegalDataValid: dataLegal.isValid,
      isDataValid: this.validate(undefined),
    })
  }

  validate = isLocationValid => {
    const {
      isLocationDataValid,
      isLocationLegalDataValid,
      isAddLegalAddressOpen,
      isContactsValid,
      contacts,
    } = this.state
    if (isLocationValid === undefined && !isLocationDataValid) {
      return false
    }
    if (
      isAddLegalAddressOpen &&
      isLocationValid === undefined &&
      !isLocationLegalDataValid
    ) {
      return false
    }
    if (isLocationValid === false) {
      return false
    }
    return !(
      !this.fullName.value ||
      this.fullName.value.length === 0 ||
      !this.shortName.value ||
      this.shortName.value.length === 0 ||
      // AT-397
      // (this.INN.value !== undefined && this.INN.value.length > 0 && this.INN.value.length !== 12) ||
      // (this.OGRN.value !== undefined && this.OGRN.value.length > 0 && this.OGRN.value.length !== 13) ||
      // (this.OKPO.value !== undefined && this.OKPO.value.length > 0 && this.OKPO.value.length !== 10) ||
      // (this.BIK.value !== undefined && this.BIK.value.length > 0 && this.BIK.value.length !== 9) ||
      (!this.clientOrganization.checked &&
        // AT-396
        // && !this.tradeOrganization.checked
        !this.serviceOrganization.checked) ||
      !isContactsValid ||
      contacts.length === 0
    )
  }

  onFullNameChange = event => {
    this.setState({
      fullName: event.currentTarget.value,
      isFullNameValid: event.currentTarget.value.length > 0,
      isDataValid: this.validate(
        undefined,
        event.currentTarget.value && event.currentTarget.value.length !== 0,
        'fullName',
      ),
    })
  }

  onShortNameChange = event => {
    this.setState({
      shortName: event.currentTarget.value,
      isShortNameValid: event.currentTarget.value.length > 0,
      isDataValid: this.validate(
        undefined,
        event.currentTarget.value && event.currentTarget.value.length !== 0,
        'shortName',
      ),
    })
  }

  organizationTypeChange = field => event => {
    const {
      target: { checked },
    } = event
    this.setState(state => ({
      [field]: checked,
      isDataValid: this.validate(undefined, checked, field),
      isOrganizationTypeValid:
        checked ||
        (field !== 'clientOrganization' && state.clientOrganization) ||
        // AT-396
        // (field !== 'tradeOrganization' && state.tradeOrganization) ||
        (field !== 'serviceOrganization' && state.serviceOrganization),
    }))
  }

  handleContactsChanged = (contacts, isContactsValid) => {
    this.setState(
      {
        contacts,
        isContactsValid,
      },
      () => {
        this.setState({
          isDataValid: this.validate(),
        })
      },
    )
  }

  isFieldValid = (field, value) => {
    switch (field) {
      case 'INN':
        return value.length === 12 || value.length === 0
      case 'OGRN':
        return value.length === 13 || value.length === 0
      case 'OKPO':
        return value.length === 10 || value.length === 0
      case 'BIK':
        return value.length === 9 || value.length === 0
      default:
        return true
    }
  }

  onFieldChange = field => event => {
    if (
      (field === 'INN' ||
        field === 'OGRN' ||
        field === 'KPP' ||
        field === 'OKPO' ||
        field === 'BIK') &&
      event.target.value.match(/[^0-9]/g)
    ) {
      return
    }
    this.setState({
      [`is${field}Valid`]: this.isFieldValid(field, event.currentTarget.value),
      [field]: event.currentTarget.value,
      isDataValid: this.validate(
        undefined,
        this.isFieldValid(field, event.currentTarget.value),
        field,
      ),
    })
  }

  showAddLegalAddressForm = () => {
    this.setState({ isAddLegalAddressOpen: true }, () => {
      this.setState({
        isDataValid: this.validate(undefined),
      })
    })
  }

  closeAddLegalAddressForm = () => {
    this.setState(
      {
        isAddLegalAddressOpen: false,
      },
      () => {
        this.setState({
          isDataValid: this.validate(undefined),
        })
      },
    )
  }

  getSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  renderAddOrganizationDialog = () => {
    const { classes, open } = this.props
    const {
      fullName,
      shortName,
      // AT-397
      // INN, KPP, OGRN, OKPO, bank, rAccount, kAccount, BIK, director, establishment,
      // isINNValid, isOGRNValid, isOKPOValid, isBIKValid,
      isFullNameValid,
      isShortNameValid,
      contacts,
      contactsFormHelperText,
      isContactsValid,
      isLocationDataValid,
      isOrganizationTypeValid,
      isLocationLegalDataValid,
    } = this.state
    return (
      <FormDialog
        open={open}
        isLoading={this.props.isCreatingOrganization}
        fullScreen
        title={i18next.t('organizations_web_atoir:organizationCreation')}
        onClose={this.handleClose}
        onExit={this.handleClose}
        onSubmit={this.handleCreate}
        submitButtonTitle={i18next.t('shared_web_atoir:add')}
        leftSecondaryButtonsArray={[
          {
            secondaryText: `${i18next.t('shared_web_atoir:cancel')}`,
            handleSecondaryAction: this.handleClose,
          },
        ]}
      >
        <Grid container spacing={2} classes="center">
          <Grid item xs={6}>
            <TextField
              label={i18next.t('organizations_web_atoir:companyFullName')}
              helperText={
                isFullNameValid === false
                  ? i18next.t('validation_web_atoir:fieldCanNotBeEmpty')
                  : ''
              }
              id="organization-full-name-name"
              required
              autoFocus
              fullWidth
              error={isFullNameValid === false}
              inputRef={el => {
                this.fullName = el
              }}
              value={fullName}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFullNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={i18next.t('organizations_web_atoir:organizationShortName')}
              helperText={
                isShortNameValid === false
                  ? i18next.t('validation_web_atoir:fieldCanNotBeEmpty')
                  : ''
              }
              id="organization-short-name-name"
              required
              inputRef={el => {
                this.shortName = el
              }}
              fullWidth
              error={isShortNameValid === false}
              value={shortName}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onShortNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Subheading
              title={i18next.t(
                'organizations_web_atoir:organizationSpecialization',
              )}
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    id="clientOrganizationCheckbox"
                    checked={this.state.clientOrganization}
                    inputRef={el => {
                      this.clientOrganization = el
                    }}
                    onChange={this.organizationTypeChange('clientOrganization')}
                    value="clientOrganization"
                    color="primary"
                  />
                }
                label={i18next.t('organizations_web_atoir:clientOrganization')}
              />

              {/* AT-396 */}
              {/* <FormControlLabel */}
              {/* control={ */}
              {/* <Checkbox */}
              {/* checked={this.state.tradeOrganization} */}
              {/* inputRef={el => { */}
              {/* this.tradeOrganization = el */}
              {/* }} */}
              {/* onChange={this.organizationTypeChange('tradeOrganization')} */}
              {/* value="tradeOrganization" */}
              {/* color="primary" */}
              {/* /> */}
              {/* } */}
              {/* label={i18next.t('organizations_web_atoir:tradeOrganization')} */}
              {/* /> */}

              <FormControlLabel
                control={
                  <Checkbox
                    id="serviceOrganizationCheckbox"
                    checked={this.state.serviceOrganization}
                    inputRef={el => {
                      this.serviceOrganization = el
                    }}
                    onChange={this.organizationTypeChange(
                      'serviceOrganization',
                    )}
                    value="serviceOrganization"
                    color="primary"
                  />
                }
                onChange={() => {}}
                label={i18next.t('organizations_web_atoir:serviceOrganization')}
              />
            </FormGroup>
            {isOrganizationTypeValid === false ? (
              <FormHelperText error>
                {i18next.t(
                  'validation_web_atoir:atLeastOneSpecializationShouldBeChosen',
                )}
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>
        <br />
        <Subheading title={i18next.t('organizations_web_atoir:address')} />
        <div className={classes.addressDiv}>
          <EditLocationComponent
            denyNameEditing
            phisycal
            onChange={this.onDataChanged}
            classes={classes}
          />
          {isLocationDataValid === false ? (
            <FormHelperText error>
              {i18next.t('validation_web_atoir:incompleteAddress')}
            </FormHelperText>
          ) : null}
        </div>
        {this.state.isAddLegalAddressOpen && (
          <Fragment>
            <br />
            <div className={classes.addressDiv}>
              <EditLocationComponent
                denyNameEditing
                legal
                onChange={this.onDataChangedLegal}
                classes={classes}
              />
              {this.state.isAddLegalAddressOpen &&
              isLocationLegalDataValid === false ? (
                <FormHelperText error>
                  {i18next.t('validation_web_atoir:incompleteAddress')}
                </FormHelperText>
              ) : null}
            </div>
          </Fragment>
        )}
        <div className={classes.btnDiv}>
          {!this.state.isAddLegalAddressOpen && (
            <TextButton
              onClick={this.showAddLegalAddressForm}
              classes={{ root: classes.marginBtn }}
              size="medium"
            >
              {i18next.t('organizations_web_atoir:addLegalAddress')}
            </TextButton>
          )}
          {this.state.isAddLegalAddressOpen && (
            <TextButton
              onClick={this.closeAddLegalAddressForm}
              classes={{ root: classes.marginBtn }}
              size="medium"
            >
              {i18next.t('organizations_web_atoir:removeLegalAddress')}
            </TextButton>
          )}
        </div>
        <br />
        <Subheading
          title={i18next.t('organizations_web_atoir:contactOrgPersonInSystem')}
        />
        <ContactsForm
          ref={this.contactsFormRef}
          contacts={[{ id: Date.now() }]}
          onCreation
          handleContactsChanged={this.handleContactsChanged}
          classes={classes}
        />
        {contactsFormHelperText && !(isContactsValid && contacts.length) > 0 ? (
          <FormHelperText error>
            {i18next.t('organizations_web_atoir:errorContact')}
          </FormHelperText>
        ) : null}
        {/* AT-397 */}
        {/* <Grid container spacing={36} classes="center"> */}
        {/* <Grid item xs={12}> */}
        {/* <Subheading title={i18next.t('organizations_web_atoir:organizationRequisites')} /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:INN')} */}
        {/* id="organization-INN" */}
        {/* fullWidth */}
        {/* inputRef={el => { */}
        {/* this.INN = el */}
        {/* }} */}
        {/* value={INN} */}
        {/* error={isINNValid === false} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('INN')} */}
        {/* helperText={isINNValid === false && */}
        {/* i18next.t('validation_web_atoir:lengthShouldBeEqualTwelveSymbols')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:KPP')} */}
        {/* id="organization-KPP" */}
        {/* fullWidth */}
        {/* value={KPP} */}
        {/* inputRef={el => { */}
        {/* this.KPP = el */}
        {/* }} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('KPP')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={ */}
        {/* i18next.t('organizations_web_atoir:OGRN')} */}
        {/* id="organization-OGRN" */}
        {/* inputRef={el => { */}
        {/* this.OGRN = el */}
        {/* }} */}
        {/* fullWidth */}
        {/* error={isOGRNValid === false} */}
        {/* value={OGRN} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('OGRN')} */}
        {/* helperText={isOGRNValid === false && */}
        {/* i18next.t('validation_web_atoir:lengthShouldBeEqualThirteenSymbols')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={ */}
        {/* i18next.t('organizations_web_atoir:OKPO')} */}
        {/* id="organization-OKPO" */}
        {/* inputRef={el => { */}
        {/* this.OKPO = el */}
        {/* }} */}
        {/* error={isOKPOValid === false} */}
        {/* fullWidth */}
        {/* value={OKPO} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('OKPO')} */}
        {/* helperText={isOKPOValid === false && */}
        {/* i18next.t('validation_web_atoir:lengthShouldBeEqualTenSymbols')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:bank')} */}
        {/* id="organization-bank" */}
        {/* inputRef={el => { */}
        {/* this.bank = el */}
        {/* }} */}
        {/* fullWidth */}
        {/* value={bank} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('bank')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:checkingAccount')} */}
        {/* inputRef={el => { */}
        {/* this.rAccount = el */}
        {/* }} */}
        {/* id="organization-r_account" */}
        {/* fullWidth */}
        {/* value={rAccount} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('rAccount')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:correspondentAccount')} */}
        {/* inputRef={el => { */}
        {/* this.kAccount = el */}
        {/* }} */}
        {/* id="organization-k_account" */}
        {/* fullWidth */}
        {/* value={kAccount} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('kAccount')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:BIK')} */}
        {/* inputRef={el => { */}
        {/* this.BIK = el */}
        {/* }} */}
        {/* id="organization-BIK" */}
        {/* error={isBIKValid === false} */}
        {/* fullWidth */}
        {/* value={BIK} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('BIK')} */}
        {/* helperText={isBIKValid === false && i18next.t('validation_web_atoir:lengthShouldBeEqualNineSymbols')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* label={i18next.t('organizations_web_atoir:director')} */}
        {/* inputRef={el => { */}
        {/* this.director = el */}
        {/* }} */}
        {/* id="organization-director" */}
        {/* fullWidth */}
        {/* value={director} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('director')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.paddingGrid}> */}
        {/* <TextField */}
        {/* inputRef={el => { */}
        {/* this.establishment = el */}
        {/* }} */}
        {/* label={i18next.t('organizations_web_atoir:establishment')} */}
        {/* id="organization-establishment" */}
        {/* fullWidth */}
        {/* value={establishment} */}
        {/* className={classNames(classes.margin, classes.textField)} */}
        {/* onChange={this.onFieldChange('establishment')} */}
        {/* /> */}
        {/* </Grid> */}
        {/* </Grid> */}
        <br />
        {this.getSnackBar()}
      </FormDialog>
    )
  }

  render() {
    return <div>{this.renderAddOrganizationDialog()}</div>
  }
}

AddOrganizationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  isCreatingOrganization: PropTypes.bool.isRequired,
  fetchUserOrganizations: PropTypes.func.isRequired,
}

export default AddOrganizationComponent
