import ServiceDeskChangeLog from './ServiceDeskOrderChangeLog'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchCallPermissions, fetchOrder } from '../../../actions/serviceDesk'

const mapStateToProps = state => ({
  order: state.serviceDesk.order.order,
  isFetchingOrder: state.serviceDesk.order.isFetching,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  permissions: state.serviceDesk.permissions.permissions,
  callStatus: state.serviceDesk.permissions.status,
  permissionsError: state.serviceDesk.permissions.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCallPermissions,
      fetchOrder,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceDeskChangeLog),
)
