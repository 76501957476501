import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import SeviceDeskHistoryWrapper from './SeviceDeskOrderHistoryTableWrapper'

import {
  fetchCallPermissions,
  fetchOrdersLogRequest,
  fetchOrdersLogCountRequest,
  fetchOrder,
} from '../../../../actions/serviceDesk'

const mapStateToProps = state => ({
  call: state.serviceDesk.request.request,
  orderObject: state.serviceDesk.order.order,
  orders_log: state.serviceDesk.orders_log.orders_log,
  orders_log_count: state.serviceDesk.orders_log_count.orders_log_count,
  isFetchingOrder: state.serviceDesk.order.isFetching,
  isFetchingLogs: state.serviceDesk.orders_log.isFetching,
  isFetchingLogCount: state.serviceDesk.orders_log_count.isFetching,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  permissions: state.serviceDesk.permissions.permissions,
  callStatus: state.serviceDesk.permissions.status,
  permissionsError: state.serviceDesk.permissions.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCallPermissions,
      fetchOrder,
      fetchOrdersLogRequest,
      fetchOrdersLogCountRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeviceDeskHistoryWrapper),
)
