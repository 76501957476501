import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TableHeadComponent from './TableHeadComponent'

const mapStateToProps = state => ({
  offers: state.inventory.fetchInventoryOffersClient.offers,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableHeadComponent),
)
