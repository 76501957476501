import React from 'react'
import PropTypes from 'prop-types'

import SwipeableViews from 'react-swipeable-views'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import i18next from '../../../../i18n/i18n'

import HistoryTable from './HistoryTable/HistoryTable'
import CallHistoryLoader from './CallHistoryLoader'
import SDActionsHistoryLoader from './SDActionsHistoryLoader'
import { canViewSDCallHistory } from '../../../../helpers/callPermissionsResolver'

function TabContainer({ children }) {
  return (
    <Typography component="div" tyle={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

function HistoryViewer({ callId, permissions }) {
  const [tab, setTab] = React.useState(0)

  function handleChange(event, value) {
    setTab(value)
  }

  const handleChangeIndex = index => {
    setTab(index)
  }

  return (
    <React.Fragment>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={i18next.t('service_desk_web_atoir:historyTabCall')} />
          <Tab label={i18next.t('service_desk_web_atoir:historyTabAction')} />
        </Tabs>
      </AppBar>
      <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
        <TabContainer>
          <CallHistoryLoader
            callId={callId}
            canLoad={canViewSDCallHistory(permissions)}
            render={paginationProps => <HistoryTable {...paginationProps} />}
          />
        </TabContainer>
        <TabContainer>
          <SDActionsHistoryLoader
            callId={callId}
            canLoad={canViewSDCallHistory(permissions)}
            render={paginationProps => <HistoryTable {...paginationProps} />}
          />
        </TabContainer>
      </SwipeableViews>
    </React.Fragment>
  )
}

HistoryViewer.propTypes = {
  callId: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
}

export default HistoryViewer
