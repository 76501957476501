import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EquipmentBAProfile from './EquipmentBAProfile'
import {
  downloadMedCertificate,
  fetchOrganizationBindedEquipment,
} from '../../../../actions/equipmentActions'

const mapStateToProps = state => ({
  isFetchingEquipmentDetails:
    state.equipment.equipmentDetails.isFetchingEquipmentDetails,
  equipment: state.equipment.equipmentDetails.equipment,
  binded: state.equipment.bindedEquipment.equipment,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationBindedEquipment,
      downloadMedCertificate,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentBAProfile),
)
