import { takeLatest, select, fork, take } from 'redux-saga/effects'
import * as actions from '../actions/innerCallsActions'
import * as constants from '../constants/actionTypes/innerCalls'
import * as api from './api'
import queryString from 'query-string'

import { createDefaultSaga } from '../helpers/api'
import {
  convertDataToCreatedChartJsData,
  convertDataToOptionChartJsData,
} from '../helpers/dataHelper'

import { get, post, putReq, worker } from './appSaga'

export function* watchFetchInnerCallsSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallsApi(payload),
      result =>
        actions.fetchInnerCallsSuccess({
          innerCalls: result.innerCalls,
          ifOneOrganizationId: result.ifOneOrganizationId,
          count: result.count,
          counts: result.counts,
        }),
      code => actions.fetchInnerCallsFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallsCountAllSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_COUNT_ALL_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallsCountApi(payload),
      result => actions.fetchInnerCallsCountAllSuccess(result.count),
      code => actions.fetchInnerCallsCountAllFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallsCountCreatedSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_COUNT_CREATED_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallsCountApi(payload),
      result => actions.fetchInnerCallsCountCreatedSuccess(result.count),
      code => actions.fetchInnerCallsCountCreatedFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallsCountClosedSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_COUNT_CLOSED_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallsCountApi(payload),
      result => actions.fetchInnerCallsCountClosedSuccess(result.count),
      code => actions.fetchInnerCallsCountClosedFailure(code),
      false,
    ),
  )
}

const injectStatus = (apiCall, ...rest) =>
  function* injectStatusInInnerCallsNumbers(p) {
    const status = yield select(s => s.innerCalls.innerCallsList.status)
    console.log('Status', status)
    const handler = createDefaultSaga(
      payload => apiCall({ status, ...payload }),
      ...rest,
    )
    yield* handler(p)
  }

export function* watchFetchInnerCallsNumbersSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_NUMBERS_REQUEST,
    injectStatus(
      payload => api.getServiceDeskInnerCallsNumbersApi(payload),
      result => actions.fetchInnerCallsNumbersSuccess(result.numbers),
      code => actions.fetchInnerCallsNumbersFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallsEngineersSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_ENGINEERS_REQUEST,
    injectStatus(
      payload => api.getServiceDeskInnerCallsEngineersApi(payload),
      result => actions.fetchInnerCallsEngineersSuccess(result.engineers),
      code => actions.fetchInnerCallsEngineersFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallsEquipmentsSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_EQUIPMENTS_REQUEST,
    injectStatus(
      payload => api.getServiceDeskInnerCallsEquipmentsApi(payload),
      result => actions.fetchInnerCallsEquipmentsSuccess(result.equipments),
      code => actions.fetchInnerCallsEquipmentsFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallApi(payload),
      result => actions.fetchInnerCallSuccess(result),
      code => actions.fetchInnerCallFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallHistorySaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_HISTORY_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallHistoryApi(payload),
      (result, payload) =>
        actions.fetchInnerCallHistorySuccess({
          history: result.history,
          end:
            payload.query.limit && result.history.length < payload.query.limit,
        }),
      code => actions.fetchInnerCallHistoryFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallPhotosSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_PHOTOS_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallPhotosApi(payload),
      result => actions.fetchInnerCallPhotosSuccess(result.photos),
      code => actions.fetchInnerCallPhotosFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallPhotosCountSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_PHOTOS_COUNT_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallPhotosCountApi(payload),
      result => actions.fetchInnerCallPhotosCountSuccess(result.count),
      code => actions.fetchInnerCallPhotosCountFailure(code),
      false,
    ),
  )
}

export function* watchUploadInnerCallPhotoSaga() {
  yield takeLatest(
    constants.UPLOAD_INNER_CALL_PHOTO_REQUEST,
    createDefaultSaga(
      payload => api.uploadInnerCallPhotoApi(payload),
      result => actions.uploadInnerCallPhotoSuccess(result),
      code => actions.uploadInnerCallPhotoFailure(code),
      true,
    ),
  )
}

export function* watchDeleteInnerCallPhotoSaga() {
  yield takeLatest(
    constants.DELETE_INNER_CALL_PHOTO_REQUEST,
    createDefaultSaga(
      payload => api.deleteInnerCallPhotoApi(payload),
      result => actions.deleteInnerCallPhotoSuccess(result),
      code => actions.deleteInnerCallPhotoFailure(code),
      true,
    ),
  )
}

export function* watchFetchInnerCallAnalyticsSummarySaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallsAnalyticsSummaryApi(payload),
      result => actions.fetchInnerCallAnalyticsSummarySuccess(result),
      code => actions.fetchInnerCallAnalyticsSummaryFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallAnalyticsSummaryAverageSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_REQUEST,
    createDefaultSaga(
      payload =>
        api.getServiceDeskInnerCallsAnalyticsSummaryAverageApi(payload),
      result => actions.fetchInnerCallAnalyticsSummaryAverageSuccess(result),
      code => actions.fetchInnerCallAnalyticsSummaryAverageFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallAnalyticsChartCreatedSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallAnalyticsChartCreatedApi(payload),
      result =>
        convertDataToCreatedChartJsData(
          actions.fetchInnerCallAnalyticsChartCreatedSuccess(result),
        ),
      code => actions.fetchInnerCallAnalyticsChartCreatedFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallAnalyticsChartAverageSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallAnalyticsChartAverageApi(payload),
      result =>
        convertDataToOptionChartJsData(
          actions.fetchInnerCallAnalyticsChartAverageSuccess(result),
          'average',
        ),
      code => actions.fetchInnerCallAnalyticsChartAverageFailure(code),
      false,
    ),
  )
}

export function* watchFetchInnerCallAnalyticsChartClosedByEngineerSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_REQUEST,
    createDefaultSaga(
      payload =>
        api.getServiceDeskInnerCallAnalyticsChartClosedByEngineerApi(payload),
      result =>
        convertDataToOptionChartJsData(
          actions.fetchInnerCallAnalyticsChartClosedByEngineerRequestSuccess(
            result,
          ),
          'closedByEngineer',
        ),
      code =>
        actions.fetchInnerCallAnalyticsChartClosedByEngineerRequestFailure(
          code,
        ),
      false,
    ),
  )
}

export function* watchFetchInnerCallEngineerCountSaga() {
  yield takeLatest(
    constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_REQUEST,
    createDefaultSaga(
      payload => api.getServiceDeskInnerCallsEngineerCountApi(payload),
      result => actions.fetchInnerCallsEngineersCountSuccess(result),
      code => actions.fetchInnerCallsEngineersCountFailure(code),
      false,
    ),
  )
}

function* watchGetOrganizationsTrusted() {
  yield worker(
    () => take(constants.GET_ORGANIZATIONS_TRUSTED.REQUEST),
    payload => get(`/organizations/trusted?${queryString.stringify(payload)}`),
    actions.getOrganizationsTrusted.FAILURE,
    actions.getOrganizationsTrusted.SUCCESS,
  )
}

function* watchGetInnerCallsPerformerEquipments() {
  yield worker(
    () => take(constants.GET_INNER_CALLS_PERFORMER_EQUIPMENTS.REQUEST),
    payload => get(`/inner_calls/equipments?${queryString.stringify(payload)}`),
    actions.getInnerCallsPerformerEquipments.FAILURE,
    actions.getInnerCallsPerformerEquipments.SUCCESS,
  )
}

function* watchCreateInnerCall() {
  yield worker(
    () => take(constants.CREATE_INNER_CALL.REQUEST),
    payload => post('/inner_calls', payload),
    actions.createInnerCall.FAILURE,
    actions.createInnerCall.SUCCESS,
    result => result,
    true,
  )
}

function* watchEditInnerCall() {
  yield worker(
    () => take(constants.EDIT_INNER_CALL.REQUEST),
    payload => putReq(`/inner_calls/${payload.id}`, _.omit(payload, 'id')),
    actions.editInnerCall.FAILURE,
    actions.editInnerCall.SUCCESS,
    result => result,
    true,
  )
}

function* watchCloseInnerCall() {
  yield worker(
    () => take(constants.CLOSE_INNER_CALL.REQUEST),
    payload => post(`/inner_calls/${payload.id}/close`),
    actions.closeInnerCall.FAILURE,
    actions.closeInnerCall.SUCCESS,
    result => result,
    true,
  )
}

function* watchAssigneeInnerCall() {
  yield worker(
    () => take(constants.ASSIGNEE_INNER_CALL.REQUEST),
    payload =>
      putReq(`/inner_calls/${payload.id}/assignee`, {
        assigneeId: payload.assigneeId,
      }),
    actions.assigneeInnerCall.FAILURE,
    actions.assigneeInnerCall.SUCCESS,
    result => result,
    true,
  )
}

export function* root() {
  yield fork(watchGetOrganizationsTrusted)
  yield fork(watchGetInnerCallsPerformerEquipments)
  yield fork(watchCreateInnerCall)
  yield fork(watchEditInnerCall)
  yield fork(watchCloseInnerCall)
  yield fork(watchAssigneeInnerCall)
}
