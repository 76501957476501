import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

// components
import AddButton from '../../../../widgets/Buttons/AddIconTextButton'
import Loader from '../../../Loader'
import Dialog from '../../../../widgets/FormDialog'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listContainer: {
    width: '100%',
  },
  details: {
    padding: 0,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    margin: theme.spacing(1),
  },
  listItemSecondaryAction: {
    marginRight: theme.spacing(3),
    maxWidth: 110,
  },
  loader: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  templateName: {
    marginRight: theme.spacing(13),
    wordBreak: 'break-word',
  },
  boundToActionText: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.34)',
    wordBreak: 'break-all',
  },
})

class EmailTemplates extends Component {
  state = {
    expanded: false,
    openDeleteDialog: false,
    deleteTemplateId: null,
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        isDeletingTemplate,
        updateSettingsLists,
        shouldUpdateTemplatesList,
      },
      getTemplates,
    } = this
    if (
      prevProps.isDeletingTemplate !== isDeletingTemplate &&
      !isDeletingTemplate
    ) {
      this.setState(
        () => ({ deleteTemplateId: null, openDeleteDialog: false }),
        () => getTemplates(),
      )
    }
    if (
      prevProps.shouldUpdateTemplatesList !== shouldUpdateTemplatesList &&
      shouldUpdateTemplatesList
    ) {
      updateSettingsLists(false)
      getTemplates()
    }
  }

  onExpandedChange = () => {
    this.setState(
      state => ({
        expanded: !state.expanded,
      }),
      () => {
        if (this.state.expanded) {
          this.getTemplates()
        }
      },
    )
  }

  getTemplates = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        getEmailTemplatesRequest,
      },
    } = this
    getEmailTemplatesRequest({ organizationId })
  }

  deleteDialogOpen = open => {
    this.setState(() => ({
      openDeleteDialog: open,
    }))
  }

  deleteTemplate = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        deleteEmailTemplateRequest,
      },
      state: { deleteTemplateId },
    } = this
    deleteEmailTemplateRequest({ organizationId, templateId: deleteTemplateId })
  }

  render() {
    const {
      props: {
        classes,
        history,
        match,
        isFetching,
        templates,
        isDeletingTemplate,
      },
      state: { expanded, openDeleteDialog },
      onExpandedChange,
      deleteDialogOpen,
      deleteTemplate,
    } = this
    return (
      <ExpansionPanel
        id="organization-settings-letter-templates"
        component="div"
        expanded={expanded}
        onChange={onExpandedChange}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <Typography className={classes.heading}>
                {i18next.t('organizations_web_atoir:letterTemplates')}
              </Typography>
            </Grid>
            <Grid item>
              {expanded && (
                <AddButton
                  onClick={() => history.push(`${match.url}/templates/create`)}
                >
                  {i18next.t('organizations_web_atoir:addLetterTemplate')}
                </AddButton>
              )}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails className={classes.details}>
          <Fragment>
            {isFetching ? (
              <Loader size={24} className={classes.loader} />
            ) : (
              <div className={classes.listContainer}>
                <List className={classes.list}>
                  {templates.map(template => (
                    <Fragment>
                      <ListItem
                        className={classes.listItem}
                        onClick={() =>
                          history.push(
                            `${match.url}/templates/edit/${template.id}`,
                          )
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="caption"
                              className={classes.templateName}
                            >
                              {template.name}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction
                          className={classes.listItemSecondaryAction}
                        >
                          {template.boundToTrigger ? (
                            <Typography
                              className={classes.boundToActionText}
                              align="right"
                            >
                              {i18next.t(
                                'organizations_web_atoir:triggerIsBoundToEmail',
                              )}
                            </Typography>
                          ) : template.boundToButton ? (
                            <Typography
                              className={classes.boundToActionText}
                              align="right"
                            >
                              {i18next.t(
                                'organizations_web_atoir:buttonIsBoundToEmail',
                              )}
                            </Typography>
                          ) : (
                            <IconButton
                              aria-label="Delete"
                              onClick={() => {
                                this.setState(
                                  { deleteTemplateId: template.id },
                                  () => deleteDialogOpen(true),
                                )
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </List>
              </div>
            )}
            <Dialog
              open={openDeleteDialog}
              contentText={i18next.t(
                'organizations_web_atoir:areYouSureToDeleteTemplate',
              )}
              submitButtonTitle={i18next.t('shared_web_atoir:delete')}
              onSubmit={deleteTemplate}
              onClose={() => deleteDialogOpen(false)}
              onExit={() => deleteDialogOpen(false)}
              isLoading={isDeletingTemplate}
            />
          </Fragment>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

EmailTemplates.propTypes = {
  getEmailTemplatesRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  templates: PropTypes.array.isRequired,
  deleteEmailTemplateRequest: PropTypes.func.isRequired,
  isDeletingTemplate: PropTypes.bool.isRequired,
  updateSettingsLists: PropTypes.func.isRequired,
  shouldUpdateTemplatesList: PropTypes.bool.isRequired,
}

export default withStyles(styles)(EmailTemplates)
