import {
  GET_INVENTORY_EQUIPMENT_REQUEST,
  GET_INVENTORY_EQUIPMENT_SUCCESS,
  GET_INVENTORY_EQUIPMENT_FAILURE,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_SUCCESS,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_FAILURE,
  GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST,
  GET_INVENTORY_EQUIPMENT_FILTERS_SUCCESS,
  GET_INVENTORY_EQUIPMENT_FILTERS_FAILURE,
  GET_INVENTORY_EQUIPMENT_COUNT_REQUEST,
  GET_INVENTORY_EQUIPMENT_COUNT_SUCCESS,
  GET_INVENTORY_EQUIPMENT_COUNT_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  isFetchingEquipment: false,
  equipment: {},
  fetchEquipmentErrors: {},
  isFetchingLocations: false,
  locations: [],
  locationsCount: 0,
  fetchLocationsErrors: {},
  isFetchingFilters: false,
  filters: {},
  fetchFiltersErrors: {},
  isFetchingCount: false,
  count: 0,
  fetchCountErrors: {},
}

const formEquipmentList = (
  projectId,
  filters,
  fetchedEquipment,
  stateEquipment,
) => {
  const locationId = filters.organization_location_id
  const { floor, cabinet } = filters
  const equipment = stateEquipment
  const projectIds = _.keys(stateEquipment)
  if (_.indexOf(projectIds, projectId) !== -1) {
    const locations = _.keys(stateEquipment[projectId])
    if (_.indexOf(locations, locationId) !== -1) {
      const floors = _.keys(stateEquipment[projectId][locationId])
      if (_.indexOf(floors, floor) !== -1) {
        equipment[projectId][locationId][floor][cabinet] =
          fetchedEquipment[locationId][floor][cabinet]
      } else {
        equipment[projectId][locationId][floor] =
          fetchedEquipment[locationId][floor]
      }
    } else {
      equipment[projectId][locationId] = fetchedEquipment[locationId]
    }
  } else {
    equipment[projectId] = fetchedEquipment
  }
  return equipment
}

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVENTORY_EQUIPMENT_REQUEST:
      return {
        ...state,
        isFetchingEquipment: true,
        fetchEquipmentErrors: {},
      }

    case GET_INVENTORY_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingEquipment: false,
        equipment: formEquipmentList(
          action.projectId,
          action.filters,
          action.equipment,
          state.equipment,
        ),
      }

    case GET_INVENTORY_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingEquipment: false,
        fetchEquipmentErrors: action.errors,
      }

    case GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetchingLocations: true,
        fetchLocationsErrors: {},
      }

    case GET_INVENTORY_EQUIPMENT_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetchingLocations: false,
        locations: action.locations,
        locationsCount: action.count,
      }

    case GET_INVENTORY_EQUIPMENT_LOCATIONS_FAILURE:
      return {
        ...state,
        isFetchingLocations: false,
        fetchLocationsErrors: action.errors,
      }

    case GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST:
      return {
        ...state,
        isFetchingFilters: true,
        fetchFiltersErrors: {},
      }

    case GET_INVENTORY_EQUIPMENT_FILTERS_SUCCESS:
      return {
        ...state,
        isFetchingFilters: false,
        filters: action.filters,
      }

    case GET_INVENTORY_EQUIPMENT_FILTERS_FAILURE:
      return {
        ...state,
        isFetchingFilters: false,
        fetchFiltersErrors: action.errors,
      }

    case GET_INVENTORY_EQUIPMENT_COUNT_REQUEST:
      return {
        ...state,
        isFetchingCount: true,
        fetchCountErrors: {},
      }

    case GET_INVENTORY_EQUIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingCount: false,
        count: action.count,
      }

    case GET_INVENTORY_EQUIPMENT_COUNT_FAILURE:
      return {
        ...state,
        isFetchingCount: false,
        fetchCountErrors: action.errors,
      }

    default:
      return state
  }
}

export default organizationsReducer
