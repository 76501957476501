import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AssignToThirdPartyOrganization from './AssignToThirdPartyOrganization'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../actions/utilsActions'
import { assignServiceDeskRequestToThirdPartyOrganization } from '../../../actions/serviceDesk'

const mapStateToProps = state => ({
  isAssigning: state.serviceDesk.assigningToThirdParty.isFetching,
  assigningError: state.serviceDesk.assigningToThirdParty.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      assignToThirdPartyOrganization: assignServiceDeskRequestToThirdPartyOrganization,
      showDialogLoader,
      hideDialogLoader,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssignToThirdPartyOrganization),
)
