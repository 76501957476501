import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class OrdersList extends Component {
  componentDidMount() {
    const {
      props: { type },
      fetchOrdersType,
    } = this
    fetchOrdersType(type)
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        isChangingOrderClientPerformerResponsible,
        isChangingOrderPerformerResponsible,
        isChangingOrderEngineer,
        isEditingOrderInfo,
        isAddingOrderWorkCompletionFile,
        isDeletingOrderWorkCompletionFile,
        isSavingOrderItems,
      },
      fetchOrders,
    } = this
    if (
      (prevProps.isChangingOrderClientPerformerResponsible !==
        isChangingOrderClientPerformerResponsible &&
        !isChangingOrderClientPerformerResponsible) ||
      (prevProps.isChangingOrderPerformerResponsible !==
        isChangingOrderPerformerResponsible &&
        !isChangingOrderPerformerResponsible) ||
      (prevProps.isChangingOrderEngineer !== isChangingOrderEngineer &&
        !isChangingOrderEngineer) ||
      (prevProps.isEditingOrderInfo !== isEditingOrderInfo &&
        !isEditingOrderInfo) ||
      (prevProps.isAddingOrderWorkCompletionFile !==
        isAddingOrderWorkCompletionFile &&
        !isAddingOrderWorkCompletionFile) ||
      (prevProps.isDeletingOrderWorkCompletionFile !==
        isDeletingOrderWorkCompletionFile &&
        !isDeletingOrderWorkCompletionFile) ||
      (prevProps.isSavingOrderItems !== isSavingOrderItems &&
        !isSavingOrderItems)
    ) {
      fetchOrders()
    }
  }

  fetchOrders = () => {
    const { fetchOrdersType } = this
    _.map(['active', 'closed', 'canceled'], type => fetchOrdersType(type))
  }

  fetchOrdersType = type => {
    const {
      props: {
        service_call_id: serviceCallId,
        fetchActiveServiceCallOrdersRequest,
        fetchClosedServiceCallOrdersRequest,
        fetchCanceledServiceCallOrdersRequest,
      },
    } = this
    switch (type) {
      case 'active':
        fetchActiveServiceCallOrdersRequest({ serviceCallId })
        break

      case 'closed':
        fetchClosedServiceCallOrdersRequest({ serviceCallId })
        break

      case 'canceled':
        fetchCanceledServiceCallOrdersRequest({ serviceCallId })
        break

      default:
        break
    }
  }

  render() {
    const {
      props: {
        type,
        isFetchingActive,
        activeOrders,
        isFetchingClosed,
        closedOrders,
        isFetchingCanceled,
        canceledOrders,
      },
    } = this
    const orders =
      type === 'active'
        ? { isFetching: isFetchingActive, orders: activeOrders }
        : type === 'closed'
        ? { isFetching: isFetchingClosed, orders: closedOrders }
        : type === 'canceled'
        ? { isFetching: isFetchingCanceled, orders: canceledOrders }
        : { isFetching: false, orders: [] }
    return <Fragment>{this.props.render(orders)}</Fragment>
  }
}

OrdersList.propTypes = {
  fetchActiveServiceCallOrdersRequest: PropTypes.func.isRequired,
  isFetchingActive: PropTypes.bool.isRequired,
  activeOrders: PropTypes.func.isRequired,
  fetchClosedServiceCallOrdersRequest: PropTypes.func.isRequired,
  isFetchingClosed: PropTypes.bool.isRequired,
  closedOrders: PropTypes.func.isRequired,
  fetchCanceledServiceCallOrdersRequest: PropTypes.func.isRequired,
  isFetchingCanceled: PropTypes.bool.isRequired,
  canceledOrders: PropTypes.func.isRequired,
}

export default OrdersList
