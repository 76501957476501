import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import BreadCrumb from '../../App/routing/BreadCrumb'
import { compactObject, popLastSections } from '../../../helpers/dataHelper'
import i18next from '../../../i18n/i18n'
import {
  addEquipmentUnit,
  getBASuggest,
} from '../../../actions/equipmentActions'
import { fetchOrganization } from '../../../actions/organizationsActions'
import { connect } from 'react-redux'
import EquipmentEditor from '../EquipmentEditor'
import EquipmentSuggestionsDialog from '../common/EquipmentSuggestionsDialog'

function AddEquipment({
  organization,
  match,
  callFetchOrganization,
  callAddEquipmentUnit,
  callGetBASuggest,
  isAddingEquipmentUnit,
  addEquipmentUnitErrors,
  addedEquipmentId,
  baSuggest,
  baSuggestIsFetching,
  baSuggestErrors,
  match: {
    params: { organizationId },
    path,
  },
  history,
}) {
  const [equipment, setEquipment] = React.useState({})
  // load needed init data
  React.useEffect(() => {
    callFetchOrganization(organizationId)
  }, [])

  // goto equipment profile on success
  const [
    prevIsAddingEquipmentUnit,
    setPrevIsAddingEquipmentUnit,
  ] = React.useState()
  React.useEffect(() => {
    if (
      prevIsAddingEquipmentUnit &&
      !isAddingEquipmentUnit &&
      !addEquipmentUnitErrors
    ) {
      history.push(
        `/organizations/${organizationId}/equipment/equipments/${addedEquipmentId}/information`,
      )
    }
    setPrevIsAddingEquipmentUnit(isAddingEquipmentUnit)
  }, [isAddingEquipmentUnit])

  // suggestion load detect
  const [prevBaSuggestIsFetching, setPrevBaSuggestIsFetching] = React.useState()
  React.useEffect(() => {
    if (
      prevBaSuggestIsFetching &&
      !baSuggestIsFetching &&
      !baSuggestErrors &&
      baSuggest.length > 0
    ) {
      setDialogOpen(true)
    }
    if (
      prevBaSuggestIsFetching &&
      !baSuggestIsFetching &&
      baSuggest.length === 0
    ) {
      callAddEquipmentUnit(organizationId, equipment)
    }
    setPrevBaSuggestIsFetching(baSuggestIsFetching)
  }, [baSuggestIsFetching])
  // /suggestion load detect

  // suggestion logic
  const [selectedId, setSelectedId] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(null)

  function rejectSuggestionAndCreateCustomEquipment() {
    setDialogOpen(false)
    callAddEquipmentUnit(organizationId, equipment)
  }

  function acceptSuggestionAndCreateBABindedEquipment() {
    const { name, model, vendor } = baSuggest.find(
      item => item.id === selectedId,
    )
    setDialogOpen(false)

    callAddEquipmentUnit(
      organizationId,
      compactObject({
        ...equipment,
        ba_equipment_id: selectedId,
        name,
        model,
        vendor,
      }),
    )
  }
  // /suggestion logic

  function submit(payload) {
    setEquipment(payload)
    if (payload.ba_equipment_id) {
      creteEquipment(payload)
    } else {
      getEquipmentSuggestion(
        _.pick(payload, [
          'name',
          'model',
          'vendor',
          'industry_id',
          'country_id',
        ]),
      )
    }
  }

  function getEquipmentSuggestion(payload) {
    callGetBASuggest(payload)
  }

  function creteEquipment(payload) {
    callAddEquipmentUnit(organizationId, payload)
  }

  return (
    <Fragment>
      <BreadCrumb to={popLastSections(match.url, 3)}>
        {organization.short_name}
      </BreadCrumb>
      {path.indexOf('/organizations') === -1 ? (
        <BreadCrumb to={popLastSections(match.url, 2)}>
          {i18next.t('organizations_web_atoir:organizationEquipmentListTitle')}
        </BreadCrumb>
      ) : null}
      <BreadCrumb to={match.url}>
        {i18next.t('equipment_web_atoir:equipmentCreation')}
      </BreadCrumb>
      <EquipmentEditor
        creation
        equipment={equipment}
        organizationId={organizationId}
        onSubmit={submit}
      />
      <EquipmentSuggestionsDialog
        open={dialogOpen}
        onSubmit={acceptSuggestionAndCreateBABindedEquipment}
        handleClose={rejectSuggestionAndCreateCustomEquipment}
        baVariants={baSuggest}
        selectedId={selectedId}
        setSelectedIdHandler={setSelectedId}
        title={i18next.t('equipment_web_atoir:suggestionsFoundTitle')}
        message={i18next.t('equipment_web_atoir:suggestionsFindDescription')}
        submitText={i18next.t('equipment_web_atoir:doBind')}
        cancelText={i18next.t('equipment_web_atoir:doSkip')}
      />
    </Fragment>
  )
}

AddEquipment.propTypes = {
  organization: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  callFetchOrganization: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  baSuggest: state.equipment.baSuggest.value,
  baSuggestIsFetching: state.equipment.baSuggest.isFetching,
  baSuggestErrors: state.equipment.baSuggest.error,
  isAddingEquipmentUnit: state.equipment.isAddingEquipmentUnit,
  addEquipmentUnitErrors: state.equipment.addEquipmentUnitErrors,
  addedEquipmentId: state.equipment.addedEquipmentId,
  organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callFetchOrganization: fetchOrganization,
      callAddEquipmentUnit: addEquipmentUnit,
      callGetBASuggest: getBASuggest.REQUEST,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEquipment),
)
