import isEmpty from 'is-empty'

import {
  CATCH_ERROR,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  CLOSE_ERROR_MESSAGE,
  REDIRECT_USER_TO_HOME_PAGE,
  REDIRECTED_TO_HOME_PAGE,
  SHOW_DIALOG_WITH_OK,
  CLOSE_DIALOG_WITH_OK,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  SET_FILTER_DRAWER,
  CLEAR_FILTER_DRAWER,
  SET_NAVIGATION_DRAWER,
  CLEAR_NAVIGATION_DRAWER,
  GET_ALL_USER_SETTINGS,
  SAVE_USER_SETTINGS,
} from '../constants/actionTypes'

const initialState = {
  isLoggedIn: false,
  notifications: [],
  simpleDialogText: '',
  simpleDialogOpen: false,
  closeErrorMessage: true,
  shouldRedirectToHome: false,
  downloadFile: {
    files: [],
    isFetching: false,
    error: [],
  },
  filterDrawer: {
    component: null,
    footer: null,
  },
  navigationDrawer: {
    component: null,
  },
  userSettings: {
    value: {},
  },
  saveUserSettings: {
    isFetching: false,
    error: null,
  },
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_SETTINGS.REQUEST:
      return {
        ...state,
        saveUserSettings: {
          ...state.saveUserSettings,
          isFetching: true,
          error: null,
        },
        userSettings: {
          ...state.userSettings,
          value: action.payload.userSettings,
        },
      }
    case SAVE_USER_SETTINGS.SUCCESS:
      return {
        ...state,
        saveUserSettings: {
          ...state.saveUserSettings,
          isFetching: false,
          error: null,
        },
      }

    case SAVE_USER_SETTINGS.FAILURE:
      return {
        ...state,
        saveUserSettings: {
          ...state.saveUserSettings,
          isFetching: false,
          error: action.payload.error,
        },
      }
    case GET_ALL_USER_SETTINGS.REQUEST:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          isFetching: true,
          error: null,
        },
      }
    case GET_ALL_USER_SETTINGS.SUCCESS:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          value: action.payload.settings,
          isFetching: false,
          error: null,
        },
      }

    case GET_ALL_USER_SETTINGS.FAILURE:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          isFetching: false,
          error: action.payload.error,
        },
      }

    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      }

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      }

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      }

    case CATCH_ERROR:
      return {
        ...state,
        error: action.errorDescription,
        closeErrorMessage: false,
      }

    case SHOW_DIALOG_WITH_OK:
      return {
        ...state,
        simpleDialogText: action.payload,
        simpleDialogOpen: true,
      }

    case CLOSE_DIALOG_WITH_OK:
      return {
        ...state,
        simpleDialogText: '',
        simpleDialogOpen: false,
      }

    case CLOSE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
        error: '',
        closeErrorMessage: true,
      }

    case REDIRECT_USER_TO_HOME_PAGE:
      return {
        ...state,
        shouldRedirectToHome: true,
      }

    case REDIRECTED_TO_HOME_PAGE:
      return {
        ...state,
        shouldRedirectToHome: false,
      }

    case DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          files: state.downloadFile.files.concat(action.payload),
          isFetching: true,
          error: action.payload.clearError ? [] : state.downloadFile.error,
        },
      }

    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          files: _.filter(
            state.downloadFile.files,
            file => file.id !== action.payload.id,
          ),
          isFetching: state.downloadFile.files.length > 1,
        },
      }

    case DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          files: _.filter(
            state.downloadFile.files,
            payload => payload.id !== action.payload.id,
          ),
          isFetching: isEmpty(state.downloadFile.files),
          error: state.downloadFile.error.concat(action.payload),
        },
      }

    case SET_FILTER_DRAWER:
      return {
        ...state,
        filterDrawer: {
          component: Array.isArray(action.payload)
            ? action.payload[0]
            : action.payload,
          footer: Array.isArray(action.payload) ? action.payload[1] : null,
        },
      }

    case CLEAR_FILTER_DRAWER:
      return {
        ...state,
        filterDrawer: {
          component: null,
          footer: null,
        },
      }

    case SET_NAVIGATION_DRAWER:
      return {
        ...state,
        navigationDrawer: {
          component: action.payload,
        },
      }

    case CLEAR_NAVIGATION_DRAWER:
      return {
        ...state,
        navigationDrawer: {
          component: null,
        },
      }

    default:
      return state
  }
}

export default appReducer
