import { all, fork } from 'redux-saga/effects'

// sagas import
import rootAppSaga, {
  watchInfoMessagesSaga,
  watchClearAuthErrorSaga,
  watchDownloadFileCommonRequest,
  watchAppSaga,
} from './appSaga'
import {
  watchRegisterEmailSaga,
  watchGetConfirmLinkAgainSaga,
  watchConfirmEmailSaga,
  watchRegisterAccountSaga,
} from './registerAccountSaga'
import {
  watchLoginSaga,
  watchRefreshTokenSaga,
  watchLogoutSaga,
} from './loginSaga'
import {
  watchRepairPasswordSaga,
  watchConfirmRepairPasswordSaga,
  watchSetNewPasswordSaga,
} from './repairPasswordSaga'
import {
  watchFetchUserSaga,
  watchFetchUserByIdSaga,
  watchUploadUserImageSaga,
  watchUpdateUserAvatarSaga,
  watchUpdateUserSaga,
  watchChangePasswordSaga,
  watchRemoveUserAvatarSaga,
  watchFetchUsesrNotificationSettings,
  watchSetUserNotificationSettings,
} from './userSaga'
import organizationsRootSaga, {
  watchFetchUserOrganizationFunctionalsSaga,
  watchCreateOrganizationSaga,
  watchUpdateOrganizationSaga,
  watchFetchUserOrganizationsSaga,
  watchFetchNotUserOrganizationsSaga,
  watchFetchOrganizationSaga,
  watchFetchOrganizationPermissionsSaga,
  watchFetchAllOrganizationsSaga,
  watchFetchCitiesWithServiceOrganizationsSaga,
  watchFetchServiceOrganizationsSaga,
  watchFetchAdminOrganizationsSaga,
  watchFetchManagerOrHigherOrganizationsSaga,
  watchFetchOrganizationsMemberOfSaga,
  watchFetchOrganizationWhoAmISaga,
  watchFetchOrganizationMeSaga,
  watchFetchServiceOrganizationCountsSaga,
  watchFetchUserOrganizationsCountSaga,
  watchFetchHasManagerOrHigherOrganizationsSaga,
  watchFetchOrganizationsCanAddEquipmentSaga,
} from './organizationsSaga'
import {
  watchFetchStaffSaga,
  watchDeleteStaffSaga,
  watchFetchOrganizationStaffSaga,
  watchAddOrganizationUserSaga,
  watchBindOrganizationUserAccountSaga,
  watchUnbindOrganizationUserAccountSaga,
  watchUpdateOrganizationUserSaga,
  watchDeleteOrganizationUserSaga,
  watchFetchOrganizationLocationsSaga,
  watchFetchBoundOrganizationUsersSaga,
  watchFetchUserContactsSaga,
  watchFetchOrganizationStaffCountSaga,
  watchFetchUserContactsContSaga,
} from './staffSaga'
import {
  watchFetchEmployersSaga,
  watchLeaveOrganizationSaga,
  watchConfirmJoinOrganizationSaga,
  watchFetchEmployersCountSaga,
} from './employersSaga'
import {
  watchFetchLocationSaga,
  watchFetchLocationsSaga,
  watchFetchLocationsCountSaga,
  watchCreateLocationSaga,
  watchUpdateLocationSaga,
  watchFetchPhysicalLocationSaga,
  watchFetchLegalLocationSaga,
  watchFetchUserEquipmentLocationsSaga,
  watchDeleteLocationSaga,
} from './locationsSaga'
import {
  watchFetchDepartmentSaga,
  watchFetchDepartmentsSaga,
  watchCreateDepartmentSaga,
  watchUpdateDepartmentSaga,
  watchDeleteDepartmentSaga,
  watchFetchUserEquipmentDepartmentsSaga,
  watchFetchDepartmentsCountSaga,
} from './departmentsSaga'
import {
  watchFetchContactsSaga,
  watchDeleteContactSaga,
  watchCreateContactSaga,
  watchUpdateContactSaga,
} from './organizationContactsSaga'
import equipmentsDeskRootSaga, {
  watchProcessEquipmentsImportFile,
  watchCheckEquipmentsImportFile,
  watchFetchEquipmentSaga,
  watchFetchVendorsSaga,
  watchFetchCountryNamesSaga,
  watchAddEquipmentUnitSaga,
  watchFetchEquipmentPermissionsSaga,
  watchFetchEquipmentDetailsSaga,
  watchUpdateEquipmentDetailsSaga,
  watchTransferEquipmentToOwnOrganizationSaga,
  watchTransferEquipmentToForeignOrganizationSaga,
  watchFetchUserEquipmentSaga,
  watchFetchUserEquipmentVendorsSaga,
  watchFetchTransferringEquipmentSenderSaga,
  watchFetchTransferringEquipmentReceiverSaga,
  watchTransferringEquipmentSaga,
  watchFetchEquipmentCountSaga,
  watchFetchUserEquipmentCountSaga,
  watchFetchEquipmentLogsSaga,
  watchEquipmentLogsCountSaga,
  watchFetchTransferringEquipmentSenderCountSaga,
  watchFetchTransferringEquipmentReceiverCountSaga,
  watchFetchEquipmentFilterLocationRequestSaga,
  watchFetchEquipmentFilterDepartmentRequestSaga,
  watchFetchEqupmentBinded,
  watchMedCertificateSaga,
  watchFetchEqupmentBindedTOWorks,
} from './equipmentSaga'
import {
  watchCreateInventoryProjectSaga,
  watchFetchInventoryProjectsSaga,
  watchFetchInventoryProjectProfileSaga,
  watchPutProjectUpOnAuctionSaga,
  watchInventoryProjectsCountSaga,
  watchEditInventoryProjectSaga,
  watchFetchInventoryProjectsCitiesSaga,
  watchGetInventoryClientsSaga,
  watchGetInventoryPerformersSaga,
  watchConfirmInventoryProjectAsPerformerSaga,
  watchRejectInventoryProjectAsPerformerSaga,
  watchSetOwnPerformerSaga,
  watchSetForeignPerformerSaga,
  watchOfferInventoryProjectSaga,
  watchEditInventoryProjectResponsibleSaga,
  watchFetchInventoryProjectsOffersPerformerSaga,
  watchFetchInventoryProjectsOffersClientSaga,
  watchStopInventoryProjectsAuctionSaga,
  watchCancelInventoryProjectSaga,
  watchSetInventoryProjectDoneSaga,
  watchSetInventoryProjectClosedSaga,
  watchSetInventoryProjectBackInWorkSaga,
  watchChangePerformerRatingSaga,
  watchChangeClientRatingSaga,
  watchConfirmOfferInventoryProjectSaga,
  watchCancelOfferInventoryProjectSaga,
  watchFetchProjectPermissionsSaga,
  watchFetchEngineersInventorySaga,
  watchEditEngineersInventorySaga,
  watchRemoveEngineerInventorySaga,
  watchFetchFiltersOrdersIvnentorySaga,
  watchFetchOrdersIvnentorySaga,
  watchFetchOrdersCountInventorySaga,
  watchRemoveOrderInventorySaga,
  watchEditEngineerOrderInventorySaga,
  watchFetchHistoryInventorySaga,
  watchFetchHistoryCountInventorySaga,
} from './inventorySaga'
import {
  watchFetchInventoryEquipmentSaga,
  watchFetchInventoryEquipmentFiltersSaga,
  watchFetchInventoryEquipmentLocationsSaga,
  watchFetchInventoryEquipmentCountSaga,
} from './inventoryEquipmentSaga'
import serviceDeskRootSaga, {
  watchAssignRequestToOwnSpecialistPhotoSaga,
  watchAssignToThirdPartyRequestSaga,
  watchCreateServiceDeskRequestSaga,
  watchDeleteDocRequestPhotoSaga,
  watchDeletePhotoRequestPhotoSaga,
  watchDownloadRequestDocumentSaga,
  watchDownloadRequestPhotoSaga,
  watchFetchCallPermissionsSaga,
  watchFetchFilesServiceDeskRequestSaga,
  watchFetchPhotosServiceDeskRequestSaga,
  watchFetchServiceDeskRequestSaga,
  watchSendToSubOrderSaga,
  watchUpdateServiceDeskRequestSaga,
  watchUploadFileServiceDeskRequestSaga,
  watchUploadPhotoServiceDeskRequestSaga,
  watchFetchOrdersSaga,
  watchFetchServiceCallsListSaga,
  watchFetchServiceCallsListCountSaga,
  watchFetchServiceCallOrdersListSaga,
  watchFetchServiceCallOrdersListCountSaga,
  watchFetchServiceDeskCallOrdersSaga,
  watchCreateServiceDeskRequestCancelSaga,
  watchCreateRejectOrderSaga,
  watchAssignServiceDeskOrderToOwnSpecialistSaga,
  watchSendOrderToSubOrderSaga,
  watchChangePerformerResponsibleOrderRequestSaga,
  watchChangeClientResponsibleOrderRequestSaga,
  watchChangeEngineerOrderRequestSaga,
  watchEditOrderInfoSaga,
  watchFetchOrdersLogSaga,
  watchFetchOrdersLogCountSaga,
  watchCancelOrderByClientRequestSaga,
  watchFetchServiceDeskOrderCommentsSaga,
  watchUploadServiceDeskOrderCommentRequestSaga,
  watchUploadServiceDeskOrderSubcommentRequestSaga,
  watchFetchServiceDeskOrderCommentsCountSaga,
  watchCreateServiceContractsRequest,
  watchSetOrderResultTypeSaga,
  watchFetchServiceCallOrderItemsSaga,
  watchFetchServiceCallOrderItemsCountSaga,
  watchFetchServiceCallSuborderItemsSaga,
  watchFetchServiceCallSuborderItemsCountSaga,
  watchSaveServiceCallOrderItemsListSaga,
  watchClearServiceCallOrderItemsListSaga,
  watchCopyOrderItemsFromChildSaga,
  watchFetchOrderNdsSaga,
  watchUpdateOrderNdsSaga,
  watchUploadOrderWorkCompletionFileRequestSaga,
  watchDeleteOrderWorkCompletionFileRequest,
  watchFetchServiceDeskContractAvailableEquipmentRequestSaga,
  watchFetchServiceDeskContractRequestSaga,
  watchfetchServiceDeskContractAvailableEquipmentCountRequestSaga,
  watchAddEquipmentToContractsRequest,
  watchAssignUsingContractSaga,
  watchConfirmCertificateOfCompletionSaga,
  watchFetchActiveServiceCallOrdersSaga,
  watchFetchClosedServiceCallOrdersSaga,
  watchFetchCanceledServiceCallOrdersSaga,
  watchAcceptOrderCertificateOfCompletionSaga,
  watchRejectOrderCertificateOfCompletionSaga,
  watchCloseServiceCallFromNeedrepairSaga,
  watchSendServiceCallToRepairDiagnosticiansSaga,
  watchFetchOfferFilesServiceDeskRequestSaga,
  watchSendCallUsingContractSaga,
  watchFetchCallHistorySaga,
  watchGetOrderPossibleReportsSaga,
  watchGetCallPossibleBlanksSaga,
  watchGetCallsByNumberSearchSaga,
  watchUpdateOrderOverheadSaga,
  watchUpdateOrderIncomeNdsSaga,
  watchFetchOrderOverheadSaga,
  watchFetchOrderIncomeNdsSaga,
  watchGetSDActionsHistorySaga,
  watchGetServiceCallDocumentLinkSaga,
  watchGetServiceCallBlankDocumentLinkSaga,
  watchGetServiceCallOrderCertificateDocumentLinkSaga,
  watchGetServiceCallOrderDocumentLinkSaga,
  watchGetServiceCallOrderCommentFileLinkSaga,
  watchAttachServiceCallTagSaga,
  watchMakeRecurringFromServiceCallSaga,
  watchDetachServiceCallTagSaga,
  watchEditRecurringCallSaga,
  watchDeleteRecurringCallSaga,
} from './serviceDeskSaga'
import {
  watchAcceptServiceDeskContractAgreementRequestSaga,
  watchAcceptServiceDeskContractRequestSaga,
  watchAddServiceDeskContractAgreementEquipmentsRequestSaga,
  watchAssignEngineerToEquipmentsRequestSaga,
  watchAssignServiceDeskContractAgreementRequestSaga,
  watchAssignServiceDeskContractRequestSaga,
  watchCancelServiceDeskContractAgreementRequestSaga,
  watchCancelServiceDeskContractRequestSaga,
  watchCreateAgreementRequestSaga,
  watchDeleteServiceDeskAgreementEquipmentRequestSaga,
  watchDeleteServiceDeskContractEquipmentRequestSaga,
  watchEditAgreementNameRequestSaga,
  watchEditServiceDeskContractRequestSaga,
  watchEditServiceDeskContractResonsibleRequestSaga,
  watchFetchAgreementRequestSaga,
  watchFetchAgreementsCountRequestSaga,
  watchFetchAgreementsRequestSaga,
  watchFetchFilteringOrganizationsRequestSaga,
  watchFetchServiceDeskAgreementEquipmentCountRequestSaga,
  watchFetchServiceDeskAgreementEquipmentRequestSaga,
  watchFetchServiceDeskAgreementLogsCountRequestSaga,
  watchFetchServiceDeskAgreementLogsRequestSaga,
  watchFetchServiceDeskContractEquipmentCountRequestSaga,
  watchFetchServiceDeskContractEquipmentRequestSaga,
  watchFetchServiceDeskContractLogsCountRequestSaga,
  watchFetchServiceDeskContractLogsRequestSaga,
  watchFetchServiceDeskContractPermissionsRequestSaga,
  watchFetchServiceDeskContractRequestSaga as watchFetchServiceDeskContractUnitRequestSaga,
  watchFetchServiceDeskContractsCountRequestSaga,
  watchFetchServiceDeskContractsRequestSaga,
  watchRejectServiceDeskContractAgreementRequestSaga,
  watchRejectServiceDeskContractRequestSaga,
  watchUnAssignEngineerToEquipmentsRequestSaga,
} from './serviceDeskContractsSaga'
import * as settingsSagas from './settingsSaga'
import {
  watchDownloadSDWorkingReportRequestSaga,
  watchFetchGenerateSDWorkingReportRequestSaga,
} from './statisticsSaga'
import {
  watchCreateScriptSaga,
  watchEditScriptSaga,
  watchGetScriptsSaga,
  watchDeleteScriptSaga,
  watchGetScriptSaga,
  watchGetScriptsHistorySaga,
  watchGetSDCallAvailableScriptsSaga,
} from './scriptSaga'
import {
  watchCreateTagSaga,
  watchEditTagSaga,
  watchGetTagsSaga,
  watchDeleteTagSaga,
  watchGetTagSaga,
} from './tagsSaga'
import {
  watchGetEmailTemplatesSaga,
  watchGetEmailTemplateSaga,
  watchCreateEmailTemplateSaga,
  watchEditEmailTemplateSaga,
  watchDeleteEmailTemplateSaga,
} from './emailTemplatesSaga'
import {
  watchCreateButtonSaga,
  watchEditButtonSaga,
  watchGetButtonsSaga,
  watchDeleteButtonSaga,
  watchGetButtonSaga,
  watchGetButtonsHistorySaga,
  watchExecuteSDCallButtonSaga,
  watchGetSDCallButtonsSaga,
} from './buttonsSaga'
import * as innerCalls from './innerCallsSaga'
import * as trustedOrganizations from './trustedOrganizations'
// eslint-disable-next-line import/named
import {
  watchGetRecurringCallsCountSaga,
  watchGetRecurringCallsSaga,
} from './recurringCallsSaga'

export default function* rootSaga() {
  yield all([
    watchAppSaga(),
    watchInfoMessagesSaga(),
    watchClearAuthErrorSaga(),
    watchDownloadFileCommonRequest(),
    fork(rootAppSaga),

    // register
    watchRegisterEmailSaga(),
    watchGetConfirmLinkAgainSaga(),
    watchConfirmEmailSaga(),
    watchRegisterAccountSaga(),

    // login
    watchLoginSaga(),
    watchRefreshTokenSaga(),
    watchLogoutSaga(),
    watchRepairPasswordSaga(),
    watchConfirmRepairPasswordSaga(),
    watchSetNewPasswordSaga(),

    // user profile
    watchFetchUserSaga(),
    watchFetchUserByIdSaga(),
    watchUpdateUserSaga(),
    watchRemoveUserAvatarSaga(),
    watchFetchUsesrNotificationSettings(),
    watchSetUserNotificationSettings(),
    watchUploadUserImageSaga(),
    watchUpdateUserAvatarSaga(),
    watchChangePasswordSaga(),

    // organizations
    fork(organizationsRootSaga),
    watchFetchUserOrganizationFunctionalsSaga(),
    watchCreateOrganizationSaga(),
    watchUpdateOrganizationSaga(),
    watchFetchUserOrganizationsSaga(),
    watchFetchUserOrganizationsCountSaga(),
    watchFetchManagerOrHigherOrganizationsSaga(),
    watchFetchHasManagerOrHigherOrganizationsSaga(),
    watchFetchAdminOrganizationsSaga(),
    watchFetchNotUserOrganizationsSaga(),
    watchFetchOrganizationSaga(),
    watchFetchOrganizationWhoAmISaga(),
    watchFetchOrganizationMeSaga(),
    watchFetchStaffSaga(),
    watchDeleteStaffSaga(),
    watchFetchUserContactsContSaga(),
    watchFetchUserContactsSaga(),
    watchFetchOrganizationStaffCountSaga(),
    watchFetchOrganizationStaffSaga(),
    watchAddOrganizationUserSaga(),
    watchBindOrganizationUserAccountSaga(),
    watchUnbindOrganizationUserAccountSaga(),
    watchUpdateOrganizationUserSaga(),
    watchDeleteOrganizationUserSaga(),
    watchFetchOrganizationLocationsSaga(),
    watchFetchEmployersSaga(),
    watchFetchEmployersCountSaga(),
    watchLeaveOrganizationSaga(),
    watchConfirmJoinOrganizationSaga(),
    watchFetchAllOrganizationsSaga(),
    watchFetchBoundOrganizationUsersSaga(),
    watchFetchCitiesWithServiceOrganizationsSaga(),
    watchFetchServiceOrganizationsSaga(),
    watchFetchServiceOrganizationCountsSaga(),
    watchFetchOrganizationsCanAddEquipmentSaga(),

    // locations & departments
    watchFetchLocationSaga(),
    watchFetchLocationsSaga(),
    watchFetchLocationsCountSaga(),
    watchFetchDepartmentSaga(),
    watchFetchDepartmentsCountSaga(),
    watchFetchDepartmentsSaga(),
    watchCreateDepartmentSaga(),
    watchUpdateDepartmentSaga(),
    watchDeleteDepartmentSaga(),
    watchCreateLocationSaga(),
    watchUpdateLocationSaga(),
    watchFetchPhysicalLocationSaga(),
    watchFetchLegalLocationSaga(),
    watchFetchUserEquipmentDepartmentsSaga(),
    watchFetchUserEquipmentLocationsSaga(),
    watchDeleteLocationSaga(),

    // organization contacts
    watchFetchContactsSaga(),
    watchDeleteContactSaga(),
    watchCreateContactSaga(),

    watchFetchOrganizationsMemberOfSaga(),

    // organization permissions
    watchUpdateContactSaga(),
    watchFetchOrganizationPermissionsSaga(),

    // organization equipment
    watchMedCertificateSaga(),
    watchProcessEquipmentsImportFile(),
    watchCheckEquipmentsImportFile(),
    watchFetchEquipmentSaga(),
    watchFetchVendorsSaga(),
    watchFetchEquipmentPermissionsSaga(),

    // equipment
    fork(equipmentsDeskRootSaga),
    watchFetchCountryNamesSaga(),
    watchAddEquipmentUnitSaga(),
    watchFetchUserEquipmentSaga(),
    watchFetchUserEquipmentVendorsSaga(),
    watchFetchTransferringEquipmentSenderSaga(),
    watchFetchTransferringEquipmentSenderCountSaga(),
    watchFetchTransferringEquipmentReceiverSaga(),
    watchFetchTransferringEquipmentReceiverCountSaga(),
    watchTransferringEquipmentSaga(),
    watchFetchEquipmentCountSaga(),
    watchFetchUserEquipmentCountSaga(),
    watchFetchEquipmentDetailsSaga(),
    watchUpdateEquipmentDetailsSaga(),
    watchTransferEquipmentToOwnOrganizationSaga(),
    watchTransferEquipmentToForeignOrganizationSaga(),
    watchFetchEquipmentLogsSaga(),
    watchEquipmentLogsCountSaga(),
    watchFetchEquipmentFilterLocationRequestSaga(),
    watchFetchEqupmentBinded(),
    watchFetchEqupmentBindedTOWorks(),

    // inventory equipment
    watchFetchInventoryEquipmentSaga(),
    watchFetchInventoryEquipmentFiltersSaga(),
    watchFetchInventoryEquipmentLocationsSaga(),
    watchFetchInventoryEquipmentCountSaga(),

    // inventory
    watchCreateInventoryProjectSaga(),
    watchFetchInventoryProjectsSaga(),
    watchEditInventoryProjectSaga(),
    watchFetchInventoryProjectProfileSaga(),
    watchPutProjectUpOnAuctionSaga(),
    watchInventoryProjectsCountSaga(),
    watchFetchInventoryProjectsCitiesSaga(),
    watchGetInventoryClientsSaga(),
    watchGetInventoryPerformersSaga(),
    watchConfirmInventoryProjectAsPerformerSaga(),
    watchRejectInventoryProjectAsPerformerSaga(),
    watchSetOwnPerformerSaga(),
    watchSetForeignPerformerSaga(),
    watchOfferInventoryProjectSaga(),
    watchEditInventoryProjectResponsibleSaga(),
    watchFetchInventoryProjectsOffersPerformerSaga(),
    watchFetchInventoryProjectsOffersClientSaga(),
    watchStopInventoryProjectsAuctionSaga(),
    watchCancelInventoryProjectSaga(),
    watchSetInventoryProjectDoneSaga(),
    watchSetInventoryProjectClosedSaga(),
    watchSetInventoryProjectBackInWorkSaga(),
    watchChangePerformerRatingSaga(),
    watchChangeClientRatingSaga(),
    watchConfirmOfferInventoryProjectSaga(),
    watchCancelOfferInventoryProjectSaga(),
    watchFetchProjectPermissionsSaga(),
    watchFetchEngineersInventorySaga(),
    watchEditEngineersInventorySaga(),
    watchFetchFiltersOrdersIvnentorySaga(),
    watchFetchOrdersIvnentorySaga(),
    watchFetchOrdersCountInventorySaga(),
    watchRemoveEngineerInventorySaga(),
    watchRemoveOrderInventorySaga(),
    watchEditEngineerOrderInventorySaga(),
    watchFetchHistoryInventorySaga(),
    watchFetchHistoryCountInventorySaga(),

    // ServiceDesk,
    fork(serviceDeskRootSaga),
    watchCreateServiceDeskRequestSaga(),
    watchFetchServiceDeskRequestSaga(),
    watchUpdateServiceDeskRequestSaga(),
    watchUploadFileServiceDeskRequestSaga(),
    watchUploadPhotoServiceDeskRequestSaga(),
    watchDownloadRequestDocumentSaga(),
    watchDeletePhotoRequestPhotoSaga(),
    watchFetchCallPermissionsSaga(),
    watchFetchCallHistorySaga(),
    // watchAssignToThirdPartyRequestPhotoSaga(),
    watchAssignRequestToOwnSpecialistPhotoSaga(),
    watchAssignToThirdPartyRequestSaga(),
    watchSendToSubOrderSaga(),
    watchDeleteDocRequestPhotoSaga(),
    watchDownloadRequestPhotoSaga(),
    watchFetchPhotosServiceDeskRequestSaga(),
    watchFetchFilesServiceDeskRequestSaga(),
    watchFetchOrdersSaga(),
    watchFetchServiceCallsListSaga(),
    watchFetchServiceCallsListCountSaga(),
    watchFetchServiceCallOrdersListSaga(),
    watchFetchServiceCallOrdersListCountSaga(),
    watchFetchServiceDeskCallOrdersSaga(),
    watchAssignServiceDeskOrderToOwnSpecialistSaga(),
    watchSendOrderToSubOrderSaga(),
    watchCreateServiceDeskRequestCancelSaga(),
    watchCreateRejectOrderSaga(),
    watchChangePerformerResponsibleOrderRequestSaga(),
    watchChangeClientResponsibleOrderRequestSaga(),
    watchChangeEngineerOrderRequestSaga(),
    watchEditOrderInfoSaga(),
    watchFetchOrdersLogSaga(),
    watchFetchOrdersLogCountSaga(),
    watchCancelOrderByClientRequestSaga(),
    watchFetchServiceDeskOrderCommentsSaga(),
    watchUploadServiceDeskOrderCommentRequestSaga(),
    watchUploadServiceDeskOrderSubcommentRequestSaga(),
    watchFetchServiceDeskOrderCommentsCountSaga(),
    watchFetchActiveServiceCallOrdersSaga(),
    watchFetchClosedServiceCallOrdersSaga(),
    watchFetchCanceledServiceCallOrdersSaga(),
    watchAcceptOrderCertificateOfCompletionSaga(),
    watchRejectOrderCertificateOfCompletionSaga(),
    watchCloseServiceCallFromNeedrepairSaga(),
    watchSendServiceCallToRepairDiagnosticiansSaga(),
    watchFetchOfferFilesServiceDeskRequestSaga(),
    watchSendCallUsingContractSaga(),
    watchGetOrderPossibleReportsSaga(),
    watchGetCallPossibleBlanksSaga(),
    watchGetCallsByNumberSearchSaga(),
    watchGetSDActionsHistorySaga(),
    watchGetServiceCallDocumentLinkSaga(),
    watchGetServiceCallBlankDocumentLinkSaga(),
    watchGetServiceCallOrderCertificateDocumentLinkSaga(),
    watchGetServiceCallOrderDocumentLinkSaga(),
    watchGetServiceCallOrderCommentFileLinkSaga(),
    watchAttachServiceCallTagSaga(),
    watchMakeRecurringFromServiceCallSaga(),
    watchDeleteRecurringCallSaga(),
    watchEditRecurringCallSaga(),
    watchDetachServiceCallTagSaga(),

    // contracts
    watchFetchServiceDeskContractsRequestSaga(),
    watchFetchServiceDeskContractPermissionsRequestSaga(),
    watchAssignEngineerToEquipmentsRequestSaga(),
    watchUnAssignEngineerToEquipmentsRequestSaga(),
    watchEditServiceDeskContractRequestSaga(),
    watchEditServiceDeskContractResonsibleRequestSaga(),
    watchCreateAgreementRequestSaga(),
    watchFetchAgreementRequestSaga(),
    watchEditAgreementNameRequestSaga(),
    watchFetchServiceDeskContractRequestSaga(),
    watchRejectServiceDeskContractRequestSaga(),
    watchAcceptServiceDeskContractRequestSaga(),
    watchAssignServiceDeskContractRequestSaga(),
    watchFetchServiceDeskContractLogsCountRequestSaga(),
    watchFetchServiceDeskContractLogsRequestSaga(),
    watchFetchServiceDeskAgreementLogsRequestSaga(),
    watchFetchServiceDeskAgreementLogsCountRequestSaga(),
    watchAddServiceDeskContractAgreementEquipmentsRequestSaga(),
    watchCancelServiceDeskContractRequestSaga(),
    watchFetchServiceDeskContractUnitRequestSaga(),
    watchFetchServiceDeskContractEquipmentRequestSaga(),
    watchFetchAgreementsRequestSaga(),
    watchFetchAgreementsCountRequestSaga(),
    watchCancelServiceDeskContractAgreementRequestSaga(),
    watchAcceptServiceDeskContractAgreementRequestSaga(),
    watchRejectServiceDeskContractAgreementRequestSaga(),
    watchAssignServiceDeskContractAgreementRequestSaga(),
    watchFetchServiceDeskAgreementEquipmentRequestSaga(),
    watchDeleteServiceDeskAgreementEquipmentRequestSaga(),
    watchFetchServiceDeskAgreementEquipmentCountRequestSaga(),
    watchDeleteServiceDeskContractEquipmentRequestSaga(),
    watchFetchServiceDeskContractEquipmentCountRequestSaga(),
    watchFetchServiceDeskContractsCountRequestSaga(),
    watchFetchFilteringOrganizationsRequestSaga(),
    watchCreateServiceContractsRequest(),
    watchSetOrderResultTypeSaga(),
    watchFetchServiceCallOrderItemsSaga(),
    watchFetchServiceCallOrderItemsCountSaga(),
    watchFetchServiceCallSuborderItemsSaga(),
    watchFetchServiceCallSuborderItemsCountSaga(),
    watchSaveServiceCallOrderItemsListSaga(),
    watchClearServiceCallOrderItemsListSaga(),
    watchCopyOrderItemsFromChildSaga(),
    watchFetchOrderNdsSaga(),
    watchFetchOrderIncomeNdsSaga(),
    watchFetchOrderOverheadSaga(),
    watchUpdateOrderNdsSaga(),
    watchUpdateOrderIncomeNdsSaga(),
    watchUpdateOrderOverheadSaga(),
    watchUploadOrderWorkCompletionFileRequestSaga(),
    watchDeleteOrderWorkCompletionFileRequest(),
    watchAssignUsingContractSaga(),
    watchFetchServiceDeskContractAvailableEquipmentRequestSaga(),
    watchfetchServiceDeskContractAvailableEquipmentCountRequestSaga(),
    watchAddEquipmentToContractsRequest(),
    watchFetchEquipmentFilterDepartmentRequestSaga(),
    watchConfirmCertificateOfCompletionSaga(),

    // settings
    settingsSagas.watchGetEngineerSettingsSaga(),
    settingsSagas.watchGetCommonOrganizationSettingsSaga(),

    // statistics
    watchFetchGenerateSDWorkingReportRequestSaga(),
    watchDownloadSDWorkingReportRequestSaga(),

    // tags
    watchCreateTagSaga(),
    watchEditTagSaga(),
    watchGetTagsSaga(),
    watchDeleteTagSaga(),
    watchGetTagSaga(),

    // scripts
    watchCreateScriptSaga(),
    watchEditScriptSaga(),
    watchGetScriptsSaga(),
    watchDeleteScriptSaga(),
    watchGetScriptSaga(),
    watchGetScriptsHistorySaga(),
    watchGetSDCallAvailableScriptsSaga(),

    // email templates
    watchGetEmailTemplatesSaga(),
    watchGetEmailTemplateSaga(),
    watchCreateEmailTemplateSaga(),
    watchEditEmailTemplateSaga(),
    watchDeleteEmailTemplateSaga(),

    // buttons
    watchCreateButtonSaga(),
    watchEditButtonSaga(),
    watchGetButtonsSaga(),
    watchDeleteButtonSaga(),
    watchGetButtonSaga(),
    watchGetButtonsHistorySaga(),
    watchExecuteSDCallButtonSaga(),
    watchGetSDCallButtonsSaga(),

    watchGetRecurringCallsSaga(),
    watchGetRecurringCallsCountSaga(),

    // inner calls
    innerCalls.watchFetchInnerCallsSaga(),
    innerCalls.watchFetchInnerCallsCountAllSaga(),
    innerCalls.watchFetchInnerCallsCountCreatedSaga(),
    innerCalls.watchFetchInnerCallsCountClosedSaga(),
    innerCalls.watchFetchInnerCallsNumbersSaga(),
    innerCalls.watchFetchInnerCallsEngineersSaga(),
    innerCalls.watchFetchInnerCallsEquipmentsSaga(),
    innerCalls.watchFetchInnerCallSaga(),
    innerCalls.watchFetchInnerCallPhotosSaga(),
    innerCalls.watchFetchInnerCallPhotosCountSaga(),
    innerCalls.watchUploadInnerCallPhotoSaga(),
    innerCalls.watchDeleteInnerCallPhotoSaga(),
    innerCalls.watchFetchInnerCallAnalyticsSummarySaga(),
    innerCalls.watchFetchInnerCallAnalyticsSummaryAverageSaga(),
    innerCalls.watchFetchInnerCallAnalyticsChartCreatedSaga(),
    innerCalls.watchFetchInnerCallAnalyticsChartAverageSaga(),
    innerCalls.watchFetchInnerCallAnalyticsChartClosedByEngineerSaga(),
    innerCalls.watchFetchInnerCallEngineerCountSaga(),
    innerCalls.watchFetchInnerCallHistorySaga(),
    fork(innerCalls.root),

    // trusted organizations
    trustedOrganizations.watchFetchTrustsSaga(),
    fork(trustedOrganizations.root),
  ])
}
