import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

const styles = {
  helperTextRoot: {
    textAlign: 'right',
  },
}

const DescriptionField = ({
  classes,
  currentValue,
  onChange,
  idValue,
  label,
  error,
  fullWidth,
  onBlur,
  maxLengthValue,
}) => {
  const onChangeHandler = ({ target: { value } }) => {
    onChange({
      target: {
        id: idValue,
        value:
          currentValue.length < maxLengthValue || value.length < maxLengthValue
            ? value.slice(0, maxLengthValue)
            : currentValue,
      },
    })
  }

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <TextField
        multiline
        id={idValue}
        value={currentValue}
        onChange={onChangeHandler}
        onBlur={onBlur}
        label={label}
        FormHelperTextProps={{
          classes: {
            root: classes.helperTextRoot,
          },
        }}
        helperText={`${currentValue.length}/${maxLengthValue}`}
      />
    </FormControl>
  )
}

DescriptionField.defaultProps = {
  label: '',
  error: false,
  onBlur: () => {},
  fullWidth: false,
  maxLengthValue: 250,
}

DescriptionField.propTypes = {
  classes: PropTypes.object.isRequired,
  idValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxLengthValue: PropTypes.number,
}

export default withStyles(styles)(memo(DescriptionField))
