import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core/styles'

import i18n from '../../../i18n/i18n'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
  },
}))

function MyOrganizationSelector({ idValue, onChange, value }) {
  const classes = useStyles()
  const handleChange = event => {
    onChange(idValue, event.target.value)
  }
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RadioGroup
        aria-label="your-side"
        name="side"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          id="performer"
          value="performer"
          control={<Radio color="primary" />}
          label={i18n.t('trusted_organizations_web_atoir:iAmPerformer')}
        />
        <FormControlLabel
          id="client"
          value="client"
          control={<Radio color="primary" />}
          label={i18n.t('trusted_organizations_web_atoir:iAmClient')}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default MyOrganizationSelector
