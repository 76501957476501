import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { validateEmail } from '../../../helpers/inputDataValidators'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import LoginFormWrapper from '../LoginFormWrapper'
import FormComponent from '../../../widgets/FormComponent/index'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  formControl: {
    margin: theme.spacing(1),
  },
})

class RegisterEmailComponent extends Component {
  state = {
    email: '',
    errors: {},
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isRegisteringEmail !== this.props.isRegisteringEmail &&
      !this.props.isRegisteringEmail &&
      isEmpty(this.props.validationErrors)
    ) {
      this.props.history.push('/email_sent')
    }
  }

  componentWillUnmount() {
    this.props.clearValidationErrors()
  }

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  render() {
    const {
      classes,
      isRegisteringEmail,
      shouldResendLink,
      validationErrors,
    } = this.props
    const { errors } = this.state

    const registerEmailHelperText =
      this.state.email === '' && errors.email
        ? i18next.t('login_web_atoir:enterYourEmailAddress')
        : !isEmpty(errors.email)
        ? errors.email
        : !isEmpty(validationErrors.email)
        ? validationErrors.email[0]
        : ''
    const textField = (
      <TextField
        error={!isEmpty(errors.email) || !isEmpty(validationErrors.email)}
        required
        fullWidth
        id="email"
        type="email"
        label={i18next.t('login_web_atoir:email')}
        placeholder={i18next.t('login_web_atoir:enterEmail')}
        className={classes.textField}
        helperText={registerEmailHelperText}
        margin="normal"
        onChange={this.handleChange('email')}
        value={this.state.email}
      />
    )
    const submitText = shouldResendLink
      ? `${i18next.t('login_web_atoir:sendLinkAgain')}`
      : `${i18next.t('login_web_atoir:registerAccount')}`

    return (
      <LoginFormWrapper
        title={i18next.t('login_web_atoir:signUpTitle')}
        nav
        text={i18next.t('login_web_atoir:alreadyRegisteredText')}
        link="/"
        linkText={i18next.t('login_web_atoir:enter')}
      >
        <FormComponent
          titleForm={i18next.t('login_web_atoir:joinSystemTitle')}
          submitText={submitText}
          isLoading={isRegisteringEmail}
          handleSubmitAction={
            shouldResendLink ? this.getConfirmLinkAgain : this.handleSubmitForm
          }
        >
          <FormGroup>{textField}</FormGroup>
        </FormComponent>
      </LoginFormWrapper>
    )
  }

  handleSubmitForm = () => {
    const { registerEmail } = this
    registerEmail()
  }

  registerEmail = () => {
    const { email } = this.state
    const validEmail = validateEmail(email.trim())
    if (!validEmail) {
      this.setState({
        errors: {
          email: i18next.t('validation_web_atoir:incorrectEmailFormat'),
        },
      })
      return
    }
    this.setState({
      submitedEmail: email,
      errors: {
        email: null,
      },
    })

    const { registerEmail } = this.props
    registerEmail(email.toLowerCase().trim())
  }

  getConfirmLinkAgain = () => {
    const { submitedEmail: email } = this.state
    const { closeErrorMessage } = this.props
    closeErrorMessage()
    const validEmail = validateEmail(email.trim())
    if (!validEmail) {
      this.setState({
        errors: {
          email: i18next.t('validation_web_atoir:incorrectEmailFormat'),
        },
      })
      return
    }
    this.setState({
      errors: {
        email: null,
      },
    })

    const { getConfirmLinkAgain } = this.props
    getConfirmLinkAgain(email.toLowerCase().trim())
  }
}

RegisterEmailComponent.propTypes = {
  isRegisteringEmail: PropTypes.bool.isRequired,
  shouldResendLink: PropTypes.bool.isRequired,
  registerEmail: PropTypes.func.isRequired,
  getConfirmLinkAgain: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegisterEmailComponent)
