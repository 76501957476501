import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

// components
import EquipmentChoosingList from '../../../CreateRequest/ChoosingEquipment/widgets/EquipmentChoosingList'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import Heading from '../../../../../widgets/Lettering/Heading'

const styles = theme => ({
  outlinedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: theme.palette.blueSecondary.main,
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
    '&:disabled': {
      borderColor: theme.palette.apply.extraLight,
      color: theme.palette.apply.extraLight,
    },
  },
  containedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.dark,
      color: 'white',
    },
  },
})

class EditEquipmentInfoDialog extends Component {
  constructor(props) {
    super(props)
    const {
      equipment: { id: equipment },
      comment,
    } = props
    this.state = {
      data: {
        equipment,
        comment,
      },
      errors: {},
    }
  }

  onChange = field => value => {
    this.setState(state => ({
      data: {
        ...state.data,
        [field]: value,
      },
    }))
  }

  onSubmit = () => {
    const {
      props: {
        equipment: { id: equipment },
        comment,
      },
      state: { data },
    } = this
    if (
      !data.equipment ||
      (data.equipment === equipment && comment === data.comment)
    ) {
      this.setState({
        errors: {
          equipmentError: i18next.t(
            'service_desk_web_atoir:requestCreationNoEquipmentValidationError',
          ),
        },
      })
      return
    }
    if (!this.state.data.comment || this.state.data.comment === '') {
      this.setState({
        errors: {
          commentError: i18next.t(
            'service_desk_web_atoir:requestCreationNoCommentValidationError',
          ),
        },
      })
      return
    }
    this.props.onSubmit(this.state.data)
  }

  render() {
    const {
      state: { data, errors },
      props: { classes, organization, open, onClose },
      onSubmit,
      onChange,
    } = this
    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Heading>
          <Typography variant="h6" style={{ padding: 16 }}>
            {i18next.t('service_desk_web_atoir:editingEquipmentInfoTitle')}
          </Typography>
        </Heading>
        <Divider />
        <DialogContent>
          <EquipmentChoosingList
            checkedEquipment={data.equipment}
            onChangeEquipment={onChange('equipment')}
            organizationId={organization.id}
          />
          <Typography color="error" align="left">
            {errors.equipmentError}
          </Typography>
          <div style={{ padding: 12 }}>
            <TextField
              id="requestCreationCommentLabel"
              onChange={event => onChange('comment')(event.target.value)}
              variant="outlined"
              multiline
              value={data.comment}
              fullWidth
              label={i18next.t(
                'service_desk_web_atoir:requestCreationCommentLabel',
              )}
            />
            <Typography color="error" align="left">
              {errors.commentError}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={onClose} className={classes.outlinedButton}>
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton
            id="dialogSubmitButton"
            onClick={onSubmit}
            className={classes.containedButton}
          >
            {i18next.t('shared_web_atoir:save')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

EditEquipmentInfoDialog.defaultProps = {
  organization: PropTypes.object.isRequired,
  equipment: PropTypes.object.isRequired,
  comment: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(EditEquipmentInfoDialog)
