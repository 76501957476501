import React from 'react'
import RLink from 'react-router-dom/Link'

// internationalization

// styles
import '../styles/components/Register.css'

// icons

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import errorImg from '../styles/images/error.svg'
import i18next from '../i18n/i18n'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
})

const Home = props => <RLink to="/" {...props} />

const ErrorComponent = ({ error }) => (
  <div>
    <img src={errorImg} className="App-not-found-img" alt="Oops!" />
    <br />
    <Typography variant="h6">{error}</Typography>
    <br />
    <Button component={Home}>
      {i18next.t('shared_web_atoir:backToHomePage')}
    </Button>
  </div>
)

export default withStyles(styles)(ErrorComponent)
