import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import Sticky from 'react-stickynode'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton/IconButton'
import TuneIcon from '@material-ui/icons/Tune'
import Badge from '@material-ui/core/Badge'
import Collapse from '@material-ui/core/Collapse'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { lighten } from '@material-ui/core/styles/colorManipulator'
// components
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import TextButton from '../../../../../widgets/Buttons/TextButton'

import Loader from '../../../../Loader'

import EquipmentsList from '../../../Contracts/Widgets/EquipmentsList'
// helpers
import {
  inventoryLocationStringifyDefault,
  organizationLocationStringify,
} from '../../../../../helpers/parserHelper'

import FormDialog from '../../../../../widgets/FormDialog'
// i18n
import i18next from '../../../../../i18n/i18n'
import { canDistributeContract } from '../../../../../helpers/ContractPermissionsResolver'
import { URL } from '../../../../../middlewares/api'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import AutocompleteLimitedAuto from '../../../../../widgets/AutocompleteLimitedAuto'
import { getUserFullName } from '../../../../../helpers/userHelper'
import SelectLimitedFromControl from '../../../../../widgets/SelectLimitedFormControl'
import DelayedLoader from '../../../../../widgets/DelayedLoader'

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  gridHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  gridCollapsedFilters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  statusChips: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    transition: 'all .3s',
  },
  divCollapsedDivider: {
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: `${lighten(theme.palette.secondary.light, 0.85)}`,
  },
  paper: {
    backgroundColor: '#fafafa',
  },
  badgeColorRoot: {
    color: 'black',
  },
  badgeColorPrimary: {
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
  },
  textField: {
    width: '100%',
  },
  hideClass: {
    display: 'none',
  },
  divHeader: {
    backgroundColor: 'white',
    borderBottom: theme.typography.pxToRem(1),
    borderBottomStyle: 'solid',
    borderBottomColor: lighten(theme.palette.secondary.light, 0.85),
  },
  card: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  middleText: {
    marginBottom: 12,
    fontSize: 12,
  },
  topText: {
    marginBottom: 8,
    fontSize: 12,
  },
  verticalDivider: {
    width: 1,
    margin: '8px 24px 8px 8px',
    backgroundColor: theme.palette.divider,
  },
  activeChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: 0,
  },
  filledChipsContainer: {
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  statusChip: {
    minWidth: 120,
    marginRight: 18,
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

const statuses = ['notDistributed', 'distributed', 'all']

const extendData = data => {
  const resData = []
  if (data === null || data.length === 0) return []
  let currAddr = data[0].address
  resData.push({ typeRow: 'addressRow', currAddr, id: currAddr })
  data.forEach(item => {
    if (item.address !== currAddr) {
      currAddr = item.address
      resData.push({ typeRow: 'addressRow', currAddr, id: currAddr })
    }
    resData.push(item)
  })
  return resData
}

class DistributeEquipmentTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      checked: [],
      filters: {},
      filtersCount: 0,
      offset: 0,
      page: 0,
      engineerStatus: statuses[0],
      engineerIdFilterValue: null,
      confirmDialogOpen: false,
      engineer: null,
    }
  }

  componentDidMount() {
    const { loadEquipmentCount } = this
    loadEquipmentCount()
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        availableEquipmentIsFetching,
        availableEquipmentFetchingError,
        isDeletingEquipment,
        equipmentCountIsFetching,
        equipmentCountFetchingError,
        isAssigningEngineer,
        deleteError,
        assignEngineerError,
        isUnAssigningEngineer,
        unAssignEngineerError,
        limit,
      },
      prepareData,
      loadEquipmentData,
      loadFilterDepartmentDataNext,
      loadFilterLocationDataNext,
      loadEquipmentCount,
    } = this
    // contract data loaded
    // availableEquipmentCount loaded
    if (
      prevProps.equipmentCountIsFetching !== equipmentCountIsFetching &&
      !equipmentCountIsFetching &&
      !equipmentCountFetchingError
    ) {
      loadEquipmentData()
    }
    if (
      prevProps.isAssigningEngineer !== isAssigningEngineer &&
      !assignEngineerError
    ) {
      loadEquipmentData()
    }
    if (
      prevProps.isUnAssigningEngineer !== isUnAssigningEngineer &&
      !unAssignEngineerError
    ) {
      loadEquipmentData()
    }
    // availableEquipment loaded
    if (
      prevProps.availableEquipmentIsFetching !== availableEquipmentIsFetching &&
      !availableEquipmentIsFetching &&
      !availableEquipmentFetchingError
    ) {
      prepareData()
    }
    // equipments added to contract
    if (
      prevProps.isDeletingEquipment !== isDeletingEquipment &&
      !isDeletingEquipment &&
      !deleteError
    ) {
      loadEquipmentCount()
      loadFilterDepartmentDataNext()
      loadFilterLocationDataNext()
    }
    // rows per page changed
    if (prevProps.limit !== limit) {
      loadEquipmentCount()
    }
  }

  nextFilterOffset = filter => () => {
    const {
      props: { departmentFilterDataIsFetching, locationFilterDataIsFetching },
      state: { addressFilterDataIsEnd, departmentFilterDataIsEnd },
      loadFilterLocationDataNext,
      loadFilterDepartmentDataNext,
    } = this
    if (
      filter === 'address' &&
      !addressFilterDataIsEnd &&
      !locationFilterDataIsFetching
    ) {
      loadFilterLocationDataNext()
    }
    if (
      filter === 'department' &&
      !departmentFilterDataIsEnd &&
      !departmentFilterDataIsFetching
    ) {
      loadFilterDepartmentDataNext()
    }
  }

  loadEquipmentCount = () => {
    const {
      props: { fetchCount, contract },
      state: { filters, engineerStatus },
    } = this
    fetchCount({
      id: contract.id,
      ...filters,
      engineerStatus,
    })
  }

  loadEquipmentData = () => {
    const {
      props: { fetchContractEquipmentsRequest, contract, limit },
      state: { offset, filters, engineerStatus },
    } = this
    fetchContractEquipmentsRequest({
      id: contract.id,
      limit,
      offset,
      engineerStatus,
      ...filters,
    })
  }

  handleChange = () => {
    this.setState(state => ({ checkedSwitch: !state.checkedSwitch }))
  }

  prepareData = () => {
    const {
      props: { availableEquipment, contract },
    } = this

    let newData = availableEquipment.map(row => {
      const {
        name,
        model,
        OrganizationDepartment,
        OrganizationLocation,
        external_id: externalId,
        vendor,
        id,
        ServiceDeskContractEquipments,
      } = row
      const outRow = {
        id,
        name,
        model,
        externalId,
        vendor,
      }
      outRow.postIndex = OrganizationLocation.post_index
      outRow.address = inventoryLocationStringifyDefault(OrganizationLocation)
      if (OrganizationDepartment)
        outRow.department = OrganizationDepartment.name
      if (
        ServiceDeskContractEquipments.filter(
          s => s.service_desk_contract_id === contract.id,
        )[0]
      ) {
        outRow.engineer = ServiceDeskContractEquipments.filter(
          s => s.service_desk_contract_id === contract.id,
        )[0].engineer
      }
      return outRow
    })
    newData = extendData(newData)
    this.setState({ data: newData })
  }

  checkedChange = id => {
    const {
      state: { checked },
    } = this
    const pos = checked.indexOf(id)
    if (pos !== -1) {
      checked.splice(pos, 1)
    } else {
      checked.push(id)
    }
    this.setState(checked)
  }

  handleSelectAllClick = event => {
    const { availableEquipment } = this.props
    if (event.target.checked) {
      this.setState(() => ({ checked: availableEquipment.map(n => n.id) }))
      return
    }
    this.setState({ checked: [] })
  }

  filtersHandler = field => event => {
    const { value } = event.target
    if (value !== '') {
      this.setState({
        [`${field}FilterValue`]: value,
      })
    } else {
      this.setState({ [`${field}FilterValue`]: undefined })
    }
  }

  filtersLimitedHandler = field => value => {
    this.setState({ [`${field}FilterValue`]: value })
  }

  applyFilters = () => {
    const {
      departmentFilterValue,
      addressFilterValue,
      nameFilterValue,
      vendorFilterValue,
      modelFilterValue,
      extIdFilterValue,
      engineerIdFilterValue,
      engineerStatus,
    } = this.state

    const filters = {}
    if (departmentFilterValue) filters.department = departmentFilterValue
    if (addressFilterValue) filters.location = addressFilterValue
    if (nameFilterValue) filters.name = nameFilterValue
    if (vendorFilterValue) filters.vendor = vendorFilterValue
    if (modelFilterValue) filters.model = modelFilterValue
    if (extIdFilterValue) filters.externalId = extIdFilterValue
    if (engineerIdFilterValue && engineerStatus !== 'notDistributed')
      filters.engineer_id = engineerIdFilterValue

    const filtersCount = Object.keys(filters).length
    this.setState({ filtersCount, filters }, () => {
      this.loadEquipmentCount()
    })
  }

  paginationChange = field => (event, page) => {
    const {
      props: { limit, setContractsEquipmentRowsPerPage },
      loadEquipmentData,
    } = this
    const newData = {}
    if (field === 'page') {
      newData.offset = page * limit
      newData.page = page
      this.setState(
        {
          ...newData,
          checked: [],
        },
        () => {
          loadEquipmentData()
        },
      )
    }
    if (field === 'rowsPerPage') {
      setContractsEquipmentRowsPerPage(event.target.value)
    }
  }

  toggle = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  unAssignEquipment = () => {
    const {
      state: { checked },
      props: {
        match: { params },
        unAssignEngineerToEquipmentsRequest,
      },
    } = this
    unAssignEngineerToEquipmentsRequest({
      id: params.contractId,
      equipmentsIds: checked,
    })
    this.setState({ checked: [] })
  }

  assignEquipment = () => {
    const {
      state: { checked, engineer },
      props: {
        match: { params },
        assignEngineerToEquipmentsRequest,
      },
    } = this
    assignEngineerToEquipmentsRequest({
      id: params.contractId,
      equipmentsIds: checked,
      engineerId: engineer.id,
    })
    this.setState({ checked: [] })
  }

  onChangeStatus = status => () => {
    if (this.state.engineerStatus === status) {
      return
    }

    this.setState(
      state => ({
        engineerStatus: status,
        engineerIdFilterValue: null,
        offset: 0,
        page: 0,
        checked: [],
        filters: {
          ..._.omit(state.filters, 'engineer_id'),
        },
        filtersCount: Object.keys({
          ..._.omit(state.filters, 'engineer_id'),
        }).length,
      }),
      () => {
        this.loadEquipmentCount()
        this.loadEquipmentData()
      },
    )
  }

  onEngineerChanged = id => {
    this.setState({
      engineer: id,
    })
  }

  onStickyStateChange = ({ status }) => {
    this.setState({
      stickyActive: status === 2,
    })
  }

  render() {
    const {
      props: {
        classes,
        availableEquipment,
        equipmentCount,
        availableEquipmentIsFetching,
        contract,
        permissions,
        limit,
      },
      state: {
        data,
        checked,
        checkedSwitch,
        filtersCount,
        page,
        addressFilterValue,
        departmentFilterValue,
        confirmDialogOpen,
        engineerStatus,
        engineer,
        engineerIdFilterValue,
        stickyActive,
      },
      onStickyStateChange,
      assignEquipment,
      unAssignEquipment,
      onEngineerChanged,
      filtersHandler,
      checkedChange,
      handleSelectAllClick,
      applyFilters,
      paginationChange,
      filtersLimitedHandler,
      deleteFromContract,
      toggle,
      onChangeStatus,
    } = this

    return !availableEquipment ? (
      <DelayedLoader delay={200} show />
    ) : (
      <Fragment>
        <div>
          <FormDialog
            onClose={toggle('confirmDialogOpen', false)}
            closeIconHidden
            invert
            secondaryButtonsArray={[
              {
                secondaryText: i18next.t('shared_web_atoir:delete'),
                handleSecondaryAction: () => {
                  deleteFromContract()
                  this.toggle('confirmDialogOpen', false)()
                },
              },
            ]}
            onSubmit={toggle('confirmDialogOpen', false)}
            submitButtonTitle={i18next.t('shared_web_atoir:cancel')}
            open={confirmDialogOpen}
            contentText={i18next.t(
              'service_desk_web_atoir:deleteEquipmentDialogContentRationale',
            )}
          />
          <Sticky
            enabled
            top={0}
            innerZ="1"
            onStateChange={onStickyStateChange}
          >
            <div
              className={classNames([
                classes.statusChips,
                stickyActive && classes.filledChipsContainer,
              ])}
            >
              {statuses.map(s => (
                <Chip
                  onClick={onChangeStatus(s)}
                  clickable={false}
                  className={classNames([
                    classes.statusChip,
                    engineerStatus === s ? classes.activeChip : null,
                  ])}
                  label={`${i18next.t(`service_desk_web_atoir:${s}Status`)}`}
                  variant="outlined"
                />
              ))}
            </div>
            <div className={classes.divHeader}>
              <Grid container className={classes.gridHeader}>
                <Grid item xs={12} sm={5}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {canDistributeContract(permissions, contract.status) && (
                      <Checkbox
                        indeterminate={
                          checked.length > 0 &&
                          checked.length < availableEquipment.length
                        }
                        checked={checked.length === availableEquipment.length}
                        onChange={handleSelectAllClick}
                      />
                    )}
                    <Typography variant="subheading">
                      {checked.length > 0 &&
                      canDistributeContract(permissions, contract.status)
                        ? `${checked.length} ${i18next.t(
                            'service_desk_web_atoir:selectedCaption',
                          )}`
                        : i18next.t('service_desk_web_atoir:equipment')}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  sm={7}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <IconButton onClick={this.handleChange}>
                    <Badge
                      classes={{
                        root: classes.badgeColorRoot,
                        colorPrimary: classNames(classes.badgeColorPrimary, {
                          [classes.hideClass]: filtersCount === 0,
                        }),
                      }}
                      color="primary"
                      badgeContent={filtersCount}
                    >
                      <TuneIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
              {checked.length > 0 && <Divider />}
              <Grid container className={classes.gridHeader}>
                <Grid xs={12} justify="flex-end">
                  {checked.length > 0 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {engineerStatus === 'distributed' && checked.length > 0 && (
                        <Fragment>
                          <TextButton onClick={unAssignEquipment}>
                            {i18next.t(
                              'service_desk_web_atoir:toUnDistributed',
                            )}
                          </TextButton>
                          <div className={classes.verticalDivider} />
                        </Fragment>
                      )}
                      <AutocompleteLimitedAuto
                        onChange={onEngineerChanged}
                        requestResultTransformerToArray={r => r.users}
                        transformInputToQuery={credentials => ({ credentials })}
                        getItemDisplayName={i => getUserFullName(i)}
                        sourceUrl={`${URL}/organizations/${contract.perf_organization.id}/users`}
                        getItemValue={i => i.id}
                        placeholder={i18next.t(
                          'service_desk_web_atoir:chooseEngineer',
                        )}
                      />
                      <TextButton
                        disabled={!engineer}
                        onClick={assignEquipment}
                      >
                        {i18next.t('shared_web_atoir:assign')}
                      </TextButton>
                    </div>
                  )}
                </Grid>
              </Grid>
              {checked.length > 0 && !checkedSwitch && <Divider />}
              <Collapse in={checkedSwitch}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.divCollapsedDivider} />
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    className={classes.gridCollapsedFilters}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="name"
                        onChange={filtersHandler('name')}
                        className={classes.textField}
                        label={i18next.t('service_desk_web_atoir:captionName')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="vendor"
                        onChange={filtersHandler('vendor')}
                        className={classes.textField}
                        label={i18next.t(
                          'service_desk_web_atoir:captionVendor',
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="model"
                        onChange={filtersHandler('model')}
                        className={classes.textField}
                        label={i18next.t('service_desk_web_atoir:captionModel')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="extId"
                        onChange={filtersHandler('extId')}
                        className={classes.textField}
                        label={i18next.t('service_desk_web_atoir:captionExtId')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <SelectLimitedFromControl
                        firstElement={{ id: null, title: '' }}
                        FormControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: i18next.t(
                            'service_desk_web_atoir:captionDepartment',
                          ),
                        }}
                        label={i18next.t(
                          'service_desk_web_atoir:captionDepartment',
                        )}
                        onChange={filtersLimitedHandler('department')}
                        sourceUrl={`${URL}/service_desk_contracts/${contract.id}/filters/departments`}
                        requestResultTransformerToArray={r => r.departments}
                        value={departmentFilterValue}
                        itemTransformer={u => ({ id: u.id, title: u.name })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <SelectLimitedFromControl
                        firstElement={{ id: null, title: '' }}
                        FormControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: i18next.t(
                            'service_desk_web_atoir:captionAddress',
                          ),
                        }}
                        label={i18next.t(
                          'service_desk_web_atoir:captionAddress',
                        )}
                        onChange={filtersLimitedHandler('address')}
                        sourceUrl={`${URL}/service_desk_contracts/${contract.id}/filters/locations`}
                        requestResultTransformerToArray={r => r.locations}
                        value={addressFilterValue}
                        itemTransformer={u => ({
                          id: u.id,
                          title: organizationLocationStringify(u),
                        })}
                      />
                    </Grid>
                    {engineerStatus !== 'notDistributed' && (
                      <Grid item xs={12} sm={6} md={4}>
                        <SelectLimitedFromControl
                          firstElement={{ id: null, title: '' }}
                          FormControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: i18next.t(
                              'service_desk_web_atoir:engineer',
                            ),
                          }}
                          label={i18next.t('service_desk_web_atoir:engineer')}
                          onChange={filtersLimitedHandler('engineerId')}
                          sourceUrl={`${URL}/service_desk_contracts/${contract.id}/engineers`}
                          requestResultTransformerToArray={r => r.users}
                          value={engineerIdFilterValue}
                          itemTransformer={u => ({
                            id: u.id,
                            title: getUserFullName(u),
                          })}
                        />
                      </Grid>
                    )}
                    <Grid container justify="flex-end" xs={12}>
                      <OutlinedButton onClick={applyFilters}>
                        {i18next.t('service_desk_web_atoir:battonApply')}
                      </OutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
              <TablePagination
                rowsPerPageOptions={[50, 150, 300, 500]}
                component="div"
                count={equipmentCount}
                rowsPerPage={limit}
                page={page}
                labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
                labelDisplayedRows={({
                  from: fromPage,
                  to: toPage,
                  count: countPage,
                }) =>
                  i18next.t('shared_web_atoir:labelDisplayedRows', {
                    fromPage,
                    toPage,
                    countPage,
                  })
                }
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={paginationChange('page')}
                onChangeRowsPerPage={paginationChange('rowsPerPage')}
              />
            </div>
          </Sticky>
          <div>
            <Grid container>
              <Grid item xs={12}>
                <Table className={classes.table}>
                  <TableBody>
                    {availableEquipmentIsFetching ? (
                      <Loader />
                    ) : (
                      <EquipmentsList
                        contractStatus={contract.status}
                        editable={canDistributeContract(
                          permissions,
                          contract.status,
                        )}
                        data={data}
                        checked={checked}
                        change={checkedChange}
                      />
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fragment>
    )
  }
}

DistributeEquipmentTable.propTypes = {
  contract: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
}

export default withStyles(styles)(DistributeEquipmentTable)
