import React, { Component } from 'react'
import PropTypes from 'prop-types'

// material
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

// modules
import Heading from '../../../../../widgets/Lettering/Heading'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import { URL } from '../../../../../middlewares/api'
import SelectLimitedFormControl from '../../../../../widgets/SelectLimitedFormControl'
import FormControlText from '../../../../../widgets/Controlls/FormControlText'

// helpers
import { addMaskCharacters } from '../../../../../helpers/phoneStringHelper'
import {
  getUserFullName,
  stringifyEmployeePosition,
} from '../../../../../helpers/userHelper'

// i18
import i18next from '../../../../../i18n/i18n'

class EditResponsible extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.responsibleId,
      user: null,
    }
  }

  onChange = (id, user) => {
    this.setState({ id, user })
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.id)
  }

  render() {
    const {
      props: { onClose, organizationId },
      state: { id, user },
      onSubmit,
    } = this
    return (
      <Dialog
        open
        onSubmit={onSubmit}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Heading>
          <Typography variant="h6" style={{ padding: 16 }}>
            {i18next.t('service_desk_web_atoir:editingPerformerInfoTitle')}
          </Typography>
        </Heading>
        <Divider />
        <DialogContent>
          <SelectLimitedFormControl
            label={i18next.t(
              'service_desk_web_atoir:contractInfoResponsibleTitle',
            )}
            value={id}
            FormControlProps={{ fullWidth: true }}
            query={{ sort: '[last_name=ASC,first_name=ASC,middle_name=ASC]' }}
            sourceUrl={`${URL}/organizations/${organizationId}/users`}
            onChange={this.onChange}
            requestResultTransformerToArray={r => r.users}
            itemTransformer={l => ({ id: l.id, title: getUserFullName(l) })}
          />
          <FormControlText
            disabled
            label={i18next.t('service_desk_web_atoir:performerInfoFiledPhone')}
            value={user && user.phone ? addMaskCharacters(user.phone) : ''}
          />
          <FormControlText
            disabled
            label={i18next.t(
              'service_desk_web_atoir:performerInfoFiledPosition',
            )}
            value={
              user && user.position
                ? stringifyEmployeePosition(user.position)
                : ''
            }
          />
        </DialogContent>
        <DialogActions style={{ marginRight: 16 }}>
          <OutlinedButton onClick={onClose} size="medium">
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton onClick={onSubmit} size="medium">
            {i18next.t('shared_web_atoir:save')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

EditResponsible.defaultProps = {
  responsibleId: null,
}

EditResponsible.propTypes = {
  responsibleId: PropTypes.string,
  organizationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default EditResponsible
