/* eslint-disable */
import * as constants from '../constants/actionTypes/serviceDesk'
import {actionCreator as externalActionCreator} from './actionCreator'


const actionCreator = type => payload => ({
  type,
  payload,
})

export const makeRecurringFromCallRequest = actionCreator(constants.MAKE_RECURRING_FROM_CALL_REQUEST)
export const makeRecurringFromCallSuccess = actionCreator(constants.MAKE_RECURRING_FROM_CALL_SUCCESS)
export const makeRecurringFromCallFailure = actionCreator(constants.MAKE_RECURRING_FROM_CALL_FAILURE)

export const editRecurringCallRequest = actionCreator(constants.EDIT_RECURRING_CALL_REQUEST)
export const editRecurringCallSuccess = actionCreator(constants.EDIT_RECURRING_CALL_SUCCESS)
export const editRecurringCallFailure = actionCreator(constants.EDIT_RECURRING_CALL_FAILURE)

export const deleteRecurringCallRequest = actionCreator(constants.DELETE_RECURRING_CALL_REQUEST)
export const deleteRecurringCallSuccess = actionCreator(constants.DELETE_RECURRING_CALL_SUCCESS)
export const deleteRecurringCallFailure = actionCreator(constants.DELETE_RECURRING_CALL_FAILURE)

export const setNeedParticipationFilter = actionCreator(constants.SET_SC_SIDE_NEED_PARTICIPATION_FILTER)
export const setTypeOrderFilter = actionCreator(constants.SET_SCO_SIDE_TYPE_ORDER)
export const setResultOrderFilter = actionCreator(constants.SET_SCO_SIDE_RESULT_ORDER)
export const setRowsPerPage = actionCreator(constants.SET_SC_LIST_REQUEST_ROWS_PER_PAGE)
export const setInitialListFiltersForStatus = actionCreator(constants.SET_INITIAL_LIST_FILTERS_FOR_STATUS)

export const fetchServiceCallsListRequest = externalActionCreator(constants.FETCH_SERVICE_CALLS_LIST_REQUEST)
export const fetchServiceCallsListSuccess = externalActionCreator(constants.FETCH_SERVICE_CALLS_LIST_SUCCESS)
export const fetchServiceCallsListFailure = externalActionCreator(constants.FETCH_SERVICE_CALLS_LIST_FAILURE)

export const fetchServiceCallsListCountRequest = externalActionCreator(constants.FETCH_SERVICE_CALLS_LIST_COUNT_REQUEST)
export const fetchServiceCallsListCountSuccess = externalActionCreator(constants.FETCH_SERVICE_CALLS_LIST_COUNT_SUCCESS)
export const fetchServiceCallsListCountFailure = externalActionCreator(constants.FETCH_SERVICE_CALLS_LIST_COUNT_FAILURE)

export const fetchServiceCallOrdersListRequest =
  externalActionCreator(constants.FETCH_SERVICE_CALL_ORDERS_LIST_REQUEST)
export const fetchServiceCallOrdersListSuccess =
  externalActionCreator(constants.FETCH_SERVICE_CALL_ORDERS_LIST_SUCCESS)
export const fetchServiceCallOrdersListFailure =
  externalActionCreator(constants.FETCH_SERVICE_CALL_ORDERS_LIST_FAILURE)

export const fetchServiceCallOrdersListCountRequest =
  externalActionCreator(constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_REQUEST)
export const fetchServiceCallOrdersListCountSuccess =
  externalActionCreator(constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_SUCCESS)
export const fetchServiceCallOrdersListCountFailure =
  externalActionCreator(constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_FAILURE)

export const setRequestDataOnCreation = actionCreator(constants.SET_DATA)
export const clearRequestDataOnCreation = actionCreator(constants.CLEAR_DATA)

export const createServiceDeskRequest = actionCreator(constants.CREATE_REQUEST_REQUEST)

export const fetchCallPermissions = actionCreator(constants.FETCH_CALL_PERMISSIONS_REQUEST)

export const fetchCallPermissionsSuccess = actionCreator(constants.FETCH_CALL_PERMISSIONS_SUCCESS)

export const fetchCallPermissionsFailure = actionCreator(constants.FETCH_CALL_PERMISSIONS_FAILURE)

export const clearCallHistory = actionCreator(constants.CLEAR_CALL_HISTORY)

export const fetchCallHistory = actionCreator(constants.FETCH_CALL_HISTORY_REQUEST)
export const fetchCallHistorySuccess = actionCreator(constants.FETCH_CALL_HISTORY_SUCCESS)
export const fetchCallHistoryFailure = actionCreator(constants.FETCH_CALL_HISTORY_FAILURE)

export const fetchCallHistoryCount = actionCreator(constants.FETCH_CALL_HISTORY_COUNT.REQUEST)
export const fetchCallHistoryCountSuccess = actionCreator(constants.FETCH_CALL_HISTORY_COUNT.SUCCESS)
export const fetchCallHistoryCountFailure = actionCreator(constants.FETCH_CALL_HISTORY_COUNT.FAILURE)


export const assignServiceDeskRequestToThirdPartyOrganization = actionCreator(constants.ASSIGN_TO_THIRD_PARTY_REQUEST_REQUEST)

export const assignServiceDeskRequestToThirdPartyOrganizationSuccess = actionCreator(constants.ASSIGN_TO_THIRD_PARTY_REQUEST_SUCCESS)

export const assignServiceDeskRequestToThirdPartyOrganizationFailure = actionCreator(constants.ASSIGN_TO_THIRD_PARTY_REQUEST_FAILURE)

export const sendingToSubOrder = actionCreator(constants.SENDING_TO_SUB_ORDER_REQUEST)

export const sendingToSubOrderSuccess = actionCreator(constants.SENDING_TO_SUB_ORDER_SUCCESS)

export const sendingToSubOrderFailure = actionCreator(constants.SENDING_TO_SUB_ORDER_FAILURE)

export const assignServiceDeskRequestToOwnSpecialist = actionCreator(constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_REQUEST)

export const assignServiceDeskRequestToOwnSpecialistSuccess = actionCreator(constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_SUCCESS)

export const assignServiceDeskRequestToOwnSpecialistFailure = actionCreator(constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_FAILURE)

export const fetchServiceDeskCall = actionCreator(constants.FETCH_CALL_REQUEST)

export const createServiceDeskRequestSuccess = actionCreator(constants.CREATE_REQUEST_SUCCESS)

export const createServiceDeskRequestFailure = actionCreator(constants.CREATE_REQUEST_FAILURE)

export const fetchServiceDeskCallSuccess = actionCreator(constants.FETCH_CALL_SUCCESS)

export const fetchServiceDeskCallFailure = actionCreator(constants.FETCH_CALL_FAILURE)

export const updateServiceDeskCall = actionCreator(constants.UPDATE_CALL_REQUEST)

export const updateServiceDeskCallSuccess = actionCreator(constants.UPDATE_CALL_SUCCESS)

export const updateServiceDeskCallFailure = actionCreator(constants.UPDATE_CALL_FAILURE)

export const uploadDocServiceDeskCall = actionCreator(constants.UPLOAD_FILE_REQUEST)

export const uploadDocServiceDeskCallSuccess = actionCreator(constants.UPLOAD_FILE_SUCCESS)

export const uploadDocServiceDeskCallFailure = actionCreator(constants.UPLOAD_FILE_FAILURE)

export const deleteDocServiceDeskCall = actionCreator(constants.DELETE_FILE_REQUEST)

export const deleteDocServiceDeskCallSuccess = actionCreator(constants.DELETE_FILE_SUCCESS)

export const deleteDocServiceDeskCallFailure = actionCreator(constants.DELETE_FILE_FAILURE)

export const deletePhotoServiceDeskCall = actionCreator(constants.DELETE_PHOTO_REQUEST)

export const deletePhotoServiceDeskCallSuccess = actionCreator(constants.DELETE_PHOTO_SUCCESS)

export const deletePhotoServiceDeskCallFailure = actionCreator(constants.DELETE_PHOTO_FAILURE)

export const uploadPhotoServiceDeskCall = actionCreator(constants.UPLOAD_PHOTO_REQUEST)

export const uploadPhotoServiceDeskCallSuccess = actionCreator(constants.UPLOAD_PHOTO_SUCCESS)

export const uploadPhotoServiceDeskCallFailure = actionCreator(constants.UPLOAD_PHOTO_FAILURE)

export const fetchServiceDeskCallDocs = actionCreator(constants.FETCH_FILES_REQUEST)

export const fetchServiceDeskCallDocsSuccess = actionCreator(constants.FETCH_FILES_SUCCESS)

export const fetchServiceDeskCallDocsFailure = actionCreator(constants.FETCH_FILES_FAILURE)

export const fetchServiceDeskCallPhotos = actionCreator(constants.FETCH_PHOTOS_REQUEST)

export const fetchServiceDeskCallPhotosSuccess = actionCreator(constants.FETCH_PHOTOS_SUCCESS)

export const fetchServiceDeskCallPhotosFailure = actionCreator(constants.FETCH_PHOTOS_FAILURE)

export const downloadServiceDeskCallDocs = actionCreator(constants.DOWNLOAD_DOCUMENT_REQUEST)

export const downloadServiceDeskCallPhotos = actionCreator(constants.DOWNLOAD_PHOTO_REQUEST)

export const fetchServiceDeskCallOrders = actionCreator(constants.FETCH_ORDERS_REQUEST)

export const fetchServiceDeskCallOrdersSuccess = actionCreator(constants.FETCH_ORDERS_SUCCESS)

export const fetchServiceDeskCallOrdersFailure = actionCreator(constants.FETCH_ORDERS_FAILURE)


export const fetchOrder = actionCreator(constants.FETCH_ORDER_REQUEST)
export const fetchOrderSuccess = actionCreator(constants.FETCH_ORDER_SUCCESS)
export const fetchOrderFailure = actionCreator(constants.FETCH_ORDER_FAILURE)

export const cancelServiceDeskRequest = actionCreator(constants.CANCEL_REQUEST_REQUEST)

export const cancelServiceDeskRequestSuccess = actionCreator(constants.CANCEL_REQUEST_SUCCESS)

export const cancelServiceDeskRequestFailure = actionCreator(constants.CANCEL_REQUEST_FAILURE)

export const rejectOrderRequest = actionCreator(constants.REJECT_ORDER_REQUEST)
export const rejectOrderRequestSuccess = actionCreator(constants.REJECT_ORDER_SUCCESS)
export const rejectOrderRequestFailure = actionCreator(constants.REJECT_ORDER_FAILURE)

export const assignServiceDeskOrderToOwnSpecialist = actionCreator(constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_REQUEST)
export const assignServiceDeskOrderToOwnSpecialistSuccess = actionCreator(constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_SUCCESS)
export const assignServiceDeskOrderToOwnSpecialistFailure = actionCreator(constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_FAILURE)

export const sentOrderToSubOrder = actionCreator(constants.SEND_ORDER_TO_SUB_ORDER_REQUEST)
export const sentOrderToSubOrderSuccess = actionCreator(constants.SEND_ORDER_TO_SUB_ORDER_SUCCESS)
export const sentOrderToSubOrderFailure = actionCreator(constants.SEND_ORDER_TO_SUB_ORDER_FAILURE)

export const changePerformerResponsibleOrder = actionCreator(constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_REQUEST)
export const changePerformerResponsibleOrderSuccess = actionCreator(constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_SUCCESS)
export const changePerformerResponsibleOrderFailure = actionCreator(constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_FAILURE)

export const changeClientResponsibleOrder = actionCreator(constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_REQUEST)
export const changeClientResponsibleOrderSuccess = actionCreator(constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_SUCCESS)
export const changeClientResponsibleOrderFailure = actionCreator(constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_FAILURE)

export const changeEngineerOrder = actionCreator(constants.CHANGE_ENGINEER_ORDER_REQUEST)
export const changeEngineerOrderSuccess = actionCreator(constants.CHANGE_ENGINEER_ORDER_SUCCESS)
export const changeEngineerOrderFailure = actionCreator(constants.CHANGE_ENGINEER_ORDER_FAILURE)

export const editOrderInfo = actionCreator(constants.EDIT_ORDER_INFO_REQUEST)
export const editOrderInfoSuccess = actionCreator(constants.EDIT_ORDER_INFO_SUCCESS)
export const editOrderInfoFailure = actionCreator(constants.EDIT_ORDER_INFO_FAILURE)

export const fetchOrdersLogRequest = actionCreator(constants.FETCH_ORDERS_LOG_REQUEST)
export const fetchOrdersLogRequestSuccess = actionCreator(constants.FETCH_ORDERS_LOG_SUCCESS)
export const fetchOrdersLogRequestFailure = actionCreator(constants.FETCH_ORDERS_LOG_FAILURE)

export const fetchOrdersLogCountRequest = actionCreator(constants.FETCH_ORDERS_LOG_COUNT_REQUEST)
export const fetchOrdersLogCountRequestSuccess = actionCreator(constants.FETCH_ORDERS_LOG_COUNT_SUCCESS)
export const fetchOrdersLogCountRequestFailure = actionCreator(constants.FETCH_ORDERS_LOG_COUNT_FAILURE)

export const fetchOrderCommentsRequest = actionCreator(constants.FETCH_ORDER_COMMENTS_REQUEST)
export const fetchOrderCommentsRequestSuccess = actionCreator(constants.FETCH_ORDER_COMMENTS_SUCCESS)
export const fetchOrderCommentsRequestFailure = actionCreator(constants.FETCH_ORDER_COMMENTS_FAILURE)

export const uploadServiceDeskOrderCommentRequest = actionCreator(constants.UPLOAD_ORDER_COMMENT_REQUEST)
export const uploadServiceDeskOrderCommentRequestSuccess = actionCreator(constants.UPLOAD_ORDER_COMMENT_SUCCESS)
export const uploadServiceDeskOrderCommentRequestFailure = actionCreator(constants.UPLOAD_ORDER_COMMENT_FAILURE)

export const uploadServiceDeskOrderSubcommentRequest = actionCreator(constants.UPLOAD_ORDER_SUBCOMMENT_REQUEST)
export const uploadServiceDeskOrderSubcommentRequestSuccess = actionCreator(constants.UPLOAD_ORDER_SUBCOMMENT_SUCCESS)
export const uploadServiceDeskOrderSubcommentRequestFailure = actionCreator(constants.UPLOAD_ORDER_SUBCOMMENT_FAILURE)

export const fetchOrderCommentsCountRequest = actionCreator(constants.FETCH_ORDER_COMMENTS_COUNT_REQUEST)
export const fetchOrderCommentsCountRequestSuccess = actionCreator(constants.FETCH_ORDER_COMMENTS_COUNT_SUCCESS)
export const fetchOrderCommentsCountRequestFailure = actionCreator(constants.FETCH_ORDER_COMMENTS_COUNT_FAILURE)

export const cancelOrderByClientRequest = actionCreator(constants.CANCEL_ORDER_BY_CLIENT_REQUEST)
export const cancelOrderByClientRequestSuccess = actionCreator(constants.CANCEL_ORDER_BY_CLIENT_SUCCESS)
export const cancelOrderByClientRequestFailure = actionCreator(constants.CANCEL_ORDER_BY_CLIENT_FAILURE)

export const createServiceContractsRequest = actionCreator(constants.CREATE_SERVICE_CONTRACT_REQUEST)
export const createServiceContractsRequestSuccess = actionCreator(constants.CREATE_SERVICE_CONTRACT_SUCCESS)
export const createServiceContractsRequestFailure = actionCreator(constants.CREATE_SERVICE_CONTRACT_FAILURE)

export const setOrderResultTypeRequest = actionCreator(constants.SET_ORDER_RESULT_TYPE_REQUEST)
export const setOrderResultTypeRequestSuccess = actionCreator(constants.SET_ORDER_RESULT_TYPE_SUCCESS)
export const setOrderResultTypeRequestFailure = actionCreator(constants.SET_ORDER_RESULT_TYPE_FAILURE)

export const fetchServiceCallOrderItemsRequest = actionCreator(constants.FETCH_SERVICE_CALL_ORDER_ITEMS_REQUEST)
export const fetchServiceCallOrderItemsRequestSuccess = actionCreator(constants.FETCH_SERVICE_CALL_ORDER_ITEMS_SUCCESS)
export const fetchServiceCallOrderItemsRequestFailure = actionCreator(constants.FETCH_SERVICE_CALL_ORDER_ITEMS_FAILURE)

export const fetchServiceCallOrderItemsCountRequest = actionCreator(constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_REQUEST)
export const fetchServiceCallOrderItemsCountRequestSuccess = actionCreator(constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_SUCCESS)
export const fetchServiceCallOrderItemsCountRequestFailure = actionCreator(constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_FAILURE)

export const fetchServiceCallSuborderItemsRequest = actionCreator(constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_REQUEST)
export const fetchServiceCallSuborderItemsRequestSuccess = actionCreator(constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_SUCCESS)
export const fetchServiceCallSuborderItemsRequestFailure = actionCreator(constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_FAILURE)

export const fetchServiceCallSuborderItemsCountRequest = actionCreator(constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_REQUEST)
export const fetchServiceCallSuborderItemsCountRequestSuccess = actionCreator(constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_SUCCESS)
export const fetchServiceCallSuborderItemsCountRequestFailure = actionCreator(constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_FAILURE)

export const saveServiceCallOrderItemsListRequest = actionCreator(constants.SAVE_ORDER_ITEMS_LIST_REQUEST)
export const saveServiceCallOrderItemsListRequestSuccess = actionCreator(constants.SAVE_ORDER_ITEMS_LIST_SUCCESS)
export const saveServiceCallOrderItemsListRequestFailure = actionCreator(constants.SAVE_ORDER_ITEMS_LIST_FAILURE)

export const clearServiceCallOrderItemsListRequest = actionCreator(constants.CLEAR_ORDER_ITEMS_LIST_REQUEST)
export const clearServiceCallOrderItemsListRequestSuccess = actionCreator(constants.CLEAR_ORDER_ITEMS_LIST_SUCCESS)
export const clearServiceCallOrderItemsListRequestFailure = actionCreator(constants.CLEAR_ORDER_ITEMS_LIST_FAILURE)

export const copyOrderItemsFromChildRequest = actionCreator(constants.COPY_ORDER_ITEMS_FROM_CHILD_REQUEST)
export const copyOrderItemsFromChildRequestSuccess = actionCreator(constants.COPY_ORDER_ITEMS_FROM_CHILD_SUCCESS)
export const copyOrderItemsFromChildRequestFailure = actionCreator(constants.COPY_ORDER_ITEMS_FROM_CHILD_FAILURE)

export const fetchOrderNdsRequest = actionCreator(constants.FETCH_ORDER_NDS_REQUEST)
export const fetchOrderNdsSuccess = actionCreator(constants.FETCH_ORDER_NDS_SUCCESS)
export const fetchOrderNdsFailure = actionCreator(constants.FETCH_ORDER_NDS_FAILURE)

export const fetchOrderIncomeNdsRequest = actionCreator(constants.FETCH_ORDER_INCOME_NDS_REQUEST)
export const fetchOrderIncomeNdsSuccess = actionCreator(constants.FETCH_ORDER_INCOME_NDS_SUCCESS)
export const fetchOrderIncomeNdsFailure = actionCreator(constants.FETCH_ORDER_INCOME_NDS_FAILURE)

export const fetchOrderOverheadRequest = actionCreator(constants.FETCH_ORDER_OVERHEAD_REQUEST)
export const fetchOrderOverheadSuccess = actionCreator(constants.FETCH_ORDER_OVERHEAD_SUCCESS)
export const fetchOrderOverheadFailure = actionCreator(constants.FETCH_ORDER_OVERHEAD_FAILURE)

export const updateOrderNdsRequest = actionCreator(constants.UPDATE_ORDER_NDS_REQUEST)
export const updateOrderNdsSuccess = actionCreator(constants.UPDATE_ORDER_NDS_SUCCESS)
export const updateOrderNdsFailure = actionCreator(constants.UPDATE_ORDER_NDS_FAILURE)

export const updateOrderOverheadRequest = actionCreator(constants.UPDATE_OVERHEAD_REQUEST)
export const updateOrderOverheadSuccess = actionCreator(constants.UPDATE_OVERHEAD_SUCCESS)
export const updateOrderOverheadFailure = actionCreator(constants.UPDATE_OVERHEAD_FAILURE)

export const updateOrderIncomeNdsRequest = actionCreator(constants.UPDATE_ORDER_INCOME_NDS_REQUEST)
export const updateOrderIncomeNdsSuccess = actionCreator(constants.UPDATE_ORDER_INCOME_NDS_SUCCESS)
export const updateOrderIncomeNdsFailure = actionCreator(constants.UPDATE_ORDER_INCOME_NDS_FAILURE)

export const uploadOrderWorkCompletionFileRequest = actionCreator(constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_REQUEST)
export const uploadOrderWorkCompletionFileRequestSuccess = actionCreator(constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_SUCCESS)
export const uploadOrderWorkCompletionFileRequestFailure = actionCreator(constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_FAILURE)

export const deleteOrderWorkCompletionFileRequest = actionCreator(constants.DELETE_ORDER_WORK_COMPLETION_FILE_REQUEST)
export const deleteOrderWorkCompletionFileRequestSuccess = actionCreator(constants.DELETE_ORDER_WORK_COMPLETION_FILE_SUCCESS)
export const deleteOrderWorkCompletionFileRequestFailure = actionCreator(constants.DELETE_ORDER_WORK_COMPLETION_FILE_FAILURE)

export const confirmCertificateOfCompletionRequest = actionCreator(constants.CONFIRM_CERTIFICATE_OF_COMPLETION_REQUEST)
export const confirmCertificateOfCompletionSuccess = actionCreator(constants.CONFIRM_CERTIFICATE_OF_COMPLETION_SUCCESS)
export const confirmCertificateOfCompletionFailure = actionCreator(constants.CONFIRM_CERTIFICATE_OF_COMPLETION_FAILURE)

export const fetchActiveServiceCallOrdersRequest = actionCreator(constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_REQUEST)
export const fetchActiveServiceCallOrdersSuccess = actionCreator(constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_SUCCESS)
export const fetchActiveServiceCallOrdersFailure = actionCreator(constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_FAILURE)

export const fetchClosedServiceCallOrdersRequest = actionCreator(constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_REQUEST)
export const fetchClosedServiceCallOrdersSuccess = actionCreator(constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_SUCCESS)
export const fetchClosedServiceCallOrdersFailure = actionCreator(constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_FAILURE)

export const fetchCanceledServiceCallOrdersRequest = actionCreator(constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_REQUEST)
export const fetchCanceledServiceCallOrdersSuccess = actionCreator(constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_SUCCESS)
export const fetchCanceledServiceCallOrdersFailure = actionCreator(constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_FAILURE)

export const acceptOrderCertificateOfCompletionRequest = actionCreator(constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST)
export const acceptOrderCertificateOfCompletionSuccess = actionCreator(constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS)
export const acceptOrderCertificateOfCompletionFailure = actionCreator(constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE)

export const rejectOrderCertificateOfCompletionRequest = actionCreator(constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST)
export const rejectOrderCertificateOfCompletionSuccess = actionCreator(constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS)
export const rejectOrderCertificateOfCompletionFailure = actionCreator(constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE)

export const assignUsingContractRequest = actionCreator(constants.ASSIGN_USING_CONTRACT_REQUEST)
export const assignUsingContractSuccess = actionCreator(constants.ASSIGN_USING_CONTRACT_SUCCESS)
export const assignUsingContractFailure = actionCreator(constants.ASSIGN_USING_CONTRACT_FAILURE)

export const fetchContractAvailableEquipmentRequest = actionCreator(constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_REQUEST)
export const fetchContractAvailableEquipmentRequestSuccess = actionCreator(constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_SUCCESS)
export const fetchContractAvailableEquipmentRequestFailure = actionCreator(constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_FAILURE)

export const fetchContractRequest = actionCreator(constants.FETCH_CONTRACT_REQUEST)
export const fetchContractRequestSuccess = actionCreator(constants.FETCH_CONTRACT_SUCCESS)
export const fetchContractRequestFailure = actionCreator(constants.FETCH_CONTRACT_FAILURE)

export const fetchServiceDeskContractAvailableEquipmentCountRequest = actionCreator(constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_REQUEST)
export const fetchServiceDeskContractAvailableEquipmentCountRequestSuccess = actionCreator(constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_SUCCESS)
export const fetchServiceDeskContractAvailableEquipmentCountRequestFailure = actionCreator(constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_FAILURE)

export const addEquipmentToContractsRequest = actionCreator(constants.ADD_EQUIPMENT_TO_CONTRACT_REQUEST)
export const addEquipmentToContractsRequestSuccess = actionCreator(constants.ADD_EQUIPMENT_TO_CONTRACT_SUCCESS)
export const addEquipmentToContractsRequestFailure = actionCreator(constants.ADD_EQUIPMENT_TO_CONTRACT_FAILURE)

export const closeServiceCallFromNeedrepairRequest = actionCreator(constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_REQUEST)
export const closeServiceCallFromNeedrepairSuccess = actionCreator(constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_SUCCESS)
export const closeServiceCallFromNeedrepairFailure = actionCreator(constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_FAILURE)

export const sendServiceCallToRepairDiagnosticiansRequest = actionCreator(constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_REQUEST)
export const sendServiceCallToRepairDiagnosticiansSuccess = actionCreator(constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_SUCCESS)
export const sendServiceCallToRepairDiagnosticiansFailure = actionCreator(constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_FAILURE)

export const fetchServiceDeskCallOfferDocs = actionCreator(constants.FETCH_OFFER_FILES_REQUEST)
export const fetchServiceDeskCallOfferDocsSuccess = actionCreator(constants.FETCH_OFFER_FILES_SUCCESS)
export const fetchServiceDeskCallOfferDocsFailure = actionCreator(constants.FETCH_OFFER_FILES_FAILURE)

export const sendCallUsingContract = actionCreator(constants.SEND_CALL_USING_CONTRACT_REQUEST)
export const sendCallUsingContractSuccess = actionCreator(constants.SEND_CALL_USING_CONTRACT_SUCCESS)
export const sendCallUsingContractFailure = actionCreator(constants.SEND_CALL_USING_CONTRACT_FAILURE)

export const getOrderPossibleReports = actionCreator(constants.GET_ORDER_POSSIBLE_REPORTS_REQUEST)
export const getOrderPossibleReportsSuccess = actionCreator(constants.GET_ORDER_POSSIBLE_REPORTS_SUCCESS)
export const getOrderPossibleReportsFailure = actionCreator(constants.GET_ORDER_POSSIBLE_REPORTS_FAILURE)

export const getCallPossibleBlanks = actionCreator(constants.GET_CALL_POSSIBLE_BLANKS_REQUEST)
export const getCallPossibleBlanksSuccess = actionCreator(constants.GET_CALL_POSSIBLE_BLANKS_SUCCESS)
export const getCallPossibleBlanksFailure = actionCreator(constants.GET_CALL_POSSIBLE_BLANKS_FAILURE)

export const getCallsByGeneralSearch = actionCreator(constants.GET_CALLS_BY_GENERAL_SEARCH_REQUEST)
export const getCallsByGeneralSearchSuccess = actionCreator(constants.GET_CALLS_BY_GENERAL_SEARCH_SUCCESS)
export const getCallsByGeneralSearchFailure = actionCreator(constants.GET_CALLS_BY_GENERAL_SEARCH_FAILURE)

export const clearCallsSearchByNumber = actionCreator(constants.CLEAR_CALLS_SEARCH_BY_NUMBER)

export const setNdsCheckboxValue = actionCreator(constants.SET_NDS_CHECKBOX_VALUE)
export const setInputNdsCheckboxValue = actionCreator(constants.SET_INPUT_NDS_CHECKBOX_VALUE)
export const setOverheadCheckboxValue = actionCreator(constants.SET_OVERHEAD_CHECKBOX_VALUE)


export const fetchSDActionsHistoryCount = actionCreator(constants.FETCH_SD_ACTIONS_HISTORY_COUNT.REQUEST)
export const fetchSDActionsHistoryCountSuccess = actionCreator(constants.FETCH_SD_ACTIONS_HISTORY_COUNT.SUCCESS)
export const fetchSDActionsHistoryCountFailure = actionCreator(constants.FETCH_SD_ACTIONS_HISTORY_COUNT.FAILURE)


export const fetchSDActionsHistory = actionCreator(constants.FETCH_SD_ACTIONS_HISTORY_REQUEST)
export const fetchSDActionsHistorySuccess = actionCreator(constants.FETCH_SD_ACTIONS_HISTORY_SUCCESS)
export const fetchSDActionsHistoryFailure = actionCreator(constants.FETCH_SD_ACTIONS_HISTORY_FAILURE)
export const clearSDActionsHistory = actionCreator(constants.CLEAR_SD_ACTIONS_HISTORY)

export const toggleDisableScripts = actionCreator(constants.TOGGLE_DISABLE_SCRIPTS)

export const clearOrdersList = actionCreator(constants.CLEAR_ORDERS_LIST)

export const setOrderResultReportingType = actionCreator(constants.SET_ORDER_RESULT_REPORTING_TYPE)
export const shouldSetOrderResultReportAction = actionCreator(constants.SHOULD_SET_ORDER_RESULT_REPORT)

export const getServiceCallDocumentLinkRequest = actionCreator(constants.GET_SC_DOCUMENT_LINK_REQUEST)
export const getServiceCallDocumentLinkSuccess = actionCreator(constants.GET_SC_DOCUMENT_LINK_SUCCESS)
export const getServiceCallDocumentLinkFailure = actionCreator(constants.GET_SC_DOCUMENT_LINK_FAILURE)

export const getServiceCallBlankDocumentLinkRequest = actionCreator(constants.GET_SC_BLANK_DOCUMENT_LINK_REQUEST)
export const getServiceCallBlankDocumentLinkSuccess = actionCreator(constants.GET_SC_BLANK_DOCUMENT_LINK_SUCCESS)
export const getServiceCallBlankDocumentLinkFailure = actionCreator(constants.GET_SC_BLANK_DOCUMENT_LINK_FAILURE)

export const getServiceCallOrderCertificateDocumentLinkRequest = actionCreator(constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_REQUEST)
export const getServiceCallOrderCertificateDocumentLinkSuccess = actionCreator(constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_SUCCESS)
export const getServiceCallOrderCertificateDocumentLinkFailure = actionCreator(constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_FAILURE)

export const getServiceCallOrderDocumentLinkRequest = actionCreator(constants.GET_SCO_DOCUMENT_LINK_REQUEST)
export const getServiceCallOrderDocumentLinkSuccess = actionCreator(constants.GET_SCO_DOCUMENT_LINK_SUCCESS)
export const getServiceCallOrderDocumentLinkFailure = actionCreator(constants.GET_SCO_DOCUMENT_LINK_FAILURE)

export const getServiceCallOrderCommentFileLinkRequest = actionCreator(constants.GET_SCO_COMMENT_FILE_LINK_REQUEST)
export const getServiceCallOrderCommentFileLinkSuccess = actionCreator(constants.GET_SCO_COMMENT_FILE_LINK_SUCCESS)
export const getServiceCallOrderCommentFileLinkFailure = actionCreator(constants.GET_SCO_COMMENT_FILE_LINK_FAILURE)

export const attachServiceCallTagRequest = actionCreator(constants.ATTACH_SC_TAG_REQUEST)
export const attachServiceCallTagSuccess = actionCreator(constants.ATTACH_SC_TAG_SUCCESS)
export const attachServiceCallTagFailure = actionCreator(constants.ATTACH_SC_TAG_FAILURE)

export const detachServiceCallTagRequest = actionCreator(constants.DETACH_SC_TAG_REQUEST)
export const detachServiceCallTagSuccess = actionCreator(constants.DETACH_SC_TAG_SUCCESS)
export const detachServiceCallTagFailure = actionCreator(constants.DETACH_SC_TAG_FAILURE)

