import * as constants from '../constants/actionTypes/contracts'

const initialState = {
  list: {
    isFetching: false,
    error: null,
    contracts: null,
  },
  createdCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  inperformerCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  inworkCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  closedCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  canceledCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  createdFilteredCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  inperformerFilteredCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  inworkFilteredCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  closedFilteredCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  canceledFilteredCount: {
    isFetching: false,
    error: null,
    count: null,
  },
  filteringOrganizationsClient: {
    isFetching: false,
    error: null,
    organizations: null,
  },
  filteringOrganizationsPerformer: {
    isFetching: false,
    error: null,
    organizations: null,
  },
  contract: {
    contract: null,
    error: null,
    isFetching: true,
  },
  logs: {
    logs: null,
    isFetching: false,
    error: null,
  },
  logsCount: {
    count: null,
    isFetching: false,
    error: null,
  },
  edit: {
    error: null,
    isFetching: false,
  },
  editResponsible: {
    error: null,
    isFetching: false,
  },
  equipments: {
    rowsPerPage: 50,
    isFetching: false,
    equipments: null,
    error: null,
  },
  addEquipments: {
    rowsPerPage: 50,
  },
  equipmentCount: {
    error: null,
    count: null,
    isFetching: false,
  },
  permissions: {
    permissions: null,
    error: null,
    isFetching: true,
  },
  accept: {
    error: null,
    isFetching: true,
  },
  reject: {
    error: null,
    isFetching: true,
  },
  assign: {
    isFetching: false,
    error: null,
    assignOwn: null,
  },
  cancel: {
    error: null,
    isFetching: true,
  },
  delete: {
    error: null,
    isFetching: false,
  },
  assignEngineer: {
    error: null,
    isFetching: false,
  },
  unAssignEngineer: {
    error: null,
    isFetching: false,
  },
  createAgreement: {
    error: null,
    createdAgreementId: null,
    isFetching: false,
  },
  agreement: {
    error: null,
    agreement: null,
    isFetching: false,
  },
  agreementEquipmentCount: {
    error: null,
    count: null,
    isFetching: false,
  },
  agreementEquipment: {
    error: null,
    count: null,
    isFetching: false,
  },
  agreementEditName: {
    error: null,
    isFetching: false,
  },
  deleteAgreementEquipment: {
    error: null,
    isFetching: false,
  },
  addAgreementEquipments: {
    isFetching: false,
    error: null,
  },
  cancelAgreement: {
    error: null,
    isFetching: false,
  },
  assignAgreement: {
    error: null,
    isFetching: false,
  },
  rejectAgreement: {
    isFetching: false,
    error: null,
  },
  acceptAgreement: {
    isFetching: false,
    error: null,
  },
  agreements: {
    agreements: null,
    isFetching: false,
    error: null,
  },
  agreementsCount: {
    error: null,
    isFetching: false,
    count: null,
  },
  agreementLogs: {
    logs: null,
    error: null,
    isFetching: false,
  },
  agreementLogsCount: {
    count: 0,
    error: null,
    isFetching: false,
  },
}

const serviceDeskContracts = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_CONTRACTS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          contracts: action.payload.append
            ? [...state.list.contracts, ...action.payload.contracts]
            : action.payload.contracts,
        },
      }

    case constants.FETCH_CONTRACTS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: action.payload,
        },
      }
    case constants.FETCH_CONTRACT_PERMISSIONS_REQUEST:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isFetching: false,
          permissions: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_REQUEST:
      return {
        ...state,
        contract: {
          ...state.contract,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: {
          ...state.contract,
          isFetching: false,
          contract: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_FAILURE:
      return {
        ...state,
        contract: {
          ...state.contract,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_LOGS_REQUEST:
      return {
        ...state,
        logs: {
          ...state.logs,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACT_LOGS_SUCCESS:
      return {
        ...state,
        logs: {
          ...state.logs,
          isFetching: false,
          logs: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_LOGS_FAILURE:
      return {
        ...state,
        logs: {
          ...state.logs,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_LOGS_REQUEST:
      return {
        ...state,
        agreementLogs: {
          ...state.agreementLogs,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENT_LOGS_SUCCESS:
      return {
        ...state,
        agreementLogs: {
          ...state.agreementLogs,
          isFetching: false,
          logs: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_LOGS_FAILURE:
      return {
        ...state,
        agreementLogs: {
          ...state.agreementLogs,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_LOGS_COUNT_REQUEST:
      return {
        ...state,
        logsCount: {
          ...state.logsCount,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACT_LOGS_COUNT_SUCCESS:
      return {
        ...state,
        logsCount: {
          ...state.logsCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_LOGS_COUNT_FAILURE:
      return {
        ...state,
        logsCount: {
          ...state.logsCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_LOGS_COUNT_REQUEST:
      return {
        ...state,
        agreementLogsCount: {
          ...state.agreementLogsCount,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENT_LOGS_COUNT_SUCCESS:
      return {
        ...state,
        agreementLogsCount: {
          ...state.agreementLogsCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_LOGS_COUNT_FAILURE:
      return {
        ...state,
        agreementLogsCount: {
          ...state.agreementLogsCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CANCEL_CONTRACT_REQUEST:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          error: null,
          isFetching: true,
        },
      }

    case constants.CANCEL_CONTRACT_SUCCESS:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isFetching: false,
          contract: action.payload,
        },
      }

    case constants.CANCEL_CONTRACT_FAILURE:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ASSIGN_CONTRACT_REQUEST:
      return {
        ...state,
        assign: {
          ...state.assign,
          error: null,
          isFetching: true,
          assignOwn: null,
        },
      }

    case constants.ASSIGN_CONTRACT_SUCCESS:
      return {
        ...state,
        assign: {
          ...state.assign,
          isFetching: false,
          assignOwn: action.payload.assignOwn,
        },
      }

    case constants.ASSIGN_CONTRACT_FAILURE:
      return {
        ...state,
        assign: {
          ...state.assign,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ACCEPT_CONTRACT_REQUEST:
      return {
        ...state,
        accept: {
          ...state.accept,
          error: null,
          isFetching: true,
        },
      }

    case constants.ACCEPT_CONTRACT_SUCCESS:
      return {
        ...state,
        accept: {
          ...state.accept,
          isFetching: false,
        },
      }

    case constants.ACCEPT_CONTRACT_FAILURE:
      return {
        ...state,
        accept: {
          ...state.accept,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.REJECT_CONTRACT_REQUEST:
      return {
        ...state,
        reject: {
          ...state.reject,
          error: null,
          isFetching: true,
        },
      }

    case constants.REJECT_CONTRACT_SUCCESS:
      return {
        ...state,
        reject: {
          ...state.reject,
          isFetching: false,
        },
      }

    case constants.REJECT_CONTRACT_FAILURE:
      return {
        ...state,
        reject: {
          ...state.reject,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_EQUIPMENT_REQUEST:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          isFetching: false,
          equipments: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_EQUIPMENT_FAILURE:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_AGREEMENTS_REQUEST:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          isFetching: false,
          agreements: action.payload.append
            ? [
                ...(state.agreements.agreements || []),
                ...action.payload.agreements,
              ]
            : action.payload.agreements,
        },
      }

    case constants.FETCH_AGREEMENTS_FAILURE:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_AGREEMENTS_COUNT_REQUEST:
      return {
        ...state,
        agreementsCount: {
          ...state.agreementsCount,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENTS_COUNT_SUCCESS:
      return {
        ...state,
        agreementsCount: {
          ...state.agreementsCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_AGREEMENTS_COUNT_FAILURE:
      return {
        ...state,
        agreementsCount: {
          ...state.agreementsCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CANCEL_AGREEMENT_REQUEST:
      return {
        ...state,
        cancelAgreement: {
          ...state.cancelAgreement,
          error: null,
          isFetching: true,
        },
      }

    case constants.CANCEL_AGREEMENT_SUCCESS:
      return {
        ...state,
        cancelAgreement: {
          ...state.cancelAgreement,
          isFetching: false,
        },
      }

    case constants.CANCEL_AGREEMENT_FAILURE:
      return {
        ...state,
        cancelAgreement: {
          ...state.cancelAgreement,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ACCEPT_AGREEMENT_REQUEST:
      return {
        ...state,
        acceptAgreement: {
          ...state.acceptAgreement,
          error: null,
          isFetching: true,
        },
      }

    case constants.ACCEPT_AGREEMENT_SUCCESS:
      return {
        ...state,
        acceptAgreement: {
          ...state.acceptAgreement,
          isFetching: false,
        },
      }

    case constants.ACCEPT_AGREEMENT_FAILURE:
      return {
        ...state,
        acceptAgreement: {
          ...state.acceptAgreement,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.REJECT_AGREEMENT_REQUEST:
      return {
        ...state,
        rejectAgreement: {
          ...state.rejectAgreement,
          error: null,
          isFetching: true,
        },
      }

    case constants.REJECT_AGREEMENT_SUCCESS:
      return {
        ...state,
        rejectAgreement: {
          ...state.rejectAgreement,
          isFetching: false,
        },
      }

    case constants.REJECT_AGREEMENT_FAILURE:
      return {
        ...state,
        rejectAgreement: {
          ...state.rejectAgreement,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ASSIGN_AGREEMENT_REQUEST:
      return {
        ...state,
        assignAgreement: {
          ...state.assignAgreement,
          error: null,
          isFetching: true,
        },
      }

    case constants.ASSIGN_AGREEMENT_SUCCESS:
      return {
        ...state,
        assignAgreement: {
          ...state.assignAgreement,
          isFetching: false,
        },
      }

    case constants.ASSIGN_AGREEMENT_FAILURE:
      return {
        ...state,
        assignAgreement: {
          ...state.assignAgreement,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ADD_AGREEMENT_EQUIPMENT_REQUEST:
      return {
        ...state,
        addAgreementEquipments: {
          ...state.addAgreementEquipments,
          isFetching: true,
          error: null,
        },
      }

    case constants.ADD_AGREEMENT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        addAgreementEquipments: {
          ...state.addAgreementEquipments,
          isFetching: false,
          error: null,
        },
      }

    case constants.ADD_AGREEMENT_EQUIPMENT_FAILURE:
      return {
        ...state,
        addAgreementEquipments: {
          ...state.addAgreementEquipments,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_EQUIPMENT_REQUEST:
      return {
        ...state,
        agreementEquipment: {
          ...state.agreementEquipment,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        agreementEquipment: {
          ...state.agreementEquipment,
          isFetching: false,
          equipments: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_EQUIPMENT_FAILURE:
      return {
        ...state,
        agreementEquipment: {
          ...state.agreementEquipment,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_REQUEST:
      return {
        ...state,
        agreementEquipmentCount: {
          ...state.agreementEquipmentCount,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        agreementEquipmentCount: {
          ...state.agreementEquipmentCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_FAILURE:
      return {
        ...state,
        agreementEquipmentCount: {
          ...state.agreementEquipmentCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_AGREEMENT_EQUIPMENT_REQUEST:
      return {
        ...state,
        deleteAgreementEquipment: {
          ...state.deleteAgreementEquipment,
          error: null,
          isFetching: true,
        },
      }

    case constants.DELETE_AGREEMENT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        deleteAgreementEquipment: {
          ...state.deleteAgreementEquipment,
          isFetching: false,
        },
      }

    case constants.DELETE_AGREEMENT_EQUIPMENT_FAILURE:
      return {
        ...state,
        deleteAgreementEquipment: {
          ...state.deleteAgreementEquipment,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_CONTRACT_EQUIPMENT_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          isFetching: true,
        },
      }

    case constants.DELETE_CONTRACT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isFetching: false,
        },
      }

    case constants.DELETE_CONTRACT_EQUIPMENT_FAILURE:
      return {
        ...state,
        delete: {
          ...state.delete,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_EQUIPMENT_COUNT_REQUEST:
      return {
        ...state,
        equipmentCount: {
          ...state.equipmentCount,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACT_EQUIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        equipmentCount: {
          ...state.equipmentCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_EQUIPMENT_COUNT_FAILURE:
      return {
        ...state,
        equipmentCount: {
          ...state.equipmentCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_CONTRACT_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          error: null,
          isFetching: true,
        },
      }

    case constants.EDIT_CONTRACT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
        },
      }

    case constants.EDIT_CONTRACT_FAILURE:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST:
      return {
        ...state,
        assignEngineer: {
          ...state.assignEngineer,
          error: null,
          isFetching: true,
        },
      }

    case constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        assignEngineer: {
          ...state.assignEngineer,
          isFetching: false,
        },
      }

    case constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE:
      return {
        ...state,
        assignEngineer: {
          ...state.assignEngineer,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST:
      return {
        ...state,
        unAssignEngineer: {
          ...state.unAssignEngineer,
          error: null,
          isFetching: true,
        },
      }

    case constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        unAssignEngineer: {
          ...state.unAssignEngineer,
          isFetching: false,
        },
      }

    case constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE:
      return {
        ...state,
        unAssignEngineer: {
          ...state.unAssignEngineer,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_CONTRACT_RESPONSIBLE_REQUEST:
      return {
        ...state,
        editResponsible: {
          ...state.editResponsible,
          error: null,
          isFetching: true,
        },
      }

    case constants.EDIT_CONTRACT_RESPONSIBLE_SUCCESS:
      return {
        ...state,
        editResponsible: {
          ...state.editResponsible,
          isFetching: false,
        },
      }

    case constants.EDIT_CONTRACT_RESPONSIBLE_FAILURE:
      return {
        ...state,
        editResponsible: {
          ...state.editResponsible,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_FILTERING_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        [`filteringOrganizations${_.capitalize(action.payload.filterSide)}`]: {
          ...state[
            `filteringOrganizations${_.capitalize(action.payload.filterSide)}`
          ],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_FILTERING_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        [`filteringOrganizations${_.capitalize(action.payload.side)}`]: {
          ...state[
            `filteringOrganizations${_.capitalize(action.payload.side)}`
          ],
          isFetching: false,
          organizations: action.payload.organizations,
        },
      }

    case constants.FETCH_FILTERING_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        [`filteringOrganizations${_.capitalize(action.payload.side)}`]: {
          ...state[
            `filteringOrganizations${_.capitalize(action.payload.side)}`
          ],
          isFetching: false,
          error: action.payload.code,
        },
      }

    case constants.FETCH_CONTRACTS_COUNT_REQUEST:
      return {
        ...state,
        [`${action.payload.status}${action.payload.params &&
          'Filtered'}Count`]: {
          ...state[
            `${action.payload.status}${action.payload.params &&
              'Filtered'}Count`
          ],
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_CONTRACTS_COUNT_SUCCESS:
      return {
        ...state,
        [`${action.payload.status}Count`]: {
          ...state[`${action.payload.status}Count`],
          isFetching: false,
          count: action.payload.count,
        },
      }

    case constants.FETCH_CONTRACTS_COUNT_FAILURE:
      return {
        ...state,
        [`${action.payload.status}Count`]: {
          ...state[`${action.payload.status}Count`],
          isFetching: false,
          error: action.payload.code,
        },
      }

    case constants.FETCH_CONTRACTS_COUNT_WITH_FILTERS_SUCCESS:
      return {
        ...state,
        [`${action.payload.status}FilteredCount`]: {
          ...state[`${action.payload.status}FilteredCount`],
          isFetching: false,
          count: action.payload.count,
        },
      }

    case constants.FETCH_CONTRACTS_COUNT_WITH_FILTERS_FAILURE:
      return {
        ...state,
        [`${action.payload.status}FilteredCount`]: {
          ...state[`${action.payload.status}FilteredCount`],
          isFetching: false,
          error: action.payload.code,
        },
      }

    case constants.CREATE_AGREEMENT_REQUEST:
      return {
        ...state,
        createAgreement: {
          error: null,
          createdAgreementId: null,
          isFetching: true,
        },
      }

    case constants.CREATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          createdAgreementId: action.payload,
          isFetching: false,
        },
      }

    case constants.CREATE_AGREEMENT_FAILURE:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          error: action.payload,
          isFetching: false,
        },
      }

    case constants.FETCH_AGREEMENT_REQUEST:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: null,
          isFetching: true,
        },
      }

    case constants.FETCH_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          agreement: action.payload,
          isFetching: false,
        },
      }

    case constants.FETCH_AGREEMENT_FAILURE:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: action.payload,
          isFetching: false,
        },
      }

    case constants.EDIT_AGREEMENT_NAME_REQUEST:
      return {
        ...state,
        agreementEditName: {
          ...state.agreementEditName,
          error: null,
          isFetching: true,
        },
      }

    case constants.EDIT_AGREEMENT_NAME_SUCCESS:
      return {
        ...state,
        agreementEditName: {
          ...state.agreementEditName,
          isFetching: false,
        },
      }

    case constants.EDIT_AGREEMENT_NAME_FAILURE:
      return {
        ...state,
        agreementEditName: {
          ...state.agreementEditName,
          error: action.payload,
          isFetching: false,
        },
      }

    case constants.SET_EQUIPMENTS_ROWS_PER_PAGE:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          rowsPerPage: action.payload,
        },
      }

    case constants.SET_ADD_EQUIPMENTS_ROWS_PER_PAGE:
      return {
        ...state,
        addEquipments: {
          ...state.addEquipments,
          rowsPerPage: action.payload,
        },
      }

    default:
      return state
  }
}

export default serviceDeskContracts
