import {
  CLEAR_BA_SUGGEST,
  GET_BA_SUGGEST,
  FETCH_ORGANIZATION_EQUIPMENT_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_SUCCESS,
  FETCH_ORGANIZATION_EQUIPMENT_FAILURE,
  FETCH_ORGANIZATION_VENDORS_SUCCESS,
  FETCH_ORGANIZATION_VENDORS_REQUEST,
  FETCH_ORGANIZATION_VENDORS_FAILURE,
  FETCH_COUNTRY_NAMES_REQUEST,
  FETCH_COUNTRY_NAMES_SUCCESS,
  FETCH_COUNTRY_NAMES_FAILURE,
  ADD_EQUIPMENT_UNIT_FIRST_STEP,
  ADD_EQUIPMENT_UNIT_REQUEST,
  ADD_EQUIPMENT_UNIT_SUCCESS,
  ADD_EQUIPMENT_UNIT_FAILURE,
  FETCH_EQUIPMENT_PERMISSIONS_REQUEST,
  FETCH_EQUIPMENT_PERMISSIONS_SUCCESS,
  FETCH_EQUIPMENT_PERMISSIONS_FAILURE,
  FETCH_EQUIPMENT_DETAILS_REQUEST,
  FETCH_EQUIPMENT_DETAILS_SUCCESS,
  FETCH_EQUIPMENT_DETAILS_FAILURE,
  UPDATE_EQUIPMENT_DETAILS_REQUEST,
  UPDATE_EQUIPMENT_DETAILS_SUCCESS,
  UPDATE_EQUIPMENT_DETAILS_FAILURE,
  FETCH_USER_EQUIPMENTS_REQUEST,
  FETCH_USER_EQUIPMENTS_FAILURE,
  FETCH_USER_EQUIPMENTS_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_FAILURE,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_FAILURE,
  FETCH_USER_EQUIPMENTS_VENDORS_SUCCESS,
  FETCH_USER_EQUIPMENTS_VENDORS_REQUEST,
  FETCH_USER_EQUIPMENTS_VENDORS_FAILURE,
  TRANSFER_EQUIPMENTS_REQUEST,
  TRANSFER_EQUIPMENTS_SUCCESS,
  TRANSFER_EQUIPMENTS_FAILURE,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_SUCCESS,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_FAILURE,
  FETCH_USER_EQUIPMENT_COUNT_REQUEST,
  FETCH_USER_EQUIPMENT_COUNT_SUCCESS,
  FETCH_USER_EQUIPMENT_COUNT_FAILURE,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_SUCCESS,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_FAILURE,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_SUCCESS,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_FAILURE,
  FETCH_EQUIPMENT_LOGS_REQUEST,
  FETCH_EQUIPMENT_LOGS_SUCCESS,
  FETCH_EQUIPMENT_LOGS_FAILURE,
  EQUIPMENT_LOGS_COUNT_REQUEST,
  EQUIPMENT_LOGS_COUNT_SUCCESS,
  EQUIPMENT_LOGS_COUNT_FAILURE,
  CLEAR_EQUIPMENT_UNIT_CREATION_DATA,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_FAILURE,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_FAILURE,
  FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST,
  FETCH_EQUIPMENT_FILTER_LOCATION_SUCCESS,
  FETCH_EQUIPMENT_FILTER_LOCATION_FAILURE,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_SUCCESS,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_FAILURE,
  EQUIPMENT_LIST_ROWS_PER_PAGE_CHANGE,
  CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST,
  CHECK_EQUIPMENTS_IMPORT_FILE_SUCCESS,
  CHECK_EQUIPMENTS_IMPORT_FILE_FAILURE,
  PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST,
  PROCESS_EQUIPMENTS_IMPORT_FILE_SUCCESS,
  PROCESS_EQUIPMENTS_IMPORT_FILE_FAILURE,
  PROCESS_EQUIPMENTS_IMPORT_FILE_CLEAR,
  GET_BA_VARIANTS,
  CLEAR_BA_VARIANTS,
  REJECT_BA_SUGGESTIONS,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_FAILURE,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_SUCCESS,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_FAILURE,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_SUCCESS,
} from '../constants/actionTypes'

const initialState = {
  isFetchingCountryNames: false,
  equipment: [],
  isFetchingTransferringEquipmentSender: false,
  isFetchingTransferringEquipmentSenderCount: false,
  transferringEquipmentSender: [],
  transferringEquipmentSenderCount: 0,
  isFetchingTransferringEquipmentReceiver: false,
  isFetchingTransferringEquipmentReceiverCount: false,
  transferringEquipmentReceiver: [],
  transferringEquipmentReceiverCount: 0,
  vendors: [],
  isFetchingEquipment: false,
  isFetchingVendors: false,
  isTransferringEquipment: false,
  isFetchingEquipmentCount: false,
  isFetchingUserEquipmentCount: false,
  count: 0,
  transfersCount: 0,
  countries: [],
  isAddingEquipmentUnit: false,
  addEquipmentUnitFirstStep: {},
  addedEquipmentOrganizationId: undefined,
  addedEquipmentId: undefined,
  addEquipmentUnitErrors: null,
  errors: {},
  equipmentPermissions: {
    permissions: {},
    isFetchingEquipmentPermissions: false,
    error: undefined,
  },
  equipmentDetails: {
    isFetchingEquipmentDetails: false,
    equipment: {},
    error: undefined,
  },
  updateEquipmentDetails: {
    isUpdatingEquipmentDetails: false,
    error: undefined,
  },
  transferEquipmentToOwnOrganization: {
    isTransferringEquipmentToOwnOrganization: false,
    error: undefined,
  },
  transferEquipmentToForeignOrganization: {
    isTransferringEquipmentToForeignOrganization: false,
    error: undefined,
  },
  fetchEquipmentLogs: {
    isFetching: false,
    logs: [],
    errors: {},
  },
  equipmentLogs: {
    isFetching: false,
    logs: [],
    errors: {},
  },
  equipmentLogsCount: {
    isFetching: false,
    count: 0,
    errors: {},
  },
  equipmentFilterLocation: {
    addresses: [],
    isFetching: false,
    error: null,
  },
  equipmentFilterDepartment: {
    departments: [],
    isFetching: false,
    error: null,
  },
  list: {
    rowsPerPage: 10,
  },
  checkEquipmentsImportFile: {
    result: null,
    isFetching: false,
    error: null,
  },
  processEquipmentsImportFile: {
    result: null,
    isFetching: false,
    error: null,
  },
  baVariants: {
    value: [],
    isFetching: false,
    error: null,
  },
  baSuggest: {
    value: [],
    isFetching: false,
    error: null,
  },
  rejectBaSuggestions: {
    isFetching: false,
    error: null,
  },
  bindedEquipment: {
    equipment: null,
    isFetching: false,
    error: null,
  },
  tos: {
    tos: null,
    isFetching: false,
    error: null,
  },
  toWorks: {
    toWorks: null,
    isFetching: false,
    error: null,
  },
}

const equipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST: {
      return {
        ...state,
        bindedEquipment: {
          ...state.bindedEquipment,
          isFetching: false,
          error: null,
        },
      }
    }
    case FETCH_ORGANIZATION_BINDED_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        bindedEquipment: {
          ...state.bindedEquipment,
          equipment: action.payload,
          isFetching: false,
        },
      }
    }
    case FETCH_ORGANIZATION_BINDED_EQUIPMENT_FAILURE: {
      return {
        ...state,
        bindedEquipment: {
          ...state.bindedEquipment,
          isFetching: false,
          error: action.payload,
        },
      }
    }
    case FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST: {
      return {
        ...state,
        toWorks: {
          ...state.toWorks,
          toWorks: [],
          isFetching: true,
          error: null,
        },
      }
    }
    case FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_SUCCESS: {
      return {
        ...state,
        toWorks: {
          ...state.toWorks,
          works: action.payload,
          isFetching: false,
        },
      }
    }
    case FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_FAILURE: {
      return {
        ...state,
        toWorks: {
          ...state.toWorks,
          isFetching: false,
          error: action.payload,
        },
      }
    }
    // REJECT_BA_SUGGESTIONS
    case REJECT_BA_SUGGESTIONS.REQUEST: {
      return {
        ...state,
        rejectBaSuggestions: {
          isFetching: true,
          error: null,
        },
      }
    }
    case REJECT_BA_SUGGESTIONS.SUCCESS: {
      return {
        ...state,
        rejectBaSuggestions: {
          isFetching: false,
          error: null,
        },
      }
    }
    case REJECT_BA_SUGGESTIONS.FAILURE: {
      return {
        ...state,
        rejectBaSuggestions: {
          isFetching: false,
          error: action.payload.error,
        },
      }
    }
    // CLEAR_BA_VARIANTS
    case CLEAR_BA_SUGGEST: {
      return {
        ...state,
        baSuggest: initialState.baSuggest,
      }
    }
    // GET_BA_SUGGEST
    case GET_BA_SUGGEST.REQUEST: {
      return {
        ...state,
        baSuggest: {
          value: [],
          isFetching: true,
          error: null,
        },
      }
    }
    case GET_BA_SUGGEST.SUCCESS: {
      return {
        ...state,
        baSuggest: {
          value: action.payload.equipments,
          isFetching: false,
          error: null,
        },
      }
    }
    case GET_BA_SUGGEST.FAILURE: {
      return {
        ...state,
        baSuggest: {
          value: [],
          isFetching: false,
          error: action.payload.error,
        },
      }
    }
    // CLEAR_BA_VARIANTS
    case CLEAR_BA_VARIANTS: {
      return {
        ...state,
        baVariants: initialState.baVariants,
      }
    }
    // GET_BA_VARIANTS
    case GET_BA_VARIANTS.REQUEST: {
      return {
        ...state,
        baVariants: {
          isFetching: true,
          error: null,
        },
      }
    }
    case GET_BA_VARIANTS.SUCCESS: {
      return {
        ...state,
        baVariants: {
          value: action.payload.equipments,
          isFetching: false,
          error: null,
        },
      }
    }
    case GET_BA_VARIANTS.FAILURE: {
      return {
        ...state,
        baVariants: {
          value: [],
          isFetching: false,
          error: action.payload.error,
        },
      }
    }
    //
    case FETCH_ORGANIZATION_EQUIPMENT_REQUEST:
      return {
        ...state,
        isFetchingEquipment: true,
        error: undefined,
      }

    case FETCH_ORGANIZATION_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: [...action.data],
        isFetchingEquipment: false,
        error: undefined,
      }

    case FETCH_ORGANIZATION_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingEquipment: false,
        error: action.code,
      }

    case FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST:
      return {
        ...state,
        isFetchingEquipmentCount: true,
        error: undefined,
      }

    case FETCH_ORGANIZATION_EQUIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        count: action.data,
        isFetchingEquipmentCount: false,
        error: undefined,
      }

    case FETCH_ORGANIZATION_EQUIPMENT_COUNT_FAILURE:
      return {
        ...state,
        isFetchingEquipmentCount: false,
      }

    case FETCH_USER_EQUIPMENT_COUNT_REQUEST:
      return {
        ...state,
        isFetchingUserEquipmentCount: true,
        error: undefined,
      }

    case FETCH_USER_EQUIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        count: action.data,
        isFetchingUserEquipmentCount: false,
        error: undefined,
      }

    case FETCH_USER_EQUIPMENT_COUNT_FAILURE:
      return {
        ...state,
        isFetchingUserEquipmentCount: false,
      }

    case FETCH_USER_EQUIPMENTS_REQUEST:
      return {
        ...state,
        isFetchingEquipment: true,
        error: undefined,
      }

    case FETCH_USER_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        equipment: [...action.data],
        isFetchingEquipment: false,
        error: undefined,
      }

    case FETCH_USER_EQUIPMENTS_FAILURE:
      return {
        ...state,
        isFetchingEquipment: false,
        error: action.code,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST:
      return {
        ...state,
        isFetchingTransferringEquipmentSender: true,
        error: null,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_SENDER_SUCCESS:
      return {
        ...state,
        isFetchingTransferringEquipmentSender: false,
        transferringEquipmentSender: action.data,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_SENDER_FAILURE:
      return {
        ...state,
        isFetchingTransferringEquipmentSender: false,
        error: action.code,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST:
      return {
        ...state,
        isFetchingTransferringEquipmentSenderCount: true,
        error: null,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingTransferringEquipmentSenderCount: false,
        transferringEquipmentSenderCount: action.count,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_FAILURE:
      return {
        ...state,
        isFetchingTransferringEquipmentSenderCount: false,
        error: action.code,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST:
      return {
        ...state,
        isFetchingTransferringEquipmentReceiver: true,
        error: null,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_SUCCESS:
      return {
        ...state,
        isFetchingTransferringEquipmentReceiver: false,
        transferringEquipmentReceiver: action.data,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_FAILURE:
      return {
        ...state,
        isFetchingTransferringEquipmentReceiver: false,
        error: action.code,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST:
      return {
        ...state,
        isFetchingTransferringEquipmentReceiverCount: true,
        error: null,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingTransferringEquipmentReceiverCount: false,
        transferringEquipmentReceiverCount: action.count,
      }

    case FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_FAILURE:
      return {
        ...state,
        isFetchingTransferringEquipmentReceiverCount: false,
        error: action.code,
      }

    case TRANSFER_EQUIPMENTS_REQUEST:
      return {
        ...state,
        isTransferringEquipment: true,
        error: null,
      }

    case TRANSFER_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        isTransferringEquipment: false,
        error: null,
      }

    case TRANSFER_EQUIPMENTS_FAILURE:
      return {
        ...state,
        isTransferringEquipment: false,
        error: action.code,
      }

    case FETCH_ORGANIZATION_VENDORS_REQUEST:
      return {
        ...state,
        isFetchingVendors: true,
        error: undefined,
      }

    case FETCH_ORGANIZATION_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.data,
        isFetchingVendors: false,
        error: undefined,
      }

    case FETCH_ORGANIZATION_VENDORS_FAILURE:
      return {
        ...state,
        isFetchingVendors: false,
        error: action.code,
      }

    case FETCH_USER_EQUIPMENTS_VENDORS_REQUEST:
      return {
        ...state,
        isFetchingVendors: true,
        error: undefined,
      }

    case FETCH_USER_EQUIPMENTS_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.data,
        isFetchingVendors: false,
        error: undefined,
      }

    case FETCH_USER_EQUIPMENTS_VENDORS_FAILURE:
      return {
        ...state,
        isFetchingVendors: false,
        error: action.code,
      }

    case FETCH_COUNTRY_NAMES_REQUEST:
      return {
        ...state,
        isFetchingCountryNames: true,
      }

    case FETCH_COUNTRY_NAMES_SUCCESS:
      return {
        ...state,
        isFetchingCountryNames: false,
        countries: action.data,
        errors: {},
      }

    case FETCH_COUNTRY_NAMES_FAILURE:
      return {
        ...state,
        isFetchingCountryNames: false,
        errors: action.errors,
      }

    case ADD_EQUIPMENT_UNIT_FIRST_STEP:
      return {
        ...state,
        addEquipmentUnitFirstStep: action.data,
      }

    case CLEAR_EQUIPMENT_UNIT_CREATION_DATA:
      return {
        ...state,
        addEquipmentUnitFirstStep: {},
      }

    case ADD_EQUIPMENT_UNIT_REQUEST:
      return {
        ...state,
        isAddingEquipmentUnit: true,
      }

    case ADD_EQUIPMENT_UNIT_SUCCESS:
      return {
        ...state,
        isAddingEquipmentUnit: false,
        addedEquipmentOrganizationId: action.organizaitonId,
        addedEquipmentId: action.equipmentId,
        addEquipmentUnitErrors: null,
      }

    case ADD_EQUIPMENT_UNIT_FAILURE:
      return {
        ...state,
        isAddingEquipmentUnit: false,
        addEquipmentUnitErrors: action.errors,
      }

    case FETCH_EQUIPMENT_PERMISSIONS_REQUEST:
      return {
        ...state,
        equipmentPermissions: {
          isFetchingEquipmentPermissions: true,
          error: undefined,
        },
      }

    case FETCH_EQUIPMENT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        equipmentPermissions: {
          isFetchingEquipmentPermissions: false,
          permissions: action.data,
          error: undefined,
        },
      }

    case FETCH_EQUIPMENT_PERMISSIONS_FAILURE:
      return {
        ...state,
        equipmentPermissions: {
          isFetchingEquipmentPermissions: false,
          error: action.errors,
        },
      }

    case FETCH_EQUIPMENT_DETAILS_REQUEST:
      return {
        ...state,
        equipmentDetails: {
          equipment: {},
          isFetchingEquipmentDetails: true,
          error: undefined,
        },
      }

    case FETCH_EQUIPMENT_DETAILS_SUCCESS:
      return {
        ...state,
        equipmentDetails: {
          isFetchingEquipmentDetails: false,
          equipment: action.data,
          error: undefined,
        },
      }

    case FETCH_EQUIPMENT_DETAILS_FAILURE:
      return {
        ...state,
        equipmentDetails: {
          isFetchingEquipmentDetails: false,
          error: action.errors,
        },
      }

    case UPDATE_EQUIPMENT_DETAILS_REQUEST:
      return {
        ...state,
        updateEquipmentDetails: {
          isUpdatingEquipmentDetails: true,
          error: undefined,
        },
      }

    case UPDATE_EQUIPMENT_DETAILS_SUCCESS:
      return {
        ...state,
        updateEquipmentDetails: {
          isUpdatingEquipmentDetails: false,
          error: undefined,
        },
      }

    case UPDATE_EQUIPMENT_DETAILS_FAILURE:
      return {
        ...state,
        updateEquipmentDetails: {
          isUpdatingEquipmentDetails: false,
          error: action.errors,
        },
      }

    case TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST:
      return {
        ...state,
        transferEquipmentToOwnOrganization: {
          isTransferringEquipmentToOwnOrganization: true,
          error: undefined,
        },
      }

    case TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        transferEquipmentToOwnOrganization: {
          isTransferringEquipmentToOwnOrganization: false,
          error: undefined,
        },
      }

    case TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_FAILURE:
      return {
        ...state,
        transferEquipmentToOwnOrganization: {
          isTransferringEquipmentToOwnOrganization: false,
          error: action.errors,
        },
      }

    case TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST:
      return {
        ...state,
        transferEquipmentToForeignOrganization: {
          isTransferringEquipmentToForeignOrganization: true,
          error: undefined,
        },
      }

    case TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        transferEquipmentToForeignOrganization: {
          isTransferringEquipmentToForeignOrganization: false,
          error: undefined,
        },
      }

    case TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_FAILURE:
      return {
        ...state,
        transferEquipmentToForeignOrganization: {
          isTransferringEquipmentToForeignOrganization: false,
          error: action.errors,
        },
      }

    case FETCH_EQUIPMENT_LOGS_REQUEST:
      return {
        ...state,
        equipmentLogs: {
          ...state.equipmentLogs,
          isFetching: true,
          errors: {},
        },
      }

    case FETCH_EQUIPMENT_LOGS_SUCCESS:
      return {
        ...state,
        equipmentLogs: {
          ...state.equipmentLogs,
          isFetching: false,
          logs: action.logs,
        },
      }

    case FETCH_EQUIPMENT_LOGS_FAILURE:
      return {
        ...state,
        equipmentLogs: {
          ...state.equipmentLogs,
          isFetching: false,
          errors: action.errors,
        },
      }

    case EQUIPMENT_LOGS_COUNT_REQUEST:
      return {
        ...state,
        equipmentLogsCount: {
          ...state.equipmentLogsCount,
          isFetching: true,
          errors: {},
        },
      }

    case EQUIPMENT_LOGS_COUNT_SUCCESS:
      return {
        ...state,
        equipmentLogsCount: {
          ...state.equipmentLogsCount,
          isFetching: false,
          count: action.count,
        },
      }

    case EQUIPMENT_LOGS_COUNT_FAILURE:
      return {
        ...state,
        equipmentLogsCount: {
          ...state.equipmentLogsCount,
          isFetching: false,
          errors: action.errors,
        },
      }

    case FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST:
      return {
        ...state,
        equipmentFilterLocation: {
          ...state.equipmentFilterLocation,
          addresses: [],
          isFetching: true,
          error: null,
        },
      }

    case FETCH_EQUIPMENT_FILTER_LOCATION_SUCCESS:
      return {
        ...state,
        equipmentFilterLocation: {
          ...state.equipmentFilterLocation,
          addresses: action.payload.addresses,
          isFetching: false,
          error: null,
        },
      }

    case FETCH_EQUIPMENT_FILTER_LOCATION_FAILURE:
      return {
        ...state,
        equipmentFilterLocation: {
          ...state.equipmentFilterLocation,
          addresses: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST:
      return {
        ...state,
        equipmentFilterDepartment: {
          ...state.equipmentFilterDepartment,
          departments: [],
          isFetching: true,
          error: null,
        },
      }

    case FETCH_EQUIPMENT_FILTER_DEPARTMENT_SUCCESS:
      return {
        ...state,
        equipmentFilterDepartment: {
          ...state.equipmentFilterDepartment,
          departments: action.payload.departments,
          isFetching: false,
          error: null,
        },
      }

    case FETCH_EQUIPMENT_FILTER_DEPARTMENT_FAILURE:
      return {
        ...state,
        equipmentFilterDepartment: {
          ...state.equipmentFilterDepartment,
          departments: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case EQUIPMENT_LIST_ROWS_PER_PAGE_CHANGE:
      return {
        ...state,
        list: {
          ...state.list,
          rowsPerPage: action.payload,
        },
      }

    case CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST:
      return {
        ...state,
        checkEquipmentsImportFile: {
          ...state.checkEquipmentsImportFile,
          result: null,
          isFetching: true,
          error: null,
        },
      }

    case CHECK_EQUIPMENTS_IMPORT_FILE_SUCCESS:
      return {
        ...state,
        checkEquipmentsImportFile: {
          ...state.checkEquipmentsImportFile,
          result: _.pick(action.payload, [
            'path',
            'countOfAll',
            'countWithError',
            'countDublicates',
          ]),
          isFetching: false,
          error: null,
        },
      }

    case CHECK_EQUIPMENTS_IMPORT_FILE_FAILURE:
      return {
        ...state,
        checkEquipmentsImportFile: {
          ...state.checkEquipmentsImportFile,
          result: null,
          isFetching: false,
          error: action.payload,
        },
      }

    case PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST:
      return {
        ...state,
        processEquipmentsImportFile: {
          ...state.processEquipmentsImportFile,
          result: null,
          isFetching: true,
          error: null,
        },
      }

    case PROCESS_EQUIPMENTS_IMPORT_FILE_SUCCESS:
      return {
        ...state,
        processEquipmentsImportFile: {
          ...state.processEquipmentsImportFile,
          result: action.payload,
          isFetching: false,
          error: null,
        },
      }

    case PROCESS_EQUIPMENTS_IMPORT_FILE_CLEAR:
      return {
        ...state,
        processEquipmentsImportFile: {
          ...state.processEquipmentsImportFile,
          result: null,
          isFetching: false,
          error: null,
        },
      }

    case PROCESS_EQUIPMENTS_IMPORT_FILE_FAILURE:
      return {
        ...state,
        processEquipmentsImportFile: {
          ...state.processEquipmentsImportFile,
          result: null,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default equipmentReducer
