import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ChangePasswordComponent from '../../components/User/ChangePasswordComponent'
import { changePassword, fetchUser } from '../../actions/userActions'

const mapStateToProps = state => ({
  isChangingPassword: state.user.isChangingPassword,
  passwordChangeErrors: state.user.passwordChangeErrors,
  isFetchingUser: state.user.isFetchingUser,
  user: state.user.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword,
      fetchUser,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent),
)
