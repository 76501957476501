import React, { Component, Fragment } from 'react'
import moment from 'moment'

// helpers
import {
  getUserFullName,
  stringifyEmployeePosition,
} from '../../../../helpers/userHelper'
import { popLastSections } from '../../../../helpers/dataHelper'
import { addMaskCharacters } from '../../../../helpers/phoneStringHelper'
import { sortStringify } from '../../../../helpers/queryHelper'
import { organizationLocationStringify } from '../../../../helpers/parserHelper'

// components
import BreadCrumb from '../../../App/routing/BreadCrumb'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import FormControlSelectWithSearch from '../../../../widgets/Controlls/FormControlSelectWithSearch'
import ConfirmExitDialog from '../Widgets/ConfirmExitDialog'

// material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import { lighten } from '@material-ui/core/styles/colorManipulator'

// i18n
import i18next from '../../../../i18n/i18n'

import { URL } from '../../../../middlewares/api'
import SelectLimitedFormControl from '../../../../widgets/SelectLimitedFormControl'

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divDate: {
    display: 'flex',
  },
  textField: {
    width: '100%',
  },
  textMonth: {
    marginRight: theme.spacing(2),
    width: 80,
  },
  textYear: {
    width: 130,
  },
  divHeader: {
    marginBottom: theme.spacing(2),
  },
  divAlignBottom: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  textContractNumber: {
    width: '100%',
  },
  divLoaderHover: {
    position: 'relative',
  },
  divLoader: {
    position: 'absolute',
    right: 20,
    top: 22,
  },
  divButtonRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bigText: {
    fontSize: 34,
    marginBottom: theme.spacing(1),
  },
  captionText: {
    fontSize: 14,
  },
  greyText: {
    color: lighten(theme.palette.secondary.dark, 0.2),
  },
  dateErrorHelper: {
    maxWidth: 208,
    height: 0,
  },
})

class CreateContract extends Component {
  constructor(props) {
    super(props)
    const monthList = []
    for (let i = 1; i < 13; i++) {
      if (i < 10) monthList.push({ id: `${i}`, title: `0${i}` })
      else monthList.push({ id: `${i}`, title: `${i}` })
    }
    const currentYear = new Date(1970, 0, 1).getFullYear()
    const fromYear = new Date(currentYear, 0)
    const toYear = new Date(currentYear + 90, 11)
    const yearList = []
    for (let i = fromYear.getFullYear(); i <= toYear.getFullYear(); i += 1) {
      yearList.push({ id: `${i}`, title: `${i}` })
    }
    const yearListEnd = []
    for (let i = new Date().getFullYear(); i <= toYear.getFullYear(); i += 1) {
      yearListEnd.push({ id: `${i}`, title: `${i}` })
    }

    this.state = {
      monthList,
      monthStartSelected: undefined,
      yearStartSelected: undefined,
      monthEndSelected: undefined,
      yearEndSelected: undefined,
      yearStartList: yearList,
      yearEndList: yearListEnd,
      position: null,
      phone: null,
      isOpenConfirmExitDialog: false,
    }
  }

  componentDidUpdate = prevProps => {
    const {
      isCreatingServiceContract,
      isCreatingServiceContractError,
      contractId,
      match,
    } = this.props
    if (
      isCreatingServiceContract !== prevProps.isCreatingServiceContract &&
      !isCreatingServiceContract &&
      !isCreatingServiceContractError
    ) {
      if (contractId)
        this.props.history.push(
          `${popLastSections(match.url, 1)}/${contractId}/add_equipment`,
        )
    }
  }

  componentDidMount = () => {
    const {
      props: { fetchManagerOrHigherOrganizations },
    } = this
    fetchManagerOrHigherOrganizations()
  }

  handleChange = field => (event, payload) => {
    const value = event.target ? event.target.value : event
    if (field === 'numContract' && value.length > 254) {
      return
    }
    if (field === 'comment' && value.length > 3000) {
      return
    }
    if (field === 'responsibleSelected') {
      this.setState({
        responsibleSelected: value,
        position: payload.position || null,
        phone: payload.phone || null,
      })
    }

    if (field === 'organizationSelected') {
      this.setState({
        organizationLocation: payload.OrganizationLocations[0],
        responsibleSelected: null,
        position: null,
        phone: null,
      })
    }

    if (field === 'yearStartSelected') {
      const yearList = []
      const currentYear = new Date(1970, 0, 1).getFullYear()
      const toYear = new Date(currentYear + 90, 11)
      const currYear = new Date().getFullYear()
      const yearFrom = Number(value) <= currYear ? currYear : Number(value)

      for (let i = yearFrom; i <= toYear.getFullYear(); i += 1) {
        yearList.push({ id: `${i}`, title: `${i}` })
      }
      this.setState({
        yearEndList: yearList,
        monthStartSelectedError: false,
        monthEndSelectedError: false,
      })
    }
    if (field === 'yearEndSelected') {
      const yearList = []
      const currentYear = new Date(1970, 0, 1).getFullYear()
      const fromYear = new Date(currentYear, 0)
      for (let i = fromYear.getFullYear(); i <= Number(value); i += 1) {
        yearList.push({ id: `${i}`, title: `${i}` })
      }
      this.setState({ yearStartList: yearList })
    }
    this.setState(
      {
        [field]: value,
        [`${field}Error`]: false,
      },
      this.calcIntervalMonths,
    )
  }

  calcIntervalMonths = () => {
    const {
      monthStartSelected,
      monthEndSelected,
      yearStartSelected,
      yearEndSelected,
    } = this.state
    if (
      monthStartSelected &&
      monthEndSelected &&
      yearStartSelected &&
      yearEndSelected
    ) {
      const intervalMonths = moment([
        yearEndSelected,
        monthEndSelected - 1,
      ]).diff(moment([yearStartSelected, monthStartSelected - 1]), 'months')
      this.setState({ intervalMonths: intervalMonths + 1 })
    }
  }

  createContract = () => {
    const {
      props: { createServiceContractsRequest },
      state: {
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        numContract,
        comment,
        organizationSelected,
        responsibleSelected,
      },
      validateForm,
    } = this

    const data = {
      client_organization_id: organizationSelected,
      client_responsible_id: responsibleSelected,
      year_begin: yearStartSelected,
      month_begin: monthStartSelected,
      year_end: yearEndSelected,
      month_end: monthEndSelected,
    }
    if (numContract) {
      data.num_contract = numContract
    }
    if (comment) data.contract_note = comment
    if (numContract) data.num_contract = numContract

    if (validateForm()) {
      createServiceContractsRequest(data)
    }
  }

  validateForm = () => {
    const {
      state: {
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        organizationSelected,
        responsibleSelected,
      },
    } = this
    let formValid = true
    const errors = {}
    _.forIn(
      {
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        organizationSelected,
        responsibleSelected,
      },
      (value, key) => {
        if (!value || value === '') {
          errors[`${key}Error`] = true
          formValid = false
        }
      },
    )
    const {
      monthStartSelectedError,
      monthEndSelectedError,
      yearStartSelectedError,
      yearEndSelectedError,
    } = errors
    let dateError = false
    if (
      !monthStartSelectedError &&
      !monthEndSelectedError &&
      !yearStartSelectedError &&
      !yearEndSelectedError
    ) {
      if (Number(yearStartSelected) > Number(yearEndSelected)) {
        dateError = true
        errors.yearStartSelectedError = true
        errors.yearEndSelectedError = true
        formValid = false
      }
      if (
        !dateError &&
        Number(yearStartSelected) === Number(yearEndSelected) &&
        Number(monthStartSelected) > Number(monthEndSelected)
      ) {
        errors.monthStartSelectedError = true
        errors.monthEndSelectedError = true
        formValid = false
      }
    }
    if (!formValid) {
      this.setState({ ...errors })
      return false
    }
    return true
  }

  render() {
    const {
      props: { classes, match },
      state: {
        monthList,
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        intervalMonths,
        numContract,
        comment,
        organizationSelected,
        responsibleSelected,
        monthStartSelectedError,
        yearStartSelectedError,
        monthEndSelectedError,
        yearEndSelectedError,
        numContractError,
        yearStartList,
        yearEndList,
        responsibleSelectedError,
        organizationSelectedError,
        position,
        phone,
        isOpenConfirmExitDialog,
        organizationLocation,
      },
      handleChange,
      createContract,
    } = this

    return (
      <Fragment>
        <BreadCrumb to={match.url}>
          {i18next.t('service_desk_web_atoir:createContract')}
        </BreadCrumb>
        <div className={classes.root}>
          <div className={classes.divHeader}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className={classes.divAlignBottom}>
                  <Typography className={classes.bigText}>
                    {i18next.t('service_desk_web_atoir:createContract')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={numContractError}
                  className={classes.textContractNumber}
                  label={i18next.t('service_desk_web_atoir:contractNumber')}
                  value={numContract}
                  onChange={handleChange('numContract')}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" className={classes.captionText}>
                {i18next.t('service_desk_web_atoir:contract_begin_date')}
              </Typography>
              <div className={classes.divDate}>
                <div className={classes.textMonth}>
                  <FormControlSelectWithSearch
                    error={monthStartSelectedError}
                    noEmpty
                    label={`${i18next.t(
                      'service_desk_web_atoir:captionMonth',
                    )}`}
                    showLabel
                    value={monthStartSelected || ''}
                    values={monthList}
                    onChange={handleChange('monthStartSelected')}
                    pointValue={`${new Date().getFullYear()}`}
                  />
                </div>
                <div className={classes.textYear}>
                  <FormControlSelectWithSearch
                    error={yearStartSelectedError}
                    noEmpty
                    label={`${i18next.t('service_desk_web_atoir:captionYear')}`}
                    showLabel
                    value={yearStartSelected || ''}
                    values={yearStartList}
                    onChange={handleChange('yearStartSelected')}
                    pointerValue={`${new Date().getFullYear()}`}
                  />
                </div>
              </div>
              <div className={classes.dateErrorHelper}>
                {(yearStartSelectedError || monthStartSelectedError) && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.captionText}
                  >
                    {i18next.t(
                      'service_desk_web_atoir:captionSetContractDateStart',
                    )}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" className={classes.captionText}>
                {i18next.t('service_desk_web_atoir:contract_end_date')}
              </Typography>
              <div className={classes.divDate}>
                <div className={classes.textMonth}>
                  <FormControlSelectWithSearch
                    error={monthEndSelectedError}
                    noEmpty
                    label={`${i18next.t(
                      'service_desk_web_atoir:captionMonth',
                    )}`}
                    showLabel
                    value={monthEndSelected || ''}
                    values={monthList}
                    onChange={handleChange('monthEndSelected')}
                  />
                </div>
                <div className={classes.textYear}>
                  <FormControlSelectWithSearch
                    error={yearEndSelectedError}
                    noEmpty
                    label={`${i18next.t('service_desk_web_atoir:captionYear')}`}
                    showLabel
                    value={yearEndSelected || ''}
                    values={yearEndList}
                    onChange={handleChange('yearEndSelected')}
                    pointerValue={`${new Date().getFullYear()}`}
                  />
                </div>
              </div>
              <div className={classes.dateErrorHelper}>
                {(yearEndSelectedError || monthEndSelectedError) && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.captionText}
                  >
                    {i18next.t(
                      'service_desk_web_atoir:captionSetContractDateEnd',
                    )}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.divAlignBottom}>
                <div style={{ marginBottom: 0 }}>
                  <Typography variant="subheading" className={classes.greyText}>
                    {intervalMonths === undefined ? (
                      <Fragment>{`0 ${i18next.t(
                        'shared_web_atoir:monthEnum',
                      )}`}</Fragment>
                    ) : intervalMonths && Number(intervalMonths) >= 1 ? (
                      <Fragment>
                        {`${intervalMonths} ${i18next.t(
                          'shared_web_atoir:monthEnum',
                        )}`}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {i18next.t('shared_web_atoir:incorrectDateInput')}
                      </Fragment>
                    )}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={6}>
              <SelectLimitedFormControl
                FormControlProps={{
                  required: true,
                  fullWidth: true,
                  error: organizationSelectedError,
                }}
                value={organizationSelected || null}
                label={i18next.t('shared_web_atoir:organizationClient')}
                helperText={
                  organizationLocation
                    ? organizationLocationStringify(organizationLocation)
                    : null
                }
                sourceUrl={`${URL}/organizations/manager_or_higher`}
                query={{
                  ...sortStringify({ short_name: 'ASC' }),
                  with_locations: true,
                  filters: '[client_organization=true]',
                }}
                onChange={handleChange('organizationSelected')}
                requestResultTransformerToArray={data =>
                  data.organizations.filter(o => o.client_organization)
                }
                itemTransformer={row => ({ id: row.id, title: row.short_name })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectLimitedFormControl
                FormControlProps={{
                  required: true,
                  fullWidth: true,
                  disabled: !organizationSelected,
                  error: responsibleSelectedError,
                }}
                value={responsibleSelected || null}
                label={i18next.t('shared_web_atoir:responsiblePerson')}
                helperText={_.compact([
                  position && stringifyEmployeePosition(position),
                  phone && addMaskCharacters(phone),
                ]).join(', ')}
                query={{
                  ...sortStringify({
                    last_name: 'ASC',
                    first_name: 'ASC',
                    middle_name: 'ASC',
                  }),
                  filters: '[state=1]',
                }}
                sourceUrl={`${URL}/organizations/${organizationSelected}/users`}
                onChange={handleChange('responsibleSelected')}
                requestResultTransformerToArray={data =>
                  data.users.filter(row => row.is_plain_user)
                }
                itemTransformer={row => ({
                  id: row.id,
                  title: getUserFullName(row),
                })}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <TextField
                multiline
                rowsMax="6"
                className={classes.textField}
                id="standard-name"
                label={i18next.t('shared_web_atoir:note')}
                value={comment}
                onChange={this.handleChange('comment')}
                margin="normal"
              />
            </Grid>
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={6}>
            <OutlinedButton
              onClick={() => {
                this.setState({ isOpenConfirmExitDialog: true })
              }}
            >
              {i18next.t('shared_web_atoir:close')}
            </OutlinedButton>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.divButtonRight}>
              <ContainedButton onClick={createContract}>
                {i18next.t('shared_web_atoir:save')}
              </ContainedButton>
            </div>
          </Grid>
          <ConfirmExitDialog
            open={isOpenConfirmExitDialog}
            onLeave={() => {
              this.props.history.push(popLastSections(match.url, 1))
            }}
            onStay={() => {
              this.setState({ isOpenConfirmExitDialog: false })
            }}
          />
        </Grid>
      </Fragment>
    )
  }
}
export default withStyles(styles)(CreateContract)
