import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'

const styles = () => ({
  button: {
    margin: 0,
    padding: 0,
  },
  round: {
    width: 24,
    height: 24,
    borderRadius: 25,
  },
  selectedIcon: {
    color: '#fff',
  },
  notSelected: {
    opacity: 0.5,
  },
})

function ColorButton(props) {
  const colored = {
    backgroundColor: props.color,
  }
  return (
    <Fragment>
      {props.color ? (
        <Button
          className={props.classes.button}
          onClick={() => props.onSelect(props.color)}
        >
          <div
            className={classnames([
              props.classes.round,
              props.notSelected ? props.classes.notSelected : null,
            ])}
            style={colored}
          >
            {props.selected && (
              <DoneIcon className={props.classes.selectedIcon} />
            )}
          </div>
        </Button>
      ) : null}
    </Fragment>
  )
}

ColorButton.defaultProps = {
  selected: false,
  notSelected: false,
}

ColorButton.propTypes = {
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  notSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(ColorButton)
