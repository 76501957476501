import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import EquipmentFilters from '../EquipmentFilters'
import EquipmentTable from './EquipmentTable'

class EquipmentChoosingList extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    filters: {
      limit: 10,
      offset: 0,
      ...this.props.defaultFilters,
    },
  }

  componentDidMount = () => {
    this.fetchEquipment()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.fetchEquipment()
    }
  }

  onChange = field => event => {
    let filter = event.target.value
    if (field === 'inventoryCode') {
      filter = filter.replace(/\s*?/g, '')
    }
    this.setState(
      state => ({
        filters: {
          ...state.filters,
          [field]: filter,
        },
      }),
      this.updateEquipmentList(300),
    )
  }

  handleChangePage = (event, page) => {
    this.setState(
      state => ({
        page,
        filters: {
          ...state.filters,
          offset: state.rowsPerPage * page,
        },
      }),
      this.updateEquipmentList(100),
    )
  }

  handleChangeRowsPerPage = event => {
    this.setState(
      state => ({
        rowsPerPage: event.target.value,
        filters: {
          ...state.filters,
          limit: event.target.value,
        },
      }),
      this.updateEquipmentList(100),
    )
  }

  onChangeEquipment = event => {
    const {
      props: { equipment },
    } = this
    this.props.onChangeEquipment(
      event.target.value,
      equipment.find(item => item.id === event.target.value),
    )
  }

  updateEquipmentList = timeOut => () => {
    clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(this.fetchEquipment, timeOut)
  }

  fetchEquipment = () => {
    const {
      props: { externalMode },
    } = this
    if (externalMode) {
      this.props.fetchOrganizationEquipment({
        client_organization_id: externalMode.client,
        performer_organization_id: externalMode.performer,
        ..._.pick(this.state.filters, ['limit', 'offset', 'query']),
      })
    } else {
      this.props.fetchOrganizationEquipment(
        this.props.organizationId,
        _.omitBy(this.state.filters, f => f === null),
      )
      this.props.fetchOrganizationEquipmentCount(
        this.props.organizationId,
        this.state.filters,
      )
    }
  }

  render() {
    const {
      state: { filters, page, rowsPerPage },
      props: {
        organizationId,
        equipment,
        equipmentCount,
        isFetchingEquipment,
        checkedEquipment,
        externalMode,
      },
      onChangeEquipment,
      onChange,
      handleChangePage,
      handleChangeRowsPerPage,
    } = this
    return (
      <Fragment>
        <EquipmentFilters
          filters={filters}
          organizationId={organizationId}
          onChange={onChange}
          externalMode={externalMode}
        />
        <br />
        <EquipmentTable
          externalMode={externalMode}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          equipment={equipment}
          count={equipmentCount}
          page={page}
          rowsPerPage={rowsPerPage}
          checked={checkedEquipment}
          onChange={onChangeEquipment}
          isFetching={isFetchingEquipment}
        />
      </Fragment>
    )
  }
}

EquipmentChoosingList.defaultProps = {
  defaultFilters: {},
  checkedEquipment: '',
}

EquipmentChoosingList.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onChangeEquipment: PropTypes.func.isRequired,
  checkedEquipment: PropTypes.string,
  defaultFilters: PropTypes.object,
}

export default EquipmentChoosingList
