export const GET_EMAIL_TEMPLATES_REQUEST =
  'emailTemplates/GET_EMAIL_TEMPLATES_REQUEST'
export const GET_EMAIL_TEMPLATES_SUCCESS =
  'emailTemplates/GET_EMAIL_TEMPLATES_SUCCESS'
export const GET_EMAIL_TEMPLATES_FAILURE =
  'emailTemplates/GET_EMAIL_TEMPLATES_FAILURE'

export const GET_EMAIL_TEMPLATE_REQUEST =
  'emailTemplates/GET_EMAIL_TEMPLATE_REQUEST'
export const GET_EMAIL_TEMPLATE_SUCCESS =
  'emailTemplates/GET_EMAIL_TEMPLATE_SUCCESS'
export const GET_EMAIL_TEMPLATE_FAILURE =
  'emailTemplates/GET_EMAIL_TEMPLATE_FAILURE'

export const CREATE_EMAIL_TEMPLATE_REQUEST =
  'emailTemplates/CREATE_EMAIL_TEMPLATE_REQUEST'
export const CREATE_EMAIL_TEMPLATE_SUCCESS =
  'emailTemplates/CREATE_EMAIL_TEMPLATE_SUCCESS'
export const CREATE_EMAIL_TEMPLATE_FAILURE =
  'emailTemplates/CREATE_EMAIL_TEMPLATE_FAILURE'

export const EDIT_EMAIL_TEMPLATE_REQUEST =
  'emailTemplates/EDIT_EMAIL_TEMPLATE_REQUEST'
export const EDIT_EMAIL_TEMPLATE_SUCCESS =
  'emailTemplates/EDIT_EMAIL_TEMPLATE_SUCCESS'
export const EDIT_EMAIL_TEMPLATE_FAILURE =
  'emailTemplates/EDIT_EMAIL_TEMPLATE_FAILURE'

export const DELETE_EMAIL_TEMPLATE_REQUEST =
  'emailTemplates/DELETE_EMAIL_TEMPLATE_REQUEST'
export const DELETE_EMAIL_TEMPLATE_SUCCESS =
  'emailTemplates/DELETE_EMAIL_TEMPLATE_SUCCESS'
export const DELETE_EMAIL_TEMPLATE_FAILURE =
  'emailTemplates/DELETE_EMAIL_TEMPLATE_FAILURE'
