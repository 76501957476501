import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AcceptDialog from './AcceptDialog'
import { acceptContractRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isAccepting: state.serviceDeskContracts.accept.isFetching,
  acceptError: state.serviceDeskContracts.accept.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      acceptContractRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcceptDialog),
)
