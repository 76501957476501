import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IndustryInfo from './IndustryInfo'
import { downloadMedCertificate } from '../../../../../actions/equipmentActions'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      downloadMedCertificate,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndustryInfo),
)
