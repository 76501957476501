import * as Yup from 'yup'

export default Yup.object().shape({
  name: Yup.string().required(),
  ba_equipment_id: Yup.string().nullable(),
  industry_id: Yup.string().nullable(),
  organization_department_id: Yup.string().nullable(),
  organization_location_id: Yup.string().required(),
  model: Yup.string().nullable(),
  vendor: Yup.string().nullable(),
  serial_number: Yup.string().nullable(),
  article_number: Yup.string().nullable(),
  external_id: Yup.string().nullable(),
  year_issue: Yup.number()
    .min(1800)
    .max(2050)
    .nullable(),
  date_input_work: Yup.object()
    .shape({
      text: Yup.string().required(),
      date_input_work: Yup.date().required(),
    })
    .nullable(),
  date_expired_guarantee: Yup.object()
    .shape({
      text: Yup.string().required(),
      date_expired_guarantee: Yup.date().required(),
    })
    .nullable(),
  status: Yup.string().nullable(),
  not_working: Yup.bool().nullable(),
  floor: Yup.string().nullable(),
  cabinet: Yup.string().nullable(),
  portable: Yup.bool().nullable(),
  mol_user_organization_id: Yup.string().nullable(),
  country_id: Yup.object()
    .shape({
      text: Yup.string().required(),
      country_id: Yup.object({
        id: Yup.string().required(),
      }).required(),
    })
    .nullable(),
})
