import React, { useEffect } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'

import OpenFileComponent from './OpenFileComponent'
import HeadersMatchingComponent from './HeadersMatchingComponent'
import CheckResultView from './CheckResultView'
import grabHeaders from './grabHeaders'
import { Grid } from '@material-ui/core'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'

import i18next from '../../../i18n/i18n'

const minHeadersCount = 2

const style = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepInfo: {
    paddingBottom: theme.spacing(2),
  },
})

function getSteps() {
  return [
    {
      id: 0,
      title: i18next.t('equipment_web_atoir:fileLoad'),
      info: i18next.t('equipment_web_atoir:exelLoadTip'),
    },
    {
      id: 1,
      title: i18next.t('equipment_web_atoir:fieldMatching'),
      info: i18next.t('equipment_web_atoir:fieldMatchingTip'),
    },
    {
      id: 2,
      title: i18next.t('equipment_web_atoir:fileChecking'),
      info: i18next.t('equipment_web_atoir:fileCheckingTip'),
    },
  ]
}

function ImportEquipments({
  classes,
  match,
  history,
  organizationId,
  checkEquipmentsImportFile,
  isCheckingByBackend,
  checkingByBackendResult,
  checkingByBackendError,
  processEquipmentsImportFile,
  isProcessingByBackend,
  processingByBackendResult,
  processingBackendError,
  checkEquipmentsImportFileResultClear,
  downloadFileRequest,
  showDialogWithOk,
}) {
  const [activeStep, setActiveStep] = React.useState(0)
  const [fileToUpload, setFileToUpload] = React.useState(null)
  const [headersList, setHeadersList] = React.useState(null)
  const [headersMatchingList, setHeadersMatchingList] = React.useState(null)
  const [isHeadersMatchingValid, setIsHeadersMatchingValid] = React.useState(
    false,
  )
  const [isFileValid, setIsFileValid] = React.useState(false)
  const [result, setResult] = React.useState({})
  const steps = getSteps()

  useEffect(() => () => checkEquipmentsImportFileResultClear(), [])

  useEffect(() => {
    if (
      !isCheckingByBackend &&
      !checkingByBackendError &&
      checkingByBackendResult &&
      activeStep > 1
    ) {
      const { origin } = window.location
      setResult(checkingByBackendResult)
      downloadFileRequest({
        url: `${origin}/${checkingByBackendResult.path.replace(
          'temp',
          'onetimelink',
        )}`,
        name: 'checking-result.xlsx',
      })
    }
  }, [isCheckingByBackend])

  useEffect(() => {
    if (
      !isProcessingByBackend &&
      !processingBackendError &&
      processingByBackendResult &&
      activeStep > 1
    ) {
      const { origin } = window.location
      setResult(processingByBackendResult)
      downloadFileRequest({
        url: `${origin}/${processingByBackendResult.path.replace(
          'temp',
          'onetimelink',
        )}`,
        name: 'import-result.xlsx',
      })
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [isProcessingByBackend])

  function paramsPrepare() {
    const formData = new FormData()
    formData.append('file', fileToUpload)
    formData.append('fields', JSON.stringify(headersMatchingList))
    return {
      organizationId,
      formData,
    }
  }

  function processImport() {
    processEquipmentsImportFile(paramsPrepare())
  }

  function handleNext() {
    if (activeStep === 1) {
      checkEquipmentsImportFile(paramsPrepare())
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleReset() {
    setActiveStep(0)
  }

  function checkDisablingForNextStep() {
    // first step
    if (!isFileValid) return true
    // second step
    if ((!isFileValid || !isHeadersMatchingValid) && activeStep > 0) return true
    return isFileValid === 5
  }

  function checkHeadersListChanges(newList, oldList) {
    if (newList.length !== oldList.length) return true
    let isIdentical = true
    newList.forEach((item, index) => {
      const oldItem = oldList[index]
      if (
        item.value !== oldItem.value ||
        item.collName !== oldItem.collName ||
        item.collIndex !== oldItem.collIndex
      ) {
        isIdentical = false
      }
    })
    return !isIdentical
  }

  function onFileSelected(file) {
    setFileToUpload(file)
    grabHeaders(file)
      .then(newHeadersList => {
        if (
          headersMatchingList &&
          checkHeadersListChanges(newHeadersList, headersList)
        ) {
          setHeadersMatchingList(null)
        }
        if (newHeadersList.length > minHeadersCount) {
          setIsFileValid(true)
          setHeadersList(newHeadersList)
        } else {
          setHeadersList(null)
          showDialogWithOk(
            i18next.t('equipment_web_atoir:formatErrorInExcelFile'),
          )
        }
      })
      .catch(() => {
        showDialogWithOk(
          i18next.t('equipment_web_atoir:formatErrorInExcelFile'),
        )
      })
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <OpenFileComponent onChange={onFileSelected} file={fileToUpload} />
        )
      case 1:
        return (
          <HeadersMatchingComponent
            headersList={headersList}
            headersMatchingList={headersMatchingList}
            setIsHeadersMatchingValid={setIsHeadersMatchingValid}
            setHeadersMatchingList={setHeadersMatchingList}
          />
        )
      case 2:
        return <CheckResultView result={result || {}} />
      case 3:
        return <React.Fragment />
      default:
        return 'Unknown step'
    }
  }

  function backToEquipmentList() {
    const {
      params: { backTo },
    } = match
    if (backTo === 'from-equipment') {
      history.push(`/organizations/${organizationId}/equipment`)
    } else {
      history.push('/equipment')
    }
  }

  function nextFileImport() {
    setActiveStep(0)
    checkEquipmentsImportFileResultClear()
    setFileToUpload(null)
    setIsHeadersMatchingValid(null)
    setIsFileValid(false)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map(step => {
          const stepProps = {}
          const labelProps = {}

          return (
            <Step key={step.id} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div>
        <div className={classes.stepInfo}>
          <Typography>
            {activeStep < steps.length
              ? steps.find(item => item.id === activeStep).info
              : i18next.t('equipment_web_atoir:equipLoadDone')}
          </Typography>
        </div>
        <div>{getStepContent(activeStep)}</div>
        {activeStep === steps.length - 1 ? (
          <div>
            <Grid container>
              <Grid item xs={6}>
                <OutlinedButton
                  size="medium"
                  classes={{ root: classes.button }}
                  onClick={handleReset}
                >
                  {i18next.t('equipment_web_atoir:replaceFile')}
                </OutlinedButton>
              </Grid>
              <Grid item container justify="flex-end" xs={6}>
                <ContainedButton
                  size="medium"
                  classes={{ root: classes.button }}
                  onClick={processImport}
                >
                  {i18next.t('equipment_web_atoir:doLoad')}
                </ContainedButton>
              </Grid>
            </Grid>
          </div>
        ) : activeStep === steps.length ? (
          <div>
            <Grid container>
              <Grid item xs={6}>
                <OutlinedButton
                  size="medium"
                  classes={{ root: classes.button }}
                  onClick={backToEquipmentList}
                >
                  {i18next.t('equipment_web_atoir:gotoEquipList')}
                </OutlinedButton>
              </Grid>
              <Grid item container justify="flex-end" xs={6}>
                <ContainedButton
                  size="medium"
                  classes={{ root: classes.button }}
                  onClick={nextFileImport}
                  disabled={checkDisablingForNextStep()}
                >
                  {i18next.t('equipment_web_atoir:gotoNextFile')}
                </ContainedButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container>
              <Grid item xs={6} />
              <Grid item container justify="flex-end" xs={6}>
                <ContainedButton
                  size="medium"
                  classes={{ root: classes.button }}
                  onClick={handleNext}
                  disabled={checkDisablingForNextStep()}
                >
                  {i18next.t('equipment_web_atoir:gotoNext')}
                </ContainedButton>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(style)(ImportEquipments)
