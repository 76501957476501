import * as constants from '../constants/actionTypes/innerCalls'
import {
  actionCreator as externalActionCreator,
  actionCreatorTyped,
} from './actionCreator'

export const fetchInnerCallsRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_REQUEST,
)
export const fetchInnerCallsSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_SUCCESS,
)
export const fetchInnerCallsFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_FAILURE,
)

export const fetchInnerCallsCountAllRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_ALL_REQUEST,
)
export const fetchInnerCallsCountAllSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_ALL_SUCCESS,
)
export const fetchInnerCallsCountAllFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_ALL_FAILURE,
)

export const fetchInnerCallsCountCreatedRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_CREATED_REQUEST,
)
export const fetchInnerCallsCountCreatedSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_CREATED_SUCCESS,
)
export const fetchInnerCallsCountCreatedFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_CREATED_FAILURE,
)

export const fetchInnerCallsCountClosedRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_CLOSED_REQUEST,
)
export const fetchInnerCallsCountClosedSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_CLOSED_SUCCESS,
)
export const fetchInnerCallsCountClosedFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_COUNT_CLOSED_FAILURE,
)

export const fetchInnerCallsNumbersRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_NUMBERS_REQUEST,
)
export const fetchInnerCallsNumbersSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_NUMBERS_SUCCESS,
)
export const fetchInnerCallsNumbersFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_NUMBERS_FAILURE,
)

export const fetchInnerCallsEngineersRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_ENGINEERS_REQUEST,
)
export const fetchInnerCallsEngineersSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_ENGINEERS_SUCCESS,
)
export const fetchInnerCallsEngineersFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_ENGINEERS_FAILURE,
)

export const fetchInnerCallsEquipmentsRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_EQUIPMENTS_REQUEST,
)
export const fetchInnerCallsEquipmentsSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_EQUIPMENTS_SUCCESS,
)
export const fetchInnerCallsEquipmentsFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_EQUIPMENTS_FAILURE,
)

export const setPagination = externalActionCreator(constants.SET_PAGINATION)
export const clearPagination = externalActionCreator(constants.CLEAR_PAGINATION)

export const fetchInnerCallRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_REQUEST,
)
export const fetchInnerCallSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_SUCCESS,
)
export const fetchInnerCallFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_FAILURE,
)

export const fetchInnerCallHistoryRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_HISTORY_REQUEST,
)
export const fetchInnerCallHistorySuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_HISTORY_SUCCESS,
)
export const fetchInnerCallHistoryFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_HISTORY_FAILURE,
)
export const clearInnerCallHistory = externalActionCreator(
  constants.CLEAR_INNER_CALL_HISTORY,
)

export const fetchInnerCallPhotosRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_PHOTOS_REQUEST,
)
export const fetchInnerCallPhotosSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_PHOTOS_SUCCESS,
)
export const fetchInnerCallPhotosFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_PHOTOS_FAILURE,
)

export const fetchInnerCallPhotosCountRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_PHOTOS_COUNT_REQUEST,
)
export const fetchInnerCallPhotosCountSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_PHOTOS_COUNT_SUCCESS,
)
export const fetchInnerCallPhotosCountFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_PHOTOS_COUNT_FAILURE,
)

export const uploadInnerCallPhotoRequest = externalActionCreator(
  constants.UPLOAD_INNER_CALL_PHOTO_REQUEST,
)
export const uploadInnerCallPhotoSuccess = externalActionCreator(
  constants.UPLOAD_INNER_CALL_PHOTO_SUCCESS,
)
export const uploadInnerCallPhotoFailure = externalActionCreator(
  constants.UPLOAD_INNER_CALL_PHOTO_FAILURE,
)

export const deleteInnerCallPhotoRequest = externalActionCreator(
  constants.DELETE_INNER_CALL_PHOTO_REQUEST,
)
export const deleteInnerCallPhotoSuccess = externalActionCreator(
  constants.DELETE_INNER_CALL_PHOTO_SUCCESS,
)
export const deleteInnerCallPhotoFailure = externalActionCreator(
  constants.DELETE_INNER_CALL_PHOTO_FAILURE,
)

export const fetchInnerCallAnalyticsSummaryRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_REQUEST,
)
export const fetchInnerCallAnalyticsSummarySuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_SUCCESS,
)
export const fetchInnerCallAnalyticsSummaryFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_FAILURE,
)

export const fetchInnerCallAnalyticsChartCreatedRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_REQUEST,
)
export const fetchInnerCallAnalyticsChartCreatedSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_SUCCESS,
)
export const fetchInnerCallAnalyticsChartCreatedFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_FAILURE,
)

export const fetchInnerCallAnalyticsSummaryAverageRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_REQUEST,
)
export const fetchInnerCallAnalyticsSummaryAverageSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_SUCCESS,
)
export const fetchInnerCallAnalyticsSummaryAverageFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_FAILURE,
)

export const fetchInnerCallAnalyticsChartAverageRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_REQUEST,
)
export const fetchInnerCallAnalyticsChartAverageSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_SUCCESS,
)
export const fetchInnerCallAnalyticsChartAverageFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_FAILURE,
)

export const fetchInnerCallAnalyticsChartClosedByEngineerRequest = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_REQUEST,
)
export const fetchInnerCallAnalyticsChartClosedByEngineerRequestSuccess = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_SUCCESS,
)
export const fetchInnerCallAnalyticsChartClosedByEngineerRequestFailure = externalActionCreator(
  constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_FAILURE,
)

export const fetchInnerCallsEngineersCountRequest = externalActionCreator(
  constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_REQUEST,
)
export const fetchInnerCallsEngineersCountSuccess = externalActionCreator(
  constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_SUCCESS,
)
export const fetchInnerCallsEngineersCountFailure = externalActionCreator(
  constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_FAILURE,
)

export const getOrganizationsTrusted = actionCreatorTyped(
  constants.GET_ORGANIZATIONS_TRUSTED,
)

export const getInnerCallsPerformerEquipments = actionCreatorTyped(
  constants.GET_INNER_CALLS_PERFORMER_EQUIPMENTS,
)
export const createInnerCall = actionCreatorTyped(constants.CREATE_INNER_CALL)

export const editInnerCall = actionCreatorTyped(constants.EDIT_INNER_CALL)

export const closeInnerCall = actionCreatorTyped(constants.CLOSE_INNER_CALL)

export const assigneeInnerCall = actionCreatorTyped(
  constants.ASSIGNEE_INNER_CALL,
)

export const setCommonInnerCallsListState = externalActionCreator(
  constants.SET_COMMON_INNER_CALLS_LIST_STATE,
)
