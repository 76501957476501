import React from 'react'
import RadioSwitch from './RadioSwitch'
import SimpleSwitch from './SimpleSwitch'
import Subtitle from './Subtitle'

const SdCallsListSettings = ({ settings = {}, onChangeById }) => {
  return (
    <>
      {settings.sd && settings.sd.orders_list && (
        <div>
          <Subtitle text="Оборудование" />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.name"
            value={_.get(settings, 'sd.orders_list.equipment.name')}
            onChangeById={onChangeById}
            title="Наименование"
          />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.vendor"
            value={_.get(settings, 'sd.orders_list.equipment.vendor')}
            onChangeById={onChangeById}
            title="Производитель"
            depended={[
              {
                idValue: 'sd.orders_list.equipment.model',
                title: 'модель',
                value: _.get(settings, 'sd.orders_list.equipment.model'),
              },
            ]}
          />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.article_number"
            value={_.get(settings, 'sd.orders_list.equipment.article_number')}
            onChangeById={onChangeById}
            title="Инвентарный номер"
          />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.serial_number"
            value={_.get(settings, 'sd.orders_list.equipment.serial_number')}
            onChangeById={onChangeById}
            title="Серийный номер"
          />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.equipment_external_id"
            value={_.get(
              settings,
              'sd.orders_list.equipment.equipment_external_id',
            )}
            onChangeById={onChangeById}
            title="Внешний ID"
          />
          <Subtitle text="Фактическое местонахождение оборудования" />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.city"
            value={_.get(settings, 'sd.orders_list.equipment.city')}
            onChangeById={onChangeById}
            title="Город"
          />
          <SimpleSwitch
            idValue="sd.orders_list.equipment.street"
            value={_.get(settings, 'sd.orders_list.equipment.street')}
            onChangeById={onChangeById}
            title="Улица, дом"
            depended={[
              {
                idValue: 'sd.orders_list.equipment.office',
                title: 'Номер офиса, этаж',
                value: _.get(settings, 'sd.orders_list.equipment.office'),
              },
            ]}
          />
          <Subtitle text="Организация-собственник оборудования" />
          <RadioSwitch
            idValue="sd.orders_list.client_organization"
            value={_.get(settings, 'sd.orders_list.client_organization')}
            onChangeById={onChangeById}
            values={[
              { value: 'full_name', title: 'Полное название' },
              { value: 'short_name', title: 'Короткое название' },
            ]}
          />
          <Subtitle text="Организация - исполнитель" />
          <RadioSwitch
            idValue="sd.orders_list.performer_organization"
            value={_.get(settings, 'sd.orders_list.performer_organization')}
            onChangeById={onChangeById}
            values={[
              { value: 'full_name', title: 'Полное название' },
              { value: 'short_name', title: 'Короткое название' },
            ]}
          />
          <SimpleSwitch
            idValue="sd.orders_list.ext_num"
            value={_.get(settings, 'sd.orders_list.ext_num')}
            onChangeById={onChangeById}
            title="Внешний номер заявки"
          />
          <SimpleSwitch
            idValue="sd.orders_list.reaction_date"
            value={_.get(settings, 'sd.orders_list.reaction_date')}
            onChangeById={onChangeById}
            title="Желаемый период ремонта"
          />
          <SimpleSwitch
            idValue="sd.orders_list.visit_date"
            value={_.get(settings, 'sd.orders_list.visit_date')}
            onChangeById={onChangeById}
            title="Дата визита инженера"
          />
          <SimpleSwitch
            idValue="sd.orders_list.engineer_user"
            value={_.get(settings, 'sd.orders_list.engineer_user')}
            onChangeById={onChangeById}
            title="ФИО инженера"
          />
          <SimpleSwitch
            idValue="sd.orders_list.order_result"
            value={_.get(settings, 'sd.orders_list.order_result')}
            onChangeById={onChangeById}
            title="Результат по заказ-наряду"
          />
        </div>
      )}
    </>
  )
}

export default SdCallsListSettings
