import React, { Component } from 'react'
import PropTypes from 'prop-types'

// material
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Radio from '@material-ui/core/Radio'
import TablePagination from '@material-ui/core/TablePagination'
import withStyles from '@material-ui/core/styles/withStyles'

// modules
import { organizationLocationStringify } from '../../../../../../../helpers/parserHelper'
import Loader from '../../../../../../Loader'

// i18n
import i18next from '../../../../../../../i18n/i18n'

const styles = {
  tableWrapper: {
    overflowX: 'auto',
  },
  root: {
    width: '100%',
  },
}

class EquipmentTable extends Component {
  state = {}

  handleChangePage = (event, page) => {
    this.props.onChangePage(page)
  }

  handleChangeRowsPerPage = event => {
    this.props.onChangeRowsPerPage(event.target.value)
  }

  render() {
    const {
      props: {
        classes,
        equipment,
        count,
        onChange,
        checked,
        rowsPerPage,
        page,
        onChangeRowsPerPage,
        onChangePage,
        isFetching,
        externalMode,
      },
    } = this
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>
                  {i18next
                    .t('service_desk_web_atoir:tableCellName')
                    .toUpperCase()}
                </TableCell>
                <TableCell>
                  {i18next
                    .t('service_desk_web_atoir:tableCellVendor')
                    .toUpperCase()}
                </TableCell>
                <TableCell>
                  {i18next
                    .t('equipment_web_atoir:articleNumberShort')
                    .toUpperCase()}
                </TableCell>
                <TableCell>
                  {i18next
                    .t('equipment_web_atoir:serialNumberShort')
                    .toUpperCase()}
                </TableCell>
                {!externalMode && (
                  <TableCell>
                    {i18next.t('equipment_web_atoir:placement').toUpperCase()}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <Loader />
              ) : equipment.length > 0 ? (
                equipment.map(e => (
                  <TableRow key={e.id}>
                    <TableCell padding="checkbox">
                      <Radio
                        inputProps={{
                          id: e.id,
                          value: e.id,
                        }}
                        checked={checked === e.id}
                        onChange={onChange}
                      />
                    </TableCell>
                    <TableCell className="wordWrapBreakAll">{e.name}</TableCell>
                    <TableCell className="wordWrapBreakAll">
                      {e.vendor}
                    </TableCell>
                    <TableCell className="wordWrapBreakAll">
                      {e.article_number}
                    </TableCell>
                    <TableCell className="wordWrapBreakAll">
                      {e.serial_number}
                    </TableCell>
                    {!externalMode && (
                      <TableCell>
                        {e.OrganizationLocation
                          ? `${organizationLocationStringify(
                              e.OrganizationLocation,
                            )}
                      ${
                        e.cabinet
                          ? `, ${i18next
                              .t('equipment_web_atoir:cabinet')
                              .toLowerCase()} ${e.cabinet}`
                          : ''
                      }`
                          : null}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <div>Оборудование не найдено</div>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          colSpan={6}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
          labelDisplayedRows={({
            from: fromPage,
            to: toPage,
            count: countPage,
          }) =>
            i18next.t('shared_web_atoir:labelDisplayedRows', {
              fromPage,
              toPage,
              countPage,
            })
          }
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    )
  }
}

EquipmentTable.defaultProps = {
  checked: '',
  equipment: [],
  count: 0,
  isFetching: false,
}

EquipmentTable.propTypes = {
  equipment: PropTypes.array,
  count: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  checked: PropTypes.string,
}

export default withStyles(styles)(EquipmentTable)
