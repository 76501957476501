/* eslint-disable */
import React from 'react'
import EquipmentSuggestionsDialog from '../common/EquipmentSuggestionsDialog'
import i18next from '../../../i18n/i18n'
import { bindActionCreators } from 'redux'
import {
  rejectBaSuggestions,
  updateEquipmentDetails,
} from '../../../actions/equipmentActions'
import { connect } from 'react-redux'

function CheckEquipmentSuggestions({
  baVariants,
  open,
  setOpen,
  equipment,
  callRejectBaSuggestions,
  callUpdateEquipmentDetails,
}) {
  const [selectedId, setSelectedId] = React.useState(null)
  const [baVariantsCustom, setBaVariantsCustom] = React.useState(null)

  // data transform for compatible vs common view from
  React.useEffect(() => {
    setBaVariantsCustom(baVariants.map(item => item.BAEquipment))
  }, [baVariants])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleSubmit = React.useCallback(() => {
    setOpen(false)
    const {
      id,
      name,
      model,
      BAVendor: { name: vendor },
    } = baVariantsCustom.find(item => item.id === selectedId)
    callUpdateEquipmentDetails(equipment.organization_id, equipment.id, {
      ba_equipment_id: id,
      name,
      model,
      vendor,
    })
  }, [selectedId])

  const handleSetNoSameEquipment = React.useCallback(() => {
    callRejectBaSuggestions({
      equipmentId: equipment.id,
      organizationId: equipment.organization_id,
    })
    setOpen(false)
  }, [baVariantsCustom])

  return (
    <React.Fragment>
      <EquipmentSuggestionsDialog
        open={open}
        handleClose={handleClose}
        onSubmit={handleSubmit}
        baVariants={baVariantsCustom}
        selectedId={selectedId}
        setSelectedIdHandler={setSelectedId}
        title={i18next.t('equipment_web_atoir:suggestionsFoundTitle')}
        message={i18next.t('equipment_web_atoir:suggestionsFindDescription')}
        submitText={i18next.t('equipment_web_atoir:doBind')}
        cancelText={i18next.t('shared_web_atoir:cancel')}
        noSameEquipmentText={i18next.t(
          'equipment_web_atoir:thereAreNoSameEquipment',
        )}
        setNoSameEquipment={handleSetNoSameEquipment}
      />
    </React.Fragment>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callRejectBaSuggestions: rejectBaSuggestions.REQUEST,
      callUpdateEquipmentDetails: updateEquipmentDetails,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckEquipmentSuggestions)
