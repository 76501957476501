import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InventoryListOrders from './InventoryListOrders'
import {
  fetchFiltersOrdersInventory,
  fetchOrdersInventory,
  fetchOrdersCountInventory,
  removeOrderInventory,
  editEngineerOrderInventory,
} from '../../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  isFetchFilters: state.inventory.filtersOrders.isFetch,
  filtersOrders: state.inventory.filtersOrders.filters,
  isFetchOrders: state.inventory.orders.isFetch,
  ordersToLocations: state.inventory.orders.orders,
  isFetchOrdersCount: state.inventory.ordersCount.isFetch,
  ordersCount: state.inventory.ordersCount.count,
  isRemoveOrder: state.inventory.removeOrders.isRemove,
  isRemoveOrderSuccess: state.inventory.removeOrders.isRemoveSuccess,
  isEditEngineerOrder: state.inventory.editEngineersOrder.isEdit,
  isEditEngineerOrderSuccess: state.inventory.editEngineersOrder.isEditSuccess,
  inventoryProjectPermissions:
    state.inventory.fetchInventoryProjectRights.inventoryProjectPermissions,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrders: fetchOrdersInventory,
      fetchOrdersCount: fetchOrdersCountInventory,
      fetchOrdersFilters: fetchFiltersOrdersInventory,
      removeOrder: removeOrderInventory,
      editEngineerOrderInventory,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryListOrders),
)
