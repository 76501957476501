import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import PropTypes from 'prop-types'

// material
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import DiagnosticAndRepairInformation from './widgets/DiagnosticAndRepairInformation'

// modules
import InformationForPerformer from './widgets/InformationForPerformer'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import Loader from '../../../Loader'

// helpers
import { organizationAddressStringify } from '../../../../helpers/parserHelper'

// i18
import i18next from '../../../../i18n/i18n'

const styles = () => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  button: {
    marginRight: 0,
    marginLeft: 0,
  },
})

class ServiceDeskRequestDetails extends Component {
  state = {
    data: {
      dateFrom: new Date(),
      dateTo: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    },
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.isFetchingUserStatus !== this.props.isFetchingUserStatus &&
      nextProps.isFetchingUserStatus === false
    ) {
      if (nextProps.userStatus.is_manager_or_higher) {
        this.props.fetchOrganizationStaff(
          nextProps.creationData.clientOrganization,
        )
      } else {
        this.props.fetchOrganizationUserMe(
          nextProps.creationData.clientOrganization,
        )
      }
    }
    if (
      nextProps.isFetchingMe !== this.props.isFetchingMe &&
      nextProps.isFetchingMe === false
    ) {
      this.setState(state => ({
        data: {
          ...state.data,
          responsible: nextProps.me.id,
        },
      }))
    }
    if (
      nextProps.isFetchingEquipment !== this.props.isFetchingEquipment &&
      nextProps.isFetchingEquipment === false &&
      !nextProps.equipmentError
    ) {
      this.setState(state => ({
        data: {
          ...state.data,
          defaultLocation: nextProps.equipment.organizationLocation,
        },
      }))
    }
  }

  onChange = field => event => {
    const { value } = event.target
    if (
      field === 'cabinet' &&
      typeof value === 'string' &&
      value.length > 255
    ) {
      return
    }
    if (
      field === 'comment' &&
      typeof value === 'string' &&
      value.length > 1000
    ) {
      return
    }
    this.setState(state => ({
      data: {
        ...state.data,
        [field]: value,
      },
    }))
  }

  handlePositive = () => {
    const {
      state: {
        data: {
          dateFrom,
          dateTo,
          location,
          floor,
          cabinet,
          responsible,
          comment: additionalComment,
        },
      },
      props: {
        creationData: { comment, clientOrganization, equipment, extNumber },
      },
    } = this
    const errors = {}
    if (!dateFrom) {
      errors.dateFromError = i18next.t(
        'service_desk_web_atoir:requestCreationNoDateValidationError',
      )
    }
    if (!dateTo) {
      errors.dateToError = i18next.t(
        'service_desk_web_atoir:requestCreationNoDateValidationError',
      )
    }
    if (dateTo && dateFrom && dateTo < dateFrom) {
      errors.dateToDateFromError = i18next.t(
        'service_desk_web_atoir:requestCreationDatesInvertedValidationError',
      )
    }
    if (!location || !location.location || !location.location.home) {
      errors.locationError = i18next.t(
        'service_desk_web_atoir:requestCreationNoLocationValidationError',
      )
    }
    if (!responsible || responsible === '') {
      errors.responsibleError = i18next.t(
        'service_desk_web_atoir:requestCreationNoResponsibleValidationError',
      )
    }

    if (isEmpty(errors)) {
      this.props.handlePositive({
        client_organization_id: clientOrganization,
        organization_equipment_id: equipment,
        whats_broken: comment,
        ext_num: extNumber ? extNumber.trim() : null,
        reaction_date_begin: dateFrom,
        reaction_date_end: dateTo,
        location_repair_home: location.location.home,
        location_repair_street_fias_id: location.location.data.street_fias_id,
        location_repair_building_id: location.location.id,
        location_value: location.location.value,
        client_responsible_id: responsible,
        location_repair_office: cabinet,
        location_repair_floor: floor === '' ? undefined : floor,
        client_comment: additionalComment,
      })
    } else {
      this.setState({ errors })
    }
  }

  componentDidMount = () => {
    const {
      props: {
        fetchEquipmentDetails,
        fetchOrganization,
        fetchOrganizationWhoAmI,
        creationData: { equipment, clientOrganization },
      },
    } = this
    fetchOrganizationWhoAmI(clientOrganization)
    fetchOrganization(clientOrganization)
    fetchEquipmentDetails(clientOrganization, equipment)
  }

  render() {
    const {
      state: { data, errors },
      props: {
        classes,
        equipment,
        isFetchingEquipment,
        isFetchingOrganization,
        organization,
        isFetchingUserStatus,
        isFetchingOrganizationStaff,
        organizationStaff,
        userStatus,
        isFetchingMe,
        handleNegative,
        me,
        creationData: { comment, extNumber },
      },
      handlePositive,
      onChange,
    } = this
    const employees = userStatus
      ? userStatus.is_manager_or_higher
        ? _.compact(organizationStaff)
        : [me]
      : []
    return (
      <Fragment>
        <Paper elevation={2}>
          {isFetchingEquipment ||
          isFetchingOrganizationStaff ||
          isFetchingOrganization ||
          isFetchingUserStatus ||
          !organization.physical_address ? (
            <Loader />
          ) : (
            <DiagnosticAndRepairInformation
              organizationAddress={organizationAddressStringify(
                organization.physical_address,
              )}
              organizationName={organization.short_name}
              comment={comment}
              callExtNum={extNumber}
              equipment={equipment}
            />
          )}
        </Paper>
        <Paper elevation={2}>
          {isFetchingEquipment ||
          isFetchingOrganization ||
          isFetchingMe ||
          isEmpty(userStatus) ||
          !organization.physical_address ? (
            <Loader />
          ) : (
            <InformationForPerformer
              data={data}
              employees={employees}
              equipment={equipment}
              organizationId={organization.id}
              errors={errors}
              managerOrHigher={userStatus.is_manager_or_higher}
              onChangeCabinet={onChange('cabinet')}
              onChangeDateFrom={onChange('dateFrom')}
              onChangeDateTo={onChange('dateTo')}
              onChangeFloor={onChange('floor')}
              onChangeLocation={onChange('location')}
              onChangeComment={onChange('comment')}
              onChangePhone={onChange('phone')}
              onChangePosition={onChange('position')}
              onChangeResponsible={onChange('responsible')}
            />
          )}
          <div className={classes.actionsSection}>
            <div>
              <OutlinedButton
                size="medium"
                onClick={handleNegative}
                classes={{ root: classes.button }}
              >
                {i18next.t('shared_web_atoir:cancel')}
              </OutlinedButton>
            </div>
            <div>
              <ContainedButton
                size="large"
                onClick={handlePositive}
                classes={{ root: classes.button }}
                data-cy="PositiveClick"
              >
                {i18next.t('service_desk_web_atoir:createRequest')}
              </ContainedButton>
            </div>
          </div>
        </Paper>
      </Fragment>
    )
  }
}

ServiceDeskRequestDetails.propTypes = {
  handlePositive: PropTypes.func.isRequired,
  handleNegative: PropTypes.func.isRequired,
}

export default withStyles(styles)(ServiceDeskRequestDetails)
