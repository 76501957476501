import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import SettingsList from '../../Organizations/SettingsList'
import CreateEditTag from '../../Organizations/SettingsList/Tags/CreateEditTag'
import CreateEditEmailTemplate from '../../Organizations/SettingsList/EmailTemplates/CreateEditEmailTemplate'
import CreateEditScripts from '../../Organizations/SettingsList/AutomaticScripts/CreateEditScripts'
import ScriptsHistory from '../../Organizations/SettingsList/AutomaticScripts/ScriptsHistory'
import CreateEditButtons from '../../Organizations/SettingsList/ActionsButtons/CreateEditButtons'
import ButtonsHistory from '../../Organizations/SettingsList/ActionsButtons/ButtonsHistory'

const tagsRouter = ({ match: { path: __path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${__path}/:tagId`}
        title={i18next.t('organizations_web_atoir:tagEditing')}
        component={CreateEditTag}
      />
      <WrapperRoute
        path={`${__path}`}
        title={i18next.t('organizations_web_atoir:tagCreation')}
        component={CreateEditTag}
      />
    </Switch>
  </Fragment>
)

const templatesRouter = ({ match: { path: __path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${__path}/:templateId`}
        title={i18next.t('organizations_web_atoir:emailTemplateEditing')}
        component={CreateEditEmailTemplate}
      />
      <WrapperRoute
        path={`${__path}`}
        title={i18next.t('organizations_web_atoir:emailTemplateCreation')}
        component={CreateEditEmailTemplate}
      />
    </Switch>
  </Fragment>
)

const buttonsRouter = ({ match: { path: __path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${__path}/:createEditButton/:buttonId`}
        title="Редактирование кнопки"
        component={CreateEditButtons}
      />
      <WrapperRoute
        path={`${__path}/history`}
        title={i18next.t('organizations_web_atoir:orgSettingsButtonsChangeLog')}
        component={ButtonsHistory}
      />
      <WrapperRoute
        path={`${__path}/:createEditButton`}
        title="Создание кнопки"
        component={CreateEditButtons}
      />
    </Switch>
  </Fragment>
)

const OrganizationSettingsRouter = ({ match: { path: _path } }) => {
  return (
    <Fragment>
      <Switch>
        <WrapperRoute
          path={`${_path}/tags/:createEditTag`}
          component={tagsRouter}
        />
        <WrapperRoute
          path={`${_path}/templates/:createEditTemplate`}
          component={templatesRouter}
        />
        <WrapperRoute
          path={`${_path}/scripts`}
          component={({ match: { path: __path } }) => (
            <Fragment>
              <Switch>
                <WrapperRoute
                  path={`${__path}/:createEditScript/:scriptId`}
                  title="Редактирование сценария"
                  component={CreateEditScripts}
                />
                <WrapperRoute
                  path={`${__path}/history`}
                  title={i18next.t(
                    'organizations_web_atoir:orgSettingsScriptsChangeLog',
                  )}
                  component={ScriptsHistory}
                />
                <WrapperRoute
                  path={`${__path}/:createEditScript`}
                  title="Создание сценария"
                  component={CreateEditScripts}
                />
              </Switch>
            </Fragment>
          )}
        />
        <WrapperRoute path={`${_path}/buttons`} component={buttonsRouter} />
        <WrapperRoute
          path={`${_path}`}
          title={i18next.t('organizations_web_atoir:organizationSettings')}
          component={SettingsList}
        />
        <Route component={NoMatchComponent} />
      </Switch>
    </Fragment>
  )
}

export default OrganizationSettingsRouter
