import React, { Component } from 'react'
import i18next from '../../i18n/i18n'
import PropTypes from 'prop-types'

// ---material---
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles/index'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

class CostInput extends Component {
  handleInputChange = event => {
    const {
      props: { handleChange },
    } = this
    if (
      event.target.value.length > 14 ||
      (event.target.value &&
        event.target.value[event.target.value.length - 1] === '.')
    ) {
      return
    }
    const numberValue = parseInt(event.target.value, 10)
    if (event.target.value === '') {
      handleChange(event.target.name, null)
      return
    }
    if (Number.isNaN(numberValue) || numberValue < 0) {
      return
    }
    handleChange(event.target.name, numberValue)
  }

  render() {
    const {
      handleInputChange,
      props: { cost, isCostValid, classes, requiredValue, name },
    } = this
    const costValue = cost === null ? '' : String(cost)
    return (
      <FormControl
        required={requiredValue}
        className={classes.textField}
        error={requiredValue && !isCostValid}
        fullWidth
      >
        <InputLabel htmlFor="cost" shrink>
          {i18next.t('inventory_web_atoir:enterCost')}
        </InputLabel>
        <Input
          // type="number"
          name={name}
          id="cost"
          value={costValue}
          onChange={handleInputChange}
          defaultValue={costValue}
        />
        <FormHelperText id="error-text">
          {requiredValue && !isCostValid
            ? i18next.t('inventory_web_atoir:enterCost')
            : ''}
        </FormHelperText>
      </FormControl>
    )
  }
}

CostInput.defaultProps = {
  requiredValue: false,
  isCostValid: false,
  cost: null,
}

CostInput.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  cost: PropTypes.number,
  requiredValue: PropTypes.bool,
  isCostValid: PropTypes.bool,
}

export default withStyles(styles)(CostInput)
