import React from 'react'
import i18next from '../../../../i18n/i18n'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import FormDialog from '../../../../widgets/FormDialog'

const reasons = [
  'Уборка снега',
  'Мелкий ремонт',
  'Сантехнические работы',
  'Работы по электричеству',
  'Клининг',
  'Другое',
]

const ReasonDictionaryDialog = ({ open, handleClose, reason, onChange }) => {
  const [value, setValue] = React.useState()

  React.useEffect(() => {
    if (open) {
      setValue(reasons.find(reasonItem => reasonItem === reason))
    }
  }, [open])

  function onChangeHandler({ target: { value: _value } }) {
    setValue(_value)
  }

  function onSubmit() {
    onChange(value)
    handleClose()
  }

  return (
    <div>
      <FormDialog
        positiveDisabled={!value}
        closeIconHidden
        open={open}
        title={i18next.t('inner_calls_web_atoir:workTypeSelection')}
        onClose={handleClose}
        onExit={handleClose}
        onSubmit={onSubmit}
        submitButtonTitle={i18next.t('inner_calls_web_atoir:doChose')}
        secondaryButtonsArray={[
          {
            secondaryText: i18next.t('shared_web_atoir:cancel'),
            handleSecondaryAction: handleClose,
          },
        ]}
      >
        {open && (
          <Box p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {i18next.t('inner_calls_web_atoir:workType')}
              </FormLabel>
              <RadioGroup
                aria-label="work type"
                name="work_type"
                value={value}
                onChange={onChangeHandler}
              >
                {reasons.map(reasonItem => (
                  <FormControlLabel
                    key={reasonItem}
                    value={reasonItem}
                    control={<Radio />}
                    label={reasonItem}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </FormDialog>
    </div>
  )
}

export default ReasonDictionaryDialog
