/* eslint-disable import/no-duplicates */
import {
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  FETCH_USER_ORGANIZATIONS_REQUEST,
  FETCH_USER_ORGANIZATIONS_SUCCESS,
  FETCH_USER_ORGANIZATIONS_FAILURE,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_SUCCESS,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_FAILURE,
  FETCH_NOT_USER_ORGANIZATIONS_REQUEST,
  FETCH_NOT_USER_ORGANIZATIONS_SUCCESS,
  FETCH_NOT_USER_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_PERMISSIONS_FAILURE,
  FETCH_ORGANIZATION_PERMISSIONS_REQUEST,
  FETCH_ORGANIZATION_PERMISSIONS_SUCCESS,
  FETCH_ALL_ORGANIZATIONS_REQUEST,
  FETCH_ALL_ORGANIZATIONS_SUCCESS,
  FETCH_ALL_ORGANIZATIONS_FAILURE,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_SUCCESS,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_FAILURE,
  FETCH_SERVICE_ORGANIZATIONS_REQUEST,
  FETCH_SERVICE_ORGANIZATIONS_SUCCESS,
  FETCH_SERVICE_ORGANIZATIONS_FAILURE,
  FETCH_ADMIN_ORGANIZATIONS_REQUEST,
  FETCH_ADMIN_ORGANIZATIONS_SUCCESS,
  FETCH_ADMIN_ORGANIZATIONS_FAILURE,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST,
  FETCH_ORGANIZATIONS_MEMBER_OF_SUCCESS,
  FETCH_ORGANIZATIONS_MEMBER_OF_FAILURE,
  FETCH_ORGANIZATION_WHO_AM_I_REQUEST,
  FETCH_ORGANIZATION_WHO_AM_I_SUCCESS,
  FETCH_ORGANIZATION_WHO_AM_I_FAILURE,
  FETCH_ORGANIZATION_USER_ME_REQUEST,
  FETCH_ORGANIZATION_USER_ME_SUCCESS,
  FETCH_ORGANIZATION_USER_ME_FAILURE,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_SUCCESS,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_FAILURE,
  FETCH_USER_ORGANIZATIONS_COUNT_SUCCESS,
  FETCH_USER_ORGANIZATIONS_COUNT_FAILURE,
  FETCH_USER_ORGANIZATIONS_COUNT_REQUEST,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE,
  SET_ORGANIZATIONS_LIST_ROWS_PER_PAGE,
  SET_ORGANIZATIONS_EMPLOYERS_LIST_ROWS_PER_PAGE,
  CREATE_SD_INVOICE_FOR_ORGANIZATION,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_SUCCESS,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_FAILURE,
} from '../constants/actionTypes'

import * as constants from '../constants/actionTypes'

import { actionCreatorTyped } from './actionCreator'

export const createOrganization = (data, contacts) => ({
  type: CREATE_ORGANIZATION_REQUEST,
  data,
  contacts,
})

export const createOrganizationSuccess = data => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  data,
})

export const createOrganizationFailure = errors => ({
  type: CREATE_ORGANIZATION_FAILURE,
  errors,
})

export const updateOrganization = (id, data) => ({
  type: UPDATE_ORGANIZATION_REQUEST,
  id,
  data,
})

export const updateOrganizationSuccess = () => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
})

export const updateOrganizationFailure = errors => ({
  type: UPDATE_ORGANIZATION_FAILURE,
  errors,
})

export const fetchUserOrganizations = (filters, query) => ({
  type: FETCH_USER_ORGANIZATIONS_REQUEST,
  query,
  filters,
})

export const fetchUserOrganizationsSuccess = data => ({
  type: FETCH_USER_ORGANIZATIONS_SUCCESS,
  data,
})

export const fetchUserOrganizationsFailure = errors => ({
  type: FETCH_USER_ORGANIZATIONS_FAILURE,
  errors,
})

export const fetchUserOrganizationsCanAddEquipment = () => ({
  type: FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST,
})

export const fetchUserOrganizationsCanAddEquipmentSuccess = payload => ({
  type: FETCH_USER_ORGANIZATIONS_CAN_ADD_SUCCESS,
  payload,
})

export const fetchUserOrganizationsCanAddEquipmentFailure = payload => ({
  type: FETCH_USER_ORGANIZATIONS_CAN_ADD_FAILURE,
  payload,
})

export const fetchUserOrganizationsCount = filters => ({
  type: FETCH_USER_ORGANIZATIONS_COUNT_REQUEST,
  filters,
})

export const fetchUserOrganizationsCountSuccess = count => ({
  type: FETCH_USER_ORGANIZATIONS_COUNT_SUCCESS,
  count,
})

export const fetchUserOrganizationsCountFailure = errors => ({
  type: FETCH_USER_ORGANIZATIONS_COUNT_FAILURE,
  errors,
})

export const fetchOrganizationsMemberOf = filters => ({
  type: FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST,
  filters,
})

export const fetchOrganizationsMemberOfSuccess = payload => ({
  type: FETCH_ORGANIZATIONS_MEMBER_OF_SUCCESS,
  payload,
})

export const fetchOrganizationsMemberOfFailure = error => ({
  type: FETCH_ORGANIZATIONS_MEMBER_OF_FAILURE,
  error,
})

export const fetchNotUserOrganizations = name => ({
  type: FETCH_NOT_USER_ORGANIZATIONS_REQUEST,
  name,
})

export const fetchNotUserOrganizationsSuccess = data => ({
  type: FETCH_NOT_USER_ORGANIZATIONS_SUCCESS,
  data,
})

export const fetchNotUserOrganizationsFailure = errors => ({
  type: FETCH_NOT_USER_ORGANIZATIONS_FAILURE,
  errors,
})

export const fetchOrganization = id => ({
  type: FETCH_ORGANIZATION_REQUEST,
  id,
})

export const fetchOrganizationSuccess = data => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  data,
})

export const fetchOrganizationFailure = errors => ({
  type: FETCH_ORGANIZATION_FAILURE,
  errors,
})

export const fetchOrganizationWhoAmI = id => ({
  type: FETCH_ORGANIZATION_WHO_AM_I_REQUEST,
  id,
})

export const fetchOrganizationWhoAmISuccess = data => ({
  type: FETCH_ORGANIZATION_WHO_AM_I_SUCCESS,
  data,
})

export const fetchOrganizationWhoAmIFailure = error => ({
  type: FETCH_ORGANIZATION_WHO_AM_I_FAILURE,
  error,
})

export const fetchOrganizationUserMe = id => ({
  type: FETCH_ORGANIZATION_USER_ME_REQUEST,
  id,
})

export const fetchOrganizationUserMeSuccess = data => ({
  type: FETCH_ORGANIZATION_USER_ME_SUCCESS,
  data,
})

export const fetchOrganizationUserMeFailure = error => ({
  type: FETCH_ORGANIZATION_USER_ME_FAILURE,
  error,
})

export const fetchAdminOrganizations = data => ({
  type: FETCH_ADMIN_ORGANIZATIONS_REQUEST,
  data,
})

export const fetchAdminOrganizationsSuccess = data => ({
  type: FETCH_ADMIN_ORGANIZATIONS_SUCCESS,
  data,
})

export const fetchAdminOrganizationsFailure = error => ({
  type: FETCH_ADMIN_ORGANIZATIONS_FAILURE,
  error,
})

export const fetchHasManagerOrHigherOrganizations = () => ({
  type: FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
})

export const fetchHasManagerOrHigherOrganizationsSuccess = payload => ({
  type: FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS,
  payload,
})

export const fetchHasManagerOrHigherOrganizationsFailure = () => ({
  type: FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE,
})

export const fetchManagerOrHigherOrganizations = () => ({
  type: FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
})

export const fetchManagerOrHigherOrganizationsSuccess = data => ({
  type: FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS,
  data,
})

export const fetchManagerOrHigherOrganizationsFailure = error => ({
  type: FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE,
  error,
})

export const fetchOrganizationPermissions = id => ({
  type: FETCH_ORGANIZATION_PERMISSIONS_REQUEST,
  id,
})

export const fetchOrganizationPermissionsSuccess = data => ({
  type: FETCH_ORGANIZATION_PERMISSIONS_SUCCESS,
  data,
})

export const fetchOrganizationPermissionsFailure = errors => ({
  type: FETCH_ORGANIZATION_PERMISSIONS_FAILURE,
  errors,
})

export const fetchAllOrganizations = name => ({
  type: FETCH_ALL_ORGANIZATIONS_REQUEST,
  name,
})

export const fetchAllOrganizationsSuccess = organizations => ({
  type: FETCH_ALL_ORGANIZATIONS_SUCCESS,
  organizations,
})

export const fetchAllOrganizationsFailure = errors => ({
  type: FETCH_ALL_ORGANIZATIONS_FAILURE,
  errors,
})

export const fetchCitiesWithServiceOrganizations = payload => ({
  type: FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST,
  payload,
})

export const fetchCitiesWithServiceOrganizationsSuccess = cities => ({
  type: FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_SUCCESS,
  cities,
})

export const fetchCitiesWithServiceOrganizationsFailure = errors => ({
  type: FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_FAILURE,
  errors,
})

export const fetchServiceOrganizations = filters => ({
  type: FETCH_SERVICE_ORGANIZATIONS_REQUEST,
  filters,
})

export const fetchServiceOrganizationsSuccess = serviceOrganizations => ({
  type: FETCH_SERVICE_ORGANIZATIONS_SUCCESS,
  serviceOrganizations,
})

export const fetchServiceOrganizationsFailure = errors => ({
  type: FETCH_SERVICE_ORGANIZATIONS_FAILURE,
  errors,
})

export const fetchServiceOrganizationsCount = filters => ({
  type: FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST,
  filters,
})

export const fetchServiceOrganizationsCountSuccess = count => ({
  type: FETCH_SERVICE_ORGANIZATIONS_COUNT_SUCCESS,
  count,
})

export const fetchServiceOrganizationsCountFailure = errors => ({
  type: FETCH_SERVICE_ORGANIZATIONS_COUNT_FAILURE,
  errors,
})

export const setOrganizationsListRowsPerPage = data => ({
  type: SET_ORGANIZATIONS_LIST_ROWS_PER_PAGE,
  data,
})

export const setOrganizationsEmployersListRowsPerPage = data => ({
  type: SET_ORGANIZATIONS_EMPLOYERS_LIST_ROWS_PER_PAGE,
  data,
})

export const fetchUserOrganizationFunctionalsRequest = errors => ({
  type: FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST,
  errors,
})

export const fetchUserOrganizationFunctionalsSuccess = data => ({
  type: FETCH_USER_ORGANIZATION_FUNCTIONALS_SUCCESS,
  data,
})

export const fetchUserOrganizationFunctionalsFailure = data => ({
  type: FETCH_USER_ORGANIZATION_FUNCTIONALS_FAILURE,
  data,
})

export const createSDInvoiceForOrganization = data => ({
  type: CREATE_SD_INVOICE_FOR_ORGANIZATION.REQUEST,
  data,
})

export const createSDInvoiceForOrganizationSuccess = data => ({
  type: CREATE_SD_INVOICE_FOR_ORGANIZATION.SUCCESS,
  data,
})

export const createSDInvoiceForOrganizationFailure = data => ({
  type: CREATE_SD_INVOICE_FOR_ORGANIZATION.FAILURE,
  data,
})

export const getOrganizationSettings = actionCreatorTyped(
  constants.GET_ORGANIZATION_SETTINGS,
)

export const getOrganizationContragents = actionCreatorTyped(
  constants.GET_ORGANIZATION_CONTRAGENTS,
)

export const editOrganizationContragent = actionCreatorTyped(
  constants.EDIT_ORGANIZATION_CONTRAGENT,
)

export const editRestrictionSdCallsFromNonContragents = actionCreatorTyped(
  constants.EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS,
)

export const createOrganizationContragent = actionCreatorTyped(
  constants.CREATE_ORGANIZATION_CONTRAGENT,
)
export const deleteOrganizationContragent = actionCreatorTyped(
  constants.DELETE_ORGANIZATION_CONTRAGENT,
)
