import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditPerformerInfoDialog from './EditPerformerInfoDialog'
import {
  fetchOrganizationUserMe,
  fetchOrganizationWhoAmI,
  fetchOrganization,
  fetchOrganizationsMemberOf,
} from '../../../../../actions/organizationsActions'
import { fetchOrganizationStaff } from '../../../../../actions/staffActions'
import { fetchEquipmentDetails } from '../../../../../actions/equipmentActions'

const mapStateToProps = state => ({
  userStatus: state.organizations.userStatus.status,
  isFetchingUserStatus: state.organizations.userStatus.isFetching,
  organizationStaff: state.staff.organizationStaff,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  isFetchingMe: state.organizations.me.isFetching,
  me: state.organizations.me.me,
  creationData: state.serviceDesk.creation.data,
  isFetchingEquipment:
    state.equipment.equipmentDetails.isFetchingEquipmentDetails,
  equipmentError: state.equipment.equipmentDetails.error,
  equipment: state.equipment.equipmentDetails.equipment,
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
  memberships: state.organizations.memberOf.member,
  isFetchingMemberOrganizations: state.organizations.memberOf.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationWhoAmI,
      fetchOrganizationStaff,
      fetchOrganizationUserMe,
      fetchEquipmentDetails,
      fetchOrganizationsMemberOf,
      fetchOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPerformerInfoDialog),
)
