import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AgreementCreation from './AgreementCreation'
import {
  createAgreementRequest,
  fetchContractPermissionsRequest,
  fetchContractRequest,
} from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isFetching: state.serviceDeskContracts.createAgreement.isFetching,
  error: state.serviceDeskContracts.createAgreement.error,
  createdAgreementId:
    state.serviceDeskContracts.createAgreement.createdAgreementId,
  contract: state.serviceDeskContracts.contract.contract,
  isFetchingContract: state.serviceDeskContracts.contract.isFetching,
  errorContract: state.serviceDeskContracts.contract.error,
  permissions: state.serviceDeskContracts.permissions.permissions,
  isFetchingPermissions: state.serviceDeskContracts.permissions.isFetching,
  permissionsError: state.serviceDeskContracts.permissions.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAgreementRequest,
      fetchContractPermissionsRequest,
      fetchContractRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementCreation),
)
