import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REFRESH_TOKENS_REQUEST,
  REFRESH_TOKENS_SUCCESS,
  REFRESH_TOKENS_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from '../constants/actionTypes'

export const login = (email, password) => ({
  type: LOGIN_REQUEST,
  email,
  password,
})

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
})

export const loginFailure = errors => ({
  type: LOGIN_FAILURE,
  errors,
})

export const refreshTokens = () => ({
  type: REFRESH_TOKENS_REQUEST,
})

export const refreshTokensSuccess = () => ({
  type: REFRESH_TOKENS_SUCCESS,
})

export const refreshTokensFailure = errors => ({
  type: REFRESH_TOKENS_FAILURE,
  errors,
})

export const logout = noRequest => ({
  type: LOGOUT,
  noRequest,
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = () => ({
  type: LOGOUT_FAILURE,
})
