import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// internationalization

import i18next from '../i18n/i18n'
// styles

import '../styles/components/Register.css'
// icons

import notFound from '../styles/images/not_found.svg'
// material-ui

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    textAlign: 'center',
  }),
})

const NoMatchComponent = ({ classes }) => (
  <div className={classes.root}>
    <img src={notFound} className="App-not-found-img" alt="Oops!" />
    <br />
    <Typography variant="h6">
      {i18next.t('error_web_atoir:pageNotFound')}
    </Typography>
  </div>
)

export default withStyles(styles)(NoMatchComponent)
