import {
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_FAILURE,
  FETCH_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_SUCCESS,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_FAILURE,
  FETCH_DEPARTMENTS_COUNT_REQUEST,
  FETCH_DEPARTMENTS_COUNT_SUCCESS,
  FETCH_DEPARTMENTS_COUNT_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  isCreatingDepartment: false,
  isUpdatingDepartment: false,
  isFetchingDepartments: false,
  isFetchingDepartmentsCount: false,
  isFetchingDepartment: false,
  addDepartmentOpen: false,
  updateDepartmentOpen: false,
  errors: '',
  count: 0,
  departments: [],
  department: {},
}

const departmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        errors: '',
        isCreatingDepartment: true,
      }

    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        errors: '',
        isCreatingDepartment: false,
        addDepartmentOpen: false,
      }

    case CREATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: action.code,
        isCreatingDepartment: false,
        addDepartmentOpen: true,
      }

    case UPDATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        errors: '',
        isUpdatingDepartment: true,
      }

    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        department: action.data,
        isUpdatingDepartment: false,
      }

    case UPDATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isUpdatingDepartment: false,
      }

    case FETCH_DEPARTMENT_REQUEST:
      return {
        ...state,
        errors: '',
        isFetchingDepartment: true,
      }

    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        department: action.data,
        isFetchingDepartment: false,
      }

    case FETCH_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingDepartment: false,
      }

    case FETCH_DEPARTMENTS_REQUEST:
      return {
        ...state,
        errors: '',
        isFetchingDepartments: true,
      }

    case FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.data,
        isFetchingDepartments: false,
      }

    case FETCH_DEPARTMENTS_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingDepartments: false,
      }

    case FETCH_DEPARTMENTS_COUNT_REQUEST:
      return {
        ...state,
        errors: '',
        isFetchingDepartmentsCount: true,
      }

    case FETCH_DEPARTMENTS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.count,
        isFetchingDepartmentsCount: false,
      }

    case FETCH_DEPARTMENTS_COUNT_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingDepartmentsCount: false,
      }

    case DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        errors: '',
        isDeletingDepartment: true,
      }

    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isDeletingDepartment: false,
      }

    case DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isDeletingDepartment: false,
      }

    case FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST:
      return {
        ...state,
        errors: null,
        isFetchingDepartments: true,
      }

    case FETCH_USER_EQUIPMENTS_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.data,
        isFetchingDepartments: false,
      }

    case FETCH_USER_EQUIPMENTS_DEPARTMENTS_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingDepartments: false,
      }

    default:
      return state
  }
}

export default departmentsReducer
