import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
import * as constants from '../constants/actionTypes/settings'
import * as actions from '../actions/settingsActions'
import * as api from './api'

export function* watchGetEngineerSettingsSaga() {
  yield takeLatest(
    constants.GET_ENGINEER_SETTINGS_REQUEST,
    getEngineerSettingsRequest,
  )
}

export function* watchGetCommonOrganizationSettingsSaga() {
  yield takeLatest(
    constants.GET_COMMON_ORGANIZATION_SETTINGS_REQUEST,
    getCommonOrganizationSettingsRequest,
  )
}

function* getEngineerSettingsRequest(action) {
  try {
    const result = yield call(api.getEngineerSettingsApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.getEngineerSettingsSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.getEngineerSettingsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* getCommonOrganizationSettingsRequest(action) {
  try {
    const result = yield call(
      api.getCommonOrganizationSettingsApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.getCommonOrganizationSettingsSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.getCommonOrganizationSettingsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
