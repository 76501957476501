import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import _ from 'lodash'
import { popLastSections } from '../../../../../helpers/dataHelper'
import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DoneIcon from '@material-ui/icons/Done'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

// components
import BreadCrumb from '../../../../App/routing/BreadCrumb'
import Tag from '../../../../../widgets/Tag'
import ColorPalette from '../../../../../widgets/ColorPalette'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import ScriptCard from '../../ScriptCard'
import TagScope from './TagScope'
import tagScope from '../../../../../constants/tagScope'
import config from '../../../../../config'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  tag: {
    marginLeft: 0,
  },
  menuItem: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  inputLabel: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    '&:focused': {
      transitionDuration: '0.2s',
      color: 'rgb(17, 82, 147)',
    },
    '&:error': {
      fontSize: 16,
      color: 'rgb(244, 67, 54)',
    },
  },
  helperTextWarning: {
    color: theme.palette.error.main,
  },
  helperText: {
    textDecoration: 'none',
    textAlign: 'right',
  },
  paddingName: {
    paddingRight: theme.spacing(2),
  },
})

class CreateEditTag extends Component {
  state = {
    edit: false,
    name: '',
    nameHelperText: '',
    color: '',
    colorHelperText: '',
    scope: [],
  }

  componentDidMount = () => {
    const {
      props: { match, getTagRequest, fetchOrganization },
    } = this
    if (match.params.createEditTag === 'edit') {
      this.setState({ edit: true })
      getTagRequest({
        organizationId: match.params.organizationId,
        tagId: match.params.tagId,
      })
    }
    fetchOrganization(match.params.organizationId)
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        classes,
        history,
        match,
        isCreatingTag,
        createTagError,
        isEditingTag,
        editTagError,
        isFetchingTag,
        tag,
        setNavigationDrawer,
      },
      state: { edit },
    } = this
    if (
      (prevProps.isCreatingTag !== isCreatingTag &&
        !isCreatingTag &&
        isEmpty(createTagError)) ||
      (prevProps.isEditingTag !== isEditingTag &&
        !isEditingTag &&
        isEmpty(editTagError))
    ) {
      history.goBack()
    }
    if (
      prevProps.isFetchingTag !== isFetchingTag &&
      !isFetchingTag &&
      !isEmpty(tag)
    ) {
      this.setState({
        name: tag.name,
        color: `#${tag.color}`,
        scope: tag.scope,
      })
      setNavigationDrawer([
        <Fragment>
          <List className={classes.list}>
            {tag.scripts.map(script => (
              <ListItem className={classes.listItem}>
                <ScriptCard
                  script={script}
                  history={history}
                  url={
                    edit
                      ? popLastSections(match.url, 3)
                      : popLastSections(match.url, 2)
                  }
                  boundTo="Tag"
                />
              </ListItem>
            ))}
          </List>
        </Fragment>,
      ])
    }
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  handleTextChange = field => event => {
    const { value } = event.target
    this.setState(() => ({
      [`${field}HelperText`]: '',
      [field]: value,
    }))
  }

  handleSelect = field => event => {
    const { value } = event.target
    this.setState(state => {
      if (_.includes(state[field], value)) {
        return { [field]: _.filter(state[field], el => el !== value) }
      }
      return { [field]: [...state[field], value] }
    })
  }

  onColorSelect = color => {
    this.setState({ color, colorHelperText: '' })
  }

  createEditTag = () => {
    const {
      props: {
        match: {
          params: { organizationId, tagId },
        },
        createTagRequest,
        editTagRequest,
      },
      state: { edit, name, color, scope },
      checkEmpty,
    } = this

    const valName = checkEmpty('name', name.trim())
    const valColor = checkEmpty('color', color)
    if (!(valName && valColor)) {
      return
    }
    const data = {
      name: name.trim(),
      color: color.substr(1),
      scope,
    }
    if (edit) {
      const payload = { organizationId, tagId, data }
      editTagRequest(payload)
    } else {
      const payload = { organizationId, data }
      createTagRequest(payload)
    }
  }

  checkEmpty = (field, text) => {
    if (text.length === 0) {
      if (field === 'color') {
        this.setState({
          [`${field}HelperText`]: i18next.t(
            'organizations_web_atoir:selectTagColor',
          ),
        })
      } else {
        this.setState({
          [`${field}HelperText`]: i18next.t(
            'organizations_web_atoir:fieldShouldBeFilled',
          ),
        })
      }
      return false
    }
    return true
  }

  render() {
    const {
      props: { classes, history, match, organization },
      state: { edit, scope, name, nameHelperText, color, colorHelperText },
      createEditTag,
      onColorSelect,
    } = this
    return (
      <Fragment>
        <BreadCrumb
          length={29}
          to={`${
            edit ? popLastSections(match.url, 4) : popLastSections(match.url, 3)
          }/profile`}
        >
          {organization.short_name}
        </BreadCrumb>
        <BreadCrumb
          to={
            edit ? popLastSections(match.url, 3) : popLastSections(match.url, 2)
          }
        >
          {i18next.t('organizations_web_atoir:organizationSettings')}
        </BreadCrumb>
        {edit ? (
          <BreadCrumb to={match.url}>{name}</BreadCrumb>
        ) : (
          <BreadCrumb to={match.url}>
            {i18next.t('organizations_web_atoir:tagCreation')}
          </BreadCrumb>
        )}
        <Typography variant="h4" gutterBottom className={classes.marginBtm}>
          {i18next.t(
            `organizations_web_atoir:${edit ? 'tagEditing' : 'tagCreation'}`,
          )}
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Tag
                  color={color}
                  name={
                    name.length === 0
                      ? i18next.t('organizations_web_atoir:tagNameLabel')
                      : name
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.paddingName}>
                <TextField
                  required
                  id="tag-name"
                  label={i18next.t(
                    'organizations_web_atoir:tagNameTextFieldLabel',
                  )}
                  margin="normal"
                  onChange={this.handleTextChange('name')}
                  value={name}
                  autoFocus
                  inputProps={{ maxLength: 255 }}
                  fullWidth
                  multiline
                  helperText={
                    <Grid
                      container
                      direction="row"
                      alignContent="space-between"
                    >
                      <Grid item xs={11}>
                        {nameHelperText ? (
                          <FormHelperText
                            id="input-tag-helper-text"
                            className={classes.helperTextWarning}
                          >
                            {nameHelperText}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={1}>
                        <FormHelperText
                          id="input-tag-helper-text-length"
                          className={classes.helperText}
                        >
                          {`${name.length}/${config.orgSettingNameLength}`}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  }
                  error={!isEmpty(nameHelperText)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid container direction="column">
              <Typography variant="subtitle1">
                {i18next.t('organizations_web_atoir:whoCanSeeTag')}
              </Typography>
              <TextField
                id="tag-scope"
                select
                multiple
                margin="normal"
                fullWidth
                onChange={this.handleSelect('scope')}
                value={scope}
                SelectProps={{
                  renderValue: selectedScope => (
                    <TagScope scope={selectedScope} />
                  ),
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
              >
                {tagScope.map(role => (
                  <MenuItem
                    key={role.value}
                    value={role.value}
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      {_.includes(scope, role.value) ? <DoneIcon /> : null}
                    </ListItemIcon>
                    <ListItemText inset primary={i18next.t(role.label)} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid container direction="row">
                  <InputLabel
                    required
                    htmlFor="color-tag-select"
                    error={!isEmpty(colorHelperText)}
                    className={classes.inputLabel}
                  >
                    {i18next.t('organizations_web_atoir:tagColor')}
                  </InputLabel>
                  <ColorPalette
                    id="color-tag-select"
                    selectedColor={color}
                    onColorSelect={onColorSelect}
                  />
                </Grid>
              </Grid>
              <Grid item>
                {colorHelperText ? (
                  <FormHelperText
                    id="color-helper-text"
                    className={classes.helperText}
                  >
                    {colorHelperText}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="space-between" xs={12}>
              <Grid item alignContent="flex-start">
                <OutlinedButton onClick={() => history.goBack()}>
                  {i18next.t('shared_web_atoir:cancel')}
                </OutlinedButton>
              </Grid>
              <Grid item alignContent="flex-end">
                <ContainedButton onClick={() => createEditTag()}>
                  {i18next.t('shared_web_atoir:save')}
                </ContainedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

CreateEditTag.defaultProps = {
  createTagError: null,
  editTagError: null,
}

CreateEditTag.propTypes = {
  setNavigationDrawer: PropTypes.func.isRequired,
  clearNavigationDrawer: PropTypes.func.isRequired,
  createTagRequest: PropTypes.func.isRequired,
  isCreatingTag: PropTypes.bool.isRequired,
  createTagError: PropTypes.object,
  editTagRequest: PropTypes.func.isRequired,
  isEditingTag: PropTypes.func.isRequired,
  editTagError: PropTypes.object,
  getTagRequest: PropTypes.func.isRequired,
  isFetchingTag: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
}

export default withStyles(styles)(CreateEditTag)
