import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper/Paper'
import Table from '@material-ui/core/Table/Table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

// helpers
import { getUserFullName } from '../../../../helpers/userHelper'
import { convertTimestamp } from '../../../../helpers/dateHelper'
import { canViewContractInfo } from '../../../../helpers/ContractPermissionsResolver'
import { popLastSections } from '../../../../helpers/dataHelper'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import DelayedLoader from '../../../../widgets/DelayedLoader'

const toolbarStyles = theme => ({
  toolbarRoot: {},
  toolbarText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
})

let EnhancedTableToolbar = props => (
  <div className={props.classes.toolbarRoot}>
    <div className={props.classes.toolbarText}>
      {props.isFetchingContract || !props.agreement ? (
        <DelayedLoader delay={0} show delayOut={300} />
      ) : (
        <Typography align="left" variant="h5" component="span">
          {i18next.t('service_desk_web_atoir:agreementNameTitle', {
            name: props.agreement.name,
            type: i18next
              .t(
                `service_desk_web_atoir:${props.agreement.agreement_type}AgreementType`,
              )
              .toLowerCase(),
          })}
        </Typography>
      )}
      {props.contract && (
        <Typography align="left" variant="h6" component="span">
          {i18next.t(
            `service_desk_web_atoir:${props.agreement.status}AgreementStatus`,
          )}
        </Typography>
      )}
    </div>
    <Divider />
  </div>
)

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const headerStyles = () => ({
  headerCell: {
    border: 0,
  },
})

let EnhancedTableHead = props => (
  <TableHead>
    <TableRow>
      <TableCell className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:changedWhen')}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:changedWho')}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:changedWhat')}
      </TableCell>
    </TableRow>
  </TableHead>
)

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class AgreementHistory extends Component {
  state = {
    page: 0,
    rowsPerPage: 50,
  }

  componentDidMount = () => {
    this.handleFetchHistory()
    this.handleFetchHistoryCount()
    this.props.fetchContractPermissionsRequest(
      this.props.match.params.contractId,
    )
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetchingPermissions !== this.props.isFetchingPermissions &&
      !this.props.isFetchingPermissions &&
      !this.props.permissionsError
    ) {
      if (canViewContractInfo(this.props.permissions)) {
        this.props.fetchAgreementRequest(this.props.match.params)
      } else {
        this.props.history.push('/404')
      }
    }
  }

  handleFetchHistoryCount = () => {
    const {
      fetchAgreementLogsCountRequest,
      match: {
        params: { contractId, agreementId },
      },
    } = this.props
    fetchAgreementLogsCountRequest({ contractId, agreementId })
  }

  handleFetchHistory = () => {
    const {
      fetchAgreementLogsRequest,
      match: {
        params: { contractId, agreementId },
      },
    } = this.props
    const { page, rowsPerPage } = this.state
    fetchAgreementLogsRequest({
      contractId,
      agreementId,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.handleFetchHistory)
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, this.handleFetchHistory)
  }

  render() {
    const {
      props: {
        classes,
        isFetchingHistory,
        isFetchingProject,
        isFetchingHistoryCount,
        logs,
        count,
        date,
        agreement,
        isFetchingContract,
        status,
        match: { url },
      },
      state: { page, rowsPerPage },
    } = this
    return (
      <Paper className={classes.root}>
        {agreement && (
          <Fragment>
            <BreadCrumb to={popLastSections(url, 3)}>
              {i18next.t('service_desk_web_atoir:contractProfileTitle', {
                number: agreement.contract.num_contract,
                from: `${agreement.contract.month_begin}.${agreement.contract.year_begin}`,
                to: `${agreement.contract.month_end}.${agreement.contract.year_end}`,
              })}
            </BreadCrumb>
            <BreadCrumb to={popLastSections(url)}>{agreement.name}</BreadCrumb>
            <BreadCrumb to={url}>
              {i18next.t('service_desk_web_atoir:historyOfChange')}
            </BreadCrumb>
          </Fragment>
        )}
        <EnhancedTableToolbar
          onDate={date}
          projectStatus={status}
          isFetchingProject={isFetchingProject}
          agreement={agreement}
          isFetchingContract={isFetchingContract}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            {isFetchingHistory || isFetchingHistoryCount ? (
              <DelayedLoader show delay={300} />
            ) : (
              <TableBody>
                {logs &&
                  logs.map((h, i) => (
                    <TableRow key={h._id} selected={!!(i % 2)}>
                      <TableCell>{convertTimestamp(h.date)}</TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {getUserFullName(h.actor_organization_user)}
                        </Typography>
                        <Typography color="default" variant="caption">
                          {h.organization.short_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{h.comment}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </div>
        <TablePagination
          component="div"
          rowsPerPageOptions={[25, 50, 100]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
          labelDisplayedRows={({
            from: fromPage,
            to: toPage,
            count: countPage,
          }) =>
            i18next.t('shared_web_atoir:labelDisplayedRows', {
              fromPage,
              toPage,
              countPage,
            })
          }
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(AgreementHistory)
