import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { downloadFileRequest } from '../../../../actions/appActions'

import ReportDownloadContainer from './ReportDownloadContainer'

const mapStateToProps = state => ({
  filesIsDownloading: state.app.downloadFile.isFetching,
  filesDownloadingQueue: state.app.downloadFile.files,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      downloadFileRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportDownloadContainer),
)
