import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ActionsButtons from './ActionsButtons'
import {
  getButtonsRequest,
  deleteButtonRequest,
} from '../../../../actions/buttonsActions'
import { updateSettingsLists } from '../../../../actions/settingsActions'

const mapStateToProps = state => ({
  isFetching: state.buttons.getButton.isFetching,
  buttons: state.buttons.getButtons.buttons,
  getScriptsError: state.buttons.getButtons.error,
  isDeleting: state.buttons.deleteButton.isFetching,
  deleteScriptError: state.buttons.deleteButton.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getButtonsRequest,
      deleteButtonRequest,
      updateSettingsLists,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsButtons),
)
