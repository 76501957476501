import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { inventoryLocationStringify } from '../../../../../../helpers/parserHelper'

// internationalization
import i18next from '../../../../../../i18n/i18n'

// components
import InventoryEquipmentFiltersToolbar from '../InventoryEquipmentFiltersToolbar'
import InventoryEquipmentTable from '../InventoryEquipmentTable'
import Loader from '../../../../../Loader'

// material-icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  chip: {
    margin: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textStyle: {
    color: 'black',
    textStyle: 'underlined',
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableVerticalMargin: {
    marginTop: 20,
    marginBottom: 20,
  },
  tableCell: {
    border: '0px none #ffffff',
  },
  expansionPanelSummary: {
    borderRadius: 2,
    border: '1.0px solid #BDBDBD',
  },
  expansionPanel: {
    border: '0px none #ffffff',
  },
})

class InventoryEquipmentLocationsTable extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      rowsPerPage: 5,
      params: {},
      filters: {},
    }
  }

  componentDidMount() {
    const {
      props: {
        match: {
          params: { projectId },
        },
        fetchInventoryEquipmentLocations,
        fetchInventoryEquipmentCount,
      },
      state: { page, rowsPerPage },
    } = this
    fetchInventoryEquipmentLocations(
      projectId,
      {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      },
      null,
    )
    fetchInventoryEquipmentCount(projectId, null)
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.handleFetch())
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, () => this.handleFetch())
  }

  handleFetch = () => {
    const {
      props: {
        match: {
          params: { projectId },
        },
        fetchInventoryEquipmentLocations,
      },
      state: { page, rowsPerPage, params, filters },
    } = this
    fetchInventoryEquipmentLocations(
      projectId,
      {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...params,
      },
      filters,
    )
  }

  updateFilters = (params, filters) => {
    this.setState({
      params,
      filters,
    })
    const {
      props: {
        match: {
          params: { projectId },
        },
        fetchInventoryEquipmentLocations,
      },
      state: { page, rowsPerPage },
    } = this
    this.setState({
      equipmentFilters: filters,
      equipmentParams: params,
    })
    fetchInventoryEquipmentLocations(
      projectId,
      {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...params,
      },
      filters,
    )
  }

  displayLocation = (classes, data, isFetching, equipment) => {
    const {
      floor,
      cabinet,
      equipmentCount,
      markedEquipmentCount,
      foundEquipmentCount,
      location,
    } = data
    const locationName = inventoryLocationStringify(location)
    return (
      <ExpansionPanel
        elevation={0}
        onChange={(event, expanded) =>
          expanded
            ? this.fetchLocationEquipment(location.id, floor, cabinet)
            : ''
        }
        className={classes.expansionPanel}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.expansionPanelSummary}
        >
          <Grid container xs={12} direction="row" justify="space-between">
            <Grid item xs={6}>
              <Grid container direction="column">
                <Typography variant="subheading" align="left">
                  {locationName}
                </Typography>
                <Grid container xs={8} direction="row">
                  <Grid item xs={4}>
                    <Typography align="left">
                      {`${i18next.t('equipment_web_atoir:floor')}: ${floor ||
                        '-'}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align="left">
                      {`${i18next.t('shared_web_atoir:office')}: ${cabinet ||
                        '-'}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container xs={12} direction="row" alignItems="center">
                <Grid xs={4}>
                  <Typography align="center">
                    {`${i18next.t('inventory_web_atoir:totalEquipment')}`}
                  </Typography>
                  <Typography align="center">
                    {`${equipmentCount} ${i18next.t(
                      'inventory_web_atoir:pieceShorten',
                    )}`}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography align="center">
                    {`${i18next.t('inventory_web_atoir:withMarking')}`}
                  </Typography>
                  <Typography align="center">
                    {`${markedEquipmentCount} ${i18next.t(
                      'inventory_web_atoir:pieceShorten',
                    )}`}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography align="center">
                    {`${i18next.t('inventory_web_atoir:foundCabinet')}`}
                  </Typography>
                  <Typography align="center">
                    {`${foundEquipmentCount} ${i18next.t(
                      'inventory_web_atoir:pieceShorten',
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Grid container xs={12}>
            <InventoryEquipmentTable
              isFetching={isFetching}
              equipment={equipment}
            />
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  fetchLocationEquipment = (locationId, floor, cabinet) => {
    const {
      props: {
        match: {
          params: { projectId },
        },
        fetchInventoryEquipment,
      },
      state: { equipmentFilters, equipmentParams },
    } = this
    const filters = {
      ...equipmentFilters,
      organization_location_id: locationId,
      floor,
      cabinet,
    }
    fetchInventoryEquipment(projectId, equipmentParams, filters)
  }

  render() {
    const {
      props: {
        classes,
        isFetchingLocations,
        locations,
        locationsCount,
        isFetchingEquipment,
        equipment,
        match: {
          params: { projectId },
        },
      },
      state: { page, rowsPerPage },
      displayLocation,
      updateFilters,
    } = this
    const projectIds = _.keys(equipment)
    let projectEquipment = {}
    if (_.indexOf(projectIds, projectId) !== -1) {
      projectEquipment = equipment[projectId]
    }
    return (
      <Fragment>
        <Grid container xs={12}>
          <Grid container xs={12}>
            <InventoryEquipmentFiltersToolbar updateFilters={updateFilters} />
          </Grid>
          <Grid container xs={12} className={classes.tableVerticalMargin}>
            <Table>
              <Fragment>
                {isFetchingLocations ? (
                  <Loader />
                ) : locations && locations.length !== 0 ? (
                  <Fragment>
                    <TableBody>
                      {locations.map(location => {
                        const {
                          location: { id },
                          floor,
                          cabinet,
                        } = location
                        let eqp = []
                        if (_.indexOf(_.keys(projectEquipment), id) !== -1) {
                          if (
                            _.indexOf(_.keys(projectEquipment[id]), floor) !==
                            -1
                          ) {
                            if (
                              _.indexOf(
                                _.keys(projectEquipment[id][floor]),
                                cabinet,
                              ) !== -1
                            ) {
                              eqp = projectEquipment[id][floor][cabinet]
                            }
                          }
                        }
                        return (
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              {displayLocation(
                                classes,
                                location,
                                isFetchingEquipment,
                                eqp,
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Fragment>
                ) : (
                  <Typography className={classes.text}>
                    {i18next.t('shared_web_atoir:noDataForThisParametrs')}
                  </Typography>
                )}
              </Fragment>
            </Table>
          </Grid>
          <Grid container xs={12} justify="flex-end">
            <TablePagination
              component="Fragment"
              count={locationsCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
              labelDisplayedRows={({
                from: fromPage,
                to: toPage,
                count: countPage,
              }) =>
                i18next.t('shared_web_atoir:labelDisplayedRows', {
                  fromPage,
                  toPage,
                  countPage,
                })
              }
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

InventoryEquipmentLocationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchInventoryEquipmentLocations: PropTypes.func.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  locationsCount: PropTypes.number.isRequired,
  fetchInventoryEquipment: PropTypes.func.isRequired,
  isFetchingEquipment: PropTypes.bool.isRequired,
  equipment: PropTypes.array.isRequired,
  fetchInventoryEquipmentCount: PropTypes.func.isRequired,
}

export default withStyles(styles)(InventoryEquipmentLocationsTable)
