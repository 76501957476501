import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

import i18next from '../../../i18n/i18n'

function UnpaidTooltip({ children, disabled }) {
  return (
    <Tooltip
      title={i18next.t('service_desk_web_atoir:sdUnpaidTooltip')}
      disableHoverListener={disabled}
      disableFocusListener
      disableTouchListener
    >
      <div>{children}</div>
    </Tooltip>
  )
}

UnpaidTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default UnpaidTooltip
