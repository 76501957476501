import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import OrganizationInformationComponent from '../../components/Organizations/Profile/OrganizationInformationComponent'

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  contacts: state.organizationContacts.contacts,
})

export default withRouter(
  connect(mapStateToProps)(OrganizationInformationComponent),
)
