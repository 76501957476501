import shortId from 'shortid'

import {
  ERROR_MESSAGE_RECEIVED,
  INFO_MESSAGE_RECEIVED,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  CATCH_ERROR,
  CLOSE_ERROR_MESSAGE,
  REDIRECT_USER_TO_HOME_PAGE,
  REDIRECTED_TO_HOME_PAGE,
  SHOW_DIALOG_WITH_OK,
  CLOSE_DIALOG_WITH_OK,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  SET_FILTER_DRAWER,
  CLEAR_FILTER_DRAWER,
  SET_NAVIGATION_DRAWER,
  CLEAR_NAVIGATION_DRAWER,
  GET_ALL_USER_SETTINGS,
  SAVE_USER_SETTINGS,
} from '../constants/actionTypes'
import { actionCreatorTyped } from './actionCreator'

const actionCreator = type => payload => ({
  type,
  payload,
})

export const receivedErrorMessage = errors => ({
  type: ERROR_MESSAGE_RECEIVED,
  errors,
})

export const receivedInfoMessage = info => ({
  type: INFO_MESSAGE_RECEIVED,
  info,
})

export const enqueueSnackbar = notification => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    ...notification,
    key:
      (notification.options && notification.options.key) || shortId.generate(),
  },
})

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
})

export const catchError = errorDescription => ({
  type: CATCH_ERROR,
  errorDescription,
})

export const closeErrorMessage = () => ({
  type: CLOSE_ERROR_MESSAGE,
})

export const redirectUserToHomePage = () => ({
  type: REDIRECT_USER_TO_HOME_PAGE,
})

export const redirectedUserToHomePage = () => ({
  type: REDIRECTED_TO_HOME_PAGE,
})

export const showDialogWithOk = payload => ({
  type: SHOW_DIALOG_WITH_OK,
  payload,
})

export const handleCloseSimpleDialog = () => ({
  type: CLOSE_DIALOG_WITH_OK,
})

export const setFilterDrawer = payload => ({
  type: SET_FILTER_DRAWER,
  payload,
})
export const clearFilterDrawer = () => ({
  type: CLEAR_FILTER_DRAWER,
})

export const setNavigationDrawer = payload => ({
  type: SET_NAVIGATION_DRAWER,
  payload,
})
export const clearNavigationDrawer = () => ({
  type: CLEAR_NAVIGATION_DRAWER,
})

export const getAllUserSettings = actionCreatorTyped(GET_ALL_USER_SETTINGS)

export const saveUserSettings = actionCreatorTyped(SAVE_USER_SETTINGS)

export const downloadFileRequest = actionCreator(DOWNLOAD_FILE_REQUEST)
export const downloadFileRequestSuccess = actionCreator(DOWNLOAD_FILE_SUCCESS)
export const downloadFileRequestFailure = actionCreator(DOWNLOAD_FILE_FAILURE)
