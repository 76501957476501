/* eslint-disable max-len */
import i18next from '../../../../i18n/i18n'

import HelpIcon from '@material-ui/icons/HelpOutline'
import WorkIcon from '@material-ui/icons/WorkOutline'
import LocalHospitalIcon from '@material-ui/icons/LocalHospitalOutlined'
import BuildIcon from '@material-ui/icons/BuildOutlined'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplicationsOutlined'

function createItem(id, to, text, icon = null, tooltip = '') {
  return {
    id,
    to,
    text,
    icon,
    tooltip,
  }
}

export default () => [
  createItem(
    'organizations',
    '/organizations',
    i18next.t('organizations_web_atoir:organizationsDrawerMenuItem'),
    WorkIcon,
  ),
  createItem(
    'equipment',
    '/equipment',
    i18next.t('equipment_web_atoir:equipmentDrawerMenuItem'),
    SettingsApplicationsIcon,
  ),
  {
    id: 'serviceDesc',
    text: i18next.t('service_desk_web_atoir:repairs'),
    icon: LocalHospitalIcon,
    nested: [
      createItem(
        'sdCallsList',
        '/service_desk/calls/all',
        i18next.t('service_desk_web_atoir:ListRequest'),
        null,
        i18next.t('service_desk_web_atoir:callsListDrawerTitleTooltip'),
      ),
      createItem(
        'sdOrdersList',
        '/service_desk/orders/all',
        i18next.t('service_desk_web_atoir:listOrders'),
        null,
        i18next.t('service_desk_web_atoir:ordersListDrawerTitleTooltip'),
      ),
      createItem(
        'contracts',
        '/service_desk/contracts',
        i18next.t('service_desk_web_atoir:routes'),
      ),
    ],
  },
  {
    id: 'innerCalls',
    text: i18next.t('inner_calls_web_atoir:innerCalls'),
    icon: BuildIcon,
    nested: [
      createItem(
        'innerCallsList',
        '/inner_calls',
        i18next.t('shared_web_atoir:list'),
      ),
      createItem(
        'trustedOrganizationsList',
        '/trusted_organizations',
        i18next.t('shared_web_atoir:trustedOrganizationsDrawer'),
      ),
    ],
  },
  {
    id: 'support',
    text: i18next.t('shared_web_atoir:support'),
    icon: HelpIcon,
    nested: [
      createItem(
        'help',
        '/faq',
        i18next.t('system_menu_web_atoir:faqReferenceMenuItem'),
      ),
    ],
  },
]
