import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

// modules
import EquipmentProfile from '../EquipmentProfile'
import Grid from '@material-ui/core/Grid/Grid'
import Subheading from '../../../../../../widgets/Lettering/Subheading'
import ApplyButton from '../../../../../../widgets/Buttons/ApplyButton'

// i18next
import i18next from '../../../../../../i18n/i18n'

const styles = theme => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  EquipmentProfileRoot: {
    padding: 12,
    textAlign: 'left',
    marginBottom: 12,
  },
  profileInfoCellNameWrapper: {
    wordBreak: 'break-all',
    color: theme.palette.grey[500],
  },
})

class DiagnosticAndRepairInformation extends Component {
  state = {}

  render() {
    const {
      props: {
        classes,
        equipment,
        onEdit,
        comment,
        organizationName,
        organizationAddress,
        onCancelCall,
        callExtNum,
      },
    } = this
    return (
      <Fragment>
        <Subheading
          title={`${i18next.t(
            'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepair',
          )} ${
            callExtNum && typeof callExtNum === 'string'
              ? callExtNum.trim()
              : ''
          }`}
        >
          {onCancelCall && (
            <ApplyButton onClick={onCancelCall}>
              {i18next.t('service_desk_web_atoir:cancelCall')}
            </ApplyButton>
          )}
        </Subheading>
        <Divider />
        <div className={classes.EquipmentProfileRoot}>
          <EquipmentProfile
            onEdit={onEdit}
            equipment={equipment}
            cellNameWrapper={content => (
              <Typography
                variant="body2"
                className={classes.profileInfoCellNameWrapper}
              >
                {content}
              </Typography>
            )}
            cellValueWrapper={content => (
              <Typography className="wordWrapBreakAll" variant="body2">
                {content}
              </Typography>
            )}
          />
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography className={classes.profileInfoCellNameWrapper}>
                {i18next.t('service_desk_web_atoir:whatsWrong')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="pre" className="preBreak" variant="body2">
                {comment}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Grid container spacing={1} className={classes.EquipmentProfileRoot}>
          <Grid item xs={3}>
            <Typography className={classes.profileInfoCellNameWrapper}>
              {i18next.t(
                'service_desk_web_atoir:requestCreationClientOrganization',
              )}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className="wordWrapBreakAll" variant="body2">
              {organizationName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.profileInfoCellNameWrapper}>
              {i18next.t('service_desk_web_atoir:organizationAddress')}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className="wordWrapBreakAll" variant="body2">
              {organizationAddress}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

DiagnosticAndRepairInformation.defaultProps = {
  onEdit: null,
  callExtNum: '',
}

DiagnosticAndRepairInformation.propTypes = {
  equipment: PropTypes.object.isRequired,
  comment: PropTypes.string.isRequired,
  callExtNum: PropTypes.string,
  organizationName: PropTypes.string.isRequired,
  organizationAddress: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
}

export default withStyles(styles)(DiagnosticAndRepairInformation)
