import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import _ from 'lodash'
import { inventoryEquipmentAmount } from '../../../../../constants/constants'

// internationalization
import i18next from '../../../../../i18n/i18n'

// components
import DeleteInventoryLocationDialog from '../DeleteInventoryLocationDialog/DeleteInventoryLocationDialog'
import Loader from '../../../../Loader'

// material-ui-icons
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { inventoryLocationStringifyDefault } from '../../../../../helpers/parserHelper'

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '0 0 auto',
  },
})

const columnData = [
  {
    id: 'address',
    label: 'Добавленные адреса',
  },
  {
    id: 'amount',
    label: 'Количество оборудования',
  },
  {
    id: 'action',
    label: 'Действия',
  },
]

let InventoryLocationsTableHead = () => (
  <TableHead>
    <TableRow>
      {columnData.map(
        column => (
          <TableCell key={column.id} padding="default">
            {column.label}
          </TableCell>
        ),
        this,
      )}
    </TableRow>
  </TableHead>
)

InventoryLocationsTableHead = withStyles(toolbarStyles)(
  InventoryLocationsTableHead,
)

let InventoryLocationsTableToolbar = props => {
  const { classes } = props

  return (
    <Toolbar>
      <div className={classes.title}>
        <Typography variant="subheading" id="tableTitle">
          {i18next.t('inventory_web_atoir:locationsList')}
        </Typography>
      </div>
    </Toolbar>
  )
}

InventoryLocationsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

InventoryLocationsTableToolbar = withStyles(toolbarStyles)(
  InventoryLocationsTableToolbar,
)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  addButton: {
    color: 'green',
  },
  deleteButton: {
    color: theme.palette.secondary.main,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
})

class InventoryLocationsTableComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      locations: [],
      isLocationsSet: false,
      deleteLocation: {},
    }
  }

  componentDidMount() {
    const { organization, fetchOrganizationLocations } = this.props
    if (!isEmpty(organization)) {
      fetchOrganizationLocations(organization.id)
    }
  }

  componentDidUpdate(prevState) {
    const {
      organization,
      fetchOrganizationLocations,
      isFetchingLocations,
      locations,
      incomeLocations,
      selectedLocations,
    } = this.props
    const { isLocationsSet } = this.state
    if (prevState.organization !== organization && !isEmpty(organization)) {
      fetchOrganizationLocations(organization.id)
    }
    if (
      prevState.isFetchingLocations !== isFetchingLocations &&
      !isFetchingLocations &&
      !isEmpty(locations)
    ) {
      if (!isLocationsSet && incomeLocations) {
        const newLocations = incomeLocations.map(l => {
          const loc = {}
          loc.location = l.OrganizationLocation
          loc.amt = l.equipments_amt
          loc.added = true
          return loc
        })
        const newLocationsAll = locations.map(l => {
          const loc = {}
          loc.location = l
          loc.amt = i18next.t('shared_web_atoir:notSelected')
          loc.added = false
          return loc
        })
        this.setState(
          {
            locations: _.unionBy(newLocations, newLocationsAll, 'location.id'),
            isLocationsSet: true,
          },
          () => selectedLocations(newLocations),
        )
      } else {
        const newLocations = locations.map(l => {
          const loc = {}
          loc.location = l
          loc.amt = i18next.t('shared_web_atoir:notSelected')
          loc.added = true
          return loc
        })
        this.setState({ locations: newLocations }, () =>
          selectedLocations(newLocations),
        )
      }
    }
  }

  handleChange = loc => event => {
    const { locations } = this.state
    const { selectedLocations } = this.props
    locations[locations.indexOf(loc)].amt = event.target.value
    this.setState({ locations }, () => selectedLocations(locations))
  }

  handleAdd = loc => {
    const { locations } = this.state
    const { selectedLocations } = this.props
    locations[locations.indexOf(loc)].added = true
    this.setState({ locations }, () => selectedLocations(locations))
  }

  handleDelete = () => {
    const { locations, deleteLocation } = this.state
    const { selectedLocations } = this.props
    const index = locations.indexOf(deleteLocation)
    locations[index].added = false
    locations[index].amt = i18next.t('shared_web_atoir:notSelected')
    this.setState(
      {
        locations,
        open: false,
        deleteLocation: {},
      },
      () => selectedLocations(locations),
    )
  }

  handleClickOpen = loc => {
    this.setState({
      open: true,
      deleteLocation: loc,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      deleteLocation: {},
    })
  }

  render() {
    const { classes, isFetchingLocations } = this.props
    const { locations } = this.state
    return isFetchingLocations ? (
      <Loader />
    ) : (
      <div>
        <InventoryLocationsTableToolbar classes={classes} />
        {locations && locations.length === 0 ? (
          <Typography>
            {i18next.t('inventory_web_atoir:emptyInventoryLocationsList')}
          </Typography>
        ) : (
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <InventoryLocationsTableHead />
              <TableBody>
                {locations.map(loc => (
                  <TableRow hover key={loc.location.id}>
                    <TableCell>
                      {loc.added ? (
                        <Typography>
                          {inventoryLocationStringifyDefault(loc.location)}
                        </Typography>
                      ) : (
                        <Typography color="textSecondary">
                          {inventoryLocationStringifyDefault(loc.location)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <div>
                        <FormControl
                          disabled={!loc.added}
                          className={classes.formControl}
                        >
                          <Select
                            value={loc.amt}
                            onChange={this.handleChange(loc)}
                            inputProps={{
                              name: 'amount',
                              id: 'amt',
                            }}
                          >
                            {inventoryEquipmentAmount.map(a => (
                              <MenuItem value={a}>
                                {a} {i18next.t('inventory_web_atoir:units')}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </TableCell>
                    <TableCell>
                      {loc.added ? (
                        <IconButton
                          onClick={() => this.handleClickOpen(loc)}
                          className={classes.deleteButton}
                          aria-label="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => this.handleAdd(loc)}
                          className={classes.addButton}
                          aria-label="Add"
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        <DeleteInventoryLocationDialog
          open={this.state.open}
          handleClose={this.handleClose}
          handleDelete={this.handleDelete}
          location={this.state.deleteLocation}
        />
      </div>
    )
  }
}

InventoryLocationsTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  fetchOrganizationLocations: PropTypes.func.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
}

export default withStyles(styles)(InventoryLocationsTableComponent)
