import React from 'react'
import { withFormik } from 'formik'
import moment from 'moment'

import { withStyles } from '@material-ui/core'

import FormHolder from './FormHolder'
import equipmentSchema from './equipment.schema'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'
import EquipmentDictionary from '../EquipmentDictionary'
import {
  pipe,
  autoCompleteDataConverterPiped,
  compactObject,
  compactObjectOnEdit,
} from '../../../helpers/dataHelper'
import i18next from '../../../i18n/i18n'

const CustomOutlinedButton = withStyles({ root: { margin: 0 } })(
  function CustomOutlinedButtonMUI({ classes, ...otherProps }) {
    return <OutlinedButton classes={{ root: classes.root }} {...otherProps} />
  },
)

function yearConverter(idValue) {
  return function(_data) {
    const out = { ..._data }
    if (idValue && out[idValue]) {
      out[idValue] = new Date(out[idValue], 0, 1)
    }
    return out
  }
}

function yearPrepare(idValue) {
  return function(_data) {
    const out = { ..._data }
    if (idValue && _data[idValue]) {
      out[idValue] = new Date(_data[idValue]).getFullYear()
    }
    return out
  }
}

function packDataCommon(payload) {
  return pipe(
    yearConverter('year_issue'),
    autoCompleteDataConverterPiped('date_input_work'),
    autoCompleteDataConverterPiped('date_expired_guarantee'),
    autoCompleteDataConverterPiped('country_id', 'country_id.id'),
  )(payload)
}

function packDataOnCreation(payload) {
  return pipe(packDataCommon, compactObject)(payload)
}

function packDataOnEditing(payload) {
  return pipe(packDataCommon, compactObjectOnEdit)(payload)
}

function rename(idValue, newIdValue) {
  return function(_data) {
    const out = { ..._data }
    if (_data[idValue]) {
      out[newIdValue] = _data[idValue]
    }
    return _.omit(out, idValue)
  }
}

function replaceObjById(idValue) {
  return function(_data) {
    const out = { ..._data }
    if (_data[idValue]) {
      out[idValue] = (_data[idValue] || {}).id
    }
    return out
  }
}

function dateInputPrepare(idValue) {
  return function(_data) {
    const out = { ..._data }
    if (_data[idValue]) {
      out[idValue] = {
        [idValue]: new Date(_data[idValue]),
        text: moment(_data[idValue]).format('DD.MM.YYYY'),
      }
    }
    return out
  }
}

function autocompletePrepare(idValue) {
  return function(_data) {
    const out = { ..._data }
    if (_data[idValue]) {
      out[idValue] = {
        [idValue]: { id: _data[idValue].id },
      }
    }
    return out
  }
}

function unpackData(payload) {
  return pipe(
    d =>
      _.pick(d, [
        'name',
        'ba_equipment_id',
        'industry',
        'OrganizationDepartment',
        'OrganizationLocation',
        'model',
        'vendor',
        'serial_number',
        'article_number',
        'external_id',
        'year_issue',
        'status',
        'not_working',
        'floor',
        'cabinet',
        'portable',
        'mol_user_organization_id',
        'date_input_work',
        'date_expired_guarantee',
        'Country',
        'BAEquipment',
      ]),
    rename('OrganizationDepartment', 'organization_department_id'),
    rename('OrganizationLocation', 'organization_location_id'),
    rename('BAEquipment', 'ba_equipment_id'),
    rename('Country', 'country_id'),
    rename('industry', 'industry_id'),
    replaceObjById('organization_department_id'),
    replaceObjById('organization_location_id'),
    replaceObjById('ba_equipment_id'),
    replaceObjById('industry_id'),
    yearPrepare('year_issue'),
    autocompletePrepare('country_id'),
    dateInputPrepare('date_input_work'),
    dateInputPrepare('date_expired_guarantee'),
    compactObject,
  )(payload)
}

function EquipmentEditor({
  setFieldValue,
  submitCount,
  validateForm,
  errors,
  equipment: { BAEquipment: baEquipmentSaved, industry },
  ...otherProps
}) {
  const forceValidation = React.useCallback(
    _.debounce(() => {
      validateForm()
    }, 300),
    [],
  )
  if (baEquipmentSaved && industry) {
    // eslint-disable-next-line no-param-reassign
    baEquipmentSaved.EquipmentToIndustries = [
      { equipment_industry_id: industry.id },
    ]
  }
  const [baEquipment, setBaEquipment] = React.useState(baEquipmentSaved || null)
  const [catalogOpen, setCatalogOpen] = React.useState(false)

  React.useEffect(() => {
    if (baEquipment && baEquipment.BAVendor) {
      setFieldValue('ba_equipment_id', baEquipment.id)
      setFieldValue('name', baEquipment.name)
      setFieldValue('vendor', baEquipment.BAVendor.name)
      setFieldValue('model', baEquipment.model)
      setFieldValue(
        'industry_id',
        baEquipment.EquipmentToIndustries &&
          baEquipment.EquipmentToIndustries[0]
          ? baEquipment.EquipmentToIndustries[0].equipment_industry_id
          : null,
      )
    } else {
      setFieldValue('ba_equipment_id', null)
    }
  }, [baEquipment])

  const atachBAComponent = React.useCallback(
    () => (
      <CustomOutlinedButton
        size="medium"
        onClick={() => {
          setCatalogOpen(true)
        }}
      >
        {i18next.t('equipment_web_atoir:chooseFromCatalog')}
      </CustomOutlinedButton>
    ),
    [setCatalogOpen],
  )

  const detachBAComponent = React.useCallback(
    () => (
      <CustomOutlinedButton
        size="medium"
        onClick={() => {
          setBaEquipment(null)
        }}
      >
        {i18next.t('equipment_web_atoir:detachFromCatalog')}
      </CustomOutlinedButton>
    ),
    [setBaEquipment],
  )

  const catalogAgentComponent = React.useCallback(
    baEquipment ? detachBAComponent : atachBAComponent,
    [baEquipment, detachBAComponent, atachBAComponent],
  )

  const closeCatalog = React.useCallback(
    equipmentItem => {
      if (equipmentItem) {
        setBaEquipment(equipmentItem)
      }
      setCatalogOpen(false)
    },
    [setBaEquipment, setCatalogOpen],
  )

  const errorsRef = React.useRef(errors)
  React.useEffect(() => {
    errorsRef.current = errors
  }, [errors])

  const setFieldValueHandler = React.useCallback(
    (event, possibleValue) => {
      let id = event
      let value = possibleValue
      if (event.target) {
        ;({ id, value } = event.target)
      }
      // после сабмита валидация будет производиться при каждом изменении
      setFieldValue(id, value, submitCount !== 0)
      // до сабмита очищаем ошибки по мере внесения изменения в поле с ошибкой,
      // общая валидация запускается по сабмиту и потере фокуса
      if (errorsRef.current[id]) {
        forceValidation()
      }
    },
    [setFieldValue, forceValidation, errorsRef],
  )
  return (
    <React.Fragment>
      <FormHolder
        catalogAgentComponent={catalogAgentComponent}
        setFieldValueHandler={setFieldValueHandler}
        {...{ validateForm, errors, baEquipment }}
        {...otherProps}
      />
      <EquipmentDictionary
        open={catalogOpen}
        handleClose={closeCatalog}
        values={otherProps.values}
      />
    </React.Fragment>
  )
}

const equipmentInit = {
  organization_location_id: '',
  country_id: null,
  date_input_work: null,
  date_expired_guarantee: null,
}

const formikEnhancer = withFormik({
  validationSchema: equipmentSchema,
  mapPropsToValues: ({ equipment }) => ({
    ...equipmentInit,
    ...unpackData(equipment),
  }),
  handleSubmit: (payload, { props }) => {
    if (props.creation) {
      props.onSubmit(packDataOnCreation(payload))
    } else {
      props.onSubmit(packDataOnEditing(payload))
    }
  },
  displayName: 'EquipmentEditorEnhanced',
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
})

const EquipmentEditorEnhanced = formikEnhancer(EquipmentEditor)

export default EquipmentEditorEnhanced
