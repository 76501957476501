import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper/Paper'

// components
import SimpleDialogLoader from '../SimpleDialogLoader'
import Subheading from '../Lettering/Subheading'
import OutlinedButton from '../Buttons/OutlinedButton'
import ContainedButton from '../Buttons/ContainedButton'

const styles = theme => ({
  subheading: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formDiv: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  button: {
    marginRight: 0,
    marginLeft: 8,
  },
})

class FormComponent extends Component {
  handleSubmit = event => {
    event.preventDefault()
    this.props.handleSubmitAction()
  }

  render() {
    const { classes } = this.props
    return (
      <Fragment>
        <Paper className={this.props.classes.root} elevation={4}>
          <Subheading
            divider={this.props.divider}
            title={this.props.titleForm}
          />
          <div className={this.props.classes.formDiv}>
            <form onSubmit={this.handleSubmit} noValidate>
              {this.props.children}
              <div className={this.props.classes.buttonWrapper}>
                {!isEmpty(this.props.secondaryButtonsArray) &&
                  this.props.secondaryButtonsArray.map(_secondaryItem => (
                    <div>
                      <OutlinedButton
                        size="medium"
                        onClick={_secondaryItem.handleSecondaryAction}
                      >
                        {_secondaryItem.secondaryText}
                      </OutlinedButton>
                    </div>
                  ))}
                <div>
                  <ContainedButton
                    size="medium"
                    type="submit"
                    classes={{ root: classes.button }}
                  >
                    {this.props.submitText}
                  </ContainedButton>
                </div>
              </div>
            </form>
          </div>
        </Paper>
        <SimpleDialogLoader open={this.props.isLoading} />
      </Fragment>
    )
  }
}
FormComponent.defaultProps = {
  titleForm: '',
  submitText: '',
  children: '',
  isLoading: false,
  secondaryButtonsArray: [],
}

FormComponent.propTypes = {
  titleForm: PropTypes.string,
  submitText: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  handleSubmitAction: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondaryButtonsArray: PropTypes.array,
}

export default withStyles(styles)(FormComponent)
