import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import Loader from '../../../Loader'

// internationalization
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import PrimaryButton from '../../../../widgets/Buttons/PrimaryButton'
import SecondaryButton from '../../../../widgets/Buttons/SecondaryButton'
import SimpleDialog from '../../../Other/SimpleDialog'

const styles = () => ({
  textField: {},
})

class RejectInventoryProjectPerformerDialog extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      comment: '',
      errors: {},
      isOpenDialogConfirm: false,
    }
  }

  componentDidUpdate(prevState) {
    const {
      props: {
        isRejectingInventoryProjectAsPerformer,
        rejectingErrors,
        handleClose,
        history: { push },
      },
    } = this
    if (
      prevState.isRejectingInventoryProjectAsPerformer !==
        isRejectingInventoryProjectAsPerformer &&
      !isRejectingInventoryProjectAsPerformer &&
      isEmpty(rejectingErrors)
    ) {
      handleClose()
      push('/inventory')
    }
  }

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleSubmitForm = event => {
    event.preventDefault()
    this.handleOpenDialog()
  }

  rejectInventoryProject = () => {
    const {
      props: { rejectInventoryProjectAsPerformer, organizationId, projectId },
      state: { comment },
    } = this

    const validation = this.validateFields(comment)
    if (!isEmpty(validation)) {
      this.setState({
        errors: validation,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const data = { comment }
    rejectInventoryProjectAsPerformer(organizationId, projectId, data)
  }

  validateFields = comment => {
    const errors = {}
    if (isEmpty(comment)) {
      errors.comment = i18next.t(
        'inventory_web_atoir:rejectInventoryProjectCommentRequired',
      )
    }
    return errors
  }

  handleOnExit = () => {
    this.setState({
      comment: '',
      errors: {},
    })
  }

  handleOpenDialog = () => {
    this.setState(state => ({
      isOpenDialogConfirm: !state.isOpenDialogConfirm,
    }))
  }

  handlePositive = () => {
    const { rejectInventoryProject } = this
    rejectInventoryProject()
  }

  render() {
    const {
      props: {
        classes,
        open,
        handleClose,
        isRejectingInventoryProjectAsPerformer,
      },
      state: { comment, errors, isOpenDialogConfirm },
    } = this

    const commentHelperText = !isEmpty(errors.comment) ? errors.comment : ''

    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={() => handleClose()}
          onExit={() => this.handleOnExit()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.handleSubmitForm} noValidate>
            <DialogTitle id="alert-dialog-title">
              {i18next.t(
                'inventory_web_atoir:rejectInventoryProjectAsPerformerDialogTitle',
              )}
            </DialogTitle>
            <DialogContent>
              {isRejectingInventoryProjectAsPerformer ? <Loader /> : null}
              <DialogContentText id="alert-dialog-description">
                {i18next.t(
                  'inventory_web_atoir:rejectInventoryProjectAsPerformerDialogText',
                )}
              </DialogContentText>
              <TextField
                error={!isEmpty(errors.comment)}
                required
                fullWidth
                multiline
                id="comment"
                label={i18next.t(
                  'inventory_web_atoir:rejectInventoryProjectAsPerformerComment',
                )}
                placeholder={i18next.t(
                  'inventory_web_atoir:enterRejectInventoryProjectAsPerformerComment',
                )}
                className={classes.textField}
                helperText={commentHelperText}
                margin="normal"
                onChange={this.handleChange('comment')}
                value={comment}
              />
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={() => handleClose()}>
                {i18next.t('shared_web_atoir:cancel')}
              </SecondaryButton>
              <PrimaryButton type="submit">
                {i18next.t('inventory_web_atoir:confirmRejection')}
              </PrimaryButton>
            </DialogActions>
          </form>
        </Dialog>
        <SimpleDialog
          isOpen={isOpenDialogConfirm}
          content={i18next.t('inventory_web_atoir:rejectConfirmDialogText')}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          positiveText={i18next.t('inventory_web_atoir:confirm')}
          handleNegative={this.handleOpenDialog}
          handlePositive={this.handlePositive}
          handleClose={this.handleOpenDialog}
        />
      </Fragment>
    )
  }
}

RejectInventoryProjectPerformerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  rejectInventoryProjectAsPerformer: PropTypes.func.isRequired,
  isRejectingInventoryProjectAsPerformer: PropTypes.bool.isRequired,
  rejectingErrors: PropTypes.object.isRequired,
}

export default withStyles(styles)(RejectInventoryProjectPerformerDialog)
