import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchOrganization } from '../../../actions/organizationsActions'

import {
  checkEquipmentsImportFileRequest as checkEquipmentsImportFile,
  processEquipmentsImportFileRequest as processEquipmentsImportFile,
  processEquipmentsImportFileClear as checkEquipmentsImportFileResultClear,
} from '../../../actions/equipmentActions'
import {
  downloadFileRequest,
  showDialogWithOk,
} from '../../../actions/appActions'
import ImportEquipmentsWrapper from './ImportEquipmentsWrapper'

const mapStateToProps = state => ({
  checkingByBackendResult: state.equipment.checkEquipmentsImportFile.result,
  isCheckingByBackend: state.equipment.checkEquipmentsImportFile.isFetching,
  checkingByBackendError: state.equipment.checkEquipmentsImportFile.error,
  processingByBackendResult: state.equipment.processEquipmentsImportFile.result,
  isProcessingByBackend: state.equipment.processEquipmentsImportFile.isFetching,
  processingBackendError: state.equipment.processEquipmentsImportFile.error,
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkEquipmentsImportFile,
      processEquipmentsImportFile,
      downloadFileRequest,
      fetchOrganization,
      checkEquipmentsImportFileResultClear,
      showDialogWithOk,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportEquipmentsWrapper),
)
