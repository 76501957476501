import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RejectAgreement from './RejectAgreement'
import { rejectAgreementRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isRejecting: state.serviceDeskContracts.rejectAgreement.isFetching,
  rejectError: state.serviceDeskContracts.rejectAgreement.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      rejectAgreementRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RejectAgreement),
)
