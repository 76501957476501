/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import SelectLimitedAuto from '../SelectLimitedAuto'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'


const SelectLimitedFromControl = ({FormControlProps, label, helperText, ...others}) => (
  <FormControl
    {...FormControlProps}
  >
    <InputLabel shrink={!!others.value}>
      {label}
    </InputLabel>
    <SelectLimitedAuto
      {...others}
    />
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
)

SelectLimitedFromControl.defaultProps = {
  FormControlProps: {},
  sourceUrl: null,
  helperText: null,
}

SelectLimitedFromControl.propTypes = {
  FormControlProps: PropTypes.object,
  label: PropTypes.string,
  onEndOfScroll: PropTypes.func,
  limit: PropTypes.number,
  offset: PropTypes.number,
  sourceUrl: PropTypes.string,
  isSuccessResponse: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  requestResultTransformerToArray: PropTypes.func.isRequired,
  itemTransformer: PropTypes.func.isRequired,
  ref: PropTypes.func,
  value: PropTypes.any,
  query: PropTypes.object,
  selectProps: PropTypes.object,
  menuListProps: PropTypes.object,
  menuListStyleProps: PropTypes.object,
  helperText: PropTypes.string,
}


export default SelectLimitedFromControl
