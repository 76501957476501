import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import AgreementRow from './AgreementRow'
import DelayedLoader from '../../../../widgets/DelayedLoader'
import i18n from '../../../../i18n/i18n'
import { withStyles } from '@material-ui/core'
import DefaultButton from '../../../../widgets/Buttons/DefaultButton'

const styles = theme => ({
  loadMoreButton: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto`,
  },
})

class AgreementsTable extends Component {
  state = {
    limit: 10,
    offset: 0,
  }

  componentDidMount() {
    this.fetchAgreements({ append: false })
    this.fetchAgreementsCount()
  }

  fetchAgreements = (props = {}) => {
    this.props.fetchAgreementsRequest({
      contractId: this.props.contract.id,
      ...this.state,
      append: true,
      sort: '[createdAt=DESC]',
      ...props,
    })
  }

  fetchAgreementsCount = () => {
    this.props.fetchAgreementsCountRequest({
      contractId: this.props.contract.id,
    })
  }

  loadMore = () => {
    const {
      state: { offset, limit },
      props: { agreementsCount },
      fetchAgreements,
    } = this
    if (agreementsCount <= offset) {
      return
    }
    this.setState(
      {
        offset: offset + limit,
      },
      fetchAgreements,
    )
  }

  render() {
    const {
      state: { offset, limit },
      props: {
        classes,
        agreements,
        agreementsCount,
        isFetching,
        history,
        match: { url },
      },
      loadMore,
    } = this
    return (
      <div>
        {!agreements ? (
          <DelayedLoader delay={300} />
        ) : (
          agreements.map((a, i) => (
            <div key={a.id}>
              <AgreementRow
                status={a.status}
                date={new Date(a.createdAt).toLocaleDateString()}
                equipmentCount={a.equipments_count}
                name={a.name}
                onClick={() => history.push(`${url}/agreements/${a.id}`)}
                type={a.agreement_type}
              />
              {i + 1 !== agreements.length && <Divider />}
            </div>
          ))
        )}
        {isFetching || agreementsCount === null ? (
          <DelayedLoader delay={200} />
        ) : (
          agreementsCount > offset + limit && (
            <DefaultButton
              onClick={loadMore}
              classes={{ button: classes.loadMoreButton }}
            >
              {i18n.t('shared_web_atoir:loadMore')}
            </DefaultButton>
          )
        )}
      </div>
    )
  }
}

AgreementsTable.propTypes = {
  contract: PropTypes.object.isRequired,
}

export default withStyles(styles)(AgreementsTable)
