import * as constants from '../constants/actionTypes/innerCalls'
import config from '../config'

const initialState = {
  page: 0,
  rowsPerPage: config.defaultItemsPerPage,
  innerCallsList: {
    status: null,
    isFetching: false,
    calls: [],
    ifOneOrganizationId: null,
    count: 0,
    counts: [],
    error: null,
  },
  innerCallsCountAll: {
    isFetching: false,
    count: 0,
    error: null,
  },
  innerCallsCountCreated: {
    isFetching: false,
    count: 0,
    error: null,
  },
  innerCallsCountClosed: {
    isFetching: false,
    count: 0,
    error: null,
  },
  innerCallsNumbers: {
    isFetching: false,
    numbers: [],
    error: null,
  },
  innerCallsEngineers: {
    isFetching: false,
    engineers: [],
    error: null,
  },
  innerCallsEquipments: {
    isFetching: false,
    equipments: [],
    error: null,
  },
  innerCall: {
    isFetching: false,
    call: null,
    error: null,
  },
  innerCallHistory: {
    isFetching: false,
    history: [],
    end: false,
    error: null,
  },
  innerCallPhotos: {
    isFetching: false,
    photos: [],
    error: null,
  },
  innerCallPhotosCount: {
    isFetching: false,
    count: 0,
    error: null,
  },
  uploadPhoto: {
    isFetching: false,
    error: null,
  },
  deletePhoto: {
    isFetching: false,
    error: null,
  },
  innerCallAnalyticSummary: {
    isFetching: false,
    summary: [],
    error: null,
  },
  innerCallAnalyticSummaryAverage: {
    isFetching: false,
    summaryAverage: [],
    error: null,
  },
  innerCallsChartCreated: {
    isFetching: false,
    chartCreated: [],
    error: null,
  },
  innerCallsChartAverage: {
    isFetching: false,
    chartAverage: [],
    error: null,
  },
  innerCallsChartClosedByEngineer: {
    isFetching: false,
    chartClosedByEngineer: [],
    error: null,
  },
  innerCallsEngineerCount: {
    isFetching: false,
    count: '',
    error: null,
  },
  organizationsTrusted: {
    value: [],
    isFetching: false,
    error: null,
    count: null,
    id: null,
  },
  performerEquipments: {
    value: [],
    isFetching: false,
    error: null,
    count: null,
  },
  createInnerCall: {
    newId: null,
    isFetching: false,
    error: null,
  },
  editInnerCall: {
    isFetching: false,
    error: null,
  },
  closeInnerCall: {
    isFetching: false,
    error: null,
  },
  assigneeInnerCall: {
    isFetching: false,
    error: null,
  },
  commonInnerCallsListState: {
    currentStatus: 'all',
    currentPage: 0,
    currentRowsPerPage: 0,
  },
}

const innerCalls = (state = initialState, action) => {
  switch (action.type) {
    // SET_COMMON_INNER_CALLS_LIST_STATE
    case constants.SET_COMMON_INNER_CALLS_LIST_STATE:
      return {
        ...state,
        commonInnerCallsListState: {
          ...state.commonInnerCallsListState,
          ...action.payload,
        },
      }
    // ASSIGNEE_INNER_CALL
    case constants.ASSIGNEE_INNER_CALL.REQUEST:
      return {
        ...state,
        assigneeInnerCall: {
          ...state.assigneeInnerCall,
          isFetching: true,
          error: null,
        },
      }

    case constants.ASSIGNEE_INNER_CALL.SUCCESS:
      return {
        ...state,
        assigneeInnerCall: {
          ...state.assigneeInnerCall,
          isFetching: false,
          error: null,
        },
      }

    case constants.ASSIGNEE_INNER_CALL.FAILURE:
      return {
        ...state,
        assigneeInnerCall: {
          ...state.assigneeInnerCall,
          isFetching: false,
          error: action.payload.error,
        },
      }
    // CLOSE_INNER_CALL
    case constants.CLOSE_INNER_CALL.REQUEST:
      return {
        ...state,
        closeInnerCall: {
          ...state.closeInnerCall,
          isFetching: true,
          error: null,
        },
      }

    case constants.CLOSE_INNER_CALL.SUCCESS:
      return {
        ...state,
        closeInnerCall: {
          ...state.closeInnerCall,
          isFetching: false,
          error: null,
        },
      }

    case constants.CLOSE_INNER_CALL.FAILURE:
      return {
        ...state,
        closeInnerCall: {
          ...state.closeInnerCall,
          isFetching: false,
          error: action.payload.error,
        },
      }
    // EDIT_INNER_CALL
    case constants.EDIT_INNER_CALL.REQUEST:
      return {
        ...state,
        editInnerCall: {
          ...state.editInnerCall,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_INNER_CALL.SUCCESS:
      return {
        ...state,
        editInnerCall: {
          ...state.editInnerCall,
          isFetching: false,
          error: null,
        },
      }

    case constants.EDIT_INNER_CALL.FAILURE:
      return {
        ...state,
        editInnerCall: {
          ...state.editInnerCall,
          isFetching: false,
          error: action.payload.error,
        },
      }
    // CREATE_INNER_CALL
    case constants.CREATE_INNER_CALL.REQUEST:
      return {
        ...state,
        createInnerCall: {
          ...state.createInnerCall,
          newId: null,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_INNER_CALL.SUCCESS:
      return {
        ...state,
        createInnerCall: {
          newId: action.payload.newId,
          isFetching: false,
          error: null,
        },
      }

    case constants.CREATE_INNER_CALL.FAILURE:
      return {
        ...state,
        createInnerCall: {
          ...state.createInnerCall,
          newId: null,
          isFetching: false,
          error: action.payload.error,
        },
      }
    // GET_INNER_CALLS_PERFORMER_EQUIPMENTS
    case constants.GET_INNER_CALLS_PERFORMER_EQUIPMENTS.REQUEST:
      return {
        ...state,
        performerEquipments: {
          ...state.performerEquipments,
          value: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_INNER_CALLS_PERFORMER_EQUIPMENTS.SUCCESS:
      return {
        ...state,
        performerEquipments: {
          ...state.performerEquipments,
          value: action.payload.equipments,
          count: action.payload.count,
          isFetching: false,
          fetchSuccess: true,
        },
      }

    case constants.GET_INNER_CALLS_PERFORMER_EQUIPMENTS.FAILURE:
      return {
        ...state,
        performerEquipments: {
          ...state.performerEquipments,
          isFetching: false,
          error: action.payload.error,
        },
      }
    // GET_ORGANIZATIONS_TRUSTED
    case constants.GET_ORGANIZATIONS_TRUSTED.REQUEST: {
      const { idSaved } = state.organizationsTrusted
      const { organizationId } = action.payload
      return {
        ...state,
        organizationsTrusted: {
          ...state.organizationsTrusted,
          isFetching: true,
          error: null,
          value:
            idSaved !== organizationId ? [] : state.organizationsTrusted.value,
          idSaved: action.payload.organizationId,
        },
      }
    }

    case constants.GET_ORGANIZATIONS_TRUSTED.SUCCESS:
      return {
        ...state,
        organizationsTrusted: {
          ...state.organizationsTrusted,
          value: action.payload.organizations,
          count: action.payload.count,
          isFetching: false,
        },
      }

    case constants.GET_ORGANIZATIONS_TRUSTED.FAILURE:
      return {
        ...state,
        organizationsTrusted: {
          ...state.organizationsTrusted,
          isFetching: false,
          error: action.payload.error,
        },
      }

    case constants.FETCH_INNER_CALLS_REQUEST:
      return {
        ...state,
        innerCallsList: {
          ...state.innerCallsList,
          status: action.payload.status,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_SUCCESS:
      return {
        ...state,
        innerCallsList: {
          ...state.innerCallsList,
          isFetching: false,
          calls: action.payload.innerCalls,
          ifOneOrganizationId: action.payload.ifOneOrganizationId,
          count: action.payload.count,
          counts: action.payload.counts,
        },
      }

    case constants.FETCH_INNER_CALLS_FAILURE:
      return {
        ...state,
        innerCallsList: {
          ...state.innerCallsList,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_ALL_REQUEST:
      return {
        ...state,
        innerCallsCountAll: {
          ...state.innerCallsCountAll,
          isFetching: true,
          count: 0,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_ALL_SUCCESS:
      return {
        ...state,
        innerCallsCountAll: {
          ...state.innerCallsCountAll,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_ALL_FAILURE:
      return {
        ...state,
        innerCallsCountAll: {
          ...state.innerCallsCountAll,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_CREATED_REQUEST:
      return {
        ...state,
        innerCallsCountCreated: {
          ...state.innerCallsCountCreated,
          isFetching: true,
          count: 0,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_CREATED_SUCCESS:
      return {
        ...state,
        innerCallsCountCreated: {
          ...state.innerCallsCountCreated,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_CREATED_FAILURE:
      return {
        ...state,
        innerCallsCountCreated: {
          ...state.innerCallsCountCreated,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_CLOSED_REQUEST:
      return {
        ...state,
        innerCallsCountClosed: {
          ...state.innerCallsCountClosed,
          isFetching: true,
          count: 0,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_CLOSED_SUCCESS:
      return {
        ...state,
        innerCallsCountClosed: {
          ...state.innerCallsCountClosed,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_COUNT_CLOSED_FAILURE:
      return {
        ...state,
        innerCallsCountClosed: {
          ...state.innerCallsCountClosed,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_NUMBERS_REQUEST:
      return {
        ...state,
        innerCallsNumbers: {
          ...state.innerCallsNumbers,
          isFetching: true,
          numbers: [],
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_NUMBERS_SUCCESS:
      return {
        ...state,
        innerCallsNumbers: {
          ...state.innerCallsNumbers,
          isFetching: false,
          numbers: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_NUMBERS_FAILURE:
      return {
        ...state,
        innerCallsNumbers: {
          ...state.innerCallsNumbers,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_ENGINEERS_REQUEST:
      return {
        ...state,
        innerCallsEngineers: {
          ...state.innerCallsEngineers,
          isFetching: true,
          engineers: [],
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_ENGINEERS_SUCCESS:
      return {
        ...state,
        innerCallsEngineers: {
          ...state.innerCallsEngineers,
          isFetching: false,
          engineers: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_ENGINEERS_FAILURE:
      return {
        ...state,
        innerCallsEngineers: {
          ...state.innerCallsEngineers,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_EQUIPMENTS_REQUEST:
      return {
        ...state,
        innerCallsEquipments: {
          ...state.innerCallsEquipments,
          isFetching: true,
          equipments: [],
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        innerCallsEquipments: {
          ...state.innerCallsEquipments,
          isFetching: false,
          equipments: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_EQUIPMENTS_FAILURE:
      return {
        ...state,
        innerCallsEquipments: {
          ...state.innerCallsEquipments,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.SET_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
      }

    case constants.CLEAR_PAGINATION:
      return {
        ...state,
        page: initialState.page,
      }

    case constants.FETCH_INNER_CALL_REQUEST:
      return {
        ...state,
        innerCall: {
          ...state.innerCall,
          isFetching: true,
          error: null,
          permissions: [],
        },
      }

    case constants.FETCH_INNER_CALL_SUCCESS:
      return {
        ...state,
        innerCall: {
          ...state.innerCall,
          isFetching: false,
          call: action.payload.innerCall,
          permissions: action.payload.permissions,
        },
      }

    case constants.FETCH_INNER_CALL_FAILURE:
      return {
        ...state,
        innerCall: {
          ...state.innerCall,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_HISTORY_REQUEST:
      return {
        ...state,
        innerCallHistory: {
          ...state.innerCallHistory,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_HISTORY_SUCCESS:
      return {
        ...state,
        innerCallHistory: {
          ...state.innerCallHistory,
          isFetching: false,
          history: [
            ...(state.innerCallHistory.history || []),
            ...action.payload.history,
          ],
          end: action.payload.history.length === 0 || action.payload.end,
        },
      }

    case constants.FETCH_INNER_CALL_HISTORY_FAILURE:
      return {
        ...state,
        innerCallHistory: {
          ...state.innerCallHistory,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CLEAR_INNER_CALL_HISTORY:
      return {
        ...state,
        innerCallHistory: initialState.innerCallHistory,
      }

    case constants.FETCH_INNER_CALL_PHOTOS_REQUEST:
      return {
        ...state,
        innerCallPhotos: {
          ...state.innerCallPhotos,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_PHOTOS_SUCCESS:
      return {
        ...state,
        innerCallPhotos: {
          ...state.innerCallPhotos,
          isFetching: false,
          photos: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_PHOTOS_FAILURE:
      return {
        ...state,
        innerCallPhotos: {
          ...state.innerCallPhotos,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_PHOTOS_COUNT_REQUEST:
      return {
        ...state,
        innerCallPhotosCount: {
          ...state.innerCallPhotosCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_PHOTOS_COUNT_SUCCESS:
      return {
        ...state,
        innerCallPhotosCount: {
          ...state.innerCallPhotosCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_PHOTOS_COUNT_FAILURE:
      return {
        ...state,
        innerCallPhotosCount: {
          ...state.innerCallPhotosCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPLOAD_INNER_CALL_PHOTO_REQUEST:
      return {
        ...state,
        uploadPhoto: {
          ...state.uploadPhoto,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPLOAD_INNER_CALL_PHOTO_SUCCESS:
      return {
        ...state,
        uploadPhoto: {
          ...state.uploadPhoto,
          isFetching: false,
        },
      }

    case constants.UPLOAD_INNER_CALL_PHOTO_FAILURE:
      return {
        ...state,
        uploadPhoto: {
          ...state.uploadPhoto,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_INNER_CALL_PHOTO_REQUEST:
      return {
        ...state,
        deletePhoto: {
          ...state.deletePhoto,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_INNER_CALL_PHOTO_SUCCESS:
      return {
        ...state,
        deletePhoto: {
          ...state.deletePhoto,
          isFetching: false,
        },
      }

    case constants.DELETE_INNER_CALL_PHOTO_FAILURE:
      return {
        ...state,
        deletePhoto: {
          ...state.deletePhoto,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_REQUEST:
      return {
        ...state,
        innerCallAnalyticSummary: {
          ...state.innerCallAnalyticSummary,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_SUCCESS:
      return {
        ...state,
        innerCallAnalyticSummary: {
          ...state.innerCallAnalyticSummary,
          isFetching: false,
          summary: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_FAILURE:
      return {
        ...state,
        innerCallAnalyticSummary: {
          ...state.innerCallAnalyticSummary,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_REQUEST:
      return {
        ...state,
        innerCallAnalyticSummaryAverage: {
          ...state.innerCallAnalyticSummaryAverage,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_SUCCESS:
      return {
        ...state,
        innerCallAnalyticSummaryAverage: {
          ...state.innerCallAnalyticSummaryAverage,
          isFetching: false,
          summaryAverage: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_SUMMARY_AVERAGE_FAILURE:
      return {
        ...state,
        innerCallAnalyticSummaryAverage: {
          ...state.innerCallAnalyticSummaryAverage,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_REQUEST:
      return {
        ...state,
        innerCallsChartCreated: {
          ...state.innerCallsChartCreated,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_SUCCESS:
      return {
        ...state,
        innerCallsChartCreated: {
          ...state.innerCallsChartCreated,
          isFetching: false,
          chartCreated: action.payload.chart_info,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_CREATED_FAILURE:
      return {
        ...state,
        innerCallsChartCreated: {
          ...state.innerCallsChartCreated,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_REQUEST:
      return {
        ...state,
        innerCallsChartAverage: {
          ...state.innerCallsChartAverage,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_SUCCESS:
      return {
        ...state,
        innerCallsChartAverage: {
          ...state.innerCallsChartAverage,
          isFetching: false,
          chartAverage: action.payload.chart_info,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_AVERAGE_FAILURE:
      return {
        ...state,
        innerCallsChartAverage: {
          ...state.innerCallsChartAverage,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_REQUEST:
      return {
        ...state,
        innerCallsChartClosedByEngineer: {
          ...state.innerCallsChartClosedByEngineer,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_SUCCESS:
      return {
        ...state,
        innerCallsChartClosedByEngineer: {
          ...state.innerCallsChartClosedByEngineer,
          isFetching: false,
          chartClosedByEngineer: action.payload.chart_info,
        },
      }

    case constants.FETCH_INNER_CALL_ANALYTICS_CHART_CLOSED_FAILURE:
      return {
        ...state,
        innerCallsChartClosedByEngineer: {
          ...state.innerCallsChartAverage,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_REQUEST:
      return {
        ...state,
        innerCallsEngineerCount: {
          ...state.innerCallsEngineerCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_SUCCESS:
      return {
        ...state,
        innerCallsEngineerCount: {
          ...state.innerCallsEngineerCount,
          isFetching: false,
          count: action.payload.count,
        },
      }

    case constants.FETCH_INNER_CALLS_ENGINEERS_COUNT_FAILURE:
      return {
        ...state,
        innerCallsEngineerCount: {
          ...state.innerCallsEngineerCount,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default innerCalls
