import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import BreadCrumb from './BreadCrumb'
import ListRequest from '../../ServiceDesk/ListRequest'
import OrdersRouter from './OrdersRouter'
import CallProfile from '../../ServiceDesk/CallProfile'
import ServiceDeskBanner from '../../ServiceDesk/ServiceDeskBanner'
import CreateRequest from '../../ServiceDesk/CreateRequest'
import ServiceDeskContractsRouter from './ServiceDeskContractsRouter'
import CallHistory from '../../ServiceDesk/CallHistory'
import ActionsHistory from '../../ServiceDesk/SDCallActionsHistory'

const TabsHandler = ({
  match: {
    url: _url,
    path: _path,
    params: { listOf },
  },
}) => (
  <Fragment>
    <BreadCrumb to={_url}>
      {i18next.t(
        `service_desk_web_atoir:${
          listOf === 'calls' ? 'ListRequest' : 'listOrders'
        }`,
      )}
    </BreadCrumb>
    <Switch>
      <WrapperRoute
        path={`${_path}/:idRequest/orders`}
        title={i18next.t('equipment_web_atoir:editEquipmentProfileTitle')}
        component={OrdersRouter}
      />
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        path={`${_path}/:id/history`}
        component={CallHistory}
      />
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        path={`${_path}/:id/actions_history`}
        component={ActionsHistory}
      />
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        path={`${_path}/:id`}
        component={CallProfile}
      />
      <WrapperRoute
        path={`${_path}/`}
        component={ListRequest}
        title={i18next.t(
          'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepair',
        )}
      />
    </Switch>
  </Fragment>
)

const ServiceDeskRouter = ({ match: { url, path } }) => (
  <Fragment>
    <BreadCrumb to={`${url}`} notClickable>
      {i18next.t('service_desk_web_atoir:repairs')}
    </BreadCrumb>
    <ServiceDeskBanner />
    <Switch>
      <WrapperRoute
        path={`${path}/contracts`}
        title={i18next.t('service_desk_web_atoir:contracts')}
        component={ServiceDeskContractsRouter}
      />
      <WrapperRoute path={`${path}/create`} component={CreateRequest} />
      <WrapperRoute
        path={[`${path}/:listOf/:tab`, `${path}/:listOf`]}
        title={i18next.t('equipment_web_atoir:equipmentCrumbTitle')}
        component={TabsHandler}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default ServiceDeskRouter
