import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import shortId from 'shortid'

// i18next
import i18next from '../../i18n/i18n'

// material
import AccountCircle from '@material-ui/icons/AccountCircle'
import IconReply from '@material-ui/icons/Reply'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography/Typography'
import { lighten } from '@material-ui/core/styles/colorManipulator'

// icons
import AttachFileIcon from '@material-ui/icons/AttachFile'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

// helpers
import { convertTimestampVerbose } from '../../helpers/dateHelper'
import { getUserFullName } from '../../helpers/userHelper'

const stylesCommentComponent = theme => ({
  divRoot: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  divAvatar: {
    flexBasis: theme.typography.pxToRem(50),
    flexGrow: 0,
  },
  divCentralArea: {
    flexBasis: theme.typography.pxToRem(100),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  divHead: {
    display: 'flex',
  },
  divTitle: {
    flexBasis: theme.typography.pxToRem(100),
    flexGrow: 1,
  },
  divReply: {
    flexBasis: theme.typography.pxToRem(50),
    flexGrow: 0,
  },
  listToggle: {},
  iconImg: {
    fontSize: theme.typography.pxToRem(16),
  },
  divDate: {
    marginLeft: theme.typography.pxToRem(10),
  },
  divFiles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.typography.pxToRem(16),
  },
  dateStyle: {
    color: `${lighten(theme.palette.secondary.light, 0.1)}`,
    fontWeight: '500',
  },
  replyBtn: {
    padding: theme.typography.pxToRem(8),
  },
  customBtn: {
    color: theme.palette.main.main,
    padding: 0,
    margin: 0,
    textTransform: 'none',
    minHeight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const CommentComponent = withStyles(stylesCommentComponent)(props => {
  const {
    classes,
    person,
    personRole,
    date,
    comment,
    doReplyFunc,
    getPreviewPath,
    files,
  } = props
  return (
    <div className={classes.divRoot}>
      <div className={classes.divAvatar}>
        <AccountCircle fontSize="large" color="secondary" />
      </div>
      <div className={classes.divCentralArea}>
        <div className={classes.divHead}>
          <div className={classes.divTitle}>
            <div style={{ display: 'flex' }}>
              <Typography variant="body1">{person}</Typography>
              <div className={classes.divDate}>
                <Typography
                  className={classes.dateStyle}
                  variant="body1"
                  color="secondary"
                >
                  {convertTimestampVerbose(date)}
                </Typography>
              </div>
            </div>
            <div>
              <Typography variant="caption">{personRole}</Typography>
            </div>
          </div>
          <div className={classes.divReply}>
            {doReplyFunc && (
              <IconButton
                aria-label={i18next.t('equipment_web_atoir:addEquipment')}
                onClick={doReplyFunc}
                className={classes.replyBtn}
              >
                <IconReply />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.divComment}>
          <Typography style={{ wordBreak: 'break-word' }}>{comment}</Typography>
        </div>
        <div>
          {files && files.length > 0 && (
            <div className={classes.divFiles}>
              {files.map(file => (
                <Button
                  key={file.name}
                  onClick={() => {
                    getPreviewPath(file.id)
                  }}
                  className={classes.customBtn}
                >
                  <Typography align="left">
                    <AttachFileIcon
                      className={classes.iconImg}
                      color="secondary"
                    />
                    <span>{file.name}</span>
                  </Typography>
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

CommentComponent.propTypes = {
  person: PropTypes.string.isRequired,
  personRole: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  getPreviewPath: PropTypes.func.isRequired,
  // fileDownload: PropTypes.func.isRequired,  - can be useful later
}

const styles = theme => ({
  rootDiv: {
    border: theme.typography.pxToRem(1),
    borderStyle: 'solid',
    borderColor: `${lighten(theme.palette.secondary.light, 0.85)}`,
    borderRadius: theme.typography.pxToRem(3),
    padding: theme.typography.pxToRem(24),
    backgroundColor: '#fff',
  },
  divSubComment: {
    marginLeft: theme.typography.pxToRem(50),
  },
  customBtn: {
    color: theme.palette.main.main,
    padding: 0,
    margin: 0,
    textTransform: 'none',
    minHeight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

class CommentComponentWrapper extends Component {
  state = {
    isCollapsed: true,
  }

  clickWrapper = () => {
    this.setState(state => ({ isCollapsed: !state.isCollapsed }))
  }

  fileDownload = file => {
    const { downloadFileRequest, fileUrlCreator } = this.props

    downloadFileRequest({
      id: shortId.generate(),
      url: fileUrlCreator(file),
      name: file.name,
    })
  }

  render() {
    const {
      props: { comment, doReplyFunc, classes, fileUrlCreator, getPreviewPath },
      state: { isCollapsed },
      fileDownload,
    } = this
    return (
      <div className={classes.rootDiv}>
        <CommentComponent
          person={getUserFullName(comment.OrganizationUser)}
          personRole={`${comment.OrganizationUser.Organization.full_name}`}
          date={comment.createdAt}
          comment={comment.message}
          doReplyFunc={() => {
            doReplyFunc(comment.id)
          }}
          files={comment.ServiceCallOrderCommentFiles}
          fileUrlCreator={fileUrlCreator}
          fileDownload={fileDownload}
          getPreviewPath={getPreviewPath}
        />
        {comment.child_comments.length > 0 && (
          <div className={classes.divSubComment}>
            {comment.child_comments.length < 4 &&
              comment.child_comments.map(subcomment => (
                <CommentComponent
                  key={shortId.generate()}
                  person={getUserFullName(subcomment.OrganizationUser)}
                  personRole={`${subcomment.OrganizationUser.Organization.full_name}`}
                  date={subcomment.createdAt}
                  comment={subcomment.message}
                  files={subcomment.ServiceCallOrderCommentFiles}
                  fileUrlCreator={fileUrlCreator}
                  fileDownload={fileDownload}
                  getPreviewPath={getPreviewPath}
                />
              ))}
            {comment.child_comments.length > 3 && (
              <Fragment>
                <Button
                  disableRipple
                  onClick={this.clickWrapper}
                  className={classes.customBtn}
                >
                  {isCollapsed ? (
                    <Fragment>
                      {i18next.t('service_desk_web_atoir:moreCommentCount', {
                        commentCountMore: comment.child_comments.length - 3,
                      })}
                      <ArrowDropDown />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {i18next.t('service_desk_web_atoir:hideComments')}
                      <ArrowDropUp />
                    </Fragment>
                  )}
                </Button>
                {comment.child_comments
                  .slice(0, isCollapsed ? 3 : comment.child_comments.length)
                  .map(subcomment => (
                    <CommentComponent
                      key={shortId.generate()}
                      person={getUserFullName(subcomment.OrganizationUser)}
                      personRole={`${subcomment.OrganizationUser.Organization.full_name}`}
                      date={subcomment.createdAt}
                      comment={subcomment.message}
                      files={subcomment.ServiceCallOrderCommentFiles}
                      fileUrlCreator={fileUrlCreator}
                      fileDownload={fileDownload}
                      getPreviewPath={getPreviewPath}
                    />
                  ))}
              </Fragment>
            )}
          </div>
        )}
      </div>
    )
  }
}

CommentComponentWrapper.propTypes = {
  comment: PropTypes.object.isRequired,
  doReplyFunc: PropTypes.func.isRequired,
  fileUrlCreator: PropTypes.func.isRequired,
  downloadFileRequest: PropTypes.func.isRequired,
  getPreviewPath: PropTypes.func.isRequired,
}

export default withStyles(styles)(CommentComponentWrapper)
