import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AddEquipment from './AddEquipment'
import {
  clearRequestDataOnCreation,
  createServiceDeskRequest,
  fetchContractAvailableEquipmentRequest,
  fetchContractRequest,
  fetchServiceDeskContractAvailableEquipmentCountRequest,
  addEquipmentToContractsRequest,
} from '../../../../actions/serviceDesk'
import {
  fetchEquipmentFilterLocationRequest,
  fetchEquipmentFilterDepartmentRequest,
} from '../../../../actions/equipmentActions'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../../actions/utilsActions'
import { setAddContractsEquipmentRowsPerPage } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isCreatingRequest: state.serviceDesk.createRequest.isFetching,
  creatingRequestError: state.serviceDesk.createRequest.error,
  callId: state.serviceDesk.createRequest.id,
  availableEquipment: state.serviceDesk.contract_available_equipment.equipment,
  availableEquipmentIsFetching:
    state.serviceDesk.contract_available_equipment.isFetching,
  availableEquipmentFetchingError:
    state.serviceDesk.contract_available_equipment.error,
  contract: state.serviceDesk.contract.contract,
  contractIsFetching: state.serviceDesk.contract.isFetching,
  contractFetchingError: state.serviceDesk.contract.error,
  equipmentCount: state.serviceDesk.contract_available_equipment_count.count,
  equipmentCountIsFetching:
    state.serviceDesk.contract_available_equipment_count.isFetching,
  equipmentCountFetchingError:
    state.serviceDesk.contract_available_equipment_count.error,
  locationFilterData: state.equipment.equipmentFilterLocation.addresses,
  locationFilterDataIsFetching:
    state.equipment.equipmentFilterLocation.isFetching,
  locationFilterDataError: state.equipment.equipmentFilterLocation.error,
  departmentFilterData: state.equipment.equipmentFilterDepartment.departments,
  departmentFilterDataIsFetching:
    state.equipment.equipmentFilterDepartment.isFetching,
  departmentFilterDataError: state.equipment.equipmentFilterDepartment.error,
  equipmentAddIsFetching: state.serviceDesk.addEquipmentToContracts.isFetching,
  equipmentAddError: state.serviceDesk.addEquipmentToContracts.error,
  limit: state.serviceDeskContracts.addEquipments.rowsPerPage,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showDialogLoader,
      hideDialogLoader,
      createServiceDeskRequest,
      clearRequestDataOnCreation,
      fetchContractAvailableEquipmentRequest,
      fetchContractRequest,
      fetchCount: fetchServiceDeskContractAvailableEquipmentCountRequest,
      fetchFilterLocation: fetchEquipmentFilterLocationRequest,
      fetchFilterDepartment: fetchEquipmentFilterDepartmentRequest,
      addEquipmentToContractsRequest,
      setAddContractsEquipmentRowsPerPage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEquipment),
)
