import React from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { editOrderInfo } from '../../../../actions/serviceDesk'

export default function withSaveVisitDateHOC(WrappedComponent) {
  class SaveVisitDateHOC extends React.Component {
    saveVisitDate = (idOrder, date) => {
      const {
        props: { callEditOrderInfo },
      } = this
      const payload = {
        id: idOrder,
        data: {
          visit_date: date,
        },
      }
      callEditOrderInfo(payload)
    }

    render() {
      const {
        props: { isFetching },
      } = this
      return (
        <WrappedComponent
          onSave={this.saveVisitDate}
          visitDateSaving={isFetching}
          {...this.props}
        />
      )
    }
  }

  SaveVisitDateHOC.displayName = `withSaveVisitDateHOC(${getDisplayName(
    WrappedComponent,
  )})`

  return connect(mapStateToProps, mapDispatchToProps)(SaveVisitDateHOC)
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const mapStateToProps = state => ({
  isFetching: state.serviceDesk.editOrderInfo.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callEditOrderInfo: editOrderInfo,
    },
    dispatch,
  )
