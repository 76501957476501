import TextField from '@material-ui/core/TextField'
import React from 'react'
import shallowEqual from 'shallowequal'

import AddressSelector from './SelectFields/AddressSelector'
import DepartmentSelector from './SelectFields/DepartmentSelector'
import ResponsibleSelector from './SelectFields/ResponsibleSelector'
import FloorSelector from './SelectFields/FloorSelector'
import i18next from '../../../i18n/i18n'

function OrgStructureInfo({
  setFieldValueHandler,
  values,
  organizationId,
  errors,
  touched,
}) {
  return (
    <React.Fragment>
      <AddressSelector
        required
        error={
          errors.organization_location_id && touched.organization_location_id
        }
        onChange={setFieldValueHandler}
        idValue={fields.organization_location_id}
        organizationId={organizationId}
        value={values.organization_location_id}
      />
      <DepartmentSelector
        onChange={setFieldValueHandler}
        idValue={fields.organization_department_id}
        organizationId={organizationId}
        value={values.organization_department_id}
      />
      <FloorSelector
        onChange={setFieldValueHandler}
        idValue={fields.floor}
        value={values.floor || ''}
      />
      <TextField
        value={values.cabinet || ''}
        fullWidth
        id={fields.cabinet}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:cabinet')}
      />
      <ResponsibleSelector
        onChange={setFieldValueHandler}
        idValue={fields.mol_user_organization_id}
        organizationId={organizationId}
        value={values.mol_user_organization_id}
      />
    </React.Fragment>
  )
}

const fields = {
  organization_location_id: 'organization_location_id',
  organization_department_id: 'organization_department_id',
  floor: 'floor',
  cabinet: 'cabinet',
  mol_user_organization_id: 'mol_user_organization_id',
}
const fieldsList = _.keys(fields)

function isAllFieldsEqual(_old, _new) {
  return shallowEqual(_.pick(_old, fieldsList), _.pick(_new, fieldsList))
}

function arePropsEqual(prevProps, nextProps) {
  return (
    isAllFieldsEqual(prevProps.values, nextProps.values) &&
    isAllFieldsEqual(prevProps.errors, nextProps.errors) &&
    isAllFieldsEqual(prevProps.touched, nextProps.touched) &&
    prevProps.setFieldValueHandler === nextProps.setFieldValueHandler
  )
}

export default React.memo(OrgStructureInfo, arePropsEqual)
