import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UserSettings from './UserSettings'
import { clearFilterDrawer, setFilterDrawer } from '../../../actions/appActions'

const mapStateToProps = state => ({
  userSettings: state.app.userSettings.value,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearFilterDrawer,
      setFilterDrawer,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSettings),
)
