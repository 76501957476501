import React, { Fragment } from 'react'

import { withStyles } from '@material-ui/core/styles/index'
import List from '@material-ui/core/List'

import config from '../../../../config'
import navigationStructure from './navigationStructure'

import NavigationItemSimple from './NavigationItemSimple'
import NavigationItemNested from './NavigationItemNested'

const drawerWidth = config.left_drawer_width

const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerList: {
    paddingTop: theme.spacing(1),
  },
  drawerItem: { paddingLeft: 12 },
  ListItemChild: {
    padding: '0px',
    marginRight: '4px',
  },
  ListItemChildText: {
    textOverflow: 'clip',
  },
  nested: {
    paddingLeft: theme.spacing(7),
  },
  noImage: {
    paddingLeft: 16,
  },
  nestedNoImage: {
    paddingLeft: theme.spacing(4),
  },
  lightTooltip: {
    backgroundColor: 'rgba(235, 235, 235, 1)',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: 200,
  },
})

function NavigationItem({ item, classes, ...otherProps }) {
  const Component = item.nested ? NavigationItemNested : NavigationItemSimple
  return <Component item={item} classesExternal={classes} {...otherProps} />
}

function ListRenderer({
  classes,
  setCollapsed = () => {},
  collapsed,
  noImage = false,
}) {
  return (
    <Fragment>
      <List component="nav" className={classes.drawerList}>
        {navigationStructure().map(item => (
          <NavigationItem
            key={item.id}
            item={item}
            classes={classes}
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            noImage={noImage}
            classForSet={noImage && classes.noImage}
          />
        ))}
      </List>
    </Fragment>
  )
}
export default withStyles(styles)(ListRenderer)
