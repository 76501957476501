import TextField from '@material-ui/core/TextField'
import React from 'react'
import shallowEqual from 'shallowequal'

import CountrySelect from './SelectFields/CountrySelect'
import IndustriesSelector from '../common/IndustriesSelector'
import i18next from '../../../i18n/i18n'

function CommonInfo({
  setFieldValueHandler,
  handleBlur,
  values,
  errors,
  touched,
  disabled = false,
}) {
  return (
    <React.Fragment>
      <TextField
        disabled={disabled}
        value={values.name || ''}
        required
        error={errors.name && touched.name}
        onBlur={handleBlur}
        fullWidth
        id={fields.name}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:equipmentName')}
      />
      <TextField
        disabled={disabled}
        fullWidth
        value={values.vendor || ''}
        id={fields.vendor}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:vendor')}
      />
      <TextField
        disabled={disabled}
        fullWidth
        value={values.model || ''}
        id={fields.model}
        onChange={setFieldValueHandler}
        label={i18next.t('equipment_web_atoir:model')}
      />
      <CountrySelect
        disabled={disabled}
        idValue={fields.country_id}
        value={values.country_id || {}}
        required
        error={Boolean(errors.country_id && touched.country_id)}
        onBlur={handleBlur}
        fullWidth
        onChange={setFieldValueHandler}
      />
      <IndustriesSelector
        disabled={disabled}
        value={values.industry_id || ''}
        idValue={fields.industry_id}
        onChange={setFieldValueHandler}
      />
    </React.Fragment>
  )
}

const fields = {
  name: 'name',
  vendor: 'vendor',
  model: 'model',
  country_id: 'country_id',
  industry_id: 'industry_id',
}

const fieldsList = _.keys(fields)

function isAllFieldsEqual(_old, _new) {
  return shallowEqual(_.pick(_old, fieldsList), _.pick(_new, fieldsList))
}

function arePropsEqual(prevProps, nextProps) {
  return (
    isAllFieldsEqual(prevProps.values, nextProps.values) &&
    isAllFieldsEqual(prevProps.errors, nextProps.errors) &&
    isAllFieldsEqual(prevProps.touched, nextProps.touched) &&
    prevProps.setFieldValueHandler === nextProps.setFieldValueHandler &&
    prevProps.disabled === nextProps.disabled
  )
}

export default React.memo(CommonInfo, arePropsEqual)
