import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import InventoryTable from './InventoryTable'

const mapStateToProps = state => ({
  isFetchingInventoryProjects:
    state.inventory.fetchInventoryProjects.isFetchingInventoryProjects,
  inventoryProjects: state.inventory.fetchInventoryProjects.inventoryProjects,
  fetchInventoryProjectsErrors:
    state.inventory.fetchInventoryProjects.fetchInventoryProjectsErrors,
  isFetchingInventoryProjectsCount:
    state.inventory.fetchInventoryProjectsCount
      .isFetchingInventoryProjectsCount,
  count: state.inventory.fetchInventoryProjectsCount.count,
  fetchCountErrors:
    state.inventory.fetchInventoryProjectsCount.fetchCountErrors,
  isFetchingCities: state.inventory.inventoryProjectsCities.isFetchingCities,
  cities: state.inventory.inventoryProjectsCities.cities,
  fetchCitiesErrors: state.inventory.inventoryProjectsCities.fetchCitiesErrors,
  isFetchingClients: state.inventory.inventoryClients.isFetching,
  fetchedClientsSuccessfully:
    state.inventory.inventoryClients.fetchedSuccessfully,
  clients: state.inventory.inventoryClients.clients,
  fetchClientsErrors: state.inventory.inventoryClients.errors,
  isFetchingPerformers: state.inventory.inventoryPerformers.isFetching,
  fetchedPerformersSuccessfully:
    state.inventory.inventoryPerformers.fetchedSuccessfully,
  performers: state.inventory.inventoryPerformers.performers,
  fetchPerformersErrors: state.inventory.inventoryPerformers.errors,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryTable),
)
