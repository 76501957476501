import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  fetchLocation,
  fetchLocations,
  fetchLocationsCount,
  deleteLocation,
} from '../../actions/locationsActions'
import {
  fetchDepartment,
  fetchDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  fetchDepartmentsCount,
} from '../../actions/departmentsActions'
import OrganizationStructureComponent from '../../components/Organizations/Profile/OrganizationStructureComponent'
import { fetchOrganization } from '../../actions/organizationsActions'

const mapStateToProps = state => ({
  isActivatingAccount: state.isActivatingAccount,
  organization: state.organizations.organization,
  isFetchingLocations: state.locations.isFetchingLocations,
  isDeletingLocations: state.locations.deleteLocation.isDeletingLocations,
  deleteLocationsError: state.locations.deleteLocation.errors,
  isCreatingLocation: state.locations.isCreatingLocation,
  isFetchingDepartments: state.departments.isFetchingDepartment,
  isCreatingDepartment: state.departments.isCreatingDepartment,
  isUpdatingDepartment: state.departments.isUpdatingDepartment,
  isDeletingDepartment: state.departments.isDeletingDepartment,
  errors: state.departments.errors,
  locations: state.locations.locations,
  physical: state.locations.physical,
  locationsCount: state.locations.count,
  departments: state.departments.departments,
  departmentsCount: state.departments.count,
  department: state.departments.department,
  addDepartmentOpen: state.departments.addDepartmentOpen,
  updateDepartmentOpen: state.departments.updateDepartmentOpen,
  showDeleteDepartmentDialog: state.departments.showDeleteDepartmentDialog,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganization,
      fetchLocation,
      fetchLocations,
      fetchLocationsCount,
      fetchDepartment,
      fetchDepartments,
      fetchDepartmentsCount,
      createDepartment,
      updateDepartment,
      deleteDepartment,
      deleteLocation,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationStructureComponent),
)
