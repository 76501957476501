import React from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import ContactForm from '../ContactForm'
import TextButton from '../../../widgets/Buttons/TextButton'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'

// material-ui-icons
import DeleteIcon from '@material-ui/icons/Close'

const styles = () => ({
  upper: {
    zIndex: '100',
  },
  flexDiv: {
    display: 'flex',
  },
})

class ContactsFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.contactsRefs = new Set()
    this.state = {
      contacts: props.contacts
        ? props.onCreation
          ? props.contacts.map(c => ({ ...c, isNew: true, isValid: undefined }))
          : props.contacts.map(c => ({ ...c, isNew: false, isValid: true }))
        : [],
      isOnDeleteContactOpen: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
    }
  }

  componentWillReceiveProps(props) {
    if (
      props.isDeletingContact !== this.props.isDeletingContact &&
      props.isDeletingContact === false
    ) {
      if (props.error) {
        switch (props.error) {
          case 60:
            this.showSnackBar(i18next.t('error_web_atoir:dataValidationError'))
            break
          case 80:
            this.showSnackBar(
              i18next.t('validation_web_atoir:canNotDeleteSingleContact'),
            )
            break
          default:
            this.showSnackBar(i18next.t('error_web_atoir:error'))
            break
        }
        this.setState(
          state => ({
            ...props,
            contacts: state.contacts,
          }),
          () => this.updateParentState(),
        )
      } else {
        const { onDeleteContact, contacts } = this.state
        this.showSnackBar(i18next.t('organizations_web_atoir:contactDeleted'))
        this.setState(
          {
            ...props,
            contacts: contacts.filter(
              c => c.id !== (onDeleteContact ? onDeleteContact.id : undefined),
            ),
          },
          () => {
            this.updateParentState()
          },
        )
      }
    }
  }

  componentWillMount = () => {
    this.updateParentState()
  }

  handleContactChanged = contact => {
    this.setState(
      state => {
        const newContacts = [...state.contacts]
        newContacts[
          _.findIndex(newContacts, o => o.id === contact.id)
        ] = contact
        return {
          contacts: newContacts,
        }
      },
      () => {
        this.updateParentState()
      },
    )
  }

  addContact = () => {
    this.setState(
      state => ({
        contacts: [
          ...state.contacts,
          { id: Date.now(), isNew: true, isValid: false },
        ],
      }),
      () => {
        this.updateParentState()
      },
    )
  }

  onCloseClicked = contact => () => {
    this.setState(
      state => ({
        contacts: state.contacts.filter(e => e.id !== contact.id),
      }),
      () => {
        this.updateParentState()
      },
    )
    this.clearContactsRefs(contact.id)
  }

  updateParentState = () => {
    const isContactFormValid = !this.state.contacts.some(c => !c.isValid)
    this.props.handleContactsChanged(this.state.contacts, isContactFormValid)
  }

  handleCloseDeleteDialog = () => {
    this.setState({
      isOnDeleteContactOpen: false,
    })
  }

  handleOpenDeleteDialog = contact => () => {
    this.setState({
      isOnDeleteContactOpen: true,
      onDeleteContact: contact,
    })
  }

  handleDeleteContact = () => {
    const {
      onDeleteContact: { id },
    } = this.state
    this.setState(
      {
        isOnDeleteContactOpen: false,
      },
      () => {
        this.clearContactsRefs(id)
      },
    )
    this.props.deleteContact(this.props.organization.id, id)
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  getDeleteContactDialog = () => (
    <Dialog
      open
      onClose={this.handleCloseDeleteDialog}
      aria-labelledby="alert-dialog-title-1"
      aria-describedby="alert-dialog-description1"
    >
      <DialogTitle id="alert-dialog-title1">
        {i18next.t('organizations_web_atoir:deleteContactDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description1">
          {i18next.t('organizations_web_atoir:deleteContactDialogText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          onClick={this.handleCloseDeleteDialog}
          size="medium"
          autoFocus
        >
          {i18next.t('shared_web_atoir:cancel')}
        </OutlinedButton>
        <ContainedButton onClick={this.handleDeleteContact} size="medium">
          {i18next.t('shared_web_atoir:delete')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )

  getSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  clearContactsRefs = id => {
    this.contactsRefs.forEach(ref => {
      if (ref.props.contact.id === id) {
        this.contactsRefs.delete(ref)
      }
    })
  }

  validateAllContacts = () => {
    this.contactsRefs.forEach(ref => {
      if (ref._ismounted) ref.showError()
    })
  }

  render = () => {
    const { classes } = this.props
    const { contacts } = this.state
    const canBeRemoved = contacts.length > 1
    return (
      <div>
        {this.state.isOnDeleteContactOpen && this.getDeleteContactDialog()}
        {_.sortBy(contacts, 'id').map(contact => (
          <div key={contact.id}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <div className={classes.flexDiv}>
                  <ContactForm
                    key={contact.id}
                    contact={contact}
                    classes={classes}
                    handleContactChanged={this.handleContactChanged}
                    ref={ref => {
                      if (ref) this.contactsRefs.add(ref)
                    }}
                  />
                  {canBeRemoved && (
                    <IconButton
                      variant="fab"
                      aria-label="delete-contact"
                      component="span"
                      onClick={
                        contact.isNew
                          ? this.onCloseClicked(contact)
                          : this.handleOpenDeleteDialog(contact)
                      }
                      className={classes.button}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </div>
        ))}
        <br />
        <div className={classes.btnDiv}>
          <TextButton
            classes={{ root: classes.marginBtn }}
            onClick={this.addContact}
            size="medium"
          >
            {i18next.t('organizations_web_atoir:addContact')}
          </TextButton>
        </div>
        {this.getSnackBar()}
      </div>
    )
  }
}

ContactsFormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleContactsChanged: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactsFormComponent)
