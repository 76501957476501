import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  CREATE_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENTS_REQUEST,
  UPDATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_REQUEST,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_COUNT_REQUEST,
} from '../constants/actionTypes'
import {
  fetchDepartmentsRequest,
  fetchDepartmentRequest,
  createDepartmentRequest,
  updateDepartmentRequest,
  deleteDepartmentRequest,
  fetchUserEquipmentDepartmentsRequest,
  fetchDepartmentsCountRequest,
} from './api'
import {
  fetchDepartmentFailure,
  fetchDepartmentsFailure,
  fetchDepartmentsSuccess,
  fetchDepartmentSuccess,
  createDepartmentFailure,
  createDepartmentSuccess,
  updateDepartmentFailure,
  updateDepartmentSuccess,
  deleteDepartmentFailure,
  deleteDepartmentSuccess,
  fetchDepartmentsCountSuccess,
  fetchDepartmentsCountFailure,
} from '../actions/departmentsActions'
import {
  fetchUserEquipmentDepartmentsFailure,
  fetchUserEquipmentDepartmentsSuccess,
} from '../actions/equipmentActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'

export function* watchFetchDepartmentsSaga() {
  yield takeLatest(FETCH_DEPARTMENTS_REQUEST, fetchDepartments)
}

export function* watchFetchDepartmentsCountSaga() {
  yield takeLatest(FETCH_DEPARTMENTS_COUNT_REQUEST, fetchDepartmentsCount)
}

export function* watchFetchDepartmentSaga() {
  yield takeLatest(FETCH_DEPARTMENT_REQUEST, fetchDepartment)
}

export function* watchCreateDepartmentSaga() {
  yield takeLatest(CREATE_DEPARTMENT_REQUEST, createDepartment)
}

export function* watchUpdateDepartmentSaga() {
  yield takeLatest(UPDATE_DEPARTMENT_REQUEST, updateDepartment)
}

export function* watchDeleteDepartmentSaga() {
  yield takeLatest(DELETE_DEPARTMENT_REQUEST, deleteDepartment)
}

export function* watchFetchUserEquipmentDepartmentsSaga() {
  yield takeLatest(
    FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST,
    fetchUserEquipmentDepartments,
  )
}

function* fetchDepartments(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchDepartmentsRequest(accessToken, action.organizationId, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchDepartmentsSuccess(result.departments))
      } else {
        const errors = handleErrors(result)
        yield put(fetchDepartmentsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchDepartmentsCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchDepartmentsCountRequest(accessToken, action.organizationId),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchDepartmentsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchDepartmentsCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchDepartment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchDepartmentRequest(accessToken, action.organizationId, action.query),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchDepartmentSuccess(result.locations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchDepartmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserEquipmentDepartments(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserEquipmentDepartmentsRequest(accessToken, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserEquipmentDepartmentsSuccess(result.departments))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserEquipmentDepartmentsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createDepartment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      createDepartmentRequest(accessToken, action.organizationId, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(createDepartmentSuccess(result.id))
      } else {
        const errors = handleErrors(result)
        yield put(createDepartmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateDepartment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, departmentId, data } = action
    const result = yield call(
      updateDepartmentRequest(accessToken, organizationId, departmentId, data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateDepartmentSuccess(result.id))
      } else {
        const errors = handleErrors(result)
        yield put(updateDepartmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteDepartment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      deleteDepartmentRequest(
        accessToken,
        action.organizationId,
        action.departmentId,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(deleteDepartmentSuccess(result.id))
      } else {
        const errors = handleErrors(result)
        yield put(deleteDepartmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
