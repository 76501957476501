/* eslint-disable max-len */
import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import i18next from '../../../i18n/i18n'

const style = theme => ({
  colorTitle: {
    color: theme.palette.blueSecondary.main,
    minHeight: 24,
  },
  firstColumn: {
    paddingLeft: 0,
    paddingRight: 24,
  },
  secondColumn: {
    textAlign: 'right',
  },
  tRow: {
    height: 24,
  },
})

function CheckResultView({ classes, result }) {
  const { countOfAll, countWithError, countDublicates } = result
  return (
    <div>
      <div className={classes.colorTitle}>
        <Typography color="inherit">
          {i18next.t('equipment_web_atoir:checkingResult')}
        </Typography>
      </div>
      <table>
        <tbody>
          <tr className={classes.tRow}>
            <td className={classes.firstColumn}>
              <Typography>
                {i18next.t('equipment_web_atoir:totalToLoad')}
              </Typography>
            </td>
            <td className={classes.secondColumn}>
              <Typography component="span">
                {countOfAll >= 0 ? countOfAll : ''}
              </Typography>
            </td>
          </tr>
          <tr className={classes.tRow}>
            <td className={classes.firstColumn}>
              <Typography>
                {i18next.t('equipment_web_atoir:totalWithError')}
              </Typography>
            </td>
            <td className={classes.secondColumn}>
              <Typography component="span">
                {countWithError >= 0 ? countWithError : ''}
              </Typography>
            </td>
          </tr>
          <tr className={classes.tRow}>
            <td className={classes.firstColumn}>
              <Typography>
                {i18next.t('equipment_web_atoir:totalDubles')}
              </Typography>
            </td>
            <td className={classes.secondColumn}>
              <Typography component="span">
                {countDublicates >= 0 ? countDublicates : ''}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default withStyles(style)(CheckResultView)
