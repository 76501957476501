import {
  HIDE_DIALOG_LOADER,
  SHOW_DIALOG_LOADER,
} from '../constants/actionTypes'

const initialState = {
  showDialogLoader: false,
}

const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DIALOG_LOADER:
      return {
        ...state,
        showDialogLoader: true,
      }
    case HIDE_DIALOG_LOADER:
      return {
        ...state,
        showDialogLoader: false,
      }

    default:
      return state
  }
}

export default utilsReducer
