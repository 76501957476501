import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InnerCallProfile from './InnerCallProfile'
import { fetchInnerCallRequest } from '../../../../actions/innerCallsActions'

const mapStateToProps = state => ({
  isFetchingInnerCall: state.innerCalls.innerCall.isFetching,
  innerCall: state.innerCalls.innerCall.call,
  permissions: state.innerCalls.innerCall.permissions,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInnerCallRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerCallProfile),
)
