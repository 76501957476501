import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import i18next from '../../../../../i18n/i18n'

// helpers
import { getUserFullName } from '../../../../../helpers/userHelper'
import { addMaskCharacters } from '../../../../../helpers/phoneStringHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Edit from '@material-ui/icons/Edit'

const styles = theme => ({
  responsibleText: {
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 'normal',
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.main,
  },
})

const ResponsibleInfo = ({ responsible, onEdit, classes }) => (
  <Grid container flexDirection="row" alignItems="flex-start">
    <Grid item>
      <Typography className={classes.responsibleText}>
        {`${i18next.t('service_desk_web_atoir:responsiblePerson')}: `}
      </Typography>
    </Grid>
    <Grid item>
      <Typography className={classes.responsibleText}>
        {getUserFullName(responsible)}
      </Typography>
      <Typography className={classes.responsibleText}>
        {!isEmpty(responsible.position)
          ? responsible.position === 'owner'
            ? i18next.t('organizations_web_atoir:ownerRole')
            : responsible.position
          : ''}
      </Typography>
      <Typography className={classes.responsibleText}>
        {!isEmpty(responsible.phone)
          ? addMaskCharacters(responsible.phone)
          : ''}
      </Typography>
    </Grid>
    <Grid item>
      {!!onEdit && (
        <Grid item>
          <IconButton onClick={onEdit}>
            <Edit className={classes.icon} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  </Grid>
)

ResponsibleInfo.defaultProps = {
  onEdit: null,
}

ResponsibleInfo.propTypes = {
  responsible: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ResponsibleInfo)
