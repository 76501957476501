import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AccountActivationComponent from './AccountActivationComponent'
import {
  registerAccount,
  getConfirmLinkAgain,
} from '../../../actions/registerAccountActions'
import { closeErrorMessage } from '../../../actions/appActions'

const mapStateToProps = state => ({
  isLoggedIn: localStorage.getItem('loggedIn'),
  isActivatingAccount: state.registerAccount.isActivatingAccount,
  canContinueRegistration: state.registerAccount.canContinueRegistration,
  hash: state.registerAccount.hash,
  resendLinkEmail: state.registerAccount.resendLinkEmail,
  validationErrors: state.registerAccount.validationErrors,
  confirmEmailErrorsCode: state.registerAccount.confirmEmailErrorsCode,
  isConfirmingEmailRegistration:
    state.registerAccount.isConfirmingEmailRegistration,
  isRegisteringEmail: state.registerAccount.isRegisteringEmail,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerAccount,
      getConfirmLinkAgain,
      closeErrorMessage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountActivationComponent),
)
