import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import Typography from '@material-ui/core/Typography'

import { URL } from '../../../../../../middlewares/api'
import OutlinedButton from '../../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../../widgets/Buttons/ContainedButton'

import i18next from '../../../../../../i18n/i18n'
import SelectAuto from '../../../../../../widgets/SelectAuto'

const styles = theme => ({
  dialogContent: {
    minWidth: theme.typography.pxToRem(550),
    minHeight: theme.typography.pxToRem(40),
  },
})

// eslint-disable-next-line no-unused-vars
const TagRenderItemComponent = withStyles(theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 110px)',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
    },
  },
  organizationName: {
    paddingLeft: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  tagSection: {
    display: 'flex',
    alignItems: 'center',
  },
  tagName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 110px - 80px)',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
}))(({ item, classes }) => {
  const iconStyle = {
    size: 24,
    color: `#${item.color}` || 'rgba(0, 0, 0, 0.54)',
  }
  return (
    <div className={classes.container}>
      <div className={classes.tagSection}>
        <div>
          <NotificationImportant style={iconStyle} />
        </div>
        <div className={classes.tagName}>{item.name}</div>
      </div>
      <Typography
        component="div"
        variant="caption"
        className={classes.organizationName}
      >
        {item.organization_short_name}
      </Typography>
    </div>
  )
})

class AttachTagDialog extends React.Component {
  constructor(props) {
    super(props)
    this.setTagIsEmpty = _.once(tagIsEmpty => {
      this.setState({ tagIsEmpty })
    })
    this.state = {
      value: null,
      tagIsEmpty: true,
    }
  }

  setValue = value => {
    this.setState({
      value,
    })
  }

  attachTag = () => {
    const {
      state: { value },
      props: { attachSave },
    } = this
    attachSave(value)
  }

  render() {
    const {
      props: { open, cancel, callId, classes },
      state: { value, tagIsEmpty },
      setValue,
      attachTag,
    } = this
    return (
      <div>
        <Dialog
          open={open}
          onClose={cancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {i18next.t('organizations_web_atoir:addTag')}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <SelectAuto
              id="tag-select"
              fullWidth
              disabled={tagIsEmpty}
              helperText={
                tagIsEmpty
                  ? i18next.t('organizations_web_atoir:noTagsAvailable')
                  : ''
              }
              value={value}
              sourceUrl={`${URL}/service_calls/${callId}/tags`}
              onChange={setValue}
              itemTransformer={l => ({ id: l.id, title: l.name })}
              requestResultTransformerToArray={r => {
                this.setTagIsEmpty(!r.tags || r.tags.length === 0)
                return r.tags
              }}
              itemComponent={TagRenderItemComponent}
            />
          </DialogContent>
          <DialogActions>
            <OutlinedButton onClick={cancel} size="medium">
              {i18next.t('shared_web_atoir:close')}
            </OutlinedButton>
            <ContainedButton
              id="updateLocationComponentSubmitBtn"
              onClick={attachTag}
              disabled={!value}
              size="medium"
            >
              {i18next.t('shared_web_atoir:save')}
            </ContainedButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

AttachTagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  attachSave: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  callId: PropTypes.number.isRequired,
}

export default withStyles(styles)(AttachTagDialog)
