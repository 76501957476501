import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import EquipmentProfileContainer from '../../../containers/Equipment/Profile/EquipmentProfileContainer'
import AddEquipment from '../../Equipment/AddEquipmentUnit'
import EditEquipment from '../../Equipment/EditEquipmentUnit'
import EquipmentContainer from '../../../containers/Equipment/EquipmentContainer'
import ImportEquipments from '../../Equipment/ImportEquipments'
import ServiceDeskBanner from '../../ServiceDesk/ServiceDeskBanner'

const specificEquipmentRouter = ({ match: { path: _path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        path={`${_path}/edit`}
        title={i18next.t('equipment_web_atoir:editEquipmentUnit')}
        component={EditEquipment}
      />
      <WrapperRoute path={`${_path}/`} component={EquipmentProfileContainer} />
    </Switch>
  </Fragment>
)

const EquipmentRouter = ({ match: { path } }) => (
  <Fragment>
    {path.indexOf('/organizations') === -1 ? <ServiceDeskBanner /> : null}
    <Switch>
      <WrapperRoute
        path={`${path}/organization/:organizationId/:equipmentId/:tab`}
        title={i18next.t('equipment_web_atoir:equipmentCrumbTitle')}
        component={specificEquipmentRouter}
      />
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        title={i18next.t('equipment_web_atoir:addEquipmentUnitTitle')}
        path={`${path}/add/:organizationId`}
        component={AddEquipment}
      />
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        title={i18next.t('equipment_web_atoir:loadByEquipList')}
        path={`${path}/:organizationId/import_equipments/:backTo*`}
        component={ImportEquipments}
      />
      <WrapperRoute
        path={`${path}/:equipmentId/:tab`}
        title={i18next.t('equipment_web_atoir:equipmentCrumbTitle')}
        component={specificEquipmentRouter}
      />
      <WrapperRoute path={`${path}/`} component={EquipmentContainer} />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default EquipmentRouter
