import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OrderCertificate from './OrderCertificate'
import {
  saveServiceCallOrderItemsListRequest,
  getOrderPossibleReports,
  getServiceCallOrderCertificateDocumentLinkRequest,
} from '../../../../../../../actions/serviceDesk'
import { downloadFileRequest } from '../../../../../../../actions/appActions'

const mapStateToProps = state => ({
  isSavingOrderItems: state.serviceDesk.saveOrderItemsList.isSaving,
  savedOrderItemsSuccessfully:
    state.serviceDesk.saveOrderItemsList.savedSuccessfully,
  isFetchingPossibleReports: state.serviceDesk.orderPossibleReports.isFetching,
  possibleReports: state.serviceDesk.orderPossibleReports.templates,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveServiceCallOrderItemsListRequest,
      getOrderPossibleReports,
      downloadFileRequest,
      getServiceCallOrderCertificateDocumentLinkRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderCertificate),
)
