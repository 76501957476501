import React, { Fragment } from 'react'
// import PropTypes from 'prop-types'

import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Grid } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Selector from '../../Components/Selector'

const whenList = () => [
  { id: 'now', description: i18next.t('organizations_web_atoir:timeNow') },
  {
    id: 'later',
    description: i18next.t('organizations_web_atoir:timeAfterThatTime'),
  },
]

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
})

const multiplier = {
  mm: 60000,
  hh: 3600000,
  dd: 86400000,
}
const TimePoints = withStyles(styles)(({ classes, currentValue, onChange }) => {
  const onChangeHandler = ({ target: { value } }) => {
    onChange(value)
  }
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RadioGroup
        row
        aria-label="Gender"
        name="timePointList"
        className={classes.group}
        value={currentValue}
        onChange={onChangeHandler}
      >
        <FormControlLabel
          id="dd"
          value="dd"
          control={<Radio />}
          label={i18next.t('organizations_web_atoir:unitDays')}
        />
        <FormControlLabel
          id="hh"
          value="hh"
          control={<Radio />}
          label={i18next.t('organizations_web_atoir:unitH')}
        />
        <FormControlLabel
          id="mm"
          value="mm"
          control={<Radio />}
          label={i18next.t('organizations_web_atoir:unitMin')}
        />
      </RadioGroup>
    </FormControl>
  )
})

class TimingConstructor extends React.Component {
  state = {
    when: 'now',
    timePoint: 'mm',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentValue } = nextProps
    if (prevState.currentValue !== currentValue) {
      const { timePoint } = prevState
      const when = currentValue.in === 0 ? 'now' : 'later'
      let visibleValue = ''
      let timePointNew = null

      if (currentValue.in > 0) {
        if (!timePointNew && currentValue.in % 86400000 === 0) {
          visibleValue = currentValue.in / 86400000
          timePointNew = 'dd'
        }
        if (!timePointNew && currentValue.in % 3600000 === 0) {
          visibleValue = currentValue.in / 3600000
          timePointNew = 'hh'
        }
        if (!timePointNew) {
          visibleValue = Math.floor(currentValue.in / 60000)
          timePointNew = 'mm'
        }
      }

      if (!timePointNew) timePointNew = timePoint || 'mm'

      return {
        when,
        currentValue,
        visibleValue,
        timePoint: timePointNew,
      }
    }
    return null
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.currentValue !== this.props.currentValue ||
      nextState.when !== this.state.when ||
      nextState.timePoint !== this.state.timePoint
    )
  }

  textChangeHandler = ({ target, target: { value } }) => {
    console.log(target.id, target.value)
    this.commitChange(value)
  }

  whenChangeHandler = (filed, value) => {
    if (value === 'later') {
      this.setState({ when: value })
    } else {
      // timing.in = 0 означает 'немедленно', поэтому устаналиваем значение 'now' через эту возможнсть
      this.commitChange(0)
    }
  }

  timePointHandler = timePoint => {
    const {
      state: { visibleValue },
      commitChange,
    } = this
    this.setState({ timePoint }, () => {
      if (visibleValue > 0) commitChange(visibleValue)
    })
  }

  commitChange = value => {
    const {
      state: { timePoint },
      props: { idValue, currentValue, onChange },
    } = this
    onChange(idValue, {
      ...currentValue,
      in: value === '' ? '' : multiplier[timePoint] * value,
    })
  }

  render() {
    const {
      state: { when, timePoint, visibleValue },
      whenChangeHandler,
      timePointHandler,
      textChangeHandler,
    } = this
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Selector
              fullWidth
              idValue="whenSelector"
              valueList={whenList()}
              currentValue={when}
              onChange={whenChangeHandler}
            />
          </Grid>
          {when !== 'now' && (
            <Fragment>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  id="timing-text-value"
                  onChange={textChangeHandler}
                  value={visibleValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TimePoints
                  currentValue={timePoint}
                  onChange={timePointHandler}
                />
              </Grid>
              <Grid item xs={0} sm={0} md={5} />
            </Fragment>
          )}
        </Grid>
      </Fragment>
    )
  }
}

TimingConstructor.defaultProps = {}

TimingConstructor.propTypes = {
  // classes: PropTypes.object.isRequired,
  // currentValue: PropTypes.number.isRequired,
}

export default withStyles(styles)(TimingConstructor)
