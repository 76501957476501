import {
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
  FETCH_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  FETCH_ORGANIZATION_STAFF_REQUEST,
  FETCH_ORGANIZATION_STAFF_SUCCESS,
  FETCH_ORGANIZATION_STAFF_FAILURE,
  ADD_ORGANIZATION_USER_REQUEST,
  ADD_ORGANIZATION_USER_SUCCESS,
  ADD_ORGANIZATION_USER_FAILURE,
  BIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  BIND_ORGANIZATION_USER_ACCOUNT_SUCCESS,
  BIND_ORGANIZATION_USER_ACCOUNT_FAILURE,
  UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  UNBIND_ORGANIZATION_USER_ACCOUNT_SUCCESS,
  UNBIND_ORGANIZATION_USER_ACCOUNT_FAILURE,
  START_CREATE_UPDATE_ORGANIZATION_USER,
  COMPLETE_CREATE_UPDATE_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER_REQUEST,
  UPDATE_ORGANIZATION_USER_SUCCESS,
  UPDATE_ORGANIZATION_USER_FAILURE,
  DELETE_ORGANIZATION_USER_REQUEST,
  DELETE_ORGANIZATION_USER_SUCCESS,
  DELETE_ORGANIZATION_USER_FAILURE,
  FETCH_ORGANIZATION_LOCATIONS_REQUEST,
  FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
  FETCH_ORGANIZATION_LOCATIONS_FAILURE,
  FETCH_BOUND_ORGANIZATION_USERS_REQUEST,
  FETCH_BOUND_ORGANIZATION_USERS_SUCCESS,
  FETCH_BOUND_ORGANIZATION_USERS_FAILURE,
  FETCH_USER_CONTACTS_SUCCESS,
  FETCH_USER_CONTACTS_FAILURE,
  FETCH_USER_CONTACTS_REQUEST,
  FETCH_ORGANIZATION_STAFF_COUNT_FAILURE,
  FETCH_ORGANIZATION_STAFF_COUNT_SUCCESS,
  FETCH_ORGANIZATION_STAFF_COUNT_REQUEST,
  FETCH_USER_CONTACTS_COUNT_REQUEST,
  FETCH_USER_CONTACTS_COUNT_SUCCESS,
  FETCH_USER_CONTACTS_COUNT_FAILURE,
  SET_STAFF_LIST_ROWS_PER_PAGE,
} from '../constants/actionTypes'

const initialState = {
  isFetchingStaff: false,
  idsForFetch: [],
  staff: [],
  count: null,
  rowsPerPage: 10,
  contactsCount: null,
  contacts: [],
  contactError: null,
  isFetchingContacts: false,
  isFetchingContactsCount: false,
  isDeletingStaff: false,
  isFetchingOrganizationStaff: false,
  isFetchingOrganizationStaffCount: false,
  organizationStaff: [],
  organizationUserId: '',
  isUpdatingOrganizationUser: false,
  updateOrganizationUserError: {},
  isDeletingOrganizationUser: false,
  deleteOrganizationUserError: {},
  isFetchingLocations: false,
  locations: [],
  errors: {},
  isAddingOrganizationUser: false,
  addUserError: {},
  isBindingUserAccount: false,
  bindUserAccountError: {},
  isUnbindingUserAccount: false,
  unbindUserAccountError: {},
  isCreatingUpdatingOrganizationUser: false,
  boundOrganizationUsers: {
    isFetchingBoundUsers: false,
    boundUsers: [],
    fetchBoundUsersErrors: {},
  },
}

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFF_REQUEST:
      return {
        ...state,
        isFetchingStaff: true,
      }

    case FETCH_STAFF_SUCCESS:
      return {
        ...state,
        isFetchingStaff: false,
        staff: action.data,
      }

    case FETCH_STAFF_FAILURE:
      return {
        ...state,
        isFetchingStaff: false,
        errors: action.errors,
      }

    case FETCH_USER_CONTACTS_REQUEST:
      return {
        ...state,
        isFetchingContacts: true,
        contactError: null,
      }

    case FETCH_USER_CONTACTS_SUCCESS:
      return {
        ...state,
        isFetchingContacts: false,
        contacts: action.data,
      }

    case FETCH_USER_CONTACTS_FAILURE:
      return {
        ...state,
        isFetchingContacts: false,
        contactError: action.errors,
      }

    case FETCH_USER_CONTACTS_COUNT_REQUEST:
      return {
        ...state,
        isFetchingContactsCount: true,
        contactError: null,
      }

    case FETCH_USER_CONTACTS_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingContactsCount: false,
        contactsCount: action.count,
      }

    case FETCH_USER_CONTACTS_COUNT_FAILURE:
      return {
        ...state,
        isFetchingContactsCount: false,
        contactError: action.errors,
      }

    case DELETE_STAFF_REQUEST:
      return {
        ...state,
        isDeletingStaff: true,
      }

    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        isDeletingStaff: false,
      }

    case DELETE_STAFF_FAILURE:
      return {
        ...state,
        isDeletingStaff: false,
      }

    case FETCH_ORGANIZATION_STAFF_REQUEST:
      return {
        ...state,
        isFetchingOrganizationStaff: true,
        errors: {},
      }

    case FETCH_ORGANIZATION_STAFF_SUCCESS:
      return {
        ...state,
        isFetchingOrganizationStaff: false,
        organizationStaff: action.data,
      }

    case FETCH_ORGANIZATION_STAFF_FAILURE:
      return {
        ...state,
        isFetchingOrganizationStaff: false,
        errors: action.errors,
      }

    case FETCH_ORGANIZATION_STAFF_COUNT_REQUEST:
      return {
        ...state,
        isFetchingOrganizationStaffCount: true,
        errors: {},
      }

    case FETCH_ORGANIZATION_STAFF_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingOrganizationStaffCount: false,
        count: action.count,
      }

    case FETCH_ORGANIZATION_STAFF_COUNT_FAILURE:
      return {
        ...state,
        isFetchingOrganizationStaffCount: false,
        errors: action.errors,
      }

    case ADD_ORGANIZATION_USER_REQUEST:
      return {
        ...state,
        isAddingOrganizationUser: true,
        organizationUserId: '',
        addUserError: {},
      }

    case ADD_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        isAddingOrganizationUser: false,
        organizationUserId: action.organizationUserId,
      }

    case ADD_ORGANIZATION_USER_FAILURE:
      return {
        ...state,
        isAddingOrganizationUser: false,
        addUserError: action.errors,
      }

    case BIND_ORGANIZATION_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        isBindingUserAccount: true,
        bindUserAccountError: {},
      }

    case BIND_ORGANIZATION_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isBindingUserAccount: false,
      }

    case BIND_ORGANIZATION_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        isBindingUserAccount: false,
        bindUserAccountError: action.errors,
      }

    case UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        isUnbindingUserAccount: true,
        unbindUserAccountError: {},
      }

    case UNBIND_ORGANIZATION_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUnbindingUserAccount: false,
      }

    case UNBIND_ORGANIZATION_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        isUnbindingUserAccount: true,
        unbindUserAccountError: action.errors,
      }

    case START_CREATE_UPDATE_ORGANIZATION_USER:
      return {
        ...state,
        isCreatingUpdatingOrganizationUser: true,
      }

    case COMPLETE_CREATE_UPDATE_ORGANIZATION_USER:
      return {
        ...state,
        isCreatingUpdatingOrganizationUser: false,
        bindUserAccountError: {},
      }

    case UPDATE_ORGANIZATION_USER_REQUEST:
      return {
        ...state,
        isUpdatingOrganizationUser: true,
        updateOrganizationUserError: {},
      }

    case UPDATE_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        isUpdatingOrganizationUser: false,
      }

    case UPDATE_ORGANIZATION_USER_FAILURE:
      return {
        ...state,
        isUpdatingOrganizationUser: false,
        updateOrganizationUserError: action.errors,
      }

    case DELETE_ORGANIZATION_USER_REQUEST:
      return {
        ...state,
        isDeletingOrganizationUser: true,
        deleteOrganizationUserError: {},
      }

    case DELETE_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        isDeletingOrganizationUser: false,
      }

    case DELETE_ORGANIZATION_USER_FAILURE:
      return {
        ...state,
        isDeletingOrganizationUser: false,
        deleteOrganizationUserError: action.errors,
      }

    case FETCH_ORGANIZATION_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetchingLocations: true,
      }

    case FETCH_ORGANIZATION_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetchingLocations: false,
        locations: action.data,
      }

    case FETCH_ORGANIZATION_LOCATIONS_FAILURE:
      return {
        ...state,
        isFetchingLocations: false,
        errors: action.errors,
      }

    case FETCH_BOUND_ORGANIZATION_USERS_REQUEST:
      return {
        ...state,
        boundOrganizationUsers: {
          ...state.boundOrganizationUsers,
          isFetchingBoundUsers: true,
          fetchBoundUsersErrors: {},
        },
      }

    case FETCH_BOUND_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        boundOrganizationUsers: {
          ...state.boundOrganizationUsers,
          isFetchingBoundUsers: false,
          boundUsers: action.data,
        },
      }

    case FETCH_BOUND_ORGANIZATION_USERS_FAILURE:
      return {
        ...state,
        boundOrganizationUsers: {
          ...state.boundOrganizationUsers,
          isFetchingBoundUsers: false,
          fetchBoundUsersErrors: action.errors,
        },
      }

    case SET_STAFF_LIST_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.data,
      }

    default:
      return state
  }
}

export default staffReducer
