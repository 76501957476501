import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import OrganizationsContainer from '../../../containers/Organizations/OrganizationsContainer'
import BreadCrumb from './BreadCrumb'
import OrganizationSettingsRouter from './OrganizationSettingsRouter'
import OrganizationRouterTabbed from './OrganizationRouterTabbed'
import ServiceDeskBanner from '../../ServiceDesk/ServiceDeskBanner'

const OrganizationsRouter = ({ match }) => {
  const { path } = match
  return (
    <Fragment>
      <BreadCrumb to={path}>
        {i18next.t('organizations_web_atoir:organizationsList')}
      </BreadCrumb>
      <ServiceDeskBanner />
      <Switch>
        <WrapperRoute
          path={`${path}/:organizationId/settings`}
          title={i18next.t('organizations_web_atoir:organizationSettings')}
          component={OrganizationSettingsRouter}
        />
        <WrapperRoute
          path={`${path}/:organizationId`}
          component={OrganizationRouterTabbed}
        />
        <WrapperRoute
          path={`${path}`}
          title={i18next.t('organizations_web_atoir:organizationsList')}
          component={OrganizationsContainer}
        />
        <Route component={NoMatchComponent} />
      </Switch>
    </Fragment>
  )
}

export default OrganizationsRouter
