import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../i18n/i18n'
import FirstStep from './ChoosingEquipment'
import SecondStep from './ServiceDeskRequestDetails'
import BreadCrumb from '../../App/routing/BreadCrumb'

const styles = () => ({})

class CreateRequest extends Component {
  state = {
    activeStep: 0,
  }

  componentDidMount = () => {
    this.props.clearRequestDataOnCreation()
    this.updateTitle()
  }

  componentWillUnmount() {
    this.props.clearRequestDataOnCreation()
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isCreatingRequest !== this.props.isCreatingRequest &&
      this.props.isCreatingRequest === false
    ) {
      this.props.hideDialogLoader()
      if (!this.props.creatingRequestError) {
        this.props.history.push(
          `/service_desk/calls/created/${this.props.callId}`,
        )
      }
    }
  }

  updateTitle = () => {
    this.props.getTitleInfo({
      title: i18next.t('service_desk_web_atoir:requestCreationStepperTitle', {
        currentStep: this.state.activeStep + 1,
      }),
    })
  }

  handleNext = () => {
    const { activeStep } = this.state
    this.setState(
      {
        activeStep: activeStep + 1,
      },
      this.updateTitle,
    )
  }

  handleCreate = payload => {
    const {
      props: { showDialogLoader, createServiceDeskRequest },
    } = this
    showDialogLoader()
    createServiceDeskRequest(payload)
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  render() {
    const {
      props: {
        match: { url },
      },
      state: { activeStep },
    } = this
    return (
      <Fragment>
        <BreadCrumb to={url} length={27}>
          {i18next.t('service_desk_web_atoir:requestCreationStepperTitle', {
            currentStep: this.state.activeStep + 1,
          })}
        </BreadCrumb>
        {activeStep === 0 ? (
          <FirstStep
            handleNext={this.handleNext}
            handleBack={this.handleBack}
          />
        ) : (
          <SecondStep
            handlePositive={this.handleCreate}
            handleNegative={this.handleBack}
          />
        )}
      </Fragment>
    )
  }
}

export default withStyles(styles)(CreateRequest)
