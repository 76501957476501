import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CreateRequest from './CreateRequest'
import {
  clearRequestDataOnCreation,
  createServiceDeskRequest,
} from '../../../actions/serviceDesk'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../actions/utilsActions'

const mapStateToProps = state => ({
  isCreatingRequest: state.serviceDesk.createRequest.isFetching,
  creatingRequestError: state.serviceDesk.createRequest.error,
  callId: state.serviceDesk.createRequest.id,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showDialogLoader,
      hideDialogLoader,
      createServiceDeskRequest,
      clearRequestDataOnCreation,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateRequest),
)
