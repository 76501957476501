import { withStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

// config
import config from '../../../../../config'

const styles = theme => ({
  mainDiv: {
    position: 'relative',
  },
  shiftedContentD: {
    transition: theme.transitions.create(['display'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: config.right_drawer_width,

    [theme.breakpoints.down(1251)]: {
      display: 'none',
    },
    position: 'absolute',
    top: 'calc(50vh - 64px)',
    left: 'calc(100% + 40px)',
  },
})

const RightTips = withStyles(styles)(({ classes, children }) => (
  <div>
    <div className={classes.shiftedContentD}>{children}</div>
  </div>
))

RightTips.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(RightTips)
