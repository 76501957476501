import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import RLink from 'react-router-dom/Link'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// styles
import '../../../styles/components/Organization.css'

// components
import Loader from '../../Loader'
import CanEquipment from './EquipmentAbility/CanEquipment'
import EquipmentAbility from './EquipmentAbility/EquipmentAbiliy'
import Subheading from '../../../widgets/Lettering/Subheading'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'

// material-ui-icons
import EditIcon from '@material-ui/icons/Edit'
import LinkIcon from '@material-ui/icons/Link'

// tab menu components
import EquipmentInformationContainer from '../../../containers/Equipment/Profile/EquipmentInformationContainer'
import EquipmentMaintenanceContainer from '../../../containers/Equipment/Profile/EquipmentMaintenanceContainer'
import EquipmentHistoryContainer from '../../../containers/Equipment/Profile/EquipmentHistoryContainer'
import EditEquipmentLocation from './EditEquipmentLocation'
import EquipmentBAProfile from './EquipmentBAProfile'
import BreadCrumb from '../../App/routing/BreadCrumb'
import { popLastSections } from '../../../helpers/dataHelper'
import { matchEquipmentProfileRoute } from '../../../helpers/routing'
import CheckEquipmentSuggestions from './CheckEquipmentSuggestions'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing(3),
  }),
  tabRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  '@media (min-width: 600px)': {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  badgeColorPrimary: {
    color: '#FFFFFF',
  },
})

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

class EquipmentProfileComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      tabValue: this.props.match.params.tab || 'information',
      editEquipmentLocationOpen: false,
      checkEquipmentSuggestionsOpen: false,
    }
  }

  componentDidMount() {
    const { organizationId, equipmentId } = this.props.match.params
    const { fetchEquipmentPermissions, fetchEquipmentDetails } = this.props
    fetchEquipmentPermissions(organizationId)
    fetchEquipmentDetails(organizationId, equipmentId)
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.isUpdatingEquipmentDetails !==
        this.props.isUpdatingEquipmentDetails &&
        !this.props.isUpdatingEquipmentDetails) ||
      (prevProps.isRejectBaSuggestionsFetching !==
        this.props.isRejectBaSuggestionsFetching &&
        !this.props.isRejectBaSuggestionsFetching)
    ) {
      const { organizationId, equipmentId } = this.props.match.params
      const { fetchEquipmentPermissions, fetchEquipmentDetails } = this.props
      fetchEquipmentPermissions(organizationId)
      fetchEquipmentDetails(organizationId, equipmentId)
    }
    if (
      prevProps.isFetchingEquipmentPermissions !==
        this.props.isFetchingEquipmentPermissions &&
      !this.props.isFetchingEquipmentPermissions
    ) {
      if (isEmpty(this.props.permissionsError)) {
        const equipmentPermissions = []
        Object.keys(this.props.permissions).forEach(key => {
          if (
            typeof this.props.permissions[key] === 'boolean' &&
            this.props.permissions[key] === true
          ) {
            equipmentPermissions.push({ subject: 'Equipment', action: key })
          }
        })
        EquipmentAbility.update(equipmentPermissions)
        if (!isEmpty(this.props.permissions) && !this.props.permissions.view) {
          this.props.history.push('/404')
        }
      }
    }

    if (
      prevProps.isFetchingEquipmentDetails !==
        this.props.isFetchingEquipmentDetails &&
      !this.props.isFetchingEquipmentDetails &&
      !isEmpty(this.props.equipment)
    ) {
      this.props.getTitleInfo({
        title: this.props.equipment.name,
      })
    }

    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      this.setState({
        tabValue: this.props.match.params.tab,
      })
    }
  }

  handleTabChange = (event, value) => {
    const { history, match } = this.props
    const baseUrl = match.url.split('/')
    baseUrl.pop()
    history.push(`${baseUrl.join('/')}/${value}`)
    this.setState({
      tabValue: value,
    })
  }

  handleToggle = (field, state) => () => {
    this.setState({ [field]: state })
  }

  setCheckEquipmentSuggestionsOpen = checkEquipmentSuggestionsOpen => {
    this.setState({ checkEquipmentSuggestionsOpen })
  }

  render() {
    const {
      classes,
      isFetchingEquipmentPermissions,
      isFetchingEquipmentDetails,
      equipment,
      match,
    } = this.props
    const {
      tabValue,
      editEquipmentLocationOpen,
      checkEquipmentSuggestionsOpen,
    } = this.state
    const baseUrl = match.url.split('/')
    baseUrl.pop()
    return isFetchingEquipmentPermissions || isFetchingEquipmentDetails ? (
      <Loader />
    ) : (
      <Paper className={classes.root}>
        <Fragment>
          {matchEquipmentProfileRoute(match.url) ? (
            <BreadCrumb to={popLastSections(match.url, 3)}>
              {i18next.t(
                'organizations_web_atoir:organizationEquipmentListTitle',
              )}
            </BreadCrumb>
          ) : (
            equipment &&
            equipment.Organization && (
              <BreadCrumb to={popLastSections(match.url, 3)}>
                {equipment.Organization.short_name}
              </BreadCrumb>
            )
          )}
          {equipment && equipment.name && (
            <BreadCrumb to={match.url}>{equipment.name}</BreadCrumb>
          )}
          <Subheading
            className="wordWrapBreakAll"
            title={(equipment && equipment.name) || ''}
            caption={(equipment && equipment.model) || ''}
            divider
          >
            <CanEquipment I="edit" a="Equipment">
              <i>
                {(equipment.OrganizationEquipmentSuggestions || []).length >
                  0 && (
                  <Tooltip
                    title={i18next.t(
                      'equipment_web_atoir:suggestionsFoundTooltip',
                    )}
                  >
                    <IconButton
                      onClick={() =>
                        this.setCheckEquipmentSuggestionsOpen(true)
                      }
                    >
                      <Badge
                        classes={{
                          colorPrimary: classes.badgeColorPrimary,
                        }}
                        badgeContent={
                          (equipment.OrganizationEquipmentSuggestions || [])
                            .length
                        }
                        color="primary"
                      >
                        <LinkIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={i18next.t('shared_web_atoir:edit')}>
                  <IconButton component={RLink} to={`${match.url}/edit`}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </i>
            </CanEquipment>
          </Subheading>
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs value={tabValue} onChange={this.handleTabChange} centered>
                <Tab
                  value="information"
                  label={i18next.t('equipment_web_atoir:equipmentProfileTitle')}
                />
                {equipment && equipment.BAEquipment ? (
                  <Tab
                    value="catalogue"
                    label={i18next.t(
                      'equipment_web_atoir:equipmentProfileCatalogue',
                    )}
                  />
                ) : null}
                <Tab
                  value="journal"
                  label={i18next.t('equipment_web_atoir:maintenanceLogTitle')}
                />
                <Tab
                  value="history"
                  label={i18next.t('equipment_web_atoir:equipmentHistoryTitle')}
                />
              </Tabs>
            </AppBar>
            {tabValue === 'information' && (
              <TabContainer>
                <EquipmentInformationContainer />
              </TabContainer>
            )}
            {equipment && equipment.BAEquipment && tabValue === 'catalogue' && (
              <TabContainer>
                <EquipmentBAProfile />
              </TabContainer>
            )}
            {tabValue === 'journal' && (
              <TabContainer>
                <EquipmentMaintenanceContainer />
              </TabContainer>
            )}
            {tabValue === 'history' && (
              <TabContainer>
                <EquipmentHistoryContainer />
              </TabContainer>
            )}
          </Grid>
          {equipment && (
            <EditEquipmentLocation
              open={editEquipmentLocationOpen}
              onClose={this.handleToggle('editEquipmentLocationOpen', false)}
              _location={
                equipment && equipment.OrganizationLocation
                  ? equipment.OrganizationLocation.id
                  : ''
              }
              department={
                equipment && equipment.OrganizationDepartment
                  ? equipment.OrganizationDepartment.id
                  : ''
              }
              office={equipment.office}
              floor={equipment.floor}
              cabinet={equipment.cabinet}
            />
          )}
        </Fragment>
        <CheckEquipmentSuggestions
          baVariants={equipment.OrganizationEquipmentSuggestions || []}
          open={checkEquipmentSuggestionsOpen}
          setOpen={this.setCheckEquipmentSuggestionsOpen}
          equipment={equipment}
        />
      </Paper>
    )
  }
}

EquipmentProfileComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchEquipmentPermissions: PropTypes.func.isRequired,
  isFetchingEquipmentPermissions: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  permissionsError: PropTypes.object.isRequired,
  fetchEquipmentDetails: PropTypes.func.isRequired,
  isFetchingEquipmentDetails: PropTypes.bool.isRequired,
  equipment: PropTypes.object.isRequired,
}

export default withStyles(styles)(EquipmentProfileComponent)
