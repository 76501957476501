import {
  CREATE_INVENTORY_PROJECT_REQUEST,
  CREATE_INVENTORY_PROJECT_SUCCESS,
  CREATE_INVENTORY_PROJECT_FAILURE,
  FETCH_INVENTORY_PROJECTS_REQUEST,
  FETCH_INVENTORY_PROJECTS_SUCCESS,
  FETCH_INVENTORY_PROJECTS_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_REQUEST,
  FETCH_INVENTORY_PROJECT_PROFILE_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_SUCCESS,
  FETCH_INVENTORY_PROJECT_PROFILE_DEFAULT,
  EDIT_INVENTORY_PROJECT_REQUEST,
  EDIT_INVENTORY_PROJECT_SUCCESS,
  EDIT_INVENTORY_PROJECT_FAILURE,
  PUT_UP_ON_AUCTION_REQUEST,
  PUT_UP_ON_AUCTION_SUCCESS,
  PUT_UP_ON_AUCTION_FAILURE,
  INVENTORY_PROJECTS_COUNT_REQUEST,
  INVENTORY_PROJECTS_COUNT_SUCCESS,
  INVENTORY_PROJECTS_COUNT_FAILURE,
  INVENTORY_PROJECTS_CITIES_REQUEST,
  INVENTORY_PROJECTS_CITIES_SUCCESS,
  INVENTORY_PROJECTS_CITIES_FAILURE,
  GET_INVENTORY_CLIENTS_REQUEST,
  GET_INVENTORY_CLIENTS_SUCCESS,
  GET_INVENTORY_CLIENTS_FAILURE,
  GET_INVENTORY_PERFORMERS_REQUEST,
  GET_INVENTORY_PERFORMERS_SUCCESS,
  GET_INVENTORY_PERFORMERS_FAILURE,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_SUCCESS,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_FAILURE,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_SUCCESS,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_FAILURE,
  SET_OWN_PERFORMER_REQUEST,
  SET_OWN_PERFORMER_SUCCESS,
  SET_OWN_PERFORMER_FAILURE,
  SET_FOREIGN_PERFORMER_REQUEST,
  SET_FOREIGN_PERFORMER_SUCCESS,
  SET_FOREIGN_PERFORMER_FAILURE,
  OFFER_INVENTORY_PROJECT_REQUEST,
  OFFER_INVENTORY_PROJECT_SUCCESS,
  OFFER_INVENTORY_PROJECT_FAILURE,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_SUCCESS,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_FAILURE,
  FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST,
  FETCH_INVENTORY_OFFERS_PERFORMER_SUCCESS,
  FETCH_INVENTORY_OFFERS_PERFORMER_FAILURE,
  FETCH_INVENTORY_OFFERS_CLIENT_REQUEST,
  FETCH_INVENTORY_OFFERS_CLIENT_SUCCESS,
  FETCH_INVENTORY_OFFERS_CLIENT_FAILURE,
  STOP_INVENTORY_PROJECT_AUCTION_REQUEST,
  STOP_INVENTORY_PROJECT_AUCTION_SUCCESS,
  STOP_INVENTORY_PROJECT_AUCTION_FAILURE,
  CANCEL_INVENTORY_PROJECT_REQUEST,
  CANCEL_INVENTORY_PROJECT_SUCCESS,
  CANCEL_INVENTORY_PROJECT_FAILURE,
  SET_INVENTORY_PROJECT_DONE_REQUEST,
  SET_INVENTORY_PROJECT_DONE_SUCCESS,
  SET_INVENTORY_PROJECT_DONE_FAILURE,
  SET_INVENTORY_PROJECT_CLOSED_REQUEST,
  SET_INVENTORY_PROJECT_CLOSED_SUCCESS,
  SET_INVENTORY_PROJECT_CLOSED_FAILURE,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_SUCCESS,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_FAILURE,
  CHANGE_PERFORMER_RATING_REQUEST,
  CHANGE_PERFORMER_RATING_SUCCESS,
  CHANGE_PERFORMER_RATING_FAILURE,
  CHANGE_CLIENT_RATING_REQUEST,
  CHANGE_CLIENT_RATING_SUCCESS,
  CHANGE_CLIENT_RATING_FAILURE,
  CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST,
  CONFIRM_OFFER_INVENTORY_PROJECT_SUCCESS,
  CONFIRM_OFFER_INVENTORY_PROJECT_FAILURE,
  CANCEL_OFFER_INVENTORY_PROJECT_REQUEST,
  CANCEL_OFFER_INVENTORY_PROJECT_SUCCESS,
  CANCEL_OFFER_INVENTORY_PROJECT_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_SUCCESS,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_DEFAULT,
  FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  FETCH_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  FETCH_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  EDIT_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  EDIT_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_SUCCESS,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_FAILURE,
  FETCH_ORDERS_INVENTORY_PROJECT_REQUEST,
  FETCH_ORDERS_INVENTORY_PROJECT_SUCCESS,
  FETCH_ORDERS_INVENTORY_PROJECT_FAILURE,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_SUCCESS,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_FAILURE,
  REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST,
  REMOVE_ORDERS_INVENTORY_PROJECT_SUCCESS,
  REMOVE_ORDERS_INVENTORY_PROJECT_FAILURE,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_SUCCESS,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_FAILURE,
  FETCH_HISTORY_INVENTORY_PROJECT_REQUEST,
  FETCH_HISTORY_INVENTORY_PROJECT_SUCCESS,
  FETCH_HISTORY_INVENTORY_PROJECT_FAILURE,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_SUCCESS,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  createInventoryProject: {
    isCreatingInventoryProject: false,
    createProjectErrors: {},
  },
  editInventoryProject: {
    isEditingInventoryProject: false,
    editProjectErrors: {},
  },
  fetchInventoryProjects: {
    isFetchingInventoryProjects: false,
    inventoryProjects: [],
    fetchInventoryProjectsErrors: {},
  },
  putUpOnAuctionInventoryProjects: {
    isPuttingOnAuctionProject: false,
    puttingOnAuctionProjectError: {},
  },
  fetchInventoryProjectsCount: {
    isFetchingInventoryProjectsCount: false,
    count: 0,
    fetchCountErrors: {},
  },
  fetchInventoryProjectRights: {
    isFetchingInventoryProjectRights: false,
    isFetchingInventoryProjectError: null,
    inventoryProjectPermissions: null,
  },
  project: null,
  isFetchingProject: false,
  error: 0,
  inventoryProjectConfirmAsPerformer: {
    isConfirmingInventoryProjectAsPerformer: false,
    confirmingErrors: {},
  },
  inventoryProjectRejectAsPerformer: {
    isRejectingInventoryProjectAsPerformer: false,
    rejectingErrors: {},
  },
  inventoryProjectsCities: {
    isFetchingCities: false,
    cities: [],
    fetchCitiesErrors: {},
  },
  inventoryClients: {
    isFetching: false,
    fetchedSuccessfully: false,
    clients: [],
    errors: {},
  },
  inventoryPerformers: {
    isFetching: false,
    fetchedSuccessfully: false,
    performers: [],
    errors: {},
  },
  setOwnPerformer: {
    isSettingPerformer: false,
    setOwnSuccessfully: false,
    errors: {},
  },
  setForeignPerformer: {
    isSettingPerformer: false,
    setForeignSuccessfully: false,
    errors: {},
  },
  offerInventoryProject: {
    isOffer: false,
    offerSuccessfully: false,
    errors: {},
  },
  editInventoryProjectResponsible: {
    isEditing: false,
    editedSuccessfully: false,
    errors: {},
  },
  fetchInventoryOffersPerformer: {
    isFetchOffers: false,
    offers: [],
    errors: {},
  },
  fetchInventoryOffersClient: {
    isFetchOffers: false,
    offers: [],
    errors: {},
  },
  stopInventoryProjectAuctions: {
    isStop: false,
    isStopSuccess: false,
    errors: {},
  },
  cancelInventoryProject: {
    isCancelling: false,
    cancelledSuccessfully: false,
    errors: {},
  },
  setProjectDone: {
    isSetting: false,
    setSuccessfully: false,
    errors: {},
  },
  setProjectClosed: {
    isSetting: false,
    setSuccessfully: false,
    errors: {},
  },
  setProjectBackInWork: {
    isSetting: false,
    setSuccessfully: false,
    errors: {},
  },
  changePerformerRating: {
    isChanging: false,
    changeSuccessfully: false,
    errors: {},
  },
  changeClientRating: {
    isChanging: false,
    changeSuccessfully: false,
    errors: {},
  },
  confirmOfferInventoryProject: {
    isConfirm: false,
    isConfirmSuccessfully: false,
    errors: {},
  },
  cancelOfferInventoryProject: {
    isCancel: false,
    isCancelSuccessfully: false,
    errors: {},
  },
  engineers: {
    isFetch: false,
    engineers: [],
    errors: {},
  },
  engineersEdit: {
    isEdit: false,
    isEditSuccess: false,
    error: {},
  },
  engineersRemove: {
    isRemove: false,
    isRemoveSuccess: false,
    error: false,
  },
  filtersOrders: {
    isFetch: false,
    filters: {},
    errors: {},
  },
  orders: {
    isFetch: false,
    orders: [],
    errors: {},
  },
  ordersCount: {
    isFetch: false,
    count: 0,
    errors: null,
  },
  removeOrders: {
    isRemove: false,
    isRemoveSuccess: false,
    errors: null,
  },
  editEngineersOrder: {
    isEdit: false,
    isEditSuccess: false,
    errors: null,
  },
  history: {
    isFetching: false,
    history: [],
    errors: null,
  },
  historyCount: {
    isFetching: false,
    count: 0,
    errors: null,
  },
}

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        createInventoryProject: {
          ...state.createInventoryProject,
          isCreatingInventoryProject: true,
          createProjectErrors: {},
        },
      }

    case CREATE_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        createInventoryProject: {
          ...state.createInventoryProject,
          isCreatingInventoryProject: false,
        },
      }

    case CREATE_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        createInventoryProject: {
          ...state.createInventoryProject,
          isCreatingInventoryProject: false,
          createProjectErrors: action.errors,
        },
      }
    case EDIT_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        editInventoryProject: {
          ...state.editInventoryProject,
          isEditingInventoryProject: true,
          editProjectErrors: {},
        },
      }

    case EDIT_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        editInventoryProject: {
          ...state.editInventoryProject,
          isEditingInventoryProject: false,
        },
      }

    case EDIT_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        editInventoryProject: {
          ...state.editInventoryProject,
          isEditingInventoryProject: false,
          editProjectErrors: action.errors,
        },
      }

    case FETCH_INVENTORY_PROJECTS_REQUEST:
      return {
        ...state,
        fetchInventoryProjects: {
          ...state.fetchInventoryProjects,
          isFetchingInventoryProjects: true,
          fetchInventoryProjectsErrors: {},
        },
      }

    case FETCH_INVENTORY_PROJECTS_SUCCESS:
      return {
        ...state,
        fetchInventoryProjects: {
          ...state.fetchInventoryProjects,
          isFetchingInventoryProjects: false,
          inventoryProjects: action.data,
        },
      }

    case FETCH_INVENTORY_PROJECTS_FAILURE:
      return {
        ...state,
        fetchInventoryProjects: {
          ...state.fetchInventoryProjects,
          isFetchingInventoryProjects: false,
          fetchInventoryProjectsErrors: action.errors,
        },
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST:
      return {
        ...state,
        fetchInventoryProjectRights: {
          ...state.fetchInventoryProjectRights,
          isFetchingInventoryProjectRights: true,
          inventoryProjectPermissions: null,
          fetchInventoryProjectsRightsError: null,
        },
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_SUCCESS:
      return {
        ...state,
        fetchInventoryProjectRights: {
          ...state.fetchInventoryProjectRights,
          isFetchingInventoryProjectRights: false,
          inventoryProjectPermissions: action.permissions,
        },
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_FAILURE:
      return {
        ...state,
        fetchInventoryProjectRights: {
          ...state.fetchInventoryProjectRights,
          isFetchingInventoryProjectRights: false,
          fetchInventoryProjectsRightsError: action.code,
        },
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_DEFAULT:
      return {
        ...state,
        fetchInventoryProjectRights: {
          ...state.fetchInventoryProjectRights,
          isFetchingInventoryProjectRights: false,
          inventoryProjectPermissions: null,
          fetchInventoryProjectsRightsError: null,
        },
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingProject: true,
        error: undefined,
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        project: action.project,
        isFetchingProject: false,
        error: undefined,
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_FAILURE:
      return {
        ...state,
        error: action.code,
        isFetchingProject: false,
      }

    case FETCH_INVENTORY_PROJECT_PROFILE_DEFAULT:
      return {
        ...state,
        project: null,
        error: null,
        isFetchingProject: false,
      }

    case PUT_UP_ON_AUCTION_REQUEST:
      return {
        ...state,
        putUpOnAuctionInventoryProjects: {
          ...state.putUpOnAuctionInventoryProjects,
          isPuttingOnAuctionProject: true,
          error: undefined,
        },
      }

    case PUT_UP_ON_AUCTION_SUCCESS:
      return {
        ...state,
        putUpOnAuctionInventoryProjects: {
          ...state.putUpOnAuctionInventoryProjects,
          isPuttingOnAuctionProject: false,
          error: undefined,
        },
      }

    case PUT_UP_ON_AUCTION_FAILURE:
      return {
        ...state,
        putUpOnAuctionInventoryProjects: {
          ...state.putUpOnAuctionInventoryProjects,
          isPuttingOnAuctionProject: false,
          error: action.code,
        },
      }

    case INVENTORY_PROJECTS_COUNT_REQUEST:
      return {
        ...state,
        fetchInventoryProjectsCount: {
          ...state.fetchInventoryProjectsCount,
          isFetchingInventoryProjectsCount: true,
          fetchCountErrors: {},
        },
      }

    case INVENTORY_PROJECTS_COUNT_SUCCESS:
      return {
        ...state,
        fetchInventoryProjectsCount: {
          ...state.fetchInventoryProjectsCount,
          isFetchingInventoryProjectsCount: false,
          count: action.count,
        },
      }

    case INVENTORY_PROJECTS_COUNT_FAILURE:
      return {
        ...state,
        fetchInventoryProjectsCount: {
          ...state.fetchInventoryProjectsCount,
          isFetchingInventoryProjectsCount: false,
          fetchCountErrors: action.errors,
        },
      }

    case INVENTORY_PROJECTS_CITIES_REQUEST:
      return {
        ...state,
        inventoryProjectsCities: {
          ...state.inventoryProjectsCities,
          isFetchingCities: true,
          fetchCitiesErrors: {},
        },
      }

    case INVENTORY_PROJECTS_CITIES_SUCCESS:
      return {
        ...state,
        inventoryProjectsCities: {
          ...state.inventoryProjectsCities,
          isFetchingCities: false,
          cities: action.data,
        },
      }

    case INVENTORY_PROJECTS_CITIES_FAILURE:
      return {
        ...state,
        inventoryProjectsCities: {
          ...state.inventoryProjectsCities,
          isFetchingCities: false,
          fetchCitiesErrors: action.errors,
        },
      }

    case GET_INVENTORY_CLIENTS_REQUEST:
      return {
        ...state,
        inventoryClients: {
          ...state.inventoryClients,
          isFetching: true,
          fetchedSuccessfully: false,
          errors: {},
        },
      }

    case GET_INVENTORY_CLIENTS_SUCCESS:
      return {
        ...state,
        inventoryClients: {
          ...state.inventoryClients,
          isFetching: false,
          fetchedSuccessfully: true,
          clients: action.clients,
        },
      }

    case GET_INVENTORY_CLIENTS_FAILURE:
      return {
        ...state,
        inventoryClients: {
          ...state.inventoryClients,
          isFetching: false,
          fetchedSuccessfully: false,
          errors: action.errors,
        },
      }

    case GET_INVENTORY_PERFORMERS_REQUEST:
      return {
        ...state,
        inventoryPerformers: {
          ...state.inventoryPerformers,
          isFetching: true,
          fetchedSuccessfully: false,
          errors: {},
        },
      }

    case GET_INVENTORY_PERFORMERS_SUCCESS:
      return {
        ...state,
        inventoryPerformers: {
          ...state.inventoryPerformers,
          isFetching: false,
          fetchedSuccessfully: true,
          performers: action.performers,
        },
      }

    case GET_INVENTORY_PERFORMERS_FAILURE:
      return {
        ...state,
        inventoryPerformers: {
          ...state.inventoryPerformers,
          isFetching: false,
          fetchedSuccessfully: false,
          errors: action.errors,
        },
      }

    case INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST: {
      return {
        ...state,
        inventoryProjectConfirmAsPerformer: {
          ...state.inventoryProjectConfirmAsPerformer,
          isConfirmingInventoryProjectAsPerformer: true,
          confirmingErrors: {},
        },
      }
    }

    case INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_SUCCESS: {
      return {
        ...state,
        inventoryProjectConfirmAsPerformer: {
          ...state.inventoryProjectConfirmAsPerformer,
          isConfirmingInventoryProjectAsPerformer: false,
        },
      }
    }

    case INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_FAILURE: {
      return {
        ...state,
        inventoryProjectConfirmAsPerformer: {
          ...state.inventoryProjectConfirmAsPerformer,
          isConfirmingInventoryProjectAsPerformer: false,
          confirmingErrors: action.errors,
        },
      }
    }

    case INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST:
      return {
        ...state,
        inventoryProjectRejectAsPerformer: {
          ...state.inventoryProjectRejectAsPerformer,
          isRejectingInventoryProjectAsPerformer: true,
          rejectingErrors: {},
        },
      }

    case INVENTORY_PROJECT_REJECT_AS_PERFORMER_SUCCESS:
      return {
        ...state,
        inventoryProjectRejectAsPerformer: {
          ...state.inventoryProjectRejectAsPerformer,
          isRejectingInventoryProjectAsPerformer: false,
        },
      }

    case INVENTORY_PROJECT_REJECT_AS_PERFORMER_FAILURE:
      return {
        ...state,
        inventoryProjectRejectAsPerformer: {
          ...state.inventoryProjectRejectAsPerformer,
          isRejectingInventoryProjectAsPerformer: false,
          rejectingErrors: action.errors,
        },
      }

    case SET_OWN_PERFORMER_REQUEST:
      return {
        ...state,
        setOwnPerformer: {
          ...state.setOwnPerformer,
          isSettingPerformer: true,
          setOwnSuccessfully: false,
          errors: {},
        },
      }

    case SET_OWN_PERFORMER_SUCCESS:
      return {
        ...state,
        setOwnPerformer: {
          ...state.setOwnPerformer,
          isSettingPerformer: false,
          setOwnSuccessfully: true,
        },
      }

    case SET_OWN_PERFORMER_FAILURE:
      return {
        ...state,
        setOwnPerformer: {
          ...state.setOwnPerformer,
          isSettingPerformer: false,
          setOwnSuccessfully: false,
          errors: action.errors,
        },
      }

    case SET_FOREIGN_PERFORMER_REQUEST:
      return {
        ...state,
        setForeignPerformer: {
          ...state.setForeignPerformer,
          isSettingPerformer: true,
          setForeignSuccessfully: false,
          errors: {},
        },
      }

    case SET_FOREIGN_PERFORMER_SUCCESS:
      return {
        ...state,
        setForeignPerformer: {
          ...state.setForeignPerformer,
          isSettingPerformer: false,
          setForeignSuccessfully: true,
        },
      }

    case SET_FOREIGN_PERFORMER_FAILURE:
      return {
        ...state,
        setForeignPerformer: {
          ...state.setForeignPerformer,
          isSettingPerformer: false,
          setForeignSuccessfully: false,
          errors: action.errors,
        },
      }

    case OFFER_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        offerInventoryProject: {
          ...state.offerInventoryProject,
          isOffer: true,
          offerSuccessfully: false,
          errors: {},
        },
      }

    case OFFER_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        offerInventoryProject: {
          ...state.offerInventoryProject,
          isOffer: false,
          offerSuccessfully: true,
          errors: {},
        },
      }

    case OFFER_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        offerInventoryProject: {
          ...state.offerInventoryProject,
          isOffer: false,
          offerSuccessfully: false,
          errors: action.errors,
        },
      }

    case EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST:
      return {
        ...state,
        editInventoryProjectResponsible: {
          ...state.editInventoryProjectResponsible,
          isEditing: true,
          editedSuccessfully: false,
          errors: {},
        },
      }

    case EDIT_INVENTORY_PROJECT_RESPONSIBLE_SUCCESS:
      return {
        ...state,
        editInventoryProjectResponsible: {
          ...state.editInventoryProjectResponsible,
          isEditing: false,
          editedSuccessfully: true,
        },
      }

    case EDIT_INVENTORY_PROJECT_RESPONSIBLE_FAILURE:
      return {
        ...state,
        editInventoryProjectResponsible: {
          ...state.editInventoryProjectResponsible,
          isEditing: false,
          editedSuccessfully: false,
          errors: action.errors,
        },
      }

    case FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST:
      return {
        ...state,
        fetchInventoryOffersPerformer: {
          ...state.fetchInventoryOffersPerformer,
          isFetchOffers: true,
          offers: [],
          errors: {},
        },
      }

    case FETCH_INVENTORY_OFFERS_PERFORMER_SUCCESS:
      return {
        ...state,
        fetchInventoryOffersPerformer: {
          ...state.fetchInventoryOffersPerformer,
          isFetchOffers: false,
          offers: action.data,
          errors: {},
        },
      }

    case FETCH_INVENTORY_OFFERS_PERFORMER_FAILURE:
      return {
        ...state,
        fetchInventoryOffersPerformer: {
          ...state.fetchInventoryOffersPerformer,
          isFetchOffers: false,
          offers: [],
          errors: action.errors,
        },
      }

    case FETCH_INVENTORY_OFFERS_CLIENT_REQUEST:
      return {
        ...state,
        fetchInventoryOffersClient: {
          ...state.fetchInventoryOffersClient,
          isFetchOffers: true,
          offers: [],
          errors: {},
        },
      }

    case FETCH_INVENTORY_OFFERS_CLIENT_SUCCESS:
      return {
        ...state,
        fetchInventoryOffersClient: {
          ...state.fetchInventoryOffersClient,
          isFetchOffers: false,
          offers: action.data,
          errors: {},
        },
      }

    case FETCH_INVENTORY_OFFERS_CLIENT_FAILURE:
      return {
        ...state,
        fetchInventoryOffersClient: {
          ...state.fetchInventoryOffersClient,
          isFetchOffers: false,
          offers: [],
          errors: action.errors,
        },
      }

    case STOP_INVENTORY_PROJECT_AUCTION_REQUEST:
      return {
        ...state,
        stopInventoryProjectAuctions: {
          ...state.stopInventoryProjectAuctions,
          isStop: true,
          isStopSuccess: false,
          errors: {},
        },
      }
    case STOP_INVENTORY_PROJECT_AUCTION_SUCCESS:
      return {
        ...state,
        stopInventoryProjectAuctions: {
          ...state.stopInventoryProjectAuctions,
          isStop: false,
          isStopSuccess: true,
          errors: {},
        },
      }
    case STOP_INVENTORY_PROJECT_AUCTION_FAILURE:
      return {
        ...state,
        stopInventoryProjectAuctions: {
          ...state.stopInventoryProjectAuctions,
          isStop: false,
          isStopSuccess: false,
          errors: action.errors,
        },
      }

    case CANCEL_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        cancelInventoryProject: {
          ...state.cancelInventoryProject,
          isCancelling: true,
          cancelledSuccessfully: false,
          errors: {},
        },
      }

    case CANCEL_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        cancelInventoryProject: {
          ...state.cancelInventoryProject,
          isCancelling: false,
          cancelledSuccessfully: true,
        },
      }

    case CANCEL_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        cancelInventoryProject: {
          ...state.cancelInventoryProject,
          isCancelling: false,
          cancelledSuccessfully: false,
          errors: action.errors,
        },
      }

    case SET_INVENTORY_PROJECT_DONE_REQUEST:
      return {
        ...state,
        setProjectDone: {
          ...state.setProjectDone,
          isSetting: true,
          setSuccessfully: false,
          errors: {},
        },
      }

    case SET_INVENTORY_PROJECT_DONE_SUCCESS:
      return {
        ...state,
        setProjectDone: {
          ...state.setProjectDone,
          isSetting: false,
          setSuccessfully: true,
        },
      }

    case SET_INVENTORY_PROJECT_DONE_FAILURE:
      return {
        ...state,
        setProjectDone: {
          ...state.setProjectDone,
          isSetting: false,
          errors: action.errors,
        },
      }

    case SET_INVENTORY_PROJECT_CLOSED_REQUEST:
      return {
        ...state,
        setProjectClosed: {
          ...state.setProjectClosed,
          isSetting: true,
          setSuccessfully: false,
          errors: {},
        },
      }

    case SET_INVENTORY_PROJECT_CLOSED_SUCCESS:
      return {
        ...state,
        setProjectClosed: {
          ...state.setProjectClosed,
          isSetting: false,
          setSuccessfully: true,
        },
      }

    case SET_INVENTORY_PROJECT_CLOSED_FAILURE:
      return {
        ...state,
        setProjectClosed: {
          ...state.setProjectClosed,
          isSetting: false,
          errors: action.errors,
        },
      }

    case SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST:
      return {
        ...state,
        setProjectBackInWork: {
          ...state.setProjectBackInWork,
          isSetting: true,
          setSuccessfully: false,
          errors: {},
        },
      }

    case SET_INVENTORY_PROJECT_BACK_IN_WORK_SUCCESS:
      return {
        ...state,
        setProjectBackInWork: {
          ...state.setProjectBackInWork,
          isSetting: false,
          setSuccessfully: true,
        },
      }

    case SET_INVENTORY_PROJECT_BACK_IN_WORK_FAILURE:
      return {
        ...state,
        setProjectBackInWork: {
          ...state.setProjectBackInWork,
          isSetting: false,
          errors: action.errors,
        },
      }

    case CHANGE_PERFORMER_RATING_REQUEST:
      return {
        ...state,
        changePerformerRating: {
          ...state.changePerformerRating,
          isChanging: true,
          changeSuccessfully: false,
          errors: {},
        },
      }

    case CHANGE_PERFORMER_RATING_SUCCESS:
      return {
        ...state,
        changePerformerRating: {
          ...state.changePerformerRating,
          isChanging: false,
          changeSuccessfully: true,
        },
      }

    case CHANGE_PERFORMER_RATING_FAILURE:
      return {
        ...state,
        changePerformerRating: {
          ...state.changePerformerRating,
          isChanging: false,
          errors: {},
        },
      }

    case CHANGE_CLIENT_RATING_REQUEST:
      return {
        ...state,
        changeClientRating: {
          ...state.changeClientRating,
          isChanging: true,
          changeSuccessfully: false,
          errors: {},
        },
      }

    case CHANGE_CLIENT_RATING_SUCCESS:
      return {
        ...state,
        changeClientRating: {
          ...state.changeClientRating,
          isChanging: false,
          changeSuccessfully: true,
        },
      }

    case CHANGE_CLIENT_RATING_FAILURE:
      return {
        ...state,
        changeClientRating: {
          ...state.changeClientRating,
          isChanging: false,
          errors: {},
        },
      }

    case CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        confirmOfferInventoryProject: {
          ...state.confirmOfferInventoryProject,
          isConfirm: true,
          isConfirmSuccessfully: false,
          errors: {},
        },
      }
    case CONFIRM_OFFER_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        confirmOfferInventoryProject: {
          ...state.confirmOfferInventoryProject,
          isConfirm: false,
          isConfirmSuccessfully: true,
          errors: {},
        },
      }
    case CONFIRM_OFFER_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        confirmOfferInventoryProject: {
          ...state.confirmOfferInventoryProject,
          isConfirm: false,
          isConfirmSuccessfully: false,
          errors: action.errors,
        },
      }

    case CANCEL_OFFER_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        cancelOfferInventoryProject: {
          ...state.cancelOfferInventoryProject,
          isCancel: true,
          isCancelSuccessfully: false,
          errors: {},
        },
      }

    case CANCEL_OFFER_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        cancelOfferInventoryProject: {
          ...state.cancelOfferInventoryProject,
          isCancel: false,
          isCancelSuccessfully: true,
          errors: {},
        },
      }

    case CANCEL_OFFER_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        cancelOfferInventoryProject: {
          ...state.cancelOfferInventoryProject,
          isCancel: false,
          isCancelSuccessfully: false,
          errors: action.errors,
        },
      }

    case FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        engineers: {
          ...state.engineers,
          isFetch: true,
          engineers: [],
          errors: {},
        },
      }

    case FETCH_ENGINEERS_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        engineers: {
          ...state.engineers,
          isFetch: false,
          engineers: action.data,
          errors: {},
        },
      }

    case FETCH_ENGINEERS_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        engineers: {
          ...state.engineers,
          isFetch: false,
          engineers: [],
          errors: action.errors,
        },
      }

    case EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        engineersEdit: {
          ...state.engineersEdit,
          isEdit: true,
          isEditSuccess: false,
          error: {},
        },
      }
    case EDIT_ENGINEERS_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        engineersEdit: {
          ...state.engineersEdit,
          isEdit: false,
          isEditSuccess: true,
          error: {},
        },
      }

    case EDIT_ENGINEERS_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        engineersEdit: {
          ...state.engineersEdit,
          isEdit: false,
          isEditSuccess: false,
          error: action.errors,
        },
      }

    case REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        engineersRemove: {
          ...state.engineersRemove,
          isRemove: true,
          isRemoveSuccess: false,
          error: null,
        },
      }

    case REMOVE_ENGINEERS_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        engineersRemove: {
          ...state.engineersRemove,
          isRemove: false,
          isRemoveSuccess: true,
          error: null,
        },
      }

    case REMOVE_ENGINEERS_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        engineersRemove: {
          ...state.engineersRemove,
          isRemove: false,
          isRemoveSuccess: false,
          error: action.error,
        },
      }

    case FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        filtersOrders: {
          ...state.filtersOrders,
          isFetch: true,
          filters: {},
          errors: {},
        },
      }

    case FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        filtersOrders: {
          ...state.filtersOrders,
          isFetch: false,
          filters: action.data,
          errors: null,
        },
      }

    case FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        filtersOrders: {
          ...state.filtersOrders,
          isFetch: false,
          filters: {},
          errors: action.errors,
        },
      }

    case FETCH_ORDERS_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        orders: {
          ...state.orders,
          isFetch: true,
          orders: [],
          errors: {},
        },
      }
    case FETCH_ORDERS_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isFetch: false,
          orders: action.data,
          errors: {},
        },
      }
    case FETCH_ORDERS_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        orders: {
          ...state.orders,
          isFetch: false,
          orders: [],
          errors: action.errors,
        },
      }
    case FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        ordersCount: {
          ...state.ordersCount,
          isFetch: true,
          count: 0,
          errors: null,
        },
      }

    case FETCH_ORDERS_COUNT_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        ordersCount: {
          ...state.ordersCount,
          isFetch: false,
          count: action.data,
          errors: null,
        },
      }
    case FETCH_ORDERS_COUNT_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        ordersCount: {
          ...state.ordersCount,
          isFetch: false,
          count: 0,
          errors: action.errors,
        },
      }

    case REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        removeOrders: {
          ...state.removeOrders,
          isRemove: true,
          isRemoveSuccess: false,
          errors: null,
        },
      }

    case REMOVE_ORDERS_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        removeOrders: {
          ...state.removeOrders,
          isRemove: false,
          isRemoveSuccess: true,
          errors: null,
        },
      }

    case REMOVE_ORDERS_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        removeOrders: {
          ...state.removeOrders,
          isRemove: false,
          isRemoveSuccess: false,
          errors: action.errors,
        },
      }

    case EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        editEngineersOrder: {
          ...state.editEngineersOrder,
          isEdit: true,
          isEditSuccess: false,
          errors: null,
        },
      }

    case EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        editEngineersOrder: {
          ...state.editEngineersOrder,
          isEdit: false,
          isEditSuccess: true,
          errors: null,
        },
      }

    case EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        editEngineersOrder: {
          ...state.editEngineersOrder,
          isEdit: false,
          isEditSuccess: false,
          errors: action.error,
        },
      }

    case FETCH_HISTORY_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: true,
          history: [],
          errors: {},
        },
      }

    case FETCH_HISTORY_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          history: action.data,
          errors: null,
        },
      }

    case FETCH_HISTORY_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          history: {},
          errors: action.errors,
        },
      }

    case FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST:
      return {
        ...state,
        historyCount: {
          ...state.historyCount,
          isFetching: true,
          count: 0,
          errors: null,
        },
      }

    case FETCH_HISTORY_COUNT_INVENTORY_PROJECT_SUCCESS:
      return {
        ...state,
        historyCount: {
          ...state.historyCount,
          isFetching: false,
          count: action.data,
          errors: null,
        },
      }

    case FETCH_HISTORY_COUNT_INVENTORY_PROJECT_FAILURE:
      return {
        ...state,
        historyCount: {
          ...state.historyCount,
          isFetching: false,
          count: 0,
          errors: action.errors,
        },
      }

    default:
      return state
  }
}

export default organizationsReducer
