import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'

function BigBreadcrumbs({
  containerProps,
  prepareItemProps,
  itemsValue,
  separator,
  count,
  finalItem: FinalItem,
  finalProps,
  item: Item = ({ children }) => <div>{children}</div>,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'big-breadcrumbs-popper' : null

  return (
    <div {...containerProps}>
      <span>
        <Item {...prepareItemProps(itemsValue[0])} />
        {separator}
      </span>
      <span>
        <IconButton
          aria-describedby={id}
          color="primary"
          size="small"
          onClick={handleClick}
        >
          <MoreHoriz />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper elevation={2}>
            <MenuList>
              {itemsValue.slice(1, -2).map((itemValue, i) => (
                <MenuItem key={itemValue}>
                  <span style={{ marginLeft: 4 * i }}>
                    {separator}
                    <Item {...prepareItemProps(itemValue)} />
                  </span>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popover>
        {separator}
      </span>
      <span>
        <Item {...prepareItemProps(itemsValue[count - 2])} />
        {separator}
      </span>
      <FinalItem {...prepareItemProps(itemsValue[count - 1])} {...finalProps} />
    </div>
  )
}

export default BigBreadcrumbs
