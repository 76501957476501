import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import WorkCompletionReport from './WorkCompletionReport'
import {
  fetchOrder,
  fetchServiceCallOrderItemsRequest,
  fetchServiceCallOrderItemsCountRequest,
  fetchServiceCallSuborderItemsRequest,
  fetchServiceCallSuborderItemsCountRequest,
  saveServiceCallOrderItemsListRequest,
  clearServiceCallOrderItemsListRequest,
  deleteOrderWorkCompletionFileRequest,
  confirmCertificateOfCompletionRequest,
  fetchCallPermissions,
  copyOrderItemsFromChildRequest,
  fetchOrderNdsRequest,
  updateOrderNdsRequest,
  getOrderPossibleReports,
  setNdsCheckboxValue,
  setInputNdsCheckboxValue,
  setOverheadCheckboxValue,
  updateOrderOverheadRequest,
  updateOrderIncomeNdsRequest,
  fetchOrderIncomeNdsRequest,
  fetchOrderOverheadRequest,
} from '../../../actions/serviceDesk'
import { downloadFileRequest } from '../../../actions/appActions'
import _ from 'lodash'
import isEmpty from 'is-empty'

const mapStateToProps = state => {
  const {
    serviceDesk: {
      orderItems: { items: orderItems },
      suborderItems: { items: suborderItems },
    },
  } = state
  const orderItemsDiagnostic = !isEmpty(orderItems)
    ? _.filter(orderItems, item => item.result_type === 'diagnostic')
    : []
  const orderItemsNeedRepair = !isEmpty(orderItems)
    ? _.filter(orderItems, item => item.result_type === 'longtermrepair')
    : []
  const suborderItemsDiagnostic = !isEmpty(suborderItems)
    ? _.filter(suborderItems, item => item.result_type === 'diagnostic')
    : []
  const suborderItemsNeedRepair = !isEmpty(suborderItems)
    ? _.filter(suborderItems, item => item.result_type === 'longtermrepair')
    : []

  return {
    isFetchingOrder: state.serviceDesk.order.isFetching,
    order: state.serviceDesk.order.order,
    files: state.serviceDesk.order.files,
    isFetchingFileDelete: state.serviceDesk.workCompletionFileDelete.isFetching,
    isFetchingOrderItems: state.serviceDesk.orderItems.isFetching,
    orderItems: {
      itemsDiagnostic: orderItemsDiagnostic,
      itemsNeedRepair: orderItemsNeedRepair,
    },
    isFetchingOrderItemsCount: state.serviceDesk.orderItemsCount.isFetching,
    orderItemsCount: state.serviceDesk.orderItemsCount.count,
    isFetchingSuborderItems: state.serviceDesk.suborderItems.isFetching,
    suborderItems: {
      itemsDiagnostic: suborderItemsDiagnostic,
      itemsNeedRepair: suborderItemsNeedRepair,
    },
    isFetchingSuborderItemsCount:
      state.serviceDesk.suborderItemsCount.isFetching,
    suborderItemsCount: state.serviceDesk.suborderItemsCount.count,
    isSavingOrderItems: state.serviceDesk.saveOrderItemsList.isSaving,
    savedOrderItemsSuccessfully:
      state.serviceDesk.saveOrderItemsList.savedSuccessfully,
    isClearingOrderItems: state.serviceDesk.clearOrderItemsList.isClearing,
    clearedOrderItemsSuccessfully:
      state.serviceDesk.clearOrderItemsList.clearedSuccessfully,
    isConfirmingCertificateOfCompletion:
      state.serviceDesk.confirmCertificateOfCompletionWork.isFetching,
    confirmCertificateOfCompletionSuccess:
      state.serviceDesk.confirmCertificateOfCompletionWork.confirmSuccessfully,
    confirmCertificateOfCompletionError:
      state.serviceDesk.confirmCertificateOfCompletionWork.error,
    isFetchingPermissions: state.serviceDesk.permissions.isFetching,
    permissions: state.serviceDesk.permissions,
    isCopyingItemsFromChild: state.serviceDesk.copyItemsFromChild.isCopying,
    copyItemsFromChildSuccess: state.serviceDesk.copyItemsFromChild.copySuccess,
    isFetchingNds: state.serviceDesk.fetchOrderNds.isFetching,
    isFetchingIncomeNds: state.serviceDesk.fetchOrderInputNds.isFetching,
    isFetchingOverhead: state.serviceDesk.fetchOrderOverhead.isFetching,
    nds: state.serviceDesk.fetchOrderNds.nds,
    inputWithNDS: state.serviceDesk.fetchOrderInputNds.nds,
    overhead: state.serviceDesk.fetchOrderOverhead.overhead,
    isUpdatingNds: state.serviceDesk.updateOrderNds.isUpdating,
    isUpdatingOverhead: state.serviceDesk.updateOrderOverhead.isUpdating,
    isUpdatingIncomeNds: state.serviceDesk.updateOrderIncomeNds.isUpdating,
    isFetchingPossibleReports:
      state.serviceDesk.orderPossibleReports.isFetching,
    possibleReports: state.serviceDesk.orderPossibleReports.templates,
    ndsChecked: state.serviceDesk.ndsChecked,
    inputWithNDSChecked: state.serviceDesk.inputWithNDSChecked,
    overheadChecked: state.serviceDesk.overheadChecked,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrder,
      fetchServiceCallOrderItemsRequest,
      fetchServiceCallOrderItemsCountRequest,
      fetchServiceCallSuborderItemsRequest,
      fetchServiceCallSuborderItemsCountRequest,
      saveServiceCallOrderItemsListRequest,
      clearServiceCallOrderItemsListRequest,
      confirmCertificateOfCompletionRequest,
      fetchCallPermissions,
      downloadFileRequest,
      deleteFile: deleteOrderWorkCompletionFileRequest,
      copyOrderItemsFromChildRequest,
      fetchOrderNdsRequest,
      fetchOrderOverheadRequest,
      fetchOrderIncomeNdsRequest,
      updateOrderNdsRequest,
      updateOrderOverheadRequest,
      updateOrderIncomeNdsRequest,
      getOrderPossibleReports,
      setNdsCheckboxValue,
      setInputNdsCheckboxValue,
      setOverheadCheckboxValue,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkCompletionReport),
)
