import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_SUCCESS,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_FAILURE,
  UPDATE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_REQUEST,
} from '../constants/actionTypes'

export const fetchContacts = organizationId => ({
  type: FETCH_CONTACTS_REQUEST,
  organizationId,
})

export const fetchContactsSuccess = data => ({
  type: FETCH_CONTACTS_SUCCESS,
  data,
})

export const fetchContactsFailure = code => ({
  type: FETCH_CONTACTS_FAILURE,
  code,
})

export const createContact = (organizationId, data) => ({
  type: CREATE_CONTACT_REQUEST,
  organizationId,
  data,
})

export const createContactSuccess = data => ({
  type: CREATE_CONTACT_SUCCESS,
  data,
})

export const createContactFailure = code => ({
  type: CREATE_CONTACT_FAILURE,
  code,
})

export const updateContact = (organizationId, contactId, data) => ({
  type: UPDATE_CONTACT_REQUEST,
  organizationId,
  contactId,
  data,
})

export const updateContactSuccess = data => ({
  type: UPDATE_CONTACT_SUCCESS,
  data,
})

export const updateContactFailure = code => ({
  type: UPDATE_CONTACT_FAILURE,
  code,
})

export const deleteContact = (organizationId, id) => ({
  type: DELETE_CONTACT_REQUEST,
  organizationId,
  id,
})

export const deleteContactSuccess = data => ({
  type: DELETE_CONTACT_SUCCESS,
  data,
})

export const deleteContactFailure = code => ({
  type: DELETE_CONTACT_FAILURE,
  code,
})
