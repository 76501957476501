import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReportDownload from './ReportDownload'
import shortId from 'shortid'

function ReportDownloadContainer({
  downloadFileRequest,
  filesDownloadingQueue,
  reportUrl,
}) {
  const [id] = useState(shortId.generate())
  const [reportDownloading, setReportDownloading] = useState(false)
  const [denyToReq, setDenyToReq] = useState(false)
  useEffect(() => {
    if (!filesDownloadingQueue.find(file => file.id === id)) {
      setReportDownloading(false)
    }
  }, [filesDownloadingQueue])

  function onClickHandler() {
    setReportDownloading(true)
    setDenyToReq(true)
    setTimeout(() => {
      setDenyToReq(false)
    }, 2000)
    downloadFileRequest({
      id,
      url: reportUrl,
      name: 'report-list.xlsx',
    })
  }
  return (
    <ReportDownload
      onClick={onClickHandler}
      disabled={reportDownloading || denyToReq}
    />
  )
}
ReportDownloadContainer.propTypes = {
  filesDownloadingQueue: PropTypes.array.isRequired,
  reportUrl: PropTypes.string.isRequired,
  downloadFileRequest: PropTypes.func.isRequired,
}

export default ReportDownloadContainer
