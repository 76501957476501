import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createSelector } from 'reselect'

import InnerCalls from './InnerCalls'
import { setFilterDrawer, clearFilterDrawer } from '../../../actions/appActions'
import {
  fetchInnerCallsRequest,
  fetchInnerCallsCountClosedRequest,
  setCommonInnerCallsListState,
} from '../../../actions/innerCallsActions'

import { withTablePaginationHOC } from '../../../helpers/tablePaginationHOC'

const selectCommonInnerCallsListState = state =>
  state.innerCalls.commonInnerCallsListState
const selectCurrentState = createSelector(
  selectCommonInnerCallsListState,
  commonInnerCallsListState => commonInnerCallsListState.currentStatus,
)
const selectCurrentPage = createSelector(
  selectCommonInnerCallsListState,
  commonInnerCallsListState => commonInnerCallsListState.currentPage,
)

const selectCurrentRowsPerPage = createSelector(
  selectCommonInnerCallsListState,
  commonInnerCallsListState => commonInnerCallsListState.currentRowsPerPage,
)

const selectInnerCallsList = state => state.innerCalls.innerCallsList
const extractCount = (counts, status) =>
  _.get(
    counts.find(count => count.status === status),
    'count',
  ) || 0

const selectCreatedCount = createSelector(
  selectInnerCallsList,
  innerCallsList => extractCount(innerCallsList.counts, 'created'),
)

const selectClosedCount = createSelector(selectInnerCallsList, innerCallsList =>
  extractCount(innerCallsList.counts, 'closed'),
)

const selectPendingCount = createSelector(
  selectInnerCallsList,
  innerCallsList => extractCount(innerCallsList.counts, 'pending'),
)

const selectAllCount = createSelector(selectInnerCallsList, innerCallsList =>
  extractCount(innerCallsList.counts, 'all'),
)

const mapStateToProps = state => ({
  isFetchingInnerCalls: state.innerCalls.innerCallsList.isFetching,
  innerCalls: state.innerCalls.innerCallsList.calls,
  ifOneOrganizationId: state.innerCalls.innerCallsList.ifOneOrganizationId,
  createdCount: selectCreatedCount(state),
  closedCount: selectClosedCount(state),
  pendingCount: selectPendingCount(state),
  allCount: selectAllCount(state),
  currentStatus: selectCurrentState(state),
  currentPage: selectCurrentPage(state),
  currentRowsPerPage: selectCurrentRowsPerPage(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setFilterDrawer,
      clearFilterDrawer,
      fetchInnerCallsRequest,
      fetchInnerCallsCountClosedRequest,
      setCommonInnerCallsListState,
    },
    dispatch,
  )

const optionsHOC = {
  propNameData: 'innerCalls',
  propNameUpdateData: 'fetchInnerCallsRequest',
  propNameIsFetching: 'isFetchingInnerCalls',
  propNameCount: 'count',
  propNameSetRequestPayload: 'setInnerCallsRequestPayload',
  rowsPerPageDefault: 10,
  rowsPerPageOptions: [10, 30, 50],
}
const mapStateToPropsForHOC = state => ({
  data: state.innerCalls.innerCallsList.calls,
  isFetching: state.innerCalls.innerCallsList.isFetching,
  count: state.innerCalls.innerCallsList.count,
  isFetchingCount: state.innerCalls.innerCallsList.isFetching,
})

const mapDispatchToPropsForHOC = dispatch =>
  bindActionCreators(
    {
      fetchAction: fetchInnerCallsRequest,
    },
    dispatch,
  )

export default compose(
  withTablePaginationHOC(
    mapStateToPropsForHOC,
    mapDispatchToPropsForHOC,
    optionsHOC,
    InnerCalls,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(InnerCalls)
