import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

// We can inject some CSS into the DOM.
const styles = theme => ({
  // button: {
  //   background: theme.palette.main.main,
  //   borderRadius: 3,
  //   border: 0,
  //   margin: '0 4px 0 15px',
  //   color: 'white',
  //   padding: '4px 30px',
  //   minHeight: '32px',
  //   boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
  //   '&$disabled': {
  //     color: 'white',
  //     background: theme.palette.main.light,
  //   },
  // },
  button: {
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
  },
})

function ApplyButton(props) {
  return (
    <Button
      size="small"
      className={classnames(
        ..._.compact([props.classes.button, props.className]),
      )}
      {...props}
    >
      {props.children}
    </Button>
  )
}

ApplyButton.defaultProps = {
  children: '',
}

ApplyButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ApplyButton)
