import { fetchProxy, URL } from '../api'

const defaultHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-auth-token': localStorage.getItem('atoirAccessToken'),
})

export const generateSDWorkingReport = payload => async () => {
  const response = await fetchProxy(
    `${URL}/organizations/${payload.organizationId}/generate_working_report`,
    {
      method: 'POST',
      headers: defaultHeaders(),
    },
  )
  return response.json()
}
