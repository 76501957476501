import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  title: {
    margin: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
})

function Title(props) {
  return (
    <Typography
      variant="subheading"
      align="left"
      className={props.classes.title}
      {...props}
    >
      {props.children}
    </Typography>
  )
}

Title.defaultProps = {
  children: '',
}

Title.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Title)
