import React from 'react'
import ReasonDictionaryDialog from '../ReasonDictionaryDialog'
import ExpandableTextField from './ExpandableTextField'
import i18next from '../../../../../i18n/i18n'

const ReasonInput = ({ work_type: workType, onChange }) => {
  function onChangeHandler(eventOrValue) {
    const value = _.get(eventOrValue, 'target.value') || eventOrValue
    onChange('work_type', value)
  }
  return (
    <ExpandableTextField
      independent
      value={workType}
      fullWidth
      label={i18next.t('inner_calls_web_atoir:workType')}
      onChange={onChangeHandler}
    >
      {({ open, handleClose }) => (
        <ReasonDictionaryDialog
          open={open}
          handleClose={handleClose}
          onChange={onChangeHandler}
          reason={workType}
        />
      )}
    </ExpandableTextField>
  )
}

export default ReasonInput
