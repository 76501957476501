import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EquipmentHistoryComponent from '../../../components/Equipment/Profile/EquipmentHistoryComponent'
import {
  fetchEquipmentLogs,
  equipmentLogsCount,
} from '../../../actions/equipmentActions'

const mapStateToProps = state => ({
  isFetchingLogs: state.equipment.equipmentLogs.isFetching,
  logs: state.equipment.equipmentLogs.logs,
  fetchLogsErrors: state.equipment.equipmentLogs.errors,
  isFetchingLogsCount: state.equipment.equipmentLogsCount.isFetching,
  count: state.equipment.equipmentLogsCount.count,
  fetchLogsCountErrors: state.equipment.equipmentLogsCount.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEquipmentLogs,
      fetchEquipmentLogsCount: equipmentLogsCount,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentHistoryComponent),
)
