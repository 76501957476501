import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../../i18n/i18n'

// material-ui
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

// modules
import FormDialog from '../../../../../../widgets/FormDialog'
import DateInput from '../../../../../../widgets/DateInput'

const unixStartTime = '1970-01-01T00:00:00.000Z'

class FormEditOrderInfo extends Component {
  constructor(props) {
    super(props)
    const { dateVisit, commentVisit } = props
    this.state = {
      date: dateVisit === '1970-01-01' ? null : dateVisit,
      comment: commentVisit,
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: { isFetching, onClose },
    } = this
    if (prevProps.isFetching !== isFetching && !isFetching) {
      onClose()
    }
  }

  onChangeDate = date => {
    this.setState({ date })
  }

  onChangeComment = event => {
    this.setState({ comment: event.target.value })
  }

  onSubmit = () => {
    const {
      props: { idOrder, editOrderInfo },
      state: { date, comment },
    } = this
    const payload = {
      id: idOrder,
      data: {
        visit_date: date || unixStartTime,
        visit_comment: comment,
      },
    }
    editOrderInfo(payload)
  }

  render() {
    const {
      props: { open, onClose },
      state: { date, comment },
      onChangeDate,
      onChangeComment,
      onSubmit,
    } = this

    return (
      <FormDialog
        open={open}
        fullWidth
        title={i18next.t('service_desk_web_atoir:editOrderDateAndComment')}
        submitButtonTitle={i18next.t('shared_web_atoir:edit')}
        onSubmit={onSubmit}
        onClose={onClose}
        onExit={onClose}
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DateInput disablePast value={date} handleChange={onChangeDate} />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              multiline
              label={i18next.t('service_desk_web_atoir:comment')}
              variant="outlined"
              value={comment}
              onChange={onChangeComment}
              margin="normal"
            />
          </Grid>
        </Grid>
      </FormDialog>
    )
  }
}

FormEditOrderInfo.defaultProps = {
  commentVisit: '',
}

FormEditOrderInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  idOrder: PropTypes.string.isRequired,
  dateVisit: PropTypes.any.isRequired,
  commentVisit: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default FormEditOrderInfo
