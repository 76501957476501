import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TrustAcceptOrDenyDialog from './TrustAcceptOrDenyDialog'
import {
  acceptTrustRequest,
  closeAcceptOrDenyDialog,
  rejectTrustRequest,
} from '../../../../actions/trustedOrganizationActions'

const mapStateToProps = state => ({
  params: state.trustedOrganizations.acceptDenyDialog.params,
  open: state.trustedOrganizations.acceptDenyDialog.open,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeAcceptOrDenyDialog,
      acceptTrust: acceptTrustRequest,
      rejectTrust: rejectTrustRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrustAcceptOrDenyDialog),
)
