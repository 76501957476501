import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EmailTemplates from './EmailTemplates'
import {
  getEmailTemplatesRequest,
  deleteEmailTemplateRequest,
} from '../../../../actions/emailTemplatesActions'
import { updateSettingsLists } from '../../../../actions/settingsActions'

const mapStateToProps = state => ({
  isFetching: state.emailTemplates.getTemplates.isFetching,
  templates: state.emailTemplates.getTemplates.templates,
  isDeletingTemplate: state.emailTemplates.deleteTemplate.isFetching,
  deleteTemplateError: state.emailTemplates.deleteTemplate.error,
  shouldUpdateTemplatesList: state.settings.updateSettingsLists,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEmailTemplatesRequest,
      deleteEmailTemplateRequest,
      updateSettingsLists,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailTemplates),
)
