import React, { Component, Fragment } from 'react'

class Auth extends Component {
  componentDidUpdate(prevProps) {
    if (
      (prevProps.shouldRedirectToHome !== this.props.shouldRedirectToHome &&
        this.props.shouldRedirectToHome) ||
      (prevProps.isLoggedIn !== this.props.isLoggedIn && !this.props.isLoggedIn)
    ) {
      this.props.history.replace('/')
    }
  }

  componentDidMount() {
    const {
      props: {
        logout,
        redirectUserToHomePage,
        refreshTokens,
        fetchUserOrganizationFunctionalsRequest,
      },
    } = this

    const minute = 60 * 1000
    const second = 1000
    const checkTokenExpirationCycle = 5 * second
    clearInterval(this.timerId)

    let atoirAccessTokenExpired = localStorage.getItem(
      'atoirAccessTokenExpired',
    )
    let currentTime = new Date().getTime()
    let updatingToken = localStorage.getItem('updatingtoken')
    if (
      atoirAccessTokenExpired &&
      currentTime >= atoirAccessTokenExpired - minute &&
      Date.now() - updatingToken < checkTokenExpirationCycle
    ) {
      refreshTokens()
    }

    this.timerId = setInterval(() => {
      updatingToken = localStorage.getItem('updatingtoken')
      if (Date.now() - updatingToken < checkTokenExpirationCycle) {
        console.log('Already refreshing ещлут')
        return
      }
      const refreshTokenExpired = localStorage.getItem(
        'atoirRefreshTokenExpired',
      )
      atoirAccessTokenExpired = localStorage.getItem('atoirAccessTokenExpired')
      currentTime = new Date().getTime()
      if (!refreshTokenExpired || currentTime >= +refreshTokenExpired) {
        logout(true)
        redirectUserToHomePage()
        return
      }
      if (currentTime >= atoirAccessTokenExpired - minute) {
        refreshTokens()
      }
    }, checkTokenExpirationCycle)

    // need to manipulate with available functional
    fetchUserOrganizationFunctionalsRequest()
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  render() {
    const { children } = this.props

    return <Fragment>{children}</Fragment>
  }
}

export default Auth
