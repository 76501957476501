import React from 'react'

export const Context = React.createContext({})

const ListSettingsDialogContext = ({ children }) => {
  const [saveStorage, setSaveStorage] = React.useState({ save: () => {} })
  function setSave(save) {
    setSaveStorage({ save })
  }
  return (
    <>
      <Context.Provider
        value={{
          save: saveStorage.save,
          setSave,
        }}
      >
        {children}
      </Context.Provider>
    </>
  )
}

export default ListSettingsDialogContext
