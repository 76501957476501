import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../i18n/i18n'
import { URL } from '../../../../../middlewares/api'

// material ui
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

// components
import RequestPhotos from '../../../CallProfile/widgets/RequestPhotos'
import BlockTitle from '../../../CallProfile/widgets/BlockTitle'
import MapViewEmbed from '../../../../../widgets/MapViewEmbed'

const styles = theme => ({
  blockTitle: {
    bottomMargin: theme.spacing(2),
  },
  blockSubtitle: {
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 'normal',
  },
  expansionPanel: {
    '&::before': {
      height: '0px',
      backgroundColor: 'transparent',
    },
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
    marginBottom: theme.spacing(2),
  },
  expansionPanelSummary: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  expansionPanelDetails: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
})

class InnerCallAttachments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  componentWillMount() {
    const {
      props: { callId, fetchInnerCallPhotosCountRequest },
    } = this
    fetchInnerCallPhotosCountRequest(callId)
  }

  componentDidUpdate(prevProps) {
    const {
      props: { isUploadingPhoto, isDeletingPhoto },
      getPhotos,
    } = this
    if (
      (prevProps.isUploadingPhoto !== isUploadingPhoto && !isUploadingPhoto) ||
      (prevProps.isDeletingPhoto !== isDeletingPhoto && !isDeletingPhoto)
    ) {
      getPhotos()
    }
  }

  getPhotos = () => {
    const {
      props: {
        callId,
        fetchInnerCallPhotosRequest,
        fetchInnerCallPhotosCountRequest,
      },
    } = this
    fetchInnerCallPhotosRequest(callId)
    fetchInnerCallPhotosCountRequest(callId)
  }

  onExpandedChange = () => {
    this.setState(
      state => ({
        expanded: !state.expanded,
      }),
      () => {
        if (this.state.expanded) {
          this.getPhotos()
        }
      },
    )
  }

  uploadPhoto = file => {
    const {
      props: {
        match: {
          params: { innerCallId },
        },
        uploadInnerCallPhotoRequest,
      },
    } = this
    uploadInnerCallPhotoRequest({ file, innerCallId })
  }

  deletePhoto = params => {
    this.props.deleteInnerCallPhotoRequest(params)
  }

  downloadPhotoUrl = photo => {
    const {
      props: {
        match: {
          params: { innerCallId },
        },
      },
    } = this
    return `${URL}/inner_calls/${innerCallId}/photos/${photo.id}`
  }

  render() {
    const {
      props: {
        classes,
        innerCall,
        callCreationDate,
        canEdit,
        isFetchingPhotos,
        photos,
        photosCount,
      },
      state: { expanded },
      onExpandedChange,
      uploadPhoto,
      deletePhoto,
      downloadPhotoUrl,
    } = this
    return (
      <Fragment>
        <ExpansionPanel
          id="call-detailed-info"
          expanded={expanded}
          onChange={onExpandedChange}
          elevation={0}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expansionPanelSummary}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.blockTitle}>
                  {i18next.t('service_desk_web_atoir:detailedInfo')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.blockSubtitle}>
                  {`${i18next.t(
                    'service_desk_web_atoir:photos',
                  )} (${photosCount}), ${i18next.t(
                    'inner_calls_web_atoir:innerCallCreatedPlace',
                  )}${
                    innerCall.status === 'closed'
                      ? `, ${i18next.t(
                          'inner_calls_web_atoir:innerCallClosedPlace',
                        )}`
                      : ''
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RequestPhotos
                  canEdit={canEdit}
                  callId={innerCall.id}
                  year={new Date(callCreationDate).getFullYear()}
                  isFetchingPhotos={isFetchingPhotos}
                  photos={photos}
                  formDataName="file"
                  upload={uploadPhoto}
                  delete={deletePhoto}
                  downloadUrl={downloadPhotoUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <BlockTitle
                      text={i18next.t(
                        'inner_calls_web_atoir:innerCallCreatedPlace',
                      )}
                      noTopMargin
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MapViewEmbed address={innerCall.created_address} />
                  </Grid>
                </Grid>
              </Grid>
              {innerCall.status === 'closed' ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BlockTitle
                        text={i18next.t(
                          'inner_calls_web_atoir:innerCallClosedPlace',
                        )}
                        noTopMargin
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MapViewEmbed address={innerCall.closed_address} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
}

InnerCallAttachments.propTypes = {
  callId: PropTypes.string.isRequired,
  callCreationDate: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  fetchInnerCallPhotosRequest: PropTypes.func.isRequired,
  isFetchingPhotos: PropTypes.bool.isRequired,
  photos: PropTypes.array.isRequired,
  fetchInnerCallPhotosCountRequest: PropTypes.func.isRequired,
  photosCount: PropTypes.number.isRequired,
  uploadInnerCallPhotoRequest: PropTypes.func.isRequired,
  isUploadingPhoto: PropTypes.bool.isRequired,
  deleteInnerCallPhotoRequest: PropTypes.func.isRequired,
  isDeletingPhoto: PropTypes.bool.isRequired,
}

export default withStyles(styles)(InnerCallAttachments)
