import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    margin: 8,
    borderWidth: 1,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
  },
  destructive: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
    '&:hover': {
      background: theme.palette.error.lightest,
      color: theme.palette.error.dark,
    },
    '&:disabled': {
      borderColor: theme.palette.apply.extraLight,
      color: theme.palette.apply.extraLight,
    },
  },
  secondary: {
    color: theme.palette.blueSecondary.main,
    borderColor: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
    '&:disabled': {
      borderColor: theme.palette.apply.extraLight,
      color: theme.palette.apply.extraLight,
    },
  },
})

function OutlinedButton({ classes, size, variant, children, ...other }) {
  return (
    <Button
      {...other}
      size={size}
      className={classNames([classes.root, classes[variant]])}
      variant="outlined"
    >
      {children}
    </Button>
  )
}

OutlinedButton.defaultProps = {
  children: '',
  size: 'small',
  variant: 'secondary',
}

OutlinedButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['destructive', 'secondary']),
}

export default withStyles(styles)(OutlinedButton)
