import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

import { withStyles } from '@material-ui/core/styles'
// components
import AddUser from './AddUser/AddUser'

import LinkUserAccount from './LinkUserAccount/LinkUserAccount'
// material-ui

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
})

class AddStaffDialogComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      activeStep: 0,
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        openDialog,
        isAddingOrganizationUser,
        addUserError,
        organizationUserId,
        isUpdatingOrganizationUser,
        updateOrganizationUserError,
        completeCreateUpdateOrganizationUser,
        isBindingUserAccount,
        bindUserAccountError,
        isUnbindingUserAccount,
        unbindUserAccountError,
      },
      state: { activeStep },
      handleDialogClose,
    } = this

    if (prevProps.openDialog !== openDialog && openDialog) {
      this.setState({
        activeStep: 1,
      })
    }
    if (
      (prevProps.isAddingOrganizationUser !== isAddingOrganizationUser &&
        !isAddingOrganizationUser &&
        isEmpty(addUserError) &&
        !isEmpty(organizationUserId)) ||
      (prevProps.isUpdatingOrganizationUser !== isUpdatingOrganizationUser &&
        !isUpdatingOrganizationUser &&
        isEmpty(updateOrganizationUserError))
    ) {
      if (this.props.user.is_owner) {
        completeCreateUpdateOrganizationUser()
        handleDialogClose()
      } else {
        this.setState({
          activeStep: 2,
        })
      }
    }
    if (
      (activeStep === 2 &&
        prevProps.isAddingOrganizationUser !== isAddingOrganizationUser &&
        !isAddingOrganizationUser &&
        isEmpty(addUserError) &&
        !isEmpty(organizationUserId)) ||
      (activeStep === 2 &&
        prevProps.isUpdatingOrganizationUser !== isUpdatingOrganizationUser &&
        !isUpdatingOrganizationUser &&
        isEmpty(updateOrganizationUserError)) ||
      (activeStep === 2 &&
        prevProps.isBindingUserAccount !== isBindingUserAccount &&
        !isBindingUserAccount &&
        isEmpty(bindUserAccountError)) ||
      (activeStep === 2 &&
        prevProps.isUnbindingUserAccount !== isUnbindingUserAccount &&
        !isUnbindingUserAccount &&
        isEmpty(unbindUserAccountError))
    ) {
      this.setState({
        activeStep: 0,
      })
    }
  }

  handleDialogClose = () => {
    this.setState(
      {
        activeStep: 0,
      },
      () => this.props.closeDialog(),
    )
  }

  render() {
    const { activeStep } = this.state
    const { edit, user } = this.props

    return (
      <div>
        <AddUser
          openDialog={activeStep === 1}
          closeDialog={() => this.handleDialogClose()}
          edit={edit}
          user={user}
        />
        <LinkUserAccount
          openDialog={activeStep === 2}
          closeDialog={() => this.handleDialogClose()}
          edit={edit}
          user={user}
        />
      </div>
    )
  }
}

AddStaffDialogComponent.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  isAddingOrganizationUser: PropTypes.bool.isRequired,
  organizationUserId: PropTypes.string.isRequired,
  addUserError: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  isUpdatingOrganizationUser: PropTypes.bool.isRequired,
  updateOrganizationUserError: PropTypes.object.isRequired,
  completeCreateUpdateOrganizationUser: PropTypes.func.isRequired,
}

export default withStyles(styles)(AddStaffDialogComponent)
