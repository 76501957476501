import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SentToAuction from './SentToAuction'
import {
  showDialogLoader,
  hideDialogLoader,
} from '../../../../actions/utilsActions'
import { putUpOnAuction } from '../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  isPuttingOnAuctionProject:
    state.inventory.putUpOnAuctionInventoryProjects.isPuttingOnAuctionProject,
  puttingOnAuctionProjectError:
    state.inventory.putUpOnAuctionInventoryProjects
      .puttingOnAuctionProjectError,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showDialogLoader,
      hideDialogLoader,
      putUpOnAuction,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SentToAuction),
)
