import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = theme => ({
  root: {
    '&$checked': {
      color: theme.palette.blueSecondary.main,
    },
  },
  checked: {},
})

const CheckBoxSelector = ({
  classes,
  currentValue,
  onChange,
  idValue,
  label,
  ...restProps
}) => {
  const onChangeHandler = ({ target: { checked } }) => {
    onChange(idValue, checked)
  }

  return (
    <FormControl className={classes.formControl} {...restProps}>
      <FormControlLabel
        control={
          <Checkbox
            id={idValue}
            onChange={onChangeHandler}
            checked={currentValue}
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        }
        label={label}
      />
    </FormControl>
  )
}

CheckBoxSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  idValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  currentValue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(withStyles(styles)(CheckBoxSelector))
