import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AssignToOwnOrganization from './AssignToOwnOrganization'
import { assignContractRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isAssigning: state.serviceDeskContracts.assign.isFetching,
  assignOwn: state.serviceDeskContracts.assign.assignOwn,
  assignError: state.serviceDeskContracts.assign.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      assignContractRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssignToOwnOrganization),
)
