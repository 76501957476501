import React from 'react'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'

import i18next from '../../../../i18n/i18n'

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 8,
  },
  label: {
    fontSize: 12,
  },
}))

const AssigningRestrictionSwitch = ({
  organizationId,
  organizationSettings,
  editRestriction,
}) => {
  const classes = useStyles()

  const assigningRestrictionValue = React.useMemo(() => {
    return (
      organizationSettings.find(
        option => option.name === 'restrict_sd_calls_from_non_contragents',
      ) || {}
    ).enabled
  }, [organizationSettings])

  const editRestrictionHandler = (event, value) => {
    editRestriction({ id: organizationId, value })
  }

  return (
    <FormGroup className={classes.root}>
      {assigningRestrictionValue !== undefined && (
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <Switch
              size="small"
              checked={assigningRestrictionValue}
              onChange={editRestrictionHandler}
              value="checkedB"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={i18next.t(
            'organizations_web_atoir:allowAssigningOnlyForContragents',
          )}
        />
      )}
    </FormGroup>
  )
}

export default AssigningRestrictionSwitch
