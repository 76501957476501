import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchInventoryEquipmentLocations,
  fetchInventoryEquipment,
  fetchInventoryEquipmentCount,
} from '../../../../../../actions/inventoryEquipmentActions'
import InventoryEquipmentLocationsTable from './InventoryEquipmentLocationsTable'

const mapStateToProps = state => ({
  isFetchingLocations: state.inventoryEquipment.isFetchingLocations,
  locations: state.inventoryEquipment.locations,
  locationsCount: state.inventoryEquipment.locationsCount,
  fetchLocationsErrors: state.inventoryEquipment.fetchLocationsErrors,
  isFetchingEquipment: state.inventoryEquipment.isFetchingEquipment,
  equipment: state.inventoryEquipment.equipment,
  fetchEquipmentErrors: state.inventoryEquipment.fetchEquipmentErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryEquipmentLocations,
      fetchInventoryEquipment,
      fetchInventoryEquipmentCount,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InventoryEquipmentLocationsTable),
)
