import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InventoryProjectProfileComponent from './InventoryProjectProfileComponent'
import {
  fetchInventoryProjectProfileRights,
  fetchInventoryProjectProfileRightsDefault,
  fetchInventoryProjectProfile,
  fetchInventoryProjectProfileDefault,
} from '../../../../actions/inventoryActions'
import { hideDialogLoader } from '../../../../actions/utilsActions'

const mapStateToProps = state => ({
  projectInfo: state.inventory.project,
  isFetchingProject: state.inventory.isFetchingProject,
  isFetchingInventoryProjectRights:
    state.inventory.fetchInventoryProjectRights
      .isFetchingInventoryProjectRights,
  inventoryProjectPermissions:
    state.inventory.fetchInventoryProjectRights.inventoryProjectPermissions,
  isPuttingOnAuctionProject:
    state.inventory.putUpOnAuctionInventoryProjects.isPuttingOnAuctionProject,
  puttingOnAuctionProjectError:
    state.inventory.putUpOnAuctionInventoryProjects
      .puttingOnAuctionProjectError,
  isStopAuctionSuccess:
    state.inventory.stopInventoryProjectAuctions.isStopSuccess,
  isStopAuctionFetching: state.inventory.stopInventoryProjectAuctions.isStop,
  isConfirmOfferSuccess:
    state.inventory.confirmOfferInventoryProject.isConfirmSuccessfully,
  isConfirmOffer: state.inventory.confirmOfferInventoryProject.isConfirm,
  isSettingOwnPerformer: state.inventory.setOwnPerformer.isSettingPerformer,
  setOwnSuccessfully: state.inventory.setOwnPerformer.setOwnSuccessfully,
  isSettingForeignPerformer:
    state.inventory.setForeignPerformer.isSettingPerformer,
  setForeignSuccessfully:
    state.inventory.setForeignPerformer.setForeignSuccessfully,
  isConfirmingAsPerformer:
    state.inventory.inventoryProjectConfirmAsPerformer
      .isConfirmingInventoryProjectAsPerformer,
  confirmingErrors:
    state.inventory.inventoryProjectConfirmAsPerformer.confirmingErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryProjectProfile,
      fetchInventoryProjectProfileRights,
      fetchInventoryProjectProfileRightsDefault,
      fetchInventoryProjectProfileDefault,
      hideDialogLoader,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InventoryProjectProfileComponent),
)
