import React from 'react'
import styled from 'styled-components'
import PhotoCell from '../images/PhotoCell'

const SlidingView = styled.div`
  overflow: auto;
  white-space: nowrap;
`

const PhotoCellInline = styled.div`
  display: inline-block;
  color: white;
  text-align: center;
  padding: 0 8px;
  text-decoration: none;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export default function({ urls }) {
  return (
    <SlidingView>
      {urls.map(url => (
        <PhotoCellInline key={url}>
          <PhotoCell component={Image} url={url} />
        </PhotoCellInline>
      ))}
    </SlidingView>
  )
}
