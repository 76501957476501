import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_BY_ID_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPLOAD_USER_IMAGE_REQUEST,
  UPLOAD_USER_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAILURE,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_AVATAR_SUCCESS,
  UPDATE_USER_AVATAR_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REMOVE_USER_AVATAR_REQUEST,
  REMOVE_USER_AVATAR_SUCCESS,
  REMOVE_USER_AVATAR_FAILURE,
  CLEAR_VALIDATION_ERRORS,
  FETCH_USER_NOTIFICATION_SETTINGS,
  FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_USER_NOTIFICATION_SETTINGS_FAILURE,
  SET_USER_NOTIFICATION_SETTING,
  SET_USER_NOTIFICATION_SETTING_SUCCESS,
  SET_USER_NOTIFICATION_SETTING_FAILURE,
} from '../constants/actionTypes'

export const fetchUser = () => ({
  type: FETCH_USER_REQUEST,
})

export const fetchUserSuccess = data => ({
  type: FETCH_USER_SUCCESS,
  data,
})

export const fetchUserFailure = errors => ({
  type: FETCH_USER_FAILURE,
  errors,
})

export const fetchUserById = (userId, organizationId) => ({
  type: FETCH_USER_BY_ID_REQUEST,
  userId,
  organizationId,
})

export const uploadUserImage = imageData => ({
  type: UPLOAD_USER_IMAGE_REQUEST,
  imageData,
})

export const uploadUserImageSuccess = avatarName => ({
  type: UPLOAD_USER_IMAGE_SUCCESS,
  avatarName,
})

export const uploadUserImageFailure = errors => ({
  type: UPLOAD_USER_IMAGE_FAILURE,
  errors,
})

export const updateUserAvatar = avatarData => ({
  type: UPDATE_USER_AVATAR_REQUEST,
  avatarData,
})

export const updateUserAvatarSuccess = avatarData => ({
  type: UPDATE_USER_AVATAR_SUCCESS,
  avatarData,
})

export const updateUserAvatarFailure = errors => ({
  type: UPDATE_USER_AVATAR_FAILURE,
  errors,
})

export const removeUserAvatar = avatarData => ({
  type: REMOVE_USER_AVATAR_REQUEST,
  avatarData,
})

export const removeUserAvatarSuccess = avatarData => ({
  type: REMOVE_USER_AVATAR_SUCCESS,
  avatarData,
})

export const removeUserAvatarFailure = errors => ({
  type: REMOVE_USER_AVATAR_FAILURE,
  errors,
})

export const updateUser = data => ({
  type: UPDATE_USER_REQUEST,
  data,
})

export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
})

export const updateUserFailure = errors => ({
  type: UPDATE_USER_FAILURE,
  errors,
})

export const changePassword = data => ({
  type: CHANGE_PASSWORD_REQUEST,
  data,
})

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const changePasswordFailure = errors => ({
  type: CHANGE_PASSWORD_FAILURE,
  errors,
})

export const clearValidationErrors = () => ({
  type: CLEAR_VALIDATION_ERRORS,
})

export const fetchNotificationSettings = () => ({
  type: FETCH_USER_NOTIFICATION_SETTINGS,
})

export const fetchNotificationSettingsSuccess = settings => ({
  type: FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  settings,
})

export const fetchNotificationSettingsFailure = code => ({
  type: FETCH_USER_NOTIFICATION_SETTINGS_FAILURE,
  code,
})

export const setNotificationSetting = changes => ({
  type: SET_USER_NOTIFICATION_SETTING,
  payload: changes,
})

export const setNotificationSettingSuccess = settings => ({
  type: SET_USER_NOTIFICATION_SETTING_SUCCESS,
  settings,
})

export const setNotificationSettingFailure = payload => ({
  type: SET_USER_NOTIFICATION_SETTING_FAILURE,
  payload,
})
