import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RejectContract from './RejectContract'
import { rejectContractRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isRejecting: state.serviceDeskContracts.reject.isFetching,
  rejectError: state.serviceDeskContracts.reject.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      rejectContractRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RejectContract),
)
