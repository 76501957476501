import {
  REPAIR_PASSWORD_REQUEST,
  REPAIR_PASSWORD_SUCCESS,
  REPAIR_PASSWORD_FAILURE,
  CONFIRM_REPAIR_PASSWORD_REQUEST,
  CONFIRM_REPAIR_PASSWORD_SUCCESS,
  CONFIRM_REPAIR_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  REPAIR_PASSWORD_CANCEL,
  CLEAR_REPAIR_PASSWORD_ERRORS,
} from '../constants/actionTypes'

const initialState = {
  isRepairingPassword: false,
  isConfirmingRepairPassword: false,
  canRepairPassword: false,
  shouldResendLink: false,
  isSettingNewPassword: false,
  repairPasswordErrors: {},
}

const repairPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPAIR_PASSWORD_REQUEST:
      return {
        ...state,
        isRepairingPassword: true,
      }

    case REPAIR_PASSWORD_SUCCESS:
      return {
        ...state,
        isRepairingPassword: false,
        shouldResendLink: false,
        repairPasswordErrors: {},
      }

    case REPAIR_PASSWORD_CANCEL:
      return {
        ...state,
        isRepairingPassword: false,
        shouldResendLink: false,
      }

    case REPAIR_PASSWORD_FAILURE:
      return {
        ...state,
        isRepairingPassword: false,
        shouldResendLink: action.shouldResendLink,
        repairPasswordErrors: action.errors,
      }

    case CLEAR_REPAIR_PASSWORD_ERRORS:
      return {
        ...state,
        repairPasswordErrors: {},
      }

    case CONFIRM_REPAIR_PASSWORD_REQUEST:
      return {
        ...state,
        isConfirmingRepairPassword: true,
        repairPasswordErrors: {},
      }

    case CONFIRM_REPAIR_PASSWORD_SUCCESS:
      return {
        ...state,
        isConfirmingRepairPassword: false,
        canRepairPassword: true,
      }

    case CONFIRM_REPAIR_PASSWORD_FAILURE:
      return {
        ...state,
        isConfirmingRepairPassword: false,
        canRepairPassword: false,
        repairPasswordErrors: action.errors,
        repairPasswordErrorCode: action.code,
      }

    case SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        isSettingNewPassword: true,
      }

    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isSettingNewPassword: false,
        repairPasswordErrors: {},
      }

    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        isSettingNewPassword: false,
        repairPasswordErrors: action.errors,
      }

    default:
      return state
  }
}

export default repairPasswordReducer
