export const CREATE_BUTTON_REQUEST = 'buttons/CREATE_BUTTON_REQUEST'
export const CREATE_BUTTON_SUCCESS = 'buttons/CREATE_BUTTON_SUCCESS'
export const CREATE_BUTTON_FAILURE = 'buttons/CREATE_BUTTON_FAILURE'

export const EDIT_BUTTON_REQUEST = 'buttons/EDIT_BUTTON_REQUEST'
export const EDIT_BUTTON_SUCCESS = 'buttons/EDIT_BUTTON_SUCCESS'
export const EDIT_BUTTON_FAILURE = 'buttons/EDIT_BUTTON_FAILURE'

export const GET_BUTTONS_REQUEST = 'buttons/GET_BUTTONS_REQUEST'
export const GET_BUTTONS_SUCCESS = 'buttons/GET_BUTTONS_SUCCESS'
export const GET_BUTTONS_FAILURE = 'buttons/GET_BUTTONS_FAILURE'

export const DELETE_BUTTON_REQUEST = 'buttons/DELETE_BUTTON_REQUEST'
export const DELETE_BUTTON_SUCCESS = 'buttons/DELETE_BUTTON_SUCCESS'
export const DELETE_BUTTON_FAILURE = 'buttons/DELETE_BUTTON_FAILURE'

export const GET_BUTTON_REQUEST = 'buttons/GET_BUTTON_REQUEST'
export const GET_BUTTON_SUCCESS = 'buttons/GET_BUTTON_SUCCESS'
export const GET_BUTTON_FAILURE = 'buttons/GET_BUTTON_FAILURE'

export const GET_BUTTONS_HISTORY_REQUEST = 'buttons/GET_BUTTONS_HISTORY_REQUEST'
export const GET_BUTTONS_HISTORY_SUCCESS = 'buttons/GET_BUTTONS_HISTORY_SUCCESS'
export const GET_BUTTONS_HISTORY_FAILURE = 'buttons/GET_BUTTONS_HISTORY_FAILURE'
export const CLEAR_BUTTONS_HISTORY = 'buttons/CLEAR_BUTTONS_HISTORY'

export const EXECUTE_SD_CALL_BUTTON_REQUEST =
  'buttons/EXECUTE_SD_CALL_BUTTON_REQUEST'
export const EXECUTE_SD_CALL_BUTTON_SUCCESS =
  'buttons/EXECUTE_SD_CALL_BUTTON_SUCCESS'
export const EXECUTE_SD_CALL_BUTTON_FAILURE =
  'buttons/EXECUTE_SD_CALL_BUTTON_FAILURE'

export const GET_SD_CALL_BUTTONS_REQUEST = 'buttons/GET_SD_CALL_BUTTONS_REQUEST'
export const GET_SD_CALL_BUTTONS_SUCCESS = 'buttons/GET_SD_CALL_BUTTONS_SUCCESS'
export const GET_SD_CALL_BUTTONS_FAILURE = 'buttons/GET_SD_CALL_BUTTONS_FAILURE'
