import React from 'react'
import PropTypes from 'prop-types'
import FormDialog from '../../../widgets/FormDialog'
import { withStyles } from '@material-ui/core/index'

const styles = theme => ({
  buttonWrapper: {
    alignItems: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const SimpleDialog = props => {
  const {
    title,
    content,
    isOpen,
    handleClose,
    handlePositive,
    handleNegative,
    positiveText,
    negativeText,
    negativeHidden,
    positiveDisabled,
    isLoading,
    closeIconHidded,
    positiveButtonVariant,
    DialogContentStyle,
  } = props

  const secondaryButtonsArray = negativeHidden
    ? []
    : [
        {
          secondaryText: negativeText,
          handleSecondaryAction: handleNegative,
        },
      ]
  return (
    <FormDialog
      open={isOpen}
      isLoading={isLoading}
      title={title}
      onClose={handleClose}
      onExit={handleClose}
      onSubmit={positiveDisabled ? () => {} : handlePositive}
      submitButtonTitle={positiveText}
      secondaryButtonsArray={secondaryButtonsArray}
      closeIconHidden={closeIconHidded}
      positiveButtonVariant={positiveButtonVariant}
      DialogContentStyle={DialogContentStyle}
    >
      {content}
    </FormDialog>
  )
}

SimpleDialog.defaultProps = {
  isLoading: false,
  negativeHidden: false,
  positiveDisabled: false,
  closeIconHidded: false,
  positiveButtonVariant: 'contained',
  DialogContentStyle: {},
}

SimpleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePositive: PropTypes.func.isRequired,
  handleNegative: PropTypes.func.isRequired,
  positiveText: PropTypes.string.isRequired,
  negativeText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  negativeHidden: PropTypes.bool,
  positiveDisabled: PropTypes.bool,
  closeIconHidded: PropTypes.bool,
  positiveButtonVariant: PropTypes.oneOf([
    'contained',
    'destructive',
    'positive',
    'secondary',
  ]),
  DialogContentStyle: PropTypes.object,
}

export default withStyles(styles)(SimpleDialog)
