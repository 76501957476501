import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import Loader from '../../../Loader'
import ProjectInfo from './ProjectInfo'
import FormAddEngineers from './FormAddEngineers'
import FormDialog from '../../../../widgets/FormDialog'
import DropDownList from '../../../../widgets/DropDownList'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography/Typography'

const styles = theme => ({
  formHelperText: {
    padding: theme.spacing(1),
  },
})

class ConfirmInventoryProjectPerformerDialog extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selectedUsers: [],
      errors: {},
      comment: '',
      isPerformerResponsibleOpen: false,
      performerResponsible: props.project.performer_responsible_id || null,
    }
  }

  componentDidUpdate(prevState) {
    const {
      props: {
        isConfirmingInventoryProjectAsPerformer,
        confirmingErrors,
        handleClose,
        open,
        organizationId,
        fetchBoundOrganizationUsers,
        fetchOrganizationStaff,
        project,
      },
    } = this

    if (project.status !== 'inwork' && open !== prevState.open && open) {
      fetchOrganizationStaff(organizationId)
      fetchBoundOrganizationUsers(organizationId)
    }

    if (
      prevState.isConfirmingInventoryProjectAsPerformer !==
        isConfirmingInventoryProjectAsPerformer &&
      !isConfirmingInventoryProjectAsPerformer &&
      isEmpty(confirmingErrors)
    ) {
      handleClose()
    }
  }

  handleSubmitForm = event => {
    event.preventDefault()
    const { confirmInventoryProject } = this
    confirmInventoryProject()
  }

  confirmInventoryProject = () => {
    const {
      props: {
        confirmInventoryProjectAsPerformer,
        editEngineersInventory,
        organizationId,
        projectId,
        project,
        match,
        handleClose,
        setOwnPerformer,
        boundUsers,
        isFetchEngineers,
        isFetchingBoundUsers,
        engineers,
      },
      state: { selectedUsers, performerResponsible, comment },
    } = this
    const items =
      isFetchEngineers || isFetchingBoundUsers
        ? []
        : boundUsers.filter(_boundUser =>
            engineers.every(_engineer => _engineer.id !== _boundUser.id),
          )
    if (!isEmpty(items)) {
      const validation = this.validateData(selectedUsers)
      if (!isEmpty(validation)) {
        this.setState({
          errors: validation,
        })
        return
      }
      this.setState({
        errors: {},
      })
      const engineersIds = selectedUsers.map(u => u.id)
      const data = {
        engineers_ids: engineersIds,
        responsible_id: performerResponsible,
      }

      if (project.status === 'inwork') {
        editEngineersInventory(
          project.performer_organization_id,
          match.params.projectId,
          data,
        )
        handleClose()
      }
      if (project.status === 'inperformer') {
        confirmInventoryProjectAsPerformer(organizationId, projectId, data)
        handleClose()
      }
      if (project.status === 'created') {
        setOwnPerformer(organizationId, projectId, { ...data, comment })
        handleClose()
      }
    } else {
      this.setState({
        noMoreEngineers: true,
      })
    }
  }

  validateData = selectedUsers => {
    const errors = {}
    if (isEmpty(selectedUsers)) {
      errors.selectedUsers = i18next.t(
        'inventory_web_atoir:inventoryProjectEngineersRequired',
      )
    }
    if (
      isEmpty(this.state.performerResponsible) &&
      this.props.project.status !== 'inwork'
    ) {
      errors.performerResponsible = i18next.t(
        'inventory_web_atoir:inventoryProjectPerformerResponsibleRequired',
      )
    }
    return errors
  }

  handleSelectedUsers = selectedUsers => {
    this.setState({ selectedUsers })
  }

  handleOnExit = () => {
    this.setState({
      selectedUsers: [],
      errors: {},
      noMoreEngineers: false,
    })
    this.props.handleClose()
  }

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleOpenDropDown = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleCloseDropDown = field => () => {
    this.setState({
      [field]: false,
    })
  }

  handleChangeResponsible = field => event => {
    this.setState({
      [field]: event.target.value,
    })
  }

  render() {
    const {
      handleSelectedUsers,
      handleCloseDropDown,
      handleOpenDropDown,
      handleChangeResponsible,
      props: {
        classes,
        open,
        isConfirmingInventoryProjectAsPerformer,
        isFetchingOrganizationStaff,
        boundUsers,
        engineers,
        organizationStaff,
        isFetchEngineers,
        isFetchingBoundUsers,
        title,
        submitButtonTitle,
        project,
        client,
      },
      state: {
        errors,
        selectedUsers,
        comment,
        isPerformerResponsibleOpen,
        performerResponsible,
        noMoreEngineers,
      },
    } = this
    const selectedUsersHelperText = errors.selectedUsers
      ? errors.selectedUsers
      : ''
    const performerResponsibleHelperText = errors.performerResponsible
      ? errors.performerResponsible
      : ''
    const items =
      isFetchEngineers || isFetchingBoundUsers
        ? []
        : boundUsers.filter(_boundUser =>
            engineers.every(_engineer => _engineer.id !== _boundUser.id),
          )
    return (
      <Fragment>
        <FormDialog
          open={open}
          isLoading={isConfirmingInventoryProjectAsPerformer}
          title={title}
          onClose={this.handleOnExit}
          onExit={this.handleOnExit}
          onSubmit={this.confirmInventoryProject}
          submitButtonTitle={submitButtonTitle}
        >
          <ProjectInfo client={client} />
          <br />
          {project.status !== 'inwork' && (
            <Fragment>
              <Typography>
                {i18next.t(
                  'inventory_web_atoir:responsibleForInventoryProject',
                )}
              </Typography>
              <DropDownList
                classes={classes}
                open={isPerformerResponsibleOpen}
                value={performerResponsible}
                error={!isEmpty(errors.performerResponsible)}
                handleToogle={handleOpenDropDown('isPerformerResponsibleOpen')}
                elements={
                  organizationStaff
                    ? organizationStaff.map(p => {
                        const middleName =
                          p.middle_name === 'none' ? '' : p.middle_name
                        return {
                          id: p.id,
                          title: `${p.first_name} ${p.last_name} ${middleName}`,
                        }
                      })
                    : []
                }
                isFetching={isFetchingOrganizationStaff}
                handleOpen={handleOpenDropDown('isPerformerResponsibleOpen')}
                handleClose={handleCloseDropDown('isPerformerResponsibleOpen')}
                handleChange={handleChangeResponsible('performerResponsible')}
                label="project-responsible"
              />
              <br />
              {!isEmpty(errors.performerResponsible) ? (
                <FormHelperText className={classes.formHelperText} error>
                  {performerResponsibleHelperText}
                </FormHelperText>
              ) : null}
              <br />
            </Fragment>
          )}
          {isFetchEngineers || isFetchingBoundUsers ? (
            <Loader />
          ) : !isEmpty(items) ? (
            <FormAddEngineers
              handleSelectedItems={handleSelectedUsers}
              items={items}
              selectedItems={selectedUsers}
            />
          ) : (
            <FormHelperText className={classes.formHelperText}>
              <Typography color={noMoreEngineers ? 'error' : ''}>
                {i18next.t('inventory_web_atoir:noMoreEngineers')}
              </Typography>
            </FormHelperText>
          )}
          {!isEmpty(errors.selectedUsers) ? (
            <FormHelperText className={classes.formHelperText} error>
              {selectedUsersHelperText}
            </FormHelperText>
          ) : null}
          {project.status === 'created' && (
            <TextField
              fullWidth
              multiline
              id="comment"
              label={i18next.t(
                'inventory_web_atoir:inventoryCommentForPerformer',
              )}
              placeholder={i18next.t(
                'inventory_web_atoir:enterInventoryCommentForPerformer',
              )}
              className={classes.textField}
              margin="normal"
              onChange={this.handleChange('comment')}
              value={comment}
            />
          )}
        </FormDialog>
      </Fragment>
    )
  }
}

ConfirmInventoryProjectPerformerDialog.propTypes = {
  title: PropTypes.string.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  confirmInventoryProjectAsPerformer: PropTypes.func.isRequired,
  isConfirmingInventoryProjectAsPerformer: PropTypes.bool.isRequired,
  confirmingErrors: PropTypes.object.isRequired,
  client: PropTypes.bool.isRequired,
}

export default withStyles(styles)(ConfirmInventoryProjectPerformerDialog)
