import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'

// helpers
import { formatSizeUnits } from '../../helpers/formatSizeUnits'

// modules
import OutlinedButton from '../Buttons/OutlinedButton'
import ContainedButton from '../Buttons/ContainedButton'
import SimpleDialogLoader from '../SimpleDialogLoader'

// i18next
import i18next from '../../i18n/i18n'

// material
import IconButton from '@material-ui/core/IconButton/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'

// icons
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  dialogTitle: {
    paddingTop: '24px',
  },
  divDialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(46),
  },
  textField: {
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  inputFiles: {
    display: 'none',
  },
  inputLabel: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
  },
  path: {
    width: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  filesArea: {},
  fileArea: {
    display: 'flex',
  },
  paper: {
    width: theme.typography.pxToRem(800),
    maxWidth: theme.typography.pxToRem(800),
  },
  delBtn: {
    padding: theme.typography.pxToRem(2),
  },
  divActionBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
  },
  noMargin: {
    margin: 0,
  },
  buttonWrapper: {
    margin: '8px 0px 0px 4px',
  },
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: theme.typography.pxToRem(224),
        width: theme.typography.pxToRem(250),
      },
    },
  },
  formControl: {
    maxWidth: 320,
  },
  button: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: theme.spacing(2),
  },
})

const AddCommentDialog = props => {
  const {
    classes,
    onClose,
    onChange,
    comment,
    fromOrg,
    fromList,
    setFrom,
    loading,
    error,
  } = props
  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        onExit={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.divDialogTitle}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            <Typography>{i18next.t('shared_web_atoir:yourComment')}</Typography>
          </DialogTitle>
          <div className={classes.container}>
            <InputBase
              type="file"
              className={classes.inputFiles}
              id="raised-button-file"
              inputProps={{ multiple: true }}
              onChange={event => {
                onChange('addFiles', { files: event.target.files })
              }}
            />
            <InputLabel
              className={classes.inputLabel}
              htmlFor="raised-button-file"
            >
              <AttachFileIcon fontSize="small" />
              <Typography>
                {i18next.t('shared_web_atoir:attachFile')}
              </Typography>
            </InputLabel>
          </div>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              multiline
              rows="4"
              rowsMax="8"
              id="outlined-bare"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={comment.text}
              onChange={event => {
                onChange('addText', { text: event.target.value })
              }}
              error={error}
            />
            {comment.files.length > 0 && (
              <div className={classes.filesArea}>
                {comment.files.map(file => (
                  <div className={classes.fileArea}>
                    <AttachFileIcon fontSize="small" color="secondary" />
                    <div>{`${file.name} (${formatSizeUnits(file.size)})`}</div>
                    <IconButton
                      className={classes.delBtn}
                      onClick={() => {
                        onChange('delFiles', { name: file.name })
                      }}
                    >
                      <CloseIcon fontSize="small" color="secondary" />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
          </DialogContentText>
          <form onSubmit={props.handleSubmit} noValidate>
            <DialogActions className={classes.buttonWrapper}>
              <div className={classes.divActionBtn}>
                <div>
                  {fromOrg && fromList && setFrom && (
                    <FormControl
                      className={classes.formControl}
                      disabled={!(fromList.length > 1)}
                    >
                      <Select
                        value={props.fromOrg.organization_id}
                        onChange={props.setFrom}
                        MenuProps={classes.MenuProps}
                      >
                        {props.fromList &&
                          props.fromList.map(fromItem => (
                            <MenuItem
                              key={fromItem.organization_id}
                              value={fromItem.organization_id}
                            >
                              {fromItem.short_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div>
                  <OutlinedButton
                    classes={{ root: classes.button }}
                    onClick={onClose}
                    size="medium"
                  >
                    {i18next.t('shared_web_atoir:cancelComment')}
                  </OutlinedButton>
                  <ContainedButton
                    classes={{ root: classes.button }}
                    type="submit"
                    size="medium"
                  >
                    {i18next.t('shared_web_atoir:submitComment')}
                  </ContainedButton>
                </div>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <SimpleDialogLoader open={loading} />
    </Fragment>
  )
}

AddCommentDialog.defaultProps = {
  fromOrg: null,
  fromList: null,
  setFrom: null,
}

AddCommentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  comment: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fromOrg: PropTypes.object,
  fromList: PropTypes.array,
  setFrom: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
}

export default withStyles(styles)(AddCommentDialog)
