import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import i18next from '../../../i18n/i18n'
import WrapperRoute from '../WrapperRoute'
import BreadCrumb from './BreadCrumb'
import FAQ from '../../Help/FAQ'
import NoMatchComponent from '../../NoMatchComponent'

const FAQRouter = ({ match: { path } }) => (
  <Fragment>
    <BreadCrumb to={path}>
      {i18next.t('system_menu_web_atoir:faqReferenceMenuItem')}
    </BreadCrumb>
    <Switch>
      <WrapperRoute
        path={`${path}`}
        title={i18next.t('employees_web_atoir:accountStaffTitle')}
        crumbOff
        component={FAQ}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default FAQRouter
