import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CancelAgreement from './CancelAgreement'
import { cancelAgreementRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isCanceling: state.serviceDeskContracts.cancelAgreement.isFetching,
  cancelError: state.serviceDeskContracts.cancelAgreement.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelAgreementRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CancelAgreement),
)
