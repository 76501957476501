import InputLabel from '@material-ui/core/InputLabel'
import i18next from '../../../../i18n/i18n'
import SelectLimitedAuto from '../../../../widgets/SelectLimitedAuto'
import { sortStringify } from '../../../../helpers/queryHelper'
import { URL } from '../../../../middlewares/api'
import { getUserFullName } from '../../../../helpers/userHelper'
import FormControl from '@material-ui/core/FormControl'
import React from 'react'

function ResponsibleSelector({ onChange, idValue, value, organizationId }) {
  const onChangeHandler = newValue => {
    onChange(idValue, newValue)
  }
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={idValue}>
        {i18next.t('inventory_web_atoir:responsibleForEquipment')}
      </InputLabel>
      <SelectLimitedAuto
        id={idValue}
        value={value}
        query={sortStringify({
          last_name: 'ASC',
          first_name: 'ASC',
          middle_name: 'ASC',
        })}
        sourceUrl={`${URL}/organizations/${organizationId}/users`}
        onChange={onChangeHandler}
        requestResultTransformerToArray={r => r.users}
        itemTransformer={l => ({ id: l.id, title: getUserFullName(l) })}
      />
    </FormControl>
  )
}

export default ResponsibleSelector
