import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import ServiceDeskContractAgreementRouter from './ServiceDeskContractsAgreementRouter'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import BreadCrumb from './BreadCrumb'
import ContractsList from '../../ServiceDesk/ContractsList'
import ContractProfile from '../../ServiceDesk/Contract/ContractProfile'
import CreateContract from '../../ServiceDesk/Contracts/CreateContract'
import AddEquipment from '../../ServiceDesk/Contracts/AddEquipment'
import ContractHistory from '../../ServiceDesk/Contract/ContractHistory'
import DistributeEquipment from '../../ServiceDesk/Contract/DistributeEquipment'

const ServiceDeskRouter = ({ match: { path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${path}/`}
        component={({ match: { url, path: _path } }) => (
          <Fragment>
            <BreadCrumb to={url}>
              {i18next.t('service_desk_web_atoir:contracts')}
            </BreadCrumb>
            <Switch>
              <WrapperRoute
                path={`${_path}create`}
                component={CreateContract}
              />
              <WrapperRoute
                path={`${_path}:contractId`}
                component={({ match: { path: __path } }) => (
                  <Fragment>
                    <Switch>
                      <WrapperRoute
                        path={`${__path}/add_equipment`}
                        component={AddEquipment}
                      />
                      <WrapperRoute
                        path={`${__path}/agreements`}
                        component={ServiceDeskContractAgreementRouter}
                      />
                      <WrapperRoute
                        showHeader
                        showTitleOutOfPaper
                        path={`${__path}/distribute_equipment`}
                        component={DistributeEquipment}
                      />
                      <WrapperRoute
                        path={`${__path}/history`}
                        component={ContractHistory}
                      />
                      <WrapperRoute
                        path={`${__path}/`}
                        component={ContractProfile}
                        showHeader
                        showTitleOutOfPaper
                      />
                    </Switch>
                  </Fragment>
                )}
              />
              <WrapperRoute
                path={`${_path}`}
                component={ContractsList}
                showHeader
                title={i18next.t(
                  'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepair',
                )}
              />
            </Switch>
          </Fragment>
        )}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default ServiceDeskRouter
