import React, { Fragment } from 'react'

// ---component---
import InventoryPerformerInfoBase from '../InventoryPerformerInfoBase'

const InventoryPerformerInfo = () => (
  <Fragment>
    <InventoryPerformerInfoBase />
  </Fragment>
)

export default InventoryPerformerInfo
