import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from '../../../../../i18n/i18n'
import isEmpty from 'is-empty'
import get from 'lodash/get'
import { convertDHMDuration } from '../../../../../helpers/dateHelper'

const styles = () => ({
  root: {
    marginTop: 20,
    overflowX: 'auto',
  },
  borderNone: {
    border: 'none',
  },
  tableRow: {
    height: 30,
  },
  tableBody: {
    '& :last-child td': {
      border: 'none',
    },
  },
})

class InnerCallsSummaryTable extends Component {
  createData = (name, value) => ({ name, value })

  rows = () => {
    const {
      props: { summary },
      createData,
    } = this

    const result = []

    if (get(summary, 'allcalls')) {
      result.push(
        createData(
          i18n.t('inner_calls_web_atoir:summaryCallByTime'),
          !isEmpty(summary) ? summary.allcalls : 0,
        ),
      )
    }
    if (get(summary, 'closed_sum')) {
      result.push(
        createData(
          i18n.t('inner_calls_web_atoir:summaryClosedCallByTime'),
          !isEmpty(summary) ? summary.closed_sum : 0,
        ),
      )
    }
    if (get(summary, 'engineer_sum')) {
      result.push(
        createData(
          i18n.t('inner_calls_web_atoir:engineerSum'),
          !isEmpty(summary) ? summary.engineer_sum : 0,
        ),
      )
    }
    if (get(summary, 'closedcalls')) {
      result.push(
        createData(
          i18n.t('inner_calls_web_atoir:ready'),
          !isEmpty(summary) ? summary.closedcalls : 0,
        ),
      )
    }
    if (get(summary, 'inworkcalls')) {
      result.push(
        createData(
          i18n.t('inner_calls_web_atoir:createdStatus'),
          !isEmpty(summary) ? summary.inworkcalls : 0,
        ),
      )
    }
    if (get(summary, 'average_work_time')) {
      result.push(
        createData(
          i18n.t('inner_calls_web_atoir:averageTimeOfOne'),
          !isEmpty(summary) ? convertDHMDuration(summary.average_work_time) : 0,
        ),
      )
    }

    return result
  }

  render() {
    const {
      rows,
      props: { classes },
    } = this

    return (
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell padding="none" className={classes.borderNone}>
              {i18n.t('inner_calls_web_atoir:summary')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {rows().map(row => (
            <TableRow key={row.name} className={classes.tableRow}>
              <TableCell align="left" padding="none">
                {row.name}
              </TableCell>
              <TableCell align="right" padding="none">
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default withStyles(styles)(InnerCallsSummaryTable)
