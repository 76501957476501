import React, { Component } from 'react'
import PropTypes from 'prop-types'
import config from '../../config'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
})

class MapViewEmbed extends Component {
  state = {}

  render() {
    const {
      props: { address },
    } = this
    return address ? (
      <iframe
        title="google-map-place"
        width="100%"
        height="400px"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/place?key=${config.GOOGLE_MAP_API_KEY}&q=${address}`}
        allowFullScreen
      />
    ) : null
  }
}

MapViewEmbed.propTypes = {
  address: PropTypes.string.isRequired,
}

export default withStyles(styles)(MapViewEmbed)
