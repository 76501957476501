import * as constants from '../constants/actionTypes/emailTemplates'
import { actionCreator as externalActionCreator } from './actionCreator'

export const getEmailTemplatesRequest = externalActionCreator(
  constants.GET_EMAIL_TEMPLATES_REQUEST,
)
export const getEmailTemplatesSuccess = externalActionCreator(
  constants.GET_EMAIL_TEMPLATES_SUCCESS,
)
export const getEmailTemplatesFailure = externalActionCreator(
  constants.GET_EMAIL_TEMPLATES_FAILURE,
)

export const getEmailTemplateRequest = externalActionCreator(
  constants.GET_EMAIL_TEMPLATE_REQUEST,
)
export const getEmailTemplateSuccess = externalActionCreator(
  constants.GET_EMAIL_TEMPLATE_SUCCESS,
)
export const getEmailTemplateFailure = externalActionCreator(
  constants.GET_EMAIL_TEMPLATE_FAILURE,
)

export const createEmailTemplateRequest = externalActionCreator(
  constants.CREATE_EMAIL_TEMPLATE_REQUEST,
)
export const createEmailTemplateSuccess = externalActionCreator(
  constants.CREATE_EMAIL_TEMPLATE_SUCCESS,
)
export const createEmailTemplateFailure = externalActionCreator(
  constants.CREATE_EMAIL_TEMPLATE_FAILURE,
)

export const editEmailTemplateRequest = externalActionCreator(
  constants.EDIT_EMAIL_TEMPLATE_REQUEST,
)
export const editEmailTemplateSuccess = externalActionCreator(
  constants.EDIT_EMAIL_TEMPLATE_SUCCESS,
)
export const editEmailTemplateFailure = externalActionCreator(
  constants.EDIT_EMAIL_TEMPLATE_FAILURE,
)

export const deleteEmailTemplateRequest = externalActionCreator(
  constants.DELETE_EMAIL_TEMPLATE_REQUEST,
)
export const deleteEmailTemplateSuccess = externalActionCreator(
  constants.DELETE_EMAIL_TEMPLATE_SUCCESS,
)
export const deleteEmailTemplateFailure = externalActionCreator(
  constants.DELETE_EMAIL_TEMPLATE_FAILURE,
)
