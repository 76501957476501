import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import InventoryList from './InventoryList'
import {
  fetchInventoryProjects,
  fetchInventoryProjectsCount,
  fetchInventoryProjectsCities,
  getInventoryClients,
  getInventoryPerformers,
} from '../../../actions/inventoryActions'

const mapStateToProps = state => ({
  isFetchingInventoryProjects:
    state.inventory.fetchInventoryProjects.isFetchingInventoryProjects,
  inventoryProjects: state.inventory.fetchInventoryProjects.inventoryProjects,
  fetchInventoryProjectsErrors:
    state.inventory.fetchInventoryProjects.fetchInventoryProjectsErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryProjects,
      fetchInventoryProjectsCount,
      fetchInventoryProjectsCities,
      fetchInventoryClients: getInventoryClients,
      fetchInventoryPerformers: getInventoryPerformers,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryList),
)
