import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../i18n/i18n'
import Typography from '@material-ui/core/Typography'

import Link from 'react-router-dom/Link'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

// components
import Loader from '../../../Loader'

// helpers
import { dateFormatShortYear } from '../../../../helpers/dateHelper'

const stylesHighlightedTemplate = theme => ({
  HighlightedColor: {
    color: theme.palette.primary.main,
  },
})

const styles = theme => ({
  searchContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  arrowBackIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  listItemText: {
    marginLeft: theme.spacing(2),
  },
  loader: {
    marginLeft: theme.spacing(18),
    marginTop: theme.spacing(3),
  },
})

const HighlightedTemplate = withStyles(stylesHighlightedTemplate)(props => {
  console.log('PROPS ', props)
  const value = `${props.children}`
  let chunks = []
  const target = props.searchTemplate
  const start = value.substring(0, value.indexOf(target))
  const fullMatch = target === value

  if (!fullMatch) {
    const rest = value.substring(value.indexOf(target) + target.length)
    chunks = rest.split(target)
  }

  return (
    <Fragment>
      {fullMatch ? (
        <span className={props.classes.HighlightedColor}>{value}</span>
      ) : (
        <Fragment>
          {start}
          {chunks.length > 0 &&
            chunks.map(chunk => (
              <Fragment>
                <span className={props.classes.HighlightedColor}>{target}</span>
                {chunk}
              </Fragment>
            ))}
        </Fragment>
      )}
    </Fragment>
  )
})

HighlightedTemplate.propTypes = {
  searchTemplate: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

const FormedString = props => {
  const value = `${props.children}`
  const { searchTemplate, name } = props
  const position = props.children ? value.indexOf(searchTemplate) : -1
  return (
    <Fragment>
      {position > -1 && (
        <Fragment>
          <div>{`${name}:`}</div>
          <div>
            <Typography component="span" variant="caption">
              <HighlightedTemplate searchTemplate={searchTemplate}>
                {value}
              </HighlightedTemplate>
            </Typography>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

FormedString.propTypes = {
  searchTemplate: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

const ItemRow = props => {
  const {
    c: {
      ext_num: extNum,
      organizationEquipment: {
        name,
        serial_number: serialNumber,
        external_id: externalId,
        model,
      },
    },
    searchTemplate,
    by,
  } = props
  return (
    <Fragment>
      {searchTemplate.length > 0 && by === 'call' && (
        <Fragment>
          <Typography component="span" variant="caption">
            {`${i18next.t(
              'service_desk_web_atoir:captionCallExternalNumber',
            )}: ${extNum || '-'}`}
          </Typography>
        </Fragment>
      )}
      {searchTemplate.length > 0 && by === 'equipment' && (
        <Fragment>
          <FormedString
            searchTemplate={searchTemplate}
            name={i18next.t('service_desk_web_atoir:captionExtId')}
          >
            {externalId}
          </FormedString>
          <FormedString
            searchTemplate={searchTemplate}
            name={i18next.t(
              'service_desk_web_atoir:requestCreationFiltersName',
            )}
          >
            {name}
          </FormedString>
          <FormedString
            searchTemplate={searchTemplate}
            name={i18next.t('service_desk_web_atoir:captionModel')}
          >
            {model}
          </FormedString>
          <FormedString
            searchTemplate={searchTemplate}
            name={i18next.t(
              'service_desk_web_atoir:captionEquipmentSerialNumber',
            )}
          >
            {serialNumber}
          </FormedString>
        </Fragment>
      )}
    </Fragment>
  )
}

class RequestsSearchList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTemplate: '',
    }
  }

  componentWillUnmount() {
    const {
      props: { clearCallsSearchByNumber },
    } = this
    clearCallsSearchByNumber()
  }

  handleChange = event => {
    const {
      props: { getCallsByGeneralSearch, clearCallsSearchByNumber, by },
    } = this
    const {
      target: { value },
    } = event
    this.setState({ searchTemplate: value }, () => {
      if (value.length > 0) {
        getCallsByGeneralSearch({
          search_value: value,
          limit: 10,
          searchFor: by,
          mode: by,
        })
      } else {
        clearCallsSearchByNumber()
      }
    })
  }

  onBack = () => {
    const {
      props: { onToggle, clearCallsSearchByNumber },
    } = this
    onToggle()
    clearCallsSearchByNumber()
  }

  render() {
    const {
      state: { searchTemplate },
      props: {
        history,
        classes,
        isFetchingCallsByNumberSearch,
        callsByNumberSearch,
        by,
        searchFor,
      },
      onBack,
    } = this
    return (
      <Fragment>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.searchContainer}
            alignItems="center"
          >
            <Grid container direction="row">
              <Grid item>
                <IconButton className={classes.arrowBackIcon} onClick={onBack}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <TextField
                  autoFocus
                  id="general-search"
                  type="search"
                  value={searchTemplate}
                  onChange={this.handleChange}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {searchFor === by && isFetchingCallsByNumberSearch ? (
              <div className={classes.loader}>
                <Loader size={24} />
              </div>
            ) : searchFor === by ? (
              <List>
                {callsByNumberSearch.map(c => (
                  <ListItem
                    button
                    component={Link}
                    to={`${history.location.pathname}/${c.id}`}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary={i18next.t(
                        'service_desk_web_atoir:callInternalNumDateTitle',
                        {
                          internalNumber: c.internal_num,
                          dateFrom: dateFormatShortYear(
                            Date.parse(c.created_at),
                          ),
                        },
                      )}
                      secondary={
                        <ItemRow
                          c={c}
                          searchTemplate={searchTemplate}
                          by={by}
                        />
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : null}
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

RequestsSearchList.propTypes = {
  classes: PropTypes.object.isRequired,
  by: PropTypes.string.isRequired,
  searchFor: PropTypes.string.isRequired,
  getCallsByGeneralSearch: PropTypes.func.isRequired,
  isFetchingCallsByNumberSearch: PropTypes.bool.isRequired,
  callsByNumberSearch: PropTypes.array.isRequired,
  clearCallsSearchByNumber: PropTypes.func.isRequired,
}

export default withStyles(styles)(RequestsSearchList)
