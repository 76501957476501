import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AddCompletionFile from './AddCompletionFile'
import { uploadOrderWorkCompletionFileRequest } from '../../../../actions/serviceDesk'
import { showDialogWithOk } from '../../../../actions/appActions'

const mapStateToProps = state => {
  const {
    serviceDesk: {
      workCompletionFile: { isFetching, error },
    },
  } = state

  return {
    isFetching,
    error,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: uploadOrderWorkCompletionFileRequest,
      showDialogWithOk,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AddCompletionFile)
