/* eslint-disable */
// call equipment repair information
export const canEditWhatsWrong = (status, permissions) => status === 'created' && permissions.MANAGER_OF_CLIENT_OR_HIGHER
export const canMakeRecurring = (status, permissions) => (
  status === 'inwork' ||
  status === 'close' ||
  status === 'repair' ||
  status === 'needrepair' ||
  status === 'done'
) && permissions.PLAIN_OF_CLIENT_OR_HIGHER
export const canEditResponsible = (status, permissions) => (status !== 'close' && status !== 'cancel') && permissions.MANAGER_OF_CLIENT_OR_HIGHER
export const canViewCallRepairPerfomanceInfo = (status, permissions) => (status !== 'created' && status !== 'inperformer') && (permissions.RESPONSIBLE_OF_CLIENT || permissions.MANAGER_OF_CLIENT_OR_HIGHER)

// add call useful info
export const canEditAttachments = (status, permissions) => (status !== 'close' && status !== 'cancel') && (permissions.MANAGER_OF_CLIENT_OR_HIGHER || permissions.RESPONSIBLE_OF_CLIENT)

// assign call to performer (own specialist / third party organization)
export const canAssignToOwnSpecialist = (status, permissions) => status === 'created' && (permissions.MANAGER_OF_CLIENT_OR_HIGHER || permissions.RESPONSIBLE_OF_CLIENT)
export const canAssignToThirdPartyOrganization = (status, permissions) => status === 'created' && (permissions.MANAGER_OF_CLIENT_OR_HIGHER || permissions.RESPONSIBLE_OF_CLIENT)
export const isOnClientCallSide = (status, permissions) => permissions.PLAIN_OF_CLIENT_OR_HIGHER
export const canSendToContract = (status, permissions) => status === 'created' && (permissions.MANAGER_OF_CLIENT_OR_HIGHER || permissions.RESPONSIBLE_OF_CLIENT)

// cancel call by equipment owner
export const canCancelCall = (status, permissions) => (status === 'created' && (permissions.RESPONSIBLE_OF_CLIENT || permissions.MANAGER_OF_CLIENT_OR_HIGHER))
  || ((status !== 'created' && status !== 'needrepair' && status !== 'close' && status !== 'cancel')
    && permissions.OWNER_OF_CLIENT)

// print blank
export const canPrintBlank = (status, permissions) => (status !== 'created' && status !== 'inperformer' && status !== 'close' && status !== 'cancel') && permissions.ANY

// cancel order by client owner
export const canCancelOrder = (status, permissions) => (status !== 'done' && status !== 'doneqa' && status !== 'cancel' && status !== 'close') && permissions.OWNER_OF_CLIENT

// reject order by performer
export const canRejectOrder = (status, permissions, type) => status === 'created'
  && permissions.MANAGER_OF_PERFORMER_OR_HIGHER && type === 'diagnostic'

export const isOnPerformerSide = (status, permissions) => permissions.PLAIN_OF_PERFORMER_OR_HIGHER

// accept order as performer
export const canOrderAssign = (status, permissions, type) => status === 'created' && permissions.PLAIN_OF_PERFORMER_OR_HIGHER && type === 'diagnostic'

// assign order as engineer
export const canOrderAssignToMe = (status, permissions) => status === 'created' && permissions.PLAIN_OF_PERFORMER && !permissions.MANAGER_OF_PERFORMER_OR_HIGHER

// assign order as manager or higher
export const canOrderAssignAsManagerOrHigher = (status, permissions) => status === 'created' && permissions.MANAGER_OF_PERFORMER_OR_HIGHER

// assign order to own engineer menu item
export const canOrderAssignToOwnSpecialist = (status, permissions) => status === 'created' && permissions.MANAGER_OF_PERFORMER_OR_HIGHER

// assign order to suborder
export const canOrderAssignToSubOrder = (status, permissions) => status === 'created' && permissions.MANAGER_OF_PERFORMER_OR_HIGHER

// set order result by engineer, responsible or manager or higher
export const canOrderSetResult = (status, permissions) => status === 'inwork'
  && (permissions.ENGINEER || permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER)

// edit / view order document or certificate of completion
export const canOrderEditReportingInfo = (status, permissions) => (status === 'inwork'
    && (permissions.ENGINEER || permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER))
  || ((status === 'control' || status === 'qa')
    && (permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER))
export const canOrderViewReportingInfo = (status, permissions) => status !== 'created' && permissions.ANY
export const canOrderViewReportingInfoNotActive = permissions => permissions.ANY

// send order from inwork to control by engineer, responsible or manager or higher
export const canOrderSendToControl = (status, permissions) => status === 'inwork'
  && (permissions.ENGINEER || permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER)

// send order from control to done by responsible or manager or higher
export const canOrderSendToDone = (status, permissions) => (status === 'control' || status === 'qa')
  && (permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER)

// accept / reject order in done by client responsible or manager or higher
export const canAcceptOrRejectCertificateInDone = (orderStatus, permissions) => (orderStatus === 'done' || orderStatus === 'doneqa')
  && (permissions.MANAGER_OF_CLIENT_OR_HIGHER || permissions.RESPONSIBLE_OF_CLIENT)

export const canViewRepairOfferDocs = (status, permissions, hasRepairDocs) => ((status === 'needrepair' || status === 'repair' || status === 'close' || status === 'cancel')
    || (status === 'qualityControl' && hasRepairDocs)) && permissions.ANY
export const canEditRepairOfferDocs = (status, permissions) => (status === 'needrepair' || status === 'repair')
  && (permissions.MANAGER_OF_CLIENT_OR_HIGHER || permissions.RESPONSIBLE_OF_CLIENT)

export const showCertificateVersionForClient = (permissions) => permissions.VIEW_AS_CLIENT && !permissions.VIEW_AS_PERFORMER

export const canOrderEditEngineer = (status, permissions) => status === 'inwork' && (permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER)
export const canOrderEditResponsible = (status, permissions) => status === 'inwork' && permissions.MANAGER_OF_PERFORMER_OR_HIGHER

export const canOrderSendCancelFromWork = (status, permission) => status === 'inwork' && permission.MANAGER_OF_CLIENT_OR_HIGHER
export const canOrderChangeResposableClient = permission => permission.MANAGER_OF_CLIENT_OR_HIGHER
export const canOrderChangeResposablePerformer = permission => permission.MANAGER_OF_PERFORMER_OR_HIGHER

export const canOrderViewForm = orderPermissions => !orderPermissions.PLAIN_OF_CLIENT_OR_HIGHER

// edit order details
export const canEditInformationForPerformer = (status, permissions) => (status === 'created') && permissions.MANAGER_OF_CLIENT_OR_HIGHER
export const canOrderChangeClientResponsible = (status, permissions) => (status !== 'close' && status !== 'cancel') && permissions.MANAGER_OF_CLIENT_OR_HIGHER
export const canOrderChangePerformerResponsible = (status, permissions) => (status !== 'created' && status !== 'close' && status !== 'cancel') && permissions.MANAGER_OF_PERFORMER_OR_HIGHER
export const canViewEngineer = permissions => permissions.VIEW_AS_PERFORMER
export const canOrderChangeEngineer = (status, permissions) => status !== 'close' && status !== 'cancel' &&
  status !== 'created' && (permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER)
export const canOrderChangeVisitDetails = (status, permissions) => status !== 'close' && status !== 'cancel' &&
  status !== 'created' &&
  (permissions.ENGINEER || permissions.RESPONSIBLE_OF_PERFOMER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER)

export const canViewOrderComments = permissions => permissions.VIEW_AS_CLIENT && permissions.VIEW_AS_PERFORMER
export const canGetEngineerSettings = (status, permissions) => status === 'inwork' && permissions.VIEW_AS_PERFORMER
export const canGetNds = (status, permissions) => ((status === 'qa' || status === 'done' || status === 'doneqa') && permissions.VIEW_AS_CLIENT)
  || (permissions.ENGINEER || permissions.MANAGER_OF_PERFORMER_OR_HIGHER || permissions.RESPONSIBLE_OF_PERFOMER)

// close call from needrepair
export const canCancelCallFromNeedrepair = (status, permissions) => status === 'needrepair'
  && (permissions.RESPONSIBLE_OF_CLIENT || permissions.MANAGER_OF_CLIENT_OR_HIGHER)

// send to repair from needrepair
export const canSendToRepairFromNeedrepair = (status, permissions) => status === 'needrepair'
  && (permissions.RESPONSIBLE_OF_CLIENT || permissions.MANAGER_OF_CLIENT_OR_HIGHER)

export const canViewSDCallButtonsMenu = permissions =>
  permissions.PLAIN_OF_CLIENT_OR_HIGHER || permissions.OWNER_OF_CLIENT

export const canViewSDCallButton = (permissions, scope) =>
  (scope.includes('admin') && permissions.ADMIN_OF_CLIENT_OR_HIGHER)
  || (scope.includes('manager') && permissions.MANAGER_OF_CLIENT_OR_HIGHER)
  || (scope.includes('plain') && permissions.PLAIN_OF_CLIENT_OR_HIGHER)
  || (scope.includes('owner') && permissions.OWNER_OF_CLIENT)

export const canViewSDOrderButton = (permissions, scope) => (
  permissions.VIEW_AS_CLIENT && (
    (scope.includes('admin') && permissions.ADMIN_OF_CLIENT_OR_HIGHER)
    || (scope.includes('manager') && permissions.MANAGER_OF_CLIENT_OR_HIGHER)
    || (scope.includes('plain') && permissions.PLAIN_OF_CLIENT_OR_HIGHER)
    || (scope.includes('owner') && permissions.OWNER_OF_CLIENT)
  )) || (
  permissions.VIEW_AS_PERFORMER && (
    (scope.includes('admin') && permissions.ADMIN_OF_PERFORMER_OR_HIGHER)
    || (scope.includes('manager') && permissions.MANAGER_OF_PERFORMER_OR_HIGHER)
    || (scope.includes('plain') && permissions.PLAIN_OF_PERFORMER_OR_HIGHER)
    || (scope.includes('owner') && permissions.OWNER_OF_PERFOMER)
  ))

export const canViewSDCallHistory = permissions => permissions.ANY

function checkLicense(permissions, side, name) {
    let result = false
    if (side === 'client') {
        result = permissions["client_organization_functionals"].find(item =>item.name === name)
    } else if (side === 'performer') {
        result = permissions["performer_organization_functionals"].find(item =>item.name === name)
    }
    return result
}

export const canOperateButton = (permissions, type, side, licenseName = 'sd_extended') => {
    let canOperate = false

    const matrix = {
        calls: {
                client() {
                    canOperate = checkLicense(permissions, 'client',  licenseName)
                },
                performer() {
                    canOperate = false
                },
                default() {
                    canOperate = false
                },
        },
        orders: {
                client() {
                    canOperate = checkLicense(permissions, 'client',  licenseName)
                },
                performer() {
                    canOperate = checkLicense(permissions, 'performer',  licenseName)
                },
                default() {
                    canOperate = false
                },
        },
        default: {
                default() {
                    canOperate = false
                },

        },
    }


    const getLevel = (data, key) => data[key] || data.default

    const getCanOperateValue = getLevel(getLevel(matrix, type), side)
    getCanOperateValue()
    return canOperate
    }
