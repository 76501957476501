export function setTokens(tokens) {
  localStorage.setItem('loggedIn', true)
  localStorage.setItem('atoirAccessToken', tokens.token)
  localStorage.setItem('atoirRefreshToken', tokens.refreshToken)
  localStorage.setItem(
    'atoirAccessTokenExpired',
    new Date(tokens.expire).getTime(),
  )
  localStorage.setItem(
    'atoirRefreshTokenExpired',
    new Date(tokens.refreshExpire).getTime(),
  )
}

export function removeTokens() {
  localStorage.removeItem('loggedIn')
  localStorage.removeItem('atoirAccessToken')
  localStorage.removeItem('atoirRefreshToken')
  localStorage.removeItem('atoirAccessTokenExpired')
  localStorage.removeItem('atoirRefreshTokenExpired')
}
