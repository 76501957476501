import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// helpers
import { inventoryLocationStringify } from '../../../helpers/parserHelper'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import Subheading from '../../../widgets/Lettering/Subheading'
import AddLocation from '../Locations/AddLocation/AddLocation'
import EditCreateDepartmentComponent from '../Departments/EditCreateDepartment'
import Loader from '../../Loader'
import OrganizationTypeIcon from '../Stateless/OrganizationTypeIcon'
import UpdateLocation from '../Locations/UpdateLocation/UpdateLocation'
import CanOrganizationLocations from './OrganizationAbility/CanOrganizationLocations'
import CanOrganizationDepartments from './OrganizationAbility/CanOrganizationDepartments'
import FormDialog from '../../../widgets/FormDialog'

// material-ui-icons
import AddIcon from '@material-ui/icons/Add'
import OptionsIcon from '@material-ui/icons/MoreVert'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: theme.mixins.gutters({
    flexGrow: 1,
  }),
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  tableWrapper: {
    overflowX: 'scroll',
  },
  leftSubTitle: {
    color: '#555',
  },
  organizationName: {
    marginLeft: '12px',
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    marginLeft: 12,
    display: 'inline-block',
    wordBreak: 'break-all',
  },
  pos: {
    marginBottom: 12,
  },
  namingContainer: {
    textAlign: 'left',
  },
  button: {
    margin: theme.spacing(1),
    width: 24,
    height: 24,
  },
  subLocationTitle: {
    margin: '12px 0 12px 0',
    wordBreak: 'break-all',
  },
  subLocationButton: {
    display: 'inline-block',
    background: 'transparent',
    wordBreak: 'break-all',
    width: 15,
    height: 15,
    boxShadow: 'none',
    padding: 0,
    minWidth: '0',
  },
  locationsAddressText: {
    margin: '12px 0 12px 0',
    wordBreak: 'break-all',
  },
  addButton: {
    margin: '12px 0 12px 0',
    color: 'rgb(66, 155, 213)',
    border: 'solid 1.5px rgb(66, 155, 213)',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      color: 'rgb(255, 255, 255)',
      background: 'rgb(66, 155, 213)',
    },
  },
  labelButton: {
    margin: '12px 0 12px 0',
    color: 'rgb(66, 155, 213)',
    border: '0',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

class OrganizationStructureComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      page: 0,
      rowsPerPage: 10,
      offset: 0,
      limit: 10,
      offsetDepartments: 0,
      limitDepartments: 10,
      pageDepartments: 0,
      rowsPerPageDepartments: 10,
      open: false,
      openDepartmentOptions: false,
      addDepartmentOpen: props.addDepartmentOpen,
      updateDepartmentOpen: false,
      department: props.department,
      snackBarMessage: '',
      isCreatingDepartment: props.isCreatingDepartment,
      updatingDepartmentData: {},
      isDeletingDepartment: props.isDeletingDepartment,
      isUpdatingDepartment: false,
      showDeleteDepartmentDialog: false,
      isCreatingDepartmentDataValid: false,
      isAddLocationDialogOpen: false,
      isUpdateLocationDialogOpen: false,
      location: null,
    }
  }

  componentDidMount() {
    this.fetchLocations()
    this.fetchLocationsCount()
    this.fetchDepartments()
    this.fetchDepartmentsCount()
  }

  componentWillReceiveProps(props) {
    if (props.isCreatingDepartment !== this.state.isCreatingDepartment) {
      this.setState({
        ...props,
      })
      this.showSnackBar(i18next.t('organizations_web_atoir:departmentAdded'))
      props.fetchDepartments(props.organization.id)
    } else if (props.isUpdatingDepartment !== this.state.isUpdatingDepartment) {
      this.setState({
        ...props,
      })
      props.fetchDepartments(props.organization.id)
      this.showSnackBar(i18next.t('organizations_web_atoir:departmentUpdated'))
    } else if (
      this.state.isDeletingDepartment !== props.isDeletingDepartment &&
      props.isDeletingDepartment === false
    ) {
      this.setState({
        ...props,
      })
      if (!props.errors) {
        props.fetchDepartments(props.organization.id)
        this.showSnackBar(
          i18next.t('organizations_web_atoir:departmentDeleted'),
        )
      }
    } else if (props.errors) {
      if (props.errors === 60) {
        this.showSnackBar(i18next.t('error_web_atoir:dataValidationError'))
      }
    }
  }

  componentDidUpdate = prevProps => {
    const { isDeletingLocations, deleteLocationsError } = this.props
    if (
      prevProps.isDeletingLocations !== isDeletingLocations &&
      !isDeletingLocations &&
      !deleteLocationsError
    ) {
      this.fetchLocations()
    }
  }

  fetchLocations = () => {
    const { fetchLocations, organization } = this.props
    const { limit, offset } = this.state
    fetchLocations(organization.id, {
      limit,
      offset,
    })
  }

  fetchDepartments = () => {
    const { fetchDepartments, organization } = this.props
    const { offsetDepartments, limitDepartments } = this.state
    fetchDepartments(organization.id, {
      limit: limitDepartments,
      offset: offsetDepartments,
    })
  }

  fetchLocationsCount = () => {
    const { fetchLocationsCount, organization } = this.props
    fetchLocationsCount(organization.id)
  }

  fetchDepartmentsCount = () => {
    const { fetchDepartmentsCount, organization } = this.props
    fetchDepartmentsCount(organization.id)
  }

  handleChangePage = (event, page) => {
    this.setState(
      state => ({ page, offset: page * state.limit }),
      this.fetchLocations,
    )
  }

  handleChangeRowsPerPage = event => {
    this.setState(
      { rowsPerPage: event.target.value, limit: event.target.value },
      this.fetchLocations,
    )
  }

  handleChangePageDepartments = (event, pageDepartments) => {
    this.setState(
      state => ({
        pageDepartments,
        offsetDepartments: pageDepartments * state.limitDepartments,
      }),
      this.fetchDepartments,
    )
  }

  handleChangeRowsPerPageDepartments = event => {
    this.setState(
      {
        rowsPerPageDepartments: event.target.value,
        limitDepartments: event.target.value,
      },
      this.fetchDepartments,
    )
  }

  handleOptionsClick = menu => event => {
    // This prevents ghost click.
    event.preventDefault()
    const change = {}
    if (menu === 'top') {
      change.openTopMenu = true
    } else {
      change.open = true
    }
    this.setState({
      ...change,
      anchorEl: event.currentTarget,
      location: JSON.parse(event.currentTarget.getAttribute('location')),
    })
  }

  handleDepartmentOptionsClick = event => {
    // This prevents ghost click.
    event.preventDefault()
    this.setState({
      openDepartmentOptions: true,
      anchorEl: event.currentTarget,
      department: JSON.parse(event.currentTarget.getAttribute('department')),
    })
  }

  handleRequestClose = menu => () => {
    const change = {}
    if (menu === 'top') {
      change.openTopMenu = false
    } else {
      change.open = false
    }
    this.setState({
      ...change,
    })
  }

  handleDepartmentRequestClose = () => {
    this.setState({
      openDepartmentOptions: false,
    })
  }

  handleAddDepartmentClose = () => {
    this.setState({
      addDepartmentOpen: false,
      isCreatingDepartment: false,
      AddDepartmentCreateShowErr: false,
      AddCreateDepartmentComponentForceValidation: false,
    })
  }

  handleDepartmentDeleteButton = () => {
    this.setState({
      openDepartmentOptions: false,
    })
    this.showDeleteDepartmentDialog()
  }

  handleDepartmentEditButton = () => {
    this.setState({
      openDepartmentOptions: false,
      updateDepartmentOpen: true,
    })
  }

  handleEditDepartmentClose = () => {
    this.setState({
      updateDepartmentOpen: false,
      EditDepartmentCreateShowErr: false,
    })
  }

  handleAddDepartmentCreate = () => {
    if (this.state.isCreatingDepartmentDataValid) {
      this.setState({
        isCreatingDepartment: true,
      })
      this.props.createDepartment(
        this.props.organization.id,
        this.state.creatingDepartmentData,
      )
      this.setState({
        creatingDepartmentData: {},
        isCreatingDepartmentDataValid: false,
      })
    } else {
      this.setState({
        AddDepartmentCreateShowErr: true,
        AddCreateDepartmentComponentForceValidation: true,
      })
    }
  }

  handleEditDepartmentDone = () => {
    if (this.state.isUpdatingDepartmentDataValid) {
      this.setState({
        isUpdatingDepartment: true,
      })
      this.props.updateDepartment(
        this.props.organization.id,
        this.state.department.id,
        this.state.updatingDepartmentData,
      )
    } else {
      this.setState({
        EditDepartmentCreateShowErr: true,
      })
    }
  }

  openAddDepartmentDialog = () => {
    this.setState({
      addDepartmentOpen: true,
    })
  }

  addDepartmentCallback = (isValid, data) => {
    this.setState(
      {
        isCreatingDepartmentDataValid: isValid,
        creatingDepartmentData: data,
        AddCreateDepartmentComponentForceValidation: false,
      },
      () => {
        if (isValid) {
          this.setState({
            AddDepartmentCreateShowErr: false,
          })
        }
      },
    )
  }

  editDepartmentCallback = (isValid, data) => {
    console.log('editDepartmentCallback', isValid, data)
    this.setState(
      {
        isUpdatingDepartmentDataValid: isValid,
        updatingDepartmentData: data,
      },
      () => {
        if (isValid) {
          this.setState({
            EditDepartmentCreateShowErr: false,
          })
        }
      },
    )
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false })
  }

  showDeleteDepartmentDialog = () => {
    this.setState({
      showDeleteDepartmentDialog: true,
    })
  }

  closeDeleteDepartmentDialog = () => {
    this.setState({
      showDeleteDepartmentDialog: false,
    })
  }

  deleteDepartment = () => {
    this.setState({
      isDeletingDepartment: true,
      showDeleteDepartmentDialog: false,
    })
    this.props.deleteDepartment(
      this.props.organization.id,
      this.state.department.id,
    )
  }

  deleteLocation = () => {
    const { deleteLocation } = this.props
    const { location } = this.state
    this.setState({
      isDeleteLocationDialogOpen: false,
    })
    deleteLocation(this.props.organization.id, location.id)
  }

  showAddAddressDialog = () => {
    this.setState({
      isAddLocationDialogOpen: true,
    })
  }

  showUpdateAddressDialog = () => {
    this.setState({
      isUpdateLocationDialogOpen: true,
      open: false,
      openTopMenu: false,
    })
  }

  showDeleteAddressDialog = () => {
    this.setState({
      isDeleteLocationDialogOpen: true,
      open: false,
    })
  }

  closeAddAddressDialog = message => {
    this.setState({
      isAddLocationDialogOpen: false,
    })
    if (message) {
      this.showSnackBar(message)
    }
  }

  closeUpdateAddressDialog = message => {
    this.setState({
      isUpdateLocationDialogOpen: false,
    })
    if (message) {
      this.showSnackBar(message)
    }
  }

  closeDeleteAddressDialog = () => {
    this.setState({
      isDeleteLocationDialogOpen: false,
    })
  }

  getDeleteDepartmentDialog = () => {
    const { isDeletingDepartment } = this.props
    return (
      <FormDialog
        open={this.state.showDeleteDepartmentDialog}
        isLoading={isDeletingDepartment}
        title={i18next.t('organizations_web_atoir:deleteDepartmentDialogTitle')}
        onClose={this.closeDeleteDepartmentDialog}
        onExit={this.closeDeleteDepartmentDialog}
        onSubmit={this.deleteDepartment}
        submitButtonTitle={i18next.t('shared_web_atoir:delete')}
      >
        {i18next.t('organizations_web_atoir:deleteDepartmentDialogText')}
      </FormDialog>
    )
  }

  getSnackbar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  addLocationDialog = () => (
    <AddLocation
      classes={this.props.classes}
      onClose={this.closeAddAddressDialog}
      open={this.state.isAddLocationDialogOpen}
    />
  )

  updateLocationDialog = () => (
    <UpdateLocation
      classes={this.props.classes}
      locationData={this.state.location}
      onClose={this.closeUpdateAddressDialog}
      open={this.state.isUpdateLocationDialogOpen}
    />
  )

  deleteLocationDialog = () => {
    const { location, isDeleteLocationDialogOpen } = this.state
    const { isDeletingLocations } = this.props
    return (
      <FormDialog
        open={isDeleteLocationDialogOpen}
        isLoading={isDeletingLocations}
        title={i18next.t('organizations_web_atoir:deleteDepartmentDialogTitle')}
        onClose={this.closeDeleteAddressDialog}
        onExit={this.closeDeleteAddressDialog}
        onSubmit={this.deleteLocation}
        submitButtonTitle={i18next.t('shared_web_atoir:delete')}
      >
        {location && (
          <Fragment>
            <Typography variant="body1"> {location.name} </Typography>
            <Typography>
              {' '}
              {!isEmpty(location.Street) &&
                inventoryLocationStringify(location)}{' '}
            </Typography>
          </Fragment>
        )}
      </FormDialog>
    )
  }

  addDepartmentDialog = () => {
    const { isCreatingDepartment } = this.props
    const { AddDepartmentCreateShowErr } = this.state
    return (
      <FormDialog
        open={this.state.addDepartmentOpen}
        isLoading={isCreatingDepartment}
        title={i18next.t('organizations_web_atoir:addDepartmentDialogTitle')}
        onClose={this.handleAddDepartmentClose}
        onExit={this.handleAddDepartmentClose}
        onSubmit={this.handleAddDepartmentCreate}
        submitButtonTitle={i18next.t('shared_web_atoir:add')}
      >
        <EditCreateDepartmentComponent
          phone=""
          phoneAdditional=""
          name=""
          molUserId=""
          personFunction=""
          onChangeFields={this.addDepartmentCallback}
          classes={this.props.classes}
          forceValidation={
            this.state.AddCreateDepartmentComponentForceValidation
          }
        />
        {AddDepartmentCreateShowErr ? (
          <Typography color="error">
            {i18next.t('validation_web_atoir:notAllFieldsFilledCorrectly')}
          </Typography>
        ) : null}
      </FormDialog>
    )
  }

  editDepartmentDialog = () => {
    let { department } = this.state
    department = department
      ? {
          phone: department.phone,
          phoneAdditional: department.phone_additional || '',
          name: department.name,
          molUserId: department.mol_user_organization_id,
          personFunction: department.person_function,
        }
      : {
          phone: '+7',
          phoneAdditional: '',
          name: '',
          molUserId: '',
          personFunction: '',
        }
    return (
      <FormDialog
        open={this.state.updateDepartmentOpen}
        isLoading={this.props.isUpdatingDepartment}
        title={i18next.t('organizations_web_atoir:EditDepartmentDialogTitle')}
        onClose={this.handleEditDepartmentClose}
        onExit={this.handleEditDepartmentClose}
        onSubmit={this.handleEditDepartmentDone}
        submitButtonTitle={i18next.t('shared_web_atoir:save')}
      >
        <EditCreateDepartmentComponent
          {...department}
          onChangeFields={this.editDepartmentCallback}
          classes={this.props.classes}
        />
        {this.state.EditDepartmentCreateShowErr ? (
          <Typography color="error">
            {i18next.t('validation_web_atoir:notAllFieldsFilledCorrectly')}
          </Typography>
        ) : null}
      </FormDialog>
    )
  }

  render() {
    const {
      props: {
        classes,
        organization,
        locations,
        departments,
        locationsCount,
        physical,
        departmentsCount,
        isFetchingLocations,
        isFetchingDepartments,
      },
      state: { rowsPerPage, page, rowsPerPageDepartments, pageDepartments },
      showAddAddressDialog,
      openAddDepartmentDialog,
    } = this
    const locationsPlain = locations.filter(l => l.location_type === 2)
    return (
      <Fragment>
        {this.getSnackbar()}
        {this.getDeleteDepartmentDialog()}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container xs={12} direction="row">
                <Grid item xs={10}>
                  <Subheading>
                    {i18next.t(
                      'organizations_web_atoir:organizationStructureAndAddressesTitle',
                    )}
                  </Subheading>
                </Grid>
                <CanOrganizationLocations I="create" a="OrganizationLocations">
                  <Grid
                    container
                    xs={2}
                    alignItems="flex-start"
                    justify="flex-end"
                  >
                    <Tooltip
                      title={i18next.t('organizations_web_atoir:addAddress')}
                    >
                      <IconButton
                        id="addAddressButton"
                        aria-label={i18next.t(
                          'organizations_web_atoir:addAddress',
                        )}
                        onClick={showAddAddressDialog}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    {this.addLocationDialog()}
                    {this.updateLocationDialog()}
                    {this.deleteLocationDialog()}
                  </Grid>
                </CanOrganizationLocations>
              </Grid>
              <Typography
                paragraph
                variant="body1"
                align="left"
                color="textSecondary"
              >
                {i18next.t(
                  'organizations_web_atoir:organizationStructureAndAddressesDescription',
                )}
              </Typography>
              <div>
                <Typography align="left">
                  <OrganizationTypeIcon
                    fluid={false}
                    clientOrganization={organization.client_organization}
                    tradeOrganization={organization.trade_organization}
                    serviceOrganization={organization.service_organization}
                    type="m"
                  />
                  <OrganizationTypeIcon
                    fluid={false}
                    clientOrganization={organization.client_organization}
                    tradeOrganization={organization.trade_organization}
                    serviceOrganization={organization.service_organization}
                    type="c"
                  />
                  {/* AT-396 */}
                  {/* <OrganizationTypeIcon */}
                  {/* fluid={false} */}
                  {/* clientOrganization={organization.client_organization} */}
                  {/* tradeOrganization={organization.trade_organization} */}
                  {/* serviceOrganization={organization.service_organization} */}
                  {/* type="t" */}
                  {/* /> */}
                  <Typography
                    className={classes.title}
                    variant="h6"
                    align="left"
                  >
                    {organization.short_name}
                    <CanOrganizationLocations
                      I="edit"
                      a="OrganizationLocations"
                    >
                      <span>
                        <IconButton
                          location={JSON.stringify(
                            locations.filter(l => l.location_type === 0)[0],
                          )}
                          onClick={this.handleOptionsClick('top')}
                        >
                          <OptionsIcon />
                        </IconButton>
                        <Menu
                          id="menu-apptoolbar"
                          anchorEl={this.state.anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={this.state.openTopMenu}
                          onClose={this.handleRequestClose('top')}
                        >
                          <MenuItem
                            onClick={this.showUpdateAddressDialog}
                            location={JSON.stringify(physical)}
                          >
                            {i18next.t('shared_web_atoir:edit')}
                          </MenuItem>
                        </Menu>
                      </span>
                    </CanOrganizationLocations>
                  </Typography>
                </Typography>
                <Divider />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.locationsAddressText}
                  align="left"
                >
                  {!isEmpty(physical) && !isEmpty(physical.Street) ? (
                    inventoryLocationStringify(physical)
                  ) : (
                    <Loader />
                  )}
                </Typography>
              </div>
              {!isFetchingLocations ? (
                <div className={classes.tableWrapper}>
                  <Table className={classes.table}>
                    <TableBody>
                      {locationsPlain.map(n => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={n.id}
                          id={`loc-${n.id}`}
                        >
                          <TableCell>
                            <Typography align="left">
                              <Typography
                                className={classes.subLocationTitle}
                                variant="subheading"
                                align="left"
                              >
                                {n.name}
                                <CanOrganizationLocations
                                  I="edit"
                                  a="OrganizationLocations"
                                >
                                  <span>
                                    <IconButton
                                      id="organizationLocationsOptionsBtn"
                                      location={JSON.stringify(n)}
                                      onClick={this.handleOptionsClick('item')}
                                    >
                                      <OptionsIcon />
                                    </IconButton>
                                    <Menu
                                      id="menu-apptoolbar"
                                      anchorEl={this.state.anchorEl}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      open={this.state.open}
                                      onClose={this.handleRequestClose('item')}
                                    >
                                      <MenuItem
                                        id="showUpdateAddressDialogBtn"
                                        onClick={this.showUpdateAddressDialog}
                                        location={JSON.stringify(n)}
                                      >
                                        {i18next.t('shared_web_atoir:edit')}
                                      </MenuItem>
                                      {/* commented until backend api have been fixed  */}
                                      {/* <MenuItem */}
                                      {/* onClick={this.showDeleteAddressDialog} */}
                                      {/* location={JSON.stringify(n)} */}
                                      {/* > */}
                                      {/* {i18next.t('shared_web_atoir:delete')} */}
                                      {/* </MenuItem> */}
                                    </Menu>
                                  </span>
                                </CanOrganizationLocations>
                              </Typography>
                            </Typography>
                            <Divider />
                            <Typography
                              variant="body2"
                              className={classes.locationsAddressText}
                              color="textSecondary"
                              align="left"
                            >
                              {!isEmpty(n.Street) &&
                                inventoryLocationStringify(n)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={6}
                          count={locationsCount}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage={i18next.t(
                            'shared_web_atoir:rowsPerPage',
                          )}
                          labelDisplayedRows={({
                            from: fromPage,
                            to: toPage,
                            count: countPage,
                          }) =>
                            i18next.t('shared_web_atoir:labelDisplayedRows', {
                              fromPage,
                              toPage,
                              countPage,
                            })
                          }
                          page={page}
                          backIconButtonProps={{
                            'aria-label': 'Previous Page',
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'Next Page',
                          }}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              ) : (
                <Loader />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {isFetchingDepartments ? (
              <Loader />
            ) : (
              <Paper className={classes.paper}>
                <Grid container xs={12} direction="row">
                  <Grid item xs={10}>
                    <Subheading>
                      {i18next.t(
                        'organizations_web_atoir:organizationDepartmentsTitle',
                      )}
                    </Subheading>
                  </Grid>
                  <CanOrganizationDepartments
                    I="create"
                    a="OrganizationDepartments"
                  >
                    <Grid
                      container
                      xs={2}
                      alignItems="flex-start"
                      justify="flex-end"
                    >
                      <Tooltip
                        title={i18next.t(
                          'organizations_web_atoir:addDepartment',
                        )}
                      >
                        <IconButton
                          aria-label={i18next.t(
                            'organizations_web_atoir:addDepartment',
                          )}
                          onClick={openAddDepartmentDialog}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                      {this.addDepartmentDialog()}
                      {this.editDepartmentDialog()}
                    </Grid>
                  </CanOrganizationDepartments>
                </Grid>
                <Typography
                  paragraph
                  variant="body1"
                  align="left"
                  color="textSecondary"
                >
                  {i18next.t(
                    'organizations_web_atoir:organizationDepartmentsDescription',
                  )}
                </Typography>
                <Divider />
                {!isFetchingDepartments ? (
                  <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                      <TableBody>
                        {isEmpty(departments) ? (
                          <Typography variant="body1">
                            {i18next.t('shared_web_atoir:emptyList')}
                          </Typography>
                        ) : (
                          departments.map(department => (
                            <TableRow hover tabIndex={-1} key={department.id}>
                              <TableCell>
                                <Typography
                                  className={classes.subLocationTitle}
                                  variant="h6"
                                  align="left"
                                >
                                  {department.name}
                                  <CanOrganizationDepartments
                                    I="edit"
                                    a="OrganizationDepartments"
                                  >
                                    <span>
                                      <IconButton
                                        onClick={
                                          this.handleDepartmentOptionsClick
                                        }
                                        department={JSON.stringify(department)}
                                      >
                                        <OptionsIcon />
                                      </IconButton>
                                      <Menu
                                        id="menu-apptoolbar"
                                        anchorEl={this.state.anchorEl}
                                        anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                        open={this.state.openDepartmentOptions}
                                        onClose={
                                          this.handleDepartmentRequestClose
                                        }
                                      >
                                        <MenuItem
                                          onClick={
                                            this.handleDepartmentEditButton
                                          }
                                        >
                                          {i18next.t('shared_web_atoir:edit')}
                                        </MenuItem>
                                        <CanOrganizationDepartments
                                          I="close"
                                          a="OrganizationDepartments"
                                        >
                                          <MenuItem
                                            onClick={
                                              this.handleDepartmentDeleteButton
                                            }
                                          >
                                            {i18next.t(
                                              'shared_web_atoir:delete',
                                            )}
                                          </MenuItem>
                                        </CanOrganizationDepartments>
                                      </Menu>
                                    </span>
                                  </CanOrganizationDepartments>
                                </Typography>
                                <Divider />
                                {department && department.OrganizationUser && (
                                  <Fragment>
                                    <Typography
                                      className="wordWrapBreakAll"
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      {/* eslint-disable-next-line max-len */}
                                      {`${department.OrganizationUser.last_name} ${department.OrganizationUser.first_name} ${department.OrganizationUser.middle_name}`}
                                    </Typography>
                                    <Typography
                                      className="wordWrapBreakAll"
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      {department.person_function}
                                      {` +${department.phone.slice(0, 1)}
                                    (${department.phone.slice(1, 4)})
                                    ${department.phone.slice(
                                      4,
                                      7,
                                    )}-${department.phone.slice(7, 11)}`}
                                    </Typography>
                                    {department.phone_additional ? (
                                      <Typography
                                        className="wordWrapBreakAll"
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {i18next.t(
                                          'organizations_web_atoir:additionalDepartmentPhone',
                                        )}
                                        :{` ${department.phone_additional}`}
                                      </Typography>
                                    ) : (
                                      ''
                                    )}
                                  </Fragment>
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={6}
                            count={departmentsCount}
                            rowsPerPage={rowsPerPageDepartments}
                            labelRowsPerPage={i18next.t(
                              'shared_web_atoir:rowsPerPage',
                            )}
                            labelDisplayedRows={({
                              from: fromPage,
                              to: toPage,
                              count: countPage,
                            }) =>
                              i18next.t('shared_web_atoir:labelDisplayedRows', {
                                fromPage,
                                toPage,
                                countPage,
                              })
                            }
                            page={pageDepartments}
                            backIconButtonProps={{
                              'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                              'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePageDepartments}
                            onChangeRowsPerPage={
                              this.handleChangeRowsPerPageDepartments
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </div>
                ) : (
                  <Loader />
                )}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

OrganizationStructureComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
}

export default withStyles(styles)(OrganizationStructureComponent)
