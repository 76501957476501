import {
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  FETCH_PHYSICAL_LOCATION_FAILURE,
  FETCH_PHYSICAL_LOCATION_REQUEST,
  FETCH_PHYSICAL_LOCATION_SUCCESS,
  FETCH_LEGAL_LOCATION_REQUEST,
  FETCH_LEGAL_LOCATION_SUCCESS,
  FETCH_LEGAL_LOCATION_FAILURE,
  UPDATE_LOCATION_IN_LOCATIONS,
  FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST,
  FETCH_USER_EQUIPMENTS_LOCATIONS_SUCCESS,
  FETCH_USER_EQUIPMENTS_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_COUNT_REQUEST,
  FETCH_LOCATIONS_COUNT_SUCCESS,
  FETCH_LOCATIONS_COUNT_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  isCreatingLocation: false,
  isUpdatingLocation: false,
  isFetchingLocations: false,
  isFetchingLocationsCount: false,
  isFetchingLocation: false,
  errors: {},
  count: 0,
  locations: [],
  location: {},
  error: 0,
  deleteLocation: {
    isDeletingLocation: false,
    error: null,
  },
}

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LOCATION_REQUEST:
      return {
        ...state,
        isCreatingLocation: true,
        error: undefined,
      }

    case CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        isCreatingLocation: false,
        error: undefined,
      }

    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.code,
        isCreatingLocation: false,
      }

    case UPDATE_LOCATION_REQUEST:
      return {
        ...state,
        isUpdatingLocation: true,
        error: undefined,
      }

    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        isUpdatingLocation: false,
        error: undefined,
      }

    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.code,
        isUpdatingLocation: false,
      }

    case FETCH_LOCATION_REQUEST:
      return {
        ...state,
        isFetchingLocation: true,
      }

    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.data,
        isFetchingLocation: false,
      }

    case FETCH_LOCATION_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingLocation: false,
      }

    case FETCH_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetchingLocations: true,
      }

    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.data,
        isFetchingLocations: false,
      }

    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingLocations: false,
      }

    case FETCH_LOCATIONS_COUNT_REQUEST:
      return {
        ...state,
        isFetchingLocationsCount: true,
      }

    case FETCH_LOCATIONS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.count,
        isFetchingLocationsCount: false,
      }

    case FETCH_LOCATIONS_COUNT_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingLocationsCount: false,
      }

    case FETCH_USER_EQUIPMENTS_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetchingLocations: true,
      }

    case FETCH_USER_EQUIPMENTS_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.data,
        isFetchingLocations: false,
      }

    case FETCH_USER_EQUIPMENTS_LOCATIONS_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingLocations: false,
      }

    case FETCH_PHYSICAL_LOCATION_REQUEST:
      return {
        ...state,
        isFetchingPhysicalLocation: true,
      }

    case FETCH_PHYSICAL_LOCATION_SUCCESS:
      return {
        ...state,
        physical: action.data,
        isFetchingPhysicalLocation: false,
      }

    case FETCH_PHYSICAL_LOCATION_FAILURE:
      return {
        ...state,
        error: action.code,
        isFetchingPhysicalLocation: false,
      }

    case FETCH_LEGAL_LOCATION_REQUEST:
      return {
        ...state,
        isFetchingLegalLocation: true,
      }

    case FETCH_LEGAL_LOCATION_SUCCESS:
      return {
        ...state,
        legal: action.data,
        isFetchingLegalLocation: false,
      }

    case FETCH_LEGAL_LOCATION_FAILURE:
      return {
        ...state,
        errors: { ...action.code },
        isFetchingLegalLocation: false,
      }

    case UPDATE_LOCATION_IN_LOCATIONS:
      return {
        ...state,
        locations: [
          ...state.locations.filter(l => action.data.id !== l.id),
          action.data,
        ].sort((a, b) => a.created_at < b.created_at),
      }

    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        deleteLocation: {
          ...state.deleteLocation,
          isDeletingLocation: true,
          error: null,
        },
      }

    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        deleteLocation: {
          ...state.deleteLocation,
          isDeletingLocation: false,
          error: null,
        },
      }

    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        deleteLocation: {
          ...state.deleteLocation,
          error: action.code,
          isDeletingLocation: false,
        },
      }

    default:
      return state
  }
}

export default locationsReducer
