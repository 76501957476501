import React, { Component, Fragment } from 'react'
import i18next from '../../../../../i18n/i18n'
import classNames from 'classnames'
import isEmpty from 'is-empty'
import PropTypes from 'prop-types'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// modules
import CostInput from '../../../../../widgets/CostInput/index'
import DateInput from '../../../../../widgets/DateInput/index'
import Loader from '../../../../Loader'
import { organizationLocationStringify } from '../../../../../helpers/parserHelper'
import { getUserFullName } from '../../../../../helpers/userHelper'
import FormDialog from '../../../../../widgets/FormDialog'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  section: {
    marginBottom: '40px',
  },
})

class DialogAddKPComponent extends Component {
  state = {
    dateActualTo: null,
    organizationId: null,
    organizationIdEmpty: null,
    responsibleId: null,
    responsibleIdEmpty: false,
    amount: null,
    amountEmpty: false,
    performerComment: null,
  }

  componentDidMount() {
    const {
      props: { fetchManagerOrHigherOrganizations },
    } = this
    fetchManagerOrHigherOrganizations('[service_organization=true]')
  }

  componentDidUpdate(prevState) {
    const {
      props: { isOffer, offerSuccessfully, error, handleClose },
    } = this
    if (
      prevState.offerSuccessfully !== offerSuccessfully &&
      !isOffer &&
      isEmpty(error)
    ) {
      this.setState({
        dateActualTo: null,
        organizationId: null,
        organizationIdEmpty: null,
        responsibleId: null,
        responsibleIdEmpty: false,
        amount: null,
        amountEmpty: false,
        performerComment: null,
      })
      handleClose()
    }
  }

  handleChangeDate = selectedDay => {
    const isDateValid = selectedDay !== undefined && selectedDay > new Date()
    const dateActualToEmpty = isDateValid
    this.setState({
      dateActualTo: selectedDay,
      dateActualToEmpty,
    })
  }

  handleChangeCost = (name, value) => {
    if (name === 'amount' && value > 0) {
      this.setState({
        amountEmpty: false,
      })
    }
    if (name === 'amount' && !(value > 0)) {
      this.setState({
        amountEmpty: true,
      })
    }
    this.setState({
      [name]: value,
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.name]:
        event.target.value === '' ? null : event.target.value,
      [`${event.target.name}Empty`]: false,
    })
  }

  handleChangeOrganization = event => {
    const {
      props: { fetchOrganizationStaff, fetchOrganizationLocations },
    } = this
    fetchOrganizationStaff(event.target.value)
    fetchOrganizationLocations(event.target.value)
    this.setState({
      organizationId: event.target.value === '' ? null : event.target.value,
      organizationIdEmpty: false,
    })
  }

  handleSubmit = () => {
    const {
      validateForm,
      props: { offerInventoryProject, projectId },
      state: {
        organizationId,
        responsibleId,
        amount,
        dateActualTo,
        performerComment,
      },
    } = this
    if (
      validateForm([
        'organizationId',
        'responsibleId',
        'amount',
        'dateActualTo',
      ])
    ) {
      offerInventoryProject(organizationId, projectId, {
        responsible_id: responsibleId,
        amount: amount.toFixed(2),
        date_actual_to: dateActualTo,
        performer_comment: performerComment,
      })
    } else {
      this.setState({
        dataValid: false,
      })
    }
  }

  validateForm = array => {
    const propsToUpdate = {}
    array.forEach(_item => {
      if (this.state[_item] === null) {
        propsToUpdate[`${_item}Empty`] = true
      }
    })
    const isDateValid =
      this.state.dateActualTo === null ||
      (this.state.dateActualTo && this.state.dateActualTo > new Date())
    propsToUpdate.dateActualToEmpty =
      this.state.dateActualToEmpty !== undefined && isDateValid
    this.setState(propsToUpdate)
    return !array.some(_item => this.state[_item] === null) && isDateValid
  }

  render() {
    const {
      handleChangeDate,
      handleChangeCost,
      handleChange,
      handleSubmit,
      handleChangeOrganization,
      state: {
        dateActualTo,
        amount,
        amountEmpty,
        performerComment,
        organizationId,
        organizationIdEmpty,
        dateActualToEmpty,
        responsibleId,
        responsibleIdEmpty,
      },
      props: {
        classes,
        open,
        projectType,
        handleClose,
        organizations,
        isFetchingOrganizations,
        staff,
        isFetchingStaff,
        locations,
        isFetchingLocations,
        clientOrganizationId,
      },
    } = this

    let position =
      responsibleId === null
        ? ''
        : staff.find(_person => _person.id === responsibleId).position
    position =
      position === 'owner'
        ? i18next.t('organizations_web_atoir:owner')
        : position
    const bindStaff = staff.filter(_person => _person.User !== null)

    return (
      <Fragment>
        <FormDialog
          open={open}
          isLoading={false}
          title={i18next.t('inventory_web_atoir:commercialOffer')}
          onClose={handleClose}
          onExit={handleClose}
          onSubmit={handleSubmit}
          submitButtonTitle={i18next.t('inventory_web_atoir:sendOffer')}
        >
          <section className={classes.section}>
            <DialogContentText>
              {i18next.t('inventory_web_atoir:typeWorks')}:{' '}
              {projectType.toUpperCase()}
            </DialogContentText>
          </section>
          <section className={classes.section}>
            <Typography variant="h6" gutterBottom>
              {i18next.t('inventory_web_atoir:organizationСompany')}
            </Typography>
            <TextField
              error={organizationIdEmpty}
              fullWidth
              select
              name="organizationId"
              label={i18next.t('organizations_web_atoir:organizationName')}
              className={classNames(classes.margin, classes.textField)}
              value={organizationId === null ? '' : organizationId}
              onChange={handleChangeOrganization}
              helperText={
                organizationIdEmpty
                  ? i18next.t('organizations_web_atoir:enterOrganizationName')
                  : ''
              }
            >
              {!isFetchingOrganizations ? (
                organizations
                  .filter(
                    o =>
                      o.id !== clientOrganizationId && o.service_organization,
                  )
                  .map(_organization => (
                    <MenuItem key={_organization.id} value={_organization.id}>
                      {_organization.short_name}
                    </MenuItem>
                  ))
              ) : (
                <Loader />
              )}
            </TextField>
          </section>
          {organizationId !== null && (
            <Fragment>
              <section className={classes.section}>
                <DialogContentText>
                  <Typography variant="h6" gutterBottom>
                    {i18next.t('organizations_web_atoir:address')}
                  </Typography>
                  <List component="nav">
                    {isEmpty(locations) && isFetchingLocations ? (
                      <Loader />
                    ) : (
                      locations.map(_location => (
                        <ListItem>
                          {organizationLocationStringify(_location)}
                        </ListItem>
                      ))
                    )}
                  </List>
                </DialogContentText>
              </section>
              <section className={classes.section}>
                <Typography variant="h6" gutterBottom>
                  {i18next.t('inventory_web_atoir:contactPersonPerformer')}
                </Typography>
                <Grid container spacing={2} alignItems="baseline">
                  <Grid item xs={6}>
                    <TextField
                      name="responsibleId"
                      required
                      error={responsibleIdEmpty}
                      fullWidth
                      select
                      label={i18next.t(
                        'organizations_web_atoir:contactFullName',
                      )}
                      className={classNames(classes.margin, classes.textField)}
                      value={responsibleId === null ? '' : responsibleId}
                      onChange={handleChange}
                      helperText={
                        responsibleIdEmpty
                          ? i18next.t('inventory_web_atoir:enterContactPerson')
                          : ''
                      }
                    >
                      {!isFetchingStaff && !isEmpty(bindStaff) ? (
                        bindStaff.map(_person => (
                          <MenuItem key={_person.id} value={_person.id}>
                            {getUserFullName(_person)}
                          </MenuItem>
                        ))
                      ) : (
                        <Loader />
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    {responsibleId !== null &&
                      `${i18next.t('employees_web_atoir:position')}: ${
                        position === 'owner'
                          ? i18next.t('employees_web_atoir:ownerRole')
                          : position
                      }`}
                  </Grid>
                </Grid>
              </section>
            </Fragment>
          )}
          <Grid container spacing={2} className={classes.section}>
            <Grid item xs={6}>
              <CostInput
                requiredValue
                name="amount"
                isCostValid={!amountEmpty}
                handleChange={handleChangeCost}
                cost={amount}
              />
              <FormHelperText>
                {i18next.t('inventory_web_atoir:costInputTextHelper')}
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <DateInput
                startFrom={
                  new Date(new Date().setMonth(new Date().getMonth() + 1))
                }
                disabledDays={{
                  before: new Date(
                    new Date().setMonth(new Date().getMonth() + 1),
                  ),
                }}
                value={dateActualTo}
                name="dateActualTo"
                handleChange={handleChangeDate}
                requiredValue={false}
                dateValid={!(dateActualToEmpty === false)}
                label={i18next.t('shared_web_atoir:date')}
              />
              <FormHelperText>
                {i18next.t('inventory_web_atoir:maxDateOffer')}
              </FormHelperText>
            </Grid>
          </Grid>
          <section className={classes.section}>
            <TextField
              fullWidth
              name="performerComment"
              label={i18next.t('inventory_web_atoir:comment')}
              multiline
              rows="4"
              className={classes.textField}
              margin="normal"
              value={performerComment === null ? '' : performerComment}
              onChange={handleChange}
            />
          </section>
        </FormDialog>
      </Fragment>
    )
  }
}

DialogAddKPComponent.defaultProps = {}

DialogAddKPComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  staff: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  isFetchingOrganizations: PropTypes.bool.isRequired,
  isFetchingStaff: PropTypes.bool.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  isOffer: PropTypes.bool.isRequired,
  fetchOrganizationStaff: PropTypes.func.isRequired,
  fetchOrganizationLocations: PropTypes.func.isRequired,
  offerInventoryProject: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectType: PropTypes.string.isRequired,
  clientOrganizationId: PropTypes.string.isRequired,
}

export default withStyles(styles)(DialogAddKPComponent)
