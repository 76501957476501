import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EditUserProfileComponent from '../../components/User/EditUserProfileComponent'
import {
  fetchUser,
  uploadUserImage,
  updateUserAvatar,
  updateUser,
  removeUserAvatar,
  clearValidationErrors,
} from '../../actions/userActions'

const mapStateToProps = state => ({
  isFetchingUser: state.user.isFetchingUser,
  isUploadingUserImage: state.user.isUploadingUserImage,
  newAvatar: state.user.newAvatar,
  isUpdatingUserAvatar: state.user.isUpdatingUserAvatar,
  isUpdatingUser: state.user.isUpdatingUser,
  user: state.user.user,
  validationErrors: state.user.validationErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUser,
      uploadUserImage,
      updateUserAvatar,
      updateUser,
      removeUserAvatar,
      clearValidationErrors,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUserProfileComponent),
)
