import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AgreementsTable from './AgreementsTable'
import {
  fetchAgreementsRequest,
  fetchAgreementsCountRequest,
} from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isFetching: state.serviceDeskContracts.agreements.isFetching,
  error: state.serviceDeskContracts.agreements.error,
  agreements: state.serviceDeskContracts.agreements.agreements,
  isFetchingCount: state.serviceDeskContracts.agreementsCount.isFetching,
  errorCount: state.serviceDeskContracts.agreementsCount.error,
  agreementsCount: state.serviceDeskContracts.agreementsCount.count,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAgreementsRequest,
      fetchAgreementsCountRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementsTable),
)
