import React, { Component } from 'react'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
})

class AddEquipmentListComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      content: i18next.t('equipment_web_atoir:addEquipmentListTitle'),
    }
  }

  render() {
    return (
      <div>
        <br />
        <Typography variant="h5" component="h5">
          {i18next.t('equipment_web_atoir:addEquipmentListText')}
        </Typography>
        <Typography>{this.state.content}</Typography>
      </div>
    )
  }
}

export default withStyles(styles)(AddEquipmentListComponent)
