import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getCallsByGeneralSearch,
  clearCallsSearchByNumber,
} from '../../../actions/serviceDesk'
import GlobalSearchComponent from './GlobalSearchComponent'

const mapStateToProps = state => ({
  isFetchingCallsSearch: state.serviceDesk.callsByNumberSearch.isFetching,
  callsBySearch: state.serviceDesk.callsByNumberSearch.calls,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCallsByGeneralSearch,
      clearCallsSearchByNumber,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalSearchComponent),
)
