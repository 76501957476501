import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { omitByEmpty } from '../../../../../helpers/dataHelper'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../../i18n/i18n'

// components
import PrimaryButton from '../../../../../widgets/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../widgets/Buttons/SecondaryButton'
import SimpleDialog from '../../../../Other/SimpleDialog'
import CanInventory from '../InventoryProjectAbility/CanInventory'
import SetRatingDialog from '../ProjectRating/SetRatingDialog'

// components
import Subheading from '../../../../../widgets/Lettering/Subheading'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'

class InventoryProjectHeading extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      openSetDoneDialog: false,
      openErrorDialog: false,
      openSetClosedDialog: false,
      closedRating: 5,
      closedComment: '',
      validation: {},
      openBackInWorkDialog: false,
      backInWorkComment: '',
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        isSettingProjectDone,
        setDoneSuccessfully,
        setDoneErrors,
        isSettingProjectClosed,
        isSettingProjectBackInWork,
        projectId,
        fetchInventoryProjectProfile,
        fetchInventoryProjectProfileRights,
      },
      handleDialogOpen,
      handleDialogClose,
    } = this
    if (
      prevProps.isSettingProjectDone !== isSettingProjectDone &&
      !isSettingProjectDone &&
      setDoneSuccessfully &&
      isEmpty(setDoneErrors)
    ) {
      handleDialogClose('openSetDoneDialog')
      fetchInventoryProjectProfileRights(projectId)
      fetchInventoryProjectProfile(projectId)
    }
    if (
      prevProps.isSettingProjectDone !== isSettingProjectDone &&
      !isSettingProjectDone &&
      !setDoneSuccessfully &&
      !isEmpty(setDoneErrors)
    ) {
      handleDialogClose('openSetDoneDialog')
      handleDialogOpen('openErrorDialog')
    }
    if (
      prevProps.isSettingProjectClosed !== isSettingProjectClosed &&
      !isSettingProjectClosed
    ) {
      handleDialogClose('openSetClosedDialog')
      fetchInventoryProjectProfileRights(projectId)
      fetchInventoryProjectProfile(projectId)
    }
    if (
      prevProps.isSettingProjectBackInWork !== isSettingProjectBackInWork &&
      !isSettingProjectBackInWork
    ) {
      handleDialogClose('openBackInWorkDialog')
      fetchInventoryProjectProfileRights(projectId)
      fetchInventoryProjectProfile(projectId)
    }
  }

  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  goBack = () => {
    this.props.history.goBack()
  }

  handleDialogOpen = field => {
    this.setState({
      [field]: true,
    })
  }

  handleDialogClose = field => {
    this.setState({
      [field]: false,
      closedRating: 5,
      closedComment: '',
      backInWorkComment: '',
    })
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  setProjectDone = () => {
    const {
      props: { projectId, setInventoryProjectDone },
    } = this
    setInventoryProjectDone(projectId)
  }

  setProjectBackInWork = () => {
    const {
      props: { projectId, setInventoryProjectBackInWork },
      state: { backInWorkComment },
      validate,
    } = this
    const validation = validate(backInWorkComment.trim())
    if (!isEmpty(validation)) {
      this.setState({ validation })
      return
    }
    this.setState({ validation: {} })
    const data = {
      client_comment: backInWorkComment.trim(),
    }
    setInventoryProjectBackInWork(projectId, data)
  }

  setProjectClosed = (rating, comment) => {
    const {
      props: { projectId, setInventoryProjectClosed },
    } = this
    const data = {
      rating_toperformer: rating,
      rating_toperformer_comment: comment,
    }
    setInventoryProjectClosed(projectId, omitByEmpty(data))
  }

  validate = backInWorkComment => {
    const validation = {}
    if (backInWorkComment === '') {
      validation.backInWorkComment = i18next.t(
        'validation_web_atoir:commentToBackProjectInWorkRequired',
      )
    }
    return validation
  }

  render = () => {
    const {
      props: {
        match: { path },
        classes,
        projectId,
        status,
        canCancelProject,
        handleCancelDialog,
        isSettingProjectDone,
        setDoneErrors,
        isSettingProjectClosed,
        isSettingProjectBackInWork,
      },
      state: {
        openSetDoneDialog,
        openErrorDialog,
        openSetClosedDialog,
        closedRating,
        closedComment,
        validation,
        openBackInWorkDialog,
        backInWorkComment,
      },
      handleDialogOpen,
      handleDialogClose,
      handleTextChange,
      setProjectDone,
      setProjectClosed,
      setProjectBackInWork,
    } = this

    const errorMessage = !isEmpty(setDoneErrors)
      ? setDoneErrors.inventoryProject[0]
      : ''
    const backInWorkMessage = !isEmpty(validation.backInWorkComment)
      ? validation.backInWorkComment
      : ''
    const buttonGroupSubheading = (
      <Fragment>
        {status === 'inwork' && !path.includes('equipment') && (
          <SecondaryButton
            onClick={() =>
              this.props.history.push(
                `/inventory_projects/${projectId}/equipment`,
              )
            }
          >
            {i18next.t('inventory_web_atoir:showAllEquipment')}
          </SecondaryButton>
        )}
        {status === 'inwork' && (
          <CanInventory I="set_project_to_done" a="Inventory">
            <PrimaryButton
              onClick={() => handleDialogOpen('openSetDoneDialog')}
            >
              {i18next.t('inventory_web_atoir:setProjectStatusDone')}
            </PrimaryButton>
          </CanInventory>
        )}

        {status === 'done' && (
          <Fragment>
            <CanInventory I="back_to_inwork" a="Inventory">
              <SecondaryButton
                onClick={() => handleDialogOpen('openBackInWorkDialog')}
              >
                {i18next.t('inventory_web_atoir:setProjectBackInWork')}
              </SecondaryButton>
            </CanInventory>
            <CanInventory I="close_project" a="Inventory">
              <PrimaryButton
                onClick={() => handleDialogOpen('openSetClosedDialog')}
              >
                {i18next.t('inventory_web_atoir:acceptInventoryProject')}
              </PrimaryButton>
            </CanInventory>
          </Fragment>
        )}

        {canCancelProject && status === 'created' && (
          <SecondaryButton onClick={handleCancelDialog}>
            {`${i18next.t('inventory_web_atoir:cancelProject')}`}
          </SecondaryButton>
        )}
      </Fragment>
    )

    return (
      <Grid container spacing={1} justify="flex-start">
        <Subheading
          divider
          classes={classes}
          title={i18next.t('inventory_web_atoir:baseInfoCaption')}
        >
          {buttonGroupSubheading}
        </Subheading>
        {
          <SimpleDialog
            isOpen={openSetDoneDialog}
            isLoading={isSettingProjectDone}
            handleClose={() => handleDialogClose('openSetDoneDialog')}
            handlePositive={() => setProjectDone()}
            handleNegative={() => handleDialogClose('openSetDoneDialog')}
            positiveText={i18next.t('inventory_web_atoir:confirm')}
            negativeText={i18next.t('shared_web_atoir:cancel')}
            title={`${i18next.t('inventory_web_atoir:projectCompletion')}`}
            content={`${i18next.t(
              'inventory_web_atoir:areYouSureAboutSettingProjectDone',
            )}?`}
          />
        }
        {
          <SimpleDialog
            isOpen={openErrorDialog}
            handleClose={() => handleDialogClose('openErrorDialog')}
            handlePositive={() => handleDialogClose('openErrorDialog')}
            positiveText={i18next.t('shared_web_atoir:close')}
            title={`${i18next.t('inventory_web_atoir:failedToChangeStatus')}`}
            content={errorMessage}
            negativeHidden
          />
        }
        {
          <SetRatingDialog
            classes={classes}
            isOpen={openSetClosedDialog}
            isLoading={isSettingProjectClosed}
            handleClose={() => handleDialogClose('openSetClosedDialog')}
            positiveAction={setProjectClosed}
            negativeAction={() => handleDialogClose('openSetClosedDialog')}
            rating={closedRating}
            comment={closedComment}
          />
        }
        {
          <SimpleDialog
            isOpen={openBackInWorkDialog}
            isLoading={isSettingProjectBackInWork}
            handleClose={() => handleDialogClose('openBackInWorkDialog')}
            handlePositive={() => setProjectBackInWork()}
            handleNegative={() => handleDialogClose('openBackInWorkDialog')}
            positiveText={i18next.t('shared_web_atoir:send')}
            negativeText={i18next.t('shared_web_atoir:cancel')}
            title={`${i18next.t(
              'inventory_web_atoir:specifyBackInWorkReason',
            )}`}
            content={
              <TextField
                id="backInWorkComment"
                required
                multiline
                fullWidth
                label={i18next.t('inventory_web_atoir:yourComment')}
                placeholder={i18next.t('inventory_web_atoir:leaveYourComment')}
                className={classes.textField}
                margin="normal"
                onChange={handleTextChange('backInWorkComment')}
                value={backInWorkComment}
                error={!isEmpty(backInWorkMessage)}
                helperText={backInWorkMessage}
              />
            }
          />
        }
      </Grid>
    )
  }
}

const styles = theme => ({
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
  linkText: {
    color: theme.palette.textSecondary.main,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  wrapSubheading: {
    paddingLeft: '16px',
    padding: theme.spacing(1),
  },
})

InventoryProjectHeading.propTypes = {
  projectId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  canCancelProject: PropTypes.bool.isRequired,
  handleCancelDialog: PropTypes.func.isRequired,
  setInventoryProjectDone: PropTypes.func.isRequired,
  isSettingProjectDone: PropTypes.bool.isRequired,
  setDoneSuccessfully: PropTypes.bool.isRequired,
  setDoneErrors: PropTypes.object.isRequired,
  setInventoryProjectClosed: PropTypes.func.isRequired,
  isSettingProjectClosed: PropTypes.bool.isRequired,
  setInventoryProjectBackInWork: PropTypes.func.isRequired,
  isSettingProjectBackInWork: PropTypes.bool.isRequired,
  fetchInventoryProjectProfileRights: PropTypes.func.isRequired,
  fetchInventoryProjectProfile: PropTypes.func.isRequired,
}

export default withStyles(styles)(InventoryProjectHeading)
