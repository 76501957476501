import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Edit from '@material-ui/icons/Edit'

const styles = theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  blockTitle: {
    color: theme.palette.blueSecondary.main,
  },
  blockTitleNoMargin: {
    color: theme.palette.blueSecondary.main,
  },
  icon: {
    color: theme.palette.text.main,
  },
})

const BlockTitle = props => (
  <Grid
    container
    direction="row"
    spacing={2}
    alignItems="center"
    className={props.classes.container}
  >
    <Grid item>
      <Typography
        variant="subtitle1"
        className={
          props.noTopMargin
            ? props.classes.blockTitleNoMargin
            : props.classes.blockTitle
        }
      >
        {props.text}
      </Typography>
    </Grid>
    {!!props.onEdit && (
      <Grid item>
        <IconButton onClick={props.onEdit} data-cy="BlockTitleIconButton">
          <Edit className={props.classes.icon} />
        </IconButton>
      </Grid>
    )}
  </Grid>
)

BlockTitle.defaultProps = {
  onEdit: null,
  noTopMargin: false,
}

BlockTitle.propTypes = {
  text: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  noTopMargin: PropTypes.bool,
}

export default withStyles(styles)(BlockTitle)
