import React, { Component, Fragment } from 'react'

import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import Loader from '../../../Loader'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import { popLastSections } from '../../../../helpers/dataHelper'
import i18next from '../../../../i18n/i18n'

class ServiceDeskHistoryWrapper extends Component {
  state = {
    orderBy: 'ByDate',
    order: 'desc',
    limit: 10,
    page: 0,
    offset: 0,
  }

  orderBySetter = By => {
    let orderNew = 'desc'
    if (this.state.orderBy === By && this.state.order === 'desc') {
      orderNew = 'asc'
    }
    this.setState({
      orderBy: By,
      order: orderNew,
    })
  }

  setOptions = (option, value) => {
    if (option === 'page') {
      this.setState(
        state => ({
          [option]: value,
          offset: value * state.limit,
        }),
        () => this.updateData(),
      )
    } else {
      this.setState(
        {
          [option]: value,
        },
        () => this.updateData(),
      )
    }
  }

  componentDidMount = () => {
    const {
      props: {
        fetchCallPermissions,
        match: {
          params: { idRequest },
        },
      },
    } = this
    fetchCallPermissions(idRequest)
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.isFetchingPermissions !== prevProps.isFetchingPermissions &&
      this.props.isFetchingPermissions === false
    ) {
      if (this.props.permissionsError) {
        this.props.history.push('/404')
      } else {
        this.props.fetchOrder({ id: this.props.match.params.id, params: '' })
        this.updateData()
      }
    }
  }

  updateData = () => {
    this.props.fetchOrdersLogCountRequest({ id: this.props.match.params.id })
    this.props.fetchOrdersLogRequest({
      id: this.props.match.params.id,
      limit: this.state.limit,
      offset: this.state.offset,
    })
  }

  render() {
    const {
      isFetchingLogs,
      isFetchingLogCount,
      isFetchingOrder,
      match,
      orderObject,
    } = this.props
    return (
      <Fragment>
        {isFetchingLogs ||
        isFetchingLogCount ||
        isFetchingOrder ||
        isEmpty(orderObject) ? (
          <Loader />
        ) : (
          <Fragment>
            <BreadCrumb to={popLastSections(match.url, 3)}>
              {i18next.t('service_desk_web_atoir:titleCallCard', {
                date: new Date(
                  Date.parse(orderObject.ServiceCall.createdAt),
                ).toLocaleDateString('ru'),
                internalNum: orderObject.ServiceCall.internal_num,
              })}
            </BreadCrumb>
            <BreadCrumb to={popLastSections(match.url, 1)}>
              {i18next.t('service_desk_web_atoir:profileOrderTitle', {
                data: new Date(
                  Date.parse(orderObject.createdAt),
                ).toLocaleDateString('ru'),
              })}
            </BreadCrumb>
            <BreadCrumb to={match.url}>
              {i18next.t('service_desk_web_atoir:historyOfChange')}
            </BreadCrumb>
            {this.props.render({
              historyData: this.props.orders_log,
              historyDataCount: this.props.orders_log_count,
              orderBy: 'ByDate',
              orderBySetter: this.orderBySetter,
              order: 'desc',
              page: this.state.page,
              offset: this.state.offset,
              setOptions: this.setOptions,
              limit: this.state.limit,
              ...this.props,
            })}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

ServiceDeskHistoryWrapper.propTypes = {
  orders_log: PropTypes.array.isRequired,
  orders_log_count: PropTypes.number.isRequired,
  isFetchingLogs: PropTypes.bool.isRequired,
  isFetchingLogCount: PropTypes.bool.isRequired,
  isFetchingPermissions: PropTypes.bool.isRequired,
  permissionsError: PropTypes.object.isRequired,
  fetchCallPermissions: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrdersLogRequest: PropTypes.func.isRequired,
  fetchOrdersLogCountRequest: PropTypes.func.isRequired,
}

export default ServiceDeskHistoryWrapper
