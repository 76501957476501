import React, { Component, Fragment } from 'react'
import Sticky from 'react-stickynode'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton/IconButton'
import TuneIcon from '@material-ui/icons/Tune'
import Badge from '@material-ui/core/Badge'
import Collapse from '@material-ui/core/Collapse'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import Delete from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import { lighten } from '@material-ui/core/styles/colorManipulator'

import Typography from '@material-ui/core/Typography'
// components
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'

import Loader from '../../../Loader'

import EquipmentsList from '../../Contracts/Widgets/EquipmentsList'
// helpers
import {
  inventoryLocationStringifyDefault,
  organizationLocationStringify,
} from '../../../../helpers/parserHelper'

import FormDialog from '../../../../widgets/FormDialog'
// i18n
import i18next from '../../../../i18n/i18n'
import {
  canDistributeContract,
  canEditContract,
} from '../../../../helpers/ContractPermissionsResolver'
import { URL } from '../../../../middlewares/api'
import SelectLimitedFromControl from '../../../../widgets/SelectLimitedFormControl'

const prepareDepartmentList = department => {
  const out = department.map(item => {
    const cur = { id: item.id, title: item.name }
    return cur
  })
  return out
}

const prepareAddressList = address => {
  const out = address.map(item => {
    const curAddr = {
      id: item.id,
      title: inventoryLocationStringifyDefault(item),
    }
    return curAddr
  })
  return out
}

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  gridHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  gridCollapsedFilters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  divCollapsedDivider: {
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: `${lighten(theme.palette.secondary.light, 0.85)}`,
  },
  paper: {
    backgroundColor: '#fafafa',
  },
  badgeColorRoot: {
    color: 'black',
  },
  badgeColorPrimary: {
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
  },
  textField: {
    width: '100%',
  },
  hideClass: {
    display: 'none',
  },
  divHeader: {
    backgroundColor: 'white',
    borderBottom: theme.typography.pxToRem(1),
    borderBottomStyle: 'solid',
    borderBottomColor: lighten(theme.palette.secondary.light, 0.85),
  },
  card: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  middleText: {
    marginBottom: 12,
    fontSize: 12,
  },
  topText: {
    marginBottom: 8,
    fontSize: 12,
  },
})

const extendData = data => {
  const resData = []
  if (data === null || data.length === 0) return []
  let currAddr = data[0].address
  resData.push({ typeRow: 'addressRow', currAddr, id: currAddr })
  data.forEach(item => {
    if (item.address !== currAddr) {
      currAddr = item.address
      resData.push({ typeRow: 'addressRow', currAddr, id: currAddr })
    }
    resData.push(item)
  })
  return resData
}

class ContractEquipmentTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      checked: [],
      filters: {},
      filtersCount: 0,
      offset: 0,
      page: 0,
      commonFilterLimit: 30,
      addressFilterPage: 0,
      confirmDialogOpen: false,
      locationFilterDataIsFetching: false,
      addressFilterData: [],
      departmentFilterPage: 0,
      departmentFilterDataIsFetching: false,
      departmentFilterData: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateDiff = {}
    // department loading
    if (
      prevState.departmentFilterDataIsFetching !==
        nextProps.departmentFilterDataIsFetching &&
      nextProps.departmentFilterDataIsFetching
    ) {
      stateDiff.departmentFilterDataIsFetching =
        nextProps.departmentFilterDataIsFetching
    }
    if (
      prevState.departmentFilterDataIsFetching !==
        nextProps.departmentFilterDataIsFetching &&
      !nextProps.departmentFilterDataIsFetching
    ) {
      const {
        departmentFilterData,
        commonFilterLimit,
        departmentFilterPage,
      } = prevState
      stateDiff.departmentFilterDataIsFetching =
        nextProps.departmentFilterDataIsFetching
      stateDiff.departmentFilterData = departmentFilterData.concat(
        prepareDepartmentList(nextProps.departmentFilterData),
      )
      stateDiff.departmentFilterPage = departmentFilterPage + 1
      if (nextProps.departmentFilterData.length < commonFilterLimit) {
        stateDiff.departmentFilterDataIsEnd = true
      }
    }
    // location filter loading
    if (
      prevState.locationFilterDataIsFetching !==
        nextProps.locationFilterDataIsFetching &&
      nextProps.locationFilterDataIsFetching
    ) {
      stateDiff.locationFilterDataIsFetching =
        nextProps.locationFilterDataIsFetching
    }
    if (
      prevState.locationFilterDataIsFetching !==
        nextProps.locationFilterDataIsFetching &&
      !nextProps.locationFilterDataIsFetching
    ) {
      const {
        addressFilterData,
        commonFilterLimit,
        addressFilterPage,
      } = prevState
      stateDiff.locationFilterDataIsFetching =
        nextProps.locationFilterDataIsFetching
      stateDiff.addressFilterData = addressFilterData.concat(
        prepareAddressList(nextProps.locationFilterData),
      )
      stateDiff.addressFilterPage = addressFilterPage + 1
      if (nextProps.locationFilterData.length < commonFilterLimit) {
        stateDiff.addressFilterDataIsEnd = true
      }
    }

    if (!_.isEmpty(stateDiff)) {
      return stateDiff
    }
    return null
  }

  componentDidMount() {
    const {
      props: { customRef },
      loadEquipmentCount,
    } = this
    customRef(this)
    loadEquipmentCount()
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        availableEquipmentIsFetching,
        availableEquipmentFetchingError,
        isDeletingEquipment,
        equipmentCountIsFetching,
        equipmentCountFetchingError,
        deleteError,
        equipmentAddIsFetching,
        equipmentAddError,
        hideDialogLoader,
        rowsPerPage: limit,
      },
      prepareData,
      loadEquipmentData,
      loadEquipmentCount,
    } = this
    // contract data loaded
    // availableEquipmentCount loaded
    if (
      prevProps.equipmentCountIsFetching !== equipmentCountIsFetching &&
      !equipmentCountIsFetching &&
      !equipmentCountFetchingError
    ) {
      loadEquipmentData()
    }
    // availableEquipment loaded
    if (
      prevProps.availableEquipmentIsFetching !== availableEquipmentIsFetching &&
      !availableEquipmentIsFetching &&
      !availableEquipmentFetchingError
    ) {
      prepareData()
      hideDialogLoader()
    }
    // equipments added to contract
    if (
      prevProps.isDeletingEquipment !== isDeletingEquipment &&
      !isDeletingEquipment &&
      !deleteError
    ) {
      loadEquipmentCount()
    }
    // failed to add equipments
    if (
      prevProps.equipmentAddIsFetching !== equipmentAddIsFetching &&
      !equipmentAddIsFetching &&
      equipmentAddError
    ) {
      hideDialogLoader()
    }
    // rows per page changed
    if (prevProps.rowsPerPage !== limit) {
      loadEquipmentCount()
    }
  }

  nextFilterOffset = filter => () => {
    const {
      props: { departmentFilterDataIsFetching, locationFilterDataIsFetching },
      state: { addressFilterDataIsEnd, departmentFilterDataIsEnd },
      loadFilterLocationDataNext,
      loadFilterDepartmentDataNext,
    } = this
    if (
      filter === 'address' &&
      !addressFilterDataIsEnd &&
      !locationFilterDataIsFetching
    ) {
      loadFilterLocationDataNext()
    }
    if (
      filter === 'department' &&
      !departmentFilterDataIsEnd &&
      !departmentFilterDataIsFetching
    ) {
      loadFilterDepartmentDataNext()
    }
  }

  loadEquipmentCount = () => {
    const {
      props: { fetchCount, contract },
      state: { filters },
    } = this
    fetchCount({
      id: contract.id,
      ...filters,
    })
  }

  loadEquipmentData = () => {
    const {
      props: { fetchContractEquipmentsRequest, contract, rowsPerPage: limit },
      state: { offset, filters },
    } = this
    fetchContractEquipmentsRequest({
      id: contract.id,
      limit,
      offset,
      ...filters,
    })
  }

  handleChange = () => {
    this.setState(state => ({ checkedSwitch: !state.checkedSwitch }))
  }

  prepareData = () => {
    const {
      props: { availableEquipment, contract },
    } = this

    let newData = availableEquipment.map(row => {
      const {
        name,
        model,
        OrganizationDepartment,
        OrganizationLocation,
        external_id: externalId,
        vendor,
        id,
        ServiceDeskContractEquipments,
      } = row
      const outRow = {
        id,
        name,
        model,
        externalId,
        vendor,
      }
      outRow.postIndex = OrganizationLocation.post_index
      outRow.address = inventoryLocationStringifyDefault(OrganizationLocation)
      if (OrganizationDepartment)
        outRow.department = OrganizationDepartment.name
      if (
        ServiceDeskContractEquipments.filter(
          s => s.service_desk_contract_id === contract.id,
        )[0]
      ) {
        outRow.engineer = ServiceDeskContractEquipments.filter(
          s => s.service_desk_contract_id === contract.id,
        )[0].engineer
      }
      return outRow
    })
    newData = extendData(newData)
    this.setState({ data: newData })
  }

  checkedChange = id => {
    const {
      state: { checked },
    } = this
    const pos = checked.indexOf(id)
    if (pos !== -1) {
      checked.splice(pos, 1)
    } else {
      checked.push(id)
    }
    this.setState(checked)
  }

  handleSelectAllClick = event => {
    const { availableEquipment } = this.props
    if (event.target.checked) {
      this.setState(() => ({ checked: availableEquipment.map(n => n.id) }))
      return
    }
    this.setState({ checked: [] })
  }

  filtersHandler = field => event => {
    const { value } = event.target
    if (value !== '') {
      this.setState({
        [`${field}FilterValue`]: value,
      })
    } else {
      this.setState({ [`${field}FilterValue`]: undefined })
    }
  }

  filtersLimitedHandler = field => value => {
    this.setState({ [`${field}FilterValue`]: value })
  }

  applyFilters = () => {
    const {
      departmentFilterValue,
      addressFilterValue,
      nameFilterValue,
      vendorFilterValue,
      modelFilterValue,
      extIdFilterValue,
    } = this.state

    const filters = {}
    if (departmentFilterValue) filters.department = departmentFilterValue
    if (addressFilterValue) filters.location = addressFilterValue
    if (nameFilterValue) filters.name = nameFilterValue
    if (vendorFilterValue) filters.vendor = vendorFilterValue
    if (modelFilterValue) filters.model = modelFilterValue
    if (extIdFilterValue) filters.externalId = extIdFilterValue

    const filtersCount = Object.keys(filters).length
    this.setState({ filtersCount, filters }, () => {
      this.loadEquipmentCount()
    })
  }

  paginationChange = field => (event, page) => {
    const {
      props: { rowsPerPage: limit, setContractsEquipmentRowsPerPage },
      loadEquipmentData,
    } = this
    const newData = {}
    if (field === 'page') {
      newData.offset = page * limit
      newData.page = page
      this.setState(
        {
          ...newData,
          checked: [],
        },
        () => {
          loadEquipmentData()
        },
      )
    }
    if (field === 'rowsPerPage') {
      setContractsEquipmentRowsPerPage(event.target.value)
    }
  }

  deleteFromContract = () => {
    const {
      state: { checked },
      props: {
        match: { params },
        deleteContractEquipmentsRequest,
        showDialogLoader,
      },
    } = this
    showDialogLoader()
    deleteContractEquipmentsRequest({
      id: params.contractId,
      equipmentsIds: checked,
    })
    this.setState({ checked: [] })
  }

  toggle = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  hasEquipment = () =>
    this.props.availableEquipment && this.props.availableEquipment.length > 0

  render() {
    const {
      props: {
        classes,
        availableEquipment,
        equipmentCount,
        availableEquipmentIsFetching,
        match: { url },
        history,
        contract,
        permissions,
        rowsPerPage: limit,
      },
      state: {
        data,
        checked,
        checkedSwitch,
        filtersCount,
        page,
        departmentFilterValue,
        addressFilterValue,
        confirmDialogOpen,
      },
      filtersHandler,
      checkedChange,
      handleSelectAllClick,
      applyFilters,
      paginationChange,
      filtersLimitedHandler,
      deleteFromContract,
      toggle,
    } = this

    return (
      availableEquipment && (
        <Fragment>
          <div>
            <FormDialog
              onClose={toggle('confirmDialogOpen', false)}
              closeIconHidden
              secondaryButtonsArray={[
                {
                  secondaryText: i18next.t('shared_web_atoir:cancel'),
                  handleSecondaryAction: toggle('confirmDialogOpen', false),
                },
              ]}
              onSubmit={() => {
                deleteFromContract()
                this.toggle('confirmDialogOpen', false)()
              }}
              submitButtonTitle={i18next.t('shared_web_atoir:delete')}
              open={confirmDialogOpen}
              contentText={i18next.t(
                'service_desk_web_atoir:deleteEquipmentDialogContentRationale',
              )}
            />
            <Sticky
              enabled
              top={0}
              innerZ="1"
              bottomBoundary="#equipment-table-body"
            >
              <div className={classes.divHeader}>
                <Grid container className={classes.gridHeader}>
                  <Grid item xs={12} sm={5}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {canEditContract(permissions, contract.status) && (
                        <Checkbox
                          indeterminate={
                            checked.length > 0 &&
                            checked.length < availableEquipment.length
                          }
                          checked={checked.length === availableEquipment.length}
                          onChange={handleSelectAllClick}
                        />
                      )}
                      <Typography variant="subheading">
                        {checked.length > 0 &&
                        canEditContract(permissions, contract.status)
                          ? `${checked.length} ${i18next.t(
                              'service_desk_web_atoir:selectedCaption',
                            )}`
                          : i18next.t('service_desk_web_atoir:equipment')}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid container xs={12} sm={7} justify="flex-end">
                    {canEditContract(permissions, contract.status) &&
                      (checked.length === 0 ? (
                        <OutlinedButton
                          onClick={() => history.push(`${url}/add_equipment`)}
                        >
                          <AddIcon />
                          {i18next.t(
                            'service_desk_web_atoir:toAddEquipmentToContract',
                          )}
                        </OutlinedButton>
                      ) : (
                        <OutlinedButton
                          onClick={toggle('confirmDialogOpen', true)}
                        >
                          <Delete />
                          {i18next.t(
                            'service_desk_web_atoir:toDeleteEquipmentToContract',
                          )}
                        </OutlinedButton>
                      ))}
                    {canDistributeContract(permissions, contract.status) && (
                      <OutlinedButton
                        onClick={() =>
                          history.push(`${url}/distribute_equipment`)
                        }
                      >
                        {i18next.t(
                          'service_desk_web_atoir:distributeEquipment',
                        )}
                      </OutlinedButton>
                    )}
                    <IconButton onClick={this.handleChange}>
                      <Badge
                        classes={{
                          root: classes.badgeColorRoot,
                          colorPrimary: classNames(classes.badgeColorPrimary, {
                            [classes.hideClass]: filtersCount === 0,
                          }),
                        }}
                        color="primary"
                        badgeContent={filtersCount}
                      >
                        <TuneIcon />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>
                <Collapse in={checkedSwitch}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.divCollapsedDivider} />
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      className={classes.gridCollapsedFilters}
                    >
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="name"
                          onChange={filtersHandler('name')}
                          className={classes.textField}
                          label={i18next.t(
                            'service_desk_web_atoir:captionName',
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="vendor"
                          onChange={filtersHandler('vendor')}
                          className={classes.textField}
                          label={i18next.t(
                            'service_desk_web_atoir:captionVendor',
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="model"
                          onChange={filtersHandler('model')}
                          className={classes.textField}
                          label={i18next.t(
                            'service_desk_web_atoir:captionModel',
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="extId"
                          onChange={filtersHandler('extId')}
                          className={classes.textField}
                          label={i18next.t(
                            'service_desk_web_atoir:captionExtId',
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <SelectLimitedFromControl
                          firstElement={{ id: null, title: '' }}
                          FormControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: i18next.t(
                              'service_desk_web_atoir:captionDepartment',
                            ),
                          }}
                          label={i18next.t(
                            'service_desk_web_atoir:captionDepartment',
                          )}
                          onChange={filtersLimitedHandler('department')}
                          sourceUrl={`${URL}/service_desk_contracts/${contract.id}/filters/departments`}
                          requestResultTransformerToArray={r => r.departments}
                          value={departmentFilterValue}
                          itemTransformer={u => ({ id: u.id, title: u.name })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <SelectLimitedFromControl
                          firstElement={{ id: null, title: '' }}
                          FormControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: i18next.t(
                              'service_desk_web_atoir:captionAddress',
                            ),
                          }}
                          label={i18next.t(
                            'service_desk_web_atoir:captionAddress',
                          )}
                          onChange={filtersLimitedHandler('address')}
                          sourceUrl={`${URL}/service_desk_contracts/${contract.id}/filters/locations`}
                          requestResultTransformerToArray={r => r.locations}
                          value={addressFilterValue}
                          itemTransformer={u => ({
                            id: u.id,
                            title: organizationLocationStringify(u),
                          })}
                        />
                      </Grid>
                      <Grid container justify="flex-end" xs={12}>
                        <OutlinedButton onClick={applyFilters}>
                          {i18next.t('service_desk_web_atoir:battonApply')}
                        </OutlinedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={equipmentCount}
                  rowsPerPage={limit}
                  page={page}
                  labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
                  labelDisplayedRows={({
                    from: fromPage,
                    to: toPage,
                    count: countPage,
                  }) =>
                    i18next.t('shared_web_atoir:labelDisplayedRows', {
                      fromPage,
                      toPage,
                      countPage,
                    })
                  }
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={paginationChange('page')}
                  onChangeRowsPerPage={paginationChange('rowsPerPage')}
                />
              </div>
            </Sticky>
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <Table className={classes.table}>
                    <TableBody id="equipment-table-body">
                      {availableEquipmentIsFetching ? (
                        <Loader />
                      ) : (
                        <EquipmentsList
                          contractStatus={contract && contract.status}
                          editable={canEditContract(
                            permissions,
                            contract.status,
                          )}
                          data={data}
                          checked={checked}
                          change={checkedChange}
                        />
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fragment>
      )
    )
  }
}

ContractEquipmentTable.propTypes = {
  contract: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContractEquipmentTable)
