import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InnerCallHistory from './InnerCallHistory'
import {
  fetchInnerCallHistoryRequest,
  clearInnerCallHistory,
} from '../../../../actions/innerCallsActions'

const mapStateToProps = state => ({
  isFetching: state.innerCalls.innerCallHistory.isFetching,
  history: state.innerCalls.innerCallHistory.history,
  end: state.innerCalls.innerCallHistory.end,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInnerCallHistoryRequest,
      clearInnerCallHistory,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerCallHistory),
)
