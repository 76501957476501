import React from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'

import {
  fetchSDActionsHistory,
  fetchSDActionsHistoryCount,
} from '../../../../actions/serviceDesk'

import withTablePagination from '../../../../helpers/tablePaginationHOC'

const mapStateToPropsForHOC = state => ({
  data: state.serviceDesk.sdActionsHistory.history,
  isFetching: state.serviceDesk.sdActionsHistory.isFetching,
  isFetchingCount: state.serviceDesk.sdActionsHistory.isFetchingCount,
  error: state.serviceDesk.sdActionsHistory.error,
  errorCount: state.serviceDesk.sdActionsHistory.errorFetchingCount,
  count: state.serviceDesk.sdActionsHistory.count,
})

const mapDispatchToPropsForHOC = dispatch =>
  bindActionCreators(
    {
      fetchAction: payload => fetchSDActionsHistory(payload),
      fetchCountAction: payload => fetchSDActionsHistoryCount(payload),
    },
    dispatch,
  )

function SDActionsHistoryLoader({
  callId,
  render,
  setRequestPayload,
  ...paginationProps
}) {
  React.useEffect(() => {
    if (callId) {
      setRequestPayload({ id: callId })
    }
  }, [])
  return <React.Fragment>{render({ ...paginationProps })}</React.Fragment>
}

SDActionsHistoryLoader.propTypes = {
  callId: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}

export default withTablePagination(
  SDActionsHistoryLoader,
  mapStateToPropsForHOC,
  mapDispatchToPropsForHOC,
)
