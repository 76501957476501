import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchUserOrganizations } from '../../../../actions/organizationsActions'
import {
  editInventoryProject,
  fetchInventoryProjectProfile,
  editInventoryProjectResponsible,
} from '../../../../actions/inventoryActions'
import { fetchOrganizationStaff } from '../../../../actions/staffActions'
import EditInventoryProject from './EditInventoryProject'

const mapStateToProps = state => ({
  isFetchingOrganizations: state.organizations.isFetchingOrganizations,
  organizations: state.organizations.organizations,
  isEditingInventoryProject:
    state.inventory.editInventoryProject.isEditingInventoryProject,
  isFetchingProject: state.inventory.isFetchingProject,
  project: state.inventory.project,
  editProjectErrors: state.inventory.editInventoryProject.editProjectErrors,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  organizationStaff: state.staff.organizationStaff,
  fetchOrganizationStaffErrors: state.staff.errors,
  isEditingResponsible:
    state.inventory.editInventoryProjectResponsible.isEditing,
  editedResponsibleSuccessfully:
    state.inventory.editInventoryProjectResponsible.editedSuccessfully,
  editResponsibleErrors: state.inventory.editInventoryProjectResponsible.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProject: fetchInventoryProjectProfile,
      fetchUserOrganizations,
      fetchOrganizationStaff,
      editInventoryProject,
      editInventoryProjectResponsible,
    },
    dispatch,
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditInventoryProject),
)
