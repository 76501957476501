import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AssignToMeAsEngineer from './AssignToMeAsEngineer'
import { fetchOrganizationUserMe } from '../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isFetchingMeInOrganization: state.organizations.me.isFetching,
  meInOrganization: state.organizations.me.me,
  meInOrganizationError: state.organizations.me.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationUserMe,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssignToMeAsEngineer),
)
