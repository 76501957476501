import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import OrganizationStaffComponent from '../../components/Organizations/Profile/OrganizationStaffComponent'
import {
  fetchOrganizationStaff,
  deleteOrganizationUser,
  fetchOrganizationLocations,
  fetchOrganizationStaffCount,
  setOrganizationsStaffListRowsPerPage,
} from '../../actions/staffActions'
import { fetchUser } from '../../actions/userActions'

const mapStateToProps = state => ({
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  organizationStaff: state.staff.organizationStaff,
  organization: state.organizations.organization,
  isAddingOrganizationUser: state.staff.isAddingOrganizationUser,
  isUpdatingOrganizationUser: state.staff.isUpdatingOrganizationUser,
  updateOrganizationUserError: state.staff.updateOrganizationUserError,
  isDeletingOrganizationUser: state.staff.isDeletingOrganizationUser,
  isFetchingLocations: state.staff.isFetchingLocations,
  locations: state.staff.locations,
  count: state.staff.count,
  isFetchingUser: state.user.isFetchingUser,
  user: state.user.user,
  addUserError: state.staff.addUserError,
  isBindingUserAccount: state.staff.isBindingUserAccount,
  bindUserAccountError: state.staff.bindUserAccountError,
  isUnbindingUserAccount: state.staff.isUnbindingUserAccount,
  unbindUserAccountError: state.staff.unbindUserAccountError,
  isCreatingUpdatingOrganizationUser:
    state.staff.isCreatingUpdatingOrganizationUser,
  rowsPerPage: state.staff.rowsPerPage,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUser,
      fetchOrganizationStaff,
      fetchOrganizationStaffCount,
      deleteOrganizationUser,
      fetchOrganizationLocations,
      setOrganizationsStaffListRowsPerPage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationStaffComponent),
)
