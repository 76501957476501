import React from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../../i18n/i18n'
import { serviceDeskRequestType } from '../../../../../../constants/constants'
import FormControlSelect from '../../../../../../widgets/Controlls/FormControlSelect'

const RequestCreationReason = props => {
  const { onChange, value } = props
  return (
    <FormControlSelect
      required
      label={i18next.t('service_desk_web_atoir:requestCreationReasonLabel')}
      value={value}
      values={[
        {
          value: '',
          content: <em />,
        },
        {
          value: serviceDeskRequestType.DIAGNOSTIC_AND_REPAIR,
          content: i18next.t(
            'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepair',
          ),
        },
      ]}
      onChange={({ target: { value: v } }) =>
        onChange('requestCreationReason')(v)
      }
    />
  )
}

RequestCreationReason.defaultProps = {
  value: '',
}

RequestCreationReason.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default RequestCreationReason
