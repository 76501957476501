import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import i18n from '../../../../i18n/i18n'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import ButtonBase from '@material-ui/core/ButtonBase'
import { lighten } from '@material-ui/core/styles/colorManipulator'

const styles = theme => ({
  root: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: theme.palette.common.white,
    paddingRight: theme.typography.pxToRem(24),
    paddingLeft: theme.typography.pxToRem(24),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(15),
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
  },
  divDetailInfo: {
    width: '100%',
    textAlign: 'left',
  },
})

class AgreementRow extends PureComponent {
  render() {
    const {
      props: { type, name, date, status, equipmentCount, classes, onClick },
    } = this
    return (
      <ButtonBase
        focusRipple
        focusVisibleClassName={classes.focusVisible}
        onClick={onClick}
        style={{
          width: '100%',
        }}
      >
        {/* TODO цвет не из палитры, и верстка без макета, изменить после согласования дизайна */}
        <div className={classes.root}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" color="textSecondary">
              {i18n.t(`service_desk_web_atoir:${type}AgreementType`)}
            </Typography>
            {status === 'inperformer' && (
              <div
                style={{
                  marginLeft: '8px',
                  backgroundColor: '#ff4538',
                  borderRadius: '2px',
                  padding: '0.25em 0.5em 0.375em',
                  color: '#fff',
                }}
              >
                <Typography variant="body1" color="inherit">
                  {i18n.t('service_desk_web_atoir:agreementInperformer')}
                </Typography>
              </div>
            )}
          </div>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body1" color="textSecondary">
            {`${i18n.t('service_desk_web_atoir:dateOfCreation')}: ${date}`}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`${i18n.t('shared_web_atoir:status')}: ${i18n.t(
              `service_desk_web_atoir:${status}AgreementStatus`,
            )}`}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`${i18n.t(
              'service_desk_web_atoir:agreementEquipmentCount',
            )}: ${equipmentCount}`}
          </Typography>
          {status === 'rejected' && (
            <div style={{ display: 'flex' }}>
              <NotificationImportant
                fontSize="small"
                color="error"
                style={{ paddingRight: 4 }}
              />
              <Typography variant="body2" gutterBottom color="textSecondary">
                {i18n.t('service_desk_web_atoir:agreementRejected')}
              </Typography>
            </div>
          )}
        </div>
      </ButtonBase>
    )
  }
}

AgreementRow.defaultProps = {
  onClick: () => {},
}

AgreementRow.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  equipmentCount: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default withStyles(styles)(AgreementRow)
