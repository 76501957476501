import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import i18n from '../../../../i18n/i18n'
import FormDialogComponent from '../../../../widgets/FormDialog'

class CancelAgreement extends Component {
  state = {
    reason: '',
    error: null,
  }

  constructor(props) {
    super(props)
    this.props.createRef(this)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCanceling !== this.props.isCanceling &&
      this.props.isCanceling === false
    ) {
      this.props.onFinish(this.props.cancelError)
      this.props.onClose()
    }
  }

  toggle = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  onSubmit = () => {
    const {
      state: { reason },
      props: { agreement, cancelAgreementRequest },
    } = this
    if (!reason) {
      this.setState({
        error: i18n.t(
          'service_desk_web_atoir:agreementCancelationReasonMustBeSpecified',
        ),
      })
      return
    }
    cancelAgreementRequest({
      id: agreement.contract.id,
      agreementId: agreement.id,
      reject_note: reason,
    })
  }

  onChange = event => {
    this.setState({
      error: null,
      reason: event.target.value,
    })
  }

  render() {
    const {
      props: { open, onClose },
      state: { reason, error },
      onSubmit,
      onChange,
    } = this
    return (
      <FormDialogComponent
        open={open}
        fullWidth
        onSubmit={onSubmit}
        onClose={onClose}
        title={i18n.t('service_desk_web_atoir:confirmAgreementCancellation')}
        submitButtonTitle={i18n.t('service_desk_web_atoir:confirm')}
        contentText={
          <Fragment>
            <TextField
              style={{ margin: '8px auto 8px auto' }}
              fullWidth
              placeholder={i18n.t(
                'service_desk_web_atoir:specifyAgreementCancelReason',
              )}
              error={error}
              multiline
              variant="outlined"
              value={reason}
              onChange={onChange}
              autoFocus
            />
            <Typography color="error">{error}</Typography>
          </Fragment>
        }
      />
    )
  }
}

CancelAgreement.defaultProps = {
  createRef: () => {},
  open: false,
}

CancelAgreement.propTypes = {
  createRef: PropTypes.func,
  open: PropTypes.bool,
  agreement: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CancelAgreement
