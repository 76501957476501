import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Chip from '@material-ui/core/Chip'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import AddDocDialog from './widgets/AddDocDialog'
import Loader from '../../../../Loader'
import config from '../../../../../config'
import i18next from '../../../../../i18n/i18n'
import SimpleDialog from '../../../../Other/SimpleDialog/DialogComponent'
import BlockTitle from '../BlockTitle'

const styles = theme => ({
  root: {
    textAlign: 'left',
    padding: 12,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileName: {
    color: theme.palette.grey[700],
    textDecoration: 'underline',
  },
  chip: {
    margin: theme.spacing(1),
  },
  empty: {
    marginLeft: theme.spacing(1),
  },
})

class RequestDocs extends Component {
  state = {
    addDocDialogOpen: false,
    file: null,
    fileText: '',
  }

  onToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  handleAdd = () => {
    const {
      props: { upload },
    } = this
    upload(this.state.file)
    this.setState({
      file: null,
      fileText: '',
      addDocDialogOpen: false,
    })
  }

  onCloseDialog = () => {
    this.setState({
      file: null,
      fileText: '',
      addDocDialogOpen: false,
    })
  }

  onDelete = doc => () => {
    this.setState({
      docToDelete: doc,
      deleteDocDialogOpen: true,
    })
  }

  deleteDoc = () => {
    this.props.deleteDocServiceDeskCall({
      callId: this.props.callId,
      docId: this.state.docToDelete.id,
    })
    this.onToggle('deleteDocDialogOpen', false)()
  }

  onChange = event => {
    const { fileTypeOffer, showDialogWithOk } = this.props
    const file = event.target.files[0]
    if (!file) {
      showDialogWithOk(i18next.t('shared_web_atoir:fileToBig'))
      return
    }
    if (file && file.size > config.max_call_file_size) {
      showDialogWithOk(i18next.t('shared_web_atoir:fileToBig'))
      return
    }
    const formData = new FormData()
    formData.append('doc', file)
    formData.append('file_type', fileTypeOffer)
    this.setState({
      file: formData,
      fileText: file.name,
    })
  }

  getLink = doc => () => {
    const {
      props: { getServiceCallDocumentLinkRequest },
    } = this
    getServiceCallDocumentLinkRequest({
      callId: doc.service_call_id,
      documentId: doc.id,
      name: doc.name,
    })
  }

  render() {
    const {
      state: { file, addDocDialogOpen, fileText, deleteDocDialogOpen },
      props: {
        classes,
        docs,
        isFetchingDocs,
        canEdit,
        fileTypeOffer,
        hideTitle,
      },
      onToggle,
      onDelete,
      onCloseDialog,
      onChange,
      handleAdd,
      deleteDoc,
      getLink,
    } = this

    return (
      <div>
        <Grid container spacing={2} style={{ textAlign: 'left' }}>
          {!hideTitle && (
            <Grid item xs={12}>
              {fileTypeOffer === 'offer' ? (
                <BlockTitle
                  text={i18next.t('service_desk_web_atoir:repairOfferDocs')}
                  noTopMargin
                />
              ) : (
                <BlockTitle
                  text={i18next.t('service_desk_web_atoir:usefullDocs')}
                  noTopMargin
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} className={classes.spaceBetween}>
            <div>
              {isFetchingDocs ? (
                <Loader />
              ) : (
                docs.map(doc => (
                  <Chip
                    icon={<VerticalAlignBottomIcon />}
                    label={doc.name}
                    onClick={getLink(doc)}
                    onDelete={
                      (fileTypeOffer === 'offer' && docs.length === 1) ||
                      !canEdit
                        ? null
                        : onDelete(doc)
                    }
                    className={classes.chip}
                    variant="outlined"
                  />
                ))
              )}
              {docs && docs.length < config.max_call_file_count && canEdit && (
                <Chip
                  id="addDocumentChip"
                  icon={<AddIcon />}
                  label={i18next.t('shared_web_atoir:addDocument')}
                  onClick={onToggle('addDocDialogOpen', true)}
                  className={classes.chip}
                />
              )}
              {!canEdit && docs.length === 0 && (
                <Typography variant="caption" className={classes.empty}>
                  {i18next.t('service_desk_web_atoir:noDocuments')}
                </Typography>
              )}
            </div>
            {deleteDocDialogOpen && (
              <SimpleDialog
                isOpen={deleteDocDialogOpen}
                handleClose={onToggle('deleteDocDialogOpen', false)}
                handlePositive={deleteDoc}
                handleNegative={onToggle('deleteDocDialogOpen', false)}
                positiveText={i18next.t('shared_web_atoir:delete')}
                negativeText={i18next.t('shared_web_atoir:cancel')}
                title=""
                content={`${i18next.t(
                  'service_desk_web_atoir:deleteDocument',
                )}?`}
                closeIconHidded
              />
            )}
          </Grid>
        </Grid>
        {addDocDialogOpen && (
          <AddDocDialog
            title={i18next.t('shared_web_atoir:addDocument')}
            handleAdd={handleAdd}
            onChange={onChange}
            file={file}
            fileText={fileText}
            isOpen={addDocDialogOpen}
            onClose={onCloseDialog}
          />
        )}
      </div>
    )
  }
}

RequestDocs.defaultProps = {
  docs: [],
  isFetchingDocs: true,
  fileTypeOffer: 'common',
  hideTitle: false,
}

RequestDocs.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  callId: PropTypes.string.isRequired,
  upload: PropTypes.func.isRequired,
  isFetchingDocs: PropTypes.bool,
  docs: PropTypes.array,
  fileTypeOffer: PropTypes.string,
  hideTitle: PropTypes.bool,
  getServiceCallDocumentLinkRequest: PropTypes.func.isRequired,
}

export default withStyles(styles)(RequestDocs)
