import React, { Component, Fragment } from 'react'
import Link from 'react-router-dom/Link'
import PropTypes from 'prop-types'

// material-ui
import { withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import Divider from '@material-ui/core/Divider'
import DelayedLoader from '../../../../widgets/DelayedLoader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// component
import ContractItem from './ContractItem'

const styles = {
  listRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  fab: {
    position: 'absolute',
    right: -28,
    top: 16,
  },
  fabIcon: {
    color: 'white',
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}

class ContractsTable extends Component {
  state = {}

  componentDidMount() {
    this.props.fetchHasManagerOrHigherOrganizations()
  }

  render() {
    const {
      props: {
        classes,
        contracts,
        isFetching,
        hasManagerOrHigherOrganizations,
        history,
        isOrganizationFunctionalsAvailable,
        match: { url },
      },
    } = this
    return (
      <div>
        <DelayedLoader
          loaderStyles={{ margin: '0 auto', display: 'block' }}
          show={isFetching}
          delay={500}
          delayOut={500}
        />
        <List className={classes.listRoot}>
          {isOrganizationFunctionalsAvailable &&
            hasManagerOrHigherOrganizations && (
              <Fab
                className={classes.fab}
                color="primary"
                onClick={() => {
                  history.push(`${url}/create`)
                }}
              >
                <AddIcon className={classes.fabIcon} />
              </Fab>
            )}
          {contracts &&
            contracts.map((c, index) => (
              <Fragment>
                <ListItem button component={Link} to={`${url}/${c.id}`}>
                  <ContractItem
                    contract={c}
                    rootStyle={{ padding: '8px 24px' }}
                    hasEquipmentWithNoEngineer={
                      c.equipment_count - c.equipment_with_engineer_count !== 0
                    }
                    hasBeenRejected={c.reject_note && c.status === 'created'}
                    hasNotAcceptedAgreements={c.has_not_accepted_agreement}
                  />
                </ListItem>
                {contracts && contracts.length !== index + 1 && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            ))}
        </List>
      </div>
    )
  }
}

ContractsTable.defaultProps = {
  contracts: [],
}

ContractsTable.propTypes = {
  contracts: PropTypes.any,
}

export default withStyles(styles)(ContractsTable)
