import React, { Fragment } from 'react'
import i18next from '../../../../i18n/i18n'
import PhotoGallery from '../../../../widgets/PhotoGallery'
import { URL } from '../../../../middlewares/api'
import { Typography } from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
const generateUrl = (organizationId, equipmentId, photoId) =>
  // 'https://images.pexels.com/photos/3319097/pexels-photo-3319097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
  `${URL}/organizations/${organizationId}/equipments/${equipmentId}/binded_ba/photo/${photoId}`

export default function({ classes, binded, equipment }) {
  const urls = binded.BAEquipmentFotos.map(photo =>
    generateUrl(equipment.organization_id, equipment.id, photo.id),
  )
  return (
    <Fragment>
      <Typography className={classes.sectionName}>
        {i18next.t('equipment_web_atoir:photos')}
      </Typography>
      <PhotoGallery urls={urls} />
    </Fragment>
  )
}
