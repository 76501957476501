import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmInfo from './ConfirmInfo'

const mapStateToProps = state => ({
  orders: state.inventory.orders.orders,
  isFetchOrders: state.inventory.orders.isFetch,
  project: state.inventory.project,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmInfo),
)
