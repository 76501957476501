import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import OrganizationsComponent from '../../components/Organizations/OrganizationsComponent'
import {
  fetchUserOrganizations,
  fetchUserOrganizationsCount,
  setOrganizationsListRowsPerPage,
  setOrganizationsEmployersListRowsPerPage,
} from '../../actions/organizationsActions'
import {
  fetchEmployers,
  leaveOrganization,
  confirmJoinOrganization,
  fetchEmployersCount,
} from '../../actions/employersActions'
import {
  downloadSDWorkingReport,
  generateSDWorkingReport,
} from '../../actions/statisticsActions'

const mapStateToProps = state => ({
  isFetchingOrganizations: state.organizations.isFetchingOrganizations,
  organizations: state.organizations.organizations,
  count: state.organizations.count,
  isFetchingEmployers: state.employers.isFetchingEmployers,
  employers: state.employers.employers,
  employersCount: state.employers.count,
  isLeavingOrganization: state.employers.isLeavingOrganization,
  isConfirmingJoinOrganization: state.employers.isConfirmingJoinOrganization,
  sdWorkingReport: state.statistics.sdWorkingReportGeneration,
  rowsPerPage: state.organizations.rowsPerPage,
  rowsPerPageEmployers: state.organizations.rowsPerPageEmployers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserOrganizations,
      fetchUserOrganizationsCount,
      fetchEmployers,
      fetchEmployersCount,
      leaveOrganization,
      confirmJoinOrganization,
      generateSDWorkingReport,
      downloadSDWorkingReport,
      setOrganizationsListRowsPerPage,
      setOrganizationsEmployersListRowsPerPage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsComponent),
)
