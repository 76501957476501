import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18next from '../i18n/i18n'

import ErrorComponent from '../components/ErrorComponent'

const mapStateToProps = state => ({
  error: state.app.errorMessage
    ? state.app.errorMessage.app[0]
    : i18next.t('error_web_atoir:error'),
})

export default withRouter(connect(mapStateToProps)(ErrorComponent))
