import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EquipmentTable from '../../EquipmentTable'
import { URL } from '../../../../../middlewares/api'
import { canEditAgreement } from '../../../../../helpers/ContractPermissionsResolver'

class AgreementAddEquipmentTable extends Component {
  state = {
    checked: [],
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isAddingEquipmentToAgreement !==
        this.props.isAddingEquipmentToAgreement &&
      !this.props.isAddingEquipmentToAgreement &&
      !this.props.deleteEquipmentError
    ) {
      this.tableRef.loadEquipmentData()
      this.tableRef.loadEquipmentCount()
      this.setState({ checked: [] })
    }
  }

  onChange = checked => {
    this.setState({
      checked,
    })
  }

  addEquipmentToAgreement = () => {
    const {
      state: { checked },
      props: { addAgreementEquipmentRequest, match },
    } = this
    addAgreementEquipmentRequest({ ...match.params, equipmentsIds: checked })
  }

  render() {
    const {
      state: { checked },
      props: {
        permissions,
        agreement,
        match,
        fetchAvailableEquipment,
        fetchAvailableEquipmentCount,
        fetchContractEquipment,
        fetchContractEquipmentCount,
        availableEquipment,
        availableEquipmentIsFetching,
        availableEquipmentFetchingError,
        availableEquipmentCount,
        availableEquipmentCountIsFetching,
        availableEquipmentCountFetchingError,
        contractEquipment,
        contractEquipmentIsFetching,
        contractEquipmentFetchingError,
        contractEquipmentCount,
        contractEquipmentCountIsFetching,
        contractEquipmentCountFetchingError,
      },
      addEquipmentToAgreement,
      onChange,
    } = this
    const fetchEquipmentsRequest =
      agreement.agreement_type === 'del'
        ? params =>
            fetchContractEquipment({
              ...params,
              exclude_agreement: agreement.id,
            })
        : params =>
            fetchAvailableEquipment({
              clientOrganizationId: agreement.contract.client_organization_id,
              payload: {
                addToContract: agreement.contract.id,
                ...params,
              },
            })
    const fetchEquipmentsCountRequest =
      agreement.agreement_type === 'del'
        ? params =>
            fetchContractEquipmentCount({
              ...params,
              exclude_agreement: agreement.id,
            })
        : params =>
            fetchAvailableEquipmentCount({
              clientOrganizationId: agreement.contract.client_organization_id,
              payload: {
                addToContract: agreement.contract.id,
                ...params,
              },
            })
    const equipment =
      agreement.agreement_type === 'del'
        ? contractEquipment
        : availableEquipment
    const isFetching =
      agreement.agreement_type === 'del'
        ? contractEquipmentIsFetching
        : availableEquipmentIsFetching
    const error =
      agreement.agreement_type === 'del'
        ? contractEquipmentFetchingError
        : availableEquipmentFetchingError
    const count =
      agreement.agreement_type === 'del'
        ? contractEquipmentCount
        : availableEquipmentCount
    const isFetchingCount =
      agreement.agreement_type === 'del'
        ? contractEquipmentCountIsFetching
        : availableEquipmentCountIsFetching
    const countError =
      agreement.agreement_type === 'del'
        ? contractEquipmentCountFetchingError
        : availableEquipmentCountFetchingError
    return (
      <div>
        <EquipmentTable
          addOnly
          createRef={ref => {
            this.tableRef = ref
          }}
          onAddEquipmentClicked={addEquipmentToAgreement}
          locationsFetchUrl={`${URL}/service_desk_contracts/${match.params.contractId}/filters/locations`}
          departmentsFetchUrl={`${URL}/service_desk_contracts/${match.params.contractId}/filters/departments`}
          canAddOrDelete={canEditAgreement(permissions, agreement.status)}
          canEdit={canEditAgreement(permissions, agreement.status)}
          equipmentCount={count}
          equipmentCountIsFetching={isFetchingCount}
          equipmentCountFetchingError={countError}
          fetchEquipment={query =>
            fetchEquipmentsRequest({
              id: match.params.contractId,
              agreementId: match.params.agreementId,
              clientOrganizationId: agreement.contract.client_organization_id,
              ...query,
            })
          }
          fetchCount={query =>
            fetchEquipmentsCountRequest({
              id: match.params.contractId,
              agreementId: match.params.agreementId,
              clientOrganizationId: agreement.contract.client_organization_id,
              ...query,
            })
          }
          availableEquipment={equipment}
          availableEquipmentIsFetching={isFetching}
          availableEquipmentFetchingError={error}
          checked={checked}
          onChange={onChange}
          permissions={permissions}
        />
      </div>
    )
  }
}

AgreementAddEquipmentTable.propTypes = {
  permissions: PropTypes.object.isRequired,
  agreement: PropTypes.object.isRequired,
}

export default AgreementAddEquipmentTable
