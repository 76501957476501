import React from 'react'

import DrawerCollapsible from './DrawerCollapsible'
import DrawerCommon from './DrawerCommon'

import { useAppState, appOptions } from '../../appContext'

function DrawerComponent() {
  const { drawerMode } = useAppState()
  return (
    <nav>
      <DrawerCollapsible
        open={drawerMode === appOptions.drawerMode.collapsible}
      />
      <DrawerCommon open={drawerMode === appOptions.drawerMode.common} />
    </nav>
  )
}

export default DrawerComponent
