import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// material
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

// modules
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'
import ChooseThirdPartyOrganization from '../ChooseThirdPartyOrganization'
import Heading from '../../../widgets/Lettering/Heading'

// i18
import i18next from '../../../i18n/i18n'

const styles = () => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
})

class SendToSubOrder extends Component {
  state = {
    organization: null,
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isAssigning !== this.props.isAssigning &&
      this.props.isAssigning === false
    ) {
      this.props.onClose()
      this.props.hideDialogLoader()
    }
  }

  onOrganizationChanged = organization => {
    this.setState({ organization })
  }

  onSendToSubOrder = () => {
    const {
      state: { organization },
      props: {
        callId,
        assignToThirdPartyOrganization,
        showDialogLoader,
        onSubmit,
      },
    } = this

    if (!isEmpty(onSubmit)) {
      if (isEmpty(organization)) {
        return
      }

      onSubmit({ organization: organization.id, comment: this.state.comment })
      return
    }

    showDialogLoader()
    assignToThirdPartyOrganization({ callId, organization: organization.id })
  }

  onChange = field => event => {
    this.setState({ [field]: event.target.value })
  }

  render() {
    const {
      state: { organization, comment },
      props: { classes, open, onClose, callId, performerId },
      onChange,
      onSendToSubOrder,
      onOrganizationChanged,
    } = this
    return (
      <Dialog
        classes={{ paper: classes.dialogRoot }}
        open={open}
        onClose={onClose}
      >
        <Heading>
          <Typography variant="h6" style={{ padding: 16 }}>
            {i18next.t('service_desk_web_atoir:assigningThirdPartyTitle')}
          </Typography>
        </Heading>
        <Divider />
        <DialogContent style={{ padding: 0, paddingTop: 16 }}>
          <ChooseThirdPartyOrganization
            clientOrganizationId={performerId}
            selected={organization && organization.id}
            serviceCallId={callId}
            onOrganizationChanged={onOrganizationChanged}
            callId={callId}
          />
          <div style={{ padding: 12 }}>
            <TextField
              fullWidth
              value={comment}
              multiline
              onChange={onChange('comment')}
              variant="outlined"
              label={i18next.t('service_desk_web_atoir:comment')}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <OutlinedButton onClick={onClose} size="medium">
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton onClick={onSendToSubOrder} autoFocus size="medium">
            {i18next.t('service_desk_web_atoir:sendToSubOrder')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

SendToSubOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callId: PropTypes.string.isRequired,
}

export default withStyles(styles)(SendToSubOrder)
