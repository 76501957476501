import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import Loader from '../../Loader'
import EditLocationComponent from '../Locations/EditLocationComponent'
import ContactsForm from '../ContactsForm'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

// modules
import FormDialog from '../../../widgets/FormDialog'
import Subheading from '../../../widgets/Lettering/Subheading'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'

const styles = () => ({
  heading: {
    margin: '12px 0 12px 0',
  },
})

class EditOrganizationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.contactsFormRef = React.createRef()
    this.state = {
      isDataValid: true,
      isFullNameValid: true,
      isShortNameValid: true,
      data: props.data || {},
      isUpdatingOrganization: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      contacts: [],
      isContactsValid: true,
      clientOrganization: props.data ? props.data.client_organization : false,
      tradeOrganization: props.data ? props.data.trade_organization : false,
      serviceOrganization: props.data ? props.data.service_organization : false,
      isActivityValid: true,
      fullName: props.data ? props.data.full_name : '',
      shortName: props.data ? props.data.short_name : '',
      INN: props.data ? (props.data.INN ? props.data.INN : '') : '',
      KPP: props.data ? (props.data.KPP ? props.data.KPP : '') : '',
      OGRN: props.data ? (props.data.OGRN ? props.data.OGRN : '') : '',
      OKPO: props.data ? (props.data.OKPO ? props.data.OKPO : '') : '',
      bank: props.data ? (props.data.bank ? props.data.bank : '') : '',
      rAccount: props.data
        ? props.data.rAccount
          ? props.data.rAccount
          : ''
        : '',
      kAccount: props.data
        ? props.data.kAccount
          ? props.data.kAccount
          : ''
        : '',
      BIK: props.data ? (props.data.BIK ? props.data.BIK : '') : '',
      director: props.data
        ? props.data.director
          ? props.data.director
          : ''
        : '',
      establishment: props.data
        ? props.data.establishment
          ? props.data.establishment
          : ''
        : '',
      contactsFormHelperText: false,
    }
  }

  componentWillReceiveProps(props) {
    if (
      this.state.isUpdatingOrganization !== props.isUpdatingOrganization &&
      props.isUpdatingOrganization === false
    ) {
      if (props.error !== undefined) {
        switch (+props.error) {
          case 60:
            this.showSnackBar(i18next.t('error_web_atoir:dataValidationError'))
            break
          default:
            this.showSnackBar(
              i18next.t('error_web_atoir:couldNotUpdateLocation'),
            )
            break
        }
      } else {
        props.onClose(
          i18next.t('organizations_web_atoir:organizationUpdatedMessage'),
        )
        props.fetchOrganization(props.organization.id)
      }
    }
    this.setState({
      ...props,
    })
  }

  componentDidMount = () => {
    this.props.fetchPhysicalLocation(this.props.organization.id)
    this.props.fetchLegalLocation(this.props.organization.id)
    this.props.fetchContacts(this.props.organization.id)
  }

  handleClose = () => {
    const { props } = this
    this.setState({
      isDataValid: true,
      isFullNameValid: true,
      isShortNameValid: true,
      data: props.data || {},
      isUpdatingOrganization: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      contacts: [],
      isContactsValid: true,
      clientOrganization: props.data ? props.data.client_organization : false,
      tradeOrganization: props.data ? props.data.trade_organization : false,
      serviceOrganization: props.data ? props.data.service_organization : false,
      isActivityValid: true,
      fullName: props.data ? props.data.full_name : '',
      shortName: props.data ? props.data.short_name : '',
      INN: props.data ? (props.data.INN ? props.data.INN : '') : '',
      KPP: props.data ? (props.data.KPP ? props.data.KPP : '') : '',
      OGRN: props.data ? (props.data.OGRN ? props.data.OGRN : '') : '',
      OKPO: props.data ? (props.data.OKPO ? props.data.OKPO : '') : '',
      bank: props.data ? (props.data.bank ? props.data.bank : '') : '',
      rAccount: props.data
        ? props.data.rAccount
          ? props.data.rAccount
          : ''
        : '',
      kAccount: props.data
        ? props.data.kAccount
          ? props.data.kAccount
          : ''
        : '',
      BIK: props.data ? (props.data.BIK ? props.data.BIK : '') : '',
      director: props.data
        ? props.data.director
          ? props.data.director
          : ''
        : '',
      establishment: props.data
        ? props.data.establishment
          ? props.data.establishment
          : ''
        : '',
      contactsFormHelperText: false,
    })
    this.props.onClose()
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  handleUpdate = () => {
    const {
      fullName,
      shortName,
      clientOrganization,
      tradeOrganization,
      serviceOrganization,
      INN,
      KPP,
      OGRN,
      OKPO,
      bank,
      rAccount,
      kAccount,
      BIK,
      director,
      establishment,
      isINNValid,
      isOGRNValid,
      isOKPOValid,
      isBIKValid,
      data,
      dataLegal,
      contacts,
      isContactsValid,
    } = this.state
    this.contactsFormRef.current.validateAllContacts()
    if (
      this.state.isDataValid &&
      isContactsValid &&
      this.state.isLegalDataValid !== false &&
      isINNValid !== false &&
      isOGRNValid !== false &&
      isOKPOValid !== false &&
      isBIKValid !== false
    ) {
      this.props.updateOrganization(this.props.organization.id, {
        full_name: fullName,
        short_name: shortName,
        client_organization: clientOrganization,
        trade_organization: tradeOrganization,
        service_organization: serviceOrganization,
        INN: INN ? (INN.length > 0 ? INN : undefined) : undefined,
        KPP: KPP ? (KPP.length > 0 ? KPP : undefined) : undefined,
        OGRN: OGRN ? (OGRN.length > 0 ? OGRN : undefined) : undefined,
        OKPO: OKPO ? (OKPO.length > 0 ? OKPO : undefined) : undefined,
        bank: bank ? (bank.length > 0 ? bank : undefined) : undefined,
        r_account: rAccount
          ? rAccount.length > 0
            ? rAccount
            : undefined
          : undefined,
        k_account: kAccount,
        BIK: BIK ? (BIK.length > 0 ? BIK : undefined) : undefined,
        director: director
          ? director.length > 0
            ? director
            : undefined
          : undefined,
        establishment: establishment
          ? establishment.length > 0
            ? establishment
            : undefined
          : undefined,
      })
      // Update physical location
      if (data.isChanged || data.location.isChanged) {
        const streetId = data.location.location.data.street_fias_id
        this.props.updateLocation(
          this.props.organization.id,
          this.props.physical.id,
          {
            name: data.name,
            home: data.location.location.home,
            floor: data.floor,
            office: data.office,
            location_type: 0,
            street_fias_id: streetId,
            buildingId: data.location.location.id,
            location_value: data.location.location.value,
          },
        )
      }

      // Update legal location
      if (dataLegal && dataLegal.location.isChanged) {
        const streetIdLegal = dataLegal.location.location.data.street_fias_id
        const legalParams = {
          name: i18next.t('organizations_web_atoir:legalAddress'),
          home: dataLegal.location.location.home,
          floor: dataLegal.floor,
          office: dataLegal.office,
          location_type: 1,
          street_fias_id: streetIdLegal,
          buildingId: dataLegal.location.location.id,
          location_value: dataLegal.location.location.value,
        }
        if (this.props.legal) {
          this.props.updateLocation(
            this.props.organization.id,
            this.props.legal.id,
            legalParams,
          )
        } else {
          this.props.createLocation(this.props.organization.id, legalParams)
        }
      }

      contacts.forEach(contact => {
        if (contact.isNew) {
          if (contact.isValid) {
            this.props.createContact(this.state.organization.id, {
              full_name: contact.fullName,
              email: contact.email,
              position: contact.position,
              phone: contact.phone.replace(/[^0-9]/g, ''),
              phone_additional: contact.phoneAdditional
                ? contact.phoneAdditional.replace(/[^0-9]/g, '')
                : undefined,
            })
          }
        } else if (contact.isValid && contact.onChanged) {
          this.props.updateContact(this.state.organization.id, contact.id, {
            full_name: contact.fullName,
            email: contact.email,
            position: contact.position,
            phone: contact.phone.replace(/[^0-9]/g, ''),
            phone_additional: contact.phoneAdditional,
          })
        }
      })
    } else {
      this.setState({
        contactsFormHelperText: true,
      })
    }
  }

  validate = isLocationValid => {
    const {
      isLocationDataValid,
      isLocationLegalDataValid,
      isContactsValid,
      dataLegal,
    } = this.state
    if (isLocationValid === undefined && isLocationDataValid === false) {
      return false
    }
    if (
      isLocationValid === undefined &&
      dataLegal &&
      isLocationLegalDataValid === false
    ) {
      return false
    }
    if (isLocationValid === false) {
      return false
    }
    if (!isContactsValid) {
      return false
    }
    if (
      !this.fullName ||
      !this.fullName.value ||
      this.fullName.value.length === 0 ||
      !this.shortName.value ||
      this.shortName.value.length === 0 ||
      (this.INN.value !== undefined &&
        this.INN.value.length > 0 &&
        this.INN.value.length !== 12) ||
      (this.OGRN.value !== undefined &&
        this.OGRN.value.length > 0 &&
        this.OGRN.value.length !== 13) ||
      (this.OKPO.value !== undefined &&
        this.OKPO.value.length > 0 &&
        this.OKPO.value.length !== 10) ||
      (this.BIK.value !== undefined &&
        this.BIK.value.length > 0 &&
        this.BIK.value.length !== 9) ||
      (!this.clientOrganization.checked &&
        // AT-396
        // !this.tradeOrganization.checked &&
        !this.serviceOrganization.checked)
    ) {
      return false
    }
    return true
  }

  onFullNameChange = event => {
    this.setState({
      isFullNameValid: event.currentTarget.value.length > 0,
      fullName:
        event.currentTarget.value.length > 0
          ? event.currentTarget.value
          : undefined,
      isDataValid: this.validate(),
    })
  }

  onShortNameChange = event => {
    this.setState({
      isShortNameValid: event.currentTarget.value.length > 0,
      shortName:
        event.currentTarget.value.length > 0
          ? event.currentTarget.value
          : undefined,
      isDataValid: this.validate(),
    })
  }

  organizationTypeChange = field => event => {
    this.setState(
      {
        [field]: event.target.checked,
        isDataValid: this.validate(),
      },
      () => {
        this.setState(state => ({
          isActivityValid:
            state.clientOrganization ||
            state.tradeOrganization ||
            state.serviceOrganization ||
            false,
        }))
      },
    )
  }

  onDataChanged = data => {
    this.setState({
      data,
      isLocationDataValid: data.isValid,
      isDataValid: this.validate(data.isValid),
    })
  }

  onDataChangedLegal = dataLegal => {
    this.setState({
      dataLegal,
      isLocationLegalDataValid: dataLegal.isValid,
      isLegalDataValid: this.validate(dataLegal.isValid),
    })
  }

  handleContactsChanged = (contacts, isContactsValid) => {
    this.setState(
      {
        contacts,
        isContactsValid,
      },
      () => {
        this.validate()
      },
    )
  }

  isFieldValid = (field, value) => {
    switch (field) {
      case 'INN':
        return value.length === 12 || value.length === 0
      case 'OGRN':
        return value.length === 13 || value.length === 0
      case 'OKPO':
        return value.length === 10 || value.length === 0
      case 'BIK':
        return value.length === 9 || value.length === 0
      default:
        return true
    }
  }

  onFieldChange = field => event => {
    if (
      (field === 'INN' ||
        field === 'OGRN' ||
        field === 'KPP' ||
        field === 'OKPO' ||
        field === 'BIK') &&
      event.target.value.match(/[^0-9]/g)
    ) {
      return
    }
    this.setState({
      [`is${field}Valid`]: this.isFieldValid(field, event.currentTarget.value),
      [field]:
        event.currentTarget.value.length > 0
          ? event.currentTarget.value
          : undefined,
      isDataValid: this.validate(
        this.isFieldValid(field, event.currentTarget.value),
        field,
      ),
    })
  }

  getSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  renderEditLocationDialog = () => {
    const {
      classes,
      open,
      isFetchingPhysicalLocation,
      isFetchingLegalLocation,
      legal,
      physical,
      contacts,
      isFetchingContacts,
    } = this.props
    const {
      fullName,
      shortName,
      INN,
      KPP,
      OGRN,
      OKPO,
      bank,
      rAccount,
      kAccount,
      BIK,
      director,
      establishment,
      isINNValid,
      isOGRNValid,
      isOKPOValid,
      isBIKValid,
      isFullNameValid,
      isShortNameValid,
      isLocationDataValid,
      isContactsValid,
      contactsFormHelperText,
      isActivityValid,
    } = this.state

    return (
      <FormDialog
        open={open}
        isLoading={this.props.isUpdatingOrganization}
        fullScreen
        title={i18next.t(
          'organizations_web_atoir:organizationDataEditDialogTitle',
          { shortName },
        )}
        onClose={this.handleClose}
        onExit={this.handleClose}
        onSubmit={this.handleUpdate}
        submitButtonTitle={i18next.t('shared_web_atoir:update')}
        leftSecondaryButtonsArray={[
          {
            secondaryText: `${i18next.t('shared_web_atoir:cancel')}`,
            handleSecondaryAction: this.handleClose,
          },
        ]}
      >
        <Grid container spacing={2} classes="center">
          <Grid item xs={6}>
            <TextField
              label={
                !isFullNameValid
                  ? i18next.t('validation_web_atoir:fieldCanNotBeEmpty')
                  : i18next.t('organizations_web_atoir:companyFullName')
              }
              id="organization-full-name-name"
              required
              fullWidth
              error={
                !isFullNameValid
                  ? i18next.t('validation_web_atoir:fieldCanNotBeEmpty')
                  : false
              }
              inputRef={el => {
                this.fullName = el
              }}
              value={fullName}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFullNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={
                !isShortNameValid
                  ? i18next.t('validation_web_atoir:fieldCanNotBeEmpty')
                  : i18next.t('organizations_web_atoir:organizationShortName')
              }
              id="organization-short-name-name"
              required
              inputRef={el => {
                this.shortName = el
              }}
              error={
                !isShortNameValid
                  ? i18next.t('validation_web_atoir:fieldCanNotBeEmpty')
                  : false
              }
              fullWidth
              value={shortName}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onShortNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Subheading
              title={i18next.t(
                'organizations_web_atoir:organizationSpecialization',
              )}
            />
            <FormControl required error={!this.state.isActivityValid}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.clientOrganization}
                      inputRef={el => {
                        this.clientOrganization = el
                      }}
                      onChange={this.organizationTypeChange(
                        'clientOrganization',
                      )}
                      value="clientOrganization"
                      color="primary"
                    />
                  }
                  label={i18next.t(
                    'organizations_web_atoir:clientOrganization',
                  )}
                />

                {/* AT-396 */}
                {/* <FormControlLabel */}
                {/* control={ */}
                {/* <Checkbox */}
                {/* checked={this.state.tradeOrganization} */}
                {/* inputRef={el => { this.tradeOrganization = el }} */}
                {/* onChange={this.organizationTypeChange('tradeOrganization')} */}
                {/* value="tradeOrganization" */}
                {/* color="primary" */}
                {/* /> */}
                {/* } */}
                {/* label={i18next.t('organizations_web_atoir:tradeOrganization')} */}
                {/* /> */}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.serviceOrganization}
                      inputRef={el => {
                        this.serviceOrganization = el
                      }}
                      onChange={this.organizationTypeChange(
                        'serviceOrganization',
                      )}
                      value="serviceOrganization"
                      color="primary"
                    />
                  }
                  label={i18next.t(
                    'organizations_web_atoir:serviceOrganization',
                  )}
                />
              </FormGroup>
              {isActivityValid === false ? (
                <FormHelperText error>
                  {i18next.t(
                    'validation_web_atoir:atLeastOneSpecializationShouldBeChosen',
                  )}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>
        <Subheading title={i18next.t('organizations_web_atoir:address')} />
        <div className={classes.addressDiv} id="phisycalEditLocationComponent">
          {isFetchingPhysicalLocation ? (
            <Loader />
          ) : (
            <EditLocationComponent
              hideLabel
              denyNameEditing
              phisycal
              locationData={physical}
              onChange={this.onDataChanged}
              classes={classes}
            />
          )}
          {isLocationDataValid === false ? (
            <FormHelperText error>
              {i18next.t('validation_web_atoir:incompleteAddress')}
            </FormHelperText>
          ) : null}
        </div>
        {isFetchingLegalLocation ? (
          <Loader />
        ) : (
          <div className={classes.addressDiv} id="legalEditLocationComponent">
            <br />
            <EditLocationComponent
              hideLabel
              denyNameEditing
              legal
              fetchLocation={!!legal}
              locationData={legal}
              onChange={this.onDataChangedLegal}
              classes={classes}
            />
          </div>
        )}
        <br />
        <Subheading
          title={i18next.t('organizations_web_atoir:contactOrgPersonInSystem')}
        />
        {isFetchingContacts ? (
          <Loader />
        ) : (
          <ContactsForm
            ref={this.contactsFormRef}
            contacts={
              contacts ? contacts.sort((a, b) => a.full_name > b.full_name) : []
            }
            handleContactsChanged={this.handleContactsChanged}
            classes={classes}
          />
        )}
        {contactsFormHelperText && !(isContactsValid && contacts.length) > 0 ? (
          <FormHelperText error>
            {i18next.t('organizations_web_atoir:errorContact')}
          </FormHelperText>
        ) : null}
        <Grid container spacing={4} classes="center">
          <Grid item xs={12}>
            <Subheading
              title={i18next.t(
                'organizations_web_atoir:organizationRequisites',
              )}
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:INN')}
              id="organization-INN"
              fullWidth
              inputRef={el => {
                this.INN = el
              }}
              value={INN}
              error={isINNValid === false}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('INN')}
              helperText={
                isINNValid === false &&
                i18next.t(
                  'validation_web_atoir:lengthShouldBeEqualTwelveSymbols',
                )
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:KPP')}
              id="organization-KPP"
              fullWidth
              value={KPP}
              inputRef={el => {
                this.KPP = el
              }}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('KPP')}
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:OGRN')}
              id="organization-OGRN"
              inputRef={el => {
                this.OGRN = el
              }}
              fullWidth
              error={isOGRNValid === false}
              value={OGRN}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('OGRN')}
              helperText={
                isOGRNValid === false &&
                i18next.t(
                  'validation_web_atoir:lengthShouldBeEqualThirteenSymbols',
                )
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:OKPO')}
              id="organization-OKPO"
              inputRef={el => {
                this.OKPO = el
              }}
              error={isOKPOValid === false}
              fullWidth
              value={OKPO}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('OKPO')}
              helperText={
                isOKPOValid === false &&
                i18next.t('validation_web_atoir:lengthShouldBeEqualTenSymbols')
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:bank')}
              id="organization-bank"
              inputRef={el => {
                this.bank = el
              }}
              fullWidth
              value={bank}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('bank')}
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:checkingAccount')}
              inputRef={el => {
                this.rAccount = el
              }}
              id="organization-r_account"
              fullWidth
              value={rAccount}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('rAccount')}
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:correspondentAccount')}
              inputRef={el => {
                this.kAccount = el
              }}
              id="organization-k_account"
              fullWidth
              value={kAccount}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('kAccount')}
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:BIK')}
              inputRef={el => {
                this.BIK = el
              }}
              id="organization-BIK"
              error={isBIKValid === false}
              fullWidth
              value={BIK}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('BIK')}
              helperText={
                isBIKValid === false &&
                i18next.t('validation_web_atoir:lengthShouldBeEqualNineSymbols')
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              label={i18next.t('organizations_web_atoir:director')}
              inputRef={el => {
                this.director = el
              }}
              id="organization-director"
              fullWidth
              value={director}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('director')}
            />
          </Grid>
          <Grid item xs={6} className={classes.paddingGrid}>
            <TextField
              inputRef={el => {
                this.establishment = el
              }}
              label={i18next.t('organizations_web_atoir:establishment')}
              id="organization-establishment"
              fullWidth
              value={establishment}
              className={classNames(classes.margin, classes.textField)}
              onChange={this.onFieldChange('establishment')}
            />
          </Grid>
        </Grid>
        <br />
      </FormDialog>
    )
  }

  render() {
    return <div>{this.renderEditLocationDialog()}</div>
  }
}

EditOrganizationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  isUpdatingOrganization: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
}

export default withStyles(styles)(EditOrganizationComponent)
