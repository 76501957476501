import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CallProfile from './CallProfile'
import {
  fetchCallPermissions,
  fetchServiceDeskCall,
  fetchServiceDeskCallDocs,
  fetchServiceDeskCallOfferDocs,
  fetchServiceDeskCallPhotos,
  updateServiceDeskCall,
  uploadDocServiceDeskCall,
  uploadPhotoServiceDeskCall,
  cancelServiceDeskRequest,
  assignUsingContractRequest,
  closeServiceCallFromNeedrepairRequest,
  sendServiceCallToRepairDiagnosticiansRequest,
  sendCallUsingContract,
  toggleDisableScripts,
  clearOrdersList,
  shouldSetOrderResultReportAction,
  setOrderResultReportingType,
  getServiceCallBlankDocumentLinkRequest,
  deletePhotoServiceDeskCall,
  downloadServiceDeskCallPhotos,
} from '../../../actions/serviceDesk'
import {
  setNavigationDrawer,
  clearNavigationDrawer,
  downloadFileRequest,
} from '../../../actions/appActions'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../actions/utilsActions'
import {
  executeSDCallButtonRequest,
  getSDCallButtonsRequest,
} from '../../../actions/buttonsActions'
import { getSDCallAvailableScriptsRequest } from '../../../actions/scriptActions'

const mapStateToProps = state => ({
  call: state.serviceDesk.request.request,
  isFetchingCall: state.serviceDesk.request.isFetching,
  isDeletingFile: state.serviceDesk.deletingFile.isFetching,
  isDeletingPhoto: state.serviceDesk.deletingPhoto.isFetching,
  isUpdatingRequest: state.serviceDesk.updatingRequest.isFetching,
  isUploadingDoc: state.serviceDesk.uploadingFile.isFetching,
  isFetchingDocs: state.serviceDesk.docs.isFetching,
  docs: state.serviceDesk.docs.files,
  isFetchingOfferDocs: state.serviceDesk.offerDocs.isFetching,
  offerDocs: state.serviceDesk.offerDocs.files,
  isUploadingPhoto: state.serviceDesk.uploadingPhoto.isFetching,
  isFetchingPhotos: state.serviceDesk.photos.isFetching,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  permissions: state.serviceDesk.permissions.permissions,
  permissionsCommon: state.serviceDesk.permissions,
  ordersPermissions: state.serviceDesk.permissions.orders,
  callStatus: state.serviceDesk.permissions.status,
  permissionsError: state.serviceDesk.permissions.error,
  photos: state.serviceDesk.photos.photos,
  isFetchingCancelRequest: state.serviceDesk.cancelRequest.isFetching,
  cancelRequestSuccess: state.serviceDesk.cancelRequest.cancelSuccess,
  cancelRequestError: state.serviceDesk.cancelRequest.error,
  isFetchingAssignUsingContract:
    state.serviceDesk.assignUsingContract.isFetching,
  assignUsingContractError: state.serviceDesk.assignUsingContract.error,
  activeOrders: state.serviceDesk.activeServiceCallOrders.orders || [],
  closedOrders: state.serviceDesk.closedServiceCallOrders.orders || [],
  canceledOrders: state.serviceDesk.canceledServiceCallOrders.orders || [],
  isConfirmingCertificateOfCompletion:
    state.serviceDesk.confirmCertificateOfCompletionWork.isFetching,
  isRejectingCertificateOfCompletion:
    state.serviceDesk.rejectOrderCertificateOfCompletion.isRejecting,
  rejectedCertificateOfCompletionSuccess:
    state.serviceDesk.rejectOrderCertificateOfCompletion.rejectedSuccessfully,
  isAcceptingCertificateOfCompletion:
    state.serviceDesk.acceptOrderCertificateOfCompletion.isAccepting,
  acceptedCerificateOfCompletionSuccess:
    state.serviceDesk.acceptOrderCertificateOfCompletion.acceptedSuccessfully,
  isClosingCallFromNeedrepair:
    state.serviceDesk.closeCallFromNeedrepair.isClosing,
  closeCallFromNeedrepairSuccess:
    state.serviceDesk.closeCallFromNeedrepair.closeSuccess,
  isSendingCallToRepairDiagnosticians:
    state.serviceDesk.sendCallToRepairDiagnosticians.isSending,
  sendCallToRepairDiagnosticiansSuccess:
    state.serviceDesk.sendCallToRepairDiagnosticians.sendSuccess,
  isAssigningOrderToOwnSpecialist:
    state.serviceDesk.assigningOrderOwnSpecialist.isFetching,
  isSendingOrderToSuborder: state.serviceDesk.sendOrderToSubOrder.isFetching,
  isRejectingOrder: state.serviceDesk.rejectOrder.isFetching,
  rejectOrderSuccess: state.serviceDesk.rejectOrder.fetchSuccess,
  isSendingCallUsingContract:
    state.serviceDesk.sendCallUsingContract.isFetching,
  sendCallUsingContractSuccess:
    state.serviceDesk.sendCallUsingContract.sendSuccess,
  sendCallUsingContractError: state.serviceDesk.sendCallUsingContract.error,
  isCancellingOrder: state.serviceDesk.cancelOrder.isFetching,
  isFetchingCallPossibleReports:
    state.serviceDesk.callPossibleReports.isFetching,
  callPossibleReportsCount: state.serviceDesk.callPossibleReports.count,
  callPossibleReportsNames: state.serviceDesk.callPossibleReports.names,
  callPossibleReportsError: state.serviceDesk.callPossibleReports.error,
  callButtons: state.buttons.getSDCallButtons.callButtons,
  orderButtons: state.buttons.getSDCallButtons.orderButtons,
  isExecutingButtonAction: state.buttons.executeSDCallButton.isFetching,
  disableScriptsForIds: state.serviceDesk.disableScriptsForIds,
  callAvailableScripts: state.scripts.getSDCallAvailableScripts.scripts,
  isSettingOrderResultType: state.serviceDesk.setOrderResultType.isSetting,
  orderResultReportingType: state.serviceDesk.orderResultReportingType,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceDeskCallDocs,
      fetchServiceDeskCallOfferDocs,
      uploadDocServiceDeskCall,
      fetchServiceDeskCallPhotos,
      uploadPhotoServiceDeskCall,
      fetchCallPermissions,
      fetchServiceDeskCall,
      showDialogLoader,
      hideDialogLoader,
      updateServiceDeskCall,
      cancelServiceDeskRequest,
      assignUsingContractRequest,
      setNavigationDrawer,
      clearNavigationDrawer,
      closeServiceCallFromNeedrepairRequest,
      sendServiceCallToRepairDiagnosticiansRequest,
      sendCallUsingContract,
      downloadFileRequest,
      executeSDCallButtonRequest,
      getSDCallButtonsRequest,
      toggleDisableScripts,
      getSDCallAvailableScriptsRequest,
      clearOrdersList,
      shouldSetOrderResultReportAction,
      setOrderResultReportingType,
      getServiceCallBlankDocumentLinkRequest,
      deletePhotoServiceDeskCall,
      downloadServiceDeskCallPhotos,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CallProfile),
)
