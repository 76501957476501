import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import FormComponent from '../../../widgets/FormComponent/index'
import PrimaryButton from '../../../widgets/Buttons/ContainedButton'
import LoginFormWrapper from '../LoginFormWrapper'
import AgreementTextWithFile from './AgreementTextWithFile'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'

const propTypes = {
  isActivatingAccount: PropTypes.bool.isRequired,
  registerAccount: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  agreementBox: {
    display: 'flex',
    alignItems: 'center',
  },
  agreementCheckboxBox: {
    marginLeft: -12,
  },
})

class AccountActivationComponent extends Component {
  state = {
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    agreementTreatmentPersonalInfo: false,
    errors: {},
  }

  componentDidMount = () => {
    const { isLoggedIn } = this.props
    if (isLoggedIn) this.props.history.push('/profile')
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isActivatingAccount !== this.props.isActivatingAccount &&
      this.props.isActivatingAccount === false &&
      isEmpty(this.props.validationErrors)
    ) {
      this.props.history.push('/profile')
    }
    if (
      prevProps.isRegisteringEmail !== this.props.isRegisteringEmail &&
      !this.props.isRegisteringEmail &&
      isEmpty(this.props.validationErrors)
    ) {
      this.props.history.push('/email_sent')
      this.props.closeErrorMessage()
    }
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleCheckboxChange = () => {
    const { agreementTreatmentPersonalInfo } = this.state

    this.setState({
      agreementTreatmentPersonalInfo: !agreementTreatmentPersonalInfo,
    })
  }

  getConfirmLinkAgain = () => {
    const { resendLinkEmail, getConfirmLinkAgain } = this.props
    getConfirmLinkAgain(resendLinkEmail.toLowerCase().trim())
  }

  render() {
    const { errors } = this.state
    const {
      classes,
      isActivatingAccount,
      validationErrors,
      canContinueRegistration,
      resendLinkEmail,
    } = this.props

    const lastNameHelperText = !isEmpty(errors.lastName)
      ? errors.lastName
      : !isEmpty(validationErrors.lastName)
      ? validationErrors.lastName[0]
      : ''

    const firstNameHelperText = !isEmpty(errors.firstName)
      ? errors.firstName
      : !isEmpty(validationErrors.firstName)
      ? validationErrors.firstName[0]
      : ''

    const passwordHelperText = !isEmpty(errors.password)
      ? errors.password
      : !isEmpty(validationErrors.password)
      ? validationErrors.password[0]
      : ''

    const passwordConfirmHelperText = !isEmpty(errors.passwordConfirm)
      ? errors.passwordConfirm
      : !isEmpty(validationErrors.passwordConfirm)
      ? validationErrors.passwordConfirm[0]
      : ''

    const agreementHelperText = !isEmpty(errors.agreementTreatmentPersonalInfo)
      ? errors.agreementTreatmentPersonalInfo
      : !isEmpty(validationErrors.agreementTreatmentPersonalInfo)
      ? validationErrors.agreementTreatmentPersonalInfo[0]
      : ''

    return canContinueRegistration ? (
      <LoginFormWrapper
        title={i18next.t('login_web_atoir:registerAccountTitle')}
      >
        <FormComponent
          titleForm={i18next.t('login_web_atoir:systemRegistration')}
          submitText={i18next.t('login_web_atoir:registerAccount')}
          isLoading={isActivatingAccount}
          handleSubmitAction={this.handleSubmitForm}
        >
          <FormGroup>
            <TextField
              error={
                !isEmpty(errors.lastName) || !isEmpty(validationErrors.lastName)
              }
              required
              id="lastName"
              label={i18next.t('login_web_atoir:lastName')}
              placeholder={i18next.t('login_web_atoir:enterLastName')}
              className={classes.textField}
              autoComplete="family-name"
              helperText={lastNameHelperText}
              margin="normal"
              onChange={this.handleTextChange('lastName')}
              value={this.state.lastName}
            />
            <TextField
              error={
                !isEmpty(errors.firstName) ||
                !isEmpty(validationErrors.firstName)
              }
              required
              id="firstName"
              label={i18next.t('login_web_atoir:firstName')}
              placeholder={i18next.t('login_web_atoir:enterFirstName')}
              className={classes.textField}
              helperText={firstNameHelperText}
              autoComplete="given-name"
              margin="normal"
              onChange={this.handleTextChange('firstName')}
              value={this.state.firstName}
            />
            <TextField
              id="middleName"
              label={i18next.t('login_web_atoir:middleName')}
              placeholder={i18next.t('login_web_atoir:enterMiddleName')}
              className={classes.textField}
              autoComplete="additional-name"
              margin="normal"
              onChange={this.handleTextChange('middleName')}
              value={this.state.middleName}
            />
            <TextField
              error={
                !isEmpty(errors.password) || !isEmpty(validationErrors.password)
              }
              required
              id="password"
              type="password"
              label={i18next.t('login_web_atoir:password')}
              placeholder={i18next.t('login_web_atoir:enterPassword')}
              autoComplete="new-password"
              className={classes.textField}
              helperText={passwordHelperText}
              margin="normal"
              onChange={this.handleTextChange('password')}
              value={this.state.password}
            />
            <TextField
              error={
                !isEmpty(errors.passwordConfirm) ||
                !isEmpty(validationErrors.passwordConfirm)
              }
              required
              id="passwordConfirm"
              type="password"
              label={i18next.t('login_web_atoir:passwordConfirmation')}
              placeholder={i18next.t(
                'login_web_atoir:enterPasswordConfirmation',
              )}
              autoComplete="new-password"
              className={classes.textField}
              helperText={passwordConfirmHelperText}
              margin="normal"
              onChange={this.handleTextChange('passwordConfirm')}
              value={this.state.passwordConfirm}
            />
            <br />
            <div className={classes.agreementBox}>
              <Checkbox
                className={classes.agreementCheckboxBox}
                checked={this.state.agreementTreatmentPersonalInfo}
                onChange={this.handleCheckboxChange}
                color="primary"
              />
              <AgreementTextWithFile />
            </div>
            <FormHelperText
              error={
                !isEmpty(errors.agreementTreatmentPersonalInfo) ||
                !isEmpty(validationErrors.agreementTreatmentPersonalInfo)
              }
            >
              {agreementHelperText}
            </FormHelperText>
          </FormGroup>
        </FormComponent>
      </LoginFormWrapper>
    ) : resendLinkEmail ? (
      <div style={{ textAlign: 'center' }}>
        <PrimaryButton onClick={this.getConfirmLinkAgain}>
          {i18next.t('login_web_atoir:sendLinkAgain')}
        </PrimaryButton>
      </div>
    ) : null
  }

  handleSubmitForm = () => {
    const { registerAccount } = this
    registerAccount()
  }

  registerAccount = () => {
    const {
      firstName,
      middleName,
      lastName,
      password,
      passwordConfirm,
      agreementTreatmentPersonalInfo,
    } = this.state

    const emptyErrors = this.validateEmptyFields(
      firstName.trim(),
      lastName.trim(),
      password,
      passwordConfirm,
      agreementTreatmentPersonalInfo,
    )
    const passwordErrors = this.validateNewPasswords(password, passwordConfirm)

    if (!isEmpty(emptyErrors)) {
      this.setState({
        errors: emptyErrors,
      })
      return
    }
    if (!isEmpty(passwordErrors)) {
      this.setState({
        errors: passwordErrors,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const { registerAccount } = this.props
    const data = {
      first_name: firstName.trim(),
      middle_name: middleName.trim(),
      last_name: lastName.trim(),
      password,
      password_confirm: passwordConfirm,
      agreement_treatment_personal_info: agreementTreatmentPersonalInfo,
    }

    registerAccount(data)
  }

  validateEmptyFields = (
    firstName,
    lastName,
    password,
    passwordConfirm,
    agreementTreatmentPersonalInfo,
  ) => {
    const errors = {}
    if (isEmpty(firstName)) {
      errors.firstName = i18next.t(
        'validation_web_atoir:firstNameShouldBeFilled',
      )
    }
    if (isEmpty(lastName)) {
      errors.lastName = i18next.t('validation_web_atoir:lastNameShouldBeFilled')
    }
    if (isEmpty(password)) {
      errors.password = i18next.t('validation_web_atoir:passwordShouldBeFilled')
    }
    if (isEmpty(passwordConfirm)) {
      errors.passwordConfirm = i18next.t(
        'validation_web_atoir:passwordConfirmShouldBeFilled',
      )
    }
    if (!agreementTreatmentPersonalInfo) {
      errors.agreementTreatmentPersonalInfo = i18next.t(
        'validation_web_atoir:shouldConsentPersonalDataProcessing',
      )
    }
    return errors
  }

  validateNewPasswords = (password, passwordConfirm) => {
    const errors = {}
    if (password !== passwordConfirm) {
      errors.passwordConfirm = i18next.t(
        'validation_web_atoir:passwordConfirmationNotMatch',
      )
    }
    return errors
  }
}

AccountActivationComponent.propTypes = propTypes

export default withStyles(styles)(AccountActivationComponent)
