import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Settings from './Settings'
import {
  fetchNotificationSettings,
  setNotificationSetting,
} from '../../../../../actions/userActions'

const mapStateToProps = state => ({
  settings: state.user.notificationSettings.settings,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNotificationSettings,
      setSetting: setNotificationSetting,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings),
)
