import React, { Component } from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper/Paper'
import Table from '@material-ui/core/Table/Table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Loader from '../../../../Loader'

// helpers
import { getUserFullName } from '../../../../../helpers/userHelper'
import { dateFormat, convertTimestamp } from '../../../../../helpers/dateHelper'

const toolbarStyles = theme => ({
  toolbarRoot: {},
  toolbarText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
})

let EnhancedTableToolbar = props => (
  <div className={props.classes.toolbarRoot}>
    <div className={props.classes.toolbarText}>
      {props.isFetchingProject ? (
        <Loader />
      ) : (
        <Typography align="left" variant="h5" component="span">
          {`${i18next.t('inventory_web_atoir:projectInventory')}\
         ${i18next.t('shared_web_atoir:unitFrom')} ${dateFormat(props.onDate)}`}
        </Typography>
      )}
      <Typography align="left" variant="h6" component="span">
        {i18next.t(`inventory_web_atoir:${props.projectStatus}`)}
      </Typography>
    </div>
    <Divider />
  </div>
)

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  onDate: PropTypes.instanceOf(Date).isRequired,
  projectStatus: PropTypes.string.isRequired,
  isFetchingProject: PropTypes.bool.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const headerStyles = () => ({
  headerCell: {
    border: 0,
  },
})

let EnhancedTableHead = props => (
  <TableHead>
    <TableRow>
      <TableCell className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:changedWhen')}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:changedWho')}
      </TableCell>
      <TableCell className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:changedWhat')}
      </TableCell>
    </TableRow>
  </TableHead>
)

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class InventoryProjectChangeLog extends Component {
  static state = {
    page: 0,
    rowsPerPage: 50,
  }

  componentDidMount = () => {
    const {
      fetchInventoryProjectProfile,
      match: {
        params: { projectId },
      },
    } = this.props
    fetchInventoryProjectProfile(projectId)
    this.handleFetchHistory()
    this.handleFetchHistoryCount()
  }

  handleFetchHistoryCount = () => {
    const {
      fetchHistoryCount,
      match: {
        params: { projectId },
      },
    } = this.props
    fetchHistoryCount(projectId)
  }

  handleFetchHistory = () => {
    const {
      fetchHistory,
      match: {
        params: { projectId },
      },
    } = this.props
    const { page, rowsPerPage } = this.state
    fetchHistory(projectId, { limit: rowsPerPage, offset: rowsPerPage * page })
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.handleFetchHistory)
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, this.handleFetchHistory)
  }

  render() {
    const {
      props: {
        classes,
        isFetchingHistory,
        isFetchingProject,
        isFetchingHistoryCount,
        history,
        historyCount,
        date,
        status,
      },
      state: { page, rowsPerPage },
    } = this
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          onDate={date}
          projectStatus={status}
          isFetchingProject={isFetchingProject}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            {isFetchingHistory || isFetchingHistoryCount ? (
              <Loader />
            ) : (
              <TableBody>
                {history &&
                  history.map((h, i) => (
                    <TableRow key={h._id} selected={!!(i % 2)}>
                      <TableCell>{convertTimestamp(h.date)}</TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {getUserFullName(h.actor_organization_user)}
                        </Typography>
                        <Typography color="default" variant="caption">
                          {h.organization.short_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{h.comment}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </div>
        <TablePagination
          component="div"
          rowsPerPageOptions={[25, 50, 100]}
          count={historyCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
          labelDisplayedRows={({
            from: fromPage,
            to: toPage,
            count: countPage,
          }) =>
            i18next.t('shared_web_atoir:labelDisplayedRows', {
              fromPage,
              toPage,
              countPage,
            })
          }
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(InventoryProjectChangeLog)
