import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import OutlinedButton from '../../../../../../../widgets/Buttons/OutlinedButton'
import i18next from '../../../../../../../i18n/i18n'
import ContainedButton from '../../../../../../../widgets/Buttons/ContainedButton'
import { makeRecurringFromCallRequest } from '../../../../../../../actions/serviceDesk'
import { useCloseAfterSuccess, useDisabled, useRecurringForm } from '../hooks'
import Form from '../form'

const MakeRecurringDialog = ({ open, handleClose, callId }) => {
  const {
    intervalUnit,
    intervalValue,
    startDate,
    endDate,
    onChangeIntervalValue,
    onChangeIntervalUnit,
    onChangeStartDate,
    onChangeEndDate,
  } = useRecurringForm({})
  const dispatch = useDispatch()
  const { isFetching: isMaking, error } = useSelector(
    state => state.serviceDesk.makeRecurringFromCall,
  )
  useCloseAfterSuccess(isMaking, error, callId, handleClose)
  const makeRecurring = useCallback(
    () =>
      dispatch(
        makeRecurringFromCallRequest({
          callId,
          intervalUnit,
          intervalValue,
          startDate,
          endDate,
        }),
      ),
    [
      makeRecurringFromCallRequest,
      dispatch,
      intervalUnit,
      intervalValue,
      startDate,
      endDate,
    ],
  )
  const disabled = useDisabled(intervalValue, startDate, endDate)
  return (
    <Dialog open={!isMaking && open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        {i18next.t('service_desk_web_atoir:create_recurring_call')}
      </DialogTitle>
      <DialogContent dividers>
        <Form
          intervalUnit={intervalUnit}
          intervalValue={intervalValue}
          startDate={startDate}
          endDate={endDate}
          onChangeIntervalValue={onChangeIntervalValue}
          onChangeIntervalUnit={onChangeIntervalUnit}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={handleClose} color="primary">
          {i18next.t('shared_web_atoir:cancel')}
        </OutlinedButton>
        <ContainedButton
          color="primary"
          onClick={makeRecurring}
          disabled={disabled}
        >
          {i18next.t('shared_web_atoir:add')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default MakeRecurringDialog
