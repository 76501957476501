import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import NoMatchComponent from '../../NoMatchComponent'
import BreadCrumb from './BreadCrumb'
import TrustedOrganizations from '../../TrustedOrganizations'

const TrustedOrganizationsRouter = ({ match: { path } }) => (
  <Fragment>
    <Switch>
      <WrapperRoute
        path={`${path}/`}
        component={({ match: { url, path: _path } }) => (
          <Fragment>
            <BreadCrumb to={url}>
              {i18next.t('shared_web_atoir:trustedOrganizations')}
            </BreadCrumb>
            <Switch>
              <WrapperRoute
                path={`${_path}`}
                component={TrustedOrganizations}
                showHeader
                title={i18next.t('inner_calls_web_atoir:trustedOrganizations')}
              />
            </Switch>
          </Fragment>
        )}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default TrustedOrganizationsRouter
