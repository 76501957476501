import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Sticky from 'react-stickynode'
import classNames from 'classnames'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton/IconButton'
import TuneIcon from '@material-ui/icons/Tune'
import Badge from '@material-ui/core/Badge'
import Collapse from '@material-ui/core/Collapse'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { lighten } from '@material-ui/core/styles/colorManipulator'

// components
import BreadCrumb from '../../../App/routing/BreadCrumb'
import SelectLimited from '../../../../widgets/SelectLimited'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import UpScrollButton from '../../../../widgets/Buttons/UpScrollButton'
import Loader from '../../../Loader'
import TextButton from '../../../../widgets/Buttons/TextButton'
import ConfirmExitDialog from '../Widgets/ConfirmExitDialog'

import EquipmentsList from '../Widgets/EquipmentsList'
import RightTips from '../Widgets/RightTips'
import ContractDetails from '../Widgets/ContractDetails'

// helpers
import { inventoryLocationStringifyDefault } from '../../../../helpers/parserHelper'
import { popLastSections } from '../../../../helpers/dataHelper'

// i18n
import i18next from '../../../../i18n/i18n'

const prepareDepartmentList = department => {
  const out = department.map(item => {
    const cur = { id: item.id, title: item.name }
    return cur
  })
  return out
}

const prepareAddressList = address => {
  const out = address.map(item => {
    const curAddr = {
      id: item.id,
      title: inventoryLocationStringifyDefault(item),
    }
    return curAddr
  })
  return out
}

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  gridHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  gridCollapsedFilters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  divCollapsedDivider: {
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: `${lighten(theme.palette.secondary.light, 0.85)}`,
  },
  paper: {
    backgroundColor: '#fafafa',
  },
  badgeColorRoot: {
    color: 'black',
  },
  badgeColorPrimary: {
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
  },
  textField: {
    width: '100%',
  },
  hideClass: {
    display: 'none',
  },
  divHeader: {
    backgroundColor: 'white',
    borderBottom: theme.typography.pxToRem(1),
    borderBottomStyle: 'solid',
    borderBottomColor: lighten(theme.palette.secondary.light, 0.85),
  },
  card: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  middleText: {
    marginBottom: 12,
    fontSize: 12,
  },
  topText: {
    marginBottom: 8,
    fontSize: 12,
  },
})

const TipCard = withRouter(
  withStyles(styles)(({ classes, history, match: { url } }) => (
    <Card className={classes.card}>
      <CardActionArea
        onClick={() => {
          history.push(popLastSections(url))
        }}
      >
        <CardContent>
          <Typography component="p" className={classes.topText}>
            {i18next.t('service_desk_web_atoir:deleteEquipment')}
          </Typography>
          <Typography
            component="p"
            className={classes.middleText}
            color="textSecondary"
          >
            {i18next.t('service_desk_web_atoir:deleteEquipmentTip')}
          </Typography>
          <Typography component="p">
            {i18next.t('service_desk_web_atoir:toProfile')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )),
)

const extendData = data => {
  const resData = []
  if (data === null || data.length === 0) return []
  let currAddr = data[0].address
  resData.push({ typeRow: 'addressRow', currAddr, id: currAddr })
  data.forEach(item => {
    if (item.address !== currAddr) {
      currAddr = item.address
      resData.push({ typeRow: 'addressRow', currAddr, id: currAddr })
    }
    resData.push(item)
  })
  return resData
}

class AddEquipment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      checked: [],
      filters: {},
      filtersCount: 0,
      offset: 0,
      page: 0,
      commonFilterLimit: 30,
      addressFilterPage: 0,
      locationFilterDataIsFetching: false,
      addressFilterData: [],
      departmentFilterPage: 0,
      departmentFilterDataIsFetching: false,
      departmentFilterData: [],
      isOpenConfirmExitDialog: false,
      waitForExit: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateDiff = {}
    // department loading
    if (
      prevState.departmentFilterDataIsFetching !==
        nextProps.departmentFilterDataIsFetching &&
      nextProps.departmentFilterDataIsFetching
    ) {
      stateDiff.departmentFilterDataIsFetching =
        nextProps.departmentFilterDataIsFetching
    }
    if (
      prevState.departmentFilterDataIsFetching !==
        nextProps.departmentFilterDataIsFetching &&
      !nextProps.departmentFilterDataIsFetching
    ) {
      const {
        departmentFilterData,
        commonFilterLimit,
        departmentFilterPage,
      } = prevState
      stateDiff.departmentFilterDataIsFetching =
        nextProps.departmentFilterDataIsFetching
      stateDiff.departmentFilterData = departmentFilterData.concat(
        prepareDepartmentList(nextProps.departmentFilterData),
      )
      stateDiff.departmentFilterPage = departmentFilterPage + 1
      if (nextProps.departmentFilterData.length < commonFilterLimit) {
        stateDiff.departmentFilterDataIsEnd = true
      }
    }
    // location filter loading
    if (
      prevState.locationFilterDataIsFetching !==
        nextProps.locationFilterDataIsFetching &&
      nextProps.locationFilterDataIsFetching
    ) {
      stateDiff.locationFilterDataIsFetching =
        nextProps.locationFilterDataIsFetching
    }
    if (
      prevState.locationFilterDataIsFetching !==
        nextProps.locationFilterDataIsFetching &&
      !nextProps.locationFilterDataIsFetching
    ) {
      const {
        addressFilterData,
        commonFilterLimit,
        addressFilterPage,
      } = prevState
      stateDiff.locationFilterDataIsFetching =
        nextProps.locationFilterDataIsFetching
      stateDiff.addressFilterData = addressFilterData.concat(
        prepareAddressList(nextProps.locationFilterData),
      )
      stateDiff.addressFilterPage = addressFilterPage + 1
      if (nextProps.locationFilterData.length < commonFilterLimit) {
        stateDiff.addressFilterDataIsEnd = true
      }
    }

    if (!_.isEmpty(stateDiff)) {
      return stateDiff
    }
    return null
  }

  componentDidMount = () => {
    const {
      props: {
        match: { params },
        fetchContractRequest,
      },
    } = this
    fetchContractRequest(params.contractId)
  }

  componentDidUpdate = prevProps => {
    const {
      state: { waitForExit },
      props: {
        contractIsFetching,
        contractFetchingError,
        availableEquipmentIsFetching,
        availableEquipmentFetchingError,
        equipmentCountIsFetching,
        equipmentCountFetchingError,
        equipmentAddIsFetching,
        equipmentAddError,
        hideDialogLoader,
        history,
        match: { url },
        limit,
      },
      prepareData,
      loadEquipmentData,
      loadFilterDepartmentDataNext,
      loadFilterLocationDataNext,
      loadEquipmentCount,
    } = this
    // contract data loaded
    if (
      prevProps.contractIsFetching !== contractIsFetching &&
      !contractIsFetching &&
      !contractFetchingError
    ) {
      loadEquipmentCount()
      loadFilterDepartmentDataNext()
      loadFilterLocationDataNext()
    }
    // availableEquipmentCount loaded
    if (
      prevProps.equipmentCountIsFetching !== equipmentCountIsFetching &&
      !equipmentCountIsFetching &&
      !equipmentCountFetchingError
    ) {
      loadEquipmentData()
    }
    // availableEquipment loaded
    if (
      prevProps.availableEquipmentIsFetching !== availableEquipmentIsFetching &&
      !availableEquipmentIsFetching &&
      !availableEquipmentFetchingError
    ) {
      prepareData()
      hideDialogLoader()
    }
    // ready for exit
    if (
      prevProps.equipmentAddIsFetching !== equipmentAddIsFetching &&
      !equipmentAddIsFetching &&
      !equipmentAddError &&
      waitForExit
    ) {
      history.push(popLastSections(url))
    }
    // equipments added to contract
    if (
      prevProps.equipmentAddIsFetching !== equipmentAddIsFetching &&
      !equipmentAddIsFetching &&
      !equipmentAddError
    ) {
      loadEquipmentCount()
      loadFilterDepartmentDataNext()
      loadFilterLocationDataNext()
    }
    // failed to add equipments
    if (
      prevProps.equipmentAddIsFetching !== equipmentAddIsFetching &&
      !equipmentAddIsFetching &&
      equipmentAddError
    ) {
      hideDialogLoader()
    }
    // rows per page changed
    if (prevProps.limit !== limit) {
      loadEquipmentCount()
    }
  }

  loadFilterDepartmentDataNext = () => {
    const {
      state: { departmentFilterPage, commonFilterLimit },
      props: { contract, fetchFilterDepartment },
    } = this

    fetchFilterDepartment({
      organizationId: contract.cl_organization.id,
      payload: {
        offset: departmentFilterPage * commonFilterLimit,
        limit: commonFilterLimit,
      },
    })
  }

  loadFilterLocationDataNext = () => {
    const {
      state: { addressFilterPage, commonFilterLimit },
      props: { contract, fetchFilterLocation },
    } = this
    fetchFilterLocation({
      organizationId: contract.cl_organization.id,
      payload: {
        offset: addressFilterPage * commonFilterLimit,
        limit: commonFilterLimit,
      },
    })
  }

  nextFilterOffset = filter => () => {
    const {
      props: { departmentFilterDataIsFetching, locationFilterDataIsFetching },
      state: { addressFilterDataIsEnd, departmentFilterDataIsEnd },
      loadFilterLocationDataNext,
      loadFilterDepartmentDataNext,
    } = this
    if (
      filter === 'address' &&
      !addressFilterDataIsEnd &&
      !locationFilterDataIsFetching
    ) {
      loadFilterLocationDataNext()
    }
    if (
      filter === 'department' &&
      !departmentFilterDataIsEnd &&
      !departmentFilterDataIsFetching
    ) {
      loadFilterDepartmentDataNext()
    }
  }

  loadEquipmentCount = () => {
    const {
      props: { fetchCount, contract, limit },
      state: { offset, filters },
    } = this
    fetchCount({
      clientOrganizationId: contract.cl_organization.id,
      payload: {
        addToContract: contract.id,
        limit,
        offset,
        ...filters,
      },
    })
  }

  loadEquipmentData = () => {
    const {
      props: { fetchContractAvailableEquipmentRequest, contract, limit },
      state: { offset, filters },
    } = this
    fetchContractAvailableEquipmentRequest({
      clientOrganizationId: contract.cl_organization.id,
      payload: {
        addToContract: contract.id,
        limit,
        offset,
        ...filters,
      },
    })
  }

  handleChange = () => {
    this.setState(state => ({ checkedSwitch: !state.checkedSwitch }))
  }

  prepareData = () => {
    const {
      props: { availableEquipment },
    } = this

    let newData = availableEquipment.map(row => {
      const {
        name,
        model,
        OrganizationDepartment,
        OrganizationLocation,
        external_id: externalId,
        vendor,
        id,
      } = row
      const outRow = {
        id,
        name,
        model,
        externalId,
        vendor,
      }
      outRow.postIndex = OrganizationLocation.post_index
      outRow.address = inventoryLocationStringifyDefault(OrganizationLocation)
      if (OrganizationDepartment)
        outRow.department = OrganizationDepartment.name
      return outRow
    })
    newData = extendData(newData)
    this.setState({ data: newData })
  }

  checkedChange = id => {
    const {
      state: { checked },
    } = this
    const pos = checked.indexOf(id)
    if (pos !== -1) {
      checked.splice(pos, 1)
    } else {
      checked.push(id)
    }
    this.setState(checked)
  }

  handleSelectAllClick = event => {
    const { availableEquipment } = this.props
    if (event.target.checked) {
      this.setState(() => ({ checked: availableEquipment.map(n => n.id) }))
      return
    }
    this.setState({ checked: [] })
  }

  filtersHandler = field => event => {
    const { value } = event.target
    if (value !== '') {
      this.setState({
        [`${field}FilterValue`]: value,
      })
    } else {
      this.setState({ [`${field}FilterValue`]: undefined })
    }
  }

  filtersLimitedHandler = field => value => {
    this.setState({ [`${field}FilterValue`]: value })
  }

  applyFilters = () => {
    const {
      departmentFilterValue,
      addressFilterValue,
      nameFilterValue,
      vendorFilterValue,
      modelFilterValue,
      extIdFilterValue,
    } = this.state

    const filters = {}
    if (departmentFilterValue) filters.department = departmentFilterValue
    if (addressFilterValue) filters.location = addressFilterValue
    if (nameFilterValue) filters.name = nameFilterValue
    if (vendorFilterValue) filters.vendor = vendorFilterValue
    if (modelFilterValue) filters.model = modelFilterValue
    if (extIdFilterValue) filters.externalId = extIdFilterValue

    const filtersCount = Object.keys(filters).length
    this.setState({ filtersCount, filters }, () => {
      this.loadEquipmentCount()
    })
  }

  paginationChange = field => (event, page) => {
    const {
      props: { limit, setAddContractsEquipmentRowsPerPage },
      loadEquipmentData,
    } = this
    const newData = {}
    if (field === 'page') {
      newData.offset = page * limit
      newData.page = page
      this.setState(
        {
          ...newData,
          checked: [],
        },
        () => {
          loadEquipmentData()
        },
      )
    }
    if (field === 'rowsPerPage') {
      setAddContractsEquipmentRowsPerPage(event.target.value)
    }
  }

  addToContracts = () => {
    const {
      state: { checked },
      props: {
        match: { params },
        addEquipmentToContractsRequest,
        showDialogLoader,
      },
    } = this
    showDialogLoader()
    addEquipmentToContractsRequest({
      contractId: params.contractId,
      payload: {
        equipmentsIds: checked,
      },
    })
    this.setState({ checked: [] })
  }

  render() {
    const {
      props: {
        classes,
        match,
        availableEquipment,
        equipmentCount,
        locationFilterDataIsFetching,
        departmentFilterDataIsFetching,
        contract,
        availableEquipmentIsFetching,
        match: { url },
        history,
        limit,
      },
      state: {
        data,
        checked,
        checkedSwitch,
        filtersCount,
        page,
        departmentFilterValue,
        addressFilterValue,
        addressFilterData,
        departmentFilterData,
        isOpenConfirmExitDialog,
      },
      filtersHandler,
      checkedChange,
      handleSelectAllClick,
      applyFilters,
      nextFilterOffset,
      paginationChange,
      filtersLimitedHandler,
      addToContracts,
    } = this

    return (
      <Fragment>
        {contract && (
          <BreadCrumb to={popLastSections(url)}>
            {i18next.t('service_desk_web_atoir:contractProfileTitle', {
              number: contract.num_contract,
              from: `${contract.month_begin}.${contract.year_begin}`,
              to: `${contract.month_end}.${contract.year_end}`,
            })}
          </BreadCrumb>
        )}
        <BreadCrumb to={match.url}>
          {i18next.t('service_desk_web_atoir:addEquipmentToContract')}
        </BreadCrumb>
        <div>
          <UpScrollButton />
          <RightTips>
            <TipCard />
          </RightTips>
          <ContractDetails
            match={{ url }}
            title={i18next.t('service_desk_web_atoir:addEquipmentToContract')}
            footerText={i18next.t(
              'service_desk_web_atoir:addEquipmentToContractText',
            )}
            contractData={contract}
          >
            <TextButton
              onClick={() => {
                if (checked.length === 0) history.push(popLastSections(url))
                else this.setState({ isOpenConfirmExitDialog: true })
              }}
            >
              {i18next.t('shared_web_atoir:close')}
            </TextButton>
          </ContractDetails>
          <Sticky enabled top={0} innerZ="1">
            <div className={classes.divHeader}>
              <Grid container className={classes.gridHeader}>
                <Grid item xs={12} sm={5}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      indeterminate={
                        checked.length > 0 &&
                        checked.length < availableEquipment.length
                      }
                      checked={checked.length === availableEquipment.length}
                      onChange={handleSelectAllClick}
                    />
                    <Typography variant="subheading">
                      {checked.length > 0
                        ? `${checked.length} ${i18next.t(
                            'service_desk_web_atoir:selectedCaption',
                          )}`
                        : i18next.t('service_desk_web_atoir:equipment')}
                    </Typography>
                  </div>
                </Grid>
                <Grid container xs={12} sm={7} justify="flex-end">
                  {checked.length > 0 && (
                    <OutlinedButton onClick={addToContracts}>
                      <AddIcon />
                      {i18next.t(
                        'service_desk_web_atoir:toAddEquipmentToContract',
                      )}
                    </OutlinedButton>
                  )}
                  <IconButton onClick={this.handleChange}>
                    <Badge
                      classes={{
                        root: classes.badgeColorRoot,
                        colorPrimary: classNames(classes.badgeColorPrimary, {
                          [classes.hideClass]: filtersCount === 0,
                        }),
                      }}
                      color="primary"
                      badgeContent={filtersCount}
                    >
                      <TuneIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={checkedSwitch}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.divCollapsedDivider} />
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    className={classes.gridCollapsedFilters}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="name"
                        onChange={filtersHandler('name')}
                        className={classes.textField}
                        label={i18next.t('service_desk_web_atoir:captionName')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="vendor"
                        onChange={filtersHandler('vendor')}
                        className={classes.textField}
                        label={i18next.t(
                          'service_desk_web_atoir:captionVendor',
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="model"
                        onChange={filtersHandler('model')}
                        className={classes.textField}
                        label={i18next.t('service_desk_web_atoir:captionModel')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="extId"
                        onChange={filtersHandler('extId')}
                        className={classes.textField}
                        label={i18next.t('service_desk_web_atoir:captionExtId')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl className={classes.textField}>
                        <InputLabel
                          shrink={departmentFilterValue}
                          htmlFor="department-filter"
                        >
                          {i18next.t(
                            'service_desk_web_atoir:captionDepartment',
                          )}
                        </InputLabel>
                        <SelectLimited
                          id="department-filter"
                          className={classes.textField}
                          onEndOfScroll={nextFilterOffset('department')}
                          isDataFetch={departmentFilterDataIsFetching}
                          onChange={filtersLimitedHandler('department')}
                          value={departmentFilterValue}
                          allowEmpty
                          customTitleForEmpty={i18next.t(
                            'service_desk_web_atoir:captionAll',
                          )}
                          inputProps={{
                            name: 'departmentFilter',
                            id: 'department-filter',
                          }}
                          data={departmentFilterData}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl className={classes.textField}>
                        <InputLabel
                          shrink={addressFilterValue}
                          htmlFor="location-filter"
                        >
                          {i18next.t('service_desk_web_atoir:captionAddress')}
                        </InputLabel>
                        <SelectLimited
                          id="location"
                          className={classes.textField}
                          onEndOfScroll={nextFilterOffset('address')}
                          isDataFetch={locationFilterDataIsFetching}
                          onChange={filtersLimitedHandler('address')}
                          value={addressFilterValue}
                          allowEmpty
                          customTitleForEmpty={i18next.t(
                            'service_desk_web_atoir:captionAll',
                          )}
                          inputProps={{
                            name: 'locationFilter',
                            id: 'location-filter',
                          }}
                          data={addressFilterData}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container justify="flex-end" xs={12}>
                      <OutlinedButton onClick={applyFilters}>
                        {i18next.t('service_desk_web_atoir:battonApply')}
                      </OutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
              <TablePagination
                rowsPerPageOptions={[50, 150, 300, 500]}
                component="div"
                count={equipmentCount}
                rowsPerPage={limit}
                page={page}
                labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
                labelDisplayedRows={({
                  from: fromPage,
                  to: toPage,
                  count: countPage,
                }) =>
                  i18next.t('shared_web_atoir:labelDisplayedRows', {
                    fromPage,
                    toPage,
                    countPage,
                  })
                }
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={paginationChange('page')}
                onChangeRowsPerPage={paginationChange('rowsPerPage')}
              />
            </div>
          </Sticky>
          <div>
            <Grid container>
              <Grid item xs={12}>
                <Table className={classes.table}>
                  <TableBody>
                    {availableEquipmentIsFetching ? (
                      <Loader />
                    ) : (
                      <EquipmentsList
                        data={data}
                        checked={checked}
                        change={checkedChange}
                      />
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </div>
          <ConfirmExitDialog
            open={isOpenConfirmExitDialog}
            onLeave={() => {
              history.push(popLastSections(url))
            }}
            stayText={i18next.t('shared_web_atoir:saveAndExit')}
            onClose={() => {
              this.setState({ isOpenConfirmExitDialog: false })
            }}
            onStay={() => {
              this.setState({ waitForExit: true }, () => {
                addToContracts()
              })
            }}
          />
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(AddEquipment)
