import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import i18next from '../../../../../i18n/i18n'
import { formEquipmentFullName } from '../../../../../helpers/equipmentHelper'
import {
  organizationLocationStringify,
  inventoryLocationStringify,
} from '../../../../../helpers/parserHelper'

// material ui
import withStyles from '@material-ui/core/styles/withStyles'
import RestoreIcon from '@material-ui/icons/Restore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// components
import Title from '../Title'
import BlockTitle from '../BlockTitle'
import InfoRow from '../InfoRow'
import OutlineButton from '../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import TagCloseable from './TagCloseable'
import AttachTagButton from './AttachTagButton'
import AttachTagDialog from './AttachTagDialog'

// helpers
import { checkCondition } from '../../../../../helpers/sdHepers'
import {
  formDatePeriod,
  visitDateParserOrderSD,
} from '../../../../../helpers/dateHelper'
import { getUserFullName } from '../../../../../helpers/userHelper'
import { addMaskCharacters } from '../../../../../helpers/phoneStringHelper'
import * as permissionResolvers from '../../../../../helpers/callPermissionsResolver'
import UnpaidTooltip from '../../UnpaidTooltip'
import MakeRecurringDialog from './ReccuringDialogs/MakeRecurringDialog'
import EditRecurringDialog from './ReccuringDialogs/EditRecurringDialog'

const styles = theme => ({
  root: {
    '&::before': {
      height: '0px',
      backgroundColor: 'transparent',
    },
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
    marginLeft: theme.spacing(-3),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  menuIcon: {
    color: theme.palette.text.medium,
  },
  tagGridMargin: {
    marginRight: theme.spacing(1),
  },
  tagGridContainer: {
    marginTop: theme.spacing(1),
  },
})

const callButtonsPrepare = (callButtons = [], call, permissions) =>
  _.compact(
    callButtons.map(button =>
      permissionResolvers.canViewSDCallButton(permissions, button.scope) &&
      checkCondition(
        { simple: button.simple_conditions, logic: button.logic },
        { call },
      )
        ? button
        : null,
    ),
  )

const parseDateShort = string =>
  string.split(' ').map(d => ({
    value: (d.toLowerCase().match(/(\d+)[dymq]/) || [undefined, '1'])[1],
    unit: (d.toLowerCase().match(/\d+([dymq])/) || [undefined, 'd'])[1],
  }))

class CallMainInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      attachTagDialogOpen: false,
      makeRecurringDialogOpen: false,
      editRecurringDialogOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: { callButtonExecuting },
    } = this
    if (
      prevProps.callButtonExecuting !== callButtonExecuting &&
      !callButtonExecuting
    ) {
      this.handleButtonsMenuClose()
    }
  }

  handleButtonsMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleButtonsMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  openAttachTagDialog = () => {
    this.setState({ attachTagDialogOpen: true })
  }

  closeAttachTagDialog = () => {
    this.setState({ attachTagDialogOpen: false })
  }

  doneAttachTag = () => {
    this.setState({ attachTagDialogOpen: false })
    this.props.updateData()
  }

  openRecurringDialog = () => {
    this.setState({ makeRecurringDialogOpen: true })
  }

  closeRecurringDialog = () => {
    this.setState({ makeRecurringDialogOpen: false })
  }

  openEditRecurringDialog = () => {
    this.setState({ editRecurringDialogOpen: true })
  }

  closeEditRecurringDialog = () => {
    this.setState({ editRecurringDialogOpen: false })
  }

  render() {
    const {
      props: {
        classes,
        call,
        permissions,
        permissionsCommon,
        tags,
        onEditEquipment,
        onEditRepairInfo,
        onCloseCallFromNeedrepair,
        onSendToRepairFromNeedrepair,
        assignButtonContent,
        repairPerfomanceInfoVisible,
        onEditRepairPerformerResponsible,
        previewBlank,
        callButtons,
        callButtonExecute,
        disableScriptsForIds,
        onDisableActionsClick,
        showDisableActionCheckbox,
        showMakeRecurringButton,
      },
      state: {
        anchorEl,
        attachTagDialogOpen,
        makeRecurringDialogOpen,
        editRecurringDialogOpen,
      },
      openAttachTagDialog,
      closeAttachTagDialog,
      doneAttachTag,
    } = this
    const {
      ext_num: extNum,
      OrganizationEquipment: equipment,
      client_organization: client,
      reaction_date_begin: reactionDateBegin,
      reaction_date_end: reactionDateEnd,
      client_responsible: responsible,
      client_comment: comment,
      id: callId,
    } = call
    const recurringCall =
      (call.RecurringServiceCalls && call.RecurringServiceCalls[0]) ||
      call.recurringCall
    const recurringInterval = recurringCall
      ? { ...parseDateShort(recurringCall.interval)[0], id: recurringCall.id }
      : undefined
    return (
      <Fragment>
        {makeRecurringDialogOpen ? (
          <MakeRecurringDialog
            open={makeRecurringDialogOpen}
            handleClose={this.closeRecurringDialog}
            callId={call.id}
          />
        ) : null}
        {editRecurringDialogOpen ? (
          <EditRecurringDialog
            open={editRecurringDialogOpen}
            handleClose={this.closeEditRecurringDialog}
            callId={call.id}
            recurringCall={recurringCall}
          />
        ) : null}
        <div id="call-main-info">
          <Grid container justify="space-between">
            <Grid item md={6} sm={12}>
              <Title text={i18next.t('service_desk_web_atoir:mainInfoTitle')} />
              {!isEmpty(extNum) ? (
                <div>
                  <InfoRow
                    name={`${i18next.t(
                      'service_desk_web_atoir:requestCreationExtNumLabel',
                    )}:`}
                    value={extNum}
                  />
                </div>
              ) : null}
              <Grid
                container
                direction="row"
                className={classes.tagGridContainer}
              >
                {tags.length ? (
                  <Fragment>
                    {call.tags.map(tag => (
                      <Grid item className={classes.tagGridMargin}>
                        <TagCloseable
                          tagId={tag.id}
                          callId={callId}
                          color={`#${tag.color}`}
                          name={tag.name}
                          deleteTag={() => {}}
                          detachDone={this.props.updateData}
                        />
                      </Grid>
                    ))}
                  </Fragment>
                ) : null}
                <Grid item className={classes.tagGridMargin}>
                  <AttachTagButton onClick={openAttachTagDialog} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                {showDisableActionCheckbox ? (
                  <Grid item>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          color="primary"
                          checked={disableScriptsForIds[callId]}
                          onClick={onDisableActionsClick(callId)}
                        />
                      }
                      label={i18next.t(
                        'service_desk_web_atoir:disableSDCallOrOrderActions',
                      )}
                      labelPlacement="end"
                    />
                  </Grid>
                ) : null}
                {previewBlank ? (
                  <Grid item>
                    <OutlineButton onClick={previewBlank}>
                      {i18next.t('service_desk_web_atoir:printBlank')}
                    </OutlineButton>
                  </Grid>
                ) : null}
                {showMakeRecurringButton || call.recurringCall ? (
                  <Grid item>
                    <OutlineButton
                      onClick={
                        recurringInterval
                          ? this.openEditRecurringDialog
                          : this.openRecurringDialog
                      }
                    >
                      {recurringInterval &&
                        `${recurringInterval.value}${i18next.t(
                          `shared_web_atoir:shortDateUnit${recurringInterval.unit.toUpperCase()}`,
                        )}`}
                      <RestoreIcon />
                    </OutlineButton>
                  </Grid>
                ) : null}
                {assignButtonContent ? (
                  <Grid item>{assignButtonContent}</Grid>
                ) : null}
                {onCloseCallFromNeedrepair ? (
                  <Grid item>
                    <OutlineButton onClick={onCloseCallFromNeedrepair}>
                      {i18next.t('shared_web_atoir:close')}
                    </OutlineButton>
                  </Grid>
                ) : null}
                {onSendToRepairFromNeedrepair ? (
                  <Grid item>
                    <ContainedButton onClick={onSendToRepairFromNeedrepair}>
                      {i18next.t('service_desk_web_atoir:repairAction')}
                    </ContainedButton>
                  </Grid>
                ) : null}
                {permissionResolvers.canViewSDCallButtonsMenu(permissions) &&
                callButtons.length ? (
                  <Grid item>
                    {callButtonsPrepare(callButtons, call, permissions).length >
                      0 && (
                      <Fragment>
                        <UnpaidTooltip
                          disabled={permissionResolvers.canOperateButton(
                            permissionsCommon,
                            'calls',
                            'client',
                          )}
                        >
                          <IconButton
                            id="service-desk-call-buttons-menu"
                            disabled={
                              !permissionResolvers.canOperateButton(
                                permissionsCommon,
                                'calls',
                                'client',
                              )
                            }
                            aria-owns={anchorEl ? 'call-buttons-menu' : null}
                            aria-haspopup="true"
                            onClick={this.handleButtonsMenuOpen}
                            color="inherit"
                          >
                            <MoreVertIcon className={classes.menuIcon} />
                          </IconButton>
                        </UnpaidTooltip>
                        <Menu
                          id="call-buttons-menu"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorEl)}
                          onClose={this.handleButtonsMenuClose}
                        >
                          {callButtonsPrepare(callButtons, call, permissions)
                            .length > 0 ? (
                            callButtonsPrepare(
                              callButtons,
                              call,
                              permissions,
                            ).map(button => (
                              <MenuItem
                                onClick={() => callButtonExecute(button)}
                              >
                                {button.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>
                              {i18next.t(
                                'service_desk_web_atoir:noSuitableButtons',
                              )}
                            </MenuItem>
                          )}
                        </Menu>
                      </Fragment>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div id="call-equipment-info" className={classes.marginBottom}>
          <BlockTitle
            text={i18next.t('equipment_web_atoir:equipmentDrawerMenuItem')}
            onEdit={onEditEquipment}
          />
          <ExpansionPanel elevation={0} className={classes.root}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <InfoRow
                name={`${i18next.t('equipment_web_atoir:equipmentName')}:`}
                value={formEquipmentFullName(equipment)}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Fragment>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <InfoRow
                      name={i18next.t('service_desk_web_atoir:captionExtId')}
                      value={equipment.external_id}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <InfoRow
                      name={`${i18next.t('equipment_web_atoir:serialNumber')}:`}
                      value={equipment.serial_number}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <InfoRow
                      name={`${i18next.t(
                        'equipment_web_atoir:articleNumber',
                      )}:`}
                      value={equipment.article_number}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <InfoRow
                      name={`${i18next.t(
                        'equipment_web_atoir:inventoryCode',
                      )}:`}
                      value={equipment.inventory_code}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:whatsWrong')}:`}
            value={call.whats_broken}
            expandMore
          />
        </div>
        <div id="call-equipment-owner" className={classes.marginBottom}>
          <BlockTitle
            text={`${i18next.t('service_desk_web_atoir:equipmentOwner')}`}
          />
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:tableCellName')}:`}
            value={client.full_name}
          />
          <InfoRow
            name={`${i18next.t('organizations_web_atoir:address')}:`}
            value={organizationLocationStringify(
              client.OrganizationLocations[0],
            )}
          />
        </div>
        <div id="call-repair-info" className={classes.marginBottom}>
          <BlockTitle
            text={`${i18next.t('service_desk_web_atoir:repairInformation')}`}
            onEdit={onEditRepairInfo}
          />
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:desiredRepairPeriod')}:`}
            value={formDatePeriod(reactionDateBegin, reactionDateEnd)}
          />
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:repairPlace')}:`}
            value={inventoryLocationStringify(call)}
          />
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:responsiblePerson')}:`}
            value={getUserFullName(responsible)}
            additional={`${
              responsible.position === 'owner'
                ? i18next.t('organizations_web_atoir:ownerRole')
                : responsible.position
            } ${
              !isEmpty(responsible.phone)
                ? addMaskCharacters(responsible.phone)
                : ''
            }`}
            onEdit={onEditRepairPerformerResponsible}
          />
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:comment')}:`}
            value={comment}
            hideIfEmpty
            expandMore
          />
        </div>
        {repairPerfomanceInfoVisible && !isEmpty(call.performer_info) && (
          <div id="call-repair-status" className={classes.marginBottom}>
            <BlockTitle
              text={i18next.t(
                'service_desk_web_atoir:callPerformedRepairsInfo',
              )}
            />
            <InfoRow
              name={`${i18next.t('service_desk_web_atoir:engineer')}:`}
              value={
                !isEmpty(call.performer_info.engineer_user)
                  ? getUserFullName(call.performer_info.engineer_user)
                  : i18next.t('service_desk_web_atoir:engineerIsNotAssignedYet')
              }
            />
            <InfoRow
              name={`${i18next.t('service_desk_web_atoir:dateVisit')}:`}
              value={visitDateParserOrderSD({}, call)}
            />
            <InfoRow
              name={`${i18next.t('service_desk_web_atoir:note')}:`}
              value={call.performer_info.visit_comment}
              hideIfEmpty
              expandMore
            />
          </div>
        )}
        {attachTagDialogOpen && (
          <AttachTagDialog
            open={attachTagDialogOpen}
            attachDone={doneAttachTag}
            cancel={closeAttachTagDialog}
            callId={callId}
          />
        )}
      </Fragment>
    )
  }
}

CallMainInfo.defaultProps = {
  onEditEquipment: null,
  onEditRepairInfo: null,
  showDisableActionCheckbox: false,
  showMakeRecurringButton: false,
  onCloseCallFromNeedrepair: null,
  onSendToRepairFromNeedrepair: null,
  assignButtonContent: null,
  repairPerfomanceInfoVisible: false,
  onEditRepairPerformerResponsible: () => {},
  previewBlank: () => {},
}

CallMainInfo.propTypes = {
  call: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  onEditEquipment: PropTypes.func,
  onEditRepairInfo: PropTypes.func,
  showDisableActionCheckbox: PropTypes.bool,
  showMakeRecurringButton: PropTypes.bool,
  onCloseCallFromNeedrepair: PropTypes.func,
  onSendToRepairFromNeedrepair: PropTypes.func,
  assignButtonContent: PropTypes.node,
  repairPerfomanceInfoVisible: PropTypes.bool,
  onEditRepairPerformerResponsible: PropTypes.func,
  previewBlank: PropTypes.func,
  callButtons: PropTypes.array.isRequired,
  callButtonExecute: PropTypes.func.isRequired,
  callButtonExecuting: PropTypes.bool.isRequired,
  disableScriptsForIds: PropTypes.array.isRequired,
  onDisableActionsClick: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
}

export default withStyles(styles)(CallMainInfo)
