import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import SimpleDialog from '../../../Other/SimpleDialog'
import i18next from '../../../../i18n/i18n'
import SecondaryButton from '../../../../widgets/Buttons/SecondaryButton'
import PrimaryButton from '../../../../widgets/Buttons/PrimaryButton'

const conformDialogContentStyles = theme => ({
  rootDiv: {
    marginTop: theme.typography.pxToRem(20),
    minHeight: theme.typography.pxToRem(50),
  },
})
const ConformDialogContent = withStyles(conformDialogContentStyles)(props => (
  <Fragment>
    <div className={props.classes.rootDiv}>
      {i18next.t(
        'inventory_web_atoir:areYouSureAboutPuttingTheProjectUpForAuction',
      )}
    </div>
  </Fragment>
))

const styles = () => ({
  root: {
    minWidth: '360px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

class SentToAuction extends Component {
  state = {
    ratingToAuction: '0',
    isConfirmDialogOpen: false,
  }

  componentDidUpdate = prevProps => {
    const {
      isPuttingOnAuctionProject,
      puttingOnAuctionProjectError,
      hideDialogLoader,
      onClose,
    } = this.props
    if (
      prevProps.isPuttingOnAuctionProject !== isPuttingOnAuctionProject &&
      isPuttingOnAuctionProject === false
    ) {
      if (!puttingOnAuctionProjectError) {
        // TODO Run success script
      } else {
        onClose(true)
      }
      hideDialogLoader()
    }
  }

  showConfirmDialog = event => {
    event.preventDefault()
    this.setState({
      isConfirmDialogOpen: true,
    })
  }

  sentToAuction = () => {
    const {
      props: { project, putUpOnAuction, showDialogLoader },
      state: { ratingToAuction },
      handleCloseConfirmDialog,
    } = this
    handleCloseConfirmDialog()
    putUpOnAuction(project.Client.id, project.id, {
      rating_to_auction: ratingToAuction,
    })
    showDialogLoader()
  }

  handleCloseConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    })
  }

  handleChange = field => event => {
    this.setState({
      [field]: event.target.value,
    })
  }

  handleClose = () => {
    const {
      props: { onClose },
    } = this
    this.setState(
      {
        ratingToAuction: '0',
      },
      () => {
        onClose()
      },
    )
  }

  render() {
    const {
      showConfirmDialog,
      handleCloseConfirmDialog,
      sentToAuction,
      props: { isOpen },
      state: { isConfirmDialogOpen, ratingToAuction },
    } = this
    return (
      <Dialog open={isOpen} onClose={this.handleClose}>
        <DialogTitle>
          {i18next.t('inventory_web_atoir:putProjectUpOnAuction')}
        </DialogTitle>
        <form onSubmit={showConfirmDialog}>
          <DialogContent>
            <FormControl>
              <InputLabel htmlFor="rating-organization-filter">
                {i18next.t('inventory_web_atoir:rating')}
              </InputLabel>
              <Select
                value={ratingToAuction}
                onChange={this.handleChange('ratingToAuction')}
                inputProps={{
                  name: 'rating',
                  id: 'rating-organization-filter',
                }}
              >
                <MenuItem value="0">
                  <em>{i18next.t('inventory_web_atoir:doesNotMatter')}</em>
                </MenuItem>
                <MenuItem value="3">
                  {i18next.t('inventory_web_atoir:notLower', { text: 3 })}
                </MenuItem>
                <MenuItem value="4">
                  {i18next.t('inventory_web_atoir:notLower', { text: 4 })}
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={this.handleClose} variant="rised">
              {i18next.t('shared_web_atoir:cancel')}
            </SecondaryButton>
            <PrimaryButton type="submit">
              {i18next.t('inventory_web_atoir:confirm')}
            </PrimaryButton>
          </DialogActions>
          <SimpleDialog
            isOpen={isConfirmDialogOpen}
            content={<ConformDialogContent />}
            handleClose={handleCloseConfirmDialog}
            handleNegative={handleCloseConfirmDialog}
            handlePositive={sentToAuction}
            negativeText={i18next.t('shared_web_atoir:cancel')}
            positiveText={i18next.t('inventory_web_atoir:confirm')}
            title={i18next.t('inventory_web_atoir:confirmATrade')}
          />
        </form>
      </Dialog>
    )
  }
}

SentToAuction.propTypes = {
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  putUpOnAuction: PropTypes.func.isRequired,
  showDialogLoader: PropTypes.func.isRequired,
  hideDialogLoader: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPuttingOnAuctionProject: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SentToAuction)
