import * as constants from '../constants/actionTypes/serviceDesk'

export default store => next => ({ type, payload, ...other }) => {
  const {
    serviceDesk: { disableScriptsForIds },
  } = store.getState()
  const action = {
    type,
    payload,
    ...other,
  }
  if (
    type === constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_REQUEST &&
    disableScriptsForIds[payload.id]
  ) {
    action.payload = {
      ...payload,
      data: {
        ...payload.data,
        disableActions: true,
      },
    }
  }
  if (
    type === constants.SEND_ORDER_TO_SUB_ORDER_REQUEST &&
    disableScriptsForIds[payload.id]
  ) {
    action.payload = {
      ...payload,
      data: {
        ...payload.data,
        disableActions: true,
      },
    }
  }
  if (
    type === constants.REJECT_ORDER_REQUEST &&
    disableScriptsForIds[payload.id]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.CANCEL_ORDER_BY_CLIENT_REQUEST &&
    disableScriptsForIds[`${payload.orderId}`]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.SET_ORDER_RESULT_TYPE_REQUEST &&
    disableScriptsForIds[`${payload.orderId}`]
  ) {
    action.payload = {
      ...payload,
      data: {
        ...payload.data,
        disableActions: true,
      },
    }
  }
  if (
    type === constants.CONFIRM_CERTIFICATE_OF_COMPLETION_REQUEST &&
    disableScriptsForIds[`${payload.orderId}`]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST &&
    disableScriptsForIds[`${payload.orderId}`]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST &&
    disableScriptsForIds[`${payload.orderId}`]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.UPLOAD_FILE_REQUEST &&
    disableScriptsForIds[payload.id]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.UPLOAD_PHOTO_REQUEST &&
    disableScriptsForIds[payload.id]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.UPDATE_CALL_REQUEST &&
    disableScriptsForIds[payload.requestId]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.CANCEL_REQUEST_REQUEST &&
    disableScriptsForIds[payload.id]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.ASSIGN_USING_CONTRACT_REQUEST &&
    disableScriptsForIds[payload]
  ) {
    action.payload = {
      id: payload,
      disableActions: true,
    }
  }
  if (
    type === constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_REQUEST &&
    disableScriptsForIds[payload.callId]
  ) {
    action.payload = {
      callId: payload.callId,
      disableActions: true,
    }
  }
  if (
    type === constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_REQUEST &&
    disableScriptsForIds[payload.callId]
  ) {
    action.payload = {
      ...payload,
      data: {
        ...payload.data,
        disableActions: true,
      },
    }
  }
  if (
    type === constants.SEND_CALL_USING_CONTRACT_REQUEST &&
    disableScriptsForIds[payload.callId]
  ) {
    action.payload = {
      callId: payload.callId,
      disableActions: true,
    }
  }
  if (
    type === constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_REQUEST &&
    disableScriptsForIds[`${payload.orderId}`]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_REQUEST &&
    disableScriptsForIds[payload.callId]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  if (
    type === constants.ASSIGN_TO_THIRD_PARTY_REQUEST_REQUEST &&
    disableScriptsForIds[payload.callId]
  ) {
    action.payload = {
      ...payload,
      disableActions: true,
    }
  }
  next(action)
}
