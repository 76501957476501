import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import InventoryTable from './InventoryTable'

// styles
import '../../../styles/components/Staff.css'

// material-ui-icons
import AddIcon from '@material-ui/icons/Add'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const columnData = []

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          {columnData.map(
            column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title={i18next.t('shared_web_atoir:sort')}
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subheading">
            {numSelected} {i18next.t('employees_web_atoir:staffSelectedAmount')}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  tabsWrapper: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 800,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formLabel: {
    textAlign: 'left',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  checked: {
    color: theme.palette.main.main,
  },
})

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

class InventoryComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      requestor: 'client',
      status: 'created',
      selected: [],
      rowsPerPage: 5,
      page: 0,
    }
  }

  componentDidMount() {
    const {
      props: {
        fetchInventoryProjects,
        fetchInventoryProjectsCount,
        fetchInventoryProjectsCities,
        fetchInventoryClients,
        fetchInventoryPerformers,
      },
      state: { requestor, status, page, rowsPerPage },
    } = this
    fetchInventoryProjects(requestor, status, {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    })
    fetchInventoryProjectsCount(requestor, status)
    fetchInventoryProjectsCities()
    fetchInventoryClients(status, `as_${requestor}`)
    fetchInventoryPerformers(status, `as_${requestor}`)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetchingInventoryProjects !==
        this.props.isFetchingInventoryProjects &&
      !this.props.isFetchingInventoryProjects
    ) {
      this.props.getTitleInfo({
        moreAction: this.getAddInventoryProjects(),
      })
    }
  }

  getAddInventoryProjects = () => (
    <IconButton
      aria-label={i18next.t('inventory_web_atoir:addInventoryProject')}
      component={Link}
      to="/inventory_project/create"
    >
      <AddIcon />
    </IconButton>
  )

  handleRequestorChange = event => {
    const {
      props: {
        fetchInventoryProjects,
        fetchInventoryProjectsCount,
        fetchInventoryClients,
        fetchInventoryPerformers,
      },
      state: { status, page, rowsPerPage },
    } = this
    const { value } = event.target
    this.setState({ requestor: value }, () => {
      fetchInventoryProjects(value, status, {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      })
      fetchInventoryProjectsCount(value, status)
      fetchInventoryClients(status, `as_${value}`)
      fetchInventoryPerformers(status, `as_${value}`)
    })
  }

  handleStatusChange = value => {
    const {
      props: {
        fetchInventoryProjects,
        fetchInventoryProjectsCount,
        fetchInventoryClients,
        fetchInventoryPerformers,
      },
      state: { requestor, page, rowsPerPage },
    } = this
    this.setState({ status: value }, () => {
      fetchInventoryProjects(requestor, value, {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      })
      fetchInventoryProjectsCount(requestor, value)
      fetchInventoryClients(value, `as_${requestor}`)
      fetchInventoryPerformers(value, `as_${requestor}`)
    })
  }

  changePage = page => {
    this.setState({ page })
  }

  changeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage })
  }

  handleFetchInventory = filters => {
    const {
      props: {
        fetchInventoryProjects,
        fetchInventoryProjectsCount,
        fetchInventoryClients,
        fetchInventoryPerformers,
      },
      state: { requestor, status },
    } = this
    fetchInventoryProjects(requestor, status, filters)
    fetchInventoryProjectsCount(requestor, status, filters)
    fetchInventoryClients(status, `as_${requestor}`)
    fetchInventoryPerformers(status, `as_${requestor}`)
  }

  displayedRows = (from, to, count) => count

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const { classes } = this.props
    const { requestor, status } = this.state

    return (
      <Fragment>
        <Paper className={classes.root}>
          <Fragment>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:requestorTypeRadioGroupTitle')}:
              </FormLabel>
              <RadioGroup
                row
                aria-label="Requestor"
                name="requestor"
                className={classes.group}
                value={requestor}
                onChange={this.handleRequestorChange}
              >
                <FormControlLabel
                  className={classes.radio}
                  value="client"
                  control={<Radio colorPrimary />}
                  label={i18next.t('inventory_web_atoir:client')}
                />
                <FormControlLabel
                  value="performer"
                  control={<Radio colorPrimary />}
                  label={i18next.t('inventory_web_atoir:performer')}
                />
              </RadioGroup>
            </FormControl>

            <div className={classes.tabsWrapper}>
              <AppBar position="static" color="default">
                <Tabs
                  value={status}
                  onChange={(event, value) => this.handleStatusChange(value)}
                  centered
                >
                  <Tab
                    value="created"
                    label={i18next.t(
                      'inventory_web_atoir:createdInventoryProjects',
                    )}
                  />
                  <Tab
                    value="auction"
                    label={i18next.t('inventory_web_atoir:auction')}
                  />
                  <Tab
                    value="inperformer"
                    label={i18next.t(
                      'inventory_web_atoir:inPerformerInventoryProjects',
                    )}
                  />
                  <Tab
                    value="inwork"
                    label={i18next.t('inventory_web_atoir:inwork')}
                  />
                  <Tab
                    value="done"
                    label={i18next.t(
                      'inventory_web_atoir:doneInventoryProjects',
                    )}
                  />
                  <Tab
                    value="close"
                    label={i18next.t(
                      'inventory_web_atoir:closedInventoryProjects',
                    )}
                  />
                </Tabs>
              </AppBar>
            </div>
            <InventoryTable
              handleFetchInventory={this.handleFetchInventory}
              changePage={this.changePage}
              changeRowsPerPage={this.changeRowsPerPage}
              requestor={requestor}
              status={status}
            />
          </Fragment>
        </Paper>
      </Fragment>
    )
  }
}

InventoryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchInventoryProjects: PropTypes.func.isRequired,
  fetchInventoryProjectsCount: PropTypes.func.isRequired,
  fetchInventoryProjectsCities: PropTypes.func.isRequired,
  fetchInventoryClients: PropTypes.func.isRequired,
  fetchInventoryPerformers: PropTypes.func.isRequired,
}

export default withStyles(styles)(InventoryComponent)
