export default {
  STATUS_CREATED: 'created',
  STATUS_INWORK: 'inwork',
  STATUS_INPERFORMER: 'inperformer',
  STATUS_CLOSED: 'closed',
  STATUS_CANCELED: 'canceled',
  AGREEMENT_STATUS_CREATED: 'created',
  AGREEMENT_STATUS_INWORK: 'inwork',
  AGREEMENT_STATUS_INPERFORMER: 'inperformer',
  AGREEMENT_STATUS_CANCELED: 'canceled',
  AGREEMENT_STATUS_REJECTED: 'rejected',
}
