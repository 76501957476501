import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Route, Link } from 'react-router-dom'
import isEmpty from 'is-empty'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import grey from '@material-ui/core/colors/grey'

// material-icons
import DoneIcon from '@material-ui/icons/Done'
import './crumbs.css'
import BreadCrumb from '../routing/BreadCrumb'

const styles = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  wrapperRoute: {
    position: 'relative',
    boxSizing: 'border-box',
  },
  titleGroup: {
    marginBottom: theme.spacing(2),
  },
  titleRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  substringRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  historyChangesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  substringRowLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  status: {
    textTransform: 'uppercase',
    color: theme.palette.apply.main,
    fontWeight: 'bold',
  },
  historyChanges: {
    color: theme.palette.apply.extraLight,
    textDecoration: 'none',
  },
  breadCrumb: {
    color: theme.palette.apply.main,
    display: 'inline',
    margin: '0 4px',
    fontSize: 16,
  },

  substringLeft: {
    color: theme.palette.apply.extraLight,
    textDecoration: 'none',
  },
  iconHover: {
    padding: theme.spacing(1),
    color: theme.palette.apply.dark,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.apply.lightest,
    },
  },
  backBtn: {
    position: 'absolute',
    top: '50%',
    left: -theme.spacing(8),
    transform: 'translateY(-50%)',
  },
  moreBtn: {
    transform: 'translateX(50%)',
  },
  // '@media (max-width: 1250px)': {
  //   wrapperRoute: {
  //     width: '90vw',
  //   },
  // },
  '@media (max-width: 1024px)': {
    backBtn: {
      display: 'none',
    },
  },
  title: {
    color: grey[900],
    textAlign: 'flex-start',
  },
  topRow: {
    display: 'flex',
    paddingBottom: 12,
    justifyContent: 'space-between',
  },
  outterSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

class WrapperRoute extends Component {
  state = {
    title: '',
    historyChanges: null,
    status: '',
    moreAction: null,
    OuterButton: null,
    substringLeft: null,
  }

  componentWillMount() {
    this.setState({
      title: this.props.title,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      props: { path, title },
    } = this
    if (prevProps.path !== path) {
      this.setState({
        title,
        historyChanges: this.props.historyChanges
          ? this.props.historyChanges
          : null,
        status: '',
        moreAction: null,
        substringLeft: this.props.substringLeft
          ? this.props.substringLeft
          : null,
      })
    }
  }

  getTitleInfo = titleInfo => {
    this.setState(state => ({
      title: isEmpty(titleInfo.title) ? state.title : titleInfo.title,
      historyChanges: isEmpty(titleInfo.historyChanges)
        ? state.historyChanges
        : titleInfo.historyChanges,
      status: isEmpty(titleInfo.status) ? state.status : titleInfo.status,
      moreAction: isEmpty(titleInfo.moreAction)
        ? state.moreAction
        : titleInfo.moreAction,
      substringLeft: isEmpty(titleInfo.substringLeft)
        ? state.substringLeft
        : titleInfo.substringLeft,
      OuterButton: isEmpty(titleInfo.OuterButton)
        ? null
        : titleInfo.OuterButton,
    }))
  }

  render() {
    const {
      props: {
        component: ChildComponent,
        classes,
        userBreadCrumb,
        crumbTitle,
        showHeader,
        showTitleOutOfPaper,
        ...rest
      },
      state: {
        title,
        historyChanges,
        status,
        moreAction,
        substringLeft,
        OuterButton,
      },
    } = this
    return (
      <Route
        {...rest}
        render={props => {
          const currentPath = props.history.location.pathname
          const prevPath = sessionStorage.getItem('atoirPrevPath')

          if (isEmpty(prevPath)) {
            sessionStorage.setItem('atoirPrevPath', currentPath)
          }
          return (
            <section className={classes.wrapperRoute}>
              {showHeader && (
                <div className={classes.titleGroup}>
                  {OuterButton && (
                    <div className={classes.outterSection}>{OuterButton}</div>
                  )}
                  <div className={classes.topRow}>
                    <Typography
                      variant="h3"
                      align="left"
                      className={classNames([
                        classes.title,
                        'wordWrapBreakAll',
                      ])}
                    >
                      {showHeader && showTitleOutOfPaper && title}
                    </Typography>
                    {moreAction && (
                      <div className={classes.status}>{moreAction}</div>
                    )}
                    {substringLeft ? (
                      substringLeft.doneIcon ? (
                        <DoneIcon style={{ fontSize: 16 }} />
                      ) : null
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={classes.substringRow}>
                    {!isEmpty(status) && (
                      <Typography variant="body1" className={classes.status}>
                        {status}
                      </Typography>
                    )}
                    {!isEmpty(historyChanges) &&
                      (Array.isArray(historyChanges) ? (
                        <div className={classes.historyChangesWrapper}>
                          {historyChanges.map(history => (
                            <Typography
                              key={history.title}
                              variant="body2"
                              className={classes.historyChanges}
                              component={history.link ? Link : null}
                              to={history.link ? history.link : null}
                            >
                              {history.title}
                            </Typography>
                          ))}
                        </div>
                      ) : (
                        <Typography
                          variant="body2"
                          className={classes.historyChanges}
                          component={Link}
                          to={historyChanges.link}
                        >
                          {historyChanges.title}
                        </Typography>
                      ))}
                  </div>
                </div>
              )}
              {userBreadCrumb && (
                <BreadCrumb to={rest.location.pathname}>
                  {crumbTitle || title}
                </BreadCrumb>
              )}
              <ChildComponent
                getTitleInfo={this.getTitleInfo}
                classIcon={classes.iconHover}
                classBtnIcon={classes.moreBtn}
                {...props}
              />
            </section>
          )
        }}
      />
    )
  }
}
WrapperRoute.defaultProps = {
  title: '',
  userBreadCrumb: false,
  showHeader: false,
  crumbTitle: null,
  showTitleOutOfPaper: false,
}

WrapperRoute.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  userBreadCrumb: PropTypes.bool,
  showHeader: PropTypes.bool,
  showTitleOutOfPaper: PropTypes.bool,
  crumbTitle: PropTypes.string,
}

export default withStyles(styles)(WrapperRoute)
