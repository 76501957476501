import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles/index'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Sensitive from './Sensitive'
import styled from 'styled-components'
import { palette } from '@material-ui/system'

import IconHolder from './IconHolder'

const Box = styled.div`
  ${palette}
`

function NavigationItemSimple({
  item,
  classesExternal = {},
  disabled = false,
  classForSet,
  collapsed,
  setCollapsed,
  noImage,
  variant = 'body2',
  theme,
}) {
  const { to, text, icon, tooltip } = item
  const isCurrent = Boolean(
    useRouteMatch({
      path: to,
      strict: false,
      sensitive: false,
    }),
  )
  return (
    <Tooltip
      disableFocusListener={!tooltip}
      disableHoverListener={!tooltip}
      disableTouchListener={!tooltip}
      title={tooltip}
      placement="bottom"
      classes={{ tooltip: classesExternal.lightTooltip }}
    >
      <Sensitive collapsed={collapsed} setCollapsed={setCollapsed}>
        <ListItem
          component={Link}
          to={to}
          button
          disabled={disabled}
          className={classForSet || classesExternal.drawerItem}
          onClick={() => {
            setCollapsed(true)
          }}
        >
          {icon && !noImage ? (
            <ListItemIcon>
              <IconHolder current={isCurrent}>
                {React.createElement(icon, { color: 'primary' })}
              </IconHolder>
            </ListItemIcon>
          ) : null}
          <Box color={isCurrent ? theme.palette.blueSecondary.main : undefined}>
            <ListItemText
              className={classesExternal.ListItemChild}
              primary={text}
              primaryTypographyProps={{
                color: 'inherit',
                noWrap: true,
                style: { fontSize: 16 },
                classes: { root: classesExternal.ListItemChildText },
                variant,
              }}
            />
          </Box>
        </ListItem>
      </Sensitive>
    </Tooltip>
  )
}

export default withStyles(
  { root: {} },
  { withTheme: true },
)(NavigationItemSimple)
