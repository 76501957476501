import React from 'react'

import FormControl from '@material-ui/core/FormControl'

import AutocompleteLimitedAuto from '../../../widgets/AutocompleteLimitedAuto'
import { URL } from '../../../middlewares/api'
import i18n from '../../../i18n/i18n'

const ContragentVariantsAutoComplete = ({
  idValue,
  value,
  onChange,
  organizationId,
  side,
}) => {
  const onChangeHandler = _value => {
    onChange(idValue, _.get(_value, 'id') || null)
  }
  return (
    <FormControl fullWidth>
      <AutocompleteLimitedAuto
        value={value || ''}
        label={i18n.t(
          'trusted_organizations_web_atoir:trustedOrganizatinCandidatesTitle',
        )}
        InputProps={{ id: idValue, disabled: !organizationId }}
        sourceUrl={`${URL}/organizations/${organizationId}/trusted/not_agents`}
        query={{ side, sort: '[short_name=ASC]' }}
        getItemDisplayName={i => i.short_name}
        getItemValue={i => i.id}
        onChange={onChangeHandler}
        limit={50}
        requestResultTransformerToArray={res => res.organizations}
        transformInputToQuery={name => ({ name })}
        menuListProps={{ style: { maxHeight: 360 } }}
        fixedWidth={500}
        dropDownPlacement="top"
      />
    </FormControl>
  )
}

export default ContragentVariantsAutoComplete
