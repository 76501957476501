import React from 'react'
import Box from '@material-ui/core/Box'

import FormDialog from '../../../../../widgets/FormDialog'
import i18next from '../../../../../i18n/i18n'
// eslint-disable-next-line max-len
import EquipmentChoosingList from '../../../CreateRequest/ChoosingEquipment/widgets/EquipmentChoosingList/ExternalEquipmentChoosingList'

const MyEquipmentsDialog = ({
  open,
  handleClose,
  checkedEquipment,
  setCheckedEquipment,
  client,
  performerId,
}) => {
  const [newValue, setNewValue] = React.useState({ id: '' })

  React.useEffect(() => {
    if (open) {
      setNewValue({ id: checkedEquipment.equipment_id })
    }
  }, [open])

  function onChangeEquipmentHandler(id, equipment) {
    setNewValue(equipment)
  }
  function onSubmit() {
    if (checkedEquipment.equipment_id !== newValue.id) {
      setCheckedEquipment(newValue)
    }
    handleClose()
  }

  return (
    <FormDialog
      closeIconHidden
      open={open}
      title={i18next.t(
        'inner_calls_web_atoir:selectionExternalEquipmentInOrg',
        { name: client.text },
      )}
      onClose={handleClose}
      onExit={handleClose}
      onSubmit={onSubmit}
      submitButtonTitle={i18next.t('inner_calls_web_atoir:doChose')}
      fullWidth
      dialogProps={{
        maxWidth: 'xl',
      }}
      secondaryButtonsArray={[
        {
          secondaryText: i18next.t('shared_web_atoir:cancel'),
          handleSecondaryAction: handleClose,
        },
      ]}
    >
      {open && (
        <Box b={2} t={2} height="calc(100vh - 350px)">
          <EquipmentChoosingList
            externalMode={{
              client: client.client_organization_id,
              performer: performerId,
            }}
            checkedEquipment={newValue.id}
            onChangeEquipment={onChangeEquipmentHandler}
            organizationId={performerId}
          />
        </Box>
      )}
    </FormDialog>
  )
}

export default MyEquipmentsDialog
