import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SDCallActionsHistory from './SDCallActionsHistory'
import {
  fetchCallPermissions,
  fetchServiceDeskCall,
  fetchSDActionsHistory,
  clearSDActionsHistory,
} from '../../../actions/serviceDesk'

const mapStateToProps = state => ({
  isFetchingCall: state.serviceDesk.request.isFetching,
  call: state.serviceDesk.request.request,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  permissions: state.serviceDesk.permissions.permissions,
  ordersPermissions: state.serviceDesk.permissions.orders,
  callStatus: state.serviceDesk.permissions.status,
  permissionsError: state.serviceDesk.permissions.error,
  isFetchingHistory: state.serviceDesk.sdActionsHistory.isFetching,
  history: state.serviceDesk.sdActionsHistory.history,
  end: state.serviceDesk.sdActionsHistory.end,
  historyError: state.serviceDesk.sdActionsHistory.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCallPermissions,
      fetchServiceDeskCall,
      fetchActionsHistory: fetchSDActionsHistory,
      clearActionsHistory: clearSDActionsHistory,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SDCallActionsHistory),
)
