import _ from 'lodash'
import i18next from '../i18n/i18n'

export const getUserFullName = u =>
  _.compact([
    u.last_name || u.lastName,
    u.first_name || u.firstName,
    u.middle_name || u.middleName,
  ]).join(' ')

export const stringifyEmployeePosition = position =>
  position === 'owner'
    ? i18next.t('organizations_web_atoir:ownerRole')
    : position

export const getUserNameWithInitials = u =>
  _.isEmpty(u)
    ? null
    : `${
        u.last_name
          ? u.last_name.concat(' ')
          : '' || u.lastName
          ? u.lastName.concat(' ')
          : ''
      } ${
        u.first_name
          ? u.first_name
              .charAt(0)
              .toUpperCase()
              .concat('.')
          : '' || u.firstName
          ? u.firstName
              .charAt(0)
              .toUpperCase()
              .concat('.')
          : ''
      }${
        u.middle_name
          ? u.middle_name
              .charAt(0)
              .toUpperCase()
              .concat('.')
          : '' || u.middleName
          ? u.middleName
              .charAt(0)
              .toUpperCase()
              .concat('.')
          : ''
      }`
