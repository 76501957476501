export const previewDocument = previewPath => {
  const { origin } = window.location
  const extension = previewPath.split('.').pop()
  if (extension === 'pdf') {
    window.open(
      `https://docs.google.com/gview?url=${origin}/${previewPath}`,
      '_blank',
    )
  } else if (['doc', 'docx'].includes(extension)) {
    window.open(
      `https://view.officeapps.live.com/op/embed.aspx?src=${origin}/${previewPath}`,
      '_blank',
    )
  } else {
    window.open(`${origin}/${previewPath}`, '_blank')
  }
}

export const previewPathChange = path => path.replace('temp', 'onetimelink')
