import { getUserFullName } from '../../../../../helpers/userHelper'
import { formatDateWithTime } from '../../../../../helpers/dateHelper'
import { getComment, getCommentAction } from './commentDictionary'

const toDefaultUserNameStructure = u => ({
  first_name: u.u_first_name,
  last_name: u.u_last_name,
  middle_name: u.u_middle_name,
})

export default function History(historyObject) {
  function getByPath(
    context,
    path,
    extractor = item => item,
    defaultValue = '',
    checkKey = null,
  ) {
    const p = pathRaw => `item.${pathRaw}`
    return _.get(context, p(checkKey || path))
      ? extractor(_.get(context, p(path)))
      : defaultValue
  }
  function historyTypeDetect(historyObj) {
    if (historyObj.id) {
      return historyDictionary.action_history.type
    }
    if (historyObj.sch_id) {
      return historyDictionary.call_history.type
    }
    return 'default'
  }
  const defaultHistoryItem = {
    type: 'unknown',
    getDateTime: () => '',
    getWho: () => '',
    getOrganization: () => '',
    getComment: () => '',
  }
  const historyDictionary = {
    call_history: {
      ...defaultHistoryItem,
      type: 'call_history',
      getDateTime() {
        return getByPath(this, 'sch_createdat', item =>
          formatDateWithTime(new Date(item)),
        )
      },
      getWho() {
        return getByPath(
          this,
          'organizationUser',
          item => getUserFullName(toDefaultUserNameStructure(item)),
          '',
          'organizationUser.u_user_id',
        )
      },
      getOrganization() {
        return getByPath(this, 'organization.o_short_name')
      },
      getComment() {
        return getComment(this.item)
      },
    },
    action_history: {
      ...defaultHistoryItem,
      type: 'action_history',
      getDateTime() {
        return getByPath(this, 'executed_at', item =>
          formatDateWithTime(new Date(item)),
        )
      },
      getWho() {
        return getByPath(this, 'User', item => getUserFullName(item))
      },
      getOrganization() {
        return getByPath(this, 'action_options.organizationName')
      },
      getComment() {
        return getCommentAction(this.item)
      },
    },
    default: {
      ...defaultHistoryItem,
    },
  }
  const res = historyDictionary[historyTypeDetect(historyObject)]
  res.item = historyObject
  return res
}
