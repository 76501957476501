import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEmpty from 'is-empty'
import i18next from '../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

// components
import Tag from '../../../../widgets/Tag'
import { getUserNameWithInitials } from '../../../../helpers/userHelper'
import { dateFormat } from '../../../../helpers/dateHelper'
import {
  organizationLocationStringify,
  organizationLocationStringifyFull,
} from '../../../../helpers/parserHelper'

const defaultUserSettings = require('../../../../config/defaultUserSettings')

const styles = theme => ({
  defaultText: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  inline: {
    display: 'inline',
  },
  heading: {
    fontSize: 16,
  },
  subHeadingDark: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 14,
  },
  subHeading: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
  },
  tagGridMargin: {
    marginRight: theme.spacing(2),
  },
})

const getSettings = (cardOf, userSettings) => {
  return cardOf === 'orders'
    ? userSettings.sd.orders_list
    : userSettings.sd.calls_list
}

const convertRadioValue = values => values[true]

const buildString = values =>
  _(values)
    .map(item => item[true])
    .compact()
    .join(', ')

const CardRow = withStyles(styles)(({ children, classes }) => (
  <Typography
    variant="subheading"
    component="div"
    className={classnames([classes.subHeading])}
  >
    {children}
  </Typography>
))

const DotSplitter = withStyles(styles)(({ classes }) => (
  <span className={classnames([classes.inline, classes.subHeading])}>
    {' '}
    &#8226;{' '}
  </span>
))

const renderRow = (data, prefix = null) => {
  const dataToShow = data.filter(item => !!item.value)
  return (
    <CardRow>
      {prefix}
      {!!prefix && dataToShow.length > 0 && <DotSplitter />}
      {dataToShow.map((item, index) => (
        <>
          {`${item.title}: ${item.value}`}
          {index + 1 < dataToShow.length && <DotSplitter />}
        </>
      ))}
    </CardRow>
  )
}

class RequestCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      userSettings: getSettings(props.cardOf, defaultUserSettings),
    }
  }

  componentDidMount = () => {
    const {
      props: { cardOf, userSettings },
    } = this
    if (!_.isEmpty(userSettings)) {
      this.setState({ userSettings: getSettings(cardOf, userSettings) })
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const {
      props: { cardOf, userSettings },
    } = this
    if (prevProps.userSettings !== userSettings && !_.isEmpty(userSettings)) {
      this.setState({ userSettings: getSettings(cardOf, userSettings) })
    }
  }

  render() {
    const {
      props: {
        cardOf,
        classes,
        status,
        internalNumber,
        externalNumber,
        typeOrder,
        callDate,
        orderDate,
        tags,
        resultOrder,
        engineer_user,
        visit_date,
        client_organization,
        performer_organization,
        organization_equipment,
        home,
        floor,
        office,
        street,
        reaction_date_begin,
        reaction_date_end,
      },
      state: { userSettings },
    } = this
    return (
      <div>
        {
          <Fragment>
            <Typography className={classes.defaultText} gutterBottom>
              {status === 'close' || status === 'cancel'
                ? `${i18next.t(
                    `service_desk_web_atoir:${cardOf}_${
                      cardOf === 'orders' &&
                      status === 'control' &&
                      typeOrder === 'repair'
                        ? 'done'
                        : status
                    }_status`,
                  )}`.toUpperCase()
                : `${i18next.t(
                    `service_desk_web_atoir:${cardOf}_${
                      cardOf === 'orders' &&
                      status === 'control' &&
                      typeOrder === 'repair'
                        ? 'done'
                        : status
                    }_status`,
                  )}`}
            </Typography>
            <Typography
              variant="h6"
              className={classes.heading}
              gutterBottom
              component="div"
            >
              {cardOf === 'calls'
                ? i18next.t('service_desk_web_atoir:callsListCardTitle', {
                    intNum: internalNumber,
                    dateFrom: callDate,
                  })
                : typeOrder === 'diagnostic'
                ? i18next.t(
                    'service_desk_web_atoir:diagnosticOrdersListCardTitle',
                    {
                      dateFrom: orderDate,
                    },
                  )
                : i18next.t(
                    'service_desk_web_atoir:repairOrdersListCardTitle',
                    { dateFrom: orderDate },
                  )}
            </Typography>
          </Fragment>
        }
        <div>
          {cardOf === 'calls' &&
          !isEmpty(externalNumber) &&
          userSettings.ext_num ? (
            <Typography
              variant="subheading"
              component="div"
              className={classnames([classes.inline, classes.subHeading])}
            >
              {i18next.t('service_desk_web_atoir:callsCardExternalNumber', {
                extNum: externalNumber,
              })}
            </Typography>
          ) : null}

          {cardOf === 'orders' &&
            renderRow(
              [
                {
                  title: 'Внешний №',
                  value: userSettings.ext_num && externalNumber,
                },
              ],
              i18next.t('service_desk_web_atoir:callsListCardTitle', {
                intNum: internalNumber,
                dateFrom: callDate,
              }),
            )}
        </div>
        {renderRow([
          {
            title: 'Желаемый период ремонта',
            value:
              userSettings.reaction_date &&
              `с ${dateFormat(reaction_date_begin)} по ${dateFormat(
                reaction_date_end,
              )}`,
          },
        ])}
        {renderRow([
          {
            title: 'Оборудование',
            value: buildString([
              { [userSettings.equipment.name]: organization_equipment.name },
              {
                [userSettings.equipment.vendor]: organization_equipment.vendor,
              },
              { [userSettings.equipment.model]: organization_equipment.model },
            ]),
          },
          {
            title: 'Сер.№',
            value:
              userSettings.equipment.serial_number &&
              organization_equipment.serial_number,
          },
          {
            title: 'Инв.№',
            value:
              userSettings.equipment.article_number &&
              organization_equipment.article_number,
          },
          {
            title: 'Внешний ID',
            value:
              userSettings.equipment.equipment_external_id &&
              organization_equipment.external_id,
          },
          {
            title: 'Фактический адрес',
            value: buildString([
              {
                [userSettings.equipment
                  .city]: `${street.City.city_type_fias}. ${street.City.name}`,
              },
              {
                [userSettings.equipment.street]: `${street.street_type_fias}. ${street.name}, ${home}`,
              },
              {
                [userSettings.equipment.office]: _([
                  floor
                      ? `${floor} ${i18next.t('equipment_web_atoir:floor').toLowerCase()}`
                      : null,
                  office
                      ? `${i18next.t('shared_web_atoir:office').toLowerCase()} ${office}`
                      : null,
                ]).compact().join(', '),
              },
            ]),
          },
        ])}
        {renderRow([
          {
            title: 'Организация-собственник оборудования',
            value: convertRadioValue({
              [userSettings.client_organization.short_name]: _.get(
                client_organization,
                'short_name',
              ),
              [userSettings.client_organization.full_name]: _.get(
                client_organization,
                'full_name',
              ),
            }),
          },
        ])}
        {renderRow([
          {
            title: 'Организация-исполнитель',
            value: convertRadioValue({
              [userSettings.performer_organization.short_name]: _.get(
                performer_organization,
                'short_name',
              ),
              [userSettings.performer_organization.full_name]: _.get(
                performer_organization,
                'full_name',
              ),
            }),
          },
        ])}
        {renderRow([
          {
            title: 'ФИО инженера',
            value:
              userSettings.engineer_user &&
              getUserNameWithInitials(engineer_user),
          },
          {
            title: 'Дата визита',
            value: userSettings.visit_date && dateFormat(visit_date),
          },
          {
            title: 'Результат',
            value:
              resultOrder && userSettings.order_result
                ? i18next.t(
                    `service_desk_web_atoir:order_status_${resultOrder}`,
                  )
                : null,
          },
        ])}
        {tags ? (
          <Fragment>
            <Grid container direction="row">
              {tags.map(tag => (
                <Grid item>
                  <Tag color={`#${tag.color}`} name={tag.name} />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        ) : null}
      </div>
    )
  }
}

RequestCard.defaultProps = {
  externalNumber: null,
  orderDate: null,
}

RequestCard.propTypes = {
  status: PropTypes.string.isRequired,
  cardOf: PropTypes.oneOf(['calls', 'orders']).isRequired,
  internalNumber: PropTypes.string.isRequired,
  externalNumber: PropTypes.string,
  typeOrder: PropTypes.oneOf(['diagnostic', 'repair']).isRequired,
  callDate: PropTypes.string.isRequired,
  orderDate: PropTypes.string,
  tags: PropTypes.array.isRequired,
}

export default withStyles(styles)(RequestCard)
