import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

// components
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'
import ChooseThirdPartyOrganization from '../ChooseThirdPartyOrganization'
import Heading from '../../../widgets/Lettering/Heading'
import FormDialog from '../../../widgets/FormDialog'

const ConfirmationDialog = ({
  open,
  onClose,
  organizationShortName,
  onConfirm,
}) => (
  <FormDialog
    open={open}
    isLoading={false}
    title=""
    onClose={onClose}
    onExit={onClose}
    onSubmit={onConfirm}
    submitButtonTitle={i18next.t('shared_web_atoir:yes')}
    secondaryButtonsArray={[
      {
        secondaryText: i18next.t('shared_web_atoir:no'),
        handleSecondaryAction: onClose,
      },
    ]}
  >
    {i18next.t('service_desk_web_atoir:assingnOrgAsPerformerOnCall', {
      organizationShortName,
    })}
  </FormDialog>
)

const styles = theme => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  containedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.dark,
      color: 'white',
    },
  },
  outlinedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: theme.palette.blueSecondary.main,
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
    '&:disabled': {
      borderColor: theme.palette.apply.extraLight,
      color: theme.palette.apply.extraLight,
    },
  },
})

class AssignToThirdPartyOrganization extends Component {
  state = {
    organization: null,
    emptyOrganization: null,
    confirmationDialogOpen: false,
    organizationShortName: null,
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isAssigning !== this.props.isAssigning &&
      this.props.isAssigning === false
    ) {
      this.props.onClose()
      this.props.hideDialogLoader()
      if (!this.props.assigningError) {
        this.props.onFinish()
      }
    }
  }

  onOrganizationChanged = organization => {
    this.setState({
      organization: organization.id,
      organizationShortName: organization.short_name,
    })
  }

  showConfirmationDialog = () => {
    const { organization } = this.state

    if (!organization) {
      this.setState({
        emptyOrganization: i18next.t(
          'service_desk_web_atoir:emptyOrganizationError',
        ),
      })
    } else {
      this.setState({ confirmationDialogOpen: true })
    }
  }

  hideConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: false })
  }

  onAssign = () => {
    const {
      state: { organization },
      props: { callId, assignToThirdPartyOrganization, showDialogLoader },
    } = this

    showDialogLoader()
    assignToThirdPartyOrganization({ callId, organization })
  }

  render() {
    const {
      state: { organization, confirmationDialogOpen, organizationShortName },
      props: { classes, open, onClose, callId, city, callClientOrganization },
      onOrganizationChanged,
      showConfirmationDialog,
      hideConfirmationDialog,
      onAssign,
    } = this
    return (
      <Fragment>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={hideConfirmationDialog}
          onConfirm={onAssign}
          organizationShortName={organizationShortName}
        />
        <Dialog
          classes={{ paper: classes.dialogRoot }}
          open={open}
          onClose={onClose}
        >
          <Heading>
            <Typography variant="h6" style={{ padding: 16 }}>
              {i18next.t('service_desk_web_atoir:assigningThirdPartyTitle')}
            </Typography>
          </Heading>
          <Divider />
          <DialogContent style={{ padding: 0, paddingTop: 16 }}>
            <ChooseThirdPartyOrganization
              city={city}
              clientOrganizationId={callClientOrganization}
              serviceCallId={callId}
              selected={organization}
              onOrganizationChanged={onOrganizationChanged}
            />
            <Typography style={{ padding: 12 }} color="error">
              {this.state.emptyOrganization}
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <OutlinedButton
              onClick={onClose}
              className={classes.outlinedButton}
            >
              {i18next.t('shared_web_atoir:cancel')}
            </OutlinedButton>
            <ContainedButton
              onClick={showConfirmationDialog}
              className={classes.containedButton}
              autoFocus
            >
              {i18next.t('service_desk_web_atoir:assignToThirdParty')}
            </ContainedButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

AssignToThirdPartyOrganization.defaultProps = {
  city: null,
  callClientOrganization: null,
}

AssignToThirdPartyOrganization.propTypes = {
  city: PropTypes.string,
  callClientOrganization: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callId: PropTypes.string.isRequired,
}

export default withStyles(styles)(AssignToThirdPartyOrganization)
