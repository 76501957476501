import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchServiceDeskCall } from '../../../../../../actions/serviceDesk'

export const useRecurringForm = ({
  unit = 'd',
  value = '',
  date = new Date(),
  dateEnd = new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
}) => {
  const [startDate, setStartDate] = useState(date)
  const [endDate, setEndDate] = useState(dateEnd)

  const [intervalUnit, setIntervalUnit] = useState(unit)

  const [intervalValue, setIntervalValue] = useState(value)
  const onChangeIntervalValue = useCallback(
    ({ target: { value: v } }) => {
      if (v) {
        const number = Number(v.replace(/[^0-9]/g, ''))
        if (Number.isInteger(number)) {
          const maxs = { d: 1825, m: 120, q: 40, y: 10 }
          setIntervalValue(
            number <= maxs[intervalUnit] ? number : maxs[intervalUnit],
          )
        }
        return
      }
      setIntervalValue(null)
    },
    [setIntervalValue, intervalUnit],
  )
  const onChangeIntervalUnit = useCallback(
    ({ target: { value: v } }) => {
      setIntervalUnit(v)
      setIntervalValue('')
    },
    [setIntervalUnit, setIntervalValue],
  )
  const onChangeStartDate = useCallback(
    d => {
      setStartDate(d)
      if (d && endDate && d.getTime() < endDate.getTime()) {
        setEndDate(null)
      }
    },
    [setStartDate],
  )
  const onChangeEndDate = useCallback(
    d => {
      setEndDate(d)
    },
    [setEndDate],
  )
  return {
    intervalUnit,
    intervalValue,
    startDate,
    endDate,
    onChangeIntervalValue,
    onChangeIntervalUnit,
    onChangeStartDate,
    onChangeEndDate,
  }
}

export const useCloseAfterSuccess = (
  isMaking,
  error,
  callId,
  handleClose,
  afterCloseFunction,
) => {
  const dispatch = useDispatch()
  const checkForSuccessfullCall = useCallback(
    _.after(2, isFetching => {
      if (isFetching === false && error === null) {
        handleClose()
        if (!afterCloseFunction) {
          dispatch(fetchServiceDeskCall(callId))
        } else {
          afterCloseFunction()
        }
      }
    }),
    [],
  )
  useEffect(() => {
    checkForSuccessfullCall(isMaking)
  }, [isMaking])
}

export const useDisabled = (intervalValue, startDate, endDate) =>
  useMemo(
    () =>
      Number.isNaN(+intervalValue) ||
      +intervalValue === 0 ||
      !startDate ||
      (endDate && endDate.getTime() < startDate.getTime()),
    [intervalValue, startDate, endDate],
  )
