import { takeLatest, put, fork, take } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import { createDefaultSaga, downloadFile } from '../helpers/api'
import {
  GET_BA_VARIANTS,
  FETCH_ORGANIZATION_EQUIPMENT_REQUEST,
  FETCH_ORGANIZATION_VENDORS_REQUEST,
  ADD_EQUIPMENT_UNIT_REQUEST,
  FETCH_COUNTRY_NAMES_REQUEST,
  FETCH_EQUIPMENT_PERMISSIONS_REQUEST,
  FETCH_EQUIPMENT_DETAILS_REQUEST,
  UPDATE_EQUIPMENT_DETAILS_REQUEST,
  FETCH_USER_EQUIPMENTS_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST,
  FETCH_USER_EQUIPMENTS_VENDORS_REQUEST,
  TRANSFER_EQUIPMENTS_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST,
  FETCH_USER_EQUIPMENT_COUNT_REQUEST,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST,
  FETCH_EQUIPMENT_LOGS_REQUEST,
  EQUIPMENT_LOGS_COUNT_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST,
  FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST,
  CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST,
  PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST,
  GET_BA_SUGGEST,
  REJECT_BA_SUGGESTIONS,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST,
  DOWNLOAD_MED_CERT,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
} from '../constants/actionTypes'
import {
  fetchOrganizationEquipmentRequest,
  fetchOrganizationVendorsRequest,
  addEquipmentUnitRequest,
  fetchCountryNamesRequest,
  fetchEquipmentPermissionsRequest,
  fetchEquipmentDetailsRequest,
  updateEquipmentDetailsRequest,
  transferEquipmentToOwnOrganizationRequest,
  transferEquipmentToForeignOrganizationRequest,
  fetchUserEquipmentRequest,
  fetchUserEquipmentVendorsRequest,
  fetchTransferringEquipmentRequest,
  transferEquipmentRequest,
  fetchOrganizationEquipmentCountRequest,
  fetchUserEquipmentCountRequest,
  equipmentLogsRequest,
  fetchEquipmentFilterLocationRequestApi,
  fetchEquipmentFilterDepartmentRequestApi,
  fetchTransferringEquipmentCountRequest,
  postMultipart,
  fetchBindedEquipment,
  URL,
  downloadFileCommonRequestApi,
  fetchOrganizationEquipmentTOWorks,
} from './api'
import {
  fetchOrganizationEquipmentFailure,
  fetchOrganizationEquipmentSuccess,
  fetchOrganizationVendorsFailure,
  fetchOrganizationVendorsSuccess,
  addEquipmentUnitSuccess,
  addEquipmentUnitFailure,
  fetchCountryNamesFailure,
  fetchCountryNamesSuccess,
  fetchEquipmentPermissionsSuccess,
  fetchEquipmentPermissionsFailure,
  fetchEquipmentDetailsSuccess,
  fetchEquipmentDetailsFailure,
  updateEquipmentDetailsSuccess,
  updateEquipmentDetailsFailure,
  fetchUserEquipmentSuccess,
  fetchUserEquipmentFailure,
  fetchTransferringEquipmentSenderFailure,
  fetchTransferringEquipmentSenderSuccess,
  fetchTransferringEquipmentReceiverFailure,
  fetchTransferringEquipmentReceiverSuccess,
  fetchUserEquipmentVendorsSuccess,
  fetchUserEquipmentVendorsFailure,
  transferEquipmentSuccess,
  transferEquipmentFailure,
  fetchOrganizationEquipmentCountSuccess,
  fetchOrganizationEquipmentCountFailure,
  fetchUserEquipmentCountFailure,
  fetchUserEquipmentCountSuccess,
  transferEquipmentToOwnOrganizationSuccess,
  transferEquipmentToOwnOrganizationFailure,
  transferEquipmentToForeignOrganizationSuccess,
  transferEquipmentToForeignOrganizationFailure,
  fetchEquipmentLogsSuccess,
  fetchEquipmentLogsFailure,
  equipmentLogsCountSuccess,
  equipmentLogsCountFailure,
  fetchTransferringEquipmentSenderCountFailure,
  fetchTransferringEquipmentSenderCountSuccess,
  fetchTransferringEquipmentReceiverCountFailure,
  fetchTransferringEquipmentReceiverCountSuccess,
  fetchEquipmentFilterLocationSuccess,
  fetchEquipmentFilterLocationFailure,
  fetchEquipmentFilterDepartmentSuccess,
  fetchEquipmentFilterDepartmentFailure,
  checkEquipmentsImportFileSuccess,
  checkEquipmentsImportFileFailure,
  processEquipmentsImportFileSuccess,
  processEquipmentsImportFileFailure,
  getBAVariants,
  getBASuggest,
  rejectBaSuggestions,
  fetchOrganizationBindedEquipmentSuccess,
  fetchOrganizationBindedEquipmentFailure,
  fetchOrganizationEquipmentTOWorksSuccess,
  fetchOrganizationEquipmentTOWorksFailure,
} from '../actions/equipmentActions'
import {
  catchError,
  enqueueSnackbar,
  receivedErrorMessage,
} from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
import { executeReq, get, post } from './appSaga'
import i18n from '../i18n/i18n'

import queryString from 'query-string'
import getNotifierButton from '../components/App/NotifierButton'

export function* watchProcessEquipmentsImportFile() {
  yield takeLatest(
    PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST,
    createDefaultSaga(
      payload =>
        postMultipart(
          `/organizations/${payload.organizationId}/equipments/upload_list/upload`,
          payload.formData,
        ),
      result => processEquipmentsImportFileSuccess(result),
      code => processEquipmentsImportFileFailure(code),
      true,
    ),
  )
}

export function* watchCheckEquipmentsImportFile() {
  yield takeLatest(
    CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST,
    createDefaultSaga(
      payload =>
        postMultipart(
          `/organizations/${payload.organizationId}/equipments/upload_list/check`,
          payload.formData,
        ),
      result => checkEquipmentsImportFileSuccess(result),
      code => checkEquipmentsImportFileFailure(code),
      true,
    ),
  )
}

export function* watchFetchEqupmentBinded() {
  yield takeLatest(
    FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST,
    createDefaultSaga(
      payload => fetchBindedEquipment(payload),
      result => fetchOrganizationBindedEquipmentSuccess(result.equipment),
      code => fetchOrganizationBindedEquipmentFailure(code),
      false,
    ),
  )
}

export function* watchFetchEqupmentBindedTOWorks() {
  yield takeLatest(
    FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
    createDefaultSaga(
      payload => fetchOrganizationEquipmentTOWorks(payload),
      result =>
        fetchOrganizationEquipmentTOWorksSuccess(
          result.tos[0] ? result.tos[0].BAEquipmentTOWorks : [],
        ),
      code => fetchOrganizationEquipmentTOWorksFailure(code),
      false,
    ),
  )
}

export function* watchMedCertificateSaga() {
  yield takeLatest(DOWNLOAD_MED_CERT, downloadMedCertificateRequest)
}

function* downloadMedCertificateRequest({ payload }) {
  try {
    // eslint-disable-next-line max-len
    const url = `${URL}/organizations/${payload.organizationId}/equipments/${payload.equipmentId}/binded_ba/industry/${payload.id}/doc`
    const result = yield call(downloadFileCommonRequestApi({ ...payload, url }))
    if (!isEmpty(result.success)) {
      if (result.success) {
        downloadFile(result.name || payload.name, result.blob)
      } else if (result.code === 400) {
        yield put(
          enqueueSnackbar({
            message: i18n.t('shared_web_atoir:downloadFailed'),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              persist: true,
              action: getNotifierButton,
            },
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

export function* watchFetchEquipmentSaga() {
  yield takeLatest(FETCH_ORGANIZATION_EQUIPMENT_REQUEST, fetchEquipment)
}

export function* watchFetchEquipmentCountSaga() {
  yield takeLatest(
    FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST,
    fetchEquipmentCount,
  )
}

export function* watchFetchUserEquipmentCountSaga() {
  yield takeLatest(FETCH_USER_EQUIPMENT_COUNT_REQUEST, fetchUserEquipmentCount)
}

export function* watchFetchTransferringEquipmentSenderSaga() {
  yield takeLatest(
    FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST,
    fetchTransferringEquipmentSender,
  )
}

export function* watchFetchTransferringEquipmentSenderCountSaga() {
  yield takeLatest(
    FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST,
    fetchTransferringEquipmentSenderCount,
  )
}

export function* watchFetchUserEquipmentSaga() {
  yield takeLatest(FETCH_USER_EQUIPMENTS_REQUEST, fetchUserEquipment)
}

export function* watchFetchTransferringEquipmentReceiverSaga() {
  yield takeLatest(
    FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST,
    fetchTransferringEquipmentReceiver,
  )
}

export function* watchFetchTransferringEquipmentReceiverCountSaga() {
  yield takeLatest(
    FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST,
    fetchTransferringEquipmentReceiverCount,
  )
}

export function* watchTransferringEquipmentSaga() {
  yield takeLatest(TRANSFER_EQUIPMENTS_REQUEST, transferEquipment)
}

export function* watchAddEquipmentUnitSaga() {
  yield takeLatest(ADD_EQUIPMENT_UNIT_REQUEST, addEquipmentUnit)
}

export function* watchFetchVendorsSaga() {
  yield takeLatest(FETCH_ORGANIZATION_VENDORS_REQUEST, fetchVendors)
}

export function* watchFetchUserEquipmentVendorsSaga() {
  yield takeLatest(
    FETCH_USER_EQUIPMENTS_VENDORS_REQUEST,
    fetchUserEquipmentVendors,
  )
}

export function* watchFetchCountryNamesSaga() {
  yield takeLatest(FETCH_COUNTRY_NAMES_REQUEST, fetchCountries)
}

export function* watchFetchEquipmentPermissionsSaga() {
  yield takeLatest(
    FETCH_EQUIPMENT_PERMISSIONS_REQUEST,
    fetchEquipmentPermissions,
  )
}

export function* watchFetchEquipmentDetailsSaga() {
  yield takeLatest(FETCH_EQUIPMENT_DETAILS_REQUEST, fetchEquipmentDetails)
}

export function* watchUpdateEquipmentDetailsSaga() {
  yield takeLatest(UPDATE_EQUIPMENT_DETAILS_REQUEST, updateEquipmentDetails)
}

export function* watchTransferEquipmentToOwnOrganizationSaga() {
  yield takeLatest(
    TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST,
    transferEquipmentToOwnOrganization,
  )
}

export function* watchTransferEquipmentToForeignOrganizationSaga() {
  yield takeLatest(
    TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST,
    transferEquipmentToForeignOrganization,
  )
}

export function* watchFetchEquipmentLogsSaga() {
  yield takeLatest(FETCH_EQUIPMENT_LOGS_REQUEST, fetchEquipmentLogs)
}

export function* watchEquipmentLogsCountSaga() {
  yield takeLatest(EQUIPMENT_LOGS_COUNT_REQUEST, equipmentLogsCount)
}

export function* watchFetchEquipmentFilterLocationRequestSaga() {
  yield takeLatest(
    FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST,
    fetchEquipmentFilterLocationRequest,
  )
}
export function* watchFetchEquipmentFilterDepartmentRequestSaga() {
  yield takeLatest(
    FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST,
    fetchEquipmentFilterDepartmentRequest,
  )
}

function* fetchEquipment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationEquipmentRequest(
        accessToken,
        action.organizationId,
        action.data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          fetchOrganizationEquipmentSuccess(result.equipment, result.count),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationEquipmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEquipmentCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationEquipmentCountRequest(
        accessToken,
        action.organizationId,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationEquipmentCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationEquipmentCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserEquipmentCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserEquipmentCountRequest(accessToken, action.filters),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserEquipmentCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserEquipmentCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserEquipment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserEquipmentRequest(accessToken, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserEquipmentSuccess(result.equipments, result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserEquipmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchTransferringEquipmentSender(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchTransferringEquipmentRequest(accessToken, 'sender', action.query),
    )

    if (!isEmpty(result)) {
      if (result.success) {
        yield put(
          fetchTransferringEquipmentSenderSuccess(
            result.transfers,
            result.count,
          ),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchTransferringEquipmentSenderFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchTransferringEquipmentSenderCount() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchTransferringEquipmentCountRequest(accessToken, 'sender'),
    )

    if (!isEmpty(result)) {
      if (result.success) {
        yield put(fetchTransferringEquipmentSenderCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchTransferringEquipmentSenderCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchTransferringEquipmentReceiver(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchTransferringEquipmentRequest(accessToken, 'receiver', action.query),
    )

    if (!isEmpty(result)) {
      if (result.success) {
        yield put(
          fetchTransferringEquipmentReceiverSuccess(
            result.transfers,
            result.count,
          ),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchTransferringEquipmentReceiverFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchTransferringEquipmentReceiverCount() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchTransferringEquipmentCountRequest(accessToken, 'receiver'),
    )

    if (!isEmpty(result)) {
      if (result.success) {
        yield put(fetchTransferringEquipmentReceiverCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchTransferringEquipmentReceiverCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* transferEquipment(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      transferEquipmentRequest(
        accessToken,
        action.transferType,
        action.organizationId,
        action.transferId,
        action.locationId,
        action.departmentId,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(transferEquipmentSuccess(result.transfers))
      } else {
        const errors = handleErrors(result)
        yield put(transferEquipmentFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* addEquipmentUnit(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      addEquipmentUnitRequest(
        accessToken,
        action.organizationId,
        action.equipmentData,
      ),
    )

    if (!isEmpty(result)) {
      if (result.success) {
        yield put(
          addEquipmentUnitSuccess(result.organization_id, result.equipment_id),
        )
      } else {
        const errors = handleErrors(result)
        yield put(addEquipmentUnitFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchVendors(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationVendorsRequest(accessToken, action.organizationId),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationVendorsSuccess(result.vendors))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationVendorsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserEquipmentVendors() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchUserEquipmentVendorsRequest(accessToken))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserEquipmentVendorsSuccess(result.vendors))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserEquipmentVendorsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchCountries() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchCountryNamesRequest(accessToken))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchCountryNamesSuccess(result.countries))
      } else {
        const errors = handleErrors(result)
        yield put(fetchCountryNamesFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEquipmentPermissions(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchEquipmentPermissionsRequest(accessToken, action.id),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchEquipmentPermissionsSuccess(result.permissions))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEquipmentPermissionsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEquipmentDetails(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchEquipmentDetailsRequest(
        accessToken,
        action.organizationId,
        action.equipmentId,
      ),
    )

    if (!isEmpty(result)) {
      if (result.success) {
        yield put(fetchEquipmentDetailsSuccess(result.equipment))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEquipmentDetailsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateEquipmentDetails(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, equipmentId, data } = action
    const result = yield call(
      updateEquipmentDetailsRequest(
        accessToken,
        organizationId,
        equipmentId,
        data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateEquipmentDetailsSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(updateEquipmentDetailsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* transferEquipmentToOwnOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, transferData } = action
    const result = yield call(
      transferEquipmentToOwnOrganizationRequest(
        accessToken,
        organizationId,
        transferData,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(transferEquipmentToOwnOrganizationSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(transferEquipmentToOwnOrganizationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* transferEquipmentToForeignOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, transferData } = action
    const result = yield call(
      transferEquipmentToForeignOrganizationRequest(
        accessToken,
        organizationId,
        transferData,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(transferEquipmentToForeignOrganizationSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(transferEquipmentToForeignOrganizationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEquipmentLogs(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { orgId, eqpId, filters } = action
    const result = yield call(
      equipmentLogsRequest(accessToken, orgId, eqpId, filters),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchEquipmentLogsSuccess(result.equipmentLogs))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEquipmentLogsFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* equipmentLogsCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { orgId, eqpId, filters } = action
    const result = yield call(
      equipmentLogsRequest(accessToken, orgId, eqpId, filters, 'count'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(equipmentLogsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(equipmentLogsCountFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEquipmentFilterLocationRequest(actionData) {
  try {
    const result = yield call(
      fetchEquipmentFilterLocationRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchEquipmentFilterLocationSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEquipmentFilterLocationFailure(actionData.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEquipmentFilterDepartmentRequest(actionData) {
  try {
    const result = yield call(
      fetchEquipmentFilterDepartmentRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchEquipmentFilterDepartmentSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEquipmentFilterDepartmentFailure(actionData.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* watchGetBAVariants() {
  while (true) {
    const { payload } = yield take(GET_BA_VARIANTS.REQUEST)
    yield executeReq(
      get(`/bo/equipments/list/equipments?${queryString.stringify(payload)}`),
      getBAVariants.FAILURE,
      getBAVariants.SUCCESS,
      result => result,
    )
  }
}

function* watchGetBASuggest() {
  while (true) {
    const { payload } = yield take(GET_BA_SUGGEST.REQUEST)
    yield executeReq(
      get(`/bo/equipments/suggest?${queryString.stringify(payload)}`),
      getBASuggest.FAILURE,
      getBASuggest.SUCCESS,
      result => result,
    )
  }
}

function* watchRejectBaSuggestions() {
  while (true) {
    const {
      payload: { equipmentId, organizationId },
    } = yield take(REJECT_BA_SUGGESTIONS.REQUEST)
    yield executeReq(
      post(
        `/organizations/${organizationId}/equipments/${equipmentId}/reject_suggestions`,
      ),
      rejectBaSuggestions.FAILURE,
      rejectBaSuggestions.SUCCESS,
      result => result,
    )
  }
}

export default function* root() {
  yield fork(watchGetBAVariants)
  yield fork(watchGetBASuggest)
  yield fork(watchRejectBaSuggestions)
}
