import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../i18n/i18n'
import config from '../../../config'
import Loader from '../../Loader'
import BreadCrumb from '../../App/routing/BreadCrumb'
import { popLastSections } from '../../../helpers/dataHelper'
import TextButton from '../../../widgets/Buttons/TextButton'
import classnames from 'classnames'
import {
  dateFormatShortYear,
  timeFormatHourMinutes,
} from '../../../helpers/dateHelper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import { getUserNameWithInitials } from '../../../helpers/userHelper'

const styles = theme => ({
  rootWithPadding: {
    padding: 12,
  },
  dividerWithMargin: {
    marginTop: 8,
    marginBottom: 8,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  editEquipmentDialogRoot: {
    width: '70%',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 8px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  textFieldMulty: {
    width: '500px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  grayText: {
    color: theme.palette.grey[500],
  },
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  listRoot: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  listItem: {
    backgroundColor: '#fff',
  },
  inline: {
    display: 'inline',
  },
  heading: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  subHeading: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  ellipse: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
})

class SDCallActionsHistory extends Component {
  state = {
    offset: 0,
    limit: config.default_service_call_history_limit,
  }

  componentDidMount = () => {
    const {
      props: {
        fetchCallPermissions,
        match: {
          params: { id },
        },
      },
      fetchHistory,
    } = this
    fetchCallPermissions(id)
    fetchHistory()
  }

  componentDidUpdate = prevProps => {
    const {
      props: { isFetchingPermissions, isFetchingCall },
    } = this

    if (
      prevProps.isFetchingPermissions !== isFetchingPermissions &&
      !isFetchingPermissions
    ) {
      if (this.props.permissionsError || !this.props.permissions.ANY) {
        history.push('/404')
        return
      }
      this.props.fetchServiceDeskCall(this.props.match.params.id)
    }

    if (prevProps.isFetchingCall !== isFetchingCall && !isFetchingCall) {
      this.props.getTitleInfo({
        title: i18next.t('service_desk_web_atoir:scriptsAndButtonsChangeLog'),
      })
    }
  }

  componentWillUnmount = () => {
    this.props.clearActionsHistory()
  }

  fetchHistory = () => {
    const {
      state: { offset, limit },
      props: {
        fetchActionsHistory,
        match: {
          params: { id },
        },
      },
    } = this
    fetchActionsHistory({ id, query: { offset, limit } })
  }

  loadMore = () => {
    this.setState(
      state => ({
        offset: state.offset + state.limit,
      }),
      this.fetchHistory,
    )
  }

  render() {
    const {
      props: {
        match: {
          params: { id: callId },
          url,
        },
        classes,
        call,
        isFetchingCall,
        isFetchingPermissions,
        permissions,
        history,
        end,
      },
      loadMore,
    } = this
    return isFetchingCall || isFetchingPermissions ? (
      <Loader />
    ) : (
      !isEmpty(call) && !isEmpty(permissions) && call.id === callId && (
        <Fragment>
          <BreadCrumb length={29} to={popLastSections(url)}>
            {i18next.t('service_desk_web_atoir:titleCallCard', {
              date: new Date(
                Date.parse(this.props.call.createdAt),
              ).toLocaleDateString('ru'),
              internalNum: this.props.call.internal_num,
            })}
          </BreadCrumb>
          <BreadCrumb length={29} to={url}>
            {i18next.t('service_desk_web_atoir:scriptsAndButtonsChangeLog')}
          </BreadCrumb>
          <List className={classes.listRoot}>
            {history.map((historyLog, index) => (
              <Fragment>
                <ListItem className={classes.listItem}>
                  <div>
                    <div className={classes.inline}>
                      <Typography
                        component="span"
                        className={classnames([
                          classes.inline,
                          classes.heading,
                        ])}
                      >
                        {getUserNameWithInitials(historyLog.User)}
                      </Typography>
                      <span
                        className={classnames([
                          classes.inline,
                          classes.ellipse,
                        ])}
                      >
                        {' '}
                        &#8226;{' '}
                      </span>
                      <Typography
                        component="span"
                        className={classnames([
                          classes.inline,
                          classes.subHeading,
                        ])}
                      >
                        {dateFormatShortYear(historyLog.executed_at)}
                      </Typography>
                      <span
                        className={classnames([
                          classes.inline,
                          classes.ellipse,
                        ])}
                      >
                        {' '}
                        &#8226;{' '}
                      </span>
                      <Typography
                        component="span"
                        className={classnames([
                          classes.inline,
                          classes.subHeading,
                        ])}
                      >
                        {timeFormatHourMinutes(historyLog.executed_at)}
                      </Typography>
                    </div>
                    <br />
                    <div>
                      <Typography
                        component="span"
                        className={classnames([classes.inline, classes.text])}
                      >
                        {i18next.t(
                          `service_desk_web_atoir:${historyLog.parent_type}ScriptsAndButtonsAction`,
                          {
                            internalNum: historyLog.ServiceCall.internal_num,
                            actionName: historyLog.name,
                          },
                        )}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
                {history && history.length !== index + 1 && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            ))}
          </List>
          {!end && (
            <TextButton onClick={loadMore}>
              {i18next.t('service_desk_web_atoir:show_more')}
            </TextButton>
          )}
        </Fragment>
      )
    )
  }
}

export default withStyles(styles)(SDCallActionsHistory)
