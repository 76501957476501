export const CREATE_SCRIPT_REQUEST = 'scripts/CREATE_SCRIPT_REQUEST'
export const CREATE_SCRIPT_SUCCESS = 'scripts/CREATE_SCRIPT_SUCCESS'
export const CREATE_SCRIPT_FAILURE = 'scripts/CREATE_SCRIPT_FAILURE'

export const EDIT_SCRIPT_REQUEST = 'scripts/EDIT_SCRIPT_REQUEST'
export const EDIT_SCRIPT_SUCCESS = 'scripts/EDIT_SCRIPT_SUCCESS'
export const EDIT_SCRIPT_FAILURE = 'scripts/EDIT_SCRIPT_FAILURE'

export const GET_SCRIPTS_REQUEST = 'scripts/GET_SCRIPTS_REQUEST'
export const GET_SCRIPTS_SUCCESS = 'scripts/GET_SCRIPTS_SUCCESS'
export const GET_SCRIPTS_FAILURE = 'scripts/GET_SCRIPTS_FAILURE'

export const DELETE_SCRIPT_REQUEST = 'scripts/DELETE_SCRIPT_REQUEST'
export const DELETE_SCRIPT_SUCCESS = 'scripts/DELETE_SCRIPT_SUCCESS'
export const DELETE_SCRIPT_FAILURE = 'scripts/DELETE_SCRIPT_FAILURE'

export const GET_SCRIPT_REQUEST = 'scripts/GET_SCRIPT_REQUEST'
export const GET_SCRIPT_SUCCESS = 'scripts/GET_SCRIPT_SUCCESS'
export const GET_SCRIPT_FAILURE = 'scripts/GET_SCRIPT_FAILURE'

export const GET_SCRIPTS_HISTORY_REQUEST = 'scripts/GET_SCRIPTS_HISTORY_REQUEST'
export const GET_SCRIPTS_HISTORY_SUCCESS = 'scripts/GET_SCRIPTS_HISTORY_SUCCESS'
export const GET_SCRIPTS_HISTORY_FAILURE = 'scripts/GET_SCRIPTS_HISTORY_FAILURE'
export const CLEAR_SCRIPTS_HISTORY = 'scripts/CLEAR_SCRIPTS_HISTORY'

export const GET_SD_CALL_AVAILABLE_SCRIPTS_REQUEST =
  'scripts/GET_SD_CALL_AVAILABLE_SCRIPTS_REQUEST'
export const GET_SD_CALL_AVAILABLE_SCRIPTS_SUCCESS =
  'scripts/GET_SD_CALL_AVAILABLE_SCRIPTS_SUCCESS'
export const GET_SD_CALL_AVAILABLE_SCRIPTS_FAILURE =
  'scripts/GET_SD_CALL_AVAILABLE_SCRIPTS_FAILURE'
