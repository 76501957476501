import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Sticky from 'react-stickynode'
import shortid from 'shortid'
import _ from 'lodash'
import classnames from 'classnames'

// material UI
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles/index'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import config from '../../../../config'

const drawerWidth = config.right_navigation_drawer_width

const styles = theme => ({
  drawerPaper: {
    position: 'absolute',
    zIndex: 1000,
    width: drawerWidth,
    backgroundColor: 'transparent',
    border: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerList: {
    paddingTop: theme.spacing(1),
  },
  drawerItem: {},
  ListItemChild: {
    padding: '0px',
    marginRight: '4px',
  },
  nested1: {
    paddingLeft: theme.spacing(4),
  },
  nested2: {
    paddingLeft: theme.spacing(6),
  },
  linkItem: {
    paddingBottom: 4,
    paddingTop: 4,
    fontSize: theme.typography.pxToRem(14),
    '&:hover': { cursor: 'pointer' },
    color: 'rgba(0,0,0,0.87)',
  },
  '@media (min-width: 1918px)': {
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'transparent',
    },
  },
  titleItem: {
    paddingBottom: 4,
    paddingTop: 4,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    '&:hover': { cursor: 'pointer' },
  },
})

class DrawerNavigationComponent extends Component {
  state = {}

  linkClicked = hash => () => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }, 0)
    }
  }

  render = () => {
    const { classes, component } = this.props
    return (
      component && (
        <nav>
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              anchor="right"
              open
            >
              <div className={classes.drawerHeader} />
              <Sticky>
                <List style={{ padding: '16px auto auto auto' }}>
                  {component.map(c =>
                    !React.isValidElement(c) ? (
                      _.has(c, 'component') && c.component === 'nestedList' ? (
                        <List
                          subheader={
                            <ListItem
                              className={classes.titleItem}
                              onClick={this.linkClicked(
                                c.listTitleAnchor.replace('#', ''),
                              )}
                              variant="body2"
                              key={shortid.generate()}
                              color="textPrimary"
                            >
                              {c.listTitle}
                            </ListItem>
                          }
                          component="div"
                          disablePadding
                        >
                          {c.listItems.map(n => (
                            <ListItem
                              inset
                              className={classnames([
                                classes.linkItem,
                                classes.nested1,
                              ])}
                              onClick={this.linkClicked(
                                n.anchor.replace('#', ''),
                              )}
                              variant="body2"
                              key={shortid.generate()}
                              color="textPrimary"
                            >
                              {n.title}
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <ListItem
                          className={classes.linkItem}
                          onClick={this.linkClicked(c.anchor.replace('#', ''))}
                          variant="body2"
                          key={shortid.generate()}
                          color="textPrimary"
                        >
                          {c.title}
                        </ListItem>
                      )
                    ) : (
                      <ListItem
                        style={{ paddingBottom: 4, paddingTop: 4 }}
                        key={shortid.generate()}
                      >
                        {c}
                      </ListItem>
                    ),
                  )}
                </List>
              </Sticky>
            </Drawer>
          </Hidden>
        </nav>
      )
    )
  }
}

DrawerNavigationComponent.defaultProps = {
  component: null,
}

DrawerNavigationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.array,
}

export default withStyles(styles)(DrawerNavigationComponent)
