import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import {
  LOGIN_REQUEST,
  REFRESH_TOKENS_REQUEST,
  LOGOUT,
} from '../constants/actionTypes'
import { call } from './loginMiddlewares'
import { loginRequest, refreshTokensRequest, logoutRequest } from './api'
import { unsubscribeFromNotifications } from '../pushNotifications'

import {
  loginSuccess,
  loginFailure,
  refreshTokensSuccess,
  refreshTokensFailure,
  logoutSuccess,
  logout as logoutAction,
  logoutFailure,
} from '../actions/loginActions'
import {
  catchError,
  closeErrorMessage,
  receivedErrorMessage,
} from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
import { setTokens, removeTokens } from '../helpers/tokensHelper'

export function* watchLoginSaga() {
  yield takeLatest(LOGIN_REQUEST, login)
}

export function* watchRefreshTokenSaga() {
  yield takeLatest(REFRESH_TOKENS_REQUEST, refreshTokens)
}

export function* watchLogoutSaga() {
  yield takeLatest(LOGOUT, logout)
}

function* login(action) {
  let result
  try {
    result = yield call(loginRequest(action.email, action.password))

    if (!isEmpty(result.success)) {
      if (result.success) {
        setTokens(result)
        localStorage.setItem('sdBannersConfig', JSON.stringify({}))
        yield put(closeErrorMessage())
        yield put(loginSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.email) || !isEmpty(errors.password)) {
          yield put(loginFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* refreshTokens() {
  try {
    localStorage.setItem('updatingtoken', Date.now())
    const refreshToken = localStorage.getItem('atoirRefreshToken')
    const result = yield call(refreshTokensRequest(refreshToken))
    if (!isEmpty(result.success)) {
      if (result.success) {
        setTokens(result)
        yield put(refreshTokensSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(refreshTokensFailure(errors))
        yield put(logoutAction())
        yield put(receivedErrorMessage(errors))
      }
    }
    localStorage.removeItem('updatingtoken')
  } catch (error) {
    localStorage.removeItem('updatingtoken')
    yield put(catchError(error.message))
  }
}

function* logout(action) {
  unsubscribeFromNotifications()
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    // TODO: refactor - pass in unsubscribe accToken
    setTimeout(() => removeTokens(), 100)
    if (action.noRequest) {
      yield put(logoutSuccess())
    } else {
      const result = yield call(logoutRequest(accessToken))
      if (!isEmpty(result.success)) {
        if (result.success) {
          yield put(logoutSuccess())
        } else {
          const errors = handleErrors(result)
          yield put(logoutFailure(errors))
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
