import React, { Component, Fragment } from 'react'
import RLink from 'react-router-dom/Link'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import Loader from '../../../Loader'
import RatingStars from '../../../Organizations/Stateless/RatingStars'

// styles
import '../../../../styles/components/Staff.css'

// components
import DropDownList from '../../../../widgets/DropDownList'
import DateInput from '../../../../widgets/DateInput'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const toolbarStyles = theme => ({
  root: {
    flex: '1 1 100%',
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 200,
  },
  formControlHeight: {
    minHeight: 70,
  },
  formLabel: {
    textAlign: 'left',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  title: {
    flex: '0 0 auto',
  },
  dateInput: {
    margin: theme.spacing(1),
  },
})

class InventoryTableToolbar extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      projectCreatedBegin: '',
      projectCreatedEnd: '',
      desiredPeriodEndBegin: '',
      desiredPeriodEndEnd: '',
      qr: false,
      rfid: false,
      inventoryKitOpen: false,
      inventoryKit: '',
      clientsOpen: false,
      client: '',
      performersOpen: false,
      performer: '',
      citiesOpen: false,
      city: '',
    }
  }

  equipmentPresence = [
    { id: 'yes', label: i18next.t('shared_web_atoir:yes') },
    { id: 'no', label: i18next.t('shared_web_atoir:no') },
  ]

  componentDidUpdate = prevPorps => {
    const {
      props: { requestor, status },
    } = this
    if (prevPorps.requestor !== requestor) {
      this.setState({
        projectCreatedBegin: '',
        projectCreatedEnd: '',
        desiredPeriodEndBegin: '',
        desiredPeriodEndEnd: '',
        qr: false,
        rfid: false,
        inventoryKitOpen: false,
        inventoryKit: '',
        clientsOpen: false,
        client: '',
        performersOpen: false,
        performer: '',
        citiesOpen: false,
        city: '',
      })
    }

    if (prevPorps.status !== status) {
      this.setState({
        projectCreatedBegin: '',
        projectCreatedEnd: '',
        desiredPeriodEndBegin: '',
        desiredPeriodEndEnd: '',
        qr: false,
        rfid: false,
        inventoryKitOpen: false,
        inventoryKit: '',
        clientsOpen: false,
        client: '',
        performersOpen: false,
        performer: '',
        citiesOpen: false,
        city: '',
      })
    }
  }

  handleCheckboxChange = name => event => {
    const { checked } = event.target
    const {
      props: { updateFilters },
    } = this
    this.setState({ [name]: checked }, () => {
      if (checked) {
        updateFilters(name, checked)
      } else {
        updateFilters(name, _)
      }
    })
  }

  handleChange = field => event => {
    const { value } = event.target
    const {
      props: { updateFilters },
    } = this
    this.setState({ [field]: value }, () => {
      if (field === 'inventoryKit') {
        const val = value === 'yes' ? true : value === 'no' ? false : ''
        updateFilters('inventory_kit', val)
      } else if (field === 'client') {
        updateFilters('client_organization_id', value)
      } else if (field === 'performer') {
        updateFilters('performer_organization_id', value)
      } else if (field === 'city') {
        updateFilters(field, value)
      }
    })
  }

  handleDateChange = field => selectedDate => {
    const {
      props: { updateFilters },
    } = this
    const date = !(selectedDate === null || selectedDate === '')
      ? new Date(selectedDate.setHours(0))
      : ''

    this.setState({ [field]: date }, () => {
      if (field === 'projectCreatedBegin') {
        updateFilters('date_created_begin', date)
      } else if (field === 'projectCreatedEnd') {
        updateFilters('date_created_end', date)
      } else if (field === 'desiredPeriodEndBegin') {
        updateFilters('desired_period_end_begin', date)
      } else if (field === 'desiredPeriodEndEnd') {
        updateFilters('desired_period_end_end', date)
      }
    })
  }

  handleOpen = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleClose = field => () => {
    this.setState({
      [field]: false,
    })
  }

  render() {
    const {
      props: {
        classes,
        isFetchingCities,
        cities,
        isFetchingClients,
        clients,
        isFetchingPerformers,
        performers,
      },
      state: {
        projectCreatedBegin,
        projectCreatedEnd,
        desiredPeriodEndBegin,
        desiredPeriodEndEnd,
        qr,
        rfid,
        inventoryKitOpen,
        inventoryKit,
        clientsOpen,
        client,
        performersOpen,
        performer,
        citiesOpen,
        city,
      },
    } = this
    return (
      <Toolbar className={classes.root}>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          justify="space-between"
        >
          <Grid item xs={3}>
            <FormControl className={classes.formControlHeight}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:creationDatePeriod')}
              </FormLabel>
              <Grid container spacing={1} direction="row">
                <Grid item xs={6}>
                  <DateInput
                    className={classes.dateInput}
                    value={projectCreatedBegin}
                    name="projectCreatedBegin"
                    handleChange={this.handleDateChange('projectCreatedBegin')}
                    requiredValue={false}
                    label={i18next.t(
                      'inventory_web_atoir:inventoryDesiredPeriodBegin',
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    className={classes.dateInput}
                    value={projectCreatedEnd}
                    name="projectCreatedEnd"
                    handleChange={this.handleDateChange('projectCreatedEnd')}
                    requiredValue={false}
                    label={i18next.t(
                      'inventory_web_atoir:inventoryDesiredPeriodEnd',
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <FormControl className={classes.formControlHeight}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:completionDatePeriod')}
              </FormLabel>
              <Grid container spacing={1} direction="row">
                <Grid item xs={6}>
                  <DateInput
                    className={classes.dateInput}
                    value={desiredPeriodEndBegin}
                    name="desiredPeriodEndBegin"
                    handleChange={this.handleDateChange(
                      'desiredPeriodEndBegin',
                    )}
                    requiredValue={false}
                    label={i18next.t(
                      'inventory_web_atoir:inventoryDesiredPeriodBegin',
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    className={classes.dateInput}
                    value={desiredPeriodEndEnd}
                    name="desiredPeriodEndEnd"
                    handleChange={this.handleDateChange('desiredPeriodEndEnd')}
                    requiredValue={false}
                    label={i18next.t(
                      'inventory_web_atoir:inventoryDesiredPeriodEnd',
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              direction="column"
              spacing={1}
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <FormControl fullWidth className={classes.formControlHeight}>
                  <FormLabel className={classes.formLabel}>
                    {i18next.t('inventory_web_atoir:inventoryType')}
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={qr}
                          onChange={this.handleCheckboxChange('qr')}
                          value={qr}
                        />
                      }
                      label={i18next.t('inventory_web_atoir:qr')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rfid}
                          onChange={this.handleCheckboxChange('rfid')}
                          value={rfid}
                        />
                      }
                      label={i18next.t('inventory_web_atoir:rfid')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth className={classes.formControlHeight}>
                  <FormLabel className={classes.formLabel}>
                    {i18next.t('inventory_web_atoir:inventoryKitPresence')}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    open={inventoryKitOpen}
                    value={inventoryKit}
                    handleToogle={this.handleOpen('inventoryKitOpen')}
                    elements={this.equipmentPresence.map(e => ({
                      id: e.id,
                      title: e.label,
                    }))}
                    isFetching={isFetchingCities}
                    handleOpen={this.handleOpen('inventoryKitOpen')}
                    handleClose={this.handleClose('inventoryKitOpen')}
                    handleChange={this.handleChange('inventoryKit')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth className={classes.formControlHeight}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:client')}
              </FormLabel>
              <DropDownList
                classes={classes}
                open={clientsOpen}
                value={client}
                handleToogle={this.handleOpen('clientsOpen')}
                elements={
                  clients
                    ? clients.map(c => ({ id: c.id, title: c.short_name }))
                    : []
                }
                isFetching={isFetchingClients}
                handleOpen={this.handleOpen('clientsOpen')}
                handleClose={this.handleClose('clientsOpen')}
                handleChange={this.handleChange('client')}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControlHeight}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:performer')}
              </FormLabel>
              <DropDownList
                classes={classes}
                open={performersOpen}
                value={performer}
                handleToogle={this.handleOpen('performersOpen')}
                elements={
                  performers
                    ? performers.map(p => ({ id: p.id, title: p.short_name }))
                    : []
                }
                isFetching={isFetchingPerformers}
                handleOpen={this.handleOpen('performersOpen')}
                handleClose={this.handleClose('performersOpen')}
                handleChange={this.handleChange('performer')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth className={classes.formControlHeight}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:cityTitle')}
              </FormLabel>
              <DropDownList
                classes={classes}
                open={citiesOpen}
                value={city}
                handleToogle={this.handleOpen('citiesOpen')}
                elements={
                  cities ? cities.map(c => ({ id: c.id, title: c.name })) : []
                }
                isFetching={isFetchingCities}
                handleOpen={this.handleOpen('citiesOpen')}
                handleClose={this.handleClose('citiesOpen')}
                handleChange={this.handleChange('city')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }
}

InventoryTableToolbar.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  isFetchingCities: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired,
  isFetchingClients: PropTypes.bool.isRequired,
  clients: PropTypes.array.isRequired,
  isFetchingPerformers: PropTypes.bool.isRequired,
  performers: PropTypes.array.isRequired,
}

InventoryTableToolbar = withStyles(toolbarStyles)(InventoryTableToolbar)

const headStyles = theme => ({
  chip: {
    margin: theme.spacing(1),
  },
  root: {
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  headerCell: {
    border: 0,
  },
  headerCellTo: {
    border: 0,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
})

let InventoryTableHead = props => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox" className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:dateOfCreation')}
      </TableCell>
      <TableCell padding="checkbox" className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:inventoryDesiredPeriod')}
      </TableCell>
      <TableCell padding="checkbox" className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:inventoryTypeTable')}
      </TableCell>
      <TableCell padding="checkbox" className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:inventoryKitHasOwnTitle')}
      </TableCell>
      <TableCell padding="checkbox" className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:client')}
      </TableCell>
      <TableCell padding="checkbox" className={props.classes.headerCell}>
        {i18next.t('inventory_web_atoir:performer')}
      </TableCell>
      <TableCell padding="checkbox" className={props.classes.headerCellTo} />
    </TableRow>
  </TableHead>
)

InventoryTableHead = withStyles(headStyles)(InventoryTableHead)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 800,
    padding: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  text: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textStyle: {
    color: 'black',
    textStyle: 'underlined',
  },
  goToProject: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
    whiteSpace: 'nowrap',
    color: 'black',
  },
  tableCell: {
    border: 0,
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: lighten(theme.palette.apply.lightest, 0.65),
    },
  },
})

class InventoryTableComponent extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      rowsPerPage: 5,
      filters: {},
    }
  }

  componentDidUpdate = prevPorps => {
    const {
      props: { requestor, status },
    } = this
    if (prevPorps.requestor !== requestor) {
      this.setState({
        page: 0,
        rowsPerPage: 5,
        filters: {},
      })
    }

    if (prevPorps.status !== status) {
      this.setState({
        page: 0,
        rowsPerPage: 5,
        filters: {},
      })
    }
  }

  handleChangePage = (event, page) => {
    this.props.changePage(page)
    this.setState({ page }, () => {
      this.handleFetchInventory()
    })
  }

  handleChangeRowsPerPage = event => {
    this.props.changeRowsPerPage(event.target.value)
    this.setState({ rowsPerPage: event.target.value }, () =>
      this.handleFetchInventory(),
    )
  }

  handleFetchInventory = () => {
    const {
      props: { handleFetchInventory },
      state: { filters, page, rowsPerPage },
    } = this
    const f = {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      ...filters,
    }
    handleFetchInventory(f)
  }

  updateFilters = (field, value) => {
    this.setState(
      state => ({ filters: { ...state.filters, [field]: value } }),
      () => this.handleFetchInventory(),
    )
  }

  render() {
    const {
      props: {
        classes,
        isFetchingInventoryProjects,
        inventoryProjects,
        isFetchingInventoryProjectsCount,
        count,
        isFetchingCities,
        cities,
        isFetchingClients,
        clients,
        isFetchingPerformers,
        performers,
        requestor,
        status,
        match,
      },
      state: { page, rowsPerPage },
    } = this

    return (
      <Fragment>
        <Fragment>
          <InventoryTableToolbar
            updateFilters={this.updateFilters}
            isFetchingCities={isFetchingCities}
            cities={cities}
            isFetchingClients={isFetchingClients}
            clients={clients}
            isFetchingPerformers={isFetchingPerformers}
            performers={performers}
            requestor={requestor}
            status={status}
          />
          <div className="table-wrapper">
            <Table className={classes.table}>
              <Fragment>
                {isFetchingInventoryProjects ||
                isFetchingInventoryProjectsCount ? (
                  <Loader />
                ) : inventoryProjects && inventoryProjects.length !== 0 ? (
                  <Fragment>
                    <InventoryTableHead />
                    <TableBody>
                      {inventoryProjects.map(project => {
                        const date = new Date(
                          project.date_created,
                        ).toLocaleDateString('ru')
                        const desiredPeriodBeginDate = project.desired_period_begin
                          ? new Date(
                              project.desired_period_begin,
                            ).toLocaleDateString('ru')
                          : ''
                        const desiredPeriodEndDate = project.desired_period_end
                          ? new Date(
                              project.desired_period_end,
                            ).toLocaleDateString('ru')
                          : ''
                        return (
                          <TableRow
                            key={project.id}
                            className={classes.tableRow}
                          >
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              {date}
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              {desiredPeriodBeginDate} - {desiredPeriodEndDate}
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              {project.inventory_type.toUpperCase()}
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              {project.inventory_kit
                                ? i18next.t('shared_web_atoir:yes')
                                : i18next.t('shared_web_atoir:no')}
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              <RLink
                                to={`${match.url}/organizations/${project.client_organization_id}`}
                              >
                                <span className={classes.textStyle}>
                                  {project.Client.short_name}
                                </span>
                              </RLink>
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              {project.Performer ? (
                                <RLink
                                  to={`${match.url}/organizations/${project.performer_organization_id}`}
                                >
                                  <span className={classes.textStyle}>
                                    {project.Performer.short_name}
                                  </span>
                                </RLink>
                              ) : (
                                '-'
                              )}
                              {!isEmpty(project.rating_toperformer) && (
                                <RatingStars
                                  rating={Math.floor(
                                    project.rating_toperformer,
                                  )}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCell}
                            >
                              <RLink to={`/inventory_projects/${project.id}`}>
                                <span className={classes.goToProject}>
                                  {`${i18next.t(
                                    'inventory_web_atoir:goToProject',
                                  )} >`}
                                </span>
                              </RLink>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Fragment>
                ) : (
                  <Typography className={classes.text} align="center">
                    {i18next.t(
                      'inventory_web_atoir:inventoryProjectsListEmpty',
                    )}
                  </Typography>
                )}
              </Fragment>
            </Table>
          </div>

          <TablePagination
            component="Fragment"
            count={count}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
            labelDisplayedRows={({
              from: fromPage,
              to: toPage,
              count: countPage,
            }) =>
              i18next.t('shared_web_atoir:labelDisplayedRows', {
                fromPage,
                toPage,
                countPage,
              })
            }
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Fragment>
      </Fragment>
    )
  }
}

InventoryTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleFetchInventory: PropTypes.func.isRequired,
  isFetchingInventoryProjects: PropTypes.bool.isRequired,
  inventoryProjects: PropTypes.array.isRequired,
  isFetchingInventoryProjectsCount: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  isFetchingCities: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired,
  isFetchingClients: PropTypes.bool.isRequired,
  clients: PropTypes.array.isRequired,
  isFetchingPerformers: PropTypes.bool.isRequired,
  performers: PropTypes.array.isRequired,
}

export default withStyles(styles)(InventoryTableComponent)
