import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import App from '../components/App'
import { SnackbarProvider } from 'notistack'

// styles
import '../styles/components/index.css'
import '../styles/components/App.css'
import { AppProvider } from '../components/App/appContext'

const Root = ({ store }) => (
  <Provider store={store}>
    <div className="App">
      <AppProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <App />
        </SnackbarProvider>
      </AppProvider>
    </div>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
