import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import queryString from 'query-string'
import classnames from 'classnames'
import i18next from '../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import ReportDownload from './ReportDownload'

// components
import TextButton from '../../../widgets/Buttons/TextButton'
import DateInput from '../../../widgets/DateInput'
import Filters from './FiltersComponent'
import TableRequests from './TableRequests'
import RequestsSearchList from './RequestsSearchList'
import { URL } from '../../../middlewares/api'
import RadioButtonsGroupFilters from './RadioButtonsGroupFilters'

const styles = theme => ({
  displayNoneClass: {
    display: 'none',
  },
  section: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  divTabsRoot: {
    display: 'flex',
    minHeight: '48px',
  },
  divTabs: {
    flexBasis: theme.typography.pxToRem(500),
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  divTabsContainer: {
    flexBasis: theme.typography.pxToRem(500),
    flexGrow: 1,
    minWidth: '1550px',
    position: 'absolute',
    transition: 'left .9s ease-out',
  },
  divArrow: {
    flexBasis: theme.typography.pxToRem(20),
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
  },
  customBtn: {
    padding: 0,
    margin: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filters: {
    paddingBottom: `${theme.spacing(3)}px`,
  },
  statusChip: {
    minWidth: 120,
    marginRight: 16,
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: 16,
  },
  activeChip: {},
  activeSortLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: 16,
  },
  inactiveSortLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold',
    fontSize: 16,
  },
  filtersPadding: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  searchCallPadding: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  noWrap: {
    flexWrap: 'nowrap',
    display: 'flex',
  },
})

const callStatuses = [
  'all',
  'created',
  'inwork',
  'needrepair',
  'control',
  // 'repair',
  'close',
  'cancel',
]

const orderStatuses = [
  'all',
  'created',
  'inwork',
  'control',
  'done',
  'synthetic_sent_to_client',
  'close',
  'cancel',
]

const defaultStatus = 'created'

class ListRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: 'updatedAt',
      order: 'desc',
      status: props.match.params.tab || defaultStatus,
      page: 0,
      filters: {},
      searching: false,
      dateFrom: '',
      dateTo: '',
    }
  }

  componentDidMount() {
    const {
      props: {
        match: {
          params: { listOf },
        },
      },
      state: { status },
      formFiltersForListOfStatus,
    } = this
    const listFilters = formFiltersForListOfStatus(listOf, status)
    this.updateFetchList(
      listFilters.needParticipation,
      listFilters.typeOrder,
      listFilters.resultOrder,
    )
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        rowsPerPage,
        match: {
          params,
          params: { tab },
        },
      },
    } = this
    if (params.tab && params.tab !== this.state.status) {
      this.setState({ status: params.tab })
    }
    if (prevProps.rowsPerPage !== rowsPerPage) {
      this.updateFetchList()
    }
    if (prevProps.match.params.listOf !== params.listOf) {
      this.setState({ status: tab || defaultStatus }, () => {
        this.updateFetchList()
      })
    }
  }

  componentWillUnmount() {
    const {
      props: { clearFilterDrawer, clearCallsSearchByNumber },
    } = this
    clearFilterDrawer()
    clearCallsSearchByNumber()
  }

  formFiltersForListOfStatus = (listOf, status) => {
    let listFilters = Object.assign({})
    if (listOf === 'calls') {
      if (status !== 'inwork' && status !== 'repair') {
        listFilters = {
          needParticipation: null,
          typeOrder: null,
          resultOrder: null,
        }
      } else if (status === 'inwork' || status === 'repair') {
        listFilters = {
          needParticipation: 'all',
          typeOrder: null,
          resultOrder: null,
        }
      }
    }
    if (listOf === 'orders') {
      if (status !== 'created') {
        listFilters = {
          needParticipation: null,
          typeOrder: 'all',
          resultOrder: 'all',
        }
      } else if (status === 'created') {
        listFilters = {
          needParticipation: null,
          typeOrder: null,
          resultOrder: null,
        }
      }
    }
    return listFilters
  }

  handleStatusChange = value => {
    const {
      props: {
        match: {
          params: { listOf },
        },
        setInitialListFiltersForStatus,
      },
      formFiltersForListOfStatus,
    } = this
    const listFilters = formFiltersForListOfStatus(listOf, value)
    setInitialListFiltersForStatus(listFilters)

    this.setState(
      {
        status: value,
        filters: {},
        page: 0,
      },
      () => {
        this.updateFetchList(
          listFilters.needParticipation,
          listFilters.typeOrder,
          listFilters.resultOrder,
        )
        this.props.history.push(`/service_desk/${listOf}/${value}`)
      },
    )
  }

  handleNeedParticipationChange = event => {
    const { value } = event.target
    const { setNeedParticipationFilter } = this.props
    this.setState(
      {
        filters: {},
        page: 0,
      },
      () => {
        setNeedParticipationFilter(value)
      },
    )
    this.updateFetchList(value, null, null, this.state.filters, 0)
  }

  handleTypeOrderChange = event => {
    const { value } = event.target
    const { setTypeOrderFilter } = this.props
    this.setState(
      {
        filters: {},
        page: 0,
      },
      () => {
        setTypeOrderFilter(value)
      },
    )
    this.updateFetchList(
      null,
      value,
      this.props.resultOrder,
      this.state.filters,
      0,
    )
  }

  handleResultOrderChange = event => {
    const { value } = event.target
    const { setResultOrderFilter } = this.props
    this.setState(
      {
        filters: {},
        page: 0,
      },
      () => {
        setResultOrderFilter(value)
      },
    )
    this.updateFetchList(
      null,
      this.props.typeOrder,
      value,
      this.state.filters,
      0,
    )
  }

  handleSubStatusChange = value => {
    this.setState(
      {
        subStatus: value,
      },
      () => {
        this.updateFetchList()
      },
    )
  }

  handleCreatedList = filters => {
    this.setState(
      {
        filters,
      },
      this.updateFetchList,
    )
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.updateFetchList)
  }

  onRequestSort = (orderBy, order) => {
    this.setState(
      {
        orderBy,
        order,
      },
      this.updateFetchList,
    )
  }

  handleChangeRowsPerPage = event => {
    this.props.setRowsPerPage(event.target.value)
  }

  prepareParams = (
    needParticipation = this.props.needParticipation,
    typeOrder = this.props.typeOrder,
    resultOrder = this.props.resultOrder,
    filters = this.state.filters,
    page = this.state.page,
    rowsPerPage = this.props.rowsPerPage,
    subStatus = this.state.subStatus,
  ) => {
    const {
      props: {
        match: {
          params: { listOf },
        },
      },
      state: { status, dateFrom, dateTo },
    } = this
    const params = {
      ...filters,
      [`${listOf === 'calls' ? 'call_status' : 'order_status'}`]:
        status === 'all' ? null : status,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      need_participation: listOf === 'calls' ? needParticipation : null,
      type_order:
        listOf === 'orders' ? (typeOrder === 'all' ? null : typeOrder) : null,
      order_result:
        listOf === 'orders'
          ? resultOrder === 'all'
            ? null
            : resultOrder
          : null,
      sort:
        this.state.orderBy && this.state.order
          ? `[${this.state.orderBy}=${this.state.order.toUpperCase()}]`
          : null,
      created_at_from:
        status === 'all' && dateFrom ? new Date(dateFrom).toISOString() : null,
      created_at_to:
        status === 'all' && dateTo ? new Date(dateTo).toISOString() : null,
      sub_status: subStatus,
    }
    let propList = _.keys(params)
    propList = _.filter(propList, prName => params[prName] !== null)
    const clearParams = _.pick(params, propList)
    return queryString.stringify(clearParams)
  }

  updateFetchList = (
    needParticipation = this.props.needParticipation,
    typeOrder = this.props.typeOrder,
    resultOrder = this.props.resultOrder,
    filters = this.state.filters,
    page = this.state.page,
    rowsPerPage = this.props.rowsPerPage,
    subStatus = this.state.subStatus,
    isNeedFetch = true,
  ) => {
    const {
      state: { status },
    } = this
    const queryStringParams = this.prepareParams(
      needParticipation,
      typeOrder,
      resultOrder,
      filters,
      page,
      rowsPerPage,
      subStatus,
    )

    const datePickers = (
      <Fragment>
        <div className={this.props.classes.noWrap}>
          <div className={this.props.classes.searchCallPadding}>
            <DateInput
              value={this.state.dateFrom}
              name="dateFrom"
              label={i18next.t('inner_calls_web_atoir:dateFrom')}
              handleChange={value => {
                this.setState({ dateFrom: value }, () =>
                  this.updateFetchList(
                    this.props.needParticipation,
                    this.props.typeOrder,
                    this.props.resultOrder,
                    this.state.filters,
                    this.state.page,
                    this.props.rowsPerPage,
                    this.state.subStatus,
                    false,
                  ),
                )
              }}
            />
          </div>
          <div className={this.props.classes.searchCallPadding}>
            <DateInput
              value={this.state.dateTo}
              name="dateTo"
              label={i18next.t('inner_calls_web_atoir:dateTo')}
              handleChange={value => {
                this.setState({ dateTo: value }, () =>
                  this.updateFetchList(
                    this.props.needParticipation,
                    this.props.typeOrder,
                    this.props.resultOrder,
                    this.state.filters,
                    this.state.page,
                    this.props.rowsPerPage,
                    this.state.subStatus,
                    false,
                  ),
                )
              }}
              disabledDays={{ before: this.state.dateFrom }}
            />
          </div>
        </div>
      </Fragment>
    )

    const reportUrl =
      this.props.match.params.listOf === 'calls'
        ? `${URL}/service_calls/list/report?${queryStringParams}`
        : `${URL}/service_call_orders/list/report?${queryStringParams}`

    if (isNeedFetch) {
      if (this.props.match.params.listOf === 'calls') {
        this.props.fetchServiceCallsListRequest(queryStringParams)
        this.props.fetchServiceCallsListCountRequest(queryStringParams)
      } else {
        this.props.fetchServiceCallOrdersListRequest(queryStringParams)
        this.props.fetchServiceCallOrdersListCountRequest(queryStringParams)
      }
    }

    if (!this.state.searching) {
      this.props.setFilterDrawer(
        <Fragment>
          <div className={this.props.classes.searchCallPadding}>
            <TextButton
              onClick={() => {
                this.onToggle('call')
              }}
            >
              <SearchIcon className={this.props.classes.searchIcon} />
              {i18next.t('service_desk_web_atoir:captionSearchByCall')}
            </TextButton>
          </div>
          <Divider />
          <div className={this.props.classes.searchCallPadding}>
            <TextButton
              onClick={() => {
                this.onToggle('equipment')
              }}
            >
              <SearchIcon className={this.props.classes.searchIcon} />
              {i18next.t('service_desk_web_atoir:captionSearchByEquipment')}
            </TextButton>
          </div>
          <Divider />
          <RadioButtonsGroupFilters
            filtersFor={this.props.match.params.listOf}
            status={status}
            handleSubStatusChange={this.handleSubStatusChange}
          />
          <Filters
            createRef={ref => {
              this.refFilters = ref
            }}
            filtersFor={this.props.match.params.listOf}
            status={status}
            handleCreatedList={this.handleCreatedList}
            datePickers={datePickers}
          />
          <ReportDownload reportUrl={reportUrl} />
        </Fragment>,
      )
    } else {
      const { by } = this.state
      this.props.setFilterDrawer(
        <RequestsSearchList onToggle={this.onToggle} by={by} />,
      )
    }
  }

  onToggle = by =>
    this.setState(
      prevState => ({
        searching: !prevState.searching,
        by: by || null,
      }),
      () => this.updateFetchList(),
    )

  render() {
    const {
      props: {
        match: {
          params: { listOf },
        },
        classes,
        isFetchingCount,
        count,
        errorCount,
        isFetchingOrdersCount,
        ordersCount,
        errorOrdersCount,
        isFetchingSC,
        errorServiceCalls,
        isFetchingSCO,
        errorServiceCallOrders,
        rowsPerPage,
      },
      state: { status, page, order, orderBy },
      updateFetchList,
    } = this
    const statuses = listOf === 'calls' ? callStatuses : orderStatuses
    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          {i18next.t(
            `service_desk_web_atoir:${
              listOf === 'calls' ? 'ListRequest' : 'listOrders'
            }`,
          )}
        </Typography>
        {statuses.map(s => (
          <Chip
            onClick={() => this.handleStatusChange(s)}
            color={status === s ? 'primary' : 'default'}
            className={classnames([
              classes.statusChip,
              status === s ? classes.activeChip : null,
            ])}
            label={`${i18next.t(
              `service_desk_web_atoir:${listOf}_${s}_status_chips`,
            )}${
              status === s
                ? (listOf === 'calls'
                  ? isFetchingSC
                  : isFetchingSCO)
                  ? ''
                  : ` (${listOf === 'calls' ? count : ordersCount})`
                : ''
            }`}
            variant="outlined"
          />
        ))}
        <Fragment>
          {isEmpty(errorCount) &&
          isEmpty(errorServiceCalls) &&
          isEmpty(errorOrdersCount) &&
          isEmpty(errorServiceCallOrders) ? (
            <TableRequests
              status={status}
              isFetching={listOf === 'calls' ? isFetchingSC : isFetchingSCO}
              isFetchingCount={
                listOf === 'calls' ? isFetchingCount : isFetchingOrdersCount
              }
              order={order}
              orderBy={orderBy}
              page={page}
              onRequestSort={this.onRequestSort}
              triggerFetch={updateFetchList}
              rowsPerPage={rowsPerPage}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          ) : null}
        </Fragment>
      </Fragment>
    )
  }
}

ListRequest.defaultProps = {
  errorServiceCalls: {},
  errorCount: {},
}

ListRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetchingCount: PropTypes.bool.isRequired,
  isFetchingSC: PropTypes.bool.isRequired,
  errorServiceCalls: PropTypes.object,
  count: PropTypes.number.isRequired,
  errorCount: PropTypes.object,
  clearFilterDrawer: PropTypes.func.isRequired,
}

export default withStyles(styles)(ListRequest)
