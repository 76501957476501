import * as constants from '../constants/actionTypes/contracts'
import { actionCreator as externalActionCreator } from './actionCreator'

export const fetchContractsRequest = externalActionCreator(
  constants.FETCH_CONTRACTS_REQUEST,
)
export const fetchContractsSuccess = externalActionCreator(
  constants.FETCH_CONTRACTS_SUCCESS,
)
export const fetchContractsFailure = externalActionCreator(
  constants.FETCH_CONTRACTS_FAILURE,
)

export const fetchContractPermissionsRequest = externalActionCreator(
  constants.FETCH_CONTRACT_PERMISSIONS_REQUEST,
)
export const fetchContractPermissionsSuccess = externalActionCreator(
  constants.FETCH_CONTRACT_PERMISSIONS_SUCCESS,
)
export const fetchContractPermissionsFailure = externalActionCreator(
  constants.FETCH_CONTRACT_PERMISSIONS_FAILURE,
)

export const fetchContractRequest = externalActionCreator(
  constants.FETCH_CONTRACT_REQUEST,
)
export const fetchContractSuccess = externalActionCreator(
  constants.FETCH_CONTRACT_SUCCESS,
)
export const fetchContractFailure = externalActionCreator(
  constants.FETCH_CONTRACT_FAILURE,
)

export const cancelContractRequest = externalActionCreator(
  constants.CANCEL_CONTRACT_REQUEST,
)
export const cancelContractSuccess = externalActionCreator(
  constants.CANCEL_CONTRACT_SUCCESS,
)
export const cancelContractFailure = externalActionCreator(
  constants.CANCEL_CONTRACT_FAILURE,
)

export const fetchContractLogsRequest = externalActionCreator(
  constants.FETCH_CONTRACT_LOGS_REQUEST,
)
export const fetchContractLogsSuccess = externalActionCreator(
  constants.FETCH_CONTRACT_LOGS_SUCCESS,
)
export const fetchContractLogsFailure = externalActionCreator(
  constants.FETCH_CONTRACT_LOGS_FAILURE,
)

export const fetchContractLogsCountRequest = externalActionCreator(
  constants.FETCH_CONTRACT_LOGS_COUNT_REQUEST,
)
export const fetchContractLogsCountSuccess = externalActionCreator(
  constants.FETCH_CONTRACT_LOGS_COUNT_SUCCESS,
)
export const fetchContractLogsCountFailure = externalActionCreator(
  constants.FETCH_CONTRACT_LOGS_COUNT_FAILURE,
)

export const fetchAgreementLogsRequest = externalActionCreator(
  constants.FETCH_AGREEMENT_LOGS_REQUEST,
)
export const fetchAgreementLogsSuccess = externalActionCreator(
  constants.FETCH_AGREEMENT_LOGS_SUCCESS,
)
export const fetchAgreementLogsFailure = externalActionCreator(
  constants.FETCH_AGREEMENT_LOGS_FAILURE,
)

export const fetchAgreementLogsCountRequest = externalActionCreator(
  constants.FETCH_AGREEMENT_LOGS_COUNT_REQUEST,
)
export const fetchAgreementLogsCountSuccess = externalActionCreator(
  constants.FETCH_AGREEMENT_LOGS_COUNT_SUCCESS,
)
export const fetchAgreementLogsCountFailure = externalActionCreator(
  constants.FETCH_AGREEMENT_LOGS_COUNT_FAILURE,
)

export const assignContractRequest = externalActionCreator(
  constants.ASSIGN_CONTRACT_REQUEST,
)
export const assignContractSuccess = externalActionCreator(
  constants.ASSIGN_CONTRACT_SUCCESS,
)
export const assignContractFailure = externalActionCreator(
  constants.ASSIGN_CONTRACT_FAILURE,
)

export const acceptContractRequest = externalActionCreator(
  constants.ACCEPT_CONTRACT_REQUEST,
)
export const acceptContractSuccess = externalActionCreator(
  constants.ACCEPT_CONTRACT_SUCCESS,
)
export const acceptContractFailure = externalActionCreator(
  constants.ACCEPT_CONTRACT_FAILURE,
)

export const rejectContractRequest = externalActionCreator(
  constants.REJECT_CONTRACT_REQUEST,
)
export const rejectContractSuccess = externalActionCreator(
  constants.REJECT_CONTRACT_SUCCESS,
)
export const rejectContractFailure = externalActionCreator(
  constants.REJECT_CONTRACT_FAILURE,
)

export const fetchContractEquipmentsRequest = externalActionCreator(
  constants.FETCH_CONTRACT_EQUIPMENT_REQUEST,
)
export const fetchContractEquipmentsSuccess = externalActionCreator(
  constants.FETCH_CONTRACT_EQUIPMENT_SUCCESS,
)
export const fetchContractEquipmentsFailure = externalActionCreator(
  constants.FETCH_CONTRACT_EQUIPMENT_FAILURE,
)

export const fetchAgreementsRequest = externalActionCreator(
  constants.FETCH_AGREEMENTS_REQUEST,
)
export const fetchAgreementsSuccess = externalActionCreator(
  constants.FETCH_AGREEMENTS_SUCCESS,
)
export const fetchAgreementsFailure = externalActionCreator(
  constants.FETCH_AGREEMENTS_FAILURE,
)

export const fetchAgreementsCountRequest = externalActionCreator(
  constants.FETCH_AGREEMENTS_COUNT_REQUEST,
)
export const fetchAgreementsCountSuccess = externalActionCreator(
  constants.FETCH_AGREEMENTS_COUNT_SUCCESS,
)
export const fetchAgreementsCountFailure = externalActionCreator(
  constants.FETCH_AGREEMENTS_COUNT_FAILURE,
)

export const cancelAgreementRequest = externalActionCreator(
  constants.CANCEL_AGREEMENT_REQUEST,
)
export const cancelAgreementSuccess = externalActionCreator(
  constants.CANCEL_AGREEMENT_SUCCESS,
)
export const cancelAgreementFailure = externalActionCreator(
  constants.CANCEL_AGREEMENT_FAILURE,
)

export const acceptAgreementRequest = externalActionCreator(
  constants.ACCEPT_AGREEMENT_REQUEST,
)
export const acceptAgreementSuccess = externalActionCreator(
  constants.ACCEPT_AGREEMENT_SUCCESS,
)
export const acceptAgreementFailure = externalActionCreator(
  constants.ACCEPT_AGREEMENT_FAILURE,
)

export const rejectAgreementRequest = externalActionCreator(
  constants.REJECT_AGREEMENT_REQUEST,
)
export const rejectAgreementSuccess = externalActionCreator(
  constants.REJECT_AGREEMENT_SUCCESS,
)
export const rejectAgreementFailure = externalActionCreator(
  constants.REJECT_AGREEMENT_FAILURE,
)

export const assignAgreementRequest = externalActionCreator(
  constants.ASSIGN_AGREEMENT_REQUEST,
)
export const assignAgreementSuccess = externalActionCreator(
  constants.ASSIGN_AGREEMENT_SUCCESS,
)
export const assignAgreementFailure = externalActionCreator(
  constants.ASSIGN_AGREEMENT_FAILURE,
)

export const addAgreementEquipmentRequest = externalActionCreator(
  constants.ADD_AGREEMENT_EQUIPMENT_REQUEST,
)
export const addAgreementEquipmentSuccess = externalActionCreator(
  constants.ADD_AGREEMENT_EQUIPMENT_SUCCESS,
)
export const addAgreementEquipmentFailure = externalActionCreator(
  constants.ADD_AGREEMENT_EQUIPMENT_FAILURE,
)

export const fetchAgreementEquipmentsRequest = externalActionCreator(
  constants.FETCH_AGREEMENT_EQUIPMENT_REQUEST,
)
export const fetchAgreementEquipmentsSuccess = externalActionCreator(
  constants.FETCH_AGREEMENT_EQUIPMENT_SUCCESS,
)
export const fetchAgreementEquipmentsFailure = externalActionCreator(
  constants.FETCH_AGREEMENT_EQUIPMENT_FAILURE,
)

export const fetchAgreementEquipmentsCountRequest = externalActionCreator(
  constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_REQUEST,
)
export const fetchAgreementEquipmentsCountSuccess = externalActionCreator(
  constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_SUCCESS,
)
export const fetchAgreementEquipmentsCountFailure = externalActionCreator(
  constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_FAILURE,
)

export const deleteAgreementEquipmentsRequest = externalActionCreator(
  constants.DELETE_AGREEMENT_EQUIPMENT_REQUEST,
)
export const deleteAgreementEquipmentsSuccess = externalActionCreator(
  constants.DELETE_AGREEMENT_EQUIPMENT_SUCCESS,
)
export const deleteAgreementEquipmentsFailure = externalActionCreator(
  constants.DELETE_AGREEMENT_EQUIPMENT_FAILURE,
)

export const deleteContractEquipmentsRequest = externalActionCreator(
  constants.DELETE_CONTRACT_EQUIPMENT_REQUEST,
)
export const deleteContractEquipmentsSuccess = externalActionCreator(
  constants.DELETE_CONTRACT_EQUIPMENT_SUCCESS,
)
export const deleteContractEquipmentsFailure = externalActionCreator(
  constants.DELETE_CONTRACT_EQUIPMENT_FAILURE,
)

export const fetchContractEquipmentsCountRequest = externalActionCreator(
  constants.FETCH_CONTRACT_EQUIPMENT_COUNT_REQUEST,
)
export const fetchContractEquipmentsCountSuccess = externalActionCreator(
  constants.FETCH_CONTRACT_EQUIPMENT_COUNT_SUCCESS,
)
export const fetchContractEquipmentsCountFailure = externalActionCreator(
  constants.FETCH_CONTRACT_EQUIPMENT_COUNT_FAILURE,
)

export const editContractRequest = externalActionCreator(
  constants.EDIT_CONTRACT_REQUEST,
)
export const editContractSuccess = externalActionCreator(
  constants.EDIT_CONTRACT_SUCCESS,
)
export const editContractFailure = externalActionCreator(
  constants.EDIT_CONTRACT_FAILURE,
)

export const assignEngineerToEquipmentsRequest = externalActionCreator(
  constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST,
)
export const assignEngineerToEquipmentsSuccess = externalActionCreator(
  constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS,
)
export const assignEngineerToEquipmentsFailure = externalActionCreator(
  constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE,
)

export const unAssignEngineerToEquipmentsRequest = externalActionCreator(
  constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST,
)
export const unAssignEngineerToEquipmentsSuccess = externalActionCreator(
  constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS,
)
export const unAssignEngineerToEquipmentsFailure = externalActionCreator(
  constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE,
)

export const editContractResponsibleRequest = externalActionCreator(
  constants.EDIT_CONTRACT_RESPONSIBLE_REQUEST,
)
export const editContractResponsibleSuccess = externalActionCreator(
  constants.EDIT_CONTRACT_RESPONSIBLE_SUCCESS,
)
export const editContractResponsibleFailure = externalActionCreator(
  constants.EDIT_CONTRACT_RESPONSIBLE_FAILURE,
)

export const fetchFilteringOrganizationsRequest = externalActionCreator(
  constants.FETCH_FILTERING_ORGANIZATIONS_REQUEST,
)
export const fetchFilteringOrganizationsSuccess = externalActionCreator(
  constants.FETCH_FILTERING_ORGANIZATIONS_SUCCESS,
)
export const fetchFilteringOrganizationsFailure = externalActionCreator(
  constants.FETCH_FILTERING_ORGANIZATIONS_FAILURE,
)

export const fetchContractsCountRequest = externalActionCreator(
  constants.FETCH_CONTRACTS_COUNT_REQUEST,
)
export const fetchContractsCountSuccess = externalActionCreator(
  constants.FETCH_CONTRACTS_COUNT_SUCCESS,
)
export const fetchContractsCountWithFiltersSuccess = externalActionCreator(
  constants.FETCH_CONTRACTS_COUNT_WITH_FILTERS_SUCCESS,
)
export const fetchContractsCountFailure = externalActionCreator(
  constants.FETCH_CONTRACTS_COUNT_FAILURE,
)
export const fetchContractsCountWithFiltersFailure = externalActionCreator(
  constants.FETCH_CONTRACTS_COUNT_WITH_FILTERS_FAILURE,
)

export const createAgreementRequest = externalActionCreator(
  constants.CREATE_AGREEMENT_REQUEST,
)
export const createAgreementSuccess = externalActionCreator(
  constants.CREATE_AGREEMENT_SUCCESS,
)
export const createAgreementFailure = externalActionCreator(
  constants.CREATE_AGREEMENT_FAILURE,
)

export const fetchAgreementRequest = externalActionCreator(
  constants.FETCH_AGREEMENT_REQUEST,
)
export const fetchAgreementSuccess = externalActionCreator(
  constants.FETCH_AGREEMENT_SUCCESS,
)
export const fetchAgreementFailure = externalActionCreator(
  constants.FETCH_AGREEMENT_FAILURE,
)

export const editAgreementNameRequest = externalActionCreator(
  constants.EDIT_AGREEMENT_NAME_REQUEST,
)
export const editAgreementNameSuccess = externalActionCreator(
  constants.EDIT_AGREEMENT_NAME_SUCCESS,
)
export const editAgreementNameFailure = externalActionCreator(
  constants.EDIT_AGREEMENT_NAME_FAILURE,
)

export const setContractsEquipmentRowsPerPage = externalActionCreator(
  constants.SET_EQUIPMENTS_ROWS_PER_PAGE,
)
export const setAddContractsEquipmentRowsPerPage = externalActionCreator(
  constants.SET_ADD_EQUIPMENTS_ROWS_PER_PAGE,
)
