import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreateEditTag from './CreateEditTag'
import {
  setNavigationDrawer,
  clearNavigationDrawer,
} from '../../../../../actions/appActions'
import {
  createTagRequest,
  editTagRequest,
  getTagRequest,
} from '../../../../../actions/tagsActions'
import { fetchOrganization } from '../../../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isCreatingTag: state.tags.createTag.isFetching,
  createTagError: state.tags.createTag.error,
  isEditingTag: state.tags.editTag.isFetching,
  editTagError: state.tags.editTag.error,
  isFetchingTag: state.tags.getTag.isFetching,
  tag: state.tags.getTag.tag,
  getTagError: state.tags.getTag.error,
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNavigationDrawer,
      clearNavigationDrawer,
      createTagRequest,
      editTagRequest,
      getTagRequest,
      fetchOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateEditTag),
)
