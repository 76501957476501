import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ContactsFormComponent from './ContactsFormComponent'
import { deleteContact } from '../../../actions/organizationContactsActions'

const mapStateToProps = state => ({
  isDeletingContact: state.organizationContacts.isDeletingContact,
  error: state.organizationContacts.error,
  organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteContact,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ContactsFormComponent)
