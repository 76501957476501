import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LocationPickerComponent from './LocationPickerComponent'
import { fetchLocations } from '../../actions/locationsActions'

const mapStateToProps = state => ({
  isFetchingLocations: state.locations.isFetchingLocations,
  locations: state.locations.locations,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationPickerComponent),
)
