import React from 'react'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
})

const SimpleLoader = ({ classes }) => (
  <CircularProgress
    className={classes.progress}
    variant="indeterminate"
    size={40}
  />
)

export default withStyles(styles)(SimpleLoader)
