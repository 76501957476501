import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DropDownList from '../../DropDownList/DropDownList'

class FormControlSelectWithSearch extends Component {
  state = {
    open: false,
  }

  toggle = status => () => {
    this.setState({ open: status })
  }

  render = () => {
    const { onChange, value, label, values, showLabel, ...others } = this.props
    return (
      <DropDownList
        classes={{}}
        value={value}
        open={this.state.open}
        handleOpen={this.toggle(true)}
        handleChange={onChange}
        handleClose={this.toggle(false)}
        label={label}
        showLabel={showLabel}
        elements={values}
        isFetching={false}
        {...others}
      />
    )
  }
}

FormControlSelectWithSearch.propTypes = {
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default FormControlSelectWithSearch
