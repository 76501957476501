import React from 'react'

// internationalization
import i18next from '../../i18n/i18n'

// styles
import '../../styles/components/App.css'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

// icons
import tick from '../../styles/images/tick.svg'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
})

const RepairPasswordSentComponent = () => (
  <div className="App-not-found">
    <img src={tick} className="Register-tick-img" alt="great!" />
    <br />
    <Typography variant="h5" component="h5">
      {i18next.t('login_web_atoir:repairPasswordEmailSentTitle')}
    </Typography>
    <Typography>
      {i18next.t('login_web_atoir:repairPasswordEmailSentText')}
    </Typography>
  </div>
)

export default withStyles(styles)(RepairPasswordSentComponent)
