import InputLabel from '@material-ui/core/InputLabel'
import i18next from '../../../../i18n/i18n'
import SelectLimitedAuto from '../../../../widgets/SelectLimitedAuto'
import { URL } from '../../../../middlewares/api'
import { organizationLocationStringify } from '../../../../helpers/parserHelper'
import FormControl from '@material-ui/core/FormControl'
import React from 'react'

function AddressSelector({
  onChange,
  idValue,
  value,
  organizationId,
  required,
  error,
}) {
  const onChangeHandler = newValue => {
    onChange(idValue, newValue)
  }
  return (
    <FormControl fullWidth required={required} error={error}>
      <InputLabel htmlFor="age-simple">
        {i18next.t('equipment_web_atoir:selectAddress')}
      </InputLabel>
      <SelectLimitedAuto
        id={idValue}
        value={value}
        sourceUrl={`${URL}/organizations/${organizationId}/locations`}
        onChange={onChangeHandler}
        requestResultTransformerToArray={r =>
          r.locations.filter(locationItem => locationItem.location_type !== 1)
        }
        itemTransformer={l => ({
          id: l.id,
          title: organizationLocationStringify(l),
        })}
      />
    </FormControl>
  )
}

export default AddressSelector
