import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import NotificationIcon from '@material-ui/icons/NotificationImportant'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

// components
import AddButton from '../../../../widgets/Buttons/AddIconTextButton'
import Loader from '../../../Loader'
import Dialog from '../../../../widgets/FormDialog'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listContainer: {
    width: '100%',
  },
  details: {
    padding: 0,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    margin: theme.spacing(1),
  },
  listItemSecondaryAction: {
    marginRight: theme.spacing(3),
    maxWidth: 90,
  },
  loader: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tagName: {
    marginRight: theme.spacing(11),
    wordBreak: 'break-word',
  },
  boundToActionText: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.34)',
    wordBreak: 'break-all',
  },
})

class Tags extends Component {
  state = {
    expanded: false,
    openDeleteDialog: false,
    deleteTagId: null,
  }

  componentDidUpdate = prevProps => {
    const {
      props: { isDeleting, updateSettingsLists, shouldUpdateTagsList },
      getTags,
    } = this
    if (prevProps.isDeleting !== isDeleting && !isDeleting) {
      this.setState(
        () => ({ deleteTagId: null, openDeleteDialog: false }),
        () => getTags(),
      )
    }
    if (
      prevProps.shouldUpdateTagsList !== shouldUpdateTagsList &&
      shouldUpdateTagsList
    ) {
      updateSettingsLists(false)
      getTags()
    }
  }

  onExpandedChange = () => {
    this.setState(
      state => ({
        expanded: !state.expanded,
      }),
      () => {
        if (this.state.expanded) {
          this.getTags()
        }
      },
    )
  }

  getTags = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        getTagsRequest,
      },
    } = this
    getTagsRequest({ organizationId })
  }

  deleteDialogOpen = open => {
    this.setState(() => ({
      openDeleteDialog: open,
    }))
  }

  deleteTag = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        deleteTagRequest,
      },
      state: { deleteTagId },
    } = this
    deleteTagRequest({ organizationId, tagId: deleteTagId })
  }

  render() {
    const {
      props: { classes, history, match, isFetching, tags, isDeleting },
      state: { expanded, openDeleteDialog },
      onExpandedChange,
      deleteDialogOpen,
      deleteTag,
    } = this
    return (
      <ExpansionPanel
        id="organization-settings-tags"
        component="div"
        expanded={expanded}
        onChange={onExpandedChange}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <Typography className={classes.heading}>
                {i18next.t('organizations_web_atoir:tags')}
              </Typography>
            </Grid>
            <Grid item>
              {expanded && (
                <AddButton
                  onClick={() => history.push(`${match.url}/tags/create`)}
                >
                  {i18next.t('organizations_web_atoir:addTag')}
                </AddButton>
              )}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails className={classes.details}>
          <Fragment>
            {isFetching ? (
              <Loader size={24} className={classes.loader} />
            ) : (
              <div className={classes.listContainer}>
                <List className={classes.list}>
                  {tags.map(tag => {
                    const iconStyle = {
                      size: 24,
                      color: `#${tag.color}`,
                    }
                    return (
                      <Fragment>
                        <ListItem
                          className={classes.listItem}
                          onClick={() =>
                            history.push(`${match.url}/tags/edit/${tag.id}`)
                          }
                        >
                          <ListItemAvatar>
                            <NotificationIcon style={iconStyle} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                component="span"
                                variant="caption"
                                className={classes.tagName}
                              >
                                {tag.name}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction
                            className={classes.listItemSecondaryAction}
                          >
                            {tag.boundToTrigger ? (
                              <Typography
                                className={classes.boundToActionText}
                                align="right"
                              >
                                {i18next.t(
                                  'organizations_web_atoir:triggerIsBoundToTag',
                                )}
                              </Typography>
                            ) : tag.boundToButton ? (
                              <Typography
                                className={classes.boundToActionText}
                                align="right"
                              >
                                {i18next.t(
                                  'organizations_web_atoir:buttonIsBoundToTag',
                                )}
                              </Typography>
                            ) : (
                              <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                  this.setState({ deleteTagId: tag.id }, () =>
                                    deleteDialogOpen(true),
                                  )
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </Fragment>
                    )
                  })}
                </List>
              </div>
            )}
            <Dialog
              open={openDeleteDialog}
              contentText={i18next.t(
                'organizations_web_atoir:areYouSureToDeleteTag',
              )}
              submitButtonTitle={i18next.t('shared_web_atoir:delete')}
              onSubmit={deleteTag}
              onClose={() => deleteDialogOpen(false)}
              onExit={() => deleteDialogOpen(false)}
              isLoading={isDeleting}
            />
          </Fragment>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

Tags.propTypes = {
  getTagsRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  deleteTagRequest: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  updateSettingsLists: PropTypes.func.isRequired,
  shouldUpdateTagsList: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Tags)
