import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AddOrganizationComponent from './AddOrganizationComponent'
import { createOrganization } from '../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isCreatingOrganization: state.organizations.isCreatingOrganization,
  organization: state.organizations.organization,
  error: state.organizations.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddOrganizationComponent),
)
