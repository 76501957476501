import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { translate } from 'react-i18next'
import classNames from 'classnames'

// internationalization
import config from '../../config'
import i18next from '../../i18n/i18n'
import namespaces from '../../i18n/namespaces'

// styles
import '../../styles/components/AppToolbar.css'
import '../../styles/components/Table.css'

// material-ui
import { withStyles } from '@material-ui/core/styles'

// components
import WrapperRoute from './WrapperRoute'
import AppBarComponent from './AppBarComponent'

// login
import HomeContainer from '../Login/HomeComponent'
import RegisterEmailContainer from '../Login/RegisterEmail'
import EmailSentComponent from '../Login/EmailSentComponent'
import AccountActivationContainer from '../Login/AccountActivation'
import RepairPasswordSentComponent from '../Login/RepairPasswordSentComponent'
import RepairPasswordContainer from '../Login/RepairPassword'
import Auth from '../Auth'

// staff
// equipment
import AddEquipmentListComponent from '../Equipment/AddEquipmentList/AddEquipmentListComponent'
// toolbar menu
import AboutSystemComponent from '../ToolbarMenu/AboutSystemComponent'
import TariffComponent from '../ToolbarMenu/TariffComponent'
import ServiceSupportComponent from '../ToolbarMenu/ServiceSupportComponent'
// common
import DialogLoader from '../DialogLoader'
import Notifier from './Notifier'
// errors
import NoMatchComponent from '../NoMatchComponent'
import SomethingWentWrong from '../SomethingWentWrong'
import ErrorContainer from '../../containers/ErrorContainer'
// inventory
import InventoryList from '../Inventory/InventoryList'
import AddInventoryProject from '../Inventory/InventoryProject/AddInventoryProject/AddInventoryProject'
import InventoryProjectProfile from '../Inventory/InventoryProject/InventoryProjectProfile'
import EditInventoryProject from '../Inventory/InventoryProject/EditInventoryProject'
import InventoryProjectEquipment from '../Inventory/InventoryProject/InventoryProjectProfile/InventoryProjectEquipment'
import InventoryProjectChangeLog from '../Inventory/InventoryProject/InventoryProjectProfile/InventoryProjectChangeLog'
import AppDialog from '../AppDialog'
import UserRouter from './routing/UserRouter'
import ContactRouter from './routing/ContactRouter'
import OrganizationsRouter from './routing/OrganizationsRouter'
import UserProfileContainer from '../../containers/User/UserProfileContainer'
import BreadCrumb from './routing/BreadCrumb'
import EquipmentRouter from './routing/EquipmentRouter'
import ServiceDeskRouter from './routing/ServiceDeskRouter'
import ServiceDeskContractsRouter from './routing/ServiceDeskContractsRouter'
import FAQRouter from './routing/FAQRouter'
import { appOptions, useAppState } from './appContext'
import SearchResult from './GlobalSearchComponent/SearchResult'
import ServiceDeskInnerCallsRouter from './routing/ServiceDeskInnerCallsRouter'
import TrustedOrganizationsRouter from './routing/TrustedOrganizationsRouter'

const drawerWidth = config.left_drawer_width
const drawerFiltersWidth = config.right_drawer_width

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  shiftedContent: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: 600,
    }),
  },
  '@media (min-width: 1280px)': {
    shiftedContent: {
      width: `calc(100% - ${drawerFiltersWidth + drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: 600,
      }),
    },
  },
  '@media (max-width: 1279px)': {
    shiftedContent: {
      width: `calc(100% - ${drawerFiltersWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: 600,
      }),
    },
  },

  shiftedContentMin: {
    width: `calc(100% - ${drawerFiltersWidth + 54}px)`,
    marginLeft: 54,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: 600,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  progress: {
    margin: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
  mainContent: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 40,
    marginRight: 40,
    marginTop: theme.spacing(2),
  },
})

const App = props => {
  const { classes, isLoggedIn, getAllUserSettings } = props
  React.useEffect(() => {
    if (isLoggedIn) {
      getAllUserSettings()
    }
  }, [isLoggedIn])
  const { drawerMode } = useAppState()
  return (
    <div className={classes.root}>
      <Notifier />
      <div className={classes.appFrame}>
        <AppBarComponent {...{ drawerMode }} />
        <main
          className={classNames(
            classes.content,
            {
              [classes.shiftedContentMin]:
                drawerMode === appOptions.drawerMode.collapsible && isLoggedIn,
            },
            {
              [classes.shiftedContent]:
                drawerMode === appOptions.drawerMode.common && isLoggedIn,
            },
          )}
        >
          <div className={classes.mainContent}>
            <DialogLoader />
            <BreadCrumb to="/">
              {i18next.t('system_menu_web_atoir:title')}
            </BreadCrumb>
            <Switch>
              <Route
                exact
                path="/(registration|repair_password_action)?"
                component={HomeContainer}
              />
              <Route path="/join" component={RegisterEmailContainer} />
              <Route path="/email_sent" component={EmailSentComponent} />
              <Route
                path="/activate_account"
                component={AccountActivationContainer}
              />
              <Route
                path="/repair_link_sent"
                component={RepairPasswordSentComponent}
              />
              <Route
                path="/repair_password"
                component={RepairPasswordContainer}
              />
              <Route path="/about_system" component={AboutSystemComponent} />
              <Route
                path="/service_support"
                component={ServiceSupportComponent}
              />
              <Route path="/tariff" component={TariffComponent} />
              <Route path="/error" component={ErrorContainer} />
              <Route path="/connection-error" component={SomethingWentWrong} />
              <Auth>
                <Switch>
                  <WrapperRoute
                    path="/profile"
                    title={i18next.t('user_profile_web_atoir:userProfileTitle')}
                    component={UserRouter}
                  />
                  <WrapperRoute
                    title={i18next.t(
                      'user_profile_web_atoir:userProfileCrumbTitle',
                    )}
                    path="/staff_worker_profile/:id"
                    component={UserProfileContainer}
                  />
                  <WrapperRoute
                    path="/organizations"
                    component={OrganizationsRouter}
                    title={i18next.t(
                      'organizations_web_atoir:organizationsList',
                    )}
                  />
                  <WrapperRoute
                    path="/contacts"
                    component={ContactRouter}
                    title={i18next.t('employees_web_atoir:accountStaffTitle')}
                  />
                  <WrapperRoute
                    path="/equipment"
                    component={EquipmentRouter}
                    title={i18next.t(
                      'organizations_web_atoir:organizationEquipmentListTitle',
                    )}
                  />
                  <WrapperRoute
                    path="/inner_calls"
                    title={i18next.t('service_desk_web_atoir:contracts')}
                    component={ServiceDeskInnerCallsRouter}
                  />
                  <WrapperRoute
                    path="/trusted_organizations"
                    title={i18next.t('shared_web_atoir:trustedOrganizations')}
                    component={TrustedOrganizationsRouter}
                  />
                  <WrapperRoute
                    path="/service_desk"
                    component={ServiceDeskRouter}
                  />
                  <WrapperRoute
                    path="/service_desk_contracts"
                    component={ServiceDeskContractsRouter}
                  />
                  <WrapperRoute
                    path="/add_equipment_list"
                    component={AddEquipmentListComponent}
                    title={i18next.t(
                      'equipment_web_atoir:addEquipmentUnitTitle',
                    )}
                  />
                  <WrapperRoute
                    path="/inventory_projects/:projectId/edit"
                    component={EditInventoryProject}
                    title={i18next.t(
                      'inventory_web_atoir:editInventoryProjectTitle',
                    )}
                  />
                  <WrapperRoute
                    path="/inventory_projects/:projectId/equipment"
                    component={InventoryProjectEquipment}
                  />
                  <WrapperRoute
                    path="/inventory_projects/:projectId/change_log"
                    component={InventoryProjectChangeLog}
                  />
                  <WrapperRoute
                    path="/inventory_projects/:projectId"
                    component={InventoryProjectProfile}
                    title={`${i18next.t(
                      'inventory_web_atoir:projectInventory',
                    )}`}
                  />
                  <WrapperRoute
                    path="/inventory"
                    component={InventoryList}
                    title={i18next.t(
                      'inventory_web_atoir:accountInventoryListTitle',
                    )}
                  />
                  <WrapperRoute
                    path="/inventory/:tab"
                    component={InventoryList}
                    title={i18next.t(
                      'inventory_web_atoir:accountInventoryListTitle',
                    )}
                  />
                  <WrapperRoute
                    path="/inventory_project/create"
                    component={AddInventoryProject}
                    title={i18next.t(
                      'inventory_web_atoir:createInventoryProjectTitle',
                    )}
                  />
                  <WrapperRoute
                    path="/faq"
                    component={FAQRouter}
                    title={i18next.t(
                      'system_menu_web_atoir:faqReferenceMenuItem',
                    )}
                  />
                  <WrapperRoute
                    path="/search_result"
                    component={SearchResult}
                    title={i18next.t('shared_web_atoir:searchResult')}
                  />

                  <Route component={NoMatchComponent} />
                </Switch>
              </Auth>
            </Switch>
            <AppDialog />
          </div>
        </main>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(
  translate(namespaces, { wait: true })(App),
)
