import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Tags from './Tags'
import {
  getTagsRequest,
  deleteTagRequest,
} from '../../../../actions/tagsActions'
import { updateSettingsLists } from '../../../../actions/settingsActions'

const mapStateToProps = state => ({
  isFetching: state.tags.getTags.isFetching,
  tags: state.tags.getTags.tags,
  getTagsError: state.tags.getTags.error,
  isDeleting: state.tags.deleteTag.isFetching,
  deleteTagError: state.tags.deleteTag.error,
  shouldUpdateTagsList: state.settings.updateSettingsLists,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTagsRequest,
      deleteTagRequest,
      updateSettingsLists,
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tags))
