import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Portal from '@material-ui/core/Portal'
import Notifications from './Notifications'

import i18next from '../../../i18n/i18n'
import BreadCrumb from '../../App/routing/BreadCrumb'
import { popLastSections } from '../../../helpers/dataHelper'
import UserSettingsProvider from './UserSettingsProvider'
import Section from './Section'
import SDCallsListSettings from './SDCallsListSettings'
import SDOrdersListSettings from './SDOrdersListSettings'
import AppTableOfContents from './SettingsTableOfContents'

const Title = ({ title, id }) => (
  <Box mb={2}>
    <Typography id={id} variant="h6">
      {title}
    </Typography>
  </Box>
)

const UserSettings = ({ match: { url }, setFilterDrawer }) => {
  const container = React.useRef(null)
  React.useEffect(() => {
    setFilterDrawer(<div ref={container} />)
  }, [])
  return (
    <div>
      <BreadCrumb to={popLastSections(url)}>
        {i18next.t('user_profile_web_atoir:userProfileCrumbTitle')}
      </BreadCrumb>
      <BreadCrumb to={url}>
        {i18next.t('user_profile_web_atoir:profileSettings')}
      </BreadCrumb>
      <Typography variant="h4" gutterBottom>
        {i18next.t('user_profile_web_atoir:profileSettings')}
      </Typography>
      <Box mt={4}>
        <Portal container={container.current}>
          <div>
            <AppTableOfContents
              items={[
                {
                  text: 'Ремонты.Медицина',
                  hash: 'sd-title',
                  children: [
                    {
                      text: 'Настройка списка заявок',
                      hash: 'sd-calls-list-settings',
                      children: [],
                    },
                    {
                      text: 'Настройка списка заказ-нарядов',
                      hash: 'sd-orders-list-settings',
                      children: [],
                    },
                    {
                      text: 'Настройка оповещений',
                      hash: 'sd-notifications-settings',
                      children: [],
                    },
                  ],
                },
              ]}
            />
          </div>
        </Portal>
        <UserSettingsProvider>
          {({ settings, onChangeById }) => (
            <>
              <Title id="sd-title" title="Ремонты.Медицина" />
              <div id="sd-calls-list-settings" />
              <Section
                title="Настройка списка заявок"
                tip="Выберите поля, которые будут отображаться для вас в списке заявок:"
              >
                <SDCallsListSettings
                  settings={settings}
                  onChangeById={onChangeById}
                />
              </Section>
              <div id="sd-orders-list-settings" />
              <Section
                title="Настройка списка заказ-нарядов"
                tip="Выберите поля, которые будут отображаться для вас в списке заказ-нарядов:"
              >
                <SDOrdersListSettings
                  settings={settings}
                  onChangeById={onChangeById}
                />
              </Section>
            </>
          )}
        </UserSettingsProvider>
        <div id="sd-notifications-settings" />
        <Section title="Настройка оповещений">
          <Notifications />
        </Section>
      </Box>
    </div>
  )
}

export default UserSettings
