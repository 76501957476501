import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TrustedOrganizationsTable from './TrustedOrganizationsTable'
import {
  changePage,
  changeRowsPerPage,
} from '../../../actions/trustedOrganizationActions'

const mapStateToProps = state => ({
  page: state.trustedOrganizations.page,
  rowsPerPage: state.trustedOrganizations.rowsPerPage,
  trusts: state.trustedOrganizations.trustsList.trusts,
  count: state.trustedOrganizations.trustsListCount.count,
  isFetching: state.trustedOrganizations.trustsList.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage,
      changeRowsPerPage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrustedOrganizationsTable),
)
