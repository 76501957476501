import React, { Component } from 'react'
import PropTypes from 'prop-types'

// material
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

// modules
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import ChooseThirdPartyOrganization from '../../ChooseThirdPartyOrganization'
import Heading from '../../../../widgets/Lettering/Heading'

// i18
import i18next from '../../../../i18n/i18n'

const styles = () => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
})

class AssignToThirdPartyOrganization extends Component {
  state = {
    organization: null,
    emptyOrganization: null,
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isAssigning !== this.props.isAssigning &&
      this.props.isAssigning === false
    ) {
      this.props.onClose()
      if (!this.props.assignError) {
        this.props.onFinish(this.props.assignError)
      }
    }
  }

  onOrganizationChanged = organization => {
    this.setState({ organization })
  }

  onAssign = () => {
    const {
      state: { organization },
      props: { contract, assignContractRequest },
    } = this
    if (!organization) {
      this.setState({
        emptyOrganization: i18next.t(
          'service_desk_web_atoir:emptyOrganizationError',
        ),
      })
    } else {
      assignContractRequest({
        id: contract.id,
        performer_organization_id: organization.id,
      })
    }
  }

  render() {
    const {
      state: { organization },
      props: { classes, open, onClose, excludeOrganizationIds },
      onAssign,
      onOrganizationChanged,
    } = this
    return (
      <Dialog
        classes={{ paper: classes.dialogRoot }}
        open={open}
        onClose={onClose}
        maxWidth={false}
      >
        <Heading>
          <Typography variant="h6" style={{ padding: 16 }}>
            {i18next.t('service_desk_web_atoir:assigningThirdPartyTitle')}
          </Typography>
        </Heading>
        <Divider />
        <DialogContent style={{ padding: 0, paddingTop: 16 }}>
          <ChooseThirdPartyOrganization
            excludeOrganizationIds={excludeOrganizationIds}
            selected={organization && organization.id}
            onOrganizationChanged={onOrganizationChanged}
          />
          <Typography style={{ padding: 12 }} color="error">
            {this.state.emptyOrganization}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <OutlinedButton onClick={onClose} size="medium">
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton onClick={onAssign} autoFocus size="medium">
            {i18next.t('service_desk_web_atoir:assignToThirdParty')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

AssignToThirdPartyOrganization.defaultProps = {
  excludeOrganizationIds: null,
}

AssignToThirdPartyOrganization.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  contract: PropTypes.string.isRequired,
  excludeOrganizationIds: PropTypes.array,
}

export default withStyles(styles)(AssignToThirdPartyOrganization)
