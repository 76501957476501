import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AcceptAgreement from './AcceptAgreement'
import { acceptAgreementRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isAccepting: state.serviceDeskContracts.acceptAgreement.isFetching,
  acceptError: state.serviceDeskContracts.acceptAgreement.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      acceptAgreementRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcceptAgreement),
)
