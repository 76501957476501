import React from 'react'
import PropTypes from 'prop-types'

import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'

import History from './historyConstuctor'

import DateCell from './DateCell'
import CommentCell from './CommentCell'

function TpgrCust({ children, ...rest }) {
  return (
    <Typography className="preBreak" {...rest}>
      {children}
    </Typography>
  )
}

const WhoComponent = ({ who, organization }) => (
  <div>
    <div>
      <TpgrCust noWrap>{who}</TpgrCust>
    </div>
    <div>
      <TpgrCust noWrap color="textSecondary">
        {organization}
      </TpgrCust>
    </div>
  </div>
)

const ActionViewer = ({ action }) => {
  const [history, setHistory] = React.useState(new History(action))
  React.useEffect(() => {
    if (action) setHistory(new History(action))
  }, [action])
  return (
    <TableRow hover>
      <DateCell>
        <TpgrCust>{history.getDateTime()}</TpgrCust>
      </DateCell>
      <TableCell>
        <WhoComponent
          who={history.getWho()}
          organization={history.getOrganization()}
        />
      </TableCell>
      <CommentCell>
        <TpgrCust>{history.getComment()}</TpgrCust>
      </CommentCell>
    </TableRow>
  )
}

ActionViewer.propTypes = {
  action: PropTypes.object.isRequired,
}

export default ActionViewer
