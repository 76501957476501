import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import OrganizationProfileComponent from '../../components/Organizations/Profile/OrganizationProfileComponent'
import {
  fetchOrganization,
  fetchOrganizationPermissions,
} from '../../actions/organizationsActions'
import isEmpty from 'is-empty'

const mapStateToProps = state => ({
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  isUpdatingOrganization: state.organizations.isUpdatingOrganization,
  isFetchingOrganizationPermissions:
    state.organizations.organizationPermissions
      .isFetchingOrganizationPermissions,
  organizationPermissions:
    state.organizations.organizationPermissions.permissions,
  fetchingPermissionsError: state.organizations.organizationPermissions.error,
  organization: state.organizations.organization,
  organizationError: state.organizations.errors,
  accessData: !isEmpty(state.login.accessData)
    ? state.login.accessData
    : state.registerAccount.accessData,
  isUpdatingLocation: state.locations.isUpdatingLocation,
  isCreatingLocation: state.locations.isCreatingLocation,
  updatingLocationError: state.locations.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganization,
      fetchOrganizationPermissions,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationProfileComponent),
)
