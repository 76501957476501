import { takeLatest, select, fork, take } from 'redux-saga/effects'
import * as actions from '../actions/trustedOrganizationActions'
import * as constants from '../constants/actionTypes/trustedOrganizations'
import * as api from './apis/trustedOrganizations'
import { createDefaultSaga } from '../helpers/api'
import { executeReq, post } from './appSaga'

function* fetchTrusts() {
  const defaultSaga = createDefaultSaga(
    payload => api.getTrustedOrganizationsTrustsApi(payload),
    result => actions.fetchTrustsSuccess(result.trusts),
    code => actions.fetchTrustsFailure(code),
    false,
  )
  const trustedOrganizationsState = yield select(s => s.trustedOrganizations)
  yield* defaultSaga({
    payload: {
      performer_organization_id:
        trustedOrganizationsState.clientOrganization !== null
          ? trustedOrganizationsState.clientOrganization
          : undefined,
      client_organization_id:
        trustedOrganizationsState.performerOrganization !== null
          ? trustedOrganizationsState.performerOrganization
          : undefined,
      side:
        trustedOrganizationsState.side !== 'all'
          ? trustedOrganizationsState.side
          : undefined,
      status:
        trustedOrganizationsState.status !== 'all'
          ? trustedOrganizationsState.status
          : undefined,
      limit: trustedOrganizationsState.rowsPerPage,
      offset:
        trustedOrganizationsState.rowsPerPage * trustedOrganizationsState.page,
    },
  })
}

function* fetchCount() {
  const defaultSaga = createDefaultSaga(
    payload => api.getTrustedOrganizationsTrustsCountApi(payload),
    result => actions.fetchTrustsCountSuccess(result.count),
    code => actions.fetchTrustsCountFailure(code),
    false,
  )
  const trustedOrganizationsState = yield select(s => s.trustedOrganizations)
  yield* defaultSaga({
    payload: {
      performer_organization_id:
        trustedOrganizationsState.clientOrganization !== null
          ? trustedOrganizationsState.clientOrganization
          : undefined,
      client_organization_id:
        trustedOrganizationsState.performerOrganization !== null
          ? trustedOrganizationsState.performerOrganization
          : undefined,
      side:
        trustedOrganizationsState.side !== 'all'
          ? trustedOrganizationsState.side
          : undefined,
      status:
        trustedOrganizationsState.status !== 'all'
          ? trustedOrganizationsState.status
          : undefined,
    },
  })
}

function* watchCreateTrust() {
  while (true) {
    const {
      payload: { contragent_id: contragentId, side, organizationId },
    } = yield take(constants.CREATE_TRUST.REQUEST)
    yield executeReq(
      post(`/organizations/${organizationId}/trusted`, {
        contragent_id: contragentId,
        side,
      }),
      actions.createTrust.FAILURE,
      actions.createTrust.SUCCESS,
      result => result,
      true,
    )
  }
}

export function* watchFetchTrustsSaga() {
  yield takeLatest(constants.FETCH_TRUSTS_REQUEST, fetchTrusts)
  yield takeLatest(constants.CHANGE_PAGE, fetchTrusts)
  yield takeLatest(constants.CHANGE_ROWS_PER_PAGE, fetchTrusts)
  yield takeLatest(constants.CHANGE_STATUS, fetchTrusts)
  yield takeLatest(constants.APPLY_FILTERS, fetchTrusts)
  yield takeLatest(constants.FETCH_TRUSTS_COUNT_REQUEST, fetchCount)
  yield takeLatest(constants.CHANGE_STATUS, fetchCount)
  yield takeLatest(constants.APPLY_FILTERS, fetchCount)
  yield takeLatest(
    constants.ACCEPT_TRUST_REQUEST,
    createDefaultSaga(
      payload => api.acceptTrustApi(payload),
      (r, p) => actions.acceptTrustSuccess(p),
      code => actions.acceptTrustFailure(code),
      true,
    ),
  )
  yield takeLatest(
    constants.REJECT_TRUST_REQUEST,
    createDefaultSaga(
      payload => api.rejectTrustApi(payload),
      (r, p) => actions.rejectTrustSuccess(p),
      code => actions.rejectTrustFailure(code),
      true,
    ),
  )
}

export function* root() {
  yield fork(watchCreateTrust)
}
