import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// components
import Loader from '../../Loader'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import PrimaryButton from '../../../widgets/Buttons/PrimaryButton'
import SecondaryButton from '../../../widgets/Buttons/SecondaryButton'

class FormDialogComponent extends Component {
  handleSubmitForm = event => {
    event.preventDefault()
    const {
      props: { onSubmit },
    } = this
    onSubmit()
  }

  render() {
    const {
      props: {
        open,
        onClose,
        onExit,
        isLoading,
        title,
        contentText,
        content,
        submitButtonTitle,
      },
    } = this

    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={() => onClose()}
          onExit={() => onExit()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.handleSubmitForm}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {contentText}
              </DialogContentText>
              {isLoading ? <Loader /> : null}
              {content}
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={() => onClose()}>
                {i18next.t('shared_web_atoir:cancel')}
              </SecondaryButton>
              <PrimaryButton type="submit">{submitButtonTitle}</PrimaryButton>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    )
  }
}
FormDialogComponent.defaultProps = {
  contentText: '',
}

FormDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string,
  content: PropTypes.object.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
}

export default FormDialogComponent
