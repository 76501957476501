import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
  root: {
    margin: 8,
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
  },
  icon: {
    size: 24,
    color: theme.palette.blueSecondary.main,
    marginRight: theme.spacing(1),
  },
})

function TextButton(props) {
  return (
    <Button size={props.size} className={props.classes.root} {...props}>
      <AddIcon className={props.classes.icon} />
      {props.children}
    </Button>
  )
}

TextButton.defaultProps = {
  children: '',
  size: 'small',
}

TextButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
}

export default withStyles(styles)(TextButton)
