import * as Yup from 'yup'

function removeEmptyObjects(obj) {
  if (_.isEmpty(obj)) {
    return {}
  }
  return _(obj)
    .pickBy(_.isObject)
    .mapValues(removeEmptyObjects)
    .omitBy(_.isEmpty)
    .assign(_.omitBy(obj, _.isObject))
    .value()
}

const removeObjectsWithNull = obj =>
  _(obj)
    .pickBy(_.isObject)
    .mapValues(removeObjectsWithNull)
    .assign(_.omitBy(obj, _.isObject))
    .omitBy(_.isNil)
    .value()

const compactObject = value => removeEmptyObjects(removeObjectsWithNull(value))

Yup.addMethod(Yup.object, 'notEmptyRecursively', function(message) {
  return this.test(
    'notEmpty',
    message || 'object empty',
    value => !_.isEmpty(compactObject(value)),
  )
})

const comditionSchema = Yup.object().shape({
  file_attached_in_current_status: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.boolean().required(),
    }),
  ),
  order_performer: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.string().required(),
    }),
  ),
  order_client: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.string().required(),
    }),
  ),
  order_type: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.string().oneOf(['diagnostic', 'repair']),
    }),
  ),
  order_result: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.string().oneOf(['needrepair', 'complete', 'writeoff']),
    }),
  ),
  order_status: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.string().oneOf([
        'inwork',
        'cancel',
        'done',
        'close',
        'control',
        'qa',
        'doneqa',
      ]),
    }),
  ),
  call_status: Yup.array().of(
    Yup.object().shape({
      number: Yup.number()
        .integer()
        .required(),
      operator: Yup.string()
        .oneOf(['equal', 'notEqual'])
        .required(),
      value: Yup.string()
        .oneOf([
          'created',
          'auction',
          'inwork',
          'done',
          'close',
          'cancel',
          'repair',
          'needrepair',
        ])
        .required(),
    }),
  ),
})

export default Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string()
    .max(255)
    .nullable(),
  scope: Yup.array()
    .of(Yup.string().oneOf(['owner', 'admin', 'manager', 'plain']))
    .min(1)
    .required(),
  show_in: Yup.array()
    .of(Yup.string().oneOf(['call', 'order']))
    .min(1)
    .required(),
  conditions: Yup.object()
    .required()
    .shape({
      simple: comditionSchema,
    })
    .required(),
  logic: Yup.string()
    .max(256)
    .nullable(),
  actions: Yup.array().of(
    Yup.object()
      .required()
      .shape({
        id: Yup.string()
          .max(256)
          .nullable(),
        action_type: Yup.string().oneOf([
          'send_email',
          'create_sc_tag',
          'create_sc_order_tag',
        ]),
        custom_tag_id: Yup.string().when('action_type', {
          is: actionType =>
            actionType === 'create_sc_tag' ||
            actionType === 'create_sc_order_tag',
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        email_template_id: Yup.string().when('action_type', {
          is: 'send_email',
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        options: Yup.object().when('action_type', {
          is: 'send_email',
          then: Yup.object()
            .shape({
              sender_address: Yup.object().shape({
                type: Yup.string()
                  .oneOf(['action_maker', 'custom'])
                  .required(),
                address: Yup.string().when('type', {
                  is: 'custom',
                  then: Yup.string()
                    .required()
                    .test(
                      'wrongForm',
                      'wrongForm',
                      value =>
                        !(value.indexOf('@') >= 0 && value.indexOf(',') >= 0),
                    ),
                  otherwise: Yup.string().nullable(),
                }),
              }),
              recipient_address: Yup.object().shape({
                type: Yup.string()
                  .oneOf(['action_maker', 'custom'])
                  .required(),
                address: Yup.string().when('type', {
                  is: 'custom',
                  then: Yup.lazy(value =>
                    typeof value === 'string'
                      ? Yup.string()
                          .email()
                          .required()
                      : Yup.string()
                          .email()
                          .required(),
                  ),
                  otherwise: Yup.string().nullable(),
                }),
              }),
            })
            .required(),
          otherwise: Yup.object().nullable(),
        }),
      }),
  ),
})
