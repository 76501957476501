import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEmpty from 'is-empty'
import i18next from '../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'

// components
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'
import ChoosingSpecialistForm from './ChooseSpecialistForm'
import Heading from '../../../widgets/Lettering/Heading'

const styles = theme => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  containedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.dark,
      color: 'white',
    },
  },
  outlinedButton: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: theme.palette.blueSecondary.main,
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
    '&:disabled': {
      borderColor: theme.palette.apply.extraLight,
      color: theme.palette.apply.extraLight,
    },
  },
})

class AssignToOwnSpecialist extends Component {
  state = {
    employee: null,
    date: new Date(),
    comment: null,
    errors: {},
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isAssigning !== this.props.isAssigning &&
      this.props.isAssigning === false
    ) {
      this.props.onClose()
      this.props.hideDialogLoader()
      if (!this.props.assigningError) {
        this.props.onFinish()
      }
    }
  }

  onSpecialistChange = event => {
    const { errors } = this.state
    if (errors.employee) delete errors.employee
    this.setState({ employee: event.target.value, errors })
  }

  onDateChange = date => {
    const { errors } = this.state
    if (errors.date) delete errors.date
    this.setState({ date, errors })
  }

  onCommentChange = event => {
    this.setState({ comment: event.target.value })
  }

  assignToOwnSpecialist = payload => {
    this.props.showDialogLoader()
    this.props.assignServiceDeskRequestToOwnSpecialist(payload)
  }

  onAssign = () => {
    const {
      state: { employee, date, comment },
      props: { callId, onSubmit },
      assignToOwnSpecialist,
    } = this
    const errors = {}
    if (!employee) {
      errors.employee = i18next.t('error_web_atoir:emptyEmployee')
    }
    if (!date) {
      errors.date = i18next.t('shared_web_atoir:incorrectDateFormat')
    }
    this.setState({ errors })
    if (isEmpty(errors) && !isEmpty(onSubmit)) {
      onSubmit({
        engineer_user_id: employee,
        visit_date: date,
        visit_comment: comment,
      })
      return
    }
    if (isEmpty(errors)) {
      assignToOwnSpecialist({
        callId,
        engineer_user_id: employee,
        visit_date: date,
        visit_comment: comment,
      })
    }
  }

  render() {
    const {
      state: { employee, date, comment, errors },
      props: { classes, open, onClose, organizationId },
      onAssign,
      onSpecialistChange,
      onCommentChange,
      onDateChange,
    } = this
    return (
      <Dialog
        classes={{ paper: classes.dialogRoot }}
        open={open}
        onClose={onClose}
      >
        <Heading
          className={classnames(classes.subheading, classes.defaultPadding)}
        >
          {i18next.t('service_desk_web_atoir:assigningToOwnSpecialistTitle')}
        </Heading>
        <Divider />
        <DialogContent style={{ padding: 24, paddingTop: 16 }}>
          <ChoosingSpecialistForm
            employee={employee}
            date={date}
            comment={comment}
            errors={errors}
            organizationId={organizationId}
            onChangeComment={onCommentChange}
            onChangeEmployee={onSpecialistChange}
            onChangeDate={onDateChange}
          />
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <OutlinedButton onClick={onClose} className={classes.outlinedButton}>
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton
            onClick={onAssign}
            className={classes.containedButton}
            autoFocus
          >
            {i18next.t('service_desk_web_atoir:assignToOwnSpecialist')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

AssignToOwnSpecialist.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  callId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default withStyles(styles)(AssignToOwnSpecialist)
