import React, { Component } from 'react'
import { popLastSections } from '../../../../helpers/dataHelper'
import { canViewContractInfo } from '../../../../helpers/ContractPermissionsResolver'
import TextButton from '../../../../widgets/Buttons/TextButton'
import i18next from '../../../../i18n/i18n'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import UpScrollButton from '../../../../widgets/Buttons/UpScrollButton'
import DistributeEquipmentTable from './DistributeEquipmentTable'
import { withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import ButtonBase from '@material-ui/core/ButtonBase'
import RightTips from '../../Contracts/Widgets/RightTips'

const styles = theme => ({
  middleText: {
    marginBottom: 12,
    fontSize: 12,
  },
  topText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    wordBreak: 'break-word',
    fontSize: theme.typography.pxToRem(18),
  },
  instruction: {
    whiteSpace: 'pre-line',
  },
  card: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

class TipCardAssignComponent extends Component {
  state = {
    open: false,
  }

  toggle = () => {
    this.setState(state => ({
      open: !state.open,
    }))
  }

  render() {
    return (
      <Card
        onClick={this.toggle}
        className={this.props.classes.card}
        style={this.props.rootStyles}
      >
        <CardContent>
          <Typography className={this.props.classes.topText}>
            <InfoOutlined />
            {i18next.t('service_desk_web_atoir:assignEquipmentToEngineer')}
          </Typography>
          {this.state.open && (
            <Typography
              component="pre"
              variant="body2"
              className={this.props.classes.instruction}
            >
              {i18next
                .t('service_desk_web_atoir:assignEquipmentInstruction')
                .replace(/\\n/g, '\n')}
            </Typography>
          )}
          {this.state.open ? (
            <ButtonBase disableTouchRipple disableRipple>
              <Typography variant="subtitle2">
                {i18next.t('shared_web_atoir:rollup')}
              </Typography>
            </ButtonBase>
          ) : (
            <ButtonBase>
              <Typography variant="subtitle2">
                {i18next.t('shared_web_atoir:buttonShow')}
              </Typography>
            </ButtonBase>
          )}
        </CardContent>
      </Card>
    )
  }
}
const TipCardAssign = withStyles(styles)(TipCardAssignComponent)

class TipCardUnAssignComponent extends Component {
  state = {
    open: false,
  }

  toggle = () => {
    this.setState(state => ({
      open: !state.open,
    }))
  }

  render() {
    return (
      <Card className={this.props.classes.card} style={this.props.rootStyles}>
        <CardContent onClick={this.toggle}>
          <Typography className={this.props.classes.topText}>
            <InfoOutlined />
            {i18next.t('service_desk_web_atoir:unassignEquipmentToEngineer')}
          </Typography>
          {this.state.open && (
            <Typography
              component="pre"
              variant="body2"
              className={this.props.classes.instruction}
            >
              {i18next
                .t('service_desk_web_atoir:unassignEquipmentInstruction')
                .replace(/\\n/g, '\n')}
            </Typography>
          )}
          {this.state.open ? (
            <ButtonBase disableTouchRipple disableRipple>
              <Typography variant="subtitle2">
                {i18next.t('shared_web_atoir:rollup')}
              </Typography>
            </ButtonBase>
          ) : (
            <ButtonBase onClick={this.toggle}>
              <Typography variant="subtitle2">
                {i18next.t('shared_web_atoir:buttonShow')}
              </Typography>
            </ButtonBase>
          )}
        </CardContent>
      </Card>
    )
  }
}
const TipCardUnAssign = withStyles(styles)(TipCardUnAssignComponent)

class DistributeEquipment extends Component {
  constructor(props) {
    super(props)
    this.equipmentTableRef = React.createRef()
  }

  componentDidMount() {
    const {
      props: {
        fetchContractPermissionsRequest,
        match: {
          params: { contractId },
        },
      },
    } = this
    fetchContractPermissionsRequest(contractId)
    this.props.getTitleInfo({
      title: i18next.t('service_desk_web_atoir:equipmentDistributionTitle'),
      OuterButton: (
        <TextButton
          onClick={() => {
            this.props.history.push(popLastSections(this.props.match.url))
          }}
        >
          {i18next.t('shared_web_atoir:close')}
        </TextButton>
      ),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetchingPermissions !== this.props.isFetchingPermissions &&
      !this.props.isFetchingPermissions &&
      !this.props.permissionsError
    ) {
      if (canViewContractInfo(this.props.permissions)) {
        this.props.fetchContractRequest(this.props.match.params.contractId)
      } else {
        this.props.history.push('/404')
      }
    }
  }

  toggle = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  onSubmit = () => {}

  onChange = () => {}

  render() {
    const {
      props: { contract, match, permissions },
    } = this
    return (
      <div>
        {contract && (
          <BreadCrumb to={popLastSections(match.url)}>
            {i18next.t('service_desk_web_atoir:contractProfileTitle', {
              number: contract.num_contract,
              from: `${contract.month_begin}.${contract.year_begin}`,
              to: `${contract.month_end}.${contract.year_end}`,
            })}
          </BreadCrumb>
        )}
        <BreadCrumb to={match.url}>
          {i18next.t('service_desk_web_atoir:addEquipmentToContract')}
        </BreadCrumb>
        <UpScrollButton />
        <RightTips>
          <TipCardAssign />
          <TipCardUnAssign rootStyles={{ marginTop: 8 }} />
        </RightTips>
        <div id="distribution-equipment-table" ref={this.equipmentTableRef}>
          {contract && (
            <DistributeEquipmentTable
              contract={contract}
              permissions={permissions}
            />
          )}
        </div>
      </div>
    )
  }
}

DistributeEquipment.defaultProps = {}

DistributeEquipment.propTypes = {}

export default DistributeEquipment
