/* eslint-disable import/no-duplicates */
import { takeLatest, put, all, take, fork } from 'redux-saga/effects'
import queryString from 'query-string'
import isEmpty from 'is-empty'

import { call } from './loginMiddlewares'
import { executeReq, putReq, post, get, deleteReq } from './appSaga'

import {
  CREATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_REQUEST,
  FETCH_USER_ORGANIZATIONS_REQUEST,
  FETCH_NOT_USER_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_PERMISSIONS_REQUEST,
  FETCH_ALL_ORGANIZATIONS_REQUEST,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST,
  FETCH_SERVICE_ORGANIZATIONS_REQUEST,
  FETCH_ADMIN_ORGANIZATIONS_REQUEST,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST,
  FETCH_ORGANIZATION_WHO_AM_I_REQUEST,
  FETCH_ORGANIZATION_USER_ME_REQUEST,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST,
  FETCH_USER_ORGANIZATIONS_COUNT_REQUEST,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST,
  CREATE_SD_INVOICE_FOR_ORGANIZATION,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST,
} from '../constants/actionTypes'

import * as constants from '../constants/actionTypes'

import {
  createOrganizationRequest,
  updateOrganizationRequest,
  fetchUserOrganizationsRequest,
  fetchNotUserOrganizationsRequest,
  fetchOrganizationRequest,
  createContactRequest,
  fetchOrganizationPermissionsRequest,
  fetchAllOrganizationsRequest,
  fetchCitiesWithServiceOrganizationsRequest,
  fetchServiceOrganizationsRequest,
  fetchAdminOrganizationsRequest,
  fetchManagerOrHigherOrganizationsRequest,
  fetchOrganizationsMemborOfRequest,
  fetchOrganizationWhoAmIRequest,
  fetchOrganizationMeRequest,
  fetchServiceOrganizationsCountRequest,
  fetchUserOrganizationsCountRequest,
  fetchHasManagerOrHigherOrganizationsRequest,
  fetchUserOrganizationsFunctionalsRequest,
  fetchUserOrganizationsCanAddEquipmentRequest,
} from './api'
import {
  createOrganizationSuccess,
  createOrganizationFailure,
  updateOrganizationSuccess,
  updateOrganizationFailure,
  fetchUserOrganizationsSuccess,
  fetchUserOrganizationsFailure,
  fetchNotUserOrganizationsSuccess,
  fetchNotUserOrganizationsFailure,
  fetchOrganizationSuccess,
  fetchOrganizationFailure,
  fetchOrganizationPermissionsFailure,
  fetchOrganizationPermissionsSuccess,
  fetchAllOrganizationsSuccess,
  fetchAllOrganizationsFailure,
  fetchCitiesWithServiceOrganizationsSuccess,
  fetchCitiesWithServiceOrganizationsFailure,
  fetchServiceOrganizationsSuccess,
  fetchServiceOrganizationsFailure,
  fetchAdminOrganizationsSuccess,
  fetchAdminOrganizationsFailure,
  fetchManagerOrHigherOrganizationsFailure,
  fetchManagerOrHigherOrganizationsSuccess,
  fetchOrganizationsMemberOfSuccess,
  fetchOrganizationsMemberOfFailure,
  fetchOrganizationWhoAmISuccess,
  fetchOrganizationWhoAmIFailure,
  fetchOrganizationUserMeSuccess,
  fetchOrganizationUserMeFailure,
  fetchServiceOrganizationsCountSuccess,
  fetchServiceOrganizationsCountFailure,
  fetchUserOrganizationsCountSuccess,
  fetchUserOrganizationsCountFailure,
  fetchHasManagerOrHigherOrganizationsFailure,
  fetchHasManagerOrHigherOrganizationsSuccess,
  fetchUserOrganizationFunctionalsFailure,
  fetchUserOrganizationFunctionalsSuccess,
  createSDInvoiceForOrganizationSuccess,
  createSDInvoiceForOrganizationFailure,
  fetchUserOrganizationsCanAddEquipmentSuccess,
  fetchUserOrganizationsCanAddEquipmentFailure,
} from '../actions/organizationsActions'

import * as actions from '../actions/organizationsActions'

import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
// import { hideDialogLoader, showDialogLoader } from '../actions/utilsActions'

export function* watchFetchUserOrganizationFunctionalsSaga() {
  yield takeLatest(
    FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST,
    fetchUserOrganizationsFunctionals,
  )
}

export function* watchCreateOrganizationSaga() {
  yield takeLatest(CREATE_ORGANIZATION_REQUEST, createOrganization)
}

export function* watchUpdateOrganizationSaga() {
  yield takeLatest(UPDATE_ORGANIZATION_REQUEST, updateOrganization)
}

export function* watchFetchUserOrganizationsSaga() {
  yield takeLatest(FETCH_USER_ORGANIZATIONS_REQUEST, fetchUserOrganizations)
}

export function* watchFetchUserOrganizationsCountSaga() {
  yield takeLatest(
    FETCH_USER_ORGANIZATIONS_COUNT_REQUEST,
    fetchUserOrganizationsCount,
  )
}

export function* watchFetchAdminOrganizationsSaga() {
  yield takeLatest(FETCH_ADMIN_ORGANIZATIONS_REQUEST, fetchAdminOrganizations)
}

export function* watchFetchManagerOrHigherOrganizationsSaga() {
  yield takeLatest(
    FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
    fetchManagerOrHigherOrganizations,
  )
}

export function* watchFetchHasManagerOrHigherOrganizationsSaga() {
  yield takeLatest(
    FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
    fetchHasManagerOrHigherOrganizations,
  )
}

export function* watchFetchNotUserOrganizationsSaga() {
  yield takeLatest(
    FETCH_NOT_USER_ORGANIZATIONS_REQUEST,
    fetchNotUserOrganizations,
  )
}

export function* watchFetchOrganizationSaga() {
  yield takeLatest(FETCH_ORGANIZATION_REQUEST, fetchOrganization)
}

export function* watchFetchOrganizationWhoAmISaga() {
  yield takeLatest(FETCH_ORGANIZATION_WHO_AM_I_REQUEST, fetchOrganizationWhoAmI)
}

export function* watchFetchOrganizationMeSaga() {
  yield takeLatest(FETCH_ORGANIZATION_USER_ME_REQUEST, fetchOrganizationMe)
}

export function* watchFetchOrganizationsMemberOfSaga() {
  yield takeLatest(
    FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST,
    fetchOrganizationsMemberOf,
  )
}

export function* watchFetchOrganizationPermissionsSaga() {
  yield takeLatest(
    FETCH_ORGANIZATION_PERMISSIONS_REQUEST,
    fetchOrganizationPermissions,
  )
}

export function* watchFetchAllOrganizationsSaga() {
  yield takeLatest(FETCH_ALL_ORGANIZATIONS_REQUEST, fetchAllOrganizations)
}

export function* watchFetchCitiesWithServiceOrganizationsSaga() {
  yield takeLatest(
    FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST,
    fetchCitiesWithServiceOrganizations,
  )
}

export function* watchFetchServiceOrganizationsSaga() {
  yield takeLatest(
    FETCH_SERVICE_ORGANIZATIONS_REQUEST,
    fetchServiceOrganizations,
  )
}

export function* watchFetchServiceOrganizationCountsSaga() {
  yield takeLatest(
    FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST,
    fetchServiceOrganizationsCount,
  )
}

export function* watchFetchOrganizationsCanAddEquipmentSaga() {
  yield takeLatest(
    FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST,
    fetchOrganizationsCanAddEquipment,
  )
}

function* fetchUserOrganizationsFunctionals() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserOrganizationsFunctionalsRequest(accessToken),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        const payload = result.organizations
        const isFuncAvailable = result.organizations.find(
          element =>
            !!element.OrganizationFunctionalAvailables.map(
              el => el.name === 'sd_extended',
            ).length,
        )
        yield put(
          fetchUserOrganizationFunctionalsSuccess({
            isFuncAvailable: Boolean(isFuncAvailable),
            payload,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserOrganizationFunctionalsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      createOrganizationRequest(accessToken, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield all(
          action.contacts.map(c =>
            call(createContactRequest(accessToken, result.id, c)),
          ),
        )
        yield put(createOrganizationSuccess(result.data))
      } else {
        const errors = handleErrors(result)
        yield put(createOrganizationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      updateOrganizationRequest(accessToken, action.id, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateOrganizationSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(updateOrganizationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationsCanAddEquipment() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserOrganizationsCanAddEquipmentRequest(accessToken),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          fetchUserOrganizationsCanAddEquipmentSuccess(result.organizations),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserOrganizationsCanAddEquipmentFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserOrganizations(action) {
  try {
    const filters = action.filters === undefined ? '' : action.filters
    const sort = '[short_name=ASC]'
    let query = {
      filters,
      sort,
    }
    if (action.query) {
      query = {
        ...query,
        ...action.query,
      }
    }
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserOrganizationsRequest(accessToken, queryString.stringify(query)),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserOrganizationsSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserOrganizationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchUserOrganizationsCount(action) {
  try {
    const filters =
      action.filters === undefined ? '' : `?filters=${action.filters}`
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserOrganizationsCountRequest(accessToken, filters),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchUserOrganizationsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchUserOrganizationsCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationsMemberOf(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationsMemborOfRequest(accessToken, action.filters),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          fetchOrganizationsMemberOfSuccess({
            organizations: result.organizations,
            member: result.member,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationsMemberOfFailure(error))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchAdminOrganizations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchAdminOrganizationsRequest(accessToken, action.data),
    )
    // TODO error handling
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchAdminOrganizationsSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchAdminOrganizationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchManagerOrHigherOrganizations() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchManagerOrHigherOrganizationsRequest(accessToken),
    )
    // TODO error handling
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          fetchManagerOrHigherOrganizationsSuccess(result.organizations),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchManagerOrHigherOrganizationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchHasManagerOrHigherOrganizations() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchHasManagerOrHigherOrganizationsRequest(accessToken),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchHasManagerOrHigherOrganizationsSuccess(result.has))
      } else {
        const errors = handleErrors(result)
        yield put(fetchHasManagerOrHigherOrganizationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchNotUserOrganizations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchNotUserOrganizationsRequest(accessToken, action.name),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchNotUserOrganizationsSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchNotUserOrganizationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchOrganizationRequest(accessToken, action.id))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationSuccess(result.organization))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationFailure(result.code))
        if (result.code !== 64 && result.code !== 50) {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationWhoAmI(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationWhoAmIRequest(accessToken, action.id),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationWhoAmISuccess(result.permissions))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationWhoAmIFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationMe(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationMeRequest(accessToken, action.id),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationUserMeSuccess(result.me))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationUserMeFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationPermissions(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationPermissionsRequest(accessToken, action.id),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationPermissionsSuccess(result.permissions))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationPermissionsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchAllOrganizations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchAllOrganizationsRequest(accessToken, action.name),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchAllOrganizationsSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchAllOrganizationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchCitiesWithServiceOrganizations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchCitiesWithServiceOrganizationsRequest(accessToken, action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchCitiesWithServiceOrganizationsSuccess(result.cities))
      } else {
        const errors = handleErrors(result)
        yield put(fetchCitiesWithServiceOrganizationsFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceOrganizations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchServiceOrganizationsRequest(accessToken, action.filters),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchServiceOrganizationsSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchServiceOrganizationsFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
function* fetchServiceOrganizationsCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchServiceOrganizationsCountRequest(accessToken, action.filters),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchServiceOrganizationsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchServiceOrganizationsCountFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* watchGetOrgInfo() {
  while (true) {
    const {
      data: { id },
    } = yield take(CREATE_SD_INVOICE_FOR_ORGANIZATION.REQUEST)
    yield executeReq(
      post(`/organizations/${id}/request_check`),
      createSDInvoiceForOrganizationFailure,
      createSDInvoiceForOrganizationSuccess,
      result => result,
      true,
    )
  }
}

function* watchEditRestrictionSdCallsFromNonContragents() {
  while (true) {
    const {
      payload: { id, value },
    } = yield take(
      constants.EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS.REQUEST,
    )
    yield executeReq(
      putReq(`/organizations/${id}/settings`, {
        setting_name: 'restrict_sd_calls_from_non_contragents',
        enabled: value,
      }),
      actions.editRestrictionSdCallsFromNonContragents.FAILURE,
      actions.editRestrictionSdCallsFromNonContragents.SUCCESS,
      result => result,
      true,
    )
  }
}

function* watchGetOrganizationSettings() {
  while (true) {
    const {
      payload: { id },
    } = yield take(constants.GET_ORGANIZATION_SETTINGS.REQUEST)
    yield executeReq(
      get(`/organizations/${id}/settings`),
      actions.getOrganizationSettings.FAILURE,
      actions.getOrganizationSettings.SUCCESS,
      result => result,
      true,
    )
  }
}

function* watchGetOrganizationContragents() {
  while (true) {
    const {
      payload: { id, limit = 100, offset = 0 },
    } = yield take(constants.GET_ORGANIZATION_CONTRAGENTS.REQUEST)
    yield executeReq(
      get(
        `/organizations/${id}/contragents?${queryString.stringify({
          limit,
          offset,
          sort: '[short_name=ASC]',
        })}`,
      ),
      actions.getOrganizationContragents.FAILURE,
      actions.getOrganizationContragents.SUCCESS,
    )
  }
}

function* watchCreateOrganizationContragent() {
  while (true) {
    const {
      payload: { id, contragent_id: contragentId },
    } = yield take(constants.CREATE_ORGANIZATION_CONTRAGENT.REQUEST)
    yield executeReq(
      post(`/organizations/${id}/contragents`, { contragent_id: contragentId }),
      actions.createOrganizationContragent.FAILURE,
      actions.createOrganizationContragent.SUCCESS,
      result => result,
    )
  }
}

function* watchEditOrganizationContragent() {
  while (true) {
    const {
      payload: { id, contragent_id: contragentId, body },
    } = yield take(constants.EDIT_ORGANIZATION_CONTRAGENT.REQUEST)
    yield executeReq(
      putReq(`/organizations/${id}/contragents/${contragentId}`, body),
      actions.editOrganizationContragent.FAILURE,
      actions.editOrganizationContragent.SUCCESS,
      result => result,
      true,
    )
  }
}

function* watchDeleteOrganizationContragent() {
  while (true) {
    const {
      payload: { id, contragent_id: contragentId },
    } = yield take(constants.DELETE_ORGANIZATION_CONTRAGENT.REQUEST)
    yield executeReq(
      deleteReq(`/organizations/${id}/contragents/${contragentId}`),
      actions.deleteOrganizationContragent.FAILURE,
      actions.deleteOrganizationContragent.SUCCESS,
      result => result,
    )
  }
}

export default function* root() {
  yield fork(watchGetOrgInfo)
  yield fork(watchGetOrganizationSettings)
  yield fork(watchGetOrganizationContragents)
  yield fork(watchEditRestrictionSdCallsFromNonContragents)
  yield fork(watchCreateOrganizationContragent)
  yield fork(watchDeleteOrganizationContragent)
  yield fork(watchEditOrganizationContragent)
}
