import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Box } from '@material-ui/core'

const OneSwitch = ({ idValue, getOnChange, title, disabled, value }) => {
  const onChangeHandler = getOnChange(idValue)
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={value}
          onChange={(event, checked) => onChangeHandler(checked)}
          color="primary"
        />
      }
      label={title}
    />
  )
}

const SimpleSwitch = ({
  idValue,
  onChangeById,
  title,
  depended = [],
  value,
}) => {
  const getOnChangeHandler = _idValue => _value => {
    if (idValue === _idValue && !_value) {
      onChangeById(
        _.concat(
          { idValue: _idValue, value: false },
          depended.map(item => ({ idValue: item.idValue, value: false })),
        ),
      )
    } else {
      onChangeById({ idValue: _idValue, value: _value })
    }
  }
  return (
    <Box pl={2} pr={2}>
      <OneSwitch
        getOnChange={getOnChangeHandler}
        {...{ idValue, title, value }}
      />
      {depended &&
        depended.map(item => (
          <div style={{ paddingLeft: 16 }}>
            <OneSwitch
              disabled={!value}
              idValue={item.idValue}
              title={item.title}
              getOnChange={getOnChangeHandler}
              value={item.value}
            />
          </div>
        ))}
    </Box>
  )
}

export default SimpleSwitch
