import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ButtonsHistory from './ButtonsHistory'
import { fetchOrganization } from '../../../../../actions/organizationsActions'
import {
  getButtonsHistoryRequest,
  clearButtonsHistory,
} from '../../../../../actions/buttonsActions'

const mapStateToProps = state => ({
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
  isFetchingHistory: state.buttons.history.isFetching,
  history: state.buttons.history.history,
  end: state.buttons.history.end,
  fetchHistoryError: state.buttons.history.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganization,
      fetchButtonsHistory: getButtonsHistoryRequest,
      clearButtonsHistory,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ButtonsHistory),
)
