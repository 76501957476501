import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../i18n/i18n'
import FormDialog from '../../../../widgets/FormDialog'

class AssignToOwnOrganization extends Component {
  componentDidUpdate = prevProps => {
    if (
      prevProps.isAssigning !== this.props.isAssigning &&
      this.props.isAssigning === false
    ) {
      this.props.onClose()
      if (!this.props.assignError) {
        this.props.onFinish(this.props.assignError, this.props.assignOwn)
      }
    }
  }

  onAssign = () => {
    const {
      props: { contract, organizationId, assignContractRequest },
    } = this
    assignContractRequest({
      id: contract.id,
      performer_organization_id: organizationId,
      assignOwn: true,
    })
  }

  render() {
    const {
      props: { onClose, open, organizationName },
      onAssign,
    } = this
    return (
      <FormDialog
        closeIconHidden
        open={open}
        onClose={onClose}
        onSubmit={onAssign}
        contentText={`${i18next.t(
          'service_desk_web_atoir:areYouSureToAssignContractToOrganization',
          { organizationName },
        )}?`}
        submitButtonTitle={i18next.t('shared_web_atoir:assign')}
        secondaryButtonsArray={[
          {
            secondaryText: i18next.t('shared_web_atoir:close'),
            handleSecondaryAction: onClose,
          },
        ]}
      />
    )
  }
}

AssignToOwnOrganization.propTypes = {
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  contract: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
}

export default AssignToOwnOrganization
