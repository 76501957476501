import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import CallMade from '@material-ui/icons/CallMade'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  card: {
    minWidth: 280,
    margin: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.87)',
    wordBreak: 'break-word',
  },
  content: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  button: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  iconButton: {
    marginRight: 0,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
})

function ScriptCard(props) {
  const { classes, script, history, url, boundTo } = props
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.heading} gutterBottom>
          {script.name}
        </Typography>
        <Typography className={classes.content}>
          {i18next.t(
            `organizations_web_atoir:${script.type}IsBoundTo${boundTo}`,
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classnames([classes.button, classes.marginLeft])}
              gutterBottom
            >
              {i18next.t(
                `organizations_web_atoir:${script.type}CardLinkButtonTitle`,
              )}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.iconButton}
              onClick={() =>
                history.push(
                  `${url}/${
                    script.type === 'trigger' ? 'scripts' : 'buttons'
                  }/edit/${script.id}`,
                )
              }
            >
              <CallMade className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

ScriptCard.propTypes = {
  script: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  boundTo: PropTypes.oneOf(['Tag', 'EmailTemplate']).isRequired,
}

export default withStyles(styles)(ScriptCard)
