import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { lighten } from '@material-ui/core/styles/colorManipulator'

// components
// helpers
import { getUserFullName } from '../../../../../helpers/userHelper'
import i18next from '../../../../../i18n/i18n'
import LongTextExpander from '../../../../../widgets/LongTextExpander'

const styles = theme => ({
  divData: {
    marginBottom: theme.spacing(2),
  },
  greyText: {
    color: lighten(theme.palette.secondary.dark, 0.2),
  },
  divBottom: {
    marginTop: theme.spacing(3),
  },
  bigText: {
    fontSize: 34,
  },
  captionText: {
    fontSize: 14,
  },
  divMinHeight: {
    minHeight: 20,
  },
})

const ContractDetails = ({
  contractData,
  classes,
  title,
  footerText,
  children,
}) => {
  const {
    month_begin: monthBegin,
    year_begin: yearBegin,
    month_end: monthEnd,
    year_end: yearEnd,
    num_contract: numContract,
    contract_note: contractNote,
    cl_organization: clOrganization,
    cl_responsible: clResponsible,
  } = contractData
  const from = moment(`${yearBegin}-${monthBegin}-1`).format('MMMM YYYY')
  const to = moment(`${yearEnd}-${monthEnd}-1`).format('MMMM YYYY')
  return (
    <Fragment>
      <div className={classes.divData}>
        <Grid container>
          <Grid item xs={10} md={11}>
            <Typography className={classes.bigText}>{title}</Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            {children}
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.divData}>
            <div>
              <Typography variant="caption" className={classes.captionText}>
                {i18next.t('service_desk_web_atoir:contract_begin_date')}
              </Typography>
            </div>
            <div className={classes.divMinHeight}>
              <Typography variant="subheading">{from}</Typography>
            </div>
          </div>
          <div className={classes.divData}>
            <div>
              <Typography variant="caption" className={classes.captionText}>
                {i18next.t('shared_web_atoir:organizationClient')}
              </Typography>
            </div>
            <div className={classes.divMinHeight}>
              <Typography variant="subheading">
                {clOrganization && clOrganization.short_name}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.divData}>
            <div>
              <Typography variant="caption" className={classes.captionText}>
                {i18next.t('service_desk_web_atoir:contract_end_date')}
              </Typography>
            </div>
            <div className={classes.divMinHeight}>
              <Typography variant="subheading">{to}</Typography>
            </div>
          </div>
          <div className={classes.divData}>
            <div>
              <Typography variant="caption" className={classes.captionText}>
                {i18next.t('service_desk_web_atoir:responsiblePerson')}
              </Typography>
            </div>
            <div className={classes.divMinHeight}>
              <Typography variant="subheading">
                {clResponsible && getUserFullName(clResponsible)}
              </Typography>
            </div>
          </div>
        </Grid>
        {contractNote && (
          <Grid item xs={12}>
            <div className={classes.divData}>
              <div>
                <Typography variant="caption" className={classes.captionText}>
                  {i18next.t('shared_web_atoir:note')}
                </Typography>
              </div>
              <div>
                <LongTextExpander>{contractNote}</LongTextExpander>
              </div>
            </div>
          </Grid>
        )}
        <div className={classes.divBottom}>
          <Typography
            paragraph
            variant="subheading"
            style={{ wordBreak: 'break-word' }}
          >
            {footerText}
            {numContract && (
              <span className={classes.greyText}>{` №${numContract}`}</span>
            )}
          </Typography>
        </div>
      </Grid>
    </Fragment>
  )
}

ContractDetails.propTypes = {
  title: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  contractData: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(ContractDetails))
