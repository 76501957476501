import React, { Component } from 'react'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper/Paper'
import i18next from '../../../i18n/i18n'
// import {canView} from '../../../helpers/callPermissionsResolver'
import Subheading from '../../../widgets/Lettering/Subheading'

// Modules
import SeviceDeskOrderHistoryTable from './SeviceDeskOrderHistoryTable'
import LogTableRender from '../../../widgets/LogTableRender'

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class ServiceDeskOrderChangeLog extends Component {
  state = {
    sectionTitle: i18next.t(
      'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepair',
    ),
  }

  componentDidMount = () => {
    const {
      props: {
        fetchCallPermissions,
        match: {
          params: { idRequest },
        },
      },
    } = this
    fetchCallPermissions(idRequest)
    this.props.getTitleInfo({
      title: i18next.t('service_desk_web_atoir:callHistoryCaption'),
    })
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.isFetchingOrder !== prevProps.isFetchingOrder &&
      this.props.isFetchingOrder === false
    ) {
      this.props.getTitleInfo({
        title: i18next.t('service_desk_web_atoir:callHistoryCaption', {
          date: new Date(
            Date.parse(this.props.order.createdAt),
          ).toLocaleDateString('ru'),
        }),
      })
      this.setState({
        sectionTitle: i18next.t(
          'service_desk_web_atoir:requestCreationReasonLabelDiagnosticAndRepairAtDate',
          {
            date: new Date(
              Date.parse(this.props.order.createdAt),
            ).toLocaleDateString('ru'),
          },
        ),
      })
    }
    if (
      this.props.isFetchingPermissions !== prevProps.isFetchingPermissions &&
      this.props.isFetchingPermissions === false
    ) {
      if (this.props.permissionsError) {
        this.props.history.push('/404')
        return
      }
      this.props.fetchOrder({ id: this.props.match.params.id, params: '' })
    }
  }

  render() {
    const {
      props: { classes },
    } = this
    return (
      <Paper className={classes.root}>
        <Subheading divider title={this.state.sectionTitle} />
        <SeviceDeskOrderHistoryTable
          render={props => <LogTableRender {...props} />}
          order_id={this.props.match.params.id}
          pagination={[10, 20, 30]}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(ServiceDeskOrderChangeLog)
