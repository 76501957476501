import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../../../i18n/i18n'

// components
import CanInventory from '../../InventoryProjectAbility/CanInventory'
import RatingStars from '../../../../../Organizations/Stateless/RatingStars'
import SecondaryButton from '../../../../../../widgets/Buttons/SecondaryButton'
import SetRatingDialog from '../SetRatingDialog'
import SimpleDialog from '../../../../../Other/SimpleDialog'
import Title from '../../../../../../widgets/Lettering/Title'
import TitleLink from '../../../../../../widgets/Lettering/TitleLink'

// material-icons
import EditIcon from '@material-ui/icons/Edit'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const styles = () => ({
  container: {
    marginTop: '5px',
  },
})

class ShowRatingDialog extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      openSetRatingDialog: false,
      changeRatingFor: '',
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        isChangingPerformerRating,
        isChangingClientRating,
        match: {
          params: { projectId },
        },
        fetchInventoryProjectProfileRights,
        fetchInventoryProjectProfile,
      },
      handleDialogClose,
    } = this
    if (
      (prevProps.isChangingPerformerRating !== isChangingPerformerRating &&
        !isChangingPerformerRating) ||
      (prevProps.isChangingClientRating !== isChangingClientRating &&
        !isChangingClientRating)
    ) {
      handleDialogClose('openSetRatingDialog')
      fetchInventoryProjectProfileRights(projectId)
      fetchInventoryProjectProfile(projectId)
    }
  }

  handleDialogOpen = (field, changeRatingFor) => {
    this.setState({
      [field]: true,
      changeRatingFor,
    })
  }

  handleDialogClose = field => {
    this.setState({
      [field]: false,
      changeRatingFor: '',
    })
  }

  changeClientRating = (rating, comment) => {
    const {
      props: {
        changeClientRating,
        match: {
          params: { projectId },
        },
      },
    } = this
    const data = {
      rating_toclient: rating,
      rating_toclient_comment: comment,
    }
    changeClientRating(projectId, data)
  }

  changePerformerRating = (rating, comment) => {
    const {
      props: {
        changePerformerRating,
        match: {
          params: { projectId },
        },
      },
    } = this
    const data = {
      rating_toperformer: rating,
      rating_toperformer_comment: comment,
    }
    changePerformerRating(projectId, data)
  }

  render = () => {
    const {
      props: {
        classes,
        isOpen,
        isLoading,
        handleClose,
        positiveAction,
        client,
        performer,
        ratings,
        isChangingClientRating,
        isChangingPerformerRating,
        isClosedForTwoWeeks,
        match,
      },
      state: { openSetRatingDialog, changeRatingFor },
      handleDialogOpen,
      handleDialogClose,
      changeClientRating,
      changePerformerRating,
    } = this

    return (
      <Fragment>
        <SimpleDialog
          maxWidth="sm"
          fullWidth
          isOpen={isOpen}
          isLoading={isLoading}
          handleClose={() => handleClose()}
          handlePositive={() => positiveAction()}
          title={`${i18next.t('inventory_web_atoir:projectRatings')}`}
          positiveText={i18next.t('shared_web_atoir:close')}
          negativeHidden
          content={
            <Grid container xs={12} direction="column">
              <Grid container xs={12} direction="column">
                <Title>{i18next.t('inventory_web_atoir:clientFeedback')}</Title>
                <Divider />
                <TitleLink link={`${match.url}/organizations/${client.id}`}>
                  {client.full_name}
                </TitleLink>
                <Grid container xs={12} direction="row" justify="space-between">
                  <Grid item>
                    <Grid container direction="row">
                      <RatingStars rating={ratings.ratingToPerformer} />
                    </Grid>
                  </Grid>
                  {isClosedForTwoWeeks && (
                    <CanInventory I="change_performer_rating" a="Inventory">
                      <Tooltip
                        title={i18next.t(
                          'inventory_web_atoir:editPerformerRating',
                        )}
                      >
                        <IconButton
                          aria-label={i18next.t(
                            'inventory_web_atoir:editPerformerRating',
                          )}
                          onClick={() =>
                            handleDialogOpen('openSetRatingDialog', 'performer')
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </CanInventory>
                  )}
                </Grid>
                <Typography align="left" variant="body2">
                  {ratings.commentToPerformer}
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                direction="column"
                className={classes.container}
              >
                <Title>
                  {i18next.t('inventory_web_atoir:performerFeedback')}
                </Title>
                <Divider />
                <TitleLink link={`${match.url}/organizations/${performer.id}`}>
                  {performer.full_name}
                </TitleLink>
                {ratings.ratingToClient ? (
                  <Grid>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      justify="space-between"
                    >
                      <Grid item>
                        <Grid container direction="row">
                          <RatingStars rating={ratings.ratingToClient} />
                        </Grid>
                      </Grid>
                      {isClosedForTwoWeeks && (
                        <CanInventory I="change_client_rating" a="Inventory">
                          <Tooltip
                            title={i18next.t(
                              'inventory_web_atoir:editClientRating',
                            )}
                          >
                            <IconButton
                              aria-label={i18next.t(
                                'inventory_web_atoir:editClientRating',
                              )}
                              onClick={() =>
                                handleDialogOpen(
                                  'openSetRatingDialog',
                                  'client',
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </CanInventory>
                      )}
                    </Grid>
                    <Typography align="left" variant="body2">
                      {ratings.commentToClient}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    container
                    xs={12}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid container xs={12} alignItems="flex-start">
                      <Typography align="left" variant="body2">
                        {i18next.t('inventory_web_atoir:noFeedbackYet')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {isClosedForTwoWeeks && (
                        <CanInventory I="change_client_rating" a="Inventory">
                          <SecondaryButton
                            onClick={() =>
                              handleDialogOpen('openSetRatingDialog', 'client')
                            }
                          >
                            {i18next.t('inventory_web_atoir:leaveFeedback')}
                          </SecondaryButton>
                        </CanInventory>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
        />
        {changeRatingFor === 'client' && (
          <SetRatingDialog
            classes={classes}
            isOpen={openSetRatingDialog}
            isLoading={isChangingClientRating}
            handleClose={() => handleDialogClose('openSetRatingDialog')}
            positiveAction={changeClientRating}
            negativeAction={() => handleDialogClose('openSetRatingDialog')}
            rating={ratings.ratingToClient}
            comment={ratings.commentToClient}
          />
        )}
        {changeRatingFor === 'performer' && (
          <SetRatingDialog
            classes={classes}
            isOpen={openSetRatingDialog}
            isLoading={isChangingPerformerRating}
            handleClose={() => handleDialogClose('openSetRatingDialog')}
            positiveAction={changePerformerRating}
            negativeAction={() => handleDialogClose('openSetRatingDialog')}
            rating={ratings.ratingToPerformer}
            comment={ratings.commentToPerformer}
          />
        )}
      </Fragment>
    )
  }
}

ShowRatingDialog.defaultProps = {
  isClosedForTwoWeeks: false,
}

ShowRatingDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  positiveAction: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  performer: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  changePerformerRating: PropTypes.func.isRequired,
  isChangingPerformerRating: PropTypes.bool.isRequired,
  changeClientRating: PropTypes.func.isRequired,
  isChangingClientRating: PropTypes.bool.isRequired,
  isClosedForTwoWeeks: PropTypes.bool,
}

export default withStyles(styles)(ShowRatingDialog)
