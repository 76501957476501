import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import RLink from 'react-router-dom/Link'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../i18n/i18n'

// components
import AddOrganization from './AddOrganization/AddOrganization'
import Loader from '../Loader'
import OrganizationTypeIcon from './Stateless/OrganizationTypeIcon'
import Heading from '../../widgets/Lettering/Heading'
import OutlinedButton from '../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../widgets/Buttons/ContainedButton'

// styles
import '../../styles/components/Organizations.css'

// material-ui-icons
import AddNew from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Divider from '@material-ui/core/Divider/Divider'

const columnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Компания',
  },
  {
    id: 'request_pending',
    numeric: true,
    disablePadding: false,
    label: 'Заявки',
  },
]

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(
            column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title={i18next.t('shared_web_atoir:sort')}
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected}{' '}
            {i18next.t('organizations_web_atoir:organizationsSelectedAmount')}
          </Typography>
        ) : (
          <Heading>
            <Typography variant="h6">
              {i18next.t('organizations_web_atoir:accountOrganizations')}
            </Typography>
          </Heading>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title={i18next.t('shared_web_atoir:delete')}>
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={i18next.t('organizations_web_atoir:addOrganization')}>
            <IconButton
              data-cy="addOrganizationButton"
              aria-label={i18next.t('organizations_web_atoir:addOrganization')}
              onClick={props.showCreateOrganizationDialog}
            >
              <AddNew />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

let EmployersTableToolbar = props => {
  const { numSelected, classes } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected}{' '}
            {i18next.t('organizations_web_atoir:organizationsSelectedAmount')}
          </Typography>
        ) : (
          <Heading>
            <Typography variant="h6">
              {i18next.t('organizations_web_atoir:accountEmployers')}
            </Typography>
          </Heading>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title={i18next.t('shared_web_atoir:delete')}>
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  )
}

EmployersTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

EmployersTableToolbar = withStyles(toolbarStyles)(EmployersTableToolbar)

const styles = theme => ({
  addressDiv: {
    padding: 0,
    marginBottom: theme.typography.pxToRem(15),
  },
  marginBtn: {
    marginLeft: 0,
  },
  paddingGrid: {
    '&:nth-of-type(even)': {
      paddingRight: theme.spacing(2),
    },
  },
  btnDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  root: theme.mixins.gutters({
    width: '100%',
    marginTop: theme.spacing(3),
  }),
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  labelButton: {
    margin: '12px 0 12px 0',
    color: 'rgb(66, 155, 213)',
    border: '0',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addButton: {
    margin: '12px 0 12px 0',
    color: 'rgb(66, 155, 213)',
    border: 'solid 1.5px rgb(66, 155, 213)',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      color: 'rgb(255, 255, 255)',
      background: 'rgb(66, 155, 213)',
    },
  },
  declineInvitation: {
    minWidth: 380,
  },
  addButtonSecondary: {
    margin: '12px 0 12px 0',
    color: 'rgb(188, 38, 26)',
    border: 'solid 1.5px rgb(188, 38, 26)',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      color: 'rgb(255, 255, 255)',
      background: 'rgb(188, 38, 26)',
    },
  },
})

class OrganizationsComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selected: [],
      page: 0,
      pageEmployee: 0,
      offset: 0,
      offsetEmployee: 0,
      snackBarMessage: '',
      dialogOpen: false,
      selectedEmployersOrganizationId: '',
      selectedEmployersHashConfirm: '',

      isCreatingOrganizationDialogOpen: false,
    }
  }

  componentWillMount() {
    const { getTitleInfo } = this.props
    getTitleInfo(`${i18next.t('organizations_web_atoir:organizationsList')}`)
  }

  componentDidMount() {
    this.fetchOrganizations()
    this.fetchEmployee()
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        isLeavingOrganization,
        isConfirmingJoinOrganization,
        sdWorkingReport,
        downloadSDWorkingReport,
        rowsPerPage,
        rowsPerPageEmployers,
      },
    } = this
    if (
      prevProps.sdWorkingReport.isFetching !== sdWorkingReport.isFetching &&
      sdWorkingReport.isFetching === false &&
      sdWorkingReport.report
    ) {
      downloadSDWorkingReport({
        report: sdWorkingReport.report,
        name: `${new Date(
          sdWorkingReport.report.createdAt,
        ).toLocaleDateString()}.${sdWorkingReport.report.extension}`,
      })
    }
    if (
      (prevProps.isLeavingOrganization !== isLeavingOrganization &&
        isLeavingOrganization === false) ||
      (prevProps.isConfirmingJoinOrganization !==
        isConfirmingJoinOrganization &&
        isConfirmingJoinOrganization === false)
    ) {
      this.handleDeclineDialogClose()
      const { fetchEmployers } = this.props
      fetchEmployers()
    }
    if (prevProps.rowsPerPage !== rowsPerPage) {
      this.fetchOrganizations()
    }

    if (prevProps.rowsPerPageEmployers !== rowsPerPageEmployers) {
      this.fetchEmployee()
    }
  }

  closeCreateOrganizationDialog = message => {
    this.setState({
      snackBarMessage: message,
      isCreatingOrganizationDialogOpen: false,
    })
  }

  showCreateOrganizationDialog = () => {
    this.setState({
      isCreatingOrganizationDialogOpen: true,
    })
  }

  addOrganizationDialog = () => (
    <AddOrganization
      classes={this.props.classes}
      onClose={this.closeCreateOrganizationDialog}
      open={this.state.isCreatingOrganizationDialogOpen}
      fetchUserOrganizations={this.fetchOrganizations}
    />
  )

  getSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }))
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    this.setState({ selected: newSelected })
  }

  fetchOrganizations = () => {
    const {
      props: {
        fetchUserOrganizations,
        fetchUserOrganizationsCount,
        rowsPerPage,
      },
      state: { offset },
    } = this
    fetchUserOrganizations(undefined, {
      offset,
      limit: rowsPerPage,
      sort: '[full_name=ASC]',
    })
    fetchUserOrganizationsCount()
  }

  fetchEmployee = () => {
    const {
      props: { fetchEmployers, fetchEmployersCount, rowsPerPageEmployers },
      state: { offsetEmployee },
    } = this
    fetchEmployers({
      sort: '[full_name=ASC]',
      offset: offsetEmployee,
      limit: rowsPerPageEmployers,
      not_owner: true,
    })
    fetchEmployersCount({ not_owner: true })
  }

  handleChangePage = (event, page) => {
    this.setState(
      () => ({ page, offset: this.props.rowsPerPage * page }),
      this.fetchOrganizations,
    )
  }

  handleChangePageEmployee = (event, pageEmployee) => {
    this.setState(
      () => ({
        pageEmployee,
        offsetEmployee: this.props.rowsPerPageEmployers * pageEmployee,
      }),
      this.fetchEmployee,
    )
  }

  handleChangeRowsPerPage = event => {
    this.props.setOrganizationsListRowsPerPage(event.target.value)
  }

  handleChangeRowsPerPageEmployee = event => {
    this.props.setOrganizationsEmployersListRowsPerPage(event.target.value)
  }

  displayedRows = count => count

  handleEmployersClick = (event, organizationId, hashConfirm) => {
    this.setState({
      selectedEmployersOrganizationId: organizationId,
      selectedEmployersHashConfirm: hashConfirm,
    })
  }

  handleDeclineDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    })
  }

  handleDeclineDialogClose = () => {
    this.setState({
      dialogOpen: false,
    })
  }

  handleDeclineInvitation = () => {
    const { leaveOrganization } = this.props
    const { selectedEmployersOrganizationId } = this.state
    leaveOrganization(selectedEmployersOrganizationId)
  }

  handleAcceptInvitation = () => {
    const { confirmJoinOrganization } = this.props
    const { selectedEmployersHashConfirm } = this.state
    confirmJoinOrganization(selectedEmployersHashConfirm)
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  generateWorkingReport = organizationId => () => {
    const {
      props: { generateSDWorkingReport },
    } = this
    generateSDWorkingReport({ organizationId })
  }

  render() {
    const {
      classes,
      isFetchingOrganizations,
      organizations,
      isFetchingEmployers,
      employers,
      match,
      count,
      employersCount,
      rowsPerPage,
      rowsPerPageEmployers,
    } = this.props
    const { selected, page, pageEmployee } = this.state

    const request = (
      <div>
        <Chip
          label={i18next.t('shared_web_atoir:denyAction')}
          onClick={this.handleDeclineClick}
          className={classes.chip}
        />
        <Chip
          label={i18next.t('shared_web_atoir:acceptAction')}
          onClick={this.handleAcceptClick}
          className={classes.chip}
        />
      </div>
    )

    const emptyList = (
      <div>
        <br />
        <Typography align="center">
          {i18next.t('organizations_web_atoir:emptyEmployersList')}
        </Typography>
        <br />
      </div>
    )
    const emptyListOrganizations = (
      <div>
        <br />
        <Typography align="center">
          {i18next.t('organizations_web_atoir:emptyOrganizationsList')}
        </Typography>
        <br />
      </div>
    )

    const confirmDelete = (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleDeclineDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {i18next.t('organizations_web_atoir:declineInvitationTitle')}
        </DialogTitle>
        <DialogContent className={classes.declineInvitation}>
          <DialogContentText>
            {i18next.t('organizations_web_atoir:declineInvitationText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={this.handleDeclineDialogClose}>
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton onClick={this.handleDeclineInvitation}>
            {i18next.t('organizations_web_atoir:decline')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    )

    return (
      <Fragment>
        {isFetchingOrganizations || isFetchingEmployers ? (
          <Loader />
        ) : (
          <div>
            <Paper elevation={2}>
              {this.getSnackBar()}
              <EnhancedTableToolbar
                showCreateOrganizationDialog={this.showCreateOrganizationDialog}
                numSelected={selected.length}
              />
              <Divider />
              {isEmpty(organizations) ? (
                emptyListOrganizations
              ) : (
                <div className={classes.tableWrapper}>
                  <Table className={classes.table}>
                    <TableBody>
                      {organizations.map(n => {
                        const isSelected = this.isSelected(n.id)
                        return (
                          <TableRow
                            hover
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                          >
                            <TableCell>
                              <div className="organization-type-container-in-row">
                                <OrganizationTypeIcon
                                  fluid={false}
                                  clientOrganization={n.client_organization}
                                  tradeOrganization={n.trade_organization}
                                  serviceOrganization={n.service_organization}
                                  type="m"
                                />
                                <OrganizationTypeIcon
                                  fluid={false}
                                  clientOrganization={n.client_organization}
                                  tradeOrganization={n.trade_organization}
                                  serviceOrganization={n.service_organization}
                                  type="c"
                                />
                                {/* AT-396 */}
                                {/* <OrganizationTypeIcon */}
                                {/* fluid={false} */}
                                {/* clientOrganization={n.client_organization} */}
                                {/* tradeOrganization={n.trade_organization} */}
                                {/* serviceOrganization={n.service_organization} */}
                                {/* type="t" */}
                                {/* /> */}
                              </div>
                              <div className="organization-type-container-in-row">
                                <RLink to={`${match.url}/${n.id}/profile`}>
                                  <span className="organization-name-in-row">
                                    {n.full_name}
                                  </span>
                                </RLink>
                                <br />
                                <span className="locations-departments-in-row">
                                  {i18next.t(
                                    'organizations_web_atoir:filialAgencies',
                                  )}
                                  :{n.locations_count}
                                </span>
                                <span className="locations-departments-in-row">
                                  {i18next.t(
                                    'organizations_web_atoir:departments',
                                  )}
                                  :{n.departments_count}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell padding="checkbox" />
                            <TableCell>
                              {n.request_pending ? request : null}
                            </TableCell>
                            <TableCell align="right">
                              <OutlinedButton
                                onClick={this.generateWorkingReport(n.id)}
                              >
                                {i18next.t(
                                  'organizations_web_atoir:generateSDWorkingReport',
                                )}
                              </OutlinedButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={4}
                          count={count || 0}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage={i18next.t(
                            'shared_web_atoir:rowsPerPage',
                          )}
                          labelDisplayedRows={({
                            from: fromPage,
                            to: toPage,
                            count: countPage,
                          }) =>
                            i18next.t('shared_web_atoir:labelDisplayedRows', {
                              fromPage,
                              toPage,
                              countPage,
                            })
                          }
                          page={page}
                          backIconButtonProps={{
                            'aria-label': 'Previous Page',
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'Next Page',
                          }}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              )}
            </Paper>
            {this.state.isCreatingOrganizationDialogOpen &&
              this.addOrganizationDialog()}
            <br />
            <Paper elevation={2}>
              <EmployersTableToolbar numSelected={selected.length} />
              <Divider />
              {isEmpty(employers) ? (
                emptyList
              ) : (
                <div className={classes.tableWrapper}>
                  <Table className={classes.table}>
                    <TableBody>
                      {employers.map(n => (
                        <TableRow
                          hover
                          onClick={event =>
                            this.handleEmployersClick(
                              event,
                              n.Organization.id,
                              n.hash_confirm,
                            )
                          }
                          aria-checked
                          tabIndex={-1}
                          key={n.Organization.id}
                        >
                          <TableCell>
                            <div className="organization-type-container-in-row">
                              <OrganizationTypeIcon
                                fluid={false}
                                clientOrganization={
                                  n.Organization.client_organization
                                }
                                tradeOrganization={
                                  n.Organization.trade_organization
                                }
                                serviceOrganization={
                                  n.Organization.service_organization
                                }
                                type="m"
                              />
                              <OrganizationTypeIcon
                                fluid={false}
                                clientOrganization={
                                  n.Organization.client_organization
                                }
                                tradeOrganization={
                                  n.Organization.trade_organization
                                }
                                serviceOrganization={
                                  n.Organization.service_organization
                                }
                                type="c"
                              />
                              {/* AT-396 */}
                              {/* <OrganizationTypeIcon */}
                              {/* fluid={false} */}
                              {/* clientOrganization={n.Organization.client_organization} */}
                              {/* tradeOrganization={n.Organization.trade_organization} */}
                              {/* serviceOrganization={n.Organization.service_organization} */}
                              {/* type="t" */}
                              {/* /> */}
                            </div>
                            <div className="organization-type-container-in-row">
                              <RLink
                                to={`${match.url}/${n.Organization.id}/profile`}
                              >
                                <span className="organization-name-in-row">
                                  {n.Organization.full_name}
                                </span>
                              </RLink>
                              <br />
                              {isEmpty(n.position) ? null : (
                                <span className="position-name-in-row">
                                  {i18next.t('employees_web_atoir:position')}:{' '}
                                  {n.position}
                                </span>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            {n.state === 0 ? (
                              <div>
                                <Chip
                                  label={i18next.t(
                                    'shared_web_atoir:denyAction',
                                  )}
                                  onClick={this.handleDeclineDialogOpen}
                                  className={classes.chip}
                                />
                                <Chip
                                  label={i18next.t(
                                    'shared_web_atoir:acceptAction',
                                  )}
                                  onClick={() =>
                                    setTimeout(
                                      this.handleAcceptInvitation,
                                      1000,
                                    )
                                  }
                                  className={classes.chip}
                                />
                              </div>
                            ) : null}
                          </TableCell>
                          {(n.is_manager || n.is_admin || n.is_owner) &&
                          n.state !== 0 ? (
                            <TableCell align="right">
                              <OutlinedButton
                                onClick={this.generateWorkingReport(
                                  n.Organization.id,
                                )}
                              >
                                {i18next.t(
                                  'organizations_web_atoir:generateSDWorkingReport',
                                )}
                              </OutlinedButton>
                            </TableCell>
                          ) : (
                            ''
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={6}
                          count={employersCount}
                          rowsPerPage={rowsPerPageEmployers}
                          labelRowsPerPage={i18next.t(
                            'shared_web_atoir:rowsPerPage',
                          )}
                          labelDisplayedRows={({
                            from: fromPage,
                            to: toPage,
                            count: countPage,
                          }) =>
                            i18next.t('shared_web_atoir:labelDisplayedRows', {
                              fromPage,
                              toPage,
                              countPage,
                            })
                          }
                          page={pageEmployee}
                          backIconButtonProps={{
                            'aria-label': 'Previous Page',
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'Next Page',
                          }}
                          onChangePage={this.handleChangePageEmployee}
                          onChangeRowsPerPage={
                            this.handleChangeRowsPerPageEmployee
                          }
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              )}
              {confirmDelete}
            </Paper>
          </div>
        )}
      </Fragment>
    )
  }
}

OrganizationsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUserOrganizations: PropTypes.func.isRequired,
  isFetchingOrganizations: PropTypes.bool.isRequired,
  organizations: PropTypes.array.isRequired,
  fetchEmployers: PropTypes.func.isRequired,
  isFetchingEmployers: PropTypes.bool.isRequired,
  employers: PropTypes.array.isRequired,
  leaveOrganization: PropTypes.func.isRequired,
  isLeavingOrganization: PropTypes.bool.isRequired,
  confirmJoinOrganization: PropTypes.func.isRequired,
  isConfirmingJoinOrganization: PropTypes.bool.isRequired,
}

export default withStyles(styles)(OrganizationsComponent)
