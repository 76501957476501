const operators = {
  equal: (a, b) => a === b,
  notEqual: (a, b) => a !== b,
}

const getOrderKeysSimple = order =>
  order
    ? {
        order_status: order.status,
        order_result: order.order_result,
        order_type: order.type_order,
        order_performer: order.performer_organization_id,
        order_client: order.client_organization_id,
      }
    : {}

const getCallKeysSimple = call =>
  call
    ? {
        call_status: call.status,
      }
    : {}

const compareConditions = (
  { simple, composite },
  simpleConditions = [],
  compositeConditions = [],
  logic = false,
) => {
  const logicIncomes = {}
  _.forEach(simpleConditions, (cond, key) => {
    if (Array.isArray(cond)) {
      cond.forEach(({ number, operator, value }) => {
        logicIncomes[number] = operators[operator](value, simple[key])
      })
    } else {
      const { number, operator, value } = cond
      logicIncomes[number] = operators[operator](value, simple[key])
    }
  })
  _.forEach(compositeConditions, ({ number, before, after }, key) => {
    logicIncomes[number] =
      operators[before.operator](before.value, composite[key].before) &&
      operators[after.operator](after.value, composite[key].after)
  })
  if (!logic) return _.values(logicIncomes).every(i => i)
  let preparedLogic = logic
    .toLowerCase()
    .replace(/или/g, '||')
    .replace(/и/g, '&&')
  _.forEach(logicIncomes, (value, key) => {
    preparedLogic = preparedLogic.replace(new RegExp(`${key}`, 'g'), value)
  })
  let out = false
  try {
    // eslint-disable-next-line no-eval
    out = eval(preparedLogic)
  } catch (e) {
    out = false
  }
  return out
}

export const checkCondition = (condition, info) =>
  compareConditions(
    {
      simple: {
        ...getCallKeysSimple(info.call),
        ...getOrderKeysSimple(info.order),
      },
    },
    condition.simple,
    undefined,
    condition.logic,
  )
