import React, { Fragment } from 'react'
import { Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import BreadCrumb from './BreadCrumb'
import InnerCalls from '../../ServiceDesk/InnerCalls'
import InnerCallProfile from '../../ServiceDesk/InnerCalls/InnerCallProfile'
import InnerCallHistory from '../../ServiceDesk/InnerCalls/InnerCallHistory'
import InnerCallsAnalytics from '../../ServiceDesk/InnerCalls/InnerCallsAnalytics'
import InnerCallCreation from '../../ServiceDesk/InnerCalls/InnerCallCreation'

const ServiceDeskInnerCallsRouter = ({ match: { url, path: _path } }) => (
  <Fragment>
    <BreadCrumb to={url}>
      {i18next.t('inner_calls_web_atoir:innerCalls')}
    </BreadCrumb>
    <Switch>
      <WrapperRoute path={`${_path}/create`} component={InnerCallCreation} />
      <WrapperRoute
        path={`${_path}/:innerCallId/history`}
        component={InnerCallHistory}
      />
      <WrapperRoute
        path={`${_path}/:organizationId/analitycs`}
        component={({ match: { path: __path } }) => (
          <Fragment>
            <WrapperRoute path={`${__path}`} component={InnerCallsAnalytics} />
            <BreadCrumb to={`${__path}`} notClickable>
              {i18next.t('inner_calls_web_atoir:analytics')}
            </BreadCrumb>
          </Fragment>
        )}
      />
      <WrapperRoute
        path={`${_path}/:innerCallId/history`}
        component={InnerCallHistory}
      />
      <WrapperRoute
        showHeader
        showTitleOutOfPaper
        path={`${_path}/:innerCallId`}
        component={InnerCallProfile}
      />
      <WrapperRoute
        path={`${_path}`}
        component={InnerCalls}
        showHeader
        title={i18next.t('inner_calls_web_atoir:innerCalls')}
      />
    </Switch>
  </Fragment>
)

export default ServiceDeskInnerCallsRouter
