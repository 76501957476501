import React, { Component, Fragment } from 'react'
// import PropTypes from 'prop-types'
import { popLastSections } from '../../../../../helpers/dataHelper'
import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

// components
import BreadCrumb from '../../../../App/routing/BreadCrumb'

import CreateEditScriptForm from './CreateEditScriptForm'
import isEmpty from 'is-empty'

// helpers
import { conditionsToArray } from '../../Components/ConditionsConstructor'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
})

const scripPreProcessing = script => {
  let out = script
  out.actions = script.CustomActionTemplates
  out.conditions = {}
  out.conditions.simple = script.simple_conditions || {}
  out.timing = {}
  out.timing.in = parseInt(script.timeout, 10)
  out.timing.update_if_triggered_on_pending =
    script.restart_timer_after_reiteration
  out.conditions_after_timeout = {}
  out.conditions_after_timeout.simple =
    script.simple_conditions_after_timeout || {}
  out.actor = {}
  out.actor.type = 'custom'
  out.actor.organization_user_id = script.actor_custom_organization_user_id
  out = _.pick(out, [
    'name',
    'description',
    'trigger_action',
    'conditions',
    'timing',
    'trigger_strategy_after_timeout',
    'conditions_after_timeout',
    'logic',
    'logic_after_timeout',
    'actor',
    'actions',
  ])
  let { actions } = out
  actions = actions.map(item => {
    const itemRet = item
    itemRet.options = itemRet.action_options
    return _.pick(itemRet, [
      'id',
      'action_type',
      'custom_tag_id',
      'email_template_id',
      'options',
    ])
  })
  out.actions = actions
  return out
}

const scripPostProcessing = script => {
  const out = script
  if (out.timing.in === 0) out.trigger_strategy_after_timeout = 'no_checks'
  if (out.description === '') out.description = null
  if (conditionsToArray((out.conditions || {}).simple || []).length <= 1)
    out.logic = null
  if (
    conditionsToArray((out.conditions_after_timeout || {}).simple || [])
      .length <= 1
  )
    out.logic_after_timeout = null
  return out
}

const stubForNewScript = {
  name: '',
  description: null,
  trigger_action: '',
  conditions: {},
  timing: {
    in: 60000,
    update_if_triggered_on_pending: false,
  },
  trigger_strategy_after_timeout: 'no_checks',
  conditions_after_timeout: {},
  logic: null,
  logic_after_timeout: null,
  actor: {
    type: 'custom',
    organization_user_id: null,
  },
  actions: [
    {
      action_type: null,
      custom_tag_id: null,
      email_template_id: null,
      options: {
        sender_address: {
          type: 'custom',
          address: null,
        },
        recipient_address: {
          type: 'custom',
          address: null,
        },
      },
    },
  ],
}

const initScript = {
  name: '',
  description: null,
  trigger_action: '',
  conditions: {
    simple: {},
  },
  timing: {
    in: 60000,
    update_if_triggered_on_pending: false,
  },
  trigger_strategy_after_timeout: '',
  conditions_after_timeout: {},
  logic: '',
  logic_after_timeout: '',
  actor: {
    type: '',
    organization_user_id: null,
  },
  actions: [
    {
      action_type: '',
      custom_tag_id: '',
      email_template_id: '',
      options: {
        sender_address: {
          type: 'custom',
          address: '',
        },
        recipient_address: {
          type: 'custom',
          address: '',
        },
      },
    },
  ],
}

class CreateEditScripts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      script: initScript,
    }
  }

  componentDidMount = () => {
    const {
      props: {
        setNavigationDrawer,
        match,
        fetchOrganization,
        getScriptRequest,
      },
    } = this
    if (match.params.createEditScript === 'create') {
      this.setState({ edit: false, script: stubForNewScript })
    }
    if (match.params.createEditScript === 'edit') {
      this.setState({ edit: true })
      getScriptRequest({
        organizationId: match.params.organizationId,
        scriptId: match.params.scriptId,
      })
    }
    setNavigationDrawer([])
    fetchOrganization(match.params.organizationId)
  }

  componentDidUpdate = prevProps => {
    const {
      isCreatingScript,
      isEditing,
      createScriptError,
      history,
      match,
      isFetching,
      getScriptError,
      script,
      editScriptError,
    } = this.props
    if (
      (isCreatingScript !== prevProps.isCreatingScript &&
        !isCreatingScript &&
        isEmpty(createScriptError)) ||
      (isEditing !== prevProps.isEditing &&
        !isEditing &&
        isEmpty(editScriptError))
    ) {
      history.push(`/organizations/${match.params.organizationId}/settings`)
    }
    if (
      isFetching !== prevProps.isFetching &&
      !isFetching &&
      isEmpty(getScriptError)
    ) {
      this.setState({ script: scripPreProcessing(script) })
    }
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  onSubmit = script => {
    const {
      props: { match },
      state: { edit },
    } = this
    const data = scripPostProcessing(script)
    data.name = data.name.trim()
    const payload = { organizationId: match.params.organizationId, data }
    if (edit) {
      this.props.editScriptRequest({
        ...payload,
        scriptId: match.params.scriptId,
      })
    } else {
      this.props.createScriptRequest(payload)
    }
  }

  render() {
    const {
      props: { classes, match, organization, history },
      state: { edit, script },
      onSubmit,
    } = this
    return (
      <Fragment>
        <BreadCrumb
          length={29}
          to={`${
            edit ? popLastSections(match.url, 4) : popLastSections(match.url, 3)
          }/profile`}
        >
          {organization.short_name}
        </BreadCrumb>
        <BreadCrumb
          to={
            edit ? popLastSections(match.url, 3) : popLastSections(match.url, 2)
          }
        >
          {i18next.t('organizations_web_atoir:organizationSettings')}
        </BreadCrumb>
        {edit ? (
          <BreadCrumb to={match.url}>{script.name || ''}</BreadCrumb>
        ) : (
          <BreadCrumb to={match.url}>
            {i18next.t('organizations_web_atoir:scriptCreation')}
          </BreadCrumb>
        )}
        <Typography variant="h4" gutterBottom className={classes.marginBtm}>
          {edit
            ? i18next.t('organizations_web_atoir:scriptChanging')
            : i18next.t('organizations_web_atoir:scriptCreation')}
        </Typography>
        <CreateEditScriptForm
          script={script}
          onSubmit={onSubmit}
          organizationId={match.params.organizationId}
          history={history}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(CreateEditScripts)
