import * as types from '../constants/actionTypes/statistics'

const initialState = {
  sdWorkingReportGeneration: {
    isFetching: false,
    error: null,
    report: null,
  },
}

const statistics = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GENERATE_SD_WORKING_REPORT_REQUEST:
      return {
        ...state,
        sdWorkingReportGeneration: {
          ...state.sdWorkingReportGeneration,
          error: null,
          isFetching: true,
          report: null,
        },
      }
    case types.GENERATE_SD_WORKING_REPORT_SUCCESS:
      return {
        ...state,
        sdWorkingReportGeneration: {
          ...state.sdWorkingReportGeneration,
          isFetching: false,
          report: payload,
        },
      }
    case types.GENERATE_SD_WORKING_REPORT_FAILURE:
      return {
        ...state,
        sdWorkingReportGeneration: {
          ...state.sdWorkingReportGeneration,
          error: payload,
          isFetching: false,
        },
      }
    default:
      return state
  }
}

export default statistics
