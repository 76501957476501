import * as constants from '../constants/actionTypes/trustedOrganizations'
import config from '../config'

const initialState = {
  page: 0,
  rowsPerPage: config.defaultItemsPerPage,
  status: 'all',
  side: 'all',
  performerOrganization: null,
  clientOrganization: null,
  trustsList: {
    isFetching: false,
    trusts: [],
    error: null,
  },
  trustsListCount: {
    isFetching: false,
    count: [],
    error: null,
  },
  acceptDenyDialog: {
    open: false,
    params: {},
  },
  createTrust: {
    isFetching: false,
    error: null,
  },
}

const innerCalls = (state = initialState, action) => {
  switch (action.type) {
    // CREATE_TRUST
    case constants.CREATE_TRUST.REQUEST:
      return {
        ...state,
        createTrust: {
          ...state.createTrust,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_TRUST.SUCCESS:
      return {
        ...state,
        createTrust: {
          ...state.createTrust,
          isFetching: false,
        },
      }

    case constants.CREATE_TRUST.FAILURE:
      return {
        ...state,
        createTrust: {
          ...state.createTrust,
          isFetching: false,
          error: action.payload.error,
        },
      }
    //
    case constants.FETCH_TRUSTS_REQUEST:
      return {
        ...state,
        trustsList: {
          ...state.trustsList,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_TRUSTS_SUCCESS:
      return {
        ...state,
        trustsList: {
          ...state.trustsList,
          isFetching: false,
          trusts: action.payload,
        },
      }

    case constants.FETCH_TRUSTS_FAILURE:
      return {
        ...state,
        trustsList: {
          ...state.trustsList,
          isFetching: false,
          error: action.payload,
        },
      }
    case constants.FETCH_TRUSTS_COUNT_REQUEST:
      return {
        ...state,
        trustsListCount: {
          ...state.trustsListCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_TRUSTS_COUNT_SUCCESS:
      return {
        ...state,
        trustsListCount: {
          ...state.trustsListCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_TRUSTS_COUNT_FAILURE:
      return {
        ...state,
        trustsListCount: {
          ...state.trustsListCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case constants.CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      }

    case constants.CHANGE_STATUS:
      return {
        ...state,
        status: action.payload,
      }

    case constants.CHANGE_SIDE:
      return {
        ...state,
        side: action.payload,
      }

    case constants.CHANGE_ORGANIZATION:
      return {
        ...state,
        [`${action.payload.side}Organization`]: action.payload.id,
      }

    case constants.SHOW_ACCEPT_OR_DENY_DIALOG:
      return {
        ...state,
        acceptDenyDialog: {
          ...state.acceptDenyDialog,
          open: true,
          params: action.payload.params,
        },
      }

    case constants.CLOSE_ACCEPT_OR_DENY_DIALOG:
      return {
        ...state,
        acceptDenyDialog: {
          ...state.acceptDenyDialog,
          open: false,
        },
      }

    case constants.ACCEPT_TRUST_SUCCESS:
      return {
        ...state,
        trustsList: {
          ...state.trustsList,
          trusts:
            state.trustsList.trusts &&
            state.trustsList.trusts.map(t => {
              if (t.id === action.payload.id) {
                return {
                  ...t,
                  status: 'accepted',
                }
              }
              return t
            }),
        },
        acceptDenyDialog: {
          ...state.acceptDenyDialog,
          open: false,
        },
      }

    case constants.ACCEPT_TRUST_FAILURE:
      return {
        ...state,
        acceptDenyDialog: {
          ...state.acceptDenyDialog,
          open: false,
        },
      }

    case constants.REJECT_TRUST_SUCCESS:
      return {
        ...state,
        trustsList: {
          ...state.trustsList,
          trusts:
            state.trustsList.trusts &&
            (action.payload.hard
              ? state.trustsList.trusts.map(t => {
                  if (t.id === action.payload.id) {
                    return {
                      ...t,
                      status: 'rejected',
                    }
                  }
                  return t
                })
              : state.trustsList.trusts.filter(
                  t => t.id !== action.payload.id,
                )),
        },
        trustsListCount: action.payload.hard
          ? state.trustsListCount
          : {
              ...state.trustsListCount,
              count: state.trustsListCount.count - 1,
            },
        acceptDenyDialog: {
          ...state.acceptDenyDialog,
          open: false,
        },
      }

    case constants.REJECT_TRUST_FAILURE:
      return {
        ...state,
        acceptDenyDialog: {
          ...state.acceptDenyDialog,
          open: false,
        },
      }

    default:
      return state
  }
}

export default innerCalls
