import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import withStyles from '@material-ui/core/styles/withStyles'

const star = '★'
const RatingStars = props => {
  const rating = isEmpty(props.rating) ? 0 : Math.floor(props.rating)
  return (
    <Fragment>
      {new Array(rating).fill(0).map(() => (
        <span className={props.classes.redStar}>{star}</span>
      ))}
      {new Array(5 - rating).fill(0).map(() => (
        <span className={props.classes.greyStar}>{star}</span>
      ))}
    </Fragment>
  )
}

RatingStars.defaultProps = {
  rating: 0,
}

RatingStars.propTypes = {
  rating: PropTypes.number,
}

const styles = () => ({
  redStar: {
    color: 'rgb(255,100,61)',
  },
  greyStar: {
    color: 'rgb(102,102,102)',
  },
})

export default withStyles(styles)(RatingStars)
