import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import { omitByEmpty } from '../../../../../helpers/dataHelper'

// components
import FormDialog from '../../../../../widgets/FormDialog'
import ServiceOrganizations from '../../../../Organizations/ServiceOrganizations'

// internationalization
import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  formHelperText: {
    padding: theme.spacing(1),
  },
})

class RejectInventoryProjectPerformerDialogComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selectedOrganization: {},
      comment: '',
      errors: {},
    }
  }

  componentDidUpdate(prevState) {
    const {
      props: { isSettingPerformer, setForeignErrors, handleClose },
    } = this
    if (
      prevState.isSettingPerformer !== isSettingPerformer &&
      !isSettingPerformer &&
      isEmpty(setForeignErrors)
    ) {
      handleClose()
    }
  }

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  setForeignPerformer = () => {
    const {
      props: { setForeignPerformer, organizationId, projectId },
      state: { selectedOrganization, comment },
    } = this

    const validation = this.validateData(selectedOrganization)
    if (!isEmpty(validation)) {
      this.setState({
        errors: validation,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const data = {
      performer_organization_id: selectedOrganization.id,
      comment,
    }
    setForeignPerformer(organizationId, projectId, omitByEmpty(data))
  }

  validateData = selectedOrganization => {
    const errors = {}
    if (isEmpty(selectedOrganization)) {
      errors.selectedOrganization = i18next.t(
        'validation_web_atoir:shouldSelectOrganization',
      )
    }
    return errors
  }

  handleOnExit = () => {
    this.setState({
      selectedOrganization: {},
      comment: '',
      errors: {},
    })
    this.props.handleClose()
  }

  handleOrganizationSelect = organization => {
    this.setState({ selectedOrganization: organization })
    const validation = this.validateData(organization)
    if (isEmpty(validation)) {
      this.setState({
        errors: {},
      })
    }
  }

  render() {
    const {
      props: { classes, open, isSettingPerformer },
      state: { comment, errors },
    } = this

    const selectedOrganizationHelperText = errors.selectedOrganization
      ? errors.selectedOrganization
      : ''

    return (
      <FormDialog
        open={open}
        onClose={this.handleOnExit}
        onExit={this.handleOnExit}
        onSubmit={this.setForeignPerformer}
        isLoading={isSettingPerformer}
        title={i18next.t(
          'inventory_web_atoir:setForeignPerformerToProjectDialogTitle',
        )}
        submitButtonTitle={i18next.t('inventory_web_atoir:setForeignPerformer')}
      >
        <ServiceOrganizations
          handleOrganizationSelect={this.handleOrganizationSelect}
        />
        {!isEmpty(errors.selectedOrganization) ? (
          <FormHelperText className={classes.formHelperText} error>
            {selectedOrganizationHelperText}
          </FormHelperText>
        ) : null}
        <TextField
          fullWidth
          multiline
          id="comment"
          label={i18next.t('inventory_web_atoir:inventoryCommentForPerformer')}
          placeholder={i18next.t(
            'inventory_web_atoir:enterInventoryCommentForPerformer',
          )}
          className={classes.textField}
          margin="normal"
          onChange={this.handleChange('comment')}
          value={comment}
        />
      </FormDialog>
    )
  }
}

RejectInventoryProjectPerformerDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  setForeignPerformer: PropTypes.func.isRequired,
  isSettingPerformer: PropTypes.bool.isRequired,
  setForeignErrors: PropTypes.object.isRequired,
}

export default withStyles(styles)(
  RejectInventoryProjectPerformerDialogComponent,
)
