import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControlText from '../../../../../widgets/Controlls/FormControlText'
import i18n from '../../../../../i18n/i18n'
import FormDialog from '../../../../../widgets/FormDialog'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { editAgreementNameRequest } from '../../../../../actions/serviceDeskContractsActions'

class EditAgreementDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      name: props.name || null,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching
    ) {
      this.props.onFinish(this.props.error)
    }
  }

  onChange = field => event => {
    this.setState({
      error: null,
      [field]: event.target.value,
    })
  }

  changeName = () => {
    const {
      state: { name },
      props: { edit, match },
    } = this
    if (!name) {
      this.setState({
        error: i18n.t('service_desk_web_atoir:agreementNameMustBeProvided'),
      })
      return
    }
    edit({ ...match.params, body: { name } })
  }

  render() {
    const {
      state: { name, error },
      props: { inputProps, open, onClose },
      onChange,
      changeName,
    } = this

    return (
      <FormDialog
        open={open}
        closeIconHidden
        contentText={
          <FormControlText
            value={name}
            error={!!error}
            helperText={error}
            label={i18n.t('service_desk_web_atoir:doEditAgreementName')}
            onChange={onChange('name')}
            {...inputProps}
          />
        }
        onSubmit={changeName}
        submitButtonTitle={i18n.t('shared_web_atoir:save')}
        secondaryButtonsArray={[
          {
            secondaryText: i18n.t('shared_web_atoir:cancel'),
            handleSecondaryAction: onClose,
          },
        ]}
        title={i18n.t('service_desk_web_atoir:editAgreementName')}
        onClose={onClose}
        fullWidth
      />
    )
  }
}

EditAgreementDialog.defaultProps = {
  inputProps: {},
  open: false,
}

EditAgreementDialog.propTypes = {
  inputProps: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isFetching: state.serviceDeskContracts.agreementEditName.isFetching,
  error: state.serviceDeskContracts.agreementEditName.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      edit: editAgreementNameRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAgreementDialog),
)
