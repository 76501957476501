import React from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import EditLocationComponent from '../EditLocationComponent'
import FormDialog from '../../../../widgets/FormDialog'

// material-ui
import Snackbar from '@material-ui/core/Snackbar'
import FormHelperText from '@material-ui/core/FormHelperText'

import { homeConcatHelper } from '../../../../helpers/parserHelper'

class AddLocationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDataValid: false,
      data: {},
      isNameValid: true,
      isFullAddress: true,
      isCreatingLocation: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      locationFormHelperText: false,
    }
  }

  componentWillReceiveProps(props) {
    if (
      this.state.isCreatingLocation !== props.isCreatingLocation &&
      props.isCreatingLocation === false
    ) {
      if (props.error !== undefined) {
        switch (+props.error) {
          case 55:
            this.showSnackBar(
              i18next.t('error_web_atoir:invalidLocationSpecified'),
            )
            break
          case 60:
            this.showSnackBar(i18next.t('error_web_atoir:dataValidationError'))
            break
          case 61:
            this.showSnackBar(
              i18next.t(
                'error_web_atoir:locationAlreadyExistsAndIsUniqueForOrganization',
              ),
            )
            break
          default:
            break
        }
      } else {
        props.fetchLocations(props.organization.id)
        props.onClose(i18next.t('organizations_web_atoir:locationAddedMessage'))
      }
    }
    this.setState({
      ...props,
    })
  }

  handleClose = () => {
    this.setState({
      isDataValid: false,
      data: {},
      isCreatingLocation: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      isNameValid: true,
      isFullAddress: true,
    })
    this.props.onClose()
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  handleCreate = () => {
    const { data } = this.state
    if (this.state.isDataValid) {
      this.props.createLocation(this.props.organization.id, {
        name: data.name,
        home: homeConcatHelper(data.location.location.data),
        street_fias_id: data.location.location.data.street_fias_id,
        floor: data.floor,
        office: data.office,
        location_type: 2,
        location_value: data.location.location.value,
      })
    } else {
      if (!data.name || !data.isNameValid) {
        this.setState({ isNameValid: false })
      }
      if (!data.location || !data.isFullAddress) {
        this.setState({ isFullAddress: false })
      }
      this.setState({
        locationFormHelperText: true,
      })
    }
  }

  onDataChanged = data => {
    this.setState({
      data,
      isDataValid: data.isValid,
      isNameValid: data.isNameValid,
      isFullAddress: data.isFullAddress,
    })
    if (data.isValid) {
      this.setState({
        locationFormHelperText: false,
      })
    }
  }

  getSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  renderAddLocationDialog = () => {
    const { classes, open, isCreatingLocation } = this.props
    const { isNameValid, isFullAddress, locationFormHelperText } = this.state
    return (
      <FormDialog
        open={open}
        fullScreen
        isLoading={isCreatingLocation}
        title={i18next.t('organizations_web_atoir:addressAdditionTitle')}
        onClose={this.handleClose}
        onExit={this.handleClose}
        onSubmit={this.handleCreate}
        submitButtonTitle={i18next.t('shared_web_atoir:add')}
      >
        <EditLocationComponent
          onChange={this.onDataChanged}
          classes={classes}
          isNameValid={isNameValid}
          isFullAddress={isFullAddress}
        />
        {locationFormHelperText && (!isNameValid || !isFullAddress) ? (
          <FormHelperText error>
            {i18next.t('validation_web_atoir:notAllFieldsFilledCorrectly')}
          </FormHelperText>
        ) : null}
        {this.getSnackBar()}
      </FormDialog>
    )
  }

  render() {
    return <div>{this.renderAddLocationDialog()}</div>
  }
}

AddLocationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AddLocationComponent
