import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import TextButton from '../../../../widgets/Buttons/TextButton'
import i18next from '../../../../i18n/i18n'

const style = theme => ({
  exportToExcel: {
    paddingTop: theme.spacing(1),
  },
})

function ReportDownload({ classes, disabled, onClick }) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justify="flex-end"
        className={classes.exportToExcel}
      >
        <TextButton onClick={onClick} disabled={disabled}>
          {i18next.t('service_desk_web_atoir:expoprtListToExcel')}
        </TextButton>
      </Grid>
    </Grid>
  )
}
ReportDownload.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(style)(ReportDownload)
