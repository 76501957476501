export default [
  {
    value: 'plain',
    label: 'organizations_web_atoir:plainTagScopeValue',
    cols: 1.1,
  },
  {
    value: 'manager',
    label: 'organizations_web_atoir:managerTagScopeValue',
    cols: 1.1,
  },
  {
    value: 'admin',
    label: 'organizations_web_atoir:adminTagScopeValue',
    cols: 1.5,
  },
  {
    value: 'owner',
    label: 'organizations_web_atoir:ownerTagScopeValue',
    cols: 1.9,
  },
]
