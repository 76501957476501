import React from 'react'

// internationalization
import i18next from '../../i18n/i18n'

// styles
import '../../styles/components/Register.css'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
})

const TariffComponent = () => (
  <div className="Register-email-sent">
    <br />
    <Typography variant="h5" component="h5">
      {i18next.t('system_menu_web_atoir:tariffTitle')}
    </Typography>
    <Typography>{i18next.t('system_menu_web_atoir:tariffText')}</Typography>
  </div>
)

export default withStyles(styles)(TariffComponent)
