import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import Link from 'react-router-dom/Link'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// localization
import i18next from '../../../../i18n/i18n'

// helpers
import { dateFormat } from '../../../../helpers/dateHelper'

// modules
import AssignToThirdPartyOrganization from '../../AssignToThirdPartyOrgnization'
import AssignToOwnSpecialist from '../../AssignToOwnSpecialist'
import RequestCard from '../RequestCard'
import DelayedLoader from '../../../../widgets/DelayedLoader'
import Tooltip from '@material-ui/core/Tooltip'
import { Box } from '@material-ui/core'
import ListSettingsDialog from '../ListSettingsDialog'
import ListSettingsDialogContext from '../ListSettingsDialog/ListSettingsDialogContext'

const CustomDivider = () => (
  <div
    style={{
      width: '100%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      paddingBottom: '5px',
      marginTop: '-5px',
    }}
  />
)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.main.default,
    },
  },
  button: {
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    color: theme.palette.main.contrastText,
    backgroundColor: theme.palette.main.main,
    '&:hover': {
      backgroundColor: theme.palette.main.light,
    },
  },
  titleEmptyList: {
    paddingBottom: theme.spacing(3),
  },
  head: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    margin: '38px 0 24px 0',
    minHeight: 44,
  },
  toCallLink: {
    minWidth: '70px',
  },
  activeSortLabel: {
    color: 'rgba(0, 0, 0, 1)',
    fontWeight: 'bold',
    fontSize: 16,
  },
  inactiveSortLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold',
    fontSize: 16,
  },
  toSortLabel: {
    display: 'inline',
    marginRight: 14,
    fontWeight: 'bold',
    fontSize: 16,
  },
  gridCell: {
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  fab: {
    position: 'absolute',
    right: -28,
    top: 16,
  },
  tooltipNormalizer: {
    paddingBottom: 16,
  },
  fabIcon: {
    color: 'white',
  },
})

class TableRequests extends Component {
  state = {
    call: null,
    assignToThirdPartyDialogOpen: false,
    assignToOwnSpecialistDialogOpen: false,
  }

  componentDidMount() {
    const {
      props: { fetchOrgFuncs },
    } = this
    fetchOrgFuncs()
  }

  onToggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  render() {
    const {
      props: {
        match: {
          params: { listOf },
        },
        classes,
        dataServicesCalls,
        callsCount,
        dataServiceCallOrders,
        ordersCount,
        page,
        rowsPerPage,
        triggerFetch,
        handleChangePage,
        handleChangeRowsPerPage,
        isFetching,
        match,
        history,
        isOrganizationFunctionalsAvailable,
        status,
      },
      state: {
        call,
        assignToOwnSpecialistDialogOpen,
        assignToThirdPartyDialogOpen,
      },
    } = this
    const data = listOf === 'calls' ? dataServicesCalls : dataServiceCallOrders
    const count = listOf === 'calls' ? callsCount : ordersCount
    return (
      <div className={classes.root}>
        {isEmpty(data) ? (
          <Typography
            variant="caption"
            align="center"
            className={classes.titleEmptyList}
          >
            {i18next.t('shared_web_atoir:emptyList')}
          </Typography>
        ) : (
          <Fragment>
            <div className={classes.table}>
              <DelayedLoader
                delay={500}
                show={isFetching}
                loaderStyles={{ display: 'block', margin: '8px auto' }}
              />
              <div>
                <List className={classes.listRoot}>
                  {(data || []).map((_item, index) => (
                    <Fragment>
                      <ListItem
                        button
                        component={Link}
                        to={`${match.url}/${
                          listOf === 'calls' ? _item.id : _item.ServiceCall.id
                        }`}
                      >
                        <RequestCard
                          cardOf={listOf}
                          status={_item.status_enhanced || _item.status}
                          filterStatus={status}
                          callStatus={
                            !isEmpty(_item.ServiceCall)
                              ? _item.ServiceCall.status
                              : null
                          }
                          internalNumber={
                            listOf === 'calls'
                              ? _item.internal_num
                              : _item.ServiceCall.internal_num
                          }
                          externalNumber={
                            listOf === 'calls'
                              ? _item.ext_num
                              : _item.ServiceCall.ext_num
                          }
                          typeOrder={_item.type_order}
                          resultOrder={
                            _item.order_result ||
                            _.get(
                              (_item.ServiceCallOrders || [])[0],
                              'order_result',
                            )
                          }
                          engineer_user={
                            _.get(
                              (_item.ServiceCallOrders || [])[0],
                              'engineer_user',
                            ) || _.get(_item, 'engineer_user')
                          }
                          visit_date={
                            _.get(
                              (_item.ServiceCallOrders || [])[0],
                              'visit_date',
                            ) || _.get(_item, 'visit_date')
                          }
                          callDate={
                            listOf === 'calls'
                              ? _item.createdAt &&
                                dateFormat(Date.parse(_item.createdAt))
                              : _item.ServiceCall.createdAt &&
                                dateFormat(
                                  Date.parse(_item.ServiceCall.createdAt),
                                )
                          }
                          client_organization={
                            _.get(_item, 'ServiceCall.Organization') ||
                            _.get(_item, 'client_organization')
                          }
                          performer_organization={
                            _.get(
                              (_item.ServiceCallOrders || [])[0],
                              'performer_organization',
                            ) || _.get(_item, 'performer_organization')
                          }
                          organization_equipment={
                            _.get(_item, 'OrganizationEquipment') ||
                            _.get(_item, 'ServiceCall.OrganizationEquipment')
                          }
                          home={
                            _.get(_item, 'location_repair_home') ||
                            _.get(_item, 'ServiceCall.location_repair_home')
                          }
                          floor={
                            _.get(_item, 'location_repair_floor') ||
                            _.get(_item, 'ServiceCall.location_repair_floor')
                          }
                          office={
                            _.get(_item, 'location_repair_office') ||
                            _.get(_item, 'ServiceCall.location_repair_office')
                          }
                          street={
                            _.get(_item, 'Street') ||
                            _.get(_item, 'ServiceCall.Street')
                          }
                          reaction_date_begin={
                            _.get(_item, 'reaction_date_begin') ||
                            _.get(_item, 'ServiceCall.reaction_date_begin')
                          }
                          reaction_date_end={
                            _.get(_item, 'reaction_date_end') ||
                            _.get(_item, 'ServiceCall.reaction_date_end')
                          }
                          orderDate={
                            _item.createdAt &&
                            dateFormat(Date.parse(_item.createdAt))
                          }
                          tags={_item.tags}
                        />
                      </ListItem>
                      {data && data.length !== index + 1 && <CustomDivider />}
                    </Fragment>
                  ))}
                </List>
              </div>
            </div>
            {assignToThirdPartyDialogOpen && (
              <AssignToThirdPartyOrganization
                callId={call.service_call_id}
                onFinish={triggerFetch}
                callClientOrganization={
                  call.service_call_client_organization_id
                }
                open={assignToThirdPartyDialogOpen}
                onClose={this.onToggle('assignToThirdPartyDialogOpen', false)}
              />
            )}
            {assignToOwnSpecialistDialogOpen && (
              <AssignToOwnSpecialist
                organizationId={call.service_call_client_organization_id}
                onFinish={triggerFetch}
                callId={call.service_call_id}
                open={assignToOwnSpecialistDialogOpen}
                onClose={this.onToggle(
                  'assignToOwnSpecialistDialogOpen',
                  false,
                )}
              />
            )}
            {
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ListSettingsDialogContext>
                  <ListSettingsDialog listOf={listOf} />
                </ListSettingsDialogContext>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 30, 50]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
                  labelDisplayedRows={({
                    from: fromPage,
                    to: toPage,
                    count: countPage,
                  }) =>
                    i18next.t('shared_web_atoir:labelDisplayedRows', {
                      fromPage,
                      toPage,
                      countPage,
                    })
                  }
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            }
          </Fragment>
        )}
        <div className={classes.fab}>
          <Tooltip
            title={i18next.t('shared_web_atoir:sdUnpaidTooltip')}
            placement="bottom"
            disableHoverListener={isOrganizationFunctionalsAvailable}
            disableFocusListener
            disableTouchListener
          >
            <span className={classes.tooltipNormalizer}>
              <Fab
                disabled={!isOrganizationFunctionalsAvailable}
                color="primary"
                onClick={() => {
                  history.push('/service_desk/create')
                }}
              >
                <AddIcon className={classes.fabIcon} />
              </Fab>
            </span>
          </Tooltip>
        </div>
      </div>
    )
  }
}

TableRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  dataServicesCalls: PropTypes.array.isRequired,
  callsCount: PropTypes.number.isRequired,
  dataServiceCallOrders: PropTypes.array.isRequired,
  ordersCount: PropTypes.number.isRequired,
  triggerFetch: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  fetchOrgFuncs: PropTypes.func.isRequired,
}

export default withStyles(styles)(TableRequests)
