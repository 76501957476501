import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from '../../../components/Loader'
import { fetchProxy } from '../../../middlewares/api'

class ImageLoader extends Component {
  state = {
    url: null,
  }

  componentDidMount = () => {
    const { url } = this.props
    fetchProxy(url, {
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('atoirAccessToken'),
      },
    })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error('Network response was not ok.')
      })
      .then(myBlob => {
        this.setState({
          url: URL.createObjectURL(myBlob),
        })
      })
      .catch(() => {
        this.setState({
          url: URL.createObjectURL(new Blob()),
        })
      })
  }

  openInANewPage = () => {
    const { url } = this.state
    const newWindow = window.open('/')
    newWindow.onload = () => {
      newWindow.location = url
    }
  }

  render = () => {
    const {
      state: { url },
    } = this
    const { component: WrappedComponent, field, ...others } = this.props
    const urlProp = {
      [field]: url,
    }
    return url ? <WrappedComponent {...urlProp} {...others} /> : <Loader />
  }
}

ImageLoader.defaultProps = {
  field: 'src',
  component: 'img',
}

ImageLoader.propTypes = {
  url: PropTypes.string.isRequired,
  field: PropTypes.string,
  component: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
}

export default ImageLoader
