import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  subheading: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    wordBreak: 'break-all',
  },
  wrapSubheading: {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    paddingLeft: '16px',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    alignItems: 'center',
  },
  sectionSubheading: {
    width: '100%',
  },
})

function Subheading({ classes, title, caption, children, divider, ...other }) {
  return (
    <section className={classes.sectionSubheading}>
      <div className={classes.wrapSubheading}>
        <div>
          <Typography
            variant="h6"
            align="left"
            className={classes.subheading}
            {...other}
          >
            {title}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {caption}
          </Typography>
        </div>
        <div>{children}</div>
      </div>
      {divider && <Divider />}
    </section>
  )
}

Subheading.defaultProps = {
  children: '',
  title: '',
  caption: '',
}

Subheading.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  caption: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Subheading)
