import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OrganizationFilters from './OrganizationFilters'
import { fetchCitiesWithServiceOrganizations } from '../../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isFetchingCities:
    state.organizations.citiesWithServiceOrganizations.isFetchingCities,
  cities: state.organizations.citiesWithServiceOrganizations.cities,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCitiesWithServiceOrganizations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationFilters),
)
