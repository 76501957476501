import {
  REGISTER_EMAIL_REQUEST,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_EMAIL_FAILURE,
  GET_CONFIRM_LINK_AGAIN_REQUEST,
  GET_CONFIRM_LINK_AGAIN_SUCCESS,
  GET_CONFIRM_LINK_AGAIN_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  REGISTER_ACCOUNT_REQUEST,
  REGISTER_ACCOUNT_SUCCESS,
  REGISTER_ACCOUNT_FAILURE,
  CLEAR_REGISTER_VALIDATION_ERRORS,
} from '../constants/actionTypes'

const initialState = {
  isRegisteringEmail: false,
  isConfirmingEmailRegistration: false,
  isActivatingAccount: false,
  shouldResendLink: false,
  canContinueRegistration: false,
  validationErrors: {},
  confirmEmailErrors: {},
}

const registerAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_EMAIL_REQUEST:
      return {
        ...state,
        isRegisteringEmail: true,
      }

    case REGISTER_EMAIL_SUCCESS:
      return {
        ...state,
        isRegisteringEmail: false,
        shouldResendLink: false,
        validationErrors: {},
      }

    case CLEAR_REGISTER_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: {},
        shouldResendLink: false,
      }

    case REGISTER_EMAIL_FAILURE:
      return {
        ...state,
        isRegisteringEmail: false,
        shouldResendLink: action.shouldResendLink,
        validationErrors: action.errors,
      }

    case GET_CONFIRM_LINK_AGAIN_REQUEST:
      return {
        ...state,
        isRegisteringEmail: true,
        validationErrors: {},
      }

    case GET_CONFIRM_LINK_AGAIN_SUCCESS:
      return {
        ...state,
        isRegisteringEmail: false,
        validationErrors: {},
      }

    case GET_CONFIRM_LINK_AGAIN_FAILURE:
      return {
        ...state,
        isRegisteringEmail: false,
        validationErrors: action.errors,
      }

    case CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        isConfirmingEmailRegistration: true,
        confirmEmailErrors: {},
      }

    case CONFIRM_EMAIL_SUCCESS: {
      return {
        ...state,
        isConfirmingEmailRegistration: false,
        canContinueRegistration: true,
      }
    }

    case CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        isConfirmingEmailRegistration: false,
        canContinueRegistration: false,
        confirmEmailErrors: action.errors,
        resendLinkEmail: action.email,
        confirmEmailErrorsCode: action.code,
      }

    case REGISTER_ACCOUNT_REQUEST:
      return {
        ...state,
        isActivatingAccount: true,
      }

    case REGISTER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isActivatingAccount: false,
        validationErrors: {},
      }

    case REGISTER_ACCOUNT_FAILURE:
      return {
        ...state,
        isActivatingAccount: false,
        validationErrors: {
          firstName: action.errors.first_name,
          lastName: action.errors.last_name,
          middleName: action.errors.middle_name,
          password: action.errors.password,
          passwordConfirm: action.errors.password_confirm,
        },
      }

    default:
      return state
  }
}

export default registerAccountReducer
