import React from 'react'

const SwitchContext = React.createContext({})

function Switch({ children, value }) {
  return (
    <SwitchContext.Provider value={{ value }}>
      {children}
    </SwitchContext.Provider>
  )
}

function useSwitchContext() {
  const context = React.useContext(SwitchContext)
  if (!context) {
    throw new Error(
      `Switch compound components cannot be rendered outside the Switch component`,
    )
  }
  return context
}

function Option({ children, option }) {
  const { value } = useSwitchContext()
  return option === value ? children : null
}

Switch.Option = Option

export default Switch
