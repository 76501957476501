import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import Link from 'react-router-dom/Link'

import Typography from '@material-ui/core/Typography'
import { display, flexbox, spacing, typography } from '@material-ui/system'

import HighlightedTemplate from '../../../../widgets/HighlightedTemplate'
import EmptyResult from './EmptyResult'
import i18next from '../../../../i18n/i18n'

const Box = styled.div`${display}${flexbox}${spacing}`
const Inline = styled.span`${spacing}${typography}`

const CallTitle = ({ searchValue, extNum, intNum }) => (
  <React.Fragment>
    <HighlightedTemplate searchTemplate={searchValue}>
      {/* eslint-disable-next-line max-len */}
      {`${i18next.t('service_desk_web_atoir:call')} №${intNum} ${
        extNum ? `(${i18next.t('shared_web_atoir:external')} ${extNum})` : ''
      }`}
    </HighlightedTemplate>
  </React.Fragment>
)

const CheckingString = ({ children: value, name, searchTemplate, last }) => (
  <Fragment>
    {name && <Inline mr={1}>{`${name}:`}</Inline>}
    <HighlightedTemplate searchTemplate={searchTemplate}>
      {value}
    </HighlightedTemplate>
    {!last && <Inline mr={1}>,</Inline>}
  </Fragment>
)

const CallInfo = ({ searchValue, name, model, externalId, serialNumber }) => (
  <React.Fragment>
    <CheckingString searchTemplate={searchValue} name={null}>
      {name || ''}
    </CheckingString>
    <CheckingString
      searchTemplate={searchValue}
      name={i18next.t('service_desk_web_atoir:captionModel')}
    >
      {model || ''}
    </CheckingString>
    <CheckingString
      searchTemplate={searchValue}
      name={i18next.t('service_desk_web_atoir:captionEquipmentSerialNumber')}
    >
      {serialNumber || ''}
    </CheckingString>
    <span>(</span>
    <CheckingString
      last
      name={i18next.t('service_desk_web_atoir:captionExtId')}
    >
      {externalId || ''}
    </CheckingString>
    <span>)</span>
  </React.Fragment>
)

function ResultRenderSD({ data, closeSearch, searchValue }) {
  return (
    <React.Fragment>
      {data.length > 0 ? (
        data.map(call => {
          const {
            id,
            internal_num: intNum,
            ext_num: extNum,
            status,
            organizationEquipment: {
              name,
              serial_number: serialNumber,
              external_id: externalId,
              model,
            },
          } = call
          return (
            <Box key={id} mb={2} mt={2}>
              <Typography variant="subtitle2" color="secondary">
                {`${i18next.t('shared_web_atoir:sdSection')} > ${i18next.t(
                  'shared_web_atoir:sdCalls',
                )}`}
              </Typography>
              <Typography
                onClick={closeSearch}
                variant="subtitle2"
                inline
                component={Link}
                to={`/service_desk/calls/${status}/${id}`}
              >
                <Inline fontWeight={500}>
                  <CallTitle {...{ searchValue, extNum, intNum }} />
                </Inline>
              </Typography>
              <Typography noWrap color="secondary">
                <CallInfo
                  variant="subtitle2"
                  {...{
                    searchValue,
                    name,
                    model,
                    externalId,
                    serialNumber,
                  }}
                />
              </Typography>
            </Box>
          )
        })
      ) : (
        <EmptyResult />
      )}
    </React.Fragment>
  )
}

ResultRenderSD.propTypes = {
  data: PropTypes.array.isRequired,
  closeSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
}

export default ResultRenderSD
