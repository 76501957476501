import _ from 'lodash'
import * as constants from '../constants/actionTypes/serviceDesk'

const initialState = {
  creation: {
    isCreating: false,
    data: {
      extNumber: '',
    },
  },
  createRequest: {
    isFetching: false,
    id: null,
    error: null,
  },
  request: {
    isFetching: false,
    request: {},
  },
  updatingRequest: {
    isFetching: false,
    error: null,
  },
  uploadingFile: {
    isFetching: false,
    error: null,
  },
  deletingFile: {
    isFetching: false,
    error: null,
  },
  deletingPhoto: {
    isFetching: false,
    error: null,
  },
  uploadingPhoto: {
    isFetching: false,
    error: null,
  },
  docs: {
    files: [],
    isFetching: false,
    error: null,
  },
  photos: {
    photos: [],
    isFetching: false,
    error: null,
  },
  serviceCalls: {
    data: [],
    isFetching: false,
    error: null,
  },
  serviceCallsCount: {
    count: 0,
    isFetching: false,
    error: null,
  },
  serviceCallOrders: {
    data: [],
    isFetching: false,
    error: null,
  },
  serviceCallOrdersCount: {
    count: 0,
    isFetching: false,
    error: null,
  },
  assigningToThirdParty: {
    isFetching: false,
    error: null,
  },
  sendingToSubOrder: {
    isFetching: false,
    error: null,
  },
  order: {
    id: null,
    isFetching: false,
    error: null,
    order: {},
  },
  assigningOwnSpecialist: {
    isFetching: false,
    error: null,
  },
  permissions: {
    permissions: null,
    id: null,
    orders: null,
    status: null,
    isFetching: false,
    error: null,
  },
  orders: {
    orders: [],
    isFetching: false,
    error: null,
  },
  assigningOrderOwnSpecialist: {
    isFetching: false,
    error: null,
  },
  sendOrderToSubOrder: {
    isFetching: false,
    error: null,
  },
  cancelRequest: {
    isFetching: false,
    id: null,
    cancelSuccess: false,
    error: null,
  },
  rejectOrder: {
    isFetching: false,
    fetchSuccess: false,
    id: null,
    error: null,
  },
  changePerformerResponsible: {
    isFetching: false,
    error: null,
  },
  changeClientResponsible: {
    isFetching: false,
    error: null,
  },
  changeEngineer: {
    isFetching: false,
    error: null,
  },
  editOrderInfo: {
    isFetching: false,
    error: null,
  },
  orders_log: {
    orders_log: [],
    isFetching: false,
    error: null,
  },
  orders_log_count: {
    orders_log_count: 0,
    isFetching: false,
    error: null,
  },
  orderComments: {
    comments: [],
    isFetching: false,
    error: null,
  },
  uploadingComment: {
    isFetching: false,
    error: null,
  },
  uploadingSubcomment: {
    isFetching: false,
    error: null,
  },
  orderCommentsCount: {
    count: 0,
    isFetching: false,
    error: null,
  },
  cancelOrder: {
    isFetching: false,
    error: null,
  },
  createServiceContracts: {
    isFetching: false,
    contract_id: null,
    success: false,
    error: null,
  },

  setOrderResultType: {
    isSetting: false,
    setSuccessfully: false,
    error: null,
  },
  orderItems: {
    isFetching: false,
    items: null,
    error: null,
  },
  orderItemsCount: {
    isFetching: false,
    count: 0,
    error: null,
  },
  suborderItems: {
    isFetching: false,
    items: null,
    error: null,
  },
  suborderItemsCount: {
    isFetching: false,
    count: 0,
    error: null,
  },
  saveOrderItemsList: {
    isSaving: false,
    savedSuccessfully: false,
    error: null,
  },
  clearOrderItemsList: {
    isClearing: false,
    clearedSuccessfully: false,
    error: null,
  },
  copyItemsFromChild: {
    isCopying: false,
    copySuccess: false,
    error: null,
  },
  fetchOrderNds: {
    isFetching: false,
    fetchSuccess: false,
    nds: 0,
    error: null,
  },
  fetchOrderInputNds: {
    isFetching: false,
    fetchSuccess: false,
    nds: 0,
    error: null,
  },
  fetchOrderOverhead: {
    isFetching: false,
    fetchSuccess: false,
    overhead: 0,
    error: null,
  },
  updateOrderNds: {
    isUpdating: false,
    updateSuccess: false,
    error: null,
  },
  updateOrderIncomeNds: {
    isUpdating: false,
    updateSuccess: false,
    error: null,
  },
  updateOrderOverhead: {
    isUpdating: false,
    updateSuccess: false,
    error: null,
  },
  workCompletionFile: {
    isFetching: false,
    error: null,
  },
  workCompletionFileDelete: {
    isFetching: false,
    error: null,
  },
  sendOrderToControl: {
    isSending: false,
    sendSuccessfully: false,
    error: null,
  },
  sendOrderToDone: {
    isSending: false,
    sendSuccessfully: false,
    error: null,
  },
  assignUsingContract: {
    isFetching: false,
    error: null,
  },
  contract_available_equipment: {
    equipment: [],
    isFetching: false,
    error: null,
  },
  contract: {
    contract: [],
    isFetching: false,
    error: null,
  },
  contract_available_equipment_count: {
    count: 0,
    isFetching: false,
    error: null,
  },
  addEquipmentToContracts: {
    isFetching: false,
    error: null,
  },
  listRequest: {
    needParticipation: null,
    typeOrder: null,
    resultOrder: null,
    rowsPerPage: 10,
  },
  confirmCertificateOfCompletionWork: {
    isFetching: false,
    confirmSuccessfully: false,
    error: null,
  },
  activeServiceCallOrders: {
    isFetching: false,
    orders: null,
    error: null,
  },
  closedServiceCallOrders: {
    isFetching: false,
    orders: null,
    error: null,
  },
  canceledServiceCallOrders: {
    isFetching: false,
    orders: null,
    error: null,
  },
  acceptOrderCertificateOfCompletion: {
    isAccepting: false,
    acceptedSuccessfully: false,
    error: null,
  },
  rejectOrderCertificateOfCompletion: {
    isRejecting: false,
    rejectedSuccessfully: false,
    error: null,
  },
  closeCallFromNeedrepair: {
    isClosing: false,
    closeSuccess: false,
    error: null,
  },
  sendCallToRepairDiagnosticians: {
    isSending: false,
    sendSuccess: false,
    error: null,
  },
  offerDocs: {
    files: [],
    isFetching: false,
    error: null,
  },
  sendCallUsingContract: {
    isFetching: false,
    sendSuccess: false,
    error: null,
  },
  history: {
    isFetching: false,
    history: [],
    error: null,
    end: false,
    count: 0,
    isFetchingCount: true,
    errorFetchingCount: null,
  },
  orderPossibleReports: {
    isFetching: false,
    templates: [],
    error: null,
  },
  callPossibleReports: {
    isFetching: false,
    count: 0,
    names: [],
    error: null,
  },
  callsByNumberSearch: {
    isFetching: false,
    calls: [],
    error: null,
  },
  ndsChecked: false,
  inputWithNDSChecked: false,
  overheadChecked: false,
  sdActionsHistory: {
    isFetching: false,
    isFetchingCount: false,
    history: [],
    count: 0,
    error: null,
  },
  disableScriptsForIds: {},
  orderResultReportingType: null,
  resultReportingTypeOrderId: null,
  shouldSetOrderResultReport: false,
  getServiceCallDocumentLink: {
    isFetching: false,
    path: null,
    error: null,
  },
  getServiceCallBlankDocumentLink: {
    isFetching: false,
    path: null,
    error: null,
  },
  getServiceCallOrderCertificateLink: {
    isFetching: false,
    path: null,
    error: null,
  },
  getServiceCallOrderDocumentLink: {
    isFetching: false,
    path: null,
    error: null,
  },
  serviceCallTagsAttaching: {
    isFetching: false,
    error: null,
  },
  serviceCallTagsDetaching: {
    isFetching: false,
    error: null,
  },
  makeRecurringFromCall: {
    isFetching: false,
    error: null,
  },
  editRecurringFromCall: {
    isFetching: false,
    error: null,
  },
  deleteRecurringFromCall: {
    isFetching: false,
    error: null,
  },
}

const serviceDesk = (state = initialState, action) => {
  switch (action.type) {
    case constants.MAKE_RECURRING_FROM_CALL_REQUEST:
      return {
        ...state,
        makeRecurringFromCall: {
          isFetching: true,
          error: null,
        },
      }
    case constants.MAKE_RECURRING_FROM_CALL_SUCCESS:
      return {
        ...state,
        makeRecurringFromCall: {
          ...state.makeRecurringFromCall,
          isFetching: false,
        },
      }
    case constants.MAKE_RECURRING_FROM_CALL_FAILURE:
      return {
        ...state,
        makeRecurringFromCall: {
          ...state.makeRecurringFromCall,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_RECURRING_CALL_REQUEST:
      return {
        ...state,
        editRecurringFromCall: {
          isFetching: true,
          error: null,
        },
      }
    case constants.EDIT_RECURRING_CALL_SUCCESS:
      return {
        ...state,
        editRecurringFromCall: {
          ...state.editRecurringFromCall,
          isFetching: false,
        },
      }
    case constants.EDIT_RECURRING_CALL_FAILURE:
      return {
        ...state,
        editRecurringFromCall: {
          ...state.editRecurringFromCall,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_RECURRING_CALL_REQUEST:
      return {
        ...state,
        deleteRecurringFromCall: {
          isFetching: true,
          error: null,
        },
      }
    case constants.DELETE_RECURRING_CALL_SUCCESS:
      return {
        ...state,
        deleteRecurringFromCall: {
          ...state.deleteRecurringFromCall,
          isFetching: false,
        },
      }
    case constants.DELETE_RECURRING_CALL_FAILURE:
      return {
        ...state,
        deleteRecurringFromCall: {
          ...state.deleteRecurringFromCall,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.SET_SC_SIDE_NEED_PARTICIPATION_FILTER:
      return {
        ...state,
        listRequest: {
          ...state.listRequest,
          needParticipation: action.payload,
        },
      }

    case constants.SET_SCO_SIDE_TYPE_ORDER:
      return {
        ...state,
        listRequest: {
          ...state.listRequest,
          typeOrder: action.payload,
        },
      }

    case constants.SET_SCO_SIDE_RESULT_ORDER:
      return {
        ...state,
        listRequest: {
          ...state.listRequest,
          resultOrder: action.payload,
        },
      }

    case constants.SET_SC_LIST_REQUEST_ROWS_PER_PAGE:
      return {
        ...state,
        listRequest: {
          ...state.listRequest,
          rowsPerPage: action.payload,
        },
      }

    case constants.SET_INITIAL_LIST_FILTERS_FOR_STATUS:
      return {
        ...state,
        listRequest: {
          ...state.listRequest,
          needParticipation: action.payload.needParticipation,
          typeOrder: action.payload.typeOrder,
          resultOrder: action.payload.resultOrder,
        },
      }

    case constants.SET_DATA:
      return {
        ...state,
        creation: {
          ...state.creation,
          data: {
            ...state.creation.data,
            ...action.payload,
          },
        },
      }

    case constants.CLEAR_DATA:
      return {
        ...state,
        creation: {
          ...state.creation,
          data: {
            extNumber: '',
          },
        },
      }

    case constants.CREATE_REQUEST_REQUEST:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          id: action.payload,
          isFetching: false,
          error: null,
        },
      }

    case constants.CREATE_REQUEST_FAILURE:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CALL_PERMISSIONS_REQUEST:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          permissions: null,
          id: null,
          orders: null,
          status: null,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_CALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload.call,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_CALL_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          permissions: null,
          id: null,
          orders: null,
          status: null,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CLEAR_CALL_HISTORY:
      return {
        ...state,
        history: {
          history: null,
        },
      }

    case constants.FETCH_CALL_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          history: [],
          isFetching: true,
        },
      }

    case constants.FETCH_CALL_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          history: action.payload.history,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_CALL_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          error: action.payload,
        },
      }

    // FETCH_CALL_HISTORY_COUNT
    case constants.FETCH_CALL_HISTORY_COUNT.REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          id: action.payload.id,
          count:
            state.history.id === action.payload.id ? state.history.count : 0,
          isFetchingCount: true,
          errorFetchingCount: null,
        },
      }

    case constants.FETCH_CALL_HISTORY_COUNT.SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          count: action.payload.count,
          isFetchingCount: false,
          errorFetchingCount: null,
        },
      }

    case constants.FETCH_CALL_HISTORY_COUNT.FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          count: 0,
          isFetchingCount: false,
          errorFetchingCount: action.payload.error,
        },
      }

    //

    case constants.ASSIGN_TO_THIRD_PARTY_REQUEST_REQUEST:
      return {
        ...state,
        assigningToThirdParty: {
          ...state.assigningToThirdParty,
          isFetching: true,
          error: null,
        },
      }

    case constants.ASSIGN_TO_THIRD_PARTY_REQUEST_SUCCESS:
      return {
        ...state,
        assigningToThirdParty: {
          ...state.assigningToThirdParty,
          isFetching: false,
          error: null,
        },
      }

    case constants.ASSIGN_TO_THIRD_PARTY_REQUEST_FAILURE:
      return {
        ...state,
        assigningToThirdParty: {
          ...state.assigningToThirdParty,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.SENDING_TO_SUB_ORDER_REQUEST:
      return {
        ...state,
        sendingToSubOrder: {
          ...state.sendingToSubOrder,
          isFetching: true,
          error: null,
        },
      }

    case constants.SENDING_TO_SUB_ORDER_SUCCESS:
      return {
        ...state,
        sendingToSubOrder: {
          ...state.sendingToSubOrder,
          isFetching: false,
          error: null,
        },
      }

    case constants.SENDING_TO_SUB_ORDER_FAILURE:
      return {
        ...state,
        sendingToSubOrder: {
          ...state.sendingToSubOrder,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_REQUEST:
      return {
        ...state,
        assigningOwnSpecialist: {
          ...state.assigningOwnSpecialist,
          isFetching: true,
          error: null,
        },
      }

    case constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_SUCCESS:
      return {
        ...state,
        assigningOwnSpecialist: {
          ...state.assigningOwnSpecialist,
          isFetching: false,
          error: null,
        },
      }

    case constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_FAILURE:
      return {
        ...state,
        assigningOwnSpecialist: {
          ...state.assigningOwnSpecialist,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CALL_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          isFetching: true,
          request: {},
        },
      }

    case constants.FETCH_CALL_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isFetching: false,
          request: action.payload,
        },
      }

    case constants.FETCH_CALL_FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPDATE_CALL_REQUEST:
      return {
        ...state,
        updatingRequest: {
          ...state.updatingRequest,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPDATE_CALL_SUCCESS:
      return {
        ...state,
        updatingRequest: {
          ...state.updatingRequest,
          isFetching: false,
          error: null,
        },
      }

    case constants.UPDATE_CALL_FAILURE:
      return {
        ...state,
        updatingRequest: {
          ...state.updatingRequest,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        uploadingFile: {
          ...state.uploadingFile,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadingFile: {
          ...state.uploadingFile,
          isFetching: false,
          error: null,
        },
      }

    case constants.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadingFile: {
          ...state.uploadingFile,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_FILE_REQUEST:
      return {
        ...state,
        deletingFile: {
          ...state.deletingFile,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_FILE_SUCCESS:
      return {
        ...state,
        deletingFile: {
          ...state.deletingFile,
          isFetching: false,
          error: null,
        },
      }

    case constants.DELETE_FILE_FAILURE:
      return {
        ...state,
        deletingFile: {
          ...state.deletingFile,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_PHOTO_REQUEST:
      return {
        ...state,
        deletingPhoto: {
          ...state.deletingPhoto,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_PHOTO_SUCCESS:
      return {
        ...state,
        deletingPhoto: {
          ...state.deletingPhoto,
          isFetching: false,
          error: null,
        },
      }

    case constants.DELETE_PHOTO_FAILURE:
      return {
        ...state,
        deletingPhoto: {
          ...state.deletingPhoto,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        uploadingPhoto: {
          ...state.uploadingPhoto,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        uploadingPhoto: {
          ...state.uploadingPhoto,
          isFetching: false,
          error: null,
        },
      }

    case constants.UPLOAD_PHOTO_FAILURE:
      return {
        ...state,
        uploadingPhoto: {
          ...state.uploadingPhoto,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_FILES_REQUEST:
      return {
        ...state,
        docs: {
          ...state.docs,
          files: [],
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_FILES_SUCCESS:
      return {
        ...state,
        docs: {
          ...state.docs,
          files: action.payload,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_FILES_FAILURE:
      return {
        ...state,
        docs: {
          ...state.docs,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_PHOTOS_REQUEST:
      return {
        ...state,
        photos: {
          ...state.photos,
          photos: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_PHOTOS_SUCCESS:
      return {
        ...state,
        photos: {
          ...state.photos,
          photos: action.payload,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_PHOTOS_FAILURE:
      return {
        ...state,
        photos: {
          ...state.photos,
          isFetching: false,
          error: null,
          filters: [],
        },
      }

    case constants.FETCH_SERVICE_CALLS_LIST_REQUEST:
      return {
        ...state,
        serviceCalls: {
          ...state.serviceCalls,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALLS_LIST_SUCCESS:
      return {
        ...state,
        serviceCalls: {
          ...state.serviceCalls,
          data: action.payload,
          isFetching: false,
        },
      }

    case constants.FETCH_SERVICE_CALLS_LIST_FAILURE:
      return {
        ...state,
        serviceCalls: {
          ...state.serviceCalls,
          isFetching: false,
          data: [],
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALLS_LIST_COUNT_REQUEST:
      return {
        ...state,
        serviceCallsCount: {
          ...state.serviceCallsCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALLS_LIST_COUNT_SUCCESS:
      return {
        ...state,
        serviceCallsCount: {
          ...state.serviceCallsCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALLS_LIST_COUNT_FAILURE:
      return {
        ...state,
        serviceCallsCount: {
          ...state.serviceCallsCount,
          isFetching: false,
          count: 0,
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDERS_LIST_REQUEST:
      return {
        ...state,
        serviceCallOrders: {
          ...state.serviceCallOrders,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        serviceCallOrders: {
          ...state.serviceCallOrders,
          data: action.payload,
          isFetching: false,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDERS_LIST_FAILURE:
      return {
        ...state,
        serviceCallOrders: {
          ...state.serviceCallOrders,
          isFetching: false,
          data: [],
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_REQUEST:
      return {
        ...state,
        serviceCallOrdersCount: {
          ...state.serviceCallOrdersCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_SUCCESS:
      return {
        ...state,
        serviceCallOrdersCount: {
          ...state.serviceCallOrdersCount,
          isFetching: false,
          count: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_FAILURE:
      return {
        ...state,
        serviceCallOrdersCount: {
          ...state.serviceCallOrdersCount,
          isFetching: false,
          count: 0,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        orders: {
          ...state.orders,
          orders: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_ORDERS_SUCCESS:
      const orders = _.map(action.payload.orders, order =>
        Object.assign(order, { isFetching: false }),
      )
      return {
        ...state,
        orders: {
          ...state.orders,
          orders,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        orders: {
          ...state.orders,
          orders: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDER_REQUEST:
      const { id, type } = action.payload
      return {
        ...state,
        order: {
          id,
          isFetching: true,
          error: null,
          order: {},
          files: null,
        },
        activeServiceCallOrders: {
          ...state.activeServiceCallOrders,
          orders:
            type === 'active'
              ? _.forEach(state.activeServiceCallOrders.orders, order => {
                  const curOrder = order
                  if (order.id === id) {
                    curOrder.isFetching = true
                  }
                  return curOrder
                })
              : state.activeServiceCallOrders.orders,
        },
        closedServiceCallOrders: {
          ...state.closedServiceCallOrders,
          orders:
            type === 'closed'
              ? _.forEach(state.closedServiceCallOrders.orders, order => {
                  const curOrder = order
                  if (order.id === id) {
                    curOrder.isFetching = true
                  }
                  return curOrder
                })
              : state.closedServiceCallOrders.orders,
        },
        canceledServiceCallOrders: {
          ...state.canceledServiceCallOrders,
          orders:
            type === 'canceled'
              ? _.forEach(state.canceledServiceCallOrders.orders, order => {
                  const curOrder = order
                  if (order.id === id) {
                    curOrder.isFetching = true
                  }
                  return curOrder
                })
              : state.canceledServiceCallOrders.orders,
        },
        orders_log: {
          ...state.orders_log,
          orders_log: [],
          isFetching: true,
          error: null,
        },
        orders_log_count: {
          ...state.orders_log_count,
          orders_log_count: 0,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_ORDER_SUCCESS:
      const { result, type: typeList } = action.payload
      const fetchedOrder = Object.assign(result.order, {
        isFetching: false,
        files: result.files,
      })
      return {
        ...state,
        order: {
          ...state.order,
          isFetching: false,
          order: action.payload.result.order,
          files: action.payload.result.order.ServiceCallOrderFile,
        },
        activeServiceCallOrders: {
          ...state.activeServiceCallOrders,
          orders:
            typeList === 'active'
              ? _.map(state.activeServiceCallOrders.orders, order => {
                  if (order.id === fetchedOrder.id) {
                    return fetchedOrder
                  }
                  return order
                })
              : state.activeServiceCallOrders.orders,
        },
        closedServiceCallOrders: {
          ...state.closedServiceCallOrders,
          orders:
            typeList === 'closed'
              ? _.map(state.closedServiceCallOrders.orders, order => {
                  if (order.id === fetchedOrder.id) {
                    return fetchedOrder
                  }
                  return order
                })
              : state.closedServiceCallOrders.orders,
        },
        canceledServiceCallOrders: {
          ...state.canceledServiceCallOrders,
          orders:
            typeList === 'canceled'
              ? _.map(state.canceledServiceCallOrders.orders, order => {
                  if (order.id === fetchedOrder.id) {
                    return fetchedOrder
                  }
                  return order
                })
              : state.canceledServiceCallOrders.orders,
        },
      }

    case constants.FETCH_ORDER_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          isFetching: false,
          error: action.payload,
        },
      }
    case constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_REQUEST:
      return {
        ...state,
        assigningOrderOwnSpecialist: {
          ...state.assigningOrderOwnSpecialist,
          isFetching: true,
          error: null,
        },
      }

    case constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_SUCCESS:
      return {
        ...state,
        assigningOrderOwnSpecialist: {
          ...state.assigningOrderOwnSpecialist,
          isFetching: false,
          error: null,
        },
      }

    case constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_FAILURE:
      return {
        ...state,
        assigningOrderOwnSpecialist: {
          ...state.assigningOrderOwnSpecialist,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.SEND_ORDER_TO_SUB_ORDER_REQUEST:
      return {
        ...state,
        sendOrderToSubOrder: {
          ...state.sendOrderToSubOrder,
          isFetching: true,
          error: null,
        },
      }

    case constants.SEND_ORDER_TO_SUB_ORDER_SUCCESS:
      return {
        ...state,
        sendOrderToSubOrder: {
          ...state.sendOrderToSubOrder,
          isFetching: false,
          error: null,
        },
      }

    case constants.SEND_ORDER_TO_SUB_ORDER_FAILURE:
      return {
        ...state,
        sendOrderToSubOrder: {
          ...state.sendOrderToSubOrder,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CANCEL_REQUEST_REQUEST:
      return {
        ...state,
        cancelRequest: {
          ...state.cancelRequest,
          isFetching: true,
          cancelSuccess: false,
          error: null,
        },
      }

    case constants.CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        cancelRequest: {
          ...state.cancelRequest,
          isFetching: false,
          cancelSuccess: true,
          error: null,
        },
      }

    case constants.CANCEL_REQUEST_FAILURE:
      return {
        ...state,
        cancelRequest: {
          ...state.cancelRequest,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.REJECT_ORDER_REQUEST:
      return {
        ...state,
        rejectOrder: {
          ...state.rejectOrder,
          isFetching: true,
          fetchSuccess: false,
          error: null,
        },
      }

    case constants.REJECT_ORDER_SUCCESS:
      return {
        ...state,
        rejectOrder: {
          ...state.rejectOrder,
          isFetching: false,
          fetchSuccess: true,
          error: null,
        },
      }

    case constants.REJECT_ORDER_FAILURE:
      return {
        ...state,
        rejectOrder: {
          ...state.rejectOrder,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_REQUEST:
      return {
        ...state,
        changeClientResponsible: {
          ...state.changeClientResponsible,
          isFetching: true,
          error: null,
        },
      }

    case constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_SUCCESS:
      return {
        ...state,
        changeClientResponsible: {
          ...state.changeClientResponsible,
          isFetching: false,
          error: null,
        },
      }

    case constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_FAILURE:
      return {
        ...state,
        changeClientResponsible: {
          ...state.changeClientResponsible,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_REQUEST:
      return {
        ...state,
        changePerformerResponsible: {
          ...state.changePerformerResponsible,
          isFetching: true,
          error: null,
        },
      }

    case constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_SUCCESS:
      return {
        ...state,
        changePerformerResponsible: {
          ...state.changePerformerResponsible,
          isFetching: false,
          error: null,
        },
      }

    case constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_FAILURE:
      return {
        ...state,
        changePerformerResponsible: {
          ...state.changePerformerResponsible,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CHANGE_ENGINEER_ORDER_REQUEST:
      return {
        ...state,
        changeEngineer: {
          ...state.changeEngineer,
          isFetching: true,
          error: null,
        },
      }

    case constants.CHANGE_ENGINEER_ORDER_SUCCESS:
      return {
        ...state,
        changeEngineer: {
          ...state.changeEngineer,
          isFetching: false,
          error: null,
        },
      }

    case constants.CHANGE_ENGINEER_ORDER_FAILURE:
      return {
        ...state,
        changeEngineer: {
          ...state.changeEngineer,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_ORDER_INFO_REQUEST:
      return {
        ...state,
        editOrderInfo: {
          ...state.editOrderInfo,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_ORDER_INFO_SUCCESS:
      return {
        ...state,
        editOrderInfo: {
          ...state.editOrderInfo,
          isFetching: false,
          error: null,
        },
      }

    case constants.EDIT_ORDER_INFO_FAILURE:
      return {
        ...state,
        editOrderInfo: {
          ...state.editOrderInfo,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDERS_LOG_REQUEST:
      return {
        ...state,
        orders_log: {
          ...state.orders_log,
          orders_log: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_ORDERS_LOG_SUCCESS:
      return {
        ...state,
        orders_log: {
          ...state.orders_log,
          orders_log: action.payload.logs,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_ORDERS_LOG_FAILURE:
      return {
        ...state,
        orders_log: {
          ...state.orders_log,
          orders_log: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDERS_LOG_COUNT_REQUEST:
      return {
        ...state,
        orders_log_count: {
          ...state.orders_log_count,
          orders_log_count: 0,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_ORDERS_LOG_COUNT_SUCCESS:
      return {
        ...state,
        orders_log_count: {
          ...state.orders_log_count,
          orders_log_count: action.payload.count,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_ORDERS_LOG_COUNT_FAILURE:
      return {
        ...state,
        orders_log_count: {
          ...state.orders_log_count,
          orders_log_count: 0,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CANCEL_ORDER_BY_CLIENT_REQUEST:
      return {
        ...state,
        cancelOrder: {
          ...state.cancelOrder,
          isFetching: true,
          error: null,
        },
      }

    case constants.CANCEL_ORDER_BY_CLIENT_SUCCESS:
      return {
        ...state,
        cancelOrder: {
          ...state.cancelOrder,
          isFetching: false,
          error: null,
        },
      }

    case constants.CANCEL_ORDER_BY_CLIENT_FAILURE:
      return {
        ...state,
        cancelOrder: {
          ...state.cancelOrder,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDER_COMMENTS_REQUEST:
      return {
        ...state,
        orderComments: {
          ...state.orderComments,
          comments: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_ORDER_COMMENTS_SUCCESS:
      return {
        ...state,
        orderComments: {
          ...state.orderComments,
          comments: action.payload.comments,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_ORDER_COMMENTS_FAILURE:
      return {
        ...state,
        orderComments: {
          ...state.orderComments,
          comments: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPLOAD_ORDER_COMMENT_REQUEST:
      return {
        ...state,
        uploadingComment: {
          ...state.uploadingComment,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPLOAD_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        uploadingComment: {
          ...state.uploadingComment,
          isFetching: false,
          error: null,
        },
      }

    case constants.UPLOAD_ORDER_COMMENT_FAILURE:
      return {
        ...state,
        uploadingComment: {
          ...state.uploadingComment,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPLOAD_ORDER_SUBCOMMENT_REQUEST:
      return {
        ...state,
        uploadingSubcomment: {
          ...state.uploadingSubcomment,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPLOAD_ORDER_SUBCOMMENT_SUCCESS:
      return {
        ...state,
        uploadingSubcomment: {
          ...state.uploadingSubcomment,
          isFetching: false,
          error: null,
        },
      }

    case constants.UPLOAD_ORDER_SUBCOMMENT_FAILURE:
      return {
        ...state,
        uploadingSubcomment: {
          ...state.uploadingSubcomment,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDER_COMMENTS_COUNT_REQUEST:
      return {
        ...state,
        orderCommentsCount: {
          ...state.orderCommentsCount,
          count: 0,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_ORDER_COMMENTS_COUNT_SUCCESS:
      return {
        ...state,
        orderCommentsCount: {
          ...state.orderCommentsCount,
          count: action.payload.count,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_ORDER_COMMENTS_COUNT_FAILURE:
      return {
        ...state,
        orderCommentsCount: {
          ...state.orderCommentsCount,
          count: 0,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CREATE_SERVICE_CONTRACT_REQUEST:
      return {
        ...state,
        createServiceContracts: {
          ...state.createServiceContracts,
          isFetching: true,
          success: false,
          contract_id: null,
          error: null,
        },
      }

    case constants.CREATE_SERVICE_CONTRACT_SUCCESS:
      return {
        ...state,
        createServiceContracts: {
          ...state.createServiceContracts,
          isFetching: false,
          success: true,
          contract_id: action.payload.contract_id,
          error: null,
        },
      }

    case constants.CREATE_SERVICE_CONTRACT_FAILURE:
      return {
        ...state,
        createServiceContracts: {
          ...state.createServiceContracts,
          isFetching: false,
          contract_id: null,
          success: false,
          error: action.payload,
        },
      }

    case constants.SET_ORDER_RESULT_TYPE_REQUEST:
      return {
        ...state,
        setOrderResultType: {
          ...state.setOrderResultType,
          isSetting: true,
          setSuccessfully: false,
          error: null,
        },
      }

    case constants.SET_ORDER_RESULT_TYPE_SUCCESS:
      return {
        ...state,
        setOrderResultType: {
          ...state.setOrderResultType,
          isSetting: false,
          setSuccessfully: true,
          error: null,
        },
      }

    case constants.SET_ORDER_RESULT_TYPE_FAILURE:
      return {
        ...state,
        setOrderResultType: {
          ...state.setOrderResultType,
          isSetting: false,
          setSuccessfully: false,
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDER_ITEMS_REQUEST:
      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          isFetching: false,
          items: action.payload.items.map(row => ({
            ...row,
            price_purchase_with_nds: +row.price_purchase + +row.delta,
          })),
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDER_ITEMS_FAILURE:
      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_REQUEST:
      return {
        ...state,
        orderItemsCount: {
          ...state.orderItemsCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_SUCCESS:
      return {
        ...state,
        orderItemsCount: {
          ...state.orderItemsCount,
          isFetching: false,
          count: action.payload.count,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_FAILURE:
      return {
        ...state,
        orderItemsCount: {
          ...state.orderItemsCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_REQUEST:
      return {
        ...state,
        suborderItems: {
          ...state.suborderItems,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_SUCCESS:
      return {
        ...state,
        suborderItems: {
          ...state.suborderItems,
          isFetching: false,
          items: action.payload.items,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_FAILURE:
      return {
        ...state,
        suborderItems: {
          ...state.suborderItems,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_REQUEST:
      return {
        ...state,
        suborderItemsCount: {
          ...state.suborderItemsCount,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_SUCCESS:
      return {
        ...state,
        suborderItemsCount: {
          ...state.suborderItemsCount,
          isFetching: false,
          count: action.payload.count,
          error: null,
        },
      }

    case constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_FAILURE:
      return {
        ...state,
        suborderItemsCount: {
          ...state.suborderItemsCount,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.SAVE_ORDER_ITEMS_LIST_REQUEST:
      return {
        ...state,
        saveOrderItemsList: {
          ...state.saveOrderItemsList,
          isSaving: true,
          savedSuccessfully: false,
          error: null,
        },
      }

    case constants.SAVE_ORDER_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        saveOrderItemsList: {
          ...state.saveOrderItemsList,
          isSaving: false,
          savedSuccessfully: true,
          error: null,
        },
      }

    case constants.SAVE_ORDER_ITEMS_LIST_FAILURE:
      return {
        ...state,
        saveOrderItemsList: {
          ...state.saveOrderItemsList,
          isSaving: false,
          savedSuccessfully: false,
          error: action.payload,
        },
      }

    case constants.CLEAR_ORDER_ITEMS_LIST_REQUEST:
      return {
        ...state,
        clearOrderItemsList: {
          ...state.clearOrderItemsList,
          isClearing: true,
          clearedSuccessfully: false,
          error: null,
        },
      }

    case constants.CLEAR_ORDER_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        clearOrderItemsList: {
          ...state.clearOrderItemsList,
          isClearing: false,
          clearedSuccessfully: true,
          error: null,
        },
      }

    case constants.CLEAR_ORDER_ITEMS_LIST_FAILURE:
      return {
        ...state,
        clearOrderItemsList: {
          ...state.clearOrderItemsList,
          isClearing: false,
          clearedSuccessfully: false,
          error: action.payload,
        },
      }

    case constants.COPY_ORDER_ITEMS_FROM_CHILD_REQUEST:
      return {
        ...state,
        copyItemsFromChild: {
          ...state.copyItemsFromChild,
          isCopying: true,
          copySuccess: false,
          error: null,
        },
      }

    case constants.COPY_ORDER_ITEMS_FROM_CHILD_SUCCESS:
      return {
        ...state,
        copyItemsFromChild: {
          ...state.copyItemsFromChild,
          isCopying: false,
          copySuccess: true,
          error: null,
        },
      }

    case constants.COPY_ORDER_ITEMS_FROM_CHILD_FAILURE:
      return {
        ...state,
        copyItemsFromChild: {
          ...state.copyItemsFromChild,
          isCopying: false,
          copySuccess: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDER_NDS_REQUEST:
      return {
        ...state,
        fetchOrderNds: {
          ...state.fetchOrderNds,
          isFetching: true,
          fetchSuccess: false,
          nds: 0,
          error: null,
        },
      }

    case constants.FETCH_ORDER_NDS_SUCCESS:
      return {
        ...state,
        fetchOrderNds: {
          ...state.fetchOrderNds,
          isFetching: false,
          fetchSuccess: true,
          nds: action.payload.nds,
          error: null,
        },
      }

    case constants.FETCH_ORDER_NDS_FAILURE:
      return {
        ...state,
        fetchOrderNds: {
          ...state.fetchOrderNds,
          isFetching: false,
          fetchSuccess: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDER_INCOME_NDS_REQUEST:
      return {
        ...state,
        fetchOrderInputNds: {
          ...state.fetchOrderInputNds,
          isFetching: true,
          fetchSuccess: false,
          nds: 0,
          error: null,
        },
      }

    case constants.FETCH_ORDER_INCOME_NDS_SUCCESS:
      return {
        ...state,
        fetchOrderInputNds: {
          ...state.fetchOrderInputNds,
          isFetching: false,
          fetchSuccess: true,
          nds: action.payload.nds,
          error: null,
        },
      }

    case constants.FETCH_ORDER_INCOME_NDS_FAILURE:
      return {
        ...state,
        fetchOrderInputNds: {
          ...state.fetchOrderInputNds,
          isFetching: false,
          fetchSuccess: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ORDER_OVERHEAD_REQUEST:
      return {
        ...state,
        fetchOrderOverhead: {
          ...state.fetchOrderOverhead,
          isFetching: true,
          fetchSuccess: false,
          overhead: 0,
          error: null,
        },
      }

    case constants.FETCH_ORDER_OVERHEAD_SUCCESS:
      return {
        ...state,
        fetchOrderOverhead: {
          ...state.fetchOrderOverhead,
          isFetching: false,
          fetchSuccess: true,
          overhead: action.payload.overhead,
          error: null,
        },
      }

    case constants.FETCH_ORDER_OVERHEAD_FAILURE:
      return {
        ...state,
        fetchOrderOverhead: {
          ...state.fetchOrderOverhead,
          isFetching: false,
          fetchSuccess: false,
          error: action.payload,
        },
      }

    case constants.UPDATE_ORDER_NDS_REQUEST:
      return {
        ...state,
        updateOrderNds: {
          ...state.updateOrderNds,
          isUpdating: true,
          updateSuccess: false,
          error: null,
        },
      }

    case constants.UPDATE_ORDER_NDS_SUCCESS:
      return {
        ...state,
        updateOrderNds: {
          ...state.updateOrderNds,
          isUpdating: false,
          updateSuccess: true,
          error: null,
        },
      }

    case constants.UPDATE_ORDER_NDS_FAILURE:
      return {
        ...state,
        updateOrderNds: {
          ...state.updateOrderNds,
          isUpdating: false,
          updateSuccess: false,
          error: action.payload,
        },
      }

    case constants.UPDATE_OVERHEAD_REQUEST:
      return {
        ...state,
        updateOrderOverhead: {
          ...state.updateOrderOverhead,
          isUpdating: true,
          updateSuccess: false,
          error: null,
        },
      }

    case constants.UPDATE_OVERHEAD_SUCCESS:
      return {
        ...state,
        updateOrderOverhead: {
          ...state.updateOrderOverhead,
          isUpdating: false,
          updateSuccess: true,
          error: null,
        },
      }

    case constants.UPDATE_OVERHEAD_FAILURE:
      return {
        ...state,
        updateOrderOverhead: {
          ...state.updateOrderOverhead,
          isUpdating: false,
          updateSuccess: false,
          error: action.payload,
        },
      }

    case constants.UPDATE_ORDER_INCOME_NDS_REQUEST:
      return {
        ...state,
        updateOrderIncomeNds: {
          ...state.updateOrderIncomeNds,
          isUpdating: true,
          updateSuccess: false,
          error: null,
        },
      }

    case constants.UPDATE_ORDER_INCOME_NDS_SUCCESS:
      return {
        ...state,
        updateOrderIncomeNds: {
          ...state.updateOrderIncomeNds,
          isUpdating: false,
          updateSuccess: true,
          error: null,
        },
      }

    case constants.UPDATE_ORDER_INCOME_NDS_FAILURE:
      return {
        ...state,
        updateOrderIncomeNds: {
          ...state.updateOrderIncomeNds,
          isUpdating: false,
          updateSuccess: false,
          error: action.payload,
        },
      }

    case constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_REQUEST:
      return {
        ...state,
        workCompletionFile: {
          ...state.workCompletionFile,
          isFetching: true,
          error: null,
        },
      }

    case constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_SUCCESS:
      return {
        ...state,
        workCompletionFile: {
          ...state.workCompletionFile,
          isFetching: false,
          error: null,
        },
      }

    case constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_FAILURE:
      return {
        ...state,
        workCompletionFile: {
          ...state.workCompletionFile,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_ORDER_WORK_COMPLETION_FILE_REQUEST:
      return {
        ...state,
        workCompletionFileDelete: {
          ...state.workCompletionFileDelete,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_ORDER_WORK_COMPLETION_FILE_SUCCESS:
      return {
        ...state,
        workCompletionFileDelete: {
          ...state.workCompletionFileDelete,
          isFetching: false,
          error: null,
        },
      }

    case constants.DELETE_ORDER_WORK_COMPLETION_FILE_FAILURE:
      return {
        ...state,
        workCompletionFileDelete: {
          ...state.workCompletionFileDelete,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CONFIRM_CERTIFICATE_OF_COMPLETION_REQUEST:
      return {
        ...state,
        confirmCertificateOfCompletionWork: {
          ...state.confirmCertificateOfCompletionWork,
          isFetching: true,
          confirmSuccessfully: false,
          error: null,
        },
      }

    case constants.CONFIRM_CERTIFICATE_OF_COMPLETION_SUCCESS:
      return {
        ...state,
        confirmCertificateOfCompletionWork: {
          ...state.confirmCertificateOfCompletionWork,
          isFetching: false,
          confirmSuccessfully: true,
        },
      }

    case constants.CONFIRM_CERTIFICATE_OF_COMPLETION_FAILURE:
      return {
        ...state,
        confirmCertificateOfCompletionWork: {
          ...state.confirmCertificateOfCompletionWork,
          isFetching: false,
          confirmSuccessfully: false,
          error: action.payload,
        },
      }

    case constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_REQUEST:
      return {
        ...state,
        activeServiceCallOrders: {
          ...state.activeServiceCallOrders,
          isFetching: true,
          orders: null,
          error: null,
        },
      }

    case constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_SUCCESS:
      const activeOrders = _.map(action.payload, order =>
        Object.assign(order, { isFetching: false }),
      )
      return {
        ...state,
        activeServiceCallOrders: {
          ...state.activeServiceCallOrders,
          isFetching: false,
          orders: activeOrders,
        },
      }

    case constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_FAILURE:
      return {
        ...state,
        activeServiceCallOrders: {
          ...state.activeServiceCallOrders,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_REQUEST:
      return {
        ...state,
        closedServiceCallOrders: {
          ...state.closedServiceCallOrders,
          isFetching: true,
          orders: null,
          error: null,
        },
      }

    case constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_SUCCESS:
      const closedOrders = _.map(action.payload, order =>
        Object.assign(order, { isFetching: false }),
      )
      return {
        ...state,
        closedServiceCallOrders: {
          ...state.closedServiceCallOrders,
          isFetching: false,
          orders: closedOrders,
        },
      }

    case constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_FAILURE:
      return {
        ...state,
        closedServiceCallOrders: {
          ...state.closedServiceCallOrders,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_REQUEST:
      return {
        ...state,
        canceledServiceCallOrders: {
          ...state.canceledServiceCallOrders,
          isFetching: true,
          orders: null,
          error: null,
        },
      }

    case constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_SUCCESS:
      const canceledOrders = _.map(action.payload, order =>
        Object.assign(order, { isFetching: false }),
      )
      return {
        ...state,
        canceledServiceCallOrders: {
          ...state.canceledServiceCallOrders,
          isFetching: false,
          orders: canceledOrders,
        },
      }

    case constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_FAILURE:
      return {
        ...state,
        canceledServiceCallOrders: {
          ...state.canceledServiceCallOrders,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST:
      return {
        ...state,
        acceptOrderCertificateOfCompletion: {
          ...state.acceptOrderCertificateOfCompletion,
          isAccepting: true,
          error: null,
        },
      }

    case constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS:
      return {
        ...state,
        acceptOrderCertificateOfCompletion: {
          ...state.acceptOrderCertificateOfCompletion,
          isAccepting: false,
          acceptedSuccessfully: true,
        },
      }

    case constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE:
      return {
        ...state,
        acceptOrderCertificateOfCompletion: {
          ...state.acceptOrderCertificateOfCompletion,
          isAccepting: false,
          error: action.payload,
        },
      }

    case constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST:
      return {
        ...state,
        rejectOrderCertificateOfCompletion: {
          ...state.rejectOrderCertificateOfCompletion,
          isRejecting: true,
          error: null,
        },
      }

    case constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_SUCCESS:
      return {
        ...state,
        rejectOrderCertificateOfCompletion: {
          ...state.rejectOrderCertificateOfCompletion,
          isRejecting: false,
          rejectedSuccessfully: true,
        },
      }

    case constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_FAILURE:
      return {
        ...state,
        rejectOrderCertificateOfCompletion: {
          ...state.rejectOrderCertificateOfCompletion,
          isRejecting: false,
          error: action.payload,
        },
      }

    case constants.ASSIGN_USING_CONTRACT_REQUEST:
      return {
        ...state,
        assignUsingContract: {
          ...state.assignUsingContract,
          isFetching: true,
          error: null,
        },
      }

    case constants.ASSIGN_USING_CONTRACT_SUCCESS:
      return {
        ...state,
        assignUsingContract: {
          ...state.assignUsingContract,
          isFetching: false,
        },
      }

    case constants.ASSIGN_USING_CONTRACT_FAILURE:
      return {
        ...state,
        assignUsingContract: {
          ...state.assignUsingContract,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_REQUEST:
      return {
        ...state,
        contract_available_equipment: {
          ...state.contract_available_equipment,
          equipment: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        contract_available_equipment: {
          ...state.contract_available_equipment,
          equipment: action.payload.equipment,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_FAILURE:
      return {
        ...state,
        contract_available_equipment: {
          ...state.contract_available_equipment,
          equipment: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_REQUEST:
      return {
        ...state,
        contract: {
          ...state.contract,
          contract: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: {
          ...state.contract,
          contract: action.payload,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_CONTRACT_FAILURE:
      return {
        ...state,
        contract: {
          ...state.contract,
          contract: [],
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_REQUEST:
      return {
        ...state,
        contract_available_equipment_count: {
          ...state.contract_available_equipment_count,
          count: 0,
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        contract_available_equipment_count: {
          ...state.contract_available_equipment_count,
          count: action.payload.count,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_FAILURE:
      return {
        ...state,
        contract_available_equipment_count: {
          ...state.contract_available_equipment_count,
          count: 0,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ADD_EQUIPMENT_TO_CONTRACT_REQUEST:
      return {
        ...state,
        addEquipmentToContracts: {
          ...state.addEquipmentToContracts,
          isFetching: true,
          error: null,
        },
      }

    case constants.ADD_EQUIPMENT_TO_CONTRACT_SUCCESS:
      return {
        ...state,
        addEquipmentToContracts: {
          ...state.addEquipmentToContracts,
          isFetching: false,
          error: null,
        },
      }

    case constants.ADD_EQUIPMENT_TO_CONTRACT_FAILURE:
      return {
        ...state,
        addEquipmentToContracts: {
          ...state.addEquipmentToContracts,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_REQUEST:
      return {
        ...state,
        closeCallFromNeedrepair: {
          ...state.closeCallFromNeedrepair,
          isClosing: true,
          closeSuccess: false,
          error: null,
        },
      }

    case constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_SUCCESS:
      return {
        ...state,
        closeCallFromNeedrepair: {
          ...state.closeCallFromNeedrepair,
          isClosing: false,
          closeSuccess: true,
          error: null,
        },
      }

    case constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_FAILURE:
      return {
        ...state,
        closeCallFromNeedrepair: {
          ...state.closeCallFromNeedrepair,
          isClosing: false,
          error: action.payload,
        },
      }

    case constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_REQUEST:
      return {
        ...state,
        sendCallToRepairDiagnosticians: {
          ...state.sendCallToRepairDiagnosticians,
          isSending: true,
          sendSuccess: false,
          error: null,
        },
      }

    case constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_SUCCESS:
      return {
        ...state,
        sendCallToRepairDiagnosticians: {
          ...state.sendCallToRepairDiagnosticians,
          isSending: false,
          sendSuccess: true,
          error: null,
        },
      }

    case constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_FAILURE:
      return {
        ...state,
        sendCallToRepairDiagnosticians: {
          ...state.sendCallToRepairDiagnosticians,
          isSending: false,
          error: action.payload,
        },
      }

    case constants.FETCH_OFFER_FILES_REQUEST:
      return {
        ...state,
        offerDocs: {
          ...state.offerDocs,
          files: [],
          isFetching: true,
          error: null,
        },
      }

    case constants.FETCH_OFFER_FILES_SUCCESS:
      return {
        ...state,
        offerDocs: {
          ...state.offerDocs,
          files: action.payload,
          isFetching: false,
          error: null,
        },
      }

    case constants.FETCH_OFFER_FILES_FAILURE:
      return {
        ...state,
        offerDocs: {
          ...state.offerDocs,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.SEND_CALL_USING_CONTRACT_REQUEST:
      return {
        ...state,
        sendCallUsingContract: {
          ...state.sendCallUsingContract,
          isFetching: true,
          sendSuccess: false,
          error: null,
        },
      }

    case constants.SEND_CALL_USING_CONTRACT_SUCCESS:
      return {
        ...state,
        sendCallUsingContract: {
          ...state.sendCallUsingContract,
          isFetching: false,
          sendSuccess: true,
          error: null,
        },
      }

    case constants.SEND_CALL_USING_CONTRACT_FAILURE:
      return {
        ...state,
        sendCallUsingContract: {
          ...state.sendCallUsingContract,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_ORDER_POSSIBLE_REPORTS_REQUEST:
      return {
        ...state,
        orderPossibleReports: {
          ...state.orderPossibleReports,
          isFetching: true,
          templates: [],
          error: null,
        },
      }

    case constants.GET_ORDER_POSSIBLE_REPORTS_SUCCESS:
      return {
        ...state,
        orderPossibleReports: {
          ...state.orderPossibleReports,
          isFetching: false,
          templates: action.payload,
          error: null,
        },
      }

    case constants.GET_ORDER_POSSIBLE_REPORTS_FAILURE:
      return {
        ...state,
        orderPossibleReports: {
          ...state.orderPossibleReports,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_CALL_POSSIBLE_BLANKS_REQUEST:
      return {
        ...state,
        callPossibleReports: {
          ...state.callPossibleReports,
          isFetching: true,
          count: 0,
          names: [],
          error: null,
        },
      }

    case constants.GET_CALL_POSSIBLE_BLANKS_SUCCESS:
      return {
        ...state,
        callPossibleReports: {
          ...state.callPossibleReports,
          isFetching: false,
          count: action.payload.count,
          names: action.payload.names,
          error: null,
        },
      }

    case constants.GET_CALL_POSSIBLE_BLANKS_FAILURE:
      return {
        ...state,
        callPossibleReports: {
          ...state.callPossibleReports,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_CALLS_BY_GENERAL_SEARCH_REQUEST:
      return {
        ...state,
        callsByNumberSearch: {
          ...state.callsByNumberSearch,
          searchValue: action.payload.search_value,
          searchFor: action.payload.searchFor,
          isFetching: true,
          calls: action.payload.dontClear
            ? state.callsByNumberSearch.calls
            : [],
          error: null,
        },
      }

    case constants.GET_CALLS_BY_GENERAL_SEARCH_SUCCESS:
      return {
        ...state,
        callsByNumberSearch: {
          ...state.callsByNumberSearch,
          isFetching: false,
          calls: action.payload,
          error: null,
        },
      }

    case constants.GET_CALLS_BY_GENERAL_SEARCH_FAILURE:
      return {
        ...state,
        callsByNumberSearch: {
          ...state.callsByNumberSearch,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CLEAR_CALLS_SEARCH_BY_NUMBER: {
      return {
        ...state,
        callsByNumberSearch: initialState.callsByNumberSearch,
      }
    }

    case constants.SET_NDS_CHECKBOX_VALUE:
      return {
        ...state,
        ndsChecked: action.payload,
      }
    case constants.SET_INPUT_NDS_CHECKBOX_VALUE:
      return {
        ...state,
        inputWithNDSChecked: action.payload,
      }
    case constants.SET_OVERHEAD_CHECKBOX_VALUE:
      return {
        ...state,
        overheadChecked: action.payload,
      }

    // FETCH_SD_ACTIONS_HISTORY
    case constants.FETCH_SD_ACTIONS_HISTORY_REQUEST:
      return {
        ...state,
        sdActionsHistory: {
          ...state.sdActionsHistory,
          isFetching: true,
        },
      }

    case constants.FETCH_SD_ACTIONS_HISTORY_SUCCESS:
      return {
        ...state,
        sdActionsHistory: {
          ...state.sdActionsHistory,
          isFetching: false,
          history: action.payload.history,
          error: null,
        },
      }

    case constants.FETCH_SD_ACTIONS_HISTORY_FAILURE:
      return {
        ...state,
        sdActionsHistory: {
          ...state.sdActionsHistory,
          isFetching: false,
          error: action.payload,
        },
      }

    // FETCH_SD_ACTIONS_HISTORY_COUNT
    case constants.FETCH_SD_ACTIONS_HISTORY_COUNT.REQUEST:
      return {
        ...state,
        sdActionsHistory: {
          ...state.sdActionsHistory,
          id: action.payload.id,
          count:
            state.sdActionsHistory.id === action.payload.id
              ? state.sdActionsHistory.count
              : 0,
          isFetchingCount: true,
          error: null,
        },
      }

    case constants.FETCH_SD_ACTIONS_HISTORY_COUNT.SUCCESS:
      return {
        ...state,
        sdActionsHistory: {
          ...state.sdActionsHistory,
          count: action.payload.count,
          isFetchingCount: false,
          error: null,
        },
      }

    case constants.FETCH_SD_ACTIONS_HISTORY_COUNT.FAILURE:
      return {
        ...state,
        sdActionsHistory: {
          ...state.sdActionsHistory,
          isFetchingCount: false,
          error: action.payload.error,
        },
      }
    //

    case constants.CLEAR_SD_ACTIONS_HISTORY:
      return {
        ...state,
        sdActionsHistory: initialState.sdActionsHistory,
      }

    case constants.TOGGLE_DISABLE_SCRIPTS:
      return {
        ...state,
        disableScriptsForIds: {
          ...state.disableScriptsForIds,
          [`${action.payload.orderId}`]: action.payload.value,
        },
      }

    case constants.CLEAR_ORDERS_LIST:
      return {
        ...state,
        activeServiceCallOrders: initialState.activeServiceCallOrders,
        closedServiceCallOrders: initialState.closedServiceCallOrders,
        canceledServiceCallOrders: initialState.canceledServiceCallOrders,
      }

    case constants.SET_ORDER_RESULT_REPORTING_TYPE:
      return {
        ...state,
        orderResultReportingType: action.payload.type,
        resultReportingTypeOrderId: action.payload.id,
      }

    case constants.SHOULD_SET_ORDER_RESULT_REPORT:
      return {
        ...state,
        shouldSetOrderResultReport: action.payload,
      }

    case constants.GET_SC_DOCUMENT_LINK_REQUEST:
      return {
        ...state,
        getServiceCallDocumentLink: {
          ...state.getServiceCallDocumentLink,
          isFetching: true,
          path: null,
          error: null,
        },
      }

    case constants.GET_SC_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        getServiceCallDocumentLink: {
          ...state.getServiceCallDocumentLink,
          isFetching: false,
          path: action.payload,
        },
      }

    case constants.GET_SC_DOCUMENT_LINK_FAILURE:
      return {
        ...state,
        getServiceCallDocumentLink: {
          ...state.getServiceCallDocumentLink,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_SC_BLANK_DOCUMENT_LINK_REQUEST:
      return {
        ...state,
        getServiceCallBlankDocumentLink: {
          ...state.getServiceCallBlankDocumentLink,
          isFetching: true,
          path: null,
          error: null,
        },
      }

    case constants.GET_SC_BLANK_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        getServiceCallBlankDocumentLink: {
          ...state.getServiceCallBlankDocumentLink,
          isFetching: false,
          path: action.payload,
        },
      }

    case constants.GET_SC_BLANK_DOCUMENT_LINK_FAILURE:
      return {
        ...state,
        getServiceCallBlankDocumentLink: {
          ...state.getServiceCallBlankDocumentLink,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_REQUEST:
      return {
        ...state,
        getServiceCallOrderCertificateLink: {
          ...state.getServiceCallOrderCertificateLink,
          isFetching: true,
          path: null,
          error: null,
        },
      }

    case constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        getServiceCallOrderCertificateLink: {
          ...state.getServiceCallOrderCertificateLink,
          isFetching: false,
          path: action.payload,
        },
      }

    case constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_FAILURE:
      return {
        ...state,
        getServiceCallOrderCertificateLink: {
          ...state.getServiceCallOrderCertificateLink,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_SCO_DOCUMENT_LINK_REQUEST:
      return {
        ...state,
        getServiceCallOrderDocumentLink: {
          ...state.getServiceCallOrderDocumentLink,
          isFetching: true,
          path: null,
          error: null,
        },
      }

    case constants.GET_SCO_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        getServiceCallOrderDocumentLink: {
          ...state.getServiceCallOrderDocumentLink,
          isFetching: false,
          path: action.payload,
        },
      }

    case constants.GET_SCO_DOCUMENT_LINK_FAILURE:
      return {
        ...state,
        getServiceCallOrderDocumentLink: {
          ...state.getServiceCallOrderDocumentLink,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.ATTACH_SC_TAG_REQUEST:
      return {
        ...state,
        serviceCallTagsAttaching: {
          ...state.serviceCallTagsAttaching,
          isFetching: true,
          error: null,
        },
      }

    case constants.ATTACH_SC_TAG_SUCCESS:
      return {
        ...state,
        serviceCallTagsAttaching: {
          ...state.serviceCallTagsAttaching,
          isFetching: false,
        },
      }

    case constants.ATTACH_SC_TAG_FAILURE:
      return {
        ...state,
        serviceCallTagsAttaching: {
          ...state.serviceCallTagsAttaching,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DETACH_SC_TAG_REQUEST:
      return {
        ...state,
        serviceCallTagsDetaching: {
          ...state.serviceCallTagsDetaching,
          isFetching: true,
          error: null,
        },
      }

    case constants.DETACH_SC_TAG_SUCCESS:
      return {
        ...state,
        serviceCallTagsDetaching: {
          ...state.serviceCallTagsDetaching,
          isFetching: false,
        },
      }

    case constants.DETACH_SC_TAG_FAILURE:
      return {
        ...state,
        serviceCallTagsDetaching: {
          ...state.serviceCallTagsDetaching,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default serviceDesk
