import React from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../i18n/i18n'

const OrganizationTypeIcon = props => {
  const {
    type,
    fluid,
    clientOrganization,
    tradeOrganization,
    serviceOrganization,
  } = props
  const fullText =
    type === 't'
      ? i18next.t('organizations_web_atoir:tradeOrganization')
      : type === 'c'
      ? i18next.t('organizations_web_atoir:serviceOrganization')
      : i18next.t('organizations_web_atoir:clientOrganization')
  const shortText = type === 't' ? 'T' : type === 'c' ? 'C' : 'M'
  const isActive =
    (type === 't' && tradeOrganization) ||
    (type === 'm' && clientOrganization) ||
    (type === 'c' && serviceOrganization)
  return (
    <i
      className={`${
        fluid ? 'organization-type-icon-fluid' : 'organization-type-icon'
      } ${isActive ? `${type}-icon-active` : ''}`}
    >
      {fluid ? fullText : shortText}
    </i>
  )
}

OrganizationTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  clientOrganization: PropTypes.bool.isRequired,
  tradeOrganization: PropTypes.bool.isRequired,
  serviceOrganization: PropTypes.bool.isRequired,
}

export default OrganizationTypeIcon
