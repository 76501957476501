import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InnerCallsFilters from './InnerCallsFilters'
import {
  fetchInnerCallsEngineersRequest,
  fetchInnerCallsEngineersCountRequest,
  fetchInnerCallAnalyticsSummaryRequest,
  fetchInnerCallAnalyticsSummaryAverageRequest,
} from '../../../../../actions/innerCallsActions'

const mapStateToProps = state => ({
  isFetchingEngineers: state.innerCalls.innerCallsEngineers.isFetching,
  engineers: state.innerCalls.innerCallsEngineers.engineers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInnerCallsEngineersRequest,
      fetchInnerCallsEngineersCountRequest,
      fetchInnerCallAnalyticsSummaryRequest,
      fetchInnerCallAnalyticsSummaryAverageRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerCallsFilters),
)
