import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  textField: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    width: 200,
  },
})

function PhoneMasked(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      keepCharPositions
      ref={inputRef}
      mask={[
        '+',
        '7',
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

class ContactFormComponent extends React.Component {
  static defaultProps = {
    contact: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      id: props.contact.id,
      fullName: props.contact.full_name,
      position: props.contact.position,
      phone:
        (props.contact.phone && props.contact.phone.length === 11
          ? props.contact.phone.slice(1)
          : props.contact.phone) || '+7',
      // phone: props.contact.phone || '+7',
      phoneAdditional: props.contact.phone_additional
        ? props.contact.phone_additional
        : '',
      email: props.contact.email,
      isNew: props.contact.isNew,
      onChanged: false,
      isValid: props.contact.isValid,
    }
  }

  componentDidMount() {
    this._ismounted = true
  }

  componentWillUnmount() {
    this._ismounted = false
  }

  handleChange = field => event => {
    const { value } = event.target
    switch (field) {
      case 'phone':
        if (value.replace(/[+(\-)\s]/g, '').match(/[^0-9]/g)) {
          return
        }
        this.setState({
          isPhoneValid: value.replace(/[^0-9]/g, '').length === 11,
        })
        break
      case 'phoneAdditional':
        if (value.match(/[^0-9]/g)) {
          return
        }
        this.setState({
          isPhoneAdditionalValid: value.replace(/[0-9]/g, '').length === 0,
        })
        break
      case 'fullName':
        if (value.match(/[`%^/~*&()=+_\-<>@£#$!?|\][.,£#¢∞§¶•ªº0-9]/g)) {
          return
        }
        this.setState({
          isFullNameValid: value.length > 0,
        })
        break
      case 'position':
        if (value.match(/[`^%/~*&()=+_\-<>@£#$!?|\][.,£#¢∞§¶•ªº]/g)) {
          return
        }
        break
      case 'email':
        this.setState({
          isEmailValid: !value || this.validateEmail(value),
        })
        break
      default:
        break
    }
    this[field] = value
    this.setState({ [field]: value, isValid: this.validate() }, () => {
      this.props.handleContactChanged({
        ...this.state,
        onChanged: true,
      })
    })
  }

  validateEmail = email => {
    const re1 = /\S+@\S+\.\S+/
    const re2 = /\.@/
    const re3 = /@\./
    const emailParts = email.split('.')
    return (
      re1.test(email) &&
      emailParts[emailParts.length - 1].length > 1 &&
      !re2.test(email) &&
      !re3.test(email)
    )
  }

  validate = () => {
    const { phoneText, phoneAdditionalText, fullNameText, emailText } = this
    return (
      fullNameText &&
      fullNameText.value.length > 0 &&
      phoneText &&
      phoneText.inputElement.value &&
      phoneText.inputElement.value.replace(/[^0-9]/g, '').length === 11 &&
      (!phoneAdditionalText ||
        (phoneAdditionalText &&
          phoneAdditionalText.value.replace(/[0-9]/g, '').length === 0)) &&
      (!emailText.value || this.validateEmail(emailText.value))
    )
  }

  showError = () => {
    const { phone, fullName, email } = this.state
    this.setState({
      isPhoneValid:
        Boolean(phone) && phone.replace(/[^0-9]/g, '').length === 11,
      isFullNameValid: Boolean(fullName) && fullName.length > 0,
      isEmailValid: !email || this.validateEmail(email),
    })
  }

  render = () => {
    const { classes } = this.props
    const {
      isFullNameValid,
      isPhoneValid,
      isPhoneAdditionalValid,
      isEmailValid,
      fullName,
      position,
      email,
      phone,
      phoneAdditional,
    } = this.state
    return (
      <div>
        <FormControl className={classes.textField}>
          <TextField
            label={i18next.t('organizations_web_atoir:contactFullName')}
            id="full-name"
            required
            value={fullName}
            error={isFullNameValid === false}
            defaultValue={fullName}
            onChange={this.handleChange('fullName')}
            inputRef={el => {
              this.fullNameText = el
            }}
          />
        </FormControl>
        <FormControl className={classes.textField}>
          <TextField
            label={i18next.t('employees_web_atoir:position')}
            id="position"
            value={position}
            onChange={this.handleChange('position')}
            defaultValue={position}
            inputRef={el => {
              this.positionText = el
            }}
          />
        </FormControl>
        <FormControl required className={classes.textField}>
          <InputLabel
            htmlFor="phone"
            shrink={phone && phone.length > 0}
            error={isPhoneValid === false}
          >
            {i18next.t('login_web_atoir:phone')}
          </InputLabel>
          <Input
            error={isPhoneValid === false}
            id="phone"
            value={phone}
            onChange={this.handleChange('phone')}
            defaultValue={phone}
            inputRef={el => {
              this.phoneText = el
            }}
            inputComponent={PhoneMasked}
          />
        </FormControl>
        <FormControl className={classes.textField}>
          <InputLabel
            htmlFor="phoneAdditional"
            error={isPhoneAdditionalValid === false}
          >
            {i18next.t('organizations_web_atoir:additionalPhone')}
          </InputLabel>
          <Input
            error={isPhoneAdditionalValid === false}
            id="phoneAdditional"
            value={phoneAdditional}
            onChange={this.handleChange('phoneAdditional')}
            defaultValue={phoneAdditional}
            inputRef={el => {
              this.phoneAdditionalText = el
            }}
          />
        </FormControl>
        <FormControl className={classes.textField}>
          <TextField
            label={i18next.t('login_web_atoir:email')}
            id="email"
            error={isEmailValid === false}
            value={email}
            onChange={this.handleChange('email')}
            defaultValue={email}
            inputRef={el => {
              this.emailText = el
            }}
          />
        </FormControl>
      </div>
    )
  }
}

ContactFormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleContactChanged: PropTypes.func.isRequired,
  contact: PropTypes.func,
}

export default withStyles(styles)(ContactFormComponent)
