import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'

import NavigationItemSimple from './NavigationItemSimple'
import Sensitive from './Sensitive'
import { withStyles } from '@material-ui/core'
import IconHolder from './IconHolder'

const styles = {}

function NavigationItemNested({
  item,
  classesExternal,
  setCollapsed: setMenuCollapsed,
  collapsed: menuCollapsed,
  noImage,
  classForSet,
  ...otherProps
}) {
  const [collapsed, setCollapsed] = React.useState(true)

  React.useEffect(() => {
    if (menuCollapsed) {
      setCollapsed(true)
    }
  }, [menuCollapsed])

  const onToggle = () => {
    if (menuCollapsed) {
      setMenuCollapsed(false)
    }
    setCollapsed(!collapsed)
  }

  const collapsedByHover = value => {
    if (!value) {
      setCollapsed(false)
    }
    setMenuCollapsed(value)
  }

  return (
    <React.Fragment>
      <Sensitive collapsed={menuCollapsed} setCollapsed={collapsedByHover}>
        <ListItem
          id={item.id}
          button
          onClick={onToggle}
          data-cy={item.id}
          className={classForSet || classesExternal.drawerItem}
        >
          {item.icon && !noImage ? (
            <ListItemIcon>
              <IconHolder>
                {React.createElement(item.icon, { color: 'primary' })}
              </IconHolder>
            </ListItemIcon>
          ) : null}
          <ListItemText
            className={classesExternal.ListItemChild}
            primary={item.text}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'body2',
              style: { fontSize: 16 },
            }}
          />
        </ListItem>
      </Sensitive>
      <Collapse in={!collapsed && !menuCollapsed} timeout="auto" unmountOnExit>
        {item.nested.map(nestedItem => (
          <NavigationItemSimple
            key={nestedItem.id}
            noImage={noImage}
            item={nestedItem}
            classForSet={
              noImage ? classesExternal.nestedNoImage : classesExternal.nested
            }
            classesExternal={classesExternal}
            setCollapsed={setMenuCollapsed}
            variant="body2"
            {...otherProps}
          />
        ))}
      </Collapse>
    </React.Fragment>
  )
}

export default withStyles(styles)(NavigationItemNested)
