import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SelectLimitedFormControl from '../../../../../widgets/SelectLimitedFormControl'
import i18next from '../../../../../i18n/i18n'
import { sortStringify } from '../../../../../helpers/queryHelper'
import { URL } from '../../../../../middlewares/api'
import { getUserFullName } from '../../../../../helpers/userHelper'

const styles = {}

const StuffSelector = ({
  organizationId,
  onChange,
  idValue,
  currentValue,
  error,
}) => {
  const onChangeHandler = value => {
    onChange(idValue, value)
  }

  return (
    <SelectLimitedFormControl
      FormControlProps={{
        required: true,
        fullWidth: true,
        error: Boolean(error),
      }}
      value={currentValue}
      label={i18next.t('shared_web_atoir:responsiblePerson')}
      query={{
        ...sortStringify({
          last_name: 'ASC',
          first_name: 'ASC',
          middle_name: 'ASC',
        }),
        filters: '[state=1]',
      }}
      sourceUrl={`${URL}/organizations/${organizationId}/users`}
      onChange={onChangeHandler}
      requestResultTransformerToArray={data => data.users}
      itemTransformer={row => ({ id: row.id, title: getUserFullName(row) })}
    />
  )
}

StuffSelector.defaultProps = {
  currentValue: null,
  error: '',
}

StuffSelector.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  idValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string,
  error: PropTypes.string,
}

export default withStyles(styles)(StuffSelector)
