import React from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'

import {
  fetchCallHistory,
  fetchCallHistoryCount,
} from '../../../../actions/serviceDesk'

import withTablePagination from '../../../../helpers/tablePaginationHOC'

const mapStateToPropsForHOC = state => ({
  data: state.serviceDesk.history.history,
  isFetching: state.serviceDesk.history.isFetching,
  isFetchingCount: state.serviceDesk.history.isFetchingCount,
  error: state.serviceDesk.history.error,
  errorCount: state.serviceDesk.history.errorFetchingCount,
  count: state.serviceDesk.history.count,
})

const mapDispatchToPropsForHOC = dispatch =>
  bindActionCreators(
    {
      fetchAction: payload => fetchCallHistory(payload),
      fetchCountAction: payload => fetchCallHistoryCount(payload),
    },
    dispatch,
  )

function CallHistoryLoader({
  callId,
  render,
  setRequestPayload,
  ...paginationProps
}) {
  React.useEffect(() => {
    if (callId) {
      setRequestPayload({ id: callId })
    }
  }, [])
  return <React.Fragment>{render({ ...paginationProps })}</React.Fragment>
}

CallHistoryLoader.propTypes = {
  callId: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}

export default withTablePagination(
  CallHistoryLoader,
  mapStateToPropsForHOC,
  mapDispatchToPropsForHOC,
)
