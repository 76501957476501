import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  icon: {
    size: 24,
    marginRight: theme.spacing(1),
  },
  tagName: {
    wordBreak: 'break-all',
  },
  tagButtonContainer: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: theme.typography.pxToRem(24),
  },
  tagButtonBackdrop: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tagButtonPlace: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.3s ease-in,opacity 0.3s ease-in',
  },
  '@global': {
    '#tag-button-backdrop:hover span ': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  deleteBtn: {
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  deleteIcon: {
    fontSize: 18,
  },
})

function Tag(props) {
  const iconStyle = {
    size: 24,
    color: props.color || 'rgba(0, 0, 0, 0.54)',
  }
  return (
    <div>
      <div className={props.classes.tagButtonContainer}>
        <span>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <NotificationImportant style={iconStyle} />
            </Grid>
            <Grid item xs>
              <Typography variant="caption" className={props.classes.tagName}>
                {props.name}
              </Typography>
            </Grid>
          </Grid>
        </span>
        <div
          id="tag-button-backdrop"
          className={props.classes.tagButtonBackdrop}
        >
          <span className={props.classes.tagButtonPlace}>
            <IconButton
              aria-label="Delete"
              className={props.classes.deleteBtn}
              onClick={props.deleteTag}
            >
              <DeleteIcon className={props.classes.deleteIcon} />
            </IconButton>
          </span>
        </div>
      </div>
    </div>
  )
}

Tag.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  deleteTag: PropTypes.func.isRequired,
}

export default withStyles(styles)(Tag)
