import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Heading from '../../../../../../widgets/Lettering/Heading'
import i18next from '../../../../../../i18n/i18n'
import InformationFrom from './InformationForm'

const styles = () => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  infoRoot: {
    padding: 12,
  },
})

class InformationForPerformer extends Component {
  state = {}

  render = () => {
    const {
      props: {
        classes,
        data,
        errors,
        employees,
        equipment,
        managerOrHigher,
        onChangeDateFrom,
        onChangeDateTo,
        onChangeFloor,
        onChangeComment,
        onChangeCabinet,
        onChangeResponsible,
        onChangePhone,
        onChangePosition,
        onChangeLocation,
        organizationId,
      },
    } = this
    const defaultLocation = equipment ? equipment.OrganizationLocation : null
    return (
      <Fragment>
        <div className={classes.heading}>
          <Heading>
            <Typography variant="h6">
              {i18next.t(
                'service_desk_web_atoir:informationForPerformerHeading',
              )}
            </Typography>
          </Heading>
        </div>
        <div className={classes.infoRoot}>
          <InformationFrom
            {...data}
            employees={employees}
            organizationId={organizationId}
            errors={errors}
            defaultLocation={defaultLocation}
            canChangeResponsible={managerOrHigher}
            onChangeCabinet={onChangeCabinet}
            onChangeDateFrom={onChangeDateFrom}
            onChangeDateTo={onChangeDateTo}
            onChangeFloor={onChangeFloor}
            onChangeLocation={onChangeLocation}
            onChangePhone={onChangePhone}
            onChangeComment={onChangeComment}
            onChangePosition={onChangePosition}
            onChangeResponsible={onChangeResponsible}
          />
        </div>
      </Fragment>
    )
  }
}

InformationForPerformer.defaultProps = {
  errors: {},
  equipment: null,
}

InformationForPerformer.propTypes = {
  data: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,
  managerOrHigher: PropTypes.bool.isRequired,
  onChangeCabinet: PropTypes.func.isRequired,
  onChangeDateFrom: PropTypes.func.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
  onChangeFloor: PropTypes.func.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  onChangeResponsible: PropTypes.func.isRequired,
  equipment: PropTypes.object,
  errors: PropTypes.object,
}

export default withStyles(styles)(InformationForPerformer)
