/* eslint-disable import/no-duplicates */
import {
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  FETCH_USER_ORGANIZATIONS_REQUEST,
  FETCH_USER_ORGANIZATIONS_SUCCESS,
  FETCH_USER_ORGANIZATIONS_FAILURE,
  FETCH_NOT_USER_ORGANIZATIONS_REQUEST,
  FETCH_NOT_USER_ORGANIZATIONS_SUCCESS,
  FETCH_NOT_USER_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_PERMISSIONS_SUCCESS,
  FETCH_ORGANIZATION_PERMISSIONS_REQUEST,
  FETCH_ORGANIZATION_PERMISSIONS_FAILURE,
  FETCH_ALL_ORGANIZATIONS_REQUEST,
  FETCH_ALL_ORGANIZATIONS_SUCCESS,
  FETCH_ALL_ORGANIZATIONS_FAILURE,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_SUCCESS,
  FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_FAILURE,
  FETCH_SERVICE_ORGANIZATIONS_REQUEST,
  FETCH_SERVICE_ORGANIZATIONS_SUCCESS,
  FETCH_SERVICE_ORGANIZATIONS_FAILURE,
  FETCH_ADMIN_ORGANIZATIONS_REQUEST,
  FETCH_ADMIN_ORGANIZATIONS_FAILURE,
  FETCH_ADMIN_ORGANIZATIONS_SUCCESS,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS,
  FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST,
  FETCH_ORGANIZATIONS_MEMBER_OF_SUCCESS,
  FETCH_ORGANIZATIONS_MEMBER_OF_FAILURE,
  FETCH_ORGANIZATION_WHO_AM_I_REQUEST,
  FETCH_ORGANIZATION_WHO_AM_I_SUCCESS,
  FETCH_ORGANIZATION_WHO_AM_I_FAILURE,
  FETCH_ORGANIZATION_USER_ME_REQUEST,
  FETCH_ORGANIZATION_USER_ME_SUCCESS,
  FETCH_ORGANIZATION_USER_ME_FAILURE,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_SUCCESS,
  FETCH_SERVICE_ORGANIZATIONS_COUNT_FAILURE,
  FETCH_USER_ORGANIZATIONS_COUNT_REQUEST,
  FETCH_USER_ORGANIZATIONS_COUNT_SUCCESS,
  FETCH_USER_ORGANIZATIONS_COUNT_FAILURE,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS,
  FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE,
  SET_ORGANIZATIONS_LIST_ROWS_PER_PAGE,
  SET_ORGANIZATIONS_EMPLOYERS_LIST_ROWS_PER_PAGE,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_SUCCESS,
  FETCH_USER_ORGANIZATION_FUNCTIONALS_FAILURE,
  CREATE_SD_INVOICE_FOR_ORGANIZATION,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_SUCCESS,
  FETCH_USER_ORGANIZATIONS_CAN_ADD_FAILURE,
} from '../constants/actionTypes'

import * as constants from '../constants/actionTypes'

const initialState = {
  isCreatingOrganization: false,
  isUpdatingOrganization: false,
  isFetchingOrganizations: false,
  isFetchingOrganization: false,
  userStatus: {
    status: {},
    error: null,
    isFetching: false,
  },
  me: {
    isFetching: false,
    me: {},
    error: null,
  },
  organizationPermissions: {
    permissions: {},
    isFetchingOrganizationPermissions: false,
    error: undefined,
  },
  errors: {},
  organizations: [],
  rowsPerPage: 10,
  rowsPerPageEmployers: 10,
  count: null,
  organization: {},
  allOrganizations: {
    isFetchingOrganizations: false,
    organizations: [],
    error: {},
  },
  notUserOrganizations: {
    isFetchingOrganizations: false,
    organizations: [],
    error: {},
  },
  citiesWithServiceOrganizations: {
    isFetchingCities: false,
    cities: [],
    errors: {},
  },
  serviceOrganizations: {
    isFetching: false,
    organizations: [],
    errors: {},
  },
  serviceOrganizationsCount: {
    isFetching: false,
    count: 0,
    errors: {},
  },
  adminOrganizations: {
    isFetching: false,
    organizations: [],
    error: null,
  },
  managerOrHigherOrganizations: {
    isFetching: false,
    organizations: [],
    error: null,
  },
  memberOf: {
    isFetching: false,
    organizations: [],
    member: [],
    error: null,
  },
  hasManagerOrHigherOrganization: {
    error: null,
    isFetching: false,
    has: false,
  },
  organizationFunctionals: {
    errors: {},
    isFetching: false,
    isOrganizationFunctionalsAvailable: false,
    organizationsFunctionals: [],
  },
  createSDInvoiceForOrganization: {
    id: null,
    error: null,
    isFetching: false,
  },
  canAddEquipmentOrganizations: {
    organizations: null,
    error: null,
    isFetching: false,
  },
  editRestrictionSdCallsFromNonContragents: {
    isFetching: false,
    error: null,
  },
  createOrganizationContragent: {
    isFetching: false,
    error: null,
  },

  restrictionSdCallsFromNonContragents: {
    value: null,
    isFetching: false,
    error: null,
  },
  organizationSettings: {
    value: [],
    isFetching: false,
    error: null,
  },
  organizationContragents: {
    value: [],
    isFetching: false,
    error: null,
    count: 0,
  },
  deleteOrganizationContragent: {
    isFetching: false,
    error: null,
  },
  editContragent: {
    isFetching: false,
    error: null,
  },
}

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    // EDIT_ORGANIZATION_CONTRAGENT
    case constants.EDIT_ORGANIZATION_CONTRAGENT.REQUEST:
      return {
        ...state,
        editContragent: {
          ...state.editContragent,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_ORGANIZATION_CONTRAGENT.SUCCESS:
      return {
        ...state,
        editContragent: {
          ...state.editContragent,
          isFetching: false,
          error: null,
        },
      }

    case constants.EDIT_ORGANIZATION_CONTRAGENT.FAILURE:
      return {
        ...state,
        editContragent: {
          ...state.editContragent,
          isFetching: false,
          error: action.code,
        },
      }
    // DELETE_ORGANIZATION_CONTRAGENT
    case constants.DELETE_ORGANIZATION_CONTRAGENT.REQUEST:
      return {
        ...state,
        deleteOrganizationContragent: {
          ...state.deleteOrganizationContragent,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_ORGANIZATION_CONTRAGENT.SUCCESS:
      return {
        ...state,
        deleteOrganizationContragent: {
          ...state.deleteOrganizationContragent,
          isFetching: false,
          error: null,
        },
      }

    case constants.DELETE_ORGANIZATION_CONTRAGENT.FAILURE:
      return {
        ...state,
        deleteOrganizationContragent: {
          ...state.deleteOrganizationContragent,
          isFetching: false,
          error: action.code,
        },
      }
    // GET_ORGANIZATION_CONTRAGENTS
    case constants.GET_ORGANIZATION_CONTRAGENTS.REQUEST:
      return {
        ...state,
        organizationContragents: {
          ...state.organizationContragents,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_ORGANIZATION_CONTRAGENTS.SUCCESS:
      return {
        ...state,
        organizationContragents: {
          ...state.organizationContragents,
          value: action.payload.contragents,
          isFetching: false,
          error: null,
          count: action.payload.count,
        },
      }

    case constants.GET_ORGANIZATION_CONTRAGENTS.FAILURE:
      return {
        ...state,
        organizationContragents: {
          ...state.organizationContragents,
          isFetching: false,
          error: action.code,
        },
      }
    // GET_ORGANIZATION_SETTINGS
    case constants.GET_ORGANIZATION_SETTINGS.REQUEST:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_ORGANIZATION_SETTINGS.SUCCESS:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          value: action.payload.settings,
          isFetching: false,
          error: null,
        },
      }

    case constants.GET_ORGANIZATION_SETTINGS.FAILURE:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          isFetching: false,
          error: action.code,
        },
      }
    // CREATE_ORGANIZATION_CONTRAGENT
    case constants.CREATE_ORGANIZATION_CONTRAGENT.REQUEST:
      return {
        ...state,
        createOrganizationContragent: {
          ...state.createOrganizationContragent,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_ORGANIZATION_CONTRAGENT.SUCCESS:
      return {
        ...state,
        createOrganizationContragent: {
          ...state.createOrganizationContragent,
          isFetching: false,
          error: null,
        },
      }

    case constants.CREATE_ORGANIZATION_CONTRAGENT.FAILURE:
      return {
        ...state,
        createOrganizationContragent: {
          ...state.createOrganizationContragent,
          isFetching: false,
          error: action.code,
        },
      }
    // EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS
    case constants.EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS.REQUEST:
      return {
        ...state,
        editRestrictionSdCallsFromNonContragents: {
          ...state.editRestrictionSdCallsFromNonContragents,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS.SUCCESS:
      return {
        ...state,
        editRestrictionSdCallsFromNonContragents: {
          ...state.editRestrictionSdCallsFromNonContragents,
          isFetching: false,
          error: null,
        },
      }

    case constants.EDIT_RESTRICTION_SD_CALLS_FROM_NON_CONTRAGENTS.FAILURE:
      return {
        ...state,
        editRestrictionSdCallsFromNonContragents: {
          ...state.editRestrictionSdCallsFromNonContragents,
          isFetching: false,
          error: action.code,
        },
      }
    //
    case CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isCreatingOrganization: true,
      }

    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isCreatingOrganization: false,
        errors: {},
      }

    case CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        isCreatingOrganization: false,
        errors: action.errors,
      }

    case FETCH_USER_ORGANIZATIONS_CAN_ADD_REQUEST:
      return {
        ...state,
        canAddEquipmentOrganizations: {
          organizations: null,
          error: null,
          isFetching: false,
        },
      }

    case FETCH_USER_ORGANIZATIONS_CAN_ADD_SUCCESS:
      return {
        ...state,
        canAddEquipmentOrganizations: {
          organizations: action.payload,
          error: null,
          isFetching: false,
        },
      }

    case FETCH_USER_ORGANIZATIONS_CAN_ADD_FAILURE:
      return {
        ...state,
        canAddEquipmentOrganizations: {
          organizations: null,
          error: action.payload,
          isFetching: false,
        },
      }

    case UPDATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isUpdatingOrganization: true,
      }

    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isUpdatingOrganization: false,
        errors: {},
      }

    case UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        isUpdatingOrganization: false,
        error: action.code,
      }
    case FETCH_ORGANIZATIONS_MEMBER_OF_REQUEST:
      return {
        ...state,
        memberOf: {
          ...state.memberOf,
          isFetching: true,
          organizations: [],
          member: [],
          error: null,
        },
      }

    case FETCH_ORGANIZATIONS_MEMBER_OF_SUCCESS:
      return {
        ...state,
        memberOf: {
          ...state.memberOf,
          isFetching: false,
          organizations: action.payload.organizations,
          member: action.payload.member,
        },
      }

    case FETCH_ORGANIZATIONS_MEMBER_OF_FAILURE:
      return {
        ...state,
        memberOf: {
          ...state.memberOf,
          isFetching: false,
          error: action.error,
        },
      }

    case FETCH_ADMIN_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        adminOrganizations: {
          ...state.adminOrganizations,
          isFetching: true,
          error: null,
        },
      }

    case FETCH_ADMIN_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        adminOrganizations: {
          ...state.adminOrganizations,
          isFetching: false,
          organizations: action.data,
        },
      }

    case FETCH_ADMIN_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        adminOrganizations: {
          ...state.adminOrganizations,
          isFetching: false,
          error: action.code,
        },
      }

    case FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        managerOrHigherOrganizations: {
          ...state.managerOrHigherOrganizations,
          isFetching: true,
          error: null,
        },
      }

    case FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        managerOrHigherOrganizations: {
          ...state.managerOrHigherOrganizations,
          isFetching: false,
          organizations: action.data,
        },
      }

    case FETCH_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        managerOrHigherOrganizations: {
          ...state.managerOrHigherOrganizations,
          isFetching: false,
          error: action.code,
        },
      }

    case FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        hasManagerOrHigherOrganization: {
          ...state.hasManagerOrHigherOrganization,
          isFetching: true,
          has: false,
        },
      }

    case FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        hasManagerOrHigherOrganization: {
          ...state.hasManagerOrHigherOrganization,
          isFetching: false,
          has: action.payload,
        },
      }

    case FETCH_HAS_MANAGER_OR_HIGHER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        hasManagerOrHigherOrganization: {
          ...state.hasManagerOrHigherOrganization,
          isFetching: false,
          error: action.code,
        },
      }

    case FETCH_USER_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        isFetchingOrganizations: true,
      }

    case FETCH_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isFetchingOrganizations: false,
        organizations: action.data,
        errors: {},
      }

    case FETCH_USER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        isFetchingOrganizations: false,
        errors: action.errors,
      }
    case FETCH_USER_ORGANIZATIONS_COUNT_REQUEST:
      return {
        ...state,
        isFetchingOrganizationsCount: true,
      }

    case FETCH_USER_ORGANIZATIONS_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingOrganizationsCount: false,
        count: action.count,
        errors: {},
      }

    case FETCH_USER_ORGANIZATIONS_COUNT_FAILURE:
      return {
        ...state,
        isFetchingOrganizationsCount: false,
        errors: action.errors,
      }

    case FETCH_NOT_USER_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        notUserOrganizations: {
          ...state.notUserOrganizations,
          isFetchingOrganizations: true,
          error: {},
        },
      }

    case FETCH_NOT_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        notUserOrganizations: {
          ...state.notUserOrganizations,
          isFetchingOrganizations: false,
          organizations: action.data,
        },
      }

    case FETCH_NOT_USER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        notUserOrganizations: {
          ...state.notUserOrganizations,
          isFetchingOrganizations: false,
          error: action.errors,
        },
      }

    case FETCH_ORGANIZATION_REQUEST:
      return {
        ...state,
        isFetchingOrganization: true,
        errors: {},
      }

    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isFetchingOrganization: false,
        organization: action.data,
        errors: {},
      }

    case FETCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        isFetchingOrganization: false,
        errors: action.errors,
      }

    case FETCH_ORGANIZATION_WHO_AM_I_REQUEST:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isFetching: true,
          status: {},
          error: null,
        },
      }

    case FETCH_ORGANIZATION_WHO_AM_I_SUCCESS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isFetching: false,
          status: action.data,
        },
      }

    case FETCH_ORGANIZATION_WHO_AM_I_FAILURE:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isFetching: false,
          error: action.error,
        },
      }

    case FETCH_ORGANIZATION_USER_ME_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          isFetching: true,
          me: {},
          error: null,
        },
      }

    case FETCH_ORGANIZATION_USER_ME_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          isFetching: false,
          me: action.data,
        },
      }

    case FETCH_ORGANIZATION_USER_ME_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          isFetching: false,
          error: action.error,
        },
      }

    case FETCH_ORGANIZATION_PERMISSIONS_REQUEST:
      return {
        ...state,
        organizationPermissions: {
          error: undefined,
          isFetchingOrganizationPermissions: true,
        },
      }

    case FETCH_ORGANIZATION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchingOrganizationPermissions: false,
        organizationPermissions: {
          ...state.organizationPermissions,
          isFetchingOrganizationPermissions: false,
          permissions: action.data,
          error: undefined,
        },
      }

    case FETCH_ORGANIZATION_PERMISSIONS_FAILURE:
      return {
        ...state,
        organizationPermissions: {
          ...state.organizationPermissions,
          error: action.code,
          isFetchingOrganizationPermissions: false,
        },
      }

    case FETCH_ALL_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        allOrganizations: {
          isFetchingOrganizations: true,
          error: undefined,
        },
      }

    case FETCH_ALL_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        allOrganizations: {
          ...state.allOrganizations,
          isFetchingOrganizations: false,
          organizations: action.organizations,
          error: undefined,
        },
      }

    case FETCH_ALL_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        allOrganizations: {
          ...state.allOrganizations,
          isFetchingOrganizations: false,
          error: action.errors,
        },
      }

    case FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        citiesWithServiceOrganizations: {
          ...state.citiesWithServiceOrganizations,
          isFetchingCities: true,
          errors: {},
        },
      }

    case FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        citiesWithServiceOrganizations: {
          ...state.citiesWithServiceOrganizations,
          isFetchingCities: false,
          cities: action.cities,
        },
      }

    case FETCH_CITIES_WITH_SERVICE_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        citiesWithServiceOrganizations: {
          ...state.citiesWithServiceOrganizations,
          isFetchingCities: false,
          errors: action.errors,
        },
      }

    case FETCH_SERVICE_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        serviceOrganizations: {
          ...state.serviceOrganizations,
          isFetching: true,
          errors: {},
        },
      }

    case FETCH_SERVICE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        serviceOrganizations: {
          ...state.serviceOrganizations,
          isFetching: false,
          organizations: action.serviceOrganizations,
        },
      }

    case FETCH_SERVICE_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        serviceOrganizations: {
          ...state.serviceOrganizations,
          isFetching: false,
          errors: action.errors,
        },
      }

    case FETCH_SERVICE_ORGANIZATIONS_COUNT_REQUEST:
      return {
        ...state,
        serviceOrganizationsCount: {
          ...state.serviceOrganizationsCount,
          isFetching: true,
          errors: {},
        },
      }

    case FETCH_SERVICE_ORGANIZATIONS_COUNT_SUCCESS:
      return {
        ...state,
        serviceOrganizationsCount: {
          ...state.serviceOrganizationsCount,
          isFetching: false,
          count: action.count,
        },
      }

    case FETCH_SERVICE_ORGANIZATIONS_COUNT_FAILURE:
      return {
        ...state,
        serviceOrganizationsCount: {
          ...state.serviceOrganizationsCount,
          isFetching: false,
          errors: action.errors,
        },
      }

    case SET_ORGANIZATIONS_LIST_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.data,
      }

    case SET_ORGANIZATIONS_EMPLOYERS_LIST_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPageEmployers: action.data,
      }

    case FETCH_USER_ORGANIZATION_FUNCTIONALS_REQUEST:
      return {
        ...state,
        organizationFunctionals: {
          ...state.organizationFunctionals,
          isFetching: true,
          errors: {},
        },
      }

    case FETCH_USER_ORGANIZATION_FUNCTIONALS_SUCCESS:
      return {
        ...state,
        organizationFunctionals: {
          ...state.organizationFunctionals,
          isFetching: false,
          isOrganizationFunctionalsAvailable: action.data.isFuncAvailable,
          organizationsFunctionals: action.data.payload,
          errors: {},
        },
      }

    case FETCH_USER_ORGANIZATION_FUNCTIONALS_FAILURE:
      return {
        ...state,
        organizationFunctionals: {
          ...state.organizationFunctionals,
          isFetching: false,
          errors: action.errors,
        },
      }

    // CREATE_SD_INVOICE_FOR_ORGANIZATION
    case CREATE_SD_INVOICE_FOR_ORGANIZATION.REQUEST:
      return {
        ...state,
        createSDInvoiceForOrganization: {
          ...state.createSDInvoiceForOrganization,
          id: action.data.id,
          isFetching: true,
          error: null,
        },
      }

    case CREATE_SD_INVOICE_FOR_ORGANIZATION.SUCCESS:
      return {
        ...state,
        createSDInvoiceForOrganization: {
          ...state.createSDInvoiceForOrganization,
          isFetching: false,
          error: null,
        },
      }

    case CREATE_SD_INVOICE_FOR_ORGANIZATION.FAILURE:
      return {
        ...state,
        createSDInvoiceForOrganization: {
          ...state.createSDInvoiceForOrganization,
          isFetching: false,
          error: action.data.error,
        },
      }

    default:
      return state
  }
}

export default organizationsReducer
