import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from '../../../../i18n/i18n'
import FormControlSelect from '../../../../widgets/Controlls/FormControlSelect'
import FormControlText from '../../../../widgets/Controlls/FormControlText'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import { popLastSections } from '../../../../helpers/dataHelper'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import { canViewContractInfo } from '../../../../helpers/ContractPermissionsResolver'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'

const styles = () => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
})

class AgreementCreation extends Component {
  state = {
    type: null,
    name: '',
    errors: {},
  }

  componentDidMount() {
    this.props.getTitleInfo({
      title: i18n.t('service_desk_web_atoir:agreementCreation'),
    })
    const {
      props: {
        fetchContractPermissionsRequest,
        match: {
          params: { contractId },
        },
      },
    } = this
    fetchContractPermissionsRequest(contractId)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching &&
      !this.props.error
    ) {
      this.props.history.push(
        `${popLastSections(this.props.match.url)}/${
          this.props.createdAgreementId
        }`,
      )
    }
    if (
      prevProps.isFetchingPermissions !== this.props.isFetchingPermissions &&
      !this.props.isFetchingPermissions &&
      !this.props.permissionsError
    ) {
      if (canViewContractInfo(this.props.permissions)) {
        this.fetchContractInfo()
      } else {
        this.props.history.push('/404')
      }
    }
  }

  fetchContractInfo = () => {
    const {
      props: {
        fetchContractRequest,
        match: {
          params: { contractId },
        },
      },
    } = this
    fetchContractRequest(contractId)
  }

  onChange = field => ({ target: { value } }) => {
    this.setState(state => ({
      errors: {
        ...state.errors,
        [field]: null,
      },
      [field]: value,
    }))
  }

  validate = () => {
    const { type, name } = this.state
    const errors = {}
    if (!type) {
      errors.type = i18n.t('service_desk_web_atoir:agreementTypeMustBeProvided')
    }
    if (!name) {
      errors.name = i18n.t('service_desk_web_atoir:agreementNameMustBeProvided')
    }
    return errors
  }

  create = () => {
    const {
      state: { name, type },
      props: {
        createAgreementRequest,
        match: {
          params: { contractId },
        },
      },
      validate,
    } = this
    const errors = validate()
    if (_.keys(errors).length) {
      this.setState({
        errors,
      })
      return
    }
    createAgreementRequest({
      id: contractId,
      data: { name, agreement_type: type },
    })
  }

  render() {
    const {
      state: { type, name, errors },
      props: {
        contract,
        history,
        match: { url },
      },
      onChange,
      create,
    } = this
    return (
      <Grid container spacing={2}>
        {contract && (
          <BreadCrumb to={popLastSections(url, 2)}>
            {i18n.t('service_desk_web_atoir:contractProfileTitle', {
              number: contract.num_contract,
              from: `${contract.month_begin}.${contract.year_begin}`,
              to: `${contract.month_end}.${contract.year_end}`,
            })}
          </BreadCrumb>
        )}
        <BreadCrumb to={url}>
          {i18n.t('service_desk_web_atoir:agreementCreation')}
        </BreadCrumb>
        <Grid item xs={6}>
          <FormControlSelect
            inputLabelProps={{ required: true, error: !!errors.type }}
            label={i18n.t('service_desk_web_atoir:type')}
            value={type}
            error={!!errors.type}
            helperText={errors.type}
            onChange={onChange('type')}
            values={[
              {
                value: 'add',
                content: i18n.t('service_desk_web_atoir:addAgreementType'),
              },
              {
                value: 'del',
                content: i18n.t('service_desk_web_atoir:delAgreementType'),
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlText
            helperText={errors.name}
            required
            error={errors.name}
            label={i18n.t('service_desk_web_atoir:agreementName')}
            value={name}
            onChange={onChange('name')}
          />
        </Grid>
        <Grid style={{ display: 'flex' }} justify="space-between" item xs={12}>
          <OutlinedButton
            onClick={() => {
              history.push(popLastSections(url, 2))
            }}
          >
            {i18n.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton onClick={create}>
            {i18n.t('shared_web_atoir:save')}
          </ContainedButton>
        </Grid>
      </Grid>
    )
  }
}

AgreementCreation.propTypes = {}

export default withStyles(styles)(AgreementCreation)
