import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AgreementAddEquipmentTable from './AgreementAddEquipmentTable'
import {
  fetchContractEquipmentsCountRequest,
  fetchContractEquipmentsRequest,
  addAgreementEquipmentRequest,
} from '../../../../../actions/serviceDeskContractsActions'
import {
  fetchContractAvailableEquipmentRequest,
  fetchServiceDeskContractAvailableEquipmentCountRequest,
} from '../../../../../actions/serviceDesk'

const mapStateToProps = state => ({
  isDeletingEquipment:
    state.serviceDeskContracts.deleteAgreementEquipment.isFetching,
  deleteEquipmentError:
    state.serviceDeskContracts.deleteAgreementEquipment.error,
  availableEquipment: state.serviceDesk.contract_available_equipment.equipment,
  availableEquipmentIsFetching:
    state.serviceDesk.contract_available_equipment.isFetching,
  availableEquipmentFetchingError:
    state.serviceDesk.contract_available_equipment.error,
  availableEquipmentCount:
    state.serviceDesk.contract_available_equipment_count.count,
  availableEquipmentCountIsFetching:
    state.serviceDesk.contract_available_equipment_count.isFetching,
  availableEquipmentCountFetchingError:
    state.serviceDesk.contract_available_equipment_count.error,
  contractEquipment: state.serviceDeskContracts.equipments.equipments,
  contractEquipmentIsFetching: state.serviceDeskContracts.equipments.isFetching,
  contractEquipmentFetchingError: state.serviceDeskContracts.equipments.error,
  contractEquipmentCount: state.serviceDeskContracts.equipmentCount.count,
  contractEquipmentCountIsFetching:
    state.serviceDeskContracts.equipmentCount.isFetching,
  contractEquipmentCountFetchingError:
    state.serviceDeskContracts.equipmentCount.error,
  isAddingEquipmentToAgreement:
    state.serviceDeskContracts.addAgreementEquipments.isFetching,
  addingEquipmentToAgreementError:
    state.serviceDeskContracts.addAgreementEquipments.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAvailableEquipment: fetchContractAvailableEquipmentRequest,
      fetchAvailableEquipmentCount: fetchServiceDeskContractAvailableEquipmentCountRequest,
      fetchContractEquipment: fetchContractEquipmentsRequest,
      fetchContractEquipmentCount: fetchContractEquipmentsCountRequest,
      addAgreementEquipmentRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementAddEquipmentTable),
)
