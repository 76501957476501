import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import StaffComponent from '../../components/Staff/StaffComponent'
import {
  deleteStaff,
  fetchContacts,
  fetchContactsCount,
} from '../../actions/staffActions'
import { fetchAdminOrganizations } from '../../actions/organizationsActions'

const mapStateToProps = state => ({
  contacts: state.staff.contacts,
  isFetchingContacts: state.staff.isFetchingContacts,
  count: state.staff.contactsCount,
  contactError: state.staff.contactError,
  isDeletingStaff: state.staff.isDeletingStaff,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAdminOrganizations,
      fetchContacts,
      fetchContactsCount,
      deleteStaff,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StaffComponent),
)
