import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  FETCH_EMPLOYERS_REQUEST,
  LEAVE_ORGANIZATION_REQUEST,
  CONFIRM_JOIN_ORGANIZATION_REQUEST,
  FETCH_EMPLOYERS_COUNT_REQUEST,
} from '../constants/actionTypes'
import {
  fetchEmployersRequest,
  leaveOrganizationRequest,
  confirmJoinOrganizationRequest,
  fetchEmployersCountRequest,
} from './api'
import {
  fetchEmployersSuccess,
  fetchEmployersFailure,
  leaveOrganizationSuccess,
  leaveOrganizationFailure,
  confirmJoinOrganizationSuccess,
  confirmJoinOrganizationFailure,
  fetchEmployersCountSuccess,
  fetchEmployersCountFailure,
} from '../actions/employersActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'

export function* watchFetchEmployersSaga() {
  yield takeLatest(FETCH_EMPLOYERS_REQUEST, fetchEmployers)
}

export function* watchFetchEmployersCountSaga() {
  yield takeLatest(FETCH_EMPLOYERS_COUNT_REQUEST, fetchEmployersCount)
}

export function* watchLeaveOrganizationSaga() {
  yield takeLatest(LEAVE_ORGANIZATION_REQUEST, leaveOrganization)
}

export function* watchConfirmJoinOrganizationSaga() {
  yield takeLatest(CONFIRM_JOIN_ORGANIZATION_REQUEST, confirmJoinOrganization)
}

function* fetchEmployers(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchEmployersRequest(accessToken, action.data))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchEmployersSuccess(result.member))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEmployersFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEmployersCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchEmployersCountRequest(accessToken, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchEmployersCountSuccess(result.organization_user_count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEmployersCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* leaveOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(leaveOrganizationRequest(accessToken, action.id))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(leaveOrganizationSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(leaveOrganizationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* confirmJoinOrganization(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      confirmJoinOrganizationRequest(accessToken, action.hash),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(confirmJoinOrganizationSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(confirmJoinOrganizationFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
