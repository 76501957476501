import {
  FETCH_INVENTORY_PROJECT_PROFILE_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_REQUEST,
  FETCH_INVENTORY_PROJECT_PROFILE_SUCCESS,
  FETCH_INVENTORY_PROJECT_PROFILE_DEFAULT,
  CREATE_INVENTORY_PROJECT_REQUEST,
  CREATE_INVENTORY_PROJECT_SUCCESS,
  CREATE_INVENTORY_PROJECT_FAILURE,
  FETCH_INVENTORY_PROJECTS_REQUEST,
  FETCH_INVENTORY_PROJECTS_SUCCESS,
  FETCH_INVENTORY_PROJECTS_FAILURE,
  INVENTORY_PROJECTS_COUNT_REQUEST,
  INVENTORY_PROJECTS_COUNT_SUCCESS,
  INVENTORY_PROJECTS_COUNT_FAILURE,
  EDIT_INVENTORY_PROJECT_REQUEST,
  EDIT_INVENTORY_PROJECT_FAILURE,
  EDIT_INVENTORY_PROJECT_SUCCESS,
  PUT_UP_ON_AUCTION_REQUEST,
  PUT_UP_ON_AUCTION_SUCCESS,
  PUT_UP_ON_AUCTION_FAILURE,
  INVENTORY_PROJECTS_CITIES_REQUEST,
  INVENTORY_PROJECTS_CITIES_SUCCESS,
  INVENTORY_PROJECTS_CITIES_FAILURE,
  GET_INVENTORY_CLIENTS_REQUEST,
  GET_INVENTORY_CLIENTS_SUCCESS,
  GET_INVENTORY_CLIENTS_FAILURE,
  GET_INVENTORY_PERFORMERS_REQUEST,
  GET_INVENTORY_PERFORMERS_SUCCESS,
  GET_INVENTORY_PERFORMERS_FAILURE,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_SUCCESS,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_FAILURE,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_SUCCESS,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_FAILURE,
  SET_OWN_PERFORMER_REQUEST,
  SET_OWN_PERFORMER_SUCCESS,
  SET_OWN_PERFORMER_FAILURE,
  SET_FOREIGN_PERFORMER_REQUEST,
  SET_FOREIGN_PERFORMER_SUCCESS,
  SET_FOREIGN_PERFORMER_FAILURE,
  OFFER_INVENTORY_PROJECT_REQUEST,
  OFFER_INVENTORY_PROJECT_SUCCESS,
  OFFER_INVENTORY_PROJECT_FAILURE,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_SUCCESS,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_FAILURE,
  FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST,
  FETCH_INVENTORY_OFFERS_PERFORMER_SUCCESS,
  FETCH_INVENTORY_OFFERS_PERFORMER_FAILURE,
  FETCH_INVENTORY_OFFERS_CLIENT_REQUEST,
  FETCH_INVENTORY_OFFERS_CLIENT_SUCCESS,
  FETCH_INVENTORY_OFFERS_CLIENT_FAILURE,
  STOP_INVENTORY_PROJECT_AUCTION_REQUEST,
  STOP_INVENTORY_PROJECT_AUCTION_SUCCESS,
  STOP_INVENTORY_PROJECT_AUCTION_FAILURE,
  CANCEL_INVENTORY_PROJECT_REQUEST,
  CANCEL_INVENTORY_PROJECT_SUCCESS,
  CANCEL_INVENTORY_PROJECT_FAILURE,
  SET_INVENTORY_PROJECT_DONE_REQUEST,
  SET_INVENTORY_PROJECT_DONE_SUCCESS,
  SET_INVENTORY_PROJECT_DONE_FAILURE,
  SET_INVENTORY_PROJECT_CLOSED_REQUEST,
  SET_INVENTORY_PROJECT_CLOSED_SUCCESS,
  SET_INVENTORY_PROJECT_CLOSED_FAILURE,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_SUCCESS,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_FAILURE,
  CHANGE_PERFORMER_RATING_REQUEST,
  CHANGE_PERFORMER_RATING_SUCCESS,
  CHANGE_PERFORMER_RATING_FAILURE,
  CHANGE_CLIENT_RATING_REQUEST,
  CHANGE_CLIENT_RATING_SUCCESS,
  CHANGE_CLIENT_RATING_FAILURE,
  CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST,
  CONFIRM_OFFER_INVENTORY_PROJECT_SUCCESS,
  CONFIRM_OFFER_INVENTORY_PROJECT_FAILURE,
  CANCEL_OFFER_INVENTORY_PROJECT_REQUEST,
  CANCEL_OFFER_INVENTORY_PROJECT_SUCCESS,
  CANCEL_OFFER_INVENTORY_PROJECT_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_SUCCESS,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_FAILURE,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_DEFAULT,
  FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  FETCH_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  FETCH_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  EDIT_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  EDIT_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_SUCCESS,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_FAILURE,
  FETCH_ORDERS_INVENTORY_PROJECT_REQUEST,
  FETCH_ORDERS_INVENTORY_PROJECT_SUCCESS,
  FETCH_ORDERS_INVENTORY_PROJECT_FAILURE,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_SUCCESS,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_FAILURE,
  REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST,
  REMOVE_ORDERS_INVENTORY_PROJECT_SUCCESS,
  REMOVE_ORDERS_INVENTORY_PROJECT_FAILURE,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_SUCCESS,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_FAILURE,
  FETCH_HISTORY_INVENTORY_PROJECT_REQUEST,
  FETCH_HISTORY_INVENTORY_PROJECT_SUCCESS,
  FETCH_HISTORY_INVENTORY_PROJECT_FAILURE,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_SUCCESS,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_FAILURE,
} from '../constants/actionTypes'

export const fetchInventoryProjectProfile = projectId => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_REQUEST,
  projectId,
})

export const fetchInventoryProjectProfileSuccess = project => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_SUCCESS,
  project,
})

export const fetchInventoryProjectProfileFailure = code => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_FAILURE,
  code,
})

export const fetchInventoryProjectProfileDefault = () => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_DEFAULT,
})

export const fetchInventoryProjectProfileRights = projectId => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST,
  projectId,
})

export const fetchInventoryProjectProfileRightsSuccess = permissions => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_SUCCESS,
  permissions,
})

export const fetchInventoryProjectProfileRightsFailure = code => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_FAILURE,
  code,
})

export const fetchInventoryProjectProfileRightsDefault = () => ({
  type: FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_DEFAULT,
})

export const createInventoryProject = (id, data) => ({
  type: CREATE_INVENTORY_PROJECT_REQUEST,
  id,
  data,
})

export const createInventoryProjectSuccess = () => ({
  type: CREATE_INVENTORY_PROJECT_SUCCESS,
})

export const createInventoryProjectFailure = errors => ({
  type: CREATE_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const editInventoryProject = (id, projectId, data) => ({
  type: EDIT_INVENTORY_PROJECT_REQUEST,
  id,
  projectId,
  data,
})

export const editInventoryProjectSuccess = () => ({
  type: EDIT_INVENTORY_PROJECT_SUCCESS,
})

export const editInventoryProjectFailure = errors => ({
  type: EDIT_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchInventoryProjects = (requestor, status, filters) => ({
  type: FETCH_INVENTORY_PROJECTS_REQUEST,
  requestor,
  status,
  filters,
})

export const fetchInventoryProjectsSuccess = data => ({
  type: FETCH_INVENTORY_PROJECTS_SUCCESS,
  data,
})

export const fetchInventoryProjectsFailure = errors => ({
  type: FETCH_INVENTORY_PROJECTS_FAILURE,
  errors,
})

export const putUpOnAuction = (organizationId, projectId, payload) => ({
  type: PUT_UP_ON_AUCTION_REQUEST,
  organizationId,
  projectId,
  payload,
})

export const putUpOnAuctionSuccess = data => ({
  type: PUT_UP_ON_AUCTION_SUCCESS,
  data,
})

export const putUpOnAuctionFailure = errors => ({
  type: PUT_UP_ON_AUCTION_FAILURE,
  errors,
})

export const fetchInventoryProjectsCount = (requestor, status, filters) => ({
  type: INVENTORY_PROJECTS_COUNT_REQUEST,
  requestor,
  status,
  filters,
})

export const fetchInventoryProjectsCountSuccess = count => ({
  type: INVENTORY_PROJECTS_COUNT_SUCCESS,
  count,
})

export const fetchInventoryProjectsCountFailure = errors => ({
  type: INVENTORY_PROJECTS_COUNT_FAILURE,
  errors,
})

export const fetchInventoryProjectsCities = () => ({
  type: INVENTORY_PROJECTS_CITIES_REQUEST,
})

export const fetchInventoryProjectsCitiesSuccess = data => ({
  type: INVENTORY_PROJECTS_CITIES_SUCCESS,
  data,
})

export const fetchInventoryProjectsCitiesFailure = errors => ({
  type: INVENTORY_PROJECTS_CITIES_FAILURE,
  errors,
})

export const getInventoryClients = (status, requestor) => ({
  type: GET_INVENTORY_CLIENTS_REQUEST,
  status,
  requestor,
})

export const getInventoryClientsSuccess = clients => ({
  type: GET_INVENTORY_CLIENTS_SUCCESS,
  clients,
})

export const getInventoryClientsFailure = errors => ({
  type: GET_INVENTORY_CLIENTS_FAILURE,
  errors,
})

export const getInventoryPerformers = (status, requestor) => ({
  type: GET_INVENTORY_PERFORMERS_REQUEST,
  status,
  requestor,
})

export const getInventoryPerformersSuccess = performers => ({
  type: GET_INVENTORY_PERFORMERS_SUCCESS,
  performers,
})

export const getInventoryPerformersFailure = errors => ({
  type: GET_INVENTORY_PERFORMERS_FAILURE,
  errors,
})

export const confirmInventoryProjectAsPerformer = (
  organizationId,
  projectId,
  data,
) => ({
  type: INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST,
  organizationId,
  projectId,
  data,
})

export const confirmInventoryProjectAsPerformerSuccess = () => ({
  type: INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_SUCCESS,
})

export const confirmInventoryProjectAsPerformerFailure = errors => ({
  type: INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_FAILURE,
  errors,
})

export const rejectInventoryProjectAsPerformer = (
  organizationId,
  projectId,
  data,
) => ({
  type: INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST,
  organizationId,
  projectId,
  data,
})

export const rejectInventoryProjectAsPerformerSuccess = () => ({
  type: INVENTORY_PROJECT_REJECT_AS_PERFORMER_SUCCESS,
})

export const rejectInventoryProjectAsPerformerFailure = errors => ({
  type: INVENTORY_PROJECT_REJECT_AS_PERFORMER_FAILURE,
  errors,
})

export const setOwnPerformer = (orgId, projId, data) => ({
  type: SET_OWN_PERFORMER_REQUEST,
  orgId,
  projId,
  data,
})

export const setOwnPerformerSuccess = () => ({
  type: SET_OWN_PERFORMER_SUCCESS,
})

export const setOwnPerformerFailure = errors => ({
  type: SET_OWN_PERFORMER_FAILURE,
  errors,
})

export const setForeignPerformer = (orgId, projId, data) => ({
  type: SET_FOREIGN_PERFORMER_REQUEST,
  orgId,
  projId,
  data,
})

export const setForeignPerformerSuccess = () => ({
  type: SET_FOREIGN_PERFORMER_SUCCESS,
})

export const setForeignPerformerFailure = errors => ({
  type: SET_FOREIGN_PERFORMER_FAILURE,
  errors,
})

export const offerInventoryProject = (orgId, projId, data) => ({
  type: OFFER_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
  data,
})

export const offerInventoryProjectSuccess = () => ({
  type: OFFER_INVENTORY_PROJECT_SUCCESS,
})

export const offerInventoryProjectFailure = errors => ({
  type: OFFER_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const editInventoryProjectResponsible = (projectId, data) => ({
  type: EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST,
  projectId,
  data,
})

export const editInventoryProjectResponsibleSuccess = () => ({
  type: EDIT_INVENTORY_PROJECT_RESPONSIBLE_SUCCESS,
})

export const editInventoryProjectResponsibleFailure = errors => ({
  type: EDIT_INVENTORY_PROJECT_RESPONSIBLE_FAILURE,
  errors,
})

export const fetchInventoryOffersPerformer = (
  orgId,
  projId,
  params,
  actionDelay,
) => ({
  type: FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST,
  orgId,
  projId,
  params,
  actionDelay,
})

export const fetchInventoryOffersPerformerSuccess = data => ({
  type: FETCH_INVENTORY_OFFERS_PERFORMER_SUCCESS,
  data,
})

export const fetchInventoryOffersPerformerFailure = errors => ({
  type: FETCH_INVENTORY_OFFERS_PERFORMER_FAILURE,
  errors,
})

export const fetchInventoryOffersClient = (
  orgId,
  projId,
  params,
  actionDelay,
) => ({
  type: FETCH_INVENTORY_OFFERS_CLIENT_REQUEST,
  orgId,
  projId,
  params,
  actionDelay,
})

export const fetchInventoryOffersClientSuccess = data => ({
  type: FETCH_INVENTORY_OFFERS_CLIENT_SUCCESS,
  data,
})

export const fetchInventoryOffersClientFailure = errors => ({
  type: FETCH_INVENTORY_OFFERS_CLIENT_FAILURE,
  errors,
})

export const stopInventoryProjectAuction = (orgId, projId) => ({
  type: STOP_INVENTORY_PROJECT_AUCTION_REQUEST,
  orgId,
  projId,
})

export const stopInventoryProjectAuctionSuccess = () => ({
  type: STOP_INVENTORY_PROJECT_AUCTION_SUCCESS,
})

export const stopInventoryProjectAuctionFailure = errors => ({
  type: STOP_INVENTORY_PROJECT_AUCTION_FAILURE,
  errors,
})

export const cancelInventoryProject = (orgId, projId) => ({
  type: CANCEL_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
})

export const cancelInventoryProjectSuccess = () => ({
  type: CANCEL_INVENTORY_PROJECT_SUCCESS,
})

export const cancelInventoryProjectFailure = errors => ({
  type: CANCEL_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const setInventoryProjectDone = projectId => ({
  type: SET_INVENTORY_PROJECT_DONE_REQUEST,
  projectId,
})

export const setInventoryProjectDoneSuccess = () => ({
  type: SET_INVENTORY_PROJECT_DONE_SUCCESS,
})

export const setInventoryProjectDoneFailure = errors => ({
  type: SET_INVENTORY_PROJECT_DONE_FAILURE,
  errors,
})

export const setInventoryProjectClosed = (projectId, data) => ({
  type: SET_INVENTORY_PROJECT_CLOSED_REQUEST,
  projectId,
  data,
})

export const setInventoryProjectClosedSuccess = () => ({
  type: SET_INVENTORY_PROJECT_CLOSED_SUCCESS,
})

export const setInventoryProjectClosedFailure = errors => ({
  type: SET_INVENTORY_PROJECT_CLOSED_FAILURE,
  errors,
})

export const setInventoryProjectBackInWork = (projectId, data) => ({
  type: SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST,
  projectId,
  data,
})

export const setInventoryProjectBackInWorkSuccess = () => ({
  type: SET_INVENTORY_PROJECT_BACK_IN_WORK_SUCCESS,
})

export const setInventoryProjectBackInWorkFailure = errors => ({
  type: SET_INVENTORY_PROJECT_BACK_IN_WORK_FAILURE,
  errors,
})

export const changePerformerRating = (projectId, data) => ({
  type: CHANGE_PERFORMER_RATING_REQUEST,
  projectId,
  data,
})

export const changePerformerRatingSuccess = () => ({
  type: CHANGE_PERFORMER_RATING_SUCCESS,
})

export const changePerformerRatingFailure = errors => ({
  type: CHANGE_PERFORMER_RATING_FAILURE,
  errors,
})

export const changeClientRating = (projectId, data) => ({
  type: CHANGE_CLIENT_RATING_REQUEST,
  projectId,
  data,
})

export const changeClientRatingSuccess = () => ({
  type: CHANGE_CLIENT_RATING_SUCCESS,
})

export const changeClientRatingFailure = errors => ({
  type: CHANGE_CLIENT_RATING_FAILURE,
  errors,
})

export const confirmOfferInventoryProject = (orgId, projId, offerId) => ({
  type: CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
  offerId,
})

export const confirmOfferInventoryProjectSuccess = () => ({
  type: CONFIRM_OFFER_INVENTORY_PROJECT_SUCCESS,
})

export const confirmOfferInventoryProjectFailure = errors => ({
  type: CONFIRM_OFFER_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const cancelOfferInventoryProject = (orgId, projId, offerId) => ({
  type: CANCEL_OFFER_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
  offerId,
})

export const cancelOfferInventoryProjectSuccess = () => ({
  type: CANCEL_OFFER_INVENTORY_PROJECT_SUCCESS,
})

export const cancelOfferInventoryProjectFailure = errors => ({
  type: CANCEL_OFFER_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchEngineersInventory = (
  orgId,
  projId,
  params,
  actionDelay,
) => ({
  type: FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
  params,
  actionDelay,
})

export const fetchEngineersInventorySuccess = data => ({
  type: FETCH_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
  data,
})

export const fetchEngineersInventoryFailure = errors => ({
  type: FETCH_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const editEngineersInventory = (orgId, projId, data, params) => ({
  type: EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
  data,
  params,
})

export const editEngineersInventorySuccess = () => ({
  type: EDIT_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
})

export const editEngineersInventoryFailure = errors => ({
  type: EDIT_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchFiltersOrdersInventory = projId => ({
  type: FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST,
  projId,
})

export const fetchFiltersOrdersInventorySuccess = data => ({
  type: FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_SUCCESS,
  data,
})

export const fetchFiltersOrdersInventoryFailure = errors => ({
  type: FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchOrdersInventory = (projId, params) => ({
  type: FETCH_ORDERS_INVENTORY_PROJECT_REQUEST,
  projId,
  params,
})

export const fetchOrdersInventorySuccess = data => ({
  type: FETCH_ORDERS_INVENTORY_PROJECT_SUCCESS,
  data,
})

export const fetchOrdersInventoryFailure = errors => ({
  type: FETCH_ORDERS_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchOrdersCountInventory = (projId, params) => ({
  type: FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST,
  projId,
  params,
})

export const fetchOrdersCountInventorySuccess = data => ({
  type: FETCH_ORDERS_COUNT_INVENTORY_PROJECT_SUCCESS,
  data,
})

export const fetchOrdersCountInventoryFailure = errors => ({
  type: FETCH_ORDERS_COUNT_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const removeOrderInventory = (projId, orderId) => ({
  type: REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST,
  projId,
  orderId,
})

export const removeOrderInventorySuccess = () => ({
  type: REMOVE_ORDERS_INVENTORY_PROJECT_SUCCESS,
})

export const removeOrderInventoryFailure = errors => ({
  type: REMOVE_ORDERS_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const removeEngineerInventory = (orgId, projId, engineerId) => ({
  type: REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  orgId,
  projId,
  engineerId,
})

export const removeEngineersInventorySuccess = () => ({
  type: REMOVE_ENGINEERS_INVENTORY_PROJECT_SUCCESS,
})

export const removeEngineersInventoryFailure = errors => ({
  type: REMOVE_ENGINEERS_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const editEngineerOrderInventory = (projId, orderId, data) => ({
  type: EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST,
  projId,
  orderId,
  data,
})

export const editEngineerOrderInventorySuccess = () => ({
  type: EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_SUCCESS,
})

export const editEngineerOrderInventoryFailure = errors => ({
  type: EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchHistoryInventory = (projId, params) => ({
  type: FETCH_HISTORY_INVENTORY_PROJECT_REQUEST,
  projId,
  params,
})

export const fetchHistoryInventorySuccess = data => ({
  type: FETCH_HISTORY_INVENTORY_PROJECT_SUCCESS,
  data,
})

export const fetchHistoryInventoryFailure = errors => ({
  type: FETCH_HISTORY_INVENTORY_PROJECT_FAILURE,
  errors,
})

export const fetchHistoryCountInventory = (projId, params) => ({
  type: FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST,
  projId,
  params,
})

export const fetchHistoryCountInventorySuccess = data => ({
  type: FETCH_HISTORY_COUNT_INVENTORY_PROJECT_SUCCESS,
  data,
})

export const fetchHistoryCountInventoryFailure = errors => ({
  type: FETCH_HISTORY_COUNT_INVENTORY_PROJECT_FAILURE,
  errors,
})
