import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import i18next from '../../../../i18n/i18n'
import UUIDInput from '../../../../widgets/Controlls/UUIDInput'
import FormControlText from '../../../../widgets/Controlls/FormControlText'
import ApplyButton from '../../../../widgets/Buttons/ApplyButton'
import withStyles from '@material-ui/core/styles/withStyles'
import SelectLimitedFromControl from '../../../../widgets/SelectLimitedFormControl'
import { URL } from '../../../../middlewares/api'

const styles = () => ({
  applyButtonRoot: {
    display: 'block',
    margin: '0 auto',
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  applyButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})

class OrganizationFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      city: props.city ? props.city : null,
      organizationName: null,
      organizationId: null,
    }
  }

  handleCitySelect = city => {
    this.setState({ city })
  }

  onChange = field => event => {
    this.setState({ [field]: event.target.value })
  }

  render() {
    const {
      props: { onSubmit, repairMode },
      state: { name, organizationId, city },
      onChange,
    } = this
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          const query = { ...this.state, city_id: this.state.city }
          delete query.city
          onSubmit(query)
        }}
      >
        <Grid container spacing={2} alignContent="center" alignItems="center">
          {!repairMode && (
            <>
              <Grid item xs={3}>
                <SelectLimitedFromControl
                  firstElement={{
                    id: null,
                    title: i18next.t('shared_web_atoir:All'),
                  }}
                  FormControlProps={{
                    fullWidth: true,
                  }}
                  label={i18next.t('shared_web_atoir:cityFull')}
                  value={city}
                  sourceUrl={`${URL}/inventory_projects/cities_with_service_organizations`}
                  query={{ sort: '[name=ASC]' }}
                  onChange={this.handleCitySelect}
                  requestResultTransformerToArray={r => r.cities}
                  itemTransformer={o => ({ id: o.id, title: o.name })}
                />
              </Grid>
              <Grid item xs={9} />
              <Grid item xs={5}>
                <UUIDInput
                  label={i18next.t('shared_web_atoir:organizationIdInSystem')}
                  onChange={onChange('organizationId')}
                  value={organizationId}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <FormControlText
              onChange={onChange('name')}
              label={i18next.t('shared_web_atoir:organizationName')}
              value={name}
            />
          </Grid>
          <Grid
            xs
            container
            item
            justify="flex-end"
            alignItems="center"
            alignContent="center"
          >
            <ApplyButton type="submit">
              {i18next.t('shared_web_atoir:formatList')}
            </ApplyButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

OrganizationFilters.defaultProps = {
  city: null,
  repairMode: false,
}

OrganizationFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  city: PropTypes.string,
  repairMode: PropTypes.bool,
}

export default withStyles(styles)(OrganizationFilters)
