import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AgreementEquipmentTable from './AgreementEquipmentTable'
import {
  deleteAgreementEquipmentsRequest,
  fetchAgreementEquipmentsCountRequest,
  fetchAgreementEquipmentsRequest,
} from '../../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  availableEquipment: state.serviceDeskContracts.agreementEquipment.equipments,
  availableEquipmentIsFetching:
    state.serviceDeskContracts.agreementEquipment.isFetching,
  availableEquipmentFetchingError:
    state.serviceDeskContracts.agreementEquipment.error,
  isDeletingEquipment:
    state.serviceDeskContracts.deleteAgreementEquipment.isFetching,
  deleteEquipmentError:
    state.serviceDeskContracts.deleteAgreementEquipment.error,
  equipmentCount: state.serviceDeskContracts.agreementEquipmentCount.count,
  equipmentCountIsFetching:
    state.serviceDeskContracts.agreementEquipmentCount.isFetching,
  equipmentCountFetchingError:
    state.serviceDeskContracts.agreementEquipmentCount.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteAgreementEquipmentsRequest,
      fetchAgreementEquipmentsRequest,
      fetchAgreementEquipmentsCountRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementEquipmentTable),
)
