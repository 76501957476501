import React, { Component, Fragment } from 'react'
import i18n from '../../../../../i18n/i18n'
import TextButton from '../../../../../widgets/Buttons/TextButton'
import {
  canCancelAgreement,
  canViewAgreementInfo,
} from '../../../../../helpers/ContractPermissionsResolver'
import { popLastSections } from '../../../../../helpers/dataHelper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import RightTips from '../../../Contracts/Widgets/RightTips'
import UpScrollButton from '../../../../../widgets/Buttons/UpScrollButton'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import AgreementAddEquipmentTable from '../AgreementAddEquipmentTable'
import BreadCrumb from '../../../../App/routing/BreadCrumb'

const styles = () => ({
  addEquipmentSubheading: {
    marginTop: 42,
  },
  middleText: {
    marginBottom: 12,
    fontSize: 12,
  },
  topText: {
    marginBottom: 8,
    fontSize: 12,
  },
})

const TipCard = withRouter(
  withStyles(styles)(({ classes, history, match: { url } }) => (
    <Card className={classes.card}>
      <CardActionArea
        onClick={() => {
          history.push(popLastSections(url))
        }}
      >
        <CardContent>
          <Typography component="p" className={classes.topText}>
            {i18n.t('service_desk_web_atoir:deleteEquipment')}
          </Typography>
          <Typography
            component="p"
            className={classes.middleText}
            color="textSecondary"
          >
            {i18n.t('service_desk_web_atoir:deleteAgreementEquipmentTip')}
          </Typography>
          <Typography component="p">
            {i18n.t('service_desk_web_atoir:toProfile')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )),
)

class AgreementAddEquipment extends Component {
  componentDidMount() {
    this.props.fetchContractPermissionsRequest(
      this.props.match.params.contractId,
    )
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching &&
      !this.props.error
    ) {
      this.props.getTitleInfo({
        title: (
          <Fragment>
            {i18n.t('service_desk_web_atoir:addingEquipmentToAgreement')}
          </Fragment>
        ),
        OuterButton: canCancelAgreement(
          this.props.permissions,
          this.props.agreement.status,
        ) ? (
          <TextButton
            onClick={() =>
              this.props.history.push(popLastSections(this.props.match.url))
            }
          >
            {i18n.t('shared_web_atoir:close')}
          </TextButton>
        ) : null,
      })
    }
    if (
      prevProps.isFetchingPermissions !== this.props.isFetchingPermissions &&
      !this.props.isFetchingPermissions &&
      !this.props.permissionsError
    ) {
      if (canViewAgreementInfo(this.props.permissions)) {
        this.props.fetchAgreementRequest({ ...this.props.match.params })
      } else {
        this.props.history.push('/404')
      }
    }
  }

  render() {
    const {
      props: {
        classes,
        agreement,
        permissions,
        match: { url },
      },
    } = this
    return (
      <div>
        {agreement && (
          <Fragment>
            <BreadCrumb to={popLastSections(url, 3)}>
              {i18n.t('service_desk_web_atoir:contractProfileTitle', {
                number: agreement.contract.num_contract,
                from: `${agreement.contract.month_begin}.${agreement.contract.year_begin}`,
                to: `${agreement.contract.month_end}.${agreement.contract.year_end}`,
              })}
            </BreadCrumb>
            <BreadCrumb to={popLastSections(url)}>{agreement.name}</BreadCrumb>
            <BreadCrumb to={url}>
              {i18n.t('service_desk_web_atoir:addingEquipmentToAgreement')}
            </BreadCrumb>
          </Fragment>
        )}
        <UpScrollButton />
        <RightTips>
          <TipCard />
        </RightTips>
        {agreement && (
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="subheading" color="textSecondary">
                {i18n.t('service_desk_web_atoir:tableCellName')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subheading">{agreement.name}</Typography>
            </Grid>
          </Grid>
        )}
        <Typography
          gutterBottom
          className={classes.addEquipmentSubheading}
          variant="subheading"
        >
          {i18n.t('service_desk_web_atoir:addEquipmentToAgreement')}
        </Typography>
        {agreement && (
          <AgreementAddEquipmentTable
            permissions={permissions}
            agreement={agreement}
          />
        )}
      </div>
    )
  }
}

AgreementAddEquipment.propTypes = {}

export default withStyles(styles)(AgreementAddEquipment)
