import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BreadCrumb from '../../App/routing/BreadCrumb'
import isEmpty from 'is-empty'
import _ from 'lodash'
import shortId from 'shortid'
import { popLastSections } from '../../../helpers/dataHelper'
import * as permissionsResolvers from '../../../helpers/callPermissionsResolver'
import { URL } from '../../../middlewares/api'

// material UI
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom'
import Snackbar from '@material-ui/core/Snackbar'

// components
import SuborderWorkCompletionReport from './SuborderWorkCompletionReport'
import OrderItemsForm from './OrderItemsForm'
import Loader from '../../Loader'
import AddCompletionFile from './AddCompletionFile'
import SnackbarElContent from '../../../widgets/SnackbarElContent'
import Dialog from '../../Other/SimpleDialog'
import CommonAddition from './CommonAddition'

// buttons
import OutlinedButton from '../../../widgets/Buttons/OutlinedButton'
import TextButton from '../../../widgets/Buttons/TextButton'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'

// i18n
import i18next from '../../../i18n/i18n'

const getErrorText = error => {
  let errorText = i18next.t(`error_web_atoir:${error}`)
  if (`${error}` === errorText)
    errorText = i18next.t('error_web_atoir:unexpectableError')
  return errorText
}

const styles = theme => ({
  label: {
    fontWeight: 'normal',
    fontSize: 12,
  },
  text: {
    fontWeight: 'normal',
    fontSize: 16,
  },
  testBack: {
    backgroundColor: 'blue',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
  },
  sectionAction: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
  textFieldMulty: {
    width: '500px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  toolboxFont: { fontSize: 14 },
  root: {
    '&$checked': {
      color: theme.palette.blueSecondary.main,
    },
  },
  checked: {},
  buttonsPannel: {
    backgroundColor: theme.palette.blueSecondary.transparent,
    padding: theme.spacing(1),
  },
  iconButton: {
    color: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  spaceBottom: {
    marginBottom: theme.spacing(2),
  },
  centered: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ndsInput: {
    width: 50,
  },
  additionInput: {
    width: 100,
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
})

class WorkCompletionReport extends Component {
  constructor(props) {
    super(props)
    this.refOrderItemsForm = React.createRef()
    this.inputFileRef = React.createRef()
    this.state = {
      orderPermissions: null,
      itemsDiagnostic: null,
      itemsNeedRepair: null,
      nds: 0,
      inputWithND: 0,
      overhead: 0,
      commonAddition: 0,
      anchorElFilesMenu: null,
      anchorElPrintedFormMenu: null,
      openSnackbar: false,
      snackbarVariant: 'default',
      snackbarMessage: '',
      unsavedDataWarningDialogMessage: '',
      openUnsavedDataDialog: false,
      openDeleteDialog: false,
      openConfirmChangeDataToPerformerDialog: false,
      openSaveDataDialog: false,
      openSendToClientDialog: false,
      openConfirmCommonAdditionDialog: false,
      openInfoDialog: false,
      openSetToControlConfirmDialog: false,
      openSetToDoneConfirmDialog: false,
    }
  }

  componentDidMount() {
    const {
      props: {
        match: { params },
        fetchCallPermissions,
        setNdsCheckboxValue,
        setInputNdsCheckboxValue,
        setOverheadCheckboxValue,
      },
    } = this
    fetchCallPermissions(params.idRequest)
    this.updateNdsBox = _.once(p => setNdsCheckboxValue(p))
    this.updateIncomeNdsBox = _.once(p => setInputNdsCheckboxValue(p))
    this.updateOverheadBox = _.once(p => setOverheadCheckboxValue(p))
    this.updateNds = _.once(p => this.setState(p))
    this.updateIncomeNds = _.once(p => this.setState(p))
    this.updateOverhead = _.once(p => this.setState(p))
  }

  orderUpdate = () => {
    const {
      props: {
        match: { params },
        fetchOrder,
      },
    } = this
    fetchOrder({ id: params.id, params: '' })
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        match: { params },
        fetchCallPermissions,
        fetchOrder,
        fetchServiceCallOrderItemsRequest,
        isFetchingPermissions,
        permissions,
        isFetchingOrder,
        isFetchingIncomeNds,
        isFetchingOverhead,
        order,
        fetchServiceCallSuborderItemsRequest,
        fetchServiceCallSuborderItemsCountRequest,
        isSavingOrderItems,
        savedOrderItemsSuccessfully,
        fetchServiceCallOrderItemsCountRequest,
        isFetchingOrderItems,
        orderItems,
        isClearingOrderItems,
        clearedOrderItemsSuccessfully,
        isFetchingFileDelete,
        isConfirmingCertificateOfCompletion,
        confirmCertificateOfCompletionSuccess,
        confirmCertificateOfCompletionError,
        isCopyingItemsFromChild,
        fetchOrderNdsRequest,
        fetchOrderIncomeNdsRequest,
        fetchOrderOverheadRequest,
        isFetchingNds,
        nds,
        inputWithNDS,
        overhead,
        isUpdatingNds,
        isUpdatingIncomeNds,
        isUpdatingOverhead,
      },
      state: { orderPermissions, openUnsavedDataDialog },
      updateNdsBox,
      updateIncomeNdsBox,
      updateOverheadBox,
    } = this
    if (
      prevProps.isFetchingPermissions !== isFetchingPermissions &&
      !isFetchingPermissions
    ) {
      const orderStatusPermissions =
        !isEmpty(permissions) && !isEmpty(permissions.orders)
          ? permissions.orders.find(_order => _order.id === params.id)
          : {}
      const orderStatePermissions = !isEmpty(orderStatusPermissions)
        ? orderStatusPermissions.permissions
        : {}
      this.setState({ orderPermissions: orderStatePermissions })
      fetchOrder({ id: params.id, params: '' })
      fetchOrderNdsRequest({ orderId: params.id })
      fetchOrderIncomeNdsRequest({ orderId: params.id })
      fetchOrderOverheadRequest({ orderId: params.id })
      fetchServiceCallOrderItemsRequest({ id: params.id })
      fetchServiceCallOrderItemsCountRequest({ id: params.id })
    }
    if (prevProps.isUpdatingNds !== isUpdatingNds && !isUpdatingNds) {
      fetchOrderNdsRequest({ orderId: params.id })
    }
    if (
      prevProps.isUpdatingIncomeNds !== isUpdatingIncomeNds &&
      !isUpdatingIncomeNds
    ) {
      fetchOrderIncomeNdsRequest({ orderId: params.id })
    }
    if (
      prevProps.isUpdatingOverhead !== isUpdatingOverhead &&
      !isUpdatingOverhead
    ) {
      fetchOrderOverheadRequest({ orderId: params.id })
    }
    if (
      prevProps.isFetchingOrderItems !== isFetchingOrderItems &&
      !isFetchingOrderItems
    ) {
      if (!isEmpty(orderItems)) {
        this.setState({
          itemsDiagnostic: orderItems.itemsDiagnostic,
          itemsNeedRepair: orderItems.itemsNeedRepair,
        })
      }
    }
    if (prevProps.isFetchingNds !== isFetchingNds && !isFetchingNds) {
      this.updateNds({ nds })
      if (nds) {
        updateNdsBox(!!nds)
      }
    }
    if (
      prevProps.isFetchingIncomeNds !== isFetchingIncomeNds &&
      !isFetchingIncomeNds
    ) {
      this.updateIncomeNds({ inputWithNDS })
      if (inputWithNDS) {
        updateIncomeNdsBox(!!inputWithNDS)
      }
    }
    if (
      prevProps.isFetchingOverhead !== isFetchingOverhead &&
      !isFetchingOverhead
    ) {
      this.updateOverhead({ overhead })
      if (overhead) {
        updateOverheadBox(!!overhead)
      }
    }
    if (
      prevProps.isFetchingFileDelete !== isFetchingFileDelete &&
      !isFetchingFileDelete
    ) {
      fetchCallPermissions(params.idRequest)
    }
    if (prevProps.isFetchingOrder !== isFetchingOrder && !isFetchingOrder) {
      if (!isEmpty(order)) {
        this.props.getTitleInfo({
          OuterButton: ((order.type_order === 'repair' &&
            _.find(
              order.ServiceCallOrderFile,
              f => f.file_type === 'act_repair',
            )) ||
            (order.type_order === 'diagnostic' &&
              order.order_result === 'complete' &&
              _.find(
                order.ServiceCallOrderFile,
                f => f.file_type === 'act_repair',
              )) ||
            (order.type_order === 'diagnostic' &&
              order.order_result === 'needrepair' &&
              _.find(
                order.ServiceCallOrderFile,
                f => f.file_type === 'act_diagnostic',
              ))) && (
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                {!!orderPermissions &&
                  permissionsResolvers.canOrderSendToDone(
                    order.status,
                    orderPermissions,
                  ) && (
                    <OutlinedButton
                      onClick={() =>
                        this.handleDialogOpen('openSetToDoneConfirmDialog')
                      }
                    >
                      {i18next.t('service_desk_web_atoir:sendToClient')}
                    </OutlinedButton>
                  )}
              </Grid>
              <Grid item>
                {!!orderPermissions &&
                  permissionsResolvers.canOrderSendToControl(
                    order.status,
                    orderPermissions,
                  ) && (
                    <OutlinedButton
                      onClick={() =>
                        this.handleDialogOpen('openSetToControlConfirmDialog')
                      }
                    >
                      {i18next.t('service_desk_web_atoir:sendToControl')}
                    </OutlinedButton>
                  )}
              </Grid>
            </Grid>
          ),
        })
        this.setState({ nds: order.nds_performer })
        if (order.child_service_call_order_id !== null) {
          fetchServiceCallSuborderItemsRequest({
            id: order.child_service_call_order_id,
          })
          fetchServiceCallSuborderItemsCountRequest({
            id: order.child_service_call_order_id,
          })
        }
      }
    }
    if (
      prevProps.isSavingOrderItems !== isSavingOrderItems &&
      !isSavingOrderItems
    ) {
      if (savedOrderItemsSuccessfully) {
        this.openSnackbar(
          'success',
          i18next.t('service_desk_web_atoir:formSaved'),
        )
        fetchServiceCallOrderItemsRequest({ id: params.id })
        fetchServiceCallOrderItemsCountRequest({ id: params.id })
      } else {
        this.openSnackbar(
          'error',
          i18next.t('service_desk_web_atoir:failedToSaveForm'),
        )
      }
      if (openUnsavedDataDialog) this.handleDialogClose('openUnsavedDataDialog')
    }
    if (
      prevProps.isClearingOrderItems !== isClearingOrderItems &&
      !isClearingOrderItems
    ) {
      if (clearedOrderItemsSuccessfully) {
        this.handleDialogClose('openDeleteDialog')
        fetchServiceCallOrderItemsRequest({ id: params.id })
        fetchServiceCallOrderItemsCountRequest({ id: params.id })
      }
    }
    if (
      prevProps.isConfirmingCertificateOfCompletion !==
        isConfirmingCertificateOfCompletion &&
      !isConfirmingCertificateOfCompletion
    ) {
      if (confirmCertificateOfCompletionSuccess) {
        this.handleRedirectToCallProfile()
      } else {
        this.openSnackbar(
          'error',
          getErrorText(confirmCertificateOfCompletionError),
        )
      }
    }
    if (
      prevProps.isCopyingItemsFromChild !== isCopyingItemsFromChild &&
      !isCopyingItemsFromChild
    ) {
      this.handleDialogClose('openConfirmChangeDataToPerformerDialog')
      fetchCallPermissions(params.idRequest)
    }
  }

  handleRedirectToCallProfile = () => {
    const {
      props: { history, match },
    } = this
    history.push(popLastSections(match.url, 3))
  }

  handleDialogOpen = fieldName => {
    this.setState({ [fieldName]: true })
  }

  handleUnsavedDataDialogOpen = (fieldName, message) => {
    this.setState({
      [fieldName]: true,
      unsavedDataWarningDialogMessage: message,
    })
  }

  handleDialogClose = fieldName => {
    // may be opened more than one dialog sometime, so that code below
    const {
      openUnsavedDataDialog,
      openDeleteDialog,
      openConfirmChangeDataToPerformerDialog,
      openSaveDataDialog,
      openSendToClientDialog,
      openConfirmCommonAdditionDialog,
      openInfoDialog,
      openSetToControlConfirmDialog,
      openSetToDoneConfirmDialog,
      unsavedDataWarningDialogMessage,
    } = this.state
    const openArr = _.compact([
      openUnsavedDataDialog,
      openDeleteDialog,
      openConfirmChangeDataToPerformerDialog,
      openSaveDataDialog,
      openSendToClientDialog,
      openConfirmCommonAdditionDialog,
      openInfoDialog,
      openSetToControlConfirmDialog,
      openSetToDoneConfirmDialog,
    ])

    // permit twice bind change
    if (this.state[fieldName]) {
      this.setState({
        [fieldName]: false,
        unsavedDataWarningDialogMessage:
          openArr.length === 1 ? '' : unsavedDataWarningDialogMessage,
      })
    }
  }

  handleCheckbox = event => {
    const {
      target: { checked, value },
    } = event
    const {
      props: {
        match: { params },
        updateOrderNdsRequest,
        updateOrderOverheadRequest,
        updateOrderIncomeNdsRequest,
        setNdsCheckboxValue,
        setOverheadCheckboxValue,
        setInputNdsCheckboxValue,
      },
      state: { nds, overhead, inputWithNDS },
      refOrderItemsForm,
    } = this
    if (value === 'ndsChecked') {
      setNdsCheckboxValue(checked)
      updateOrderNdsRequest({
        orderId: params.id,
        data: { nds: checked ? Number(nds) : 0 },
      })
    } else if (value === 'overheadChecked') {
      setOverheadCheckboxValue(checked)
      // TODO recalculation of prices
      updateOrderOverheadRequest({
        orderId: params.id,
        data: { overhead: checked ? Number(overhead) : 0 },
      })
    } else {
      // TODO recalculation of prices
      setInputNdsCheckboxValue(checked)
      const newData = refOrderItemsForm.current.incomeNdsChanged(
        inputWithNDS,
        checked ? Number(inputWithNDS) : 0,
        'checkbox',
      )
      const diagnostic = _.filter(
        newData,
        row => row.update !== 'delete' && row.result_type === 'diagnostic',
      )
      const longTerm = _.filter(
        newData,
        row => row.update !== 'delete' && row.result_type === 'longtermrepair',
      )
      this.setState({
        itemsDiagnostic: diagnostic,
        itemsNeedRepair: longTerm,
      })
      updateOrderIncomeNdsRequest({
        orderId: params.id,
        data: { nds: checked ? Number(inputWithNDS) : 0 },
      })
    }
  }

  handleTextChange = name => event => {
    const {
      state: { inputWithND },
      props: {
        match: { params },
        updateOrderNdsRequest,
        ndsChecked,
        overheadChecked,
        inputWithNDSChecked,
        updateOrderOverheadRequest,
        updateOrderIncomeNdsRequest,
      },
      refOrderItemsForm,
    } = this
    const { value } = event.target
    console.log('INPUT', value)
    if (name === 'nds' || name === 'inputWithNDS' || name === 'overhead') {
      const nds = value
      const ndsRegExp = /^[0-9\b]+$/
      if (nds !== '' && ndsRegExp.test(nds) && nds.length <= 2) {
        this.setState({ [name]: nds }, () => {
          if (name === 'nds' && ndsChecked) {
            updateOrderNdsRequest({
              orderId: params.id,
              data: { nds: Number(nds) },
            })
          } else if (name === 'inputWithNDS' && inputWithNDSChecked) {
            const newData = refOrderItemsForm.current.incomeNdsChanged(
              inputWithND,
              inputWithNDSChecked ? Number(nds) : 0,
              'number',
            )
            const diagnostic = _.filter(
              newData,
              row =>
                row.update !== 'delete' && row.result_type === 'diagnostic',
            )
            const longTerm = _.filter(
              newData,
              row =>
                row.update !== 'delete' && row.result_type === 'longtermrepair',
            )
            this.setState({
              itemsDiagnostic: diagnostic,
              itemsNeedRepair: longTerm,
            })
            updateOrderIncomeNdsRequest({
              orderId: params.id,
              data: { nds: Number(nds) },
            })
          } else if (name === 'overhead' && overheadChecked) {
            updateOrderOverheadRequest({
              orderId: params.id,
              data: { overhead: Number(nds) },
            })
          }
        })
      } else if (nds === '') {
        this.setState({ [name]: nds }, () => {
          if (name === 'nds' && ndsChecked) {
            updateOrderNdsRequest({
              orderId: params.id,
              data: { nds: Number(nds) },
            })
          } else if (name === 'inputWithNDSChecked' && inputWithNDSChecked) {
            updateOrderIncomeNdsRequest({
              orderId: params.id,
              data: { nds: Number(nds) },
            })
          } else if (name === 'overhead' && overheadChecked) {
            updateOrderOverheadRequest({
              orderId: params.id,
              data: { overhead: Number(nds) },
            })
          }
        })
      }
    } else if (name === 'commonAddition') {
      if (value !== '') {
        this.setState(state => ({
          commonAddition:
            parseFloat(value) > 999.99 ? state.commonAddition : value,
        }))
      } else if (value === '') {
        this.setState({ [name]: value })
      }
    } else {
      this.setState({ [name]: value })
    }
  }

  handleCommonAdditionApply = () => {
    const {
      state: { commonAddition },
    } = this
    this.refOrderItemsForm.current.setCommonPercent(commonAddition)
    this.setState({ openConfirmCommonAdditionDialog: false })
  }

  showFilesMenu = event => {
    this.setState({ anchorElFilesMenu: event.currentTarget })
  }

  showPrintedFormMenu = event => {
    const {
      props: {
        getOrderPossibleReports,
        match: { params },
      },
    } = this
    getOrderPossibleReports({ orderId: params.id })
    this.setState({ anchorElPrintedFormMenu: event.currentTarget })
  }

  onToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  openSnackbar = (variant, message) => {
    this.setState({
      openSnackbar: true,
      snackbarVariant: variant,
      snackbarMessage: message,
    })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      openSnackbar: false,
      snackbarVariant: 'default',
      snackbarMessage: '',
    })
  }

  setDataFromPerformer = () => {
    const {
      props: {
        match: { params },
        copyOrderItemsFromChildRequest,
      },
    } = this
    copyOrderItemsFromChildRequest({ order_id: params.id })
  }

  clearOrderItemsList = () => {
    const {
      props: {
        match: { params },
        clearServiceCallOrderItemsListRequest,
      },
    } = this
    clearServiceCallOrderItemsListRequest({
      order_id: params.id,
    })
  }

  showPrintedForm = () => {
    const validateFormForViewPdf = this.refOrderItemsForm.current.validateForUnsavedData()
    if (validateFormForViewPdf.success) {
      // TODO: handle showing pdf
      console.log('Should be handled for redirecting to show PDF screen')
    } else {
      this.handleUnsavedDataDialogOpen(
        'openUnsavedDataDialog',
        i18next.t(
          'service_desk_web_atoir:beforeShowingPrintedFormYouShouldSaveChanges',
        ),
      )
    }
  }

  CommentsAttachmentsUrlCreator = file => {
    const {
      props: {
        match: { params },
        order,
      },
    } = this

    const url = `/api/downloads/service_desc/${new Date(
      order.ServiceCall.createdAt,
    ).getFullYear()}/${params.idRequest}/orders/${params.id}/files/${
      file.id
    }.${file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)}`
    return url
  }

  fileDownload = file => {
    const { downloadFileRequest } = this.props

    downloadFileRequest({
      id: shortId.generate(),
      url: this.CommentsAttachmentsUrlCreator(file),
      name: file.name,
    })
  }

  saveOrderItemsList = () => {
    const {
      props: {
        match: { params },
        saveServiceCallOrderItemsListRequest,
      },
    } = this
    const validateFormForSave = this.refOrderItemsForm.current.validateForSave()
    if (validateFormForSave.success) {
      const newItems = _.map(validateFormForSave.payload.newItems, newItm =>
        _.omitBy(
          {
            name: newItm.name,
            item_type: newItm.item_type,
            item_category: newItm.item_category,
            oem: newItm.oem,
            units: newItm.units,
            count: newItm.count === null ? null : Number(newItm.count),
            price_purchase:
              newItm.price_purchase === null
                ? null
                : Number(newItm.price_purchase),
            result_type: newItm.result_type,
            percent: newItm.percent === null ? null : Number(newItm.percent),
          },
          e => e === null || e === '',
        ),
      )
      const updatedItems = _.map(
        validateFormForSave.payload.updatedItems,
        updItm => ({
          name: updItm.name,
          item_type: updItm.item_type,
          item_category: updItm.item_category,
          oem: updItm.oem,
          units: updItm.units,
          delta: updItm.delta,
          count: updItm.count === null ? null : Number(updItm.count),
          price_purchase:
            updItm.price_purchase === null || updItm.price_purchase === ''
              ? null
              : Number(updItm.price_purchase),
          result_type: updItm.result_type,
          ba_equipment_part_id: updItm.ba_equipment_part_id,
          ba_equipment_part_variant_id: updItm.ba_equipment_part_variant_id,
          percent: updItm.percent === null ? 0 : Number(updItm.percent),
          id: updItm.id,
        }),
      )
      const deletedItems = validateFormForSave.payload.deletedItems.map(
        delItm => ({ id: delItm.id }),
      )
      saveServiceCallOrderItemsListRequest({
        order_id: params.id,
        new_items: newItems,
        updated_items: updatedItems,
        deleted_items: deletedItems,
      })
    } else {
      this.handleDialogOpen('openSaveDataDialog')
    }
  }

  sendOrderToControl = () => {
    const {
      props: {
        match: { params },
        confirmCertificateOfCompletionRequest,
      },
    } = this
    this.handleDialogClose('openSetToControlConfirmDialog')
    const validateFormForUnsavedData = this.refOrderItemsForm.current.validateForUnsavedData()
    if (validateFormForUnsavedData.success) {
      confirmCertificateOfCompletionRequest({ orderId: params.id })
    } else {
      this.handleUnsavedDataDialogOpen(
        'openUnsavedDataDialog',
        i18next.t(
          'service_desk_web_atoir:beforeGoingFurtherYouShouldSaveChanges',
        ),
      )
    }
  }

  sendOrderToDone = () => {
    const {
      props: {
        match: { params },
        confirmCertificateOfCompletionRequest,
      },
    } = this
    this.handleDialogClose('openSetToDoneConfirmDialog')
    const validateFormForUnsavedData = this.refOrderItemsForm.current.validateForUnsavedData()
    if (validateFormForUnsavedData.success) {
      const validateFormForSendToClient = this.refOrderItemsForm.current.validateForSendToClient()
      if (validateFormForSendToClient.success) {
        confirmCertificateOfCompletionRequest({ orderId: params.id })
      } else {
        this.handleDialogOpen('openSendToClientDialog')
      }
    } else {
      this.handleUnsavedDataDialogOpen(
        'openUnsavedDataDialog',
        i18next.t(
          'service_desk_web_atoir:beforeGoingFurtherYouShouldSaveChanges',
        ),
      )
    }
  }

  downloadReport = item => {
    const {
      props: {
        downloadFileRequest,
        match: { params },
      },
    } = this
    downloadFileRequest({
      url: `${URL}/service_call_orders/${params.id}/reports/${item.id}`,
      name: item.name,
    })
    this.onToggle('anchorElPrintedFormMenu', null)()
  }

  render() {
    const {
      props: {
        match,
        classes,
        isFetchingPermissions,
        permissions,
        isFetchingOrder,
        order,
        isFetchingOrderItems,
        orderItems,
        isFetchingOrderItemsCount,
        orderItemsCount,
        isFetchingSuborderItems,
        suborderItems,
        isFetchingSuborderItemsCount,
        suborderItemsCount,
        files,
        deleteFile,
        isClearingOrderItems,
        isSavingOrderItems,
        isCopyingItemsFromChild,
        isFetchingPossibleReports,
        possibleReports,
        ndsChecked,
        overheadChecked,
        inputWithNDSChecked,
      },
      state: {
        itemsDiagnostic,
        itemsNeedRepair,
        orderPermissions,
        nds,
        overhead,
        inputWithNDS,
        commonAddition,
        anchorElFilesMenu,
        anchorElPrintedFormMenu,
        openSnackbar,
        snackbarVariant,
        snackbarMessage,
        unsavedDataWarningDialogMessage,
        openUnsavedDataDialog,
        openDeleteDialog,
        openConfirmChangeDataToPerformerDialog,
        openSaveDataDialog,
        openSendToClientDialog,
        openConfirmCommonAdditionDialog,
        openInfoDialog,
        openSetToControlConfirmDialog,
        openSetToDoneConfirmDialog,
      },
      handleCheckbox,
      handleTextChange,
    } = this
    const { params } = match
    return (
      <Fragment>
        {isFetchingPermissions || isFetchingOrder ? (
          <Loader />
        ) : (
          !isEmpty(order) &&
          !isEmpty(permissions) && (
            <Fragment>
              <BreadCrumb to={popLastSections(match.url, 3)}>
                {i18next.t('service_desk_web_atoir:titleCallCard', {
                  date: new Date(
                    Date.parse(order.ServiceCall.createdAt),
                  ).toLocaleDateString('ru'),
                  internalNum: order.ServiceCall.internal_num,
                })}
              </BreadCrumb>
              <BreadCrumb to={match.url}>
                {i18next.t(
                  'service_desk_web_atoir:workCompletionReportForming',
                )}
              </BreadCrumb>
              {!!orderPermissions && !!order && (
                <Fragment>
                  <Grid container spacing={2} className={classes.spaceBottom}>
                    <Grid item lg={6} md={6} sm={12}>
                      <Fragment>
                        <Typography className={classes.label}>
                          {i18next.t('service_desk_web_atoir:client')}
                        </Typography>
                        <Typography className={classes.text}>
                          {order.client_organization.full_name}
                        </Typography>
                      </Fragment>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                      <Fragment>
                        <Typography className={classes.label}>
                          {i18next.t('service_desk_web_atoir:performer')}
                        </Typography>
                        <Typography className={classes.text}>
                          {order.performer_organization.full_name}
                        </Typography>
                      </Fragment>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    justify="space-between"
                    className={classes.spaceBottom}
                  >
                    <Grid item lg={6} md={6} sm={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <FormControlLabel
                            classes={{ label: classes.toolboxFont }}
                            control={
                              <Checkbox
                                checked={ndsChecked}
                                onChange={handleCheckbox}
                                value="ndsChecked"
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label={i18next.t(
                              'service_desk_web_atoir:calculateWithValueAddedTax',
                            )}
                          />
                        </Grid>
                        {/* TODO: NDS */}
                        <Grid item>
                          <TextField
                            margin="normal"
                            value={nds}
                            onChange={handleTextChange('nds')}
                            className={classes.ndsInput}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>%</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <CommonAddition
                        classes={classes}
                        commonAddition={commonAddition}
                        onChange={handleTextChange('commonAddition')}
                        onApply={() =>
                          this.handleDialogOpen(
                            'openConfirmCommonAdditionDialog',
                          )
                        }
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <FormControlLabel
                            classes={{ label: classes.toolboxFont }}
                            control={
                              <Checkbox
                                checked={inputWithNDSChecked}
                                onChange={handleCheckbox}
                                value="inputWithNDSChecked"
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label={i18next.t(
                              'service_desk_web_atoir:calculateWithInputValueAddedTax',
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            margin="normal"
                            value={inputWithNDS}
                            onChange={handleTextChange('inputWithNDS')}
                            className={classes.ndsInput}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>%</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <FormControlLabel
                            classes={{ label: classes.toolboxFont }}
                            control={
                              <Checkbox
                                checked={overheadChecked}
                                onChange={handleCheckbox}
                                value="overheadChecked"
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label={i18next.t(
                              'service_desk_web_atoir:calculateWithOverhead',
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            margin="normal"
                            value={overhead}
                            onChange={handleTextChange('overhead')}
                            className={classes.ndsInput}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>%</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    justify="space-between"
                    className={classes.buttonsPannel}
                  >
                    <Grid item>
                      <Grid container>
                        <Grid>
                          <IconButton
                            onClick={() =>
                              this.handleDialogOpen('openInfoDialog')
                            }
                            className={classes.iconButton}
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <TextButton onClick={this.showFilesMenu}>
                            {i18next.t('service_desk_web_atoir:fileActions')}
                            <KeyboardArrowDown className={classes.rightIcon} />
                          </TextButton>
                        </Grid>
                        <Grid>
                          {order.status !== 'done' && (
                            <TextButton
                              onClick={() =>
                                this.handleDialogOpen('openDeleteDialog')
                              }
                            >
                              {i18next.t('service_desk_web_atoir:clearForm')}
                            </TextButton>
                          )}
                        </Grid>
                        <Grid>
                          <TextButton onClick={this.showPrintedFormMenu}>
                            {i18next.t(
                              'service_desk_web_atoir:showPrintedForm',
                            )}
                            <KeyboardArrowDown className={classes.rightIcon} />
                          </TextButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {order.status !== 'done' && (
                        <ContainedButton
                          onClick={() => this.saveOrderItemsList()}
                        >
                          {i18next.t('service_desk_web_atoir:saveForm')}
                        </ContainedButton>
                      )}
                    </Grid>
                  </Grid>
                  {isFetchingOrderItems || isFetchingOrderItemsCount ? (
                    <Loader />
                  ) : (
                    orderItems !== null &&
                    orderItemsCount !== null && (
                      <OrderItemsForm
                        orderType={order.type_order}
                        orderResultType={order.order_result}
                        orderPerformerOrganization={
                          order.performer_organization_id
                        }
                        itemsDiagnostic={itemsDiagnostic}
                        itemsNeedRepair={itemsNeedRepair}
                        percentSetMixed={value => {
                          if (typeof value === 'boolean') {
                            this.setState({
                              commonAddition: i18next.t(
                                'service_desk_web_atoir:mixed',
                              ),
                            })
                          } else if (typeof value === 'string') {
                            this.setState({ commonAddition: Number(value) })
                          }
                        }}
                        percent={commonAddition}
                        nds={ndsChecked ? nds : 0}
                        inputNDS={inputWithNDSChecked ? inputWithNDS : 0}
                        overhead={overheadChecked ? overhead : 0}
                        ref={this.refOrderItemsForm}
                      />
                    )
                  )}
                  {order.child_service_call_order_id !== null && (
                    <Fragment>
                      {isFetchingSuborderItems ||
                      isFetchingSuborderItemsCount ? (
                        <Loader />
                      ) : (
                        suborderItems !== null &&
                        suborderItemsCount !== null && (
                          <Fragment>
                            {order.status !== 'done' && (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                  <Typography className={classes.label}>
                                    {i18next.t(
                                      'service_desk_web_atoir:fillInWithInitialDataFromPerformer',
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <OutlinedButton
                                    onClick={() =>
                                      this.handleDialogOpen(
                                        'openConfirmChangeDataToPerformerDialog',
                                      )
                                    }
                                  >
                                    {i18next.t('service_desk_web_atoir:fillIn')}
                                  </OutlinedButton>
                                </Grid>
                              </Grid>
                            )}
                            <SuborderWorkCompletionReport
                              nds={order.child_orders.nds_performer}
                              orderResult={order.child_orders.order_result}
                              overhead={
                                order.child_orders.overhead_performer || 0
                              }
                              performer={
                                order.child_orders.performer_organization
                                  .full_name
                              }
                              itemsDiagnostic={suborderItems.itemsDiagnostic}
                              itemsNeedRepair={suborderItems.itemsNeedRepair}
                            />
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )}
                  <Menu
                    id="filesMenu"
                    anchorEl={anchorElFilesMenu}
                    open={Boolean(anchorElFilesMenu)}
                    onClose={this.onToggle('anchorElFilesMenu', null)}
                  >
                    {!(
                      Array.isArray(files) &&
                      files.find(file => file.file_type === 'act_diagnostic')
                    ) ? (
                      <MenuItem
                        onClick={() => {
                          this.onToggle('anchorElFilesMenu', null)()
                          this.inputFileRef.current.openFileDialog()
                        }}
                      >
                        {i18next.t('service_desk_web_atoir:attach')}
                      </MenuItem>
                    ) : (
                      [
                        <MenuItem
                          onClick={() => {
                            this.onToggle('anchorElFilesMenu', null)()
                            this.fileDownload(
                              files.find(
                                file => file.file_type === 'act_diagnostic',
                              ),
                            )
                          }}
                        >
                          {i18next.t('service_desk_web_atoir:downloadFile')}
                        </MenuItem>,
                        <MenuItem
                          onClick={() => {
                            this.onToggle('anchorElFilesMenu', null)()
                            deleteFile({
                              fileId: files.find(
                                file => file.file_type === 'act_diagnostic',
                              ).id,
                              orderId: params.id,
                            })
                          }}
                        >
                          {i18next.t('service_desk_web_atoir:deleteFile')}
                        </MenuItem>,
                      ]
                    )}
                  </Menu>

                  <Menu
                    id="printedFormMenu"
                    anchorEl={anchorElPrintedFormMenu}
                    open={Boolean(anchorElPrintedFormMenu)}
                    onClose={this.onToggle('anchorElPrintedFormMenu', null)}
                  >
                    {isFetchingPossibleReports ? (
                      <Fragment>
                        <Loader />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {possibleReports.map(r => (
                          <MenuItem onClick={() => this.downloadReport(r)}>
                            <Fragment>
                              <DownloadIcon className={classes.downloadIcon} />
                              {`${i18next.t(
                                `service_desk_web_atoir:${r.report_type}`,
                              )} (${r.Organization.short_name})`}
                            </Fragment>
                          </MenuItem>
                        ))}
                      </Fragment>
                    )}
                  </Menu>

                  <Dialog
                    isOpen={openDeleteDialog}
                    title={i18next.t(
                      'service_desk_web_atoir:areYouSureToClearForm',
                    )}
                    content={i18next.t(
                      'service_desk_web_atoir:allDataWillBeLost',
                    )}
                    handleClose={() =>
                      this.handleDialogClose('openDeleteDialog')
                    }
                    handleNegative={() =>
                      this.handleDialogClose('openDeleteDialog')
                    }
                    handlePositive={this.clearOrderItemsList}
                    negativeText={i18next.t('shared_web_atoir:close')}
                    positiveText={i18next.t('shared_web_atoir:delete')}
                    positiveButtonVariant="destructive"
                    closeIconHidded
                    isLoading={isClearingOrderItems}
                  />
                  <Dialog
                    isOpen={openUnsavedDataDialog}
                    content={unsavedDataWarningDialogMessage}
                    handleClose={() =>
                      this.handleDialogClose('openUnsavedDataDialog')
                    }
                    handleNegative={() =>
                      this.handleDialogClose('openUnsavedDataDialog')
                    }
                    handlePositive={this.saveOrderItemsList}
                    negativeText={i18next.t('shared_web_atoir:cancel')}
                    positiveText={i18next.t('shared_web_atoir:save')}
                    positiveButtonVariant="contained"
                    closeIconHidded
                    isLoading={isSavingOrderItems}
                  />
                  <Dialog
                    isOpen={openInfoDialog}
                    content={i18next.t(
                      'service_desk_web_atoir:requiredFieldsDescriptionNote',
                    )}
                    handleClose={() => this.handleDialogClose('openInfoDialog')}
                    handlePositive={() =>
                      this.handleDialogClose('openInfoDialog')
                    }
                    positiveText={i18next.t('shared_web_atoir:close')}
                    positiveButtonVariant="secondary"
                    closeIconHidded
                    negativeHidden
                  />
                  <Dialog
                    isOpen={openSaveDataDialog}
                    content={i18next.t('service_desk_web_atoir:fillInNameData')}
                    handleClose={() =>
                      this.handleDialogClose('openSaveDataDialog')
                    }
                    handlePositive={() =>
                      this.handleDialogClose('openSaveDataDialog')
                    }
                    positiveText={i18next.t('shared_web_atoir:close')}
                    positiveButtonVariant="secondary"
                    closeIconHidded
                    negativeHidden
                  />
                  <Dialog
                    isOpen={openSendToClientDialog}
                    content={i18next.t(
                      'service_desk_web_atoir:fillInRequiredData',
                    )}
                    handleClose={() =>
                      this.handleDialogClose('openSendToClientDialog')
                    }
                    handlePositive={() =>
                      this.handleDialogClose('openSendToClientDialog')
                    }
                    positiveText={i18next.t('shared_web_atoir:close')}
                    positiveButtonVariant="secondary"
                    closeIconHidded
                    negativeHidden
                  />
                  <Dialog
                    isOpen={openConfirmChangeDataToPerformerDialog}
                    isLoading={isCopyingItemsFromChild}
                    content={i18next.t(
                      'service_desk_web_atoir:allFilledDataWillBeChangedWithPerformerData',
                    )}
                    handleClose={() =>
                      this.handleDialogClose(
                        'openConfirmChangeDataToPerformerDialog',
                      )
                    }
                    handleNegative={() =>
                      this.handleDialogClose(
                        'openConfirmChangeDataToPerformerDialog',
                      )
                    }
                    handlePositive={() => this.setDataFromPerformer()}
                    negativeText={i18next.t('shared_web_atoir:cancel')}
                    positiveText={i18next.t('service_desk_web_atoir:fillIn')}
                    positiveButtonVariant="contained"
                    closeIconHidded
                  />
                  <Dialog
                    isOpen={openConfirmCommonAdditionDialog}
                    content={i18next.t(
                      'service_desk_web_atoir:areYouSureToApplyCommonAddition',
                    )}
                    handleClose={() =>
                      this.handleDialogClose('openConfirmCommonAdditionDialog')
                    }
                    handleNegative={() =>
                      this.handleDialogClose('openConfirmCommonAdditionDialog')
                    }
                    handlePositive={() => this.handleCommonAdditionApply()}
                    negativeText={i18next.t('shared_web_atoir:cancel')}
                    positiveText={i18next.t(
                      'service_desk_web_atoir:applyAction',
                    )}
                    positiveButtonVariant="contained"
                    closeIconHidded
                  />
                  <Dialog
                    isOpen={openSetToControlConfirmDialog}
                    content={i18next.t(
                      'service_desk_web_atoir:areYouSureToSendOrderToControl',
                    )}
                    handleClose={() =>
                      this.handleDialogClose('openSetToControlConfirmDialog')
                    }
                    handleNegative={() =>
                      this.handleDialogClose('openSetToControlConfirmDialog')
                    }
                    handlePositive={() => this.sendOrderToControl()}
                    negativeText={i18next.t('shared_web_atoir:cancel')}
                    positiveText={i18next.t('service_desk_web_atoir:send')}
                    positiveButtonVariant="contained"
                    closeIconHidded
                  />
                  <Dialog
                    isOpen={openSetToDoneConfirmDialog}
                    content={i18next.t(
                      'service_desk_web_atoir:areYouSureToSendOrderToClient',
                    )}
                    handleClose={() =>
                      this.handleDialogClose('openSetToDoneConfirmDialog')
                    }
                    handleNegative={() =>
                      this.handleDialogClose('openSetToDoneConfirmDialog')
                    }
                    handlePositive={() => this.sendOrderToDone()}
                    negativeText={i18next.t('shared_web_atoir:cancel')}
                    positiveText={i18next.t('service_desk_web_atoir:send')}
                    positiveButtonVariant="contained"
                    closeIconHidded
                  />
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={this.closeSnackbar}
                  >
                    <SnackbarElContent
                      onClose={this.closeSnackbar}
                      variant={snackbarVariant}
                      message={snackbarMessage}
                    />
                  </Snackbar>
                </Fragment>
              )}
              <AddCompletionFile
                ref={this.inputFileRef}
                uploadDoneFunc={this.orderUpdate}
                idOrder={params.id}
              />
            </Fragment>
          )
        )}
      </Fragment>
    )
  }
}

WorkCompletionReport.defaultProps = {
  isFetchingPossibleReports: false,
  possibleReports: [],
}

WorkCompletionReport.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  isFetchingOrder: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  fetchServiceCallOrderItemsRequest: PropTypes.func.isRequired,
  isFetchingOrderItems: PropTypes.bool.isRequired,
  orderItems: PropTypes.array.isRequired,
  fetchServiceCallOrderItemsCountRequest: PropTypes.func.isRequired,
  isFetchingOrderItemsCount: PropTypes.bool.isRequired,
  orderItemsCount: PropTypes.number.isRequired,
  fetchServiceCallSuborderItemsRequest: PropTypes.func.isRequired,
  isFetchingSuborderItems: PropTypes.bool.isRequired,
  suborderItems: PropTypes.array.isRequired,
  fetchServiceCallSuborderItemsCountRequest: PropTypes.func.isRequired,
  isFetchingSuborderItemsCount: PropTypes.bool.isRequired,
  suborderItemsCount: PropTypes.number.isRequired,
  saveServiceCallOrderItemsListRequest: PropTypes.func.isRequired,
  isSavingOrderItems: PropTypes.bool.isRequired,
  savedOrderItemsSuccessfully: PropTypes.bool.isRequired,
  clearServiceCallOrderItemsListRequest: PropTypes.func.isRequired,
  isClearingOrderItems: PropTypes.bool.isRequired,
  clearedOrderItemsSuccessfully: PropTypes.bool.isRequired,
  confirmCertificateOfCompletionRequest: PropTypes.func.isRequired,
  isConfirmingCertificateOfCompletion: PropTypes.bool.isRequired,
  confirmCertificateOfCompletionSuccess: PropTypes.bool.isRequired,
  fetchCallPermissions: PropTypes.func.isRequired,
  isFetchingPermissions: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  copyOrderItemsFromChildRequest: PropTypes.func.isRequired,
  isCopyingItemsFromChild: PropTypes.bool.isRequired,
  isFetchingPossibleReports: PropTypes.bool,
  possibleReports: PropTypes.array,
  setNdsCheckboxValue: PropTypes.func.isRequired,
  ndsChecked: PropTypes.bool.isRequired,
  inputWithNDSChecked: PropTypes.bool.isRequired,
  overheadChecked: PropTypes.bool.isRequired,
}

export default withStyles(styles)(WorkCompletionReport)
