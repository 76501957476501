import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import i18n from '../../../../../i18n/i18n'
import {
  dateFormatShortYear,
  timeFormatHourMinutes,
  convertDHMDuration,
} from '../../../../../helpers/dateHelper'

// material-ui
import { withStyles } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// components
import MapViewEmbed from '../../../../../widgets/MapViewEmbed'

const styles = theme => ({
  EnhancedCell: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'white',
    borderBottom: theme.typography.pxToRem(1),
    borderBottomStyle: 'solid',
    borderBottomColor: lighten(theme.palette.secondary.light, 0.85),
    '&:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
  actionTitleText: {
    fontSize: theme.typography.pxToRem(16),
    color: 'rgba(0,0,0,0.87)',
    marginBottom: theme.spacing(0.5),
  },
  actionActorText: {
    fontSize: theme.typography.pxToRem(12),
    color: 'rgba(0,0,0,0.54)',
    marginBottom: theme.spacing(2),
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: 'rgba(0,0,0,0.87)',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    padding: '16px 16px',
  },
  expansionPanel: {
    '&::before': {
      height: '0px',
      backgroundColor: 'transparent',
    },
    marginTop: theme.spacing(1),
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  expansionPanelSummary: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  expansionPanelDetails: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    color: 'rgba(0,0,0,0.54)',
  },
  arrow: {
    width: 16,
    height: 16,
    color: 'rgba(0,0,0,0.54)',
  },
})

const getComment = action => {
  switch (action.action_type) {
    case 'inner_call_created':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_createdHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
        },
      )

    case 'inner_call_edited':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_editedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
        },
      )

    case 'inner_call_closed':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_closedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
          innerCallWorkTime: convertDHMDuration(action.options.work_time),
        },
      )

    case 'inner_call_doc_attached':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_doc_attachedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
          documentName: action.options.target_name,
        },
      )

    case 'inner_call_doc_deleted':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_doc_deletedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
          documentName: action.options.target_name,
        },
      )

    case 'inner_call_photo_attached':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_photo_attachedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
          photoName: action.options.target_name,
        },
      )

    case 'inner_call_photo_deleted':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_photo_deletedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
          photoName: action.options.target_name,
        },
      )
    case 'inner_call_description_changed':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_description_changedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
          photoName: action.options.target_name,
        },
      )
    case 'inner_call_engineer_assigned':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_engineer_assignedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
        },
      )
    case 'inner_call_engineer_reassigned':
      return i18n.t(
        'inner_calls_web_atoir:inner_call_engineer_reassignedHistoryDescription',
        {
          actionActor: action.actor_full_name,
          innerCallId: action.inner_call_id,
        },
      )

    default:
      return ''
  }
}

const getDetails = (action, classes) => {
  switch (action.action_type) {
    case 'inner_call_created':
      return (
        action.InnerCall.created_address && (
          <ExpansionPanel elevation={0} className={classes.expansionPanel}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.expansionPanelSummary}
            >
              <Typography
                variant="subheader"
                className={classNames([classes.subHeading])}
              >
                {getComment(action)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <MapViewEmbed address={action.InnerCall.created_address} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      )

    case 'inner_call_description_changed':
      return (
        <ExpansionPanel elevation={0} className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expansionPanelSummary}
          >
            <Typography
              variant="subheader"
              className={classNames([classes.subHeading])}
            >
              {getComment(action)}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid
              direction="column"
              container
              spacing={2}
              alignItems="flex-start"
            >
              <Grid item alignContent="flex-start">
                <Typography className={classes.description}>
                  {action.options.before.description}
                </Typography>
              </Grid>
              <Grid item alignContent="flex-start">
                <ArrowDownward className={classes.arrow} />
              </Grid>
              <Grid item alignContent="flex-start">
                <Typography className={classes.description}>
                  {action.options.after.description}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )

    case 'inner_call_engineer_reassigned':
      return (
        <ExpansionPanel elevation={0} className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expansionPanelSummary}
          >
            <Typography
              variant="subheader"
              className={classNames([classes.subHeading])}
            >
              {getComment(action)}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid
              direction="column"
              container
              spacing={2}
              alignItems="flex-start"
            >
              <Grid item alignContent="flex-start">
                <Typography className={classes.description}>
                  {action.options.before.assignee.full_name}
                </Typography>
              </Grid>
              <Grid item alignContent="flex-start">
                <ArrowDownward className={classes.arrow} />
              </Grid>
              <Grid item alignContent="flex-start">
                <Typography className={classes.description}>
                  {action.options.after.assignee.full_name}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    case 'inner_call_engineer_assigned':
      return (
        <ExpansionPanel elevation={0} className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expansionPanelSummary}
          >
            <Typography
              variant="subheader"
              className={classNames([classes.subHeading])}
            >
              {getComment(action)}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid
              direction="column"
              container
              spacing={2}
              alignItems="flex-start"
            >
              <Grid item alignContent="flex-start">
                <Typography className={classes.description}>
                  {action.options.after.assignee.full_name}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )

    case 'inner_call_closed':
      return (
        action.InnerCall.closed_address && (
          <ExpansionPanel elevation={0} className={classes.expansionPanel}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.expansionPanelSummary}
            >
              <Typography
                variant="subheader"
                className={classNames([classes.subHeading])}
              >
                {getComment(action)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <MapViewEmbed address={action.InnerCall.closed_address} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      )

    case 'inner_call_doc_attached':
      return null

    case 'inner_call_doc_deleted':
      return null

    case 'inner_call_photo_attached':
      return null

    case 'inner_call_photo_deleted':
      return null

    default:
      return ''
  }
}

const ActionViewer = ({ classes, action }) => (
  <TableRow hover>
    <TableCell className={classNames([classes.EnhancedCell, classes.root])}>
      <Typography className={classes.actionTitleText}>
        {`${i18n.t(
          `inner_calls_web_atoir:${action.action_type}HistoryAction`,
        )} ${dateFormatShortYear(action.createdAt)} ${timeFormatHourMinutes(
          action.createdAt,
        )}`}
      </Typography>
      <Typography className={classes.actionActorText}>
        {action.actor_full_name}
      </Typography>
      <Fragment>{getDetails(action, classes)}</Fragment>
    </TableCell>
  </TableRow>
)

ActionViewer.propTypes = {
  action: PropTypes.object.isRequired,
}

export default withStyles(styles)(ActionViewer)
