import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import isEmpty from 'is-empty'
import RLink from 'react-router-dom/Link'
import PropTypes from 'prop-types'

// ---material---
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import MenuIcon from '@material-ui/icons/Menu'

import RatingStars from '../../../../../Organizations/Stateless/RatingStars'
import i18next from '../../../../../../i18n/i18n'

import './index.css'

class TableComponent extends Component {
  state = {
    anchorEl: null,
    selectedOffer: null,
  }

  componentWillMount() {
    this.setState({
      selectedOffer: this.props.selectedOffer,
    })
  }

  handleUserMenuOpen = (event, id) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedOffer: id,
    })
  }

  handleUserMenuClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleOpenDialog = () => {
    const {
      props: { handleChangeOffer },
    } = this
    this.setState({
      anchorEl: null,
    })
    handleChangeOffer(this.state.selectedOffer)
  }

  render() {
    const {
      props: { offers, match },
      state: { anchorEl },
    } = this

    return (
      <TableBody>
        {!isEmpty(offers) &&
          offers.map(_offer => (
            <TableRow
              className={
                _offer.status !== 'send' ? '_offers-list_disabled' : ''
              }
              key={_offer.id}
            >
              <TableCell padding="dense">
                {new Date(_offer.date_created).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {new Date(_offer.date_actual_to).toLocaleDateString()}
              </TableCell>
              <TableCell padding="dense">
                <RLink
                  to={`${match.url}/organizations/${_offer.organization_id}/profile`}
                >
                  <span className="staff-name-underlined">
                    {_offer.Organization.short_name}
                  </span>
                </RLink>
                {_offer.status === 'cancel' &&
                  ` (${i18next.t('inventory_web_atoir:offerCancel')})`}
              </TableCell>
              <TableCell padding="dense">
                <RatingStars rating={5} />
              </TableCell>
              <TableCell padding="dense">{_offer.amount}</TableCell>
              <TableCell>
                <div>
                  <IconButton
                    className="User-profile-edit-icon"
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={event => this.handleUserMenuOpen(event, _offer.id)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleUserMenuClose}
                  >
                    <MenuItem onClick={this.handleOpenDialog}>
                      {i18next.t('inventory_web_atoir:details')}
                    </MenuItem>
                  </Menu>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    )
  }
}

const styles = () => ({})

TableComponent.defaultProps = {
  selectedOffer: null,
}

TableComponent.propTypes = {
  offers: PropTypes.array.isRequired,
  handleChangeOffer: PropTypes.func.isRequired,
  selectedOffer: PropTypes.string,
}

export default withStyles(styles)(TableComponent)
