import * as constants from '../constants/actionTypes/buttons'
import _ from 'lodash'

const initialState = {
  createButton: {
    isFetching: false,
    error: null,
  },
  editButton: {
    isFetching: false,
    error: null,
  },
  getButtons: {
    isFetching: false,
    buttons: [],
    error: null,
  },
  deleteButton: {
    isFetching: false,
    error: null,
  },
  getButton: {
    isFetching: false,
    button: [],
    error: null,
  },
  history: {
    isFetching: false,
    history: [],
    end: false,
    error: null,
  },
  executeSDCallButton: {
    isFetching: false,
    error: null,
  },
  getSDCallButtons: {
    isFetching: false,
    callButtons: [],
    orderButtons: [],
    error: null,
  },
}

const buttons = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_BUTTON_REQUEST:
      return {
        ...state,
        createButton: {
          ...state.createButton,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_BUTTON_SUCCESS:
      return {
        ...state,
        createButton: {
          ...state.createButton,
          isFetching: false,
        },
      }

    case constants.CREATE_BUTTON_FAILURE:
      return {
        ...state,
        createButton: {
          ...state.createButton,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_BUTTON_REQUEST:
      return {
        ...state,
        editButton: {
          ...state.editButton,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_BUTTON_SUCCESS:
      return {
        ...state,
        editButton: {
          ...state.editButton,
          isFetching: false,
        },
      }

    case constants.EDIT_BUTTON_FAILURE:
      return {
        ...state,
        editButton: {
          ...state.editButton,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_BUTTONS_REQUEST:
      return {
        ...state,
        getButtons: {
          ...state.getButtons,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_BUTTONS_SUCCESS:
      return {
        ...state,
        getButtons: {
          ...state.getButtons,
          isFetching: false,
          buttons: action.payload,
        },
      }

    case constants.GET_BUTTONS_FAILURE:
      return {
        ...state,
        getButtons: {
          ...state.getButtons,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_BUTTON_REQUEST:
      return {
        ...state,
        deleteButton: {
          ...state.deleteButton,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_BUTTON_SUCCESS:
      return {
        ...state,
        deleteButton: {
          ...state.deleteButton,
          isFetching: false,
        },
      }

    case constants.DELETE_BUTTON_FAILURE:
      return {
        ...state,
        deleteButton: {
          ...state.deleteButton,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_BUTTON_REQUEST:
      return {
        ...state,
        getButton: {
          ...state.getButton,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_BUTTON_SUCCESS:
      return {
        ...state,
        getButton: {
          ...state.getButton,
          isFetching: false,
          button: action.payload,
        },
      }

    case constants.GET_BUTTON_FAILURE:
      return {
        ...state,
        getButton: {
          ...state.getButton,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_BUTTONS_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_BUTTONS_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          history: [
            ...(state.history.history || []),
            ...action.payload.history,
          ],
          end: action.payload.history.length === 0 || action.payload.end,
        },
      }

    case constants.GET_BUTTONS_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CLEAR_BUTTONS_HISTORY:
      return {
        ...state,
        history: initialState.history,
      }

    case constants.EXECUTE_SD_CALL_BUTTON_REQUEST:
      return {
        ...state,
        executeSDCallButton: {
          ...state.executeSDCallButton,
          isFetching: true,
          error: null,
        },
      }

    case constants.EXECUTE_SD_CALL_BUTTON_SUCCESS:
      return {
        ...state,
        executeSDCallButton: {
          ...state.executeSDCallButton,
          isFetching: false,
        },
      }

    case constants.EXECUTE_SD_CALL_BUTTON_FAILURE:
      return {
        ...state,
        executeSDCallButton: {
          ...state.executeSDCallButton,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_SD_CALL_BUTTONS_REQUEST:
      return {
        ...state,
        getSDCallButtons: {
          ...state.getSDCallButtons,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_SD_CALL_BUTTONS_SUCCESS:
      return {
        ...state,
        getSDCallButtons: {
          ...state.getSDCallButtons,
          isFetching: false,
          callButtons: _.filter(action.payload, button =>
            button.show_in.includes('call'),
          ).sort((a, b) => a.name.localeCompare(b.name)),
          orderButtons: _.filter(action.payload, button =>
            button.show_in.includes('order'),
          ).sort((a, b) => a.name.localeCompare(b.name)),
        },
      }

    case constants.GET_SD_CALL_BUTTONS_FAILURE:
      return {
        ...state,
        getSDCallButtons: {
          ...state.getSDCallButtons,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default buttons
