/* eslint-disable max-len */
import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import headersSchema from './headers.schema'

import i18next from '../../../../i18n/i18n'

import HeadersMatchingComponent from './HeadersMatchingComponent'

const optionsList = () => [
  {
    id: 'name',
    title: i18next.t('equipment_web_atoir:equipNameRequired'),
    required: true,
    uniqueAvailable: true,
  },
  {
    id: 'address',
    title: i18next.t('equipment_web_atoir:equipAddrRequired'),
    required: true,
    uniqueAvailable: false,
  },
  {
    id: 'model',
    title: i18next.t('equipment_web_atoir:equipModel'),
    required: false,
    uniqueAvailable: true,
  },
  {
    id: 'vendor',
    title: i18next.t('equipment_web_atoir:equipAddress'),
    required: false,
    uniqueAvailable: true,
  },
  {
    id: 'serial_number',
    title: i18next.t('equipment_web_atoir:serialNumber'),
    required: false,
    uniqueAvailable: true,
  },
  {
    id: 'article_number',
    title: i18next.t('equipment_web_atoir:artNumber'),
    required: false,
    uniqueAvailable: true,
  },
  {
    id: 'external_id',
    title: i18next.t('equipment_web_atoir:extNumber'),
    required: false,
    uniqueAvailable: true,
  },
  {
    id: 'country',
    title: i18next.t('equipment_web_atoir:equipCountry'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'year_issue',
    title: i18next.t('equipment_web_atoir:yearOfIssue'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'date_input_work',
    title: i18next.t('equipment_web_atoir:yearOfCommissioning'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'date_expired_guarantee',
    title: i18next.t('equipment_web_atoir:yearEndOfWarranty'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'status',
    title: i18next.t('equipment_web_atoir:status'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'workable',
    title: i18next.t('equipment_web_atoir:ableToWork'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'floor',
    title: i18next.t('equipment_web_atoir:foor'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'cabinet',
    title: i18next.t('equipment_web_atoir:cabinet'),
    required: false,
    uniqueAvailable: false,
  },
  {
    id: 'portable',
    title: i18next.t('equipment_web_atoir:portable'),
    required: false,
    uniqueAvailable: false,
  },
]

function isInitialValid(props) {
  if (!props.validationSchema) return false
  return props.validationSchema().isValidSync(props.initialValues)
}

const formikEnhancer = withFormik({
  validationSchema: headersSchema,
  mapPropsToValues: ({ headersMatchingList }) => {
    const defaultVals = {
      name: {},
    }
    return headersMatchingList
      ? {
          ...headersMatchingList,
        }
      : defaultVals
  },
  handleSubmit: (payload, { props, setSubmitting }) => {
    setSubmitting(false)
    props.onSubmit(payload)
  },
  displayName: 'CreateEditScriptFormEnhanced',
  validateOnBlur: true,
  validateOnChange: true,
  isInitialValid,
})

const HeadersMatchingContainerTemplate = props => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    headersList,
    isValid,
    setIsHeadersMatchingValid,
    setHeadersMatchingList,
  } = props

  const [
    headersListWithoutSelected,
    setHeadersListWithoutSelected,
  ] = React.useState(null)

  useEffect(() => {
    if (isValid) {
      // additional check for prevent cast error
      headersSchema.isValid(values).then(valid => {
        if (valid) {
          setIsHeadersMatchingValid(true)
          setHeadersMatchingList(headersSchema.cast(values))
        }
      })
    } else {
      setIsHeadersMatchingValid(false)
    }
  }, [isValid, values])

  // disabling selected (in select component) headers or enable it in case deselect
  useEffect(() => {
    if (values) {
      setHeadersListWithoutSelected(
        headersList.map(item =>
          _.findKey(values, o => +o.col === item.collIndex)
            ? { ...item, disabled: true }
            : { ...item, disabled: false },
        ),
      )
    }
  }, [values])

  const setFieldValueHandler = (id, value) => {
    setFieldValue(id, value)
  }
  return (
    <form onSubmit={handleSubmit}>
      <Fragment>
        <HeadersMatchingComponent
          headersMatching={values}
          onChange={setFieldValueHandler}
          optionsList={optionsList()}
          headersList={headersListWithoutSelected || headersList}
        />
      </Fragment>
    </form>
  )
}

const HeadersMatchingContainer = formikEnhancer(
  HeadersMatchingContainerTemplate,
)

HeadersMatchingContainer.defaultProps = {
  headersMatchingList: null,
}

HeadersMatchingContainer.propTypes = {
  headersList: PropTypes.array.isRequired,
  headersMatchingList: PropTypes.object,
}

export default HeadersMatchingContainer
