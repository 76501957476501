import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const defaultOptions = {
  propNameData: 'data',
  propNameUpdateData: 'updateData',
  propNameIsFetching: 'dataIsFetching',
  propNameRowsPerPageOptions: 'rowsPerPageOptions',
  rowsPerPageOptions: [5, 10, 25],
  propNameRowsPerPage: 'rowsPerPage',
  rowsPerPageDefault: 10,
  propNamePage: 'page',
  pageDefault: 0,
  propNameOnChangePage: 'onChangePage',
  propNameOnChangeRowsPerPage: 'onChangeRowsPerPage',
  propNameError: 'error',
  propNameErrorCount: 'errorCount',
  propNameCount: 'count',
  propNameSetRequestPayload: 'setRequestPayload',
}

export default function withTablePagination(
  WrappedComponent,
  mapStateToProps,
  mapDispatchToProps,
  options,
) {
  const mergedOptions = { ...defaultOptions, ...options }
  const {
    propNameData,
    propNameUpdateData,
    propNameIsFetching,
    propNameRowsPerPageOptions,
    rowsPerPageOptions,
    propNameRowsPerPage,
    rowsPerPageDefault,
    propNamePage,
    pageDefault,
    propNameOnChangePage,
    propNameOnChangeRowsPerPage,
    propNameError,
    propNameCount,
    propNameErrorCount,
    propNameSetRequestPayload,
  } = mergedOptions

  const WithTablePaginationComponent = ({
    data,
    count,
    isFetching,
    error,
    errorCount,
    fetchAction,
    fetchCountAction,
    canLoad,
    ...otherProp
  }) => {
    const [payload, setPayload] = React.useState(null)
    const [page, setPage] = React.useState(pageDefault)
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault)

    React.useEffect(() => {
      loadData()
    }, [canLoad, page, rowsPerPage, payload])

    function loadData(_payload) {
      if (payload) {
        fetchAction({
          ...(payload && payload),
          ...(_payload && _payload),
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        })
        if (fetchCountAction) {
          fetchCountAction(payload)
        }
      }
    }

    function handleChangePage(event, pageValue) {
      setPage(pageValue)
    }

    function handleChangeRowsPerPage(event, rowsPerPageValue) {
      setRowsPerPage(rowsPerPageValue || event.target.value)
      setPage(0)
    }

    function setRequestPayload(payloadValue) {
      setPayload(payloadValue)
    }

    return (
      <React.Fragment>
        {React.createElement(
          WrappedComponent,
          {
            ...otherProp,
            [propNameSetRequestPayload]: setRequestPayload,
            [propNameIsFetching]: isFetching,
            [propNameData]: data,
            [propNameUpdateData]: loadData,
            [propNameRowsPerPageOptions]: rowsPerPageOptions,
            [propNamePage]: page,
            [propNameRowsPerPage]: rowsPerPage,
            [propNameOnChangePage]: handleChangePage,
            [propNameOnChangeRowsPerPage]: handleChangeRowsPerPage,
            [propNameError]: error,
            [propNameErrorCount]: errorCount,
            [propNameCount]: count,
          },
          null,
        )}
      </React.Fragment>
    )
  }

  WithTablePaginationComponent.defaultProps = {
    canLoad: true,
    error: null,
    errorCount: null,
  }

  WithTablePaginationComponent.propTypes = {
    canLoad: PropTypes.bool,
    data: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.number,
    errorCount: PropTypes.number,
    fetchAction: PropTypes.func.isRequired,
    fetchCountAction: PropTypes.func.isRequired,
  }

  const WithTablePagination = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithTablePaginationComponent)
  WithTablePagination.displayName = `WithTablePagination(${getDisplayName(
    WrappedComponent,
  )})`

  return WithTablePagination
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export const withTablePaginationHOC = (
  mapStateToPropsForHOC,
  mapDispatchToPropsForHOC,
  options = {},
) => component =>
  withTablePagination(
    component,
    mapStateToPropsForHOC,
    mapDispatchToPropsForHOC,
    options,
  )
