import i18n from '../../../../../i18n/i18n'
import { getUserFullName } from '../../../../../helpers/userHelper'
import { formatDate } from '../../../../../helpers/dateHelper'

const toDefaultUserNameStructure = u => ({
  first_name: u.u_first_name,
  last_name: u.u_last_name,
  middle_name: u.u_middle_name,
})

export const getComment = action => {
  switch (action.sch_content_key) {
    case 'service_call_created':
      return i18n.t('service_desk_web_atoir:service_call_created')
    case 'auto_service_call_created':
      return i18n.t('service_desk_web_atoir:auto_service_call_created')
    case 'service_call_assigned_foreign_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_assigned_foreign_performer',
        {
          callNum: action.call.sc_ext_num ? `№ ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'auto_service_call_assigned_foreign_performer':
      return i18n.t(
        'service_desk_web_atoir:auto_service_call_assigned_foreign_performer',
        {
          callNum: action.call.sc_ext_num ? `№ ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_assigned_foreign_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_assigned_foreign_client',
        {
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.sch_arguments.target_organization_name,
        },
      )
    case 'auto_service_call_assigned_foreign_client':
      return i18n.t(
        'service_desk_web_atoir:auto_service_call_assigned_foreign_client',
        {
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.sch_arguments.target_organization_name,
        },
      )
    case 'service_call_assigned_on_engineer_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_assigned_on_engineer_performer',
        {
          engineerName: action.sch_arguments.target_user_name,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_taken_in_work_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_taken_in_work_client',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_rejected_client':
      return i18n.t('service_desk_web_atoir:service_call_rejected_client', {
        callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        organization: action.organization.o_short_name,
      })
    case 'service_call_rejected_performer':
      return i18n.t('service_desk_web_atoir:service_call_rejected_performer', {
        callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        userName: getUserFullName(
          toDefaultUserNameStructure(action.organizationUser),
        ),
      })
    case 'service_call_performer_responsible_changed_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_performer_responsible_changed_performer',
        {
          before: action.sch_arguments.value_before,
          after: action.sch_arguments.value_after,
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_client_responsible_changed_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_client_responsible_changed_client',
        {
          before: action.sch_arguments.value_before,
          after: action.sch_arguments.value_after,
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_change_engineer':
      return i18n.t('service_desk_web_atoir:service_call_change_engineer', {
        after: action.sch_arguments.target_user_name,
        userName: getUserFullName(
          toDefaultUserNameStructure(action.organizationUser),
        ),
      })
    case 'service_call_change_visit_date_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_change_visit_date_performer',
        {
          before: formatDate(action.sch_arguments.value_before),
          after: formatDate(action.sch_arguments.value_after),
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_change_visit_date_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_change_visit_date_client',
        {
          before: formatDate(action.sch_arguments.value_before),
          after: formatDate(action.sch_arguments.value_after),
        },
      )
    case 'service_call_change_visit_date_owner':
      return i18n.t(
        'service_desk_web_atoir:service_call_change_visit_date_client',
        {
          before: formatDate(action.sch_arguments.value_before),
          after: formatDate(action.sch_arguments.value_after),
        },
      )
    case 'service_call_order_result_changed_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_order_result_changed_performer',
        {
          result: i18n
            .t(
              `service_desk_web_atoir:order_status_${action.order.sco_order_result}`,
            )
            .toLowerCase(),
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_diagnostic_results_sent_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_sent_performer',
        {
          result: i18n
            .t(
              `service_desk_web_atoir:order_status_${action.order.sco_order_result}`,
            )
            .toLowerCase(),
          organization: action.clientOrganization.scoco_short_name,
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_diagnostic_results_sent_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_sent_client',
        {
          result: i18n
            .t(
              `service_desk_web_atoir:order_status_${action.order.sco_order_result}`,
            )
            .toLowerCase(),
          organization: action.performerOrganization.scopo_short_name,
        },
      )
    case 'service_call_repair_results_sent_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_sent_performer',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_repair_results_sent_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_sent_client',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_repair_results_accepted_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_accepted_client',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_repair_results_accepted_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_accepted_performer',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_diagnostic_results_accepted_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_accepted_client',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_diagnostic_results_accepted_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_accepted_performer',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_diagnostic_results_rejected_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_rejected_client',
        {
          reason: action.sch_arguments.reason,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.sch_arguments.target_organization_name,
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_diagnostic_results_rejected_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_rejected_performer',
        {
          reason: action.sch_arguments.reason,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        },
      )
    case 'service_call_repair_results_rejected_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_rejected_client',
        {
          reason: action.sch_arguments.reason,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_repair_results_rejected_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_rejected_performer',
        {
          reason: action.sch_arguments.reason,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_closed_client':
      return i18n.t('service_desk_web_atoir:service_call_closed_client', {
        callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        userName: getUserFullName(
          toDefaultUserNameStructure(action.organizationUser),
        ),
      })
    case 'service_call_closed_performer':
      return i18n.t('service_desk_web_atoir:service_call_closed_performer', {
        organization: action.organization.o_short_name,
        callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
      })
    case 'service_call_order_canceled_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_order_canceled_client',
        {
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
          organization: action.organization.o_short_name,
        },
      )
    case 'service_call_order_canceled_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_order_canceled_performer',
        {
          organization: action.clientOrganization.scoco_short_name,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        },
      )
    case 'service_call_canceled_client':
      return i18n.t('service_desk_web_atoir:service_call_canceled_client', {
        callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        userName: getUserFullName(
          toDefaultUserNameStructure(action.organizationUser),
        ),
        organization: action.organization.o_short_name,
      })
    case 'service_call_canceled_performer':
      return i18n.t('service_desk_web_atoir:service_call_canceled_performer', {
        organization: action.organization.o_short_name,
        callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
      })
    case 'service_call_repair_approved_client':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_approved_client',
        {
          organization: action.sch_arguments.target_organization_name,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_repair_approved_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_approved_performer',
        {
          organization: action.organization.o_short_name,
          callNum: action.call.sc_ext_num ? ` № ${action.call.sc_ext_num}` : '',
        },
      )
    case 'service_call_diagnostic_results_sent_for_control_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_diagnostic_results_sent_for_control_performer',
        {
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    case 'service_call_repair_results_sent_for_control_performer':
      return i18n.t(
        'service_desk_web_atoir:service_call_repair_results_sent_for_control_performer',
        {
          userName: getUserFullName(
            toDefaultUserNameStructure(action.organizationUser),
          ),
        },
      )
    default:
      return ''
  }
}

export function getCommentAction(action) {
  const {
    ServiceCall: { internal_num: internalNum },
    action_options: { tagName },
    action_type: actionType,
    parent_type: parentType,
    name,
  } = action
  let comment
  const comments = {
    tag_manual: {
      default() {
        comment = i18n.t(`service_desk_web_atoir:${parentType}_${actionType}`, {
          tagName,
        })
      },
    },
    default: {
      default() {
        comment = i18n.t(
          `service_desk_web_atoir:${parentType}ScriptsAndButtonsAction`,
          {
            internalNum,
            actionName: name,
          },
        )
      },
    },
  }
  function getLevel(data, key) {
    return data[key] || data.default
  }
  getLevel(getLevel(comments, parentType), actionType)()

  return comment
}
