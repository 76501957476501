import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../../../i18n/i18n'

// components
import Rating from '../../../../../../widgets/Rating'
import SimpleDialog from '../../../../../Other/SimpleDialog'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const styles = () => ({
  root: {
    minWidth: '360px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

class SetRatingDialog extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      rating: props.rating ? props.rating : 5,
      comment: props.comment,
      validation: {},
    }
  }

  handleRatingChange = rating => {
    this.setState({ rating })
  }

  handleTextChange = event => {
    this.setState({
      comment: event.target.value,
    })
  }

  submitAction = () => {
    const {
      props: { positiveAction },
      state: { rating, comment },
      validate,
    } = this
    const commentStr = comment ? comment.trim() : ''
    const validation = validate(rating, commentStr)
    if (!isEmpty(validation)) {
      this.setState({ validation })
      return
    }
    this.setState({ validation: {} })
    positiveAction(rating, commentStr)
  }

  validate = (rating, comment) => {
    const validation = {}
    if (rating < 4 && comment === '') {
      validation.closedComment = i18next.t(
        'validation_web_atoir:commentToRatingRequired',
      )
    }
    return validation
  }

  render() {
    const {
      props: { classes, isOpen, isLoading, handleClose, negativeAction },
      state: { rating, comment, validation },
      handleRatingChange,
      handleTextChange,
      submitAction,
    } = this

    const commentValidation = !isEmpty(validation.closedComment)
      ? validation.closedComment
      : ''

    return (
      <SimpleDialog
        isOpen={isOpen}
        isLoading={isLoading}
        handleClose={() => handleClose()}
        handlePositive={() => submitAction()}
        handleNegative={() => negativeAction()}
        positiveText={i18next.t('shared_web_atoir:send')}
        negativeText={i18next.t('shared_web_atoir:cancel')}
        title={`${i18next.t('inventory_web_atoir:evaluateWorkQuality')}`}
        content={
          <Grid container direction="column">
            <Grid container xs={12} direction="row" justify="space-between">
              <Grid xs={5}>{i18next.t('inventory_web_atoir:yourMark')}</Grid>
              <Grid xs={5}>
                <Rating rating={rating} getRating={handleRatingChange} />
              </Grid>
            </Grid>
            <Grid>
              <TextField
                id="comment"
                required={rating < 4}
                multiline
                fullWidth
                label={i18next.t('inventory_web_atoir:yourComment')}
                placeholder={i18next.t('inventory_web_atoir:leaveYourComment')}
                className={classes.textField}
                margin="normal"
                onChange={handleTextChange}
                value={comment}
                error={!isEmpty(commentValidation)}
                helperText={commentValidation}
              />
            </Grid>
          </Grid>
        }
      />
    )
  }
}

SetRatingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  positiveAction: PropTypes.func.isRequired,
  negativeAction: PropTypes.func.isRequired,
  rating: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
}

export default withStyles(styles)(SetRatingDialog)
