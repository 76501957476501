import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// material UI
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography/Typography'

// components
import EquipmentName from './EquipmentName'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import i18next from '../../../../i18n/i18n'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { getUserFullName } from '../../../../helpers/userHelper'

const styles = theme => ({
  EnhancedCell: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'white',
    borderBottom: theme.typography.pxToRem(1),
    borderBottomStyle: 'solid',
    borderBottomColor: lighten(theme.palette.secondary.light, 0.85),
    '&:last-child': {
      paddingRight: 0,
    },
  },
  divItemRow: {
    display: 'flex',
    width: '100%',
    paddingRight: theme.typography.pxToRem(24),
    paddingLeft: theme.typography.pxToRem(24),
    paddingTop: theme.typography.pxToRem(4),
    paddingBottom: theme.typography.pxToRem(4),
  },
  divDetailInfo: {
    width: '100%',
    textAlign: 'left',
  },
  buttonStyle: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
  },
  divCheckBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  warnings: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: theme.typography.pxToRem(12),
  },
  priorityHigh: {
    color: '#F44336',
    fontSize: theme.typography.pxToRem(16),
    verticalAlign: 'sub',
  },
})

const ItemRow = withStyles(styles)(props => {
  const { item, classes, onChange, checked, editable, contractStatus } = props
  return (
    <ButtonBase
      className={classes.buttonStyle}
      focusRipple
      focusVisibleClassName={classes.focusVisible}
      onClick={() => {
        onChange(item.id)
      }}
      style={{
        width: '100%',
      }}
    >
      <div className={classes.divItemRow}>
        <div className={classes.divCheckBox}>
          {editable && (
            <Checkbox
              checked={checked.indexOf(item.id) !== -1}
              value={item.id}
              color="primary"
            />
          )}
        </div>
        <div>
          {item.externalId && (
            <div className={classes.divDetailInfo}>
              <Typography variant="body1">
                Внеш.ID:
                {item.externalId}
              </Typography>
            </div>
          )}
          <div className={classes.divDetailInfo}>
            <EquipmentName data={item.name} />
          </div>
          <div className={classes.divDetailInfo}>
            <Typography variant="body1">
              {item.vendor} {item.model}
            </Typography>
          </div>
          {item.department && (
            <div className={classes.divDetailInfo}>
              <Typography variant="body1">
                {i18next.t('organizations_web_atoir:department')}:{' '}
                {item.department}
              </Typography>
            </div>
          )}
          {item.engineer === null && contractStatus === 'inwork' && (
            <div className={classes.divDetailInfo}>
              <Typography
                component="span"
                variant="body1"
                className={classes.warnings}
              >
                <PriorityHigh className={classes.priorityHigh} />{' '}
                {i18next.t(
                  'service_desk_web_atoir:equipmentWithNoEngineerExists',
                )}
              </Typography>
            </div>
          )}
          {item.engineer && (
            <div className={classes.divDetailInfo}>
              <Typography>
                {i18next.t('service_desk_web_atoir:engineer')}:{' '}
                {getUserFullName(item.engineer)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </ButtonBase>
  )
})

const AddressRow = withStyles(styles)(props => {
  const { item } = props
  return (
    <div className={props.classes.divItemRow}>
      <Typography variant="subtitle2">{item.currAddr}</Typography>
    </div>
  )
})

const EnhancedRow = withStyles(styles)(props => {
  const { item, classes } = props
  return (
    <TableRow>
      <TableCell className={classes.EnhancedCell}>
        {item.typeRow === 'addressRow' ? (
          <AddressRow {...props} />
        ) : (
          <ItemRow {...props} />
        )}
      </TableCell>
    </TableRow>
  )
})

class EquipmentsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: [],
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  onChange = id => {
    const {
      state: { checked },
    } = this
    const pos = checked.indexOf(id)
    if (pos !== -1) {
      checked.splice(pos, 1)
    } else {
      checked.push(id)
    }
    this.setState(checked)
  }

  render() {
    const {
      props: { data, checked, change, contractStatus, editable },
    } = this
    return (
      <Fragment>
        {data.map(item => (
          <EnhancedRow
            contractStatus={contractStatus}
            key={item.id}
            item={item}
            editable={editable}
            onChange={change}
            checked={checked}
          />
        ))}
      </Fragment>
    )
  }
}

EquipmentsList.defaultProps = {
  editable: true,
}

EquipmentsList.propTypes = {
  data: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  editable: PropTypes.bool,
}

export default withStyles(styles)(EquipmentsList)
