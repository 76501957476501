export const CREATE_TAG_REQUEST = 'tags/CREATE_TAG_REQUEST'
export const CREATE_TAG_SUCCESS = 'tags/CREATE_TAG_SUCCESS'
export const CREATE_TAG_FAILURE = 'tags/CREATE_TAG_FAILURE'

export const EDIT_TAG_REQUEST = 'tags/EDIT_TAG_REQUEST'
export const EDIT_TAG_SUCCESS = 'tags/EDIT_TAG_SUCCESS'
export const EDIT_TAG_FAILURE = 'tags/EDIT_TAG_FAILURE'

export const GET_TAGS_REQUEST = 'tags/GET_TAGS_REQUEST'
export const GET_TAGS_SUCCESS = 'tags/GET_TAGS_SUCCESS'
export const GET_TAGS_FAILURE = 'tags/GET_TAGS_FAILURE'

export const DELETE_TAG_REQUEST = 'tags/DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'tags/DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'tags/DELETE_TAG_FAILURE'

export const GET_TAG_REQUEST = 'tags/GET_TAG_REQUEST'
export const GET_TAG_SUCCESS = 'tags/GET_TAG_SUCCESS'
export const GET_TAG_FAILURE = 'tags/GET_TAG_FAILURE'
