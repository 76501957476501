import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TableRequestComponent from './TableRequestComponent'
import { fetchUserOrganizationFunctionalsRequest } from '../../../../actions/organizationsActions'

const mapStateToProps = state => ({
  dataServicesCalls: state.serviceDesk.serviceCalls.data,
  callsCount: state.serviceDesk.serviceCallsCount.count,
  dataServiceCallOrders: state.serviceDesk.serviceCallOrders.data,
  ordersCount: state.serviceDesk.serviceCallOrdersCount.count,
  isOrganizationFunctionalsAvailable:
    state.organizations.organizationFunctionals
      .isOrganizationFunctionalsAvailable,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrgFuncs: fetchUserOrganizationFunctionalsRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableRequestComponent),
)
