import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TablePagination from '@material-ui/core/TablePagination'

import i18next from '../../../../../i18n/i18n'

import DelayedLoader from '../../../../../widgets/DelayedLoader'
import DateCell from './DateCell'
import CommentCell from './CommentCell'
import ActionViewer from './ActionViewer'
import Typography from '@material-ui/core/Typography'

const styles = {
  table: {
    minWidth: 550,
    tableLayout: 'fixed',
  },
  border: { border: 0 },
}

function HistoryTable({
  classes,
  data,
  dataIsFetching,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  count,
}) {
  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <DateCell classes={{ root: classes.border }}>
              <Typography color="textSecondary">
                {i18next.t('service_desk_web_atoir:whenTableHistoryTitle')}
              </Typography>
            </DateCell>
            <TableCell classes={{ root: classes.border }}>
              <Typography color="textSecondary">
                {i18next.t('service_desk_web_atoir:whoTableHistoryTitle')}
              </Typography>
            </TableCell>
            <CommentCell classes={{ root: classes.border }}>
              <Typography color="textSecondary">
                {i18next.t('service_desk_web_atoir:whotTableHistoryTitle')}
              </Typography>
            </CommentCell>
          </TableRow>
        </TableHead>
        <DelayedLoader delay={300} show={dataIsFetching} />
        <TableBody>
          {data &&
            data.map(action => (
              <ActionViewer key={action.sch_id || action.id} action={action} />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  )
}

HistoryTable.propTypes = {
  data: PropTypes.array.isRequired,
  dataIsFetching: PropTypes.bool.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
}

export default withStyles(styles)(HistoryTable)
