import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

import { closeSnackbar } from '../../actions/appActions'

const styles = () => ({
  icon: {
    fontSize: 20,
  },
})

function NotifierButton({ notificationKey, callCloseSnackbar, classes }) {
  const onClose = () => {
    callCloseSnackbar(notificationKey)
  }
  return (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={onClose}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ callCloseSnackbar: closeSnackbar }, dispatch)

const CustomNotifierButton = withStyles(styles)(
  connect(null, mapDispatchToProps)(NotifierButton),
)

export default key => <CustomNotifierButton notificationKey={key} />
