import React from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../i18n/i18n'

// helpers
import { visitDateParserOrderSD } from '../../../../../helpers/dateHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Edit from '@material-ui/icons/Edit'

// components
import LongTextExpander from '../../../../../widgets/LongTextExpander'
import RepairDateReminder from '../../RepairDateReminder'

const styles = theme => ({
  dateVisitText: {
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 'normal',
    marginRight: theme.spacing(1),
  },
  commentVisitText: {
    color: theme.palette.text.main,
    fontSize: 12,
    fontWeight: 'normal',
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.main,
  },
})

const VisitInfo = ({
  dateVisit,
  commentVisit,
  onEdit,
  classes,
  orderType,
  orderId,
  order,
  engineer,
}) => (
  <React.Fragment>
    <RepairDateReminder
      orderId={orderId}
      order={order}
      canEdit={Boolean(onEdit)}
      engineer={engineer}
    />
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container flexDirection="row" alignItems="center">
          <Grid item>
            <Typography className={classes.dateVisitText}>
              {`${i18next.t('service_desk_web_atoir:dateVisit')}: `}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dateVisitText}>
              {visitDateParserOrderSD(order)}
            </Typography>
          </Grid>
          <Grid item>
            {!!onEdit &&
              ((orderType === 'diagnostic' && !!dateVisit) ||
                orderType === 'repair') && (
                <Grid item>
                  <IconButton onClick={onEdit}>
                    <Edit className={classes.icon} />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      {!!commentVisit && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <LongTextExpander
            Wrapper={Typography}
            WrapperProps={{ className: classes.commentVisitText }}
          >
            {`${i18next.t('service_desk_web_atoir:note')}: ${commentVisit}`}
          </LongTextExpander>
        </Grid>
      )}
    </Grid>
  </React.Fragment>
)

VisitInfo.defaultProps = {
  onEdit: null,
}

VisitInfo.propTypes = {
  dateVisit: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(VisitInfo)
