import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  avatar: {
    margin: 0,
    color: '#fff',
    width: 150,
    height: 150,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
  },
  rootDiv: {
    position: 'relative',
    width: 150,
    height: 150,
  },
})

const UserAvatar = props => (
  <div className={props.classes.row}>
    <div className={props.classes.rootDiv}>
      <Avatar src={props.imageUrl} className={props.classes.avatar} />
      {props.canClose && (
        <IconButton
          className={props.classes.closeBtn}
          color="inherit"
          onClick={props.closeHandler}
          aria-label="Close"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </div>
  </div>
)

UserAvatar.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default withStyles(styles)(UserAvatar)
