import React from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    margin: 8,
    color: 'white',
    backgroundColor: theme.palette.blueSecondary.main,
    '&:hover': {
      background: theme.palette.blueSecondary.dark,
      color: 'white',
    },
  },
})

function ContainedButton(props) {
  return (
    <Button
      variant="contained"
      size={props.size}
      className={props.classes.root}
      {...props}
    >
      {props.children}
    </Button>
  )
}

ContainedButton.defaultProps = {
  children: '',
  size: 'small',
}

ContainedButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
}

export default withStyles(styles)(ContainedButton)
