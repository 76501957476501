import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import { validateEmail } from '../../../helpers/inputDataValidators'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import FormComponent from '../../../widgets/FormComponent/index'
import FormDialog from '../../../widgets/FormDialog/index'

// styles
import '../../../styles/components/Login.css'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'

const styles = () => ({})

class SignInFormComponent extends Component {
  state = {
    email: null,
    password: null,
    sendEmail: null,
    dialogOpen: false,
    repairErrors: {},
    errors: {},
  }

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleDialogOpen = () => {
    const { email } = this.state
    this.setState({ dialogOpen: true, sendEmail: email })
  }

  handleDialogClose = () => {
    const { repairPasswordCancel } = this.props
    repairPasswordCancel()
    this.setState({
      dialogOpen: false,
      repairErrors: {},
      errors: {},
      sendEmail: '',
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isRequestingAccessData !== this.props.isRequestingAccessData &&
      !this.props.isRequestingAccessData &&
      isEmpty(this.props.validationErrors)
    ) {
      this.props.history.push('/profile')
    } else if (
      prevProps.isRepairingPassword !== this.props.isRepairingPassword &&
      !this.props.isRepairingPassword &&
      isEmpty(this.props.repairPasswordErrors)
    ) {
      this.props.history.push('/repair_link_sent')
    } else if (
      prevProps.repairPasswordErrors !== this.props.repairPasswordErrors
    ) {
      this.setState({
        repairErrors: this.props.repairPasswordErrors,
      })
    } else if (
      prevProps.isRegisteringEmail !== this.props.isRegisteringEmail &&
      !this.props.isRegisteringEmail &&
      isEmpty(this.props.sendLinkAgainErrors)
    ) {
      this.props.history.push('/email_sent')
    }
    if (prevProps.sendLinkAgainErrors !== this.props.sendLinkAgainErrors) {
      this.setState({
        repairErrors: this.props.sendLinkAgainErrors,
      })
    }
  }

  render() {
    const {
      classes,
      isRequestingAccessData,
      isRepairingPassword,
      shouldResendLink,
      validationErrors,
    } = this.props
    const { repairErrors, errors } = this.state

    const emailHelperText = !isEmpty(errors.email)
      ? errors.email
      : !isEmpty(validationErrors.email)
      ? validationErrors.email[0]
      : ''

    const passwordHelperText = !isEmpty(validationErrors.password)
      ? validationErrors.password[0]
      : ''

    const sendEmailHelperText = !isEmpty(errors.sendEmail)
      ? errors.sendEmail
      : !isEmpty(repairErrors)
      ? !isEmpty(repairErrors.email)
        ? repairErrors.email[0]
        : repairErrors.password[0]
      : ''
    const secondaryButtonsArray = shouldResendLink
      ? [
          {
            handleSecondaryAction: this.getConfirmLinkAgain,
            secondaryText: `${i18next.t(
              'login_web_atoir:sendRegistrationLinkAgain',
            )}`,
          },
        ]
      : []

    return (
      <Fragment>
        <FormComponent
          titleForm={`${i18next.t('login_web_atoir:authorization')}`}
          submitText={`${i18next.t('login_web_atoir:authorization')}`}
          isLoading={isRequestingAccessData}
          handleSubmitAction={this.handleLoginForm}
          secondaryButtonsArray={[
            {
              secondaryText: `${i18next.t('login_web_atoir:forgotPassword')}`,
              handleSecondaryAction: this.handleDialogOpen,
            },
          ]}
        >
          <FormGroup>
            <TextField
              error={!isEmpty(errors.email) || !isEmpty(validationErrors.email)}
              required
              autoFocus
              id="email"
              type="email"
              label={i18next.t('login_web_atoir:email')}
              placeholder={i18next.t('login_web_atoir:enterEmail')}
              className={classes.textField}
              value={this.state.email}
              margin="normal"
              helperText={emailHelperText}
              onChange={this.handleChange('email')}
            />
            <TextField
              error={
                !isEmpty(validationErrors.password) || !isEmpty(errors.password)
              }
              required
              id="password"
              type="password"
              label={i18next.t('login_web_atoir:password')}
              placeholder={i18next.t('login_web_atoir:enterPassword')}
              autoComplete="current-password"
              className={classes.textField}
              value={this.state.password}
              margin="normal"
              helperText={passwordHelperText}
              onChange={this.handleChange('password')}
            />
          </FormGroup>
        </FormComponent>
        <FormDialog
          open={this.state.dialogOpen}
          fullWidth
          isLoading={isRepairingPassword}
          title={i18next.t('login_web_atoir:repairPasswordDialogTitle')}
          onClose={this.handleDialogClose}
          onExit={this.handleDialogClose}
          onSubmit={this.handleRepairForm}
          secondaryButtonsArray={secondaryButtonsArray}
          submitButtonTitle={i18next.t(
            'login_web_atoir:sendPasswordRecoveryLink',
          )}
          contentText={
            shouldResendLink
              ? i18next.t('login_web_atoir:sendRegistrationLinkAgainDialogText')
              : i18next.t('login_web_atoir:repairPasswordDialogText')
          }
        >
          <TextField
            error={!isEmpty(errors.sendEmail) || !isEmpty(repairErrors.email)}
            required
            fullWidth
            id="sendEmail"
            type="email"
            label={i18next.t('login_web_atoir:email')}
            placeholder={i18next.t('login_web_atoir:enterEmail')}
            className={classes.textField}
            helperText={sendEmailHelperText}
            margin="normal"
            onChange={this.handleChange('sendEmail')}
            value={this.state.sendEmail}
          />
        </FormDialog>
      </Fragment>
    )
  }

  handleLoginForm = () => {
    const { login } = this
    login()
  }

  handleRepairForm = () => {
    const { repairPassword } = this
    repairPassword()
  }

  login = () => {
    const { email, password } = this.state
    const validEmail = validateEmail((email || '').trim())
    const errors = {}
    if (!validEmail) {
      errors.email = i18next.t('validation_web_atoir:incorrectEmailFormat')
    }
    if (!password) {
      errors.password = i18next.t(
        'validation_web_atoir:incorrectPasswordFormat',
      )
    }
    if (!isEmpty(errors)) {
      this.setState({
        errors,
      })
      return
    }
    this.setState({
      errors: {
        email: null,
      },
    })

    const { login } = this.props
    login(email.toLowerCase().trim(), password)
  }

  repairPassword = () => {
    const { sendEmail } = this.state
    const validEmail = !isEmpty(sendEmail) && validateEmail(sendEmail.trim())
    if (!validEmail) {
      this.setState({
        errors: {
          sendEmail: i18next.t('validation_web_atoir:incorrectEmailFormat'),
        },
      })
      return
    }
    this.setState({
      errors: {
        sendEmail: null,
      },
    })

    const { repairPassword } = this.props
    repairPassword(sendEmail.toLowerCase().trim())
  }

  getConfirmLinkAgain = () => {
    const { sendEmail } = this.state
    this.props.clearValidationErrors()
    const validEmail = validateEmail(sendEmail.trim())
    if (!validEmail) {
      this.setState({
        errors: {
          sendEmail: i18next.t('validation_web_atoir:incorrectEmailFormat'),
        },
      })
      return
    }
    this.setState({
      errors: {
        sendEmail: null,
      },
    })

    const { getConfirmLinkAgain } = this.props
    getConfirmLinkAgain(sendEmail.toLowerCase().trim())
  }
}

SignInFormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  isRequestingAccessData: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  repairPassword: PropTypes.func.isRequired,
  getConfirmLinkAgain: PropTypes.func.isRequired,
  isRepairingPassword: PropTypes.bool.isRequired,
  shouldResendLink: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  repairPasswordErrors: PropTypes.object.isRequired,
}

export default withStyles(styles)(SignInFormComponent)
