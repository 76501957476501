import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EquipmentTable from '../../EquipmentTable'
import { URL } from '../../../../../middlewares/api'
import { canEditAgreement } from '../../../../../helpers/ContractPermissionsResolver'

class AgreementEquipmentTable extends Component {
  state = {
    checked: [],
  }

  componentDidMount() {
    if (this.props.customRef) {
      this.props.customRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDeletingEquipment !== this.props.isDeletingEquipment &&
      !this.props.isDeletingEquipment &&
      !this.props.deleteEquipmentError
    ) {
      this.tableRef.loadEquipmentData()
      this.tableRef.loadEquipmentCount()
      this.setState({ checked: [] })
    }
  }

  onChange = checked => {
    this.setState({
      checked,
    })
  }

  hasEquipment = () =>
    this.props.availableEquipment && this.props.availableEquipment.length > 0

  onDeleteEquipment = () => {
    const {
      props: { deleteAgreementEquipmentsRequest, agreement },
      state: { checked },
    } = this
    deleteAgreementEquipmentsRequest({
      equipmentsIds: checked,
      id: agreement.contract.id,
      agreementId: agreement.id,
    })
  }

  render() {
    const {
      state: { checked },
      props: {
        permissions,
        availableEquipment,
        availableEquipmentIsFetching,
        availableEquipmentFetchingError,
        fetchAgreementEquipmentsRequest,
        fetchAgreementEquipmentsCountRequest,
        equipmentCount,
        equipmentCountIsFetching,
        equipmentCountFetchingError,
        agreement,
        match,
        history,
      },
      onDeleteEquipment,
      onChange,
    } = this
    return (
      <div>
        <EquipmentTable
          createRef={ref => {
            this.tableRef = ref
          }}
          onAddEquipmentClicked={() =>
            history.push(`${match.url}/add_equipment`)
          }
          onDeleteEquipment={onDeleteEquipment}
          locationsFetchUrl={`${URL}/service_desk_contracts/${match.params.contractId}/filters/locations`}
          departmentsFetchUrl={`${URL}/service_desk_contracts/${match.params.contractId}/filters/departments`}
          canAddOrDelete={canEditAgreement(permissions, agreement.status)}
          canEdit={canEditAgreement(permissions, agreement.status)}
          equipmentCount={equipmentCount}
          equipmentCountIsFetching={equipmentCountIsFetching}
          equipmentCountFetchingError={equipmentCountFetchingError}
          fetchEquipment={query =>
            fetchAgreementEquipmentsRequest({
              id: match.params.contractId,
              agreementId: match.params.agreementId,
              ...query,
            })
          }
          fetchCount={query =>
            fetchAgreementEquipmentsCountRequest({
              id: match.params.contractId,
              agreementId: match.params.agreementId,
              ...query,
            })
          }
          availableEquipment={availableEquipment}
          availableEquipmentIsFetching={availableEquipmentIsFetching}
          availableEquipmentFetchingError={availableEquipmentFetchingError}
          checked={checked}
          onChange={onChange}
          permissions={permissions}
        />
      </div>
    )
  }
}

AgreementEquipmentTable.propTypes = {
  permissions: PropTypes.object.isRequired,
  agreement: PropTypes.object.isRequired,
}

export default AgreementEquipmentTable
