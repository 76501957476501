import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Heading from '../../../../widgets/Lettering/Heading'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import i18next from '../../../../i18n/i18n'
import Grid from '@material-ui/core/Grid'
import RequestCreationReason from './widgets/RequestCreationReason'
import EquipmentChoosingList from './widgets/EquipmentChoosingList'
import TextField from '@material-ui/core/TextField/TextField'
import { serviceDeskRequestType } from '../../../../constants/constants'
import { URL } from '../../../../middlewares/api'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import SelectLimitedAuto from '../../../../widgets/SelectLimitedAuto'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'

const styles = () => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },
  initDataGrid: {
    padding: 12,
  },
  equipmentListComponent: {
    padding: 12,
  },
  dataPaperRoot: {
    paddingBottom: 12,
  },
  commentField: {
    padding: 12,
  },
  button: {
    marginRight: 0,
    marginLeft: 0,
    minWidth: 94,
  },
})

class ChoosingEquipment extends Component {
  state = {
    errors: {},
  }

  onChange = field => event => {
    const value = event.target ? event.target.value : event
    const {
      props: { data, setRequestData },
    } = this
    if (data[field] === value) {
      return
    }
    if (field === 'clientOrganization') {
      setRequestData({ [field]: value, equipment: null })
    } else {
      setRequestData({ [field]: value })
    }
  }

  onChangeCreationData = field => value => {
    const {
      props: { setRequestData },
    } = this
    setRequestData({ [field]: value })
  }

  onChangeCreationDataRaw = field => event => {
    this.onChangeCreationData(field)(event.target.value)
  }

  handlePositiveClick = () => {
    const {
      props: {
        handleNext,
        data: { equipment: checkedEquipment, comment, requestCreationReason },
      },
    } = this
    const errors = {}
    if (!checkedEquipment || checkedEquipment === '') {
      errors.equipmentListError = i18next.t(
        'service_desk_web_atoir:requestCreationNoEquipmentValidationError',
      )
    }
    if (!comment || comment === '') {
      errors.commentError = i18next.t(
        'service_desk_web_atoir:requestCreationNoCommentValidationError',
      )
    }
    if (
      !Object.values(serviceDeskRequestType).some(
        s => s === requestCreationReason,
      )
    ) {
      errors.requestCreationReasonError = i18next.t(
        'service_desk_web_atoir:requestCreationNoReasonValidationError',
      )
    }
    if (Object.keys(errors).length === 0) {
      handleNext()
    } else {
      this.setState({ errors })
    }
  }

  render() {
    const {
      state: { errors },
      props: {
        classes,
        handleBack,
        setRequestData,
        data: {
          requestCreationReason,
          clientOrganization,
          defaultLocation,
          equipment: checkedEquipment,
          comment,
          extNumber,
        },
      },
      onChange,
      onChangeCreationData,
      onChangeCreationDataRaw,
      handlePositiveClick,
    } = this
    return (
      <Fragment>
        <Paper className={classes.dataPaperRoot}>
          <div className={classes.heading}>
            <Heading>
              <Typography variant="h6">
                {i18next.t('service_desk_web_atoir:requestCreationReason')}
              </Typography>
            </Heading>
          </div>
          <Divider />
          <Grid container spacing={2} className={classes.initDataGrid}>
            <Grid item xs={6}>
              <RequestCreationReason
                onChange={onChange}
                value={requestCreationReason}
              />
              <Typography color="error" align="left">
                {errors.requestCreationReasonError}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl} required>
                <InputLabel htmlFor="age-simple">
                  {i18next.t(
                    'service_desk_web_atoir:requestCreationClientOrganization',
                  )}
                </InputLabel>
                <SelectLimitedAuto
                  value={clientOrganization}
                  sourceUrl={`${URL}/users/members`}
                  query={{
                    filters: JSON.stringify({ state: 1 }),
                    sort: '[short_organization_name=ASC]',
                    block: 'sd_extended',
                  }}
                  onChange={this.onChange('clientOrganization')}
                  requestResultTransformerToArray={r => r.members}
                  itemTransformer={l => ({
                    id: l.Organization.id,
                    title: l.Organization.short_name,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={extNumber}
                fullWidth
                onChange={onChange('extNumber')}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationExtNumLabel',
                )}
              />
            </Grid>
          </Grid>
        </Paper>
        <br />
        {clientOrganization && (
          <Paper className={classes.dataPaperRoot}>
            <div className={classes.heading}>
              <Heading>
                <Typography variant="h6" data-cy="requestCreationEquipmentList">
                  {i18next.t(
                    'service_desk_web_atoir:requestCreationEquipmentList',
                  )}
                </Typography>
              </Heading>
            </div>
            <Divider />
            <div className={classes.equipmentListComponent}>
              <EquipmentChoosingList
                setData={setRequestData}
                checkedEquipment={checkedEquipment}
                onChangeEquipment={onChangeCreationData('equipment')}
                defaultFilters={{ location: defaultLocation }}
                organizationId={clientOrganization}
              />
              <Typography color="error" align="left">
                {errors.equipmentListError}
              </Typography>
            </div>
            <div className={classes.commentField}>
              <TextField
                id="commentTextField"
                required
                onChange={onChangeCreationDataRaw('comment')}
                variant="outlined"
                multiline
                value={comment}
                fullWidth
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationCommentLabel',
                )}
                inputProps={{
                  name: 'CommentTextField',
                }}
              />
              <Typography color="error" align="left">
                {errors.commentError}
              </Typography>
            </div>
            <div className={classes.actionsSection}>
              <OutlinedButton
                size="medium"
                classes={{ root: classes.button }}
                onClick={handleBack}
              >
                {i18next.t('shared_web_atoir:cancel')}
              </OutlinedButton>
              <ContainedButton
                size="medium"
                classes={{ root: classes.button }}
                onClick={handlePositiveClick}
                data-cy="PositiveClick"
              >
                {i18next.t('shared_web_atoir:next')}
              </ContainedButton>
            </div>
          </Paper>
        )}
      </Fragment>
    )
  }
}

ChoosingEquipment.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setRequestData: PropTypes.func.isRequired,
}

export default withStyles(styles)(ChoosingEquipment)
