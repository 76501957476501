import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TrustedOrganizations from './TrustedOrganizations'
import {
  changeStatus,
  fetchTrustsCountRequest,
  fetchTrustsRequest,
} from '../../actions/trustedOrganizationActions'
import { clearFilterDrawer, setFilterDrawer } from '../../actions/appActions'

const mapStateToProps = state => ({
  status: state.trustedOrganizations.status,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrustsRequest,
      fetchTrustsCountRequest,
      changeStatus,
      setFilterDrawer,
      clearFilterDrawer,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrustedOrganizations),
)
