import React from 'react'
import { bindActionCreators } from 'redux'

import withContinuousLoad from './ContinuousLoadHOC'
import { getCallsByGeneralSearch } from '../../../../actions/serviceDesk'

const mapStateToPropsForHOC = state => ({
  data: state.serviceDesk.callsByNumberSearch.calls,
  fetching: state.serviceDesk.callsByNumberSearch.isFetching,
  error: state.serviceDesk.callsByNumberSearch.error,
})

const mapDispatchToPropsForHOC = dispatch =>
  bindActionCreators(
    {
      fetchAction: getCallsByGeneralSearch,
    },
    dispatch,
  )

function InterfaceToSection({
  componentToConnect,
  data,
  dataEnd,
  getData,
  searchValue,
}) {
  const loadWrapper = () => {
    getData({ search_value: searchValue })
  }
  return (
    <React.Fragment>
      {React.cloneElement(componentToConnect, {
        data,
        dataEnd,
        getData: loadWrapper,
      })}
    </React.Fragment>
  )
}

const SectionConnector = withContinuousLoad(
  mapStateToPropsForHOC,
  mapDispatchToPropsForHOC,
  'data',
  'getData',
  0,
  10,
)(InterfaceToSection)

function ManagerSD({ children: section, searchValue, maxFirstDataCount }) {
  return (
    <React.Fragment>
      <SectionConnector
        key={searchValue}
        componentToConnect={section}
        searchValue={searchValue}
        {...{ maxFirstDataCount }}
      />
    </React.Fragment>
  )
}

export default ManagerSD
