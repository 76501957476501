import React from 'react'
import IndustriesSelector from '../common/IndustriesSelector'
import { Grid } from '@material-ui/core'
import VendorSelector from './VendorSelector'
import ModelInput from './ModelInput'

function DictionaryForm({
  industry,
  vendor,
  model,
  vendorCandidate,
  modelTouched,
  onFocusModel,
  onChange,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <IndustriesSelector
          value={industry}
          idValue="industry"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorSelector
          key={vendorCandidate || 'vendor-selector-empty'}
          vendorCandidate={vendorCandidate}
          value={vendor}
          onChange={onChange}
          idValue="vendor"
        />
      </Grid>
      <Grid item xs={12} md={4} container alignItems="flex-end">
        <ModelInput
          value={model || ''}
          onChange={onChange}
          idValue="model"
          disabled={!vendor}
          modelTouched={modelTouched}
          onFocusModel={onFocusModel}
        />
      </Grid>
    </Grid>
  )
}
export default DictionaryForm
