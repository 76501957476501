import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

function NumberFormatCustom(props) {
  const { getInputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={getInputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      decimalSeparator=","
      thousandSeparator=" "
      decimalScale={2}
      allowNegative={false}
    />
  )
}

NumberFormatCustom.defaultProps = {
  getInputRef: () => {},
}

NumberFormatCustom.propTypes = {
  getInputRef: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom
