import React from 'react'

import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import { appActions, useAppDispatch, useAppState } from '../../appContext'
import ManagerSD from './ManagerSD'
import Section from './Section'
import ResultRenderSD from './ResultRenderSD'
import useWindowDimensions from './useWindowDimensions'
import BreadCrumb from '../../routing/BreadCrumb'
import i18next from '../../../../i18n/i18n'

const Box = styled.div`
  ${spacing}
`
const Inline = styled.span`
  ${spacing}
`

function SearchResult({ match: { url } }) {
  const dispatch = useAppDispatch()
  const { globalSearchString: searchValue } = useAppState()
  const { height } = useWindowDimensions()
  const [maxFirstDataCount, setMaxFirstDataCount] = React.useState(3)

  React.useEffect(() => {
    return () => {
      closeSearch()
    }
  }, [])

  React.useEffect(() => {
    setMaxFirstDataCount(Math.round((height - 200 - 70) / 76))
  }, [height])

  const closeSearch = () => {
    dispatch(appActions.setGlobalSearchShow(false))
  }
  return (
    <React.Fragment>
      <BreadCrumb to={url}>
        {i18next.t('shared_web_atoir:searchResult')}
      </BreadCrumb>
      <Box>
        {searchValue && (
          <React.Fragment>
            <Box mb={2} mt={-2}>
              <Typography inline variant="subtitle2" color="secondary">
                {i18next.t('shared_web_atoir:searchResultFor')}
              </Typography>
              <Inline ml={1}>
                <Typography
                  inline
                  variant="subtitle1"
                >{`"${searchValue}"`}</Typography>
              </Inline>
            </Box>
            <ManagerSD
              maxFirstDataCount={maxFirstDataCount}
              searchValue={searchValue}
            >
              <Section tittle={i18next.t('shared_web_atoir:sdSection')}>
                <ResultRenderSD {...{ searchValue, closeSearch }} />
              </Section>
            </ManagerSD>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

export default SearchResult
