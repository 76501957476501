import React from 'react'
import { render } from 'react-dom'
import { ThroughProvider } from 'react-through'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import store from './store/configureStore'
import registerServiceWorker from './registerServiceWorker'
import Root from './containers/Root'

import './i18n/i18n'
// app color scheme
import theme from './styles/colorTheme'

render(
  <Router basename="/app">
    <ThroughProvider>
      <MuiThemeProvider theme={theme}>
        <Root store={store} />
      </MuiThemeProvider>
    </ThroughProvider>
  </Router>,
  document.getElementById('root'),
)
registerServiceWorker()
