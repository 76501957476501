import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Loader from '../../Loader'
import DropDownList from '../../../widgets/DropDownList'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  wrapperRoot: {
    height: '158px',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: 120,
  },
  formControl: {
    minWidth: 200,
  },
  text: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
})

class ServiceOrganizationsSelect extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selectedOrganization: {},
      citiesOpen: false,
      city: '',
      name: '',
      id: '',
      filters: {},
      errors: {},
    }
  }

  componentDidMount() {
    const {
      props: {
        fetchServiceOrganizations,
        fetchCitiesWithServiceOrganizations,
        match: {
          params: { projectId },
        },
      },
    } = this
    fetchServiceOrganizations({ exclude_organization_by_project_id: projectId })
    fetchCitiesWithServiceOrganizations()
  }

  handleOpen = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleClose = field => () => {
    this.setState({
      [field]: false,
    })
  }

  handleChange = field => event => {
    const { updateFilters } = this
    const {
      target: { value },
    } = event

    this.setState({ [field]: value }, () => {
      if (field !== 'organization') {
        updateFilters(field, value)
      }
    })
  }

  handleToggle = value => () => {
    const {
      props: { handleOrganizationSelect },
    } = this
    this.setState({ selectedOrganization: value }, () =>
      handleOrganizationSelect(value),
    )
  }

  updateFilters = (field, value) => {
    const {
      props: {
        fetchServiceOrganizations,
        match: {
          params: { projectId },
        },
      },
    } = this
    const name = field === 'city' ? 'city_id' : field
    let f = {}
    if (name === 'id') {
      if (value.length !== 36 && value.length !== 0) {
        this.setState({
          errors: {
            id: i18next.t('validation_web_atoir:incompleteId'),
          },
        })
        return
      }
      f = {
        ...this.state.filters,
        filters: isEmpty(value) ? '' : `[id=${value}]`,
      }
    } else {
      f = { ...this.state.filters, [name]: value }
    }
    this.setState(
      {
        filters: f,
        errors: {},
      },
      () =>
        fetchServiceOrganizations({
          ...f,
          exclude_organization_by_project_id: projectId,
        }),
    )
  }

  render() {
    const {
      props: {
        classes,
        isFetchingCities,
        cities,
        isFetchingServiceOrganizations,
        serviceOrganizations,
      },
      state: { selectedOrganization, citiesOpen, city, name, id, errors },
    } = this

    const idHelperText = errors.id ? errors.id : ''

    return (
      <Fragment>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <FormLabel className={classes.formLabel}>
                {i18next.t('inventory_web_atoir:searchCity')}
              </FormLabel>
              <DropDownList
                classes={classes}
                open={citiesOpen}
                value={city}
                handleToogle={this.handleOpen('citiesOpen')}
                elements={
                  cities ? cities.map(c => ({ id: c.id, title: c.name })) : []
                }
                isFetching={isFetchingCities}
                handleOpen={this.handleOpen('citiesOpen')}
                handleClose={this.handleClose('citiesOpen')}
                handleChange={this.handleChange('city')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              id="name"
              label={i18next.t('organizations_web_atoir:organizationName')}
              placeholder={i18next.t(
                'organizations_web_atoir:enterOrganizationName',
              )}
              className={classes.textField}
              margin="normal"
              onChange={this.handleChange('name')}
              value={name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!isEmpty(errors.id)}
              fullWidth
              multiline
              id="id"
              label={i18next.t('equipment_web_atoir:organizationId')}
              placeholder={i18next.t('equipment_web_atoir:enterOrganizationId')}
              helperText={idHelperText}
              className={classes.textField}
              margin="normal"
              onChange={this.handleChange('id')}
              value={id}
              inputProps={{ maxlength: 36 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            alignItems="center"
            justify="center"
            className={classes.wrapperRoot}
          >
            <Typography variant="subtitle" className={classes.title}>
              {i18next.t('inventory_web_atoir:systemServiceOrganizations')}
            </Typography>
            <div className={classes.root}>
              {isFetchingServiceOrganizations && <Loader />}
              {serviceOrganizations.length === 0 ? (
                <Typography className={classes.text}>
                  {i18next.t('inventory_web_atoir:organizationsNotFound')}
                </Typography>
              ) : (
                <List>
                  {_.sortBy(serviceOrganizations, 'full_name').map(org => (
                    <ListItem
                      key={org.id}
                      dense
                      button
                      className={classes.listItem}
                    >
                      <ListItemText primary={`${org.full_name}`} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          onChange={this.handleToggle(org)}
                          checked={org === selectedOrganization}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

ServiceOrganizationsSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchServiceOrganizations: PropTypes.func.isRequired,
  isFetchingServiceOrganizations: PropTypes.bool.isRequired,
  serviceOrganizations: PropTypes.array.isRequired,
  fetchCitiesWithServiceOrganizations: PropTypes.func.isRequired,
  isFetchingCities: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired,
  handleOrganizationSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(ServiceOrganizationsSelect)
