import {
  FETCH_EMPLOYERS_REQUEST,
  FETCH_EMPLOYERS_SUCCESS,
  FETCH_EMPLOYERS_FAILURE,
  LEAVE_ORGANIZATION_REQUEST,
  LEAVE_ORGANIZATION_SUCCESS,
  LEAVE_ORGANIZATION_FAILURE,
  CONFIRM_JOIN_ORGANIZATION_REQUEST,
  CONFIRM_JOIN_ORGANIZATION_SUCCESS,
  CONFIRM_JOIN_ORGANIZATION_FAILURE,
  FETCH_EMPLOYERS_COUNT_REQUEST,
  FETCH_EMPLOYERS_COUNT_SUCCESS,
  FETCH_EMPLOYERS_COUNT_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  isFetchingEmployers: false,
  isFetchingEmployer: false,
  isLeavingOrganization: false,
  isConfirmingJoinOrganization: false,
  errors: {},
  count: null,
  employers: [],
  employer: {},
}

const employersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYERS_REQUEST:
      return {
        ...state,
        isFetchingEmployers: true,
      }

    case FETCH_EMPLOYERS_SUCCESS:
      return {
        ...state,
        isFetchingEmployers: false,
        employers: action.data,
      }

    case FETCH_EMPLOYERS_FAILURE:
      return {
        ...state,
        isFetchingEmployers: false,
        errors: action.errors,
      }
    case FETCH_EMPLOYERS_COUNT_REQUEST:
      return {
        ...state,
        isFetchingEmployersCount: true,
      }

    case FETCH_EMPLOYERS_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingEmployersCount: false,
        count: action.count,
      }

    case FETCH_EMPLOYERS_COUNT_FAILURE:
      return {
        ...state,
        isFetchingEmployersCount: false,
        errors: action.errors,
      }

    case LEAVE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isLeavingOrganization: true,
      }

    case LEAVE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLeavingOrganization: false,
      }

    case LEAVE_ORGANIZATION_FAILURE:
      return {
        ...state,
        isLeavingOrganization: false,
      }

    case CONFIRM_JOIN_ORGANIZATION_REQUEST:
      return {
        ...state,
        isConfirmingJoinOrganization: true,
      }

    case CONFIRM_JOIN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isConfirmingJoinOrganization: false,
      }

    case CONFIRM_JOIN_ORGANIZATION_FAILURE:
      return {
        ...state,
        isConfirmingJoinOrganization: false,
      }

    default:
      return state
  }
}

export default employersReducer
