import React from 'react'

function Sensitive({ collapsed, setCollapsed, children }) {
  const [timer, setTimer] = React.useState(null)

  React.useEffect(() => {
    if (collapsed) {
      setCollapsed(true)
      clearTimeout(timer)
    }
  }, [collapsed])

  const clearTimer = () => {
    clearTimeout(timer)
  }

  const handlerCollapsed = () => {
    setTimer(
      setTimeout(() => {
        setCollapsed(false)
      }, 500),
    )
  }

  const clickHandler = () => {
    clearTimeout(timer)
    // так как мы перезаписасили оригинальный onClick то нужно его вызвать вручную если он был задан
    if (_.get(children, 'props.onClick')) {
      children.props.onClick()
    }
  }

  return React.cloneElement(children, {
    onClick: clickHandler,
    onMouseEnter: handlerCollapsed,
    onMouseLeave: clearTimer,
  })
}

export default Sensitive
