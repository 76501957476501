import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import i18next from '../../../../i18n/i18n'
import Divider from '@material-ui/core/Divider'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Loader from '../../../Loader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import { withStyles } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import RestoreIcon from '@material-ui/icons/Restore'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  getOrganizationRecurringCallsCountRequest,
  getOrganizationRecurringCallsRequest,
} from '../../../../actions/recurringCallsActions'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
// eslint-disable-next-line max-len
import EditRecurringDialog from '../../../ServiceDesk/CallProfile/widgets/CallMainInfo/ReccuringDialogs/EditRecurringDialog'
import OutlineButton from '../../../../widgets/Buttons/OutlinedButton'
import { getUserFullName } from '../../../../helpers/userHelper'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listContainer: {
    width: '100%',
  },
  details: {
    padding: 0,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listItemSecondaryAction: {
    marginRight: theme.spacing(3),
    maxWidth: 90,
  },
  loader: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tagName: {
    marginRight: theme.spacing(11),
    wordBreak: 'break-word',
  },
  boundToActionText: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.34)',
    wordBreak: 'break-all',
  },
})

const useOnChangeExpansion = () => {
  const [expanded, setExpanded] = useState(false)
  const onChanged = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])
  return [expanded, onChanged]
}

const useOnChangeFromEvent = (defaultValue = 0) => {
  const [value, setPage] = useState(defaultValue)
  const onChanged = useCallback(
    (e, page) => {
      setPage(Number.isInteger(e.target.value) ? e.target.value : page)
    },
    [value],
  )
  return [value, onChanged]
}

const useFetchingRecurringCalls = (
  expanded,
  page,
  rowsPerPage,
  organizationId,
) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (expanded) {
      dispatch(
        getOrganizationRecurringCallsRequest({
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          organizationId,
        }),
      )
    }
  }, [expanded, page, rowsPerPage])
  useEffect(() => {
    if (expanded) {
      dispatch(
        getOrganizationRecurringCallsCountRequest({
          organizationId,
        }),
      )
    }
  }, [expanded])
}

const useFetchRecurringCalls = (organizationId, page, rowsPerPage) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(
      getOrganizationRecurringCallsRequest({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        organizationId,
      }),
    )
    dispatch(
      getOrganizationRecurringCallsCountRequest({
        organizationId,
      }),
    )
  }, [rowsPerPage, page, organizationId])
}

const parseDateShort = string =>
  string.split(' ').map(d => ({
    value: (d.toLowerCase().match(/(\d+)[dymq]/) || [undefined, '1'])[1],
    unit: (d.toLowerCase().match(/\d+([dymq])/) || [undefined, 'd'])[1],
  }))

function RecurringCalls({ classes, isFetching }) {
  const { organizationId } = useParams()
  const history = useHistory()
  const [expanded, onExpandedChange] = useOnChangeExpansion()

  const [page, onChangePage] = useOnChangeFromEvent(0)
  const [rowsPerPage, onChangeRowsPerPage] = useOnChangeFromEvent(10)
  useFetchingRecurringCalls(expanded, page, rowsPerPage, organizationId)

  const recurringCalls = useSelector(state => state.recurringCalls.calls.calls)
  const recurringCallsCount = useSelector(
    state => state.recurringCalls.callsCount.count,
  )
  const recurringIntervals = useMemo(() => {
    return recurringCalls.reduce((a, v) => {
      return { ...a, [v.id]: { ...parseDateShort(v.interval)[0], id: v.id } }
    }, {})
  }, [recurringCalls])

  const [editRecurringDialogOpen, setEditRecurringDialogOpen] = useState(null)

  const fetchRecurringCalls = useFetchRecurringCalls(
    organizationId,
    page,
    rowsPerPage,
  )
  return (
    <ExpansionPanel
      id="organization-settings-recurring-calls"
      component="div"
      expanded={expanded}
      onChange={onExpandedChange}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography className={classes.heading}>
              {i18next.t('organizations_web_atoir:recurring_calls_settings')}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails className={classes.details}>
        <Fragment>
          {isFetching ? (
            <Loader size={24} className={classes.loader} />
          ) : (
            <div className={classes.listContainer}>
              <List className={classes.list}>
                {recurringCalls.map(call => (
                  <Fragment>
                    <ListItem
                      className={classes.listItem}
                      id={call.id}
                      button
                      onClick={() =>
                        history.push(
                          `/service_desk/calls/inwork/${call.service_call_id}`,
                        )
                      }
                    >
                      <ListItemText
                        secondaryTypographyProps={{
                          variant: 'body2',
                          noWrap: true,
                        }}
                        primary={call.OrganizationEquipment.name}
                        primaryTypographyProps={{
                          variant: 'body1',
                          noWrap: true,
                        }}
                        secondary={
                          <span>
                            {call.performer.short_name}
                            <br />
                            {i18next.t('organizations_web_atoir:responsible', {
                              name: getUserFullName(call.client_responsible),
                            })}
                          </span>
                        }
                        className={classes.buttonName}
                      />
                      <ListItemSecondaryAction
                        className={classes.listItemSecondaryAction}
                      >
                        <OutlineButton
                          onClick={() => setEditRecurringDialogOpen(call)}
                        >
                          {console.log(recurringIntervals[call.id]) ||
                            (recurringIntervals[call.id] &&
                              `${recurringIntervals[call.id].value}${i18next.t(
                                `shared_web_atoir:shortDateUnit${recurringIntervals[
                                  call.id
                                ].unit.toUpperCase()}`,
                              )}`)}
                          <RestoreIcon />
                        </OutlineButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
              <Table>
                <TablePagination
                  count={recurringCallsCount || 0}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
                  labelDisplayedRows={({
                    from: fromPage,
                    to: toPage,
                    count: countPage,
                  }) =>
                    i18next.t('shared_web_atoir:labelDisplayedRows', {
                      fromPage,
                      toPage,
                      countPage,
                    })
                  }
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </Table>
            </div>
          )}
          {editRecurringDialogOpen !== null ? (
            <EditRecurringDialog
              open={editRecurringDialogOpen !== null}
              handleClose={() => setEditRecurringDialogOpen(null)}
              callId={editRecurringDialogOpen.service_call_id}
              recurringCall={editRecurringDialogOpen}
              afterCloseFunction={fetchRecurringCalls}
            />
          ) : null}
        </Fragment>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default withStyles(styles)(RecurringCalls)
