import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  closeInnerCall,
  editInnerCall,
  assigneeInnerCall,
} from '../../../../actions/innerCallsActions'
import { useDidUpdateEffect } from '../../../../helpers/hooks'

const InnerCallsModifier = ({ children, callId, update }) => {
  const dispatch = useDispatch()

  const { isFetching: isClosing } = useSelector(
    state => state.innerCalls.closeInnerCall,
  )
  const { isFetching: isEditing } = useSelector(
    state => state.innerCalls.editInnerCall,
  )
  const { isFetching: isAssigning } = useSelector(
    state => state.innerCalls.assigneeInnerCall,
  )

  useDidUpdateEffect(() => {
    if (!isClosing && !isEditing && !isAssigning) {
      update()
    }
  }, [isClosing, isEditing, isAssigning])

  function closeCall() {
    dispatch(closeInnerCall.REQUEST({ id: callId }))
  }
  function editCall({ description }) {
    dispatch(editInnerCall.REQUEST({ id: callId, description }))
  }
  function assigneeCall({ assigneeId }) {
    dispatch(assigneeInnerCall.REQUEST({ id: callId, assigneeId }))
  }

  return <>{children({ closeCall, editCall, assigneeCall })}</>
}

export default InnerCallsModifier
