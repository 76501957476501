import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import { removeMaskCharacters } from '../../../../helpers/phoneStringHelper'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../i18n/i18n'

// components
import OrganizationTypeIcon from '../../../Organizations/Stateless/OrganizationTypeIcon'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    minWidth: 100,
  },
})

function PhoneMask(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '+',
        '7',
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

class AddUserComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      lastName: '',
      firstName: '',
      middleName: '',
      position: '',
      phone: '',
      errors: {},
    }
  }

  componentDidUpdate(prevProps) {
    const { edit, user } = this.props
    if (prevProps.edit !== edit && edit && !isEmpty(user)) {
      this.setState({
        lastName: user.last_name,
        firstName: user.first_name,
        middleName: user.middle_name ? user.middle_name : '',
        position:
          user.is_owner && user.position === 'owner'
            ? i18next.t('organizations_web_atoir:ownerRole')
            : user.position,
        phone: user.phone ? `+${user.phone}` : '',
      })
    }
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleClose = () => {
    this.setState(
      {
        lastName: '',
        firstName: '',
        middleName: '',
        position: '',
        phone: '',
        errors: {},
      },
      () => this.props.closeDialog,
    )
  }

  handleSubmitForm = event => {
    event.preventDefault()
    const { addUser } = this
    addUser()
  }

  addUser = () => {
    const { lastName, firstName, middleName, position, phone } = this.state
    let phoneStr = ''
    if (!isEmpty(phone)) {
      phoneStr = removeMaskCharacters(phone)
    }
    const emptyErrors = this.validateEmptyFields(
      firstName.trim(),
      lastName.trim(),
      position.trim(),
      phoneStr.trim().length,
    )

    if (!isEmpty(emptyErrors)) {
      this.setState({
        errors: emptyErrors,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const {
      edit,
      user,
      organization,
      addOrganizationUser,
      updateOrganizationUser,
      startCreateUpdateOrganizationUser,
    } = this.props
    let data = _.omitBy(
      {
        first_name: firstName,
        last_name: lastName,
        position,
      },
      e => e === undefined || e === '',
    )
    data = {
      ...data,
      middle_name: middleName,
      phone: phoneStr,
    }

    if (edit) {
      updateOrganizationUser(organization.id, user.id, data)
    } else {
      addOrganizationUser(organization.id, data)
    }
    startCreateUpdateOrganizationUser()
  }

  validateEmptyFields = (firstName, lastName, position, phoneLength) => {
    const errors = {}
    if (isEmpty(lastName)) {
      errors.lastName = i18next.t('validation_web_atoir:lastNameShouldBeFilled')
    }
    if (isEmpty(firstName)) {
      errors.firstName = i18next.t(
        'validation_web_atoir:firstNameShouldBeFilled',
      )
    }
    if (isEmpty(position)) {
      errors.position = i18next.t('validation_web_atoir:fieldShouldBeFilled')
    }
    if (phoneLength >= 1 && phoneLength < 10) {
      errors.phone = i18next.t('validation_web_atoir:incompletePhoneNumber')
    }
    return errors
  }

  render() {
    const { errors } = this.state
    const { classes, user, organization, addUserError } = this.props

    const lastNameHelperText = !isEmpty(errors.lastName)
      ? errors.lastName
      : !isEmpty(addUserError.lastName)
      ? addUserError.lastName[0]
      : ''

    const firstNameHelperText = !isEmpty(errors.firstName)
      ? errors.firstName
      : !isEmpty(addUserError.firstName)
      ? addUserError.firstName[0]
      : ''

    const positionHelperText = !isEmpty(errors.position) ? errors.position : ''

    const phoneHelperText = !isEmpty(errors.phone)
      ? errors.phone
      : !isEmpty(addUserError.phone)
      ? addUserError.phone[0]
      : ''

    return (
      <div>
        <Dialog
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          onExiting={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleSubmitForm}>
            <DialogTitle id="form-dialog-title">
              <div>
                <div className="organization-type-container-in-row">
                  <OrganizationTypeIcon
                    fluid={false}
                    clientOrganization={organization.client_organization}
                    tradeOrganization={organization.trade_organization}
                    serviceOrganization={organization.service_organization}
                    type="m"
                  />
                  <OrganizationTypeIcon
                    fluid={false}
                    clientOrganization={organization.client_organization}
                    tradeOrganization={organization.trade_organization}
                    serviceOrganization={organization.service_organization}
                    type="c"
                  />
                  {/* AT-396 */}
                  {/* <OrganizationTypeIcon */}
                  {/* fluid={false} */}
                  {/* clientOrganization={organization.client_organization} */}
                  {/* tradeOrganization={organization.trade_organization} */}
                  {/* serviceOrganization={organization.service_organization} */}
                  {/* type="t" */}
                  {/* /> */}
                </div>
                <div className="organization-type-container-in-row">
                  <span className="organization-name-not-underlined">
                    {organization.short_name}
                  </span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <FormGroup>
                <TextField
                  error={
                    !isEmpty(errors.lastName) || !isEmpty(addUserError.lastName)
                  }
                  required
                  autoFocus
                  id="lastName"
                  label={i18next.t('login_web_atoir:lastName')}
                  placeholder={i18next.t('login_web_atoir:enterLastName')}
                  className={classes.textField}
                  helperText={lastNameHelperText}
                  margin="normal"
                  onChange={this.handleTextChange('lastName')}
                  value={this.state.lastName}
                />
                <TextField
                  error={
                    !isEmpty(errors.firstName) ||
                    !isEmpty(addUserError.firstName)
                  }
                  required
                  id="firstName"
                  label={i18next.t('login_web_atoir:firstName')}
                  placeholder={i18next.t('login_web_atoir:enterFirstName')}
                  className={classes.textField}
                  helperText={firstNameHelperText}
                  margin="normal"
                  onChange={this.handleTextChange('firstName')}
                  value={this.state.firstName}
                />
                <TextField
                  id="middleName"
                  label={i18next.t('login_web_atoir:middleName')}
                  placeholder={i18next.t('login_web_atoir:enterMiddleName')}
                  className={classes.textField}
                  margin="normal"
                  onChange={this.handleTextChange('middleName')}
                  value={this.state.middleName}
                />
                <TextField
                  error={!isEmpty(errors.position)}
                  required
                  id="position"
                  label={i18next.t('employees_web_atoir:position')}
                  placeholder={i18next.t(
                    'inventory_web_atoir:enterOrganizationUserPosition',
                  )}
                  className={classes.textField}
                  helperText={positionHelperText}
                  margin="normal"
                  onChange={this.handleTextChange('position')}
                  value={this.state.position}
                />
                <TextField
                  error={!isEmpty(errors.phone) || !isEmpty(addUserError.phone)}
                  className={classes.textField}
                  id="phone"
                  label={i18next.t('login_web_atoir:phone')}
                  placeholder={i18next.t('login_web_atoir:enterPhone')}
                  value={this.state.phone}
                  onChange={this.handleTextChange('phone')}
                  helperText={phoneHelperText}
                  margin="normal"
                  InputProps={{
                    inputComponent: PhoneMask,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <div
                style={{
                  width: '100%',
                  minWidth: 300,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 12,
                  paddingRight: 12,
                  margin: 0,
                }}
              >
                <OutlinedButton
                  classes={{ root: classes.button }}
                  onClick={this.props.closeDialog}
                  size="medium"
                >
                  {i18next.t('shared_web_atoir:cancel')}
                </OutlinedButton>
                <ContainedButton
                  classes={{ root: classes.button }}
                  type="submit"
                  size="medium"
                >
                  {user.is_owner
                    ? i18next.t('shared_web_atoir:saveChanges')
                    : i18next.t('shared_web_atoir:next')}
                </ContainedButton>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

AddUserComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  addOrganizationUser: PropTypes.func.isRequired,
  addUserError: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  startCreateUpdateOrganizationUser: PropTypes.func.isRequired,
  updateOrganizationUser: PropTypes.func.isRequired,
}

export default withStyles(styles)(AddUserComponent)
