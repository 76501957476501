import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SendToSubOrder from './SendToSubOrder'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../actions/utilsActions'
import { sendingToSubOrder } from '../../../actions/serviceDesk'

const mapStateToProps = state => ({
  isAssigning: state.serviceDesk.sendingToSubOrder.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendingToSubOrder,
      showDialogLoader,
      hideDialogLoader,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SendToSubOrder),
)
