import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../i18n/i18n'
import FormDialogComponent from '../../../../widgets/FormDialog'

class AssignAgreement extends Component {
  constructor(props) {
    super(props)
    this.props.createRef(this)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isAssigning !== this.props.isAssigning &&
      this.props.isAssigning === false
    ) {
      this.props.onFinish(this.props.assignError)
      this.props.onClose()
    }
  }

  toggle = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  onSubmit = () => {
    const {
      props: { agreement, assignAgreementRequest },
    } = this
    assignAgreementRequest({
      id: agreement.contract.id,
      agreementId: agreement.id,
    })
  }

  render() {
    const {
      props: { open, onClose },
      onSubmit,
    } = this
    return (
      <FormDialogComponent
        open={open}
        fullWidth
        closeIconHidden
        onSubmit={onSubmit}
        onClose={onClose}
        submitButtonTitle={i18n.t('shared_web_atoir:send')}
        secondaryButtonsArray={[
          {
            secondaryText: i18n.t('shared_web_atoir:close'),
            handleSecondaryAction: onClose,
          },
        ]}
        contentText={`${i18n.t(
          'service_desk_web_atoir:sendAgreementToPerformerOrganization',
        )}?`}
      />
    )
  }
}

AssignAgreement.defaultProps = {
  createRef: () => {},
  open: false,
}

AssignAgreement.propTypes = {
  createRef: PropTypes.func,
  open: PropTypes.bool,
  agreement: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AssignAgreement
