import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import DistributeEquipment from './DistributeEquipment'
import {
  fetchContractPermissionsRequest,
  fetchContractRequest,
} from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  contract: state.serviceDeskContracts.contract.contract,
  permissions: state.serviceDeskContracts.permissions.permissions,
  isFetchingPermissions: state.serviceDeskContracts.permissions.isFetching,
  permissionsError: state.serviceDeskContracts.permissions.error,
  isFetching: state.serviceDeskContracts.contract.isFetching,
  error: state.serviceDeskContracts.contract.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractPermissionsRequest,
      fetchContractRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DistributeEquipment),
)
