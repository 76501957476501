import React from 'react'

import Typography from '@material-ui/core/Typography'

import FormDialog from '../../../widgets/FormDialog'
import i18next from '../../../i18n/i18n'

function SuccessDialog({ open, isOpenHandler }) {
  function close() {
    isOpenHandler(false)
  }

  return (
    <FormDialog
      closeIconHidden
      open={open}
      isLoading={false}
      title={i18next.t('service_desk_web_atoir:sdBannerSuccessDialogTitle')}
      onClose={close}
      onExit={close}
      onSubmit={close}
      submitButtonTitle={i18next.t('service_desk_web_atoir:ok')}
      positiveButtonVariant="applyButton"
    >
      <Typography>
        {i18next.t('service_desk_web_atoir:sdBannerSuccessDialogText')}
      </Typography>
    </FormDialog>
  )
}

export default SuccessDialog
