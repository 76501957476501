import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchInventoryProjectProfile,
  cancelInventoryProject,
} from '../../../../../actions/inventoryActions'
import BaseInfoComponent from './BaseInfoComponent'

const mapStateToProps = state => ({
  isFetchingProject: state.inventory.isFetchingProject,
  inventoryProject: state.inventory.project,
  error: state.inventory.error,
  isCancellingProject: state.inventory.cancelInventoryProject.isCancelling,
  cancelledProjectSuccessfully:
    state.inventory.cancelInventoryProject.cancelledSuccessfully,
  cancelProjectErrors: state.inventory.cancelInventoryProject.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventoryProjectProfile,
      cancelInventoryProject,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseInfoComponent),
)
