import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EquipmentInformationComponent from '../../../components/Equipment/Profile/EquipmentInformationComponent'

const mapStateToProps = state => ({
  isFetchingEquipmentDetails:
    state.equipment.equipmentDetails.isFetchingEquipmentDetails,
  equipment: state.equipment.equipmentDetails.equipment,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentInformationComponent),
)
