import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ContractHistory from './ContractHistory'
import {
  fetchContractLogsCountRequest,
  fetchContractLogsRequest,
  fetchContractPermissionsRequest,
  fetchContractRequest,
} from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  logs: state.serviceDeskContracts.logs.logs,
  isFetching: state.serviceDeskContracts.logs.isFetching,
  isFetchingContract: state.serviceDeskContracts.contract.isFetching,
  contract: state.serviceDeskContracts.contract.contract,
  error: state.serviceDeskContracts.logs.error,
  count: state.serviceDeskContracts.logsCount.count,
  isFetchingCount: state.serviceDeskContracts.logsCount.isFetching,
  permissions: state.serviceDeskContracts.permissions.permissions,
  isFetchingPermissions: state.serviceDeskContracts.permissions.isFetching,
  permissionsError: state.serviceDeskContracts.permissions.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractPermissionsRequest,
      fetchContractRequest,
      fetchContractLogsRequest,
      fetchContractLogsCountRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractHistory),
)
