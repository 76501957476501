import {
  takeLatest,
  takeEvery,
  put,
  take,
  call as baseCall,
  fork,
} from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  catchError,
  receivedErrorMessage,
  showDialogWithOk,
} from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
import * as constants from '../constants/actionTypes/serviceDesk'
import * as actions from '../actions/serviceDesk'
import * as api from './api'
import i18next from '../i18n/i18n'
import { hideDialogLoader, showDialogLoader } from '../actions/utilsActions'
import { createDefaultSaga } from '../helpers/api'
import { previewDocument, previewPathChange } from '../helpers/previewHelper'
import { executeReq, doFetch, get, handlerResult } from './appSaga'

export function* watchCreateServiceDeskRequestSaga() {
  yield takeLatest(constants.CREATE_REQUEST_REQUEST, createServiceDeskRequest)
}

export function* watchFetchServiceDeskRequestSaga() {
  yield takeLatest(constants.FETCH_CALL_REQUEST, fetchServiceDeskRequest)
}

export function* watchUpdateServiceDeskRequestSaga() {
  yield takeLatest(constants.UPDATE_CALL_REQUEST, updateServiceDeskRequest)
}

export function* watchUploadFileServiceDeskRequestSaga() {
  yield takeLatest(constants.UPLOAD_FILE_REQUEST, uploadFileServiceDeskRequest)
}

export function* watchUploadPhotoServiceDeskRequestSaga() {
  yield takeLatest(
    constants.UPLOAD_PHOTO_REQUEST,
    uploadPhotoServiceDeskRequest,
  )
}

export function* watchFetchFilesServiceDeskRequestSaga() {
  yield takeLatest(constants.FETCH_FILES_REQUEST, fetchFilesServiceDeskRequest)
}

export function* watchFetchPhotosServiceDeskRequestSaga() {
  yield takeLatest(
    constants.FETCH_PHOTOS_REQUEST,
    fetchPhotosServiceDeskRequest,
  )
}

export function* watchDownloadRequestDocumentSaga() {
  yield takeLatest(constants.DOWNLOAD_DOCUMENT_REQUEST, downloadDoc)
}

export function* watchDownloadRequestPhotoSaga() {
  yield takeLatest(constants.DOWNLOAD_PHOTO_REQUEST, downloadPhoto)
}

export function* watchDeleteDocRequestPhotoSaga() {
  yield takeLatest(constants.DELETE_FILE_REQUEST, deleteDocServiceDeskRequest)
}

export function* watchDeletePhotoRequestPhotoSaga() {
  yield takeLatest(
    constants.DELETE_PHOTO_REQUEST,
    deletePhotoServiceDeskRequest,
  )
}

export function* watchFetchServiceCallsListSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALLS_LIST_REQUEST,
    createDefaultSaga(
      payload => api.fetchServiceDeskCallsListRequest(payload),
      result => actions.fetchServiceCallsListSuccess(result.calls),
      code => actions.fetchServiceCallsListFailure(code),
      false,
    ),
  )
}

export function* watchFetchServiceCallsListCountSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALLS_LIST_COUNT_REQUEST,
    createDefaultSaga(
      payload => api.fetchServiceDeskCallsListCountRequest(payload),
      result => actions.fetchServiceCallsListCountSuccess(result.count),
      code => actions.fetchServiceCallsListCountFailure(code),
      false,
    ),
  )
}

export function* watchFetchServiceCallOrdersListSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALL_ORDERS_LIST_REQUEST,
    createDefaultSaga(
      payload => api.fetchServiceDeskCallOrdersListRequest(payload),
      result => actions.fetchServiceCallOrdersListSuccess(result.orders),
      code => actions.fetchServiceCallOrdersListFailure(code),
      false,
    ),
  )
}

export function* watchFetchServiceCallOrdersListCountSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALL_ORDERS_LIST_COUNT_REQUEST,
    createDefaultSaga(
      payload => api.fetchServiceDeskCallOrdersListCountRequest(payload),
      result => actions.fetchServiceCallOrdersListCountSuccess(result.count),
      code => actions.fetchServiceCallOrdersListCountFailure(code),
      false,
    ),
  )
}

export function* watchAssignToThirdPartyRequestSaga() {
  yield takeLatest(
    constants.ASSIGN_TO_THIRD_PARTY_REQUEST_REQUEST,
    assignToThirdPartyServiceDeskRequest,
  )
}

export function* watchAssignRequestToOwnSpecialistPhotoSaga() {
  yield takeLatest(
    constants.ASSIGN_TO_OWN_SPECIALIST_REQUEST_REQUEST,
    assignToOwnSpecialistServiceDeskRequest,
  )
}

export function* watchFetchCallPermissionsSaga() {
  yield takeLatest(
    constants.FETCH_CALL_PERMISSIONS_REQUEST,
    fetchCallPermissions,
  )
}

export function* watchFetchCallHistorySaga() {
  yield takeLatest(constants.FETCH_CALL_HISTORY_REQUEST, fetchCallHistory)
}

export function* watchSendToSubOrderSaga() {
  yield takeLatest(
    constants.SENDING_TO_SUB_ORDER_REQUEST,
    sendToSubOrderRequest,
  )
}

export function* watchFetchServiceDeskCallOrdersSaga() {
  yield takeLatest(constants.FETCH_ORDERS_REQUEST, fetchServiceDeskCallOrders)
}

export function* watchFetchOrdersSaga() {
  yield takeEvery(constants.FETCH_ORDER_REQUEST, fetchOrders)
}

export function* watchAssignServiceDeskOrderToOwnSpecialistSaga() {
  yield takeLatest(
    constants.ASSIGN_ORDER_TO_OWN_SPECIALIST_REQUEST,
    assignServiceDeskOrderToOwnSpecialist,
  )
}

export function* watchSendOrderToSubOrderSaga() {
  yield takeLatest(
    constants.SEND_ORDER_TO_SUB_ORDER_REQUEST,
    sendOrderToSubOrder,
  )
}

export function* watchCreateServiceDeskRequestCancelSaga() {
  yield takeLatest(
    constants.CANCEL_REQUEST_REQUEST,
    createServiceDeskCancelRequest,
  )
}

export function* watchCreateRejectOrderSaga() {
  yield takeLatest(constants.REJECT_ORDER_REQUEST, createRejectOrderRequest)
}

export function* watchFetchOrdersLogSaga() {
  yield takeLatest(
    constants.FETCH_ORDERS_LOG_REQUEST,
    fetchServiceDeskOrdersLog,
  )
}

export function* watchFetchOrdersLogCountSaga() {
  yield takeLatest(
    constants.FETCH_ORDERS_LOG_COUNT_REQUEST,
    fetchServiceDeskOrdersLogCount,
  )
}

export function* watchChangePerformerResponsibleOrderRequestSaga() {
  yield takeLatest(
    constants.CHANGE_PERFORMER_RESPONSIBLE_ORDER_REQUEST,
    changePerformerResponsibleOrderRequest,
  )
}

export function* watchChangeClientResponsibleOrderRequestSaga() {
  yield takeLatest(
    constants.CHANGE_CLIENT_RESPONSIBLE_ORDER_REQUEST,
    changeClientResponsibleOrderRequest,
  )
}

export function* watchChangeEngineerOrderRequestSaga() {
  yield takeLatest(
    constants.CHANGE_ENGINEER_ORDER_REQUEST,
    changeEngineerOrderRequest,
  )
}

export function* watchEditOrderInfoSaga() {
  yield takeLatest(constants.EDIT_ORDER_INFO_REQUEST, editOrderInfoRequest)
}

export function* watchFetchServiceDeskOrderCommentsSaga() {
  yield takeLatest(
    constants.FETCH_ORDER_COMMENTS_REQUEST,
    fetchServiceDeskOrderCommentsRequest,
  )
}

export function* watchUploadServiceDeskOrderCommentRequestSaga() {
  yield takeLatest(
    constants.UPLOAD_ORDER_COMMENT_REQUEST,
    uploadServiceDeskOrderCommentRequest,
  )
}

export function* watchUploadServiceDeskOrderSubcommentRequestSaga() {
  yield takeLatest(
    constants.UPLOAD_ORDER_SUBCOMMENT_REQUEST,
    uploadServiceDeskOrderSubcommentRequest,
  )
}

export function* watchFetchServiceDeskOrderCommentsCountSaga() {
  yield takeLatest(
    constants.FETCH_ORDER_COMMENTS_COUNT_REQUEST,
    fetchServiceDeskOrderCommentsCountRequest,
  )
}

export function* watchCancelOrderByClientRequestSaga() {
  yield takeLatest(
    constants.CANCEL_ORDER_BY_CLIENT_REQUEST,
    cancelOrderByClientRequest,
  )
}

export function* watchCreateServiceContractsRequest() {
  yield takeLatest(
    constants.CREATE_SERVICE_CONTRACT_REQUEST,
    createServiceContractsRequest,
  )
}

export function* watchSetOrderResultTypeSaga() {
  yield takeLatest(
    constants.SET_ORDER_RESULT_TYPE_REQUEST,
    setOrderResultTypeRequest,
  )
}

export function* watchFetchServiceCallOrderItemsSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALL_ORDER_ITEMS_REQUEST,
    fetchServiceDeskOrderItemsRequest,
  )
}

export function* watchFetchServiceCallOrderItemsCountSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALL_ORDER_ITEMS_COUNT_REQUEST,
    fetchServiceDeskOrderItemsCountRequest,
  )
}

export function* watchFetchServiceCallSuborderItemsSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_REQUEST,
    fetchServiceDeskSuborderItemsRequest,
  )
}

export function* watchFetchServiceCallSuborderItemsCountSaga() {
  yield takeLatest(
    constants.FETCH_SERVICE_CALL_SUBORDER_ITEMS_COUNT_REQUEST,
    fetchServiceDeskSuborderItemsCountRequest,
  )
}

export function* watchSaveServiceCallOrderItemsListSaga() {
  yield takeLatest(
    constants.SAVE_ORDER_ITEMS_LIST_REQUEST,
    saveServiceDeskOrderItemsListRequest,
  )
}

export function* watchClearServiceCallOrderItemsListSaga() {
  yield takeLatest(
    constants.CLEAR_ORDER_ITEMS_LIST_REQUEST,
    clearServiceDeskOrderItemsListRequest,
  )
}

export function* watchCopyOrderItemsFromChildSaga() {
  yield takeLatest(
    constants.COPY_ORDER_ITEMS_FROM_CHILD_REQUEST,
    copyOrderItemsFromChildRequest,
  )
}

export function* watchFetchOrderNdsSaga() {
  yield takeLatest(constants.FETCH_ORDER_NDS_REQUEST, fetchOrderNdsRequest)
}

export function* watchFetchOrderOverheadSaga() {
  yield takeLatest(
    constants.FETCH_ORDER_OVERHEAD_REQUEST,
    fetchOrderOverheadRequest,
  )
}

export function* watchFetchOrderIncomeNdsSaga() {
  yield takeLatest(
    constants.FETCH_ORDER_INCOME_NDS_REQUEST,
    fetchOrderIncomeNdsRequest,
  )
}

export function* watchUpdateOrderNdsSaga() {
  yield takeLatest(constants.UPDATE_ORDER_NDS_REQUEST, updateOrderNdsRequest)
}

export function* watchUpdateOrderIncomeNdsSaga() {
  yield takeLatest(
    constants.UPDATE_ORDER_INCOME_NDS_REQUEST,
    updateOrderIncomeNdsRequest,
  )
}

export function* watchUpdateOrderOverheadSaga() {
  yield takeLatest(
    constants.UPDATE_OVERHEAD_REQUEST,
    updateOrderOverheadRequest,
  )
}

export function* watchUploadOrderWorkCompletionFileRequestSaga() {
  yield takeLatest(
    constants.UPLOAD_ORDER_WORK_COMPLETION_FILE_REQUEST,
    uploadOrderWorkCompletionFileRequest,
  )
}

export function* watchDeleteOrderWorkCompletionFileRequest() {
  yield takeLatest(
    constants.DELETE_ORDER_WORK_COMPLETION_FILE_REQUEST,
    deleteOrderWorkCompletionFileRequest,
  )
}

export function* watchConfirmCertificateOfCompletionSaga() {
  yield takeLatest(
    constants.CONFIRM_CERTIFICATE_OF_COMPLETION_REQUEST,
    confirmCertificateOfCompletionRequest,
  )
}

export function* watchFetchActiveServiceCallOrdersSaga() {
  yield takeLatest(
    constants.FETCH_ACTIVE_SERVICE_CALL_ORDERS_REQUEST,
    fetchActiveServiceCallOrdersRequest,
  )
}

export function* watchFetchClosedServiceCallOrdersSaga() {
  yield takeLatest(
    constants.FETCH_CLOSED_SERVICE_CALL_ORDERS_REQUEST,
    fetchClosedServiceCallOrdersRequest,
  )
}

export function* watchFetchCanceledServiceCallOrdersSaga() {
  yield takeLatest(
    constants.FETCH_CANCELED_SERVICE_CALL_ORDERS_REQUEST,
    fetchCanceledServiceCallOrdersRequest,
  )
}

export function* watchAssignUsingContractSaga() {
  yield takeLatest(
    constants.ASSIGN_USING_CONTRACT_REQUEST,
    assignUsingContractRequest,
  )
}

export function* watchAcceptOrderCertificateOfCompletionSaga() {
  yield takeLatest(
    constants.ACCEPT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST,
    acceptOrderCertificateOfCompletionRequest,
  )
}

export function* watchRejectOrderCertificateOfCompletionSaga() {
  yield takeLatest(
    constants.REJECT_ORDER_CERTIFICATE_OF_COMPLETION_REQUEST,
    rejectOrderCertificateOfCompletionRequest,
  )
}
export function* watchFetchServiceDeskContractAvailableEquipmentRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_REQUEST,
    fetchServiceDeskContractAvailableEquipmentRequest,
  )
}

export function* watchFetchServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_REQUEST,
    fetchServiceDeskContractRequest,
  )
}

export function* watchfetchServiceDeskContractAvailableEquipmentCountRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_AVAILABLE_EQUIPMENT_COUNT_REQUEST,
    fetchServiceDeskContractAvailableEquipmentCountRequest,
  )
}

export function* watchAddEquipmentToContractsRequest() {
  yield takeLatest(
    constants.ADD_EQUIPMENT_TO_CONTRACT_REQUEST,
    addEquipmentToContractsRequest,
  )
}

export function* watchCloseServiceCallFromNeedrepairSaga() {
  yield takeLatest(
    constants.CLOSE_SERVICE_CALL_FROM_NEEDREPAIR_REQUEST,
    closeServiceCallFromNeedrepairRequest,
  )
}

export function* watchSendServiceCallToRepairDiagnosticiansSaga() {
  yield takeLatest(
    constants.SEND_CALL_TO_REPAIR_DIAGNOSTICIANS_REQUEST,
    sendServiceCallToRepairDiagnosticiansRequest,
  )
}

export function* watchFetchOfferFilesServiceDeskRequestSaga() {
  yield takeLatest(
    constants.FETCH_OFFER_FILES_REQUEST,
    fetchOfferFilesServiceDeskRequest,
  )
}

export function* watchSendCallUsingContractSaga() {
  yield takeLatest(
    constants.SEND_CALL_USING_CONTRACT_REQUEST,
    sendCallUsingContractRequest,
  )
}

export function* watchGetOrderPossibleReportsSaga() {
  yield takeLatest(
    constants.GET_ORDER_POSSIBLE_REPORTS_REQUEST,
    getOrderPossibleReportsRequest,
  )
}

export function* watchGetCallPossibleBlanksSaga() {
  yield takeLatest(
    constants.GET_CALL_POSSIBLE_BLANKS_REQUEST,
    getCallPossibleBlanksRequest,
  )
}

export function* watchGetCallsByNumberSearchSaga() {
  yield takeLatest(
    constants.GET_CALLS_BY_GENERAL_SEARCH_REQUEST,
    getCallsByGeneralSearchRequest,
  )
}

function* createServiceDeskRequest(action) {
  try {
    const result = yield call(api.createServiceDeskRequestApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.createServiceDeskRequestSuccess(result.service_call_id),
        )
      } else {
        const errors = handleErrors(result)
        yield put(actions.createServiceDeskRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchCallPermissions(action) {
  try {
    const result = yield call(api.fetchCallPermissionsApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchCallPermissionsSuccess(result.user_permissions))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchCallPermissionsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchCallHistory(action) {
  try {
    const result = yield call(api.fetchCallHistoryApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.fetchCallHistorySuccess({
            history: result.history,
            // end: action.payload.query.limit && result.history.length < action.payload.query.limit,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchCallHistoryFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* assignToThirdPartyServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.assignToThirdPartyDeskRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.assignServiceDeskRequestToThirdPartyOrganizationSuccess(),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.assignServiceDeskRequestToThirdPartyOrganizationFailure(
            result.code,
          ),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* assignToOwnSpecialistServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.assignRequestToOwnSpecialistApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.assignServiceDeskRequestToOwnSpecialistSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.assignServiceDeskRequestToOwnSpecialistFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* sendToSubOrderRequest(action) {
  try {
    const result = yield call(api.sendToSubOrderRequestApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.sendingToSubOrderSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.sendingToSubOrderFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskRequest(action) {
  try {
    const result = yield call(api.fetchServiceDeskCallRequest(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceDeskCallSuccess(result.service_call))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchServiceDeskCallFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateServiceDeskRequest(action) {
  try {
    const result = yield call(api.updateServiceDeskCallRequest(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.updateServiceDeskCallSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.updateServiceDeskCallFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* uploadFileServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.uploadFileServiceDeskCallRequest(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.uploadDocServiceDeskCallSuccess())
      } else if (result.code === 413) {
        const mess = `${i18next.t('shared_web_atoir:fileToBig')}`
        yield put(showDialogWithOk(mess))
        yield put(actions.uploadDocServiceDeskCallFailure(result.code))
      } else {
        const errors = handleErrors(result)
        yield put(actions.uploadDocServiceDeskCallFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(actions.uploadDocServiceDeskCallFailure(0))
  }
}

function* uploadPhotoServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.uploadPhotoServiceDeskCallRequest(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.uploadPhotoServiceDeskCallSuccess())
      } else if (result.code === 413) {
        const mess = `${i18next.t('shared_web_atoir:fileToBig')}`
        yield put(showDialogWithOk(mess))
        yield put(actions.uploadPhotoServiceDeskCallFailure(result.code))
      } else {
        const errors = handleErrors(result)
        yield put(actions.uploadPhotoServiceDeskCallFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(actions.uploadPhotoServiceDeskCallFailure(0))
  }
}

function* deleteDocServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.deleteDocServiceDeskCallRequest(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.deleteDocServiceDeskCallSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.deleteDocServiceDeskCallFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deletePhotoServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.deletePhotoServiceDeskCallRequest(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.deletePhotoServiceDeskCallSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.deletePhotoServiceDeskCallFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchFilesServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.fetchFilesServiceDeskCallRequest(action.payload, 'common'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceDeskCallDocsSuccess(result.docs))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchServiceDeskCallDocsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchPhotosServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.fetchPhotosServiceDeskCallRequest(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceDeskCallPhotosSuccess(result.photos))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchServiceDeskCallPhotosFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* downloadDoc(action) {
  try {
    const result = yield call(
      api.downloadFileServiceDeskCallRequest(action.payload),
    )
    const url = window.URL.createObjectURL(new Blob([result]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', action.payload.name)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    yield put(catchError(error.message))
  }
}
function* downloadPhoto(action) {
  try {
    const result = yield call(
      api.downloadPhotoServiceDeskCallRequest(action.payload),
    )
    const url = window.URL.createObjectURL(new Blob([result]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', action.payload.name)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrders(action) {
  try {
    const { type } = action.payload
    const result = yield call(api.fetchOrdersRequest(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrderSuccess({ result, type }))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrderFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskCallOrders(action) {
  try {
    const result = yield call(api.fetchServiceDeskCallOrdersApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceDeskCallOrdersSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchServiceDeskCallOrdersFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* assignServiceDeskOrderToOwnSpecialist(action) {
  try {
    const result = yield call(
      api.performerHandlerOrder(action.payload, 'accept_order'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.assignServiceDeskOrderToOwnSpecialistSuccess(result.success),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.assignServiceDeskOrderToOwnSpecialistFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* sendOrderToSubOrder(action) {
  try {
    const result = yield call(
      api.performerHandlerOrder(action.payload, 'send_to_suborder'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.sentOrderToSubOrderSuccess(result.success))
      } else {
        const errors = handleErrors(result)
        yield put(actions.sentOrderToSubOrderFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createServiceDeskCancelRequest(action) {
  try {
    const result = yield call(api.fetchServiceDeskCancelRequest(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.cancelServiceDeskRequestSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.cancelServiceDeskRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createRejectOrderRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskRejectOrderRequest(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.rejectOrderRequestSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.rejectOrderRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* changePerformerResponsibleOrderRequest(action) {
  try {
    const result = yield call(
      api.changeResponsibleOrder(
        action.payload,
        'change_performer_responsible',
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.changePerformerResponsibleOrderSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.changePerformerResponsibleOrderFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* changeClientResponsibleOrderRequest(action) {
  try {
    const result = yield call(
      api.changeResponsibleOrder(action.payload, 'change_client_responsible'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.changeClientResponsibleOrderSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.changeClientResponsibleOrderFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* changeEngineerOrderRequest(action) {
  try {
    const result = yield call(
      api.changeResponsibleOrder(action.payload, 'change_engineer'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.changeEngineerOrderSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.changeEngineerOrderFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* editOrderInfoRequest(action) {
  try {
    const result = yield call(api.editOrderInfoApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.editOrderInfoSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.editOrderInfoFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskOrdersLog(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrdersLogRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrdersLogRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrdersLogRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskOrdersLogCount(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrdersLogCountRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrdersLogCountRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrdersLogCountRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* cancelOrderByClientRequest(actionData) {
  try {
    const result = yield call(
      api.cancelOrderByClientRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.cancelOrderByClientRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.cancelOrderByClientRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskOrderCommentsRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrderCommentsRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrderCommentsRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrderCommentsRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* uploadServiceDeskOrderCommentRequest(actionData) {
  try {
    const result = yield call(
      api.uploadServiceDeskOrderCommentRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.uploadServiceDeskOrderCommentRequestSuccess(result))
      } else if (result.code === 413) {
        const mess = `${i18next.t('shared_web_atoir:fileToBig')}`
        yield put(showDialogWithOk(mess))
        yield put(
          actions.uploadServiceDeskOrderCommentRequestFailure(result.code),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.uploadServiceDeskOrderCommentRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* uploadServiceDeskOrderSubcommentRequest(actionData) {
  try {
    const result = yield call(
      api.uploadServiceDeskOrderSubcommentRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.uploadServiceDeskOrderSubcommentRequestSuccess(result),
        )
      } else if (result.code === 413) {
        const mess = `${i18next.t('shared_web_atoir:fileToBig')}`
        yield put(showDialogWithOk(mess))
        yield put(
          actions.uploadServiceDeskOrderSubcommentRequestFailure(result.code),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.uploadServiceDeskOrderSubcommentRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskOrderCommentsCountRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrderCommentsCountRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrderCommentsCountRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrderCommentsCountRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createServiceContractsRequest(actionData) {
  try {
    const result = yield call(
      api.createServiceContractsRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.createServiceContractsRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.createServiceContractsRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setOrderResultTypeRequest(actionData) {
  try {
    const result = yield call(api.setOrderResultTypeApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.setOrderResultTypeRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.setOrderResultTypeRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskOrderItemsRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrderItemsApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceCallOrderItemsRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchServiceCallOrderItemsRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskOrderItemsCountRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrderItemsCountApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceCallOrderItemsCountRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.fetchServiceCallOrderItemsCountRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskSuborderItemsRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrderItemsApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceCallSuborderItemsRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.fetchServiceCallSuborderItemsRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskSuborderItemsCountRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskOrderItemsCountApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.fetchServiceCallSuborderItemsCountRequestSuccess(result),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.fetchServiceCallSuborderItemsCountRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* saveServiceDeskOrderItemsListRequest(actionData) {
  try {
    const result = yield call(
      api.saveServiceCallOrderItemsListApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.saveServiceCallOrderItemsListRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.saveServiceCallOrderItemsListRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* clearServiceDeskOrderItemsListRequest(actionData) {
  try {
    const result = yield call(
      api.clearServiceCallOrderItemsListApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.clearServiceCallOrderItemsListRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.clearServiceCallOrderItemsListRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* copyOrderItemsFromChildRequest(actionData) {
  try {
    const result = yield call(
      api.copyOrderItemsFromChildApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.copyOrderItemsFromChildRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.copyOrderItemsFromChildRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrderNdsRequest(actionData) {
  try {
    const result = yield call(api.fetchOrderNdsApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrderNdsSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrderNdsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrderOverheadRequest(actionData) {
  try {
    const result = yield call(api.fetchOrderOverheadApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrderOverheadSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrderOverheadFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrderIncomeNdsRequest(actionData) {
  try {
    const result = yield call(api.fetchOrderIncomeNdsApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchOrderIncomeNdsSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchOrderIncomeNdsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateOrderNdsRequest(actionData) {
  try {
    const result = yield call(api.updateOrderNdsApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.updateOrderNdsSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.updateOrderNdsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateOrderIncomeNdsRequest(actionData) {
  try {
    const result = yield call(api.updateOrderIncomeNdsApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.updateOrderIncomeNdsSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.updateOrderIncomeNdsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateOrderOverheadRequest(actionData) {
  try {
    const result = yield call(api.updateOrderOverheadApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.updateOrderOverheadSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.updateOrderOverheadFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* uploadOrderWorkCompletionFileRequest(actionData) {
  try {
    const result = yield call(
      api.uploadOrderWorkCompletionFileRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.uploadOrderWorkCompletionFileRequestSuccess(result))
      } else if (result.code === 413) {
        const mess = `${i18next.t('shared_web_atoir:fileToBig')}`
        yield put(showDialogWithOk(mess))
        yield put(
          actions.uploadOrderWorkCompletionFileRequestFailure(result.code),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.uploadOrderWorkCompletionFileRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteOrderWorkCompletionFileRequest(actionData) {
  try {
    const result = yield call(
      api.deleteOrderWorkCompletionFileRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.deleteOrderWorkCompletionFileRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.deleteOrderWorkCompletionFileRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(actions.deleteOrderWorkCompletionFileRequestFailure(0))
  }
}

function* confirmCertificateOfCompletionRequest(actionData) {
  try {
    const result = yield call(
      api.confirmCertificateOfCompletionApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.confirmCertificateOfCompletionSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.confirmCertificateOfCompletionFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchActiveServiceCallOrdersRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceCallOrdersApi(
        Object.assign(actionData.payload, { ordersType: 'active' }),
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchActiveServiceCallOrdersSuccess(result.orders))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchActiveServiceCallOrdersFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchClosedServiceCallOrdersRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceCallOrdersApi(
        Object.assign(actionData.payload, { ordersType: 'closed' }),
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchClosedServiceCallOrdersSuccess(result.orders))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchClosedServiceCallOrdersFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchCanceledServiceCallOrdersRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceCallOrdersApi(
        Object.assign(actionData.payload, { ordersType: 'canceled' }),
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchCanceledServiceCallOrdersSuccess(result.orders))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchCanceledServiceCallOrdersFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* acceptOrderCertificateOfCompletionRequest(actionData) {
  try {
    const result = yield call(
      api.acceptOrderCertificateOfCompletionApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.acceptOrderCertificateOfCompletionSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.acceptOrderCertificateOfCompletionFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* rejectOrderCertificateOfCompletionRequest(actionData) {
  try {
    const result = yield call(
      api.rejectOrderCertificateOfCompletionApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.rejectOrderCertificateOfCompletionSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.rejectOrderCertificateOfCompletionFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* assignUsingContractRequest(actionData) {
  try {
    yield put(showDialogLoader())
    const result = yield call(api.assignUsingContractApi(actionData.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.assignUsingContractSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.assignUsingContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchServiceDeskContractAvailableEquipmentRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractAvailableEquipmentRequestApi(
        actionData.payload,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractAvailableEquipmentRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.fetchContractAvailableEquipmentRequestFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskContractRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractRequestSuccess(result.contract))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskContractAvailableEquipmentCountRequest(actionData) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractAvailableEquipmentCountRequestApi(
        actionData.payload,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.fetchServiceDeskContractAvailableEquipmentCountRequestSuccess(
            result,
          ),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.fetchServiceDeskContractAvailableEquipmentCountRequestFailure(
            result.code,
          ),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* addEquipmentToContractsRequest(actionData) {
  try {
    const result = yield call(
      api.addEquipmentToContractsRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.addEquipmentToContractsRequestSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.addEquipmentToContractsRequestFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* closeServiceCallFromNeedrepairRequest(actionData) {
  try {
    const result = yield call(
      api.closeServiceCallFromNeedrepairApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.closeServiceCallFromNeedrepairSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.closeServiceCallFromNeedrepairFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* sendServiceCallToRepairDiagnosticiansRequest(actionData) {
  try {
    const result = yield call(
      api.sendServiceCallToRepairDiagnosticiansApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.sendServiceCallToRepairDiagnosticiansSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.sendServiceCallToRepairDiagnosticiansFailure(result.code),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOfferFilesServiceDeskRequest(action) {
  try {
    const result = yield call(
      api.fetchFilesServiceDeskCallRequest(action.payload, 'offer'),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchServiceDeskCallOfferDocsSuccess(result.docs))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchServiceDeskCallOfferDocsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* sendCallUsingContractRequest(action) {
  try {
    const result = yield call(api.sendCallUsingContractApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.sendCallUsingContractSuccess(result.docs))
      } else {
        const errors = handleErrors(result)
        yield put(actions.sendCallUsingContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* getOrderPossibleReportsRequest(action) {
  try {
    const result = yield call(api.getOrderPossibleReportsApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.getOrderPossibleReportsSuccess(result.templates))
      } else {
        const errors = handleErrors(result)
        yield put(actions.getOrderPossibleReportsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(actions.getOrderPossibleReportsFailure(403))
    yield put(catchError(error.message))
  }
}

function* getCallPossibleBlanksRequest(action) {
  try {
    const result = yield call(api.getCallPossibleBlanksApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.getCallPossibleBlanksSuccess(result))
      } else {
        const errors = handleErrors(result)
        yield put(actions.getCallPossibleBlanksFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(actions.getCallPossibleBlanksFailure(403))
    yield put(catchError(error.message))
  }
}

function* getCallsByGeneralSearchRequest(action) {
  try {
    const result = yield call(api.getCallsByGeneralSearchApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.getCallsByGeneralSearchSuccess(result.calls))
      } else {
        const errors = handleErrors(result)
        yield put(actions.getCallsByGeneralSearchFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(actions.getCallsByGeneralSearchFailure(403))
    yield put(catchError(error.message))
  }
}

export function* watchGetSDActionsHistorySaga() {
  yield takeLatest(
    constants.FETCH_SD_ACTIONS_HISTORY_REQUEST,
    createDefaultSaga(
      payload => api.getSDActionsHistoryRequestApi(payload),
      result =>
        actions.fetchSDActionsHistorySuccess({
          history: result.customActions,
        }),
      code => actions.fetchSDActionsHistoryFailure(code),
      false,
    ),
  )
}

export function* watchGetServiceCallDocumentLinkSaga() {
  yield takeLatest(
    constants.GET_SC_DOCUMENT_LINK_REQUEST,
    createDefaultSaga(
      payload => api.getServiceCallDocumentLinkApi(payload),
      result => {
        const path = previewPathChange(result.path)
        previewDocument(path)
        return actions.getServiceCallDocumentLinkSuccess(path)
      },
      code => actions.getServiceCallDocumentLinkFailure(code),
      false,
    ),
  )
}

export function* watchGetServiceCallBlankDocumentLinkSaga() {
  yield takeLatest(
    constants.GET_SC_BLANK_DOCUMENT_LINK_REQUEST,
    createDefaultSaga(
      payload => api.getServiceCallBlankDocumentLinkApi(payload),
      result => {
        const path = previewPathChange(result.path)
        previewDocument(path)
        return actions.getServiceCallBlankDocumentLinkSuccess(path)
      },
      code => actions.getServiceCallBlankDocumentLinkFailure(code),
      false,
    ),
  )
}

export function* watchGetServiceCallOrderCertificateDocumentLinkSaga() {
  yield takeLatest(
    constants.GET_SCO_CERTIFICATE_DOCUMENT_LINK_REQUEST,
    createDefaultSaga(
      payload => api.getServiceCallOrderCertificateDocumentLinkApi(payload),
      result => {
        const path = previewPathChange(result.path)
        previewDocument(path)
        return actions.getServiceCallOrderCertificateDocumentLinkSuccess(path)
      },
      code => actions.getServiceCallOrderCertificateDocumentLinkFailure(code),
      false,
    ),
  )
}

export function* watchGetServiceCallOrderDocumentLinkSaga() {
  yield takeLatest(
    constants.GET_SCO_DOCUMENT_LINK_REQUEST,
    createDefaultSaga(
      payload => api.getServiceCallOrderDocumentLinkApi(payload),
      result => {
        const path = previewPathChange(result.path)
        previewDocument(path)
        return actions.getServiceCallOrderDocumentLinkSuccess(path)
      },
      code => actions.getServiceCallOrderDocumentLinkFailure({ code }),
      false,
    ),
  )
}

export function* watchGetServiceCallOrderCommentFileLinkSaga() {
  yield takeLatest(
    constants.GET_SCO_COMMENT_FILE_LINK_REQUEST,
    createDefaultSaga(
      payload => api.getServiceCallOrderCommentLinkApi(payload),
      result => {
        const path = previewPathChange(result.path)
        previewDocument(path)
        return actions.getServiceCallOrderCommentFileLinkSuccess(path)
      },
      code => actions.getServiceCallOrderCommentFileLinkFailure(code),
      false,
    ),
  )
}

export function* watchAttachServiceCallTagSaga() {
  yield takeLatest(
    constants.ATTACH_SC_TAG_REQUEST,
    createDefaultSaga(
      payload => api.attachServiceCallTagApi(payload),
      () => actions.attachServiceCallTagSuccess(),
      code => actions.attachServiceCallTagFailure(code),
      true,
    ),
  )
}

export function* watchMakeRecurringFromServiceCallSaga() {
  yield takeLatest(
    constants.MAKE_RECURRING_FROM_CALL_REQUEST,
    createDefaultSaga(
      payload => api.makeRecurringFromCall(payload),
      () => actions.makeRecurringFromCallSuccess(),
      code => actions.makeRecurringFromCallFailure(code),
      true,
    ),
  )
}

export function* watchEditRecurringCallSaga() {
  yield takeLatest(
    constants.EDIT_RECURRING_CALL_REQUEST,
    createDefaultSaga(
      payload => api.editRecurringCall(payload),
      () => actions.editRecurringCallSuccess(),
      code => actions.editRecurringCallFailure(code),
      true,
    ),
  )
}

export function* watchDeleteRecurringCallSaga() {
  yield takeLatest(
    constants.DELETE_RECURRING_CALL_REQUEST,
    createDefaultSaga(
      payload => api.deleteRecurringCall(payload),
      () => actions.deleteRecurringCallSuccess(),
      code => actions.deleteRecurringCallFailure(code),
      true,
    ),
  )
}

export function* watchDetachServiceCallTagSaga() {
  yield takeLatest(
    constants.DETACH_SC_TAG_REQUEST,
    createDefaultSaga(
      payload => api.detachServiceCallTagApi(payload),
      () => actions.detachServiceCallTagSuccess(),
      code => actions.detachServiceCallTagFailure(code),
      true,
    ),
  )
}

function* watchGetCallHistoryCount() {
  while (true) {
    const {
      payload: { id },
    } = yield take(constants.FETCH_CALL_HISTORY_COUNT.REQUEST)
    const { result, error } = yield baseCall(
      doFetch,
      'GET',
      `/service_calls/${id}/history/count`,
    )
    yield baseCall(
      handlerResult,
      actions.fetchCallHistoryCountFailure,
      actions.fetchCallHistoryCountSuccess,
      result.success,
      error,
      result,
    )
  }
}

function* watchGeTSDActionsHistoryCount() {
  while (true) {
    const {
      payload: { id },
    } = yield take(constants.FETCH_SD_ACTIONS_HISTORY_COUNT.REQUEST)
    yield executeReq(
      get(`/service_calls/${id}/actionsHistory/count`),
      actions.fetchSDActionsHistoryCountFailure,
      actions.fetchSDActionsHistoryCountSuccess,
      result => result,
    )
  }
}

export default function* root() {
  yield fork(watchGetCallHistoryCount)
  yield fork(watchGeTSDActionsHistoryCount)
}
