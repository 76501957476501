import React from 'react'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import i18next from '../../../../i18n/i18n'

const minWidth = 700

const useStyles = makeStyles({
  root: {
    overflowX: 'auto',
  },
  table: {
    minWidth,
    overflowX: 'scroll',
  },
  tablePagination: {
    minWidth,
  },

  paddingRight: {
    paddingRight: 0,
  },
  paddingLeft: {
    paddingLeft: 0,
  },
})

const OrgCard = withStyles({})(({ contragent: { contragent } }) => {
  return (
    <div>
      <Typography variant="body2" color="textSecondary">
        {contragent.short_name}
      </Typography>
      <Typography variant="body1">{contragent.full_name}</Typography>
    </div>
  )
})

const ContragentsTable = ({
  contragents = [],
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  organizationContragentsCount,
  children,
  ...toolBoxProps
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Table className={classes.table} aria-label="contragents table">
        <TableBody>
          {contragents.map(contragent => (
            <TableRow key={contragent.id}>
              <TableCell
                component="th"
                scope="row"
                className={classes.paddingLeft}
              >
                <Box display="flex" alignItems="center">
                  <OrgCard contragent={contragent} />
                </Box>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={classes.paddingRight}
              >
                {React.cloneElement(
                  children,
                  {
                    ...toolBoxProps,
                    contragent,
                  },
                  null,
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        className={classes.tablePagination}
        component="div"
        count={organizationContragentsCount}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
        labelDisplayedRows={({
          from: fromPage,
          to: toPage,
          count: countPage,
        }) =>
          i18next.t('shared_web_atoir:labelDisplayedRows', {
            fromPage,
            toPage,
            countPage,
          })
        }
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  )
}

export default ContragentsTable
