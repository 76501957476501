import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FormEditResponsible from './FormEditResponsible'
import {
  changePerformerResponsibleOrder,
  changeClientResponsibleOrder,
  changeEngineerOrder,
} from '../../../../../../actions/serviceDesk'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePerformerResponsibleOrder,
      changeClientResponsibleOrder,
      changeEngineerOrder,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditResponsible),
)
