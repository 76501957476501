import { fetchProxy, URL } from '../api'
import querystring from 'query-string'

const defaultHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-auth-token': localStorage.getItem('atoirAccessToken'),
})

export const getTrustedOrganizationsTrustsApi = payload => async () => {
  const response = await fetchProxy(
    `${URL}/trust?${querystring.stringify(payload)}`,
    {
      method: 'GET',
      headers: defaultHeaders(),
    },
  )
  return response.json()
}

export const getTrustedOrganizationsTrustsCountApi = payload => async () => {
  const response = await fetchProxy(
    `${URL}/trust/count?${querystring.stringify(payload)}`,
    {
      method: 'GET',
      headers: defaultHeaders(),
    },
  )
  return response.json()
}

export const acceptTrustApi = ({ id }) => async () => {
  const response = await fetchProxy(`${URL}/trust/accept/${id}`, {
    method: 'PUT',
    headers: defaultHeaders(),
  })
  return response.json()
}

export const rejectTrustApi = ({ id, hard }) => async () => {
  const response = await fetchProxy(
    `${URL}/trust/reject/${id}?${hard ? querystring.stringify({ hard }) : ''}`,
    {
      method: 'PUT',
      headers: defaultHeaders(),
    },
  )
  return response.json()
}
