/* eslint-disable */
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import i18next from '../../../../i18n/i18n'
import DefaultButton from '../../../../widgets/Buttons/DefaultButton'
import PrimaryButton from '../../../../widgets/Buttons/PrimaryButton'
import FormDialog from '../../../../widgets/FormDialog'
import AutocompleteLimitedAuto from '../../../../widgets/AutocompleteLimitedAuto'
import {URL} from '../../../../middlewares/api'
import {getUserFullName} from '../../../../helpers/userHelper'


const styles = () => ({
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
})

class AcceptDialog extends Component {
  state = {
    performerResponsibleId: null,
    error: null,
  }

  componentDidUpdate = prevProps => {
    if (prevProps.isAccepting !== this.props.isAccepting && this.props.isAccepting === false) {
      this.props.onClose()
      this.props.onFinish(this.props.acceptError)
    }
  }

  onAssign = () => {
    const {
      state: {
        performerResponsibleId
      },
      props: {
        acceptContractRequest,
        contract,
      }
    } = this
    if (!performerResponsibleId) {
      this.setState({
        error: i18next.t('service_desk_web_atoir:requestCreationNoResponsibleValidationError'),
      })
      return
    }
    acceptContractRequest({id: contract.id, performer_responsible_id: performerResponsibleId})
  }

  onChange = responsible => {
    this.setState({
      error: null,
      performerResponsibleId: responsible && responsible.id,
    })
  }

  render() {
    const {
      state: {
        error,
      },
      props: {
        open,
        onClose,
        contract,
      },
      onAssign,
      onChange,
    } = this
    return (
      <FormDialog
        onClose={onClose} onSubmit={onAssign} fullWidth
                  open={open}
                  submitButtonTitle={i18next.t('service_desk_web_atoir:takePlace')}
        title={i18next.t('service_desk_web_atoir:acceptContract')}
                  contentText={
        <Fragment>
          <AutocompleteLimitedAuto
            InputProps={{required: true, error}}
            placeholder={i18next.t('service_desk_web_atoir:chooseResponsible')}
            sourceUrl={`${URL}/organizations/${contract.perf_organization.id}/users`}
            query={{limit: 10}}
            getItemDisplayName={u => getUserFullName(u)}
            getItemValue={u => u.id}
            onChange={onChange}
            transformInputToQuery={v => ({credentials: v})}
            requestResultTransformerToArray={res => res.users}
          />
          <Typography color="error">
            {error}
          </Typography>
        </Fragment>
      }
      />
    )
  }
}

AcceptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  contract: PropTypes.string.isRequired,
}

export default withStyles(styles)(AcceptDialog)
