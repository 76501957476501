import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  setInventoryProjectDone,
  setInventoryProjectClosed,
  setInventoryProjectBackInWork,
  fetchInventoryProjectProfileRights,
  fetchInventoryProjectProfile,
} from '../../../../../actions/inventoryActions'
import InventoryProjectHeading from './InventoryProjectHeading'

const mapStateToProps = state => ({
  isSettingProjectDone: state.inventory.setProjectDone.isSetting,
  setDoneSuccessfully: state.inventory.setProjectDone.setSuccessfully,
  setDoneErrors: state.inventory.setProjectDone.errors,
  isSettingProjectClosed: state.inventory.setProjectClosed.isSetting,
  setClosedSuccessfully: state.inventory.setProjectClosed.setSuccessfully,
  setClosedErrors: state.inventory.setProjectClosed.errors,
  isSettingProjectBackInWork: state.inventory.setProjectBackInWork.isSetting,
  setBackInWorkSuccessfully:
    state.inventory.setProjectBackInWork.setSuccessfully,
  setBackInWorkErrors: state.inventory.setProjectBackInWork.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setInventoryProjectDone,
      setInventoryProjectClosed,
      setInventoryProjectBackInWork,
      fetchInventoryProjectProfileRights,
      fetchInventoryProjectProfile,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryProjectHeading),
)
