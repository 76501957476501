import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import isEmpty from 'is-empty'
import i18next from '../../../../i18n/i18n'

// material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// components
import ApplyButton from '../../../../widgets/Buttons/ApplyButton'

// helpers
import { URL } from '../../../../middlewares/api'
import SelectLimitedFromControl from '../../../../widgets/SelectLimitedFormControl'
import { sortStringify } from '../../../../helpers/queryHelper'

const styles = theme => ({
  filtersContainerGrid: {
    paddingTop: theme.spacing(1),
  },
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
})

class FiltersComponent extends Component {
  constructor(props) {
    super(props)
    this.selectRefs = {}
    this.state = {
      params: {},
    }
  }

  componentDidMount() {
    if (this.props.createRef) {
      this.props.createRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: { status },
      state: { params },
    } = this
    const newParams = {}
    if (params && params.created_at_from)
      newParams.created_at_from = params.created_at_from
    if (params && params.created_at_to)
      newParams.created_at_to = params.created_at_to
    if (status !== prevProps.status) {
      this.setState(
        {
          params: newParams,
        },
        this.resetFilters,
      )
    }
  }

  resetFilters = () => {
    _.compact(Object.values(this.selectRefs)).map(ref => ref.reset())
  }

  onChangeDateFrom = date => {
    this.setState(state => ({
      params: {
        ...state.params,
        created_at_from: date,
      },
    }))
  }

  onChangeDateTo = date => {
    this.setState(state => ({
      params: {
        ...state.params,
        created_at_to: date,
      },
    }))
  }

  onChangeSelect = name => event => {
    this.setState(state => ({
      params: {
        ...state.params,
        [name]: event === '' || event === 'all' ? null : event,
      },
    }))
  }

  handleCreatedList = () => {
    const {
      props: { handleCreatedList },
    } = this
    const { params } = this.state
    handleCreatedList(params)
  }

  render() {
    const {
      state: { params },
      props: { filtersFor, status, classes, datePickers },
    } = this

    return (
      <Fragment>
        <Grid
          alignItems="flex-end"
          justify="space-between"
          className={classes.root}
        >
          {status !== 'created' && (
            <Grid item className={classes.filtersContainerGrid}>
              <SelectLimitedFromControl
                key={`${status}-select-limited-performer`}
                FormControlProps={{
                  fullWidth: true,
                }}
                label={i18next.t('shared_web_atoir:organizationPerformer')}
                firstElement={{ id: '', title: '' }}
                query={{
                  ...sortStringify({ short_name: 'ASC' }),
                  status,
                }}
                value={
                  isEmpty(params.performer_organization_id)
                    ? ''
                    : params.performer_organization_id
                }
                sourceUrl={`${URL}/${
                  filtersFor === 'calls'
                    ? 'service_calls'
                    : 'service_call_orders'
                }/list/performer_organizations`}
                onChange={this.onChangeSelect('performer_organization_id')}
                requestResultTransformerToArray={r =>
                  r.performer_organization_list
                }
                itemTransformer={l => ({ id: l.id, title: l.short_name })}
              />
            </Grid>
          )}
          <Grid item className={classes.filtersContainerGrid}>
            <SelectLimitedFromControl
              key={`${status}-select-limited-owner-equipment`}
              FormControlProps={{
                fullWidth: true,
              }}
              label={i18next.t(
                'service_desk_web_atoir:organizationOwnerEquipment',
              )}
              firstElement={{ id: '', title: '' }}
              query={{
                ...sortStringify({ short_name: 'ASC' }),
                status,
              }}
              value={
                isEmpty(params.equipment_owner) ? '' : params.equipment_owner
              }
              sourceUrl={`${URL}/${
                filtersFor === 'calls' ? 'service_calls' : 'service_call_orders'
              }/list/equipment_owner_organizations`}
              onChange={this.onChangeSelect('equipment_owner')}
              requestResultTransformerToArray={r => r.owner_organization_list}
              itemTransformer={l => ({ id: l.id, title: l.short_name })}
            />
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            className={classes.filtersContainerGrid}
          >
            {status === 'all' ? datePickers : null}
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            className={classes.filtersContainerGrid}
          >
            <ApplyButton onClick={this.handleCreatedList}>
              {i18next.t('service_desk_web_atoir:applyAction')}
            </ApplyButton>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

// TODO: Prop types

export default withStyles(styles)(FiltersComponent)
