import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

// components
import AddButton from '../../../../../../widgets/Buttons/AddIconTextButton'

// constants
import {
  firstSelect,
  secondSelect,
  thirdSelect,
} from '../../../../../../constants/emailTemplateParams'

const checkScopeForSubject = scope =>
  Boolean(scope.find(searchFor => searchFor === 'subject'))
const checkScopeForBody = scope =>
  Boolean(scope.find(searchFor => searchFor === 'body'))

const firstSelectValues = {}
firstSelectValues.subject = firstSelect.filter(value =>
  checkScopeForSubject(value.scope),
)
firstSelectValues.body = firstSelect.filter(value =>
  checkScopeForBody(value.scope),
)
const secondSelectValues = {}
secondSelectValues.subject = secondSelect.filter(value =>
  checkScopeForSubject(value.scope),
)
secondSelectValues.body = secondSelect.filter(value =>
  checkScopeForBody(value.scope),
)
const thirdSelectValues = {}
thirdSelectValues.subject = thirdSelect.filter(value =>
  checkScopeForSubject(value.scope),
)
thirdSelectValues.body = thirdSelect.filter(value =>
  checkScopeForBody(value.scope),
)

const styles = theme => ({
  addButton: {
    marginLeft: 0,
    marginTop: theme.spacing(2),
  },
})

function AddTemplateParameters(props) {
  const {
    classes,
    parameters,
    textField,
    arrayField,
    onAdd,
    onChange,
    onDelete,
    applyFor,
  } = props
  return (
    <Fragment>
      {parameters &&
        parameters.map(parameter => (
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="flex-end"
            justify="space-between"
          >
            <Grid item sm={3}>
              <TextField
                id="first-param-select"
                select
                fullWidth
                className={classes.textField}
                value={parameter.first}
                onChange={onChange(textField, arrayField, 'first', parameter)}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {firstSelectValues[applyFor].map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={4}>
              <TextField
                id="second-param-select"
                select
                fullWidth
                className={classes.textField}
                value={parameter.second}
                onChange={onChange(textField, arrayField, 'second', parameter)}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {secondSelectValues[applyFor].map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={4}>
              {parameter.third && (
                <TextField
                  id="third-param-select"
                  select
                  fullWidth
                  className={classes.textField}
                  value={parameter.third}
                  onChange={onChange(textField, arrayField, 'third', parameter)}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                >
                  {thirdSelectValues[applyFor].map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="Delete"
                onClick={() => onDelete(textField, arrayField, parameter)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <AddButton
        onClick={() =>
          onAdd(textField, arrayField, {
            first: firstSelectValues[applyFor][0].value,
            second: secondSelectValues[applyFor][0].value,
            third: null,
          })
        }
        className={classes.addButton}
      >
        {i18next.t('organizations_web_atoir:addTemplateParam')}
      </AddButton>
    </Fragment>
  )
}

AddTemplateParameters.defaultProps = {
  applyFor: 'body',
}

AddTemplateParameters.propTypes = {
  applyFor: PropTypes.oneOf(['body', 'subject']),
  parameters: PropTypes.array.isRequired,
  textField: PropTypes.string.isRequired,
  arrayField: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default withStyles(styles)(AddTemplateParameters)
