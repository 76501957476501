import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  inventoryLocationStringifyDefault,
  organizationAddressStringifyFull,
} from '../../../../../helpers/parserHelper'
import { addMaskCharacters } from '../../../../../helpers/phoneStringHelper'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../../i18n/i18n'

// components
import RatingStars from '../../../../Organizations/Stateless/RatingStars'
import SecondaryButton from '../../../../../widgets/Buttons/SecondaryButton'
import ShowRatingDialog from '../ProjectRating/ShowRatingDialog'
import ProfileRow from '../Widgets/ProfileRow'
import TitleLink from '../../../../../widgets/Lettering/TitleLink'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const StyledTypography = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
  },
}))(Typography)

class InventoryProjectClientInfo extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      openShowRatingDialog: false,
    }
  }

  handleDialogOpen = field => {
    this.setState({
      [field]: true,
    })
  }

  handleDialogClose = field => {
    this.setState({
      [field]: false,
    })
  }

  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  getAddress = structure =>
    organizationAddressStringifyFull(structure.OrganizationLocation)

  displayAddress = (classes, address, floor, office, amount) => (
    <Grid container xs={12} direction="row" justify="space-between">
      <Grid item xs={6}>
        <Grid container direction="column">
          <Typography align="left">{address}</Typography>
          <Grid container xs={6} direction="row">
            <Grid item xs={3}>
              <Typography align="left">
                {`${i18next.t('equipment_web_atoir:floor')}: ${floor}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left">
                {`${i18next.t('shared_web_atoir:office')}: ${office}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column" alignItems="flex-end">
          <Typography align="right">
            {`${i18next.t('inventory_web_atoir:inventoryEquipmentAmount')}`}
          </Typography>
          <Typography align="right">{amount}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )

  render = () => {
    const {
      props: {
        classes,
        clientOrganization,
        performerOrganization,
        clientRating,
        projectLocations,
        projectResponsible,
        projectBeginDesired,
        projectEndDesired,
        projectComment,
        status,
        projectRatings,
        match,
      },
      state: { openShowRatingDialog },
      getDividerGrid,
      displayAddress,
      handleDialogOpen,
    } = this
    return (
      <Grid container spacing={14} justify="flex-start">
        <Grid item direction="row" xs={12}>
          <ProfileRow title={i18next.t('inventory_web_atoir:client')}>
            <Fragment>
              <div className={classes.infoContentDiv}>
                <div>
                  <TitleLink
                    link={`${match.url}/organizations/${clientOrganization.id}`}
                    classes={classes}
                  >
                    {`${clientOrganization.full_name}`}
                  </TitleLink>
                </div>
                <div>
                  <RatingStars rating={clientRating} />
                  {status === 'close' && (
                    <SecondaryButton
                      onClick={() => handleDialogOpen('openShowRatingDialog')}
                    >
                      {i18next.t('inventory_web_atoir:showProjectRatings')}
                    </SecondaryButton>
                  )}
                  {status === 'close' && (
                    <ShowRatingDialog
                      classes={classes}
                      isOpen={openShowRatingDialog}
                      isLoading={false}
                      handleClose={() =>
                        this.handleDialogClose('openShowRatingDialog')
                      }
                      positiveAction={() =>
                        this.handleDialogClose('openShowRatingDialog')
                      }
                      client={clientOrganization}
                      performer={performerOrganization}
                      ratings={projectRatings}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          </ProfileRow>
          <ProfileRow
            title={i18next.t('organizations_web_atoir:orgAddressCaption')}
          >
            <Typography>
              {!isEmpty(clientOrganization.OrganizationLocations)
                ? inventoryLocationStringifyDefault(
                    clientOrganization.OrganizationLocations[0],
                  )
                : ''}
            </Typography>
          </ProfileRow>
        </Grid>
        {getDividerGrid()}
        <Grid item direction="row" xs={12}>
          <ProfileRow
            title={i18next.t('equipment_web_atoir:equipmentPlacement')}
          >
            <Typography>
              {projectLocations.map(l => {
                const address = inventoryLocationStringifyDefault(
                  l.OrganizationLocation,
                )
                const floor = l.OrganizationLocation.floor
                  ? l.OrganizationLocation.floor
                  : '-'
                const office = l.OrganizationLocation.office
                  ? l.OrganizationLocation.office
                  : '-'
                let amount
                if (l.equipments_amt === '5000') {
                  amount = `${i18next.t('shared_web_atoir:unitFrom')} ${
                    l.equipments_amt
                  } ${i18next.t('inventory_web_atoir:pieceShorten')}.`
                } else if (l.equipments_amt === '100') {
                  amount = `${i18next.t('shared_web_atoir:unitTo')} ${
                    l.equipments_amt
                  } ${i18next.t('inventory_web_atoir:pieceShorten')}.`
                } else {
                  amount = `${l.equipments_amt} ${i18next.t(
                    'inventory_web_atoir:pieceShorten',
                  )}.`
                }
                return displayAddress(classes, address, floor, office, amount)
              })}
            </Typography>
          </ProfileRow>
        </Grid>
        {getDividerGrid()}

        <Grid item direction="row" xs={12}>
          <ProfileRow title={i18next.t('inventory_web_atoir:desiredDeadline')}>
            <Typography>
              {projectBeginDesired && projectEndDesired
                ? `${new Date(projectBeginDesired).toLocaleDateString('ru')}
                  - ${new Date(projectEndDesired).toLocaleDateString('ru')}`
                : '_ - _'}
            </Typography>
          </ProfileRow>
          <ProfileRow
            title={i18next.t('organizations_web_atoir:responsiblePerson')}
          >
            <div>
              <Typography align="left" variant="body2">
                {`${projectResponsible.last_name} `}
                {`${projectResponsible.first_name} `}
                {`${projectResponsible.middle_name || ''}`}
              </Typography>
              <StyledTypography align="left">
                {projectResponsible.position === 'owner'
                  ? `${i18next.t('organizations_web_atoir:ownerRole')}`
                  : i18next.t(projectResponsible.position) ||
                    projectResponsible.position}
              </StyledTypography>
              {projectResponsible.phone ? (
                <StyledTypography align="left">
                  {addMaskCharacters(projectResponsible.phone)}
                </StyledTypography>
              ) : null}
            </div>
          </ProfileRow>
          <ProfileRow
            title={i18next.t('inventory_web_atoir:inventoryComments')}
          >
            <div>
              {projectComment && (
                <Typography align="left" variant="body2">
                  {projectComment}
                </Typography>
              )}
            </div>
          </ProfileRow>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  linkText: {
    color: theme.palette.textSecondary.main,
  },
  container: {
    marginTop: 20,
  },
  infoContentDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleLink: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 700,
  },
  link: {
    textDecoration: 'none',
  },
})

InventoryProjectClientInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  clientOrganization: PropTypes.object.isRequired,
  performerOrganization: PropTypes.object.isRequired,
  clientRating: PropTypes.number.isRequired,
  projectLocations: PropTypes.array.isRequired,
  projectResponsible: PropTypes.object.isRequired,
  projectBeginDesired: PropTypes.object.isRequired,
  projectEndDesired: PropTypes.object.isRequired,
  projectComment: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  projectRatings: PropTypes.object.isRequired,
}

export default withStyles(styles)(InventoryProjectClientInfo)
