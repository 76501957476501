import {
  FETCH_EMPLOYERS_REQUEST,
  FETCH_EMPLOYERS_SUCCESS,
  FETCH_EMPLOYERS_FAILURE,
  LEAVE_ORGANIZATION_REQUEST,
  LEAVE_ORGANIZATION_SUCCESS,
  LEAVE_ORGANIZATION_FAILURE,
  CONFIRM_JOIN_ORGANIZATION_REQUEST,
  CONFIRM_JOIN_ORGANIZATION_SUCCESS,
  CONFIRM_JOIN_ORGANIZATION_FAILURE,
  FETCH_EMPLOYERS_COUNT_REQUEST,
  FETCH_EMPLOYERS_COUNT_SUCCESS,
  FETCH_EMPLOYERS_COUNT_FAILURE,
} from '../constants/actionTypes'

export const fetchEmployers = data => ({
  type: FETCH_EMPLOYERS_REQUEST,
  data,
})

export const fetchEmployersSuccess = data => ({
  type: FETCH_EMPLOYERS_SUCCESS,
  data,
})

export const fetchEmployersFailure = errors => ({
  type: FETCH_EMPLOYERS_FAILURE,
  errors,
})

export const fetchEmployersCount = data => ({
  type: FETCH_EMPLOYERS_COUNT_REQUEST,
  data,
})

export const fetchEmployersCountSuccess = count => ({
  type: FETCH_EMPLOYERS_COUNT_SUCCESS,
  count,
})

export const fetchEmployersCountFailure = errors => ({
  type: FETCH_EMPLOYERS_COUNT_FAILURE,
  errors,
})

export const leaveOrganization = id => ({
  type: LEAVE_ORGANIZATION_REQUEST,
  id,
})

export const leaveOrganizationSuccess = () => ({
  type: LEAVE_ORGANIZATION_SUCCESS,
})

export const leaveOrganizationFailure = errors => ({
  type: LEAVE_ORGANIZATION_FAILURE,
  errors,
})

export const confirmJoinOrganization = hash => ({
  type: CONFIRM_JOIN_ORGANIZATION_REQUEST,
  hash,
})

export const confirmJoinOrganizationSuccess = () => ({
  type: CONFIRM_JOIN_ORGANIZATION_SUCCESS,
})

export const confirmJoinOrganizationFailure = errors => ({
  type: CONFIRM_JOIN_ORGANIZATION_FAILURE,
  errors,
})
