export default [
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion1',
    answer: 'faq_web_atoir:faqAnswer1',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion2',
    answer: 'faq_web_atoir:faqAnswer2',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion3',
    answer: 'faq_web_atoir:faqAnswer3',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion4',
    answer: 'faq_web_atoir:faqAnswer4',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion5',
    answer: 'faq_web_atoir:faqAnswer5',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion6',
    answer: 'faq_web_atoir:faqAnswer6',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion7',
    answer: 'faq_web_atoir:faqAnswer7',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion8',
    answer: 'faq_web_atoir:faqAnswer8',
  },
  {
    section: 'faq_web_atoir:faqSectionSD',
    question: 'faq_web_atoir:faqQuestion9',
    answer: 'faq_web_atoir:faqAnswer9',
  },
]
