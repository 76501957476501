import React from 'react'
import Menu from '@material-ui/core/Menu/index'
import MenuItem from '@material-ui/core/MenuItem/index'
import IconButton from '@material-ui/core/IconButton/index'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const disableMenuItems = (menuItems, disabledItems) =>
  menuItems.map(item => ({ ...item, enable: !disabledItems[item.id] }))

const styles = {
  menuBtn: {},
}

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.menuItems !== this.props.menuItems ||
      nextProps.disabledItems !== this.props.disabledItems ||
      nextState.anchorEl !== this.state.anchorEl
    )
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleMenuClick = ({ target: { id } }) => {
    const { menuItems } = this.props
    const { onClick } = menuItems.find(item => item.id === id)
    this.setState({ anchorEl: null }, () => {
      onClick()
    })
  }

  render() {
    const { anchorEl } = this.state
    const { classes, menuItems, disabledItems } = this.props

    return (
      <div>
        <IconButton
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.menuBtn}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {disableMenuItems(menuItems, disabledItems).map(item => (
            <MenuItem
              id={item.id}
              disabled={!item.enable}
              key={item.title}
              onClick={this.handleMenuClick}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

SimpleMenu.defaultProps = {
  menuItems: [],
  disabledItems: {},
}

SimpleMenu.propTypes = {
  menuItems: PropTypes.string,
  disabledItems: PropTypes.object,
}

export default withStyles(styles)(SimpleMenu)
