export const GET_ORGANIZATION_RECURRING_CALLS_REQUEST =
  'recurringCalls/GET_ORGANIZATION_RECURRING_CALLS_REQUEST'
export const GET_ORGANIZATION_RECURRING_CALLS_SUCCESS =
  'recurringCalls/GET_ORGANIZATION_RECURRING_CALLS_SUCCESS'
export const GET_ORGANIZATION_RECURRING_CALLS_FAILURE =
  'recurringCalls/GET_ORGANIZATION_RECURRING_CALLS_FAILURE'

export const GET_ORGANIZATION_RECURRING_CALLS_COUNT_REQUEST =
  'recurringCalls/GET_ORGANIZATION_RECURRING_CALLS_COUNT_REQUEST'
export const GET_ORGANIZATION_RECURRING_CALLS_COUNT_SUCCESS =
  'recurringCalls/GET_ORGANIZATION_RECURRING_CALLS_COUNT_SUCCESS'
export const GET_ORGANIZATION_RECURRING_CALLS_COUNT_FAILURE =
  'recurringCalls/GET_ORGANIZATION_RECURRING_CALLS_COUNT_FAILURE'
