import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const styles = theme => ({
  rootDiv: {
    display: 'flex',
  },
  titleDiv: {
    flexBasis: '230px',
    flexGrow: 0,
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  infoDiv: {
    flexBasis: '100px',
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
})

const StyledTypography = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
  },
}))(Typography)

const ProfileRow = withStyles(styles)(props => (
  <div className={props.classes.rootDiv}>
    <div className={props.classes.titleDiv}>
      <StyledTypography>{props.title}</StyledTypography>
    </div>
    <div className={props.classes.infoDiv}>{props.children}</div>
  </div>
))

ProfileRow.propTypes = {
  title: PropTypes.string.isRequired,
  infoDiv: PropTypes.element.isRequired,
}
export default ProfileRow
