import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Loader from '../../components/Loader'

class DelayedLoader extends PureComponent {
  state = {
    showLoader: false,
  }

  componentDidUpdate(prevProps) {
    const { requestLoader, clearLoader } = this

    if (this.props.show !== prevProps.show) {
      if (this.props.show === true) {
        requestLoader()
      } else {
        clearLoader()
      }
    }
  }

  requestLoader = () => {
    this.loaderTimer = setTimeout(
      () => this.setState({ showLoader: true }),
      this.props.delay,
    )
  }

  clearLoader = () => {
    clearTimeout(this.loaderTimer)
    if (this.state.showLoader) {
      this.loaderTimer = setTimeout(
        () => this.setState({ showLoader: false }),
        this.props.delayOut,
      )
    }
  }

  render() {
    return this.state.showLoader ? (
      <Loader style={this.props.loaderStyles} />
    ) : (
      ''
    )
  }
}

DelayedLoader.defaultProps = {
  delay: 0,
  delayOut: 0,
  show: false,
  loaderStyles: {},
}

DelayedLoader.propTypes = {
  delay: PropTypes.number,
  delayOut: PropTypes.number,
  show: PropTypes.bool,
  loaderStyles: PropTypes.object,
}

export default DelayedLoader
