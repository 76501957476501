import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import i18next from '../../../../i18n/i18n'
import SelectLimitedFormControl from '../../../../widgets/SelectLimitedFormControl'
import { sortStringify } from '../../../../helpers/queryHelper'
import { URL } from '../../../../middlewares/api'

const styles = {}

const MailTemplatesSelector = ({
  organizationId,
  onChange,
  idValue,
  currentValue,
  error,
  fullWidth,
}) => {
  const onChangeHandler = value => {
    onChange(idValue, value)
  }

  return (
    <SelectLimitedFormControl
      FormControlProps={{
        required: true,
        fullWidth,
        error: Boolean(error),
      }}
      value={currentValue}
      label={i18next.t('organizations_web_atoir:emailTemplateCaption')}
      query={{
        ...sortStringify({ name: 'ASC' }),
      }}
      sourceUrl={`${URL}/organizations/${organizationId}/settings/emails/templates`}
      onChange={onChangeHandler}
      requestResultTransformerToArray={data => data.templates}
      itemTransformer={row => ({ id: row.id, title: row.name })}
    />
  )
}

MailTemplatesSelector.defaultProps = {
  currentValue: null,
  error: '',
  fullWidth: false,
}

MailTemplatesSelector.propTypes = {
  fullWidth: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  idValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string,
  error: PropTypes.string,
}

export default withStyles(styles)(MailTemplatesSelector)
