import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import UserProfileComponent from '../../components/User/UserProfileComponent'
import { fetchUser, fetchUserById } from '../../actions/userActions'
import { fetchOrganization } from '../../actions/organizationsActions'
import { logout } from '../../actions/loginActions'

const mapStateToProps = state => ({
  isFetchingUser: state.user.isFetchingUser,
  isLoggedIn: localStorage.getItem('loggedIn'),
  user: state.user.user,
  organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUser,
      fetchOrganization,
      fetchUserById,
      logout,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent),
)
