import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  fetchUserOrganizationFunctionalsRequest,
  createSDInvoiceForOrganization,
} from '../../../actions/organizationsActions'

import ServiceDeskBanner from './ServiceDeskBanner'

const mapStateToProps = state => ({
  organizationsFunctionals:
    state.organizations.organizationFunctionals.organizationsFunctionals,
  isFetching: state.organizations.organizationFunctionals.isFetching,
  isInvoiceCreating:
    state.organizations.createSDInvoiceForOrganization.isFetching,
  invoiceCreatingError:
    state.organizations.createSDInvoiceForOrganization.error,
  invoiceCreatingOrgId: state.organizations.createSDInvoiceForOrganization.id,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFunctionals: fetchUserOrganizationFunctionalsRequest,
      createInvoice: createSDInvoiceForOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceDeskBanner),
)
