import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import i18next from '../../../../i18n/i18n'
import AddButton from '../../../../widgets/Buttons/AddIconTextButton'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Loader from '../../../Loader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '../../../../widgets/FormDialog'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  headingExpanded: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: theme.spacing(1.5),
  },
  historyChanges: {
    color: theme.palette.apply.extraLight,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.apply.main,
      textDecoration: 'underline',
    },
  },
  listContainer: {
    width: '100%',
  },
  details: {
    padding: 0,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    margin: theme.spacing(1),
  },
  listItemSecondaryAction: {
    marginRight: theme.spacing(3),
  },
  loader: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonName: {
    marginRight: theme.spacing(3),
    wordBreak: 'break-word',
  },
})

class ActionsButtons extends Component {
  state = {
    expanded: false,
    openDeleteDialog: false,
    deleteButtonId: null,
  }

  getButtons = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        getButtonsRequest,
      },
    } = this
    getButtonsRequest({ organizationId })
  }

  deleteButton = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        deleteButtonRequest,
      },
      state: { deleteButtonId },
    } = this
    deleteButtonRequest({ organizationId, buttonId: deleteButtonId })
  }

  deleteDialogOpen = open => {
    this.setState(() => ({
      openDeleteDialog: open,
    }))
  }

  componentDidUpdate = prevProps => {
    const {
      props: { isDeleting, updateSettingsLists },
      getButtons,
    } = this
    if (prevProps.isDeleting !== isDeleting && !isDeleting) {
      updateSettingsLists(true)
      this.setState(
        () => ({ deleteButtonId: null, openDeleteDialog: false }),
        () => getButtons(),
      )
    }
  }

  onExpandedChange = () => {
    this.setState(
      state => ({
        expanded: !state.expanded,
      }),
      () => {
        if (this.state.expanded) {
          this.getButtons()
        }
      },
    )
  }

  render() {
    const {
      state: { expanded, openDeleteDialog },
      props: { classes, match, history, buttons, isDeleting, isFetching },
      onExpandedChange,
      deleteDialogOpen,
      deleteButton,
    } = this
    return (
      <ExpansionPanel
        id="organization-settings-actions-buttons"
        component="div"
        onChange={onExpandedChange}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <div>
                <Typography
                  className={
                    expanded ? classes.headingExpanded : classes.heading
                  }
                >
                  {i18next.t('organizations_web_atoir:ActionsButtons')}
                </Typography>
                {expanded && (
                  <Typography
                    variant="body2"
                    className={classes.historyChanges}
                    component={Link}
                    to={`${match.url}/buttons/history`}
                  >
                    {i18next.t(
                      'organizations_web_atoir:orgSettingsButtonsChangeLog',
                    )}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item>
              {expanded && (
                <AddButton
                  onClick={() => history.push(`${match.url}/buttons/create`)}
                >
                  {i18next.t('organizations_web_atoir:addButton')}
                </AddButton>
              )}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails className={classes.details}>
          <Fragment>
            {isFetching ? (
              <Loader size={24} className={classes.loader} />
            ) : (
              <div className={classes.listContainer}>
                <List className={classes.list}>
                  {buttons.map(button => (
                    <Fragment>
                      <ListItem
                        className={classes.listItem}
                        onClick={() =>
                          history.push(`${match.url}/buttons/edit/${button.id}`)
                        }
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            variant: 'caption',
                          }}
                          primary={button.name}
                          className={classes.buttonName}
                        />
                        <ListItemSecondaryAction
                          className={classes.listItemSecondaryAction}
                        >
                          <IconButton
                            aria-label="Delete"
                            onClick={() => {
                              this.setState({ deleteButtonId: button.id }, () =>
                                deleteDialogOpen(true),
                              )
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </List>
              </div>
            )}
            <Dialog
              open={openDeleteDialog}
              contentText={i18next.t(
                'organizations_web_atoir:areYouSureToDeleteButton',
              )}
              submitButtonTitle={i18next.t('shared_web_atoir:delete')}
              onSubmit={deleteButton}
              onClose={() => deleteDialogOpen(false)}
              onExit={() => deleteDialogOpen(false)}
              isLoading={isDeleting}
            />
          </Fragment>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

ActionsButtons.propTypes = {
  getButtonsRequest: PropTypes.func.isRequired,
  deleteButtonRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  buttons: PropTypes.array.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  updateSettingsLists: PropTypes.func.isRequired,
}

export default withStyles(styles)(ActionsButtons)
