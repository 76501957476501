import React from 'react'
import PropTypes from 'prop-types'

// material
import TextField from '@material-ui/core/TextField/TextField'
import { withStyles } from '@material-ui/core'

// modules
import FormDialog from '../../../../../../widgets/FormDialog'

// i18next
import i18next from '../../../../../../i18n/i18n'

const addStyles = theme => ({
  textFieldMulty: {
    width: theme.typography.pxToRem(500),
  },
})

const CancelOrderDialog = withStyles(addStyles)(props => (
  <FormDialog
    open={props.open}
    isLoading={props.isLoading}
    title={i18next.t('service_desk_web_atoir:cancelOrderCaption')}
    onClose={props.onClose}
    onExit={props.onClose}
    onSubmit={props.onCancelOrder}
    submitButtonTitle={i18next.t('service_desk_web_atoir:confirm')}
  >
    <TextField
      required
      label={i18next.t('service_desk_web_atoir:cancelOrderNote')}
      onChange={props.onCancelReasonEdit}
      variant="outlined"
      multiline
      rows="4"
      className={props.classes.textFieldMulty}
      margin="normal"
      error={props.reasonValid === false}
    />
  </FormDialog>
))

CancelOrderDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  reasonValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  onCancelReasonEdit: PropTypes.func.isRequired,
}

export default CancelOrderDialog
