import React, { Fragment, Component } from 'react'
import isEmpty from 'is-empty'
import i18next from '../../../../../i18n/i18n'
import PropTypes from 'prop-types'
import { inventoryLocationStringifyDefault } from '../../../../../helpers/parserHelper'
import { addMaskCharacters } from '../../../../../helpers/phoneStringHelper'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles/index'

// --- components---
import RatingStars from '../../../../Organizations/Stateless/RatingStars'
import SecondaryButton from '../../../../../widgets/Buttons/SecondaryButton'
import ShowRatingDialog from '../ProjectRating/ShowRatingDialog'
import TitleLink from '../../../../../widgets/Lettering/TitleLink'
import ProfileRow from '../Widgets/ProfileRow'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  section: {
    marginBottom: '40px',
  },
  noUnderline: {
    textDecoration: 'none',
  },
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
  sectionContainer: {
    marginBottom: '20px',
  },
  infoContentDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleLink: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 700,
  },
  link: {
    textDecoration: 'none',
  },
})

const StyledTypography = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
  },
}))(Typography)

class InventoryPerformerInfoBase extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      openShowRatingDialog: false,
    }
  }

  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  handleDialogOpen = field => {
    this.setState({
      [field]: true,
    })
  }

  handleDialogClose = field => {
    this.setState({
      [field]: false,
    })
  }

  render() {
    const {
      props: {
        classes,
        inventoryProject: {
          Performer: performer,
          rating_toclient: ratingToClient,
          rating_toclient_comment: commentToClient,
          rating_toperformer: ratingToPerformer,
          rating_toperformer_comment: commentToPerformer,
          PerformerResponsible,
          InventoryOffers,
          status,
          Client: client,
          match,
        },
      },
      state: { openShowRatingDialog },
      handleDialogOpen,
    } = this
    const projectRatings = {
      ratingToClient,
      commentToClient,
      ratingToPerformer,
      commentToPerformer,
    }
    const rating = isEmpty(ratingToPerformer)
      ? 0
      : Math.floor(ratingToPerformer)
    return (
      <Fragment>
        <Grid container spacing={1} justify="flex-start">
          <Grid className={classes.sectionContainer} item xs={12}>
            <Grid item xs={12}>
              <ProfileRow title={i18next.t('inventory_web_atoir:performer')}>
                <div className={classes.infoContentDiv}>
                  <div>
                    <TitleLink
                      link={`${match.url}/organizations/${performer.id}`}
                      classes={classes}
                    >
                      {isEmpty(performer) ? '' : performer.full_name}
                    </TitleLink>
                  </div>
                  <div>
                    <RatingStars rating={rating} />
                    {status === 'close' && (
                      <SecondaryButton
                        onClick={() => handleDialogOpen('openShowRatingDialog')}
                      >
                        {i18next.t('inventory_web_atoir:showProjectRatings')}
                      </SecondaryButton>
                    )}
                    {status === 'close' && (
                      <ShowRatingDialog
                        classes={classes}
                        isOpen={openShowRatingDialog}
                        isLoading={false}
                        handleClose={() =>
                          this.handleDialogClose('openShowRatingDialog')
                        }
                        positiveAction={() =>
                          this.handleDialogClose('openShowRatingDialog')
                        }
                        client={client}
                        performer={performer}
                        ratings={projectRatings}
                      />
                    )}
                  </div>
                </div>
              </ProfileRow>
              <ProfileRow
                title={i18next.t('organizations_web_atoir:orgAddressCaption')}
              >
                {!isEmpty(performer.OrganizationLocations) && (
                  <Typography>
                    {inventoryLocationStringifyDefault(
                      performer.OrganizationLocations[0],
                    )}
                  </Typography>
                )}
              </ProfileRow>
              <ProfileRow
                title={i18next.t('organizations_web_atoir:responsiblePerson')}
              >
                <div>
                  {!isEmpty(PerformerResponsible) ? (
                    <Fragment>
                      <Typography align="left">
                        {`${PerformerResponsible.last_name} `}
                        {`${PerformerResponsible.first_name} `}
                        {`${PerformerResponsible.middle_name || ''}`}
                      </Typography>
                      <StyledTypography align="left">
                        {PerformerResponsible.position === 'owner'
                          ? `${i18next.t('organizations_web_atoir:ownerRole')}`
                          : i18next.t(PerformerResponsible.position) ||
                            PerformerResponsible.position}
                      </StyledTypography>
                      {!isEmpty(PerformerResponsible.phone) && (
                        <StyledTypography align="left">
                          {addMaskCharacters(PerformerResponsible.phone)}
                        </StyledTypography>
                      )}
                    </Fragment>
                  ) : (
                    <Typography align="left">
                      {i18next.t('inventory_web_atoir:noData')}
                    </Typography>
                  )}
                </div>
              </ProfileRow>
              {!isEmpty(InventoryOffers) && (
                <ProfileRow title={i18next.t('inventory_web_atoir:totalCost')}>
                  <Typography align="left" variant="body1">
                    {InventoryOffers[0] && InventoryOffers[0].amount}
                  </Typography>
                </ProfileRow>
              )}
              <ProfileRow title={i18next.t('inventory_web_atoir:comment')}>
                <Typography align="left" variant="body2">
                  {InventoryOffers[0] && InventoryOffers[0].performer_comment}
                </Typography>
              </ProfileRow>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

InventoryPerformerInfoBase.propTypes = {
  inventoryProject: PropTypes.object.isRequired,
}

export default withStyles(styles)(InventoryPerformerInfoBase)
