import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// material-ui
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// modules
import LongTextExpander from '../../../../../widgets/LongTextExpander'
import IconButton from '@material-ui/core/IconButton/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  icon: {
    color: theme.palette.text.main,
  },
  editIconPosition: {
    height: 0,
    marginTop: -10,
    paddingLeft: 16,
  },
})

const InfoRow = props =>
  props.hideIfEmpty && isEmpty(props.value) ? null : (
    <Grid
      container
      direction="row"
      spacing={2}
      alignItems="flex-start"
      justify="flex-start"
    >
      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
        <Typography variant="subtitle1">{props.name}</Typography>
      </Grid>
      <Grid item xl={9} lg={9} md={9} sm={8} xs={6}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            {props.expandMore ? (
              <LongTextExpander>
                {isEmpty(props.value) ? '-' : props.value}
              </LongTextExpander>
            ) : (
              <Typography component="span" variant="subtitle1">
                {isEmpty(props.value) ? '-' : props.value}
              </Typography>
            )}
            {props.onEdit && (
              <div className={props.classes.editIconPosition}>
                <IconButton onClick={props.onEdit} data-cy="InfoRowIconButton">
                  <EditIcon className={props.classes.icon} />
                </IconButton>
              </div>
            )}
          </div>
          {props.actionsArea && props.actionsArea()}
        </div>
        {!isEmpty(props.additional) && (
          <Typography variant="subtitle1">{props.additional}</Typography>
        )}
      </Grid>
    </Grid>
  )

InfoRow.defaultProps = {
  hideIfEmpty: false,
  additional: '',
  expandMore: false,
  onEdit: null,
}

InfoRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  additional: PropTypes.string,
  hideIfEmpty: PropTypes.bool,
  expandMore: PropTypes.bool,
  onEdit: PropTypes.func,
}

export default withStyles(styles)(InfoRow)
