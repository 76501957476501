import React, { Component } from 'react'
import isEmpty from 'is-empty'

// ---components---
import i18next from '../../../../../i18n/i18n'
import PropTypes from 'prop-types'
import FormDialog from '../../../../Other/FormDialog'
import ConfirmInfo from './ConfirmInfo'
import ConfirmForm from './ConfirmForm'

class ConfirmEditEngineerOrder extends Component {
  state = {
    performerUserId: null,
    error: false,
  }

  componentWillReceiveProps(nextProps) {
    const {
      props: { order },
    } = this
    if (order !== nextProps.order && !isEmpty(nextProps.order)) {
      this.setState({
        performerUserId: nextProps.order.performer_user_id,
      })
    }
  }

  handleChange = event => {
    this.setState({
      performerUserId: event.target.value,
      error: false,
    })
  }

  handleSubmit = () => {
    const {
      props: { changeEngineer, order },
      state: { performerUserId },
    } = this
    if (this.validateForm()) {
      changeEngineer(order.id, {
        performer_user_id: performerUserId,
      })
    }
  }

  validateForm = () => {
    const {
      props: { order },
      state: { performerUserId },
    } = this
    if (order.performer_user_id === performerUserId) {
      this.setState({
        error: true,
      })
    }
    return order.performer_user_id !== performerUserId
  }

  render() {
    const {
      props: { handleClose, isLoading, open, order },
      state: { performerUserId, error },
    } = this
    return (
      <FormDialog
        open={open}
        title={i18next.t('inventory_web_atoir:editSpecialistTourOfDuty')}
        onSubmit={this.handleSubmit}
        submitButtonTitle={i18next.t(
          'inventory_web_atoir:setOwnEngineerAndClose',
        )}
        onClose={handleClose}
        onExit={handleClose}
        content={
          <ConfirmForm
            handleChange={this.handleChange}
            performerUserId={performerUserId}
            error={error}
          />
        }
        contentText={<ConfirmInfo order={order} />}
        isLoading={isLoading}
      />
    )
  }
}

ConfirmEditEngineerOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  changeEngineer: PropTypes.func.isRequired,
}

export default ConfirmEditEngineerOrder
