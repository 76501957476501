import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'

// component
import InnerCallsTableCell from './InnerCallsTableCell'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import LoadingWrapper from '../../../../widgets/LoadingWrapper'
import Loader from '../../../Loader'
import Link from 'react-router-dom/Link'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  listRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  fab: {
    position: 'absolute',
    zIndex: 101,
    right: -28,
    top: 16,
  },
  fabIcon: {
    color: 'white',
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  titleEmptyList: {
    paddingBottom: theme.spacing(3),
  },
})

class InnerCallsTable extends Component {
  state = {}

  render() {
    const {
      props: {
        classes,
        match,
        isFetching,
        innerCalls,
        count,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        ifOneOrganizationId,
        rowsPerPageOptions,
      },
    } = this
    return (
      <div className={classes.root}>
        <Fragment>
          <LoadingWrapper
            loading={isFetching}
            inDelay={0}
            outDelay={500}
            data={innerCalls || []}
          >
            {({ data, loading }) => (
              <LoadingWrapper.Fader show={loading && data.length > 0}>
                <>
                  {isEmpty(innerCalls) && !loading && (
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.titleEmptyList}
                    >
                      {i18next.t('shared_web_atoir:emptyList')}
                    </Typography>
                  )}
                  {/* eslint-disable-next-line no-self-compare */}
                  {loading && data.length === 0 ? (
                    <div
                      style={{
                        height: 60,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    <List className={classes.listRoot}>
                      {data &&
                        data.map((c, index) => (
                          <Fragment>
                            <ListItem
                              button
                              component={Link}
                              to={`${match.url}/${c.id}`}
                            >
                              <InnerCallsTableCell
                                ifOneOrganizationId={ifOneOrganizationId}
                                innerCall={c}
                                rootStyle={{ padding: '8px 24px' }}
                              />
                            </ListItem>
                            {data && data.length !== index + 1 && (
                              <Divider className={classes.divider} />
                            )}
                          </Fragment>
                        ))}
                    </List>
                  )}
                </>
              </LoadingWrapper.Fader>
            )}
          </LoadingWrapper>
          {count ? (
            <TablePagination
              component="div"
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
              labelDisplayedRows={({
                from: fromPage,
                to: toPage,
                count: countPage,
              }) =>
                i18next.t('shared_web_atoir:labelDisplayedRows', {
                  fromPage,
                  toPage,
                  countPage,
                })
              }
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </Fragment>
        <div className={classes.fab}>
          <Fab
            color="primary"
            onClick={() => {
              this.props.history.push('/inner_calls/create')
            }}
          >
            <AddIcon className={classes.fabIcon} />
          </Fab>
        </div>
      </div>
    )
  }
}

InnerCallsTable.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  innerCalls: PropTypes.array.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
}

export default withStyles(styles)(InnerCallsTable)
