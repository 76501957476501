import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  CREATE_CONTACT_REQUEST,
  DELETE_CONTACT_REQUEST,
  FETCH_CONTACTS_REQUEST,
  UPDATE_CONTACT_REQUEST,
} from '../constants/actionTypes'
import {
  fetchContactsRequest,
  deleteContactRequest,
  createContactRequest,
  updateContactRequest,
} from './api'
import {
  fetchContactsSuccess,
  fetchContactsFailure,
  deleteContactSuccess,
  deleteContactFailure,
  createContactSuccess,
  createContactFailure,
  updateContactSuccess,
  updateContactFailure,
} from '../actions/organizationContactsActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'

export function* watchFetchContactsSaga() {
  yield takeLatest(FETCH_CONTACTS_REQUEST, fetchContacts)
}

export function* watchDeleteContactSaga() {
  yield takeLatest(DELETE_CONTACT_REQUEST, deleteContact)
}

export function* watchCreateContactSaga() {
  yield takeLatest(CREATE_CONTACT_REQUEST, createContact)
}

export function* watchUpdateContactSaga() {
  yield takeLatest(UPDATE_CONTACT_REQUEST, updateContact)
}

function* fetchContacts(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchContactsRequest(accessToken, action.organizationId, action.data),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchContactsSuccess(result.contacts))
      } else {
        const errors = handleErrors(result)
        yield put(fetchContactsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteContact(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      deleteContactRequest(accessToken, action.organizationId, action.id),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(deleteContactSuccess(action.id))
      } else {
        const errors = handleErrors(result)
        yield put(deleteContactFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* createContact(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      createContactRequest(accessToken, action.organizationId, action.data),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(createContactSuccess(result.contacts))
      } else {
        const errors = handleErrors(result)
        yield put(createContactFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateContact(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      updateContactRequest(
        accessToken,
        action.organizationId,
        action.contactId,
        action.data,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateContactSuccess(result.contacts))
      } else {
        const errors = handleErrors(result)
        yield put(updateContactFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
