import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AgreementAddEquipment from './AgreementAddEquipment'
import {
  fetchAgreementRequest,
  fetchContractPermissionsRequest,
} from '../../../../../actions/serviceDeskContractsActions'
import {
  clearNavigationDrawer,
  setNavigationDrawer,
} from '../../../../../actions/appActions'

const mapStateToProps = state => ({
  isFetching: state.serviceDeskContracts.agreement.isFetching,
  permissions: state.serviceDeskContracts.permissions.permissions,
  isFetchingPermissions: state.serviceDeskContracts.permissions.isFetching,
  permissionsError: state.serviceDeskContracts.permissions.error,
  error: state.serviceDeskContracts.agreement.error,
  agreement: state.serviceDeskContracts.agreement.agreement,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractPermissionsRequest,
      fetchAgreementRequest,
      setNavigationDrawer,
      clearNavigationDrawer,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementAddEquipment),
)
