import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import SelectLimitedAuto from '../../../widgets/SelectLimitedAuto'
import { URL } from '../../../middlewares/api'
import React from 'react'
import i18next from '../../../i18n/i18n'

function IndustriesSelector({
  onChange,
  idValue,
  value,
  noEmpty = false,
  disabled = false,
}) {
  const onChangeHandler = newValue => {
    onChange(idValue, newValue)
  }
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={idValue}>
        {i18next.t('equipment_web_atoir:industry')}
      </InputLabel>
      <SelectLimitedAuto
        disabled={disabled}
        {...(() => (noEmpty ? {} : { firstElement: { id: '', title: '' } }))()}
        id={idValue}
        value={value}
        sourceUrl={`${URL}/bo/equipments/industries`}
        onChange={onChangeHandler}
        requestResultTransformerToArray={r => r.industries}
        itemTransformer={l => ({
          id: l.id,
          title: i18next.t(`equipment_web_atoir:${l.name}`),
        })}
      />
    </FormControl>
  )
}

export default IndustriesSelector
