import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  organizationLocationStringify,
  organizationAddressStringifyFull,
} from '../../../../../helpers/parserHelper'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../../i18n/i18n'

// components
import Loader from '../../../../Loader'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

class ProjectInfo extends Component {
  componentDidMount() {
    const {
      props: { fetchLocations, client, inventoryProject },
    } = this

    if (!client) fetchLocations(inventoryProject.Performer.id)
  }

  getDividerGrid = props => (
    <Grid xs={12} className={props.dividerMargin}>
      <Divider />
    </Grid>
  )

  getAddress = structure =>
    organizationAddressStringifyFull(structure.OrganizationLocation)

  render = () => {
    const {
      props: {
        classes,
        inventoryProject,
        isFetchingProject,
        client,
        locations,
        isFetchingLocations,
      },
      getDividerGrid,
      getAddress,
    } = this
    if (isFetchingProject || !inventoryProject) {
      return <Loader />
    }
    const {
      ipl: projectLocations,
      Client: clientOrganization,
      Performer: performerOrganization,
    } = inventoryProject

    let organization

    if (client === true) {
      organization = clientOrganization
    } else {
      organization = performerOrganization
    }

    return (
      <Grid container spacing={1} alignItems="flex-start">
        <Grid xs={5}>
          <Typography variant="body1" align="left">
            {`${i18next.t('inventory_web_atoir:inventoryType')}:`}
          </Typography>
        </Grid>
        <Grid xs={7}>
          <Typography align="left" variant="body2">
            {inventoryProject.inventory_type.toUpperCase()}
          </Typography>
        </Grid>
        {getDividerGrid(classes)}
        <Grid xs={5}>
          <Typography variant="body1" align="left">
            {`${i18next.t('inventory_web_atoir:organizationPerformer')}:`}
          </Typography>
        </Grid>
        <Grid xs={7}>
          <Typography align="left" variant="body2">
            <Typography>{organization.short_name}</Typography>
            <Typography color="textSecondary">
              {!client ? (
                isFetchingLocations ? (
                  <Loader />
                ) : (
                  !isEmpty(locations) &&
                  organizationLocationStringify(locations[0])
                )
              ) : (
                projectLocations.map(pl => {
                  const adderss = getAddress(pl)
                  return (
                    <Fragment>
                      <Grid xs={8}>{adderss}</Grid>
                    </Fragment>
                  )
                })
              )}
            </Typography>
          </Typography>
        </Grid>
        {getDividerGrid(classes)}
        <Grid xs={5}>
          <Typography variant="body1" align="left">
            {i18next.t('inventory_web_atoir:inventoryDesiredPeriod')}:
          </Typography>
        </Grid>
        <Grid xs={7}>
          <Typography align="left" variant="body2">
            {inventoryProject.desired_period_begin
              ? `${new Date(
                  inventoryProject.desired_period_begin,
                ).toLocaleDateString('ru')} `
              : '_ '}
            -
            {inventoryProject.desired_period_end
              ? ` ${new Date(
                  inventoryProject.desired_period_end,
                ).toLocaleDateString('ru')}`
              : ' _'}
          </Typography>
        </Grid>
        {getDividerGrid(classes)}
      </Grid>
    )
  }
}

const styles = theme => ({
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

ProjectInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetchingProject: PropTypes.bool.isRequired,
  inventoryProject: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProjectInfo)
