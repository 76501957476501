import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { round10 } from 'round10'

// material UI
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

// i18n
import i18next from '../../../../i18n/i18n'

const styles = theme => ({
  label: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  text: {
    fontWeight: 'normal',
    fontSize: 12,
  },
  testBack: {
    backgroundColor: 'blue',
  },
  root: {
    marginBottom: theme.spacing(2),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
  },
  sectionAction: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
  textFieldMulty: {
    width: '500px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  titleCell: {
    color: theme.palette.apply.extraLight,
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: 12,
    border: 0,
  },
  emptyMargin: {
    margin: theme.spacing(1),
  },
  marginVertical: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

const curFormat = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

class SuborderWorkCompletionReport extends Component {
  renderTableBody = items =>
    items.map(item => (
      <TableRow key={item.id}>
        <TableCell padding="none">
          {i18next.t(`service_desk_web_atoir:${item.item_type}ItemType`)}
        </TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.item_category}</TableCell>
        <TableCell align="center">
          {i18next.t(`service_desk_web_atoir:${item.units}UnitsType`)}
        </TableCell>
        <TableCell align="right">{item.count}</TableCell>
        <TableCell align="right">
          {curFormat.format(
            round10(+item.price_purchase * (1 + item.percent / 100), -2),
          )}
        </TableCell>
        <TableCell align="right">
          {curFormat.format(
            round10(
              this.getSum(item.count, item.price_purchase) *
                (1 + item.percent / 100),
              -2,
            ),
          )}
        </TableCell>
      </TableRow>
    ))

  getSum = (amt, price) => round10(Number(amt) * Number(price), -2)

  getOverhead = (data, overhead) => {
    let total = 0
    data.forEach(row => {
      if (
        Number.isFinite(Number(row.price_purchase)) &&
        Number.isFinite(Number(row.count)) &&
        Number.isFinite(Number(row.percent))
      ) {
        total +=
          Number(row.price_purchase) *
          Number(row.count) *
          ((row.percent || 0) / 100 + 1) *
          (Number(overhead) / 100)
      }
    })
    return total
  }

  getPurchase = data => {
    let total = 0
    data.forEach(row => {
      if (
        Number.isFinite(Number(row.price_purchase)) &&
        Number.isFinite(Number(row.count)) &&
        Number.isFinite(Number(row.percent))
      ) {
        total += round10(
          round10(
            Number(row.price_purchase) * ((Number(row.percent) || 0) / 100 + 1),
            -2,
          ) * Number(row.count),
          -2,
        )
      }
    })
    return total
  }

  getTotalPurchase = (itemsDiagnostic, itemsNeedRepair, overhead = 0) => {
    let total = 0
    if (itemsDiagnostic.length > 0) {
      total += this.getPurchase(itemsDiagnostic)
    }
    if (itemsNeedRepair.length > 0) {
      total += this.getPurchase(itemsNeedRepair)
    }
    total += this.getOverhead(
      [...itemsDiagnostic, ...itemsNeedRepair],
      overhead,
    )
    return total
  }

  getNdsPurchase = (itemsDiagnostic, itemsNeedRepair, nds, overhead = 0) =>
    round10(
      (this.getTotalPurchase(itemsDiagnostic, itemsNeedRepair) +
        this.getOverhead([...itemsDiagnostic, ...itemsNeedRepair], overhead)) *
        (nds / 100),
      -2,
    )

  getTotalWithNds = (itemsDiagnostic, itemsNeedRepair, nds, overhead = 0) =>
    round10(
      this.getTotalPurchase(itemsDiagnostic, itemsNeedRepair, overhead) *
        (1 + nds / 100),
      -2,
    )

  render() {
    const {
      props: {
        classes,
        nds,
        orderResult,
        overhead,
        performer,
        itemsDiagnostic,
        itemsNeedRepair,
      },
      renderTableBody,
    } = this

    return (
      <Fragment>
        <Grid container spacing={1} className={classes.marginVertical}>
          <Grid item>
            <Typography className={classes.label}>
              {`${i18next.t('service_desk_web_atoir:performer')}:`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{performer}</Typography>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.titleCell}>
                {`${i18next.t('service_desk_web_atoir:type')}*`}
              </TableCell>
              <TableCell className={classes.titleCell}>
                {`${i18next.t(
                  'service_desk_web_atoir:tableCellName',
                )}*, ${i18next.t('service_desk_web_atoir:catalogueNumber')}`}
              </TableCell>
              <TableCell className={classes.titleCell}>
                {`${i18next.t('service_desk_web_atoir:itemCategory')}`}
              </TableCell>
              <TableCell className={classes.titleCell}>
                {`${i18next.t('service_desk_web_atoir:unitsOfMeasurement')}*`}
              </TableCell>
              <TableCell className={classes.titleCell} align="right">
                {`${i18next.t('service_desk_web_atoir:amount')}*`}
              </TableCell>
              <TableCell className={classes.titleCell} align="right">
                {`${i18next.t('service_desk_web_atoir:price')} ${i18next.t(
                  'service_desk_web_atoir:roubles',
                )}`}
              </TableCell>
              <TableCell className={classes.titleCell} align="right">
                {`${i18next.t('service_desk_web_atoir:tableOrderAmount')}`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={6}
                className={classes.tableCell}
                align="center"
              >
                <Typography variant="caption" className={classes.titleCell}>
                  {`${i18next.t('service_desk_web_atoir:workPerformed')}`}
                </Typography>
              </TableCell>
            </TableRow>
            {itemsDiagnostic.length !== 0 ? (
              renderTableBody(itemsDiagnostic)
            ) : (
              <Typography className={classes.emptyMargin} variant="body2">
                {i18next.t('service_desk_web_atoir:noDataToDisplay')}
              </Typography>
            )}
            <TableRow>
              <TableCell
                colSpan={6}
                className={classes.tableCell}
                align="center"
              >
                <Typography variant="caption" className={classes.titleCell}>
                  {`${i18next.t('service_desk_web_atoir:workRequired')}`}
                </Typography>
              </TableCell>
            </TableRow>
            {orderResult !== 'complete' &&
              (itemsNeedRepair.length !== 0 ? (
                renderTableBody(itemsNeedRepair)
              ) : (
                <Typography className={classes.emptyMargin} variant="body2">
                  {i18next.t('service_desk_web_atoir:noDataToDisplay')}
                </Typography>
              ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={4} className={classes.tableCell} />
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {i18next.t('service_desk_web_atoir:overheadSum')}
            </TableCell>
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {curFormat.format(
                this.getOverhead(
                  [...itemsDiagnostic, ...itemsNeedRepair],
                  overhead,
                ),
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} rowSpan={3} className={classes.tableCell} />
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {`${i18next.t('service_desk_web_atoir:totalSum')}`}
            </TableCell>
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {curFormat.format(
                this.getTotalPurchase(
                  itemsDiagnostic,
                  itemsNeedRepair,
                  overhead,
                ),
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {`${i18next.t('service_desk_web_atoir:goodsAndServicesTax')}`}
            </TableCell>
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {curFormat.format(
                this.getNdsPurchase(
                  itemsDiagnostic,
                  itemsNeedRepair,
                  nds,
                  overhead,
                ),
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {`${i18next.t(
                'service_desk_web_atoir:totalWithGoodsAndServicesTax',
              )}`}
            </TableCell>
            <TableCell colSpan={1} align="right" className={classes.tableCell}>
              {curFormat.format(
                this.getTotalWithNds(
                  itemsDiagnostic,
                  itemsNeedRepair,
                  nds,
                  overhead,
                ),
              )}
            </TableCell>
          </TableRow>
        </Table>
      </Fragment>
    )
  }
}

SuborderWorkCompletionReport.defaultProps = {
  nds: 0,
  performer: '',
  itemsDiagnostic: [],
  itemsNeedRepair: [],
}

SuborderWorkCompletionReport.propTypes = {
  classes: PropTypes.object.isRequired,
  nds: PropTypes.number,
  orderResult: PropTypes.string.isRequired,
  performer: PropTypes.string,
  itemsDiagnostic: PropTypes.array,
  itemsNeedRepair: PropTypes.array,
}

export default withStyles(styles)(SuborderWorkCompletionReport)
