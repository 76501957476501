import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import { withStyles } from '@material-ui/core/styles/index'
import i18next from '../../../../../i18n/i18n'
import PropTypes from 'prop-types'

// ---material---
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

class FormAddEngineers extends Component {
  state = {
    filteredItems: [],
    filteredItemsValue: '',
    filteredSelectedUsers: [],
    filteredSelectedUsersValue: '',
  }

  componentWillMount() {
    const {
      props: { selectedItems, items },
    } = this

    this.setState({
      filteredItems: items,
      filteredItemsValue: '',
      filteredSelectedUsers: selectedItems,
      filteredSelectedUsersValue: '',
    })
  }

  handleToggle = value => () => {
    const {
      state: { filteredSelectedUsersValue },
      props: { handleSelectedItems, selectedItems },
    } = this
    let newSelected = []

    if (value !== null) {
      const currentIndex = selectedItems.findIndex(
        _user => _user.id === value.id,
      )
      newSelected = [...selectedItems]

      if (currentIndex === -1) {
        newSelected.push(value)
      } else {
        newSelected.splice(currentIndex, 1)
      }
    }

    const filteredSelectedUsers = newSelected.filter(
      _user =>
        _user.last_name
          .toLowerCase()
          .indexOf(filteredSelectedUsersValue.toLowerCase()) !== -1 ||
        _user.first_name
          .toLowerCase()
          .indexOf(filteredSelectedUsersValue.toLowerCase()) !== -1,
    )

    this.setState(
      {
        filteredSelectedUsers,
      },
      () => {
        handleSelectedItems(newSelected)
      },
    )
  }

  handleSearchItems = field => event => {
    const {
      props: { selectedItems, items },
    } = this

    const array = field === 'filteredItems' ? items : selectedItems

    const findStr = event.target.value.toLowerCase()
    const filteredArr = isEmpty(array)
      ? []
      : array.filter(
          _user =>
            _user.last_name.toLowerCase().indexOf(findStr) !== -1 ||
            _user.first_name.toLowerCase().indexOf(findStr) !== -1,
        )
    this.setState({
      [field]: filteredArr,
      [`${field}Value`]: event.target.value,
    })
  }

  render() {
    const {
      handleToggle,
      handleSearchItems,
      props: { classes, selectedItems },
      state: {
        filteredItems,
        filteredSelectedUsers,
        filteredItemsValue,
        filteredSelectedUsersValue,
      },
    } = this

    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle" className={classes.title}>
              {i18next.t('inventory_web_atoir:organizationEngineersList')}
            </Typography>
            <TextField
              fullWidth
              type="text"
              className={classes.textField}
              onChange={handleSearchItems('filteredItems')}
              value={filteredItemsValue}
            />
            <List className={classes.root}>
              {_.sortBy(filteredItems, 'last_name')
                .filter(u => u.is_plain_user)
                .map(_item => {
                  const middleName =
                    _item.middle_name === 'none' ? '' : _item.middle_name
                  return (
                    <ListItem
                      key={_item.id}
                      dense
                      button
                      className={classes.listItem}
                    >
                      <ListItemText
                        primary={`${_item.last_name} ${_item.first_name} ${middleName}`}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          onChange={handleToggle(_item)}
                          checked={
                            !isEmpty(
                              selectedItems.find(
                                _selectedUser => _item.id === _selectedUser.id,
                              ),
                            )
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle" className={classes.title}>
              {i18next.t('inventory_web_atoir:inventoryProjectEngineersList')}
            </Typography>
            <TextField
              fullWidth
              type="text"
              onChange={handleSearchItems('filteredSelectedUsers')}
              className={classes.textField}
              value={filteredSelectedUsersValue}
            />
            <List className={classes.root}>
              {_.sortBy(filteredSelectedUsers, 'last_name').map(_item => {
                const middleName =
                  _item.middle_name === 'none' ? '' : _item.middle_name
                return (
                  <ListItem
                    key={_item.id}
                    dense
                    button
                    className={classes.listItem}
                  >
                    <ListItemText
                      primary={`${_item.last_name} ${_item.first_name} ${middleName}`}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

FormAddEngineers.propTypes = {
  handleSelectedItems: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.func.isRequired,
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  rightBtn: {
    textAlign: 'right',
  },
})

export default withStyles(styles)(FormAddEngineers)
