import * as constants from '../constants/actionTypes/emailTemplates'

const initialState = {
  getTemplates: {
    isFetching: false,
    templates: [],
    error: null,
  },
  getTemplate: {
    isFetching: false,
    template: [],
    error: null,
  },
  createTemplate: {
    isFetching: false,
    error: null,
  },
  editTemplate: {
    isFetching: false,
    error: null,
  },
  deleteTemplate: {
    isFetching: false,
    error: null,
  },
}

const emailTemplates = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        getTemplates: {
          ...state.getTemplates,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        getTemplates: {
          ...state.getTemplates,
          isFetching: false,
          templates: action.payload,
        },
      }

    case constants.GET_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        getTemplates: {
          ...state.getTemplates,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isFetching: false,
          template: action.payload,
        },
      }

    case constants.GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CREATE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          isFetching: false,
        },
      }

    case constants.CREATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        editTemplate: {
          ...state.editTemplate,
          isFetching: true,
          error: null,
        },
      }

    case constants.EDIT_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        editTemplate: {
          ...state.editTemplate,
          isFetching: false,
        },
      }

    case constants.EDIT_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        editTemplate: {
          ...state.editTemplate,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        deleteTemplate: {
          ...state.deleteTemplate,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteTemplate: {
          ...state.deleteTemplate,
          isFetching: false,
        },
      }

    case constants.DELETE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteTemplate: {
          ...state.deleteTemplate,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default emailTemplates
