import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AgreementHistory from './AgreementHistory'
import {
  fetchAgreementLogsCountRequest,
  fetchAgreementLogsRequest,
  fetchAgreementRequest,
  fetchContractPermissionsRequest,
} from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  logs: state.serviceDeskContracts.agreementLogs.logs,
  isFetching: state.serviceDeskContracts.agreementLogs.isFetching,
  isFetchingAgreement: state.serviceDeskContracts.agreement.isFetching,
  agreement: state.serviceDeskContracts.agreement.agreement,
  error: state.serviceDeskContracts.agreementLogs.error,
  count: state.serviceDeskContracts.agreementLogsCount.count,
  isFetchingCount: state.serviceDeskContracts.agreementLogsCount.isFetching,
  permissions: state.serviceDeskContracts.permissions.permissions,
  isFetchingPermissions: state.serviceDeskContracts.permissions.isFetching,
  permissionsError: state.serviceDeskContracts.permissions.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractPermissionsRequest,
      fetchAgreementRequest,
      fetchAgreementLogsRequest,
      fetchAgreementLogsCountRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementHistory),
)
