import React, { Fragment, useMemo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import i18n from '../../../i18n/i18n'
import ListItem from '@material-ui/core/ListItem'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import ApplyButton from '../../../widgets/Buttons/ApplyButton'
import Grid from '@material-ui/core/Grid'
import { URL } from '../../../middlewares/api'
import AutocompleteLimitedAuto from '../../../widgets/AutocompleteLimitedAuto'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  qwe: {
    background: 'red',
    padding: theme.spacing(2),
  },
  radio: {
    '&$checked': {
      color: theme.palette.blueSecondary.main,
    },
  },
  filterWrapper: {
    padding: 20,
  },
  checked: {
    color: theme.palette.blueSecondary.main,
  },
  groupContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  autocomplete: {
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 24,
  },
  dividerMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
})

const TrustedOrganizationsFilters = ({
  side,
  classes,
  status,
  changeSide,
  applyFilters,
  changeOrganization,
}) => {
  const onChangeSide = e => {
    e.persist()
    changeSide(e.target.value)
  }
  return useMemo(
    () => (
      <Fragment>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {i18n.t('trusted_organizations_web_atoir:additionalFilters')}
            </ListSubheader>
          }
        >
          <ListItem className={classes.listItem}>
            <FormControl>
              <RadioGroup
                aria-label="contract-side"
                name="contract-side"
                value={side}
                onChange={onChangeSide}
              >
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t('shared_web_atoir:All')}
                />
                <FormControlLabel
                  value="client"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t(
                    'trusted_organizations_web_atoir:client_side_radio_filter',
                  )}
                />
                <FormControlLabel
                  value="performer"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t(
                    'trusted_organizations_web_atoir:performer_side_radio_filter',
                  )}
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <Divider className={classes.dividerMargin} />
          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item xs={12} className={classes.autocomplete}>
              <AutocompleteLimitedAuto
                InputProps={{ required: true }}
                placeholder={i18n.t(
                  'trusted_organizations_web_atoir:performerOrganization',
                )}
                sourceUrl={`${URL}/trust/availableOrganizations`}
                query={{
                  limit: 10,
                  side: 'performer',
                  status: status !== 'all' ? status : undefined,
                }}
                getItemDisplayName={o => o.short_name}
                getItemValue={o => o.id}
                onChange={e => {
                  changeOrganization({
                    id: e ? e.id : e,
                    side: 'client',
                  })
                }}
                transformInputToQuery={v => ({ query: v })}
                requestResultTransformerToArray={res => res.organizations}
              />
            </Grid>
            <Grid item xs={12} className={classes.autocomplete}>
              <AutocompleteLimitedAuto
                InputProps={{ required: true }}
                placeholder={i18n.t(
                  'trusted_organizations_web_atoir:clientOrganization',
                )}
                sourceUrl={`${URL}/trust/availableOrganizations`}
                query={{
                  limit: 10,
                  side: 'client',
                  status: status !== 'all' ? status : undefined,
                }}
                getItemDisplayName={o => o.short_name}
                getItemValue={o => o.id}
                onChange={e => {
                  changeOrganization({
                    id: e ? e.id : e,
                    side: 'performer',
                  })
                }}
                transformInputToQuery={v => ({ query: v })}
                requestResultTransformerToArray={res => res.organizations}
              />
            </Grid>
            <Grid item xs={12} container justify="flex-end">
              <ApplyButton onClick={applyFilters}>
                {i18n.t('service_desk_web_atoir:applyAction')}
              </ApplyButton>
            </Grid>
          </Grid>
        </List>
      </Fragment>
    ),
    [side],
  )
}

export default withStyles(styles)(TrustedOrganizationsFilters)
