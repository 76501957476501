import * as constants from '../constants/actionTypes/recurringCalls'
import { actionCreator as externalActionCreator } from './actionCreator'

export const getOrganizationRecurringCallsRequest = externalActionCreator(
  constants.GET_ORGANIZATION_RECURRING_CALLS_REQUEST,
)
export const getOrganizationRecurringCallsSuccess = externalActionCreator(
  constants.GET_ORGANIZATION_RECURRING_CALLS_SUCCESS,
)
export const getOrganizationRecurringCallsFailure = externalActionCreator(
  constants.GET_ORGANIZATION_RECURRING_CALLS_FAILURE,
)

export const getOrganizationRecurringCallsCountRequest = externalActionCreator(
  constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_REQUEST,
)
export const getOrganizationRecurringCallsCountSuccess = externalActionCreator(
  constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_SUCCESS,
)
export const getOrganizationRecurringCallsCountFailure = externalActionCreator(
  constants.GET_ORGANIZATION_RECURRING_CALLS_COUNT_FAILURE,
)
