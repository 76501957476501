import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchContractsCountRequest,
  fetchContractsRequest,
} from '../../../actions/serviceDeskContractsActions'
import ContractsList from './ContractsList'
import {
  fetchHasManagerOrHigherOrganizations,
  fetchManagerOrHigherOrganizations,
} from '../../../actions/organizationsActions'
import { clearFilterDrawer, setFilterDrawer } from '../../../actions/appActions'

const mapStateToProps = state => ({
  createdCount: state.serviceDeskContracts.createdCount.count,
  inperformerCount: state.serviceDeskContracts.inperformerCount.count,
  inworkCount: state.serviceDeskContracts.inworkCount.count,
  hasManagerOrHigherOrganizations:
    state.organizations.hasManagerOrHigherOrganization.has,
  closedCount: state.serviceDeskContracts.closedCount.count,
  canceledCount: state.serviceDeskContracts.canceledCount.count,
  createdFilteredCount: state.serviceDeskContracts.createdFilteredCount.count,
  inperformerFilteredCount:
    state.serviceDeskContracts.inperformerFilteredCount.count,
  inworkFilteredCount: state.serviceDeskContracts.inworkFilteredCount.count,
  closedFilteredCount: state.serviceDeskContracts.closedFilteredCount.count,
  canceledFilteredCount: state.serviceDeskContracts.canceledFilteredCount.count,
  contracts: state.serviceDeskContracts.list.contracts,
  isFetching: state.serviceDeskContracts.list.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractsRequest,
      fetchHasManagerOrHigherOrganizations,
      fetchContractsCountRequest,
      fetchManagerOrHigherOrganizations,
      setFilterDrawer,
      clearFilterDrawer,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractsList),
)
