import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Input from '@material-ui/core/Input'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'
import AttachFileIcon from '@material-ui/icons/AttachFile'

// mpdules

import TextButton from '../../../../../../../widgets/Buttons/TextButton'
import ContainedButton from '../../../../../../../widgets/Buttons/ContainedButton'

// i18
import i18next from '../../../../../../../i18n/i18n'

const AddDocDialog = props => {
  const {
    onClose,
    isOpen,
    title,
    fileText,
    onChange,
    classes,
    handleAdd,
    file,
  } = props
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <Input
            type="file"
            className={classes.input}
            id="call-doc-attach-input-component"
            multiple
            onChange={onChange}
          />
          <InputLabel
            className={classes.inputLabel}
            htmlFor="call-doc-attach-input-component"
          >
            <AttachFileIcon />
            {i18next.t('service_desk_web_atoir:chooseFile')}
          </InputLabel>
          <div className={classes.path}>{fileText}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={onClose} classes={{ root: classes.button }}>
          {i18next.t('shared_web_atoir:cancel')}
        </TextButton>
        <ContainedButton
          id="addDocDialogSubmitButton"
          onClick={handleAdd}
          disabled={!file}
          classes={{ root: classes.button }}
        >
          {i18next.t('shared_web_atoir:add')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

AddDocDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.any.isRequired,
  handleAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  fileText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    display: 'none',
  },
  inputLabel: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  path: {
    width: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  button: {
    margin: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
})

export default withStyles(styles)(AddDocDialog)
