import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  REPAIR_PASSWORD_REQUEST,
  CONFIRM_REPAIR_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_REQUEST,
} from '../constants/actionTypes'
import {
  repairPasswordRequest,
  confirmRepairPasswordRequest,
  setNewPasswordRequest,
} from './api'
import {
  repairPasswordSuccess,
  repairPasswordFailure,
  confirmRepairPasswordSuccess,
  confirmRepairPasswordFailure,
  setNewPasswordSuccess,
  setNewPasswordFailure,
} from '../actions/repairPasswordActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { setTokens } from '../helpers/tokensHelper'
import { handleErrors, checkCodes } from '../helpers/errorsHelper'

export function* watchRepairPasswordSaga() {
  yield takeLatest(REPAIR_PASSWORD_REQUEST, repairPassword)
}

export function* watchConfirmRepairPasswordSaga() {
  yield takeLatest(CONFIRM_REPAIR_PASSWORD_REQUEST, confirmRepairPassword)
}

export function* watchSetNewPasswordSaga() {
  yield takeLatest(SET_NEW_PASSWORD_REQUEST, setNewPassword)
}

function* repairPassword(action) {
  try {
    const result = yield call(repairPasswordRequest(action.email))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(repairPasswordSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.email) || !isEmpty(errors.password)) {
          const shouldResend = checkCodes(result, 'shouldResendLink')
          yield put(repairPasswordFailure(errors, shouldResend))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* confirmRepairPassword() {
  try {
    const confirmRepairPasswordHash = sessionStorage.getItem(
      'confirmRepairPasswordHash',
    )
    const result = yield call(
      confirmRepairPasswordRequest(confirmRepairPasswordHash),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        sessionStorage.setItem('setNewPasswordHash', result.data.hash)
        yield put(confirmRepairPasswordSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(confirmRepairPasswordFailure(errors, result.code))
        if (result.code !== 95) {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setNewPassword(action) {
  try {
    const setNewPasswordHash = sessionStorage.getItem('setNewPasswordHash')
    const result = yield call(
      setNewPasswordRequest(setNewPasswordHash, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        setTokens(result)
        sessionStorage.removeItem('setNewPasswordHash')
        yield put(setNewPasswordSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.password) || !isEmpty(errors.passwordConfirm)) {
          yield put(setNewPasswordFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
