import i18next from '../i18n/i18n'

export const homeConcatHelper = data =>
  `${data.house}${data.block ? `${data.block_type}${data.block}` : ''}`

export const inventoryLocationStringify = l =>
  organizationLocationStringifyFull(l)

export const inventoryLocationStringifyDefault = l =>
  inventoryLocationStringify(l)

export const organizationLocationStringify = l =>
  `${l ? (l.Street ? l.Street.name_full : '') : ''}, ${l.home}`

export const organizationLocationStringifyFull = l => {
  const home = l.location_repair_home || l.home
  const floor = l.location_repair_floor || l.floor
  const office = l.location_repair_office || l.office
  return _.compact([
    _.compact([l.name_full || l.Street.name_full]).join(' '),
    home,
    floor
      ? `${floor} ${i18next.t('equipment_web_atoir:floor').toLowerCase()}`
      : null,
    office
      ? `${i18next.t('shared_web_atoir:office').toLowerCase()} ${office}`
      : null,
  ]).join(', ')
}

export const organizationAddressStringify = l =>
  _.compact([
    l.streetFull,
    l.home,
    l.floor
      ? `${l.floor} ${i18next.t('equipment_web_atoir:floor').toLowerCase()}`
      : null,
    l.office
      ? `${i18next.t('shared_web_atoir:office').toLowerCase()} ${l.office}`
      : null,
  ]).join(', ')

export const organizationAddressStringifyFull = l =>
  _.compact([
    _.compact([l.zip, l.streetFull]).join(' '),
    l.home,
    l.floor
      ? `${l.floor} ${i18next.t('equipment_web_atoir:floor').toLowerCase()}`
      : null,
    l.office
      ? `${i18next.t('shared_web_atoir:office').toLowerCase()} ${l.office}`
      : null,
  ]).join(', ')
