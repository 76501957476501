import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import i18next from '../../../../../i18n/i18n'
import { URL } from '../../../../../middlewares/api'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import { getUserFullName } from '../../../../../helpers/userHelper'
import FormControlSelectWithSearch from '../../../../../widgets/Controlls/FormControlSelectWithSearch'
import SelectLimitedFormControl from '../../../../../widgets/SelectLimitedFormControl'
import Grid from '@material-ui/core/Grid'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divDate: {
    display: 'flex',
  },
  textField: {
    width: '100%',
  },
  textMonth: {
    marginRight: theme.spacing(2),
    width: 80,
  },
  textYear: {
    width: 130,
  },
  divHeader: {
    marginBottom: theme.spacing(2),
  },
  divAlignBottom: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  textContractNumber: {
    width: '100%',
  },
  divLoaderHover: {
    position: 'relative',
  },
  divLoader: {
    position: 'absolute',
    right: 20,
    top: 22,
  },
  divButtonRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bigText: {
    fontSize: 34,
    marginBottom: theme.spacing(1),
  },
  captionText: {
    fontSize: 14,
  },
  greyText: {
    color: lighten(theme.palette.secondary.dark, 0.2),
  },
})

class EditContractBase extends Component {
  constructor(props) {
    super(props)
    const monthList = []
    for (let i = 1; i < 13; i++) {
      if (i < 10) monthList.push({ id: `${i}`, title: `0${i}` })
      else monthList.push({ id: `${i}`, title: `${i}` })
    }
    const currentYear = new Date(1970, 0, 1).getFullYear()
    const fromYear = new Date(currentYear, 0)
    const toYear = new Date(currentYear + 90, 11)
    const yearList = []
    for (let i = fromYear.getFullYear(); i <= toYear.getFullYear(); i += 1) {
      yearList.push({ id: `${i}`, title: `${i}` })
    }
    const yearListEnd = []
    for (let i = new Date().getFullYear(); i <= toYear.getFullYear(); i += 1) {
      yearListEnd.push({ id: `${i}`, title: `${i}` })
    }

    this.state = {
      monthList,
      monthStartSelected: props.contract.month_begin,
      yearStartSelected: props.contract.year_begin,
      monthEndSelected: props.contract.month_end,
      yearEndSelected: props.contract.year_end,
      numContract: props.contract.num_contract,
      organizationSelected: props.contract.cl_organization.id,
      responsibleSelected: props.contract.cl_responsible.id,
      comment: props.contract.contract_note,
      yearStartList: yearList,
      yearEndList: yearListEnd,
    }
  }

  componentDidMount() {
    this.calcIntervalMonths()
  }

  handleChange = field => event => {
    const value = event.target ? event.target.value : event
    if (field === 'organizationSelected') {
      this.setState({
        responsibleSelected: null,
      })
    }
    if (field === 'yearStartSelected') {
      const yearList = []
      const currentYear = new Date(1970, 0, 1).getFullYear()
      const toYear = new Date(currentYear + 90, 11)
      const currYear = new Date().getFullYear()
      const yearFrom = Number(value) <= currYear ? currYear : Number(value)

      for (let i = yearFrom; i <= toYear.getFullYear(); i += 1) {
        yearList.push({ id: `${i}`, title: `${i}` })
      }
      this.setState({
        yearEndList: yearList,
        monthStartSelectedError: false,
        monthEndSelectedError: false,
      })
    }
    if (field === 'yearEndSelected') {
      const yearList = []
      const currentYear = new Date(1960, 0, 1).getFullYear()
      const fromYear = new Date(currentYear, 0)
      for (let i = fromYear.getFullYear(); i <= Number(value); i += 1) {
        yearList.push({ id: `${i}`, title: `${i}` })
      }
      this.setState({ yearStartList: yearList })
    }
    this.setState(
      {
        [field]: value,
        [`${field}Error`]: false,
      },
      this.calcIntervalMonths,
    )
  }

  calcIntervalMonths = () => {
    const {
      monthStartSelected,
      monthEndSelected,
      yearStartSelected,
      yearEndSelected,
    } = this.state
    if (
      monthStartSelected &&
      monthEndSelected &&
      yearStartSelected &&
      yearEndSelected
    ) {
      const intervalMonths = moment([
        yearEndSelected,
        monthEndSelected - 1,
      ]).diff(moment([yearStartSelected, monthStartSelected - 1]), 'months')
      this.setState({ intervalMonths: intervalMonths + 1 })
    }
  }

  validateForm = () => {
    const {
      state: {
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        organizationSelected,
        responsibleSelected,
      },
    } = this
    let formValid = true
    const errors = {}
    _.forIn(
      {
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        organizationSelected,
        responsibleSelected,
      },
      (value, key) => {
        if (!value || value === '') {
          errors[`${key}Error`] = true
          formValid = false
        }
      },
    )
    const {
      monthStartSelectedError,
      monthEndSelectedError,
      yearStartSelectedError,
      yearEndSelectedError,
    } = errors
    let dateError = false
    if (
      !monthStartSelectedError &&
      !monthEndSelectedError &&
      !yearStartSelectedError &&
      !yearEndSelectedError
    ) {
      if (Number(yearStartSelected) > Number(yearEndSelected)) {
        dateError = true
        errors.yearStartSelectedError = true
        errors.yearEndSelectedError = true
        formValid = false
      }
      if (
        !dateError &&
        Number(yearStartSelected) === Number(yearEndSelected) &&
        Number(monthStartSelected) > Number(monthEndSelected)
      ) {
        errors.monthStartSelectedError = true
        errors.monthEndSelectedError = true
        formValid = false
      }
    }
    if (!formValid) {
      this.setState({ ...errors })
      return false
    }
    return true
  }

  submit = () => {
    const {
      props: { onSubmit },
      state: {
        organizationSelected,
        responsibleSelected,
        yearStartSelected,
        monthStartSelected,
        yearEndSelected,
        monthEndSelected,
        numContract,
        comment,
      },
      validateForm,
    } = this
    if (validateForm()) {
      onSubmit(
        _.omitBy(
          {
            client_organization_id: organizationSelected,
            client_responsible_id: responsibleSelected,
            year_begin: yearStartSelected,
            month_begin: monthStartSelected,
            year_end: yearEndSelected,
            month_end: monthEndSelected,
            num_contract: numContract,
            contract_note: comment,
          },
          v => v === null,
        ),
      )
    }
  }

  render() {
    const {
      props: { onClose, classes, open, noResponsible },
      state: {
        monthList,
        monthStartSelected,
        yearStartSelected,
        monthEndSelected,
        yearEndSelected,
        intervalMonths,
        numContract,
        comment,
        organizationSelected,
        responsibleSelected,
        monthStartSelectedError,
        yearStartSelectedError,
        monthEndSelectedError,
        yearEndSelectedError,
        numContractError,
        yearStartList,
        yearEndList,
      },
      submit,
      handleChange,
    } = this
    return (
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogContent>
          <div className={classes.root}>
            <div className={classes.divHeader}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className={classes.divAlignBottom}>
                    <Typography className={classes.bigText}>
                      {i18next.t('service_desk_web_atoir:editingContract')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={numContractError}
                    className={classes.textContractNumber}
                    label={i18next.t('service_desk_web_atoir:contractNumber')}
                    value={numContract}
                    onChange={handleChange('numContract')}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="caption" className={classes.captionText}>
                  {i18next.t('service_desk_web_atoir:contract_begin_date')}
                </Typography>
                <div className={classes.divDate}>
                  <div className={classes.textMonth}>
                    <FormControlSelectWithSearch
                      error={monthStartSelectedError}
                      noEmpty
                      label={`${i18next.t(
                        'service_desk_web_atoir:captionMonth',
                      )}*`}
                      showLabel
                      value={monthStartSelected || ''}
                      values={monthList}
                      onChange={handleChange('monthStartSelected')}
                    />
                  </div>
                  <div className={classes.textYear}>
                    <FormControlSelectWithSearch
                      error={yearStartSelectedError}
                      noEmpty
                      label={`${i18next.t(
                        'service_desk_web_atoir:captionYear',
                      )}*`}
                      showLabel
                      value={yearStartSelected || ''}
                      values={yearStartList}
                      onChange={handleChange('yearStartSelected')}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="caption" className={classes.captionText}>
                  {i18next.t('service_desk_web_atoir:contract_end_date')}
                </Typography>
                <div className={classes.divDate}>
                  <div className={classes.textMonth}>
                    <FormControlSelectWithSearch
                      error={monthEndSelectedError}
                      noEmpty
                      label={`${i18next.t(
                        'service_desk_web_atoir:captionMonth',
                      )}*`}
                      showLabel
                      value={monthEndSelected || ''}
                      values={monthList}
                      onChange={handleChange('monthEndSelected')}
                    />
                  </div>
                  <div className={classes.textYear}>
                    <FormControlSelectWithSearch
                      error={yearEndSelectedError}
                      noEmpty
                      label={`${i18next.t(
                        'service_desk_web_atoir:captionYear',
                      )}*`}
                      showLabel
                      value={yearEndSelected || ''}
                      values={yearEndList}
                      onChange={handleChange('yearEndSelected')}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.divAlignBottom}>
                  <div style={{ marginBottom: 0 }}>
                    <Typography
                      variant="subheading"
                      className={classes.greyText}
                    >
                      {intervalMonths === undefined ? (
                        <Fragment>{`0 ${i18next.t(
                          'shared_web_atoir:monthEnum',
                        )}`}</Fragment>
                      ) : intervalMonths && Number(intervalMonths) >= 1 ? (
                        <Fragment>
                          {`${intervalMonths} ${i18next.t(
                            'shared_web_atoir:monthEnum',
                          )}`}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {i18next.t('shared_web_atoir:incorrectDateInput')}
                        </Fragment>
                      )}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} md={6}>
                {!noResponsible && (
                  <SelectLimitedFormControl
                    FormControlProps={{
                      required: true,
                      fullWidth: true,
                      disabled: noResponsible,
                    }}
                    firstElement={{ id: '', title: '' }}
                    value={organizationSelected || null}
                    label={i18next.t('shared_web_atoir:organizationClient')}
                    sourceUrl={`${URL}/organizations/manager_or_higher?filters=[client_organization=true]`}
                    onChange={handleChange('organizationSelected')}
                    requestResultTransformerToArray={data =>
                      data.organizations.filter(o => o.client_organization)
                    }
                    itemTransformer={row => ({
                      id: row.id,
                      title: row.full_name,
                    })}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {!(!organizationSelected || noResponsible) && (
                  <SelectLimitedFormControl
                    FormControlProps={{
                      required: true,
                      fullWidth: true,
                      disabled: !organizationSelected || noResponsible,
                    }}
                    firstElement={{ id: '', title: '' }}
                    value={responsibleSelected || null}
                    label={i18next.t('shared_web_atoir:responsiblePerson')}
                    sourceUrl={`${URL}/organizations/${organizationSelected}/users`}
                    onChange={handleChange('responsibleSelected')}
                    requestResultTransformerToArray={data => data.users}
                    itemTransformer={row => ({
                      id: row.id,
                      title: getUserFullName(row),
                    })}
                  />
                )}
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <TextField
                  multiline
                  rowsMax="6"
                  className={classes.textField}
                  id="standard-name"
                  label={i18next.t('shared_web_atoir:note')}
                  value={comment}
                  onChange={this.handleChange('comment')}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <OutlinedButton onClick={onClose}>
            {i18next.t('shared_web_atoir:cancel')}
          </OutlinedButton>
          <ContainedButton onClick={submit}>
            {i18next.t('shared_web_atoir:save')}
          </ContainedButton>
        </div>
      </Dialog>
    )
  }
}

EditContractBase.defaultProps = {
  noResponsible: false,
}

EditContractBase.propTypes = {
  contract: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  noResponsible: PropTypes.bool,
}

export default withStyles(styles)(EditContractBase)
