import { createRequestTypes } from '../actionTypes'

export const FETCH_TRUSTS_REQUEST = 'trustedOrganizations/FETCH_TRUSTS_REQUEST'
export const FETCH_TRUSTS_SUCCESS = 'trustedOrganizations/FETCH_TRUSTS_SUCCESS'
export const FETCH_TRUSTS_FAILURE = 'trustedOrganizations/FETCH_TRUSTS_FAILURE'

export const FETCH_TRUSTS_COUNT_REQUEST =
  'trustedOrganizations/FETCH_TRUSTS_COUNT_REQUEST'
export const FETCH_TRUSTS_COUNT_SUCCESS =
  'trustedOrganizations/FETCH_TRUSTS_COUNT_SUCCESS'
export const FETCH_TRUSTS_COUNT_FAILURE =
  'trustedOrganizations/FETCH_TRUSTS_COUNT_FAILURE'

export const CHANGE_PAGE = 'trustedOrganizations/CHANGE_PAGE'

export const CHANGE_ROWS_PER_PAGE = 'trustedOrganizations/CHANGE_ROWS_PER_PAGE'

export const CHANGE_STATUS = 'trustedOrganizations/CHANGE_STATUS'

export const CHANGE_SIDE = 'trustedOrganizations/CHANGE_SIDE'

export const CHANGE_ORGANIZATION = 'trustedOrganizations/CHANGE_ORGANIZATION'

export const APPLY_FILTERS = 'trustedOrganizations/APPLY_FILTERS'

export const SHOW_ACCEPT_OR_DENY_DIALOG =
  'trustedOrganizations/SHOW_ACCEPT_OR_DENY_DIALOG'

export const CLOSE_ACCEPT_OR_DENY_DIALOG =
  'trustedOrganizations/CLOSE_ACCEPT_OR_DENY_DIALOG'

export const ACCEPT_TRUST_REQUEST = 'trustedOrganizations/ACCEPT_TRUST'
export const ACCEPT_TRUST_SUCCESS = 'trustedOrganizations/ACCEPT_TRUST_SUCCESS'
export const ACCEPT_TRUST_FAILURE = 'trustedOrganizations/ACCEPT_TRUST_FAILURE'

export const REJECT_TRUST_REQUEST = 'trustedOrganizations/REJECT_TRUST_REQUEST'
export const REJECT_TRUST_SUCCESS = 'trustedOrganizations/REJECT_TRUST_SUCCESS'
export const REJECT_TRUST_FAILURE = 'trustedOrganizations/REJECT_TRUST_FAILURE'

export const CREATE_TRUST = createRequestTypes(
  'trustedOrganizations/CREATE_TRUST',
)
