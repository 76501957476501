import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  CONFIRM_EMAIL_REQUEST,
  GET_CONFIRM_LINK_AGAIN_REQUEST,
  REGISTER_ACCOUNT_REQUEST,
  REGISTER_EMAIL_REQUEST,
} from '../constants/actionTypes'
import {
  confirmEmailRequest,
  getConfirmLinkAgainRequest,
  registerAccountRequest,
  registerEmailRequest,
} from './api'
import {
  registerEmailSuccess,
  registerEmailFailure,
  getConfirmLinkAgainSuccess,
  getConfirmLinkAgainFailure,
  confirmEmailSuccess,
  confirmEmailFailure,
  registerAccountSuccess,
  registerAccountFailure,
  confirmEmailFailureCodes,
} from '../actions/registerAccountActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { setTokens } from '../helpers/tokensHelper'
import { handleErrors, checkCodes } from '../helpers/errorsHelper'

export function* watchRegisterEmailSaga() {
  yield takeLatest(REGISTER_EMAIL_REQUEST, registerEmail)
}

export function* watchGetConfirmLinkAgainSaga() {
  yield takeLatest(GET_CONFIRM_LINK_AGAIN_REQUEST, getConfirmLinkAgain)
}

export function* watchConfirmEmailSaga() {
  yield takeLatest(CONFIRM_EMAIL_REQUEST, confirmEmail)
}

export function* watchRegisterAccountSaga() {
  yield takeLatest(REGISTER_ACCOUNT_REQUEST, registerAccount)
}

function* registerEmail(action) {
  try {
    const result = yield call(registerEmailRequest(action.email))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(registerEmailSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.email)) {
          const shouldResend = checkCodes(result, 'shouldResendLink')
          yield put(registerEmailFailure(errors, shouldResend))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* getConfirmLinkAgain(action) {
  try {
    const result = yield call(getConfirmLinkAgainRequest(action.email))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(getConfirmLinkAgainSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.email)) {
          yield put(getConfirmLinkAgainFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* confirmEmail() {
  try {
    const confirmEmailHash = sessionStorage.getItem('confirmEmailHash')
    const result = yield call(confirmEmailRequest(confirmEmailHash))

    if (!isEmpty(result.success)) {
      if (result.success) {
        sessionStorage.setItem('registerAccountHash', result.data.hash)
        yield put(confirmEmailSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(confirmEmailFailure(errors, result.code, result.email))
        yield put(confirmEmailFailureCodes(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* registerAccount(action) {
  try {
    const registerAccountHash = sessionStorage.getItem('registerAccountHash')
    const result = yield call(
      registerAccountRequest(registerAccountHash, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        setTokens(result)
        yield put(registerAccountSuccess())
      } else {
        const errors = handleErrors(result)
        if (
          !isEmpty(errors.firstName) ||
          !isEmpty(errors.lastName) ||
          !isEmpty(errors.password) ||
          !isEmpty(errors.passwordConfirm) ||
          !isEmpty(errors.agreementTreatmentPersonalInfo)
        ) {
          yield put(registerAccountFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
