import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import LinkUserAccountComponent from './LinkUserAccountComponent'
import {
  fetchOrganizationLocations,
  bindOrganizationUserAccount,
  unbindOrganizationUserAccount,
  completeCreateUpdateOrganizationUser,
  updateOrganizationUser,
} from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  organizationUserId: state.staff.organizationUserId,
  isFetchingLocations: state.staff.isFetchingLocations,
  locations: state.staff.locations,
  bindUserAccountError: state.staff.bindUserAccountError,
  unbindUserAccountError: state.staff.unbindUserAccountError,
  isCreatingUpdatingOrganizationUser:
    state.staff.isCreatingUpdatingOrganizationUser,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationLocations,
      bindOrganizationUserAccount,
      unbindOrganizationUserAccount,
      completeCreateUpdateOrganizationUser,
      updateOrganizationUser,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LinkUserAccountComponent),
)
