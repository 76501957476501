import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ContractsTable from './ContractsTable'
import { fetchHasManagerOrHigherOrganizations } from '../../../../actions/organizationsActions'

const mapStateToProps = state => ({
  hasManagerOrHigherOrganizations:
    state.organizations.hasManagerOrHigherOrganization.has,
  isOrganizationFunctionalsAvailable:
    state.organizations.organizationFunctionals
      .isOrganizationFunctionalsAvailable,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchHasManagerOrHigherOrganizations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractsTable),
)
