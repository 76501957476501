import React from 'react'
import i18next from '../../../../../i18n/i18n'
import Box from '@material-ui/core/Box'
import FormDialog from '../../../../../widgets/FormDialog'
import { TextField } from '@material-ui/core'

const EditCallDialog = ({ open, onSubmit, onClose, value }) => {
  const [description, setDescription] = React.useState('')
  const [descriptionError, setDescriptionError] = React.useState(false)
  React.useEffect(() => {
    if (value) {
      setDescription(value)
    }
  }, [])
  function onChangeHandler({ target: { value: _value } }) {
    setDescription(_value)
    if (_value.length === 0 || _value.length > 5000) setDescriptionError(true)
    else setDescriptionError(false)
  }
  function onSubmitHandler() {
    if (!descriptionError) onSubmit({ description })
  }
  return (
    <FormDialog
      positiveDisabled={descriptionError}
      closeIconHidden
      open={open}
      title="Редактирование описания"
      onClose={onClose}
      onExit={onClose}
      onSubmit={onSubmitHandler}
      submitButtonTitle={i18next.t('inner_calls_web_atoir:doChose')}
      fullWidth
      dialogProps={{
        maxWidth: 'sm',
      }}
      secondaryButtonsArray={[
        {
          secondaryText: i18next.t('shared_web_atoir:cancel'),
          handleSecondaryAction: onClose,
        },
      ]}
    >
      {open && (
        <Box b={2} t={2}>
          <TextField
            error={descriptionError}
            label="Что нужно сделать? Опишите проблему *"
            variant="outlined"
            onChange={onChangeHandler}
            fullWidth
            multiline
            rows="4"
            value={description}
          />
        </Box>
      )}
    </FormDialog>
  )
}

export default EditCallDialog
