import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import i18next from '../../../../../i18n/i18n'

// helpers
import { getUserFullName } from '../../../../../helpers/userHelper'
import { organizationLocationStringify } from '../../../../../helpers/parserHelper'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// components
import { Can } from '../Can'
import BlockTitle from '../../../CallProfile/widgets/BlockTitle'
import InfoRow from '../../../CallProfile/widgets/InfoRow'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import DialogManager from '../DialogManager'
import AssigneeCallDialog from './AssigneeCallDialog'
import EditCallDialog from './EditCallDialog'

const styles = theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
})

class InnerCallMainInfo extends Component {
  state = {}

  render() {
    const {
      props: { classes, innerCall, assigneeCall, editCall, closeCall },
    } = this
    return (
      <Fragment>
        <div id="inner-call-main-info">
          <InfoRow
            name={`${i18next.t('inner_calls_web_atoir:status')}:`}
            value={i18next.t(`inner_calls_web_atoir:${innerCall.status}Status`)}
            actionsArea={() => (
              <Can I="edit" a="CALL_STATUS">
                <ContainedButton
                  onClick={() => {
                    closeCall({ id: innerCall.id })
                  }}
                >
                  {i18next.t('shared_web_atoir:close')}
                </ContainedButton>
              </Can>
            )}
          />
        </div>
        <div id="inner-call-client" className={classes.marginBottom}>
          <BlockTitle text={`${i18next.t('service_desk_web_atoir:client')}`} />
          <InfoRow
            name={`${i18next.t('organizations_web_atoir:organization')}:`}
            value={innerCall.client_organization.short_name}
          />
          <InfoRow
            name={`${i18next.t('organizations_web_atoir:address')}:`}
            value={organizationLocationStringify(
              innerCall.client_organization.OrganizationLocations[0],
            )}
          />
        </div>
        <div id="inner-call-equipment" className={classes.marginBottom}>
          <BlockTitle
            text={`${i18next.t('service_desk_web_atoir:equipment')}`}
          />
          <InfoRow
            name={`${i18next.t('service_desk_web_atoir:equipment')}:`}
            value={
              !isEmpty(innerCall.OrganizationEquipment)
                ? innerCall.OrganizationEquipment.name
                : '-'
            }
          />
          <InfoRow
            name={`${i18next.t('organizations_web_atoir:address')}:`}
            value={
              !isEmpty(innerCall.OrganizationEquipment)
                ? organizationLocationStringify(
                    innerCall.OrganizationEquipment.OrganizationLocation,
                  )
                : '-'
            }
          />
        </div>
        <div id="inner-call-performer" className={classes.marginBottom}>
          <BlockTitle
            text={`${i18next.t('service_desk_web_atoir:performer')}`}
          />
          <InfoRow
            name={`${i18next.t('organizations_web_atoir:organization')}:`}
            value={innerCall.performer_organization.short_name}
          />
          <Can I="edit" a="CALL_ASSIGNEE" passThrough>
            {can => (
              <DialogManager
                onSubmit={({ assigneeId }) => {
                  assigneeCall({ id: innerCall.id, assigneeId })
                }}
                dialog={({ open, onSubmit, onClose }) => (
                  <AssigneeCallDialog
                    open={open}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    orgId={innerCall.performer_organization.id}
                  />
                )}
              >
                {({ openDialog }) => (
                  <InfoRow
                    onEdit={can && openDialog}
                    name={`${i18next.t('service_desk_web_atoir:engineer')}:`}
                    value={
                      innerCall.performer
                        ? getUserFullName(innerCall.performer)
                        : ''
                    }
                  />
                )}
              </DialogManager>
            )}
          </Can>
          <InfoRow name="Тип работ" value={innerCall.work_type} />
          <Can I="edit" a="CALL_INFO" passThrough>
            {can => (
              <DialogManager
                onSubmit={({ description }) => {
                  editCall({ id: innerCall.id, description })
                }}
                dialog={({ open, onSubmit, onClose }) => (
                  <EditCallDialog
                    open={open}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    value={innerCall.description}
                  />
                )}
              >
                {({ openDialog }) => (
                  <InfoRow
                    onEdit={can && openDialog}
                    name={`${i18next.t('inner_calls_web_atoir:description')}:`}
                    value={innerCall.description}
                  />
                )}
              </DialogManager>
            )}
          </Can>
        </div>
      </Fragment>
    )
  }
}

InnerCallMainInfo.propTypes = {
  innerCall: PropTypes.object.isRequired,
}

export default withStyles(styles)(InnerCallMainInfo)
