import moment from 'moment'
import i18next from '../i18n/i18n'

export function convertTimestamp(timestamp) {
  const d = new Date(timestamp)
  const fullYear = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2)
  const day = `0${d.getDate()}`.slice(-2)
  const hours = d.getHours()
  const minutes = `0${d.getMinutes()}`.slice(-2)

  return `${day}.${month}.${fullYear}, ${hours}:${minutes}`
}

export function dateFormat(date) {
  let result = null
  if (date) {
    const d = new Date(date)
    const fullYear = d.getFullYear()
    const month = `0${d.getMonth() + 1}`.slice(-2)
    const day = `0${d.getDate()}`.slice(-2)
    result = `${day}.${month}.${fullYear}`
  }

  return result
}

export function dateFormatShortYear(date) {
  const d = new Date(date)
  const fullYear = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2)
  const day = `0${d.getDate()}`.slice(-2)

  return `${day}.${month}.${fullYear.toString().substring(2)}`
}

export function dateFormatFromTo(from, to) {
  let fromResult = ''
  if (from) {
    const dateFrom = new Date(from)
    const dateFromYear = dateFrom.getFullYear()
    const dateFromMonth = `0${dateFrom.getMonth() + 1}`.slice(-2)
    const dateFromDay = `0${dateFrom.getDate()}`.slice(-2)
    fromResult = `${i18next.t(
      'shared_web_atoir:dateFrom',
    )} ${dateFromDay}.${dateFromMonth}.${dateFromYear}`
  }

  let toResult = ''
  if (to) {
    const dateTo = new Date(to)
    const dateToYear = dateTo.getFullYear()
    const dateToMonth = `0${dateTo.getMonth() + 1}`.slice(-2)
    const dateToDay = `0${dateTo.getDate()}`.slice(-2)

    toResult = `${i18next.t(
      'shared_web_atoir:dateTo',
    )} ${dateToDay}.${dateToMonth}.${dateToYear}`
  }

  return `${fromResult} ${toResult}`
}

export function timeFormatHourMinutes(date) {
  const d = new Date(date)
  const hours = d.getHours()
  const minutes = `0${d.getMinutes()}`.slice(-2)

  return `${hours}:${minutes}`
}

export function convertTimestampVerbose(timestamp) {
  const d = new Date(timestamp)
  const fullYear = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2)
  const day = `0${d.getDate()}`.slice(-2)
  const hours = d.getHours()
  const minutes = `0${d.getMinutes()}`.slice(-2)

  const dateDiff = moment().diff(timestamp, 'days')
  return dateDiff > 1
    ? `${day}.${month}.${fullYear} ${hours}:${minutes}`
    : dateDiff > 0
    ? `${i18next.t('shared_web_atoir:dateYesterday')} ${hours}:${minutes}`
    : `${i18next.t('shared_web_atoir:dateToday')} ${hours}:${minutes}`
}

export function countOfDatesBetween(dateFrom, dateTo, interval) {
  const a = moment(dateFrom)
  const b = dateTo ? moment(dateTo) : moment(new Date())
  if (interval === 'month') {
    return b.diff(a, 'months')
  }

  if (interval === 'year') {
    return b.diff(a, 'years')
  }

  return b.diff(a, 'days')
}

export const formatDateWithTime = date =>
  `${date.toLocaleDateString('ru')} ${moment(date).format('HH:mm')}`

export const formatDate = date => {
  if (
    !date ||
    !(new Date(date) instanceof Date) ||
    new Date(date).getFullYear() === 1970
  ) {
    return `(${i18next.t('shared_web_atoir:noDate')})`
  }
  return moment(date).format('DD.MM.YYYY')
}

export const formDatePeriod = (begin, end) =>
  `${new Date(Date.parse(begin)).toLocaleDateString('ru')} - ${new Date(
    Date.parse(end),
  ).toLocaleDateString('ru')}`

export const convertDHMDuration = ms => {
  const hours = Math.floor(ms / (1000 * 60 * 60))
  const hoursms = ms % (60 * 60 * 1000)
  const minutes = Math.floor(hoursms / (60 * 1000))

  return `${hours ? i18next.t('shared_web_atoir:hoursInWork', { hours }) : ''}${
    minutes ? i18next.t('shared_web_atoir:minutesInWork', { minutes }) : ''
  }`
}

export function visitDateParserOrderSD(order = {}, call = {}) {
  let res = null
  if (order.status === 'cancel') res = ''
  if (res === null) {
    const performerInfo =
      (call.performer_info || {}).repair ||
      (call.performer_info || {}).diagnostic
    const visitDate = order.visit_date || (performerInfo || {}).visit_date
    if (visitDate === null) {
      res = i18next.t('service_desk_web_atoir:dateOfVisitIsNotAssignedYet')
    }
    if (res === null && visitDate === '1970-01-01') {
      res = i18next.t('service_desk_web_atoir:dateOfVisitIsNotAssigned')
    }
    if (res === null && visitDate) {
      res = dateFormat(visitDate)
    }
  }
  return res
}
