import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles/index'

const styles = theme => ({
  section: {
    padding: theme.spacing(3),
  },
  root: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    color: theme.palette.apply.main,
  },
  content: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const SectionProfileList = props => (
  <Fragment>
    <section className={props.classes.section}>
      {!isEmpty(props.list) &&
        props.list.map(
          _item =>
            !isEmpty(_item.content) && (
              <Grid container className={props.classes.root}>
                <Grid item md={3} sm={4} xs={12}>
                  <Typography variant="body2" className={props.classes.title}>
                    {_item.title}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={4} xs={6}>
                  <Typography
                    variant="body2"
                    className={classNames(
                      { [props.classes.content]: _item.ellipsis },
                      'wordWrapBreakAll',
                    )}
                  >
                    {_item.content}
                  </Typography>
                  {!isEmpty(_item.subContentArr) &&
                    _item.subContentArr.map(_subContentItem => (
                      <Typography
                        variant="body2"
                        className={props.classes.title}
                      >
                        {_subContentItem}
                      </Typography>
                    ))}
                </Grid>
                <Grid container item md={3} sm={4} xs={6} justify="flex-end">
                  <Typography variant="body2" className={props.classes.title}>
                    {!isEmpty(_item.actionItem) && _item.actionItem}
                  </Typography>
                </Grid>
              </Grid>
            ),
        )}
    </section>
    {!props.notDivider && <Divider />}
  </Fragment>
)

SectionProfileList.defaultProps = {
  list: [],
  notDivider: false,
}

SectionProfileList.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array,
  notDivider: PropTypes.bool,
}

export default withStyles(styles)(SectionProfileList)
