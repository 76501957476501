import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AddUserComponent from './AddUserComponent'
import {
  startCreateUpdateOrganizationUser,
  addOrganizationUser,
  updateOrganizationUser,
} from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  organization: state.organizations.organization,
  addUserError: state.staff.addUserError,
  updateOrganizationUserError: state.staff.updateOrganizationUserError,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      startCreateUpdateOrganizationUser,
      addOrganizationUser,
      updateOrganizationUser,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUserComponent),
)
