import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EquipmentProfileComponent from '../../../components/Equipment/Profile/EquipmentProfileComponent'
import {
  fetchEquipmentPermissions,
  fetchEquipmentDetails,
} from '../../../actions/equipmentActions'

const mapStateToProps = state => ({
  isFetchingEquipmentPermissions:
    state.equipment.equipmentPermissions.isFetchingEquipmentPermissions,
  permissions: state.equipment.equipmentPermissions.permissions,
  permissionsError: state.equipment.equipmentPermissions.error,
  isFetchingEquipmentDetails:
    state.equipment.equipmentDetails.isFetchingEquipmentDetails,
  equipment: state.equipment.equipmentDetails.equipment,
  fetchError: state.equipment.equipmentDetails.error,
  isUpdatingEquipmentDetails:
    state.equipment.updateEquipmentDetails.isUpdatingEquipmentDetails,
  isRejectBaSuggestionsFetching: state.equipment.rejectBaSuggestions.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEquipmentPermissions,
      fetchEquipmentDetails,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentProfileComponent),
)
