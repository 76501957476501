import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'

// modules
import Loader from '../../../../Loader'
import OrderRow from './OrderRow'

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  item: {
    width: '100%',
  },
})

const OrdersListRenderer = props => {
  const {
    classes,
    type,
    isFetching,
    orders,
    permissions,
    orderButtons,
    orderButtonExecute,
    orderButtonExecuting,
    callScripts,
    call,
  } = props
  return (
    <Fragment>
      {isFetching ? (
        <Loader />
      ) : (
        !isFetching &&
        !!orders &&
        orders.length > 0 && (
          <Fragment>
            <Paper elevation={2}>
              <List className={classes.root}>
                {orders &&
                  orders.map((h, ind) => {
                    const orderPermissions = _.find(
                      permissions,
                      orderPerms => orderPerms.id === h.id,
                    )
                    return (
                      orderPermissions && (
                        <Fragment key={h.id}>
                          <ListItem className={classes.item}>
                            <OrderRow
                              call={call}
                              type={type}
                              order={h}
                              orderPermissions={orderPermissions.permissions}
                              commonOrderPermissions={orderPermissions}
                              orderButtons={orderButtons}
                              orderButtonExecute={orderButtonExecute}
                              orderButtonExecuting={orderButtonExecuting}
                              callScripts={callScripts}
                            />
                          </ListItem>
                          {orders.length - 1 !== ind ? <Divider /> : null}
                        </Fragment>
                      )
                    )
                  })}
              </List>
            </Paper>
          </Fragment>
        )
      )}
    </Fragment>
  )
}

OrdersListRenderer.propTypes = {
  call: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  orders: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  orderButtons: PropTypes.array.isRequired,
  orderButtonExecute: PropTypes.func.isRequired,
  orderButtonExecuting: PropTypes.bool.isRequired,
  callScripts: PropTypes.array.isRequired,
}

export default withStyles(styles)(OrdersListRenderer)
