import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Chip from '@material-ui/core/Chip'
import i18n from '../../../i18n/i18n'
import ContractsTable from './ContractsTable'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import config from '../../../config'
import ApplyButton from '../../../widgets/Buttons/ApplyButton'
import SelectLimitedFromControl from '../../../widgets/SelectLimitedFormControl'
import { URL } from '../../../middlewares/api'

const styles = theme => ({
  statusChip: {
    minWidth: 120,
    marginRight: 18,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeChip: {},
  arrowIcon: {
    fontSize: 16,
    rotate: 180,
    marginRight: 4,
    verticalAlign: 'text-top',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  arrowIconRotated: {
    transform: 'rotate(180deg)',
  },
  listItem: {
    paddingTop: 6,
    paddingBottom: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  loadMore: {
    margin: '14px auto',
    display: 'block',
  },
  acceptButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    marginLeft: -12,
  },
  radio: {
    '&$checked': {
      color: theme.palette.blueSecondary.main,
    },
  },
  checked: {
    color: theme.palette.blueSecondary.main,
  },
  root: {
    padding: 24,
  },
})

const statuses = ['created', 'inperformer', 'inwork', 'closed', 'canceled']

class ContractsList extends Component {
  state = {
    status: localStorage.getItem('ContractsListStatus') || 'created',
    sortBy: '',
    sortDirection: 'desc',
    limit: config.defaultItemsPerPage,
    offset: 0,
    side: '',
    hasBeenFiltered: false,
    notDistributed: '',
  }

  performerRef = null

  clientRef = null

  componentDidMount() {
    const {
      props: { fetchManagerOrHigherOrganizations, fetchContractsCountRequest },
      changeFilters,
      fetchContracts,
    } = this
    fetchContracts()
    fetchManagerOrHigherOrganizations()
    statuses.forEach(s => fetchContractsCountRequest({ status: s }))
    changeFilters()
  }

  componentWillUnmount() {
    const {
      props: { clearFilterDrawer },
      state: { status },
    } = this
    clearFilterDrawer()
    localStorage.setItem('ContractsListStatus', status)
  }

  fetchContracts = () => {
    const {
      state: {
        status,
        sortDirection,
        sortBy,
        limit,
        offset,
        side,
        notDistributed,
        clientOrganization,
        performerOrganization,
      },
      props: { fetchContractsRequest },
    } = this
    fetchContractsRequest({
      status,
      sort_direction: sortDirection,
      sort_by: sortBy,
      limit,
      offset,
      side,
      performer_organization: performerOrganization,
      client_organization: clientOrganization,
      notDistributed: !!notDistributed,
    })
  }

  loadMore = () => {
    const {
      state: { limit, offset },
      fetchContracts,
    } = this
    this.setState(
      {
        offset: offset + limit,
      },
      fetchContracts,
    )
  }

  fetchContractsCount = () => {
    const {
      state: {
        status,
        side,
        notDistributed,
        clientOrganization,
        performerOrganization,
      },
      props: { fetchContractsCountRequest },
    } = this
    fetchContractsCountRequest({
      status,
      params: {
        side,
        not_distributed: !!notDistributed,
        performer_organization: performerOrganization,
        client_organization: clientOrganization,
      },
    })
  }

  onSort = key => () => {
    const {
      changeFilters,
      state: { sortDirection: sd },
    } = this
    let sortBy = key
    let sortDirection = 'asc'

    if (this.state.sortBy === key && sd === 'asc') {
      sortDirection = 'desc'
    } else if (this.state.sortBy === key && sd === 'desc') {
      sortBy = ''
      sortDirection = sd
    }

    this.setState({ sortDirection, sortBy, offset: 0 }, () => {
      changeFilters()
    })
  }

  onChangeRadio = key => async ({ target: { value } }) => {
    // Не понял зачем этот кусок кода. Блокирует работу выбора типа договора.
    // if (key === 'side' && ['client', 'performer'].includes(value)) {
    //   await this[`${value}Ref`].clear()
    // }
    this.setState(
      {
        [key]: value,
        hasBeenFiltered: true,
        offset: 0,
      },
      () => {
        this.changeFilters()
        this.fetchContracts()
        this.fetchContractsCount()
      },
    )
  }

  changeFilters = () => {
    const {
      state: { status, sortBy, sortDirection },
      props: { classes, setFilterDrawer },
      onChangeOrganizationFilter,
      onAcceptOrganizationFilter,
      onChangeRadio,
      onSort,
    } = this
    const orderKeys = [
      'date_created',
      'contract_begin_date',
      'contract_end_date',
    ]
    const ordering = (
      <Fragment>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {i18n.t('service_desk_web_atoir:sortings')}
            </ListSubheader>
          }
        >
          {orderKeys.map(k => (
            <ListItem onClick={onSort(k)} className={classes.listItem}>
              <ListItemText>
                <div style={{ display: 'flex' }}>
                  <ArrowUpward
                    className={classnames([
                      classes.arrowIcon,
                      sortBy !== k && classes.hidden,
                      sortDirection === 'desc' && classes.arrowIconRotated,
                    ])}
                  />
                  <span style={sortBy === k ? { fontWeight: 'bold' } : {}}>
                    {i18n.t(`service_desk_web_atoir:${k}`)}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Fragment>
    )
    const contractType = (
      <Fragment>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {i18n.t('service_desk_web_atoir:contract_type')}
            </ListSubheader>
          }
        >
          <ListItem className={classes.listItem}>
            <FormControl>
              <RadioGroup
                aria-label="contract-side"
                name="contract-side"
                value={this.state.side}
                onChange={onChangeRadio('side')}
              >
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t('shared_web_atoir:All')}
                />
                <FormControlLabel
                  value="performer"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t('service_desk_web_atoir:client_contracts')}
                />
                <FormControlLabel
                  value="client"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t('service_desk_web_atoir:performer_contracts')}
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
      </Fragment>
    )
    const equipmentDistributed = (
      <Fragment>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {i18n.t('service_desk_web_atoir:equipmentDistibutedFilterTitle')}
            </ListSubheader>
          }
        >
          <ListItem className={classes.listItem}>
            <FormControl>
              <RadioGroup
                aria-label="contract-side"
                name="contract-side"
                value={this.state.notDistributed}
                onChange={onChangeRadio('notDistributed')}
              >
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t('shared_web_atoir:All')}
                />
                <FormControlLabel
                  value="not_distributed"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18n.t('service_desk_web_atoir:not_distributed')}
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
      </Fragment>
    )
    setFilterDrawer(
      <Fragment>
        {ordering}
        <Divider />
        {status !== 'created' && contractType}
        {status !== 'created' && <Divider />}
        {status === 'inwork' && equipmentDistributed}
        {status === 'inwork' && <Divider />}
        <div className={classes.root}>
          <SelectLimitedFromControl
            FormControlProps={{
              fullWidth: true,
            }}
            label={i18n.t(
              'service_desk_web_atoir:clientOrganizationFilterPlaceholder',
            )}
            firstElement={{ id: null, title: '' }}
            createRef={ref => {
              this.clientRef = ref
            }}
            query={{
              status,
              side: this.state.side || 'all',
              filter_side: 'client',
            }}
            value={this.state.clientOrganization}
            sourceUrl={`
              ${URL}/service_desk_contracts/filters/organizations`}
            onChange={onChangeOrganizationFilter('clientOrganization')}
            requestResultTransformerToArray={r => r.organizations}
            itemTransformer={l => ({ id: l.id, title: l.short_name })}
          />
          {status !== 'created' && (
            <SelectLimitedFromControl
              FormControlProps={{ fullWidth: true }}
              label={i18n.t(
                'service_desk_web_atoir:performerOrganizationFilterPlaceholder',
              )}
              firstElement={{ id: null, title: '' }}
              createRef={ref => {
                this.performerRef = ref
              }}
              query={{
                status,
                side: this.state.side || 'all',
                filter_side: 'performer',
              }}
              value={this.state.performerOrganization}
              sourceUrl={`
                ${URL}/service_desk_contracts/filters/organizations`}
              onChange={onChangeOrganizationFilter('performerOrganization')}
              requestResultTransformerToArray={r => r.organizations}
              itemTransformer={l => ({ id: l.id, title: l.short_name })}
            />
          )}
        </div>
        <div className={classes.acceptButtonWrapper}>
          <ApplyButton onClick={onAcceptOrganizationFilter}>
            {i18n.t('service_desk_web_atoir:applyAction')}
          </ApplyButton>
        </div>
      </Fragment>,
    )
  }

  onChangeStatus = status => () => {
    const {
      state: { status: stateStatus },
      changeFilters,
      fetchContracts,
      fetchContractsCount,
    } = this
    if (stateStatus === status) {
      return
    }
    this.setState(
      {
        status,
        limit: config.defaultItemsPerPage,
        offset: 0,
        hasBeenFiltered: false,
        notDistributed: '',
        side: '',
      },
      () => {
        fetchContracts()
        changeFilters()
        fetchContractsCount()
      },
    )
  }

  onChangeOrganizationFilter = key => value => {
    console.log('onChangeOrganizationFilter', value)
    this.setState(
      {
        [key]: value,
        hasBeenFiltered: true,
        limit: config.defaultItemsPerPage,
        offset: 0,
      },
      () => {
        this.changeFilters()
      },
    )
  }

  onAcceptOrganizationFilter = () => {
    this.fetchContracts()
    this.fetchContractsCount()
  }

  render() {
    const {
      props: {
        classes,
        createdCount,
        inperformerCount,
        inworkCount,
        closedCount,
        canceledCount,
        createdFilteredCount,
        inperformerFilteredCount,
        inworkFilteredCount,
        closedFilteredCount,
        canceledFilteredCount,
        isFetching,
        contracts,
      },
      state: { status, hasBeenFiltered },
      loadMore,
      onChangeStatus,
    } = this
    const counts = {
      createdCount,
      inperformerCount,
      inworkCount,
      closedCount,
      canceledCount,
      createdFilteredCount,
      inperformerFilteredCount,
      inworkFilteredCount,
      closedFilteredCount,
      canceledFilteredCount,
    }
    return (
      <Fragment>
        {statuses.map(s => (
          <Chip
            onClick={onChangeStatus(s)}
            color={status === s ? 'primary' : 'default'}
            className={classnames([
              classes.statusChip,
              status === s ? classes.activeChip : null,
            ])}
            label={`${i18n.t(`service_desk_web_atoir:${s}Contracts`)}(${
              s === status &&
              hasBeenFiltered &&
              (counts[`${s}FilteredCount`] || counts[`${s}FilteredCount`] === 0)
                ? `${counts[`${s}FilteredCount`]}/`
                : ''
            }${
              counts[`${s}Count`] || counts[`${s}Count`] === 0
                ? counts[`${s}Count`]
                : ''
            })`}
            variant="outlined"
          />
        ))}
        <div style={{ marginTop: 31 }}>
          <ContractsTable contracts={contracts} isFetching={isFetching} />
        </div>
        {contracts &&
        !isFetching &&
        // eslint-disable-next-line
        (counts[`${status}FilteredCount`] != undefined
          ? counts[`${status}FilteredCount`] > contracts.length
          : counts[`${status}Count`] > contracts.length) ? (
          <Button
            className={classes.loadMore}
            onClick={loadMore}
            variant="text"
          >
            {i18n.t('service_desk_web_atoir:show_more')}
          </Button>
        ) : null}
      </Fragment>
    )
  }
}

ContractsList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContractsList)
