import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

// reducers
import app from './appReducer'
import login from './loginReducer'
import registerAccount from './registerAccountReducer'
import repairPassword from './repairPasswordReducer'
import user from './userReducer'
import organizations from './organizationsReducer'
import staff from './staffReducer'
import employers from './employersReducer'
import locations from './locationsReducer'
import departments from './departmentsReducer'
import organizationContacts from './organizationContactsReducer'
import equipment from './equipmentReducer'
import inventory from './inventoryReducer'
import utils from './utilsReducer'
import inventoryEquipment from './inventoryEquipmentReducer'
import serviceDesk from './serviceDesk'
import serviceDeskContracts from './serviceDeskContractsReducer'
import settings from './settingsReducer'
import statistics from './statisticsReducer'
import scripts from './scriptsReducer'
import tags from './tagsReducer'
import emailTemplates from './emailTemplatesReducer'
import buttons from './buttonsReducer'
import innerCalls from './innerCallsReducer'
import trustedOrganizations from './trustedOrganizationsReducer'
import recurringCalls from './recurringCallsReducer'

const rootReducer = combineReducers({
  app,
  login,
  registerAccount,
  repairPassword,
  user,
  organizations,
  staff,
  employers,
  locations,
  departments,
  organizationContacts,
  equipment,
  inventory,
  utils,
  inventoryEquipment,
  serviceDesk,
  serviceDeskContracts,
  settings,
  statistics,
  scripts,
  tags,
  emailTemplates,
  buttons,
  innerCalls,
  trustedOrganizations,
  recurringCalls,
  routing: routerReducer,
})

export default rootReducer
