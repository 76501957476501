import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import SideSelector from './SideSelector'
import MyOrganizationSelector from './MyOrganizationSelector'
import ContragentVariantsAutoComplete from './ContragentVariantsAutoComplete'

import i18n from '../../../i18n/i18n'

const TitleRow = ({ children }) => (
  <Box mb={0.5}>
    <Typography variant="body1" color="secondary">
      {children}
    </Typography>
  </Box>
)

function AddTrustedOrganizationsDialogForm({ data, setData }) {
  const onChange = (id, value) => {
    if (id === 'side') {
      setData({ ...data, contragent_id: undefined })
    }
    setData({ ...data, [id]: value })
  }
  return (
    <Box width={330}>
      <Box mb={4}>
        <TitleRow>
          {i18n.t('trusted_organizations_web_atoir:yourSideAndColon')}
        </TitleRow>
        <SideSelector idValue="side" value={data.side} onChange={onChange} />
      </Box>
      <Box mb={4}>
        <TitleRow>
          {i18n.t('trusted_organizations_web_atoir:organiztionAndColon')}
        </TitleRow>
        <MyOrganizationSelector
          idValue="organizationId"
          value={data.organizationId || ''}
          onChange={onChange}
        />
        <ContragentVariantsAutoComplete
          key={data.side}
          idValue="contragent_id"
          organizationId={data.organizationId}
          side={data.side}
          onChange={onChange}
        />
      </Box>
    </Box>
  )
}

export default AddTrustedOrganizationsDialogForm
