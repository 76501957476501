import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AppBarComponent from './AppBarComponent'
import namespaces from '../../../i18n/namespaces'
import { fetchUser } from '../../../actions/userActions'
import { logout } from '../../../actions/loginActions'
import {
  closeErrorMessage,
  handleCloseSimpleDialog,
  redirectedUserToHomePage,
} from '../../../actions/appActions'

const mapStateToProps = state => ({
  isLoggedIn: localStorage.getItem('loggedIn'),
  isLoggingOut: state.login.isLoggingOut,
  errorMessage: state.app.errorMessage,
  filtersComponent: state.app.filterDrawer.component,
  filtersFooter: state.app.filterDrawer.footer,
  navigationComponent: state.app.navigationDrawer.component,
  error: state.app.error,
  namespaces,
  shouldRedirectToHome: state.app.shouldRedirectToHome,
  user: state.user.ownUser,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      handleCloseSimpleDialog,
      closeErrorMessage,
      redirectedUserToHomePage,
      fetchUser,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppBarComponent),
)
