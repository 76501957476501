import React, { Component } from 'react'
import PropTypes from 'prop-types'

// material
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'

// modules
import { URL } from '../../../../middlewares/api'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import SelectLimitedAuto from '../../../../widgets/SelectLimitedAuto'
import { organizationLocationStringify } from '../../../../helpers/parserHelper'
import DropDownList from '../../../../widgets/DropDownList'

// i18
import i18next from '../../../../i18n/i18n'

const style = {
  formControl: {
    marginBottom: 0,
  },
}

class EditEquipmentLocationComponent extends Component {
  state = {
    isFloorOpen: false,
    floor: '',
    location: '',
    cabinet: '',
    department: '',
  }

  constructor(props) {
    super(props)
    this.floors = []
    for (let i = -11; i < 101; i++) {
      if (i <= -2) {
        const ind = i + 1
        this.floors.push(`${ind}`)
      } else if (i === -1) {
        this.floors.push('')
      } else {
        this.floors.push(`${i}`)
      }
    }
  }

  componentDidMount = () => {
    this.setState({
      floor: this.props.floor || '',
      location: this.props._location || '',
      cabinet: this.props.cabinet || '',
      department: this.props.department || '',
    })
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.isUpdatingEquipmentDetails !==
        this.props.isUpdatingEquipmentDetails &&
      !this.props.isUpdatingEquipmentDetails
    ) {
      this.props.hideDialogLoader()
      this.props.onClose()
      if (!this.props.error) {
        this.props.receivedInfoMessage(
          i18next.t('shared_web_atoir:changesSaved'),
        )
      }
    }
  }

  handleToggle = (field, state) => () => {
    this.setState({ [field]: state })
  }

  handleChange = field => event => {
    this.setState({ [field]: event.target ? event.target.value : event })
  }

  editLocation = event => {
    event.preventDefault()
    const { floor, cabinet, location, department } = this.state
    this.props.updateEquipmentDetails(
      this.props.match.params.organizationId,
      this.props.match.params.equipmentId,
      _.omitBy(
        {
          floor,
          cabinet,
          organization_location_id: location,
          organization_department_id: department,
        },
        v => v === 'allAddr' || !v,
      ),
    )
    this.props.showDialogLoader()
  }

  render() {
    const {
      props: {
        classes,
        open,
        onClose,
        match: {
          params: { organizationId },
        },
      },
      state: { isFloorOpen, floor, location, cabinet, department },
      editLocation,
    } = this
    return (
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={editLocation}>
          <DialogContent>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="age-simple">
                    {i18next.t('equipment_web_atoir:placement')}
                  </InputLabel>
                  <SelectLimitedAuto
                    value={location}
                    sourceUrl={`${URL}/organizations/${organizationId}/locations`}
                    onChange={this.handleChange('location')}
                    requestResultTransformerToArray={r => r.locations}
                    itemTransformer={l => ({
                      id: l.id,
                      title: organizationLocationStringify(l),
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <DropDownList
                  showLabel
                  label={i18next.t('equipment_web_atoir:floor')}
                  classes={classes}
                  open={isFloorOpen}
                  value={`${floor}` || ''}
                  elements={this.floors
                    .map(_floor => ({ id: _floor, title: _floor }))
                    .reverse()}
                  isFetching={false}
                  handleOpen={this.handleToggle('isFloorOpen', true)}
                  handleClose={this.handleToggle('isFloorOpen', false)}
                  handleChange={this.handleChange('floor')}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    id="cabinet"
                    label={i18next.t('equipment_web_atoir:cabinet')}
                    placeholder={i18next.t('equipment_web_atoir:enterCabinet')}
                    onChange={this.handleChange('cabinet')}
                    value={cabinet}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="age-simple">
                    {i18next.t('equipment_web_atoir:department')}
                  </InputLabel>
                  <SelectLimitedAuto
                    value={department}
                    sourceUrl={`${URL}/organizations/${organizationId}/departments`}
                    onChange={this.handleChange('department')}
                    requestResultTransformerToArray={r => r.departments}
                    itemTransformer={l => ({ id: l.id, title: l.name })}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <OutlinedButton onClick={onClose} size="medium">
              {i18next.t('shared_web_atoir:cancel')}
            </OutlinedButton>
            <ContainedButton type="submit" size="medium">
              {i18next.t('shared_web_atoir:saveChanges')}
            </ContainedButton>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

EditEquipmentLocationComponent.defaultProps = {
  _location: '',
  cabinet: '',
  department: '',
  floor: '',
}

EditEquipmentLocationComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  _location: PropTypes.string,
  cabinet: PropTypes.string,
  floor: PropTypes.string,
  department: PropTypes.object,
}

export default withStyles(style)(EditEquipmentLocationComponent)
