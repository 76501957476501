import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditPerformerResponsibleDialog from './EditPerformerResponsibleDialog'
import {
  fetchOrganizationUserMe,
  fetchOrganizationWhoAmI,
} from '../../../../../actions/organizationsActions'
import { fetchOrganizationStaff } from '../../../../../actions/staffActions'

const mapStateToProps = state => ({
  userStatus: state.organizations.userStatus.status,
  isFetchingUserStatus: state.organizations.userStatus.isFetching,
  organizationStaff: state.staff.organizationStaff,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  isFetchingMe: state.organizations.me.isFetching,
  me: state.organizations.me.me,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationWhoAmI,
      fetchOrganizationStaff,
      fetchOrganizationUserMe,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPerformerResponsibleDialog),
)
