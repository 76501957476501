import React, { Fragment, memo } from 'react'

import { withStyles } from '@material-ui/core/styles/index'
import Selector from './Selector'
import TagSelector from './TagSelector'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import i18next from '../../../../i18n/i18n'

import MailTemplatesSelector from './MailTemplatesSelector'
import TextField from '@material-ui/core/TextField'

const actionList = () => [
  {
    id: 'send_email',
    description: i18next.t('organizations_web_atoir:toSendEmail'),
  },
  {
    id: 'create_sc_tag',
    description: i18next.t('organizations_web_atoir:attachTagToCall'),
  },
  // {id: 'create_sc_order_tag', description: i18next.t('organizations_web_atoir:attachTagToOrder')},
]

const styles = {}

const ActionChanger = ({
  organizationId,
  action,
  onChange,
  errors,
  touched,
}) => {
  const onChangeOptionsHandler = (event, possibleValue) => {
    let id = event
    let value = possibleValue
    if (event.target) {
      ;({ id, value } = event.target)
    }

    onChangeHandler('options', {
      ...action.options,
      [id]: {
        ...action.options[id],
        address: value,
      },
    })
  }

  const onChangeHandler = (event, possibleValue) => {
    let id = event
    let value = possibleValue
    if (event.target) {
      ;({ id, value } = event.target)
    }
    onChange({
      ...action,
      [id]: value,
    })
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Selector
            fullWidth
            idValue="action_type"
            valueList={actionList()}
            currentValue={action.action_type}
            onChange={onChangeHandler}
            error={Boolean(errors.action_type) && touched.action_type}
          />
        </Grid>
        <Grid item xs={12}>
          {action.action_type === 'send_email' && (
            <MailTemplatesSelector
              fullWidth
              idValue="email_template_id"
              currentValue={action.email_template_id}
              onChange={onChangeHandler}
              organizationId={organizationId}
              error={
                Boolean(errors.email_template_id) && touched.email_template_id
              }
            />
          )}
          {action.action_type === 'send_email' && action.email_template_id && (
            <Fragment>
              <TextField
                id="sender_address"
                fullWidth
                label={i18next.t('organizations_web_atoir:senderAddress')}
                helperText={
                  Boolean(
                    ((errors.options || {}).sender_address || {}).address ===
                      'wrongForm',
                  ) && ((touched.options || {}).sender_address || {}).address
                    ? i18next.t(
                        'organizations_web_atoir:actionCreatorErrorRcptAddrFormat',
                      )
                    : ' '
                }
                onChange={onChangeOptionsHandler}
                value={
                  ((action.options || {}).sender_address || {}).address || ''
                }
                error={
                  Boolean(
                    ((errors.options || {}).sender_address || {}).address,
                  ) && ((touched.options || {}).sender_address || {}).address
                }
              />
              <TextField
                id="recipient_address"
                fullWidth
                label={i18next.t('organizations_web_atoir:recipientAddress')}
                helperText=" "
                onChange={onChangeOptionsHandler}
                value={
                  ((action.options || {}).recipient_address || {}).address || ''
                }
                error={
                  Boolean(
                    ((errors.options || {}).recipient_address || {}).address,
                  ) && ((touched.options || {}).recipient_address || {}).address
                }
              />
            </Fragment>
          )}
          {(action.action_type === 'create_sc_tag' ||
            action.action_type === 'create_sc_tag') && (
            <TagSelector
              fullWidth
              idValue="custom_tag_id"
              currentValue={action.custom_tag_id}
              onChange={onChangeHandler}
              organizationId={organizationId}
              error={Boolean(errors.custom_tag_id) && touched.custom_tag_id}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}

const ActionCreator = ({
  actions,
  onChange,
  organizationId,
  errors,
  touched,
}) => {
  const onChangeHandler = value => {
    onChange('actions', [value])
  }

  return (
    <Fragment>
      {actions.map(action => (
        <Fragment>
          <ActionChanger
            action={action}
            onChange={onChangeHandler}
            organizationId={organizationId}
            errors={errors}
            touched={touched}
          />
        </Fragment>
      ))}
    </Fragment>
  )
}

ActionCreator.defaultProps = {
  errors: {},
}

ActionCreator.propTypes = {
  organizationId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.array.isRequired,
}

export default withStyles(styles)(memo(ActionCreator))
