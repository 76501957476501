import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import Typography from '@material-ui/core/Typography'
import Edit from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import i18n from '../../../../i18n/i18n'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import ContractBaseInfo from './ContractBaseInfo'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import LongTextExpander from '../../../../widgets/LongTextExpander'
import DelayedLoader from '../../../../widgets/DelayedLoader'
import TextButton from '../../../../widgets/Buttons/TextButton'
import {
  popLastSections,
  transformCamelCaseObjectCaseToSnake,
} from '../../../../helpers/dataHelper'
import contractsConstants from '../../../../constants/contracts'
import {
  canAcceptContract,
  canAssignContract,
  canCancelContract,
  canCreateAgreement,
  canEditContract,
  canRejectContract,
  canViewContractInfo,
} from '../../../../helpers/ContractPermissionsResolver'
import ButtonBase from '@material-ui/core/ButtonBase'
import ContractEquipmentTable from '../ContractEquipmentTable'
import EditContractBase from './EditContractBase/EditContractBase'
import AssignToThirdPartyOrganization from '../AssignToThirdParty'
import RejectContract from '../RejectContract'
import AcceptDialog from '../AcceptDialog'
import CancelContract from '../CancelContract'
import AgreementsTable from '../AgreementsTable'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AssignToOwnOrganization from '../AssignToOwnOrganization'
import moment from 'moment'

const styles = theme => ({
  root: {
    marginTop: 40,
  },
  headline: {
    margin: '36px 0 8px 0',
  },
  editIcon: {
    color: theme.palette.common.black,
  },
  mainInfoText: {
    marginTop: theme.typography.pxToRem(36),
  },
  rejectNoteTitle: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
  },
  bottomActions: {
    display: 'flex',
    marginBottom: 32,
    justifyContent: 'flex-end',
  },
  createAgreementButtonText: {
    paddingLeft: 8,
    fontSize: theme.typography.pxToRem(13),
  },
  tableTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
})

class ContractProfile extends Component {
  state = {
    editContractBaseInfoOpen: false,
    assignToThirdPartyOpen: false,
    acceptDialogOpen: false,
    cancelContractOpen: false,
    assignToOwnOrganizationOpen: false,
    anchorElAssign: null,
  }

  constructor(props) {
    super(props)
    this.rejectRef = React.createRef()
  }

  componentDidMount() {
    const {
      props: {
        fetchContractPermissionsRequest,
        match: {
          params: { contractId },
        },
      },
    } = this
    fetchContractPermissionsRequest(contractId)
    this.props.setNavigationDrawer([
      {
        title: i18n.t('service_desk_web_atoir:mainInfoTitle'),
        anchor: '#main-contract-info',
      },
      {
        title: i18n.t('service_desk_web_atoir:equipment'),
        anchor: '#equipment-table',
      },
      {
        title: i18n.t('service_desk_web_atoir:agreements'),
        anchor: '#agreement-table',
      },
    ])
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetchingPermissions !== this.props.isFetchingPermissions &&
      !this.props.isFetchingPermissions &&
      !this.props.permissionsError
    ) {
      if (canViewContractInfo(this.props.permissions)) {
        this.fetchContractInfo()
      } else {
        this.props.history.push('/404')
      }
    }
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching &&
      !this.props.error
    ) {
      const from = moment(
        `${this.props.contract.year_begin}-${this.props.contract.month_begin}-1`,
      )
        .format('DD MMMM YYYY')
        .substring(3)
      const to = moment(
        `${this.props.contract.year_end}-${this.props.contract.month_end}-1`,
      ).format('MMMM YYYY')
      this.props.getTitleInfo({
        title: i18n.t('service_desk_web_atoir:contractProfileTitle', {
          number: this.props.contract.num_contract,
          from,
          to,
        }),
        OuterButton: canCancelContract(
          this.props.permissions,
          this.props.contract.status,
        ) ? (
          <TextButton onClick={this.toggle('cancelContractOpen', true)}>
            {i18n.t('service_desk_web_atoir:cancelContract')}
          </TextButton>
        ) : null,
        substringLeft: i18n.t(
          `service_desk_web_atoir:${this.props.contract.status}Contract`,
        ),
        status: i18n.t(
          `service_desk_web_atoir:${this.props.contract.status}Contract`,
        ),
        historyChanges: {
          title: i18n.t('service_desk_web_atoir:historyOfChange'),
          link: `${this.props.match.url}/history`,
        },
      })
      this.props.setNavigationDrawer([
        {
          title: i18n.t('service_desk_web_atoir:mainInfoTitle'),
          anchor: '#main-contract-info',
        },
        {
          title: i18n.t('service_desk_web_atoir:equipment'),
          anchor: '#equipment-table',
        },
        {
          title: i18n.t('service_desk_web_atoir:agreements'),
          anchor: '#agreement-table',
        },
        ...(canCreateAgreement(
          this.props.permissions,
          this.props.contract.status,
        )
          ? [
              <ButtonBase
                style={{ display: 'flex' }}
                disableRipple
                disableTouchRipple
                onClick={() =>
                  this.props.history.push(
                    `${this.props.match.url}/agreements/create`,
                  )
                }
              >
                <IconButton
                  centerRipple={false}
                  disableRipple
                  disableTouchRipple
                  style={{ padding: 0 }}
                >
                  <AddCircleOutline color="primary" />
                </IconButton>
                <Typography
                  color="primary"
                  align="left"
                  className={this.props.classes.createAgreementButtonText}
                >
                  {i18n.t('service_desk_web_atoir:createAgreement')}
                </Typography>
              </ButtonBase>,
            ]
          : []),
      ])
    }
    if (
      prevProps.isFetchingEditResponsible !==
        this.props.isFetchingEditResponsible &&
      !this.props.isFetchingEditResponsible &&
      !this.props.editResponsibleError
    ) {
      this.fetchContractInfo()
    }
    if (
      prevProps.isEditingContract !== this.props.isEditingContract &&
      !this.props.isEditingContract &&
      !this.props.editContractError
    ) {
      this.fetchContractInfo()
      this.toggle('editContractBaseInfoOpen', false)()
    }
  }

  fetchContractInfo = () => {
    const {
      props: {
        fetchContract,
        match: {
          params: { contractId },
        },
      },
    } = this
    fetchContract(contractId)
  }

  editClientResponsible = responsibleId => {
    const {
      props: { editContractResponsible, contract },
    } = this
    if (!contract || !responsibleId) {
      return
    }
    editContractResponsible({
      id: contract.id,
      postfix: 'client_responsible',
      data: transformCamelCaseObjectCaseToSnake({ responsibleId }),
    })
  }

  editPerformerResponsible = responsibleId => {
    const {
      props: { editContractResponsible, contract },
    } = this
    if (!contract || !responsibleId) {
      return
    }
    editContractResponsible({
      id: contract.id,
      postfix: 'performer_responsible',
      data: transformCamelCaseObjectCaseToSnake({ responsibleId }),
    })
  }

  toggle = (field, status) => () => {
    this.setState({
      [field]: status,
    })
  }

  showAssignMenu = event => {
    this.setState({ anchorElAssign: event.currentTarget })
  }

  hideAssignMenu = () => {
    this.setState({ anchorElAssign: null })
  }

  editContract = data => {
    const {
      props: { contract, editContract },
    } = this
    editContract({ id: contract.id, data })
  }

  showNoEquipmentDialog = () => {
    this.props.showDialogWithOk(
      i18n.t('error_web_atoir:canNotAssignContractWithoutEquipment'),
    )
  }

  render() {
    const {
      props: {
        classes,
        contract,
        fetchContractPermissionsRequest,
        permissions,
        history,
        match: { url, params },
      },
      state: {
        editContractBaseInfoOpen,
        assignToThirdPartyOpen,
        acceptDialogOpen,
        anchorElAssign,
        cancelContractOpen,
        assignToOwnOrganizationOpen,
      },
      editContract,
      toggle,
      fetchContractInfo,
      editClientResponsible,
      editPerformerResponsible,
      showAssignMenu,
      hideAssignMenu,
      showNoEquipmentDialog,
    } = this
    return (
      <div className={classes.root}>
        {contract && (
          <BreadCrumb to={url}>
            {i18n.t('service_desk_web_atoir:contractProfileTitle', {
              number: contract.num_contract,
              from: `${contract.month_begin}.${contract.year_begin}`,
              to: `${contract.month_end}.${contract.year_end}`,
            })}
          </BreadCrumb>
        )}
        {contract &&
          contract.status === contractsConstants.STATUS_CREATED &&
          contract.reject_note && (
            <Fragment>
              <div style={{ display: 'flex' }}>
                <NotificationImportant
                  color="error"
                  style={{ paddingRight: 8 }}
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.rejectNoteTitle}
                >
                  {i18n.t(
                    'service_desk_web_atoir:performerRejectedContractReAssignOrCancel',
                  )}
                </Typography>
              </div>
              <LongTextExpander
                WrapperProps={{ style: { textWeight: 'bold' } }}
              >
                {contract.reject_note}
              </LongTextExpander>
            </Fragment>
          )}
        <Typography
          id="main-contract-info"
          variant="h5"
          gutterBottom
          className={classes.mainInfoText}
        >
          {i18n.t('service_desk_web_atoir:mainInfoTitle')}
          {contract &&
            permissions &&
            canEditContract(permissions, contract.status) && (
              <IconButton
                className={classes.editIcon}
                onClick={toggle('editContractBaseInfoOpen', true)}
              >
                <Edit fontSize="default" />
              </IconButton>
            )}
          {contract && (
            <EditContractBase
              noResponsible
              onSubmit={editContract}
              open={editContractBaseInfoOpen}
              contract={contract}
              onClose={toggle('editContractBaseInfoOpen', false)}
            />
          )}
        </Typography>
        {contract ? (
          <ContractBaseInfo
            permissions={permissions}
            editClientResponsible={editClientResponsible}
            editPerformerResponsible={editPerformerResponsible}
            classes={{ editIcon: classes.editIcon }}
            contract={contract}
          />
        ) : (
          <DelayedLoader show delay={300} />
        )}
        <div className={classes.bottomActions}>
          {contract &&
            permissions &&
            canRejectContract(permissions, contract.status) && (
              <Fragment>
                <OutlinedButton
                  onClick={() => {
                    this.rejectRef.open()
                  }}
                >
                  {i18n.t('service_desk_web_atoir:reject')}
                </OutlinedButton>
                <RejectContract
                  contract={contract}
                  onFinish={error => {
                    if (!error) {
                      history.replace(popLastSections(url))
                    }
                  }}
                  createRef={ref => {
                    this.rejectRef = ref
                  }}
                />
              </Fragment>
            )}
          {contract &&
            permissions &&
            canAcceptContract(permissions, contract.status) && (
              <Fragment>
                <ContainedButton onClick={toggle('acceptDialogOpen', true)}>
                  {i18n.t('service_desk_web_atoir:takePlace')}
                </ContainedButton>
                <AcceptDialog
                  onFinish={error => {
                    toggle('acceptDialogOpen', false)()
                    if (!error) {
                      fetchContractPermissionsRequest(params.contractId)
                    }
                  }}
                  contract={contract}
                  open={acceptDialogOpen}
                  onClose={toggle('acceptDialogOpen', false)}
                />
              </Fragment>
            )}
          {contract && cancelContractOpen && (
            <CancelContract
              open={cancelContractOpen}
              onClose={toggle('cancelContractOpen', false)}
              contract={contract}
              onFinish={error => {
                toggle('cancelContractOpen', false)()
                if (!error) {
                  fetchContractInfo()
                }
              }}
            />
          )}
          {contract &&
          permissions &&
          canAssignContract(permissions, contract.status) ? (
            <div className={classes.actionBlock}>
              <ContainedButton
                onClick={e => {
                  if (!this.contractTableRef) return
                  if (!this.contractTableRef.hasEquipment()) {
                    showNoEquipmentDialog()
                    return
                  }
                  showAssignMenu(e)
                }}
              >
                {i18n.t('service_desk_web_atoir:assignToThirdParty')}
              </ContainedButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorElAssign}
                open={Boolean(anchorElAssign)}
                onClose={hideAssignMenu}
              >
                <MenuItem
                  onClick={() => {
                    toggle('assignToThirdPartyOpen', true)()
                    toggle('anchorElAssign', null)()
                  }}
                >
                  {i18n.t('service_desk_web_atoir:assignContractFromList')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggle('assignToOwnOrganizationOpen', true)()
                    toggle('anchorElAssign', null)()
                  }}
                >
                  {i18n.t('service_desk_web_atoir:toOwnOrganization')}
                </MenuItem>
              </Menu>
              <AssignToThirdPartyOrganization
                contract={contract}
                excludeOrganizationIds={[contract.cl_organization.id]}
                onFinish={error => {
                  if (!error) {
                    fetchContractInfo()
                    fetchContractPermissionsRequest(contract.id)
                  }
                }}
                onClose={toggle('assignToThirdPartyOpen', false)}
                open={assignToThirdPartyOpen}
                allOrganizations
              />
              <AssignToOwnOrganization
                contract={contract}
                organizationId={contract.cl_organization.id}
                organizationName={contract.cl_organization.short_name}
                onFinish={(error, openAccept) => {
                  if (!error) {
                    fetchContractInfo()
                    fetchContractPermissionsRequest(contract.id)
                    if (openAccept) {
                      toggle('acceptDialogOpen', true)()
                    }
                  }
                }}
                onClose={toggle('assignToOwnOrganizationOpen', false)}
                open={assignToOwnOrganizationOpen}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div id="equipment-table">
          {contract && contract.id === params.contractId && (
            <Fragment>
              <Typography className={classes.tableTitle} variant="h5">
                {i18n.t('service_desk_web_atoir:equipments')}
              </Typography>
              <ContractEquipmentTable
                customRef={ref => {
                  this.contractTableRef = ref
                }}
                contract={contract}
                permissions={permissions}
              />
            </Fragment>
          )}
        </div>
        <div id="agreement-table">
          {contract && contract.id === params.contractId && (
            <Fragment>
              <Typography
                className={classes.tableTitle}
                gutterBottom
                variant="h5"
              >
                {i18n.t('service_desk_web_atoir:agreements')}
              </Typography>
              <AgreementsTable contract={contract} />
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

ContractProfile.propTypes = {
  fetchContract: PropTypes.func.isRequired,
}

export default withStyles(styles)(ContractProfile)
