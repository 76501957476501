import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AsClient from './AsClient'
import {
  fetchInventoryOffersClient,
  stopInventoryProjectAuction,
  confirmOfferInventoryProject,
} from '../../../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  infoProject: state.inventory.project,
  offers: state.inventory.fetchInventoryOffersClient.offers,
  isFetchOffers: state.inventory.fetchInventoryOffersClient.isFetchOffers,
  isOffer: state.inventory.offerInventoryProject.isOffer,
  isOfferSuccess: state.inventory.offerInventoryProject.offerSuccessfully,
  isCancelOffer: state.inventory.cancelOfferInventoryProject.isCancel,
  isCancelOfferSuccess:
    state.inventory.cancelOfferInventoryProject.isCancelSuccessfully,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOffer: fetchInventoryOffersClient,
      stopAuction: stopInventoryProjectAuction,
      confirmOffer: confirmOfferInventoryProject,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsClient),
)
