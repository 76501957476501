import React from 'react'
import classNames from 'classnames'

import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import { withStyles } from '@material-ui/core/styles/index'
import Drawer from '@material-ui/core/Drawer'
import Fade from '@material-ui/core/Fade'

import config from '../../../../config'

import ListRenderer from './ListRenderer'

const drawerWidth = config.left_drawer_width

const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer,
    overflow: 'hidden',
    position: 'absolute',
  },
  paperAnchorLeft: {
    transition: 'all 0.3s ease-in-out',
    left: 0,
  },
  paperAnchorLeftClosed: {
    left: -240,
  },
})

const Box = styled.div`
  ${spacing}
`

function DrawerCommon({ classes, open }) {
  return (
    <Drawer
      data-cy="menuDrawer"
      classes={{
        paper: classes.drawerPaper,
        paperAnchorLeft: classNames(classes.paperAnchorLeft, {
          [classes.paperAnchorLeftClosed]: !open,
        }),
      }}
      variant="permanent"
    >
      <Box mt={8}>
        <Fade in={open} timeout={500}>
          <ListRenderer collapsed={false} noImage />
        </Fade>
      </Box>
    </Drawer>
  )
}

export default withStyles(styles)(DrawerCommon)
