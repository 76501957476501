import { takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/buttonsActions'
import * as constants from '../constants/actionTypes/buttons'
import * as api from './api'
import { createDefaultSaga } from '../helpers/api'

export function* watchCreateButtonSaga() {
  yield takeLatest(
    constants.CREATE_BUTTON_REQUEST,
    createDefaultSaga(
      payload => api.createButtonRequestApi(payload),
      result => actions.createButtonSuccess(result),
      code => actions.createButtonFailure(code),
      true,
    ),
  )
}

export function* watchEditButtonSaga() {
  yield takeLatest(
    constants.EDIT_BUTTON_REQUEST,
    createDefaultSaga(
      payload => api.editButtonRequestApi(payload),
      result => actions.editButtonSuccess(result),
      code => actions.editButtonFailure(code),
      true,
    ),
  )
}

export function* watchGetButtonsSaga() {
  yield takeLatest(
    constants.GET_BUTTONS_REQUEST,
    createDefaultSaga(
      payload => api.getButtonsRequestApi(payload),
      result =>
        actions.getButtonsSuccess(
          result.buttons.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      code => actions.getButtonsFailure(code),
      false,
    ),
  )
}

export function* watchDeleteButtonSaga() {
  yield takeLatest(
    constants.DELETE_BUTTON_REQUEST,
    createDefaultSaga(
      payload => api.deleteButtonRequestApi(payload),
      result => actions.deleteButtonSuccess(result),
      code => actions.deleteButtonFailure(code),
      false,
    ),
  )
}

export function* watchGetButtonSaga() {
  yield takeLatest(
    constants.GET_BUTTON_REQUEST,
    createDefaultSaga(
      payload => api.getButtonRequestApi(payload),
      result => actions.getButtonSuccess(result.button),
      code => actions.getButtonFailure(code),
      true,
    ),
  )
}

export function* watchGetButtonsHistorySaga() {
  yield takeLatest(
    constants.GET_BUTTONS_HISTORY_REQUEST,
    createDefaultSaga(
      payload => api.getButtonsHistoryRequestApi(payload),
      (result, payload) =>
        actions.getButtonsHistorySuccess({
          history: result.customActions,
          end:
            payload.query.limit &&
            result.customActions.length < payload.query.limit,
        }),
      code => actions.getButtonsHistoryFailure(code),
      false,
    ),
  )
}

export function* watchExecuteSDCallButtonSaga() {
  yield takeLatest(
    constants.EXECUTE_SD_CALL_BUTTON_REQUEST,
    createDefaultSaga(
      payload => api.executeSDCallButtonRequestApi(payload),
      result => actions.executeSDCallButtonSuccess(result),
      code => actions.executeSDCallButtonFailure(code),
      true,
    ),
  )
}

export function* watchGetSDCallButtonsSaga() {
  yield takeLatest(
    constants.GET_SD_CALL_BUTTONS_REQUEST,
    createDefaultSaga(
      payload => api.getSDCallButtonsRequestApi(payload),
      result => actions.getSDCallButtonsSuccess(result.buttons),
      code => actions.getSDCallButtonsFailure(code),
      false,
    ),
  )
}
