import React, { Fragment, Component } from 'react'
import isEmpty from 'is-empty'
import PropTypes from 'prop-types'

// ---material--
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'

// ---components--
import FiltersListOrders from './FiltersListOrders'
import TableOrders from './TableOrders'
import Loader from '../../../../Loader'
import DialogLoader from '../../../../DialogLoader'
import ConfirmEditEngineerOrder from '../ConfirmEditEngineerOrder'

import i18next from '../../../../../i18n/i18n'
import { withStyles } from '@material-ui/core/styles/index'

const styles = theme => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  tableCellBig: {
    width: '15%',
  },
  tableCellSmall: {
    width: '8%',
  },
  tableCell: {
    width: 'auto',
  },
})

class InventoryListOrders extends Component {
  state = {
    rowsPerPage: 15,
    page: 0,
    strParams: '',
    selectedOrder: null,
  }

  componentDidMount() {
    this.updateFilters()
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        isRemoveOrder,
        isRemoveOrderSuccess,
        isEditEngineerOrder,
        isEditEngineerOrderSuccess,
      },
    } = this

    if (
      prevProps.isRemoveOrder !== isRemoveOrder &&
      prevProps.isRemoveSuccess !== isRemoveOrderSuccess &&
      !isRemoveOrder &&
      isRemoveOrderSuccess
    ) {
      this.updateFilters()
    }

    if (
      prevProps.isEditEngineerOrder !== isEditEngineerOrder &&
      prevProps.isEditEngineerOrderSuccess !== isEditEngineerOrderSuccess &&
      !isEditEngineerOrder &&
      isEditEngineerOrderSuccess
    ) {
      this.updateFilters()
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      this.updateFilters()
    })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, () => {
      this.updateFilters()
    })
  }

  handleFilters = strParams => {
    this.setState(
      {
        strParams,
      },
      () => {
        this.updateFilters()
      },
    )
  }

  updateFilters = () => {
    const {
      props: {
        fetchOrders,
        fetchOrdersFilters,
        fetchOrdersCount,
        match: { params },
      },
      state: { rowsPerPage, page, strParams },
    } = this
    const paramsOrders = isEmpty(strParams) ? '' : `&${strParams}`
    const paramsOrdersCount = isEmpty(strParams) ? '' : `?${strParams}`
    fetchOrders(
      params.projectId,
      `?limit=${rowsPerPage}&offset=${page * rowsPerPage}${paramsOrders}`,
    )
    fetchOrdersCount(params.projectId, paramsOrdersCount)
    fetchOrdersFilters(params.projectId)
  }

  handleRemoveOrder = orderId => {
    const {
      props: { match, removeOrder },
    } = this
    removeOrder(match.params.projectId, orderId)
  }

  handleOpenConfirmEditEngineerOrder = order => () => {
    this.setState({
      selectedOrder: order,
    })
  }

  changeEngineer = (orderId, data) => {
    const {
      props: { editEngineerOrderInventory, match },
    } = this
    editEngineerOrderInventory(match.params.projectId, orderId, data)
    this.setState({
      selectedOrder: null,
    })
  }

  render() {
    const {
      props: {
        isFetchFilters,
        filtersOrders,
        classes,
        isFetchOrders,
        ordersToLocations,
        isFetchOrdersCount,
        ordersCount,
        isRemoveOrder,
        isEditEngineerOrder,
        inventoryProjectPermissions,
      },
      state: { rowsPerPage, page, strParams, selectedOrder },
    } = this

    return (
      <Fragment>
        <Typography variant="subheading" align="left">
          {i18next.t('inventory_web_atoir:listOutfits')}
        </Typography>
        {isEmpty(strParams) && isEmpty(ordersToLocations) ? (
          <Typography align="left" variant="body2">
            {i18next.t('inventory_web_atoir:noOrders')}
          </Typography>
        ) : (
          <Fragment>
            <FiltersListOrders
              isFetch={isFetchFilters}
              filters={filtersOrders}
              classes={classes}
              handleFilters={this.handleFilters}
            />
            {isFetchOrders ? (
              <Loader />
            ) : (
              <Fragment>
                {!isEmpty(strParams) && isEmpty(ordersToLocations) ? (
                  <Typography align="center" variant="body2">
                    {i18next.t('shared_web_atoir:noDataForThisParametrs')}
                  </Typography>
                ) : (
                  ordersToLocations.map(_location => (
                    <TableOrders
                      key={_location.id}
                      location={_location}
                      classes={classes}
                      removeItem={this.handleRemoveOrder}
                      editEngineerOrder={
                        this.handleOpenConfirmEditEngineerOrder
                      }
                      permissions={inventoryProjectPermissions}
                    />
                  ))
                )}
              </Fragment>
            )}
            {isFetchOrdersCount ? (
              <Loader />
            ) : (
              <TablePagination
                component="div"
                colSpan={3}
                count={ordersCount}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
                labelDisplayedRows={({
                  from: fromPage,
                  to: toPage,
                  count: countPage,
                }) =>
                  i18next.t('shared_web_atoir:labelDisplayedRows', {
                    fromPage,
                    toPage,
                    countPage,
                  })
                }
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPageOptions={[15, 25, 50]}
              />
            )}
          </Fragment>
        )}
        <ConfirmEditEngineerOrder
          open={!isEmpty(selectedOrder)}
          handleClose={this.handleOpenConfirmEditEngineerOrder(null)}
          isLoading={isFetchOrders}
          order={selectedOrder}
          changeEngineer={this.changeEngineer}
        />
        <DialogLoader showDialogLoader={isRemoveOrder || isEditEngineerOrder} />
      </Fragment>
    )
  }
}

InventoryListOrders.propTypes = {
  isFetchFilters: PropTypes.bool.isRequired,
  filtersOrders: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  isFetchOrders: PropTypes.bool.isRequired,
  ordersToLocations: PropTypes.array.isRequired,
  isFetchOrdersCount: PropTypes.bool.isRequired,
  ordersCount: PropTypes.number.isRequired,
  isRemoveOrder: PropTypes.bool.isRequired,
  isRemoveOrderSuccess: PropTypes.bool.isRequired,
  isEditEngineerOrder: PropTypes.bool.isRequired,
  isEditEngineerOrderSuccess: PropTypes.bool.isRequired,
}

export default withStyles(styles)(InventoryListOrders)
