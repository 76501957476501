import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FormEditOrderInfo from './FormEditOrderInfo'
import { editOrderInfo } from '../../../../../../actions/serviceDesk'

const mapStateToProps = state => ({
  isFetching: state.serviceDesk.editOrderInfo.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editOrderInfo,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditOrderInfo),
)
