import { takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/emailTemplatesActions'
import * as constants from '../constants/actionTypes/emailTemplates'
import * as api from './api'
import { createDefaultSaga } from '../helpers/api'

export function* watchGetEmailTemplatesSaga() {
  yield takeLatest(
    constants.GET_EMAIL_TEMPLATES_REQUEST,
    createDefaultSaga(
      payload => api.getEmailTemplatesRequestApi(payload),
      result =>
        actions.getEmailTemplatesSuccess(
          result.templates.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      code => actions.getEmailTemplatesFailure(code),
      false,
    ),
  )
}

export function* watchGetEmailTemplateSaga() {
  yield takeLatest(
    constants.GET_EMAIL_TEMPLATE_REQUEST,
    createDefaultSaga(
      payload => api.getEmailTemplateRequestApi(payload),
      result => actions.getEmailTemplateSuccess(result.template),
      code => actions.getEmailTemplateFailure(code),
      true,
    ),
  )
}

export function* watchCreateEmailTemplateSaga() {
  yield takeLatest(
    constants.CREATE_EMAIL_TEMPLATE_REQUEST,
    createDefaultSaga(
      payload => api.createEmailTemplateRequestApi(payload),
      result => actions.createEmailTemplateSuccess(result),
      code => actions.createEmailTemplateFailure(code),
      true,
    ),
  )
}

export function* watchEditEmailTemplateSaga() {
  yield takeLatest(
    constants.EDIT_EMAIL_TEMPLATE_REQUEST,
    createDefaultSaga(
      payload => api.editEmailTemplateRequestApi(payload),
      result => actions.editEmailTemplateSuccess(result),
      code => actions.editEmailTemplateFailure(code),
      true,
    ),
  )
}

export function* watchDeleteEmailTemplateSaga() {
  yield takeLatest(
    constants.DELETE_EMAIL_TEMPLATE_REQUEST,
    createDefaultSaga(
      payload => api.deleteEmailTemplateRequestApi(payload),
      result => actions.deleteEmailTemplateSuccess(result),
      code => actions.deleteEmailTemplateFailure(code),
      false,
    ),
  )
}
