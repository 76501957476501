import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import LinkIcon from '@material-ui/icons/Link'

import i18next from '../../../i18n/i18n'

const style = {
  container: {
    height: 0,
    position: 'relative',
  },
  indicatorPlacement: {
    position: 'absolute',
    top: -4,
    left: -4,
    color: 'rgb(25, 118, 210)',
  },
}

function EquipmentSuggestionsIndicator({ show, classes }) {
  return (
    <React.Fragment>
      {show && (
        <span className={classes.container}>
          <React.Fragment>
            {'\u00A0\u00A0\u00A0\u00A0\u00A0'}
            <Tooltip
              className={classes.indicatorPlacement}
              title={i18next.t('equipment_web_atoir:suggestionsFoundTooltip')}
            >
              <LinkIcon />
            </Tooltip>
          </React.Fragment>
        </span>
      )}
    </React.Fragment>
  )
}

export default withStyles(style)(EquipmentSuggestionsIndicator)
