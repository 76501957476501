import React from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Fade from '@material-ui/core/Fade'

import {
  useAppDispatch,
  appActions,
  appOptions,
  useAppState,
} from '../appContext'

function CollapsibleDrawerTurnOn() {
  const { drawerMode } = useAppState()

  const dispatch = useAppDispatch()

  const showCollapsibleDrawer = () => {
    dispatch(appActions.setDrawerMode(appOptions.drawerMode.collapsible))
  }
  return (
    <React.Fragment>
      <Fade in={drawerMode === appOptions.drawerMode.common}>
        <IconButton
          onClick={showCollapsibleDrawer}
          color="inherit"
          data-cy="mainMenuBtn"
        >
          <MenuIcon />
        </IconButton>
      </Fade>
    </React.Fragment>
  )
}

export default CollapsibleDrawerTurnOn
