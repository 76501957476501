import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import OrganizationFilters from './OrganizationFilters'
import OrganizationsTable from './OrganizationsTable'
import i18next from '../../../i18n/i18n'

const styles = {
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}

const inintialFilters = {
  limit: 10,
  offset: 0,
}

class ChooseThirdPartyOrganization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: inintialFilters,
      page: 0,
      rowsPerPage: 10,
      order: 'ASC',
      orderBy: null,
    }
  }

  componentDidMount = () => {
    this.fetchOrganizations()
  }

  fetchOrganizations = () => {
    const {
      props: { clientOrganizationId, repairMode, side },
    } = this
    const sort = { sort: '[short_name=ASC]' }
    if (this.state.order && this.state.orderBy) {
      sort.sort = `[${this.state.orderBy}=${this.state.order}]`
    }
    if (repairMode) {
      this.props.fetchServiceOrganizations(
        _.omitBy(
          {
            organizationId: clientOrganizationId,
            side,
            limit: this.state.rowsPerPage,
            offset: this.state.rowsPerPage * this.state.page,
            query: this.state.filters.name,
          },
          f => !f,
        ),
      )
    } else {
      this.props.fetchServiceOrganizations(
        _.omitBy(
          {
            exclude_restricted_by_contragents: true,
            contragents_first: clientOrganizationId,
            ...this.state.filters,
            ...sort,
            exclude_organization_ids:
              this.props.excludeOrganizationIds &&
              JSON.stringify(this.props.excludeOrganizationIds),
            exclude_organization_by_service_call_id: this.props.serviceCallId,
            limit: this.state.rowsPerPage,
            offset: this.state.rowsPerPage * this.state.page,
            block: 'sd_extended',
          },
          f => !f,
        ),
      )
      this.props.fetchServiceOrganizationsCount({
        ..._.omitBy(
          {
            exclude_restricted_by_contragents: true,
            contragents_first: clientOrganizationId,
            ...this.state.filters,
            exclude_organization_ids:
              this.props.excludeOrganizationIds &&
              JSON.stringify(this.props.excludeOrganizationIds),
            exclude_organization_by_service_call_id: this.props.serviceCallId,
            block: 'sd_extended',
            ..._.pick(this.state.filters, [
              'organization_id',
              'city_id',
              'name',
            ]),
          },
          f => !f,
        ),
      })
    }
  }

  fetchOrganizationsWithFilters = filters => {
    const organizationId = (filters.organizationId || '')
      .replace(/\s-\s/g, '')
      .replace(/\s*?/g, '')
      .trim()
    this.setState(
      state => ({
        page: 0,
        filters: {
          ...state.filters,
          ...filters,
          offset: 0,
          organization_id: organizationId,
          city_name: filters.city ? filters.city.name : '',
        },
      }),
      this.fetchOrganizations,
    )
  }

  onRequestSort = (event, field) => {
    const orderBy = field
    let order = 'DESC'

    if (this.state.orderBy === field && this.state.order === 'DESC') {
      order = 'ASC'
    } else if (this.state.orderBy === field && this.state.order === 'ASC') {
      order = null
    }
    this.setState(
      {
        orderBy,
        order,
      },
      this.fetchOrganizations,
    )
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.fetchOrganizations)
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, this.fetchOrganizations)
  }

  render() {
    const {
      state: { order, orderBy, page, rowsPerPage, city },
      props: {
        classes,
        selected,
        onOrganizationChanged,
        serviceOrganizations,
        isFetchingServiceOrganizations,
        isFetchingServiceOrganizationsCount,
        serviceOrganizationsCount,
        excludeOrganizations,
        repairMode,
      },
      fetchOrganizationsWithFilters,
      handleChangePage,
      handleChangeRowsPerPage,
      onRequestSort,
    } = this
    return (
      <>
        <div className={classNames({ [classes.defaultPadding]: !repairMode })}>
          <OrganizationFilters
            key={city}
            city={city}
            onSubmit={fetchOrganizationsWithFilters}
            repairMode={repairMode}
          />
        </div>
        {!repairMode && (
          <>
            <br />
            <Divider />
            <Divider />
            <Divider />
            <br />
            <div className={classes.defaultPadding}>
              <Typography variant="h6">
                {i18next.t('service_desk_web_atoir:listOfOrganizationsTitle')}
              </Typography>
            </div>
          </>
        )}
        {repairMode && (
          <>
            <Divider />
          </>
        )}
        <div
          style={{
            height: 'calc(100% - 64px)',
            'overflow-y': 'auto',
          }}
        >
          <OrganizationsTable
            selected={selected}
            onRequestSort={onRequestSort}
            onPageChanged={handleChangePage}
            onSelectedChanged={onOrganizationChanged}
            onRowsPerPageChanged={handleChangeRowsPerPage}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            page={page}
            serviceOrganizationsCount={serviceOrganizationsCount}
            isFetchingServiceOrganizationsCount={
              isFetchingServiceOrganizationsCount
            }
            organizations={(serviceOrganizations || []).filter(o =>
              _.indexOf(excludeOrganizations, o.id),
            )}
            isFetching={!!isFetchingServiceOrganizations}
          />
        </div>
      </>
    )
  }
}

ChooseThirdPartyOrganization.defaultProps = {
  serviceCallId: null,
  excludeOrganizationIds: null,
  serviceOrganizations: [],
}

ChooseThirdPartyOrganization.propTypes = {
  onOrganizationChanged: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  serviceCallId: PropTypes.string,
  excludeOrganizationIds: PropTypes.array,
  serviceOrganizations: PropTypes.array,
}

export default withStyles(styles)(ChooseThirdPartyOrganization)
