import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import isEmpty from 'is-empty'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import AddIcon from '@material-ui/icons/Add'

import i18next from '../../../../../i18n/i18n'

// ---components---
import SecondaryButton from '../../../../../widgets/Buttons/SecondaryButton'
import Loader from '../../../../Loader'
import DialogLoader from '../../../../DialogLoader'
import SimpleDialog from '../../../../Other/SimpleDialog'
import ConfirmInventoryProjectPerformer from '../../ConfirmInventoryProjectPerformer'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  section: {
    marginBottom: '40px',
  },
  linkText: {
    color: theme.palette.textSecondary.main,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
  sectionContainer: {
    marginBottom: '20px',
  },
  rootDiv: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
})

class InventoryListEngineersInWork extends Component {
  state = {
    openDialogDelete: false,
    engineerId: null,
    openDialogConfirm: false,
  }

  componentDidMount() {
    const {
      props: { inventoryProject, match, fetchEngineersInventory },
    } = this

    if (inventoryProject && inventoryProject.performer_organization_id) {
      fetchEngineersInventory(
        inventoryProject.performer_organization_id,
        match.params.projectId,
      )
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        isRemoveEngineerSuccess,
        isRemoveEngineer,
        isEditEngineers,
        isEditEngineersSuccess,
        inventoryProject,
        match,
        fetchEngineersInventory,
      },
    } = this
    if (
      isRemoveEngineerSuccess !== prevProps.isRemoveEngineerSuccess &&
      isRemoveEngineer !== prevProps.isRemoveEngineer &&
      !isRemoveEngineer &&
      isRemoveEngineerSuccess
    ) {
      fetchEngineersInventory(
        inventoryProject.performer_organization_id,
        match.params.projectId,
      )
    }
    if (
      isEditEngineersSuccess !== prevProps.isEditEngineersSuccess &&
      isEditEngineers !== prevProps.isEditEngineers &&
      !isEditEngineers &&
      isEditEngineersSuccess
    ) {
      fetchEngineersInventory(
        inventoryProject.performer_organization_id,
        match.params.projectId,
      )
    }
  }

  handleOpenDialogDelete = (flag, id) => () => {
    const engineerId = flag ? id : null
    this.setState({
      openDialogDelete: flag,
      engineerId,
    })
  }

  handleDeleteEngineer = () => {
    const {
      props: { inventoryProject, match, removeEngineerInventory },
      state: { engineerId },
    } = this
    removeEngineerInventory(
      inventoryProject.performer_organization_id,
      match.params.projectId,
      engineerId,
    )
    this.setState({
      openDialogDelete: false,
      engineerId: null,
    })
  }

  handleOpenDialogConfirm = flag => () => {
    const {
      props: {
        match,
        fetchBoundOrganizationUsers,
        fetchEngineersInventory,
        inventoryProject,
      },
    } = this
    if (flag && !isEmpty(inventoryProject)) {
      fetchBoundOrganizationUsers(inventoryProject.performer_organization_id)
      fetchEngineersInventory(
        inventoryProject.performer_organization_id,
        match.params.projectId,
      )
    }
    this.setState({
      openDialogConfirm: flag,
    })
  }

  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  render() {
    const {
      getDividerGrid,
      state: { openDialogDelete, openDialogConfirm },
      props: {
        engineers,
        isFetchEngineers,
        classes,
        inventoryProject,
        isRemoveEngineer,
        match,
        inventoryProjectPermissions,
      },
    } = this
    const noEngineersText =
      `${i18next.t('inventory_web_atoir:dataSpecialistsNumberOutfits')}: ` +
      `${i18next.t('inventory_web_atoir:noAppointed')}`
    const total = i18next.t('shared_web_atoir:total').toUpperCase()
    const totalEquipment = _.sumBy(engineers, 'IPJEFoundCount')
    const totalIPJCountDone = _.sumBy(engineers, 'IPJCountDone')
    const totalIPJCountAll = _.sumBy(engineers, 'IPJCountAll')
    return (
      <Fragment>
        <div className={classes.rootDiv}>
          <Grid container className={classes.sectionContainer}>
            <Grid container alignItems="baseline">
              <Grid item xs={9}>
                <Typography variant="subheading" align="left">
                  {i18next.t(
                    'inventory_web_atoir:listSpecialistsEngagedProject',
                  )}
                </Typography>
              </Grid>
              {!isEmpty(inventoryProjectPermissions) &&
                inventoryProjectPermissions.create_inventory_project_user && (
                  <Grid item xs={3}>
                    <SecondaryButton
                      onClick={this.handleOpenDialogConfirm(true)}
                    >
                      <AddIcon />
                      {`${i18next.t('inventory_web_atoir:addSpecialist')}`}
                    </SecondaryButton>
                  </Grid>
                )}
            </Grid>
            {getDividerGrid()}
            {inventoryProject && inventoryProject.status === 'inwork' ? (
              <Fragment>
                {isFetchEngineers ? (
                  <Loader />
                ) : (
                  <Fragment>
                    {isEmpty(engineers) ? (
                      <Typography align="left" variant="body2">
                        {noEngineersText}
                      </Typography>
                    ) : (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {i18next.t('user_profile_web_atoir:userFullName')}
                            </TableCell>
                            <TableCell>
                              {i18next.t(
                                'organizations_web_atoir:contactPhone',
                              )}
                            </TableCell>
                            <TableCell>
                              {i18next.t(
                                'inventory_web_atoir:dataSpecialistsNumberOutfits',
                              )}
                            </TableCell>
                            <TableCell>
                              {i18next.t('inventory_web_atoir:unitsEquipment')}
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {engineers.map(_engineer => {
                            const middleName =
                              _engineer.middle_name === 'none'
                                ? ''
                                : _engineer.middle_name
                            const phone = isEmpty(_engineer.phone)
                              ? i18next.t('user_profile_web_atoir:noData')
                              : _engineer.phone
                            return (
                              <TableRow key={_engineer.id}>
                                <TableCell>
                                  <Typography
                                    variant="body2"
                                    align="left"
                                    className={classes.linkText}
                                  >
                                    {_engineer.first_name} {_engineer.last_name}{' '}
                                    {middleName}
                                  </Typography>
                                </TableCell>
                                <TableCell>{phone}</TableCell>
                                <TableCell>
                                  {_engineer.IPJCountDone}/
                                  {_engineer.IPJCountAll}
                                </TableCell>
                                <TableCell>
                                  {_engineer.IPJEFoundCount}
                                </TableCell>
                                <TableCell>
                                  {_engineer.can_be_deleted &&
                                    inventoryProjectPermissions.delete_inventory_project_user && (
                                      <IconButton
                                        onClick={this.handleOpenDialogDelete(
                                          true,
                                          _engineer.id,
                                        )}
                                        className={classes.button}
                                        aria-label="Delete"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    )}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell />
                            <TableCell>{total}:</TableCell>
                            <TableCell>
                              {totalIPJCountDone}/{totalIPJCountAll}
                            </TableCell>
                            <TableCell>{totalEquipment}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Typography align="left" variant="body2">
                {noEngineersText}
              </Typography>
            )}
          </Grid>
          {!isEmpty(inventoryProjectPermissions) &&
            inventoryProjectPermissions.create_inventory_project_user && (
              <ConfirmInventoryProjectPerformer
                open={openDialogConfirm}
                handleClose={this.handleOpenDialogConfirm(false)}
                organizationId={inventoryProject.performer_organization_id}
                projectId={match.params.projectId}
                title={i18next.t(
                  'inventory_web_atoir:confirmInventoryProjectAsPerformerDialogTitle',
                )}
                submitButtonTitle={i18next.t(
                  'inventory_web_atoir:setOwnEngineers',
                )}
              />
            )}
          <DialogLoader open={isRemoveEngineer} />
          <SimpleDialog
            isOpen={openDialogDelete}
            handleClose={this.handleOpenDialogDelete(false)}
            handlePositive={this.handleDeleteEngineer}
            handleNegative={this.handleOpenDialogDelete(false)}
            positiveText={i18next.t('shared_web_atoir:delete')}
            negativeText={i18next.t('shared_web_atoir:cancel')}
            title=""
            content={i18next.t('inventory_web_atoir:deleteSpecialistProject')}
          />
        </div>
      </Fragment>
    )
  }
}

InventoryListEngineersInWork.propTypes = {
  engineers: PropTypes.object.isRequired,
  isFetchEngineers: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  inventoryProject: PropTypes.object.isRequired,
}

export default withStyles(styles)(InventoryListEngineersInWork)
