import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Auth from './Auth'
import { refreshTokens, logout } from '../../actions/loginActions'
import { redirectUserToHomePage } from '../../actions/appActions'
import { fetchUserOrganizationFunctionalsRequest } from '../../actions/organizationsActions'

const mapStateToProps = state => ({
  shouldRedirectToHome: state.app.shouldRedirectToHome,
  isLoggedIn: localStorage.getItem('loggedIn'),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      refreshTokens,
      redirectUserToHomePage,
      fetchUserOrganizationFunctionalsRequest,
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))
