import React from 'react'
import FormControl from '@material-ui/core/FormControl'

import { URL } from '../../../../middlewares/api'
import { sortStringify } from '../../../../helpers/queryHelper'

import AutocompleteLimitedAuto from '../../../../widgets/AutocompleteLimitedAuto'

import i18next from '../../../../i18n/i18n'

const ContragentSelector = ({ onChange, organizationId }) => {
  const onChangeHandler = contragent => {
    const id = _.get(contragent, 'id') || ''
    onChange(id)
  }
  return (
    <FormControl fullWidth required>
      <AutocompleteLimitedAuto
        placeholder={i18next.t('equipment_web_atoir:selectOrganizationName')}
        sourceUrl={`${URL}/organizations/${organizationId}/contragents/not_agent`}
        query={sortStringify({ short_name: 'ASC' })}
        getItemDisplayName={i => i.short_name}
        getItemValue={i => i.id}
        onChange={onChangeHandler}
        limit={50}
        requestResultTransformerToArray={r => r.organizations}
        transformInputToQuery={query => ({ query })}
        menuListProps={{ style: { maxHeight: 360 } }}
      />
    </FormControl>
  )
}

export default ContragentSelector
