export const firstSelect = [
  { value: 'Заявка', label: 'Заявка', scope: ['subject', 'body'] },
]

export const secondSelect = [
  {
    value: 'Внутренний_номер_заявки',
    label: 'Внутренний номер заявки',
    scope: ['subject', 'body'],
  },
  {
    value: 'Внешний_номер_заявки',
    label: 'Внешний номер заявки',
    scope: ['subject', 'body'],
  },
  {
    value: 'Дата_создания',
    label: 'Дата создания',
    scope: ['subject', 'body'],
  },
  { value: 'Статус', label: 'Статус', scope: ['subject', 'body'] },
  {
    value: 'Наименование_оборудования',
    label: 'Наименование оборудования',
    scope: ['subject', 'body'],
  },
  {
    value: 'Наименование_собственника_оборудования',
    label: 'Наименование собственника оборудования',
    scope: ['subject', 'body'],
  },
  {
    value: 'Адрес_собственника_оборудования',
    label: 'Адрес собственника оборудования',
    scope: ['subject', 'body'],
  },
  {
    value: 'Желаемый_период_ремонта',
    label: 'Желаемый период ремонта',
    scope: ['subject', 'body'],
  },
  {
    value: 'Место_ремонта',
    label: 'Место ремонта',
    scope: ['subject', 'body'],
  },
  {
    value: 'Последний_прикрепленный_файл',
    label: 'Последний прикрепленный файл',
    scope: ['body'],
  },
  { value: 'Все_файлы_заявки', label: 'Все файлы заявки', scope: ['body'] },
  { value: 'ЗН', label: 'Заказ-наряд', scope: ['subject', 'body'] },
  {
    value: 'Комментарий_к_заявке',
    label: 'Комментарий к заявке',
    scope: ['subject', 'body'],
  },
]

export const thirdSelect = [
  { value: 'Статус', label: 'Статус', scope: ['subject', 'body'] },
  {
    value: 'Дата_создания',
    label: 'Дата создания',
    scope: ['subject', 'body'],
  },
  {
    value: 'Последний_прикрепленный_файл',
    label: 'Последний прикрепленный файл',
    scope: ['body'],
  },
  { value: 'Все_файлы', label: 'Все файлы', scope: ['body'] },
]

export const templateParamScheme = {
  Заявка: {
    Внешний_номер_заявки: true,
    Внутренний_номер_заявки: true,
    Дата_создания: true,
    Статус: true,
    Наименование_оборудования: true,
    Наименование_собственника_оборудования: true,
    Адрес_собственника_оборудования: true,
    Желаемый_период_ремонта: true,
    Место_ремонта: true,
    Последний_прикрепленный_файл: true,
    Все_файлы_заявки: true,
    Комментарий_к_заявке: true,
    ЗН: {
      Статус: true,
      Дата_создания: true,
      Последний_прикрепленный_файл: true,
      Все_файлы: true,
    },
  },
}
