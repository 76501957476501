import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import NotificationImportant from '@material-ui/icons/NotificationImportant'

const styles = theme => ({
  icon: {
    size: 24,
    marginRight: theme.spacing(1),
  },
  tagName: {
    wordBreak: 'break-all',
  },
})

function Tag(props) {
  const iconStyle = {
    size: 24,
    color: props.color || 'rgba(0, 0, 0, 0.54)',
  }
  return (
    <Fragment>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item>
          <NotificationImportant style={iconStyle} />
        </Grid>
        <Grid item xs>
          <Typography
            variant="caption"
            className={props.classes.tagName}
            color="textSecondary"
          >
            {props.name}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

Tag.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default withStyles(styles)(Tag)
