import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditEquipmentLocationComponent from './EditEquipmentLocationComponent'
import { updateEquipmentDetails } from '../../../../actions/equipmentActions'
import {
  hideDialogLoader,
  showDialogLoader,
} from '../../../../actions/utilsActions'

import { receivedInfoMessage } from '../../../../actions/appActions'

const mapStateToProps = state => ({
  isUpdatingEquipmentDetails:
    state.equipment.updateEquipmentDetails.isUpdatingEquipmentDetails,
  error: state.equipment.updateEquipmentDetails.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showDialogLoader,
      hideDialogLoader,
      updateEquipmentDetails,
      receivedInfoMessage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditEquipmentLocationComponent),
)
