import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Loader from '../../Loader'
import DateInput from '../../../widgets/DateInput'
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import Input from '@material-ui/core/Input/Input'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Table from '@material-ui/core/Table/Table'
import TablePagination from '@material-ui/core/TablePagination/TablePagination'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import FormControl from '@material-ui/core/FormControl/FormControl'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

let LogsTableToolbar = props => {
  const { classes, handleChange } = props
  const handleChangeDate = field => day => {
    if (day === '' && day === undefined) {
      props.handleChange(null)
      return
    }

    handleChange(field, day)
  }
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">
          {i18next.t('equipment_web_atoir:equipmentChangesJournal')}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <DateInput
          classes={classes}
          label={i18next.t('shared_web_atoir:unitFrom')}
          handleChange={handleChangeDate('date_from')}
        />
        <DateInput
          classes={classes}
          label={i18next.t('shared_web_atoir:unitTo')}
          handleChange={handleChangeDate('date_to')}
        />
      </div>
    </Toolbar>
  )
}

LogsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

LogsTableToolbar = withStyles(toolbarStyles)(LogsTableToolbar)

class LogsTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      fields: { type },
      fieldChanged,
    } = this.props
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <FormControl>
              <InputLabel htmlFor="type-helper">
                {i18next.t('equipment_web_atoir:equipmentChangesType')}
              </InputLabel>
              <Select
                value={type}
                onChange={fieldChanged('type')}
                input={<Input name="type" id="type-helper" />}
              >
                <MenuItem value="">
                  <em />
                </MenuItem>
                <MenuItem value="created">
                  {i18next.t('equipment_web_atoir:equipmentChangesTypeCreated')}
                </MenuItem>
                <MenuItem value="edited">
                  {i18next.t('equipment_web_atoir:equipmentChangesTypeEdited')}
                </MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>{i18next.t('shared_web_atoir:date')}</TableCell>
          <TableCell>
            {i18next.t('equipment_web_atoir:equipmentChangesActorId')}
          </TableCell>
          <TableCell>
            {i18next.t('equipment_web_atoir:equipmentChangesSubjectId')}
          </TableCell>
          <TableCell>{i18next.t('inventory_web_atoir:comment')}</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

LogsTableHead.propTypes = {
  fieldChanged: PropTypes.func.isRequired,
}

class EquipmentHistoryComponent extends Component {
  state = {
    fields: {
      type: '',
    },
    rowsPerPage: 5,
    page: 0,
    order: '',
    orderBy: '',
  }

  componentDidMount() {
    const {
      props: {
        fetchEquipmentLogs,
        fetchEquipmentLogsCount,
        match: {
          params: { organizationId, equipmentId },
        },
      },
      state: { rowsPerPage, page },
    } = this
    fetchEquipmentLogs(organizationId, equipmentId, {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    })
    fetchEquipmentLogsCount(organizationId, equipmentId, {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    })
  }

  fieldChanged = field => event => {
    this.setState(
      state => ({
        fields: {
          ...state.field,
          [field]: event.target.value,
        },
      }),
      this.updateLogs,
    )
  }

  handleChangeDateFilter = (field, day) => {
    this.setState(
      state => ({
        fields: {
          ...state.fields,
          [field]: new Date(day).getTime(),
        },
      }),
      this.updateLogs,
    )
  }

  onOrderChanged = field => () => {
    const order =
      this.state.orderBy === field
        ? this.state.order === 'ASC'
          ? 'DESC'
          : this.state.order === 'DESC'
          ? ''
          : 'ASC'
        : ''
    this.setState(
      state => ({
        order,
        orderBy: field,
        ...(field && field.length > 0
          ? {
              fields: {
                ...state.fields,
                sort: `[${field}=${order}]`,
              },
            }
          : undefined),
      }),
      this.updateLogs,
    )
  }

  updateLogs = () => {
    const {
      state: { fields, page, rowsPerPage },
      props: {
        fetchEquipmentLogs,
        fetchEquipmentLogsCount,
        match: {
          params: { organizationId, equipmentId },
        },
      },
    } = this
    const filters = {
      ..._.omitBy(fields, f => f === ''),
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    }
    fetchEquipmentLogs(organizationId, equipmentId, filters)
    fetchEquipmentLogsCount(organizationId, equipmentId, filters)
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.updateLogs)
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, this.updateLogs)
  }

  render() {
    const {
      props: { isFetchingLogs, logs, isFetchingLogsCount, count, classes },
      state: { fields, order, orderBy, rowsPerPage, page },
      fieldChanged,
      onOrderChanged,
      handleChangeRowsPerPage,
      handleChangePage,
      handleChangeDateFilter,
    } = this

    return (
      <Fragment>
        {isEmpty(logs) ? (
          <Typography variant="h5" align="center">
            {i18next.t('equipment_web_atoir:noLogs')}
          </Typography>
        ) : (
          <Fragment>
            <LogsTableToolbar
              classes={classes}
              handleChange={handleChangeDateFilter}
            />
            <div className="table-wrapper">
              <Table>
                <LogsTableHead
                  fields={fields}
                  order={order}
                  orderBy={orderBy}
                  fieldChanged={fieldChanged}
                  orderChanged={onOrderChanged}
                />
                {isFetchingLogs || isFetchingLogsCount ? (
                  <Loader />
                ) : (
                  logs.map(l => (
                    <TableRow>
                      <TableCell>
                        <Typography>{l.action_type}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{l.date}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{l.actor_id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{l.subject_id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{l.comment}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </Table>
            </div>
            <TablePagination
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
              labelDisplayedRows={({
                from: fromPage,
                to: toPage,
                count: countPage,
              }) =>
                i18next.t('shared_web_atoir:labelDisplayedRows', {
                  fromPage,
                  toPage,
                  countPage,
                })
              }
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

EquipmentHistoryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchEquipmentLogs: PropTypes.func.isRequired,
  isFetchingLogs: PropTypes.bool.isRequired,
  logs: PropTypes.array.isRequired,
  fetchEquipmentLogsCount: PropTypes.func.isRequired,
  isFetchingLogsCount: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
}

export default withStyles(styles)(EquipmentHistoryComponent)
