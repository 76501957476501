import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { BreadcrumbsItem } from '@atoir/react-breadcrumbs-dynamic'

const styles = {
  crumb: {
    textDecoration: 'none',
    display: 'inline',
    color: 'inherit',
  },
  notClickable: {
    '&:hover': {
      cursor: 'default',
    },
  },
}

class BreadCrumb extends PureComponent {
  render() {
    const {
      props: { wrapper: Wrapper, children, to, classes, length, notClickable },
    } = this
    return (
      <BreadcrumbsItem
        to={to}
        onClick={
          notClickable
            ? e => {
                e.preventDefault()
              }
            : undefined
        }
        className={classNames([
          classes.crumb,
          notClickable && classes.notClickable,
        ])}
      >
        <Wrapper component="span" className={classes.crumb}>
          {typeof children === 'string'
            ? `${children.slice(0, length).trim()}${
                children.length > length ? '...' : ''
              }`
            : children}
        </Wrapper>
      </BreadcrumbsItem>
    )
  }
}

BreadCrumb.defaultProps = {
  length: 25,
  notClickable: false,
  wrapper: props => <Typography {...props}>{props.children}</Typography>,
}

BreadCrumb.propTypes = {
  to: PropTypes.string.isRequired,
  wrapper: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  length: PropTypes.number,
  notClickable: PropTypes.bool,
}

export default withStyles(styles)(BreadCrumb)
