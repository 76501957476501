import React from 'react'
// import i18next from '../../../../i18n/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Chip } from '@material-ui/core'
import i18next from '../../../../i18n/i18n'

const useStyles = makeStyles(() => ({
  statusChip: {
    minWidth: 120,
    marginRight: 4,
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const RoleEditor = ({ edit, contragent }) => {
  const classes = useStyles()

  const editHandler = ({ currentTarget: { id } }) => {
    edit({
      [id]: !contragent[id],
    })
  }

  return (
    <Box display="flex" alignItems="center">
      <Chip
        id="is_client"
        onClick={editHandler}
        color={contragent.is_client ? 'primary' : 'default'}
        className={classes.statusChip}
        label={i18next.t('organizations_web_atoir:client')}
        variant="outlined"
      />
      <Chip
        id="is_performer"
        onClick={editHandler}
        color={contragent.is_performer ? 'primary' : 'default'}
        className={classes.statusChip}
        label={i18next.t('organizations_web_atoir:performer')}
        variant="outlined"
      />
    </Box>
  )
}

export default RoleEditor
