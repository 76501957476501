import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import SideSelector from './SideSelector'
import OptionsForClient from './OptionsForClient'
import OptionsForPerformer from './OptionsForPerformer'
import Switch from '../../../../widgets/Switch'
import TextButton from '../../../../widgets/Buttons/TextButton'
import i18next from '../../../../i18n/i18n'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import ReasonInput from './components/ReasonInput'
import { createInnerCall } from '../../../../actions/innerCallsActions'
import { useDidUpdateEffect } from '../../../../helpers/hooks'
import {
  autoCompleteDataConverterPiped,
  compactObject,
  pipe,
  popLastSections,
} from '../../../../helpers/dataHelper'
import BreadCrumb from '../../../App/routing/BreadCrumb'

const initialValues = {
  sender: 'client',
  description: '',
  client_organization_id: {
    client_organization_id: '',
    text: '',
  },
  performer_organization_id: {
    performer_organization_id: '',
    text: '',
  },
  equipment_id: {
    equipment_id: '',
    text: '',
  },
  work_type: '',
}

const validationSchema = Yup.object({
  sender: Yup.string().oneOf(['client', 'performer']),
  client_organization_id: Yup.object()
    .shape({
      text: Yup.string().required(),
      client_organization_id: Yup.string().required(),
    })
    .required(),
  performer_organization_id: Yup.object()
    .shape({
      text: Yup.string().required(),
      performer_organization_id: Yup.string().required(),
    })
    .required(),
  equipment_id: Yup.object()
    .shape({
      text: Yup.string().nullable(),
      equipment_id: Yup.string().nullable(),
    })
    .nullable(),
  description: Yup.string()
    .max(5000, 'Количество знаков  в тексте не может быть более 5000')
    .required('Описание должно быть заполнено'),
  work_type: Yup.string(),
})

function packDataCommon(payload) {
  return pipe(
    autoCompleteDataConverterPiped('client_organization_id'),
    autoCompleteDataConverterPiped('performer_organization_id'),
    autoCompleteDataConverterPiped('equipment_id'),
  )(payload)
}

function packDataOnCreation(payload) {
  return pipe(packDataCommon, compactObject)(payload)
}

const InnerCallCreation = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      create(packDataOnCreation(values))
    },
  })

  const url = useLocation()

  const { isFetching: isCreating, newId } = useSelector(
    state => state.innerCalls.createInnerCall,
  )

  useDidUpdateEffect(() => {
    if (!isCreating && newId) {
      history.push(`/inner_calls/${newId}`)
    }
  }, [isCreating])

  const create = React.useCallback(
    payload => {
      dispatch(createInnerCall.REQUEST(payload))
    },
    [createInnerCall],
  )

  const resetFormPartial = delta => {
    formik.resetForm({
      values: {
        ...initialValues,
        ...delta,
      },
    })
  }

  const setFieldValueHandlerFormik = (id, value) => {
    // после сабмита валидация будет производиться при каждом изменении
    formik.setFieldValue(id, value, formik.submitCount !== 0)
  }

  const setFieldValueHandler = (eventOrId, possibleValue) => {
    let id = eventOrId
    let value = possibleValue
    if (eventOrId.target) {
      ;({ id, value } = eventOrId.target)
    }
    if (id === 'sender' && formik.values.sender !== value) {
      resetFormPartial({
        sender: value,
        description: formik.values.description,
      })
    } else {
      setFieldValueHandlerFormik(id, value)
    }
  }

  return (
    <>
      <BreadCrumb length={29} to={popLastSections(url.pathname)}>
        {i18next.t('inner_calls_web_atoir:innerCalls')}
      </BreadCrumb>
      <BreadCrumb length={29} to={url.pathname}>
        {i18next.t('inner_calls_web_atoir:repairCallCreation')}
      </BreadCrumb>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h4">
          {i18next.t('inner_calls_web_atoir:repairCallCreation')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={12} md={4} lg={5} xl={6}>
            <SideSelector
              idValue="sender"
              value={formik.values.sender}
              onChange={setFieldValueHandler}
            />
          </Grid>
          <Grid item sm={12} md={8} lg={7} xl={6}>
            <Switch value={formik.values.sender}>
              <Switch.Option option="client">
                <OptionsForClient
                  clientOrganizationId={formik.values.client_organization_id}
                  errors={formik.errors}
                  performerOrganizationId={
                    formik.values.performer_organization_id
                  }
                  equipmentId={formik.values.equipment_id}
                  onChange={setFieldValueHandler}
                />
              </Switch.Option>
              <Switch.Option option="performer">
                <OptionsForPerformer
                  clientOrganizationId={formik.values.client_organization_id}
                  errors={formik.errors}
                  performerOrganizationId={
                    formik.values.performer_organization_id
                  }
                  equipmentId={formik.values.equipment_id}
                  onChange={setFieldValueHandler}
                />
              </Switch.Option>
            </Switch>
            <ReasonInput
              onChange={setFieldValueHandler}
              work_type={formik.values.work_type}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!formik.errors.description}
              helperText={formik.errors.description || ' '}
              value={formik.values.description}
              onChange={({ target: { value } }) => {
                setFieldValueHandler('description', value)
              }}
              fullWidth
              required
              id="issue-description"
              rised
              label={i18next.t(
                'inner_calls_web_atoir:reparCallDescriptionHelperText',
              )}
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box width="100%" display="flex" justifyContent="flex-end">
              <div>
                <TextButton
                  size="medium"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  {i18next.t('shared_web_atoir:cancel')}
                </TextButton>
                <ContainedButton size="medium" type="submit">
                  {i18next.t('shared_web_atoir:create')}
                </ContainedButton>
              </div>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default InnerCallCreation
