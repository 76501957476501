import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

// We can inject some CSS into the DOM.
const styles = theme => ({
  button: {
    borderRadius: 3,
    border: 0,
    margin: '0 4px 0 15px',
    color: theme.palette.apply.main,
    padding: '4px 30px',
    minHeight: '32px',
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      background: theme.palette.apply.lightest,
    },
  },
})

function DefaultButton(props) {
  return (
    <Button color="apply" {...props} className={props.classes.button}>
      {props.children}
    </Button>
  )
}

DefaultButton.defaultProps = {
  children: '',
}

DefaultButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DefaultButton)
