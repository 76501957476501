import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import Loader from '../../Loader'
import CanOrganization from './OrganizationAbility/CanOrganization'
import CanOrganizationEmployees from './OrganizationAbility/CanOrganizationEmployees'
import OrganizationAbility from './OrganizationAbility/OrganizationAbility'
import OrganizationEmployeesAbility from './OrganizationAbility/OrganizationEmployeesAbility'
import OrganizationLocationsAbility from './OrganizationAbility/OrganizationLocationsAbility'
import OrganizationDepartmentsAbility from './OrganizationAbility/OrganizationDepartmentsAbility'
import OrganizationEquipmentAbility from './OrganizationAbility/OrganizationEquipmentAbility'

// styles
import '../../../styles/components/Organization.css'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

// material-ui-icons
import MoreVertIcon from '@material-ui/icons/MoreVert'

// tab menu components
import OrganizationInformationContainer from '../../../containers/Organizations/OrganizationInformationContainer'
import OrganizationStaffContainer from '../../../containers/Organizations/OrganizationStaffContainer'
import OrganizationEquipmentContainer from '../../../containers/Organizations/OrganizationEquipmentContainer'
import OrganizationStructureContainer from '../../../containers/Organizations/OrganizationStructureContainer'
import EditOrganization from '../EditOrganization/EditOrganization'
import CanOrganizationEquipment from './OrganizationAbility/CanOrganizationEquipment'
import BreadCrumb from '../../App/routing/BreadCrumb'
import { popLastSections } from '../../../helpers/dataHelper'

const styles = theme => ({
  addressDiv: {
    padding: 0,
    marginBottom: theme.typography.pxToRem(15),
  },
  marginBtn: {
    marginLeft: 0,
  },
  paddingGrid: {
    '&:nth-of-type(even)': {
      paddingRight: theme.spacing(2),
    },
  },
  btnDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  tabRoot: {
    flexGrow: 1,
  },
  labelButton: {
    margin: '12px 0 12px 0',
    color: 'rgb(66, 155, 213)',
    border: '0',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addButton: {
    margin: '12px 0 12px 0',
    color: 'rgb(66, 155, 213)',
    border: 'solid 1.5px rgb(66, 155, 213)',
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      color: 'rgb(255, 255, 255)',
      background: 'rgb(66, 155, 213)',
    },
  },
})

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

class OrganizationProfileComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      tabValue: this.props.match.params.tab,
      isUpdatingOrganizationDialogOpen: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
    }
  }

  componentDidMount() {
    const { organizationId: id } = this.props.match.params
    const { fetchOrganization, fetchOrganizationPermissions } = this.props
    fetchOrganization(id)
    fetchOrganizationPermissions(id)
  }

  componentDidUpdate = prevProps => {
    const {
      isUpdatingLocation,
      updatingLocationError,
      isCreatingLocation,
    } = this.props
    if (
      ((prevProps.isUpdatingLocation !== isUpdatingLocation &&
        !isUpdatingLocation) ||
        (prevProps.isCreatingLocation !== isCreatingLocation &&
          !isCreatingLocation)) &&
      !updatingLocationError
    ) {
      const { organizationId: id } = this.props.match.params
      const { fetchOrganization, fetchOrganizationPermissions } = this.props
      fetchOrganization(id)
      fetchOrganizationPermissions(id)
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      state: { anchorEl },
    } = this
    if (
      this.props.isFetchingOrganization !== newProps.isFetchingOrganization &&
      newProps.isFetchingOrganization === false
    ) {
      if (
        newProps.organizationError === 64 ||
        newProps.organizationError === 50
      ) {
        this.props.history.push('/404')
      }
      if (!isEmpty(newProps.organization)) {
        this.props.getTitleInfo({
          title: newProps.organization.short_name,
          moreAction: this.renderMenuMore(Boolean(anchorEl), anchorEl),
        })
      }
    }
    if (
      this.props.isFetchingOrganizationPermissions !==
        newProps.isFetchingOrganizationPermissions &&
      newProps.isFetchingOrganizationPermissions === false
    ) {
      if (newProps.fetchingPermissionsError) {
        // TODO handleError
      } else {
        const organizationPermissions = []
        if (newProps.organizationPermissions) {
          Object.keys(newProps.organizationPermissions).forEach(key => {
            if (
              typeof newProps.organizationPermissions[key] === 'boolean' &&
              newProps.organizationPermissions[key] === true
            ) {
              organizationPermissions.push({
                subject: 'Organization',
                action: key,
              })
            }
          })
          const permissions = {}
          const capitalizeFirstLetter = s =>
            s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
          ;['employees', 'locations', 'departments', 'equipment'].forEach(k => {
            permissions[k] = []
            Object.keys(newProps.organizationPermissions[k]).forEach(key => {
              if (
                typeof newProps.organizationPermissions[k][key] === 'boolean' &&
                newProps.organizationPermissions[k][key] === true
              ) {
                permissions[k].push({
                  subject: `Organization${capitalizeFirstLetter(k)}`,
                  action: key,
                })
              }
            })
          })
          OrganizationAbility.update(organizationPermissions)
          OrganizationEmployeesAbility.update(permissions.employees)
          OrganizationLocationsAbility.update(permissions.locations)
          OrganizationDepartmentsAbility.update(permissions.departments)
          OrganizationEquipmentAbility.update(permissions.equipment)
        }
      }
    }
    if (this.props.match.params.tab !== newProps.match.params.tab) {
      this.setState({
        tabValue: newProps.match.params.tab,
      })
    }
  }

  handleMenuOpen = event => {
    const {
      props: { organization },
    } = this
    this.setState({ anchorEl: event.currentTarget }, () => {
      const { anchorEl } = this.state
      this.props.getTitleInfo({
        title: organization.short_name,
        moreAction: this.renderMenuMore(Boolean(anchorEl), anchorEl),
      })
    })
  }

  handleMenuClose = () => {
    const {
      props: { organization },
    } = this
    this.setState({ anchorEl: null }, () => {
      const { anchorEl } = this.state
      this.props.getTitleInfo({
        title: organization.short_name,
        moreAction: this.renderMenuMore(Boolean(anchorEl), anchorEl),
      })
    })
  }

  handleTabChange = (event, value) => {
    const { history, match } = this.props
    const baseUrl = match.url.split('/')
    baseUrl.pop()
    this.setState(
      {
        tabValue: value,
      },
      () => history.push(`${baseUrl.join('/')}/${value}`),
    )
  }

  closeUpdateOrganizationDialog = message => {
    this.setState({
      isUpdatingOrganizationDialogOpen: false,
    })
    if (message) {
      this.showSnackBar(message)
    }
  }

  showSnackBar = message => {
    this.setState({ isSnackBarOpen: true, snackBarMessage: message })
  }

  closeSnackBar = () => {
    this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
  }

  openUpdateOrganizationDialog = () => {
    this.setState(
      {
        isUpdatingOrganizationDialogOpen: true,
        anchorEl: null,
      },
      () => {
        const { anchorEl } = this.state
        this.props.getTitleInfo({
          title: this.props.organization.short_name,
          moreAction: this.renderMenuMore(Boolean(anchorEl), anchorEl),
        })
      },
    )
  }

  renderUpdateOrganizationDialog = () => (
    <EditOrganization
      data={this.props.organization}
      classes={this.props.classes}
      onClose={this.closeUpdateOrganizationDialog}
      open={this.state.isUpdatingOrganizationDialogOpen}
    />
  )

  renderSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={this.state.isSnackBarOpen}
      onClose={this.closeSnackBar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
  )

  renderMenuMore = (openMenu, anchorEl) => (
    <CanOrganization I="edit" a="Organization">
      <div>
        <IconButton
          id="organizationProfileMenuBtn"
          aria-owns={openMenu ? 'menu-company' : null}
          aria-haspopup="true"
          onClick={this.handleMenuOpen}
          color="inherit"
          className={this.props.classBtnIcon}
        >
          <MoreVertIcon className={this.props.classIcon} />
        </IconButton>
        <Menu
          id="menu-company"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openMenu}
          onClose={this.handleMenuClose}
        >
          <CanOrganization I="edit" a="Organization">
            <MenuItem
              data-cy="openUpdateOrganizationDialog"
              onClick={this.openUpdateOrganizationDialog}
            >
              {i18next.t('organizations_web_atoir:editOrganizationMenuItem')}
            </MenuItem>
          </CanOrganization>
          <MenuItem component={Link} to={`${this.getSettingsUrl()}`}>
            {i18next.t('organizations_web_atoir:organizationSettings')}
          </MenuItem>
        </Menu>
      </div>
    </CanOrganization>
  )

  getSettingsUrl = () => {
    const {
      props: {
        match: { url },
      },
    } = this
    return `${popLastSections(url)}/settings`
  }

  render() {
    const {
      classes,
      isFetchingOrganization,
      isFetchingOrganizationPermissions,
      organization,
      match: { url },
    } = this.props
    const { tabValue } = this.state

    return (
      <Fragment>
        <BreadCrumb to={url}>{organization.short_name}</BreadCrumb>
        {isFetchingOrganization ||
        isFetchingOrganizationPermissions ||
        isEmpty(organization) ? (
          <Loader />
        ) : (
          <div className="Organization-profile-container">
            {this.renderSnackBar()}
            {this.renderUpdateOrganizationDialog()}
            <br />

            <div className={classes.tabRoot}>
              <AppBar position="static" color="default">
                <Tabs value={tabValue} onChange={this.handleTabChange} centered>
                  <Tab
                    value="profile"
                    label={i18next.t(
                      'organizations_web_atoir:organizationProfileTabTitle',
                    )}
                  />
                  {OrganizationEmployeesAbility.can(
                    'view',
                    'OrganizationEmployees',
                  ) ? (
                    <Tab
                      value="staff"
                      label={i18next.t(
                        'organizations_web_atoir:organizationStaffTabTitle',
                      )}
                    />
                  ) : (
                    ''
                  )}
                  {OrganizationEquipmentAbility.can(
                    'view',
                    'OrganizationEquipment',
                  ) ? (
                    <Tab
                      value="equipment"
                      label={i18next.t(
                        'organizations_web_atoir:organizationEquipmentTabTitle',
                      )}
                    />
                  ) : (
                    ''
                  )}
                  <Tab
                    value="structure"
                    label={i18next.t(
                      'organizations_web_atoir:organizationStructureTabTitle',
                    )}
                  />
                </Tabs>
              </AppBar>
              {tabValue === 'profile' && (
                <TabContainer>
                  <OrganizationInformationContainer />
                </TabContainer>
              )}
              <CanOrganizationEmployees I="view" a="OrganizationEmployees">
                <div>
                  {tabValue === 'staff' && (
                    <TabContainer>
                      <OrganizationStaffContainer organization={organization} />
                    </TabContainer>
                  )}
                </div>
              </CanOrganizationEmployees>
              <CanOrganizationEquipment I="view" a="OrganizationEquipment">
                <div>
                  {tabValue === 'equipment' && (
                    <TabContainer>
                      <OrganizationEquipmentContainer />
                    </TabContainer>
                  )}
                </div>
              </CanOrganizationEquipment>
              {tabValue === 'structure' && (
                <TabContainer>
                  <OrganizationStructureContainer />
                </TabContainer>
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

OrganizationProfileComponent.propTypes = {
  isFetchingOrganization: PropTypes.bool.isRequired,
  isFetchingOrganizationPermissions: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  fetchOrganizationPermissions: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OrganizationProfileComponent)
