import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InnerCallAttachments from './InnerCallAttachments'
import {
  fetchInnerCallPhotosRequest,
  fetchInnerCallPhotosCountRequest,
  uploadInnerCallPhotoRequest,
  deleteInnerCallPhotoRequest,
} from '../../../../../actions/innerCallsActions'

const mapStateToProps = state => ({
  isFetchingPhotos: state.innerCalls.innerCallPhotos.isFetching,
  photos: state.innerCalls.innerCallPhotos.photos,
  isFetchingPhotosCount: state.innerCalls.innerCallPhotosCount.isFetching,
  photosCount: state.innerCalls.innerCallPhotosCount.count,
  isUploadingPhoto: state.innerCalls.uploadPhoto.isFetching,
  isDeletingPhoto: state.innerCalls.deletePhoto.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInnerCallPhotosRequest,
      fetchInnerCallPhotosCountRequest,
      uploadInnerCallPhotoRequest,
      deleteInnerCallPhotoRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerCallAttachments),
)
