import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import _ from 'lodash'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

// components
import Subheading from '../../../../../../widgets/Lettering/Subheading'
import Loader from '../../../../../Loader'
import AddCommentDialog from '../../../../../../widgets/Comments/AddCommentDialog'
import CommentsList from '../../../../../../widgets/Comments/CommentsList'

// config
import config from '../../../../../../config'

// i18n
import i18next from '../../../../../../i18n/i18n'

import * as permissionResolvers from '../../../../../../helpers/callPermissionsResolver'
import { formatSizeUnits } from '../../../../../../helpers/formatSizeUnits'
import BreadCrumb from '../../../../../App/routing/BreadCrumb'
import { popLastSections } from '../../../../../../helpers/dataHelper'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
  },
  sectionAction: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
  textFieldMulty: {
    width: '500px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
})

class OrderProfile extends Component {
  constructor(props) {
    super(props)
    this.inputFileRef = React.createRef()
    this.state = {
      orderPermissions: null,
      addCommentShow: false,
      comment: {
        text: '',
        files: [],
        parentId: null,
      },
      limit: 5,
      page: 0,
      offset: 0,
      fromOrg: {},
      fromList: [],
      commentError: false,
      comments: [],
    }
  }

  componentDidMount() {
    const {
      props: {
        match: { params },
        fetchCallPermissions,
      },
    } = this
    fetchCallPermissions(params.idRequest)
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        match: { params },
        isFetchingPermissions,
        permissions,
        fetchOrder,
        isFetchingOrder,
        order,
        isUploadingComment,
        isUploadingSubcomment,
        isFetchingComments,
      },
      commentsUpdateData,
    } = this

    if (
      prevProps.isFetchingPermissions !== isFetchingPermissions &&
      !isFetchingPermissions
    ) {
      if (permissionResolvers.canViewOrderComments) {
        fetchOrder({ id: params.id, params: '' })
      }
      const orderStatusPermissions =
        !isEmpty(permissions) && !isEmpty(permissions.orders)
          ? permissions.orders.find(_order => _order.id === params.id)
          : {}
      const orderPermissions = !isEmpty(orderStatusPermissions)
        ? orderStatusPermissions.permissions
        : {}
      this.setState({ orderPermissions })
    }

    if (
      prevProps.isFetchingOrder !== isFetchingOrder &&
      !isFetchingOrder &&
      !isEmpty(order)
    ) {
      commentsUpdateData()
    }

    if (
      prevProps.isFetchingComments !== isFetchingComments &&
      !isFetchingComments
    ) {
      this.commentsSorting()
      this.commentsSetFrom()
    }

    if (
      (prevProps.isUploadingComment !== isUploadingComment &&
        !isUploadingComment) ||
      (prevProps.isUploadingSubcomment !== isUploadingSubcomment &&
        !isUploadingSubcomment)
    ) {
      this.commentsUpdateData()
    }
  }

  onChange = field => event => {
    this.setState({ [field]: event.target.value })
  }

  onToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  commentsUpdateData = () => {
    const {
      props: {
        match: { params },
        fetchOrderCommentsRequest,
        fetchOrderCommentsCountRequest,
      },
      state: { limit, offset },
    } = this
    fetchOrderCommentsRequest({
      order_id: params.id,
      limit,
      offset,
    })
    fetchOrderCommentsCountRequest({ order_id: params.id })
  }

  commentsSetFrom = (event, e) => {
    const {
      props: {
        match: { params },
        permissions,
        order,
      },
    } = this
    const clientInfo = {
      organization_id: order.client_organization_id,
      orgRole: i18next.t('frontend:client'),
      organization_full_name: order.client_organization.full_name,
      short_name: order.client_organization.short_name,
    }
    const performerInfo = {
      organization_id: order.performer_organization_id,
      orgRole: i18next.t('frontend:performer'),
      organization_full_name: order.performer_organization.full_name,
      short_name: order.performer_organization.short_name,
    }

    if (e) {
      this.setState({
        fromOrg:
          e.props.value === order.client_organization_id
            ? clientInfo
            : performerInfo,
      })
    } else if (permissions.orders) {
      const orderPermissions = permissions.orders.find(
        _order => _order.id === params.id,
      )

      const { VIEW_AS_CLIENT, VIEW_AS_PERFORMER } = orderPermissions.permissions
      let personOrg = ''
      let fromList = []
      if (VIEW_AS_CLIENT !== VIEW_AS_PERFORMER) {
        personOrg = VIEW_AS_CLIENT ? clientInfo : performerInfo
        fromList = [personOrg]
      } else {
        personOrg = performerInfo
        fromList =
          clientInfo.organization_id !== performerInfo.organization_id
            ? [clientInfo, performerInfo]
            : [
                {
                  ...clientInfo,
                  orgRole: `${i18next.t('frontend:client')}, ${i18next.t(
                    'frontend:performer',
                  )}`,
                },
              ]
      }
      this.setState({
        fromOrg: personOrg,
        fromList: _.uniqBy(fromList, 'organization_id'),
      })
    }
  }

  commentsPaginationOptionsHandler = (option, value) => {
    if (option === 'page') {
      this.setState(
        state => ({
          [option]: value,
          offset: value * state.limit,
        }),
        () => this.commentsUpdateData(),
      )
    } else {
      this.setState(
        {
          [option]: value,
        },
        () => this.commentsUpdateData(),
      )
    }
  }

  AddCommentDialogShow = id => {
    this.setState(state => {
      const comment = { ...state.comment }
      if (id) {
        comment.parentId = id
      }
      return {
        addCommentShow: true,
        comment,
      }
    })
  }

  AddCommentDialogClose = () => {
    this.setState({
      addCommentShow: false,
      commentError: false,
      comment: {
        text: '',
        files: [],
        parentId: null,
      },
    })
  }

  submitComment = event => {
    const {
      props: {
        match: { params },
        uploadServiceDeskOrderCommentRequest,
        uploadServiceDeskOrderSubcommentRequest,
      },
      state: { comment, fromOrg },
    } = this
    event.preventDefault()

    let errorSize = false
    if (comment.files.length > 0) {
      comment.files.forEach(file => {
        if (file.size > config.max_logo_photo_size) {
          errorSize = true
        }
      })
    }
    if (errorSize) {
      this.props.showDialogWithOk(`${i18next.t('shared_web_atoir:fileToBig')} \
      ${formatSizeUnits(config.max_logo_photo_size)}`)
    } else if (
      !errorSize &&
      comment &&
      comment.text &&
      comment.text.length > 0
    ) {
      const formData = new FormData()
      formData.append('message', comment.text)
      formData.append('order_id', params.id)
      formData.append('organization_id', fromOrg.organization_id)

      comment.files.forEach(file => {
        formData.append('files', file, file.name)
      })
      if (comment.parentId) {
        uploadServiceDeskOrderSubcommentRequest({
          formData,
          parentId: comment.parentId,
          order_id: params.id,
        })
      } else {
        uploadServiceDeskOrderCommentRequest({
          formData,
          order_id: params.id,
        })
      }
      this.AddCommentDialogClose()
    } else {
      this.setState({
        commentError: true,
      })
    }
  }

  AddCommentDialogOnChange = (action, payload) => {
    // eslint-disable-next-line
    const comment = {...this.state.comment}
    switch (action) {
      case 'addFiles': {
        comment.files.push(...payload.files)
        break
      }
      case 'addText': {
        comment.text = payload.text
        if (payload.text && payload.text.length > 0) {
          this.setState({
            commentError: false,
          })
        }
        break
      }
      case 'delFiles': {
        _.remove(comment.files, file => file.name === payload.name)
        break
      }
      default: {
        return
      }
    }
    this.setState({ comment })
  }

  CommentsAttachmentsUrlCreator = file => {
    const {
      props: {
        match: { params },
        order,
      },
    } = this
    const url = `/api/downloads/service_desc/${new Date(
      order.ServiceCall.createdAt,
    ).getFullYear()}/${params.idRequest}/orders/${params.id}/commentfiles/${
      file.id
    }.${file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)}`
    return url
  }

  commentsSorting = () => {
    const {
      props: { comments },
    } = this
    const commentsArr = comments
    commentsArr.map(commentParent =>
      commentParent.child_comments.sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      }),
    )
    this.setState({ comments: commentsArr })
  }

  render() {
    const {
      props: {
        order,
        isFetchingOrder,
        isFetchingPermissions,
        match,
        isFetchingComments,
        isUploadingComment,
        isUploadingSubcomment,
      },
      state: { addCommentShow, orderPermissions },
    } = this
    return (
      <Fragment>
        {isFetchingOrder || isFetchingPermissions ? (
          <Loader />
        ) : (
          <Fragment>
            {!!order && !!orderPermissions && (
              <Fragment>
                <BreadCrumb to={popLastSections(match.url, 3)}>
                  {i18next.t('service_desk_web_atoir:titleCallCard', {
                    date: new Date(
                      Date.parse(order.ServiceCall.createdAt),
                    ).toLocaleDateString('ru'),
                    internalNum: order.ServiceCall.internal_num,
                  })}
                </BreadCrumb>
                <BreadCrumb to={match.url}>
                  {i18next.t('service_desk_web_atoir:comments')}
                </BreadCrumb>
                <Paper elevation={2}>
                  <Subheading
                    divider
                    title={i18next.t('shared_web_atoir:captionComments')}
                  >
                    <Fragment>
                      <IconButton
                        aria-label={i18next.t(
                          'equipment_web_atoir:addEquipment',
                        )}
                        onClick={() => {
                          this.AddCommentDialogShow()
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Fragment>
                  </Subheading>
                  {isFetchingComments ? (
                    <Loader />
                  ) : (
                    <CommentsList
                      comments={this.state.comments}
                      doReplyFunc={this.AddCommentDialogShow}
                      limit={this.state.limit}
                      page={this.state.page}
                      offset={this.state.offset}
                      setOptions={this.commentsPaginationOptionsHandler}
                      commentsCount={
                        this.props.orderCommentsCount
                          ? this.props.orderCommentsCount
                          : 0
                      }
                      pagination={[5, 25, 50]}
                      fileUrlCreator={this.CommentsAttachmentsUrlCreator}
                      downloadFileRequest={this.props.downloadFileRequest}
                      getPreviewPath={id =>
                        this.props.getPreviewPath({
                          fileId: id,
                          orderId: order.id,
                        })
                      }
                    />
                  )}
                  <AddCommentDialog
                    open={addCommentShow}
                    onClose={this.AddCommentDialogClose}
                    onChange={this.AddCommentDialogOnChange}
                    comment={this.state.comment}
                    doReplyFunc={this.AddCommentDialogShow}
                    handleSubmit={this.submitComment}
                    fromOrg={this.state.fromOrg}
                    fromList={this.state.fromList}
                    setFrom={this.commentsSetFrom}
                    loading={isUploadingComment || isUploadingSubcomment}
                    error={this.state.commentError}
                  />
                </Paper>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default withStyles(styles)(OrderProfile)
