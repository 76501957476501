import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { organizationLocationStringify } from '../../helpers/parserHelper'
import { statuses } from '../../constants/constants'

// internationalization
import i18next from '../../i18n/i18n'

// components
import Subheading from '../../widgets/Lettering/Subheading'
import DropDownList from '../../widgets/DropDownList'
import EquipmentTable from '../Organizations/OrganizationEquipmentTable'
// AT-406
// import TransferringEquipmentTable from './TransferringEquipmentTable'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

// material-ui-icons
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
import ContainedButton from '../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../widgets/Buttons/OutlinedButton'
import BreadCrumb from '../App/routing/BreadCrumb'
import ImportFromListDialog from './ImportFromListDialog'
import AddNewDialog from './AddNewDialog'

const columnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Оборудование',
  },
  {
    id: 'request_pending',
    numeric: false,
    disablePadding: false,
    label: 'Заявки',
  },
]

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(
            column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title={i18next.t('shared_web_atoir:sort')}
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  buttons: {
    display: 'flex',
    justifyItems: 'center',
  },
})

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    openImportFromListDialog,
    openAddNewDialog,
    canAdd,
  } = props

  return (
    <Fragment>
      {numSelected > 0 ? (
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.title}>
            <Typography color="inherit" variant="subheading">
              {numSelected} selected
            </Typography>
          </div>
          <div className={classes.actions}>
            <Tooltip title={i18next.t('shared_web_atoir:delete')}>
              <IconButton aria-label={i18next.t('shared_web_atoir:delete')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      ) : (
        <Subheading
          divider
          title={i18next.t('equipment_web_atoir:accountEquipmentTitle')}
        >
          {canAdd ? (
            <Fragment>
              <IconButton
                aria-label={i18next.t('equipment_web_atoir:addEquipment')}
                onClick={openAddNewDialog}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                aria-label={i18next.t('equipment_web_atoir:addEquipmentList')}
                onClick={openImportFromListDialog}
              >
                <ListIcon />
              </IconButton>
            </Fragment>
          ) : null}
        </Subheading>
      )}
    </Fragment>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const AcceptDenyEquipmentDialog = props => {
  const ACCEPT_EQUIPMENT_TITLE = i18next.t(
    'equipment_web_atoir:acceptEquipmentDialogTitle',
  )
  const ACCEPT_EQUIPMENT_MESSAGE = i18next.t(
    'equipment_web_atoir:acceptEquipmentDialogText',
  )
  const DENY_EQUIPMENT_TITLE = i18next.t(
    'equipment_web_atoir:denyEquipmentDialogTitle',
  )
  const DENY_EQUIPMENT_MESSAGE = i18next.t(
    'equipment_web_atoir:denyEquipmentDialogText',
  )
  const CANCEL_TRANSFER_TITLE = i18next.t('equipment_web_atoir:denyTransfer')
  const CANCEL_TRANSFER_MESSAGE = i18next.t(
    'equipment_web_atoir:cancelTransferDialogText',
  )
  const {
    classes,
    isLocationsOpen,
    locations,
    isFetchingOrganizationLocations,
    location,
    open,
    onClose,
    type,
    doAccept,
    acceptEquipment,
    denyEquipment,
    handleOpenDropDown,
    handleCloseDropDown,
    handleChangeLocation,
    isFetchingOrganizationDepartments,
    isDepartmentsOpen,
    department,
    departments,
    equipment,
  } = props
  const cancelTransfer = type === 'sender'
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {doAccept
          ? ACCEPT_EQUIPMENT_TITLE
          : cancelTransfer
          ? CANCEL_TRANSFER_TITLE
          : DENY_EQUIPMENT_TITLE}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {doAccept
            ? ACCEPT_EQUIPMENT_MESSAGE
            : cancelTransfer
            ? CANCEL_TRANSFER_MESSAGE
            : DENY_EQUIPMENT_MESSAGE}
          {doAccept ? (
            <div>
              <Typography color="textSecondary">
                {i18next.t('equipment_web_atoir:equipmentName')}:
                {equipment.Equipment.name}
              </Typography>
              {equipment.Equipment.model ? (
                <Typography color="textSecondary">
                  {i18next.t('equipment_web_atoir:model')}:
                  {equipment.Equipment.model}
                </Typography>
              ) : null}
              {equipment.Equipment.vendor ? (
                <Typography color="textSecondary">
                  {i18next.t('equipment_web_atoir:vendor')}:
                  {equipment.Equipment.vendor}
                </Typography>
              ) : null}
              <Typography color="textSecondary">
                {i18next.t('equipment_web_atoir:equipmentStatus')}:
                {statuses.find(s => s.id === equipment.Equipment.status).title}
              </Typography>
              <br />
              <Typography>
                {i18next.t('equipment_web_atoir:selectTransferAddress')}:
              </Typography>
              <DropDownList
                classes={classes}
                open={isLocationsOpen}
                value={location}
                error={doAccept && location.length !== 36}
                handleToogle={handleOpenDropDown('isLocationsOpen')}
                elements={
                  locations
                    ? locations.map(l => ({
                        id: l.id,
                        title: `${l.name} - ${organizationLocationStringify(
                          l,
                        )}`,
                      }))
                    : []
                }
                isFetching={isFetchingOrganizationLocations}
                handleOpen={handleOpenDropDown('isLocationsOpen')}
                handleClose={handleCloseDropDown('isLocationsOpen')}
                handleChange={handleChangeLocation('locationForTransferring')}
              />
              <br />
              <Typography>
                {i18next.t('equipment_web_atoir:selectTransferDepartment')}:
              </Typography>
              <DropDownList
                classes={classes}
                open={isDepartmentsOpen}
                value={department}
                handleToogle={handleOpenDropDown('isDepartmentsOpen')}
                elements={
                  departments
                    ? departments.map(l => ({ id: l.id, title: l.name }))
                    : []
                }
                isFetching={isFetchingOrganizationDepartments}
                handleOpen={handleOpenDropDown('isDepartmentsOpen')}
                handleClose={handleCloseDropDown('isDepartmentsOpen')}
                handleChange={handleChangeLocation('departmentForTransferring')}
              />
            </div>
          ) : (
            ''
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          onClick={onClose}
          classes={{ root: classes.button }}
          size="medium"
        >
          {i18next.t('shared_web_atoir:cancel')}
        </OutlinedButton>
        <ContainedButton
          classes={{ root: classes.button }}
          disabled={doAccept && location.length !== 36}
          onClick={doAccept ? acceptEquipment : denyEquipment}
          autoFocus
          size="medium"
        >
          {doAccept
            ? i18next.t('equipment_web_atoir:acceptEquipmentDialogTitle')
            : cancelTransfer
            ? i18next.t('equipment_web_atoir:denyTransfer')
            : i18next.t('equipment_web_atoir:refuseEquipment')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

AcceptDenyEquipmentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  department: PropTypes.object.isRequired,
  denyEquipment: PropTypes.func.isRequired,
  acceptEquipment: PropTypes.func.isRequired,
  handleOpenDropDown: PropTypes.func.isRequired,
  handleCloseDropDown: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
  isFetchingOrganizationDepartments: PropTypes.bool.isRequired,
  isFetchingOrganizationLocations: PropTypes.bool.isRequired,
}

const styles = theme => ({
  root: {
    // width: '100%',
    marginBottom: theme.spacing(3),

    '& :last-child': {
      marginBottom: 0,
    },
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
})

class EquipmentComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      acceptDenyDialogOpen: false,
      importFromListDialogOpen: false,
      addNewDialogOpen: false,
      selected: [],
      // AT-406
      // transferringEquipmentTableRowsPerPage: 10,
      // transferringEquipmentTablePage: 0,
      // transferringEquipmentTableRowsPerPageMe: 10,
      // transferringEquipmentTablePageMe: 0,
      page: 0,
    }
  }

  componentDidMount = () => {
    const {
      fetchEquipment,
      fetchVendors,
      fetchDepartments,
      fetchLocations,
      fetchOrganizations,
      fetchUserEquipmentCount,
      fetchUserOrganizationsCanAddEquipment,
      rowsPerPage,
    } = this.props

    const { page } = this.state
    fetchEquipment({ limit: rowsPerPage, offset: rowsPerPage * page })
    fetchVendors()
    fetchLocations()
    fetchDepartments()
    fetchOrganizations()
    fetchUserEquipmentCount()
    fetchUserOrganizationsCanAddEquipment()
  }

  fetchEquipment = incomeFilters => {
    const { fetchEquipment, fetchOrganizationEquipment } = this.props
    if (incomeFilters.organization) {
      fetchOrganizationEquipment(incomeFilters.organization, incomeFilters)
    } else {
      const filters = {
        ...incomeFilters,
      }
      if (incomeFilters.orderField && incomeFilters.orderField.length > 0) {
        filters.sort = `[${
          incomeFilters.orderField
        }=${incomeFilters.orderDirection.toUpperCase()}]`
      }
      fetchEquipment(filters)
    }
  }

  onAcceptDenyEquipment = accept => (equipment, type) => {
    if (type === 'receiver') {
      this.props.fetchOrganizationLocations(equipment.receiver_organization_id)
      this.props.fetchOrganizationDepartments(
        equipment.receiver_organization_id,
      )
    }
    this.setState({
      doAcceptTransferringEquipment: accept,
      transferringEquipmentUnit: equipment,
      type,
    })
    this.acceptDenyDialogOpen()
  }

  acceptDenyDialogClose = () => {
    this.setState({ acceptDenyDialogOpen: false })
  }

  acceptDenyDialogOpen = () => {
    this.setState({ acceptDenyDialogOpen: true })
  }

  transferEquipmentEquipment = accept => () => {
    const { transferEquipment, showDialogLoader } = this.props
    const {
      transferringEquipmentUnit,
      locationForTransferring,
      departmentForTransferring,
      type,
    } = this.state
    this.acceptDenyDialogClose()
    showDialogLoader()
    const organizationId =
      type === 'receiver'
        ? transferringEquipmentUnit.receiver_organization_id
        : transferringEquipmentUnit.sender_organization_id
    transferEquipment(
      accept ? 'approve' : 'cancel',
      organizationId,
      transferringEquipmentUnit.id,
      locationForTransferring,
      departmentForTransferring,
    )
  }

  handleChange = field => event => {
    this.setState({
      [field]: event.target.value,
    })
  }

  handleOpen = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleClose = field => () => {
    this.setState({
      [field]: false,
    })
  }

  handleChangePage = field => (event, page) => {
    this.setState({ [field]: page })
  }

  handleChangeRowsPerPage = field => event => {
    this.setState({ [field]: event.target.value })
  }

  openImportFromListDialog = () => {
    this.setState({ importFromListDialogOpen: true })
  }

  openAddNewDialog = () => {
    this.setState({ addNewDialogOpen: true })
  }

  goToImportEquipmentFromList = organizationId => {
    this.props.history.push(
      `/equipment/${organizationId}/import_equipments/from-equipment`,
    )
  }

  goToAddEquipment = organizationId => {
    this.props.history.push(`/equipment/add/${organizationId}`)
  }

  render() {
    const {
      classes,
      organizationLocations,
      organizationDepartments,
      isFetchingOrganizationLocations,
      isFetchingOrganizationDepartments,
      fetchUserEquipmentCount,
      canAddEquipmentOrganizations,
      match,
    } = this.props
    const {
      selected,
      acceptDenyDialogOpen,
      type,
      doAcceptTransferringEquipment,
      isLocationsOpen,
      locationForTransferring,
      departmentForTransferring,
      isDepartmentsOpen,
      // AT-406
      // transferringEquipmentTablePage,transferringEquipmentTableRowsPerPage, transferringEquipmentTablePageMe,
      // transferringEquipmentTableRowsPerPageMe,
      transferringEquipmentUnit,
      importFromListDialogOpen,
      addNewDialogOpen,
    } = this.state
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <BreadCrumb to={match.url}>
            {i18next.t(
              'organizations_web_atoir:organizationEquipmentListTitle',
            )}
          </BreadCrumb>
          <ImportFromListDialog
            open={importFromListDialogOpen}
            closeDialog={() => {
              this.setState({ importFromListDialogOpen: false })
            }}
            submit={this.goToImportEquipmentFromList}
          />
          <AddNewDialog
            open={addNewDialogOpen}
            closeDialog={() => {
              this.setState({ addNewDialogOpen: false })
            }}
            submit={this.goToAddEquipment}
          />
          <AcceptDenyEquipmentDialog
            classes={classes}
            equipment={transferringEquipmentUnit}
            open={acceptDenyDialogOpen}
            onClose={this.acceptDenyDialogClose}
            locations={organizationLocations}
            departments={organizationDepartments}
            acceptEquipment={this.transferEquipmentEquipment(true)}
            denyEquipment={this.transferEquipmentEquipment(false)}
            type={type}
            doAccept={doAcceptTransferringEquipment}
            handleChangeLocation={this.handleChange}
            handleCloseDropDown={this.handleClose}
            handleOpenDropDown={this.handleOpen}
            isFetchingOrganizationLocations={isFetchingOrganizationLocations}
            isLocationsOpen={isLocationsOpen}
            location={locationForTransferring || { id: 0, title: '' }}
            department={departmentForTransferring || { id: 0, title: '' }}
            isDepartmentsOpen={isDepartmentsOpen}
            isFetchingOrganizationDepartments={
              isFetchingOrganizationDepartments
            }
          />
          <EnhancedTableToolbar
            match={match}
            numSelected={selected.length}
            openImportFromListDialog={this.openImportFromListDialog}
            openAddNewDialog={this.openAddNewDialog}
            canAdd={
              canAddEquipmentOrganizations &&
              canAddEquipmentOrganizations.length > 0
            }
          />
          <EquipmentTable
            noOrganization
            fetchCount={fetchUserEquipmentCount}
            fetchEquipment={this.fetchEquipment}
          />
        </Paper>
        {/* AT-406 */}
        {/* <Paper className={classes.paper}> */}
        {/* <TransferringEquipmentTable */}
        {/* type="receiver" */}
        {/* toolbarTitle={i18next.t('equipment_web_atoir:receivedEquipmentListTitle')} */}
        {/* onAccept={this.onAcceptDenyEquipment(true)} */}
        {/* onDeny={this.onAcceptDenyEquipment(false)} */}
        {/* page={transferringEquipmentTablePageMe} */}
        {/* rowsPerPage={transferringEquipmentTableRowsPerPageMe} */}
        {/* onPageChanged={this.handleChangePage('transferringEquipmentTablePageMe')} */}
        {/* onRowsPerPageChanged={this.handleChangeRowsPerPage('transferringEquipmentTableRowsPerPageMe')} */}
        {/* /> */}
        {/* </Paper> */}
        {/* <Paper className={classes.paper}> */}
        {/* <TransferringEquipmentTable */}
        {/* type="sender" */}
        {/* toolbarTitle={i18next.t('equipment_web_atoir:transferringEquipmentListTitle')} */}
        {/* onAccept={this.onAcceptDenyEquipment(true)} */}
        {/* onDeny={this.onAcceptDenyEquipment(false)} */}
        {/* page={transferringEquipmentTablePage} */}
        {/* rowsPerPage={transferringEquipmentTableRowsPerPage} */}
        {/* onPageChanged={this.handleChangePage('transferringEquipmentTablePage')} */}
        {/* onRowsPerPageChanged={this.handleChangeRowsPerPage('transferringEquipmentTableRowsPerPage')} */}
        {/* /> */}
        {/* </Paper> */}
      </div>
    )
  }
}

EquipmentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EquipmentComponent)
