import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import ConditionsConstructor from './ConditionsConstructor'
import PropTypes from 'prop-types'

const styles = {}

class ConditionsConstructorWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.conditions !== this.props.conditions ||
      nextProps.errors !== this.props.errors ||
      nextProps.logic !== this.props.logic ||
      nextProps.touched !== this.props.touched
    )
  }

  onChangeHandler = (idValue, value) => {
    const { onChange } = this.props
    onChange(idValue, value)
  }

  render() {
    const {
      conditions,
      logic,
      idValueConditions,
      idValueLogic,
      errors,
      touched,
    } = this.props
    const { onChangeHandler } = this
    return (
      <Fragment>
        <ConditionsConstructor
          idValueConditions={idValueConditions}
          idValueLogic={idValueLogic}
          conditions={conditions}
          logic={logic}
          onChange={onChangeHandler}
          errors={errors || {}}
          touched={touched}
        />
      </Fragment>
    )
  }
}

ConditionsConstructorWrapper.defaultProps = {}

ConditionsConstructorWrapper.propTypes = {
  conditions: PropTypes.object.isRequired,
  logic: PropTypes.string.isRequired,
  idValueConditions: PropTypes.string.isRequired,
  idValueLogic: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.oneOf([PropTypes.object, PropTypes.string]).isRequired,
  touched: PropTypes.object.isRequired,
}

export default withStyles(styles)(ConditionsConstructorWrapper)
