import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CreateContract from './CreateContract'
import { fetchManagerOrHigherOrganizations } from '../../../../actions/organizationsActions'
import { fetchOrganizationStaff } from '../../../../actions/staffActions'
import { createServiceContractsRequest } from '../../../../actions/serviceDesk'

const mapStateToProps = state => ({
  member: state.organizations.managerOrHigherOrganizations.organizations,
  isFetchingOrganizations:
    state.organizations.managerOrHigherOrganizations.isFetching,
  fetchingOrganizationsError:
    state.organizations.managerOrHigherOrganizations.error,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  isCreatingServiceContract:
    state.serviceDesk.createServiceContracts.isFetching,
  isCreatingServiceContractError:
    state.serviceDesk.createServiceContracts.error,
  contractId: state.serviceDesk.createServiceContracts.contract_id,
  organizationStaff: state.staff.organizationStaff,
  fetchOrganizationStaffErrors: state.staff.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchManagerOrHigherOrganizations,
      fetchOrganizationStaff,
      createServiceContractsRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateContract),
)
