import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

import SelectLimitedAuto from '../../../widgets/SelectLimitedAuto'
import { URL } from '../../../middlewares/api'

import i18n from '../../../i18n/i18n'

const useStyles = makeStyles(() => ({}))

function MyOrganizationSelector({ idValue, value, onChange }) {
  const classes = useStyles()

  const handleChange = _value => {
    onChange(idValue, _value)
  }
  return (
    <FormControl fullWidth className={classes.formControl} required>
      <InputLabel htmlFor={idValue}>
        {i18n.t('trusted_organizations_web_atoir:myOrganizationTitle')}
      </InputLabel>
      <SelectLimitedAuto
        query={{ sort: '[short_name=ASC]' }}
        id={idValue}
        value={value}
        sourceUrl={`${URL}/organizations/admin_or_higher`}
        onChange={handleChange}
        requestResultTransformerToArray={o => o.organizations}
        itemTransformer={o => ({
          id: o.id,
          title: o.short_name,
        })}
      />
    </FormControl>
  )
}

export default MyOrganizationSelector
