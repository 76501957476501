import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmForm from './ConfirmForm'

const mapStateToProps = state => ({
  engineers: state.inventory.engineers.engineers,
  isFetchEngineers: state.inventory.engineers.isFetch,
  project: state.inventory.project,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmForm),
)
