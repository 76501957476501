import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../../../../i18n/i18n'

// material-ui
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import SecondaryButton from '../../../../../widgets/Buttons/SecondaryButton'

const DeleteInventoryLocationDialogComponent = props => (
  <Dialog
    open={props.open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {i18next.t('inventory_web_atoir:deleteInventoryLocationDialogTitle')}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {i18next.t('inventory_web_atoir:deleteInventoryLocationDialogText')}
      </DialogContentText>
      <Typography>
        {!isEmpty(props.location.location) ? props.location.location.home : ''}
      </Typography>
    </DialogContent>
    <DialogActions>
      <SecondaryButton onClick={props.handleClose}>
        {i18next.t('shared_web_atoir:cancel')}
      </SecondaryButton>
      <Button onClick={props.handleDelete} autoFocus>
        {i18next.t('shared_web_atoir:delete')}
      </Button>
    </DialogActions>
  </Dialog>
)

DeleteInventoryLocationDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

export default DeleteInventoryLocationDialogComponent
