import React from 'react'
import PropTypes from 'prop-types'

import TagCloseable from '../../../../../../widgets/TagCloseable'

class TagCloseableContainer extends React.Component {
  componentDidUpdate(prevProps) {
    const { detaching, error } = this.props
    if (detaching !== prevProps.detaching && !detaching && !error) {
      const { detachDone } = this.props
      detachDone()
    }
  }

  deleteTag = () => {
    const { detachTag, callId, tagId } = this.props
    detachTag({
      callId,
      tagId,
    })
  }

  render() {
    const {
      props: { color, name },
      deleteTag,
    } = this
    return <TagCloseable deleteTag={deleteTag} color={color} name={name} />
  }
}

TagCloseableContainer.propTypes = {
  tagId: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  detachTag: PropTypes.func.isRequired,
  detachDone: PropTypes.func.isRequired,
}

export default TagCloseableContainer
