import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getCallsByGeneralSearch,
  clearCallsSearchByNumber,
} from '../../../../actions/serviceDesk'
import RequestsSearchList from './RequestsSearchList'

const mapStateToProps = state => ({
  isFetchingCallsByNumberSearch:
    state.serviceDesk.callsByNumberSearch.isFetching,
  callsByNumberSearch: state.serviceDesk.callsByNumberSearch.calls,
  searchFor: state.serviceDesk.callsByNumberSearch.searchFor,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCallsByGeneralSearch,
      clearCallsSearchByNumber,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestsSearchList),
)
