import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AddLocationComponent from './AddLocationComponent'
import {
  createLocation,
  fetchLocations,
} from '../../../../actions/locationsActions'

const mapStateToProps = state => ({
  // isAddLocationOpen: state.locations.isAddLocationOpen,
  organization: state.organizations.organization,
  isCreatingLocation: state.locations.isCreatingLocation,
  error: state.locations.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createLocation,
      fetchLocations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddLocationComponent),
)
