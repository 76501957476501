import {
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_REQUEST,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
} from '../constants/actionTypes'

const initialState = {
  isCreatingContact: false,
  isUpdatingContact: false,
  isFetchingContacts: false,
  isFetchingContact: false,
  contacts: [],
  error: 0,
}

const organizationContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONTACT_REQUEST:
      return {
        ...state,
        isCreatingContact: true,
        error: undefined,
      }

    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        isCreatingContact: false,
        error: undefined,
      }

    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.code,
        isCreatingContact: false,
      }

    case UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        isUpdatingContact: true,
        error: undefined,
      }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        isUpdatingContact: false,
        error: undefined,
      }

    case UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.code,
        isUpdatingContact: false,
      }

    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        isFetchingContacts: true,
        error: undefined,
      }

    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.data,
        isFetchingContacts: false,
        error: undefined,
      }

    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.code,
        isFetchingContacts: false,
      }

    case DELETE_CONTACT_REQUEST:
      return {
        ...state,
        isDeletingContact: true,
        error: undefined,
      }

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: _.filter(state.contacts, c => action.data !== c.id),
        isDeletingContact: false,
        error: undefined,
      }

    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.code,
        isDeletingContact: false,
      }

    default:
      return state
  }
}

export default organizationContactsReducer
