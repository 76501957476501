import React, { Component, Fragment } from 'react'
// import PropTypes from 'prop-types'
import { popLastSections } from '../../../../../helpers/dataHelper'
import i18next from '../../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

// components
import BreadCrumb from '../../../../App/routing/BreadCrumb'

import CreateEditButtonForm from './CreateEditButtonForm'
import isEmpty from 'is-empty'

// helpers
import { conditionsToArray } from '../../Components/ConditionsConstructor'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
})

const scripPreProcessing = button => {
  let out = button
  out.actions = button.CustomActionTemplates
  out.conditions = {}
  out.conditions.simple = button.simple_conditions || {}
  out = _.pick(out, [
    'name',
    'description',
    'show_in',
    'scope',
    'conditions',
    'logic',
    'actions',
  ])
  let { actions } = out
  actions = actions.map(item => {
    const itemRet = item
    itemRet.options = itemRet.action_options
    return _.pick(itemRet, [
      'action_type',
      'custom_tag_id',
      'email_template_id',
      'options',
    ])
  })
  out.actions = actions
  return out
}

const buttonPostProcessing = button => {
  const out = button
  if (out.description === '') out.description = null
  if (conditionsToArray((out.conditions || {}).simple || []).length <= 1)
    out.logic = null
  return out
}

const stubForNewButton = {
  name: '',
  scope: ['owner'],
  description: '',
  show_in: [],
  conditions: {},
  logic: null,
  actions: [
    {
      action_type: null,
      custom_tag_id: null,
      email_template_id: null,
      options: {
        sender_address: {
          type: 'custom',
          address: null,
        },
        recipient_address: {
          type: 'custom',
          address: null,
        },
      },
    },
  ],
}

const initButton = {
  name: '',
  scope: [],
  description: '',
  show_in: [],
  conditions: {
    simple: {},
  },
  logic: '',
  actions: [
    {
      action_type: '',
      custom_tag_id: '',
      email_template_id: '',
      options: {
        sender_address: {
          type: 'custom',
          address: '',
        },
        recipient_address: {
          type: 'custom',
          address: '',
        },
      },
    },
  ],
}

class CreateEditButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      button: initButton,
    }
  }

  componentDidMount = () => {
    const {
      props: {
        setNavigationDrawer,
        match,
        fetchOrganization,
        getButtonRequest,
      },
    } = this
    if (match.params.createEditButton === 'create') {
      this.setState({ edit: false, button: stubForNewButton })
    }
    if (match.params.createEditButton === 'edit') {
      this.setState({ edit: true })
      getButtonRequest({
        organizationId: match.params.organizationId,
        buttonId: match.params.buttonId,
      })
    }
    setNavigationDrawer([])
    fetchOrganization(match.params.organizationId)
  }

  componentDidUpdate = prevProps => {
    const {
      isCreatingButton,
      isEditing,
      createButtonError,
      history,
      match,
      isFetching,
      getButtonError,
      button,
      editButtonError,
    } = this.props
    if (
      (isCreatingButton !== prevProps.isCreatingButton &&
        !isCreatingButton &&
        isEmpty(createButtonError)) ||
      (isEditing !== prevProps.isEditing &&
        !isEditing &&
        isEmpty(editButtonError))
    ) {
      history.push(`/organizations/${match.params.organizationId}/settings`)
    }
    if (
      isFetching !== prevProps.isFetching &&
      !isFetching &&
      isEmpty(getButtonError)
    ) {
      this.setState({ button: scripPreProcessing(button) })
    }
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  onSubmit = button => {
    const {
      props: { match },
      state: { edit },
    } = this
    const data = buttonPostProcessing(button)
    data.name = data.name.trim()
    const payload = { organizationId: match.params.organizationId, data }
    if (edit) {
      this.props.editButtonRequest({
        ...payload,
        buttonId: match.params.buttonId,
      })
    } else {
      this.props.createButtonRequest(payload)
    }
  }

  render() {
    const {
      props: { classes, match, organization, history },
      state: { edit, button },
      onSubmit,
    } = this
    return (
      <Fragment>
        <BreadCrumb
          length={29}
          to={`${
            edit ? popLastSections(match.url, 4) : popLastSections(match.url, 3)
          }/profile`}
        >
          {organization.short_name}
        </BreadCrumb>
        <BreadCrumb
          to={
            edit ? popLastSections(match.url, 3) : popLastSections(match.url, 2)
          }
        >
          {i18next.t('organizations_web_atoir:organizationSettings')}
        </BreadCrumb>
        {edit ? (
          <BreadCrumb to={match.url}>{button.name || ''}</BreadCrumb>
        ) : (
          <BreadCrumb to={match.url}>
            {i18next.t('organizations_web_atoir:creatingBuuton')}
          </BreadCrumb>
        )}
        <Typography variant="h4" gutterBottom className={classes.marginBtm}>
          {edit
            ? i18next.t('organizations_web_atoir:editingButton')
            : i18next.t('organizations_web_atoir:creatingBuuton')}
        </Typography>
        <CreateEditButtonForm
          button={button}
          onSubmit={onSubmit}
          organizationId={match.params.organizationId}
          history={history}
        />
      </Fragment>
    )
  }
}

CreateEditButtons.defaultProps = {}

CreateEditButtons.propTypes = {}

export default withStyles(styles)(CreateEditButtons)
