import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import RequestDocs from './RequestDocs'
import {
  deleteDocServiceDeskCall,
  getServiceCallDocumentLinkRequest,
} from '../../../../../actions/serviceDesk'
import { showDialogWithOk } from '../../../../../actions/appActions'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteDocServiceDeskCall,
      showDialogWithOk,
      getServiceCallDocumentLinkRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestDocs),
)
