import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AutomaticScripts from './AutomaticScripts'
import {
  getScriptsRequest,
  deleteScriptRequest,
} from '../../../../actions/scriptActions'
import { updateSettingsLists } from '../../../../actions/settingsActions'

const mapStateToProps = state => ({
  isFetching: state.scripts.getScripts.isFetching,
  scripts: state.scripts.getScripts.scripts,
  getScriptsError: state.scripts.getScripts.error,
  isDeleting: state.scripts.deleteScript.isFetching,
  deleteScriptError: state.scripts.deleteScript.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getScriptsRequest,
      deleteScriptRequest,
      updateSettingsLists,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutomaticScripts),
)
