import * as constants from '../constants/actionTypes/scripts'

const initialState = {
  createScript: {
    isFetching: false,
    error: null,
  },
  editScript: {
    isFetching: false,
    error: null,
  },
  getScripts: {
    isFetching: false,
    scripts: [],
    error: null,
  },
  deleteScript: {
    isFetching: false,
    error: null,
  },
  getScript: {
    isFetching: false,
    script: [],
    error: null,
  },
  history: {
    isFetching: false,
    history: [],
    end: false,
    error: null,
  },
  getSDCallAvailableScripts: {
    isFetching: false,
    scripts: [],
    error: null,
  },
}

const scripts = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_SCRIPT_REQUEST:
      return {
        ...state,
        createScript: {
          ...state.createScript,
          isFetching: true,
          error: null,
        },
      }

    case constants.CREATE_SCRIPT_SUCCESS:
      return {
        ...state,
        createScript: {
          ...state.createScript,
          isFetching: false,
        },
      }

    case constants.CREATE_SCRIPT_FAILURE:
      return {
        ...state,
        createScript: {
          ...state.createScript,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.EDIT_SCRIPT_REQUEST:
      return {
        ...state,
        editScript: {
          ...state.editScript,
          isEditing: true,
          error: null,
        },
      }

    case constants.EDIT_SCRIPT_SUCCESS:
      return {
        ...state,
        editScript: {
          ...state.editScript,
          isEditing: false,
        },
      }

    case constants.EDIT_SCRIPT_FAILURE:
      return {
        ...state,
        editScript: {
          ...state.editScript,
          isEditing: false,
          error: action.payload,
        },
      }

    case constants.GET_SCRIPTS_REQUEST:
      return {
        ...state,
        getScripts: {
          ...state.getScripts,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_SCRIPTS_SUCCESS:
      return {
        ...state,
        getScripts: {
          ...state.getScripts,
          isFetching: false,
          scripts: action.payload,
        },
      }

    case constants.GET_SCRIPTS_FAILURE:
      return {
        ...state,
        getScripts: {
          ...state.getScripts,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.DELETE_SCRIPT_REQUEST:
      return {
        ...state,
        deleteScript: {
          ...state.deleteScript,
          isFetching: true,
          error: null,
        },
      }

    case constants.DELETE_SCRIPT_SUCCESS:
      return {
        ...state,
        deleteScript: {
          ...state.deleteScript,
          isFetching: false,
        },
      }

    case constants.DELETE_SCRIPT_FAILURE:
      return {
        ...state,
        deleteScript: {
          ...state.deleteScript,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_SCRIPT_REQUEST:
      return {
        ...state,
        getScript: {
          ...state.getScript,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_SCRIPT_SUCCESS:
      return {
        ...state,
        getScript: {
          ...state.getScript,
          isFetching: false,
          script: action.payload,
        },
      }

    case constants.GET_SCRIPT_FAILURE:
      return {
        ...state,
        getScript: {
          ...state.getScript,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_SCRIPTS_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_SCRIPTS_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          history: [
            ...(state.history.history || []),
            ...action.payload.history,
          ],
          end: action.payload.history.length === 0 || action.payload.end,
        },
      }

    case constants.GET_SCRIPTS_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.CLEAR_SCRIPTS_HISTORY:
      return {
        ...state,
        history: initialState.history,
      }

    case constants.GET_SD_CALL_AVAILABLE_SCRIPTS_REQUEST:
      return {
        ...state,
        getSDCallAvailableScripts: {
          ...state.getSDCallAvailableScripts,
          isFetching: true,
          error: null,
        },
      }

    case constants.GET_SD_CALL_AVAILABLE_SCRIPTS_SUCCESS:
      return {
        ...state,
        getSDCallAvailableScripts: {
          ...state.getSDCallAvailableScripts,
          isFetching: false,
          scripts: action.payload,
        },
      }

    case constants.GET_SD_CALL_AVAILABLE_SCRIPTS_FAILURE:
      return {
        ...state,
        getSDCallAvailableScripts: {
          ...state.getSDCallAvailableScripts,
          isFetching: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default scripts
