import { createStore, applyMiddleware, compose } from 'redux'

// middleware
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

// imports
import rootSaga from '../middlewares/rootSaga'
import reducer from '../reducers/rootReducer'
import disableScriptsMiddleware from '../middlewares/disableScriptsMiddleware'

const saga = createSagaMiddleware()
const middleware =
  process.env.NODE_ENV !== 'development'
    ? applyMiddleware(thunk, disableScriptsMiddleware, saga)
    : applyMiddleware(thunk, disableScriptsMiddleware, saga, logger)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store =
  process.env.NODE_ENV !== 'development'
    ? createStore(reducer, compose(middleware))
    : createStore(reducer, composeEnhancers(middleware))

saga.run(rootSaga)

export default store
