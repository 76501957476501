import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../../../../i18n/i18n'

// ---material--
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TextField from '@material-ui/core/TextField'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const TableHeadComponent = ({
  order,
  orderBy,
  shortName,
  handleRequestSort,
  handleChange,
  classes,
}) => (
  <TableHead>
    <TableRow>
      <TableCell padding="dense">
        <TableSortLabel
          active={orderBy === 'date_created'}
          direction={order ? order.toLowerCase() : order}
          onClick={handleRequestSort('date_created')}
        >
          {i18next.t('inventory_web_atoir:create')}
        </TableSortLabel>
      </TableCell>
      <TableCell padding="dense">
        <TableSortLabel
          active={orderBy === 'date_actual_to'}
          direction={order ? order.toLowerCase() : order}
          onClick={handleRequestSort('date_actual_to')}
        >
          {i18next.t('inventory_web_atoir:actualTo')}
        </TableSortLabel>
      </TableCell>
      <TableCell padding="dense">
        <TableSortLabel
          active={orderBy === 'short_name'}
          direction={order ? order.toLowerCase() : order}
          onClick={handleRequestSort('short_name')}
        >
          {i18next.t('inventory_web_atoir:performer')}
        </TableSortLabel>
        <TextField
          name="shortName"
          type="text"
          defaultValue={shortName === null ? '' : shortName}
          onChange={handleChange}
          className={classes.textField}
          value={shortName === null ? '' : shortName}
        />
      </TableCell>
      <TableCell padding="dense">
        {i18next.t('inventory_web_atoir:rating')}
      </TableCell>
      <TableCell padding="dense">
        <TableSortLabel
          active={orderBy === 'amount'}
          direction={order ? order.toLowerCase() : order}
          onClick={handleRequestSort('amount')}
        >
          {i18next.t('inventory_web_atoir:cost')}
        </TableSortLabel>
      </TableCell>
      <TableCell />
    </TableRow>
  </TableHead>
)

const styles = () => ({})

TableHeadComponent.defaultProps = {
  shortName: '',
}

TableHeadComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.string.isRequired,
  shortName: PropTypes.string,
  handleRequestSort: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(TableHeadComponent)
