import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import Subheading from '../../../widgets/Lettering/Subheading'
import OrganizationEquipmentTable from '../OrganizationEquipmentTable'
import CanOrganizationEquipment from './OrganizationAbility/CanOrganizationEquipment'

// material-icons
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider/Divider'
import Paper from '@material-ui/core/Paper/Paper'

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

const handleAddEquipmentClick = () => {}

let EnhancedTableToolbar = props => {
  const { numSelected, classes, organization, match } = props
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Subheading>
          {i18next.t('organizations_web_atoir:organizationEquipmentListTitle')}
        </Subheading>
      </div>
      <div className={classes.spacer} />

      <CanOrganizationEquipment I="add" a="OrganizationEquipment">
        <Tooltip title={i18next.t('equipment_web_atoir:addEquipment')}>
          <IconButton
            aria-label={i18next.t('equipment_web_atoir:addEquipment')}
            onClick={handleAddEquipmentClick}
            component={Link}
            to={`${match.url}/equipments/add/${organization.id}`}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </CanOrganizationEquipment>
      <CanOrganizationEquipment I="add" a="OrganizationEquipment">
        <Tooltip title={i18next.t('equipment_web_atoir:addEquipmentList')}>
          <IconButton
            aria-label={i18next.t('equipment_web_atoir:addEquipmentList')}
            component={Link}
            to={`${match.url}/import_equipments`}
          >
            <ListIcon />
          </IconButton>
        </Tooltip>
      </CanOrganizationEquipment>
    </Toolbar>
  )
}
EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
  }),
})

class OrganizationEquipmentComponent extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
    }
  }

  componentDidMount = () => {
    const {
      fetchOrganizationEquipment,
      organization,
      fetchOrganizationEquipmentCount,
      rowsPerPage,
    } = this.props
    const { page } = this.state
    fetchOrganizationEquipment(organization.id, {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      withCount: true,
    })
    fetchOrganizationEquipmentCount(organization.id)
  }

  render() {
    const {
      classes,
      organization,
      fetchOrganizationEquipment,
      fetchOrganizationEquipmentCount,
      match,
    } = this.props
    return (
      <Paper classes={classes.root}>
        <EnhancedTableToolbar match={match} organization={organization} />
        <Divider />
        <OrganizationEquipmentTable
          organization={organization}
          fetchOrganizationCount={fetchOrganizationEquipmentCount}
          fetchEquipment={fetchOrganizationEquipment}
          classes={classes}
        />
      </Paper>
    )
  }
}

OrganizationEquipmentComponent.propTypes = propTypes

export default withStyles(styles)(OrganizationEquipmentComponent)
