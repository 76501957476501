import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import * as organizationsActions from '../../../../actions/organizationsActions'
import { withTablePaginationHOC } from '../../../../helpers/tablePaginationHOC'

import Contragents from './Contragents'

const mapStateToProps = state => ({
  creatingIsFetching:
    state.organizations.createOrganizationContragent.isFetching,
  creatingError: state.organizations.createOrganizationContragent.error,
  organizationSettings: state.organizations.organizationSettings.value,
  organizationSettingsIsFetching:
    state.organizations.organizationSettings.isFetching,
  editRestrictionSdCallsFromNonContragentsIsFetching:
    state.organizations.editRestrictionSdCallsFromNonContragents.isFetching,
  deleteOrganizationContragentIsFetching:
    state.organizations.deleteOrganizationContragent.isFetching,
  editOrganizationContragentIsFetching:
    state.organizations.editContragent.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callCreateOrganizationContragent:
        organizationsActions.createOrganizationContragent.REQUEST,
      callGetOrganizationSettings:
        organizationsActions.getOrganizationSettings.REQUEST,
      callGetOrganizationContragents:
        organizationsActions.getOrganizationContragents.REQUEST,
      callDeleteOrganizationContragent:
        organizationsActions.deleteOrganizationContragent.REQUEST,
      callEditOrganizationContragent:
        organizationsActions.editOrganizationContragent.REQUEST,
      callEditRestrictionSdCallsFromNonContragents:
        organizationsActions.editRestrictionSdCallsFromNonContragents.REQUEST,
    },
    dispatch,
  )
const optionsHOC = {
  propNameData: 'organizationContragents',
  propNameUpdateData: 'organizationContragentsUpdate',
  propNameIsFetching: 'organizationContragentsIsFetching',
  propNameCount: 'organizationContragentsCount',
  propNameSetRequestPayload: 'setOrganizationContragentRequestPayload',
  rowsPerPageDefault: 5,
}
const mapStateToPropsForHOC = state => ({
  data: state.organizations.organizationContragents.value,
  isFetching: state.organizations.organizationContragents.isFetching,
  count: state.organizations.organizationContragents.count,
  isFetchingCount: state.organizations.organizationContragents.isFetching,
})

const mapDispatchToPropsForHOC = dispatch =>
  bindActionCreators(
    {
      fetchAction: payload =>
        organizationsActions.getOrganizationContragents.REQUEST(payload),
    },
    dispatch,
  )

export default compose(
  withTablePaginationHOC(
    mapStateToPropsForHOC,
    mapDispatchToPropsForHOC,
    optionsHOC,
  ),
  connect(mapStateToProps, mapDispatchToProps),
)(Contragents)
