import React, { Component, Fragment } from 'react'

import { round10 } from 'round10'
// i18n
import i18next from '../../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedTableRow from './EnhancedTableRow'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

// components
import TextButton from '../../../../../widgets/Buttons/TextButton'

const styles = theme => ({
  root: {
    width: 1400,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1020,
  },
  tableCell: {
    border: 0,
    padding: 0,
    fontSize: '12px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  divActions: {
    display: 'flex',
    minHeight: '66px',
    alignItems: 'center',
    backgroundColor: lighten(theme.palette.secondary.light, 0.95),
  },
  divActionsRight: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  button: {
    color: theme.palette.second.contrastText,
    background: theme.palette.second.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      backgroundColor: theme.palette.main.main,
      color: theme.palette.main.contrastText,
    },
  },
  customBtnBlue: {
    padding: '8px 8px',
    color: theme.palette.second.contrastText,
    background: theme.palette.second.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      backgroundColor: theme.palette.main.main,
      color: theme.palette.main.contrastText,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
})

const calcTotal = (data, overhead) => {
  let total = 0
  data.forEach(row => {
    if (
      Number.isFinite(Number(row.price_purchase)) &&
      Number.isFinite(Number(row.count)) &&
      Number.isFinite(Number(row.percent))
    ) {
      total += round10(
        round10(
          Number(row.price_purchase) * ((Number(row.percent) || 0) / 100 + 1),
          -2,
        ) * Number(row.count),
        -2,
      )
    }
  })
  return round10(total * ((100 + Number(overhead)) / 100), -2)
}

const calcOverhead = (data, overhead) => {
  let total = 0
  data.forEach(row => {
    if (
      Number.isFinite(Number(row.price_purchase)) &&
      Number.isFinite(Number(row.count)) &&
      Number.isFinite(Number(row.percent))
    ) {
      total +=
        Number(row.price_purchase) *
        Number(row.count) *
        ((row.percent || 0) / 100 + 1) *
        (Number(overhead) / 100)
    }
  })
  return total
}

class CompletionCertificate extends Component {
  state = {
    total: 0,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.dataInProps !== nextProps.data) {
      const curFormat = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      const total = calcTotal(nextProps.data, nextProps.overhead)
      const overheadSum = calcOverhead(nextProps.data, nextProps.overhead)
      const nds = total * (nextProps.ndsValue / 100)
      const totalAndNds = round10(total * (1 + nextProps.ndsValue / 100), -2)
      return {
        dataInProps: nextProps.data,
        overheadSum: curFormat.format(overheadSum),
        total: curFormat.format(total),
        nds: curFormat.format(nds),
        totalAndNds: curFormat.format(totalAndNds),
      }
    }
    return null
  }

  render() {
    const {
      props: {
        classes,
        data,
        addRow,
        delRow,
        handleChange,
        ndsValue,
        inputNDS,
        overhead,
        itemsEditable,
        orderPerformerOrganization,
        showClientVersion,
      },
      state: { total, nds, totalAndNds, overheadSum },
    } = this
    return (
      <Fragment>
        {data &&
          data.length > 0 &&
          data.map(h => (
            <EnhancedTableRow
              orderPerformerOrganization={orderPerformerOrganization}
              data={h}
              key={h.id}
              handleChange={handleChange}
              delRow={delRow}
              ndsValue={ndsValue}
              inputNDS={inputNDS}
              editable={itemsEditable}
              clientVersion={showClientVersion}
            />
          ))}
        <TableRow>
          <TableCell colSpan={6} className={classes.tableCell} />
          {itemsEditable && (
            <TableCell colSpan={3} align="right" className={classes.tableCell}>
              <TextButton onClick={addRow}>
                <AddCircleOutlineIcon className={classes.leftIcon} />
                {i18next.t('service_desk_web_atoir:addNewLineAction')}
              </TextButton>
            </TableCell>
          )}
          <TableCell className={classes.tableCell} />
        </TableRow>
        {overhead > 0 && (
          <TableRow>
            <TableCell colSpan={4} className={classes.tableCell} />
            <TableCell colSpan={2} align="right" className={classes.tableCell}>
              {i18next.t('service_desk_web_atoir:overheadSum')}
            </TableCell>
            <TableCell colSpan={3} align="right" className={classes.tableCell}>
              {overheadSum}
            </TableCell>
            <TableCell className={classes.tableCell} />
          </TableRow>
        )}
        <TableRow>
          <TableCell colSpan={4} className={classes.tableCell} />
          <TableCell colSpan={2} align="right" className={classes.tableCell}>
            {i18next.t('service_desk_web_atoir:totalSum')}
          </TableCell>
          <TableCell colSpan={3} align="right" className={classes.tableCell}>
            {total}
          </TableCell>
          <TableCell className={classes.tableCell} />
        </TableRow>
        {ndsValue > 0 && (
          <Fragment>
            <TableRow>
              <TableCell
                colSpan={4}
                rowSpan={2}
                className={classes.tableCell}
              />
              <TableCell
                colSpan={2}
                align="right"
                className={classes.tableCell}
              >
                {i18next.t('service_desk_web_atoir:goodsAndServicesTax')}
              </TableCell>
              <TableCell
                colSpan={3}
                align="right"
                className={classes.tableCell}
              >
                {nds}{' '}
              </TableCell>
              <TableCell className={classes.tableCell} />
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={2}
                align="right"
                className={classes.tableCell}
              >
                {i18next.t(
                  'service_desk_web_atoir:totalWithGoodsAndServicesTax',
                )}
              </TableCell>
              <TableCell
                colSpan={3}
                align="right"
                className={classes.tableCell}
              >
                {totalAndNds}
              </TableCell>
              <TableCell className={classes.tableCell} />
            </TableRow>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

CompletionCertificate.defaultProps = {
  itemsEditable: true,
  showClientVersion: false,
}

CompletionCertificate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  delRow: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  ndsValue: PropTypes.number.isRequired,
  orderPerformerOrganization: PropTypes.string.isRequired,
  itemsEditable: PropTypes.bool,
  showClientVersion: PropTypes.bool,
}

export default withStyles(styles)(CompletionCertificate)
