import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  fetchOrganizationStaff,
  fetchOrganizationLocations,
} from '../../../../../actions/staffActions'
import { offerInventoryProject } from '../../../../../actions/inventoryActions'

import DialogAddKPComponent from './DialogAddKPComponent'
import { fetchManagerOrHigherOrganizations } from '../../../../../actions/organizationsActions'

const mapStateToProps = state => ({
  organizations: state.organizations.managerOrHigherOrganizations.organizations,
  isFetchingOrganizations:
    state.organizations.managerOrHigherOrganizations.isFetching,
  staff: state.staff.organizationStaff,
  isFetchingStaff: state.staff.isFetchingOrganizationStaff,
  locations: state.staff.locations,
  isFetchingLocations: state.staff.isFetchingLocations,
  isOffer: state.inventory.offerInventoryProject.isOffer,
  offerSuccessfully: state.inventory.offerInventoryProject.offerSuccessfully,
  error: state.inventory.offerInventoryProject.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchManagerOrHigherOrganizations,
      fetchOrganizationStaff,
      fetchOrganizationLocations,
      offerInventoryProject,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DialogAddKPComponent),
)
