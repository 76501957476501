import axios from 'axios'
import config from '../config'

const baUsername = config.bau
const baPassword = config.bap
const auth = `Basic ${Buffer.from(`${baUsername}:${baPassword}`).toString(
  'base64',
)}`
export const URL =
  process.env.NODE_ENV !== 'development'
    ? process.env.API_PATH || '/api'
    : 'http://localhost:3001'

export const axiosFetcher = async (
  method,
  route,
  data = null,
  params = null,
) => {
  let result
  try {
    result = await axios({
      method,
      url: `${URL}${route}`,
      params,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth,
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
  } catch (e) {
    return {
      success: false,
      code: e.code || 66,
    }
  }
  return result.data
}
