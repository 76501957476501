import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton/IconButton'

// icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

// We can inject some CSS into the DOM.
const styles = theme => ({
  buttonMy: {
    padding: 4,
    borderColor: theme.palette.blueSecondary.main,
  },
})

function CollapseButton(props) {
  const { isCollapsed, onClick } = props
  return (
    <IconButton
      disableRipple
      onClick={onClick}
      className={props.classes.buttonMy}
    >
      {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}
    </IconButton>
  )
}

CollapseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
}

export default withStyles(styles)(CollapseButton)
