import React, { Component } from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../../i18n/i18n'

// components
import Loader from '../../../../Loader'
import Dialog from '../../../../Other/SimpleDialog'
import ProjectHeading from '../InventoryProjectHeading'
import ProjectClient from '../InventoryProjectClientInfo'

// material-ui
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

class BaseInfoComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      openCancelDialog: false,
    }
  }

  componentWillReceiveProps = props => {
    if (
      props.isFetchingProject !== this.props.isFetchingProject &&
      props.isFetchingProject === false
    ) {
      if (props.error) {
        switch (props.error) {
          case 179:
            props.history.push('/404')
            break
          case 52:
            props.history.push('/404')
            break
          default:
            break
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: { isCancellingProject, cancelledProjectSuccessfully },
      handleCancelDialog,
      goBack,
    } = this

    if (
      prevProps.isCancellingProject !== isCancellingProject &&
      !isCancellingProject
    ) {
      handleCancelDialog()
      if (cancelledProjectSuccessfully) {
        goBack()
      }
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleCancelDialog = () => {
    const {
      state: { openCancelDialog },
    } = this
    this.setState({ openCancelDialog: !openCancelDialog })
  }

  cancelProject = () => {
    const {
      props: { cancelInventoryProject, inventoryProject },
    } = this
    cancelInventoryProject(
      inventoryProject.client_organization_id,
      inventoryProject.id,
    )
  }

  render = () => {
    const {
      props: {
        inventoryProject,
        isFetchingProject,
        isCancellingProject,
        canCancelProject,
      },
      state: { openCancelDialog },
      handleCancelDialog,
      cancelProject,
    } = this
    if (isFetchingProject || !inventoryProject) {
      return <Loader />
    }
    const {
      ipl: projectLocations,
      Client: clientOrganization,
      Performer: performerOrganization,
      rating_toclient: ratingToClient,
      rating_toclient_comment: commentToClient,
      rating_toperformer: ratingToPerformer,
      rating_toperformer_comment: commentToPerformer,
    } = inventoryProject
    const projectRatings = {
      ratingToClient,
      commentToClient,
      ratingToPerformer,
      commentToPerformer,
    }
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <ProjectHeading
            created={new Date(inventoryProject.date_created)}
            projectId={inventoryProject.id}
            status={inventoryProject.status}
            type={inventoryProject.inventory_type}
            kit={inventoryProject.inventory_kit}
            canCancelProject={canCancelProject}
            handleCancelDialog={handleCancelDialog}
          />
        </Grid>
        <Grid item xs={12}>
          <ProjectClient
            clientOrganization={clientOrganization}
            performerOrganization={performerOrganization}
            clientRating={
              inventoryProject.rating_toclient
                ? inventoryProject.rating_toclient
                : 5
            }
            projectLocations={projectLocations}
            projectResponsible={inventoryProject.ClientResponsible}
            projectBeginDesired={inventoryProject.desired_period_begin}
            projectEndDesired={inventoryProject.desired_period_end}
            projectComment={inventoryProject.client_comment}
            status={inventoryProject.status}
            projectRatings={projectRatings}
          />
        </Grid>
        <Dialog
          isOpen={openCancelDialog}
          title={i18next.t('inventory_web_atoir:cancelInventoryProjectTitle')}
          content={i18next.t('inventory_web_atoir:cancelInventoryProjectText')}
          handleClose={handleCancelDialog}
          handleNegative={handleCancelDialog}
          handlePositive={cancelProject}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          positiveText={i18next.t('inventory_web_atoir:cancelInventoryProject')}
          isLoading={isCancellingProject}
        />
      </Grid>
    )
  }
}

const styles = theme => ({
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
})

BaseInfoComponent.propTypes = {
  // fetchInventoryProjectProfile: PropTypes.func.isRequired,
  isFetchingProject: PropTypes.bool.isRequired,
  inventoryProject: PropTypes.object.isRequired,
  cancelInventoryProject: PropTypes.func.isRequired,
  isCancellingProject: PropTypes.bool.isRequired,
  canCancelProject: PropTypes.bool.isRequired,
  cancelledProjectSuccessfully: PropTypes.bool.isRequired,
}

export default withStyles(styles)(BaseInfoComponent)
