import React from 'react'
import PropTypes from 'prop-types'
import shortId from 'shortid'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = {
  menuItem: {
    minHeight: 36,
  },
}

const FormControlSelect = props => {
  const {
    onChange,
    value,
    label,
    values,
    disabled,
    inputLabelProps,
    error,
    helperText,
    required,
    classes,
  } = props
  const id = shortId.generate()
  return (
    <FormControl
      fullWidth
      disabled={disabled}
      error={error}
      required={required}
    >
      <InputLabel
        error={error}
        htmlFor={id}
        shrink={!!value}
        {...inputLabelProps}
      >
        {label}
      </InputLabel>
      <Select
        data-cy="FormControl"
        value={value}
        onChange={onChange}
        inputProps={{
          name: 'FormControlSelect',
          id,
          error,
        }}
      >
        {values.map(v => (
          <MenuItem
            className={classes.menuItem}
            data-cy={v.value}
            key={shortId.generate()}
            value={v.value}
          >
            {v.content}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormControlSelect.defaultProps = {
  disabled: false,
  inputLabelProps: {},
  helperText: null,
  error: false,
  required: false,
}

FormControlSelect.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  inputLabelProps: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
}

export default withStyles(styles)(FormControlSelect)
