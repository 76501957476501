import { takeLatest, put, delay } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  fetchInventoryProjectProfileFailure,
  fetchInventoryProjectProfileSuccess,
  createInventoryProjectSuccess,
  createInventoryProjectFailure,
  fetchInventoryProjectsSuccess,
  fetchInventoryProjectsFailure,
  fetchInventoryProjectsCountSuccess,
  fetchInventoryProjectsCountFailure,
  editInventoryProjectSuccess,
  editInventoryProjectFailure,
  putUpOnAuctionSuccess,
  putUpOnAuctionFailure,
  fetchInventoryProjectsCitiesSuccess,
  fetchInventoryProjectsCitiesFailure,
  getInventoryClientsSuccess,
  getInventoryClientsFailure,
  getInventoryPerformersSuccess,
  getInventoryPerformersFailure,
  confirmInventoryProjectAsPerformerSuccess,
  confirmInventoryProjectAsPerformerFailure,
  rejectInventoryProjectAsPerformerSuccess,
  rejectInventoryProjectAsPerformerFailure,
  setOwnPerformerSuccess,
  setOwnPerformerFailure,
  setForeignPerformerSuccess,
  setForeignPerformerFailure,
  offerInventoryProjectSuccess,
  offerInventoryProjectFailure,
  editInventoryProjectResponsibleSuccess,
  editInventoryProjectResponsibleFailure,
  fetchInventoryOffersPerformerSuccess,
  fetchInventoryOffersPerformerFailure,
  fetchInventoryOffersClientSuccess,
  fetchInventoryOffersClientFailure,
  stopInventoryProjectAuctionSuccess,
  stopInventoryProjectAuctionFailure,
  cancelInventoryProjectSuccess,
  cancelInventoryProjectFailure,
  setInventoryProjectDoneSuccess,
  setInventoryProjectDoneFailure,
  setInventoryProjectClosedSuccess,
  setInventoryProjectClosedFailure,
  setInventoryProjectBackInWorkSuccess,
  setInventoryProjectBackInWorkFailure,
  changePerformerRatingSuccess,
  changePerformerRatingFailure,
  changeClientRatingSuccess,
  changeClientRatingFailure,
  confirmOfferInventoryProjectSuccess,
  confirmOfferInventoryProjectFailure,
  cancelOfferInventoryProjectSuccess,
  cancelOfferInventoryProjectFailure,
  fetchInventoryProjectProfileRightsFailure,
  fetchInventoryProjectProfileRightsSuccess,
  fetchEngineersInventorySuccess,
  fetchEngineersInventoryFailure,
  editEngineersInventorySuccess,
  editEngineersInventoryFailure,
  removeEngineersInventorySuccess,
  removeEngineersInventoryFailure,
  fetchFiltersOrdersInventorySuccess,
  fetchFiltersOrdersInventoryFailure,
  fetchOrdersInventorySuccess,
  fetchOrdersInventoryFailure,
  fetchOrdersCountInventorySuccess,
  fetchOrdersCountInventoryFailure,
  removeOrderInventorySuccess,
  removeOrderInventoryFailure,
  editEngineerOrderInventorySuccess,
  editEngineerOrderInventoryFailure,
  fetchHistoryInventorySuccess,
  fetchHistoryInventoryFailure,
  fetchHistoryCountInventorySuccess,
  fetchHistoryCountInventoryFailure,
} from '../actions/inventoryActions'
import {
  CREATE_INVENTORY_PROJECT_REQUEST,
  FETCH_INVENTORY_PROJECTS_REQUEST,
  FETCH_INVENTORY_PROJECT_PROFILE_REQUEST,
  EDIT_INVENTORY_PROJECT_REQUEST,
  PUT_UP_ON_AUCTION_REQUEST,
  INVENTORY_PROJECTS_COUNT_REQUEST,
  INVENTORY_PROJECTS_CITIES_REQUEST,
  GET_INVENTORY_CLIENTS_REQUEST,
  GET_INVENTORY_PERFORMERS_REQUEST,
  INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST,
  INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST,
  SET_OWN_PERFORMER_REQUEST,
  SET_FOREIGN_PERFORMER_REQUEST,
  OFFER_INVENTORY_PROJECT_REQUEST,
  EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST,
  CANCEL_INVENTORY_PROJECT_REQUEST,
  SET_INVENTORY_PROJECT_DONE_REQUEST,
  SET_INVENTORY_PROJECT_CLOSED_REQUEST,
  SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST,
  CHANGE_PERFORMER_RATING_REQUEST,
  CHANGE_CLIENT_RATING_REQUEST,
  FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST,
  FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST,
  FETCH_INVENTORY_OFFERS_CLIENT_REQUEST,
  STOP_INVENTORY_PROJECT_AUCTION_REQUEST,
  CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST,
  CANCEL_OFFER_INVENTORY_PROJECT_REQUEST,
  FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST,
  FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST,
  FETCH_ORDERS_INVENTORY_PROJECT_REQUEST,
  FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST,
  REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST,
  EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST,
  FETCH_HISTORY_INVENTORY_PROJECT_REQUEST,
  FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST,
} from '../constants/actionTypes'
import { errorCodes } from '../constants/errors'
import {
  createInventoryProjectRequest,
  fetchInventoryProfileRequest,
  editInventoryProjectRequest,
  fetchInventoryProjectsRequest,
  putProjectUpOnAuctionRequest,
  inventoryProjectsCountRequest,
  fetchInventoryProjectsCitiesRequest,
  inventoryProjectActionRequest,
  setInventoryProjectStatus,
  setInventoryProjectRating,
  offerInventoryProjectRequest,
  editInventoryProjectResponsibleRequest,
  fetchInventoryProfilePermissionsRequest,
  fetchInventoryProjectsOffersPerformerRequest,
  fetchInventoryProjectsOffersClientRequest,
  fetchEngineersInventoryRequest,
  editEngineersInventoryRequest,
  fetchFiltersOrdersInventoryRequest,
  fetchOrdersInventoryRequest,
  removeEngineerInventoryRequest,
  editEngineerOrderInventoryRequest,
  removeOrderInventoryRequest,
  fetchFiltersInventoryOrganizations,
  fetchHistoryInventoryRequest,
  fetchHistoryCountInventoryRequest,
} from './api'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'

export function* watchCreateInventoryProjectSaga() {
  yield takeLatest(CREATE_INVENTORY_PROJECT_REQUEST, createInventoryProject)
}

export function* watchEditInventoryProjectSaga() {
  yield takeLatest(EDIT_INVENTORY_PROJECT_REQUEST, editInventoryProject)
}

export function* watchFetchInventoryProjectProfileSaga() {
  yield takeLatest(FETCH_INVENTORY_PROJECT_PROFILE_REQUEST, fetchProject)
}

export function* watchFetchInventoryProjectsSaga() {
  yield takeLatest(FETCH_INVENTORY_PROJECTS_REQUEST, fetchInventoryProjects)
}

export function* watchPutProjectUpOnAuctionSaga() {
  yield takeLatest(PUT_UP_ON_AUCTION_REQUEST, putProjectUpOnAuction)
}

export function* watchInventoryProjectsCountSaga() {
  yield takeLatest(
    INVENTORY_PROJECTS_COUNT_REQUEST,
    fetchInventoryProjectsCount,
  )
}

export function* watchFetchInventoryProjectsCitiesSaga() {
  yield takeLatest(
    INVENTORY_PROJECTS_CITIES_REQUEST,
    fetchInventoryProjectsCities,
  )
}

export function* watchGetInventoryClientsSaga() {
  yield takeLatest(GET_INVENTORY_CLIENTS_REQUEST, getInventoryClients)
}

export function* watchGetInventoryPerformersSaga() {
  yield takeLatest(GET_INVENTORY_PERFORMERS_REQUEST, getInventoryPerformers)
}

export function* watchConfirmInventoryProjectAsPerformerSaga() {
  yield takeLatest(
    INVENTORY_PROJECT_CONFIRM_AS_PERFORMER_REQUEST,
    confirmInventoryProjectAsPerformer,
  )
}

export function* watchRejectInventoryProjectAsPerformerSaga() {
  yield takeLatest(
    INVENTORY_PROJECT_REJECT_AS_PERFORMER_REQUEST,
    rejectInventoryProjectAsPerformer,
  )
}

export function* watchSetOwnPerformerSaga() {
  yield takeLatest(SET_OWN_PERFORMER_REQUEST, setOwnPerformer)
}

export function* watchSetForeignPerformerSaga() {
  yield takeLatest(SET_FOREIGN_PERFORMER_REQUEST, setForeignPerformer)
}

export function* watchOfferInventoryProjectSaga() {
  yield takeLatest(OFFER_INVENTORY_PROJECT_REQUEST, offerInventoryProject)
}

export function* watchEditInventoryProjectResponsibleSaga() {
  yield takeLatest(
    EDIT_INVENTORY_PROJECT_RESPONSIBLE_REQUEST,
    editInventoryProjectResponsible,
  )
}

export function* watchFetchInventoryProjectsOffersPerformerSaga() {
  yield takeLatest(
    FETCH_INVENTORY_OFFERS_PERFORMER_REQUEST,
    fetchInventoryProjectsOffersPerformer,
  )
}

export function* watchFetchInventoryProjectsOffersClientSaga() {
  yield takeLatest(
    FETCH_INVENTORY_OFFERS_CLIENT_REQUEST,
    fetchInventoryProjectsOffersClient,
  )
}

export function* watchStopInventoryProjectsAuctionSaga() {
  yield takeLatest(
    STOP_INVENTORY_PROJECT_AUCTION_REQUEST,
    stopInventoryProjectsAuction,
  )
}

export function* watchCancelInventoryProjectSaga() {
  yield takeLatest(CANCEL_INVENTORY_PROJECT_REQUEST, cancelInventoryProject)
}

export function* watchSetInventoryProjectDoneSaga() {
  yield takeLatest(SET_INVENTORY_PROJECT_DONE_REQUEST, setInventoryProjectDone)
}

export function* watchSetInventoryProjectClosedSaga() {
  yield takeLatest(
    SET_INVENTORY_PROJECT_CLOSED_REQUEST,
    setInventoryProjectClosed,
  )
}

export function* watchSetInventoryProjectBackInWorkSaga() {
  yield takeLatest(
    SET_INVENTORY_PROJECT_BACK_IN_WORK_REQUEST,
    setInventoryProjectBackInWork,
  )
}

export function* watchChangePerformerRatingSaga() {
  yield takeLatest(CHANGE_PERFORMER_RATING_REQUEST, changePerformerRating)
}

export function* watchChangeClientRatingSaga() {
  yield takeLatest(CHANGE_CLIENT_RATING_REQUEST, changeClientRating)
}

export function* watchFetchProjectPermissionsSaga() {
  yield takeLatest(
    FETCH_INVENTORY_PROJECT_PROFILE_RIGHTS_REQUEST,
    fetchProjectPermissions,
  )
}

export function* watchConfirmOfferInventoryProjectSaga() {
  yield takeLatest(
    CONFIRM_OFFER_INVENTORY_PROJECT_REQUEST,
    confirmOfferInventoryProject,
  )
}

export function* watchCancelOfferInventoryProjectSaga() {
  yield takeLatest(
    CANCEL_OFFER_INVENTORY_PROJECT_REQUEST,
    cancelOfferInventoryProject,
  )
}

export function* watchFetchEngineersInventorySaga() {
  yield takeLatest(
    FETCH_ENGINEERS_INVENTORY_PROJECT_REQUEST,
    fetchEngineersInventory,
  )
}

export function* watchEditEngineersInventorySaga() {
  yield takeLatest(
    EDIT_ENGINEERS_INVENTORY_PROJECT_REQUEST,
    editEngineersInventory,
  )
}

export function* watchRemoveEngineerInventorySaga() {
  yield takeLatest(
    REMOVE_ENGINEERS_INVENTORY_PROJECT_REQUEST,
    removeEngineerInventory,
  )
}

export function* watchFetchFiltersOrdersIvnentorySaga() {
  yield takeLatest(
    FETCH_FILTERS_ORDERS_INVENTORY_PROJECT_REQUEST,
    fetchFiltersOrdersInventory,
  )
}

export function* watchFetchOrdersIvnentorySaga() {
  yield takeLatest(FETCH_ORDERS_INVENTORY_PROJECT_REQUEST, fetchOrdersInventory)
}

export function* watchRemoveOrderInventorySaga() {
  yield takeLatest(
    REMOVE_ORDERS_INVENTORY_PROJECT_REQUEST,
    removeOrderInventory,
  )
}

export function* watchFetchOrdersCountInventorySaga() {
  yield takeLatest(
    FETCH_ORDERS_COUNT_INVENTORY_PROJECT_REQUEST,
    fetchOrdersCountInventory,
  )
}

export function* watchEditEngineerOrderInventorySaga() {
  yield takeLatest(
    EDIT_ENGINEER_ORDER_INVENTORY_PROJECT_REQUEST,
    editEngineerOrderInventory,
  )
}

export function* watchFetchHistoryInventorySaga() {
  yield takeLatest(
    FETCH_HISTORY_INVENTORY_PROJECT_REQUEST,
    fetchHistoryInventory,
  )
}

export function* watchFetchHistoryCountInventorySaga() {
  yield takeLatest(
    FETCH_HISTORY_COUNT_INVENTORY_PROJECT_REQUEST,
    fetchHistoryCountInventory,
  )
}

function* createInventoryProject(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      createInventoryProjectRequest(accessToken, action.id, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(createInventoryProjectSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(createInventoryProjectFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* editInventoryProject(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      editInventoryProjectRequest(
        accessToken,
        action.id,
        action.projectId,
        action.data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(editInventoryProjectSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(editInventoryProjectFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchProject(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchInventoryProfileRequest(accessToken, action.projectId),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryProjectProfileSuccess(result.project))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryProjectProfileFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchProjectPermissions(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchInventoryProfilePermissionsRequest(accessToken, action.projectId),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryProjectProfileRightsSuccess(result.permissions))
      } else {
        const errors = [errorCodes().result.code]
        yield put(fetchInventoryProjectProfileRightsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* putProjectUpOnAuction(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      putProjectUpOnAuctionRequest(
        accessToken,
        action.organizationId,
        action.projectId,
        action.payload,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(putUpOnAuctionSuccess())
      } else {
        yield put(putUpOnAuctionFailure(result.code))
        const message = errorCodes().inventoryProject[result.code]
        if (message) {
          yield put(catchError(message))
        } else {
          yield put(catchError(errorCodes().app[66]))
        }
      }
    }
  } catch (error) {
    yield put(putUpOnAuctionFailure(result.code))
    yield put(catchError(error.message))
  }
}

function* fetchInventoryProjects(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchInventoryProjectsRequest(
        accessToken,
        action.requestor,
        action.status,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryProjectsSuccess(result.projects))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryProjectsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryProjectsCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectsCountRequest(
        accessToken,
        action.requestor,
        action.status,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryProjectsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryProjectsCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryProjectsCities() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchInventoryProjectsCitiesRequest(accessToken))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryProjectsCitiesSuccess(result.cities))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryProjectsCitiesFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* getInventoryClients(action) {
  try {
    const result = yield call(
      fetchFiltersInventoryOrganizations(
        'clients',
        action.requestor,
        action.status,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(getInventoryClientsSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(getInventoryClientsFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* getInventoryPerformers(action) {
  try {
    const result = yield call(
      fetchFiltersInventoryOrganizations(
        'performers',
        action.requestor,
        action.status,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(getInventoryPerformersSuccess(result.organizations))
      } else {
        const errors = handleErrors(result)
        yield put(getInventoryPerformersFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* confirmInventoryProjectAsPerformer(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.organizationId,
        action.projectId,
        action.data,
        'confirm_as_performer',
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(confirmInventoryProjectAsPerformerSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(confirmInventoryProjectAsPerformerFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* rejectInventoryProjectAsPerformer(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.organizationId,
        action.projectId,
        action.data,
        'reject_as_performer',
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(rejectInventoryProjectAsPerformerSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(rejectInventoryProjectAsPerformerFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setOwnPerformer(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.orgId,
        action.projId,
        action.data,
        'set_own_performer',
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(setOwnPerformerSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(setOwnPerformerFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setForeignPerformer(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.orgId,
        action.projId,
        action.data,
        'set_foreign_performer',
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(setForeignPerformerSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(setForeignPerformerFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* offerInventoryProject(action) {
  try {
    const result = yield call(
      offerInventoryProjectRequest(action.orgId, action.projId, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(offerInventoryProjectSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(offerInventoryProjectFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* editInventoryProjectResponsible(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      editInventoryProjectResponsibleRequest(
        accessToken,
        action.projectId,
        action.data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(editInventoryProjectResponsibleSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(editInventoryProjectResponsibleFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryProjectsOffersPerformer(action) {
  try {
    if (action.actionDelay !== undefined) {
      yield call(delay, action.actionDelay)
    }
    const result = yield call(
      fetchInventoryProjectsOffersPerformerRequest(
        action.orgId,
        action.projId,
        action.params,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryOffersPerformerSuccess(result.offers))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryOffersPerformerFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryProjectsOffersClient(action) {
  try {
    if (action.actionDelay !== undefined) {
      yield call(delay, action.actionDelay)
    }
    const result = yield call(
      fetchInventoryProjectsOffersClientRequest(
        action.orgId,
        action.projId,
        action.params,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryOffersClientSuccess(result.offers))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryOffersClientFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* stopInventoryProjectsAuction(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.orgId,
        action.projId,
        _,
        'stop_auction',
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(stopInventoryProjectAuctionSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(stopInventoryProjectAuctionFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setInventoryProjectDone(action) {
  try {
    const result = yield call(
      setInventoryProjectStatus(action.projectId, 'make_done', _),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(setInventoryProjectDoneSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.inventoryProject)) {
          yield put(setInventoryProjectDoneFailure(errors))
        } else {
          yield put(setInventoryProjectDoneFailure())
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setInventoryProjectClosed(action) {
  try {
    const result = yield call(
      setInventoryProjectStatus(action.projectId, 'make_closed', action.data),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(setInventoryProjectClosedSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(setInventoryProjectClosedFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* setInventoryProjectBackInWork(action) {
  try {
    const result = yield call(
      setInventoryProjectStatus(
        action.projectId,
        'make_back_inwork',
        action.data,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(setInventoryProjectBackInWorkSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(setInventoryProjectBackInWorkFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* changePerformerRating(action) {
  try {
    const result = yield call(
      setInventoryProjectRating(
        action.projectId,
        'change_performer_rating',
        action.data,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(changePerformerRatingSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(changePerformerRatingFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* changeClientRating(action) {
  try {
    const result = yield call(
      setInventoryProjectRating(
        action.projectId,
        'change_client_rating',
        action.data,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(changeClientRatingSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(changeClientRatingFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* cancelInventoryProject(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.orgId,
        action.projId,
        _,
        'cancel_project',
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(cancelInventoryProjectSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(cancelInventoryProjectFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* confirmOfferInventoryProject(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const actionStr = `offers/${action.offerId}/accept`
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.orgId,
        action.projId,
        _,
        actionStr,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(confirmOfferInventoryProjectSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(confirmOfferInventoryProjectFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* cancelOfferInventoryProject(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const actionStr = `offers/${action.offerId}/cancel`
    const result = yield call(
      inventoryProjectActionRequest(
        accessToken,
        action.orgId,
        action.projId,
        _,
        actionStr,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(cancelOfferInventoryProjectSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(cancelOfferInventoryProjectFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchEngineersInventory(action) {
  try {
    if (action.actionDelay !== undefined) {
      yield call(delay, action.actionDelay)
    }
    const result = yield call(fetchEngineersInventoryRequest(action.projId))
    if (!isEmpty(result.success)) {
      if (result.success) {
        const users = isEmpty(result.users) ? [] : result.users
        yield put(fetchEngineersInventorySuccess(users))
      } else {
        const errors = handleErrors(result)
        yield put(fetchEngineersInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(fetchEngineersInventoryFailure(error.message))
    yield put(catchError(error.message))
    yield put(fetchEngineersInventoryFailure(error.message))
  }
}

function* editEngineersInventory(action) {
  try {
    if (action.actionDelay !== undefined) {
      yield call(delay, action.actionDelay)
    }
    const result = yield call(
      editEngineersInventoryRequest(action.orgId, action.projId, action.data),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(editEngineersInventorySuccess())
      } else {
        const errors = handleErrors(result)
        yield put(editEngineersInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(editEngineersInventoryFailure(error.message))
  }
}

function* fetchFiltersOrdersInventory(action) {
  try {
    const result = yield call(fetchFiltersOrdersInventoryRequest(action.projId))
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchFiltersOrdersInventorySuccess(result.filters))
      } else {
        const errors = handleErrors(result)
        yield put(fetchFiltersOrdersInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(fetchFiltersOrdersInventoryFailure(error.message))
  }
}

function* fetchOrdersInventory(action) {
  try {
    const result = yield call(
      fetchOrdersInventoryRequest(action.projId, '', action.params),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrdersInventorySuccess(result.locations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrdersInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(fetchOrdersInventoryFailure(error.message))
  }
}

function* fetchOrdersCountInventory(action) {
  try {
    const result = yield call(
      fetchOrdersInventoryRequest(action.projId, '/count', action.params),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrdersCountInventorySuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrdersInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(fetchOrdersCountInventoryFailure(error.message))
  }
}

function* removeOrderInventory(action) {
  try {
    const result = yield call(
      removeOrderInventoryRequest(action.projId, action.orderId),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(removeOrderInventorySuccess())
      } else {
        const errors = handleErrors(result)
        yield put(removeOrderInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(removeOrderInventoryFailure(error.message))
  }
}

function* editEngineerOrderInventory(action) {
  try {
    const result = yield call(
      editEngineerOrderInventoryRequest(
        action.projId,
        action.orderId,
        action.data,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(editEngineerOrderInventorySuccess())
      } else {
        const errors = handleErrors(result)
        yield put(editEngineerOrderInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(editEngineerOrderInventoryFailure(error.message))
  }
}

function* removeEngineerInventory(action) {
  try {
    if (action.actionDelay !== undefined) {
      yield call(delay, action.actionDelay)
    }
    const result = yield call(
      removeEngineerInventoryRequest(
        action.orgId,
        action.projId,
        action.engineerId,
      ),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(removeEngineersInventorySuccess())
      } else {
        const errors = handleErrors(result)
        yield put(removeEngineersInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchHistoryInventory(action) {
  try {
    const result = yield call(
      fetchHistoryInventoryRequest(action.projId, action.params),
    )
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchHistoryInventorySuccess(result.logs))
      } else {
        const errors = handleErrors(result)
        yield put(fetchHistoryInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(fetchHistoryInventoryFailure(error.message))
  }
}

function* fetchHistoryCountInventory(action) {
  try {
    const result = yield call(fetchHistoryCountInventoryRequest(action.projId))
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchHistoryCountInventorySuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchHistoryCountInventoryFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
    yield put(fetchHistoryCountInventoryFailure(error.message))
  }
}
