/* eslint-disable max-len */
import * as constants from '../constants/actionTypes/settings'

const initialState = {
  engineerSettings: {
    isFetching: false,
    settings: null,
    error: null,
  },
  commonOrganizationSettings: {
    isFetching: false,
    settings: null,
    error: null,
  },
  updateSettingsLists: false,
}

const serviceDesk = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ENGINEER_SETTINGS_REQUEST:
      return {
        ...state,
        engineerSettings: {
          ...state.engineerSettings,
          isFetching: true,
          settings: null,
          error: null,
        },
      }

    case constants.GET_ENGINEER_SETTINGS_SUCCESS:
      const {
        settings: {
          send_from_inwork_to_done_through_control_in_diagnostic_with_complete_result_order_engineer_permission: engineerSendInworkToDoneSkippingControlDiagnosticCompleteOrder,
          send_from_inwork_to_done_through_control_in_diagnostic_with_needrepair_result_order_engineer_permission: engineerSendInworkToDoneSkippingControlDiagnosticNeedrepairOrder,
          send_from_inwork_to_done_through_control_in_repair_order_engineer_permission: engineerSendInworkToDoneSkippingControlRepairOrder,
        },
      } = action.payload
      return {
        ...state,
        engineerSettings: {
          ...state.engineerSettings,
          isFetching: false,
          settings: {
            ...action.payload.settings,
            engineerSendInworkToDoneSkippingControlDiagnosticCompleteOrder,
            engineerSendInworkToDoneSkippingControlDiagnosticNeedrepairOrder,
            engineerSendInworkToDoneSkippingControlRepairOrder,
          },
        },
      }

    case constants.GET_ENGINEER_SETTINGS_FAILURE:
      return {
        ...state,
        engineerSettings: {
          ...state.engineerSettings,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.GET_COMMON_ORGANIZATION_SETTINGS_REQUEST:
      return {
        ...state,
        commonOrganizationSettings: {
          ...state.commonOrganizationSettings,
          isFetching: true,
          settings: null,
          error: null,
        },
      }

    case constants.GET_COMMON_ORGANIZATION_SETTINGS_SUCCESS:
      return {
        ...state,
        commonOrganizationSettings: {
          ...state.commonOrganizationSettings,
          isFetching: false,
          settings: action.payload.settings,
        },
      }

    case constants.GET_COMMON_ORGANIZATION_SETTINGS_FAILURE:
      return {
        ...state,
        commonOrganizationSettings: {
          ...state.commonOrganizationSettings,
          isFetching: false,
          error: action.payload,
        },
      }

    case constants.UPDATE_SETTINGS_LISTS:
      return {
        ...state,
        updateSettingsLists: action.payload,
      }

    default:
      return state
  }
}

export default serviceDesk
