import React from 'react'

import Typography from '@material-ui/core/Typography'

import FormDialog from '../../../widgets/FormDialog'
import i18next from '../../../i18n/i18n'

function PaymentDialog({ open, isOpenHandler, doPaymentRequest }) {
  function close() {
    isOpenHandler(false)
  }
  function submit() {
    doPaymentRequest()
    isOpenHandler(false)
  }
  return (
    <FormDialog
      closeIconHidden
      open={open}
      isLoading={false}
      title={i18next.t('service_desk_web_atoir:sdBannerPaymentDialogTitle')}
      onClose={close}
      onExit={close}
      onSubmit={submit}
      submitButtonTitle={i18next.t('service_desk_web_atoir:doRequest')}
      positiveButtonVariant="applyButton"
      secondaryButtonVariant="applyButton"
      secondaryButtonsArray={[
        {
          secondaryText: i18next.t('service_desk_web_atoir:doClose'),
          handleSecondaryAction: close,
        },
      ]}
    >
      <Typography>
        {i18next.t('service_desk_web_atoir:sdBannerPaymentDialogText')}
      </Typography>
    </FormDialog>
  )
}

export default PaymentDialog
