import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

const styles = theme => ({
  qwe: {
    background: 'red',
    padding: theme.spacing(2),
  },
  radio: {
    '&$checked': {
      color: theme.palette.blueSecondary.main,
    },
  },
  checked: {
    color: theme.palette.blueSecondary.main,
  },
  groupContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
})

const inWorkStatus = [
  'inworkDiagnostic',
  'inworkRepair',
  'inworkDiagnosticRepairRejected',
]
const needRepairStatus = ['inworkNeedrepairDone', 'inworkNeedrepair']
const createdStatus = ['createdUnassigned', 'createdSubPerformerReject']
const inWorkOrderStatus = [
  'inworkDiagnostic',
  'inworkRepair',
  'inworkDiagnosticSubPerformer',
  'inworkRepairSubPerformerRejected',
]
const controlStatus = [
  'inworkDiagnosticPricesForming',
  'inworkDiagnosticPricesFormingPriceMatching',
  'inworkDiagnosticPricesFormingRejected',
]
const doneStatus = [
  'qaDiagnosticOrRepairDone',
  'qaDiagnosticOrRepairDoneRejected',
]

function statusesDictionary(status, filtersFor = 'order') {
  let i18key = null
  const i18keys = {
    calls: {
      inworkDiagnostic() {
        i18key = 'callStatusForFiltersInworkDiagnostic'
      },
      inworkRepair() {
        i18key = 'callStatusForFiltersInworkRepair'
      },
      default() {
        i18key = null
      },
    },
    orders: {
      default() {
        i18key = null
      },
    },
    default: {
      default() {
        i18key = null
      },
    },
  }
  const getLevel = (data, key) => data[key] || data.default
  const getI18Key = getLevel(getLevel(i18keys, filtersFor), status)

  getI18Key()
  return i18key
}

class RadioButtonsGroupFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = { checkedRadio: 'all' }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.setState({ checkedRadio: 'all' })
    }
  }

  statusToRadioElement = (statusArray, classes, filtersFor = 'order') =>
    statusArray.map(s => (
      <FormControlLabel
        value={s}
        control={
          <Radio
            classes={{
              root: classes.radio,
              checked: classes.checked,
            }}
          />
        }
        label={i18next.t(
          `service_desk_web_atoir:${statusesDictionary(s, filtersFor) || s}`,
        )}
      />
    ))

  render() {
    const {
      state: { checkedRadio },
      props: { handleSubStatusChange, classes, filtersFor, status },
      statusToRadioElement,
    } = this

    return (
      <Fragment>
        {filtersFor === 'calls' && ['inwork', 'needrepair'].includes(status) && (
          <FormControl
            id="calls-radio-group-filters"
            component="fieldset"
            className={classes.formControl}
          >
            <Fragment>
              <FormLabel component="legend">
                {i18next.t('service_desk_web_atoir:additionalFilter')}
              </FormLabel>
              <RadioGroup
                aria-label="InworkDiagnostic"
                name="inworkDiagnostic"
                value={checkedRadio}
                onChange={e => {
                  this.setState({ checkedRadio: e.target.value })
                  handleSubStatusChange(e.target.value)
                }}
                className={classes.groupContainer}
              >
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={i18next.t('service_desk_web_atoir:showAllCallsFilter')}
                />
                {status === 'inwork'
                  ? statusToRadioElement(inWorkStatus, classes, 'calls')
                  : null}
                {status === 'needrepair'
                  ? statusToRadioElement(needRepairStatus, classes, 'calls')
                  : null}
              </RadioGroup>
            </Fragment>
          </FormControl>
        )}
        {filtersFor === 'orders' &&
          ['created', 'inwork', 'control', 'done'].includes(status) && (
            <Fragment>
              <FormControl
                id="calls-radio-group-filters"
                component="fieldset"
                className={classes.formControl}
              >
                <FormLabel component="legend">
                  {i18next.t('service_desk_web_atoir:additionalFilter')}
                </FormLabel>
                <RadioGroup
                  aria-label="InworkDiagnostic"
                  name="inworkDiagnostic"
                  value={checkedRadio}
                  onChange={e => {
                    this.setState({ checkedRadio: e.target.value })
                    handleSubStatusChange(e.target.value)
                  }}
                  className={classes.groupContainer}
                >
                  <FormControlLabel
                    value="all"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={i18next.t(
                      'service_desk_web_atoir:showAllCallsFilter',
                    )}
                  />
                  {status === 'created'
                    ? statusToRadioElement(createdStatus, classes)
                    : null}
                  {status === 'inwork'
                    ? statusToRadioElement(inWorkOrderStatus, classes)
                    : null}
                  {status === 'control'
                    ? statusToRadioElement(controlStatus, classes)
                    : null}
                  {status === 'done'
                    ? statusToRadioElement(doneStatus, classes)
                    : null}
                </RadioGroup>
              </FormControl>
            </Fragment>
          )}
      </Fragment>
    )
  }
}

RadioButtonsGroupFilters.defaultProps = {
  handleSubStatusChange: () => {},
}

RadioButtonsGroupFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  filtersFor: PropTypes.oneOf(['calls', 'orders']).isRequired,
  status: PropTypes.string.isRequired,
  handleSubStatusChange: PropTypes.func,
}

export default withStyles(styles)(RadioButtonsGroupFilters)
