import DropDownList from '../../../../widgets/DropDownList'
import i18next from '../../../../i18n/i18n'
import React from 'react'

const floors = (() => {
  const res = []
  for (let i = -11; i < 101; i++) {
    if (i <= -2) {
      const ind = i + 1
      res.push(`${ind}`)
    } else if (i === -1) {
      res.push('')
    } else {
      res.push(`${i}`)
    }
  }
  return res
})()
  .reverse()
  .map(_floor => ({
    id: _floor,
    title: _floor,
  }))

function FloorSelector({ onChange, idValue, value }) {
  const [open, setOpen] = React.useState(false)
  const closeList = React.useCallback(() => {
    setOpen(false)
  }, [])
  const openList = React.useCallback(() => {
    setOpen(true)
  }, [])

  const [rawValue, setRawValue] = React.useState(
    floors.findIndex(item => item.title === ''),
  )

  React.useEffect(() => {
    if (value) {
      setRawValue(floors.findIndex(item => item.title === `${value}`))
    } else {
      setRawValue(floors.findIndex(item => item.title === ''))
    }
  }, [value])

  const onChangeHandler = ({ target: { value: newValue } }) => {
    onChange(idValue, newValue)
  }
  return (
    <DropDownList
      fullWidth
      classes={{}}
      noEmpty
      showLabel
      label={i18next.t('equipment_web_atoir:floor')}
      open={open}
      value={floors[rawValue].title}
      elements={floors}
      isFetching={false}
      handleOpen={openList}
      handleClose={closeList}
      handleChange={onChangeHandler}
    />
  )
}

export default FloorSelector
