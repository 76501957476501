import React, { Fragment, useMemo } from 'react'
import ListItem from '@material-ui/core/ListItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ApplyButton from '../../../../widgets/Buttons/ApplyButton'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import i18next from '../../../../i18n/i18n'

const styles = theme => ({
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  container: {
    width: '100%',
  },
  listRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  defaultText: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  inline: {
    display: 'inline',
  },
  heading: {
    fontSize: 16,
  },
  subHeadingDark: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 14,
  },
  subHeading: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
  },
  verticalText: {
    display: 'flex',
    alignItems: 'center',
  },
  tagGridMargin: {
    marginRight: theme.spacing(2),
  },
})

const TrustRow = ({ classes, trust, showAcceptOrDenyDialog }) => {
  const showDialogOnAccept = useMemo(
    () => () =>
      showAcceptOrDenyDialog({
        params: {
          number: trust.available,
          sender: trust.sender,
          action: 'accept',
          id: trust.id,
        },
      }),
    [trust.id],
  )
  const showDialogOnDeny = useMemo(
    () => () =>
      showAcceptOrDenyDialog({
        params: {
          number: trust.available,
          sender: trust.sender,
          action: 'deny',
          id: trust.id,
        },
      }),
    [trust.id],
  )

  const counterSender = trust.sender === 'client' ? 'performer' : 'client'
  const memberOfBoth = trust.clientUser && trust.performerUser
  const side =
    trust.performerUser && trust.clientUser
      ? counterSender
      : trust.performerUser
      ? 'performer'
      : 'client'
  const counterSide = side === 'client' ? 'performer' : 'client'
  const canAcceptOrReject =
    trust[`${counterSender}User`] && trust.status === 'pending'
  const showPending = !canAcceptOrReject && trust.status === 'pending'
  const showRejected = trust.status === 'rejected'
  return (
    <Fragment>
      <ListItem
        divider
        // onClick={() => history.push(`${match.url}/${trust.id}`)}
      >
        <div className={classes.container}>
          <Grid container>
            <Grid
              item
              xs={!showPending && !canAcceptOrReject && !showRejected ? 12 : 8}
            >
              {memberOfBoth ? (
                <Typography className={classes.defaultText} gutterBottom noWrap>
                  {i18next.t(
                    `trusted_organizations_web_atoir:bothOrganizationsMember`,
                  )}
                </Typography>
              ) : (
                <Typography className={classes.defaultText} gutterBottom noWrap>
                  {i18next.t(
                    `trusted_organizations_web_atoir:${side}TrustHeader`,
                    {
                      organization: trust[side].short_name,
                    },
                  )}
                </Typography>
              )}
              {memberOfBoth ? (
                <Fragment>
                  <Typography
                    noWrap
                    variant="h6"
                    className={classes.heading}
                    gutterBottom
                  >
                    {`${i18next.t('trusted_organizations_web_atoir:client')}: ${
                      trust.client.short_name
                    }`}
                  </Typography>
                  <Typography
                    noWrap
                    variant="h6"
                    className={classes.heading}
                    gutterBottom
                  >
                    {`${i18next.t(
                      'trusted_organizations_web_atoir:performer',
                    )}: ${trust.performer.short_name}`}
                  </Typography>
                </Fragment>
              ) : (
                <Typography
                  noWrap
                  variant="h6"
                  className={classes.heading}
                  gutterBottom
                >
                  {i18next.t(
                    'trusted_organizations_web_atoir:counterOrganization',
                    {
                      organization: trust[counterSide].short_name,
                    },
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} className={classes.verticalText} justify="center">
              {showRejected && (
                <Typography
                  noWrap
                  color="textSecondary"
                  align="center"
                  variant="button"
                >
                  {i18next.t(
                    'trusted_organizations_web_atoir:rejectedRowStatus',
                  )}
                </Typography>
              )}
              {showPending && (
                <Typography
                  noWrap
                  color="textSecondary"
                  align="center"
                  variant="button"
                >
                  {i18next.t(
                    'trusted_organizations_web_atoir:pendingRowStatus',
                  )}
                </Typography>
              )}
              {canAcceptOrReject && (
                <Fragment>
                  <ApplyButton onClick={showDialogOnDeny}>
                    {i18next.t('shared_web_atoir:denyAction')}
                  </ApplyButton>
                  <OutlinedButton onClick={showDialogOnAccept}>
                    {i18next.t('shared_web_atoir:acceptAction')} (
                    {trust.available})
                  </OutlinedButton>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </div>
      </ListItem>
    </Fragment>
  )
}

export default withStyles(styles)(TrustRow)
