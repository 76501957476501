import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router-dom'

import classNames from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import CommonInfo from './CommonInfo'
import AdditionalInfo from './AdditionalInfo'
import OrgStructureInfo from './OrgStructureInfo'
import i18next from '../../../i18n/i18n'
import ContainedButton from '../../../widgets/Buttons/ContainedButton'
import TextButton from '../../../widgets/Buttons/TextButton'

const styles = theme => ({
  root: {
    padding: 20,
  },
  headerHolder: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  agentHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('xl')]: {
      marginTop: 8,
      justifyContent: 'center',
    },
  },
  delimiter: {
    [theme.breakpoints.up('lg')]: {
      '&:after': {
        position: 'absolute',
        content: '""',
        marginTop: 40,
        top: 0,
        right: 0,
        Bottom: 0,
        width: 1,
        height: 'calc(100% - 130px)',
        background: 'rgba(0, 0, 0, 0.12)',
      },
    },
    [theme.breakpoints.up('xl')]: {
      '&:before': {
        position: 'absolute',
        content: '""',
        marginTop: 40,
        top: 0,
        left: 0,
        Bottom: 0,
        width: 1,
        height: 'calc(100% - 130px)',
        background: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
})

const Header = withStyles(theme => ({
  disabled: { color: theme.palette.apply.light },
}))(({ children, disabled = false, classes }) => (
  <Typography
    className={classNames({ [classes.disabled]: disabled })}
    variant="h6"
  >
    {children}
  </Typography>
))

function FormHolder({
  classes,
  catalogAgentComponent,
  handleSubmit,
  setFieldValueHandler,
  handleBlur,
  values,
  errors,
  organizationId,
  touched,
  validateForm,
  baEquipment,
  history,
  creation,
}) {
  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={10}>
          <Grid item sm={12} lg={12} xl={4}>
            <div>
              <Grid container>
                <Grid item xs={12} md={6} xl={12}>
                  <div className={classes.headerHolder}>
                    <Header disabled={baEquipment}>
                      {i18next.t('equipment_web_atoir:commonInfo')}
                    </Header>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} xl={12}>
                  <div className={classes.agentHolder}>
                    {catalogAgentComponent()}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <CommonInfo
                    disabled={baEquipment}
                    {...{
                      setFieldValueHandler,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      validateForm,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} lg={6} xl={4} style={{ position: 'relative' }}>
            <div className={classes.delimiter}>
              <Header>{i18next.t('equipment_web_atoir:additionalInfo')}</Header>
              <AdditionalInfo
                {...{
                  setFieldValueHandler,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  validateForm,
                }}
              />
            </div>
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <Header>{i18next.t('equipment_web_atoir:orgStructureInfo')}</Header>
            <OrgStructureInfo
              {...{
                setFieldValueHandler,
                handleBlur,
                values,
                errors,
                organizationId,
                touched,
              }}
            />
          </Grid>
          <Grid xs={12} item container justify="flex-end">
            <TextButton
              size="medium"
              onClick={() => {
                history.goBack()
              }}
            >
              {i18next.t('shared_web_atoir:cancel')}
            </TextButton>
            <ContainedButton size="big" type="submit">
              {creation
                ? i18next.t('shared_web_atoir:create')
                : i18next.t('shared_web_atoir:save')}
            </ContainedButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}
export default withRouter(withStyles(styles)(FormHolder))
