export default [
  'employees_web_atoir',
  'equipment_web_atoir',
  'error_web_atoir',
  'inventory_web_atoir',
  'login_web_atoir',
  'organizations_web_atoir',
  'shared_web_atoir',
  'system_menu_web_atoir',
  'user_profile_web_atoir',
  'validation_web_atoir',
  'service_desk_web_atoir',
  'faq_web_atoir',
  'inner_calls_web_atoir',
  'trusted_organizations_web_atoir',
]
