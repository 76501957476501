import fetch from 'cross-fetch'
import queryString from 'query-string'
import config from '../config'
import endpoints from '../constants/endpoints'
import { errorCodes } from '../constants/errors'
import { transformCamelCaseObjectCaseToSnake } from '../helpers/dataHelper'

export const URL =
  process.env.NODE_ENV !== 'development'
    ? process.env.API_PATH || '/api'
    : 'http://localhost:3001'
// export const URL = 'https://dev.atoir.ru/api'

// register user account

const baUsername = config.bau
const baPassword = config.bap
const auth = `Basic ${Buffer.from(`${baUsername}:${baPassword}`).toString(
  'base64',
)}`
const fetchNetworkError = Error(errorCodes().app.networkError)
export const fetchProxy = async (...argsIncome) => {
  // Authorization:
  const args = [...argsIncome]
  if (args[1]) {
    args[1] = Object.assign(args[1], {
      headers: {
        ...args[1].headers,
        Authorization: auth,
      },
    })
  }
  try {
    return fetch(...args)
  } catch (e) {
    console.error(e)
    throw fetchNetworkError
  }
}

export const unsubscribeFromAllNotifications = subscription =>
  fetchProxy(`${URL}/notifications/unsubscribe`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
    },
    body: JSON.stringify(subscription),
  })

export const subscribeToAllNotifications = async subscription => {
  const response = await fetchProxy(`${URL}/notifications/subscribe`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
    },
    body: JSON.stringify(subscription),
  })
  return response.json()
}

export const pushNotificationsKey = subscription =>
  fetchProxy(`${URL}/notifications/pushKey`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
    },
    body: JSON.stringify(subscription),
  })

export const registerEmailRequest = email => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/join`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getConfirmLinkAgainRequest = email => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/get_confirm_link_again`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getKLADRLocation = async params => {
  try {
    const response = await fetchProxy(
      `${endpoints.kladr.atoirAPI}?${queryString.stringify(params)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    const res = await response.json()
    return res.searchContext ? (res.result ? res.result : []) : null
  } catch (error) {
    throw new Error(error.message)
  }
}

export const confirmEmailRequest = hash => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/confirm/${hash}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const registerAccountRequest = (hash, data) => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/registration/${hash}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// login / refreshTokens / logout
export const loginRequest = (email, password) => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/auth/jwt`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const refreshTokensRequest = refreshToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/refresh_tokens/jwt`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: refreshToken,
      }),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const logoutRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/logout`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// repair password
export const repairPasswordRequest = email => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/repair_password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const confirmRepairPasswordRequest = hash => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/repair_password_check_hash_expiration/${hash}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const setNewPasswordRequest = (hash, data) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/repair_password_set_new/${hash}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// user
export const fetchUserRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/personal_area`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    const res = await response.json()
    if (res.data) {
      res.data.isOneself = true
    }
    return res
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserOrganizationsFunctionalsRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/functionals`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserByIdRequest = (
  accessToken,
  userId,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/get_by_id/${userId}${
        organizationId ? `?${queryString.stringify({ organizationId })}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadImage = (accessToken, imageData) => async () => {
  try {
    const response = await fetchProxy(`${URL}/files/upload/image`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        type: 'formData',
        enctype: 'multipart/form-data',
        'X-Auth-Token': accessToken,
      },
      body: imageData,
    })
    if (!response.ok) {
      return { success: false, status: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateUserRequest = (accessToken, data) => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/update`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const changePasswordRequest = (accessToken, data) => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/change_password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserNotificationSettingsRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/notifications?${queryString.stringify({
        block: 'sc_repair',
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const setUserNotificationSettingsRequest = (
  accessToken,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/notifications/${data[0].value ? 'enable' : 'disable'}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify({ name: data.map(s => `${s.key}_${s.type}`) }),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const removeUserAvatarRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/avatar`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// organizations
export const createOrganizationRequest = (accessToken, data) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/create`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateOrganizationRequest = (
  accessToken,
  id,
  data,
) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserOrganizationsRequest = (
  accessToken,
  query,
) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/my?${query}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserOrganizationsCanAddEquipmentRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/canAdd`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserOrganizationsCountRequest = (
  accessToken,
  filters,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/my/count${filters}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchAdminOrganizationsRequest = (
  accessToken,
  filters,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/canAdd${
        filters ? `?${queryString.stringify(filters)}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchManagerOrHigherOrganizationsRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/manager_or_higher`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchHasManagerOrHigherOrganizationsRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/has_manager_or_higher_organizations`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchNotUserOrganizationsRequest = (
  accessToken,
  name,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/not_my?name=${name}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}?contacts=1`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationWhoAmIRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/permissions/organizations/${id}/who_am_i`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationMeRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}/users/me`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchAllOrganizationsRequest = (accessToken, name) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations?name=${name}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchCitiesWithServiceOrganizationsRequest = (
  accessToken,
  payload,
) => async () => {
  try {
    const query = queryString.stringify(payload)
    const response = await fetchProxy(
      `${URL}/inventory_projects/cities_with_service_organizations${
        query ? `?${query}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceOrganizationsRequest = (
  accessToken,
  query,
) => async () => {
  try {
    const queryFull = { sort: '[short_name=ASC]', ...query }
    const params = queryFull
      ? Object.keys(queryFull)
          .map(k => `${k}=${queryFull[k]}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/organizations/service${params ? `?${params}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceOrganizationsCountRequest = (
  accessToken,
  filters,
) => async () => {
  try {
    const params = filters
      ? Object.keys(filters)
          .map(k => `${k}=${filters[k]}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/organizations/service/count${params ? `?${params}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchLocationsRequest = (
  accessToken,
  organizationId,
  query,
) => async () => {
  const sort = '[name=ASC]'
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/locations?${queryString.stringify(
        { sort, ...query },
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchLocationsCountRequest = (
  accessToken,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/locations/count`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserEquipmentLocationsRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/equipment/locations`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchLocationRequest = (
  accessToken,
  organizationId,
  id,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/locations` +
        `?filters=[id=${id}]`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchPhysicalLocationRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${id}/locations?filters=[location_type=0]`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchLegalLocationRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${id}/locations?filters=[location_type=1]`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// TODO request only needing fields
export const fetchDepartmentsRequest = (
  accessToken,
  organizationId,
  data,
) => async () => {
  try {
    const sort = '[name=ASC]'
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/departments?${queryString.stringify(
        { sort, ...data },
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchDepartmentsCountRequest = (
  accessToken,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/departments/count`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchDepartmentRequest = (
  accessToken,
  organizationId,
  query,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/departments?filters=[id=]&${queryString.stringify(
        query,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserEquipmentDepartmentsRequest = (
  accessToken,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/equipment/departments?filters[organization_id=${organizationId}]`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createDepartmentRequest = (
  accessToken,
  organizationId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/departments/create`,
      {
        method: 'POST',
        body: JSON.stringify({
          name: data.name,
          mol_user_organization_id: data.molUserId,
          person_function: data.personFunction,
          phone: +data.phone.replace(/[^0-9]/g, '').slice(0, 12),
          phone_additional:
            data.phoneAdditional && data.phoneAdditional.length > 0
              ? data.phoneAdditional
              : undefined,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateDepartmentRequest = (
  accessToken,
  organizationId,
  departmentId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/departments/${departmentId}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          name: data.name,
          mol_user_organization_id: data.molUserId,
          person_function: data.personFunction,
          phone: +data.phone.replace(/[^0-9]/g, '').slice(0, 12),
          phone_additional: data.phoneAdditional,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteDepartmentRequest = (
  accessToken,
  organizationId,
  departmentId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/departments/${departmentId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createLocationRequest = (
  accessToken,
  organizationId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/locations/add`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )

    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateLocationRequest = (
  accessToken,
  orgId,
  locId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/locations/${locId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteLocationRequest = (
  accessToken,
  orgId,
  locId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/locations/${locId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEmployersRequest = (accessToken, data) => async () => {
  try {
    const sort = '[short_name=ASC]'
    const response = await fetchProxy(
      `${URL}/organizations/member?noMy=true&${queryString.stringify({
        sort,
        ...data,
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEmployersCountRequest = (accessToken, data) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/member/count?noMy=true&${queryString.stringify(
        data,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationsMemborOfRequest = (
  accessToken,
  filters,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/member${
        filters ? `?${queryString.stringify(filters)}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const leaveOrganizationRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/leave/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const confirmJoinOrganizationRequest = (
  accessToken,
  hash,
) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/confirm/${hash}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// staff
export const fetchStaffRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/workers?with_connections=true`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserContactsRequest = (accessToken, filters) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/user_to_user/with_organizations${
        filters ? `?${queryString.stringify(filters)}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserContactsCountRequest = (
  accessToken,
  filters,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/user_to_user/with_organizations/count${
        filters ? `?${queryString.stringify(filters)}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteStaffRequest = (accessToken, ids) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/user_to_user/bulk?fields=[${ids}]`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationStaffRequest = (
  accessToken,
  id,
  filters,
  query,
) => async () => {
  try {
    const sort = '[last_name=ASC,first_name=ASC,middle_name=ASC]'
    const response = await fetchProxy(
      `${URL}/organizations/${id}/users?${
        filters
          ? `${queryString.stringify({ filters: JSON.stringify(filters) })}`
          : ''
      }&${queryString.stringify({ sort, ...query })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationStaffCountRequest = (
  accessToken,
  id,
  filters,
  query,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${id}/users/count?${
        filters
          ? `${queryString.stringify({ filters: JSON.stringify(filters) })}`
          : ''
      }&${queryString.stringify(query)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const addOrganizationUserRequest = (
  accessToken,
  id,
  data,
) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}/users/add`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const bindOrganizationUserAccountRequest = (
  accessToken,
  id,
  data,
) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}/users/bind`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const unbindOrganizationUserAccountRequest = (
  accessToken,
  id,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${id}/users/unbind`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateOrganizationUserRequest = (
  accessToken,
  organizationId,
  organizationUserId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/users/${organizationUserId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteOrganizationUserRequest = (
  accessToken,
  organizationId,
  organizationUserId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/users/${organizationUserId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationLocationsRequest = (
  accessToken,
  id,
) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}/locations`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchBoundOrganizationUsersRequest = (
  accessToken,
  organizationId,
  filters = {},
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/users/only_binded?${queryString.stringify(
        { filters: JSON.stringify({ state: 1, ...filters }) },
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchContactsRequest = (accessToken, id) => async () => {
  try {
    const response = await fetchProxy(`${URL}/organizations/${id}/contacts`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteContactRequest = (
  accessToken,
  organizationId,
  id,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/contacts/${id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createContactRequest = (
  accessToken,
  organizationId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/contacts/create`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateContactRequest = (
  accessToken,
  organizationId,
  contactId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/contacts/${contactId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationPermissionsRequest = (
  accessToken,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/permissions/organizations/${organizationId}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// equipment
export const addEquipmentUnitRequest = (
  accessToken,
  organizationId,
  equipmentData,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/equipments/add`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(equipmentData),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserEquipmentRequest = (accessToken, data) => async () => {
  try {
    const req = {
      sort: '[name=ASC,id=ASC]',
      ...data,
    }
    const params = req
      ? Object.keys(req)
          .map(k => `${k}=${encodeURIComponent(req[k])}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/users/equipment${params ? `?${params}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchTransferringEquipmentRequest = (
  accessToken,
  organizationRole,
  query,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/equipment/transfers?role=${organizationRole}&status=open&${queryString.stringify(
        query,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchTransferringEquipmentCountRequest = (
  accessToken,
  organizationRole,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/users/equipment/transfers/count?role=${organizationRole}&status=open`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const transferEquipmentRequest = (
  accessToken,
  transferType,
  organizationId,
  transferId,
  locationId,
  departmentId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/transfers/${transferId}/${transferType}`,
      {
        method: 'POST',
        body: JSON.stringify({
          organization_location_id: locationId,
          organization_department_id: departmentId,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationVendorsRequest = (
  accessToken,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/equipments/vendors`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserEquipmentVendorsRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/users/equipment/vendors`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationEquipmentCountRequest = (
  accessToken,
  organizationId,
  data,
) => async () => {
  try {
    const params = data
      ? Object.keys(data)
          .map(k => `${k}=${encodeURIComponent(data[k])}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/equipments/count${
        params ? `?${params}` : ''
      }`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationEquipmentRequest = (
  accessToken,
  organizationId,
  data,
) => async () => {
  try {
    const req = {
      sort: '[name=ASC]',
      ...data,
    }
    const params = req
      ? Object.keys(req)
          .map(k => `${k}=${encodeURIComponent(req[k])}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/equipments${
        params ? `?${params}` : ''
      }`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchUserEquipmentCountRequest = (
  accessToken,
  data,
) => async () => {
  try {
    const params = data
      ? Object.keys(data)
          .map(k => `${k}=${encodeURIComponent(data[k])}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/users/equipment/count${params ? `?${params}` : ''}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchCountryNamesRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/countries`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEquipmentPermissionsRequest = (
  accessToken,
  organizationId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/permissions/organizations/${organizationId}/equipment`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEquipmentDetailsRequest = (
  accessToken,
  organizationId,
  equipmentId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/equipments/${equipmentId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateEquipmentDetailsRequest = (
  accessToken,
  organizationId,
  equipmentId,
  equipmentData,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/equipments/${equipmentId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(equipmentData),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const transferEquipmentToOwnOrganizationRequest = (
  accessToken,
  organizationId,
  transferData,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/transfers/toOwnOrganization`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(transferData),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const transferEquipmentToForeignOrganizationRequest = (
  accessToken,
  organizationId,
  transferData,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/transfers/toForeignOrganization`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(transferData),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const equipmentLogsRequest = (
  accessToken,
  orgId,
  eqpId,
  filters,
  reqType,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/equipments/${eqpId}/logs${
        reqType ? `/${reqType}` : ''
      }${filters ? `?${queryString.stringify(filters)}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// inventory
export const fetchInventoryProfileRequest = (
  accessToken,
  projectId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projectId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchInventoryProfilePermissionsRequest = (
  accessToken,
  projectId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/permissions/inventory_projects/${projectId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const putProjectUpOnAuctionRequest = (
  accessToken,
  organizationId,
  projectId,
  payload,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/inventory_projects/${projectId}/start_auction${
        payload && payload.rating_to_auction
          ? `?rating_to_auction=${payload.rating_to_auction}`
          : ''
      }`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createInventoryProjectRequest = (
  accessToken,
  id,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${id}/inventory_projects/create`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editInventoryProjectRequest = (
  accessToken,
  id,
  projectId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${id}/inventory_projects/${projectId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchInventoryProjectsRequest = (
  accessToken,
  requestor,
  status,
  filters,
) => async () => {
  try {
    const params = filters
      ? Object.keys(filters)
          .map(k => `${k}=${filters[k]}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/inventory_projects/${requestor}/${status}${
        params ? `?${params}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const inventoryProjectsCountRequest = (
  accessToken,
  requestor,
  status,
  filters,
) => async () => {
  try {
    const params = filters
      ? Object.keys(filters)
          .map(k => `${k}=${filters[k]}`)
          .join('&')
      : undefined
    const response = await fetchProxy(
      `${URL}/inventory_projects/${requestor}/${status}/count${
        params ? `?${params}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchFiltersInventoryOrganizations = (
  typeOrg,
  requestor,
  statusProj,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${typeOrg}/${requestor}/${statusProj}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchInventoryProjectsCitiesRequest = accessToken => async () => {
  try {
    const response = await fetchProxy(`${URL}/inventory_projects/cities`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': accessToken,
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const inventoryProjectActionRequest = (
  accessToken,
  orgId,
  projId,
  data,
  action,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/${action}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getInventoryProjectActionRequest = (
  orgId,
  projId,
  action,
  params = '',
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/${action}${params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const setInventoryProjectStatus = (projId, status, data) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/${status}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const setInventoryProjectRating = (
  projId,
  ratingTo,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/${ratingTo}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const offerInventoryProjectRequest = (
  orgId,
  projId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/offers/`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const inventoryProjectEquipmentRequest = (
  projectId,
  route,
  params,
  filters,
) => async () => {
  try {
    const p = params ? `?${queryString.stringify(params)}` : ''
    const mark = params ? '&' : '?'
    const f = JSON.stringify(filters)
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projectId}/equipments${
        route !== '' ? `/${route}` : `${route}`
      }${p}${mark}${f ? `filters=${f}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editInventoryProjectResponsibleRequest = (
  accessToken,
  projectId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/change_responsible_client_user/${projectId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': accessToken,
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchInventoryProjectsOffersPerformerRequest = (
  orgId,
  projId,
  params = '',
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/offers/as_performer${params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchInventoryProjectsOffersClientRequest = (
  orgId,
  projId,
  params = '',
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/offers/as_client${params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEngineersInventoryRequest = projId => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/users`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editEngineersInventoryRequest = (
  orgId,
  projId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/users`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchFiltersOrdersInventoryRequest = projId => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/jobs/filters`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const removeEngineerInventoryRequest = (
  orgId,
  projId,
  engineerId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/users/${engineerId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrdersInventoryRequest = (
  projId,
  action,
  params,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/jobs/by_location${action}${params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editEngineerOrderInventoryRequest = (
  projId,
  orderId,
  data,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/jobs/${orderId}/performer_user`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const removeOrderInventoryRequest = (projId, orderId) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/jobs/${orderId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const clearInventoryProjectRequest = (
  orgId,
  projId,
  offerId,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${orgId}/inventory_projects/${projId}/offers/${offerId}/cancel`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchHistoryInventoryRequest = (projId, filters) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/logs${
        filters ? `?${queryString.stringify(filters)}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchHistoryCountInventoryRequest = projId => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inventory_projects/${projId}/logs/count`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createServiceDeskRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(`${URL}/service_calls`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchCallPermissionsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload}/permissions`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchCallHistoryApi = payload => async () => {
  try {
    const { id, limit, offset } = payload
    const response = await fetchProxy(
      `${URL}/service_calls/${id}/history?${queryString.stringify({
        limit,
        offset,
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const assignToThirdPartyDeskRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/set_foreign_performer`,
      {
        method: 'POST',
        body: JSON.stringify({
          performer_organization_id: payload.organization,
          disableActions: payload.disableActions,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const assignRequestToOwnSpecialistApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/set_own_performer`,
      {
        method: 'POST',
        body: JSON.stringify(_.omit(payload, ['callId'])),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const sendToSubOrderRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/send_suborder`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCallsListRequest = (payload = '') => async () => {
  try {
    const response = await fetchProxy(`${URL}/service_calls/list?${payload}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCallsListCountRequest = (
  payload = '',
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/list/count?${payload}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCallOrdersListRequest = (
  payload = '',
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/list?${payload}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCallOrdersListCountRequest = (
  payload = '',
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/list/count?${payload}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCallRequest = (payload = '') => async () => {
  try {
    const response = await fetchProxy(`${URL}/service_calls/${payload}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.requestId}`,
      {
        body: JSON.stringify(payload.data),
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadFileServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.id}/upload_doc?${queryString.stringify({
        disableActions: payload.disableActions,
      })}`,
      {
        body: payload.file,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    if (!response.ok) {
      return { success: false, code: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadPhotoServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.id}/upload_photo?${queryString.stringify({
        disableActions: payload.disableActions,
      })}`,
      {
        body: payload.file,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    if (!response.ok) {
      return { success: false, code: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteDocServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/docs/${payload.docId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deletePhotoServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/photos/${payload.photoId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchFilesServiceDeskCallRequest = (
  payload,
  filter,
) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload}/docs${
        filter ? `?filters=[file_type=${filter}]` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchPhotosServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload}/photos`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const downloadFileServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/downloads/service_desc/${payload.links.replace(
        '/home/app/files/service_desc/',
        '',
      )}`,
      {
        method: 'GET',
        headers: {
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.blob()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const downloadPhotoServiceDeskCallRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/downloads/files/service_desc/${payload.links.replace(
        '/home/app/files/service_desc/',
        '',
      )}`,
      {
        method: 'GET',
        headers: {
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.blob()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCallOrdersApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders?${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const fetchOrdersRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/${payload.params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const performerHandlerOrder = (payload, params) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/${params}`,
      {
        body: JSON.stringify(payload.data),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskCancelRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.id}/cancel`,
      {
        body: JSON.stringify({
          reason: payload.reason,
          disableActions: payload.disableActions,
        }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskRejectOrderRequest = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/reject_order`,
      {
        body: JSON.stringify({
          reject_comment: payload.reject_comment,
          disableActions: payload.disableActions,
        }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const changeResponsibleOrder = (payload, action) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/${action}`,
      {
        body: JSON.stringify(payload.data),
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editOrderInfoApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}`,
      {
        body: JSON.stringify(payload.data),
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskOrdersLogRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/logs/${payload.id}?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskOrdersLogCountRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/logs/${payload.id}/count`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskOrderCommentsRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_comments?${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadServiceDeskOrderCommentRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_comments?${queryString.stringify({
        order_id: payload.order_id,
      })}`,
      {
        body: payload.formData,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          enctype: 'multipart/form-data',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    if (!response.ok) {
      return { success: false, code: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadServiceDeskOrderSubcommentRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_comments/${
        payload.parentId
      }/subcomment?${queryString.stringify({ order_id: payload.order_id })}`,
      {
        body: payload.formData,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    if (!response.ok) {
      return { success: false, code: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskOrderCommentsCountRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_comments/count?${queryString.stringify({
        order_id: payload.order_id,
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const cancelOrderByClientRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/cancel`,
      {
        method: 'PUT',
        body: JSON.stringify({
          cancel_comment: payload.cancelComment,
          disableActions: payload.disableActions,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const downloadFileCommonRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(payload.url, {
      method: 'GET',
      headers: {
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })

    const cd = response.headers.get('content-disposition')
    return {
      success: response.ok,
      blob: await response.blob(),
      code: response.status,
      name: cd && cd.match(/filename="(.+?)"/i)[1],
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createServiceContractsRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(`${URL}/service_desk_contracts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
      body: JSON.stringify(payload),
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const setOrderResultTypeApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/set_order_result`,
      {
        method: 'PUT',
        body: JSON.stringify(payload.body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskOrderItemsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_order_items/?order_id=${payload.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskOrderItemsCountApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_order_items/count?order_id=${payload.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const saveServiceCallOrderItemsListApi = payload => async () => {
  try {
    const response = await fetchProxy(`${URL}/service_call_order_items/list`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const clearServiceCallOrderItemsListApi = payload => async () => {
  try {
    const response = await fetchProxy(`${URL}/service_call_order_items/clear`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const copyOrderItemsFromChildApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_order_items/copy_from_child`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrderNdsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/nds`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrderOverheadApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/overhead`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrderIncomeNdsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/income_nds`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateOrderNdsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/nds`,
      {
        method: 'PUT',
        body: JSON.stringify(payload.data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateOrderIncomeNdsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/income_nds`,
      {
        method: 'PUT',
        body: JSON.stringify(payload.data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateOrderOverheadApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/overhead`,
      {
        method: 'PUT',
        body: JSON.stringify(payload.data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadOrderWorkCompletionFileRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${
        payload.orderId
      }/upload_file?${queryString.stringify({
        disableActions: payload.disableActions,
      })}`,
      {
        body: payload.formData,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          enctype: 'multipart/form-data',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    if (!response.ok) {
      return { success: false, code: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteOrderWorkCompletionFileRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/files/${payload.fileId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const sendOrderToControlApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/send_to_control`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const sendOrderToDoneApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.id}/send_to_done`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const assignUsingContractApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${
        typeof payload === 'string' ? payload : payload.id
      }/set_using_contract`,
      {
        method: 'POST',
        body: JSON.stringify({
          disableActions:
            typeof payload === 'string' ? undefined : payload.disableActions,
        }),
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
//
// export const createServiceContractsRequestApi = payload => async () => {
//   try {
//     const response = await fetchProxy(
//       `${URL}/service_desk_contracts`,
//       {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json',
//           'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
//         },
//         body: JSON.stringify(payload),
//       },
//     )
//     return await response.json()
//   } catch (error) {
//     throw new Error(error.message)
//   }
// }

export const fetchServiceDeskContractRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const addServiceDeskContractAgreementEquipmentsRequestApi = ({
  contractId,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${contractId}/agreement/${agreementId}/equipments`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const assignServiceDeskContractRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/assign`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const cancelServiceDeskContractRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/cancel`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractLogsRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/logs?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskAgreementLogsRequestApi = ({
  contractId,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${contractId}/agreement/${agreementId}/logs?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskAgreementLogsCountRequestApi = ({
  contractId,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${contractId}/agreement/${agreementId}/logs/count?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractLogsCountRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload}/logs/count`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const acceptServiceDeskContractRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/accept`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const rejectServiceDeskContractRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/reject`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractEquipmentsRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/equipments?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchAgreementsRequestApi = ({
  contractId,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${contractId}/agreement?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchAgreementsCountRequestApi = ({
  contractId,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${contractId}/agreement/count?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const cancelServiceDeskContractAgreementRequestApi = ({
  id,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/cancel`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const acceptServiceDeskContractAgreementRequestApi = ({
  id,
  agreementId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/accept`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const rejectServiceDeskContractAgreementRequestApi = ({
  id,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/reject`,
      {
        method: 'POST',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const assignServiceDeskContractAgreementRequestApi = ({
  id,
  agreementId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/assign`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskAgreementEquipmentsRequestApi = ({
  id,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/equipments?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskAgreementEquipmentsCountRequestApi = ({
  id,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/equipments/count?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteServiceDeskAgreementEquipmentsRequestApi = ({
  id,
  agreementId,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/agreement/${agreementId}/equipments?${queryString.stringify(
        other,
      )}`,
      {
        method: 'DELETE',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteServiceDeskContractEquipmentsRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/equipments`,
      {
        method: 'DELETE',
        body: JSON.stringify(other),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractEquipmentsCountRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/equipments/count?${queryString.stringify(
        other,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editServiceDeskContractRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload.data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const assignEngineerToEquipmentsRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/equipments/assign`,
      {
        method: 'POST',
        body: JSON.stringify(transformCamelCaseObjectCaseToSnake(other)),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const unAssignEngineerToEquipmentsRequestApi = ({
  id,
  ...other
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${id}/equipments/unassign`,
      {
        method: 'POST',
        body: JSON.stringify(transformCamelCaseObjectCaseToSnake(other)),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editServiceDeskContractResponsibleRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload.id}/${payload.postfix}`,
      {
        method: 'put',
        body: JSON.stringify(payload.data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createContractRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload.id}/agreement`,
      {
        method: 'POST',
        body: JSON.stringify(payload.data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchContractAgreementRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload.contractId}/agreement/${payload.agreementId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editContractAgreementNameRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload.contractId}/agreement/${payload.agreementId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload.body),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractsRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/list/${
        payload.status
      }?${queryString.stringify(
        _.omitBy(
          {
            sort_direction: payload.sort_direction,
            sort_by: payload.sort_by,
            side: payload.side,
            limit: payload.limit,
            client_organization: payload.client_organization,
            performer_organization: payload.performer_organization,
            offset: payload.offset,
            not_distributed: !!payload.notDistributed,
          },
          v => !v,
        ),
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractPermissionsRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload}/permissions`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractsCountRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/list/${
        payload.status
      }/count?${queryString.stringify(_.omitBy(payload.params, v => !v))}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchFilteringOrganizationsRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/filters/organizations?${queryString.stringify(
        _.omitBy(payload, v => !v),
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getEngineerSettingsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/settings/engineer/?organization_id=${payload.organizationId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCommonOrganizationSettingsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/settings/common/organization/?organization_id=${payload.organizationId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const confirmCertificateOfCompletionApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/confirm_certificate_of_completion`,
      {
        body: JSON.stringify({ disableActions: payload.disableActions }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceCallOrdersApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.ordersType}/?service_call_id=${payload.serviceCallId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const acceptOrderCertificateOfCompletionApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/accept_certificate_of_completion`,
      {
        body: JSON.stringify({ disableActions: payload.disableActions }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const rejectOrderCertificateOfCompletionApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/reject_certificate_of_completion`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify({
          reject_comment: payload.rejectСomment,
          disableActions: payload.disableActions,
        }),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchServiceDeskContractAvailableEquipmentRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${
        payload.clientOrganizationId
      }/equipments?${queryString.stringify(
        payload.payload,
      )}&sort=[organization_location_id=ASC,location_floor=ASC,location_office=ASC,name=ASC]`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
//
// export const fetchServiceDeskContractRequestApi = payload => async () => {
//   try {
//     const response = await fetchProxy(
//       `${URL}/service_desk_contracts/${payload.contractId}`,
//       {
//         method: 'GET',
//         headers: {
//           Accept: 'application/json',
//           'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
//         },
//       },
//     )
//     return await response.json()
//   } catch (error) {
//     throw new Error(error.message)
//   }
// }

export const fetchServiceDeskContractAvailableEquipmentCountRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${
        payload.clientOrganizationId
      }/equipments/count?${queryString.stringify(payload.payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const addEquipmentToContractsRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_desk_contracts/${payload.contractId}/equipments`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(payload.payload),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEquipmentFilterLocationRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${
        payload.organizationId
      }/locations/with_equipment?${queryString.stringify(payload.payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchEquipmentFilterDepartmentRequestApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${
        payload.organizationId
      }/equipments/department_list?${queryString.stringify(payload.payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const closeServiceCallFromNeedrepairApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/close`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
        body: JSON.stringify(payload.data),
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const sendServiceCallToRepairDiagnosticiansApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/send_to_repair_diagnosticians`,
      {
        body: JSON.stringify({
          disableActions: payload.disableActions,
        }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const sendCallUsingContractApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/set_using_contract`,
      {
        method: 'POST',
        body: JSON.stringify({
          disableActions: payload.disableActions,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getOrderPossibleReportsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${payload.orderId}/possible_reports`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCallPossibleBlanksApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${payload.callId}/possible_blanks`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCallsByGeneralSearchApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/general_search?${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getScriptsHistoryRequestApi = ({
  organizationId,
  query,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/actions/history?${queryString.stringify(
        query,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createTagRequestApi = ({ organizationId, data }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/tags`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getRecurringCallsRequestApi = ({
  organizationId,
  limit,
  offset,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/recurring_service_calls?${queryString.stringify({
        organization_id: organizationId,
        limit,
        offset,
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getRecurringCallsRequestCountApi = ({
  organizationId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/recurring_service_calls/count?${queryString.stringify({
        organization_id: organizationId,
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editTagRequestApi = ({
  organizationId,
  tagId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/tags/${tagId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getTagsRequestApi = ({ organizationId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/tags`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteTagRequestApi = ({ organizationId, tagId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/tags/${tagId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getTagRequestApi = ({ organizationId, tagId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/tags/${tagId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getEmailTemplatesRequestApi = ({ organizationId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/emails/templates`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getEmailTemplateRequestApi = ({
  organizationId,
  templateId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/emails/templates/${templateId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createEmailTemplateRequestApi = ({
  organizationId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/emails/templates`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editEmailTemplateRequestApi = ({
  organizationId,
  templateId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/emails/templates/${templateId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteEmailTemplateRequestApi = ({
  organizationId,
  templateId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/emails/templates/${templateId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const executeSDCallButtonRequestApi = ({
  organizationId,
  buttonId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons/execute/${buttonId}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getSDCallButtonsRequestApi = ({ serviceCallId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${serviceCallId}/buttons`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getSDActionsHistoryRequestApi = ({
  id,
  limit,
  offset,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${id}/actionsHistory?${queryString.stringify({
        limit,
        offset,
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createScriptRequestApi = ({
  organizationId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/actions`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editScriptRequestApi = ({
  organizationId,
  scriptId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/actions/${scriptId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getScriptsRequestApi = ({ organizationId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/actions`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteScriptRequestApi = ({
  organizationId,
  scriptId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/actions/${scriptId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getScriptRequestApi = ({
  organizationId,
  scriptId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/actions/${scriptId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getSDCallAvailableScriptsRequestApi = ({
  serviceCallId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${serviceCallId}/availableScripts`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

// ActionsButtons

export const createButtonRequestApi = ({
  organizationId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const editButtonRequestApi = ({
  organizationId,
  buttonId,
  data,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons/${buttonId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getButtonsRequestApi = ({
  organizationId,
  params,
}) => async () => {
  const fullParams = { sort: '[name=ASC]', ...params }
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons?${queryString.stringify(
        fullParams,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteButtonRequestApi = ({
  organizationId,
  buttonId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons/${buttonId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getButtonRequestApi = ({
  organizationId,
  buttonId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons/${buttonId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getButtonsHistoryRequestApi = ({
  organizationId,
  query,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${organizationId}/settings/buttons/history?${queryString.stringify(
        query,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceCallDocumentLinkApi = ({
  callId,
  documentId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${callId}/getDocumentLink/${documentId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceCallBlankDocumentLinkApi = ({ callId }) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${callId}/blanks?responseType=link`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceCallOrderCertificateDocumentLinkApi = ({
  orderId,
  documentId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${orderId}/reports/${documentId}?responseType=link`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceCallOrderDocumentLinkApi = ({
  orderId,
  documentId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_orders/${orderId}/files/${documentId}?responseType=link`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceCallOrderCommentLinkApi = ({
  orderId,
  fileId,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/service_call_comments/getDocumentLink?responseType=link&${queryString.stringify(
        {
          order_id: orderId,
          file_id: fileId,
        },
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const getServiceDeskInnerCallsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls?${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const attachServiceCallTagApi = payload => async () => {
  const { callId } = payload
  const data = _.omit(payload, ['callId'])
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${callId}/tags/assign_to_call`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const makeRecurringFromCall = payload => async () => {
  const { callId, intervalUnit, intervalValue, startDate, endDate } = payload
  try {
    const response = await fetchProxy(
      `${URL}/recurring_service_calls/make_from_call`,
      {
        method: 'POST',
        body: JSON.stringify({
          call_id: callId,
          interval_unit: intervalUnit,
          interval_value: intervalValue,
          start_date: startDate,
          end_date: endDate,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const editRecurringCall = payload => async () => {
  const { id, intervalUnit, intervalValue, startDate, endDate } = payload
  try {
    const response = await fetchProxy(`${URL}/recurring_service_calls/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        interval_unit: intervalUnit,
        interval_value: intervalValue,
        start_date: startDate,
        end_date: endDate,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const deleteRecurringCall = payload => async () => {
  const { id } = payload
  try {
    const response = await fetchProxy(`${URL}/recurring_service_calls/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const getServiceDeskInnerCallsAnalyticsSummaryApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/created_done_chart_in_general_extra?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const detachServiceCallTagApi = payload => async () => {
  const { callId } = payload
  const data = _.omit(payload, ['callId'])
  try {
    const response = await fetchProxy(
      `${URL}/service_calls/${callId}/tags/unassign_from_call`,
      {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const getServiceDeskInnerCallsAnalyticsSummaryAverageApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/created_done_average_inwork_time_chart_by_engineer_extra?` +
        `${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallsEngineerCountApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/engineers_for_statistics_count?` +
        `${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
export const getServiceDeskInnerCallAnalyticsChartCreatedApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/created_done_chart_in_general?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallAnalyticsChartClosedByEngineerApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/created_done_chart_by_engineer?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallAnalyticsChartAverageApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/average_inwork_time_chart_by_engineer?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallsCountApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/count?${queryString.stringify(payload)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallsNumbersApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/inner_calls_numbers?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallsEngineersApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/engineers?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallsEquipmentsApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/statistics/equipments?${queryString.stringify(
        payload,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallApi = payload => async () => {
  try {
    const response = await fetchProxy(`${URL}/inner_calls/${payload}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallHistoryApi = ({
  innerCallId,
  query,
}) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/${innerCallId}/history?${queryString.stringify(
        query,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const postMultipart = (path, body) => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/${path.replace(new RegExp('^[/]', 'g'), '')}`,
      {
        method: 'POST',
        body,
        type: 'formData',
        headers: {
          Accept: 'application/json',
          enctype: 'multipart/form-data',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallPhotosApi = payload => async () => {
  try {
    const response = await fetchProxy(`${URL}/inner_calls/${payload}/photos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
      },
    })
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getServiceDeskInnerCallPhotosCountApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/${payload}/photos/count`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const uploadInnerCallPhotoApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/${payload.innerCallId}/photos`,
      {
        body: payload.file,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    if (!response.ok) {
      return { success: false, code: response.status }
    }
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteInnerCallPhotoApi = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/inner_calls/${payload.callId}/photos/${payload.photoId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchBindedEquipment = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${payload.organizationId}/equipments/${payload.equipmentId}/binded_ba`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchOrganizationEquipmentTOWorks = payload => async () => {
  try {
    const response = await fetchProxy(
      `${URL}/organizations/${payload.organizationId}/equipments/${payload.equipmentId}/binded_ba/to?id=${payload.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return await response.json()
  } catch (error) {
    throw new Error(error.message)
  }
}
