import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ConfirmInventoryProjectPerformerDialog from './ConfirmInventoryProjectPerformerDialog'
import {
  confirmInventoryProjectAsPerformer,
  editEngineersInventory,
  setOwnPerformer,
} from '../../../../actions/inventoryActions'
import {
  fetchBoundOrganizationUsers,
  fetchOrganizationStaff,
} from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  isConfirmingInventoryProjectAsPerformer:
    state.inventory.inventoryProjectConfirmAsPerformer
      .isConfirmingInventoryProjectAsPerformer,
  confirmingErrors:
    state.inventory.inventoryProjectConfirmAsPerformer.confirmingErrors,
  boundUsers: state.staff.boundOrganizationUsers.boundUsers,
  isFetchingBoundUsers: state.staff.boundOrganizationUsers.isFetchingBoundUsers,
  engineers: state.inventory.engineers.engineers,
  isFetchEngineers: state.inventory.engineers.isFetch,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  organizationStaff: state.staff.organizationStaff,
  project: state.inventory.project,
  isEditEngineers: state.inventory.engineersEdit.isEdit,
  isEditEngineersSuccess: state.inventory.engineersEdit.isEditSuccess,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBoundOrganizationUsers,
      confirmInventoryProjectAsPerformer,
      editEngineersInventory,
      fetchOrganizationStaff,
      setOwnPerformer,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ConfirmInventoryProjectPerformerDialog),
)
