import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'

// modules
import CommentComponent from './CommentComponent'
import TablePagination from '@material-ui/core/TablePagination/TablePagination'
import i18next from '../../i18n/i18n'
import shortId from 'shortid'
// icons

const styles = theme => ({
  divRoot: {
    padding: theme.spacing(2),
  },
  divSubComment: {
    marginLeft: theme.typography.pxToRem(50),
  },
})

const CommentsList = props => {
  const {
    comments,
    classes,
    doReplyFunc,
    pagination,
    limit,
    commentsCount,
    page,
    downloadFileRequest,
    fileUrlCreator,
    getPreviewPath,
  } = props
  return (
    <div>
      {comments.length > 0 && (
        <Fragment>
          {comments.map(comment => (
            <div className={classes.divRoot} key={shortId.generate()}>
              <CommentComponent
                comment={comment}
                doReplyFunc={doReplyFunc}
                fileUrlCreator={fileUrlCreator}
                downloadFileRequest={downloadFileRequest}
                getPreviewPath={getPreviewPath}
              />
            </div>
          ))}
          <TablePagination
            component="div"
            rowsPerPageOptions={pagination}
            count={commentsCount}
            rowsPerPage={limit}
            page={page}
            labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
            labelDisplayedRows={({
              from: fromPage,
              to: toPage,
              count: countPage,
            }) =>
              i18next.t('shared_web_atoir:labelDisplayedRows', {
                fromPage,
                toPage,
                countPage,
              })
            }
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={(event, newPage) => props.setOptions('page', newPage)}
            onChangeRowsPerPage={event =>
              props.setOptions('limit', event.target.value)
            }
          />
        </Fragment>
      )}
    </div>
  )
}

CommentsList.defaultProps = {
  pagination: [25, 50, 100],
  limit: 50,
  page: 1,
  fileUrlCreator: () => {},
  downloadFileRequest: () => {},
  getPreviewPath: () => {},
}

CommentsList.propTypes = {
  comments: PropTypes.array.isRequired,
  doReplyFunc: PropTypes.func.isRequired,
  pagination: PropTypes.array,
  limit: PropTypes.number,
  page: PropTypes.number,
  commentsCount: PropTypes.number.isRequired,
  setOptions: PropTypes.func.isRequired,
  fileUrlCreator: PropTypes.func,
  downloadFileRequest: PropTypes.func,
  getPreviewPath: PropTypes.func,
}

export default withStyles(styles)(CommentsList)
