import React, { Fragment } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import i18next from '../../../../../../i18n/i18n'
import DateInput from '../../../../../../widgets/DateInput'

const PrimaryRadio = withStyles(theme => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />)

const MakeRecurringDialog = ({
  intervalUnit,
  onChangeIntervalUnit,
  intervalValue,
  onChangeIntervalValue,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
}) => {
  return (
    <Fragment>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">
          {i18next.t('service_desk_web_atoir:interval')}:
        </FormLabel>
        <RadioGroup
          name="intervalUnit"
          value={intervalUnit}
          onChange={onChangeIntervalUnit}
        >
          <FormControlLabel
            value="d"
            control={<PrimaryRadio />}
            label={i18next.t('shared_web_atoir:day')}
          />
          <FormControlLabel
            value="m"
            control={<PrimaryRadio />}
            label={i18next.t('shared_web_atoir:month')}
          />
          <FormControlLabel
            value="q"
            control={<PrimaryRadio />}
            label={i18next.t('shared_web_atoir:quarter')}
          />
          <FormControlLabel
            value="y"
            control={<PrimaryRadio />}
            label={i18next.t('shared_web_atoir:year')}
          />
        </RadioGroup>
      </FormControl>
      <br />
      <TextField
        label={i18next.t(
          `service_desk_web_atoir:number_of_${
            {
              d: 'day',
              m: 'month',
              q: 'quarter',
              y: 'year',
            }[intervalUnit]
          }s`,
        )}
        required
        fullWidth
        value={intervalValue}
        onChange={onChangeIntervalValue}
        error={intervalValue === null}
        helperText={
          intervalValue === null
            ? i18next.t('validation_web_atoir:fieldShouldBeFilled')
            : null
        }
      />
      <br />
      <DateInput
        id="start-recurring-date"
        fullWidth
        disabledDays={{ before: new Date() }}
        value={startDate}
        label={i18next.t('service_desk_web_atoir:start_date')}
        handleChange={onChangeStartDate}
        error={!startDate}
        helperText={
          startDate === ''
            ? i18next.t('validation_web_atoir:fieldShouldBeFilled')
            : null
        }
      />
      <br />
      <DateInput
        fullWidth
        value={endDate}
        disabledDays={{
          before: new Date(),
        }}
        id="end-recurring-date"
        label={i18next.t('service_desk_web_atoir:end_date')}
        handleChange={onChangeEndDate}
      />
    </Fragment>
  )
}

export default MakeRecurringDialog
