import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AddStaffDialogComponent from './AddStaffDialogComponent'
import {
  addOrganizationUser,
  updateOrganizationUser,
  deleteOrganizationUser,
  fetchOrganizationLocations,
  completeCreateUpdateOrganizationUser,
} from '../../../actions/staffActions'

const mapStateToProps = state => ({
  isAddingOrganizationUser: state.staff.isAddingOrganizationUser,
  organizationUserId: state.staff.organizationUserId,
  addUserError: state.staff.addUserError,
  isUpdatingOrganizationUser: state.staff.isUpdatingOrganizationUser,
  isBindingUserAccount: state.staff.isBindingUserAccount,
  bindUserAccountError: state.staff.bindUserAccountError,
  isUnbindingUserAccount: state.staff.isUnbindingUserAccount,
  unbindUserAccountError: state.staff.unbindUserAccountError,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addOrganizationUser,
      updateOrganizationUser,
      deleteOrganizationUser,
      fetchOrganizationLocations,
      completeCreateUpdateOrganizationUser,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddStaffDialogComponent),
)
