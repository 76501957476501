import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import HomeComponent from './HomeComponent'
import { confirmEmail } from '../../../actions/registerAccountActions'
import { confirmRepairPassword } from '../../../actions/repairPasswordActions'

const mapStateToProps = state => ({
  isConfirmingEmailRegistration:
    state.registerAccount.isConfirmingEmailRegistration,
  isConfirmingRepairPassword: state.repairPassword.isConfirmingRepairPassword,
  canContinueRegistration: state.registerAccount.canContinueRegistration,
  canRepairPassword: state.repairPassword.canRepairPassword,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirmEmail,
      confirmRepairPassword,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeComponent),
)
