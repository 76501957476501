import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// material-ui
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'

// components
import SimpleDialogLoader from '../SimpleDialogLoader'
import ContainedButton from '../Buttons/ContainedButton'
import OutlinedButton from '../Buttons/OutlinedButton'
import ApplyButton from '../Buttons/ApplyButton'

const styles = theme => ({
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // marginBottom: -theme.spacing(1),
  },
  buttonWrapperLeft: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: -theme.spacing(1),
  },
  dialogActions: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  marginNull: {},
  closeBtn: {
    alignSelf: 'flex-start',
    transform: 'translate(20px, -20px)',
  },
  dialogTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  secondaryLeft: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
    marginBottom: 0,
    marginTop: theme.spacing(1),
  },
  button: {
    marginRight: 0,
    marginLeft: theme.spacing(1),
    marginBottom: 0,
    marginTop: theme.spacing(1),
  },
  contained: {
    minWidth: 80,
    marginRight: 0,
    marginLeft: theme.spacing(1),
    marginBottom: 0,
    marginTop: theme.spacing(1),
  },
  borderBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: 16,
  },
})

//  positiveButtonVariant: PropTypes.oneOf(['contained', 'destructive', 'secondary', 'applyButton']),

function buttonLibrary(variant) {
  let button = ContainedButton
  const buttons = {
    contained() {
      button = ContainedButton
    },
    destructive() {
      button = OutlinedButton
    },
    secondary() {
      button = OutlinedButton
    },
    applyButton() {
      button = ApplyButton
    },
    default() {
      button = ContainedButton
    },
  }

  ;(buttons[variant] || buttons.default)()

  return button
}

class FormDialog extends Component {
  handleSubmit = event => {
    event.preventDefault()
    const {
      props: { onSubmit },
    } = this
    onSubmit()
  }

  invertButtons = (buttons, invert) => (invert ? buttons.reverse() : buttons)

  render() {
    const {
      props: {
        open,
        onClose,
        onExit,
        isLoading,
        title,
        contentText,
        invert,
        children,
        submitButtonTitle,
        secondaryButtonsArray,
        leftSecondaryButtonsArray,
        classes,
        fullScreen,
        fullWidth,
        closeIconHidden,
        positiveButtonVariant,
        secondaryButtonVariant,
        positiveDisabled,
        DialogContentStyle,
        dialogProps,
      },
    } = this

    return (
      <Fragment>
        <Dialog
          {...dialogProps}
          open={open}
          onClose={onClose}
          onExit={onExit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={fullScreen}
          fullWidth={fullWidth}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            <div className={classes.dialogTitleContent}>
              {title || <span />}
              {!closeIconHidden && (
                <IconButton
                  className={classes.closeBtn}
                  color="inherit"
                  onClick={onClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </DialogTitle>
          <DialogContent
            style={DialogContentStyle}
            className={classes.dialogContent}
          >
            <DialogContentText id="alert-dialog-description">
              {contentText}
            </DialogContentText>
            <form onSubmit={this.handleSubmit} noValidate>
              {children}
            </form>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Grid container spacing={0} className={classes.marginNull}>
              <Grid item xs={!isEmpty(leftSecondaryButtonsArray) ? 6 : 0}>
                <div className={classes.buttonWrapperLeft}>
                  {!isEmpty(leftSecondaryButtonsArray) &&
                    leftSecondaryButtonsArray.map(_secondaryItem => (
                      <OutlinedButton
                        key={_secondaryItem.secondaryText}
                        size="medium"
                        classes={{ root: classes.secondaryLeft }}
                        onClick={_secondaryItem.handleSecondaryAction}
                      >
                        {_secondaryItem.secondaryText}
                      </OutlinedButton>
                    ))}
                </div>
              </Grid>
              <Grid item xs={isEmpty(leftSecondaryButtonsArray) ? 12 : 6}>
                <div className={classes.buttonWrapper}>
                  {this.invertButtons(
                    [
                      !isEmpty(secondaryButtonsArray) &&
                        secondaryButtonsArray.map(_secondaryItem =>
                          React.createElement(
                            buttonLibrary(
                              _secondaryItem.variant || secondaryButtonVariant,
                            ),
                            {
                              key: _secondaryItem.secondaryText,
                              size: 'medium',
                              classes: { root: classes.button },
                              onClick: _secondaryItem.handleSecondaryAction,
                            },
                            _secondaryItem.secondaryText,
                          ),
                        ),
                      React.createElement(
                        buttonLibrary(positiveButtonVariant),
                        {
                          key: submitButtonTitle,
                          size: 'medium',
                          type: 'submit',
                          classes: { root: classes.button },
                          onClick: this.handleSubmit,
                          disabled: positiveDisabled,
                          'data-cy': 'DialogPositiveBtn',
                        },
                        submitButtonTitle,
                      ),
                    ],
                    invert,
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <SimpleDialogLoader open={isLoading} />
      </Fragment>
    )
  }
}
FormDialog.defaultProps = {
  contentText: '',
  open: false,
  isLoading: false,
  title: '',
  children: '',
  submitButtonTitle: '',
  secondaryButtonsArray: [],
  leftSecondaryButtonsArray: [],
  fullWidth: false,
  fullScreen: false,
  closeIconHidden: false,
  invert: false,
  positiveButtonVariant: 'contained',
  secondaryButtonVariant: 'secondary',
  positiveDisabled: false,
  DialogContentStyle: {},
  dialogProps: {},
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  title: PropTypes.string,
  contentText: PropTypes.string,
  children: PropTypes.node,
  submitButtonTitle: PropTypes.string,
  secondaryButtonsArray: PropTypes.array,
  leftSecondaryButtonsArray: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeIconHidden: PropTypes.bool,
  invert: PropTypes.bool,
  positiveButtonVariant: PropTypes.oneOf([
    'contained',
    'destructive',
    'secondary',
    'applyButton',
  ]),
  secondaryButtonVariant: PropTypes.oneOf(['secondary', 'applyButton']),
  positiveDisabled: PropTypes.bool,
  DialogContentStyle: PropTypes.object,
  dialogProps: PropTypes.object,
}

export default withStyles(styles)(FormDialog)
