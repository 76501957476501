import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import Input from '@material-ui/core/Input'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DoneIcon from '@material-ui/icons/Done'
import ListItemText from '@material-ui/core/ListItemText'
import FormHelperText from '@material-ui/core/FormHelperText'
import * as _ from 'lodash'

const styles = {
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}

const SelectorChipsed = ({
  classes,
  onChange,
  idValue,
  valueList,
  error,
  errorText,
  fullWidth,
  currentValue,
  onBlur,
}) => {
  const onChangeHandler = ({ target: { value } }) => {
    // используем _.intersection для сохранения порядка
    onChange(
      idValue,
      _.intersection(
        valueList.map(item => item.id),
        value,
      ),
    )
  }

  const onBlurHandler = () => {
    onBlur(idValue)
  }
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <Select
        multiple
        value={currentValue}
        onChange={onChangeHandler}
        input={<Input id="select-multiple-chip" />}
        MenuProps={{
          onExiting: onBlurHandler,
        }}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(value => (
              <Chip
                key={value}
                label={valueList.find(item => item.id === value).description}
                className={classes.chip}
              />
            ))}
          </div>
        )}
      >
        {valueList.map(item => (
          <MenuItem id={item.id} key={item.id} value={item.id}>
            <ListItemIcon>
              {_.includes(currentValue, item.id) ? <DoneIcon /> : null}
            </ListItemIcon>
            <ListItemText inset primary={item.description} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error && errorText ? errorText : ''}</FormHelperText>
    </FormControl>
  )
}

SelectorChipsed.defaultProps = {
  error: false,
  fullWidth: false,
  errorText: null,
  onBlur: () => {},
}

SelectorChipsed.propTypes = {
  fullWidth: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  valueList: PropTypes.array.isRequired,
  currentValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
}

export default withStyles(styles)(memo(SelectorChipsed))
