import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchCountryNames } from '../../../../actions/equipmentActions'

import Downshift from 'downshift'
import i18next from '../../../../i18n/i18n'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

function getSuggestions(inputValue, suggestions) {
  return suggestions
    .map(suggestion => {
      const index = suggestion.full_name
        .toLowerCase()
        .indexOf(inputValue.toLowerCase())
      return { suggestion, index }
    })
    .filter(o => o.index !== -1)
    .sort(
      (suggestionObjA, suggestionObjB) =>
        suggestionObjA.index - suggestionObjB.index,
    )
    .map(o => o.suggestion)
    .slice(0, 5)
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.full_name}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.full_name}
    </MenuItem>
  )
}

function renderInput(inputProps) {
  const { InputProps, ref, error, ...other } = inputProps

  return (
    <TextField
      error={error}
      fullWidth
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      {...other}
    />
  )
}

renderSuggestion.propTypes = {
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

function CountrySelect({
  idValue,
  callFetchCountryNames,
  countries,
  value,
  onChange,
  classes,
  onBlur,
  error,
  disabled = false,
}) {
  React.useEffect(() => {
    callFetchCountryNames()
  }, [])

  React.useEffect(() => {
    if (
      countries.length > 0 &&
      value[idValue] &&
      value[idValue].id &&
      !value.text
    ) {
      const country = countries.find(c => c.id === value[idValue].id)
      onChangeHandler({ text: country.name, [idValue]: country })
    }
  }, [countries, value])

  const valueRef = React.useRef(value)

  React.useEffect(() => {
    valueRef.current = value
  }, [value])

  const countriesRef = React.useRef(countries)
  React.useEffect(() => {
    countriesRef.current = countries
  }, [countries])

  const tryToFindValue = React.useCallback(
    _.debounce(() => {
      const country = countriesRef.current.find(
        c => c.name === valueRef.current.text,
      )
      if (country) {
        onChangeHandler({ text: country.name, [idValue]: country })
      }
    }, 100),
    [],
  )

  const onChangeHandler = newValue => {
    onChange(idValue, newValue)
  }
  function textOnChange({ target: { value: text } }) {
    if (text === '') {
      onChangeHandler(null)
    } else {
      onChangeHandler({ text })
      // если пользовель предпочтет не выбирать из списка а ввести вручную
      tryToFindValue()
    }
  }
  function valueOnChange(country) {
    onChangeHandler({ text: country.name, [idValue]: country })
  }
  return (
    <Downshift
      inputValue={value.text || ''}
      onChange={valueOnChange}
      selectedItem={value[idValue] || null}
      itemToString={country => (country ? country.name : '')}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div className={classes.textField}>
          {renderInput({
            disabled,
            fullWidth: true,
            label: i18next.t('equipment_web_atoir:country'),
            error,
            InputProps: getInputProps({
              onChange: textOnChange,
              id: idValue,
              onBlur,
            }),
          })}
          {isOpen ? (
            <Paper className={classes.paper} square>
              {getSuggestions(inputValue, countries).map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({ item: suggestion }),
                  highlightedIndex,
                  selectedItem,
                }),
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  )
}

const mapStateToProps = state => ({
  isFetchingCountryNames: state.equipment.isFetchingCountryNames,
  countries: state.equipment.countries,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      callFetchCountryNames: fetchCountryNames,
    },
    dispatch,
  )

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CountrySelect),
)
