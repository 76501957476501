import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEmpty from 'is-empty'
import i18next from '../../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

// components
import DirectionIcon from './DirectionIcon'

// helpers
import {
  dateFormatShortYear,
  timeFormatHourMinutes,
  convertDHMDuration,
} from '../../../../../helpers/dateHelper'
import { getUserFullName } from '../../../../../helpers/userHelper'
import { organizationLocationStringifyFull } from '../../../../../helpers/parserHelper'

const styles = theme => ({
  defaultText: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  inline: {
    display: 'inline',
  },
  heading: {
    fontSize: 16,
  },
  subHeadingDark: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 14,
  },
  subHeading: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
  },
  tagGridMargin: {
    marginRight: theme.spacing(2),
  },
})

function directDetect(call) {
  const { sender, isPerformer, isClient } = call
  let direction = 'unavailable'
  if (
    (sender === 'performer' && isPerformer && !isClient) ||
    (sender === 'client' && !isPerformer && isClient)
  ) {
    direction = 'outgoing'
  }
  if (
    (sender === 'performer' && !isPerformer && isClient) ||
    (sender === 'client' && isPerformer && !isClient)
  ) {
    direction = 'incoming'
  }
  return direction
}

function directColorDetect(call) {
  const { isPerformer, isClient } = call
  let color = 'rgba(0, 0, 0, 0.54)'
  if (isPerformer && !isClient) {
    color = 'rgb(76, 175, 80)'
  }
  if (!isPerformer && isClient) {
    color = '#1976d2'
  }
  return color
}

function generateTooltip(call) {
  const tooltipsDictionary = {
    innerByClient:
      'Наша организация создала заявку. Наша организация в заявке - заказчик',
    innerByPerformer:
      'Наша организация создала заявку. Наша организация в заявке - исполнитель',
    outerByPerformer:
      'Сторонняя организация создала заявку. Наша организация в заявке - заказчик',
    outerByClient:
      'Сторонняя организация создала заявку. Наша организация в заявке - исполнитель',
    deadpool: 'Обе организации ваши',
  }
  const { sender, isPerformer, isClient } = call
  let tooltip = ''
  if (sender === 'client' && !isPerformer && isClient) {
    tooltip = tooltipsDictionary.innerByClient
  }
  if (sender === 'performer' && isPerformer && !isClient) {
    tooltip = tooltipsDictionary.innerByPerformer
  }
  if (sender === 'client' && isPerformer && !isClient) {
    tooltip = tooltipsDictionary.outerByClient
  }
  if (sender === 'performer' && !isPerformer && isClient) {
    tooltip = tooltipsDictionary.outerByPerformer
  }
  if (isPerformer && isClient) {
    tooltip = tooltipsDictionary.deadpool
  }

  return tooltip
}

class InnerCallsTableCell extends PureComponent {
  render() {
    const {
      props: { classes, innerCall, ifOneOrganizationId },
    } = this
    return (
      <div>
        <Fragment>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Typography className={classes.defaultText} component="span">
              {i18next.t(`inner_calls_web_atoir:${innerCall.status}Status`)}
            </Typography>
            <DirectionIcon
              value={directDetect(innerCall)}
              color={directColorDetect(innerCall)}
              tooltip={generateTooltip(innerCall)}
            />
          </div>
          <Typography
            variant="h6"
            className={classes.heading}
            gutterBottom
            component="div"
          >
            {i18next.t('inner_calls_web_atoir:innerCallNumberFromDate', {
              innerCallNum: innerCall.id,
              dateFrom: `${dateFormatShortYear(
                innerCall.createdAt,
              )} ${timeFormatHourMinutes(innerCall.createdAt)}`,
            })}
          </Typography>
        </Fragment>
        {ifOneOrganizationId !== innerCall.client_organization.id && (
          <div>
            <Typography variant="body2" component="span">
              Заказчик:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {innerCall.client_organization.short_name}
            </Typography>
            <Typography variant="body2" component="span">
              {' '}
              &#8226; Адрес:{' '}
            </Typography>
            <Typography variant="body2" component="span">
              {organizationLocationStringifyFull(
                innerCall.client_organization.OrganizationLocations[0],
              )}
            </Typography>
          </div>
        )}
        {ifOneOrganizationId !== innerCall.performer_organization.id && (
          <div>
            <Typography variant="body2" component="span">
              Исполнитель:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {innerCall.performer_organization.short_name}
            </Typography>
            <Typography variant="body2" component="span">
              {' '}
              &#8226; Адрес:{' '}
            </Typography>
            <Typography variant="body2" component="span">
              {organizationLocationStringifyFull(
                innerCall.performer_organization.OrganizationLocations[0],
              )}
            </Typography>
          </div>
        )}
        <Fragment>
          <Typography
            variant="subheading"
            component="span"
            className={classnames([classes.inline, classes.subHeading])}
          >
            {i18next.t('inner_calls_web_atoir:innerCallInWorkTime', {
              inWorkTime: convertDHMDuration(innerCall.work_time),
            })}
          </Typography>
          {innerCall.performer && innerCall.isPerformer ? (
            <>
              <span
                className={classnames([classes.inline, classes.subHeading])}
              >
                {' '}
                &#8226;{' '}
              </span>
              <Typography
                variant="subheading"
                component="span"
                className={classnames([classes.inline, classes.subHeading])}
              >
                {`${i18next.t(
                  'service_desk_web_atoir:performer',
                )}: ${getUserFullName(innerCall.performer)}`}
              </Typography>
            </>
          ) : null}
          {!isEmpty(innerCall.OrganizationEquipment) ? (
            <Fragment>
              <span
                className={classnames([classes.inline, classes.subHeading])}
              >
                {' '}
                &#8226;{' '}
              </span>
              <Typography
                variant="subheading"
                component="span"
                className={classnames([classes.inline, classes.subHeading])}
              >
                {i18next.t('inner_calls_web_atoir:innerCallEquipmentName', {
                  equipmentName: innerCall.OrganizationEquipment.name,
                })}
              </Typography>
            </Fragment>
          ) : null}
        </Fragment>
      </div>
    )
  }
}

InnerCallsTableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  innerCall: PropTypes.object.isRequired,
}

export default withStyles(styles)(InnerCallsTableCell)
