import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { appActions, useAppDispatch } from '../appContext'

function GlobalSearchWidget() {
  const dispatch = useAppDispatch()

  const showFullView = () => {
    dispatch(appActions.setGlobalSearchShow(true))
  }

  return (
    <React.Fragment>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        data-cy="ExitToAppBtn"
        onClick={showFullView}
      >
        <SearchIcon />
      </IconButton>
    </React.Fragment>
  )
}

export default GlobalSearchWidget
