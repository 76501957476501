import React from 'react'

import { withStyles } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

// icons
import MenuIcon from '@material-ui/icons/Menu'

import Sensitive from './Sensitive'
import i18next from '../../../../i18n/i18n'

const styles = theme => ({
  root: {
    borderRadius: 0,
    paddingBottom: 11,
    paddingTop: 11,
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'hidden',
    textTransform: 'none',
  },
  label: {
    justifyContent: 'left',
  },
  icon: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  text: {
    color: theme.palette.blueSecondary.main,
    fontWeight: 500,
  },
})

function SensitiveButton({ collapsed, setCollapsed, classes, onClick }) {
  return (
    <Sensitive collapsed={collapsed} setCollapsed={setCollapsed}>
      <Button
        fullWidth
        className={classes.root}
        classes={{ label: classes.label }}
        onClick={onClick}
      >
        <MenuIcon color="primary" className={classes.icon} />
        <Typography
          className={classes.text}
          variant="caption"
          component="div"
          style={{ whiteSpace: 'nowrap' }}
        >
          {i18next.t('shared_web_atoir:setMenuExpanded')}
        </Typography>
      </Button>
    </Sensitive>
  )
}

export default withStyles(styles)(SensitiveButton)
