import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid/Grid'
import i18next from '../../../../../../i18n/i18n'
import LocationPickerComponent from '../../../../../../widgets/LocationPicker'
import FormControlText from '../../../../../../widgets/Controlls/FormControlText'
import UUIDInput from '../../../../../../widgets/Controlls/UUIDInput'

class EquipmentFilters extends Component {
  // TODO redundant
  state = {}

  onlyAllowedSymbols = cb => event => {
    const value = event.target.value || ''
    cb({
      target: {
        value: value.replace(/[^A-Za-zА-Яа-я0-9&:;#$№–_+* /\\]/gi, ''),
      },
    })
  }

  render() {
    const {
      props: { organizationId, filters, onChange, externalMode },
      onlyAllowedSymbols,
    } = this
    return (
      <Fragment>
        {externalMode ? (
          <Grid container spacing={2}>
            <Grid item lg={5} md={6} sm={10} xs={12}>
              <FormControlText
                onChange={onlyAllowedSymbols(onChange('query'))}
                label="Поиск в имени, в сирийном и инвентарном номерах"
                value={filters.query}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocationPickerComponent
                onChange={onChange('location')}
                organizationId={organizationId}
                organizationLocation={filters.location}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <FormControlText
                onChange={onlyAllowedSymbols(onChange('name'))}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationFiltersName',
                )}
                value={filters.name}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <FormControlText
                onChange={onChange('model')}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationFiltersModel',
                )}
                value={filters.model}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlText
                onChange={onChange('articleNumber')}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationFiltersInventoryNumber',
                )}
                value={filters.articleNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlText
                onChange={onChange('serialNumber')}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationFiltersSerialNumber',
                )}
                value={filters.serialNumber}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <FormControlText
                onChange={onChange('externalId')}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationFiltersExternalId',
                )}
                value={filters.externalId}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <UUIDInput
                onChange={onChange('inventoryCode')}
                label={i18next.t(
                  'service_desk_web_atoir:requestCreationFiltersInventoryCode',
                )}
                value={filters.inventoryCode}
              />
            </Grid>
          </Grid>
        )}
      </Fragment>
    )
  }
}

EquipmentFilters.defaultProps = {
  filters: {},
}

EquipmentFilters.propTypes = {
  organizationId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default EquipmentFilters
