import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import i18next from '../../i18n/i18n'

const stylesHighlightedTemplate = theme => ({
  highlightedColor: {
    color: theme.palette.primary.main,
  },
})

const HighlightedTemplate = ({ children, searchTemplate, classes }) => {
  const value = children
  let chunks = []
  const target = searchTemplate || ''
  const start = value.substring(
    0,
    value.toLowerCase().indexOf(target.toLowerCase()),
  )
  const fullMatch = target.toLowerCase() === value.toLowerCase()

  if (!fullMatch) {
    const rest = value.substring(
      value.toLowerCase().indexOf(target.toLowerCase()) + target.length,
    )
    chunks = rest.split(new RegExp(target.toLowerCase(), 'gi'))
  }

  return (
    <Fragment>
      {value && value.toLowerCase().includes(target.toLowerCase()) ? (
        <Fragment>
          {fullMatch ? (
            <span className={classes.highlightedColor}>{value}</span>
          ) : (
            <Fragment>
              {start}
              {chunks.length > 0 &&
                chunks.map((chunk, index) => (
                  <Fragment key={String(index)}>
                    <span className={classes.highlightedColor}>
                      {target.toLocaleUpperCase()}
                    </span>
                    {chunk}
                  </Fragment>
                ))}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <span>{value || i18next.t('shared_web_atoir:emptyFiled')}</span>
      )}
    </Fragment>
  )
}

HighlightedTemplate.propTypes = {
  searchTemplate: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(stylesHighlightedTemplate)(HighlightedTemplate)
