import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import i18n from '../../../../i18n/i18n'
import FormDialogComponent from '../../../../widgets/FormDialog'

class RejectContract extends Component {
  state = {
    open: false,
    reason: '',
    error: null,
  }

  constructor(props) {
    super(props)
    this.props.createRef(this)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isRejecting !== this.props.isRejecting &&
      this.props.isRejecting === false
    ) {
      this.props.onFinish(this.props.rejectError)
    }
  }

  toggle = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  open = () => {
    this.toggle('open', true)()
  }

  onSubmit = () => {
    const {
      state: { reason },
      props: { contract, rejectContractRequest },
    } = this
    if (!reason) {
      this.setState({
        error: i18n.t('service_desk_web_atoir:reasonMustBeSpecified'),
      })
      return
    }
    rejectContractRequest({ id: contract.id, reject_note: reason })
  }

  onChange = event => {
    this.setState({
      error: null,
      reason: event.target.value,
    })
  }

  render() {
    const {
      state: { open, reason, error },
      toggle,
      onSubmit,
      onChange,
    } = this
    return (
      <FormDialogComponent
        open={open}
        fullWidth
        onSubmit={onSubmit}
        onClose={toggle('open', false)}
        title={i18n.t('service_desk_web_atoir:contractRejection')}
        submitButtonTitle={i18n.t('service_desk_web_atoir:confirm')}
        contentText={
          <Fragment>
            <TextField
              style={{ margin: '8px auto 8px auto' }}
              fullWidth
              error={error}
              multiline
              label={i18n.t('service_desk_web_atoir:contractRejectionReason')}
              variant="outlined"
              value={reason}
              onChange={onChange}
              autoFocus
            />
            <Typography color="error">{error}</Typography>
          </Fragment>
        }
      />
    )
  }
}

RejectContract.defaultProps = {
  createRef: () => {},
}

RejectContract.propTypes = {
  createRef: PropTypes.func,
  contract: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default RejectContract
