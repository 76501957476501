import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RegisterEmailComponent from './RegisterEmailComponent'
import { closeErrorMessage } from '../../../actions/appActions'
import {
  registerEmail,
  getConfirmLinkAgain,
  clearValidationErrors,
} from '../../../actions/registerAccountActions'

const mapStateToProps = state => ({
  isRegisteringEmail: state.registerAccount.isRegisteringEmail,
  shouldResendLink: state.registerAccount.shouldResendLink,
  validationErrors: state.registerAccount.validationErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearValidationErrors,
      registerEmail,
      getConfirmLinkAgain,
      closeErrorMessage,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterEmailComponent),
)
