import React from 'react'
import produce from 'immer'
import { useSelector, useDispatch } from 'react-redux'
import {
  saveUserSettings,
  getAllUserSettings,
} from '../../../actions/appActions'

import { Context } from '../../ServiceDesk/ListRequest/ListSettingsDialog/ListSettingsDialogContext'

const UserSettingsProvider = ({ children, manualSave }) => {
  const dispatch = useDispatch()

  const { setSave } = React.useContext(Context)

  const callSaveUserSettings = React.useCallback(payload => {
    dispatch(saveUserSettings.REQUEST(payload))
  }, [])
  const callGetAllUserSettings = React.useCallback(() => {
    dispatch(getAllUserSettings.REQUEST())
  }, [])

  const { value: userSettings } = useSelector(state => state.app.userSettings)

  const [userSettingsLocal, setUserSettingsLocal] = React.useState({})
  React.useEffect(() => {
    if (userSettings && _.isEmpty(userSettingsLocal)) {
      setUserSettingsLocal(userSettings)
    }
  }, [userSettings])

  React.useEffect(() => {
    callGetAllUserSettings()
  }, [])

  React.useEffect(() => {
    if (userSettingsLocal.sd && !manualSave) {
      callSaveUserSettings({ userSettings: userSettingsLocal })
    }
  }, [userSettingsLocal])

  const save = React.useCallback(() => {
    callSaveUserSettings({ userSettings: userSettingsLocal })
  }, [userSettingsLocal])

  React.useEffect(() => {
    if (setSave) {
      setSave(save)
    }
  }, [save])

  const onChangeById = newValues => {
    // convert to array, для возможности передачи как объекта так и массива объекта
    const values = _.concat([], newValues)
    setUserSettingsLocal(
      produce(draft => {
        values.forEach(item => {
          _.set(draft, item.idValue, item.value)
        })
      }),
    )
  }

  return children({
    settings: userSettingsLocal,
    onChangeById,
    save,
  })
}

export default UserSettingsProvider
