import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { spacing } from '@material-ui/system'

import TextButton from '../../../../widgets/Buttons/TextButton'
import { withStyles } from '@material-ui/core/styles'
import i18next from '../../../../i18n/i18n'

const Box = styled.div`
  ${spacing}
`

const styles = {
  button: {
    marginLeft: 0,
  },
}

function Section({ children, data = [], getData, dataEnd, tittle, classes }) {
  React.useEffect(() => {
    getData()
  }, [])
  return (
    <Box mb={2}>
      <Box>
        <Typography variant="h6">{tittle}</Typography>
      </Box>
      <Divider />
      {React.cloneElement(children, { data })}
      {!dataEnd && (
        <TextButton classes={{ root: classes.button }} onClick={getData}>
          {i18next.t('shared_web_atoir:searchResultMore')}
        </TextButton>
      )}
    </Box>
  )
}

export default withStyles(styles)(Section)

Section.propTypes = {
  children: PropTypes.element.isRequired,
  tittle: PropTypes.string.isRequired,
  // data: PropTypes.array.isRequired,
  // getData: PropTypes.func.isRequired,
  // dataEnd: PropTypes.bool.isRequired,
}
