import { AbilityBuilder } from '@casl/ability'

export default AbilityBuilder.define((can, cannot) => {
  cannot('accept_offer', 'Inventory')
  cannot('add_location', 'Inventory')
  cannot('back_to_inwork', 'Inventory')
  cannot('cancel_offer', 'Inventory')
  cannot('cancel_project', 'Inventory')
  cannot('change_client_rating', 'Inventory')
  cannot('change_client_responsible_user', 'Inventory')
  cannot('change_performer_rating', 'Inventory')
  cannot('change_performer_responsible_user', 'Inventory')
  cannot('change_performer_user', 'Inventory')
  cannot('close_project', 'Inventory')
  cannot('confirm_as_performer', 'Inventory')
  cannot('create_inventory_project_user', 'Inventory')
  cannot('create_work_order', 'Inventory')
  cannot('delete_inventory_project_user', 'Inventory')
  cannot('delete_job', 'Inventory')
  cannot('edit', 'Inventory')
  cannot('reject_as_performer', 'Inventory')
  cannot('remove_location', 'Inventory')
  cannot('send_offer', 'Inventory')
  cannot('set_foreign_performer', 'Inventory')
  cannot('set_own_performer', 'Inventory')
  cannot('set_project_to_done', 'Inventory')
  cannot('start_auction', 'Inventory')
  cannot('stop_auction', 'Inventory')
  cannot('view', 'Inventory')
})
