import React from 'react'
import { withRouter } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'

function UserMenu({ onLogout, history }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = (event = {}) => {
    if (!anchorEl.contains(event.target)) {
      setAnchorEl(null)
    }
  }

  const clickHandler = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const logout = () => {
    handleClose()
    onLogout()
  }

  const goToProfile = () => {
    handleClose()
    history.push('/profile')
  }

  const goToProfileSettings = () => {
    handleClose()
    history.push('/profile/settings')
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  return (
    <React.Fragment>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        data-cy="ExitToAppBtn"
        onClick={clickHandler}
      >
        <AccountCircleOutlinedIcon />
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={handleClose}>
              <Paper elevation={2}>
                <MenuItem onClick={goToProfile}>Мой профиль</MenuItem>
                <MenuItem onClick={goToProfileSettings}>
                  Настройки профиля
                </MenuItem>
                <MenuItem onClick={logout}>Выйти</MenuItem>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default withRouter(UserMenu)
