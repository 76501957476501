import React, { Fragment } from 'react'
import isEmpty from 'is-empty'
import i18next from '../../../../../../i18n/i18n'

// helpers
import {
  getUserFullName,
  stringifyEmployeePosition,
} from '../../../../../../helpers/userHelper'

// material-ui
import Grid from '@material-ui/core/Grid'

// modules
import FormDialog from '../../../../../../widgets/FormDialog'
import Loader from '../../../../../../widgets/SimpleLoader'
import { URL } from '../../../../../../middlewares/api'
import SelectLimitedFormControl from '../../../../../../widgets/SelectLimitedFormControl'
import { addMaskCharacters } from '../../../../../../helpers/phoneStringHelper'
import { sortStringify } from '../../../../../../helpers/queryHelper'

class FormEditResponsible extends React.Component {
  constructor(props) {
    super(props)
    const { responsible } = props
    const changeUser = isEmpty(responsible)
      ? null
      : {
          id: responsible.id,
          title: getUserFullName(responsible),
          position: responsible.position,
          phone: responsible.phone,
        }
    this.state = {
      changeUser,
    }
  }

  onChange = (id, changeUser) => {
    if (id && changeUser) {
      this.setState({ changeUser })
    }
  }

  onSubmit = () => {
    const {
      state: { changeUser },
      props: {
        changePerformerResponsibleOrder,
        changeClientResponsibleOrder,
        changeEngineerOrder,
        engineer,
        performer,
        client,
        idOrder,
        onClose,
      },
    } = this
    if (client) {
      changeClientResponsibleOrder({
        id: idOrder,
        data: {
          responsible_id: changeUser.id,
        },
      })
    }
    if (performer) {
      changePerformerResponsibleOrder({
        id: idOrder,
        data: {
          responsible_id: changeUser.id,
        },
      })
    }
    if (engineer) {
      changeEngineerOrder({
        id: idOrder,
        data: {
          engineer_id: changeUser.id,
        },
      })
    }
    onClose()
  }

  render() {
    const {
      props: {
        open,
        idOrganization,
        isFetchingUser,
        isFetching,
        onClose,
        engineer,
      },
      state: { changeUser },
      onSubmit,
    } = this

    return (
      <FormDialog
        open={open}
        title={
          engineer
            ? i18next.t('service_desk_web_atoir:engineer')
            : i18next.t('service_desk_web_atoir:responsiblePerson')
        }
        submitButtonTitle={i18next.t('shared_web_atoir:edit')}
        onSubmit={onSubmit}
        onClose={onClose}
        onExit={onClose}
        DialogContentStyle={{ minWidth: 550 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Fragment>
              {isFetchingUser || isFetching ? (
                <Loader />
              ) : (
                <SelectLimitedFormControl
                  FormControlProps={{
                    fullWidth: true,
                  }}
                  label={
                    engineer
                      ? i18next.t('service_desk_web_atoir:engineer')
                      : i18next.t('service_desk_web_atoir:responsiblePerson')
                  }
                  query={{
                    ...sortStringify({
                      last_name: 'ASC',
                      first_name: 'ASC',
                      middle_name: 'ASC',
                    }),
                    filters: JSON.stringify({ is_plain_user: true }),
                  }}
                  value={isEmpty(changeUser) ? '' : changeUser.id}
                  sourceUrl={
                    engineer
                      ? `${URL}/organizations/${idOrganization}/users/only_binded`
                      : `${URL}/organizations/${idOrganization}/users`
                  }
                  onChange={this.onChange}
                  requestResultTransformerToArray={r => r.users}
                  itemTransformer={l => ({
                    id: l.id,
                    title: getUserFullName(l),
                  })}
                  helperText={_.compact([
                    changeUser &&
                      changeUser.position &&
                      stringifyEmployeePosition(changeUser.position),
                    changeUser &&
                      changeUser.phone &&
                      addMaskCharacters(changeUser.phone),
                  ]).join(', ')}
                />
              )}
            </Fragment>
          </Grid>
        </Grid>
      </FormDialog>
    )
  }
}

export default FormEditResponsible
