import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  fetchInventoryEquipmentSuccess,
  fetchInventoryEquipmentFailure,
  fetchInventoryEquipmentLocationsSuccess,
  fetchInventoryEquipmentLocationsFailure,
  fetchInventoryEquipmentFiltersSuccess,
  fetchInventoryEquipmentFiltersFailure,
  fetchInventoryEquipmentCountSuccess,
  fetchInventoryEquipmentCountFailure,
} from '../actions/inventoryEquipmentActions'
import {
  GET_INVENTORY_EQUIPMENT_REQUEST,
  GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST,
  GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST,
  GET_INVENTORY_EQUIPMENT_COUNT_REQUEST,
} from '../constants/actionTypes'
import { inventoryProjectEquipmentRequest } from './api'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'
import { inventoryLocationStringify } from '../helpers/parserHelper'
import i18next from '../i18n/i18n'

export function* watchFetchInventoryEquipmentSaga() {
  yield takeLatest(GET_INVENTORY_EQUIPMENT_REQUEST, fetchInventoryEquipment)
}

export function* watchFetchInventoryEquipmentFiltersSaga() {
  yield takeLatest(
    GET_INVENTORY_EQUIPMENT_LOCATIONS_REQUEST,
    fetchInventoryEquipmentLocations,
  )
}

export function* watchFetchInventoryEquipmentLocationsSaga() {
  yield takeLatest(
    GET_INVENTORY_EQUIPMENT_FILTERS_REQUEST,
    fetchInventoryEquipmentFilters,
  )
}

export function* watchFetchInventoryEquipmentCountSaga() {
  yield takeLatest(
    GET_INVENTORY_EQUIPMENT_COUNT_REQUEST,
    fetchInventoryEquipmentCount,
  )
}

function* fetchInventoryEquipment(action) {
  try {
    const result = yield call(
      inventoryProjectEquipmentRequest(
        action.projectId,
        '',
        action.params,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          fetchInventoryEquipmentSuccess(
            action.projectId,
            action.filters,
            result.equipments,
          ),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryEquipmentFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryEquipmentLocations(action) {
  try {
    const result = yield call(
      inventoryProjectEquipmentRequest(
        action.projectId,
        'address_list',
        action.params,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        const inventoryLocations = []
        const locationsObject = result.address_list
        _.keys(locationsObject).map(id => {
          const orgLocation = locationsObject[id].organization_location
          const sublocations = locationsObject[id].sub_locations
          _.forIn(sublocations, (cabinets, floor) => {
            cabinets.map(c => {
              const inventoryLocation = {}
              inventoryLocation.floor = floor
              inventoryLocation.cabinet = c.cabinet
              inventoryLocation.equipmentCount = c.equipmentCount
              inventoryLocation.markedEquipmentCount = c.markedEquipmentCount
              inventoryLocation.foundEquipmentCount = c.foundEquipmentCount
              inventoryLocation.location = orgLocation
              return inventoryLocations.push(inventoryLocation)
            })
          })
          return true
        })
        yield put(
          fetchInventoryEquipmentLocationsSuccess(
            inventoryLocations,
            result.count,
          ),
        )
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryEquipmentLocationsFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryEquipmentFilters(action) {
  try {
    const result = yield call(
      inventoryProjectEquipmentRequest(
        action.projectId,
        'filters_data',
        null,
        null,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        const { locations, departments, floors, cabinets } = result.filters_data
        const locationsFilter = []
        if (locations) {
          locations.map(l =>
            locationsFilter.push({
              id: l.id,
              title: inventoryLocationStringify(l),
            }),
          )
        }
        const departmentsFilter = []
        if (departments) {
          _.remove(departments, value => value.id === '')
          departments.map(d =>
            departmentsFilter.push({ id: d.id, title: d.name }),
          )
        }
        const floorsFilter = []
        if (floors) {
          _.remove(floors, value => value === '')
          floors.map(f => floorsFilter.push({ id: f, title: f }))
        }
        const cabinetsFilter = []
        if (cabinets) {
          _.remove(cabinets, value => value === '')
          cabinets.map(c => cabinetsFilter.push({ id: c, title: c }))
        }
        const notSpecified = {
          id: 'notSpecified',
          title: `${i18next.t('shared_web_atoir:notSpecified')}`,
        }
        departmentsFilter.push(notSpecified)
        floorsFilter.push(notSpecified)
        cabinetsFilter.push(notSpecified)
        const filters = {
          locations: locationsFilter,
          departments: departmentsFilter,
          floors: _.sortBy(floorsFilter, 'title'),
          cabinets: _.sortBy(cabinetsFilter, 'title'),
        }
        yield put(fetchInventoryEquipmentFiltersSuccess(filters))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryEquipmentFiltersFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchInventoryEquipmentCount(action) {
  try {
    const result = yield call(
      inventoryProjectEquipmentRequest(
        action.projectId,
        'count',
        action.params,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchInventoryEquipmentCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchInventoryEquipmentCountFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
