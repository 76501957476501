import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import TOWorks from './TOWorks'
import {
  clearOrganizationEquipmentTOWorks,
  fetchOrganizationEquipmentTOWorks,
} from '../../../../../../actions/equipmentActions'

const mapStateToProps = state => ({
  isFetching: state.equipment.toWorks.isFetching,
  works: state.equipment.toWorks.works,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationEquipmentTOWorks,
      clearOrganizationEquipmentTOWorks,
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TOWorks))
