import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import i18next from '../../../../i18n/i18n'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
}))

export default function RadioButtonsGroup({ value, onChange, idValue }) {
  const classes = useStyles()

  const handleChange = event => {
    onChange(idValue, event.target.value)
  }

  return (
    <div>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="call-side"
          name="side"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="client"
            control={<Radio />}
            label={i18next.t('inner_calls_web_atoir:iAmClient')}
          />
          <FormControlLabel
            value="performer"
            control={<Radio />}
            label={i18next.t('inner_calls_web_atoir:iAmPerformer')}
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
}
