import React from 'react'

import CallMadeIcon from '@material-ui/icons/CallMade'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import Tooltip from '@material-ui/core/Tooltip'

import Switch from '../../../../../widgets/Switch'

const DirectionIcon = ({ value, color, tooltip }) => {
  return (
    <Switch value={value}>
      <Switch.Option option="outgoing">
        <Tooltip title={tooltip} placement="right-start">
          <CallMadeIcon fontSize="small" style={{ color }} />
        </Tooltip>
      </Switch.Option>
      <Switch.Option option="incoming">
        <Tooltip title={tooltip} placement="right-start">
          <CallReceivedIcon fontSize="small" style={{ color }} />
        </Tooltip>
      </Switch.Option>
      <Switch.Option option="unavailable">
        <Tooltip title={tooltip} placement="right-start">
          <SyncAltIcon fontSize="small" style={{ color }} />
        </Tooltip>
      </Switch.Option>
    </Switch>
  )
}

export default DirectionIcon
