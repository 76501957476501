import Route from 'route-parser'

export const getEquipmentProfileRoute = () =>
  '/equipment/organization/:organizationId/:equipmentId/:tab'
export const getEditEquipmentProfileRoute = () =>
  '/equipment/organization/:organizationId/:equipmentId/:tab/edit'
export const matchEquipmentProfileRoute = url =>
  new Route(getEquipmentProfileRoute()).match(url)
export const matchEditEquipmentProfileRoute = url =>
  new Route(getEditEquipmentProfileRoute()).match(url)
