import React, { Fragment, useMemo } from 'react'
import List from '@material-ui/core/List'
import TablePagination from '@material-ui/core/TablePagination'
import i18next from '../../../i18n/i18n'
import withStyles from '@material-ui/core/styles/withStyles'
import DelayedLoader from '../../../widgets/DelayedLoader'
import TrustRow from './TrustRow'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'

import AddTrustedOrganizationsDialog from '../AddTrustedOrganizationsDialog'

const styles = () => ({
  root: { position: 'relative' },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  listContainer: {
    minHeight: 71,
  },
  listRoot: {
    width: '100%',
    backgroundColor: '#fff',
    padding: 0,
  },
  fab: {
    position: 'absolute',
    right: -28,
    top: 8,
  },
  fabIcon: {
    color: 'white',
  },
})

const TrustedOrganizationsTable = ({
  classes,
  isFetching,
  page,
  trusts,
  rowsPerPage,
  changePage,
  changeRowsPerPage,
  count = 0,
}) => {
  const rows = useMemo(() => trusts.map(t => <TrustRow trust={t} />), [trusts])
  const handleChangePage = (e, p) => changePage(p)
  const handleChangeRowsPerPage = ({ target: { value } }) =>
    changeRowsPerPage(value)

  const [newItemDialogOpen, setNewItemDialogOpen] = React.useState(false)
  const openNewItemDialog = () => {
    setNewItemDialogOpen(true)
  }
  const closeNewItemDialog = () => {
    setNewItemDialogOpen(false)
  }
  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.listContainer}>
          <List className={classes.listRoot}>
            <Fragment>
              <DelayedLoader
                loaderStyles={{ margin: '0 auto', display: 'block' }}
                show={isFetching}
                delay={500}
                delayOut={500}
              />
              {rows}
            </Fragment>
          </List>
        </div>
        {count !== null && count !== undefined ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 30, 50]}
            count={count}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
            labelDisplayedRows={({
              from: fromPage,
              to: toPage,
              count: countPage,
            }) =>
              i18next.t('shared_web_atoir:labelDisplayedRows', {
                fromPage,
                toPage,
                countPage,
              })
            }
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
        <div className={classes.fab}>
          <Tooltip
            title={i18next.t(
              'trusted_organizations_web_atoir:addNewTrustedOrganization',
            )}
            placement="bottom"
            disableHoverListener={false}
            disableFocusListener
            disableTouchListener
          >
            <span className={classes.tooltipNormalizer}>
              <Fab disabled={false} color="primary" onClick={openNewItemDialog}>
                <AddIcon className={classes.fabIcon} />
              </Fab>
            </span>
          </Tooltip>
        </div>
        <AddTrustedOrganizationsDialog
          doClose={closeNewItemDialog}
          open={newItemDialogOpen}
        />
      </div>
    </Fragment>
  )
}

export default withStyles(styles)(TrustedOrganizationsTable)
