import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../../../../i18n/i18n'
import isEmpty from 'is-empty'

// ---material--
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'

// ---components---
import TableComponent from '../TableComponent'
import TableHeadComponent from '../TableHeadComponent'
import Loader from '../../../../../Loader'
import DetailsOffer from '../DetailsOffer'

class AsPerformer extends Component {
  state = {
    order: 'ASC',
    orderBy: null,
    shortName: null,
    offerId: null,
  }

  componentDidMount() {
    const { updateSorting } = this
    updateSorting()
  }

  componentDidUpdate(prevProps) {
    const {
      updateSorting,
      props: { isOfferSuccess, isOffer, isCancelOffer, isCancelOfferSuccess },
    } = this
    if (
      prevProps.isOfferSuccess !== isOfferSuccess &&
      !isOffer &&
      isOfferSuccess
    ) {
      updateSorting()
    }
    if (
      prevProps.isCancelOfferSuccess !== isCancelOfferSuccess &&
      !isCancelOffer &&
      isCancelOfferSuccess
    ) {
      updateSorting()
    }
  }

  handleRequestSort = property => () => {
    let orderBy = property

    let order = 'DESC'
    if (this.state.orderBy === property) {
      if (this.state.order === 'DESC') {
        order = 'ASC'
      } else if (this.state.order === 'ASC') {
        order = ''
        orderBy = ''
      } else {
        order = 'DESC'
      }
    }
    this.setState({ order, orderBy }, () => {
      this.updateSorting()
    })
  }

  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.updateSorting(300)
      },
    )
  }

  updateSorting = (actionDelay = 0) => {
    const {
      state: { order, orderBy, shortName },
      props: { infoProject, match, fetchOffer },
    } = this
    const sort = orderBy === null ? null : `[${orderBy}=${order}]`
    const filters =
      shortName === null ? null : JSON.stringify({ short_name: shortName })
    const params =
      sort && filters
        ? `?sort=${sort}&filters=${filters}`
        : sort && !filters
        ? `?sort=${sort}`
        : !sort && filters
        ? `?filters=${filters}`
        : ''
    fetchOffer(
      infoProject.client_organization_id,
      match.params.projectId,
      params,
      actionDelay,
    )
  }

  handleChangeOffer = id => {
    this.setState({
      offerId: id,
    })
  }

  handleCancelOffer = (offerId, orgId) => {
    const {
      props: { match, cancelOffer },
    } = this
    cancelOffer(orgId, match.params.projectId, offerId)
    this.setState({
      offerId: null,
    })
  }

  render() {
    const {
      handleRequestSort,
      handleChange,
      handleChangeOffer,
      handleCancelOffer,
      props: { isFetchOffers, classes, offers },
      state: { order, orderBy, shortName, offerId },
    } = this
    const offer =
      offerId !== null ? offers.find(_offer => _offer.id === offerId) : {}
    return (
      <div className={classes.rootDiv}>
        <section className={classes.container}>
          <Typography variant="h6" className={classes.title} gutterBottom>
            {i18next.t('inventory_web_atoir:myOffers')}
          </Typography>
          <Table className={classes.table}>
            <TableHeadComponent
              handleRequestSort={handleRequestSort}
              handleChange={handleChange}
              order={order}
              orderBy={orderBy}
              shortName={shortName}
            />
            {isFetchOffers ? (
              <Loader />
            ) : (
              <TableComponent
                offers={offers}
                handleChangeOffer={handleChangeOffer}
                selectedOffer={offerId}
              />
            )}
          </Table>
          {offerId !== null && !isEmpty(offers) && (
            <DetailsOffer
              textAction={i18next.t('inventory_web_atoir:cancelOffer')}
              offer={offer}
              handleClose={() => {
                handleChangeOffer(null)
              }}
              handleAction={handleCancelOffer}
            />
          )}
        </section>
      </div>
    )
  }
}
const styles = theme => ({
  container: {
    marginTop: '40px',
  },
  title: {
    textAlign: 'left',
  },
  rootDiv: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
})

AsPerformer.defaultProps = {
  infoProject: {},
}

AsPerformer.propTypes = {
  offers: PropTypes.array.isRequired,
  infoProject: PropTypes.object,
  isFetchOffers: PropTypes.bool.isRequired,
  fetchOffer: PropTypes.func.isRequired,
}

export default withStyles(styles)(AsPerformer)
