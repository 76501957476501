import React from 'react'
import { Paper, Box, Typography } from '@material-ui/core'

const Section = ({ children, title, tip }) => {
  return (
    <Box ml={2} mr={2}>
      <Box m={1}>
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          {title}
        </Typography>
      </Box>
      <Paper>
        {tip && (
          <Box p={1} mb={-1}>
            <Typography>{tip}</Typography>
          </Box>
        )}
        <Box p={1}>{children}</Box>
      </Paper>
    </Box>
  )
}

export default Section
