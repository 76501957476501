import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AddInventoryProjectComponent from './AddInventoryProjectComponent'
import { fetchManagerOrHigherOrganizations } from '../../../../actions/organizationsActions'
import { createInventoryProject } from '../../../../actions/inventoryActions'
import { fetchOrganizationStaff } from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  isFetchingOrganizations:
    state.organizations.managerOrHigherOrganizations.isFetching,
  organizations: state.organizations.managerOrHigherOrganizations.organizations,
  isCreatingInventoryProject:
    state.inventory.createInventoryProject.isCreatingInventoryProject,
  createProjectErrors:
    state.inventory.createInventoryProject.createProjectErrors,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  organizationStaff: state.staff.organizationStaff,
  fetchOrganizationStaffErrors: state.staff.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchManagerOrHigherOrganizations,
      fetchOrganizationStaff,
      createInventoryProject,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddInventoryProjectComponent),
)
