import React, { createContext } from 'react'
import { createContextualCan } from '@casl/react'
import { Ability } from '@casl/ability'

export const AbilityContext = createContext()
export const Can = createContextualCan(AbilityContext.Consumer)

export function AbilityContextManager({ permissions, children }) {
  const [ability, setAbility] = React.useState(new Ability(permissions))
  React.useEffect(() => {
    if (ability) {
      setAbility(new Ability(permissions))
    }
  }, [permissions])
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}
