import {
  REGISTER_EMAIL_REQUEST,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_EMAIL_FAILURE,
  GET_CONFIRM_LINK_AGAIN_REQUEST,
  GET_CONFIRM_LINK_AGAIN_SUCCESS,
  GET_CONFIRM_LINK_AGAIN_FAILURE,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_FAILURE,
  REGISTER_ACCOUNT_SUCCESS,
  REGISTER_ACCOUNT_REQUEST,
  REGISTER_ACCOUNT_FAILURE,
  CLEAR_REGISTER_VALIDATION_ERRORS,
  CONFIRM_EMAIL_FAILURE_CODES,
} from '../constants/actionTypes'

export const registerEmail = email => ({
  type: REGISTER_EMAIL_REQUEST,
  email,
})

export const registerEmailSuccess = () => ({
  type: REGISTER_EMAIL_SUCCESS,
})

export const clearValidationErrors = () => ({
  type: CLEAR_REGISTER_VALIDATION_ERRORS,
})

export const registerEmailFailure = (errors, shouldResendLink) => ({
  type: REGISTER_EMAIL_FAILURE,
  errors,
  shouldResendLink,
})

export const getConfirmLinkAgain = email => ({
  type: GET_CONFIRM_LINK_AGAIN_REQUEST,
  email,
})

export const getConfirmLinkAgainSuccess = () => ({
  type: GET_CONFIRM_LINK_AGAIN_SUCCESS,
})

export const getConfirmLinkAgainFailure = errors => ({
  type: GET_CONFIRM_LINK_AGAIN_FAILURE,
  errors,
})

export const confirmEmail = () => ({
  type: CONFIRM_EMAIL_REQUEST,
})

export const confirmEmailSuccess = () => ({
  type: CONFIRM_EMAIL_SUCCESS,
})

export const confirmEmailFailure = (errors, code, email) => ({
  type: CONFIRM_EMAIL_FAILURE,
  errors,
  code,
  email,
})

export const confirmEmailFailureCodes = code => ({
  type: CONFIRM_EMAIL_FAILURE_CODES,
  code,
})

export const registerAccount = data => ({
  type: REGISTER_ACCOUNT_REQUEST,
  data,
})

export const registerAccountSuccess = () => ({
  type: REGISTER_ACCOUNT_SUCCESS,
})

export const registerAccountFailure = errors => ({
  type: REGISTER_ACCOUNT_FAILURE,
  errors,
})
