import * as types from '../constants/actionTypes/statistics'
import { actionCreator as externalActionCreator } from './actionCreator'

export const generateSDWorkingReport = externalActionCreator(
  types.GENERATE_SD_WORKING_REPORT_REQUEST,
)
export const generateSDWorkingReportSuccess = externalActionCreator(
  types.GENERATE_SD_WORKING_REPORT_SUCCESS,
)
export const generateSDWorkingReportFailure = externalActionCreator(
  types.GENERATE_SD_WORKING_REPORT_FAILURE,
)

export const downloadSDWorkingReport = externalActionCreator(
  types.DOWNLOAD_SD_WORKING_REPORT_REQUEST,
)
