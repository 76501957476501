import React from 'react'
import { fetchProxy } from '../../middlewares/api'
import queryString from 'query-string'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from '../../components/Loader'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const SelectAuto = ({
  query,
  sourceUrl,
  requestResultTransformerToArray,
  itemTransformer,
  isSuccessResponse = r => r.success,
  label,
  id,
  value,
  onChange,
  firstElement,
  fullWidth,
  shrink,
  itemComponent,
  disabled,
  helperText,
}) => {
  const [isFetching, setIsFetching] = React.useState(false)

  async function makeRequest() {
    setIsFetching(true)
    const request = await fetchProxy(
      `${sourceUrl}?${queryString.stringify({ ...query })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    setIsFetching(false)
    return request.json()
  }
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    const loadData = async () => {
      const result = await makeRequest()
      if (isSuccessResponse(result)) {
        setData(requestResultTransformerToArray(result))
      }
    }
    loadData()
  }, [])
  function onChangeHandler({ target: { value: newValue } }) {
    onChange(
      newValue,
      data.find(item => itemTransformer(item).id === newValue),
    )
  }
  return (
    <React.Fragment>
      <FormControl fullWidth={fullWidth}>
        <InputLabel htmlFor={`open-select-${id}`} shrink={shrink}>
          {label || ''}
        </InputLabel>
        <Select
          disabled={disabled}
          id={`select-${id}`}
          onChange={onChangeHandler}
          inputProps={{
            id: `open-select-${id}`,
          }}
          value={value}
        >
          {isFetching && (
            <MenuItem>
              <Loader />
            </MenuItem>
          )}
          {!isFetching && firstElement && (
            <MenuItem value={firstElement.id} key={firstElement.id}>
              {firstElement.title}
            </MenuItem>
          )}
          {!isFetching &&
            data.length > 0 &&
            data.map(item => (
              <MenuItem
                value={itemTransformer(item).id}
                key={itemTransformer(item).id}
              >
                {itemComponent
                  ? React.createElement(itemComponent, { item }, null)
                  : itemTransformer(item).title}
              </MenuItem>
            ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  )
}

export default SelectAuto
