import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchServiceCallsListRequest,
  fetchServiceCallsListCountRequest,
  fetchServiceCallOrdersListRequest,
  fetchServiceCallOrdersListCountRequest,
  clearCallsSearchByNumber,
  setRowsPerPage,
  setNeedParticipationFilter,
  setTypeOrderFilter,
  setResultOrderFilter,
  setInitialListFiltersForStatus,
} from '../../../actions/serviceDesk'
import {
  setFilterDrawer,
  clearFilterDrawer,
  downloadFileRequest,
} from '../../../actions/appActions'

import ListRequest from './ListRequest'

const mapStateToProps = state => ({
  isFetchingCount: state.serviceDesk.serviceCallsCount.isFetching,
  count: state.serviceDesk.serviceCallsCount.count,
  errorCount: state.serviceDesk.serviceCallsCount.error,
  isFetchingOrdersCount: state.serviceDesk.serviceCallOrdersCount.isFetching,
  ordersCount: state.serviceDesk.serviceCallOrdersCount.count,
  errorOrdersCount: state.serviceDesk.serviceCallOrdersCount.error,
  isFetchingSC: state.serviceDesk.serviceCalls.isFetching,
  errorServiceCalls: state.serviceDesk.serviceCalls.error,
  isFetchingSCO: state.serviceDesk.serviceCallOrders.isFetching,
  errorServiceCallOrders: state.serviceDesk.serviceCallOrders.error,
  rowsPerPage: state.serviceDesk.listRequest.rowsPerPage,
  needParticipation: state.serviceDesk.listRequest.needParticipation,
  typeOrder: state.serviceDesk.listRequest.typeOrder,
  resultOrder: state.serviceDesk.listRequest.resultOrder,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceCallsListRequest,
      fetchServiceCallsListCountRequest,
      fetchServiceCallOrdersListRequest,
      fetchServiceCallOrdersListCountRequest,
      setRowsPerPage,
      setFilterDrawer,
      clearFilterDrawer,
      clearCallsSearchByNumber,
      setNeedParticipationFilter,
      setTypeOrderFilter,
      setResultOrderFilter,
      setInitialListFiltersForStatus,
      downloadFileRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListRequest),
)
