import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// actions
import { attachServiceCallTagRequest } from '../../../../../../actions/serviceDesk'
// component
import AttachTagDialogContainer from './AttachTagDialogContainer'

const mapStateToProps = state => ({
  attaching: state.serviceDesk.serviceCallTagsAttaching.isFetching,
  error: state.serviceDesk.serviceCallTagsAttaching.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      attachTag: attachServiceCallTagRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttachTagDialogContainer),
)
