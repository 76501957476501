// eslint-disable-next-line
import i18next from '../i18n/i18n'

export const errorCodes = () => ({
  email: {
    34: i18next.t('error_web_atoir:registrationLinkSentAlready'),
    39: i18next.t('validation_web_atoir:wrongEmailOrPassword'),
    56: i18next.t('error_web_atoir:userNotFound'),
    57: i18next.t('error_web_atoir:userIsBlocked'),
    58: i18next.t('error_web_atoir:userWithGivenEmailExists'),
    59: i18next.t('error_web_atoir:confirmationLinkWasNotSentOnEmail'),
    99: i18next.t('error_web_atoir:restorePasswordLinkWasNotSentOnEmail'),
    119: i18next.t('error_web_atoir:yourAccountIsNotActivated'),
    134: i18next.t('validation_web_atoir:emailHasIncorrectFormat'),
    135: i18next.t('login_web_atoir:enterEmail'),
    136: i18next.t('validation_web_atoir:emailLengthMustBe255OrLess'),
    141: i18next.t('validation_web_atoir:givenEmailIsNotRegistered'),
    161: i18next.t('organizations_web_atoir:userIsAlreadyInOrganization'),
    196: i18next.t(
      'organizations_web_atoir:userWithGivenEmailIsAlreadyInOrganization',
    ),
  },
  password: {
    98: i18next.t('error_web_atoir:restorePasswordAttemptsCountExceeded'),
    126: i18next.t('validation_web_atoir:wrongPassword'),
    131: i18next.t('login_web_atoir:enterPassword'),
  },
  passwordConfirm: {
    132: i18next.t(
      'validation_web_atoir:passwordNotEqualsToConfirmationPassword',
    ),
    142: i18next.t('login_web_atoir:enterPasswordConfirmation'),
  },
  newPassword: {
    127: i18next.t('validation_web_atoir:newPasswordMustBeDifferentFromPast'),
  },
  firstName: {
    172: i18next.t('validation_web_atoir:userNameMustNotBeEmpty'),
    173: i18next.t('validation_web_atoir:userNameLengthMustBe255OrLess'),
  },
  lastName: {
    174: i18next.t('validation_web_atoir:lastNameCanNotBeEmpty'),
    175: i18next.t('validation_web_atoir:lastNameLengthMustBe255OrLess'),
  },
  agreementTreatmentPersonalInfo: {
    128: i18next.t(
      'validation_web_atoir:processingOfPersonalDataAcceptanseIsNecessary',
    ),
  },
  phone: {
    133: i18next.t('validation_web_atoir:phoneNumberMustContainNumbersOnly'),
  },
  organizationFullName: {
    162: i18next.t('validation_web_atoir:organizationFullNameCanNotBeEmpty'),
    163: i18next.t(
      'validation_web_atoir:organizationFullNameLengthMustBe255OrLess',
    ),
  },
  organizationShortName: {
    164: i18next.t('validation_web_atoir:organizationShortNameCanNotBeEmpty'),
    165: i18next.t(
      'validation_web_atoir:organizationShortNameLengthMustBe255OrLess',
    ),
  },
  inn: {
    166: i18next.t('validation_web_atoir:INNLengthIsNotEqualsTo12'),
  },
  ogrn: {
    167: i18next.t('validation_web_atoir:OGRNLengthIsNotEqualsTo13'),
  },
  okpo: {
    168: i18next.t('validation_web_atoir:OKPOLengthIsNotEqualsTo10'),
  },
  bik: {
    169: i18next.t('validation_web_atoir:BIKLengthIsNotEqualsTo9'),
  },
  inventoryProject: {
    179: i18next.t('error_web_atoir:inventoryProjectDoesNotExist'),
    182: i18next.t('error_web_atoir:unworkableOnCurrentProjectStage'),
    60: i18next.t('error_web_atoir:notEnoughRights'),
    223: i18next.t('error_web_atoir:doneRejectedhasIncompleteOrders'),
    224: i18next.t('error_web_atoir:doneRejectedNoCompletedOrders'),
  },
  app: {
    0: i18next.t('error_web_atoir:somethingWentWrong'),
    50: i18next.t('error_web_atoir:organizationDoesNotExist'),
    52: i18next.t('error_web_atoir:notEnoughRights'),
    53: i18next.t('error_web_atoir:invalidIncomeParams'),
    54: i18next.t('error_web_atoir:givenLocationDoesNotExist'),
    55: i18next.t('error_web_atoir:wrongLocation'),
    56: i18next.t('error_web_atoir:userNotFound'),
    60: i18next.t('validation_web_atoir:validationError'),
    61: i18next.t('error_web_atoir:thisTypeOfLocationIsUniqueAndAlreadyExists'),
    62: i18next.t('error_web_atoir:notLocationWithGivenIdentifier'),
    63: i18next.t('error_web_atoir:locationDeletingFailed'),
    64: i18next.t('error_web_atoir:invalidIdentifierFormat'),
    65: i18next.t('error_web_atoir:organizationOrUserIncorrect'),
    66: i18next.t('error_web_atoir:unexpectableError'),
    68: i18next.t('error_web_atoir:invalidParamsForFiltration'),
    69: i18next.t('error_web_atoir:organizationDoesNotHaveSuchDepartment'),
    70: i18next.t('error_web_atoir:youAreNotAuthorized'),
    71: i18next.t('error_web_atoir:yourAccountIsBlocked'),
    78: i18next.t('error_web_atoir:noWorkerWithGivenIdentifier'),
    80: i18next.t('error_web_atoir:canNotDeleteLastContact'),
    81: i18next.t('error_web_atoir:noDepartmentWithGivenIdentifier'),
    82: i18next.t('error_web_atoir:noEquipmentWithGivenIdentifier'),
    83: i18next.t('error_web_atoir:noTransferWithGivenIdentifier'),
    84: i18next.t('error_web_atoir:transferIsAlreadyConfirmedOrCanceled'),
    85: i18next.t('error_web_atoir:organizationDoesNotHaveSuchEquipment'),
    86: i18next.t('error_web_atoir:notEnoughRightsForTransferApproval'),
    87: i18next.t('error_web_atoir:notEnoughRightsForTransferCanceling'),
    88: i18next.t('error_web_atoir:organizationRecipientDoesNotExist'),
    89: i18next.t('error_web_atoir:transferForThisEquipmentAlreadyExists'),
    92: i18next.t('error_web_atoir:noUserWithThisHash'),
    94: i18next.t('error_web_atoir:tokenNotFound'),
    95: i18next.t('error_web_atoir:youWentThroughTheWrongLink'),
    96: i18next.t('error_web_atoir:loginCountExceeded'),
    97: i18next.t('error_web_atoir:tokenIsExceededAuthorize'),
    116: i18next.t('error_web_atoir:linkLifetimeIsOver'),
    120: i18next.t('error_web_atoir:emailConfirmationLinkHasInvalidFormat'),
    122: i18next.t('error_web_atoir:invalidTokenFormat'),
    144: i18next.t('error_web_atoir:youAccountHasNotBeenActivatedYet'),
    145: i18next.t('error_web_atoir:linkDoesNotExist'),
    150: i18next.t('error_web_atoir:organizationDoesNotHaveSuchUser'),
    151: i18next.t(
      'error_web_atoir:organizationOwnerCanNotLeaveHisOrganization',
    ),
    152: i18next.t('error_web_atoir:youDoesNotBelongToTheOrganization'),
    158: i18next.t('error_web_atoir:userIsAdminOrManagerOfTheOrganization'),
    160: i18next.t('error_web_atoir:notAllAuthorizationDataReceived'),
    176: i18next.t('error_web_atoir:theOrganizationDoesNotHaveSuchUser'),
    177: i18next.t('error_web_atoir:couldNotCreateInventoryProject'),
    178: i18next.t('error_web_atoir:couldNotReceiveInventoryProjects'),
    179: i18next.t('error_web_atoir:thisInventoryProjectDoesNotExist'),
    181: i18next.t('error_web_atoir:invalidLocationFormat'),
    182: i18next.t(
      'organizations_web_atoir:projectEditingIsNotAvailableOnThisStage',
    ),
    188: i18next.t('error_web_atoir:closedProjectCanNotBeEdited'),
    191: i18next.t('error_web_atoir:thisInventoryProjectWasNotOfferedYou'),
    192: i18next.t(
      'error_web_atoir:givenInventoryProjectDoesNotBelongToYouCompany',
    ),
    194: i18next.t(
      'error_web_atoir:oneOfGivenUsersDoesNotBelongToTheOrganization',
    ),
    195: i18next.t('error_web_atoir:organizationUserIsNotActivated'),
    199: i18next.t('error_web_atoir:offerIsAlreadyApprovedOrCancelled'),
    201: i18next.t('error_web_atoir:givenProjectCanNotBeCanceled'),
    202: i18next.t('error_web_atoir:cityWithGivenIdDoesNotExist'),
    213: i18next.t('error_web_atoir:linkedWithSomething'),
    214: i18next.t('error_web_atoir:linkedWithEquipment'),
    222: i18next.t('employees_web_atoir:boundWithSomething'),
    225: i18next.t('error_web_atoir:failedToSetProjectInStatus'),
    226: i18next.t('error_web_atoir:alreadyFoundSomeEquipmentInOrder'),
    233: i18next.t('error_web_atoir:233'),
    238: 'Файл недоступен',
    261: i18next.t('error_web_atoir:261'),
    262: i18next.t('error_web_atoir:262'),
    274: i18next.t('error_web_atoir:274'),
    287: i18next.t('error_web_atoir:287'),
    289: i18next.t('error_web_atoir:289'),
    303: i18next.t('error_web_atoir:303'),
    403: i18next.t('error_web_atoir:somethingWentWrong'),
    404: i18next.t('error_web_atoir:notFound'),
    3001: i18next.t('error_web_atoir:mailServiceIsNotAvailable'),
    networkError: i18next.t('error_web_atoir:networkProblems'),
  },
})
