import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input/Input'
import FormHelperText from '@material-ui/core/FormHelperText'

const FormControlText = props => {
  const { onChange, value, label, helperText, error, ...others } = props
  return (
    <FormControl fullWidth error={error} {...others}>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <Input id={label} value={value} onChange={onChange} />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormControlText.defaultProps = {
  helperText: null,
}

FormControlText.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  helperText: PropTypes.string,
}

export default FormControlText
