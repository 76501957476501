import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RepairPasswordComponent from './RepairPasswordComponent'
import { setNewPassword } from '../../../actions/repairPasswordActions'

const mapStateToProps = state => ({
  isSettingNewPassword: state.repairPassword.isSettingNewPassword,
  repairPasswordErrors: state.repairPassword.repairPasswordErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNewPassword,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepairPasswordComponent),
)
