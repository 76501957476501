import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import RLink from 'react-router-dom/Link'
import isEmpty from 'is-empty'
import { addMaskCharacters } from '../../../helpers/phoneStringHelper'

// internationalization
import i18next from '../../../i18n/i18n'

// components
import Subheading from '../../../widgets/Lettering/Subheading'
import Loader from '../../Loader'
import CanOrganizationEmployees from './OrganizationAbility/CanOrganizationEmployees'
import AddStaffDialog from '../../Staff/AddStaffDialog/AddStaffDialog'

// styles
import '../../../styles/components/Staff.css'
import '../../../styles/components/Organizations.css'

// material-ui-icons
import AddNewIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuIcon from '@material-ui/icons/Menu'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import OrganizationEmployeesAbility from './OrganizationAbility/OrganizationEmployeesAbility'
import SimpleDialog from '../../Other/SimpleDialog/DialogComponent'
import SelectLimitedAuto from '../../../widgets/SelectLimitedAuto'
import { URL } from '../../../middlewares/api'
import {
  organizationLocationStringify,
  organizationLocationStringifyFull,
} from '../../../helpers/parserHelper'

const columnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'ФИО сотрудника',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Телефон',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: false,
    label: 'Должность',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Роль',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Адрес',
  },
]

const roles = [
  { id: 'all_roles', label: 'Все роли' },
  { id: 'is_admin', label: 'Администратор' },
  { id: 'is_manager', label: 'Менеджер' },
  { id: 'is_plain_user', label: 'Сотрудник' },
]

const EnhancedTableHeadStyles = () => ({
  EnhancedTableHeadCell: {
    paddingTop: 0,
    verticalAlign: 'top',
  },
  EnhancedTableHeadDiv: {},
})

class EnhancedTableHead extends Component {
  state = {
    filterRole: roles[0],
    filterLocation: this.props.locations[0],
  }

  handleLocationChange = event => {
    const { staff } = this.props
    let filtered = staff.filter(
      worker => worker.location_id === event.target.value.id,
    )

    if (roles.indexOf(this.state.filterRole) !== 0) {
      filtered = filtered.filter(worker => worker[this.state.filterRole.id])
    }

    this.setState(
      {
        filterLocation: event.target.value,
      },
      () => this.returnLocationFilterData(filtered),
    )
  }

  handleRoleChange = event => {
    this.props.callbackFromParent('role', event.target.value.id)
  }

  returnFilterDataToParent = (filterData, filterInput) => {
    let isFiltering = true
    if (isEmpty(filterInput)) {
      isFiltering = false
    }
    this.props.callbackFromParent(filterData, isFiltering)
  }

  returnLocationFilterData = filterData => {
    let isFiltering = true
    if (this.props.locations.indexOf(this.state.filterLocation) === 0) {
      isFiltering = false
    }
    this.props.callbackFromParent(filterData, isFiltering)
  }

  returnRoleFilterData = filterData => {
    let isFiltering = true
    if (roles.indexOf(this.state.filterRole) === 0) {
      isFiltering = false
    }
    this.props.callbackFromParent(filterData, isFiltering)
  }

  render() {
    const { order, orderBy, classes, location, callbackFromParent } = this.props

    return (
      <TableHead>
        <TableRow>
          {columnData.map(
            column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : undefined}
                sortDirection={orderBy === column.id ? order : false}
                className={classes.EnhancedTableHeadCell}
              >
                <div className={classes.EnhancedTableHeadDiv}>
                  <div className={classes.EnhancedTableHeadDiv}>
                    {column.label}
                  </div>
                  {column.id === 'name' ? (
                    <Fragment>
                      <TextField
                        onChange={event =>
                          callbackFromParent('name', event.target.value)
                        }
                      />
                    </Fragment>
                  ) : column.id === 'role' ? (
                    <Select
                      value={this.state.filterRole}
                      onChange={this.handleRoleChange}
                      name="filterRole"
                      input={<Input name="filterRole" id="filter-role" />}
                      MenuProps={MenuProps}
                    >
                      {roles.map(role => (
                        <MenuItem key={roles.indexOf(role)} value={role}>
                          {role.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : column.id === 'address' ? (
                    <Fragment>
                      <SelectLimitedAuto
                        value={location}
                        firstElement={{ id: '', title: '' }}
                        onChange={l =>
                          this.props.callbackFromParent('location', l)
                        }
                        itemTransformer={l =>
                          l
                            ? {
                                id: l.id,
                                title: organizationLocationStringify(l),
                              }
                            : {}
                        }
                        requestResultTransformerToArray={r => r.locations}
                        sourceUrl={`${URL}/organizations/${this.props.props.match.params.organizationId}/locations`}
                        isSuccessResponse={r => !!r.success}
                      />
                    </Fragment>
                  ) : (
                    <br />
                  )}
                </div>
              </TableCell>
            ),
            this,
          )}
          <TableCell />
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  callbackFromParent: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  props: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
}

EnhancedTableHead = withStyles(EnhancedTableHeadStyles)(EnhancedTableHead)

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

let EnhancedTableToolbar = props => {
  const { numSelected, classes, handleDialogOpen } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} {i18next.t('employees_web_atoir:staffSelectedAmount')}
          </Typography>
        ) : (
          <Subheading>
            {i18next.t('organizations_web_atoir:organizationStaffTitle')}
          </Subheading>
        )}
      </div>
      <div className={classes.spacer} />
      <CanOrganizationEmployees I="add" a="OrganizationEmployees">
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <Tooltip title={i18next.t('shared_web_atoir:delete')}>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={i18next.t('organizations_web_atoir:addOrganizationUser')}
            >
              <IconButton
                aria-label={i18next.t(
                  'organizations_web_atoir:addOrganizationUser',
                )}
                onClick={handleDialogOpen}
              >
                <AddNewIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </CanOrganizationEmployees>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  handleDialogOpen: PropTypes.func.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  container: {},
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

class OrganizationStaffComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      page: 0,
      limit: 10,
      offset: 0,
      openDialog: false,
      anchorEl: null,
      shouldEditUser: false,
      selectedOrganizationUser: {},
      filters: {},
      filterDataToDisplay: [],
      isFiltering: false,
      isDeleteOrganizationUserOpen: false,
    }
  }

  componentDidMount() {
    const { organizationId: id } = this.props.match.params
    const {
      fetchOrganizationLocations,
      user,
      fetchUser,
      fetchOrganizationStaffCount,
    } = this.props
    if (!user.id) {
      fetchUser()
    }
    this.fetchStaff()
    fetchOrganizationLocations(id)
    fetchOrganizationStaffCount(id)
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.isDeletingOrganizationUser !==
        this.props.isDeletingOrganizationUser &&
        !this.props.isDeletingOrganizationUser) ||
      (prevProps.isBindingUserAccount !== this.props.isBindingUserAccount &&
        !this.props.isBindingUserAccount &&
        isEmpty(this.props.bindUserAccountError)) ||
      (prevProps.isUnbindingUserAccount !== this.props.isUnbindingUserAccount &&
        !this.props.isUnbindingUserAccount &&
        isEmpty(this.props.unbindUserAccountError)) ||
      (prevProps.isCreatingUpdatingOrganizationUser !==
        this.props.isCreatingUpdatingOrganizationUser &&
        !this.props.isCreatingUpdatingOrganizationUser)
    ) {
      const { organizationId: id } = this.props.match.params
      const { fetchOrganizationLocations } = this.props
      this.fetchStaff()
      fetchOrganizationLocations(id)
      this.setState(
        state => ({
          ...state,
          filterDataToDisplay: [],
          isFiltering: false,
        }),
        () => this.handleDialogClose(),
      )
    }
    if (prevProps.rowsPerPage !== this.props.rowsPerPage) {
      this.fetchStaff(false, 0)
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy }, this.fetchStaff)
  }

  doFetchStaff = fetchCount => {
    const { organizationId: id } = this.props.match.params
    const {
      props: {
        fetchOrganizationStaff,
        fetchOrganizationStaffCount,
        rowsPerPage,
      },
      state: { offset, filters },
    } = this
    const sort = { sort: '[last_name=ASC,first_name=ASC,middle_name=ASC]' }
    let filtersQuery = {}
    if (filters.location) {
      filtersQuery.location_id = filters.location
    }
    const cred = {}
    if (filters.name) {
      cred.credentials = filters.name
    }
    if (filters.role) {
      switch (filters.role) {
        case 'all_roles':
          break
        case 'is_admin':
          filtersQuery.is_admin = true
          break
        case 'is_manager':
          filtersQuery.is_manager = true
          break
        case 'is_plain_user':
          filtersQuery.is_plain_user = true
          break
        default:
          break
      }
      filtersQuery.location_id = filters.location
    }
    filtersQuery = JSON.stringify(filtersQuery)
    fetchOrganizationStaff(id, undefined, {
      limit: rowsPerPage,
      offset,
      ...sort,
      filters: filtersQuery,
      ...cred,
    })
    if (fetchCount) {
      fetchOrganizationStaffCount(id, undefined, {
        ...sort,
        filters: filtersQuery,
        ...cred,
      })
    }
  }

  fetchStaff = (fetchCount = true, timeout = 200) => {
    clearTimeout(this.fetchTimeout)
    this.fetchTimeout = setTimeout(() => {
      this.doFetchStaff(fetchCount)
    }, timeout)
  }

  handleChangePage = (event, page) => {
    this.setState(
      state => ({ page, offset: page * state.rowsPerPage }),
      () => this.fetchStaff(false, 0),
    )
  }

  handleChangeRowsPerPage = event => {
    this.props.setOrganizationsStaffListRowsPerPage(event.target.value)
  }

  displayedRows = (from, to, count) => count

  // user menu
  handleUserMenuOpen = (event, organizationUser) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedOrganizationUser: organizationUser,
    })
  }

  handleUserMenuClose = () => {
    this.setState({
      anchorEl: null,
      selectedOrganizationUser: {},
    })
  }

  handleUserDelete = () => {
    this.setState({
      anchorEl: null,
      shouldEditUser: false,
      selectedOrganizationUser: {},
    })
    const { deleteOrganizationUser } = this.props
    const { selectedOrganizationUser } = this.state
    deleteOrganizationUser(
      selectedOrganizationUser.organization_id,
      selectedOrganizationUser.id,
    )
  }

  handleToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  // add user dialog
  handleDialogOpen = shouldEditUser => {
    const { selectedOrganizationUser } = this.state
    this.setState({
      anchorEl: null,
      openDialog: true,
      shouldEditUser,
      selectedOrganizationUser: shouldEditUser ? selectedOrganizationUser : {},
    })
  }

  handleDialogClose = () => {
    this.setState({
      anchorEl: null,
      openDialog: false,
      shouldEditUser: false,
      selectedOrganizationUser: {},
    })
  }

  handleLocationChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleFilterData = (field, value) => {
    this.setState(
      state => ({
        filters: {
          ...state.filters,
          [field]: value,
        },
      }),
      this.fetchStaff,
    )
  }

  render() {
    const {
      classes,
      isFetchingOrganizationStaff,
      organizationStaff,
      isFetchingLocations,
      locations,
      match,
      organization,
      user,
      count,
      isFetchingUser,
      isAddingOrganizationUser,
      isUpdatingOrganizationUser,
      isDeletingOrganizationUser,
      rowsPerPage,
    } = this.props
    const {
      openDialog,
      anchorEl,
      order,
      orderBy,
      page,
      selectedOrganizationUser,
      shouldEditUser,
      filterDataToDisplay,
      isDeleteOrganizationUserOpen,
      isFiltering,
      filters,
    } = this.state

    const loader = (
      <Dialog
        open={
          isAddingOrganizationUser ||
          isUpdatingOrganizationUser ||
          isDeletingOrganizationUser
        }
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Loader />
        </DialogContent>
      </Dialog>
    )

    const data = isFiltering ? filterDataToDisplay : organizationStaff
    return (isFetchingOrganizationStaff && isFetchingLocations) ||
      isFetchingUser ? (
      <Loader />
    ) : (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          handleDialogOpen={() => this.handleDialogOpen(false)}
        />
        <Fragment>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <EnhancedTableHead
                props={this.props}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={organizationStaff.length}
                staff={organizationStaff}
                location={filters.location}
                locations={['Не выбрано'].concat(locations)}
                callbackFromParent={this.handleFilterData}
              />
              <TableBody>
                {data.map(n => (
                  <TableRow hover key={n.id}>
                    <TableCell>
                      {!isEmpty(n.user_id) &&
                      n.User.first_name !== 'none' &&
                      n.User.last_name !== 'none' ? (
                        <RLink
                          to={`${match.url}/staff_worker_profile/${n.user_id}`}
                        >
                          <span className="staff-name-underlined">
                            {`${n.last_name}
                                     ${n.first_name}
                                     ${
                                       n.middle_name ? ` ${n.middle_name}` : ''
                                     }`}
                          </span>
                        </RLink>
                      ) : (
                        <span className="staff-name-not-underlined">
                          {`${n.last_name}
                                   ${n.first_name}
                                   ${n.middle_name ? ` ${n.middle_name}` : ''}`}
                        </span>
                      )}
                    </TableCell>
                    <TableCell className="table-cell-phone">
                      {n.phone ? addMaskCharacters(n.phone) : '-'}
                    </TableCell>
                    <TableCell>{n.user_id ? n.User.email : '-'}</TableCell>
                    <TableCell>
                      {isEmpty(n.position) ? (
                        '-'
                      ) : (
                        <Fragment>
                          {n.position === 'owner'
                            ? i18next.t('organizations_web_atoir:ownerRole')
                            : n.position}
                        </Fragment>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="staff-position-container">
                        {n.is_admin ? (
                          <div>
                            <span>
                              {i18next.t(
                                'employees_web_atoir:administratorRole',
                              )}
                            </span>
                            <br />
                          </div>
                        ) : null}
                        {n.is_manager ? (
                          <div>
                            <span>
                              {i18next.t('employees_web_atoir:managerRole')}
                            </span>
                            <br />
                          </div>
                        ) : null}
                        {n.is_plain_user ? (
                          <span>
                            {i18next.t('employees_web_atoir:plainUserRole')}
                          </span>
                        ) : null}
                        {!n.is_admin && !n.is_manager && !n.is_plain_user
                          ? '-'
                          : ''}
                      </div>
                    </TableCell>
                    <TableCell>
                      {!isEmpty(n.location_id)
                        ? locations.map(location => {
                            if (location.id === n.location_id) {
                              return (
                                <Fragment>
                                  {organizationLocationStringifyFull(location)}
                                </Fragment>
                              )
                            }
                            return null
                          })
                        : '-'}
                    </TableCell>
                    {!OrganizationEmployeesAbility.can(
                      'edit',
                      'OrganizationEmployees',
                    ) ||
                    (organization.owner_id !== user.id && n.is_owner) ? (
                      <TableCell padding="none" />
                    ) : (
                      <TableCell>
                        <div>
                          <IconButton
                            className="User-profile-edit-icon"
                            aria-owns={anchorEl ? 'simple-menu' : null}
                            aria-haspopup="true"
                            onClick={event => this.handleUserMenuOpen(event, n)}
                          >
                            <MenuIcon />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            transitionDuration={
                              selectedOrganizationUser.is_owner
                                ? { enter: 200, exit: 0 }
                                : 200
                            }
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) &&
                              !isEmpty(selectedOrganizationUser) &&
                              selectedOrganizationUser.id === n.id
                            }
                            onClose={this.handleUserMenuClose}
                          >
                            <MenuItem
                              onClick={() => this.handleDialogOpen(true)}
                            >
                              {i18next.t('shared_web_atoir:edit')}
                            </MenuItem>
                            {selectedOrganizationUser.is_owner ? (
                              ''
                            ) : (
                              <CanOrganizationEmployees
                                I="delete"
                                a="OrganizationEmployees"
                              >
                                <MenuItem
                                  onClick={() =>
                                    this.handleToggle(
                                      'isDeleteOrganizationUserOpen',
                                      true,
                                    )()
                                  }
                                >
                                  {i18next.t(
                                    'organizations_web_atoir:deleteUserOrganization',
                                  )}
                                </MenuItem>
                              </CanOrganizationEmployees>
                            )}
                          </Menu>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={i18next.t('shared_web_atoir:rowsPerPage')}
            labelDisplayedRows={({
              from: fromPage,
              to: toPage,
              count: countPage,
            }) =>
              i18next.t('shared_web_atoir:labelDisplayedRows', {
                fromPage,
                toPage,
                countPage,
              })
            }
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Fragment>
        <SimpleDialog
          isOpen={isDeleteOrganizationUserOpen}
          handleClose={this.handleToggle('isDeleteOrganizationUserOpen', false)}
          handlePositive={() => {
            this.handleToggle('isDeleteOrganizationUserOpen', false)()
            this.handleUserDelete()
          }}
          handleNegative={this.handleToggle(
            'isDeleteOrganizationUserOpen',
            false,
          )}
          positiveText={i18next.t('shared_web_atoir:delete')}
          negativeText={i18next.t('shared_web_atoir:cancel')}
          title={`${i18next.t('employees_web_atoir:deleteOrganizationUser')}?`}
          content={`${i18next.t(
            'employees_web_atoir:wantToDeleteOrganizationUser',
          )}?`}
        />
        <AddStaffDialog
          openDialog={openDialog}
          closeDialog={this.handleDialogClose}
          edit={shouldEditUser}
          user={selectedOrganizationUser}
        />
        {loader}
      </Paper>
    )
  }
}

OrganizationStaffComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchOrganizationStaff: PropTypes.func.isRequired,
  deleteOrganizationUser: PropTypes.func.isRequired,
  fetchOrganizationLocations: PropTypes.func.isRequired,
  isFetchingOrganizationStaff: PropTypes.bool.isRequired,
  organizationStaff: PropTypes.array.isRequired,
  isAddingOrganizationUser: PropTypes.bool.isRequired,
  isUpdatingOrganizationUser: PropTypes.bool.isRequired,
  isDeletingOrganizationUser: PropTypes.bool.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  isBindingUserAccount: PropTypes.bool.isRequired,
  bindUserAccountError: PropTypes.object.isRequired,
  isUnbindingUserAccount: PropTypes.bool.isRequired,
  unbindUserAccountError: PropTypes.object.isRequired,
  isCreatingUpdatingOrganizationUser: PropTypes.bool.isRequired,
}

export default withStyles(styles)(OrganizationStaffComponent)
