import * as constants from '../constants/actionTypes/settings'
import { actionCreator as externalActionCreator } from './actionCreator'

export const getEngineerSettingsRequest = externalActionCreator(
  constants.GET_ENGINEER_SETTINGS_REQUEST,
)
export const getEngineerSettingsSuccess = externalActionCreator(
  constants.GET_ENGINEER_SETTINGS_SUCCESS,
)
export const getEngineerSettingsFailure = externalActionCreator(
  constants.GET_ENGINEER_SETTINGS_FAILURE,
)

export const getCommonOrganizationSettingsRequest = externalActionCreator(
  constants.GET_COMMON_ORGANIZATION_SETTINGS_REQUEST,
)
export const getCommonOrganizationSettingsSuccess = externalActionCreator(
  constants.GET_COMMON_ORGANIZATION_SETTINGS_SUCCESS,
)
export const getCommonOrganizationSettingsFailure = externalActionCreator(
  constants.GET_COMMON_ORGANIZATION_SETTINGS_FAILURE,
)

export const updateSettingsLists = externalActionCreator(
  constants.UPDATE_SETTINGS_LISTS,
)
