/* eslint-disable */
import React from 'react'
import i18next from '../../../../../i18n/i18n'
import Box from '@material-ui/core/Box'
import FormDialog from '../../../../../widgets/FormDialog'
import { URL } from '../../../../../middlewares/api'
import { sortStringify } from '../../../../../helpers/queryHelper'
import { getUserFullName } from '../../../../../helpers/userHelper'
import SelectLimitedFromControl from '../../../../../widgets/SelectLimitedFormControl'

const AssigneeCallDialog = ({ open, onSubmit, onClose, orgId }) => {
  const [assigneeId, setAssigneeId] = React.useState(null)
  function onChangeHandler(id) {
    setAssigneeId(id)
  }
  function onSubmitHandler() {
    onSubmit({ assigneeId })
  }
  return (
    <FormDialog
      positiveDisabled={!assigneeId}
      closeIconHidden
      open={open}
      title="Смена исполнителя"
      onClose={onClose}
      onExit={onClose}
      onSubmit={onSubmitHandler}
      submitButtonTitle={i18next.t('inner_calls_web_atoir:doChose')}
      fullWidth
      dialogProps={{
        maxWidth: 'sm',
      }}
      secondaryButtonsArray={[
        {
          secondaryText: i18next.t('shared_web_atoir:cancel'),
          handleSecondaryAction: onClose,
        },
      ]}
    >
      {open && (
        <Box b={2} t={2}>
          <SelectLimitedFromControl
            FormControlProps={{
              fullWidth: true,
            }}
            label={i18next.t('shared_web_atoir:specialistFullName')}
            value={assigneeId}
            sourceUrl={`${URL}/organizations/${orgId}/users/only_binded`}
            query={{
              ...sortStringify({
                last_name: 'ASC',
                first_name: 'ASC',
                middle_name: 'ASC',
              }),
              filters: '[state=1,is_plain_user=true]',
            }}
            onChange={onChangeHandler}
            requestResultTransformerToArray={r => r.users}
            itemTransformer={l => ({ id: l.id, title: getUserFullName(l) })}
          />
        </Box>
      )}
    </FormDialog>
  )
}

export default AssigneeCallDialog
