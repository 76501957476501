import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'

// components
import BreadCrumb from '../../../../App/routing/BreadCrumb'
import Loader from '../../../../Loader'
import TextButton from '../../../../../widgets/Buttons/TextButton'

// utils
import { popLastSections } from '../../../../../helpers/dataHelper'
import { dateFormatShortYear } from '../../../../../helpers/dateHelper'
import i18next from '../../../../../i18n/i18n'
import config from '../../../../../config'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  listRoot: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  listItem: {
    backgroundColor: '#fff',
  },
  inline: {
    display: 'inline',
  },
  heading: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  subHeading: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  ellipse: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
})

class ScriptsHistory extends Component {
  state = {
    offset: 0,
    limit: config.default_organization_settings_history_limit,
  }

  componentDidMount = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        fetchOrganization,
      },
      fetchHistory,
    } = this
    fetchOrganization(organizationId)
    fetchHistory()
  }

  componentWillUnmount = () => {
    this.props.clearScriptsHistory()
  }

  fetchHistory = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        fetchScriptsHistory,
      },
      state: { offset, limit },
    } = this
    fetchScriptsHistory({ organizationId, query: { offset, limit } })
  }

  loadMore = () => {
    this.setState(
      state => ({
        offset: state.offset + state.limit,
      }),
      this.fetchHistory,
    )
  }

  render() {
    const {
      props: { match, classes, organization, isFetchingHistory, history, end },
      loadMore,
    } = this
    return isFetchingHistory ? (
      <Loader />
    ) : (
      <Fragment>
        <BreadCrumb length={29} to={`${popLastSections(match.url, 3)}/profile`}>
          {organization.short_name}
        </BreadCrumb>
        <BreadCrumb to={popLastSections(match.url, 2)}>
          {i18next.t('organizations_web_atoir:organizationSettings')}
        </BreadCrumb>
        <BreadCrumb to={match.url}>
          {i18next.t('organizations_web_atoir:orgSettingsScriptsLogs')}
        </BreadCrumb>
        <Typography variant="h4" gutterBottom className={classes.marginBtm}>
          {i18next.t('organizations_web_atoir:orgSettingsScriptsChangeLog')}
        </Typography>
        <List className={classes.listRoot}>
          {history.map((historyLog, index) => (
            <Fragment>
              <ListItem className={classes.listItem}>
                <div className={classes.inline}>
                  <Typography
                    component="span"
                    className={classnames([classes.inline, classes.heading])}
                  >
                    {historyLog.name}
                  </Typography>
                  <span
                    className={classnames([classes.inline, classes.ellipse])}
                  >
                    {' '}
                    &#8226;{' '}
                  </span>
                  <Typography
                    component="span"
                    className={classnames([classes.inline, classes.subHeading])}
                  >
                    {dateFormatShortYear(historyLog.executed_at)}
                  </Typography>
                  <span
                    className={classnames([classes.inline, classes.ellipse])}
                  >
                    {' '}
                    &#8226;{' '}
                  </span>
                  <Typography
                    component="span"
                    className={classnames([classes.inline, classes.subHeading])}
                  >
                    {i18next.t(
                      'organizations_web_atoir:orgSettingsLogsCallIntNum',
                      { internalNum: historyLog.ServiceCall.internal_num },
                    )}
                  </Typography>
                </div>
              </ListItem>
              {history && history.length !== index + 1 && (
                <Divider className={classes.divider} />
              )}
            </Fragment>
          ))}
        </List>
        {!end && (
          <TextButton onClick={loadMore}>
            {i18next.t('service_desk_web_atoir:show_more')}
          </TextButton>
        )}
      </Fragment>
    )
  }
}

ScriptsHistory.propTypes = {
  fetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  fetchScriptsHistory: PropTypes.func.isRequired,
  isFetchingHistory: PropTypes.bool.isRequired,
  history: PropTypes.array.isRequired,
  end: PropTypes.bool.isRequired,
  clearScriptsHistory: PropTypes.func.isRequired,
}

export default withStyles(styles)(ScriptsHistory)
