import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import i18n from '../../../../i18n/i18n'

const styles = {
  inline: {
    display: 'inline',
  },
  heading: {
    fontSize: 16,
  },
  subHeading: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 14,
  },
  warnings: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 12,
  },
  priorityHigh: {
    color: '#F44336',
    fontSize: 16,
    verticalAlign: 'sub',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}

class ContractItem extends Component {
  state = {}

  render() {
    const {
      props: {
        classes,
        contract,
        hasNotAcceptedAgreements,
        hasEquipmentWithNoEngineer,
        hasBeenRejected,
        rootStyle,
      },
    } = this
    const from = moment(`${contract.year_begin}-${contract.month_begin}-1`)
      .format('DD MMMM YYYY')
      .substring(3)
    const to = moment(`${contract.year_end}-${contract.month_end}-1`).format(
      'MMMM YYYY',
    )
    return (
      <div className={classes.root} style={rootStyle}>
        <Typography
          variant="h6"
          className={classNames([classes.heading, 'preBreak'])}
          component="div"
        >
          {i18n.t('service_desk_web_atoir:contractDatesIntervals', {
            number: contract.num_contract ? `№${contract.num_contract}` : '',
            from,
            to,
          })}
        </Typography>
        <div>
          <Typography
            variant="subheading"
            component="span"
            className={classNames([classes.inline, classes.subHeading])}
          >
            {i18n.t('service_desk_web_atoir:contractCreationDate', {
              date: new Date(contract.date_created).toLocaleString(
                i18n.language,
                {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },
              ),
            })}
          </Typography>
          <span className={classNames([classes.inline, classes.subHeading])}>
            {' '}
            &#8226;{' '}
          </span>
          <Typography
            variant="subheading"
            component="span"
            className={classNames([classes.inline, classes.subHeading])}
          >
            {i18n.t('service_desk_web_atoir:contractEquipmentCount', {
              count: contract.equipment_count,
            })}
          </Typography>
        </div>
        {hasNotAcceptedAgreements && (
          <Typography
            component="div"
            variant="body2"
            className={classNames([classes.warnings])}
          >
            <PriorityHigh className={classes.priorityHigh} />{' '}
            {i18n.t('service_desk_web_atoir:rejectedAgreementExists')}
          </Typography>
        )}
        {hasBeenRejected && (
          <Typography
            component="div"
            variant="body2"
            className={classNames([classes.warnings])}
          >
            <PriorityHigh className={classes.priorityHigh} />{' '}
            {i18n.t('service_desk_web_atoir:performerRejectedContract')}
          </Typography>
        )}
        {hasEquipmentWithNoEngineer && (
          <Typography
            component="div"
            variant="body2"
            className={classNames([classes.warnings])}
          >
            <PriorityHigh className={classes.priorityHigh} />{' '}
            {i18n.t('service_desk_web_atoir:equipmentWithNoEngineerExists')}
          </Typography>
        )}
      </div>
    )
  }
}

ContractItem.defaultProps = {
  hasNotAcceptedAgreements: false,
  hasBeenRejected: false,
  hasEquipmentWithNoEngineer: false,
  rootStyle: {},
}

ContractItem.propTypes = {
  contract: PropTypes.object.isRequired,
  rootStyle: PropTypes.object,
  hasNotAcceptedAgreements: PropTypes.bool,
  hasEquipmentWithNoEngineer: PropTypes.bool,
  hasBeenRejected: PropTypes.bool,
}

export default withStyles(styles)(ContractItem)
