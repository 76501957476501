import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ChoosingSpecialistForm from './ChoosingSpecialistForm'
import { fetchBoundOrganizationUsers } from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  staff: state.staff.boundOrganizationUsers.boundUsers,
  isFetching: state.staff.boundOrganizationUsers.isFetchingBoundUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationStaff: fetchBoundOrganizationUsers,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChoosingSpecialistForm),
)
