import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'

import i18next from '../../../i18n/i18n'

// styles
import '../../../styles/components/Login.css'

// components
import Loader from '../../Loader'
import SignInFormContainer from '../SignInForm'
import LoginFormWrapper from '../LoginFormWrapper'

// material-ui
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
})

class HomeComponent extends Component {
  constructor(props) {
    super(props)

    const params = queryString.parse(props.location.search)

    this.state = {
      isConfirmingEmail: params.action === 'confirm_email',
      isRepairingPassword: params.action === 'repair_password',
      isLoggedIn: localStorage.getItem('loggedIn'),
      hash: params.hash,
    }
  }

  componentDidMount() {
    const { isConfirmingEmail, isRepairingPassword, hash } = this.state
    if (isConfirmingEmail) {
      sessionStorage.setItem('confirmEmailHash', hash)
      this.props.confirmEmail()
    } else if (isRepairingPassword) {
      sessionStorage.setItem('confirmRepairPasswordHash', hash)
      this.props.confirmRepairPassword()
    }
  }

  render() {
    const {
      isConfirmingEmailRegistration,
      isConfirmingRepairPassword,
    } = this.props
    const { isConfirmingEmail, isRepairingPassword, isLoggedIn } = this.state

    const showLogin = (
      <LoginFormWrapper title={i18next.t('login_web_atoir:signInTitle')}>
        <SignInFormContainer />
      </LoginFormWrapper>
    )

    const isLoading = <Loader />

    const continueAccountActivation = <Redirect to="/activate_account" />
    // const continueAccountActivation =
    //   canContinueRegistration ?
    //     <Redirect to="/activate_account" />
    //     :
    //     <Redirect to="/error" />

    const continueRepairingPassword = <Redirect to="/repair_password" />
    // const continueRepairingPassword =
    //   canRepairPassword ?
    //     <Redirect to="/repair_password" />
    //     :
    //     <Redirect to="/error" />

    const redirectToProfile = <Redirect to="/profile" />

    return isLoggedIn
      ? redirectToProfile
      : isConfirmingEmail
      ? isConfirmingEmailRegistration
        ? isLoading
        : continueAccountActivation
      : isRepairingPassword
      ? isConfirmingRepairPassword
        ? isLoading
        : continueRepairingPassword
      : showLogin
  }
}

export default withStyles(styles)(HomeComponent)
