import DateInput from '../../../../widgets/DateInput'
import React from 'react'

function DatePickField({ value, idValue, onChange, onBlur, error, label }) {
  const onChangeHandler = newValue => {
    onChange(idValue, newValue)
  }
  function textOnChange(text) {
    if (text === '') {
      onChangeHandler(null)
    } else {
      onChangeHandler({ text })
    }
  }
  function valueOnChange(_value, text) {
    if (_value) {
      onChangeHandler({ text, [idValue]: _value })
    }
  }
  return (
    <DateInput
      error={error}
      id={idValue}
      value={(value && value[idValue]) || null}
      label={label}
      handleChange={valueOnChange}
      handleChangeText={textOnChange}
      onBlur={onBlur}
    />
  )
}

export default DatePickField
