import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OrderRow from './OrderRow'
import {
  fetchOrder,
  assignServiceDeskOrderToOwnSpecialist,
  sentOrderToSubOrder,
  rejectOrderRequest,
  cancelOrderByClientRequest,
  fetchOrderCommentsRequest,
  setOrderResultTypeRequest,
  confirmCertificateOfCompletionRequest,
  rejectOrderCertificateOfCompletionRequest,
  acceptOrderCertificateOfCompletionRequest,
  fetchOrderNdsRequest,
  getOrderPossibleReports,
  fetchOrderOverheadRequest,
  fetchOrderIncomeNdsRequest,
  toggleDisableScripts,
  setOrderResultReportingType,
  shouldSetOrderResultReportAction,
} from '../../../../../../actions/serviceDesk'
import {
  getEngineerSettingsRequest,
  getCommonOrganizationSettingsRequest,
} from '../../../../../../actions/settingsActions'
import {
  showDialogWithOk,
  downloadFileRequest,
} from '../../../../../../actions/appActions'
import { executeSDCallButtonRequest } from '../../../../../../actions/buttonsActions'

const mapStateToProps = state => ({
  permissions: state.serviceDesk.permissions,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  isAssignOrderFetching:
    state.serviceDesk.assigningOrderOwnSpecialist.isFetching,
  assignOrderError: state.serviceDesk.assigningOrderOwnSpecialist.error,
  isSendSubOrder: state.serviceDesk.sendOrderToSubOrder.isFetching,
  sendSubOrderError: state.serviceDesk.sendOrderToSubOrder.error,
  isFetchingCancelOrder: state.serviceDesk.cancelOrder.isFetching,
  fetchingCancelOrderError: state.serviceDesk.cancelOrder.error,
  isFetchingRejectOrder: state.serviceDesk.rejectOrder.isFetching,
  fetchRejectOrderSuccess: state.serviceDesk.rejectOrder.fetchSuccess,
  fetchingRejectOrderError: state.serviceDesk.rejectOrder.error,
  call: state.serviceDesk.request.request,
  isFetchingCall: state.serviceDesk.request.isFetching,
  isSettingOrderResultType: state.serviceDesk.setOrderResultType.isSetting,
  setOrderResultTypeSuccessfully:
    state.serviceDesk.setOrderResultType.setSuccessfully,
  isFetchingEngineerSettings: state.settings.engineerSettings.isFetching,
  engineerSettings: state.settings.engineerSettings.settings,
  isFetchingCommonOrganizationSettings:
    state.settings.commonOrganizationSettings.isFetching,
  commonOrganizationSettings:
    state.settings.commonOrganizationSettings.settings,
  isRejectingCertificateOfCompletion:
    state.serviceDesk.rejectOrderCertificateOfCompletion.isRejecting,
  rejectedCertificateOfCompletionSuccess:
    state.serviceDesk.rejectOrderCertificateOfCompletion.rejectedSuccessfully,
  isAcceptingCertificateOfCompletion:
    state.serviceDesk.acceptOrderCertificateOfCompletion.isAccepting,
  acceptedCerificateOfCompletionSuccess:
    state.serviceDesk.acceptOrderCertificateOfCompletion.acceptedSuccessfully,
  isFetchingNds: state.serviceDesk.fetchOrderNds.isFetching,
  nds: state.serviceDesk.fetchOrderNds.nds,
  inputWithNDS: state.serviceDesk.fetchOrderInputNds.nds,
  overhead: state.serviceDesk.fetchOrderOverhead.overhead,
  isFetchingIncomeNds: state.serviceDesk.fetchOrderInputNds.isFetching,
  isFetchingOverhead: state.serviceDesk.fetchOrderOverhead.isFetching,
  isFetchingPossibleReports: state.serviceDesk.orderPossibleReports.isFetching,
  possibleReports: state.serviceDesk.orderPossibleReports.templates,
  disableScriptsForIds: state.serviceDesk.disableScriptsForIds,
  orderResultReportingType: state.serviceDesk.orderResultReportingType,
  resultReportingTypeOrderId: state.serviceDesk.resultReportingTypeOrderId,
  shouldSetOrderResultReport: state.serviceDesk.shouldSetOrderResultReport,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrder,
      assignOwnSpecialist: assignServiceDeskOrderToOwnSpecialist,
      sentOrderToSubOrder,
      rejectOrderRequest,
      cancelOrderByClientRequest,
      fetchOrderCommentsRequest,
      setOrderResultTypeRequest,
      showDialogWithOk,
      downloadFileRequest,
      getEngineerSettingsRequest,
      getCommonOrganizationSettingsRequest,
      confirmCertificateOfCompletionRequest,
      rejectOrderCertificateOfCompletionRequest,
      acceptOrderCertificateOfCompletionRequest,
      fetchOrderNdsRequest,
      fetchOrderOverheadRequest,
      fetchOrderIncomeNdsRequest,
      getOrderPossibleReports,
      executeSDCallButtonRequest,
      toggleDisableScripts,
      setOrderResultReportingType,
      shouldSetOrderResultReportAction,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderRow),
)
