import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditOrganizationComponent from './EditOrganizationComponent'
import {
  updateOrganization,
  fetchOrganization,
} from '../../../actions/organizationsActions'
import {
  fetchLocations,
  fetchPhysicalLocation,
  fetchLegalLocation,
  updateLocation,
  createLocation,
} from '../../../actions/locationsActions'
import {
  fetchContacts,
  createContact,
  updateContact,
} from '../../../actions/organizationContactsActions'

const mapStateToProps = state => ({
  isUpdatingOrganization: state.organizations.isUpdatingOrganization,
  isFetchingLocations: state.locations.isFetchingLocations,
  isFetchingPhysicalLocation: state.locations.isFetchingPhysicalLocation,
  isFetchingLegalLocation: state.locations.isFetchingLegalLocation,
  organization: state.organizations.organization,
  locations: state.locations.locations,
  legal: state.locations.legal,
  physical: state.locations.physical,
  error: state.organizations.error,
  locationsError: state.locations.error,
  contacts: state.organizationContacts.contacts,
  isFetchingContacts: state.organizationContacts.isFetchingContacts,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganization,
      fetchLocations,
      fetchPhysicalLocation,
      fetchLegalLocation,
      updateLocation,
      createLocation,
      updateOrganization,
      fetchContacts,
      createContact,
      updateContact,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditOrganizationComponent),
)
