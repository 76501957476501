import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import UserProfileContainer from '../../../containers/User/UserProfileContainer'
import EditUserProfileContainer from '../../../containers/User/EditUserProfileContainer'
import UserSettings from '../../User/UserSettings'
import ChangePasswordContainer from '../../../containers/User/ChangePasswordContainer'
import NoMatchComponent from '../../NoMatchComponent'
import ServiceDeskBanner from '../../ServiceDesk/ServiceDeskBanner'

const UserRouter = ({ match: { path } }) => (
  <Fragment>
    <ServiceDeskBanner />
    <Switch>
      <WrapperRoute
        path={`${path}/edit`}
        showHeader
        title={i18next.t('user_profile_web_atoir:editProfileCrumbTitle')}
        component={EditUserProfileContainer}
      />
      <WrapperRoute
        path={`${path}/settings`}
        title={i18next.t('shared_web_atoir:profileSettings')}
        component={UserSettings}
      />
      <WrapperRoute
        path={`${path}/change_password`}
        component={ChangePasswordContainer}
        title={i18next.t(
          'user_profile_web_atoir:changePasswordProfileCrumbTitle',
        )}
      />
      <WrapperRoute
        path={`${path}`}
        title={i18next.t('user_profile_web_atoir:userProfileTitle')}
        crumbOff
        component={UserProfileContainer}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default UserRouter
