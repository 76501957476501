import React, { Component } from 'react'

// internationalization
import i18next from '../../../i18n/i18n'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
})

class EquipmentMaintenanceComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      content: i18next.t('equipment_web_atoir:maintenanceLogTitle'),
    }
  }

  render() {
    return (
      <div>
        <br />
        <Typography align="center">{this.state.content}</Typography>
      </div>
    )
  }
}

export default withStyles(styles)(EquipmentMaintenanceComponent)
