import React, { useEffect } from 'react'
import VendorInfo from './VendorInfo'
import IndustryInfo from './IndustryInfo'
import TOInfo from './TOInfo'
import Photos from './Photos'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  infoRoot: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    color: theme.palette.apply.main,
  },
  sectionName: {
    marginBottom: theme.spacing(2),
  },
  content: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  downloadText: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  toName: {
    fontWeight: 400,
  },
  toCardRoot: {
    marginBottom: 8,
  },
})

export default withStyles(styles)(function({
  equipment,
  binded,
  classes,
  fetchOrganizationBindedEquipment,
}) {
  useEffect(() => {
    console.log('Fetch fetchOrganizationBindedEquipment')
    fetchOrganizationBindedEquipment({
      equipmentId: equipment.id,
      organizationId: equipment.organization_id,
    })
  }, [])

  return binded ? (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} md={6}>
        <VendorInfo classes={classes} vendor={binded.BAVendor} />
      </Grid>
      {binded.EquipmentToIndustries.length ? (
        <Grid item xs={12} md={6}>
          {binded.EquipmentToIndustries.map(eti => (
            <IndustryInfo
              classes={classes}
              key={eti.id}
              industry={eti}
              equipment={equipment}
            />
          ))}
        </Grid>
      ) : null}
      {binded.BAEquipmentFotos.length ? (
        <Grid item xs={12} md={6}>
          <Photos classes={classes} equipment={equipment} binded={binded} />
        </Grid>
      ) : null}
      {binded.BAEquipmentTOs.length ? (
        <Grid item xs={12} md={6}>
          <TOInfo classes={classes} equipment={equipment} binded={binded} />
        </Grid>
      ) : null}
    </Grid>
  ) : null
})
