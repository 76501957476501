import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Edit from '@material-ui/icons/Edit'
import i18n from '../../../../i18n/i18n'
import {
  canAcceptAgreement,
  canCancelAgreement,
  canEditAgreement,
  canRejectAgreement,
  canSendAgreement,
  canViewAgreementInfo,
} from '../../../../helpers/ContractPermissionsResolver'
import BreadCrumb from '../../../App/routing/BreadCrumb'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import FormDialog from '../../../../widgets/FormDialog'
import LongTextExpander from '../../../../widgets/LongTextExpander'
import ContainedButton from '../../../../widgets/Buttons/ContainedButton'
import TextButton from '../../../../widgets/Buttons/TextButton'
import ListItem from '@material-ui/core/ListItem'
import { getUserFullName } from '../../../../helpers/userHelper'
import EditAgreementDialog from './EditAgreementName'
import { popLastSections } from '../../../../helpers/dataHelper'
import AgreementEquipmentTable from './AgreementEquipmentTable'
import CancelAgreement from '../CancelAgreement'
import contractsConstants from '../../../../constants/contracts'
import AssignAgreement from '../AssignAgreement'
import RejectAgreement from '../RejectAgreement'
import AcceptAgreement from '../AcceptAgreement'
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'

const styles = theme => ({
  bottomActions: {
    display: 'flex',
    marginBottom: 32,
    justifyContent: 'flex-end',
  },
  defaultPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogRoot: {
    minWidth: 1080,
  },
  subheading: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  editIcon: {
    color: theme.palette.common.black,
  },
  rejectNoteTitle: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
  },
  tableTitle: {
    marginBottom: theme.spacing(2),
  },
})

class AgreementProfile extends Component {
  state = {
    editNameDialogOpen: false,
    cancelAgreementOpen: false,
    assignAgreementOpen: false,
    rejectAgreementOpen: false,
    acceptAgreementOpen: false,
  }

  componentDidMount() {
    this.props.setNavigationDrawer([
      {
        title: i18n.t('service_desk_web_atoir:mainInfoTitle'),
        anchor: '#main-contract-info',
      },
      {
        title: i18n.t('service_desk_web_atoir:equipment'),
        anchor: '#equipment-table',
      },
    ])
    this.props.fetchContractPermissionsRequest(
      this.props.match.params.contractId,
    )
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching &&
      !this.props.error
    ) {
      this.props.getTitleInfo({
        status: i18n.t(
          `service_desk_web_atoir:${this.props.agreement.status}AgreementStatus`,
        ),
        title: (
          <Fragment>
            {i18n.t('service_desk_web_atoir:agreementNameTitle', {
              name: this.props.agreement.name,
              type: i18n
                .t(
                  `service_desk_web_atoir:${this.props.agreement.agreement_type}AgreementType`,
                )
                .toLowerCase(),
            })}
            {canEditAgreement(
              this.props.permissions,
              this.props.agreement.status,
            ) && (
              <IconButton onClick={this.onToggle('editNameDialogOpen', true)}>
                <Edit className={this.props.classes.editIcon} />
              </IconButton>
            )}
          </Fragment>
        ),
        historyChanges: {
          title: i18n.t('service_desk_web_atoir:historyOfChange'),
          link: `${this.props.match.url}/history`,
        },
        OuterButton: canCancelAgreement(
          this.props.permissions,
          this.props.agreement.status,
        ) ? (
          <TextButton onClick={this.onToggle('cancelAgreementOpen', true)}>
            {i18n.t('service_desk_web_atoir:cancelAgreement')}
          </TextButton>
        ) : null,
      })
    }
    if (
      prevProps.isFetchingPermissions !== this.props.isFetchingPermissions &&
      !this.props.isFetchingPermissions &&
      !this.props.permissionsError
    ) {
      if (canViewAgreementInfo(this.props.permissions)) {
        this.props.fetchAgreementRequest({ ...this.props.match.params })
      } else {
        this.props.history.push('/404')
      }
    }
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({
      [field]: value,
    })
  }

  onToggle = (field, value) => () => {
    this.setState({
      [field]: value,
    })
  }

  showNoEquipmentDialog = () => {
    this.props.showDialogWithOk(
      i18n.t('error_web_atoir:canNotAssignAgreementWithoutEquipment'),
    )
  }

  render() {
    const {
      state: {
        editNameDialogOpen,
        cancelAgreementOpen,
        assignAgreementOpen,
        rejectAgreementOpen,
        acceptAgreementOpen,
      },
      props: {
        classes,
        agreement,
        permissions,
        history,
        match: { url },
      },
      showNoEquipmentDialog,
      onToggle,
    } = this
    return (
      <div>
        {agreement && (
          <Fragment>
            <BreadCrumb to={popLastSections(url, 2)}>
              {i18n.t('service_desk_web_atoir:contractProfileTitle', {
                number: agreement.contract.num_contract,
                from: `${agreement.contract.month_begin}.${agreement.contract.year_begin}`,
                to: `${agreement.contract.month_end}.${agreement.contract.year_end}`,
              })}
            </BreadCrumb>
            <BreadCrumb to={url}>{agreement.name}</BreadCrumb>
          </Fragment>
        )}
        {agreement &&
          (agreement.status === contractsConstants.AGREEMENT_STATUS_CANCELED ||
            agreement.status ===
              contractsConstants.AGREEMENT_STATUS_REJECTED) &&
          agreement.reject_note && (
            <Fragment>
              <div style={{ display: 'flex' }}>
                <NotificationImportant
                  color="error"
                  style={{ paddingRight: 8 }}
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.rejectNoteTitle}
                >
                  {agreement.status ===
                    contractsConstants.AGREEMENT_STATUS_REJECTED &&
                    i18n.t('service_desk_web_atoir:agreementRejectedWarning')}
                  {agreement.status ===
                    contractsConstants.AGREEMENT_STATUS_CANCELED &&
                    i18n.t('service_desk_web_atoir:agreementCanceledWarning')}
                </Typography>
              </div>
              <LongTextExpander
                WrapperProps={{ style: { textWeight: 'bold' } }}
              >
                {agreement.reject_note}
              </LongTextExpander>
            </Fragment>
          )}
        <Typography
          id="main-contract-info"
          variant="h5"
          gutterBottom
          className={classes.mainInfoText}
        >
          {i18n.t('service_desk_web_atoir:mainInfoTitle')}
        </Typography>
        {editNameDialogOpen && (
          <FormDialog
            open={editNameDialogOpen}
            closeIconHidden
            contentText={
              <EditAgreementDialog
                onFinish={error => {
                  onToggle('editNameDialogOpen', false)()
                  if (!error) {
                    this.props.fetchAgreementRequest({
                      ...this.props.match.params,
                    })
                  }
                }}
                onClose={onToggle('editNameDialogOpen', false)}
                open={editNameDialogOpen}
                name={agreement.name}
              />
            }
            submitButtonTitle={i18n.t('shared_web_atoir:save')}
            secondaryButtonsArray={[
              {
                secondaryText: i18n.t('shared_web_atoir:cancel'),
                handleSecondaryAction: onToggle('editNameDialogOpen', false),
              },
            ]}
            title={i18n.t('service_desk_web_atoir:editAgreementName')}
            onClose={onToggle('editNameDialogOpen', false)}
          />
        )}
        {agreement && (
          <List>
            <ListItem disableGutters>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  {i18n.t('service_desk_web_atoir:dateOfCreation')}:
                </Grid>
                <Grid item xs={12} sm={9}>
                  {new Date(agreement.date_created).toLocaleDateString()}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem disableGutters>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  {i18n.t('service_desk_web_atoir:createdBy')}:
                </Grid>
                <Grid item xs={12} sm={9}>
                  {getUserFullName(agreement.client_user)}
                </Grid>
              </Grid>
            </ListItem>
          </List>
        )}
        {agreement && cancelAgreementOpen && (
          <CancelAgreement
            agreement={agreement}
            open
            onFinish={error => {
              if (!error) {
                this.props.fetchAgreementRequest({ ...this.props.match.params })
              }
            }}
            onClose={onToggle('cancelAgreementOpen', false)}
          />
        )}
        {permissions &&
          agreement &&
          canSendAgreement(permissions, agreement.status) && (
            <div className={classes.bottomActions}>
              <ContainedButton
                onClick={() => {
                  if (!this.agreementTableRef) return
                  if (!this.agreementTableRef.hasEquipment()) {
                    showNoEquipmentDialog()
                    return
                  }
                  onToggle('assignAgreementOpen', true)()
                }}
              >
                {i18n.t('service_desk_web_atoir:send')}
              </ContainedButton>
              {assignAgreementOpen && (
                <AssignAgreement
                  agreement={agreement}
                  open
                  onFinish={error => {
                    if (!error) {
                      this.props.fetchAgreementRequest({
                        ...this.props.match.params,
                      })
                    }
                  }}
                  onClose={onToggle('assignAgreementOpen', false)}
                />
              )}
            </div>
          )}
        <div className={classes.bottomActions}>
          {permissions &&
            agreement &&
            canRejectAgreement(permissions, agreement.status) && (
              <Fragment>
                <OutlinedButton onClick={onToggle('rejectAgreementOpen', true)}>
                  {i18n.t('shared_web_atoir:doNotAccept')}
                </OutlinedButton>
                {agreement && rejectAgreementOpen && (
                  <RejectAgreement
                    agreement={agreement}
                    open
                    onFinish={error => {
                      if (!error) {
                        history.push(popLastSections(url, 2))
                      }
                    }}
                    onClose={onToggle('rejectAgreementOpen', false)}
                  />
                )}
              </Fragment>
            )}
          {permissions &&
            agreement &&
            canAcceptAgreement(permissions, agreement.status) && (
              <Fragment>
                {acceptAgreementOpen && (
                  <AcceptAgreement
                    agreement={agreement}
                    open
                    onFinish={error => {
                      if (!error) {
                        history.push(
                          `${popLastSections(url, 2)}/distribute_equipment`,
                        )
                      }
                    }}
                    onClose={onToggle('acceptAgreementOpen', false)}
                  />
                )}
                <ContainedButton
                  onClick={onToggle('acceptAgreementOpen', true)}
                >
                  {i18n.t('service_desk_web_atoir:takePlace')}
                </ContainedButton>
              </Fragment>
            )}
        </div>
        {agreement && (
          <div id="equipment-table">
            <Typography className={classes.tableTitle} variant="h5">
              {i18n.t('service_desk_web_atoir:equipments')}
            </Typography>
            <AgreementEquipmentTable
              customRef={ref => {
                this.agreementTableRef = ref
              }}
              agreement={agreement}
              permissions={permissions}
            />
          </div>
        )}
      </div>
    )
  }
}

AgreementProfile.propTypes = {}

export default withStyles(styles)(AgreementProfile)
