export const GET_ENGINEER_SETTINGS_REQUEST =
  'settings/GET_ENGINEER_SETTINGS_REQUEST'
export const GET_ENGINEER_SETTINGS_SUCCESS =
  'settings/GET_ENGINEER_SETTINGS_SUCCESS'
export const GET_ENGINEER_SETTINGS_FAILURE =
  'settings/GET_ENGINEER_SETTINGS_FAILURE'

export const GET_COMMON_ORGANIZATION_SETTINGS_REQUEST =
  'organizationSettings/GET_COMMON_ORGANIZATION_SETTINGS_REQUEST'
export const GET_COMMON_ORGANIZATION_SETTINGS_SUCCESS =
  'organizationSettings/GET_COMMON_ORGANIZATION_SETTINGS_SUCCESS'
export const GET_COMMON_ORGANIZATION_SETTINGS_FAILURE =
  'organizationSettings/GET_COMMON_ORGANIZATION_SETTINGS_FAILURE'

export const UPDATE_SETTINGS_LISTS = 'settings/UPDATE_SETTINGS_LISTS'
