import React from 'react'
import PropTypes from 'prop-types'
// material-ui
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Heading from '../../widgets/Lettering/Heading'
import LoginNavLink from './LoginNavLink'

const styles = () => ({})

const LoginFormWrapper = props => (
  <Grid container justify="center">
    <Grid item xs={12} sm={8} md={8}>
      <Heading data-cy="AuthorizationHeading">{props.title}</Heading>
      {props.children}
    </Grid>
  </Grid>
)

LoginNavLink.defaultProps = {
  title: '',
  children: '',
  link: '',
}

LoginNavLink.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginFormWrapper)
