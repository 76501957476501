import * as constants from '../constants/actionTypes/tags'
import { actionCreator as externalActionCreator } from './actionCreator'

export const createTagRequest = externalActionCreator(
  constants.CREATE_TAG_REQUEST,
)
export const createTagSuccess = externalActionCreator(
  constants.CREATE_TAG_SUCCESS,
)
export const createTagFailure = externalActionCreator(
  constants.CREATE_TAG_FAILURE,
)

export const editTagRequest = externalActionCreator(constants.EDIT_TAG_REQUEST)
export const editTagSuccess = externalActionCreator(constants.EDIT_TAG_SUCCESS)
export const editTagFailure = externalActionCreator(constants.EDIT_TAG_FAILURE)

export const getTagsRequest = externalActionCreator(constants.GET_TAGS_REQUEST)
export const getTagsSuccess = externalActionCreator(constants.GET_TAGS_SUCCESS)
export const getTagsFailure = externalActionCreator(constants.GET_TAGS_FAILURE)

export const deleteTagRequest = externalActionCreator(
  constants.DELETE_TAG_REQUEST,
)
export const deleteTagSuccess = externalActionCreator(
  constants.DELETE_TAG_SUCCESS,
)
export const deleteTagFailure = externalActionCreator(
  constants.DELETE_TAG_FAILURE,
)

export const getTagRequest = externalActionCreator(constants.GET_TAG_REQUEST)
export const getTagSuccess = externalActionCreator(constants.GET_TAG_SUCCESS)
export const getTagFailure = externalActionCreator(constants.GET_TAG_FAILURE)
