/* eslint-disable max-len */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../../i18n/i18n'

const TypographyCustom = ({ children }) => (
  <Typography variant="caption">{children}</Typography>
)

const UlCustom = withStyles({
  root: {
    marginTop: 0,
    paddingLeft: 20,
  },
})(({ children, classes }) => <ul className={classes.root}>{children}</ul>)

const LiCustom = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
})(({ children, classes }) => <li className={classes.root}>{children}</li>)

const style = {
  root: {
    borderLeft: '3px solid rgba(0, 0, 0, 0.54)',
    paddingLeft: 8,
  },
}

function FileRequirementInfo({ classes }) {
  return (
    <div className={classes.root}>
      <TypographyCustom>
        {i18next.t('equipment_web_atoir:fileRequirement')}
      </TypographyCustom>
      <UlCustom>
        <LiCustom>
          <TypographyCustom>
            {i18next.t('equipment_web_atoir:fileRequirementExcelCommon')}
          </TypographyCustom>
        </LiCustom>
        <LiCustom>
          <TypographyCustom>
            {i18next.t('equipment_web_atoir:fileRequirementStringLimit')}
          </TypographyCustom>
        </LiCustom>
        <LiCustom>
          <TypographyCustom>
            {i18next.t('equipment_web_atoir:fileRequirementAddressString')}
          </TypographyCustom>
        </LiCustom>
        <LiCustom>
          <TypographyCustom>
            {i18next.t('equipment_web_atoir:fileRequirementAddressInSystem')}
          </TypographyCustom>
        </LiCustom>
        <LiCustom>
          <TypographyCustom>
            {i18next.t('equipment_web_atoir:fileRequirementDicForInwork')}
          </TypographyCustom>
        </LiCustom>
        <LiCustom>
          <TypographyCustom>
            {i18next.t(
              'equipment_web_atoir:fileRequirementDicForInuseAndPortable',
            )}
          </TypographyCustom>
        </LiCustom>
      </UlCustom>
    </div>
  )
}

export default withStyles(style)(FileRequirementInfo)
