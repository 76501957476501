import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import shortId from 'shortid'
import ContentEditable from 'react-contenteditable'
import { popLastSections } from '../../../../../helpers/dataHelper'
import i18next from '../../../../../i18n/i18n'
import config from '../../../../../config'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// components
import BreadCrumb from '../../../../App/routing/BreadCrumb'
import AddTemplateParameters from './AddTemplateParameters'
import OutlinedButton from '../../../../../widgets/Buttons/OutlinedButton'
import ContainedButton from '../../../../../widgets/Buttons/ContainedButton'
import ScriptCard from '../../ScriptCard'

// helpers
import {
  firstSelect,
  secondSelect,
  thirdSelect,
} from '../../../../../constants/emailTemplateParams'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  contentEditableDiv: {
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    fontSize: '1rem',
    lineHeight: '1.4875em',
    alignItems: 'center',
    width: '100%',
    minHeight: '19px',
    display: 'inline-block',
    overflow: 'auto',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(0,0,0,0.54)',
    '&:hover': {
      transitionDuration: '0.2s',
      borderBottom: '2px solid rgba(0,0,0,0.84)',
      marginBottom: '-1px',
    },
    '&:focus': {
      outline: 'none',
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      borderBottom: '2px solid rgb(17, 82, 147)',
      marginBottom: '-1px',
    },
    '&:before': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      pointerEvents: 'none',
    },
    '&:after': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      borderBottom: '2px solid rgb(17, 82, 147)',
      pointerEvents: 'none',
    },
  },
  contentEditableDivError: {
    cursor: 'text',
    fontSize: '1rem',
    lineHeight: '1.4875em',
    alignItems: 'center',
    width: '100%',
    minHeight: '19px',
    overflow: 'auto',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '2px solid rgba(0,0,0,0.84)',
    borderBottomColor: theme.palette.error.main,
    marginBottom: '-1px',
    '&:focus': {
      outline: 'none',
    },
  },
  inputLabel: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 12,
    '&:focused': {
      transitionDuration: '0.2s',
      color: 'rgb(17, 82, 147)',
    },
    '&:error': {
      fontSize: 12,
    },
  },
  inline: {
    display: 'inline',
  },
  param: {
    padding: 4,
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,0.12)',
    color: 'rgba(0,0,0,0.54)',
    fontSize: 12,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  helperTextWarning: {
    color: theme.palette.error.main,
  },
  helperTextWarningRight: {
    color: theme.palette.error.main,
    textAlign: 'right',
  },
  helperText: {
    textDecoration: 'none',
    textAlign: 'right',
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
})

class CreateEditEmailTemplate extends Component {
  state = {
    edit: false,
    name: '',
    nameHelperText: '',
    theme: '',
    themeParams: [],
    themeHelperText: '',
    themeFocused: false,
    text: '',
    textParams: [],
    textHelperText: '',
    textFocused: false,
  }

  componentDidMount = () => {
    const {
      props: { match, fetchOrganization, getEmailTemplateRequest },
    } = this
    if (match.params.createEditTemplate === 'edit') {
      this.setState({ edit: true })
      getEmailTemplateRequest({
        organizationId: match.params.organizationId,
        templateId: match.params.templateId,
      })
    }
    fetchOrganization(match.params.organizationId)
  }

  componentDidUpdate = prevProps => {
    const {
      props: {
        classes,
        history,
        match,
        isFetchingTemplate,
        template,
        isCreatingTemplate,
        createTemplateError,
        isEditingTemplate,
        editTemplateError,
        setNavigationDrawer,
      },
      state: { edit },
      processReceivedText,
    } = this
    if (
      (prevProps.isCreatingTemplate !== isCreatingTemplate &&
        !isCreatingTemplate &&
        isEmpty(createTemplateError)) ||
      (prevProps.isEditingTemplate !== isEditingTemplate &&
        !isEditingTemplate &&
        isEmpty(editTemplateError))
    ) {
      history.goBack()
    }
    if (
      prevProps.isFetchingTemplate !== isFetchingTemplate &&
      !isFetchingTemplate &&
      !isEmpty(template)
    ) {
      const textValues = processReceivedText(template.text)
      const themeValues = processReceivedText(template.theme)
      this.setState({
        name: template.name,
        theme: themeValues.text,
        themeParams: themeValues.params,
        text: textValues.text,
        textParams: textValues.params,
      })
      setNavigationDrawer([
        <Fragment>
          <List className={classes.list}>
            {template.scripts.map(script => (
              <ListItem className={classes.listItem}>
                <ScriptCard
                  script={script}
                  history={history}
                  url={
                    edit
                      ? popLastSections(match.url, 3)
                      : popLastSections(match.url, 2)
                  }
                  boundTo="EmailTemplate"
                />
              </ListItem>
            ))}
          </List>
        </Fragment>,
      ])
    }
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  processReceivedText = text => {
    const {
      props: { classes },
    } = this
    const matches = text.match(/{{\s*Сервис-Деск[^.]*.(.*?)\s*}}/gm) || []
    const arrParams = []
    matches
      .map(m => {
        const id = shortId.generate()
        return {
          id,
          value: m.replace('{{Сервис-Деск.', '').replace('}}', ''),
        }
      })
      .forEach(m => {
        const keys = m.value.split('.')
        arrParams.push({
          id: m.id,
          first: keys[0] || null,
          second: keys[1] || null,
          third: keys[2] || null,
        })
      })
    let resText = text
    arrParams.forEach(param => {
      resText = resText
        .replace(
          '{{Сервис-Деск.',
          `<span id="${param.id}" class="${classes.inline} ${classes.param}" contentEditable="false">`,
        )
        .replace('}}', '</span>')
    })
    return { text: resText, params: arrParams }
  }

  getInsertionFromString = text => {
    const match = text.match(/<span.*?id=".*?">(.*?)<\/span>/)
    if (!match) return null
    return match[1] || null
  }

  removeUnsuitable = text => {
    const { getIdFormInsertionString, getInsertionFromString } = this
    let bundle = text
    const matches = bundle.match(/<span.*?id=".*?">.*?<\/span>/gm)
    if (!matches) return { bundle, removed: [] }
    const removed = []
    matches.forEach(m => {
      if (
        getInsertionFromString(m)
          .split('.')
          .some(
            i =>
              !firstSelect.map(v => v.value).includes(i) &&
              !secondSelect.map(v => v.value).includes(i) &&
              !thirdSelect.map(v => v.value).includes(i),
          )
      ) {
        const id = getIdFormInsertionString(m)
        removed.push(id)
        bundle = bundle
          .replace(` ${m} `, '')
          .replace(` ${m}`, '')
          .replace(`${m} `, '')
          .replace(m, '')
      }
    })
    bundle = bundle || ''
    return { bundle, removed }
  }

  processHTMLText = text => {
    const { bundle, removed } = this.removeUnsuitable(text)
    const matches = bundle.match(/<\s*span[^>]*>(.*?)<\s*\/\s*span>/gm) || []
    const params = []
    matches
      .map(m => ({
        id: this.getIdFormInsertionString(m),
        value: m.replace(/<span.*?>/gm, '').replace(/<\/span>/gm, ''),
      }))
      .forEach(m => {
        const keys = m.value.split('.')
        params.push({
          id: m.id,
          first: keys[0] || null,
          second: keys[1] || null,
          third: keys[2] || null,
        })
      })
    const newParams = params.filter(p => !removed.includes(p.id))
    return { text: bundle, array: newParams }
  }

  formParamString = param =>
    `<span id="${shortId.generate()}" class="${this.props.classes.inline} ${
      this.props.classes.param
    }" contenteditable="false">${param.first}.${param.second}${
      param.third ? `.${param.third}` : ''
    }</span>`

  handleTextChange = field => event => {
    const { value } = event.target
    this.setState({
      nameHelperText: '',
      [field]: value,
    })
  }

  handleParamAdd = (textField, arrayField, param) => {
    this.setState({ [`${textField}HelperText`]: '' })
    const { processHTMLText, formParamString } = this
    this.setState(state => {
      const text = state[textField]
      const textStr = `${text} ${formParamString(param)}&nbsp;`
      const values = processHTMLText(textStr, textField)
      return this.setState({
        [textField]: values.text,
        [arrayField]: values.array,
      })
    })
    document.getElementById(`input-template-${textField}`).focus()
  }

  handleParamsTextChange = (textField, arrayField) => event => {
    this.setState({ [`${textField}HelperText`]: '' })
    const { value } = event.target
    const { processHTMLText } = this
    const values = processHTMLText(value, textField)
    this.setState({
      [textField]: values.text,
      [arrayField]: values.array,
    })
  }

  handleParamChange = (textField, arrayField, param, prevParam) => event => {
    const { value } = event.target
    this.setState(state => {
      const changedParam = Object.assign(prevParam)
      changedParam[param] = value
      if (param === 'second') {
        if (value === 'ЗН') {
          changedParam.third = thirdSelect[0].value
        } else {
          changedParam.third = null
        }
      }
      return {
        [arrayField]: state[arrayField].map(f => {
          if (f.id === changedParam.id) {
            return {
              ...changedParam,
            }
          }
          return f
        }),
      }
    }, this.updateText(textField, arrayField))
  }

  updateText = (textField, arrayField) => () => {
    const {
      state: { [textField]: text, [arrayField]: keys },
      getInsertionString,
      getIdFormInsertionString,
    } = this
    let bundle = text
    keys.forEach(key => {
      const matches = bundle.match(
        new RegExp(`<span.*?id="${key.id}".*?>`, 'gm'),
      )
      if (!matches || !matches[0]) {
        return
      }
      const startPart = matches[0]
      const textStartIndex = bundle.indexOf(startPart) + startPart.length
      const textEndIndex = bundle.indexOf('</span>', textStartIndex)
      const paramString = getInsertionString(key)
      bundle =
        bundle.slice(0, textStartIndex) +
        paramString +
        bundle.slice(textEndIndex, bundle.length + paramString.length)
    })
    const matches = bundle.match(/<span.*?id=".*?">.*?<\/span>/gm)
    matches.forEach(m => {
      const id = getIdFormInsertionString(m)
      if (keys.every(k => k.id !== id)) {
        bundle = bundle
          .replace(` ${m} `, '')
          .replace(` ${m}`, '')
          .replace(`${m} `, '')
          .replace(m, '')
      }
    })
    this.setState({
      [textField]: bundle,
    })
  }

  getInsertionString = insertion =>
    `${insertion.first}.${insertion.second}${
      insertion.third ? `.${insertion.third}` : ''
    }`

  handleParamDelete = (textField, arrayField, parameter) => {
    this.setState(
      state => ({
        [arrayField]: state[arrayField].filter(k => k.id !== parameter.id),
      }),
      this.updateText(textField, arrayField),
    )
  }

  getIdFormInsertionString = string => {
    const matches = string.match(/<span.*?id="(.*?)".*?>/)
    return matches ? matches[1] || null : null
  }

  formParamsTextForSending = text =>
    text
      .replace(/&ensp;/g, ' ')
      .replace(/&ensp/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/&nbsp/g, ' ')
      .replace(/<br>/gm, ' ')
      .replace(/<div>/gm, ' ')
      .replace(/<\/div>/gm, ' ')
      .replace(/<\s*span[^>]*>/gm, '{{Сервис-Деск.')
      .replace(/<\s*\/\s*span>/gm, '}}')

  toggleFocus = field => {
    this.setState(state => ({
      [`${field}Focused`]: !state[`${field}Focused`],
    }))
  }

  createEditTemplate = () => {
    const {
      props: {
        match: {
          params: { organizationId, templateId },
        },
        createEmailTemplateRequest,
        editEmailTemplateRequest,
      },
      state: { edit, name, theme, text },
      checkEmpty,
      formParamsTextForSending,
    } = this
    const themeToSend = formParamsTextForSending(theme)
    const textToSend = formParamsTextForSending(text)
    const valName = checkEmpty('name', name.trim())
    const valTheme = checkEmpty('theme', themeToSend)
    const valText = checkEmpty('text', textToSend)
    if (valName && valTheme && valText) {
      if (themeToSend.length > config.templateThemeMaxLength) {
        this.setState({
          themeHelperText: i18next.t(
            'organizations_web_atoir:templateThemeIsTooLong',
          ),
        })
        return
      }
      if (textToSend.length > config.templateTextMaxLength) {
        this.setState({
          textHelperText: i18next.t(
            'organizations_web_atoir:templateTextIsTooLong',
          ),
        })
        return
      }
    } else {
      return
    }
    const data = {
      name: name.trim(),
      theme: themeToSend,
      text: textToSend,
    }
    if (edit) {
      const payload = { organizationId, templateId, data }
      editEmailTemplateRequest(payload)
    } else {
      const payload = { organizationId, data }
      createEmailTemplateRequest(payload)
    }
  }

  checkEmpty = (field, text) => {
    if (text.length === 0) {
      this.setState({
        [`${field}HelperText`]: i18next.t(
          'organizations_web_atoir:fieldShouldBeFilled',
        ),
      })
      return false
    }
    return true
  }

  render() {
    const {
      props: { classes, history, match, organization, template },
      state: {
        edit,
        name,
        theme,
        text,
        themeParams,
        textParams,
        nameHelperText,
        themeHelperText,
        textHelperText,
        themeFocused,
        textFocused,
      },
      handleParamAdd,
      handleParamChange,
      handleParamDelete,
      createEditTemplate,
      toggleFocus,
      formParamsTextForSending,
    } = this
    return (
      <Fragment>
        <BreadCrumb
          length={29}
          to={`${
            edit ? popLastSections(match.url, 4) : popLastSections(match.url, 3)
          }/profile`}
        >
          {organization.short_name}
        </BreadCrumb>
        <BreadCrumb
          to={
            edit ? popLastSections(match.url, 3) : popLastSections(match.url, 2)
          }
        >
          {i18next.t('organizations_web_atoir:organizationSettings')}
        </BreadCrumb>
        {edit ? (
          <BreadCrumb to={match.url}>{template.name}</BreadCrumb>
        ) : (
          <BreadCrumb to={match.url}>
            {i18next.t(
              'organizations_web_atoir:emailTemplateCreationBreadcrumb',
            )}
          </BreadCrumb>
        )}
        <Typography variant="h4" gutterBottom className={classes.marginBtm}>
          {i18next.t(
            `organizations_web_atoir:${
              edit ? 'emailTemplateEditing' : 'emailTemplateCreation'
            }`,
          )}
        </Typography>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="template-name"
              label={i18next.t('organizations_web_atoir:templateNameLabel')}
              multiline
              autoFocus
              value={name}
              onChange={this.handleTextChange('name')}
              margin="normal"
              inputProps={{ maxLength: 255 }}
              fullWidth
              helperText={
                <Grid container direction="row" alignContent="space-between">
                  <Grid item xs={11}>
                    {nameHelperText ? (
                      <FormHelperText
                        id="input-template-helper-text"
                        className={classes.helperTextWarning}
                      >
                        {nameHelperText}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={1}>
                    <FormHelperText
                      id="input-template-helper-text-length"
                      className={classes.helperText}
                    >
                      {`${name.length}/${config.orgSettingNameLength}`}
                    </FormHelperText>
                  </Grid>
                </Grid>
              }
              error={!isEmpty(nameHelperText)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              required
              htmlFor="input-template-theme"
              focused={themeFocused}
              error={
                !isEmpty(themeHelperText) ||
                formParamsTextForSending(theme).length >
                  config.templateThemeMaxLength
              }
              className={classes.inputLabel}
            >
              {i18next.t('organizations_web_atoir:templateThemeLabel')}
            </InputLabel>
            <ContentEditable
              id="input-template-theme"
              html={theme}
              disabled={false}
              onChange={this.handleParamsTextChange('theme', 'themeParams')}
              className={
                isEmpty(themeHelperText)
                  ? classes.contentEditableDiv
                  : classes.contentEditableDivError
              }
              onFocus={() => toggleFocus('theme')}
              onBlur={() => toggleFocus('theme')}
            />
            <Grid container direction="row" alignContent="space-between">
              <Grid item xs={11}>
                {themeHelperText ||
                formParamsTextForSending(theme).length >
                  config.templateThemeMaxLength ? (
                  <FormHelperText
                    id="input-template-helper-theme"
                    className={classes.helperTextWarning}
                  >
                    {themeHelperText ||
                      `${i18next.t(
                        'organizations_web_atoir:templateThemeIsTooLong',
                      )}`}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={1}>
                <FormHelperText
                  id="input-template-helper-theme-length"
                  className={
                    themeHelperText ||
                    formParamsTextForSending(theme).length >
                      config.templateThemeMaxLength
                      ? classes.helperTextWarningRight
                      : classes.helperText
                  }
                >
                  {`${formParamsTextForSending(theme).length}/${
                    config.templateThemeMaxLength
                  }`}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AddTemplateParameters
              parameters={themeParams}
              textField="theme"
              arrayField="themeParams"
              onAdd={handleParamAdd}
              onChange={handleParamChange}
              onDelete={handleParamDelete}
              applyFor="subject"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              required
              htmlFor="input-template-text"
              focused={textFocused}
              error={
                !isEmpty(textHelperText) ||
                formParamsTextForSending(text).length >
                  config.templateTextMaxLength
              }
              className={classes.inputLabel}
            >
              {i18next.t('organizations_web_atoir:templateTextLabel')}
            </InputLabel>
            <ContentEditable
              id="input-template-text"
              html={text}
              disabled={false}
              onChange={this.handleParamsTextChange('text', 'textParams')}
              className={
                isEmpty(textHelperText)
                  ? classes.contentEditableDiv
                  : classes.contentEditableDivError
              }
              onFocus={() => toggleFocus('text')}
              onBlur={() => toggleFocus('text')}
            />
            <Grid container direction="row" alignContent="space-between">
              <Grid item xs={11}>
                {textHelperText ||
                formParamsTextForSending(text).length >
                  config.templateTextMaxLength ? (
                  <FormHelperText
                    id="input-template-helper-text"
                    className={classes.helperTextWarning}
                  >
                    {textHelperText ||
                      `${i18next.t(
                        'organizations_web_atoir:templateTextIsTooLong',
                      )}`}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={1}>
                <FormHelperText
                  id="input-template-helper-text-length"
                  className={
                    textHelperText ||
                    formParamsTextForSending(text).length >
                      config.templateTextMaxLength
                      ? classes.helperTextWarningRight
                      : classes.helperText
                  }
                >
                  {`${formParamsTextForSending(text).length}/${
                    config.templateTextMaxLength
                  }`}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AddTemplateParameters
              parameters={textParams}
              textField="text"
              arrayField="textParams"
              onAdd={handleParamAdd}
              onChange={handleParamChange}
              onDelete={handleParamDelete}
              applyFor="body"
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="space-between" xs={12}>
              <Grid item alignContent="flex-start">
                <OutlinedButton onClick={() => history.goBack()}>
                  {i18next.t('shared_web_atoir:cancel')}
                </OutlinedButton>
              </Grid>
              <Grid item alignContent="flex-end">
                <ContainedButton onClick={() => createEditTemplate()}>
                  {i18next.t('shared_web_atoir:save')}
                </ContainedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

CreateEditEmailTemplate.defaultProps = {
  createTemplateError: null,
  editTemplateError: null,
}

CreateEditEmailTemplate.propTypes = {
  setNavigationDrawer: PropTypes.func.isRequired,
  clearNavigationDrawer: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  getEmailTemplateRequest: PropTypes.func.isRequired,
  isFetchingTemplate: PropTypes.bool.isRequired,
  template: PropTypes.object.isRequired,
  createEmailTemplateRequest: PropTypes.func.isRequired,
  isCreatingTemplate: PropTypes.bool.isRequired,
  createTemplateError: PropTypes.object,
  editEmailTemplateRequest: PropTypes.func.isRequired,
  isEditingTemplate: PropTypes.bool.isRequired,
  editTemplateError: PropTypes.object,
}

export default withStyles(styles)(CreateEditEmailTemplate)
