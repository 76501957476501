import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AssignAgreement from './AssignAgreement'
import { assignAgreementRequest } from '../../../../actions/serviceDeskContractsActions'

const mapStateToProps = state => ({
  isAssigning: state.serviceDeskContracts.assignAgreement.isFetching,
  assignError: state.serviceDeskContracts.assignAgreement.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      assignAgreementRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssignAgreement),
)
