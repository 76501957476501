import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// material
import Select from '@material-ui/core/Select'
import Popover from '@material-ui/core/Popover/Popover'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'

// modules
import Loader from '../../components/Loader'

class SelectLimited extends React.Component {
  constructor(props) {
    super(props)
    this.listEl = React.createRef()
    this.state = {
      anchorEl: null,
      open: false,
    }
  }

  handlePopoverOpen = event => {
    event.preventDefault()
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  handlePopoverClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  valueSelectHandler = value => {
    this.handlePopoverClose()
    this.props.onChange(value)
  }

  handleScroll = () => {
    const {
      listEl: { paperRef },
      props: { onEndOfScroll },
    } = this
    if (
      paperRef.scrollHeight - paperRef.clientHeight - paperRef.scrollTop <=
      0
    ) {
      onEndOfScroll()
    }
  }

  render = () => {
    const {
      state: { anchorEl, open },
      props: { data, isDataFetch, value, allowEmpty, customTitleForEmpty },
      handlePopoverOpen,
      handlePopoverClose,
      handleScroll,
      valueSelectHandler,
    } = this
    return (
      <Fragment>
        <Select
          component="nav"
          open={false}
          onOpen={event => {
            handlePopoverOpen(event)
          }}
          value={value}
          {...this.props}
        >
          {value && (
            <MenuItem value={value}>
              {data.find(item => item.id === value).title}
            </MenuItem>
          )}
        </Select>
        <Popover
          id="simple-popper"
          open={open}
          onScroll={handleScroll}
          onClose={handlePopoverClose}
          anchorEl={anchorEl}
          innerRef={node => {
            this.listEl = node
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            {allowEmpty && data.length > 0 && (
              <ListItem
                button
                onClick={() => {
                  valueSelectHandler(null)
                }}
              >
                <ListItemText
                  style={{ minHeight: '24px' }}
                  primary={customTitleForEmpty}
                />
              </ListItem>
            )}
            {data.map(item => (
              <ListItem
                key={item.id}
                button
                onClick={() => {
                  valueSelectHandler(item.id)
                }}
                selected={item.id === value}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
            {data.length === 0 && (
              <ListItem
                button
                onClick={() => {
                  valueSelectHandler(null)
                }}
              >
                <ListItemText
                  style={{ minHeight: '24px' }}
                  primary={customTitleForEmpty}
                />
              </ListItem>
            )}
            {isDataFetch && (
              <ListItem>
                <Loader size={20} />
              </ListItem>
            )}
          </List>
        </Popover>
      </Fragment>
    )
  }
}

SelectLimited.defaultProps = {
  isDataFetch: false,
  value: undefined,
  allowEmpty: false,
  customTitleForEmpty: '',
}

SelectLimited.propTypes = {
  data: PropTypes.array.isRequired,
  isDataFetch: PropTypes.bool,
  onEndOfScroll: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  allowEmpty: PropTypes.bool,
  customTitleForEmpty: PropTypes.string,
}

export default SelectLimited
