import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import AddPhotoDialog from './widgets/AddPhotoDialog'
import Loader from '../../../../Loader'
import i18next from '../../../../../i18n/i18n'
import config from '../../../../../config'
import PhotoCell, {
  AddPhotoComponent,
} from '../../../../../widgets/images/PhotoCell'
import SimpleDialog from '../../../../Other/SimpleDialog/DialogComponent'
import BlockTitle from '../BlockTitle'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    textAlign: 'left',
    padding: 12,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileName: {
    color: theme.palette.grey[700],
    textDecoration: 'underline',
  },
  rootGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background: 'transparent',
  },
  photosRoot: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cellContainer: {
    padding: theme.spacing(1),
  },
  photoMainDiv: {
    paddingBottom: '8px',
  },
  empty: {
    marginLeft: theme.spacing(1),
  },
})

class RequestPhotos extends Component {
  state = {
    addPhotoDialogOpen: false,
    file: null,
    fileText: '',
  }

  onToggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  handleAdd = () => {
    this.props.upload(this.state.file)
    this.setState({
      file: null,
      fileText: '',
      addPhotoDialogOpen: false,
    })
  }

  onCloseDialog = () => {
    this.setState({
      file: null,
      fileText: '',
      addPhotoDialogOpen: false,
    })
  }

  onDelete = photo => () => {
    this.setState({
      photoToDelete: photo,
      deletePhotoDialogOpen: true,
    })
  }

  deletePhoto = () => {
    this.props.delete({
      callId: this.props.callId,
      photoId: this.state.photoToDelete.id,
    })
    this.onToggle('deletePhotoDialogOpen', false)()
  }

  onChange = event => {
    const { catchError, formDataName } = this.props
    const file = event.target.files[0]
    if (!file) {
      catchError(i18next.t('shared_web_atoir:noFileChosen'))
      return
    }
    if (file && file.size > config.max_call_photo_size) {
      catchError(i18next.t('shared_web_atoir:fileToBig'))
      return
    }
    const formData = new FormData()
    formData.append(formDataName, file)
    this.setState({
      file: formData,
      fileText: file.name,
    })
  }

  download = photo => () => {
    this.props.download(photo)
  }

  render() {
    const {
      state: { file, addPhotoDialogOpen, deletePhotoDialogOpen, fileText },
      props: { classes, photos, isFetchingPhotos, canEdit, downloadUrl },
      onToggle,
      onCloseDialog,
      onChange,
      handleAdd,
      download,
      deletePhoto,
      onDelete,
    } = this
    return (
      <div className={classes.photoMainDiv}>
        <Grid container spacing={2} style={{ textAlign: 'left' }}>
          <Grid item xs={12}>
            <BlockTitle
              text={i18next.t('service_desk_web_atoir:usefullPhotos')}
              noTopMargin
            />
          </Grid>
          <Grid item xs={12} className={classes.spaceBetween}>
            {isFetchingPhotos ? (
              <Loader />
            ) : (
              <div className={classes.photosRoot}>
                {photos.map(photo => (
                  <div className={classes.cellContainer}>
                    <PhotoCell
                      onDelete={canEdit && onDelete(photo)}
                      download={download}
                      url={downloadUrl(photo)}
                    />
                  </div>
                ))}
                {photos &&
                photos.length < config.max_call_photo_count &&
                canEdit ? (
                  <AddPhotoComponent
                    onClick={onToggle('addPhotoDialogOpen', true)}
                  />
                ) : (
                  ''
                )}
                {!canEdit && photos.length === 0 && (
                  <Typography variant="caption" className={classes.empty}>
                    {i18next.t('service_desk_web_atoir:noPhotos')}
                  </Typography>
                )}
              </div>
            )}
          </Grid>
        </Grid>
        {deletePhotoDialogOpen && (
          <SimpleDialog
            isOpen={deletePhotoDialogOpen}
            handleClose={onToggle('deletePhotoDialogOpen', false)}
            handlePositive={deletePhoto}
            handleNegative={onToggle('deletePhotoDialogOpen', false)}
            positiveText={i18next.t('shared_web_atoir:delete')}
            negativeText={i18next.t('shared_web_atoir:cancel')}
            title=""
            content={`${i18next.t('service_desk_web_atoir:deletePhoto')}?`}
            closeIconHidded
          />
        )}
        {addPhotoDialogOpen && (
          <AddPhotoDialog
            title={i18next.t('shared_web_atoir:addPhoto')}
            handleAdd={handleAdd}
            onChange={onChange}
            file={file}
            fileText={fileText}
            isOpen={addPhotoDialogOpen}
            onClose={onCloseDialog}
          />
        )}
      </div>
    )
  }
}

RequestPhotos.defaultProps = {
  photos: [],
  isFetchingPhotos: true,
  download: () => {},
}

RequestPhotos.propTypes = {
  callId: PropTypes.string.isRequired,
  isFetchingPhotos: PropTypes.bool,
  photos: PropTypes.array,
  formDataName: PropTypes.string.isRequired,
  upload: PropTypes.func.isRequired,
  download: PropTypes.func,
  delete: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
}

export default withStyles(styles)(RequestPhotos)
