import React from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import InputAdornment from '@material-ui/core/InputAdornment'

const ExpandableTextField = ({
  children,
  disabled,
  independent = false,
  onChange = () => {},
  ...otherProps
}) => {
  const [open, setOpen] = React.useState(false)
  const inputRef = React.useRef()
  function handleOpen() {
    if (inputRef.current) {
      inputRef.current.blur()
    }
    if (!disabled) {
      setOpen(true)
    }
  }
  function handleClose() {
    setOpen(false)
  }
  return (
    <>
      <TextField
        value=""
        {...otherProps}
        disabled={disabled}
        onClick={() => (independent ? null : handleOpen())}
        onChange={event => (independent ? onChange(event) : null)}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="expand"
                onClick={handleOpen}
                disabled={disabled}
              >
                <LaunchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {children({ open, handleClose })}
    </>
  )
}

export default ExpandableTextField
