import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ChooseThirdPartyOrganization from './ChooseThirdPartyOrganization'
import {
  fetchServiceOrganizations,
  fetchServiceOrganizationsCount,
} from '../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isFetchingServiceOrganizations:
    state.organizations.serviceOrganizations.isFetching,
  serviceOrganizations: state.organizations.serviceOrganizations.organizations,
  isFetchingServiceOrganizationsCount:
    state.organizations.serviceOrganizationsCount.isFetching,
  serviceOrganizationsCount:
    state.organizations.serviceOrganizationsCount.count,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceOrganizationsCount,
      fetchServiceOrganizations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChooseThirdPartyOrganization),
)
