import {
  CLEAR_BA_VARIANTS,
  FETCH_ORGANIZATION_EQUIPMENT_FAILURE,
  FETCH_ORGANIZATION_EQUIPMENT_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_SUCCESS,
  FETCH_ORGANIZATION_VENDORS_FAILURE,
  FETCH_ORGANIZATION_VENDORS_REQUEST,
  FETCH_ORGANIZATION_VENDORS_SUCCESS,
  FETCH_COUNTRY_NAMES_REQUEST,
  FETCH_COUNTRY_NAMES_SUCCESS,
  FETCH_COUNTRY_NAMES_FAILURE,
  ADD_EQUIPMENT_UNIT_FIRST_STEP,
  ADD_EQUIPMENT_UNIT_REQUEST,
  ADD_EQUIPMENT_UNIT_SUCCESS,
  ADD_EQUIPMENT_UNIT_FAILURE,
  FETCH_EQUIPMENT_PERMISSIONS_REQUEST,
  FETCH_EQUIPMENT_PERMISSIONS_SUCCESS,
  FETCH_EQUIPMENT_PERMISSIONS_FAILURE,
  FETCH_EQUIPMENT_DETAILS_REQUEST,
  FETCH_EQUIPMENT_DETAILS_SUCCESS,
  FETCH_EQUIPMENT_DETAILS_FAILURE,
  UPDATE_EQUIPMENT_DETAILS_REQUEST,
  UPDATE_EQUIPMENT_DETAILS_SUCCESS,
  UPDATE_EQUIPMENT_DETAILS_FAILURE,
  FETCH_USER_EQUIPMENTS_FAILURE,
  FETCH_USER_EQUIPMENTS_REQUEST,
  FETCH_USER_EQUIPMENTS_SUCCESS,
  FETCH_USER_EQUIPMENTS_VENDORS_REQUEST,
  FETCH_USER_EQUIPMENTS_VENDORS_SUCCESS,
  FETCH_USER_EQUIPMENTS_VENDORS_FAILURE,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_SUCCESS,
  FETCH_USER_EQUIPMENTS_DEPARTMENTS_FAILURE,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_FAILURE,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_FAILURE,
  TRANSFER_EQUIPMENTS_REQUEST,
  TRANSFER_EQUIPMENTS_SUCCESS,
  TRANSFER_EQUIPMENTS_FAILURE,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_SUCCESS,
  FETCH_ORGANIZATION_EQUIPMENT_COUNT_FAILURE,
  FETCH_USER_EQUIPMENT_COUNT_REQUEST,
  FETCH_USER_EQUIPMENT_COUNT_SUCCESS,
  FETCH_USER_EQUIPMENT_COUNT_FAILURE,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_SUCCESS,
  TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_FAILURE,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_SUCCESS,
  TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_FAILURE,
  FETCH_EQUIPMENT_LOGS_REQUEST,
  FETCH_EQUIPMENT_LOGS_SUCCESS,
  FETCH_EQUIPMENT_LOGS_FAILURE,
  EQUIPMENT_LOGS_COUNT_REQUEST,
  EQUIPMENT_LOGS_COUNT_SUCCESS,
  EQUIPMENT_LOGS_COUNT_FAILURE,
  CLEAR_EQUIPMENT_UNIT_CREATION_DATA,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_FAILURE,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_SUCCESS,
  FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_FAILURE,
  FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST,
  FETCH_EQUIPMENT_FILTER_LOCATION_SUCCESS,
  FETCH_EQUIPMENT_FILTER_LOCATION_FAILURE,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_SUCCESS,
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_FAILURE,
  EQUIPMENT_LIST_ROWS_PER_PAGE_CHANGE,
  CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST,
  CHECK_EQUIPMENTS_IMPORT_FILE_SUCCESS,
  CHECK_EQUIPMENTS_IMPORT_FILE_FAILURE,
  PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST,
  PROCESS_EQUIPMENTS_IMPORT_FILE_SUCCESS,
  PROCESS_EQUIPMENTS_IMPORT_FILE_FAILURE,
  PROCESS_EQUIPMENTS_IMPORT_FILE_CLEAR,
  GET_BA_VARIANTS,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_SUCCESS,
  FETCH_ORGANIZATION_BINDED_EQUIPMENT_FAILURE,
  GET_BA_SUGGEST,
  CLEAR_BA_SUGGEST,
  REJECT_BA_SUGGESTIONS,
  DOWNLOAD_MED_CERT,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_SUCCESS,
  FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_FAILURE,
  CLEAR_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
} from '../constants/actionTypes'

import { actionCreatorTyped } from './actionCreator'

const actionCreator = type => payload => ({
  type,
  payload,
})

export const downloadMedCertificate = payload => ({
  type: DOWNLOAD_MED_CERT,
  payload,
})

export const fetchOrganizationEquipment = (organizationId, data) => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_REQUEST,
  data,
  organizationId,
})

export const fetchOrganizationEquipmentSuccess = (data, count) => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_SUCCESS,
  data,
  count,
})

export const fetchOrganizationEquipmentFailure = code => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_FAILURE,
  code,
})

export const clearOrganizationEquipmentTOWorks = payload => ({
  type: CLEAR_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
  payload,
})
export const fetchOrganizationEquipmentTOWorks = payload => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_REQUEST,
  payload,
})

export const fetchOrganizationEquipmentTOWorksSuccess = payload => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_SUCCESS,
  payload,
})

export const fetchOrganizationEquipmentTOWorksFailure = payload => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_TO_WORKS_FAILURE,
  payload,
})

export const fetchOrganizationBindedEquipment = payload => ({
  type: FETCH_ORGANIZATION_BINDED_EQUIPMENT_REQUEST,
  payload,
})

export const fetchOrganizationBindedEquipmentSuccess = payload => ({
  type: FETCH_ORGANIZATION_BINDED_EQUIPMENT_SUCCESS,
  payload,
})

export const fetchOrganizationBindedEquipmentFailure = payload => ({
  type: FETCH_ORGANIZATION_BINDED_EQUIPMENT_FAILURE,
  payload,
})

export const fetchUserEquipment = data => ({
  type: FETCH_USER_EQUIPMENTS_REQUEST,
  data,
})

export const fetchUserEquipmentSuccess = (data, count) => ({
  type: FETCH_USER_EQUIPMENTS_SUCCESS,
  data,
  count,
})

export const fetchUserEquipmentFailure = code => ({
  type: FETCH_USER_EQUIPMENTS_FAILURE,
  code,
})

export const fetchTransferringEquipmentSender = query => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_SENDER_REQUEST,
  query,
})

export const fetchTransferringEquipmentSenderSuccess = (data, count) => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_SENDER_SUCCESS,
  data,
  count,
})

export const fetchTransferringEquipmentSenderFailure = code => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_SENDER_FAILURE,
  code,
})

export const fetchTransferringEquipmentSenderCount = () => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_REQUEST,
})

export const fetchTransferringEquipmentSenderCountSuccess = count => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_SUCCESS,
  count,
})

export const fetchTransferringEquipmentSenderCountFailure = code => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_SENDER_COUNT_FAILURE,
  code,
})

export const fetchTransferringEquipmentReceiver = query => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_REQUEST,
  query,
})

export const fetchTransferringEquipmentReceiverSuccess = (data, count) => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_SUCCESS,
  data,
  count,
})

export const fetchTransferringEquipmentReceiverFailure = code => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_FAILURE,
  code,
})

export const fetchTransferringEquipmentReceiverCount = () => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_REQUEST,
})

export const fetchTransferringEquipmentReceiverCountSuccess = count => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_SUCCESS,
  count,
})

export const fetchTransferringEquipmentReceiverCountFailure = code => ({
  type: FETCH_TRANSFERRING_EQUIPMENTS_RECEIVER_COUNT_FAILURE,
  code,
})

export const transferEquipment = (
  transferType,
  organizationId,
  transferId,
  locationId,
  departmentId,
) => ({
  type: TRANSFER_EQUIPMENTS_REQUEST,
  transferType,
  organizationId,
  transferId,
  locationId,
  departmentId,
})

export const transferEquipmentSuccess = data => ({
  type: TRANSFER_EQUIPMENTS_SUCCESS,
  data,
})

export const transferEquipmentFailure = code => ({
  type: TRANSFER_EQUIPMENTS_FAILURE,
  code,
})

export const fetchUserEquipmentVendors = data => ({
  type: FETCH_USER_EQUIPMENTS_VENDORS_REQUEST,
  data,
})

export const fetchUserEquipmentVendorsSuccess = data => ({
  type: FETCH_USER_EQUIPMENTS_VENDORS_SUCCESS,
  data,
})

export const fetchUserEquipmentVendorsFailure = code => ({
  type: FETCH_USER_EQUIPMENTS_VENDORS_FAILURE,
  code,
})

export const fetchOrganizationEquipmentCount = (organizationId, filters) => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_COUNT_REQUEST,
  organizationId,
  filters,
})

export const fetchOrganizationEquipmentCountSuccess = data => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_COUNT_SUCCESS,
  data,
})

export const fetchOrganizationEquipmentCountFailure = code => ({
  type: FETCH_ORGANIZATION_EQUIPMENT_COUNT_FAILURE,
  code,
})

export const fetchUserEquipmentCount = filters => ({
  type: FETCH_USER_EQUIPMENT_COUNT_REQUEST,
  filters,
})

export const fetchUserEquipmentCountSuccess = data => ({
  type: FETCH_USER_EQUIPMENT_COUNT_SUCCESS,
  data,
})

export const fetchUserEquipmentCountFailure = code => ({
  type: FETCH_USER_EQUIPMENT_COUNT_FAILURE,
  code,
})

export const fetchUserEquipmentDepartments = data => ({
  type: FETCH_USER_EQUIPMENTS_DEPARTMENTS_REQUEST,
  data,
})

export const fetchUserEquipmentDepartmentsSuccess = (data, count) => ({
  type: FETCH_USER_EQUIPMENTS_DEPARTMENTS_SUCCESS,
  data,
  count,
})

export const fetchUserEquipmentDepartmentsFailure = code => ({
  type: FETCH_USER_EQUIPMENTS_DEPARTMENTS_FAILURE,
  code,
})

export const fetchOrganizationVendors = organizationId => ({
  type: FETCH_ORGANIZATION_VENDORS_REQUEST,
  organizationId,
})

export const fetchOrganizationVendorsSuccess = data => ({
  type: FETCH_ORGANIZATION_VENDORS_SUCCESS,
  data,
})

export const fetchOrganizationVendorsFailure = code => ({
  type: FETCH_ORGANIZATION_VENDORS_FAILURE,
  code,
})

export const fetchCountryNames = () => ({
  type: FETCH_COUNTRY_NAMES_REQUEST,
})

export const fetchCountryNamesSuccess = data => ({
  type: FETCH_COUNTRY_NAMES_SUCCESS,
  data,
})

export const fetchCountryNamesFailure = errors => ({
  type: FETCH_COUNTRY_NAMES_FAILURE,
  errors,
})

export const addEquipmentUnitFirstStep = data => ({
  type: ADD_EQUIPMENT_UNIT_FIRST_STEP,
  data,
})

export const clearEquipmentUnitCreationData = () => ({
  type: CLEAR_EQUIPMENT_UNIT_CREATION_DATA,
})

export const addEquipmentUnit = (organizationId, equipmentData) => ({
  type: ADD_EQUIPMENT_UNIT_REQUEST,
  organizationId,
  equipmentData,
})

export const addEquipmentUnitSuccess = (organizaitonId, equipmentId) => ({
  type: ADD_EQUIPMENT_UNIT_SUCCESS,
  organizaitonId,
  equipmentId,
})

export const addEquipmentUnitFailure = errors => ({
  type: ADD_EQUIPMENT_UNIT_FAILURE,
  errors,
})

export const fetchEquipmentPermissions = id => ({
  type: FETCH_EQUIPMENT_PERMISSIONS_REQUEST,
  id,
})

export const fetchEquipmentPermissionsSuccess = data => ({
  type: FETCH_EQUIPMENT_PERMISSIONS_SUCCESS,
  data,
})

export const fetchEquipmentPermissionsFailure = errors => ({
  type: FETCH_EQUIPMENT_PERMISSIONS_FAILURE,
  errors,
})

export const fetchEquipmentDetails = (organizationId, equipmentId) => ({
  type: FETCH_EQUIPMENT_DETAILS_REQUEST,
  organizationId,
  equipmentId,
})

export const fetchEquipmentDetailsSuccess = data => ({
  type: FETCH_EQUIPMENT_DETAILS_SUCCESS,
  data,
})

export const fetchEquipmentDetailsFailure = errors => ({
  type: FETCH_EQUIPMENT_DETAILS_FAILURE,
  errors,
})

export const updateEquipmentDetails = (organizationId, equipmentId, data) => ({
  type: UPDATE_EQUIPMENT_DETAILS_REQUEST,
  organizationId,
  equipmentId,
  data,
})

export const updateEquipmentDetailsSuccess = () => ({
  type: UPDATE_EQUIPMENT_DETAILS_SUCCESS,
})

export const updateEquipmentDetailsFailure = errors => ({
  type: UPDATE_EQUIPMENT_DETAILS_FAILURE,
  errors,
})

export const transferEquipmentToOwnOrganization = (
  organizationId,
  transferData,
) => ({
  type: TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_REQUEST,
  organizationId,
  transferData,
})

export const transferEquipmentToOwnOrganizationSuccess = () => ({
  type: TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_SUCCESS,
})

export const transferEquipmentToOwnOrganizationFailure = errors => ({
  type: TRANSFER_EQUIPMENT_TO_OWN_ORGANIZATION_FAILURE,
  errors,
})

export const transferEquipmentToForeignOrganization = (
  organizationId,
  transferData,
) => ({
  type: TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_REQUEST,
  organizationId,
  transferData,
})

export const transferEquipmentToForeignOrganizationSuccess = () => ({
  type: TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_SUCCESS,
})

export const transferEquipmentToForeignOrganizationFailure = errors => ({
  type: TRANSFER_EQUIPMENT_TO_FOREIGN_ORGANIZATION_FAILURE,
  errors,
})

export const fetchEquipmentLogs = (orgId, eqpId, filters) => ({
  type: FETCH_EQUIPMENT_LOGS_REQUEST,
  orgId,
  eqpId,
  filters,
})

export const fetchEquipmentLogsSuccess = logs => ({
  type: FETCH_EQUIPMENT_LOGS_SUCCESS,
  logs,
})

export const fetchEquipmentLogsFailure = errors => ({
  type: FETCH_EQUIPMENT_LOGS_FAILURE,
  errors,
})

export const equipmentLogsCount = (orgId, eqpId, filters) => ({
  type: EQUIPMENT_LOGS_COUNT_REQUEST,
  orgId,
  eqpId,
  filters,
})

export const equipmentLogsCountSuccess = count => ({
  type: EQUIPMENT_LOGS_COUNT_SUCCESS,
  count,
})

export const equipmentLogsCountFailure = errors => ({
  type: EQUIPMENT_LOGS_COUNT_FAILURE,
  errors,
})

export const fetchEquipmentFilterLocationRequest = actionCreator(
  FETCH_EQUIPMENT_FILTER_LOCATION_REQUEST,
)
export const fetchEquipmentFilterLocationSuccess = actionCreator(
  FETCH_EQUIPMENT_FILTER_LOCATION_SUCCESS,
)
export const fetchEquipmentFilterLocationFailure = actionCreator(
  FETCH_EQUIPMENT_FILTER_LOCATION_FAILURE,
)

export const fetchEquipmentFilterDepartmentRequest = actionCreator(
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_REQUEST,
)
export const fetchEquipmentFilterDepartmentSuccess = actionCreator(
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_SUCCESS,
)
export const fetchEquipmentFilterDepartmentFailure = actionCreator(
  FETCH_EQUIPMENT_FILTER_DEPARTMENT_FAILURE,
)

export const setEquipmentRowsPerPage = actionCreator(
  EQUIPMENT_LIST_ROWS_PER_PAGE_CHANGE,
)

export const checkEquipmentsImportFileRequest = actionCreator(
  CHECK_EQUIPMENTS_IMPORT_FILE_REQUEST,
)
export const checkEquipmentsImportFileSuccess = actionCreator(
  CHECK_EQUIPMENTS_IMPORT_FILE_SUCCESS,
)
export const checkEquipmentsImportFileFailure = actionCreator(
  CHECK_EQUIPMENTS_IMPORT_FILE_FAILURE,
)

export const processEquipmentsImportFileRequest = actionCreator(
  PROCESS_EQUIPMENTS_IMPORT_FILE_REQUEST,
)
export const processEquipmentsImportFileSuccess = actionCreator(
  PROCESS_EQUIPMENTS_IMPORT_FILE_SUCCESS,
)
export const processEquipmentsImportFileFailure = actionCreator(
  PROCESS_EQUIPMENTS_IMPORT_FILE_FAILURE,
)
export const processEquipmentsImportFileClear = actionCreator(
  PROCESS_EQUIPMENTS_IMPORT_FILE_CLEAR,
)

export const getBAVariants = actionCreatorTyped(GET_BA_VARIANTS)

export const clearBAVariants = () => ({
  type: CLEAR_BA_VARIANTS,
})

export const getBASuggest = actionCreatorTyped(GET_BA_SUGGEST)

export const clearBASuggest = () => ({
  type: CLEAR_BA_SUGGEST,
})

export const rejectBaSuggestions = actionCreatorTyped(REJECT_BA_SUGGESTIONS)
