import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import { Link } from 'react-router-dom'
import { convertTimestamp } from '../../helpers/dateHelper'
import { addMaskCharacters } from '../../helpers/phoneStringHelper'

// internationalization
import i18next from '../../i18n/i18n'

// components
import Heading from '../../widgets/Lettering/Heading'
import LetterAvatar from './LetterAvatarComponent'
import UserAvatar from './UserAvatarComponent'
import Loader from '../Loader'

// styles
import '../../styles/components/Register.css'
import '../../styles/components/User.css'

// material ui
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// icons
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import SettingsIcon from '@material-ui/icons/Settings'
import EditIcon from '@material-ui/icons/Edit'
import PasswordIcon from '@material-ui/icons/Lock'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { getUserFullName } from '../../helpers/userHelper'
import BreadCrumb from '../App/routing/BreadCrumb'
import { popLastSections } from '../../helpers/dataHelper'

const propTypes = {
  isFetchingUser: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchUserById: PropTypes.func.isRequired,
}

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  userHeading: {
    wordBreak: 'break-all',
    display: 'flex',
    justifyContent: 'space-between',
    // margin: 10,
    padding: 12,
    alignItems: 'center',
  },
  userInfo: {
    marginTop: theme.typography.pxToRem(10),
  },
})

class UserProfileComponent extends Component {
  constructor(props) {
    super(props)

    const { id } = this.props.match.params
    this.state = {
      anchorEl: null,
      currentUserProfile: id === undefined,
      id,
    }
  }

  componentDidMount() {
    const { isLoggedIn } = this.props
    const { currentUserProfile, id } = this.state
    const { fetchUserById, fetchUser, fetchOrganization, logout } = this.props
    const { organizationId } = this.props.match.params
    if (!currentUserProfile) {
      fetchUser('fetchUserById')
      fetchUserById(id)
      fetchOrganization(organizationId)
    } else if (isLoggedIn) {
      fetchUser()
    } else {
      // only for redirect to login page in case open with empty global store happens
      logout()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      if (this.props.match.params.id !== undefined) {
        this.setState({
          currentUserProfile: false,
        })
        const { fetchUserById } = this.props
        fetchUserById(this.props.match.params.id)
      } else {
        this.setState({
          currentUserProfile: true,
        })
        const { fetchUser } = this.props
        fetchUser()
      }
    }
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleClickSettings = () => {
    this.props.history.push('/profile/settings')
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  convertToDate = () => {
    const { user } = this.props
    return convertTimestamp(user.passwordChanged).toString()
  }

  render() {
    const { anchorEl } = this.state
    const {
      isFetchingUser,
      user,
      classes,
      match,
      wentFromOrganization,
      organization,
    } = this.props
    return isFetchingUser || isEmpty(user) ? (
      <Loader />
    ) : (
      <div className="User-profile-container">
        {wentFromOrganization && (
          <BreadCrumb to={popLastSections(match.url, 2)}>
            {organization.short_name}
          </BreadCrumb>
        )}
        <BreadCrumb to={match.url}>
          {i18next.t('user_profile_web_atoir:userProfileCrumbTitle')}
        </BreadCrumb>
        <div className="User-info-container">
          <Paper className="User-info-form" elevation={4}>
            <div className={classes.userHeading}>
              <Heading>
                <Typography component="span" variant="h6" data-cy="User-info">
                  {getUserFullName(user)}
                </Typography>
              </Heading>
              {this.state.currentUserProfile ? (
                <div>
                  <Tooltip title={i18next.t('shared_web_atoir:edit')}>
                    <IconButton
                      className="User-profile-edit-icon"
                      aria-owns={anchorEl ? 'simple-menu' : null}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={i18next.t('shared_web_atoir:profileSettings')}
                  >
                    <IconButton
                      className="User-profile-edit-icon"
                      aria-owns={anchorEl ? 'simple-menu' : null}
                      aria-haspopup="true"
                      onClick={this.handleClickSettings}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem component={Link} to={`${match.url}/edit`}>
                      {i18next.t(
                        'user_profile_web_atoir:editUserProfileMenuItem',
                      )}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={`${match.url}/change_password`}
                    >
                      {i18next.t(
                        'user_profile_web_atoir:changePasswordMenuItem',
                      )}
                    </MenuItem>
                  </Menu>
                </div>
              ) : null}
            </div>
            <Divider />
            <div className={classes.userInfo}>
              <Grid container spacing={2} justify="center">
                <Grid item md={4} sm={12}>
                  <div>
                    {user.avatar ? (
                      <UserAvatar imageUrl={`/user/avatars/${user.avatar}`} />
                    ) : (
                      <LetterAvatar
                        letters={
                          user.firstName.charAt(0).toUpperCase() +
                          user.lastName.charAt(0).toUpperCase()
                        }
                      />
                    )}
                  </div>
                </Grid>
                <Grid item md={8} sm={12}>
                  <List component="nav">
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isEmpty(user.phone)
                            ? '−'
                            : addMaskCharacters(user.phone)
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="wordWrapBreakAll"
                        primary={user.email}
                      />
                    </ListItem>
                    {this.state.currentUserProfile ? (
                      <ListItem>
                        <ListItemIcon>
                          <PasswordIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${i18next.t(
                            'user_profile_web_atoir:passwordCreated',
                          )}:
                              ${this.convertToDate()}`}
                        />
                      </ListItem>
                    ) : null}
                  </List>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <br />
        </div>
      </div>
    )
  }
}

UserProfileComponent.propTypes = propTypes

export default withStyles(styles)(UserProfileComponent)
