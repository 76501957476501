import { takeLatest, put, takeEvery, delay } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import * as actions from '../actions/serviceDeskContractsActions'
import { handleErrors } from '../helpers/errorsHelper'
import * as constants from '../constants/actionTypes/contracts'
import * as api from './api'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { hideDialogLoader, showDialogLoader } from '../actions/utilsActions'

export function* watchFetchServiceDeskContractsRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACTS_REQUEST,
    fetchServiceDeskContractsRequest,
  )
}

export function* watchFetchServiceDeskContractPermissionsRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_PERMISSIONS_REQUEST,
    fetchServiceDeskContractPermissionsRequest,
  )
}

export function* watchFetchServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_REQUEST,
    fetchServiceDeskContractRequest,
  )
}

export function* watchCancelServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.CANCEL_CONTRACT_REQUEST,
    cancelServiceDeskContractRequest,
  )
}

export function* watchFetchServiceDeskContractLogsRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_LOGS_REQUEST,
    fetchServiceDeskContractLogsRequest,
  )
}

export function* watchAddServiceDeskContractAgreementEquipmentsRequestSaga() {
  yield takeLatest(
    constants.ADD_AGREEMENT_EQUIPMENT_REQUEST,
    addServiceDeskContractAgreementEquipmentsRequest,
  )
}

export function* watchFetchServiceDeskAgreementLogsRequestSaga() {
  yield takeLatest(
    constants.FETCH_AGREEMENT_LOGS_REQUEST,
    fetchServiceDeskAgreementLogsRequest,
  )
}

export function* watchFetchServiceDeskAgreementLogsCountRequestSaga() {
  yield takeLatest(
    constants.ADD_AGREEMENT_EQUIPMENT_REQUEST,
    fetchServiceDeskAgreementLogsCountRequest,
  )
}

export function* watchFetchServiceDeskContractLogsCountRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_LOGS_COUNT_REQUEST,
    fetchServiceDeskContractLogsCountRequest,
  )
}

export function* watchAssignServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.ASSIGN_CONTRACT_REQUEST,
    assignServiceDeskContractRequest,
  )
}

export function* watchAcceptServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.ACCEPT_CONTRACT_REQUEST,
    acceptServiceDeskContractRequest,
  )
}

export function* watchRejectServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.REJECT_CONTRACT_REQUEST,
    rejectServiceDeskContractRequest,
  )
}

export function* watchFetchServiceDeskContractEquipmentRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_EQUIPMENT_REQUEST,
    fetchServiceDeskContractEquipmentsRequest,
  )
}

export function* watchFetchAgreementsRequestSaga() {
  yield takeLatest(constants.FETCH_AGREEMENTS_REQUEST, fetchAgreementsRequest)
}

export function* watchFetchAgreementsCountRequestSaga() {
  yield takeLatest(
    constants.FETCH_AGREEMENTS_COUNT_REQUEST,
    fetchAgreementsCountRequest,
  )
}

export function* watchCancelServiceDeskContractAgreementRequestSaga() {
  yield takeLatest(
    constants.CANCEL_AGREEMENT_REQUEST,
    cancelServiceDeskContractAgreementRequest,
  )
}

export function* watchAcceptServiceDeskContractAgreementRequestSaga() {
  yield takeLatest(
    constants.ACCEPT_AGREEMENT_REQUEST,
    acceptServiceDeskContractAgreementRequest,
  )
}

export function* watchRejectServiceDeskContractAgreementRequestSaga() {
  yield takeLatest(
    constants.REJECT_AGREEMENT_REQUEST,
    rejectServiceDeskContractAgreementRequest,
  )
}

export function* watchAssignServiceDeskContractAgreementRequestSaga() {
  yield takeLatest(
    constants.ASSIGN_AGREEMENT_REQUEST,
    assignServiceDeskContractAgreementRequest,
  )
}

export function* watchFetchServiceDeskAgreementEquipmentRequestSaga() {
  yield takeLatest(
    constants.FETCH_AGREEMENT_EQUIPMENT_REQUEST,
    fetchServiceDeskAgreementEquipmentsRequest,
  )
}

export function* watchFetchServiceDeskAgreementEquipmentCountRequestSaga() {
  yield takeLatest(
    constants.FETCH_AGREEMENT_EQUIPMENT_COUNT_REQUEST,
    fetchServiceDeskAgreementEquipmentsCountRequest,
  )
}

export function* watchDeleteServiceDeskAgreementEquipmentRequestSaga() {
  yield takeLatest(
    constants.DELETE_AGREEMENT_EQUIPMENT_REQUEST,
    deleteServiceDeskAgreementEquipmentsRequest,
  )
}

export function* watchDeleteServiceDeskContractEquipmentRequestSaga() {
  yield takeLatest(
    constants.DELETE_CONTRACT_EQUIPMENT_REQUEST,
    deleteServiceDeskContractEquipmentsRequest,
  )
}

export function* watchFetchServiceDeskContractEquipmentCountRequestSaga() {
  yield takeLatest(
    constants.FETCH_CONTRACT_EQUIPMENT_COUNT_REQUEST,
    fetchServiceDeskContractEquipmentsCountRequest,
  )
}

export function* watchEditServiceDeskContractRequestSaga() {
  yield takeLatest(
    constants.EDIT_CONTRACT_REQUEST,
    editServiceDeskContractRequest,
  )
}

export function* watchAssignEngineerToEquipmentsRequestSaga() {
  yield takeLatest(
    constants.ASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST,
    assignEngineerToEquipmentsRequest,
  )
}

export function* watchUnAssignEngineerToEquipmentsRequestSaga() {
  yield takeLatest(
    constants.UNASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST,
    unAssignEngineerToEquipmentsRequest,
  )
}

export function* watchEditServiceDeskContractResonsibleRequestSaga() {
  yield takeLatest(
    constants.EDIT_CONTRACT_RESPONSIBLE_REQUEST,
    editServiceDeskContractResponsibleRequest,
  )
}

export function* watchCreateAgreementRequestSaga() {
  yield takeLatest(constants.CREATE_AGREEMENT_REQUEST, createAgreementRequest)
}

export function* watchFetchAgreementRequestSaga() {
  yield takeLatest(constants.FETCH_AGREEMENT_REQUEST, fetchAgreementRequest)
}

export function* watchEditAgreementNameRequestSaga() {
  yield takeLatest(
    constants.EDIT_AGREEMENT_NAME_REQUEST,
    editAgreementNameRequest,
  )
}

function* fetchServiceDeskContractsRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.fetchContractsSuccess({
            contracts: result.contracts,
            append: action.payload.offset !== 0,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskContractPermissionsRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractPermissionsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractPermissionsSuccess(result.permissions))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractPermissionsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskContractRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.fetchServiceDeskContractRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractSuccess(result.contract))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* cancelServiceDeskContractRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.cancelServiceDeskContractRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.cancelContractSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.cancelContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchServiceDeskContractLogsRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.fetchServiceDeskContractLogsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractLogsSuccess(result.logs))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractLogsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchServiceDeskAgreementLogsRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.fetchServiceDeskAgreementLogsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchAgreementLogsSuccess(result.logs))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementLogsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchServiceDeskAgreementLogsCountRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.fetchServiceDeskAgreementLogsCountRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchAgreementLogsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementLogsCountFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchServiceDeskContractLogsCountRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.fetchServiceDeskContractLogsCountRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractLogsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractLogsCountFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* assignServiceDeskContractRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.assignServiceDeskContractRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.assignContractSuccess({
            assignOwn: action.payload.assignOwn,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(actions.assignContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* acceptServiceDeskContractRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.acceptServiceDeskContractRequestApi(action.payload),
    )
    yield put(hideDialogLoader())
    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.acceptContractSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.acceptContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* rejectServiceDeskContractRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.rejectServiceDeskContractRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.rejectContractSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.rejectContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchServiceDeskContractEquipmentsRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractEquipmentsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractEquipmentsSuccess(result.equipments))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractEquipmentsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchAgreementsRequest(action) {
  try {
    const result = yield call(api.fetchAgreementsRequestApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.fetchAgreementsSuccess({
            agreements: result.agreements,
            append: action.payload.append,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchAgreementsCountRequest(action) {
  try {
    const result = yield call(
      api.fetchAgreementsCountRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchAgreementsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementsCountFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* cancelServiceDeskContractAgreementRequest(action) {
  try {
    const result = yield call(
      api.cancelServiceDeskContractAgreementRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.cancelAgreementSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.cancelAgreementFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* acceptServiceDeskContractAgreementRequest(action) {
  try {
    const result = yield call(
      api.acceptServiceDeskContractAgreementRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.acceptAgreementSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.acceptAgreementFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* rejectServiceDeskContractAgreementRequest(action) {
  try {
    const result = yield call(
      api.rejectServiceDeskContractAgreementRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.rejectAgreementSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.rejectAgreementFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* assignServiceDeskContractAgreementRequest(action) {
  try {
    const result = yield call(
      api.assignServiceDeskContractAgreementRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.assignAgreementSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.assignAgreementFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskAgreementEquipmentsRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskAgreementEquipmentsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchAgreementEquipmentsSuccess(result.equipments))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementEquipmentsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskAgreementEquipmentsCountRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskAgreementEquipmentsCountRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchAgreementEquipmentsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementEquipmentsCountFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteServiceDeskAgreementEquipmentsRequest(action) {
  try {
    const result = yield call(
      api.deleteServiceDeskAgreementEquipmentsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.deleteAgreementEquipmentsSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.deleteAgreementEquipmentsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteServiceDeskContractEquipmentsRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.deleteServiceDeskContractEquipmentsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.deleteContractEquipmentsSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.deleteContractEquipmentsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* addServiceDeskContractAgreementEquipmentsRequest(actionData) {
  try {
    const result = yield call(
      api.addServiceDeskContractAgreementEquipmentsRequestApi(
        actionData.payload,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.addAgreementEquipmentSuccess(result.contract))
      } else {
        const errors = handleErrors(result)
        yield put(actions.addAgreementEquipmentFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchServiceDeskContractEquipmentsCountRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractEquipmentsCountRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchContractEquipmentsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchContractEquipmentsCountFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* editServiceDeskContractRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.editServiceDeskContractRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.editContractSuccess(result.contract))
      } else {
        const errors = handleErrors(result)
        yield put(actions.editContractFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* assignEngineerToEquipmentsRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.assignEngineerToEquipmentsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.assignEngineerToEquipmentsSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.assignEngineerToEquipmentsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* unAssignEngineerToEquipmentsRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.unAssignEngineerToEquipmentsRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.unAssignEngineerToEquipmentsSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.unAssignEngineerToEquipmentsFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* editServiceDeskContractResponsibleRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.editServiceDeskContractResponsibleRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.editContractResponsibleSuccess(result.contract))
      } else {
        const errors = handleErrors(result)
        yield put(actions.editContractResponsibleFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* createAgreementRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(api.createContractRequestApi(action.payload))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.createAgreementSuccess(result.contract_agreement_id))
      } else {
        const errors = handleErrors(result)
        yield put(actions.createAgreementFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* fetchAgreementRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.fetchContractAgreementRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.fetchAgreementSuccess(result.agreement))
      } else {
        const errors = handleErrors(result)
        yield put(actions.fetchAgreementFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

function* editAgreementNameRequest(action) {
  try {
    yield put(showDialogLoader())
    const result = yield call(
      api.editContractAgreementNameRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(actions.editAgreementNameSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(actions.editAgreementNameFailure(result.code))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
  yield put(hideDialogLoader())
}

export function* watchFetchServiceDeskContractsCountRequestSaga() {
  yield takeEvery(
    constants.FETCH_CONTRACTS_COUNT_REQUEST,
    fetchServiceDeskContractsCountRequest,
  )
}

function* fetchServiceDeskContractsCountRequest(action) {
  try {
    const result = yield call(
      api.fetchServiceDeskContractsCountRequestApi(action.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        if (action.payload.params) {
          yield put(
            actions.fetchContractsCountWithFiltersSuccess({
              status: action.payload.status,
              count: result.not_distributed_count || result.count,
            }),
          )
        } else {
          yield put(
            actions.fetchContractsCountSuccess({
              status: action.payload.status,
              count: result.count,
            }),
          )
        }
      } else {
        const errors = handleErrors(result)
        if (action.payload.params) {
          yield put(
            actions.fetchContractsCountFailure({
              status: action.payload.status,
              code: result.code,
            }),
          )
        } else {
          yield put(
            actions.fetchContractsCountWithFiltersFailure({
              status: action.payload.status,
              code: result.code,
            }),
          )
        }
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

export function* watchFetchFilteringOrganizationsRequestSaga() {
  yield takeLatest(
    constants.FETCH_FILTERING_ORGANIZATIONS_REQUEST,
    fetchFilteringOrganizationsRequest,
  )
}

function* fetchFilteringOrganizationsRequest(action) {
  yield call(delay, 500)
  try {
    const result = yield call(
      api.fetchFilteringOrganizationsRequestApi({
        side: action.payload.side,
        name: action.payload.name || '',
        status: action.payload.status,
        filter_side: action.payload.filterSide,
      }),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(
          actions.fetchFilteringOrganizationsSuccess({
            side: action.payload.filterSide,
            organizations: result.organizations,
          }),
        )
      } else {
        const errors = handleErrors(result)
        yield put(
          actions.fetchFilteringOrganizationsFailure({
            side: action.payload.filterSide,
            code: result.code,
          }),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
