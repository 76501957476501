import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchLocations } from '../../../../../actions/locationsActions'

import ProjectInfo from './ProjectInfo'

const mapStateToProps = state => ({
  isFetchingProject: state.inventory.isFetchingProject,
  inventoryProject: state.inventory.project,
  locations: state.locations.locations,
  isFetchingLocations: state.locations.isFetchingLocations,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectInfo),
)
