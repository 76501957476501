import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    fontWeight: '100',
  },
})

function Heading(props) {
  return (
    <Fragment>
      {// eslint-disable-next-line max-len
      // check added for legacy compatible due it can be used with nested Typography component or with plain text as nested children
      React.isValidElement(props.children) ? (
        props.children
      ) : (
        <Typography
          variant="h5"
          align="left"
          className={props.classes.root}
          {...props}
        >
          {props.children}
        </Typography>
      )}

      {props.divider && <Divider />}
    </Fragment>
  )
}

Heading.defaultProps = {
  children: '',
}

Heading.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Heading)
