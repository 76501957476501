import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    padding: 4,
  },
}))

const AddContragentButton = ({ onClick }) => {
  const classes = useStyles()

  return (
    <IconButton
      id="addContragentButton"
      onClick={onClick}
      disableRipple
      className={classes.root}
    >
      <DeleteIcon />
    </IconButton>
  )
}

export default AddContragentButton
