import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OrderComments from './OrderComments'
import {
  fetchCallPermissions,
  fetchOrder,
  fetchOrderCommentsRequest,
  fetchOrderCommentsCountRequest,
  uploadServiceDeskOrderCommentRequest,
  uploadServiceDeskOrderSubcommentRequest,
  getServiceCallOrderCommentFileLinkRequest,
} from '../../../../../../actions/serviceDesk'
import { downloadFileRequest } from '../../../../../../actions/appActions'

const mapStateToProps = state => ({
  permissions: state.serviceDesk.permissions,
  isFetchingPermissions: state.serviceDesk.permissions.isFetching,
  order: state.serviceDesk.order.order,
  isFetchingOrder: state.serviceDesk.order.isFetching,
  comments: state.serviceDesk.orderComments.comments,
  isFetchingComments: state.serviceDesk.orderComments.isFetching,
  isUploadingComment: state.serviceDesk.uploadingComment.isFetching,
  isUploadingSubcomment: state.serviceDesk.uploadingSubcomment.isFetching,
  orderCommentsCount: state.serviceDesk.orderCommentsCount.count,
  isFetchingOrderCommentsCount: state.serviceDesk.orderCommentsCount.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCallPermissions,
      fetchOrder,
      fetchOrderCommentsRequest,
      fetchOrderCommentsCountRequest,
      uploadServiceDeskOrderCommentRequest,
      uploadServiceDeskOrderSubcommentRequest,
      downloadFileRequest,
      getPreviewPath: getServiceCallOrderCommentFileLinkRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderComments),
)
