import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ServiceDeskRequestDetails from './ServiceDeskRequestDetails'
import { fetchEquipmentDetails } from '../../../../actions/equipmentActions'
import {
  fetchOrganization,
  fetchOrganizationsMemberOf,
  fetchOrganizationUserMe,
  fetchOrganizationWhoAmI,
} from '../../../../actions/organizationsActions'
import { fetchOrganizationStaff } from '../../../../actions/staffActions'

const mapStateToProps = state => ({
  creationData: state.serviceDesk.creation.data,
  isFetchingEquipment:
    state.equipment.equipmentDetails.isFetchingEquipmentDetails,
  equipmentError: state.equipment.equipmentDetails.error,
  equipment: state.equipment.equipmentDetails.equipment,
  isFetchingOrganization: state.organizations.isFetchingOrganization,
  organization: state.organizations.organization,
  memberships: state.organizations.memberOf.member,
  isFetchingMemberOrganizations: state.organizations.memberOf.isFetching,
  organizationStaff: state.staff.organizationStaff,
  isFetchingOrganizationStaff: state.staff.isFetchingOrganizationStaff,
  isFetchingMe: state.organizations.me.isFetching,
  me: state.organizations.me.me,
  isFetchingUserStatus: state.organizations.userStatus.isFetching,
  userStatus: state.organizations.userStatus.status,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationStaff,
      fetchEquipmentDetails,
      fetchOrganizationWhoAmI,
      fetchOrganizationsMemberOf,
      fetchOrganizationUserMe,
      fetchOrganization,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceDeskRequestDetails),
)
