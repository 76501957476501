import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'
import withStyles from '@material-ui/core/styles/withStyles'
import i18next from '../../../i18n/i18n'
import Loader from '../../Loader'
import BreadCrumb from '../../App/routing/BreadCrumb'
import HistoryViewer from './widgets/HistoryViewer'
import { popLastSections } from '../../../helpers/dataHelper'

const styles = theme => ({
  rootWithPadding: {
    padding: 12,
  },
  dividerWithMargin: {
    marginTop: 8,
    marginBottom: 8,
  },
  profileInfoCellNameWrapper: {
    color: theme.palette.grey[500],
  },
  editEquipmentDialogRoot: {
    width: '70%',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 8px',
  },
  actionButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  textFieldMulty: {
    width: '500px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  grayText: {
    color: theme.palette.grey[500],
  },
})

class CallHistory extends Component {
  componentDidMount = () => {
    const {
      props: {
        fetchCallPermissions,
        match: {
          params: { id },
        },
      },
    } = this
    fetchCallPermissions(id)
  }

  componentDidUpdate = prevProps => {
    const {
      props: { isFetchingPermissions, isFetchingCall },
    } = this

    if (
      prevProps.isFetchingPermissions !== isFetchingPermissions &&
      !isFetchingPermissions
    ) {
      if (this.props.permissionsError || !this.props.permissions.ANY) {
        history.push('/404')
        return
      }
      this.props.fetchServiceDeskCall(this.props.match.params.id)
    }

    if (prevProps.isFetchingCall !== isFetchingCall && !isFetchingCall) {
      this.props.getTitleInfo({
        title: i18next.t('service_desk_web_atoir:callActionHistoryTitleInfo', {
          internalNumber: `${
            this.props.call.internal_num
              ? ` № ${this.props.call.internal_num}`
              : ''
          }`,
          date: new Date(
            Date.parse(this.props.call.createdAt),
          ).toLocaleDateString('ru'),
        }),
      })
    }
  }

  render() {
    const {
      props: {
        match: {
          params: { id: callId },
          url,
        },
        call,
        isFetchingCall,
        isFetchingPermissions,
        permissions,
      },
    } = this
    return isFetchingCall || isFetchingPermissions ? (
      <Loader />
    ) : (
      !isEmpty(call) && !isEmpty(permissions) && call.id === callId && (
        <Fragment>
          <BreadCrumb length={29} to={popLastSections(url)}>
            {i18next.t('service_desk_web_atoir:titleCallCard', {
              date: new Date(
                Date.parse(this.props.call.createdAt),
              ).toLocaleDateString('ru'),
              internalNum: this.props.call.internal_num,
            })}
          </BreadCrumb>
          <BreadCrumb length={29} to={url}>
            {i18next.t('service_desk_web_atoir:history')}
          </BreadCrumb>
          <HistoryViewer callId={callId} permissions={permissions} />
        </Fragment>
      )
    )
  }
}

export default withStyles(styles)(CallHistory)
