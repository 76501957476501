import React, { Component } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from '../../widgets/Buttons/OutlinedButton'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography/Typography'

class AppDialog extends Component {
  handleCloseSimpleDialog = () => {
    this.props.handleCloseSimpleDialog()
  }

  render = () => {
    const { simpleDialogOpen, simpleDialogText } = this.props
    return (
      <Dialog
        open={simpleDialogOpen}
        onClose={this.handleCloseSimpleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography align="center">{simpleDialogText}</Typography>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            onClick={this.handleCloseSimpleDialog}
            autoFocus
            size="medium"
          >
            OK
          </OutlinedButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AppDialog
