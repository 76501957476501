import {
  FETCH_STAFF_SUCCESS,
  FETCH_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  FETCH_ORGANIZATION_STAFF_REQUEST,
  FETCH_ORGANIZATION_STAFF_SUCCESS,
  FETCH_ORGANIZATION_STAFF_FAILURE,
  FETCH_ORGANIZATION_LOCATIONS_REQUEST,
  FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
  FETCH_ORGANIZATION_LOCATIONS_FAILURE,
  ADD_ORGANIZATION_USER_REQUEST,
  ADD_ORGANIZATION_USER_SUCCESS,
  ADD_ORGANIZATION_USER_FAILURE,
  BIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  BIND_ORGANIZATION_USER_ACCOUNT_SUCCESS,
  BIND_ORGANIZATION_USER_ACCOUNT_FAILURE,
  UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  UNBIND_ORGANIZATION_USER_ACCOUNT_SUCCESS,
  UNBIND_ORGANIZATION_USER_ACCOUNT_FAILURE,
  START_CREATE_UPDATE_ORGANIZATION_USER,
  COMPLETE_CREATE_UPDATE_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER_REQUEST,
  UPDATE_ORGANIZATION_USER_SUCCESS,
  UPDATE_ORGANIZATION_USER_FAILURE,
  DELETE_ORGANIZATION_USER_REQUEST,
  DELETE_ORGANIZATION_USER_SUCCESS,
  DELETE_ORGANIZATION_USER_FAILURE,
  FETCH_BOUND_ORGANIZATION_USERS_REQUEST,
  FETCH_BOUND_ORGANIZATION_USERS_SUCCESS,
  FETCH_BOUND_ORGANIZATION_USERS_FAILURE,
  FETCH_USER_CONTACTS_REQUEST,
  FETCH_USER_CONTACTS_SUCCESS,
  FETCH_USER_CONTACTS_FAILURE,
  FETCH_ORGANIZATION_STAFF_COUNT_REQUEST,
  FETCH_ORGANIZATION_STAFF_COUNT_SUCCESS,
  FETCH_ORGANIZATION_STAFF_COUNT_FAILURE,
  FETCH_USER_CONTACTS_COUNT_REQUEST,
  FETCH_USER_CONTACTS_COUNT_SUCCESS,
  FETCH_USER_CONTACTS_COUNT_FAILURE,
  SET_STAFF_LIST_ROWS_PER_PAGE,
} from '../constants/actionTypes'

export const fetchStaffSuccess = data => ({
  type: FETCH_STAFF_SUCCESS,
  data,
})

export const fetchStaffFailure = errors => ({
  type: FETCH_STAFF_FAILURE,
  errors,
})

export const fetchContacts = data => ({
  type: FETCH_USER_CONTACTS_REQUEST,
  data,
})

export const fetchContactsSuccess = data => ({
  type: FETCH_USER_CONTACTS_SUCCESS,
  data,
})

export const fetchContactsFailure = errors => ({
  type: FETCH_USER_CONTACTS_FAILURE,
  errors,
})

export const fetchContactsCount = data => ({
  type: FETCH_USER_CONTACTS_COUNT_REQUEST,
  data,
})

export const fetchContactsCountSuccess = count => ({
  type: FETCH_USER_CONTACTS_COUNT_SUCCESS,
  count,
})

export const fetchContactsCountFailure = errors => ({
  type: FETCH_USER_CONTACTS_COUNT_FAILURE,
  errors,
})

export const deleteStaff = ids => ({
  type: DELETE_STAFF_REQUEST,
  ids,
})

export const deleteStaffSuccess = () => ({
  type: DELETE_STAFF_SUCCESS,
})

export const deleteStaffFailure = () => ({
  type: DELETE_STAFF_FAILURE,
})

export const fetchOrganizationStaff = (id, filters, query) => ({
  type: FETCH_ORGANIZATION_STAFF_REQUEST,
  id,
  query,
  filters,
})

export const fetchOrganizationStaffSuccess = data => ({
  type: FETCH_ORGANIZATION_STAFF_SUCCESS,
  data,
})

export const fetchOrganizationStaffFailure = errors => ({
  type: FETCH_ORGANIZATION_STAFF_FAILURE,
  errors,
})

export const fetchOrganizationStaffCount = (id, filters, query) => ({
  type: FETCH_ORGANIZATION_STAFF_COUNT_REQUEST,
  id,
  filters,
  query,
})

export const fetchOrganizationStaffCountSuccess = count => ({
  type: FETCH_ORGANIZATION_STAFF_COUNT_SUCCESS,
  count,
})

export const fetchOrganizationStaffCountFailure = errors => ({
  type: FETCH_ORGANIZATION_STAFF_COUNT_FAILURE,
  errors,
})

export const addOrganizationUser = (id, data) => ({
  type: ADD_ORGANIZATION_USER_REQUEST,
  id,
  data,
})

export const addOrganizationUserSuccess = organizationUserId => ({
  type: ADD_ORGANIZATION_USER_SUCCESS,
  organizationUserId,
})

export const addOrganizationUserFailure = errors => ({
  type: ADD_ORGANIZATION_USER_FAILURE,
  errors,
})

export const bindOrganizationUserAccount = (organizationId, data) => ({
  type: BIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  organizationId,
  data,
})

export const bindOrganizationUserAccountSuccess = () => ({
  type: BIND_ORGANIZATION_USER_ACCOUNT_SUCCESS,
})

export const bindOrganizationUserAccountFailure = errors => ({
  type: BIND_ORGANIZATION_USER_ACCOUNT_FAILURE,
  errors,
})

export const unbindOrganizationUserAccount = (organizationId, data) => ({
  type: UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  organizationId,
  data,
})

export const unbindOrganizationUserAccountSuccess = () => ({
  type: UNBIND_ORGANIZATION_USER_ACCOUNT_SUCCESS,
})

export const unbindOrganizationUserAccountFailure = errors => ({
  type: UNBIND_ORGANIZATION_USER_ACCOUNT_FAILURE,
  errors,
})

export const startCreateUpdateOrganizationUser = () => ({
  type: START_CREATE_UPDATE_ORGANIZATION_USER,
})

export const completeCreateUpdateOrganizationUser = () => ({
  type: COMPLETE_CREATE_UPDATE_ORGANIZATION_USER,
})

export const updateOrganizationUser = (
  organizationId,
  organizationUserId,
  data,
) => ({
  type: UPDATE_ORGANIZATION_USER_REQUEST,
  organizationId,
  organizationUserId,
  data,
})

export const updateOrganizationUserSuccess = () => ({
  type: UPDATE_ORGANIZATION_USER_SUCCESS,
})

export const updateOrganizationUserFailure = errors => ({
  type: UPDATE_ORGANIZATION_USER_FAILURE,
  errors,
})

export const deleteOrganizationUser = (organizationId, organizationUserId) => ({
  type: DELETE_ORGANIZATION_USER_REQUEST,
  organizationId,
  organizationUserId,
})

export const deleteOrganizationUserSuccess = () => ({
  type: DELETE_ORGANIZATION_USER_SUCCESS,
})

export const deleteOrganizationUserFailure = errors => ({
  type: DELETE_ORGANIZATION_USER_FAILURE,
  errors,
})

// this request should be get from organizations
export const fetchOrganizationLocations = id => ({
  type: FETCH_ORGANIZATION_LOCATIONS_REQUEST,
  id,
})

export const fetchOrganizationLocationsSuccess = data => ({
  type: FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
  data,
})

export const fetchOrganizationLocationsFailure = errors => ({
  type: FETCH_ORGANIZATION_LOCATIONS_FAILURE,
  errors,
})

export const fetchBoundOrganizationUsers = (organizationId, filters) => ({
  type: FETCH_BOUND_ORGANIZATION_USERS_REQUEST,
  organizationId,
  filters,
})

export const fetchBoundOrganizationUsersSuccess = data => ({
  type: FETCH_BOUND_ORGANIZATION_USERS_SUCCESS,
  data,
})

export const fetchBoundOrganizationUsersFailure = errors => ({
  type: FETCH_BOUND_ORGANIZATION_USERS_FAILURE,
  errors,
})

export const setOrganizationsStaffListRowsPerPage = data => ({
  type: SET_STAFF_LIST_ROWS_PER_PAGE,
  data,
})
