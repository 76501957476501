import {
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_FAILURE,
  FETCH_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENTS_COUNT_REQUEST,
  FETCH_DEPARTMENTS_COUNT_SUCCESS,
  FETCH_DEPARTMENTS_COUNT_FAILURE,
} from '../constants/actionTypes'

export const fetchDepartment = (organizationId, query) => ({
  type: FETCH_DEPARTMENT_REQUEST,
  organizationId,
  query,
})

export const fetchDepartmentSuccess = data => ({
  type: FETCH_DEPARTMENT_SUCCESS,
  data,
})

export const fetchDepartmentFailure = code => ({
  type: FETCH_DEPARTMENT_FAILURE,
  code,
})

export const fetchDepartments = (organizationId, data) => ({
  type: FETCH_DEPARTMENTS_REQUEST,
  data,
  organizationId,
})

export const fetchDepartmentsSuccess = data => ({
  type: FETCH_DEPARTMENTS_SUCCESS,
  data,
})

export const fetchDepartmentsFailure = code => ({
  type: FETCH_DEPARTMENTS_FAILURE,
  code,
})

export const fetchDepartmentsCount = organizationId => ({
  type: FETCH_DEPARTMENTS_COUNT_REQUEST,
  organizationId,
})

export const fetchDepartmentsCountSuccess = count => ({
  type: FETCH_DEPARTMENTS_COUNT_SUCCESS,
  count,
})

export const fetchDepartmentsCountFailure = code => ({
  type: FETCH_DEPARTMENTS_COUNT_FAILURE,
  code,
})

export const createDepartment = (organizationId, data) => ({
  type: CREATE_DEPARTMENT_REQUEST,
  organizationId,
  data,
})

export const createDepartmentSuccess = data => ({
  type: CREATE_DEPARTMENT_SUCCESS,
  data,
})

export const createDepartmentFailure = code => ({
  type: CREATE_DEPARTMENT_FAILURE,
  code,
})

export const updateDepartment = (organizationId, departmentId, data) => ({
  type: UPDATE_DEPARTMENT_REQUEST,
  organizationId,
  departmentId,
  data,
})

export const updateDepartmentSuccess = data => ({
  type: UPDATE_DEPARTMENT_SUCCESS,
  data,
})

export const updateDepartmentFailure = code => ({
  type: UPDATE_DEPARTMENT_FAILURE,
  code,
})

export const deleteDepartment = (organizationId, departmentId) => ({
  type: DELETE_DEPARTMENT_REQUEST,
  organizationId,
  departmentId,
})

export const deleteDepartmentSuccess = data => ({
  type: DELETE_DEPARTMENT_SUCCESS,
  data,
})

export const deleteDepartmentFailure = code => ({
  type: DELETE_DEPARTMENT_FAILURE,
  code,
})
