import React from 'react'
import PropTypes from 'prop-types'
import { statuses } from '../../../../../../constants/constants'

// internationalization
import i18next from '../../../../../../i18n/i18n'

// components
import DropDownList from '../../../../../../widgets/DropDownList'
import ApplyButton from '../../../../../../widgets/Buttons/ApplyButton'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    flex: '1 1 100%',
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formLabel: {
    textAlign: 'left',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  title: {
    flex: '0 0 auto',
  },
  dateInput: {
    margin: theme.spacing(1),
  },
  applyButton: {
    alignSelf: 'flex-end',
  },
})

class InventoryEquipmentFiltersToolbar extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      locationsOpen: false,
      selectedLocation: '',
      departmentsOpen: false,
      selectedDepartment: '',
      floorsOpen: false,
      selectedFloor: '',
      cabinetsOpen: false,
      selectedCabinet: '',
      equipmentStatusOpen: false,
      selectedEquipmentStatus: '',
      markingOpen: false,
      selectedMarking: '',
      foundEquipmentOpen: false,
      selectedFoundEquipment: '',
      serialNo: '',
      inventoryNo: '',
      nameFilter: '',
    }
  }

  markedEsquipment = [
    { id: true, title: i18next.t('shared_web_atoir:thereIs') },
    { id: false, title: i18next.t('shared_web_atoir:No') },
    { id: 'all', title: i18next.t('shared_web_atoir:All') },
  ]

  foundEquipment = [
    { id: true, title: i18next.t('shared_web_atoir:Found') },
    { id: false, title: i18next.t('shared_web_atoir:notFound') },
    { id: 'all', title: i18next.t('shared_web_atoir:All') },
  ]

  componentDidMount() {
    const {
      props: {
        match: {
          params: { projectId },
        },
        fetchInventoryEquipmentFilters,
      },
    } = this
    fetchInventoryEquipmentFilters(projectId)
  }

  handleOpen = field => () => {
    this.setState({
      [field]: true,
    })
  }

  handleChange = field => event => {
    const { value } = event.target
    this.setState({
      [field]: value,
    })
  }

  handleClose = field => () => {
    this.setState({
      [field]: false,
    })
  }

  handleFilter = event => {
    event.preventDefault()
    const {
      props: { updateFilters },
      state: {
        selectedLocation,
        selectedDepartment,
        selectedFloor,
        selectedCabinet,
        selectedEquipmentStatus,
        selectedMarking,
        selectedFoundEquipment,
        serialNo,
        inventoryNo,
        nameFilter,
      },
    } = this
    const params = _.omitBy(
      {
        common_filter: nameFilter,
        serial_number: serialNo,
        article_number: inventoryNo,
        is_found:
          selectedFoundEquipment === 'all' ? '' : selectedFoundEquipment,
        is_marked: selectedMarking === 'all' ? '' : selectedMarking,
      },
      p => p === undefined || p === '',
    )
    const filters = _.omitBy(
      {
        status: selectedEquipmentStatus,
        organization_location_id: selectedLocation,
      },
      f => f === undefined || f === '',
    )
    const filtersConditional = {
      floor: selectedFloor,
      cabinet: selectedCabinet,
      organization_department_id: selectedDepartment,
    }
    _.forIn(filtersConditional, (value, key) => {
      if (value === 'notSpecified') {
        filters[key] = ''
      } else if (value !== '') {
        filters[key] = value
      }
    })
    updateFilters(params, filters)
  }

  render() {
    const {
      props: {
        classes,
        isFetchingFilters,
        filters: { locations, departments, floors, cabinets },
      },
      state: {
        locationsOpen,
        selectedLocation,
        departmentsOpen,
        selectedDepartment,
        floorsOpen,
        selectedFloor,
        cabinetsOpen,
        selectedCabinet,
        equipmentStatusOpen,
        selectedEquipmentStatus,
        markingOpen,
        selectedMarking,
        foundEquipmentOpen,
        selectedFoundEquipment,
        serialNo,
        inventoryNo,
        nameFilter,
      },
      handleOpen,
      handleChange,
      handleClose,
      markedEquipment,
      foundEquipment,
      handleFilter,
    } = this
    return (
      <Toolbar className={classes.root}>
        <form onSubmit={handleFilter}>
          <Grid
            container
            spacing={1}
            alignItems="stretch"
            justify="space-between"
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel className={classes.formLabel}>
                  {`${i18next.t('inventory_web_atoir:Address')}`}
                </FormLabel>
                <DropDownList
                  classes={classes}
                  value={selectedLocation}
                  open={locationsOpen}
                  handleOpen={handleOpen('locationsOpen')}
                  handleChange={handleChange('selectedLocation')}
                  handleClose={handleClose('locationsOpen')}
                  label={`${i18next.t('inventory_web_atoir:Address')}`}
                  elements={locations}
                  isFetching={isFetchingFilters}
                />
              </FormControl>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="space-between"
            >
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:Department')}`}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    value={selectedDepartment}
                    open={departmentsOpen}
                    handleOpen={handleOpen('departmentsOpen')}
                    handleChange={handleChange('selectedDepartment')}
                    handleClose={handleClose('departmentsOpen')}
                    label={`${i18next.t('inventory_web_atoir:Department')}`}
                    elements={departments}
                    isFetching={isFetchingFilters}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:Floor')}`}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    value={selectedFloor}
                    open={floorsOpen}
                    handleOpen={handleOpen('floorsOpen')}
                    handleChange={handleChange('selectedFloor')}
                    handleClose={handleClose('floorsOpen')}
                    label={`${i18next.t('inventory_web_atoir:Floor')}`}
                    elements={floors}
                    isFetching={isFetchingFilters}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:Cabinet')}`}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    value={selectedCabinet}
                    open={cabinetsOpen}
                    handleOpen={handleOpen('cabinetsOpen')}
                    handleChange={handleChange('selectedCabinet')}
                    handleClose={handleClose('cabinetsOpen')}
                    label={`${i18next.t('inventory_web_atoir:Cabinet')}`}
                    elements={cabinets}
                    isFetching={isFetchingFilters}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              direction="row"
              justify="space-between"
            >
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:equipmentStatus')}`}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    value={selectedEquipmentStatus}
                    open={equipmentStatusOpen}
                    handleOpen={handleOpen('equipmentStatusOpen')}
                    handleChange={handleChange('selectedEquipmentStatus')}
                    handleClose={handleClose('equipmentStatusOpen')}
                    label={`${i18next.t(
                      'inventory_web_atoir:equipmentStatus',
                    )}`}
                    elements={statuses}
                    isFetching={isFetchingFilters}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:Marking')}`}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    value={selectedMarking}
                    open={markingOpen}
                    handleOpen={handleOpen('markingOpen')}
                    handleChange={handleChange('selectedMarking')}
                    handleClose={handleClose('markingOpen')}
                    label={`${i18next.t('inventory_web_atoir:Marking')}`}
                    elements={markedEquipment}
                    isFetching={isFetchingFilters}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t(
                      'inventory_web_atoir:availabilityEquipment',
                    )}`}
                  </FormLabel>
                  <DropDownList
                    classes={classes}
                    value={selectedFoundEquipment}
                    open={foundEquipmentOpen}
                    handleOpen={handleOpen('foundEquipmentOpen')}
                    handleChange={handleChange('selectedFoundEquipment')}
                    handleClose={handleClose('foundEquipmentOpen')}
                    label={`${i18next.t(
                      'inventory_web_atoir:availabilityEquipment',
                    )}`}
                    elements={foundEquipment}
                    isFetching={isFetchingFilters}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              spacing={1}
              direction="row"
              justify="space-between"
            >
              <Grid item xs={3} spacing={1}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:serialNo')}`}
                  </FormLabel>
                  <TextField
                    id="serialNo"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange('serialNo')}
                    value={serialNo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} spacing={1}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:inventoryNo')}`}
                  </FormLabel>
                  <TextField
                    id="inventoryNo"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange('inventoryNo')}
                    value={inventoryNo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} spacing={1}>
                <FormControl fullWidth>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t('inventory_web_atoir:nameFilter')}`}
                  </FormLabel>
                  <TextField
                    id="nameFilter"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange('nameFilter')}
                    value={nameFilter}
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                xs={2}
                spacing={1}
                alignItems="center"
                justify="flex-end"
              >
                <ApplyButton type="submit">
                  {`${i18next.t('shared_web_atoir:buttonShow')}`}
                </ApplyButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Toolbar>
    )
  }
}

InventoryEquipmentFiltersToolbar.propTypes = {
  fetchInventoryEquipmentFilters: PropTypes.func.isRequired,
  isFetchingFilters: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
}

export default withStyles(styles)(InventoryEquipmentFiltersToolbar)
