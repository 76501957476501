import React from 'react'

const AppStateContext = React.createContext()
const AppDispatchContext = React.createContext()

const appOptions = {
  drawerMode: {
    common: 'common',
    collapsible: 'collapsible',
  },
}

const actionTypes = {
  SET_DRAWER_MODE: 'SET_DRAWER_MODE',
  SET_GLOBAL_SEARCH_SHOW: 'SET_GLOBAL_SEARCH_SHOW',
  SET_GLOBAL_SEARCH_STRING: 'SET_GLOBAL_SEARCH_STRING',
}

const appActions = {
  setDrawerMode: payload => ({
    type: actionTypes.SET_DRAWER_MODE,
    payload,
  }),
  setGlobalSearchShow: payload => ({
    type: actionTypes.SET_GLOBAL_SEARCH_SHOW,
    payload,
  }),
  setGlobalSearchString: payload => ({
    type: actionTypes.SET_GLOBAL_SEARCH_STRING,
    payload,
  }),
}

const defaultState = {
  drawerMode:
    localStorage.getItem('drawerMode') || appOptions.drawerMode.common,
  globalSearchShow: false,
  globalSearchString: null,
}

function appReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_DRAWER_MODE: {
      localStorage.setItem('drawerMode', action.payload)
      return {
        ...state,
        drawerMode: action.payload,
      }
    }
    case actionTypes.SET_GLOBAL_SEARCH_SHOW: {
      return {
        ...state,
        globalSearchShow: action.payload,
      }
    }
    case actionTypes.SET_GLOBAL_SEARCH_STRING: {
      return {
        ...state,
        globalSearchString: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(appReducer, defaultState)
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }
  return context
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }
  return context
}

export { AppProvider, useAppState, useAppDispatch, appOptions, appActions }
