import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import i18next from '../../../../i18n/i18n'
import { getUserFullName } from '../../../../helpers/userHelper'
import DateInput from '../../../../widgets/DateInput/index'
import { URL } from '../../../../middlewares/api'
import SelectLimitedFromControl from '../../../../widgets/SelectLimitedFormControl'
import { sortStringify } from '../../../../helpers/queryHelper'

const styles = theme => ({
  divErrorOut: {
    minHeight: theme.spacing(3),
  },
  divFullWidth: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
})

class ChoosingSpecialistForm extends Component {
  componentDidMount = () => {}

  toggle = (field, status) => () => {
    this.setState({ [field]: status })
  }

  render() {
    const {
      props: {
        onChangeEmployee,
        onChangeDate,
        onChangeComment,
        employee,
        comment,
        date,
        errors,
        classes,
        organizationId,
      },
    } = this
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={classes.divFullWidth}>
            {/* TODO complete */}
            <SelectLimitedFromControl
              FormControlProps={{
                fullWidth: true,
                className: classes.formControl,
                error: errors.employee,
              }}
              label={
                errors.employee ||
                i18next.t('shared_web_atoir:specialistFullName')
              }
              value={employee}
              sourceUrl={`${URL}/organizations/${organizationId}/users/only_binded`}
              query={{
                ...sortStringify({
                  last_name: 'ASC',
                  first_name: 'ASC',
                  middle_name: 'ASC',
                }),
                filters: '[state=1,is_plain_user=true]',
              }}
              onChange={id => onChangeEmployee({ target: { value: id } })}
              requestResultTransformerToArray={r => r.users}
              itemTransformer={l => ({ id: l.id, title: getUserFullName(l) })}
            />
            <div className={classes.divErrorOut} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.divFullWidth}>
            <DateInput
              label={i18next.t('service_desk_web_atoir:dateVisit')}
              dateValid={errors.date === undefined}
              disablePast
              value={date}
              handleChange={onChangeDate}
              helperText={errors.date ? errors.date : null}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={i18next.t('service_desk_web_atoir:dateVisitComment')}
            variant="outlined"
            value={comment}
            onChange={onChangeComment}
          />
        </Grid>
      </Grid>
    )
  }
}

ChoosingSpecialistForm.defaultProps = {
  comment: '',
  employee: null,
  date: null,
  errors: {},
}

ChoosingSpecialistForm.propTypes = {
  onChangeDate: PropTypes.func.isRequired,
  onChangeEmployee: PropTypes.func.isRequired,
  onChangeComment: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  comment: PropTypes.string,
  date: PropTypes.object,
  employee: PropTypes.object,
  errors: PropTypes.object,
}

export default withStyles(styles)(ChoosingSpecialistForm)
