import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { popLastSections } from '../../../helpers/dataHelper'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import i18next from '../../../i18n/i18n'
import BreadCrumb from '../../App/routing/BreadCrumb'

import AutomaticScripts from './AutomaticScripts'
import Tags from './Tags'
import EmailTemplates from './EmailTemplates'
import RecurringCalls from './RecurringCalls'
import ActionsButtons from './ActionsButtons'
import Contragents from './Contragents'

const styles = theme => ({
  marginBtm: {
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
})

class SettingsList extends Component {
  componentDidMount = () => {
    const {
      props: {
        match: {
          params: { organizationId },
        },
        setNavigationDrawer,
        fetchOrganization,
      },
    } = this
    setNavigationDrawer([
      {
        title: i18next.t('organizations_web_atoir:automaticScripts'),
        anchor: '#organization-settings-automatic-scripts',
      },
      {
        title: i18next.t('organizations_web_atoir:tags'),
        anchor: '#organization-settings-tags',
      },
      {
        title: i18next.t('organizations_web_atoir:letterTemplates'),
        anchor: '#organization-settings-letter-templates',
      },
      {
        title: i18next.t('organizations_web_atoir:ActionsButtons'),
        anchor: '#organization-settings-actions-buttons',
      },
      {
        title: i18next.t('organizations_web_atoir:contragents'),
        anchor: 'organization-settings-contragents',
      },
    ])
    fetchOrganization(organizationId)
  }

  componentWillUnmount() {
    this.props.clearNavigationDrawer()
  }

  render() {
    const {
      props: {
        match,
        match: {
          params: { organizationId },
        },
        classes,
        organization,
      },
    } = this
    return (
      <Fragment>
        <BreadCrumb length={29} to={`${popLastSections(match.url)}/profile`}>
          {organization.short_name}
        </BreadCrumb>
        <BreadCrumb to={match.url}>
          {i18next.t('organizations_web_atoir:organizationSettings')}
        </BreadCrumb>
        <div>
          <Typography variant="h4" gutterBottom className={classes.marginBtm}>
            {i18next.t('organizations_web_atoir:organizationSettingsList')}
          </Typography>
          <AutomaticScripts />
          <Tags />
          <EmailTemplates />
          <ActionsButtons />
          <RecurringCalls />
          <Contragents organizationId={organizationId} />
        </div>
      </Fragment>
    )
  }
}

SettingsList.propTypes = {
  setNavigationDrawer: PropTypes.func.isRequired,
  clearNavigationDrawer: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
}

export default withStyles(styles)(SettingsList)
