import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SignInFormComponent from './SignInFormComponent'
import { login } from '../../../actions/loginActions'
import {
  repairPassword,
  repairPasswordCancel,
} from '../../../actions/repairPasswordActions'
import {
  clearValidationErrors,
  getConfirmLinkAgain,
} from '../../../actions/registerAccountActions'
import { showDialogWithOk } from '../../../actions/appActions'

const mapStateToProps = state => ({
  isRequestingAccessData: state.login.isRequestingAccessData,
  isRepairingPassword: state.repairPassword.isRepairingPassword,
  isRegisteringEmail: state.registerAccount.isRegisteringEmail,
  shouldResendLink: state.repairPassword.shouldResendLink,
  validationErrors: state.login.validationErrors,
  repairPasswordErrors: state.repairPassword.repairPasswordErrors,
  sendLinkAgainErrors: state.registerAccount.validationErrors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      repairPassword,
      clearValidationErrors,
      getConfirmLinkAgain,
      repairPasswordCancel,
      showDialogWithOk,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignInFormComponent),
)
