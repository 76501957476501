import React, { Component, Fragment } from 'react'
import isEmpty from 'is-empty'

import { withStyles } from '@material-ui/core/styles/index'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import i18next from '../../../../../../i18n/i18n'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  section: {
    marginBottom: '40px',
  },
  linkText: {
    color: theme.palette.textSecondary.main,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
  sectionContainer: {
    marginBottom: '20px',
  },
})

class ConfirmInfo extends Component {
  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  render() {
    const {
      props: { engineers, classes, handleChange, performerUserId, error },
    } = this
    const noEngineersText =
      `${i18next.t('inventory_web_atoir:dataSpecialistsNumberOutfits')}: ` +
      `${i18next.t('inventory_web_atoir:noAppointed')}`
    return (
      <Fragment>
        <Typography variant="subheading" align="left">
          {i18next.t('inventory_web_atoir:listSpecialistsEngagedProject')}
        </Typography>
        <FormControl error={error}>
          {isEmpty(engineers) ? (
            <Typography align="left" variant="body2">
              {noEngineersText}
            </Typography>
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    {i18next.t('user_profile_web_atoir:userFullName')}
                  </TableCell>
                  <TableCell>
                    {i18next.t('organizations_web_atoir:contactPhone')}
                  </TableCell>
                  <TableCell>
                    {i18next.t('inventory_web_atoir:numberOutfits')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {engineers.map(_engineer => {
                  const middleName =
                    _engineer.middle_name === 'none'
                      ? ''
                      : _engineer.middle_name
                  const phone = isEmpty(_engineer.phone)
                    ? i18next.t('user_profile_web_atoir:noData')
                    : _engineer.phone
                  return (
                    <TableRow key={_engineer.id}>
                      <TableCell padding="checkbox">
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <RadioGroup
                            aria-label="performerUserId"
                            name="performerUserId"
                            className={classes.group}
                            value={performerUserId}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value={_engineer.id}
                              control={<Radio color="primary" />}
                              label=""
                            />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {_engineer.last_name} {_engineer.first_name}{' '}
                        {middleName}
                      </TableCell>
                      <TableCell>{phone}</TableCell>
                      <TableCell>
                        {_engineer.IPJCountAll - _engineer.IPJCountDone}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
          <FormHelperText id="name-error-text">
            {error &&
              i18next.t('validation_web_atoir:inventorySetEngineersInOrder')}
          </FormHelperText>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ConfirmInfo)
