import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { spacing } from '@material-ui/system'
import i18next from '../../../../../../i18n/i18n'
import Loader from '../../../../../Loader'

const WorkRoot = styled.div`
  ${spacing}
`

function Work({ work, work: { BAEquipmentTOWorksParts: parts } }) {
  return (
    <WorkRoot mb={2}>
      <Typography gutterBottom>
        {work.name} {' | '}
        {i18next.t('equipment_web_atoir:equipmentTOWorkWorkingHours', {
          hours: work.working_hours || 0,
        })}
      </Typography>
      <Fragment>
        {parts
          .filter(part => part.part)
          .map(part => (
            <Typography color="textSecondary">
              {part.part.name}
              {', '}
              {i18next.t('equipment_web_atoir:toWorkPartCount', {
                unit: i18next.t(
                  `equipment_web_atoir:${part.part.units}ToWorkPartCountUnit`,
                ),
                count: part.count || 0,
              })}
            </Typography>
          ))}
      </Fragment>
    </WorkRoot>
  )
}

export default function({
  id,
  organizationId,
  equipmentId,
  fetchOrganizationEquipmentTOWorks,
  clearOrganizationEquipmentTOWorks,
  isFetching,
  works,
}) {
  useEffect(() => {
    fetchOrganizationEquipmentTOWorks({ id, equipmentId, organizationId })
    return () => clearOrganizationEquipmentTOWorks()
  }, [])

  return (
    <div>
      {isFetching ? <Loader /> : null}
      {!isFetching && works ? works.map(work => <Work work={work} />) : null}
    </div>
  )
}
