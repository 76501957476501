import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FixedSizeList } from 'react-window'

import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
// material

import Select from '@material-ui/core/Select'
// modules
import queryString from 'query-string'
import { fetchProxy } from '../../middlewares/api'
import Popover from '@material-ui/core/Popover/Popover'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Loader from '../../components/Loader'

const styles = {
  menuItemElement: {
    minHeight: 36,
  },
}

class SelectLimitedAuto extends React.Component {
  constructor(props) {
    super(props)
    this.listEl = React.createRef()
    this.listContainerRef = React.createRef()
    this.selectRef = React.createRef()
    const { limit, offset, firstElement } = props
    this.itemTransformer = item => {
      if (item) {
        return item.selectLimitedAutoEmptyMarker
          ? item
          : props.itemTransformer(item)
      }
      return {
        id: null,
        title: '',
      }
    }
    this.state = {
      open: false,
      limit,
      offset,
      data: firstElement
        ? [
            {
              selectLimitedAutoEmptyMarker: true,
              id: 'first-element-select-limited',
              title: firstElement.title || '',
            },
          ]
        : [],
      end: false,
      firstFetchDone: false,
      anchorEl: null,
      _width: 0,
    }
  }

  componentDidMount = () => {
    const {
      fetchMore,
      props: { ref, createRef, initValue },
      onChange,
      itemTransformer,
    } = this

    const continueForInitValue = () => {
      const result = this.state.data.find(
        item => itemTransformer(item).title === initValue,
      )
      if (result) {
        onChange({ target: { value: result.id } })
      }
      if (!this.state.end && !result) {
        fetchMore(continueForInitValue)
      }
    }

    fetchMore(initValue ? continueForInitValue : undefined)
    if (ref) {
      ref(this)
    }
    if (createRef) {
      createRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sourceUrl !== this.props.sourceUrl ||
      !_.isEqual(prevProps.query, this.props.query)
    ) {
      this.reset()
    }
  }

  reset = () => {
    this.setState(
      {
        open: false,
        limit: this.props.limit,
        offset: this.props.offset,
        data: [],
        end: false,
      },
      this.fetchMore,
    )
  }

  makeRequest = async () => {
    const {
      state: { limit, offset },
      props: { sourceUrl, query },
    } = this
    const request = await fetchProxy(
      `${sourceUrl}?${queryString.stringify({ limit, offset, ...query })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return request.json()
  }

  fetchMore = (callbackOnDone = () => {}) => {
    const {
      state: { data, firstFetchDone },
      props,
      props: {
        requestResultTransformerToArray,
        isSuccessResponse,
        onError,
        sourceUrl,
        dataUpdatedTransformer,
        autoSetOnOneValue,
      },
      itemTransformer,
    } = this
    if (!sourceUrl) {
      return
    }
    this.setState(
      {
        isFetching: true,
      },
      async () => {
        const result = await this.makeRequest()
        if (isSuccessResponse(result)) {
          const newElements = requestResultTransformerToArray(result)
          if (newElements.length === 0) {
            this.setState(
              {
                firstFetchDone: true,
                end: true,
                isFetching: false,
              },
              () => {
                callbackOnDone()
              },
            )

            return
          }
          if (
            !firstFetchDone &&
            newElements.length === 1 &&
            autoSetOnOneValue
          ) {
            props.onChange(
              props.itemTransformer(newElements[0]).id,
              newElements[0],
            )
          }

          this.setState(
            state => ({
              firstFetchDone: true,
              data: dataUpdatedTransformer([
                ...data,
                ..._.differenceBy(
                  _.uniqBy(
                    newElements.map(i => ({ o: i, c: itemTransformer(i) })),
                    'c.id',
                  ),
                  data.map(i => ({ o: i, c: itemTransformer(i) })),
                  'c.id',
                ).map(i => i.o),
              ]),
              offset:
                state.offset + requestResultTransformerToArray(result).length,
              isFetching: false,
            }),
            () => {
              callbackOnDone()
            },
          )
        } else {
          onError(result)
          this.setState({
            isFetching: false,
          })
        }
      },
    )
  }

  handlePopoverClose = () => {
    this.handleSelectClose()
  }

  handleSelectOpen = event => {
    event.preventDefault()
    const {
      selectRef,
      props: { width, minWidth },
    } = this
    this.setState({
      open: true,
      _width:
        width || selectRef.current.offsetWidth > minWidth
          ? selectRef.current.offsetWidth
          : minWidth,
      anchorEl: event.currentTarget,
    })
  }

  handleSelectClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  onChange = id => {
    const {
      props,
      state: { data },
      handleSelectClose,
    } = this
    if (id === 'first-element-select-limited') {
      props.onChange(props.firstElement.id, props.firstElement)
    } else {
      props.onChange(
        id,
        data.find(l => props.itemTransformer(l).id === id),
      )
    }

    handleSelectClose()
  }

  handleScroll = event => {
    const {
      props: { onEndOfScroll, height },
      state: { isFetching, end },
      fetchMore,
      listEl,
    } = this

    const { scrollOffset } = event
    if (listEl.current.scrollHeight - scrollOffset - height <= height / 2) {
      onEndOfScroll()
      if (!end && !isFetching) {
        fetchMore()
      }
    }
  }

  onListOpened = () => {
    const {
      listContainerRef,
      props: { value },
      state: { data },
    } = this
    if (value) {
      listContainerRef.current.scrollToItem(
        data.findIndex(item => item.id === value),
        'center',
      )
    }
  }

  renderRow = props => {
    const { index, style } = props
    const {
      props: { value, firstElement, classes },
      state: { data, isFetching },
      onChange,
      itemTransformer,
    } = this
    const getId = () =>
      index === 0 && firstElement ? data[0].id : itemTransformer(data[index]).id

    const getTitle = () =>
      index === 0 && firstElement
        ? data[index].title
        : itemTransformer(data[index]).title
    return (
      <React.Fragment>
        {isFetching && index === data.length ? (
          <ListItem style={style} key="loader">
            <Loader size={20} />
          </ListItem>
        ) : (
          <ListItem
            className={classes.menuItemElement}
            button
            style={style}
            key={index}
            data-cy={getTitle()}
            onClick={() => {
              onChange(getId())
            }}
            selected={getId() === value}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={getTitle()}
            />
          </ListItem>
        )}
      </React.Fragment>
    )
  }

  render = () => {
    const {
      state: { data, open, isFetching, anchorEl, _width },
      props: { value, selectProps, inputProps, disabled, id, height, itemSize },
      handleSelectOpen,
      handlePopoverClose,
      handleScroll,
      onListOpened,
      renderRow,
      itemTransformer,
    } = this
    return (
      <React.Fragment>
        <Select
          disabled={disabled}
          open={false}
          ref={this.selectRef}
          onOpen={handleSelectOpen}
          value={value}
          inputProps={{
            name: 'SelectLimitedAuto',
            id,
            ...inputProps,
          }}
          {...selectProps}
        >
          {value && (
            <MenuItem value={value}>
              {
                itemTransformer(
                  data.find(item => itemTransformer(item).id === value),
                ).title
              }
            </MenuItem>
          )}
        </Select>
        <Popover
          id="simple-popper"
          open={open}
          onClose={handlePopoverClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {data.length * itemSize > height ? (
            <MountDetector onOpened={onListOpened}>
              <FixedSizeList
                innerRef={this.listEl}
                ref={this.listContainerRef}
                onScroll={handleScroll}
                height={height}
                width={_width}
                itemSize={46}
                itemCount={isFetching ? data.length + 1 : data.length}
              >
                {renderRow}
              </FixedSizeList>
            </MountDetector>
          ) : (
            <div style={{ width: _width }}>
              {data.map((item, index) => renderRow({ index }))}
              {isFetching && (
                <ListItem>
                  <Loader size={20} />
                </ListItem>
              )}
            </div>
          )}
        </Popover>
      </React.Fragment>
    )
  }
}

SelectLimitedAuto.defaultProps = {
  itemSize: 46,
  height: 400,
  width: null,
  minWidth: null,
  onEndOfScroll: () => {},
  limit: 30,
  offset: 0,
  value: '',
  firstElement: null,
  selectProps: {},
  inputProps: {},
  menuListProps: {},
  menuListStyleProps: {},
  query: {},
  ref: () => {},
  createRef: () => {},
  onError: () => {},
  dataUpdatedTransformer: data => data,
  isSuccessResponse: r => r.success,
  sourceUrl: null,
  itemRender: null,
  disabled: false,
  id: 'selectLimited-auto',
  initValue: null,
  autoSetOnOneValue: false,
}

SelectLimitedAuto.propTypes = {
  onEndOfScroll: PropTypes.func,
  itemSize: PropTypes.number,
  limit: PropTypes.number,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  height: PropTypes.number,
  offset: PropTypes.number,
  sourceUrl: PropTypes.string,
  isSuccessResponse: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  requestResultTransformerToArray: PropTypes.func.isRequired,
  itemTransformer: PropTypes.func.isRequired,
  ref: PropTypes.func,
  createRef: PropTypes.func,
  dataUpdatedTransformer: PropTypes.func,
  value: PropTypes.any,
  query: PropTypes.object,
  firstElement: PropTypes.object,
  selectProps: PropTypes.object,
  inputProps: PropTypes.object,
  menuListProps: PropTypes.object,
  menuListStyleProps: PropTypes.object,
  itemRender: PropTypes.element,
  disabled: PropTypes.bool,
  autoSetOnOneValue: PropTypes.bool,
  id: PropTypes.string,
  initValue: PropTypes.string,
}

export default withStyles(styles)(SelectLimitedAuto)

const MountDetector = function({ children, onOpened }) {
  React.useEffect(() => {
    onOpened()
  }, [])
  return <React.Fragment>{children}</React.Fragment>
}
