export const FETCH_CONTRACTS_REQUEST = 'serviceDesk/FETCH_CONTRACTS_REQUEST'
export const FETCH_CONTRACTS_SUCCESS = 'serviceDesk/FETCH_CONTRACTS_SUCCESS'
export const FETCH_CONTRACTS_FAILURE = 'serviceDesk/FETCH_CONTRACTS_FAILURE'

export const FETCH_CONTRACT_PERMISSIONS_REQUEST =
  'serviceDesk/FETCH_CONTRACT_PERMISSIONS_REQUEST'
export const FETCH_CONTRACT_PERMISSIONS_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_PERMISSIONS_SUCCESS'
export const FETCH_CONTRACT_PERMISSIONS_FAILURE =
  'serviceDesk/FETCH_CONTRACT_PERMISSIONS_FAILURE'

export const FETCH_CONTRACT_REQUEST = 'serviceDesk/FETCH_CONTRACT_REQUEST'
export const FETCH_CONTRACT_SUCCESS = 'serviceDesk/FETCH_CONTRACT_SUCCESS'
export const FETCH_CONTRACT_FAILURE = 'serviceDesk/FETCH_CONTRACT_FAILURE'

export const CANCEL_CONTRACT_REQUEST = 'serviceDesk/CANCEL_CONTRACT_REQUEST'
export const CANCEL_CONTRACT_SUCCESS = 'serviceDesk/CANCEL_CONTRACT_SUCCESS'
export const CANCEL_CONTRACT_FAILURE = 'serviceDesk/CANCEL_CONTRACT_FAILURE'

export const FETCH_CONTRACT_LOGS_REQUEST =
  'serviceDesk/FETCH_CONTRACT_LOGS_REQUEST'
export const FETCH_CONTRACT_LOGS_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_LOGS_SUCCESS'
export const FETCH_CONTRACT_LOGS_FAILURE =
  'serviceDesk/FETCH_CONTRACT_LOGS_FAILURE'

export const FETCH_CONTRACT_LOGS_COUNT_REQUEST =
  'serviceDesk/FETCH_CONTRACT_LOGS_COUNT_REQUEST'
export const FETCH_CONTRACT_LOGS_COUNT_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_LOGS_COUNT_SUCCESS'
export const FETCH_CONTRACT_LOGS_COUNT_FAILURE =
  'serviceDesk/FETCH_CONTRACT_LOGS_COUNT_FAILURE'

export const FETCH_AGREEMENT_LOGS_REQUEST =
  'serviceDesk/FETCH_AGREEMENT_LOGS_REQUEST'
export const FETCH_AGREEMENT_LOGS_SUCCESS =
  'serviceDesk/FETCH_AGREEMENT_LOGS_SUCCESS'
export const FETCH_AGREEMENT_LOGS_FAILURE =
  'serviceDesk/FETCH_AGREEMENT_LOGS_FAILURE'

export const FETCH_AGREEMENT_LOGS_COUNT_REQUEST =
  'serviceDesk/FETCH_AGREEMENT_LOGS_COUNT_REQUEST'
export const FETCH_AGREEMENT_LOGS_COUNT_SUCCESS =
  'serviceDesk/FETCH_AGREEMENT_LOGS_COUNT_SUCCESS'
export const FETCH_AGREEMENT_LOGS_COUNT_FAILURE =
  'serviceDesk/FETCH_AGREEMENT_LOGS_COUNT_FAILURE'

export const ASSIGN_CONTRACT_REQUEST = 'serviceDesk/ASSIGN_CONTRACT_REQUEST'
export const ASSIGN_CONTRACT_SUCCESS = 'serviceDesk/ASSIGN_CONTRACT_SUCCESS'
export const ASSIGN_CONTRACT_FAILURE = 'serviceDesk/ASSIGN_CONTRACT_FAILURE'

export const ACCEPT_CONTRACT_REQUEST = 'serviceDesk/ACCEPT_CONTRACT_REQUEST'
export const ACCEPT_CONTRACT_SUCCESS = 'serviceDesk/ACCEPT_CONTRACT_SUCCESS'
export const ACCEPT_CONTRACT_FAILURE = 'serviceDesk/ACCEPT_CONTRACT_FAILURE'

export const REJECT_CONTRACT_REQUEST = 'serviceDesk/REJECT_CONTRACT_REQUEST'
export const REJECT_CONTRACT_SUCCESS = 'serviceDesk/REJECT_CONTRACT_SUCCESS'
export const REJECT_CONTRACT_FAILURE = 'serviceDesk/REJECT_CONTRACT_FAILURE'

export const FETCH_CONTRACT_EQUIPMENT_REQUEST =
  'serviceDesk/FETCH_CONTRACT_EQUIPMENT_REQUEST'
export const FETCH_CONTRACT_EQUIPMENT_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_EQUIPMENT_SUCCESS'
export const FETCH_CONTRACT_EQUIPMENT_FAILURE =
  'serviceDesk/FETCH_CONTRACT_EQUIPMENT_FAILURE'

export const FETCH_AGREEMENTS_REQUEST = 'serviceDesk/FETCH_AGREEMENTS_REQUEST'
export const FETCH_AGREEMENTS_SUCCESS = 'serviceDesk/FETCH_AGREEMENTS_SUCCESS'
export const FETCH_AGREEMENTS_FAILURE = 'serviceDesk/FETCH_AGREEMENTS_FAILURE'

export const FETCH_AGREEMENTS_COUNT_REQUEST =
  'serviceDesk/FETCH_AGREEMENTS_COUNT_REQUEST'
export const FETCH_AGREEMENTS_COUNT_SUCCESS =
  'serviceDesk/FETCH_AGREEMENTS_COUNT_SUCCESS'
export const FETCH_AGREEMENTS_COUNT_FAILURE =
  'serviceDesk/FETCH_AGREEMENTS_COUNT_FAILURE'

export const CANCEL_AGREEMENT_REQUEST = 'serviceDesk/CANCEL_AGREEMENT_REQUEST'
export const CANCEL_AGREEMENT_SUCCESS = 'serviceDesk/CANCEL_AGREEMENT_SUCCESS'
export const CANCEL_AGREEMENT_FAILURE = 'serviceDesk/CANCEL_AGREEMENT_FAILURE'

export const ACCEPT_AGREEMENT_REQUEST = 'serviceDesk/ACCEPT_AGREEMENT_REQUEST'
export const ACCEPT_AGREEMENT_SUCCESS = 'serviceDesk/ACCEPT_AGREEMENT_SUCCESS'
export const ACCEPT_AGREEMENT_FAILURE = 'serviceDesk/ACCEPT_AGREEMENT_FAILURE'

export const REJECT_AGREEMENT_REQUEST = 'serviceDesk/REJECT_AGREEMENT_REQUEST'
export const REJECT_AGREEMENT_SUCCESS = 'serviceDesk/REJECT_AGREEMENT_SUCCESS'
export const REJECT_AGREEMENT_FAILURE = 'serviceDesk/REJECT_AGREEMENT_FAILURE'

export const ASSIGN_AGREEMENT_REQUEST = 'serviceDesk/ASSIGN_AGREEMENT_REQUEST'
export const ASSIGN_AGREEMENT_SUCCESS = 'serviceDesk/ASSIGN_AGREEMENT_SUCCESS'
export const ASSIGN_AGREEMENT_FAILURE = 'serviceDesk/ASSIGN_AGREEMENT_FAILURE'

export const ADD_AGREEMENT_EQUIPMENT_REQUEST =
  'serviceDesk/ADD_AGREEMENT_EQUIPMENT_REQUEST '
export const ADD_AGREEMENT_EQUIPMENT_SUCCESS =
  'serviceDesk/ADD_AGREEMENT_EQUIPMENT_SUCCESS'
export const ADD_AGREEMENT_EQUIPMENT_FAILURE =
  'serviceDesk/ADD_AGREEMENT_EQUIPMENT_FAILURE'

export const FETCH_AGREEMENT_EQUIPMENT_REQUEST =
  'serviceDesk/FETCH_AGREEMENT_EQUIPMENT_REQUEST'
export const FETCH_AGREEMENT_EQUIPMENT_SUCCESS =
  'serviceDesk/FETCH_AGREEMENT_EQUIPMENT_SUCCESS'
export const FETCH_AGREEMENT_EQUIPMENT_FAILURE =
  'serviceDesk/FETCH_AGREEMENT_EQUIPMENT_FAILURE'

export const FETCH_AGREEMENT_EQUIPMENT_COUNT_REQUEST =
  'serviceDesk/FETCH_AGREEMENT_EQUIPMENT_COUNT_REQUEST'
export const FETCH_AGREEMENT_EQUIPMENT_COUNT_SUCCESS =
  'serviceDesk/FETCH_AGREEMENT_EQUIPMENT_COUNT_SUCCESS'
export const FETCH_AGREEMENT_EQUIPMENT_COUNT_FAILURE =
  'serviceDesk/FETCH_AGREEMENT_EQUIPMENT_COUNT_FAILURE'

export const DELETE_AGREEMENT_EQUIPMENT_REQUEST =
  'serviceDesk/DELETE_AGREEMENT_EQUIPMENT_REQUEST'
export const DELETE_AGREEMENT_EQUIPMENT_SUCCESS =
  'serviceDesk/DELETE_AGREEMENT_EQUIPMENT_SUCCESS'
export const DELETE_AGREEMENT_EQUIPMENT_FAILURE =
  'serviceDesk/DELETE_AGREEMENT_EQUIPMENT_FAILURE'

export const DELETE_CONTRACT_EQUIPMENT_REQUEST =
  'serviceDesk/DELETE_CONTRACT_EQUIPMENT_REQUEST'
export const DELETE_CONTRACT_EQUIPMENT_SUCCESS =
  'serviceDesk/DELETE_CONTRACT_EQUIPMENT_SUCCESS'
export const DELETE_CONTRACT_EQUIPMENT_FAILURE =
  'serviceDesk/DELETE_CONTRACT_EQUIPMENT_FAILURE'

export const FETCH_CONTRACT_EQUIPMENT_COUNT_REQUEST =
  'serviceDesk/FETCH_CONTRACT_EQUIPMENT_COUNT_REQUEST'
export const FETCH_CONTRACT_EQUIPMENT_COUNT_SUCCESS =
  'serviceDesk/FETCH_CONTRACT_EQUIPMENT_COUNT_SUCCESS'
export const FETCH_CONTRACT_EQUIPMENT_COUNT_FAILURE =
  'serviceDesk/FETCH_CONTRACT_EQUIPMENT_COUNT_FAILURE'

export const EDIT_CONTRACT_REQUEST = 'serviceDesk/EDIT_CONTRACT_REQUEST'
export const EDIT_CONTRACT_SUCCESS = 'serviceDesk/EDIT_CONTRACT_SUCCESS'
export const EDIT_CONTRACT_FAILURE = 'serviceDesk/EDIT_CONTRACT_FAILURE'

export const ASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST =
  'serviceDesk/ASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST'
export const ASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS =
  'serviceDesk/ASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS'
export const ASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE =
  'serviceDesk/ASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE'

export const UNASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST =
  'serviceDesk/UNASSIGN_ENGINEER_TO_EQUIPMENTS_REQUEST'
export const UNASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS =
  'serviceDesk/UNASSIGN_ENGINEER_TO_EQUIPMENTS_SUCCESS'
export const UNASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE =
  'serviceDesk/UNASSIGN_ENGINEER_TO_EQUIPMENTS_FAILURE'

export const EDIT_CONTRACT_RESPONSIBLE_REQUEST =
  'serviceDesk/EDIT_CONTRACT_RESPONSIBLE_REQUEST'
export const EDIT_CONTRACT_RESPONSIBLE_SUCCESS =
  'serviceDesk/EDIT_CONTRACT_RESPONSIBLE_SUCCESS'
export const EDIT_CONTRACT_RESPONSIBLE_FAILURE =
  'serviceDesk/EDIT_CONTRACT_RESPONSIBLE_FAILURE'

export const FETCH_FILTERING_ORGANIZATIONS_REQUEST =
  'serviceDesk/FETCH_FILTERING_ORGANIZATIONS_REQUEST'
export const FETCH_FILTERING_ORGANIZATIONS_SUCCESS =
  'serviceDesk/FETCH_FILTERING_ORGANIZATIONS_SUCCESS'
export const FETCH_FILTERING_ORGANIZATIONS_FAILURE =
  'serviceDesk/FETCH_FILTERING_ORGANIZATIONS_FAILURE'

export const FETCH_CONTRACTS_COUNT_REQUEST =
  'serviceDesk/FETCH_CONTRACTS_COUNT_REQUEST'
export const FETCH_CONTRACTS_COUNT_SUCCESS =
  'serviceDesk/FETCH_CONTRACTS_COUNT_SUCCESS'
export const FETCH_CONTRACTS_COUNT_WITH_FILTERS_SUCCESS =
  'serviceDesk/FETCH_CONTRACTS_COUNT_WITH_FILTERS_SUCCESS'
export const FETCH_CONTRACTS_COUNT_WITH_FILTERS_FAILURE =
  'serviceDesk/FETCH_CONTRACTS_COUNT_WITH_FILTERS_FAILURE'
export const FETCH_CONTRACTS_COUNT_FAILURE =
  'serviceDesk/FETCH_CONTRACTS_COUNT_FAILURE'

export const CREATE_AGREEMENT_REQUEST = 'serviceDesk/CREATE_AGREEMENT_REQUEST'
export const CREATE_AGREEMENT_SUCCESS = 'serviceDesk/CREATE_AGREEMENT_SUCCESS'
export const CREATE_AGREEMENT_FAILURE = 'serviceDesk/CREATE_AGREEMENT_FAILURE'

export const FETCH_AGREEMENT_REQUEST = 'serviceDesk/FETCH_AGREEMENT_REQUEST'
export const FETCH_AGREEMENT_SUCCESS = 'serviceDesk/FETCH_AGREEMENT_SUCCESS'
export const FETCH_AGREEMENT_FAILURE = 'serviceDesk/FETCH_AGREEMENT_FAILURE'

export const EDIT_AGREEMENT_NAME_REQUEST =
  'serviceDesk/EDIT_AGREEMENT_NAME_REQUEST'
export const EDIT_AGREEMENT_NAME_SUCCESS =
  'serviceDesk/EDIT_AGREEMENT_NAME_SUCCESS'
export const EDIT_AGREEMENT_NAME_FAILURE =
  'serviceDesk/EDIT_AGREEMENT_NAME_FAILURE'

export const SET_EQUIPMENTS_ROWS_PER_PAGE =
  'serviceDesk/SET_EQUIPMENTS_ROWS_PER_PAGE'
export const SET_ADD_EQUIPMENTS_ROWS_PER_PAGE =
  'serviceDesk/SET_ADD_EQUIPMENTS_ROWS_PER_PAGE'
