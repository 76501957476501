import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InventoryPerformerInfo from './InventoryPerformerInfo'

const mapStateToProps = state => ({
  inventoryProject: state.inventory.project,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryPerformerInfo),
)
