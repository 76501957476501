import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// actions
import {
  // fetchServiceDeskCallOrders,
  fetchActiveServiceCallOrdersRequest,
  fetchClosedServiceCallOrdersRequest,
  fetchCanceledServiceCallOrdersRequest,
} from '../../../../../actions/serviceDesk'
// component
import OrdersList from './OrdersList'

const mapStateToProps = state => ({
  isFetchingActive: state.serviceDesk.activeServiceCallOrders.isFetching,
  activeOrders: state.serviceDesk.activeServiceCallOrders.orders,
  fetchActiveError: state.serviceDesk.activeServiceCallOrders.error,
  isFetchingClosed: state.serviceDesk.closedServiceCallOrders.isFetching,
  closedOrders: state.serviceDesk.closedServiceCallOrders.orders,
  fetchClosedError: state.serviceDesk.closedServiceCallOrders.error,
  isFetchingCanceled: state.serviceDesk.canceledServiceCallOrders.isFetching,
  canceledOrders: state.serviceDesk.canceledServiceCallOrders.orders,
  fetchCanceledError: state.serviceDesk.canceledServiceCallOrders.error,
  isChangingOrderClientPerformerResponsible:
    state.serviceDesk.changeClientResponsible.isFetching,
  isChangingOrderPerformerResponsible:
    state.serviceDesk.changePerformerResponsible.isFetching,
  isChangingOrderEngineer: state.serviceDesk.changeEngineer.isFetching,
  isEditingOrderInfo: state.serviceDesk.editOrderInfo.isFetching,
  isAddingOrderWorkCompletionFile:
    state.serviceDesk.workCompletionFile.isFetching,
  isDeletingOrderWorkCompletionFile:
    state.serviceDesk.workCompletionFileDelete.isFetching,
  isSavingOrderItems: state.serviceDesk.saveOrderItemsList.isSaving,
  savedOrderItemsSuccessfully:
    state.serviceDesk.saveOrderItemsList.savedSuccessfully,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActiveServiceCallOrdersRequest,
      fetchClosedServiceCallOrdersRequest,
      fetchCanceledServiceCallOrdersRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrdersList),
)
