import React from 'react'

import Box from '@material-ui/core/Box'

import RoleEditor from './RoleEditor'
import DeleteContragentButton from './DeleteContragentButton'

const ContragentToolBox = ({
  deleteContragent,
  editContragent,
  organizationId,
  contragent,
}) => {
  const deleteContragentHandler = () => {
    deleteContragent({ id: organizationId, contragent_id: contragent.id })
  }
  const editContragentHandler = body => {
    editContragent({ id: organizationId, contragent_id: contragent.id, body })
  }
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <RoleEditor edit={editContragentHandler} contragent={contragent} />
      <DeleteContragentButton onClick={deleteContragentHandler} />
    </Box>
  )
}

export default ContragentToolBox
