import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import i18next from '../../../../i18n/i18n'
import SelectLimitedAuto from '../../../../widgets/SelectLimitedAuto'
import { URL } from '../../../../middlewares/api'
import React from 'react'

function DepartmentSelector({ onChange, idValue, value, organizationId }) {
  const onChangeHandler = newValue => {
    onChange(idValue, newValue)
  }
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="age-simple">
        {i18next.t('equipment_web_atoir:selectDepartment')}
      </InputLabel>
      <SelectLimitedAuto
        id={idValue}
        value={value}
        sourceUrl={`${URL}/organizations/${organizationId}/departments`}
        onChange={onChangeHandler}
        requestResultTransformerToArray={r => r.departments}
        itemTransformer={l => ({ id: l.id, title: l.name })}
      />
    </FormControl>
  )
}

export default DepartmentSelector
