import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SetForeignPerformer from './SetForeignPerformer'
import { setForeignPerformer } from '../../../../../actions/inventoryActions'

const mapStateToProps = state => ({
  isSettingPerformer: state.inventory.setForeignPerformer.isSettingPerformer,
  setForeignSuccessfully:
    state.inventory.setForeignPerformer.setForeignSuccessfully,
  setForeignErrors: state.inventory.setForeignPerformer.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setForeignPerformer,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetForeignPerformer),
)
