import getNotifierButton from '../components/App/NotifierButton'

import {
  takeLatest,
  takeEvery,
  put,
  call as baseCall,
  take,
  fork,
} from 'redux-saga/effects'

import isEmpty from 'is-empty'

import {
  ERROR_MESSAGE_RECEIVED,
  INFO_MESSAGE_RECEIVED,
  LOGIN_SUCCESS,
  DOWNLOAD_FILE_REQUEST,
  GET_ALL_USER_SETTINGS,
  SAVE_USER_SETTINGS,
} from '../constants/actionTypes'

import {
  catchError,
  closeErrorMessage,
  receivedErrorMessage,
  redirectUserToHomePage,
  downloadFileRequestSuccess,
  downloadFileRequestFailure,
  enqueueSnackbar,
  getAllUserSettings,
  saveUserSettings,
} from '../actions/appActions'

import { call } from './loginMiddlewares'
import * as api from './api'
import { handleErrors } from '../helpers/errorsHelper'

import { axiosFetcher } from './fetcher'
import { hideDialogLoader, showDialogLoader } from '../actions/utilsActions'

export function get(url) {
  return {
    method: 'GET',
    url,
  }
}

export function deleteReq(url) {
  return {
    method: 'DELETE',
    url,
  }
}

export function post(url, payload = {}) {
  return {
    method: 'POST',
    url,
    payload,
  }
}

export function putReq(url, payload = {}) {
  return {
    method: 'PUT',
    url,
    payload,
  }
}

export function* doFetch(method, url, body) {
  let out
  try {
    const result = yield call(axiosFetcher, method, url, body)
    const error = !result.success ? result.code : null
    out = { result, error }
  } catch (e) {
    out = { result: { success: false }, error: e.code || 66 }
  }
  return out
}

export function* handlerResult(
  actionFailure,
  actionSuccess,
  resultSuccess,
  codeError,
  resultData = null,
) {
  if (resultSuccess) {
    yield put(actionSuccess(resultData))
  }
  if (!resultSuccess && actionFailure) {
    yield put(actionFailure({ error: codeError }, resultData))
  }
  if (!resultSuccess) {
    const errors = handleErrors({ code: codeError })
    yield put(receivedErrorMessage(errors))
  }
}

export function* executeReq(
  reqObj,
  failureAction,
  successAction,
  resultExtractor = payload => payload,
  showLoader = false,
) {
  const reqDefault = {
    method: 'GET',
    url: '/type_route_there',
    payload: {},
  }
  const req = { ...reqDefault, ...reqObj }

  if (showLoader) {
    yield put(showDialogLoader())
  }
  const { result, error } = yield baseCall(
    doFetch,
    req.method,
    req.url,
    req.payload,
  )

  yield baseCall(
    handlerResult,
    failureAction,
    successAction,
    result.success,
    error,
    resultExtractor(result),
  )
  if (showLoader) {
    yield put(hideDialogLoader())
  }
}

export function* watchAppSaga() {
  yield takeLatest(ERROR_MESSAGE_RECEIVED, errorMessageReceived)
}

export function* watchInfoMessagesSaga() {
  yield takeLatest(INFO_MESSAGE_RECEIVED, infoMessageReceived)
}

export function* watchClearAuthErrorSaga() {
  yield takeLatest(LOGIN_SUCCESS, clearAuthError)
}

export function* watchDownloadFileCommonRequest() {
  yield takeEvery(DOWNLOAD_FILE_REQUEST, downloadFileCommonRequest)
}

function* errorMessageReceived(action) {
  if (
    action.errorCode === 70 ||
    (action.errors &&
      action.errors.app &&
      action.errors.app[0] === 'Вы не авторизованы')
  ) {
    yield put(closeErrorMessage())
    yield put(redirectUserToHomePage())
  }
  if (_.get(action, 'errors.app')) {
    yield put(
      enqueueSnackbar({
        message: action.errors.app[0],
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          persist: true,
          action: getNotifierButton,
        },
      }),
    )
  }
}
function* infoMessageReceived(action) {
  yield put(
    enqueueSnackbar({
      message: action.info,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        action: getNotifierButton,
      },
    }),
  )
}

function* clearAuthError() {
  yield put(closeErrorMessage())
}

function* downloadFileCommonRequest(actionData) {
  try {
    const result = yield call(
      api.downloadFileCommonRequestApi(actionData.payload),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(downloadFileRequestSuccess(actionData.payload))
        const url = window.URL.createObjectURL(new Blob([result.blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', actionData.payload.name)
        document.body.appendChild(link)
        link.click()
      } else {
        const errors = handleErrors(result)
        yield put(
          downloadFileRequestFailure({
            ...actionData.payload,
            code: result.code,
          }),
        )
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(downloadFileRequestFailure({ ...actionData.payload, code: 433 }))
    yield put(catchError(error.message))
  }
}

function* getAllUserSettingsSaga() {
  yield worker(
    () => take(GET_ALL_USER_SETTINGS.REQUEST),
    () => get(`/settings`),
    getAllUserSettings.FAILURE,
    getAllUserSettings.SUCCESS,
    result => result,
    false,
  )
}

function* saveUserSettingsSaga() {
  yield worker(
    () => take(SAVE_USER_SETTINGS.REQUEST),
    payload => putReq(`/settings`, payload),
    saveUserSettings.FAILURE,
    saveUserSettings.SUCCESS,
    result => result,
    false,
  )
}

export function* worker(
  effectCatcher,
  queryFunctionBuilder,
  failureAction,
  successAction,
  resultExtractor,
  showLoader,
) {
  while (true) {
    const { payload } = yield effectCatcher()
    yield executeReq(
      queryFunctionBuilder(payload),
      failureAction,
      successAction,
      resultExtractor,
      showLoader,
    )
  }
}

export default function* root() {
  yield fork(getAllUserSettingsSaga)
  yield fork(saveUserSettingsSaga)
}
