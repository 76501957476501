import fetch from 'cross-fetch'
import config from '../config'

const token =
  process.env.NODE_ENV === 'development'
    ? config.dadata_token_developer
    : config.dadata_token_developer

const getSuggestionsDadata = async query => {
  try {
    const rawResponse = await fetch(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ query }),
      },
    )
    const content = await rawResponse.json()
    return content.suggestions || null
  } catch (error) {
    return null
  }
}

const getStreetDadata = async query => {
  try {
    const rawResponse = await fetch(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ query }),
      },
    )
    const content = await rawResponse.json()
    return content.suggestions || null
  } catch (error) {
    return null
  }
}

const getSuggestions = getSuggestionsDadata
const getLocation = getStreetDadata

export { getSuggestions, getLocation }
