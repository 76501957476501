import React, { Component, Fragment } from 'react'

// config
import config from '../../../config'

// material
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

// icons
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

// i18
import i18next from '../../../i18n/i18n'

const styles = theme => ({
  button: {
    color: theme.palette.blueSecondary.main,
    boxShadow: '0 0px 0px 0px rgba(255, 255, 255)',
    '&:hover': {
      backgroundColor: theme.palette.blueSecondary.lightest,
      color: theme.palette.blueSecondary.main,
    },
    borderRadius: 0,
    transition: theme.transitions.create(['display'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: config.right_drawer_width,

    [theme.breakpoints.down(1251)]: {
      display: 'none',
    },
    position: 'fixed',
    bottom: 0,
    right: 0,
  },
})

class UpScrollButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  componentDidMount = () => {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('scroll', this.handleScroll)
  }

  updateWindowDimensions = () => {
    const {
      state: { isVisible },
    } = this
    const { scrollY } = window
    if (!isVisible && scrollY > 300) {
      this.setState({
        isVisible: true,
      })
    } else if (isVisible && scrollY < 10) {
      this.setState({
        isVisible: false,
      })
    }
  }

  handleScroll = event => {
    const { scrollTop } = event.target.scrollingElement
    const {
      state: { isVisible },
    } = this
    if (!isVisible && scrollTop > 300) {
      this.setState({
        isVisible: true,
      })
    } else if (isVisible && scrollTop < 10) {
      this.setState({
        isVisible: false,
      })
    }
  }

  render() {
    const {
      props: { classes },
      state: { isVisible },
    } = this
    return (
      <Fragment>
        {isVisible && (
          <Button
            className={classes.button}
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            <ArrowUpwardIcon />
            {i18next.t('shared_web_atoir:scrollToTop')}
          </Button>
        )}
      </Fragment>
    )
  }
}

UpScrollButton.propTypes = {}

export default withStyles(styles)(UpScrollButton)
