import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ServiceOrganizationsSelect from './ServiceOrganizationsSelect'
import {
  fetchServiceOrganizations,
  fetchCitiesWithServiceOrganizations,
} from '../../../actions/organizationsActions'

const mapStateToProps = state => ({
  isFetchingServiceOrganizations:
    state.organizations.serviceOrganizations.isFetching,
  serviceOrganizations: state.organizations.serviceOrganizations.organizations,
  fetchOrganizationsErrors: state.organizations.serviceOrganizations.errors,
  isFetchingCities:
    state.organizations.citiesWithServiceOrganizations.isFetchingCities,
  cities: state.organizations.citiesWithServiceOrganizations.cities,
  fetchCitiesErrors: state.organizations.citiesWithServiceOrganizations.errors,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceOrganizations,
      fetchCitiesWithServiceOrganizations,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceOrganizationsSelect),
)
