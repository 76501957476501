import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'

// internationalization
import i18next from '../../i18n/i18n'

// components
import Heading from '../../widgets/Lettering/Heading'
import ContainedButton from '../../widgets/Buttons/ContainedButton'
import OutlinedButton from '../../widgets/Buttons/OutlinedButton'
import Loader from '../Loader'

// styles
import '../../styles/components/User.css'
import '../../styles/components/Register.css'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import FormHelperText from '@material-ui/core/FormHelperText'
import BreadCrumb from '../App/routing/BreadCrumb'
import { popLastSections } from '../../helpers/dataHelper'

const styles = theme => ({
  root: theme.mixins.gutters({}),
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  actionsRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  formPaperRoot: {
    paddingBottom: theme.spacing(5),
  },
  formgroupRoot: {
    padding: 12,
    width: 480,
    margin: '0 auto',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  button: {
    marginRight: 0,
    marginLeft: 0,
  },
})

class ChangePasswordComponent extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    errors: {},
    showOldPassword: false,
    showNewPassword: false,
    showNewConfirmationPassword: false,
  }

  componentDidMount() {
    if (isEmpty(this.props.user)) {
      this.props.fetchUser()
    }
  }

  handleTextChange = value => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isChangingPassword !== this.props.isChangingPassword &&
      this.props.isChangingPassword === false &&
      isEmpty(this.props.passwordChangeErrors)
    ) {
      this.props.history.goBack()
    }
  }

  handleClickShowPassword = field => () => {
    this.setState(state => ({ [field]: !state[field] }))
  }

  render() {
    const {
      classes,
      isChangingPassword,
      passwordChangeErrors,
      match: { url },
      isFetchingUser,
    } = this.props
    const { errors } = this.state

    const oldPasswordHelperText = !isEmpty(errors.oldPassword)
      ? errors.oldPassword
      : !isEmpty(passwordChangeErrors.password)
      ? passwordChangeErrors.password[0]
      : ''

    const newPasswordHelperText = !isEmpty(errors.newPassword)
      ? errors.newPassword
      : !isEmpty(passwordChangeErrors.newPassword)
      ? passwordChangeErrors.newPassword[0]
      : ''

    const newPasswordConfirmHelperText = !isEmpty(errors.newPasswordConfirm)
      ? errors.newPasswordConfirm
      : !isEmpty(passwordChangeErrors.passwordConfirm)
      ? passwordChangeErrors.passwordConfirm[0]
      : ''

    return (
      <div>
        {!isFetchingUser && (
          <Fragment>
            <BreadCrumb to={popLastSections(url, 1)}>
              {i18next.t('user_profile_web_atoir:userProfileCrumbTitle')}
            </BreadCrumb>
            <BreadCrumb to={url}>
              {i18next.t(
                'user_profile_web_atoir:changePasswordProfileCrumbTitle',
              )}
            </BreadCrumb>
          </Fragment>
        )}
        <form onSubmit={this.handleSubmitForm} noValidate>
          <Paper elevation={4} className={classes.formPaperRoot}>
            <div className="Content-title">
              <Heading>
                <Typography variant="h6">
                  {i18next.t('login_web_atoir:changePasswordTitle')}
                </Typography>
              </Heading>
            </div>
            <Divider />
            <div className={classes.formgroupRoot}>
              {isChangingPassword ? <Loader /> : null}
              <FormGroup>
                <FormControl
                  margin="normal"
                  error={
                    !isEmpty(errors.oldPassword) ||
                    !isEmpty(passwordChangeErrors.password)
                  }
                >
                  <InputLabel htmlFor="adornment-password">
                    {i18next.t('login_web_atoir:enterOldPassword')}
                  </InputLabel>
                  <Input
                    id="adornment-password"
                    type={this.state.showOldPassword ? 'text' : 'password'}
                    value={this.state.oldPassword}
                    onChange={this.handleTextChange('oldPassword')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword(
                            'showOldPassword',
                          )}
                        >
                          {this.state.showOldPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="error-text">
                    {oldPasswordHelperText}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  margin="normal"
                  error={
                    !isEmpty(errors.newPassword) ||
                    !isEmpty(passwordChangeErrors.newPassword)
                  }
                >
                  <InputLabel htmlFor="adornment-password">
                    {i18next.t('login_web_atoir:enterNewPassword')}
                  </InputLabel>
                  <Input
                    id="adornment-password"
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    value={this.state.newPassword}
                    onChange={this.handleTextChange('newPassword')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword(
                            'showNewPassword',
                          )}
                        >
                          {this.state.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="error-text">
                    {newPasswordHelperText}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  margin="normal"
                  error={
                    !isEmpty(errors.newPasswordConfirm) ||
                    !isEmpty(passwordChangeErrors.passwordConfirm)
                  }
                >
                  <InputLabel htmlFor="adornment-password">
                    {i18next.t('login_web_atoir:enterNewPasswordConfirmation')}
                  </InputLabel>
                  <Input
                    id="adornment-password"
                    type={
                      this.state.showNewConfirmationPassword
                        ? 'text'
                        : 'password'
                    }
                    value={this.state.newPasswordConfirm}
                    onChange={this.handleTextChange('newPasswordConfirm')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword(
                            'showNewConfirmationPassword',
                          )}
                        >
                          {this.state.showNewConfirmationPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="error-text">
                    {newPasswordConfirmHelperText}
                  </FormHelperText>
                </FormControl>
              </FormGroup>
              <div className={classes.actionsRoot}>
                <div>
                  <OutlinedButton
                    size="medium"
                    onClick={this.props.history.goBack}
                    classes={{ root: classes.button }}
                  >
                    {i18next.t('shared_web_atoir:cancel')}
                  </OutlinedButton>
                </div>
                <div>
                  <ContainedButton
                    size="medium"
                    type="submit"
                    classes={{ root: classes.button }}
                  >
                    {i18next.t('login_web_atoir:saveNewPassword')}
                  </ContainedButton>
                </div>
              </div>
            </div>
          </Paper>
        </form>
      </div>
    )
  }

  handleSubmitForm = event => {
    event.preventDefault()
    const { changePassword } = this
    changePassword()
  }

  changePassword = () => {
    const { oldPassword, newPassword, newPasswordConfirm } = this.state

    const emptyErrors = this.validateEmptyFields(
      oldPassword,
      newPassword,
      newPasswordConfirm,
    )
    const passwordErrors = this.validateNewPasswords(
      newPassword,
      newPasswordConfirm,
    )

    if (!isEmpty(emptyErrors)) {
      this.setState({
        errors: emptyErrors,
      })
      return
    }
    if (!isEmpty(passwordErrors)) {
      this.setState({
        errors: passwordErrors,
      })
      return
    }
    this.setState({
      errors: {},
    })

    const { changePassword } = this.props
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirm: newPasswordConfirm,
    }

    changePassword(data)
  }

  validateEmptyFields = (oldPassword, newPassword, newPasswordConfirm) => {
    const errors = {}
    if (isEmpty(oldPassword)) {
      errors.oldPassword = i18next.t('validation_web_atoir:fieldShouldBeFilled')
    }
    if (isEmpty(newPassword)) {
      errors.newPassword = i18next.t('validation_web_atoir:fieldShouldBeFilled')
    }
    if (isEmpty(newPasswordConfirm)) {
      errors.newPasswordConfirm = i18next.t(
        'validation_web_atoir:fieldShouldBeFilled',
      )
    }
    return errors
  }

  validateNewPasswords = (newPassword, newPasswordConfirm) => {
    const errors = {}
    if (newPassword !== newPasswordConfirm) {
      errors.newPasswordConfirm = i18next.t(
        'validation_web_atoir:passwordConfirmationNotMatch',
      )
    }
    return errors
  }
}

ChangePasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  passwordChangeErrors: PropTypes.object.isRequired,
}

export default withStyles(styles)(ChangePasswordComponent)
