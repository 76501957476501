import App from './App'
import { bindActionCreators } from 'redux'
import { getAllUserSettings } from '../../actions/appActions'
import { connect } from 'react-redux'
const mapStateToProps = state => ({
  isLoggedIn: state.login.isLoggedIn,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllUserSettings: getAllUserSettings.REQUEST,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)
