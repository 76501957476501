import * as constants from '../constants/actionTypes/scripts'
import { actionCreator as externalActionCreator } from './actionCreator'

export const createScriptRequest = externalActionCreator(
  constants.CREATE_SCRIPT_REQUEST,
)
export const createScriptSuccess = externalActionCreator(
  constants.CREATE_SCRIPT_SUCCESS,
)
export const createScriptFailure = externalActionCreator(
  constants.CREATE_SCRIPT_FAILURE,
)

export const editScriptRequest = externalActionCreator(
  constants.EDIT_SCRIPT_REQUEST,
)
export const editScriptSuccess = externalActionCreator(
  constants.EDIT_SCRIPT_SUCCESS,
)
export const editScriptFailure = externalActionCreator(
  constants.EDIT_SCRIPT_FAILURE,
)

export const getScriptsRequest = externalActionCreator(
  constants.GET_SCRIPTS_REQUEST,
)
export const getScriptsSuccess = externalActionCreator(
  constants.GET_SCRIPTS_SUCCESS,
)
export const getScriptsFailure = externalActionCreator(
  constants.GET_SCRIPTS_FAILURE,
)

export const deleteScriptRequest = externalActionCreator(
  constants.DELETE_SCRIPT_REQUEST,
)
export const deleteScriptSuccess = externalActionCreator(
  constants.DELETE_SCRIPT_SUCCESS,
)
export const deleteScriptFailure = externalActionCreator(
  constants.DELETE_SCRIPT_FAILURE,
)

export const getScriptRequest = externalActionCreator(
  constants.GET_SCRIPT_REQUEST,
)
export const getScriptSuccess = externalActionCreator(
  constants.GET_SCRIPT_SUCCESS,
)
export const getScriptFailure = externalActionCreator(
  constants.GET_SCRIPT_FAILURE,
)

export const getScriptsHistoryRequest = externalActionCreator(
  constants.GET_SCRIPTS_HISTORY_REQUEST,
)
export const getScriptsHistorySuccess = externalActionCreator(
  constants.GET_SCRIPTS_HISTORY_SUCCESS,
)
export const getScriptsHistoryFailure = externalActionCreator(
  constants.GET_SCRIPTS_HISTORY_FAILURE,
)
export const clearScriptsHistory = externalActionCreator(
  constants.CLEAR_SCRIPTS_HISTORY,
)

export const getSDCallAvailableScriptsRequest = externalActionCreator(
  constants.GET_SD_CALL_AVAILABLE_SCRIPTS_REQUEST,
)
export const getSDCallAvailableScriptsSuccess = externalActionCreator(
  constants.GET_SD_CALL_AVAILABLE_SCRIPTS_SUCCESS,
)
export const getSDCallAvailableScriptsFailure = externalActionCreator(
  constants.GET_SD_CALL_AVAILABLE_SCRIPTS_FAILURE,
)
