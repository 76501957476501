import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import EditIcon from '@material-ui/icons/Edit'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../../i18n/i18n'
import { organizationLocationStringify } from '../../../../helpers/parserHelper'
import {
  getUserFullName,
  stringifyEmployeePosition,
} from '../../../../helpers/userHelper'
import { addMaskCharacters } from '../../../../helpers/phoneStringHelper'
import LongTextExpander from '../../../../widgets/LongTextExpander'
import IconButton from '@material-ui/core/IconButton'
import EditResponsible from './EditResponsible'
import {
  canEditClientResponsible,
  canEditPerformerResponsible,
} from '../../../../helpers/ContractPermissionsResolver'

const styles = theme => ({
  bodyTextStyle: {
    fontSize: '1.3rem',
  },
  subText: {
    color: theme.palette.text.secondary,
  },
})

class ContractBaseInfo extends Component {
  state = {
    editClientResponsibleOpen: false,
    editPerformerResponsibleOpen: false,
  }

  handleChange = (field, state) => () => {
    this.setState({
      [field]: state,
    })
  }

  render() {
    const {
      state: { editClientResponsibleOpen, editPerformerResponsibleOpen },
      props: {
        classes,
        contract,
        editClientResponsible,
        editPerformerResponsible,
        permissions,
      },
      handleChange,
    } = this
    return (
      <div>
        <List>
          <ListItem disableGutters>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                {i18n.t('service_desk_web_atoir:dateOfCreation')}:
              </Grid>
              <Grid item xs={12} sm={9}>
                {new Date(contract.date_created).toLocaleDateString('ru')}
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <List
          subheader={
            <ListSubheader disableGutters>
              {i18n.t('service_desk_web_atoir:client')}
            </ListSubheader>
          }
        >
          <ListItem disableGutters>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                {i18n.t('service_desk_web_atoir:contractInfoOrganizationTitle')}
                :
              </Grid>
              <Grid item xs={12} sm={9}>
                {contract.cl_organization.short_name}
                <Typography variant="body2" className={classes.subText}>
                  {organizationLocationStringify(
                    contract.cl_organization.OrganizationLocations[0],
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem disableGutters>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                {i18n.t('service_desk_web_atoir:contractInfoResponsibleTitle')}:
              </Grid>
              <Grid item xs={12} sm={9}>
                {getUserFullName(contract.cl_responsible)}
                {permissions &&
                  canEditClientResponsible(permissions, contract.status) && (
                    <IconButton
                      className={classes.editIcon}
                      onClick={handleChange('editClientResponsibleOpen', true)}
                    >
                      <EditIcon fontSize="default" />
                    </IconButton>
                  )}
                <Typography
                  variant="body2"
                  className={classNames([classes.subText, 'wordWrapBreakAll'])}
                >
                  {stringifyEmployeePosition(contract.cl_responsible.position)}
                  {contract.cl_responsible.phone &&
                    `, ${addMaskCharacters(contract.cl_responsible.phone)}`}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {contract.contract_note && (
            <ListItem disableGutters>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  {i18n.t(
                    'service_desk_web_atoir:contractInfoContractNoteTitle',
                  )}
                  :
                </Grid>
                <Grid item xs={12} sm={9}>
                  <LongTextExpander>{contract.contract_note}</LongTextExpander>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </List>
        {contract.perf_organization && (
          <List
            subheader={
              <ListSubheader disableGutters>
                {i18n.t('service_desk_web_atoir:performer')}
              </ListSubheader>
            }
          >
            <ListItem disableGutters>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  {i18n.t(
                    'service_desk_web_atoir:contractInfoOrganizationTitle',
                  )}
                  :
                </Grid>
                <Grid item xs={12} sm={9} className="wordWrapBreakAll">
                  {contract.perf_organization.short_name}
                  <Typography variant="body2" className={classes.subText}>
                    {organizationLocationStringify(
                      contract.perf_organization.OrganizationLocations[0],
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem disableGutters>
              {contract.perf_responsible && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    {i18n.t(
                      'service_desk_web_atoir:contractInfoResponsibleTitle',
                    )}
                    :
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {getUserFullName(contract.perf_responsible)}
                    {contract.perf_responsible &&
                      canEditPerformerResponsible(
                        permissions,
                        contract.status,
                      ) && (
                        <IconButton
                          className={classes.editIcon}
                          onClick={handleChange(
                            'editPerformerResponsibleOpen',
                            true,
                          )}
                        >
                          <EditIcon fontSize="default" />
                        </IconButton>
                      )}
                    <Typography variant="body2" className={classes.subText}>
                      {stringifyEmployeePosition(
                        contract.perf_responsible.position,
                      )}
                      {contract.perf_responsible.phone &&
                        `, ${addMaskCharacters(
                          contract.perf_responsible.phone,
                        )}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </ListItem>
          </List>
        )}
        {editClientResponsibleOpen && (
          <EditResponsible
            onClose={handleChange('editClientResponsibleOpen', false)}
            onSubmit={props => {
              handleChange('editClientResponsibleOpen', false)()
              editClientResponsible(props)
            }}
            organizationId={contract.cl_organization.id}
            responsibleId={contract.cl_responsible.id}
          />
        )}
        {editPerformerResponsibleOpen && (
          <EditResponsible
            organizationId={contract.perf_organization.id}
            onSubmit={props => {
              handleChange('editPerformerResponsibleOpen', false)()
              editPerformerResponsible(props)
            }}
            onClose={handleChange('editPerformerResponsibleOpen', false)}
            responsibleId={contract.perf_responsible.id}
          />
        )}
      </div>
    )
  }
}

ContractBaseInfo.propTypes = {
  contract: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  editPerformerResponsible: PropTypes.func.isRequired,
  editClientResponsible: PropTypes.func.isRequired,
}

export default withStyles(styles)(ContractBaseInfo)
