import React from 'react'
import FormDialog from '../../../../widgets/FormDialog'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import SDCallsListSettings from '../../../User/UserSettings/SDCallsListSettings'
import SDOrdersListSettings from '../../../User/UserSettings/SDOrdersListSettings'
import UserSettingsProvider from '../../../User/UserSettings/UserSettingsProvider'
import Switch from '../../../../widgets/Switch'
import { Context } from './ListSettingsDialogContext'
import i18next from '../../../../i18n/i18n'

const ListSettingsDialog = ({ listOf }) => {
  const [open, setOpen] = React.useState(false)
  const { save } = React.useContext(Context)
  function handleClose() {
    setOpen(false)
  }
  return (
    <div>
      <FormDialog
        fullWidth
        dialogProps={{
          maxWidth: 'md',
        }}
        closeIconHidden
        open={open}
        isLoading={false}
        title={`Настройка списка ${
          listOf === 'orders' ? 'заказ-нарядов' : 'заявок'
        }`}
        onClose={handleClose}
        onSubmit={() => {
          save()
          handleClose()
        }}
        submitButtonTitle="Применить"
        secondaryButtonsArray={[
          {
            secondaryText: i18next.t('shared_web_atoir:cancel'),
            handleSecondaryAction: handleClose,
          },
        ]}
      >
        <UserSettingsProvider manualSave>
          {({ settings, onChangeById }) => (
            <>
              <Switch value={listOf}>
                <Switch.Option option="calls">
                  <SDCallsListSettings
                    id="sd-calls-list-settings"
                    settings={settings}
                    onChangeById={onChangeById}
                  />
                </Switch.Option>
                <Switch.Option option="orders">
                  <SDOrdersListSettings
                    id="sd-orders-list-settings"
                    settings={settings}
                    onChangeById={onChangeById}
                  />
                </Switch.Option>
              </Switch>
            </>
          )}
        </UserSettingsProvider>
      </FormDialog>
      <IconButton
        size="small"
        onClick={() => {
          setOpen(true)
        }}
        color="inherit"
      >
        <SettingsIcon />
      </IconButton>
    </div>
  )
}

export default ListSettingsDialog
