import React, { Component } from 'react'
import PropTypes from 'prop-types'

// internationalization
import i18next from '../../../../../i18n/i18n'

// components
import Loader from '../../../../Loader'
import ProjectHeading from '../InventoryProjectHeading'
import InventoryEquipmentLocationsTable from './InventoryEquipmentLocationsTable'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

class InventoryProjectEquipment extends Component {
  componentDidMount = () => {
    const {
      props: {
        match: {
          params: { projectId },
        },
        fetchInventoryProjectProfile,
      },
    } = this
    fetchInventoryProjectProfile(projectId)
  }

  componentWillReceiveProps = props => {
    if (
      props.isFetchingProject !== this.props.isFetchingProject &&
      props.isFetchingProject === false
    ) {
      if (props.error) {
        switch (props.error) {
          case 179:
            props.history.push('/404')
            break
          case 52:
            props.history.push('/404')
            break
          default:
            break
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: { isCancellingProject, cancelledProjectSuccessfully },
      handleCancelDialog,
      goBack,
    } = this

    if (
      prevProps.isCancellingProject !== isCancellingProject &&
      !isCancellingProject
    ) {
      handleCancelDialog()
      if (cancelledProjectSuccessfully) {
        goBack()
      }
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleCancelDialog = () => {
    const {
      state: { openCancelDialog },
    } = this
    this.setState({ openCancelDialog: !openCancelDialog })
  }

  getDividerGrid = () => (
    <Grid xs={12} className={this.props.classes.dividerMargin}>
      <Divider
        classes={{
          root: this.props.classes.primaryDividerRoot,
        }}
      />
    </Grid>
  )

  render = () => {
    const {
      props: {
        classes,
        inventoryProject,
        isFetchingProject,
        canCancelProject,
        equipmentCount,
        locationsCount,
      },
      handleCancelDialog,
      getDividerGrid,
    } = this
    if (isFetchingProject || !inventoryProject) {
      return <Loader />
    }
    return (
      <Paper className={classes.root}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <ProjectHeading
              created={new Date(inventoryProject.date_created)}
              status={inventoryProject.status}
              type={inventoryProject.inventory_type}
              kit={inventoryProject.inventory_kit}
              canCancelProject={canCancelProject}
              handleCancelDialog={handleCancelDialog}
            />
          </Grid>
          <Grid container xs={12} direction="row">
            <Grid item xs={6}>
              <Typography variant="subheading" align="left">
                {`${i18next.t(
                  'inventory_web_atoir:projectInventoryEquipmentList',
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right">
                {`${i18next.t(
                  'inventory_web_atoir:numbersSeats',
                )}: ${locationsCount}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right">
                {`${i18next.t(
                  'inventory_web_atoir:unitsEquipment',
                )}: ${equipmentCount}`}
              </Typography>
            </Grid>
            {getDividerGrid()}
            <Grid container xs={12} direction="column">
              <InventoryEquipmentLocationsTable />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  dividerMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerPrimary: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  primaryDividerRoot: {
    color: '#123567',
  },
  underlined: {
    'text-decoration': 'underline',
    'text-decoration-style': 'solid',
  },
})

InventoryProjectEquipment.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchInventoryProjectProfile: PropTypes.func.isRequired,
  isFetchingProject: PropTypes.bool.isRequired,
  inventoryProject: PropTypes.object.isRequired,
  equipmentCount: PropTypes.number.isRequired,
  locationsCount: PropTypes.number.isRequired,
}

export default withStyles(styles)(InventoryProjectEquipment)
