import isEmpty from 'is-empty'
import { errorCodes } from '../constants/errors'

// eslint-disable-next-line
export const handleErrors = json => {
  const { code, errors } = json
  const codesArray = formCodesArray(code, errors)

  return _.pickBy(
    _.mapValues(errorCodes(), value =>
      Object.values(_.pick(value, codesArray)),
    ),
    el => !isEmpty(el),
  )
}

export const checkCodes = (json, type) => {
  const { code, errors } = json
  const codesArray = formCodesArray(code, errors)
  switch (type) {
    case 'shouldResendLink':
      const codesToCheckAmong = ['119', '34']
      return codesArray.some(el => codesToCheckAmong.includes(el))

    default:
      return false
  }
}

const formCodesArray = (code, errors) => {
  const codesArray = []
  if (code) {
    codesArray.push(code.toString())
  } else {
    errors.forEach(c => codesArray.push(c.toString()))
  }
  return codesArray
}
