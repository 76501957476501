import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import WrapperRoute from '../WrapperRoute'
import i18next from '../../../i18n/i18n'
import UserProfileContainer from '../../../containers/User/UserProfileContainer'
import NoMatchComponent from '../../NoMatchComponent'
import StaffContainer from '../../../containers/Staff/StaffContainer'
import BreadCrumb from './BreadCrumb'

const ContactRouter = ({ match: { path } }) => (
  <Fragment>
    <BreadCrumb to={path}>{i18next.t('shared_web_atoir:contacts')}</BreadCrumb>
    <Switch>
      <WrapperRoute
        title={i18next.t('user_profile_web_atoir:userProfileCrumbTitle')}
        path={`${path}/:id`}
        component={UserProfileContainer}
      />
      <WrapperRoute
        path={`${path}`}
        title={i18next.t('employees_web_atoir:accountStaffTitle')}
        crumbOff
        component={StaffContainer}
      />
      <Route component={NoMatchComponent} />
    </Switch>
  </Fragment>
)

export default ContactRouter
