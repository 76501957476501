import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import InventoryLocationsTableComponent from './InventoryLocationsTableComponent'
import { fetchOrganizationLocations } from '../../../../../actions/staffActions'

const mapStateToProps = state => ({
  isFetchingLocations: state.staff.isFetchingLocations,
  locations: state.staff.locations,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganizationLocations,
    },
    dispatch,
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InventoryLocationsTableComponent),
)
