import React from 'react'
import classNames from 'classnames'

import styled from 'styled-components'
import { spacing, sizing } from '@material-ui/system'

import { withStyles } from '@material-ui/core/styles/index'
import Drawer from '@material-ui/core/Drawer'

import config from '../../../../config'

import ListRenderer from './ListRenderer'
import SensitiveButton from './SensitiveButton'

import {
  useAppDispatch,
  useAppState,
  appActions,
  appOptions,
} from '../../appContext'

const drawerWidth = config.left_drawer_width

const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: 'rgba(245, 245, 245, .95);',
  },
  drawerPaperCollapsed: {
    width: 54,
  },
  paperAnchorLeft: {
    transition: 'all 0.1s ease-in-out',
    left: 0,
  },
  paperAnchorLeftClosed: {
    left: -54,
  },
})

const Box = styled.div`${spacing}${sizing}`

function DrawerCollapsible({ classes, open }) {
  const { drawerMode } = useAppState()
  const [collapsed, setCollapsed] = React.useState(true)

  React.useEffect(() => {
    if (open === false) {
      setCollapsed(true)
    }
    if (open === true) {
      setCollapsed(true)
    }
  }, [open])

  const dispatch = useAppDispatch()

  const handlerCollapsed = () => {
    setCollapsed(true)
  }

  const showCommonDrawer = () => {
    setCollapsed(true)
    dispatch(appActions.setDrawerMode(appOptions.drawerMode.common))
  }

  return (
    <Drawer
      data-cy="menuDrawer"
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerPaperCollapsed]: collapsed,
        }),
        paperAnchorLeft: classNames(classes.paperAnchorLeft, {
          [classes.paperAnchorLeftClosed]: !open,
        }),
      }}
      variant="permanent"
      onMouseLeave={handlerCollapsed}
    >
      <div>
        <Box mt={1} minHeight={46}>
          {drawerMode === appOptions.drawerMode.collapsible && (
            <SensitiveButton
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              onClick={showCommonDrawer}
            />
          )}
        </Box>
        <Box mt={1}>
          <ListRenderer collapsed={collapsed} setCollapsed={setCollapsed} />
        </Box>
      </div>
    </Drawer>
  )
}

export default withStyles(styles)(DrawerCollapsible)
