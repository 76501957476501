import React from 'react'
import PropTypes from 'prop-types'
import i18next from '../../../../i18n/i18n'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

// component
import OutlinedButton from '../../../../widgets/Buttons/OutlinedButton'
import NumberFormatCustom from '../OrderItemsForm/Widgets/NumberFormatCustom'

const styles = () => ({
  additionInput: {
    width: 100,
  },
})

const CommonAddition = props => (
  <Grid
    container
    direction="row"
    spacing={1}
    alignItems="center"
    justify="flex-end"
  >
    <Grid item>
      <Typography variant="body2">
        {i18next.t('service_desk_web_atoir:commonAddition')}
      </Typography>
    </Grid>
    <Grid item alignContent="center" justify="center">
      <TextField
        margin="normal"
        value={String(props.commonAddition).replace('.', ',')}
        onChange={props.onChange}
        className={props.classes.additionInput}
        InputProps={
          props.commonAddition === i18next.t('service_desk_web_atoir:mixed')
            ? {}
            : { inputComponent: NumberFormatCustom }
        }
      />
    </Grid>
    <Grid item>
      <Typography>%</Typography>
    </Grid>
    <Grid item>
      <OutlinedButton
        onClick={props.onApply}
        disabled={
          props.commonAddition === i18next.t('service_desk_web_atoir:mixed')
        }
      >
        {i18next.t('service_desk_web_atoir:applyAction')}
      </OutlinedButton>
    </Grid>
  </Grid>
)

CommonAddition.propTypes = {
  classes: PropTypes.object.isRequired,
  commonAddition: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
}

export default withStyles(styles)(CommonAddition)
