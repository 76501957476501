import React, { Component } from 'react'
import i18next from '../../i18n/i18n'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import isEmpty from 'is-empty'

// components
import Loader from '../../components/Loader'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography/Typography'

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  overrideItemToString,
}) {
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
    >
      {overrideItemToString(suggestion)}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

const overrideItemToString = item => (item ? item.full_name : '')

const styles = theme => ({
  root: {
    zIndex: 10,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
})

class Autocomplete extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      inputValue: '',
      selectedItem: {},
    }
  }

  componentDidMount() {
    this.props.refFunc(this)
  }

  componentDidUpdate = prevProps => {
    if (prevProps.suggestions !== this.props.suggestions) {
      this.setState({
        fetched: true,
      })
    }
  }

  clear = cb => {
    this.setState(
      {
        inputValue: '',
        selectedItem: {},
      },
      cb || (() => {}),
    )
  }

  searchValue = value => {
    const {
      props: { searchValue },
    } = this
    this.setState({
      inputValue: value,
    })
    searchValue(value)
  }

  handleChange = item => {
    this.setState({
      inputValue: this.props.overrideItemToString(item),
      selectedItem: item,
    })
  }

  render() {
    const {
      classes,
      placeholder,
      overrideItemToString: _overrideItemToString,
    } = this.props
    return (
      <div className={classes.root}>
        <Downshift
          inputValue={this.state.inputValue}
          selectedItem={this.state.selectedItem}
          onChange={this.handleChange}
          onInputValueChange={value => this.searchValue(value)}
          onSelect={item => this.props.onSelect(item)}
          itemToString={_overrideItemToString}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            selectedItem,
            highlightedIndex,
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  placeholder,
                  className: classes.inputClassName,
                  id: 'integration-downshift-simple',
                }),
              })}
              {isOpen && (
                <Paper className={classes.paper} square>
                  {this.props.isFetchingSuggestions ? (
                    <Loader />
                  ) : isEmpty(this.props.suggestions) ? (
                    this.state.fetched ? (
                      <Typography>
                        {i18next.t('shared_web_atoir:notFound')}
                      </Typography>
                    ) : (
                      ''
                    )
                  ) : (
                    this.props.suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                        overrideItemToString: _overrideItemToString,
                      }),
                    )
                  )}
                </Paper>
              )}
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

Autocomplete.defaultProps = {
  overrideItemToString,
  refFunc: () => {},
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  overrideItemToString: PropTypes.func,
  refFunc: PropTypes.func,
}

export default withStyles(styles)(Autocomplete)
