import { takeLatest, put } from 'redux-saga/effects'
import isEmpty from 'is-empty'
import { call } from './loginMiddlewares'
import {
  FETCH_STAFF_REQUEST,
  DELETE_STAFF_REQUEST,
  FETCH_ORGANIZATION_STAFF_REQUEST,
  ADD_ORGANIZATION_USER_REQUEST,
  BIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
  UPDATE_ORGANIZATION_USER_REQUEST,
  DELETE_ORGANIZATION_USER_REQUEST,
  FETCH_ORGANIZATION_LOCATIONS_REQUEST,
  FETCH_BOUND_ORGANIZATION_USERS_REQUEST,
  FETCH_USER_CONTACTS_REQUEST,
  FETCH_ORGANIZATION_STAFF_COUNT_REQUEST,
  FETCH_USER_CONTACTS_COUNT_REQUEST,
} from '../constants/actionTypes'
import {
  fetchStaffRequest,
  deleteStaffRequest,
  fetchOrganizationStaffRequest,
  addOrganizationUserRequest,
  bindOrganizationUserAccountRequest,
  unbindOrganizationUserAccountRequest,
  updateOrganizationUserRequest,
  deleteOrganizationUserRequest,
  fetchOrganizationLocationsRequest,
  fetchBoundOrganizationUsersRequest,
  fetchUserContactsRequest,
  fetchOrganizationStaffCountRequest,
  fetchUserContactsCountRequest,
} from './api'
import {
  fetchStaffSuccess,
  fetchStaffFailure,
  deleteStaffSuccess,
  deleteStaffFailure,
  fetchOrganizationStaffSuccess,
  fetchOrganizationStaffFailure,
  addOrganizationUserSuccess,
  addOrganizationUserFailure,
  bindOrganizationUserAccountSuccess,
  bindOrganizationUserAccountFailure,
  unbindOrganizationUserAccountSuccess,
  unbindOrganizationUserAccountFailure,
  updateOrganizationUserSuccess,
  updateOrganizationUserFailure,
  deleteOrganizationUserSuccess,
  deleteOrganizationUserFailure,
  fetchOrganizationLocationsSuccess,
  fetchOrganizationLocationsFailure,
  fetchBoundOrganizationUsersSuccess,
  fetchBoundOrganizationUsersFailure,
  fetchContactsFailure,
  fetchContactsSuccess,
  fetchOrganizationStaffCountFailure,
  fetchOrganizationStaffCountSuccess,
  fetchContactsCountSuccess,
  fetchContactsCountFailure,
} from '../actions/staffActions'
import { catchError, receivedErrorMessage } from '../actions/appActions'
import { handleErrors } from '../helpers/errorsHelper'

export function* watchFetchStaffSaga() {
  yield takeLatest(FETCH_STAFF_REQUEST, fetchStaff)
}

export function* watchFetchUserContactsSaga() {
  yield takeLatest(FETCH_USER_CONTACTS_REQUEST, fetchContacts)
}

export function* watchFetchUserContactsContSaga() {
  yield takeLatest(FETCH_USER_CONTACTS_COUNT_REQUEST, fetchContactsCount)
}

export function* watchDeleteStaffSaga() {
  yield takeLatest(DELETE_STAFF_REQUEST, deleteStaff)
}

export function* watchFetchOrganizationStaffSaga() {
  yield takeLatest(FETCH_ORGANIZATION_STAFF_REQUEST, fetchOrganizationStaff)
}

export function* watchFetchOrganizationStaffCountSaga() {
  yield takeLatest(
    FETCH_ORGANIZATION_STAFF_COUNT_REQUEST,
    fetchOrganizationStaffCount,
  )
}

export function* watchAddOrganizationUserSaga() {
  yield takeLatest(ADD_ORGANIZATION_USER_REQUEST, addOrganizationUser)
}

export function* watchBindOrganizationUserAccountSaga() {
  yield takeLatest(
    BIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
    bindOrganizationUserAccount,
  )
}

export function* watchUnbindOrganizationUserAccountSaga() {
  yield takeLatest(
    UNBIND_ORGANIZATION_USER_ACCOUNT_REQUEST,
    unbindOrganizationUserAccount,
  )
}

export function* watchUpdateOrganizationUserSaga() {
  yield takeLatest(UPDATE_ORGANIZATION_USER_REQUEST, updateOrganizationUser)
}

export function* watchDeleteOrganizationUserSaga() {
  yield takeLatest(DELETE_ORGANIZATION_USER_REQUEST, deleteOrganizationUser)
}

export function* watchFetchOrganizationLocationsSaga() {
  yield takeLatest(
    FETCH_ORGANIZATION_LOCATIONS_REQUEST,
    fetchOrganizationLocations,
  )
}

export function* watchFetchBoundOrganizationUsersSaga() {
  yield takeLatest(
    FETCH_BOUND_ORGANIZATION_USERS_REQUEST,
    fetchBoundOrganizationUsers,
  )
}

function* fetchStaff() {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(fetchStaffRequest(accessToken))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchStaffSuccess(result.workers))
      } else {
        const errors = handleErrors(result)
        yield put(fetchStaffFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchContacts(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserContactsRequest(accessToken, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchContactsSuccess(result.contacts))
      } else {
        const errors = handleErrors(result)
        yield put(fetchContactsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchContactsCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchUserContactsCountRequest(accessToken, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchContactsCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchContactsCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteStaff(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(deleteStaffRequest(accessToken, action.ids))

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(deleteStaffSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(deleteStaffFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationStaff(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationStaffRequest(
        accessToken,
        action.id,
        action.filters,
        action.query,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationStaffSuccess(result.users))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationStaffFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationStaffCount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationStaffCountRequest(
        accessToken,
        action.id,
        action.filters,
        action.query,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationStaffCountSuccess(result.count))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationStaffCountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* addOrganizationUser(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      addOrganizationUserRequest(accessToken, action.id, action.data),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(addOrganizationUserSuccess(result.organization_user_id))
      } else {
        const errors = handleErrors(result)
        yield put(addOrganizationUserFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* bindOrganizationUserAccount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      bindOrganizationUserAccountRequest(
        accessToken,
        action.organizationId,
        action.data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(bindOrganizationUserAccountSuccess())
      } else {
        const errors = handleErrors(result)
        if (!isEmpty(errors.email)) {
          yield put(bindOrganizationUserAccountFailure(errors))
        } else {
          yield put(receivedErrorMessage(errors))
        }
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* unbindOrganizationUserAccount(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      unbindOrganizationUserAccountRequest(
        accessToken,
        action.organizationId,
        action.data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(unbindOrganizationUserAccountSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(unbindOrganizationUserAccountFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* updateOrganizationUser(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, organizationUserId, data } = action
    const result = yield call(
      updateOrganizationUserRequest(
        accessToken,
        organizationId,
        organizationUserId,
        data,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(updateOrganizationUserSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(updateOrganizationUserFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* deleteOrganizationUser(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const { organizationId, organizationUserId } = action
    const result = yield call(
      deleteOrganizationUserRequest(
        accessToken,
        organizationId,
        organizationUserId,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(deleteOrganizationUserSuccess())
      } else {
        const errors = handleErrors(result)
        yield put(deleteOrganizationUserFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchOrganizationLocations(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchOrganizationLocationsRequest(accessToken, action.id),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchOrganizationLocationsSuccess(result.locations))
      } else {
        const errors = handleErrors(result)
        yield put(fetchOrganizationLocationsFailure(errors))
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}

function* fetchBoundOrganizationUsers(action) {
  try {
    const accessToken = localStorage.getItem('atoirAccessToken')
    const result = yield call(
      fetchBoundOrganizationUsersRequest(
        accessToken,
        action.organizationId,
        action.filters,
      ),
    )

    if (!isEmpty(result.success)) {
      if (result.success) {
        yield put(fetchBoundOrganizationUsersSuccess(result.users))
      } else {
        const errors = handleErrors(result)
        yield put(fetchBoundOrganizationUsersFailure())
        yield put(receivedErrorMessage(errors))
      }
    }
  } catch (error) {
    yield put(catchError(error.message))
  }
}
