import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import AddIcon from '@material-ui/icons/Add'
import i18next from '../../../../../i18n/i18n'

const styles = () => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  inputFiles: {
    display: 'none',
  },
  inputLabel: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
  },
})

function AttachTagButton({ classes, onClick }) {
  return (
    <div className={classes.root}>
      <InputBase
        type="button"
        className={classes.inputFiles}
        id="raised-button-file"
        onClick={onClick}
      />
      <InputLabel className={classes.inputLabel} htmlFor="raised-button-file">
        <AddIcon fontSize="small" />
        <Typography variant="caption">
          {i18next.t('organizations_web_atoir:addTag')}
        </Typography>
      </InputLabel>
    </div>
  )
}

AttachTagButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(AttachTagButton)
