/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import Autosuggest from 'react-autosuggest'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

// modules
import queryString from 'query-string'
import { fetchProxy } from '../../middlewares/api'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import DelayedLoader from '../DelayedLoader'

const PaddedContentDiv = styled.div`
  margin: 0 12px;
`

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: 500,
    overflowY: 'auto',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
})

class AutocompleteLimitedAuto extends React.Component {
  constructor(props) {
    super(props)
    this.setChunkOnlyOnce = _.once(chunkHeight => {
      this.setState({ chunkHeight: chunkHeight / 3 })
    })
    this.state = {
      value: props.value || '',
      open: false,
      end: false,
      limit: props.limit,
      offset: props.offset,
      data: [],
      selected: null,
      chunkHeight: 0,
    }
  }

  componentDidMount() {
    const {
      fetchMore,
      ref,
      props: { valueCandidate },
      defaultValueInitialisation,
    } = this
    if (valueCandidate) {
      defaultValueInitialisation()
    } else {
      fetchMore()
    }
    if (ref) {
      ref(this)
    }
  }

  defaultValueInitialisation = async () => {
    const {
      state: { limit },
      props: {
        valueCandidate,
        isSuccessResponse,
        requestResultTransformerToArray,
      },
      makeRequest,
      onSelect,
      fetchMore,
    } = this
    const result = await makeRequest(valueCandidate, { offset: 0, limit })
    if (isSuccessResponse(result)) {
      const newData = requestResultTransformerToArray(result)
      const suggestion = newData.find(i => i.name === valueCandidate)
      if (suggestion) {
        onSelect(null, { suggestion, suggestionValue: suggestion.name })
      } else {
        fetchMore()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sourceUrl !== this.props.sourceUrl) {
      this.reset()
    }
    if (
      prevProps.value !== this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState(_.omitBy({ value: this.props.value }, _.isUndefined))
    }
  }

  reset = () => {
    this.setState(
      {
        open: false,
        limit: this.props.limit,
        offset: 0,
        end: false,
        data: [],
      },
      this.fetchMore,
    )
  }

  makeRequest = async (value, { offset, limit }) => {
    const {
      props: { sourceUrl, query: q, transformInputToQuery },
    } = this
    const query = { ...q, ...transformInputToQuery(value) }
    const request = await fetchProxy(
      `${sourceUrl}?${queryString.stringify({ limit, offset, ...query })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('atoirAccessToken'),
        },
      },
    )
    return request.json()
  }

  fetchMore = (value, offset) => {
    const {
      props: {
        requestResultTransformerToArray,
        isSuccessResponse,
        onError,
        sourceUrl,
      },
      state: { isFetching, limit, dataForFalue },
    } = this
    if (!sourceUrl || isFetching) {
      return
    }
    {
      this.setState(
        {
          isFetching: true,
        },
        async () => {
          const result = await this.makeRequest(value, { offset, limit })
          if (isSuccessResponse(result)) {
            const newData = requestResultTransformerToArray(result)
            if (newData.length < limit) {
              this.onEndOfScroll()
            }
            this.setState(({ data }) => ({
              dataForFalue: value,
              data: dataForFalue === value ? [...data, ...newData] : newData,
              isFetching: false,
              offset: offset || 0,
            }))
          } else {
            onError(result)
            this.setState({
              isFetching: false,
            })
          }
        },
      )
    }
  }

  onSelect = (event, { suggestion, suggestionValue }) => {
    this.props.onChange(suggestion)
    this.setState({
      value: this.props.getItemDisplayName(suggestion),
      selected: suggestionValue,
      selectedItem: suggestion,
    })
  }

  onChange = (event, { method, newValue }) => {
    const {
      props: { suggestionMode },
    } = this
    if (method === 'type') {
      this.setState({
        value: newValue,
        end: false,
        selected: null,
      })
      if (suggestionMode) {
        this.props.onChange(newValue, 'type')
      } else {
        this.props.onChange(null)
      }
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      value,
    })
    this.fetchMore(value)
  }

  onSuggestionsClearRequested = () => {
    console.log('Clear')
    this.setState({ data: [] })
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(this.props.getItemDisplayName(suggestion), query)
    const parts = parse(this.props.getItemDisplayName(suggestion), matches)
    return (
      <MenuItem selected={isHighlighted} component="div">
          <Typography variant="subtitle1" noWrap>
            {parts.map((part, index) =>
              part.highlight ? (
                <strong key={String(index)}>{part.text}</strong>
              ) : (
                <span key={String(index)}>{part.text}</span>
              ),
            )}
          </Typography>
      </MenuItem>
    )
  }

  renderInputComponent = inputProps => {
    const {
      classes,
      inputRef = () => {},
      ref,
      value,
      onChange,
      ...other
    } = inputProps
    const onChangeHandler = event => {
      onChange(event)
    }
    return (
      <TextField
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node)
            inputRef(node)
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
        value={value}
        onChange={onChangeHandler}
      />
    )
  }

  onEndOfScroll = () => {
    this.setState({ end: true })
  }

  handleScroll = ({ target }) => {
    const { isFetching, chunkHeight, end, value, limit, offset } = this.state
    this.setChunkOnlyOnce(target.scrollHeight)
    if (
      !end &&
      !isFetching &&
      target.scrollHeight - target.clientHeight - target.scrollTop <=
        chunkHeight
    ) {
      this.fetchMore(value, limit + offset)
    }
  }

  render = () => {
    const {
      state: { data, value, isFetching },
      props: {
        classes,
        getItemValue,
        placeholder,
        theme,
        label,
        InputProps,
        menuListProps,
        dropDownPlacement,
        fixedWidth,
      },
      onChange,
      onSelect,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      renderInputComponent,
      renderSuggestion,
    } = this
    const inputProps = {
      classes,
      placeholder,
      label,
      value,
      onChange,
      inputRef: node => {
        this.popperNode = node
      },
      ...InputProps,
    }

    function fixedWidthCB(data) {
      const newData = { ...data }
      newData.offsets.popper.left = Math.round(
        (document.documentElement.clientWidth - fixedWidth) / 2,
      )
      newData.styles.width = fixedWidth
      return newData
    }

    return (
      <Autosuggest
        suggestions={data}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSelect}
        getSuggestionValue={getItemValue}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={false}
        inputProps={inputProps}
        shouldRenderSuggestions={() => true}
        renderInputComponent={renderInputComponent}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
          ...theme,
        }}
        renderSuggestionsContainer={options => (
          <Popper
            anchorEl={this.popperNode}
            placement={dropDownPlacement}
            open={Boolean(options.children)}
            style={{ zIndex: 10000, transform: 'translate(22px, -6px)' }}
            modifiers={
              fixedWidth
                ? {
                    fixedWidth: {
                      enabled: true,
                      fn: fixedWidthCB,
                      order: 840,
                    },
                  }
                : {}
            }
          >
            <Paper
              square
              {...options.containerProps}
              onScroll={this.handleScroll}
              {...menuListProps}
            >
              {options.children}
              {!isFetching ? (
                <PaddedContentDiv>
                  <DelayedLoader delay={300} />
                </PaddedContentDiv>
              ) : null}
            </Paper>
          </Popper>
        )}
      />
    )
  }
}

AutocompleteLimitedAuto.defaultProps = {
  limit: 10,
  offset: 0,
  value: '',
  firstElement: null,
  selectProps: {},
  menuListProps: {},
  menuListStyleProps: {},
  query: {},
  ref: () => {},
  onError: () => {},
  isSuccessResponse: r => r.success,
  sourceUrl: null,
  placeholder: '',
  label: '',
  getItemValue: i => i.id,
  InputProps: {},
  error: false,
  theme: {},
  suggestionMode: false,
  dropDownPlacement: 'bottom',
  fixedWidth: null,
}

AutocompleteLimitedAuto.propTypes = {
  suggestionMode: PropTypes.bool,
  limit: PropTypes.number,
  fixedWidth: PropTypes.number,
  sourceUrl: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isSuccessResponse: PropTypes.func,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  transformInputToQuery: PropTypes.func.isRequired,
  onError: PropTypes.func,
  requestResultTransformerToArray: PropTypes.func.isRequired,
  getItemValue: PropTypes.func,
  getItemDisplayName: PropTypes.func.isRequired,
  ref: PropTypes.func,
  value: PropTypes.any,
  query: PropTypes.object,
  firstElement: PropTypes.object,
  selectProps: PropTypes.object,
  InputProps: PropTypes.object,
  menuListProps: PropTypes.object,
  menuListStyleProps: PropTypes.object,
  theme: PropTypes.object,
  dropDownPlacement: PropTypes.string,
}

export default withStyles(styles)(AutocompleteLimitedAuto)
