import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import i18next from '../../../../../i18n/i18n'
import isEmpty from 'is-empty'

import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'

// components
import BaseInfo from '../BaseInfo'
import SendToAuction from '../../SendToAuctionDialog'
// import ConfirmInventoryProjectPerformer from '../../ConfirmInventoryProjectPerformer'
import SetForeignPerformer from '../../SetPerformerDialogs/SetForeignPerformer'
import DialogAddKP from '../DialogAddKP'
import ConfirmInventoryProject from '../../ConfirmInventoryProjectPerformer'
import RejectInventoryProject from '../../RejectInventoryProjectPerformer'
import CanInventory from '../InventoryProjectAbility/CanInventory'
import InventoryAbility from '../InventoryProjectAbility/InventoryAbility'
import PrimaryButton from '../../../../../widgets/Buttons/PrimaryButton'

class InventoryProjectProfileInfoComponent extends Component {
  state = {
    choosePerformerDialogAnchor: null,
    isSendToAuctionOpen: false,
    openSetOwnPerformer: false,
    openSetForeignPerformer: false,
    openResponseAction: false,
    openConfirmAsPerformer: false,
    openRejectAsPerformer: false,
  }

  handleChoosePerformerMenuOpen = event => {
    this.setState({ choosePerformerDialogAnchor: event.currentTarget })
  }

  handleChoosePerformerDialogClose = () => {
    this.setState({ choosePerformerDialogAnchor: null })
  }

  handleOpenAuction = () => {
    this.setState({ isSendToAuctionOpen: true })
  }

  handleCloseAuction = status => {
    const { project, fetchInventoryProjectProfile } = this.props
    if (status) {
      fetchInventoryProjectProfile(project.id)
    }
    this.setState({ isSendToAuctionOpen: false })
  }

  handleSetOwnDialog = () => {
    const {
      state: { openSetOwnPerformer },
      props: { project, fetchOrganizationStaff, fetchBoundOrganizationUsers },
    } = this
    if (
      !openSetOwnPerformer &&
      !isEmpty(project) &&
      !isEmpty(project.client_organization_id)
    ) {
      fetchBoundOrganizationUsers(project.client_organization_id)
      fetchOrganizationStaff(project.client_organization_id)
    }
    this.setState({ openSetOwnPerformer: !openSetOwnPerformer })
  }

  handleSetForeignDialog = () => {
    const {
      state: { openSetForeignPerformer },
    } = this
    this.setState({ openSetForeignPerformer: !openSetForeignPerformer })
  }

  handleResponseAction = () => {
    this.setState(state => ({
      openResponseAction: !state.openResponseAction,
    }))
  }

  handleConfirmAsPerformerDialog = () => {
    const {
      state: { openConfirmAsPerformer },
      props: { project, fetchOrganizationStaff, fetchBoundOrganizationUsers },
    } = this
    if (
      !openConfirmAsPerformer &&
      !isEmpty(project) &&
      !isEmpty(project.performer_organization_id)
    ) {
      fetchBoundOrganizationUsers(project.performer_organization_id)
      fetchOrganizationStaff(project.performer_organization_id)
    }
    this.setState({ openConfirmAsPerformer: !openConfirmAsPerformer })
  }

  handleRejectAsPerformerDialog = () => {
    const {
      state: { openRejectAsPerformer },
    } = this
    this.setState({ openRejectAsPerformer: !openRejectAsPerformer })
  }

  render() {
    const {
      state: {
        choosePerformerDialogAnchor,
        isSendToAuctionOpen,
        openSetOwnPerformer,
        openSetForeignPerformer,
        openResponseAction,
        openConfirmAsPerformer,
        openRejectAsPerformer,
      },
      props: { classes, location, project, permissions },
      handleSetOwnDialog,
      handleSetForeignDialog,
      handleResponseAction,
      handleChoosePerformerDialogClose,
      handleChoosePerformerMenuOpen,
      handleCloseAuction,
      handleOpenAuction,
      handleConfirmAsPerformerDialog,
      handleRejectAsPerformerDialog,
    } = this
    return (
      <Fragment>
        <BaseInfo
          canCancelProject={permissions && permissions.cancel_project}
        />
        <br />
        <Divider />
        <Divider />
        <Divider />
        <Divider />
        <br />
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CanInventory I="edit" a="Inventory">
              <PrimaryButton component={Link} to={`${location.pathname}/edit`}>
                {i18next.t('inventory_web_atoir:editInventoryProject')}
              </PrimaryButton>
            </CanInventory>
          </Grid>
          <Grid item xs={7} />
          <Grid container xs={3} justify="flex-end">
            <div className={classes.leftBtnArea}>
              <CanInventory I="send_offer" a="Inventory">
                <div className={classes.buttonMarginVertical}>
                  <PrimaryButton onClick={handleResponseAction}>
                    {i18next.t('inventory_web_atoir:respond')}
                  </PrimaryButton>
                </div>
              </CanInventory>
              <CanInventory I="start_auction" a="Inventory">
                <div className={classes.buttonMarginVertical}>
                  <PrimaryButton onClick={handleOpenAuction}>
                    {i18next.t('inventory_web_atoir:putUpForAuction')}
                  </PrimaryButton>
                </div>
              </CanInventory>
              {InventoryAbility.can('set_own_performer', 'Inventory') ||
              InventoryAbility.can('set_foreign_performer', 'Inventory') ? (
                <div className={classes.buttonMarginVertical}>
                  <PrimaryButton
                    aria-haspopup="true"
                    aria-owns={choosePerformerDialogAnchor ? 'fade-menu' : null}
                    onClick={handleChoosePerformerMenuOpen}
                  >
                    {i18next.t('inventory_web_atoir:designateProjectPerformer')}
                  </PrimaryButton>
                </div>
              ) : (
                ''
              )}
              <CanInventory I="confirm_as_performer" a="Inventory">
                <div className={classes.buttonMarginVertical}>
                  <PrimaryButton onClick={handleConfirmAsPerformerDialog}>
                    {i18next.t('inventory_web_atoir:acceptInventoryProject')}
                  </PrimaryButton>
                </div>
              </CanInventory>
              <CanInventory I="reject_as_performer" a="Inventory">
                <div className={classes.buttonMarginVertical}>
                  <PrimaryButton onClick={handleRejectAsPerformerDialog}>
                    {i18next.t('inventory_web_atoir:rejectInventoryProject')}
                  </PrimaryButton>
                </div>
              </CanInventory>
            </div>
          </Grid>
        </Grid>
        <Menu
          id="fade-menu"
          anchorEl={choosePerformerDialogAnchor}
          open={Boolean(choosePerformerDialogAnchor)}
          onClose={handleChoosePerformerDialogClose}
        >
          {InventoryAbility.can('set_foreign_performer', 'Inventory') ? (
            <MenuItem onClick={this.handleSetForeignDialog}>
              {i18next.t('inventory_web_atoir:thirdPartyOrganization')}
            </MenuItem>
          ) : (
            ''
          )}
          {InventoryAbility.can('set_foreign_performer', 'Inventory') ? (
            <MenuItem onClick={this.handleSetOwnDialog}>
              {i18next.t('inventory_web_atoir:ownSpecialist')}
            </MenuItem>
          ) : (
            ''
          )}
        </Menu>
        <SendToAuction
          project={project}
          isOpen={isSendToAuctionOpen}
          onClose={handleCloseAuction}
        />
        {project && openSetOwnPerformer ? (
          <ConfirmInventoryProject
            open={openSetOwnPerformer}
            handleClose={handleSetOwnDialog}
            organizationId={project.client_organization_id}
            projectId={project.id}
            title={i18next.t(
              'inventory_web_atoir:setOwnPerformerToProjectDialogTitle',
            )}
            submitButtonTitle={i18next.t('inventory_web_atoir:setOwnEngineers')}
            client
          />
        ) : null}
        {project && openSetForeignPerformer ? (
          <SetForeignPerformer
            open={openSetForeignPerformer}
            handleClose={handleSetForeignDialog}
            organizationId={project.client_organization_id}
            projectId={project.id}
          />
        ) : null}
        {project && openResponseAction && (
          <DialogAddKP
            open={openResponseAction}
            handleClose={handleResponseAction}
            projectId={project.id}
            clientOrganizationId={project.client_organization_id}
            projectType={project.inventory_type}
            projectName={project.Client.full_name}
          />
        )}
        {project && openConfirmAsPerformer && (
          <ConfirmInventoryProject
            open={openConfirmAsPerformer}
            handleClose={handleConfirmAsPerformerDialog}
            organizationId={project.performer_organization_id}
            projectId={project.id}
            title={i18next.t(
              'inventory_web_atoir:confirmInventoryProjectAsPerformerDialogTitle',
            )}
            submitButtonTitle={i18next.t(
              'inventory_web_atoir:setProjectInWork',
            )}
          />
        )}
        {project && openRejectAsPerformer && (
          <RejectInventoryProject
            open={openRejectAsPerformer}
            handleClose={handleRejectAsPerformerDialog}
            organizationId={project.performer_organization_id}
            projectId={project.id}
          />
        )}
      </Fragment>
    )
  }
}

const styles = theme => ({
  location: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  fetchInventoryProjectProfile: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  buttonMarginVertical: {
    marginBottom: theme.spacing(1),
  },
  buttonMargin: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  leftBtnArea: {
    width: theme.typography.pxToRem(200),
  },
})

export default withStyles(styles)(InventoryProjectProfileInfoComponent)
