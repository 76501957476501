import React from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import i18next from '../../../../i18n/i18n'

const Box = styled.div`
  ${spacing}
`

export default function EmptyResult() {
  return (
    <Box mb={2} mt={2}>
      <Typography variant="subtitle2" color="secondary">
        {i18next.t('shared_web_atoir:searchResultNo')}
      </Typography>
    </Box>
  )
}
