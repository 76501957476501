export const statuses = [
  { id: 'working', title: 'Исправно' },
  { id: 'broken', title: 'Сломано' },
  { id: 'need_utilization', title: 'Требует списания' },
  { id: 'utilization', title: 'Списано' },
]

export const serviceDeskRequestType = {
  DIAGNOSTIC_AND_REPAIR: 'DIAGNOSTIC_AND_REPAIR',
}

export const inventoryEquipmentAmount = [
  '100',
  '100-200',
  '200-300',
  '300-400',
  '400-500',
  '500-600',
  '600-700',
  '700-800',
  '800-900',
  '900-1000',
  '1000-1500',
  '1500-2000',
  '2000-2500',
  '2500-3000',
  '3000-3500',
  '3500-4000',
  '4000-4500',
  '4500-5000',
  '5000',
]
